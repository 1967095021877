import React from 'react';
import JobRequisitionCard, { JOB_REQUISITION_CARD_HEIGHT } from '../index';
import { ApprovalFlowSummary } from '../../../../ApprovalFlows/components/ApprovalFlowSummary';
import {
  ApproverAnswer,
  JobRequisitionApprover,
} from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { ApprovalFlowStep } from '../../../../../../entities/applicant_tracking/ApprovalFlow';
import { Chip, ChipColor } from '../../../../../../components/Chip';
import { getOrdinal } from '../../../../../../utils/number';
import {
  getCurrentStepOrder,
  buildSteps,
} from '../../../../../../utils/applicant_tracking/jobRequisition';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Avatar } from '../../../../../../components/Avatar';
import { User } from '../../../../../../entities/User';

interface PropTypes {
  approvers: JobRequisitionApprover[];
  currentUser: User;
}

function getDeclinedStepOrder(steps: ApprovalFlowStep[]): number {
  return steps.find((step) =>
    step.approvers.find(
      (approver) => approver.answer === ApproverAnswer.Declined,
    ),
  )?.order;
}

function buildStatusText(steps: ApprovalFlowStep[]): string {
  const wasApprovedByAll = steps.every((step) =>
    step.approvers.find(
      (approver) => approver.answer === ApproverAnswer.Approved,
    ),
  );

  if (wasApprovedByAll) {
    return 'Approved by all';
  }

  const declinedStepOrder = getDeclinedStepOrder(steps);

  if (declinedStepOrder != null) {
    return null;
  }

  const currentStepOrder = getCurrentStepOrder(steps);

  if (currentStepOrder != null) {
    return `Awaiting for ${getOrdinal(currentStepOrder)} approval`;
  }

  return null;
}

function StatusChip(props: { steps: ApprovalFlowStep[] }) {
  const statusText = buildStatusText(props.steps);

  if (!statusText) {
    return null;
  }

  return (
    <Chip className='fw-semibold' color={ChipColor.Gray}>
      {statusText}
    </Chip>
  );
}

function DeclinedReason(
  props: PropTypes & { declinedApprover: JobRequisitionApprover },
) {
  const user = props.declinedApprover.user;

  return (
    <div>
      <div className='fs-5 mb-2 mt-2h'>Decline Reason</div>
      <div className='rounded-4 bg-light-danger p-3'>
        <div className='d-flex align-items-center mb-2'>
          <Avatar
            size='lg'
            className='me-2h'
            name={user.photoUrl ?? user.name}
            type={user.photoUrl ? 'photo' : 'initials'}
          />
          <div className='fs-5 fw-semibold text-nowrap text-primary'>
            {user.name} {props.currentUser.id === user.id && '(You)'}
          </div>
        </div>
        <div className='fs-6'>{props.declinedApprover.declineReason}</div>
      </div>
    </div>
  );
}

export default function ApprovalFlowCard(props: PropTypes) {
  const approvalSteps = buildSteps(props.approvers);
  const currentStepOrder = getCurrentStepOrder(approvalSteps);
  const declinedStepOrder = getDeclinedStepOrder(approvalSteps);
  const declinedApprover = props.approvers.find(
    (approver) => approver.answer === ApproverAnswer.Declined,
  );

  return (
    <JobRequisitionCard
      title={'Approval Flow'}
      status={<StatusChip steps={approvalSteps} />}
      customStyles={{ minHeight: JOB_REQUISITION_CARD_HEIGHT }}
    >
      <ApprovalFlowSummary
        steps={approvalSteps}
        approversBreakLine={true}
        className={classNames('mt-3 overflow-scroll', styles['hide-scrollbar'])}
        currentUser={props.currentUser}
        currentStepOrder={currentStepOrder}
        declinedStepOrder={declinedStepOrder}
      />
      {declinedApprover?.declineReason && (
        <DeclinedReason {...props} declinedApprover={declinedApprover} />
      )}
    </JobRequisitionCard>
  );
}
