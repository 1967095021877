import { NpsExplainabilityEntry } from '../../../../../entities/candidate_experience/NpsExplainabilityEntry';
import { Badge, Tooltip } from 'reactstrap';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles.module.scss';
import { findRatingBgColorByScore } from '../../utils';
import {
  CPS_CLASSIFICATION_LEGEND,
  CPS_DEFAULT_CLASSIFICATION_LEGEND,
} from '../../../../../components/survey_engine/RatingLegend';

export const BADGE_COLOR_MAPPING = {
  green: styles['green-badge'],
  red: styles['red-badge'],
};

export interface PropTypes {
  entry: NpsExplainabilityEntry;
  badgeStyle: string;
  standardNpsCalculation: boolean;
}

export function NPSExplainabilityTopImpactTableRow(props: PropTypes) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const dimension_value = props.entry.dimensions_values[0]?.value;
  const ratingLegend = props.standardNpsCalculation
    ? CPS_DEFAULT_CLASSIFICATION_LEGEND
    : CPS_CLASSIFICATION_LEGEND;

  const bgColorScore = findRatingBgColorByScore(ratingLegend, props.entry.nps);

  useEffect(() => {
    const el = ref.current;
    if (el == null) return;
    setIsOverflowing(el.offsetWidth < el.scrollWidth);
  }, [dimension_value]);

  return (
    <tr key={dimension_value}>
      <td>
        <Badge
          className={classNames(
            'rounded-pill me-1',
            styles.badge,
            'text-start text-truncate mw-100',
          )}
          innerRef={ref}
        >
          {dimension_value}
        </Badge>
        {isOverflowing && (
          <Tooltip
            target={ref.current}
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            placement='top'
          >
            {dimension_value}
          </Tooltip>
        )}
      </td>
      <td className='text-center'>
        <Badge
          className={classNames('rounded-pill me-1', styles['score-badge'])}
          color={bgColorScore}
        >
          {props.entry.nps}
        </Badge>
      </td>
      <td>
        <Badge
          className={classNames(
            'rounded-pill me-1',
            BADGE_COLOR_MAPPING[props.badgeStyle],
          )}
        >
          {Number(props.entry.impact).toFixed(1)}%
        </Badge>
      </td>
      <td className='text-center'>
        <Badge className={classNames('rounded-pill me-1', styles.badge)}>
          {props.entry.count}
        </Badge>
      </td>
      <td className='text-center'>
        <Badge className={classNames('rounded-pill me-1', styles.badge)}>
          {Number(props.entry.percentage).toFixed(1)}%
        </Badge>
      </td>
    </tr>
  );
}
