import React, { useState } from 'react';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';
import { GenericEntry } from '..';
import { Application } from '../../../../entities/Application';
import { OfferFeed } from '../../../../entities/ApplicationFeed';
import { User } from '../../../../entities/User';
import { AuthorName } from '../AuthorName';
import { AlertObject } from '../../../../components/Alert';
import { Avatar } from '../../../../components/Avatar';
import { ActivityButton } from '../ActivityButton';
import { Button } from 'reactstrap';
import { RevokeOfferModal } from './RevokeOfferModal';
import { AttachmentList } from '../Email/EmailEntry/AttachmentList';

interface PropTypes {
  application: Application;
  currentUser: User;
  entry: OfferFeed;
  isLastItemInPage: boolean;
  setAlert: (data: AlertObject) => void;
  reloadApplication: () => void;
}

function OfferBody(
  props: PropTypes & { setRevokeModalOpen: (open: boolean) => void },
) {
  return (
    <div className='d-flex flex-nowrap align-items-start'>
      <Avatar className='me-3' name={props.entry.authorName} size='lg' />
      <div className='w-100 rounded-3 bg-gray py-3 px-4'>
        <div>
          <b>{props.entry.authorName}</b>{' '}
          <small className='text-dark-200'>({props.entry.authorEmail})</small>
        </div>
        <div className='mt-3'>
          {parse(DOMPurify.sanitize(props.entry.note))}
        </div>
        {props.entry.email && (
          <AttachmentList attachments={props.entry.email.attachments} />
        )}
        <OfferActions {...props} />
      </div>
    </div>
  );
}

function OfferActions(
  props: PropTypes & { setRevokeModalOpen: (open: boolean) => void },
) {
  return (
    <div className='d-flex mt-4 gap-2'>
      {props.entry.offerId && (
        <ActivityButton
          url={`/applicant_tracking/offers/${props.entry.offerId}/preview`}
          size='sm'
          openInNewTab
          text='View Offer'
          icon='bi-box-arrow-up-right'
        />
      )}
      {props.entry.revokeOfferPermitted && (
        <Button
          size='sm'
          color='danger'
          onClick={() => props.setRevokeModalOpen(true)}
        >
          Revoke Offer
        </Button>
      )}
    </div>
  );
}

export function Offer(props: PropTypes) {
  const [revokeModalOpen, setRevokeModalOpen] = useState(false);

  return (
    <>
      <RevokeOfferModal
        id={props.entry.offerId}
        isOpen={revokeModalOpen}
        setAlert={props.setAlert}
        reloadApplication={props.reloadApplication}
        setIsOpen={setRevokeModalOpen}
      />
      <GenericEntry
        icon='bi-envelope'
        iconClassName='bg-blue-gray-light'
        title={
          <>
            <AuthorName name={props.entry.authorName} /> sent{' '}
            <strong>{props.application.candidate.name}</strong> an offer.
          </>
        }
        date={props.entry.createdAt}
        {...props}
      >
        <OfferBody {...props} setRevokeModalOpen={setRevokeModalOpen} />
      </GenericEntry>
    </>
  );
}
