import React from 'react';
import { InterviewSecret } from '../../../entities/InterviewSecret';
import styles from './styles.module.scss';

export interface Segment {
  start: number;
  end: number;
}

interface PropTypes {
  segments: Segment[];
  totalTime: number;
  color: string;
  secrets: InterviewSecret[];
  onSelectTime: (time: number) => void;
}

function renderSegment(key: number, segment: Segment, props: PropTypes) {
  const marginLeft = (segment.start / props.totalTime) * 100;
  const width = ((segment.end - segment.start) / props.totalTime) * 100;

  return (
    <a
      key={key}
      className={styles.timelineSegment}
      style={{
        marginLeft: `${marginLeft}%`,
        width: `${width}%`,
        background: props.color,
      }}
      onClick={() => props.onSelectTime(segment.start)}
    />
  );
}

function renderSecret(key: number, secret: InterviewSecret, props: PropTypes) {
  const marginLeft = (secret.start_time / props.totalTime) * 100;
  const width = ((secret.end_time - secret.start_time) / props.totalTime) * 100;

  return (
    <a
      key={key}
      className={styles.timelineSegment}
      style={{
        marginLeft: `${marginLeft}%`,
        width: `${width}%`,
        background: '#F6F6FF',
        border: '1px solid #7976FF',
        cursor: 'default',
      }}
    />
  );
}

export const TimelineBar = React.memo(function TimelineBar(props: PropTypes) {
  return (
    <div className={styles.timeline}>
      <div className={styles.timelineSegments}>
        {props.segments.map((segment, idx) =>
          renderSegment(idx, segment, props),
        )}
        {props.secrets.map((secret, idx) => renderSecret(idx, secret, props))}
      </div>
    </div>
  );
});
