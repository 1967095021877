import { Answer } from '../../entities/v1/survey_engine/Answer';
import { Question } from '../../entities/v1/survey_engine/Question';
import { Survey } from '../../entities/v1/survey_engine/Survey';

export function getAnswerToQuestion(
  survey: Survey,
  question: Question,
): string {
  return survey.answers.find(
    (answer: Answer) => answer.questionId === question.id,
  )?.value;
}
