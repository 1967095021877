import React from 'react';
import { Condition, ConditionsData } from '../../..';
import { SelectOption } from '../../../../../../../components/Select';
import { ConditionRow } from './ConditionRow';
import { SetIconCol } from './SetIconCol';

interface ConditionRowsPropTypes {
  conditions: Condition[];
  applicableConditions: SelectOption[];
  setConditions: (conditions: Condition[]) => void;
  selectedSurveyTemplate: SelectOption;
  conditionsData: ConditionsData;
}

export function ConditionRows(props: ConditionRowsPropTypes) {
  return (
    <>
      {props.conditions.map((condition, index) => (
        <div key={index} className={`row ${index > 0 ? 'pt-2' : 'pt-4'}`}>
          <div className='row align-items-center'>
            <ConditionRow
              conditionsSelectOptions={{
                index,
                available: condition.available,
                singleSelected: condition.singleSelected,
                multiSelected: condition.multiSelected,
                type: condition.type,
                invert: condition.invert,
                selectedSurveyTemplate: props.selectedSurveyTemplate,
                conditions: props.conditions,
                conditionsData: props.conditionsData,
                setConditions: props.setConditions,
              }}
              mandatory={condition.mandatory}
              applicableConditions={props.applicableConditions}
            />
            <SetIconCol
              condition={condition}
              conditions={props.conditions}
              index={index}
              setConditions={props.setConditions}
            />
          </div>
        </div>
      ))}
    </>
  );
}
