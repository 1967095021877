export function getFirstOverflowIndex(
  containerWidth: number,
  names: string[],
  pixelPerChar: number,
): number | null {
  let accumulator = 0;
  let overflowIndex = 0;

  for (overflowIndex = 0; overflowIndex < names.length; overflowIndex++) {
    accumulator += names[overflowIndex].length * pixelPerChar;
    if (accumulator > containerWidth) {
      break;
    }
  }

  // If the overflow index is equals to the total elements, means that no element is overflowing.
  if (overflowIndex == names.length) {
    return null;
  }

  return overflowIndex;
}

export function isOverflowing(overflowIndex: number | null): boolean {
  return overflowIndex != null;
}
