import React from 'react';
import { User } from '../../../../../entities/User';
import { SingleComment } from './SingleComment';
import { MultipleComments } from './MultipleComments';
import { ApplicationFeed } from '../../../../../entities/ApplicationFeed';
import { CommentButton } from './CommentButton';
import { AlertObject } from '../../../../../components/Alert';

const EXPAND_COMMENTS_COUNT = 1;

interface PropTypes {
  applicationFeed: ApplicationFeed;
  currentUser: User;
  showNewCommentBox: boolean;
  setAlert: (data: AlertObject) => void;
  setShowNewCommentBox: (state: boolean) => void;
  setShowReplyToEmail: (state: boolean) => void;
  reloadApplication: (state: boolean) => void;
}

export function CommentList(props: PropTypes) {
  const sortedComments = props.applicationFeed.comments.sort(
    (a, b) => a.id - b.id,
  );

  const additionalComments = sortedComments.slice(EXPAND_COMMENTS_COUNT);

  return (
    <>
      <SingleComment
        applicationFeed={props.applicationFeed}
        comment={sortedComments[0]}
        currentUser={props.currentUser}
        reloadApplication={props.reloadApplication}
        setAlert={props.setAlert}
      />
      {additionalComments.length >= 1 ? (
        <MultipleComments
          applicationFeed={props.applicationFeed}
          comments={additionalComments}
          currentUser={props.currentUser}
          reloadApplication={props.reloadApplication}
          showNewCommentBox={props.showNewCommentBox}
          setShowNewCommentBox={props.setShowNewCommentBox}
          setShowReplyToEmail={props.setShowReplyToEmail}
          setAlert={props.setAlert}
        />
      ) : (
        <div className='d-flex justify-content-end mt-2'>
          <CommentButton
            entryType={props.applicationFeed.entryType}
            showNewCommentBox={props.showNewCommentBox}
            setShowNewCommentBox={props.setShowNewCommentBox}
            setShowReplyToEmail={props.setShowReplyToEmail}
          />
        </div>
      )}
    </>
  );
}
