import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { AnalyticsPanel } from '../../../../../../components/AnalyticsPanel';
import { NhsTeamBreakdown } from '../../../../../../entities/quality_of_hire/NhsTeamBreakdown';
import { NpsTeamBreakdownChip } from './NpsTeamBreakdownChip';
import { ChipColor } from '../../../../../../components/Chip';

interface PropTypes {
  npsTeamBreakdown: NhsTeamBreakdown;
}

export function NpsTeamBreakdown(props: PropTypes) {
  return (
    <AnalyticsPanel
      title={
        <div>
          <div className={classNames('mb-0', 'fw-bold')}>
            NHS Team Breakdown
          </div>
        </div>
      }
    >
      <Row>
        <Col>
          <NpsTeamBreakdownChip
            total={props.npsTeamBreakdown.hiringManagerNhs.total}
            value={props.npsTeamBreakdown.hiringManagerNhs.value}
            trend={props.npsTeamBreakdown.hiringManagerNhsTrend}
            chipColor={ChipColor.BlueLight}
            title='Hiring Manager'
          />
        </Col>
        <Col>
          <NpsTeamBreakdownChip
            total={props.npsTeamBreakdown.employeeNhs.total}
            value={props.npsTeamBreakdown.employeeNhs.value}
            trend={props.npsTeamBreakdown.employeeNhsTrend}
            chipColor={ChipColor.Gray}
            title='Employee'
          />
        </Col>
      </Row>
    </AnalyticsPanel>
  );
}
