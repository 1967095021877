import React from 'react';
import { IconButton } from '../../../../../components/IconButton';
import { Cart } from '../Cart';
import { HeadingRow } from '../../../HeadingRow';
import { JobBoardView, WindowWithHapi } from '../..';
import { JobPostingDetailsForm } from '../JobPostingDetails';
import { Product, Wallet } from '@vonq/hapi-elements-types';
import { JobBoardAlertObject } from '../JobBoardAlert';
import { JobState } from '../../../../../entities/JobDetails';

interface PropTypes {
  cartAmount: number;
  form: JobPostingDetailsForm;
  hasLogo: boolean;
  hasSubdomain: boolean;
  jobStatus: JobState;
  products: Product[];
  view: JobBoardView;
  wallet: Wallet;
  window: WindowWithHapi;
  onPay: () => void;
  setAlert: (alert: JobBoardAlertObject) => void;
  setView: (view: JobBoardView) => void;
  setWallet: (wallet: Wallet) => void;
}

export function JobBoardsHeader(props: PropTypes) {
  return (
    <HeadingRow tabName='Job Boards'>
      {props.view === JobBoardView.AdsReporting && (
        <IconButton
          color='borderless'
          iconName='bi-arrow-left'
          buttonText='Back to Job Boards'
          onClick={() => props.setView(JobBoardView.Marketplace)}
        />
      )}
      <IconButton
        color='borderless'
        iconName='bi-bar-chart'
        buttonText='Campaign Reporting'
        disabled={props.view === JobBoardView.AdsReporting}
        onClick={() => props.setView(JobBoardView.AdsReporting)}
      />
      <Cart
        cartAmount={props.cartAmount}
        disabled={props.view === JobBoardView.AdsReporting}
        form={props.form}
        hasLogo={props.hasLogo}
        hasSubdomain={props.hasSubdomain}
        jobStatus={props.jobStatus}
        products={props.products}
        wallet={props.wallet}
        window={props.window}
        setAlert={props.setAlert}
        setWallet={props.setWallet}
        onPay={props.onPay}
      />
    </HeadingRow>
  );
}
