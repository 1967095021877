import React from 'react';
import { Condition } from '../../../..';
import { IconButton } from '../../../../../../../../components/IconButton';

interface PropTypes {
  condition: Condition;
  conditions: Condition[];
  index: number;
  setConditions: (conditions: Condition[]) => void;
}

function deleteConditionRow(
  index: number,
  conditions: Condition[],
  setConditions: (filtered: Condition[]) => void,
) {
  const filtered = conditions.filter((_, i) => {
    return i !== index;
  });
  setConditions(filtered);
}

export function SetIconCol(props: PropTypes) {
  return (
    <div className='col-1' style={{ marginLeft: '-1rem', marginTop: 'auto' }}>
      {!props.condition.mandatory ? (
        props.index !== 0 && (
          <IconButton
            color='borderless'
            iconName='bi-trash'
            onClick={() =>
              deleteConditionRow(
                props.index,
                props.conditions,
                props.setConditions,
              )
            }
          />
        )
      ) : (
        <span className='ps-1' style={{ color: 'red' }}>
          *
        </span>
      )}
    </div>
  );
}
