import React from 'react';
import classNames from 'classnames';
import { SortingIcon } from '../../../../components/SortingIcon';
import { SortingDirection } from '../../../../utils/types/SortingDirection';
import { TableHeader as TableHeaderLabel } from '../../../../components/TableHeader';
import { ColumnPropTypes } from '..';

interface PropTypes {
  columns: ColumnPropTypes[];
  sortColumn: string;
  sortDirection: SortingDirection;
  onSortUpdate: (sort: string) => void;
}

function TableHeaderColumnName(
  props: PropTypes & {
    tag: string;
    label: string;
    idx: number;
    tooltipText: string;
    isSortable: boolean;
  },
) {
  return props.isSortable ? (
    <a
      className='d-flex justify-content-start align-items-center'
      href='#'
      onClick={() => props.onSortUpdate(props.tag)}
    >
      <TableHeaderLabel label={props.label} tooltipText={props.tooltipText} />
      <SortingIcon
        sortDirection={props.sortDirection}
        isSelected={props.tag === props.sortColumn}
        className={'ms-auto'}
      />
    </a>
  ) : (
    <TableHeaderLabel label={props.label} tooltipText={props.tooltipText} />
  );
}

export function TableHeader(props: PropTypes) {
  return (
    <thead className={classNames('text-nowrap')}>
      <tr>
        {props.columns.map(
          ({ key, label, tooltipText, isSortable, hidden, className }, idx) => {
            return !hidden ? (
              <th
                className={classNames(
                  { 'right-col': idx === props.columns.length - 1 },
                  className,
                )}
                key={idx}
              >
                <TableHeaderColumnName
                  {...props}
                  tag={key}
                  label={label}
                  idx={idx}
                  tooltipText={tooltipText}
                  isSortable={isSortable ?? true}
                />
              </th>
            ) : null;
          },
        )}
      </tr>
    </thead>
  );
}
