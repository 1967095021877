import React, { useEffect } from 'react';
import { MonologueToken } from '../../../entities/v1/interview_intelligence/Monologue';
import { MarkItem } from '../MarkItem';
import { TokenItem, TokenItemExtraProps } from '../TokenItem';

enum TokenMarkType {
  Start = 'START',
  Middle = 'MIDDLE',
  End = 'END',
  Single = 'SINGLE',
}

interface TokenMark {
  start: number;
  end: number;
  type: TokenMarkType;
}

interface PropTypes {
  tokens: MonologueToken[];
  marks: TokenMark[][];
  monologueIndex: number;
  activeIndex: number;
  extraTokenProps: TokenItemExtraProps;
  selectedMonologueSearchOccurenceIndex: number | undefined;
  onUpdateMonologueSearchOccurences?: (index: number, count: number) => void;
}

export function TokenList(props: PropTypes) {
  const children = [];
  let accumulator = [];

  let tokenCount = 0;
  let markCount = 0;

  for (let i = 0; i < props.tokens.length; ++i) {
    const token = props.tokens[i];
    const marks = props.marks[i];

    if (marks.length === 0) {
      children.push(
        <TokenItem
          key={++tokenCount}
          token={token}
          active={i === props.activeIndex}
          {...props.extraTokenProps}
        />,
      );

      continue;
    }

    let offset = 0;

    for (const mark of marks) {
      if (mark.type === TokenMarkType.Single) {
        children.push(
          <TokenItem
            key={++tokenCount}
            token={{
              ...token,
              text: token.text.substring(offset, mark.start),
            }}
            active={i === props.activeIndex}
            suppressSpace={offset > 0}
            {...props.extraTokenProps}
          />,
        );

        children.push(
          <MarkItem
            selected={markCount === props.selectedMonologueSearchOccurenceIndex}
            key={`MarkItem-${++markCount}`}
          >
            <TokenItem
              key={++tokenCount}
              token={{
                ...token,
                text: token.text.substring(mark.start, mark.end),
              }}
              active={i === props.activeIndex}
              suppressSpace={mark.start > 0}
              {...props.extraTokenProps}
            />
          </MarkItem>,
        );
      } else if (mark.type === TokenMarkType.Start) {
        if (mark.start > offset) {
          children.push(
            <TokenItem
              key={++tokenCount}
              token={{
                ...token,
                text: token.text.substring(offset, mark.start),
              }}
              active={i === props.activeIndex}
              suppressSpace={offset > 0}
              {...props.extraTokenProps}
            />,
          );
        }

        accumulator.push(
          <TokenItem
            key={++tokenCount}
            token={{ ...token, text: token.text.substring(mark.start) }}
            active={i === props.activeIndex}
            suppressSpace={mark.start > 0}
            {...props.extraTokenProps}
          />,
        );
      } else if (mark.type === TokenMarkType.Middle) {
        accumulator.push(
          <TokenItem
            key={++tokenCount}
            token={token}
            active={i === props.activeIndex}
            {...props.extraTokenProps}
          />,
        );
      } else if (mark.type === TokenMarkType.End) {
        accumulator.push(
          <TokenItem
            key={++tokenCount}
            token={{ ...token, text: token.text.substring(0, mark.end) }}
            active={i === props.activeIndex}
            {...props.extraTokenProps}
          />,
        );
        children.push(
          <MarkItem
            selected={markCount === props.selectedMonologueSearchOccurenceIndex}
            key={`MarkItem-${++markCount}`}
          >
            {accumulator}
          </MarkItem>,
        );
        accumulator = [];
      }

      offset = mark.end;
    }

    if (offset < token.text.length) {
      children.push(
        <TokenItem
          key={++tokenCount}
          token={{
            ...token,
            text: token.text.substring(offset, token.text.length),
          }}
          active={i === props.activeIndex}
          suppressSpace={offset > 0}
          {...props.extraTokenProps}
        />,
      );
    }
  }

  useEffect(() => {
    props.onUpdateMonologueSearchOccurences?.(props.monologueIndex, markCount);
  }, [markCount]);

  return <>{children}</>;
}
