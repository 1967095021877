import React, { useEffect, useState } from 'react';
import { Avatar } from '../../../../components/Avatar';
import RobotIcon from '../../../../images/icons/robot-white.svg';
import { BouncingDotsLoader } from '../../../../components/BouncingDotsLoader';
import { RoundedIconAvatar } from '../../../../components/RoundedIconAvatar';
import classNames from 'classnames';
import { AssistantResponse } from '..';
import { CopyButton } from '../../../../components/CopyButton';
import styles from './styles.module.scss';
import { marked } from 'marked';

interface PropTypes {
  userName: string;
  responses: AssistantResponse[];
  isAiGenerating: boolean;
}

function ChatAvatars(props: {
  aiAssistant: boolean;
  auto: boolean;
  userName: string;
  index: number;
}) {
  return props.aiAssistant ? (
    (props.index === 0 || !props.auto) && (
      <div className='d-flex mb-2'>
        <RoundedIconAvatar icon={RobotIcon} />
        <span className='me-2'>Screenloop Assistant</span>
      </div>
    )
  ) : (
    <div className='d-flex justify-content-end mb-2'>
      <span className='me-2'>You</span>
      <Avatar name={props.userName} size={'md'} />
    </div>
  );
}

function ResponseCopyButton(props: {
  response: AssistantResponse;
  isAiGenerating: boolean;
}) {
  return (
    !props.response.auto &&
    props.response.aiAssistant &&
    !props.isAiGenerating && <CopyButton textToBeCopied={props.response.text} />
  );
}

function ChatConversation(props: {
  response: AssistantResponse;
  isAiGenerating: boolean;
}) {
  const [htmlContent, setHtmlContent] = useState<string>('');

  useEffect(() => {
    const convertMarkdownToHtml = async () => {
      const html = await marked(props.response.text.trim());
      setHtmlContent(html);
    };

    convertMarkdownToHtml();
  }, [props.response.text]);

  return (
    <div
      className={`d-flex ${
        props.response.aiAssistant
          ? 'justify-content-start'
          : 'justify-content-end'
      }`}
    >
      <div
        className={`${
          props.response.aiAssistant ? 'bg-gray' : 'bg-green-light'
        } p-3 mb-2`}
        style={{
          width: 'fit-content',
          borderRadius: '12px',
          maxWidth: '100%',
        }}
      >
        <div
          className={classNames(
            'd-flex align-items-start',
            styles['markdown-content'],
          )}
        >
          {props.response.text ? (
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          ) : (
            <BouncingDotsLoader />
          )}
          <ResponseCopyButton
            response={props.response}
            isAiGenerating={props.isAiGenerating}
          />
        </div>
      </div>
    </div>
  );
}

export function InterviewAssistantChat(props: PropTypes) {
  return (
    <div
      className={classNames(
        'p-2',
        'mb-2',
        'mt-4',
        'w-100',
        'h-100',
        'overflow-auto',
        'fs-5',
      )}
    >
      {props.responses.map((response, index) => (
        <div className={'d-flex flex-column'} key={index}>
          <ChatAvatars
            aiAssistant={response.aiAssistant}
            auto={response.auto}
            userName={props.userName}
            index={index}
          />
          <ChatConversation
            response={response}
            isAiGenerating={props.isAiGenerating}
          />
        </div>
      ))}
    </div>
  );
}
