import classNames from 'classnames';
import React from 'react';
import styles from '../../TableBody/styles.module.scss';

interface PropTypes {
  email: string;
}

export function EmailDataCell(props: PropTypes) {
  return (
    <td className={classNames('pb-3', styles['table-data-cell-border-bottom'])}>
      <div className='fs-5 text-primary fw-normal'>{props.email}</div>
    </td>
  );
}
