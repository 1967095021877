import React from 'react';
import { GenericEntry } from '../..';
import { User } from '../../../../../entities/User';
import { SendEmailTrigger } from '../../../../../entities/applicant_tracking/Trigger';
import { Application } from '../../../../../entities/Application';
import { TriggerActions } from '../../../../../entities/ApplicationFeed';
import { AlertObject } from '../../../../../components/Alert';

interface PropTypes {
  application: Application;
  currentUser: User;
  entry: TriggerActions;
  isLastItemInPage: boolean;
  setAlert: (data: AlertObject) => void;
  reloadApplication: () => void;
}

export function TriggerEmailSent(props: PropTypes) {
  const trigger = props.entry.trigger as SendEmailTrigger;

  return (
    <GenericEntry
      icon='bi-envelope'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          An automatic e-mail was sent with the template{' '}
          <strong>{trigger.emailTemplateName}</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}
