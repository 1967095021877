export function isVisible(
  el: HTMLElement,
  holder: HTMLElement,
  offset: { top?: number; bottom?: number } = undefined,
): boolean {
  if (holder == null) {
    return false;
  }
  const { top, bottom } = el.getBoundingClientRect();
  const holderRect = holder.getClientRects()[0];
  if (holderRect == null) {
    return false;
  }

  const topOffset = offset?.top || 0;
  const bottomOffset = offset?.bottom || 0;

  return (
    top >= holderRect.top + topOffset &&
    bottom <= holderRect.bottom + bottomOffset
  );
}
