import React from 'react';
import { Col } from 'reactstrap';
import { IconLink } from '../../../../../../../components/IconLink';
import { SpanTooltip } from '../../../../../../../components/SpanTooltip';
import { User } from '../../../../../../../entities/User';
import { ApplicationRating } from '../../../../../../../entities/v1/applicant_tracking/ApplicationRating';
import { CandidateRatingBlockTitle } from './CandidateRatingBlockTitle';
import { CandidateStarRating } from '../CandidateStarRating';

interface PropTypes {
  applicationRating: ApplicationRating;
  currentUser: User;
  setCandidateRatingModalOpen: (isOpen: boolean) => void;
}

export function CandidateRatingHeader(props: PropTypes) {
  return (
    <div className='d-flex justify-content-between w-100 align-items-center row flex-nowrap'>
      <Col>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div className='d-flex align-items-center'>
            <CandidateRatingBlockTitle
              currentUser={props.currentUser}
              applicationRating={props.applicationRating}
            />
          </div>
          <CandidateStarRating applicationRating={props.applicationRating} />
        </div>
      </Col>
      <Col xl={2} xs={1} className='d-flex justify-content-end'>
        {props.applicationRating.user.id === props.currentUser.id && (
          <SpanTooltip
            className='me-2'
            text={
              <IconLink
                text=''
                iconClassName='bi-pencil text-info'
                className='my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  props.setCandidateRatingModalOpen(true);
                }}
              />
            }
            tooltipText='Edit'
          />
        )}
      </Col>
    </div>
  );
}
