import React from 'react';
import { ClearIndicatorProps } from 'react-select';
import { ClearIcon } from '../../ClearIcon';
import classNames from 'classnames';
import styles from './styles.module.scss';

export function ClearIndicator(props: ClearIndicatorProps) {
  return (
    <ClearIcon
      innerProps={props.innerProps}
      classNames={classNames(
        'ms-1',
        'me-2',
        'fs-6',
        props.isFocused ? styles['clear-focused'] : styles['clear'],
      )}
    />
  );
}
