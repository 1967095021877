import { NpsExplainabilityEntry } from '../../../../../entities/candidate_experience/NpsExplainabilityEntry';
import React, { useState } from 'react';
import MetricCard, {
  Body,
  Head,
  Title,
} from '../../../../../components/MetricCard';
import { NPSExplainabilityTabNavigation } from '../NPSExplainabilityTabNavigation';
import { NPSExplainabilityTopImpactTable } from './NPSExplainabilityTopImpactTable';

export interface PropTypes {
  title: string;
  badgeStyle: string;
  jobEntries: NpsExplainabilityEntry[];
  departmentEntries: NpsExplainabilityEntry[];
  tooltipText: string;
  standardNpsCalculation: boolean;
}

export function NPSExplainabilityTopImpact(props: PropTypes) {
  const [activeTab, setActiveTab] = useState('Jobs');

  return (
    <MetricCard className='mt-3 h-100'>
      <Head>
        <Title value={props.title} tooltip={props.tooltipText} />
      </Head>
      <Body>
        <NPSExplainabilityTabNavigation
          activeTab={activeTab}
          onTabClick={setActiveTab}
        />
        {activeTab === 'Jobs' && (
          <NPSExplainabilityTopImpactTable
            activeTab={activeTab}
            onTabClick={setActiveTab}
            tableDimensionTitle={'Jobs'}
            npsType={'CNPS'}
            badgeStyle={props.badgeStyle}
            entries={props.jobEntries}
            standardNpsCalculation={props.standardNpsCalculation}
          />
        )}
        {activeTab === 'Departments' && (
          <NPSExplainabilityTopImpactTable
            activeTab={activeTab}
            onTabClick={setActiveTab}
            tableDimensionTitle={'Departments'}
            npsType={'CNPS'}
            badgeStyle={props.badgeStyle}
            entries={props.departmentEntries}
            standardNpsCalculation={props.standardNpsCalculation}
          />
        )}
      </Body>
    </MetricCard>
  );
}
