import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { DepartmentList } from '../../../../entities/v1/DepartmentList';
import { HiringMemberList } from '../../../../entities/v1/HiringMemberList';
import { JobList } from '../../../../entities/v1/JobList';
import { LocationList } from '../../../../entities/v1/LocationList';
import { TriggerList } from '../../../../entities/v1/quality_of_hire/TriggerList';

interface Params {
  name?: string;
  page?: number;
  pageSize?: number;
}

interface HiringMemberParams extends Params {
  role?: string;
}

export class AnalyticsFiltersService {
  public static triggers(params: Params) {
    return ApiService.get<TriggerList>(
      '/api/v1/quality_of_hire/analytics/triggers',
      snakecaseKeys(params),
    );
  }

  public static locations(params: Params) {
    return ApiService.get<LocationList>(
      '/api/v1/quality_of_hire/analytics/locations',
      snakecaseKeys(params),
    );
  }

  public static jobs(params: Params) {
    return ApiService.get<JobList>(
      '/api/v1/quality_of_hire/analytics/jobs',
      snakecaseKeys(params),
    );
  }

  public static hiringManagers(params: Params) {
    return AnalyticsFiltersService.hiring_members({
      role: 'hiring_manager',
      ...params,
    });
  }

  public static recruiters(params: Params) {
    return AnalyticsFiltersService.hiring_members({
      role: 'recruiter',
      ...params,
    });
  }

  public static hiring_members(params: HiringMemberParams) {
    return ApiService.get<HiringMemberList>(
      '/api/v1/quality_of_hire/analytics/hiring_members',
      snakecaseKeys(params),
    );
  }

  public static departments(params: Params) {
    return ApiService.get<DepartmentList>(
      '/api/v1/quality_of_hire/analytics/departments',
      snakecaseKeys(params),
    );
  }
}
