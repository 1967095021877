import React from 'react';
import { Button } from 'reactstrap';

export function SuccessfulEvaluationScoreFooter(props: {
  onSubmit: () => void;
}) {
  return (
    <>
      <Button color='primary' onClick={props.onSubmit}>
        Finish
      </Button>
    </>
  );
}
