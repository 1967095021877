import React from 'react';
import { InterviewAutomaticNote } from '../../../../entities/InterviewAutomaticNote';
import { DeprecatedPanel } from '../../../DeprecatedPanel';
import { CollapsableText } from '../../../CollapsableText';

interface PropTypes {
  typeName: string;
  automaticNotes: InterviewAutomaticNote[];
}
export function AutomaticNotesBlock(props: PropTypes) {
  if (!props.automaticNotes.length) {
    return null;
  }

  const automaticNotes = props.automaticNotes.sort((a, b) => b.order - a.order);

  return (
    <div className='col-12'>
      <div className='row g-0 align-items-center'>
        <div className='col mt-2 ps-0'>
          <DeprecatedPanel className='bg-gray p-3 fs-5'>
            <div>
              <div>
                {automaticNotes.map((note) => (
                  <CollapsableText
                    collapseWords={50}
                    maxWords={350}
                    expandWordsStep={note.text.length}
                    text={note.text}
                    key={note.id}
                    data-testid={`NoteBlock-automatic-note-${note.id}`}
                    expandedByDefault={true}
                    buttonSize='lg'
                  />
                ))}
              </div>
            </div>
          </DeprecatedPanel>
        </div>
      </div>
    </div>
  );
}
