import classNames from 'classnames';
import React from 'react';
import { Row } from 'reactstrap';
import { Pagination } from '../Pagination';

interface PropTypes {
  /** Current page number. */
  page: number;
  /** Number of entries per page. */
  pageSize: number;
  /** Total number of entries. */
  totalRows: number;
  /** Number of pages to display. */
  visiblePageCount?: number;
  disabled?: boolean;
  onPageChanged: (number: number) => void;
  className?: string;
}

interface PaginationCountProps {
  currentPage: number;
  total: number;
  pageSize: number;
  countClassName?: string;
}

export function PaginationCount(props: PaginationCountProps) {
  return (
    <div className='col page-entries mb-0'>
      <span className='d-none d-sm-inline'>Showing </span>
      <span className={props.countClassName}>
        {(props.currentPage - 1) * props.pageSize + 1}
        &nbsp;-&nbsp;
        {Math.min(props.currentPage * props.pageSize, props.total)}
      </span>{' '}
      of <span className={props.countClassName}>{props.total}</span>
    </div>
  );
}

export function PanelFooter(props: PropTypes) {
  return (
    <Row
      className={classNames(
        'align-items-center',
        'fs-6',
        'mt-2',
        props.className,
      )}
    >
      {!props.disabled && (
        <PaginationCount
          currentPage={props.page}
          total={props.totalRows}
          pageSize={props.pageSize}
          countClassName='fw-bold'
        />
      )}
      <div className='col'>
        <Pagination
          page={props.page}
          pageCount={Math.ceil(props.totalRows / props.pageSize)}
          visiblePageCount={props.visiblePageCount ?? 3}
          disabled={props.disabled}
          onPageClicked={props.onPageChanged}
        />
      </div>
    </Row>
  );
}
