import React from 'react';
import { EmailDomain } from '../../../../../entities/EmailDomain';
import { EmptyState } from '../../../../../components/EmptyState';
import { DnsRecord } from './DnsRecord';
import { AlertType } from '../../../../../components/Alert';

export interface PropTypes {
  emailDomain: EmailDomain;
  onAlert: (message: string, alertType: AlertType) => void;
}

export function DnsTable(props: PropTypes) {
  return (
    <>
      {props.emailDomain?.dkim_host != null ? (
        <table className='table table-borderless text-break'>
          <thead>
            <tr className='fs-6 text-dark-200'>
              <th className='col-2'></th>
              <th className='col-3'>Hostname</th>
              <th className='col-1'>Type</th>
              <th className='col-6'>Value</th>
            </tr>
          </thead>
          <tbody>
            <DnsRecord
              name={'DKIM'}
              host={props.emailDomain.dkim_host}
              type={'TXT'}
              value={props.emailDomain.dkim_value}
              verifiedAt={props.emailDomain.dkim_verified_at}
              onAlert={props.onAlert}
            />
            <DnsRecord
              name={'Return-Path'}
              host={props.emailDomain.return_path_host}
              type={'CNAME'}
              value={props.emailDomain.return_path_value}
              verifiedAt={props.emailDomain.return_path_verified_at}
              onAlert={props.onAlert}
            />
          </tbody>
        </table>
      ) : (
        <EmptyState
          title='No DNS settings yet'
          text='Configure your domain settings and generate a value to start.'
        />
      )}
    </>
  );
}
