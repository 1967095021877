import React from 'react';
import GmailLogoSrc from '../../../../images/logos/google_gmail.svg';
import OutlookLogoSrc from '../../../../images/logos/microsoft_outlook.svg';
import { EmailProvider } from '../../../../enums/IntegrationProvider/EmailProvider';
import ScreenloopLogoSrc from '../../../../images/logo.png';
import { EmailOption } from '../../../../entities/EmailOption';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';

interface PropTypes {
  emailOptions: EmailOption[];
}

function getLogoSrc(emailProvider: EmailProvider) {
  switch (emailProvider) {
    case EmailProvider.Gmail:
      return GmailLogoSrc;
    case EmailProvider.Outlook:
      return OutlookLogoSrc;
    default:
      return null;
  }
}

function EmailProviderName(props: { emailProvider: EmailProvider }) {
  switch (props.emailProvider) {
    case EmailProvider.Gmail:
      return (
        <p>
          Google
          <br />
          Gmail
        </p>
      );
    case EmailProvider.Outlook:
      return (
        <p>
          Microsoft
          <br />
          Outlook
        </p>
      );
    default:
      return null;
  }
}

function ScreenloopLogo() {
  return (
    <img
      src={ScreenloopLogoSrc}
      className={classNames(styles['screenloop-logo'], 'mb-6h')}
    />
  );
}

function EmailOption(props: { emailOption: EmailOption }) {
  const logoSrc = getLogoSrc(props.emailOption.provider);

  return (
    <Col className={classNames('col-lg-4 col-5 px-0')}>
      <a href={props.emailOption.authorizeUrl}>
        <div
          className={classNames(
            styles['email-option'],
            'border border-2 border-blue-gray-700 rounded-2 py-3 px-4',
          )}
        >
          <img
            src={logoSrc}
            className={classNames(styles['email-logo'], 'mb-2h')}
          />
          <div className={classNames(styles['provider-name'])}>
            <EmailProviderName emailProvider={props.emailOption.provider} />
          </div>
        </div>
      </a>
    </Col>
  );
}

function EmailOptions(props: PropTypes) {
  return (
    <Row className={'justify-content-center gap-3 my-6h'}>
      {props.emailOptions.map((emailOption) => (
        <EmailOption key={emailOption.provider} emailOption={emailOption} />
      ))}
    </Row>
  );
}

export default function EmailIntegrationsPage(props: PropTypes) {
  return (
    <div className={classNames(styles['wrapper'], 'bg-white')}>
      <div className='border border-gray-300 rounded-3 text-center p-5'>
        <ScreenloopLogo />
        <h4 className='fw-normal'>
          Screenloop is trying to securely access your email account so it can:
        </h4>
        <div
          className={classNames(
            styles['bullet-points'],
            'd-flex mt-4 text-dark-200 justify-content-center',
          )}
        >
          <ul>
            <li>Show emails exchanged with candidates</li>
            <li>Send emails to candidates</li>
          </ul>
        </div>
        <EmailOptions {...props} />
      </div>
    </div>
  );
}
