import cloneDeep from 'lodash.clonedeep';
import React, { useEffect, useState } from 'react';
import { defaultDateRange } from '../../../components/candidate_experience/Filters/filterState';
import { DateFilter } from '../../../components/DateRangePicker';
import { SelectOption } from '../../../components/Select';
import { QualityOfHireAnalytics } from '../../../entities/quality_of_hire/QualityOfHireAnalytics';
import { AnalyticsService } from '../../../services/quality_of_hire/AnalyticsService';
import { Filters } from './Filters';
import { AnalyticsSection } from './AnalyticsSection';
import { readHash, writeHash } from '../../../utils/location';

export interface FilterState {
  dateRange: DateFilter;
  selectedFilter: SelectOption;
  selectedFilterData: SelectOption[];
  selectedTriggers: SelectOption[];
  selectedLocations: SelectOption[];
}

const DEFAULT_FILTER_STATE: FilterState = {
  selectedFilter: { label: 'Job', value: 'job' },
  dateRange: defaultDateRange(),
  selectedFilterData: [],
  selectedTriggers: [],
  selectedLocations: [],
};

function hashedFilterState(): FilterState {
  return readHash<FilterState>(DEFAULT_FILTER_STATE);
}

function selectedFilterValues(
  filterData: FilterState,
  filterType: string,
): string[] {
  return filterType === filterData.selectedFilter.value
    ? filterData.selectedFilterData.map((option) => option.value)
    : [];
}

export default function HiringManagerEmployeeEvalPage() {
  const [analytics, setAnalytics] = useState<QualityOfHireAnalytics>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [appliedHiddenFilters, setAppliedHiddenFilters] = useState(0);

  const [appliedFilterState, setAppliedFilterState] = useState(
    hashedFilterState(),
  );

  const [draftFilterState, setDraftFilterState] = useState(
    cloneDeep(appliedFilterState),
  );

  function handleFilterUpdate(
    name: keyof FilterState,
    value: FilterState[keyof FilterState],
  ) {
    const newFilterState = cloneDeep(draftFilterState);
    newFilterState[name] = value as any; // Whatever.
    if (name === 'selectedFilter') {
      if (
        newFilterState.selectedFilter.value ===
        appliedFilterState.selectedFilter.value
      ) {
        newFilterState.selectedFilterData =
          appliedFilterState.selectedFilterData;
      } else {
        newFilterState.selectedFilterData = [];
      }
    }
    setDraftFilterState(newFilterState);
  }

  function handleResetClick() {
    setDraftFilterState(DEFAULT_FILTER_STATE);
    setAppliedFilterState(DEFAULT_FILTER_STATE);
    setAppliedHiddenFilters(0);
  }

  function handleApplyClick(appliedHiddenFilters: number) {
    setAppliedFilterState(draftFilterState);
    setAppliedHiddenFilters(appliedHiddenFilters);
  }

  function loadAnalytics() {
    setAnalytics(null);
    setIsLoading(true);

    AnalyticsService.get(
      appliedFilterState.selectedFilter.value,
      selectedFilterValues(appliedFilterState, 'job'),
      selectedFilterValues(appliedFilterState, 'department'),
      selectedFilterValues(appliedFilterState, 'hiring_manager'),
      selectedFilterValues(appliedFilterState, 'recruiter'),
      appliedFilterState.dateRange,
      appliedFilterState.selectedTriggers.map((trigger) => trigger.value),
      appliedFilterState.selectedLocations.map((location) => location.label),
    ).then((analytics) => {
      setAnalytics(analytics);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    writeHash(appliedFilterState);
    loadAnalytics();
  }, [appliedFilterState]);

  useEffect(() => {
    function handlePopState() {
      setAppliedFilterState(hashedFilterState());
    }

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  return (
    <main>
      <div className='d-flex align-items-start mb-4'>
        <h1 className='mb-0 me-auto'>Quality of Hire</h1>
      </div>
      <Filters
        filterState={draftFilterState}
        onFilterUpdate={handleFilterUpdate}
        onResetClick={handleResetClick}
        onApplyClick={handleApplyClick}
        appliedHiddenFilters={appliedHiddenFilters}
      />
      <AnalyticsSection
        analytics={analytics}
        filterState={appliedFilterState}
        loading={isLoading}
      />
    </main>
  );
}
