export function buildLabelledDemographicOptions(demographics: {
  [index: string]: string;
}) {
  const labelledOptionsList = [];
  Object.keys(demographics).forEach((key) => {
    const labelledOption = { label: demographics[key], value: key };
    labelledOptionsList.push(labelledOption);
  });
  return labelledOptionsList;
}
