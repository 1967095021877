import React from 'react';

import { EditorState } from 'lexical';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { SingleLinePlugin } from '../../../plugins/SingleLinePlugin';
import { ExportPlainTextPlugin } from '../../../plugins/ExportPlainTextlPlugin';
import FloatingLinkEditorPlugin from '../../../plugins/FloatingLinkEditorPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';

interface PropTypes {
  externalPlugins?: JSX.Element[];
  disabled?: boolean;
  onChangeJson?: (state: any) => void;
  onChangeText?: (state: any) => void;
  floatingAnchorElem: HTMLDivElement | null;
  isLinkEditMode: boolean;
  setIsLinkEditMode: (v: boolean) => void;
}

export function PluginLoader(props: PropTypes) {
  return (
    <>
      {props.externalPlugins}
      {!props.disabled && (
        <>
          <SingleLinePlugin />
          <ExportPlainTextPlugin onChangeText={props.onChangeText} />
          {props.floatingAnchorElem && (
            <FloatingLinkEditorPlugin
              anchorElem={props.floatingAnchorElem}
              isLinkEditMode={props.isLinkEditMode}
              setIsLinkEditMode={props.setIsLinkEditMode}
            />
          )}
          <LinkPlugin />
          <OnChangePlugin
            ignoreHistoryMergeTagChange={true}
            ignoreSelectionChange={true}
            onChange={(editorState: EditorState) => {
              const updatedState = JSON.stringify(editorState.toJSON());
              props.onChangeJson?.(updatedState);
            }}
          />
        </>
      )}
    </>
  );
}
