import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CloseEvaluationModal } from '../CloseEvaluationModal';

interface PropTypes {
  sessionId: number;
}

export function CloseButton(props: PropTypes) {
  const [closeEvaluationModalVisible, setCloseEvaluationModalVisible] =
    useState<boolean>(false);

  return (
    <>
      <Button
        className='me-2'
        color='secondary'
        onClick={() => setCloseEvaluationModalVisible(true)}
      >
        Close
      </Button>
      <CloseEvaluationModal
        visible={closeEvaluationModalVisible}
        onClose={() => setCloseEvaluationModalVisible(false)}
        onLeaveEvaluation={() => {
          window.location.href = `/training/sessions/${props.sessionId}`;
        }}
      />
    </>
  );
}
