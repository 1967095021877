import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../../components/FormFieldHeader';
import { SelectFormHook } from '../../../../../components/SelectFormHook';
import { Control, useWatch } from 'react-hook-form';
import { UpsertStagePlanTemplateDto } from '../../DTOs/UpsertStagePlanTemplateDto';
import { SelectOption } from '../../../../../components/Select';
import styles from '../styles.module.scss';
import { ScorecardTemplate } from '../../../../../entities/v1/applicant_tracking/ScorecardTemplate';
import { PreviewButton } from '../../../../../components/PreviewButton';

const FIELD_NAME = 'scorecardTemplateId';

interface PropTypes {
  control: Control<UpsertStagePlanTemplateDto, void>;
  scoreCardTemplates: ScorecardTemplate[];
  enabled: boolean;
  formResetField: (controllerName: string, options: any) => void;
}

function mapScoreCardTemplatesToOptions(
  scoreCardTemplates: ScorecardTemplate[],
): SelectOption[] {
  return scoreCardTemplates.map((scoreCardTemplate: ScorecardTemplate) => {
    return {
      value: scoreCardTemplate.id.toString(),
      label: scoreCardTemplate.surveyTemplate.name,
    };
  });
}

export function ScoreCardTemplateInput(props: PropTypes) {
  const [selectedTemplate, setSelectedTemplate] =
    useState<ScorecardTemplate>(null);
  const selectedTemplateId = useWatch({
    control: props.control,
    name: FIELD_NAME,
  });

  useEffect(() => {
    if (selectedTemplateId) {
      const selectedTemplate = props.scoreCardTemplates.find(
        (interviewKitTemplate) => interviewKitTemplate.id == selectedTemplateId,
      );
      setSelectedTemplate(selectedTemplate);
    } else {
      setSelectedTemplate(null);
    }
  }, [selectedTemplateId]);

  return (
    <div className='d-flex'>
      <Row className={classNames('mt-3h w-100', styles['input-fields'])}>
        <FormFieldHeader
          fieldName='Interview Scorecard'
          isRequired={props.enabled}
          classNames='fw-semibold mb-3 pb-1'
          fontSize='fs-3'
        />
        <SelectFormHook
          disabled={!props.enabled}
          placeholder={'Select scorecard'}
          options={mapScoreCardTemplatesToOptions(props.scoreCardTemplates)}
          formHookContext={{
            controllerName: FIELD_NAME,
            formControl: props.control,
            required: props.enabled,
          }}
          formResetField={props.formResetField}
        />
      </Row>
      <div className='align-self-end'>
        {selectedTemplate && (
          <PreviewButton
            className='ms-2'
            onClick={() =>
              window.open(
                `/organization_settings/scorecard_templates/${selectedTemplateId}/preview`,
                '_blank',
              )
            }
          />
        )}
      </div>
    </div>
  );
}
