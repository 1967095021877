import React from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { SurveyMetricBadge } from './SurveyMetricBadge';
import { SurveyMetrics } from '../../../entities/survey_engine/SurveyMetrics';

interface PropTypes {
  surveyMetrics: SurveyMetrics;
}

export function SurveyMetricsContent(props: PropTypes) {
  const metrics = [
    {
      name: 'Sent',
      value: props.surveyMetrics?.totalSent.toString(),
      badgeClass: styles.grayBadge,
    },
    {
      name: 'Completed',
      value: props.surveyMetrics?.totalCompleted.toString(),
      badgeClass: styles.greenBadge,
    },
    {
      name: 'Completion Rate',
      value: `${Number(props.surveyMetrics?.completionRate * 100).toFixed(0)}%`,
      trend: props.surveyMetrics?.completionRateTrend,
      badgeClass: styles.orangeBadge,
    },
  ];

  return (
    <Row
      className={classNames(
        'd-flex',
        'align-items-center',
        'justify-content-start',
        'w-100',
        styles.surveyMetricRow,
      )}
    >
      {metrics.map((metric, index) => (
        <Col key={index} className={classNames(styles.surveyMetric)}>
          <SurveyMetricBadge
            metricName={metric.name}
            metricValue={metric.value}
            metricTrend={metric.trend}
            badgeClassname={metric.badgeClass}
          />
        </Col>
      ))}
    </Row>
  );
}
