import React from 'react';
import { SurveyLayout } from '../../../../components/survey_engine/SurveyLayout';
import { SurveyPanel } from '../../../../components/survey_engine/SurveyPanel';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Survey } from '../../../../entities/survey_engine/Survey';
import { humanize } from '../../../../utils/humanize';

interface PropTypes {
  survey: Survey;
  finishScreenImage?: string;
  headingStyle?: React.CSSProperties;
}

export function SurveyFinalScreen(props: PropTypes) {
  return (
    <SurveyLayout companyInfo={props.survey.company_info}>
      <SurveyPanel className='text-center'>
        <img className='mt-5 w-25' src={props.finishScreenImage} />
        <h1 className='mt-3' style={props.headingStyle}>
          Your feedback was submitted!
        </h1>
        <p className={classNames('mt-1 mb-4 mx-auto', styles.text)}>
          Thank you for taking the time to complete this survey. We will review
          your feedback, and look to improve and continue to offer the best{' '}
          {humanize(props.survey.target).toLowerCase()} experience possible.
        </p>
      </SurveyPanel>
    </SurveyLayout>
  );
}
