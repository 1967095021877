import React from 'react';
import Chart from 'react-chartjs-2';
import { ChartProps } from 'react-chartjs-2/dist/types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { BreakdownValues } from '../BreakDownValues/index';
import { SelectOption } from '../../../components/Select';
import { ChartType } from 'chart.js';

interface DoughnutChartSectionPropTypes<T extends ChartType> {
  chartOptions: ChartProps<T>;
  breakdownValues: { [key: number]: number };
  colors: { [index: string]: string };
  interviewers: SelectOption[];
  score: number;
}

function DoughnutChartSection<T extends ChartType>(
  props: DoughnutChartSectionPropTypes<T>,
) {
  return (
    <>
      <div className='d-flex align-items-center justify-content-center'>
        {props.chartOptions && (
          <div
            className={classNames(
              'w-50',
              styles.chartContainer,
              styles.firstRowDoughnutChart,
            )}
          >
            <Chart {...props.chartOptions} />
          </div>
        )}
        <div className='ms-3'>
          <div
            className={classNames(
              styles.breakdowntitle,
              'mt-3',
              'fw-semibold',
              'fs-6',
            )}
          >
            % Breakdown
          </div>
          <ul className={classNames(styles.breakdownList, 'p-0')}>
            <BreakdownValues
              breakdownValues={props.breakdownValues}
              colors={props.colors}
              interviewers={props.interviewers}
              score={props.score}
            />
          </ul>
        </div>
      </div>
    </>
  );
}

interface DefaultChartSectionPropTypes<T extends ChartType> {
  chartOptions: ChartProps<T>;
  chartType: string;
}

function DefaultChartSection<T extends ChartType>(
  props: DefaultChartSectionPropTypes<T>,
) {
  let stylesfirstRowBarChart = styles.firstRowBarChart;

  if (props.chartType !== 'first row bar') {
    stylesfirstRowBarChart = null;
  }

  return (
    <>
      {props.chartOptions && (
        <div
          className={classNames(styles.chartContainer, stylesfirstRowBarChart)}
        >
          <Chart {...props.chartOptions} />
        </div>
      )}
    </>
  );
}

interface PropTypes<T extends ChartType> {
  chartType: string;
  chartOptions: ChartProps<T>;
  breakdownValues: { [key: number]: number };
  colors: { [index: string]: string };
  interviewers: SelectOption[];
  score: number;
}

export function ChartSection<T extends ChartType>(props: PropTypes<T>) {
  return (
    <>
      {props.chartType === 'doughnut' ? (
        <DoughnutChartSection
          chartOptions={props.chartOptions}
          breakdownValues={props.breakdownValues}
          colors={props.colors}
          interviewers={props.interviewers}
          score={props.score}
        />
      ) : (
        <DefaultChartSection
          chartOptions={props.chartOptions}
          chartType={props.chartType}
        />
      )}
    </>
  );
}
