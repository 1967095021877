import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { AutomaticQuestionList } from '../../../../entities/v1/interview_intelligence/AutomaticQuestion';

export class AutomaticQuestionService {
  public static list(
    interviewId: number,
    expand?: string[],
  ): Promise<AutomaticQuestionList> {
    return ApiService.get<AutomaticQuestionList>(
      `/api/v1/interview_intelligence/automatic_questions`,
      snakecaseKeys({ interviewId: interviewId, expand: expand }),
    );
  }
}
