import React, { ReactNode } from 'react';
import { LoadingSpinner } from '../../LoadingSpinner';
import { WidgetEmptyStateContent } from '../../../containers/survey_engine/WidgetEmptyStateContent';

interface PropTypes {
  isLoading: boolean;
  isEmpty: boolean;
  children: ReactNode;
}

export function LazyContent(props: PropTypes) {
  if (props.isLoading) return <LoadingSpinner />;

  if (props.isEmpty) return <WidgetEmptyStateContent />;

  return <>{props.children}</>;
}
