import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom';
import { FilterStates } from '../../../components/candidate_experience/Filters/filterState';
import { BenchmarkType } from '../../../entities/candidate_experience/BenchmarkType';
import { CandidatePulseAnalytics } from './CandidatePulseAnalytics';
import {
  setFilters,
  generateQueryString,
} from '../../../components/candidate_experience/Filters/urlHelper';
import { NpsExplainabilityAttribute } from '../../../entities/candidate_experience/NpsExplainabilityAttribute';
import { FilterState } from '../../../components/candidate_experience/Filters/update';

interface PropTypes {
  organization_name: string;
  benchmark_types: BenchmarkType[];
  feature_flags: {
    pulse_standard_nps_calculation: boolean;
  };
  candidate_demographics: {
    age_ranges: { [index: string]: string };
    ethnicities: { [index: string]: string };
    genders: { [index: string]: string };
  };
  candidate_demographic_questions_enabled: boolean;
  activeTab: string;
  nps_explainability_attributes: NpsExplainabilityAttribute[];
}

function StateHolder(props: PropTypes) {
  const initFilters: FilterState = {
    benchmarkTypes: props.benchmark_types,
    ethnicities: props.candidate_demographics.ethnicities,
    genders: props.candidate_demographics.genders,
    age_ranges: props.candidate_demographics.age_ranges,
    npsAttributes: props.nps_explainability_attributes,
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterStates, setFilterStates] = useState<FilterStates>(
    setFilters(initFilters, searchParams),
  );
  const [moreFiltersShown, setMoreFiltersShown] = useState(false);

  useEffect(() => {
    setSearchParams(generateQueryString(filterStates));
  }, [filterStates]);

  function handleApplyFilter(filterStates: FilterStates, moreFilters: boolean) {
    setFilterStates(filterStates);
    setMoreFiltersShown(moreFilters);
  }

  return (
    <CandidatePulseAnalytics
      organizationName={props.organization_name}
      filterStates={filterStates}
      moreFiltersShown={moreFiltersShown}
      onApplyFilter={handleApplyFilter}
      candidateDemographicQuestionsEnabled={
        props.candidate_demographic_questions_enabled
      }
      activeTab={props.activeTab}
      pulseStandardNpsCalculation={
        props.feature_flags.pulse_standard_nps_calculation
      }
    />
  );
}

export default function CandidatePulsePage(props: PropTypes) {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/analytics/candidate_experience/overview'
          element={<StateHolder {...props} activeTab={'Overview'} />}
        />
        <Route
          path='/analytics/candidate_experience/cnps_explainability'
          element={<StateHolder {...props} activeTab={'CNPS Explainability'} />}
        />
        <Route
          path='/analytics/candidate_experience/follow-up_questions'
          element={<StateHolder {...props} activeTab={'Follow-up Questions'} />}
        />
        <Route
          path='/analytics/candidate_experience/team_leaderboard'
          element={<StateHolder {...props} activeTab={'Team Leaderboard'} />}
        />
      </Routes>
    </BrowserRouter>
  );
}
