import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { RejectReasonService } from '../../../../services/applicant_tracking/RejectReasonService';
import { RejectReason } from '../../../../entities/applicant_tracking/RejectReason';
import { RejectionForm } from './RejectionForm';

interface ModalProps {
  applicationIds: number[];
  description: React.ReactNode;
  filterRejectedBy?: string;
  preSelectedRejectReasonId?: number;
  onClose: (succeeded?: boolean, rejectedReason?: string) => void;
  setPreSelectedRejectReasonId?: (preSelectedRejectReasonId: number) => void;
}

export function ModalBody(props: ModalProps) {
  const [rejectReasons, setRejectReasons] = useState<RejectReason[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    RejectReasonService.list().then((rejectReasons: RejectReason[]) => {
      setRejectReasons(rejectReasons);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='fw-semibold'>
          <div className='fs-5'>{props.description}</div>
          <RejectionForm
            applicationIds={props.applicationIds}
            rejectReasons={rejectReasons}
            filterRejectedBy={props.filterRejectedBy}
            preSelectedRejectReasonId={props.preSelectedRejectReasonId}
            onClose={props.onClose}
            setPreSelectedRejectReasonId={props.setPreSelectedRejectReasonId}
          />
        </div>
      )}
    </>
  );
}
