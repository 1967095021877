import React from 'react';
import CopyButton from './CopyButton';
import { AlertType } from '../../../../../../components/Alert';

export interface PropTypes {
  name: string;
  host: string;
  type: string;
  value: string;
  verifiedAt: Date;
  onAlert: (message: string, alertType: AlertType) => void;
}

export function DnsRecord(props: PropTypes) {
  const isActive = props.verifiedAt !== null;

  return (
    <tr className='fs-5'>
      <td className='text-primary fw-bold'>
        {props.name}
        <br />
        <div className='d-inline-flex bg-light-success rounded-pill px-2h py-2 fw-normal'>
          {isActive ? 'Active' : 'Inactive'}
        </div>
      </td>
      <td>
        <div className='d-flex justify-content-between'>
          {props.host}
          <CopyButton
            content={props.host}
            contentType={`${props.name} Hostname`}
            onAlert={props.onAlert}
          />
        </div>
      </td>
      <td>{props.type}</td>
      <td>
        <div className='d-flex justify-content-between'>
          {props.value}
          <CopyButton
            content={props.value}
            contentType={`${props.name} Value`}
            onAlert={props.onAlert}
          />
        </div>
      </td>
    </tr>
  );
}
