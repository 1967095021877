import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface PropTypes {
  title: string;
  imageSrc?: string;
  text?: string;
  size: Size;
  children?: React.ReactNode;
}

export type Size = 'small' | 'medium';

export function EmptyTab(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex',
        'justify-content-center',
        { 'my-5': props.size === 'medium' },
        { 'my-xxxl-3': props.size === 'small' },
        props.imageSrc ? styles.container : null,
      )}
    >
      <div
        className={classNames(
          'text-center',
          { 'w-100': props.size === 'medium' },
          {
            'mx-3 d-flex gap-1 justify-content-center': props.size === 'small',
          },
          styles.container,
        )}
      >
        {props.imageSrc && (
          <img
            src={props.imageSrc}
            className={classNames(
              'img-fluid',
              `${
                props.size === 'medium'
                  ? styles.mediumillustration
                  : styles.smallillustration
              }`,
            )}
          />
        )}
        {props.size === 'medium' ? (
          <div>
            <h3 className={classNames('fw-bold', 'mt-4')}>{props.title}</h3>
            <p className='mt-3'>{props.text}</p>
          </div>
        ) : (
          <div>
            <h4 className='fw-bold'>{props.title}</h4>
            <p className='mt-2'>{props.text}</p>
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
}
