import React, { useEffect, useState } from 'react';
import { CloseableModal } from '../../../../CloseableModal';
import { ConfirmDeleteStageModalBody } from './ConfirmDeleteStageModalBody';
import { DeleteStageWithCandidatesModalBody } from './DeleteStageWithCandidatesModalBody';
import { JobStageTemplateInput } from '../../../../../containers/PlanTemplates/SetupPlanTemplatePage';
import { PlanTemplate } from '../../../../../entities/applicant_tracking/PlanTemplate';
import { PlanTemplateService } from '../../../../../services/applicant_tracking/PlanTemplateService';
import { JobStageJob } from '../../../../../entities/applicant_tracking/JobStageJob';
import { LoadingSpinner } from '../../../../LoadingSpinner';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  isOpen: boolean;
  stage: JobStageTemplateInput;
  jobStages: JobStageTemplateInput[];
  planTemplate: PlanTemplate;
  onClose: (state?: boolean, moveDestinationStageName?: string) => void;
}

function ModalBody(
  props: PropTypes & {
    isLoading: boolean;
    jobStageJobs: JobStageJob[];
  },
) {
  if (props.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {props.jobStageJobs.length > 0 ? (
        <DeleteStageWithCandidatesModalBody
          stage={props.stage}
          jobStages={props.jobStages}
          jobStageJobs={props.jobStageJobs}
          onClose={props.onClose}
        />
      ) : (
        <ConfirmDeleteStageModalBody
          stageName={props.stage.name}
          onClose={props.onClose}
        />
      )}
    </>
  );
}

export function DeleteStageConfirmationModal(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(true);
  const [jobStageJobs, setJobStageJob] = useState<JobStageJob[]>([]);

  useEffect(() => {
    if (props.isOpen) {
      if (props.planTemplate && props.stage?.id != null) {
        PlanTemplateService.listJobStageJobs({
          stagePlanTemplateId: props.stage?.id,
        }).then((response: JobStageJob[]) => {
          setJobStageJob(response);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    }
  }, [props.isOpen]);

  return (
    <CloseableModal
      className={classNames('modal-dialog-centered', styles['modal'])}
      onClose={() => props.onClose(false)}
      isOpen={props.isOpen}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle={'Remove Job Workflow Stage'}
      bodyChildren={
        <ModalBody
          {...props}
          isLoading={isLoading}
          jobStageJobs={jobStageJobs}
        />
      }
    />
  );
}
