import { useQuery } from 'react-query';
import {
  ActionItemService,
  ListParams,
} from '../../../services/v1/applicant_tracking/ActionItemService';

export const useActionItems = (params: ListParams) =>
  useQuery({
    queryKey: ['action_items', params],
    queryFn: () => ActionItemService.list(params),
  });
