import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Interview } from '../../../../entities/Interview';
import { SummaryLength } from './SummaryLength';
import { SummaryTopics } from './SummaryTopics';
import { SummaryPreview } from './SummaryPreview';
import { generateAiResponse } from './generateAiResponse';
import { InterviewService } from '../../../../services/InterviewService';
import { Summary } from '../../../../entities/Summary';
import { defaultTopics } from './defaultTopics';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  interview: Interview;
  onCustomizing: (customizing: boolean) => void;
  onSummaryChange: (summary: Summary) => void;
}

export type SummaryLengthOption = 'short' | 'medium' | 'long';

export function CustomizeSummary(props: PropTypes) {
  const [selectedLength, setSelectedLength] =
    useState<SummaryLengthOption>('medium');
  const [topics, setTopics] = useState<string[]>(
    props.interview.summary.topics.length > 0
      ? props.interview.summary.topics.map((t) => t.name)
      : defaultTopics,
  );
  const [customSummary, setCustomSummary] = useState<string>();
  const [isAiGenerating, setIsAiGenerating] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  async function handleOnSaveAndReplaceClick() {
    await InterviewService.customSummary(
      props.interview.id,
      customSummary,
      topics,
    );

    const summary: Summary = {
      text: customSummary,
      topics: topics.map((name) => ({ name })),
    };
    props.onSummaryChange(summary);
    props.onCustomizing(false);
  }

  function handleOnCancelClick() {
    props.onCustomizing(false);
  }
  function handleLengthSelection(length: SummaryLengthOption) {
    setSelectedLength(length);
  }

  const handleAskAI = async () => {
    await generateAiResponse(
      props.interview.id,
      topics,
      selectedLength,
      setCustomSummary,
      setIsAiGenerating,
      setIsErrorMessage,
    );
  };

  return (
    <div className={classNames('pt-4', styles.customizeSummary)}>
      <div className='pb-1'>
        <div className='d-flex flex-column gap-2h'>
          <SummaryTopics
            topics={topics}
            onChange={setTopics}
            isAiGenerating={isAiGenerating}
          />
        </div>
        <div className='d-flex flex-column gap-2h pt-4'>
          <SummaryLength
            selectedLength={selectedLength}
            onChange={handleLengthSelection}
            isAiGenerating={isAiGenerating}
          />
        </div>
        <div className='d-flex flex-column gap-2h w-100 pt-4'>
          <SummaryPreview
            handleAskAI={handleAskAI}
            summary={customSummary}
            isAiGenerating={isAiGenerating}
            topics={topics}
          />
        </div>
      </div>
      <div
        className={classNames('d-flex flex-column gap-2h pt-4', styles.footer)}
      >
        <div className='d-flex flex-row-reverse'>
          <Button
            size='md'
            color='primary'
            onClick={handleOnSaveAndReplaceClick}
            disabled={
              !customSummary?.trim() || isAiGenerating || isErrorMessage
            }
          >
            Save and Replace
          </Button>
          <Button
            size='md'
            color='danger'
            onClick={handleOnCancelClick}
            className='me-3'
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
