import React from 'react';
import { Control } from 'react-hook-form';
import { UpsertStagePlanTemplateDto } from '../../DTOs/UpsertStagePlanTemplateDto';
import { CustomizationStageToggle } from '../CustomizationStageToggle';

interface PropTypes {
  disabled: boolean;
  formControl: Control<UpsertStagePlanTemplateDto, void>;
}

export function BulkReviewToggle(props: PropTypes) {
  return (
    <CustomizationStageToggle
      controllerName='bulkReviewEnabled'
      disabled={props.disabled}
      formControl={props.formControl}
      title='Does this stage include bulk candidate review process?'
      subTitle='Enables the option to bulk review all candidates in this stage from the job pipeline view'
    />
  );
}
