import { Interview } from '../../../../entities/Interview';
import React, { useState } from 'react';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { VideoModal } from '../../../../components/VideoModal';
import { KeyMoment } from '../../../../entities/KeyMoment';
import { KeyMomentBox } from '../../../../components/KeyMoments/KeyMomentBox';
import { CardEmptyState } from '../CardEmptyState';
import styles from './styles.module.scss';
import classNames from 'classnames';

export function KeyMomentsCard(props: { interview: Interview }) {
  const [interviewMarkerOffset, setInterviewMarkerOffset] = useState<number>();
  const [videoOpen, setVideoOpen] = useState<boolean>();

  return (
    <DeprecatedPanel
      additionalInnerContainerClassNames='p-4 h-100'
      className='h-100'
    >
      <VideoModal
        interviewMarkerOffset={interviewMarkerOffset}
        isOpen={videoOpen}
        setIsOpen={setVideoOpen}
        media={props.interview.media}
      />
      <h3>Key Moments</h3>
      {props.interview.keyMoments != null &&
      props.interview.keyMoments.length !== 0 ? (
        <div className={classNames(styles.keyMomentsContainer)}>
          {props.interview.keyMoments.map(
            (keyMoment: KeyMoment, index: number) => (
              <KeyMomentBox
                className='mb-3 w-100'
                onSelectTimestamp={(t) => {
                  setInterviewMarkerOffset(t);
                  setVideoOpen(true);
                }}
                key={index}
                keyMoment={keyMoment}
              />
            ),
          )}
        </div>
      ) : (
        <CardEmptyState />
      )}
    </DeprecatedPanel>
  );
}
