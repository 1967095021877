import { InterviewSidekick } from '../../entities/InterviewSidekick';
import { ApiService } from '../ApiService';

export class SidekickService {
  public static updateInterviewQuestion(
    interviewId: number,
    interviewQuestionId: string,
  ): Promise<InterviewSidekick> {
    return ApiService.post<InterviewSidekick>(
      '/interviews/' + interviewId + '/sidekick/interview_markers/',
      {
        interview_marker: {
          interview_stage_question_id: interviewQuestionId,
          kind: 'question',
        },
      },
    );
  }

  public static updateScorecardAttribute(
    interviewId: number,
    scorecardAttributeId: string,
    score?: number,
  ): Promise<InterviewSidekick> {
    return ApiService.post<InterviewSidekick>(
      '/interviews/' + interviewId + '/sidekick/interview_markers/',
      {
        interview_marker: {
          scorecard_attribute_id: scorecardAttributeId,
          kind: 'scorecard_attribute',
          score: score,
        },
      },
    );
  }

  public static createInterviewMarker(
    interviewId: number,
    kind: string,
    text: string,
  ): Promise<InterviewSidekick> {
    return ApiService.post<InterviewSidekick>(
      '/interviews/' + interviewId + '/sidekick/interview_markers/',
      {
        interview_marker: {
          kind: kind,
          note: {
            text: text,
          },
        },
      },
    );
  }
}
