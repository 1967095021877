const KEY_ERRORS_TO_IGNORE = [
  'orderedProducts',
  'orderedProductsSpecs',
  'postingDetails',
];

export function getErrorMessage(errors: Record<string, string>): string {
  let errorMessage = '';
  try {
    const keys = Object.keys(errors).filter(
      (x) => !KEY_ERRORS_TO_IGNORE.includes(x),
    );

    errorMessage = keys.map((key) => errors[key]).join('.');
  } catch (e: any) {
    console.error(e);
    // When this happens means that vonq didn't return any recognized error.
  }

  return (
    errorMessage ||
    'Your post details form contains errors. Review it and try again.'
  );
}
