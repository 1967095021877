import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { HorizontalCarouselItems } from './HorizontalCarouselItems';
import { PaginatedButtons } from './PaginatedButtons';
import styles from './styles.module.scss';

interface PropTypes {
  elements: JSX.Element[];
  border: boolean;
  buttonColor: string;
  maxElements?: number;
  classNames?: string;
  innerClassNames?: string;
  allowHorizontalScroll?: boolean;
  hideScrollbar?: boolean;
  scrollWidth?: number;
}

export function HorizontalCarousel(props: PropTypes) {
  if (
    !props.allowHorizontalScroll &&
    (props.elements.length === 0 || props.maxElements <= 0)
  ) {
    return null;
  }

  const ref = useRef<HTMLDivElement>(null);
  const [startElement, setStartElement] = useState<number>(0);
  const [endElement, setEndElement] = useState<number>(
    Math.min(props.elements.length - 1, props.maxElements - 1),
  );
  const slicedElements = props.allowHorizontalScroll
    ? props.elements
    : props.elements.slice(startElement, endElement + 1);

  useEffect(() => {
    setStartElement(0);
    setEndElement(Math.min(props.elements.length - 1, props.maxElements - 1));
  }, [props.elements.length]);

  return (
    <div
      className={classNames(
        'd-flex',
        'align-items-center',
        'mt-3',
        props.border && 'border p-4 rounded-2',
        props.classNames,
      )}
    >
      <div
        ref={ref}
        className={classNames(
          'd-flex gap-3 flex-grow-1 overflow-auto',
          props.allowHorizontalScroll && 'me-2 overflow-auto',
          props.allowHorizontalScroll && styles['horizontal-carousel'],
          props.hideScrollbar !== false && styles['hide-scrollbar'],
          props.innerClassNames,
        )}
      >
        <HorizontalCarouselItems
          elements={slicedElements}
          maxElements={props.maxElements}
          startElement={startElement}
          endElement={endElement}
          allowHorizontalScroll={props.allowHorizontalScroll}
        />
      </div>
      <div className='d-flex'>
        <PaginatedButtons
          maxSize={props.elements.length}
          startElement={startElement}
          endElement={endElement}
          buttonColor={props.buttonColor}
          allowHorizontalScroll={props.allowHorizontalScroll}
          scrollContainerRef={ref}
          scrollWidth={props.scrollWidth}
          setStartElement={setStartElement}
          setEndElement={setEndElement}
        />
      </div>
    </div>
  );
}
