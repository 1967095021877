import React from 'react';
import { SurveyRoute } from '../../../containers/survey_engine/SurveyPage/SurveyRoute';
import { Survey } from '../../../entities/survey_engine/Survey';
import { ReferralScreen } from './ReferralScreen';

interface PropTypes {
  survey: Survey;
}

export function ReferralRoute(props: PropTypes) {
  return (
    <SurveyRoute survey={props.survey} status='completed' mismatchPath='..'>
      <ReferralScreen survey={props.survey} />
    </SurveyRoute>
  );
}
