export function computeInitials(name: string) {
  if (!name) {
    return null;
  }

  const initials: string[] = name.split(' ').map((word) => word[0]);
  const length = initials.length;

  if (length <= 1) {
    return initials[0];
  }

  return [initials[0], initials[length - 1]].join('');
}
