'use strict';

import $ from 'jquery';
import * as bootstrap from 'bootstrap';

function setupBootstrap() {
  var popoverTriggerList = Array.from(
    document.querySelectorAll('[data-toggle="popover"]'),
  );
  popoverTriggerList.forEach(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });

  Array.from(
    document.querySelectorAll<HTMLElement>('[data-toggle="tooltip"]'),
  ).forEach((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

  // Array.from(document.querySelectorAll<HTMLElement>('[data-toggle="dropdown"]'))
  //   .forEach((el) => new bootstrap.Dropdown(el));

  Array.from(document.querySelectorAll<HTMLElement>('.alert')).forEach(
    (el) => new bootstrap.Alert(el),
  );
}

document.addEventListener('turbo:load', () => setupBootstrap());

$(() => {
  // document.addEventListener('turbolink:click', (event) => {
  //   const anchorElement = event.target as HTMLElement;
  //   const isSamePageAnchor = anchorElement.getAttribute('href').startsWith('#');

  //   if (!isSamePageAnchor) return;

  //   event.preventDefault();

  //   (Turbolinks as any).controller.pushHistoryWithLocationAndRestorationIdentifier(
  //     (event as any).data.url,
  //     (Turbolinks as any).uuid(),
  //   );
  // });

  // /* ===== Responsive Sidepanel ====== */
  const getSidePanelToggler = () =>
    document.getElementById('sidepanel-toggler');
  const getSidePanel = () => document.getElementById('app-sidepanel');
  const getSidePanelDrop = () => document.getElementById('sidepanel-drop');
  const getSidePanelClose = () => document.getElementById('sidepanel-close');
  const getMenuPanel = () => document.querySelector('.menu');
  const getMenuPanelToggler = () => document.querySelector('.menu-title');
  const getMenuPanelDrop = () => document.querySelector('.menu-drop');

  window.addEventListener('load', function () {
    responsiveSidePanel();
  });

  window.addEventListener('resize', function () {
    responsiveSidePanel();
  });

  function responsiveSidePanel() {
    const sidePanel = getSidePanel();
    const w = window.innerWidth;
    if (w >= 1200) {
      sidePanel?.classList?.remove('sidepanel-hidden');
      sidePanel?.classList?.add('sidepanel-visible');
      getMenuPanel()?.classList?.remove('menu-visible');
    } else {
      sidePanel?.classList?.remove('sidepanel-visible');
      sidePanel?.classList?.add('sidepanel-hidden');
    }
  }

  function didClickOn(element, event) {
    if (!element) return false;

    const target = event.target;
    return target === element || element.contains(target);
  }

  document.addEventListener('click', (e) => {
    const sidePanelToggler = getSidePanelToggler();
    const sidePanel = getSidePanel();
    if (!didClickOn(sidePanelToggler, e)) return;

    if (sidePanel?.classList?.contains('sidepanel-visible')) {
      sidePanel?.classList?.remove('sidepanel-visible');
      sidePanel?.classList?.add('sidepanel-hidden');
    } else {
      sidePanel?.classList?.remove('sidepanel-hidden');
      sidePanel?.classList?.add('sidepanel-visible');
    }
  });

  document.addEventListener('click', (e) => {
    const menuPanelToggler = getMenuPanelToggler();
    const menuPanel = getMenuPanel();
    const menuPanelDrop = getMenuPanelDrop();
    if (!didClickOn(menuPanelToggler, e) && !didClickOn(menuPanelDrop, e))
      return;

    if (menuPanel?.classList?.contains('menu-visible')) {
      menuPanel?.classList?.remove('menu-visible');
    } else {
      menuPanel?.classList?.add('menu-visible');
    }

    e.preventDefault();
  });

  document.addEventListener('click', (e) => {
    const sidePanelClose = getSidePanelClose();
    const sidePanelDrop = getSidePanelDrop();

    if (didClickOn(sidePanelClose, e) || didClickOn(sidePanelDrop, e)) {
      e.preventDefault();
      getSidePanelToggler().click();
    }
  });

  /* ====== Mobile search ======= */
  const searchMobileTrigger = document.querySelector('.search-mobile-trigger');
  const searchBox = document.querySelector('.app-search-box');

  searchMobileTrigger?.addEventListener('click', () => {
    searchBox.classList.toggle('is-visible');

    let searchMobileTriggerIcon = document.querySelector(
      '.search-mobile-trigger-icon',
    );

    if (searchMobileTriggerIcon.classList.contains('bi-search')) {
      searchMobileTriggerIcon.classList.remove('bi-search');
      searchMobileTriggerIcon.classList.add('bi-x');
    } else {
      searchMobileTriggerIcon.classList.remove('bi-x');
      searchMobileTriggerIcon.classList.add('bi-search');
    }
  });
});
