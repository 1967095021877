import React, { useEffect, useState } from 'react';
import { Select, SelectOption } from '../../components/Select';
import { useQueryParam } from '../../hooks/useQueryParam';

export interface SelectContainerOption {
  label: string;
  value: string;
  selected: boolean;
}

interface PropTypes {
  name: string;
  className?: string;
  placeholder: string;
  options: SelectContainerOption[];
  autosubmitForm?: string;
  targetQueryParam?: string;
  isClearable?: string;
  disabled?: string;
}

function convertFromSelectContainerOption(
  so: SelectContainerOption,
): SelectOption {
  if (so == null) {
    return undefined;
  }

  return {
    label: so.label,
    value: so.value,
  };
}

function convertToSelectContainerOption(
  so: SelectOption,
): SelectContainerOption {
  if (so == null) {
    return undefined;
  }

  return {
    label: so.label,
    value: so.value,
    selected: true,
  };
}

export function SelectContainer(props: PropTypes) {
  const placeholder = props.options.find((opt) => opt.value == '');
  const options = props.options.filter((opt) => opt.value != '');
  const selected = options.find((opt) => opt.selected);
  const isClearable: boolean =
    !props.isClearable || props.isClearable == 'true';
  const disabled: boolean = props.disabled == 'true';

  const [value, setValue] = useState<SelectContainerOption>(selected);
  const [previousValue, setPreviousValue] = useState<SelectContainerOption>();

  useQueryParam(props.targetQueryParam, (param) => {
    if (param === null) {
      return;
    }

    const selectedOption = options.find((v) => v.value === param);

    setPreviousValue(selectedOption);
    setValue(selectedOption);
  });

  useEffect(() => {
    if (props.autosubmitForm && previousValue !== value) {
      (
        document.getElementById(props.autosubmitForm) as HTMLFormElement
      ).requestSubmit();
    }
  }, [value]);

  return (
    <Select
      disabled={disabled}
      options={options}
      name={props.name}
      className={props.className}
      selected={convertFromSelectContainerOption(value)}
      isClearable={isClearable}
      placeholder={placeholder?.label}
      onChange={(v) => setValue(convertToSelectContainerOption(v))}
    />
  );
}
