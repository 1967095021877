import React from 'react';
import classNames from 'classnames';

export interface PropTypes {
  selectedValue?: string;
  id: string;
  label: string;
  value: string;
  name?: string;
  disabled?: boolean;
  className?: string;
  // Both methods should be defined unless they are children of CheckbokPillGroup
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  resetValue?: () => void;
}

export function CheckboxPill(props: PropTypes) {
  const checked = props.selectedValue === props.value;

  const onClick = (ev: React.FormEvent<HTMLInputElement>) => {
    if (checked) {
      props.resetValue?.();
    } else {
      props.onChange?.(ev);
    }
  };

  return (
    <>
      <input
        type='checkbox'
        className={'btn-check'}
        value={props.value}
        name={props.name || 'options'}
        id={props.id}
        autoComplete='off'
        defaultChecked={checked}
        onClick={onClick}
        disabled={props.disabled}
      />
      <label
        className={classNames(
          'btn',
          'btn-sm',
          {
            'btn-primary': checked,
            'btn-secondary': !checked,
          },
          'rounded-pill',
          'mt-1',
          'me-1',
          'fs-6',
          props.className,
        )}
        htmlFor={props.id}
      >
        {props.label}
      </label>
    </>
  );
}
