import React from 'react';
import { EmptyState } from '../../../../../components/EmptyState';
import { IconButton } from '../../../../../components/IconButton';
import { IconSpan } from '../../../../../components/IconSpan';
import { Icon } from '../../../../../components/Icon';
import { TextPanel } from '../../../../../components/TextPanel';

interface PropTypes {
  summary?: string;
  handleAskAI: () => void;
  isAiGenerating: boolean;
  topics: string[];
}

export function SummaryPreview(props: PropTypes) {
  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='fw-semibold fs-5'>Summary Preview</div>
        {props.isAiGenerating ? (
          <IconSpan
            className='text-dark-500 fs-6 text-nowrap ms-2'
            spanText='AI is writing...'
            icon={{
              name: 'bi-stars',
            }}
          />
        ) : (
          <IconButton
            size='sm'
            color='secondary'
            onClick={props.handleAskAI}
            disabled={props.topics === undefined || props.topics.length == 0}
            iconName='bi-arrow-repeat'
            buttonText={'Regenerate'}
          />
        )}
      </div>
      {props.summary == null && !props.isAiGenerating ? (
        <EmptyState
          title='No preview generated yet'
          text='Add new topics, exclude existing ones or change the length to generate and preview your new summary.'
          cardless={true}
          classNames={'mt-4 col-8'}
          textFontSize={'fs-5'}
          titleClassName={'fs-4'}
          excludeImage={true}
        />
      ) : props.isAiGenerating ? (
        <>
          <div className='fs-6 text-dark-200'>
            <Icon name='bi-info-circle' /> This may take a moment. Please stay
            on this page.
          </div>
          <div>
            <TextPanel text={props.summary} />
          </div>
        </>
      ) : (
        <TextPanel text={props.summary} />
      )}
    </>
  );
}
