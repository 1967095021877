import React from 'react';
import { Condition, ConditionsData } from '../../../..';
import { SelectOption } from '../../../../../../../../components/Select';
import { findAvailableConditions } from '../../../../utils/findAvailableConditions';
import { AvailableConditionsLabelledSelectCol } from './AvailableConditionsLabelledSelectCol';
import { ConditionsLabelledSelectsCol } from './ConditionsLabelledSelects';
import { IncludesLabelledSelectCol } from './IncludesLabelledSelectCol';

export interface ConditionsSelectOptions {
  index: number;
  available: SelectOption[];
  singleSelected: SelectOption;
  multiSelected: SelectOption[];
  type: SelectOption;
  invert: SelectOption;
  selectedSurveyTemplate: SelectOption;
  conditions: Condition[];
  conditionsData: ConditionsData;
  setConditions: (condition: Condition[]) => void;
}
interface PropTypes {
  conditionsSelectOptions: ConditionsSelectOptions;
  mandatory: boolean;
  applicableConditions: SelectOption[];
}

export function ConditionRow(props: PropTypes) {
  const availableConditions = findAvailableConditions(
    props.conditionsSelectOptions.conditions,
    props.applicableConditions,
  );
  return (
    <>
      <AvailableConditionsLabelledSelectCol
        conditionsSelectOptions={props.conditionsSelectOptions}
        mandatory={props.mandatory}
        availableConditions={availableConditions}
      />
      <IncludesLabelledSelectCol
        conditionsSelectOptions={props.conditionsSelectOptions}
      />
      <ConditionsLabelledSelectsCol
        conditionsSelectOptions={props.conditionsSelectOptions}
      />
    </>
  );
}
