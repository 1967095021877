import { Product } from '@vonq/hapi-elements-types';

const DEFAULT_PAGINATION_STATE = {
  offset: 0,
  limit: 18,
};

export const EMPTY_PRODUCTS = {
  pagination: DEFAULT_PAGINATION_STATE,
  products: [],
};

export interface PaginationState {
  offset: number;
  limit: number;
  totalElements?: number;
}
export interface ProductsWithPagination {
  products: Product[];
  pagination: PaginationState;
}
