import classNames from 'classnames';
import React from 'react';
import { Table, TableProps } from 'reactstrap';
import styles from './styles.module.scss';

export function BubbleTable(props: TableProps) {
  return (
    <Table
      {...props}
      className={classNames(styles['bubble-table'], props.className)}
    />
  );
}
