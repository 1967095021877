import { InterviewerAnalytics } from '../../../../entities/candidate_experience/InterviewerAnalytics';
import {
  FilterStates,
  OnChangeFilterStatesCallback,
  SelectOption,
} from '../../../../components/candidate_experience/Filters/filterState';
import { Filters } from '../../../../components/candidate_experience/Filters';
import { InterviewerPulseEmptyState } from './InterviewerPulseEmptyState';
import React from 'react';
import { QuestionAveragesPanel } from '../../shared_panels/QuestionAveragesPanel';
import { InterviewerTopChoicePanels } from './InterviewerTopChoicePanels';
import { OverallNpsPanels } from '../../../../components/survey_engine/OverallNpsPanels';

/**
 * Props managing the state of the page, as the user interacts with it.
 */
interface PagePropTypes {
  analytics: InterviewerAnalytics;
  filterStates: FilterStates;
  showMoreFilters: boolean;
  onApplyFilter: OnChangeFilterStatesCallback;
}

function hasAnalytics(analytics: InterviewerAnalytics) {
  return Object.keys(analytics).length > 0 && analytics.rps.value !== null;
}

function Body(props: PagePropTypes) {
  return (
    <div className='d-flex flex-column mt-3'>
      <OverallNpsPanels
        npsTitle='Recruitment Process Score'
        npsDescription="Recruitment Process Score is the
                  interviewer's score of the overall process experience."
        npsPromotersText='Positive'
        npsNeutralsText='Neutral'
        npsDetractorsText='Negative'
        nps={props.analytics.rps}
        npsHistory={props.analytics.rps_history}
        npsTrend={props.analytics.rps_trend}
        npsBenchmark={props.analytics.rps_benchmark}
        dateRange={props.filterStates.dateRange}
        exportToPDF={false}
        standardNpsCalculation={true}
      />
      <QuestionAveragesPanel
        questionAverages={props.analytics.question_averages}
        benchmarkType={props.filterStates.benchmarkType.selected}
        title='Process'
      />
      <InterviewerTopChoicePanels
        analytics={props.analytics}
        benchmarkType={props.filterStates.benchmarkType.selected}
      />
    </div>
  );
}

function hideHiringMemberFilters(filterStates: FilterStates): SelectOption[] {
  const filtersToHide = ['managers', 'recruiters'];

  return filterStates.filterSelector.all.filter(
    (option) => !filtersToHide.includes(option.value),
  );
}

export function InterviewerPulseAnalytics(props: PagePropTypes) {
  const filterStates = props.filterStates;
  filterStates.filterSelector.all = hideHiringMemberFilters(props.filterStates);

  return (
    <main>
      <Filters
        filterStates={filterStates}
        onApplyFilter={props.onApplyFilter}
        moreFiltersShown={props.showMoreFilters}
        className='mb-4'
        hideMoreFiltersButton={true}
      />
      {hasAnalytics(props.analytics) ? (
        <Body {...props} />
      ) : (
        <InterviewerPulseEmptyState />
      )}
    </main>
  );
}
