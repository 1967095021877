import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface PropTypes {
  header: JSX.Element;
  children: JSX.Element;
  classNames?: string;
}

export function CommonListPanel(props: PropTypes) {
  return (
    <div
      className={classNames(
        'bg-white',
        'mx-3',
        'mt-4',
        'd-flex',
        'flex-column',
        styles.list,
        props.classNames,
      )}
    >
      {props.header}
      <div className={classNames(styles.list)}>{props.children}</div>
    </div>
  );
}
