import React from 'react';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { SpanTooltip } from '../../../../components/SpanTooltip';

interface PropTypes {
  title: string;
  tooltip?: string;
  className?: string;
  children: JSX.Element;
}

export function MetricCard(props: PropTypes) {
  return (
    <DeprecatedPanel
      additionalInnerContainerClassNames='p-4'
      className={props.className}
    >
      <div className='fw-semibold'>
        {props.title}
        {props.tooltip && (
          <SpanTooltip
            className='ms-2 text-info'
            placement='right'
            text={<i className='bi bi-info-circle'></i>}
            tooltipText={props.tooltip}
            tooltipStyle={{ maxWidth: '250px' }}
          />
        )}
      </div>
      {props.children}
    </DeprecatedPanel>
  );
}
