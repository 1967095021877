import React, { ReactNode, useEffect, useState } from 'react';
import { TitledPanel } from '../../../applicant_tracking/Analytics/Widgets/TitledPanel';
import { InfoTooltip } from '../../../../components/InfoTooltip';
import classNames from 'classnames';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ApplicationsCountHistory } from '../../../../entities/applicant_tracking/analytics/ApplicationsCountHistory';
import { ApplicationsCountHistoryService } from '../../../../services/applicant_tracking/analytics/ApplicationsCountHistoryService';
import { shortFormatDate } from '../../../../utils/timeFormat';
import { ActiveAndHiredBarChart } from '../../../../components/ActiveAndHiredBarChart';
import { CardTitle } from '../../../../components/Card';

interface PropTypes {
  height: string;
}

export function ApplicationsCountHistoryPanel(props: PropTypes) {
  return (
    <TitledPanel title={<Title />} height={props.height}>
      <LazyContent />
    </TitledPanel>
  );
}

function Title() {
  return (
    <div className='hstack gap-2'>
      <div className='hstack gap-2 me-auto'>
        <CardTitle>History</CardTitle>
        <InfoTooltip>Weekly hired vs active applicants comparison.</InfoTooltip>
      </div>
      <div className='d-flex gap-3'>
        <Legend iconClassName='bg-green-500'>Active Candidates</Legend>
        <Legend iconClassName='bg-blue-500'>Hires</Legend>
      </div>
    </div>
  );
}

function Legend(props: { iconClassName: string; children: ReactNode }) {
  return (
    <div className='hstack gap-2'>
      <span
        style={{ width: '14px', height: '14px' }}
        className={classNames('rounded', props.iconClassName)}
      />
      <span className='fs-6 fw-normal'>{props.children}</span>
    </div>
  );
}

function LazyContent() {
  const [data, setData] = useState<ApplicationsCountHistory>(null);

  useEffect(() => {
    ApplicationsCountHistoryService.show().then((history) => setData(history));
  }, []);

  if (!data) return <LoadingSpinner />;

  return <Content data={data} />;
}

function Content(props: { data: ApplicationsCountHistory }) {
  const labels = props.data.hiredPerInterval.map(
    (history) =>
      `${shortFormatDate(history.from)} - ${shortFormatDate(history.to)}`,
  );
  const activeValues = props.data.activePerInterval.map(
    (history) => history.value,
  );
  const hiredValues = props.data.hiredPerInterval.map(
    (history) => history.value,
  );

  const max = activeValues.reduce((a, b) => Math.max(a, b), -Infinity);

  if (max === 0) {
    return <EmptyState />;
  }

  return (
    <div className='vstack gap-2'>
      <div>
        <h2 className='mb-0'>
          {props.data.hiredCount}{' '}
          {props.data.hiredCount === 1 ? 'Hire' : 'Hires'}
        </h2>
      </div>
      <div className='flex-grow-1'>
        <ActiveAndHiredBarChart
          labels={labels}
          activeValues={activeValues}
          hiredValues={hiredValues}
        />
      </div>
    </div>
  );
}

function EmptyState() {
  return (
    <div className='flex-fill d-flex align-items-center justify-content-center fw-bold'>
      No data available yet
    </div>
  );
}
