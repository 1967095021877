import React, { useState } from 'react';
import { AsyncSelect } from '../../../../components/Select/async';
import { SelectOption } from '../../../../components/Select';

export type StartingPoint =
  | { value: string; label: string }
  | 'FROM_SCRATCH'
  | undefined;

interface PropTypes {
  startingPoint: StartingPoint;
  initialState?: () => Promise<unknown>;
  filterOptions: (
    options: Promise<unknown>,
    q: string,
  ) => Promise<{ value: string; label: string }[]>;
  placeholder: string;
  onStartingPointChange: (value: StartingPoint) => void;
}

export function StartingPointSelector(props: PropTypes) {
  const [selectEnabled, setSelectEnabled] = useState<boolean>(
    props.startingPoint != null && props.startingPoint != 'FROM_SCRATCH',
  );
  const [options] = useState(props.initialState);

  const handleClickFromScratch = () => {
    setSelectEnabled(false);
    props.onStartingPointChange('FROM_SCRATCH');
  };

  const handleClickTemplate = () => {
    setSelectEnabled(true);
    props.onStartingPointChange(undefined);
  };

  return (
    <div className='d-flex align-items-center'>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          checked={!selectEnabled}
          onChange={handleClickFromScratch}
        />
        <label className='form-check-label' onClick={handleClickFromScratch}>
          Custom
        </label>
      </div>
      <div className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          checked={selectEnabled}
          onChange={handleClickTemplate}
        />
        <label className='form-check-label' onClick={handleClickTemplate}>
          Based on template
        </label>
      </div>
      <AsyncSelect
        placeholder={props.placeholder}
        className='d-inline-block flex-grow-1'
        loadOptions={(q: string) => props.filterOptions(options, q)}
        selected={selectedOption(props.startingPoint)}
        onChange={(newOption) => {
          props.onStartingPointChange(newOption);
        }}
        disabled={!selectEnabled}
      />
    </div>
  );
}

function selectedOption(startingPoint: StartingPoint): SelectOption {
  if (!startingPoint) return undefined;
  if (typeof startingPoint === 'string') return undefined;

  return startingPoint;
}
