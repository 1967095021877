import { getQueryParam, QueryParam } from '../../../../../utils/url';

export const PAGE_QUERY_PARAM = 'page';

export function defaultPage(): number {
  return 1;
}

export function parsePage(): number {
  const value = getQueryParam(PAGE_QUERY_PARAM);

  const parsedPage = Number(value);

  if (isNaN(parsedPage) || parsedPage < 1) return defaultPage();

  return parsedPage;
}

export function mapPageToQueryParam(value: number): QueryParam {
  return {
    key: PAGE_QUERY_PARAM,
    value: value.toString(),
  };
}
