import classNames from 'classnames';
import React from 'react';
import styles from '../../TableBody/styles.module.scss';
import { Chip, ChipColor } from '../../../../../../../components/Chip';

interface PropTypes {
  autoRecording: boolean;
}

export function AutoRecordingDataCell(props: PropTypes) {
  return (
    <td className={classNames('pb-3', styles['table-data-cell-border-bottom'])}>
      <Chip
        className={classNames('fw-semibold')}
        color={
          props.autoRecording ? ChipColor.SuccessLight : ChipColor.DangerLight
        }
      >
        {props.autoRecording ? 'Enabled' : 'Disabled'}
      </Chip>
    </td>
  );
}
