import classNames from 'classnames';
import React from 'react';
import { HiringMemberAnalytics } from '../../../../../../../../../entities/candidate_experience/HiringMemberAnalytics';
import styles from '../../TableBody/styles.module.scss';
import { LabelledAvatar } from '../../../../../../../../../components/LabelledAvatar';

interface PropTypes {
  hiring_member: HiringMemberAnalytics;
}

export function Name(props: PropTypes) {
  return (
    <td
      className={classNames(
        'ps-4 pb-4',
        styles['table-data-cell-border-bottom'],
      )}
    >
      <LabelledAvatar
        type={props.hiring_member.userPhotoUrl ? 'photo' : 'initials'}
        name={props.hiring_member.userPhotoUrl || props.hiring_member.name}
        label={props.hiring_member.name}
      />
    </td>
  );
}
