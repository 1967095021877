import classNames from 'classnames';
import React from 'react';
import { CollapsablePanel } from '../../../../components/ElementPanel/CollapsablePanel';
import { User } from '../../../../entities/User';
import { AssignUserModal } from '../../AssignUserModal';
import { AssignedUsers } from '../AssignedUsers';
import { useResizeDetector } from 'react-resize-detector';
import { groupUsers, isAssignable } from '../../AssignUserModal/utils';
import { IconButton } from '../../../../components/IconButton';
import { Session } from '../../../../entities/training/Session';

interface PropTypes {
  currentUser: User;
  assignModalVisible: boolean;
  onAssignModalVisible: (flag: boolean) => void;
  session: Session;
  onAssign: (sessionId: number, users: number[]) => void;
}

function getGroupSize(width: number): number {
  if (width < 250) {
    return 1;
  }

  if (width <= 350) {
    return 2;
  }

  return 3;
}

function getElements(session: Session, width: number) {
  const assignedUsers = session.trainingUsers?.filter((user) => user.assigned);

  return groupUsers(assignedUsers, getGroupSize(width)).map((users, index) => (
    <AssignedUsers key={index} users={users} />
  ));
}

function AssignedUsersLength(props: { usersLength?: number }) {
  return (
    <div>
      <b>{props.usersLength || 0}</b> Users
    </div>
  );
}

function AssignUsersButton(props: PropTypes) {
  const assignedUsers = props.session.trainingUsers?.filter(
    (user) => user.assigned,
  );

  return (
    <div
      className={classNames(
        'd-flex',
        'flex-nowrap',
        'justify-content-between',
        'align-items-center',
      )}
    >
      <AssignedUsersLength usersLength={assignedUsers?.length} />
      <IconButton
        color='borderless'
        size='sm'
        iconName={'bi-pencil-square'}
        buttonText={'Edit Assigned Users'}
        onClick={() => props.onAssignModalVisible(true)}
      />
    </div>
  );
}

export function AssignedUsersPanel(props: PropTypes) {
  if (
    !isAssignable(props.session) ||
    props.session.trainingUsers == null ||
    props.session.trainingUsers.length == 0
  ) {
    return null;
  }

  const { width, ref } = useResizeDetector();

  return (
    <>
      <AssignUserModal
        visible={props.assignModalVisible}
        sessionId={props.session.id}
        sessionName={props.session.name}
        trainingUsers={props.session.trainingUsers}
        onClose={() => props.onAssignModalVisible(false)}
        onAssign={props.onAssign}
      />
      <div ref={ref}>
        <CollapsablePanel
          elements={getElements(props.session, width)}
          contextElement={<AssignUsersButton {...props} />}
          maxElements={4}
          title={'Assigned users'}
          elementNamePlural={'users'}
          classNames={classNames(
            'border-gray',
            'bg-white',
            'border-2',
            'd-flex',
            'flex-wrap',
            'px-4',
          )}
        />
      </div>
    </>
  );
}
