import { SelectOption } from '../../../../../components/Select';
import { SurveyTemplate } from '../../../../../entities/candidate_experience/SurveyTemplate';

export function setApplicableConditions(
  selectedSurveyTemplate: SelectOption,
  filterSurveyTemplate: (
    st: SelectOption,
    surveyTemplates: SurveyTemplate[],
  ) => SurveyTemplate,
  labelledConditions: {
    value: string;
    label: string;
  }[],
  surveyTemplates: SurveyTemplate[],
) {
  if (!selectedSurveyTemplate) {
    return [];
  }

  const surveyTemplate = filterSurveyTemplate(
    selectedSurveyTemplate,
    surveyTemplates,
  );

  const conditions = surveyTemplate.trigger_requirements;

  const applicableConditions = labelledConditions.filter(
    (condition) => conditions[condition.value] !== 'not_applicable',
  );
  return applicableConditions;
}
