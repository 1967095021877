import classNames from 'classnames';
import React from 'react';

export function PanelSubHeader(props: { contextElement?: JSX.Element }) {
  if (props.contextElement == null) {
    return null;
  }

  return (
    <div className={classNames('d-flex', 'flex-column', 'mt-3', 'w-100')}>
      {props.contextElement}
    </div>
  );
}
