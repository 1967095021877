import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { CollapsablePanel } from '../../../../components/ElementPanel/CollapsablePanel';
import { Comment } from '../../../../entities/training/Comment';

interface PropTypes {
  comments: Comment[];
}

function formatDate(date: Date) {
  return moment(date).format('DD/MM/yyyy');
}

function getLabel(size: number): string {
  if (size == 1) {
    return '1 Comment';
  }

  return `${size} Comments`;
}

function CommentElement(props: { comment: Comment }) {
  return (
    <div>
      <div className='bg-white text-dark-500 px-3 py-2 fs-6 rounded-4'>
        {props.comment.text}
      </div>
      <Row className='flex-nowrap mt-2 px-3 justify-content-between text-blue-gray-900'>
        <Col className='fs-6'>
          <i className={classNames('bi', 'bi-star', 'me-1')} />
          <span>{props.comment.rating || 'N/A'}</span>
        </Col>
        <Col className='fs-6 text-end'>{formatDate(props.comment.date)}</Col>
      </Row>
    </div>
  );
}

export function CommentsPanel(props: PropTypes) {
  if (props.comments == null || props.comments.length == 0) {
    return null;
  }

  return (
    <CollapsablePanel
      elements={props.comments.map((comment, index) => (
        <CommentElement key={index} comment={comment} />
      ))}
      elementNamePlural={'Comments'}
      elementsClassName={'gap-3'}
      label={getLabel(props.comments.length)}
      maxElements={3}
      title={'Comments'}
      classNames={classNames(
        'border-gray',
        'bg-gray',
        'border-2',
        'd-flex',
        'flex-wrap',
        'px-4',
      )}
    />
  );
}
