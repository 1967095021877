import React, { useState } from 'react';
import classNames from 'classnames';
import { formatTime } from '../../../utils/timeFormat';
import { Seekbar } from '../../../components/Seekbar';
import styles from './styles.module.scss';

interface PropTypes {
  time: number;
  duration: number;
  onSelectTime: (time: number) => void;
}

export function Timeline(props: PropTypes) {
  const [previewTime, setPreviewTime] = useState<number>();
  const totalTime = props.duration;

  return (
    <div
      className={classNames(
        styles['timeline-wrap'],
        'bg-white',
        'w-100',
        'p-2',
        'rounded-4',
        'mt-3',
      )}
    >
      <div
        className={`${styles['video-player-time']} ${styles['video-player-timestamp']}`}
      >
        <span
          className={styles['video-player-current-time']}
          data-testid='InterviewTimeline-current-time'
        >
          {formatTime(props.time)}
        </span>
        <span className={styles['video-player-duration']}>
          {formatTime(totalTime)}
        </span>
      </div>
      <div className='my-3 px-3'>
        <Seekbar
          totalTime={totalTime}
          time={props.time}
          previewTime={previewTime}
          seekbarMarkers={[]}
          onSelectTime={props.onSelectTime}
          onSelectPreviewTime={setPreviewTime}
        />
      </div>
    </div>
  );
}
