import React, { useEffect, useState } from 'react';
import { ToggleButton } from '../../../../../../components/ToggleButton';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { OrganicPostService } from '../../../../../../services/applicant_tracking/analytics/jobboards/OrganicPostService';
import { OrganicPost } from '../../../../../../entities/applicant_tracking/jobboards/OrganicPost';
import { OrganicPostRow } from './OrganicPostRow';
import { JobPostingDetailsForm } from '../../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails';

interface PropTypes {
  enabled: boolean;
  form: JobPostingDetailsForm;
  setEnabled: (enabled: boolean) => void;
}

export function JobOrganicPostsToggle(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(false);
  const [organicPosts, setOrganicPosts] = useState<OrganicPost[]>(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await OrganicPostService.list();
        setOrganicPosts(
          result?.organicPosts?.sort((a, b) => a.order - b.order),
        );
      } catch (e: any) {
        console.error('It was not able to get the organic job posts', e);
      }

      setIsLoading(false);
    })();
  }, []);

  return (
    <div className='border border-blue-gray-500 rounded-3 py-4 ps-4 pe-6h'>
      <div className='d-flex flex-nowrap justify-content-between mb-5h'>
        <h3>Free Job Boards</h3>
        <ToggleButton
          name='JobBoards'
          checked={props.enabled}
          onChange={() => props.setEnabled(!props.enabled)}
          color='info'
        />
      </div>
      <div className='d-flex flex-column gap-4'>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          organicPosts?.map((v: OrganicPost, i: number) => (
            <OrganicPostRow
              key={i}
              index={i}
              organicPost={v}
              hasCompanyIdFilled={!!props.form?.companyId || false}
            />
          ))
        )}
      </div>
    </div>
  );
}
