import React, { useState } from 'react';
import { IconSpan } from '../../../../../components/IconSpan';
import { DeleteJobConfirmationModal } from '../DeleteJobConfirmationModal';
import { DropdownAction } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';

interface PropTypes {
  jobId: number;
  jobName: string;
}

export default function DeleteJobButton(props: PropTypes) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <>
      <DeleteJobConfirmationModal
        jobId={props.jobId}
        jobName={props.jobName}
        isModalOpen={deleteModalOpen}
        setIsModalOpen={setDeleteModalOpen}
      />
      <DropdownAction
        action={() => setDeleteModalOpen(true)}
        buttonChild={
          <IconSpan
            className='ms-2'
            spanText='Delete'
            icon={{ name: 'bi-trash' }}
          />
        }
      />
    </>
  );
}
