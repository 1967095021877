import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../entities/survey_engine/SurveyQuestionAnswer';
import { setQuestionVisible } from './setQuestionVisible';

export function getSurveyQuestionsWithConditionsVisibility(
  questions: SurveyQuestion[],
  answers: SurveyQuestionAnswer[],
) {
  const questionIds = answers.map((answer) => answer.question_id);

  return questions
    .filter((question) => question.conditions?.length > 0)
    .map((question) => {
      const condition = question.conditions.find((condition) =>
        questionIds.includes(condition.condition_question_id),
      );
      const answer = answers.find(
        (answer) => answer.question_id === condition.condition_question_id,
      );

      const questionVisible = setQuestionVisible(answer, condition);

      return {
        questionVisible,
        dependentQuestionId: condition.dependent_question_id,
      };
    });
}
