import { ApiService } from '../ApiService';

export class ChangePasswordService {
  public static put(
    password: string,
    passwordConfirmation: string,
    resetPasswordToken: string,
  ): Promise<void> {
    return ApiService.put<void>(`/auth/password`, {
      user: {
        password: password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetPasswordToken,
      },
    });
  }
}
