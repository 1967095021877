import React from 'react';
import { ModalBody } from './ModalBody';
import { CloseableModal } from '../../../../../../components/CloseableModal';

interface PropTypes {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

export function ChangeFormConfirmationModal(props: PropTypes) {
  return (
    <>
      {props.isOpen && (
        <CloseableModal
          className='modal-dialog-centered'
          onClose={props.onCancel}
          isOpen={true}
          size='md'
          closeDisabled={false}
          keyboard={true}
          backdrop={true}
          headerTitle='Change Application Form'
          bodyChildren={
            <ModalBody onSuccess={props.onSuccess} onCancel={props.onCancel} />
          }
        />
      )}
    </>
  );
}
