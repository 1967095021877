import React, { useState } from 'react';
import { Interview } from '../../../entities/Interview';
import { Seekbar } from '../../../components/Seekbar';
import { SpeakerSegments } from '../SpeakerSegments';
import styles from './styles.module.scss';
import { formatTime } from '../../../utils/timeFormat';
import classNames from 'classnames';
import buildSeekbarMarkers from '../../../utils/buildSeekbarMarkers';
import { HighlightType } from '../Entities/HighlightType';
import { CreateInterviewClipButton } from '../InterviewClipsTab/CreateInterviewClipButton';
import RangeSlider from '../../../components/RangeSlider';
import { TimelineBar } from '../TimelineBar';
import Skeleton from 'react-loading-skeleton';
import { InterviewClip } from '../../../entities/InterviewClip';
import { Media } from '../../../entities/Media';
import { useSpeakers } from '../../../queries/v1/interview_intelligence/useSpeakers';
import { useParticipants } from '../../../queries/v1/interview_intelligence/useParticipants';
import { useTranscript } from '../../../queries/v1/interview_intelligence/useTranscript';
import { Participant } from '../../../entities/v1/interview_intelligence/Participant';
import { Transcript } from '../../../entities/v1/interview_intelligence/Transcript';
import { useAutomaticQuestions } from '../../../queries/v1/interview_intelligence/useAutomaticQuestions';
import { AutomaticQuestion } from '../../../entities/v1/interview_intelligence/AutomaticQuestion';

export interface PropTypes {
  interview: Interview;
  time: number;
  selectedHighlightType: HighlightType;
  interviewClipsEditing: boolean;
  clipRange: number[];
  onSelectedHighlightType: (val: HighlightType) => void;
  onSelectTime: (time: number) => void;
  onSwapParticipants: (
    interviewId: number,
    participantAId: number,
    participantBId: number,
  ) => void;
  onCreateInterviewClip: () => void;
  onInterviewClipRangeChanged: (values: number[]) => void;
}

export interface InterviewTimelineUIPropTypes {
  interview: Interview;
  speakers?: Participant[];
  participants?: Participant[];
  transcript?: Transcript;
  automaticQuestions?: AutomaticQuestion[];
  isLoading: boolean;
  time: number;
  selectedHighlightType: HighlightType;
  interviewClipsEditing: boolean;
  clipRange: number[];
  onSelectedHighlightType: (val: HighlightType) => void;
  onSelectTime: (time: number) => void;
  onSwapParticipants: (
    interviewId: number,
    participantAId: number,
    participantBId: number,
  ) => void;
  onCreateInterviewClip: () => void;
  onInterviewClipRangeChanged: (values: number[]) => void;
}

function InterviewClipTimeline(props: {
  interviewClips: InterviewClip[];
  interviewClipsEditing: boolean;
  interviewMedia: Media;
  clipRange: number[];
  onInterviewClipRangeChanged: (values: number[]) => void;
  onSelectTime: (time: number) => void;
}) {
  if (!props.interviewClips.length && !props.interviewClipsEditing) {
    return null;
  }

  return (
    <div className='my-3'>
      <span className='fs-6'>Clips</span>
      {props.interviewClipsEditing && (
        <RangeSlider
          min={0}
          max={props.interviewMedia.duration}
          step={1000}
          value={[props.clipRange[0], props.clipRange[1]]}
          tooltipFormatter={(value) => formatTime(value)}
          onChange={(value: number[]) =>
            props.onInterviewClipRangeChanged(value)
          }
        />
      )}
      {!props.interviewClipsEditing && props.interviewClips && (
        <TimelineBar
          segments={props.interviewClips.map((clip) => ({
            start: clip.startOffsetMs,
            end: clip.endOffsetMs,
          }))}
          secrets={[]}
          totalTime={props.interviewMedia.duration}
          color='#FFAD62'
          onSelectTime={props.onSelectTime}
        />
      )}
    </div>
  );
}

export function InterviewTimeline(props: PropTypes) {
  const { isLoading: isLoadingSpeakers, data: speakerList } = useSpeakers(
    props.interview.id,
    ['insights'],
  );
  const { isLoading: isLoadingParticipants, data: participantList } =
    useParticipants(props.interview.id);
  const { isLoading: isLoadingTranscript, data: transcript } = useTranscript(
    props.interview.id,
  );
  const { isLoading: isLoadingAutomaticQuestions, data: automaticQuestions } =
    useAutomaticQuestions(props.interview.id);

  const isLoading =
    isLoadingSpeakers ||
    isLoadingParticipants ||
    isLoadingTranscript ||
    isLoadingAutomaticQuestions;

  return (
    <InterviewTimelineUI
      {...props}
      speakers={speakerList?.participants}
      participants={participantList?.participants}
      transcript={transcript}
      automaticQuestions={automaticQuestions?.automaticQuestions}
      isLoading={isLoading}
    />
  );
}

export function InterviewTimelineUI(props: InterviewTimelineUIPropTypes) {
  const [previewTime, setPreviewTime] = useState<number>();

  const totalTime = props.interview.media.duration;
  const timeRatio = totalTime !== 0 ? (props.time / totalTime) * 100 : 100;
  const previewTimeRatio =
    previewTime !== undefined && totalTime !== 0
      ? (previewTime / totalTime) * 100
      : 0;

  const seekbarMarkers = buildSeekbarMarkers(
    props.interview,
    props.selectedHighlightType,
    props.automaticQuestions,
  );

  const sliderHeight =
    (props.interview.interviewClips.length ? 50 : 0) +
    (props.speakers?.length > 0 ? 20 + props.speakers?.length * 35 : 0);

  return (
    <div className={classNames(styles.timelineWrap, 'bg-white', 'w-100')}>
      <div className='mb-4'>
        <div className={styles['create-clip']}>
          <CreateInterviewClipButton
            color='borderless'
            size='sm'
            disabled={props.interviewClipsEditing}
            onClick={props.onCreateInterviewClip}
          />
        </div>
        <div
          className={`${styles.videoPlayerTime} ${styles.videoPlayerTimestamp}`}
        >
          <span
            className={styles.videoPlayerCurrentTime}
            data-testid='InterviewTimeline-current-time'
          >
            {formatTime(props.time)}
          </span>
          <span className={styles.videoPlayerDuration}>
            {formatTime(props.interview.media.duration)}
          </span>
        </div>
      </div>
      <div className='mb-4 pt-4'>
        <Seekbar
          totalTime={totalTime}
          time={props.time}
          previewTime={previewTime}
          seekbarMarkers={seekbarMarkers}
          onSelectTime={props.onSelectTime}
          onSelectPreviewTime={setPreviewTime}
        />
      </div>
      <InterviewClipTimeline
        interviewClips={props.interview.interviewClips}
        interviewClipsEditing={props.interviewClipsEditing}
        interviewMedia={props.interview.media}
        clipRange={props.clipRange}
        onInterviewClipRangeChanged={props.onInterviewClipRangeChanged}
        onSelectTime={props.onSelectTime}
      />
      {props.isLoading ? (
        <Skeleton />
      ) : (
        props.speakers
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map((speaker) => (
            <div key={speaker.id} className='mb-2'>
              <SpeakerSegments
                interviewId={props.interview.id}
                interviewSecrets={props.interview.interview_secrets}
                speaker={speaker}
                monologues={
                  props.transcript?.monologues?.filter(
                    (m) => m.participantId === speaker.id,
                  ) || []
                }
                totalTime={totalTime}
                onSelectTime={props.onSelectTime}
                otherSpeakers={props.participants.filter(
                  (s) => s.id !== speaker.id,
                )}
                onSwapParticipants={props.onSwapParticipants}
              />
            </div>
          ))
      )}
      <div
        className={styles.timelineSliderHandle}
        style={{ height: `${sliderHeight}px`, left: `${timeRatio}%` }}
      />
      {previewTimeRatio != 0 && (
        <div
          className={styles.timelinePreviewSliderHandle}
          style={{ height: `${sliderHeight}px`, left: `${previewTimeRatio}%` }}
        />
      )}
    </div>
  );
}
