import React from 'react';
import { SelectOption } from '../Select';
import { selectTheme } from '../Select/selectTheme';
import CreatableSelect from 'react-select/creatable';
import isEmail from 'validator/lib/isEmail';
import { Option } from './Option';
import { multiSelectStyle } from '../Select/multiSelectStyle';
import { User } from '../../entities/v1/User';

interface PropTypes {
  placeholder: string;
  users: User[];
  selected?: SelectOption[];
  onChange: (emails: SelectOption[]) => void;
}

const buildUserOptions = (
  users: User[],
  selected: SelectOption[],
): SelectOption[] => {
  return users
    ?.sort((a, b) => a.name.localeCompare(b.name))
    ?.map((user) => ({
      value: user.email,
      label: user.email,
      subLabel: user.name,
      selected: selected?.map((s) => s.value).includes(user.email) ?? false,
    }));
};

export function EmailRecipientCreatableMultiSelect(props: PropTypes) {
  return (
    <CreatableSelect
      isLoading={props.users == null}
      value={props.selected}
      isMulti
      hideSelectedOptions={true}
      tabSelectsValue
      components={{ DropdownIndicator: null, Option }}
      theme={selectTheme}
      formatCreateLabel={(inputValue) => inputValue}
      isValidNewOption={(inputValue) => isEmail(inputValue)}
      styles={multiSelectStyle}
      isClearable={false}
      noOptionsMessage={(_) => 'No results'}
      placeholder={props.placeholder}
      options={buildUserOptions(props.users, props.selected)}
      isOptionSelected={(option: SelectOption) => !!option.selected}
      onChange={(values: SelectOption[]) => props.onChange(values)}
    />
  );
}
