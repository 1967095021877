import React from 'react';
import { Dropdown } from '../../../../components/Dropdown';
import { IconSpan } from '../../../../components/IconSpan';
import { appendRedirectUri } from '../../../../utils/url';

interface PropTypes {
  scorecardTemplateId: number;
  showScorecardTemplateUrl: string;
  deleteEnabled: boolean;
  default: boolean;
}

function buildMenuAction(
  action: () => void,
  label: string,
  iconName: string,
  enabled: boolean,
) {
  if (!enabled) return null;

  return {
    action: action,
    buttonChild: (
      <IconSpan
        spanText={label}
        icon={{ name: iconName, className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

export default function RowActionsDropdown(props: PropTypes) {
  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-4 text-info',
      }}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={[
        buildMenuAction(
          () =>
            (window.location.href = appendRedirectUri(
              props.showScorecardTemplateUrl,
            )),
          'View',
          'bi-eye',
          true,
        ),
        buildMenuAction(
          () =>
            (window.location.href = appendRedirectUri(
              `/organization_settings/scorecard_templates/${props.scorecardTemplateId}/edit`,
            )),
          'Edit',
          'bi-pencil',
          !props.default,
        ),
      ].filter((a) => a != null)}
    />
  );
}
