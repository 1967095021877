import sjcl from 'sjcl';
import { ChipColor } from '../components/Chip';

const CHIP_COLORS = [
  ChipColor.BlueLight,
  ChipColor.OrangeLight,
  ChipColor.SuccessLight,
  ChipColor.DangerLight,
  ChipColor.Gray,
];

export function jobStageChipColor(name: string): ChipColor {
  if (name == null) return null;
  const index = parseInt(
    sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(name)),
    16,
  );
  return CHIP_COLORS[index % CHIP_COLORS.length];
}
