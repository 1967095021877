import React from 'react';
import { EmailAttachment } from '../../../../../../entities/EmailAttachment';
import { IconLink } from '../../../../../../components/IconLink';
import { IconSpan } from '../../../../../../components/IconSpan';
import { trimFileName } from '../../../../../../utils/trimFileName';

const ATTACHMENT_FILENAME_MAX_LENGTH = 20;

interface PropTypes {
  attachments?: EmailAttachment[];
}

function Attachment(props: { emailAttachment: EmailAttachment }) {
  const attachmentFilename = trimFileName(
    props.emailAttachment.filename,
    ATTACHMENT_FILENAME_MAX_LENGTH,
  );

  const downloadableAttachment = !!props.emailAttachment.url;

  if (!downloadableAttachment) {
    return (
      <IconSpan
        icon={{ name: 'bi-paperclip', className: 'ps-2 fs-6 text-primary' }}
        spanText={attachmentFilename}
        spanClassName='text-primary pe-2'
      />
    );
  }

  return (
    <IconLink
      download={true}
      iconClassName='bi-paperclip text-blue'
      text={attachmentFilename}
      textClassName={'text-blue'}
      disabled={!downloadableAttachment}
      url={props.emailAttachment.url}
    />
  );
}

export function AttachmentList(props: PropTypes) {
  if (props.attachments == null || props.attachments?.length === 0) {
    return null;
  }

  return (
    <div className='d-flex flex-wrap gap-3 mt-2'>
      {props.attachments.map((attachment: EmailAttachment) => (
        <Attachment key={attachment.id} emailAttachment={attachment} />
      ))}
    </div>
  );
}
