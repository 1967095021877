import snakecaseKeys from 'snakecase-keys';
import { HiringMemberSettings } from '../../../entities/v1/HiringMemberSettings';
import { ApiService } from '../../ApiService';

export class HiringMemberSettingsService {
  public static update(params: HiringMemberSettings): Promise<void> {
    return ApiService.patch(
      `/api/v1/hiring_member_settings/${params.id}`,
      snakecaseKeys(params),
    );
  }

  public static create(params: HiringMemberSettings): Promise<void> {
    return ApiService.post(
      '/api/v1/hiring_member_settings',
      snakecaseKeys(params),
    );
  }
}
