import classNames from 'classnames';
import React from 'react';
import { Survey } from '../../../../entities/survey_engine/Survey';
import { humanize } from '../../../../utils/humanize';
import styles from './styles.module.scss';

interface PropTypes {
  survey: Survey;
}

export function DefaultEndScreen(props: PropTypes) {
  return (
    <p className={classNames('mt-1 mb-4 mx-auto', styles.text)}>
      Thank you for taking the time to complete this survey. We will review your
      feedback, and look to improve and continue to offer the best{' '}
      {humanize(props.survey.target).toLowerCase()} experience possible.
    </p>
  );
}
