import React from 'react';

interface PropTypes {
  isFormActive: boolean;
  emailAlias: string;
  setEmailAlias: (emailAlias: string) => void;
}

export function EmailAliasInput(props: PropTypes) {
  return (
    <input
      type='text'
      className='form-control form-control-sm'
      placeholder='Email Alias'
      id='emailAlias'
      aria-label='emailAlias'
      disabled={!props.isFormActive}
      value={props.emailAlias}
      onChange={(e) => props.setEmailAlias(e.target.value)}
    />
  );
}
