import React from 'react';
import { Col, Row } from 'reactstrap';
import { SurveyLayout } from '../../../components/SurveyLayout';
import containerStyles from '../styles.module.scss';
import { Survey } from '../../../entities/Survey';
import classNames from 'classnames';
import styles from './styles.module.scss';
import NotInterestedPageImage from '../../../images/not_interested_page.svg';
import { MoreInfoFooter } from '../MoreInfoFooter';

export function NotInterestedProspectScreen({ survey }: { survey: Survey }) {
  return (
    <SurveyLayout
      companyInfo={survey.company_info}
      className={containerStyles.surveyPage}
    >
      <div className='col-9 mx-auto'>
        <Row className='text-center'>
          <div className={classNames(styles['references-card'])}>
            <div
              className={classNames(
                'pt-5 pb-5',
                styles['card-content-max-width'],
              )}
            >
              <Col>
                <Row className='mb-2 mt-5'>
                  <div className='d-flex justify-content-center'>
                    <img
                      className='d-block img-fluid'
                      src={NotInterestedPageImage}
                    />
                  </div>
                </Row>
                <Row className='mb-5 mt-5'>
                  <Col>
                    <h2 className='fw-semibold fs-display2'>
                      Thank you! Maybe next time.
                    </h2>
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <MoreInfoFooter />
                </Row>
              </Col>
            </div>
          </div>
        </Row>
      </div>
    </SurveyLayout>
  );
}
