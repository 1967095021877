import React from 'react';
import { Button } from 'reactstrap';
import { CandidateService } from '../../../../../../../services/applicant_tracking/CandidateService';
import { CandidateActionProps } from '..';

interface ModalProps {
  candidateId: number;
  candidateName: string;
  action: 'Archive' | 'Unarchive';
  onClose: (action?: CandidateActionProps) => void;
}

export function ArchiveModal(props: ModalProps) {
  const performAction = async () => {
    try {
      switch (props.action) {
        case 'Archive':
          await CandidateService.archive(props.candidateId);
          break;
        case 'Unarchive':
          await CandidateService.unarchive(props.candidateId);
          break;
      }

      props.onClose({
        candidateName: props.candidateName,
        action: props.action,
        state: 'Success',
      });
    } catch (error) {
      props.onClose({
        candidateName: props.candidateName,
        action: props.action,
        state: 'Error',
      });
      console.error(error);
    }
  };

  return (
    <>
      <div className='mb-2 fs-5'>
        Are you sure you want to {props.action.toLowerCase()}{' '}
        <span className='fw-bold'>{props.candidateName}</span>?
      </div>
      <div className='d-flex mt-5 justify-content-end'>
        <Button className='me-2' color='danger' onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button color='primary' onClick={performAction}>
          {props.action}
        </Button>
      </div>
    </>
  );
}
