import React, { Fragment, useEffect, useState } from 'react';
import { HeaderRow } from './HeaderRow';
import { Subject } from './Subject';
import { To } from './To';
import { EmailRecipientCreatableMultiSelect } from '../../../../components/EmailRecipientCreatableMultiSelect';
import { SelectOption } from '../../../../components/Select';
import { Email } from '../../../../entities/Email';
import { EmailTemplate } from '../../../../entities/EmailTemplate';
import { EmailTemplates } from './EmailTemplates';
import { UserService } from '../../../../services/v1/UserService';
import { EmailTemplateService } from '../../../../services/EmailTemplateService';

interface PropTypes {
  bcc: SelectOption[];
  cc: SelectOption[];
  to: string;
  emailTemplate?: EmailTemplate;
  replyToEmail?: Email;
  subjectJson?: string;
  setEmailTemplate: (emailTemplate: EmailTemplate) => void;
  setCc: (cc: SelectOption[]) => void;
  setBcc: (cc: SelectOption[]) => void;
  setSubjectText: (text: string) => void;
  setSubjectJson: (json: string) => void;
  setIsValid: (valid: boolean) => void;
}

export function EmailHeader(props: PropTypes) {
  const [users, setUsers] = useState(null);
  const [ccEnabled, setCcEnabled] = useState(props.cc?.length > 0);
  const [bccEnabled, setBccEnabled] = useState(props.bcc?.length > 0);

  useEffect(() => {
    (async () => {
      if (users == null) {
        setUsers(
          (
            await UserService.list({
              ignorePagination: true,
            })
          ).users,
        );
      }
    })();
  }, [users]);

  return (
    <Fragment>
      <HeaderRow label='To'>
        <To
          bccEnabled={bccEnabled}
          ccEnabled={ccEnabled}
          to={props.to}
          setBccEnabled={setBccEnabled}
          setCcEnabled={setCcEnabled}
        />
      </HeaderRow>
      {ccEnabled && (
        <HeaderRow label='Cc'>
          <EmailRecipientCreatableMultiSelect
            placeholder={'Insert cc'}
            users={users}
            selected={props.cc}
            onChange={props.setCc}
          />
        </HeaderRow>
      )}
      {bccEnabled && (
        <HeaderRow label='Bcc'>
          <EmailRecipientCreatableMultiSelect
            placeholder={'Insert bcc'}
            users={users}
            selected={props.bcc}
            onChange={props.setBcc}
          />
        </HeaderRow>
      )}
      <EmailTemplates
        emailTemplate={props.emailTemplate}
        initialState={() => EmailTemplateService.list()}
        setEmailTemplate={props.setEmailTemplate}
      />
      <Subject
        replyToEmail={props.replyToEmail}
        emailTemplate={props.emailTemplate}
        subjectJson={props.subjectJson}
        setSubjectJson={props.setSubjectJson}
        setSubjectText={props.setSubjectText}
        setIsValid={props.setIsValid}
      />
    </Fragment>
  );
}
