import React from 'react';
import { Button, Input } from 'reactstrap';
import { IconSpan } from '../../../../../../../../components/IconSpan';

interface PropTypes {
  regeneratePrompt: string;
  onCancel: () => void;
  onRegenerate: (value: string) => void;
  setRegeneratePrompt: (value: string) => void;
}

export function RegeneratePanel(props: PropTypes) {
  return (
    <div className='my-2 pb-1'>
      <Input
        value={props.regeneratePrompt}
        type='text'
        className='form-control fs-5'
        placeholder='Regenerate or add context to improve the AI response...'
        onChange={(v) => props.setRegeneratePrompt(v.target.value)}
      />
      <div className='d-flex justify-content-end mt-2 pt-1'>
        <Button
          type='button'
          color='danger'
          onClick={props.onCancel}
          size='sm'
          className='me-2'
        >
          Cancel
        </Button>
        <Button
          type='button'
          color='primary'
          onClick={() => props.onRegenerate(props.regeneratePrompt)}
          size='sm'
          className='ms-1'
        >
          <IconSpan
            spanText='Regenerate'
            icon={{
              name: 'bi-arrow-repeat',
            }}
          />
        </Button>
      </div>
    </div>
  );
}
