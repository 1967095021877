import classNames from 'classnames';
import styles from './styles.module.scss';
import React from 'react';
import { SurveyMetrics } from '../../../entities/survey_engine/SurveyMetrics';
import { CardTitle } from '../../Card';

interface PropTypes {
  surveyMetrics: SurveyMetrics;
  benchmarkLabel: string;
}

export function SurveyMetricsTitle(props: PropTypes) {
  return (
    <div className='d-flex'>
      <div className={classNames('mb-0')}>
        <CardTitle>Survey Metrics</CardTitle>
      </div>
      <div
        className='ms-auto d-flex text-end'
        data-testid='benchmark-survey-metrics'
      >
        <small className='d-block text-muted'>{props.benchmarkLabel}</small>
        <span className={classNames(styles.activity, 'mx-2')}>
          <i className='bi bi-activity'></i>
        </span>
        <span className='fw-semibold d-inline-block mb-0 fs-5'>
          {props.surveyMetrics?.completionRateBenchmark
            ? `${Number(props.surveyMetrics.completionRateBenchmark).toFixed(
                0,
              )}%`
            : 'N/A'}
        </span>
      </div>
    </div>
  );
}
