import React, { useState } from 'react';
import { CloseableModal } from '../../../../components/CloseableModal';
import { AlertObject, AlertType } from '../../../../components/Alert';
import { Button, Col, Row } from 'reactstrap';
import { IconSpan } from '../../../../components/IconSpan';
import { Chip, ChipColor } from '../../../../components/Chip';
import { ApplicationService } from '../../../../services/applicant_tracking/ApplicationService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';
import { getHrisName } from '../../../../utils/hris';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { getLabelForCount } from '../../../../utils/grammar';
import { AlertMessage } from '../../../../components/AlertMessage';

interface PropTypes {
  applicationIds: number[];
  allowedHrisProvider: HrisProvider;
  isOpen: boolean;
  onClose: (succeeded: boolean) => void;
  setAlert: (alert: AlertObject) => void;
  candidateName?: string;
  jobName?: string;
}

function ChipDetail(props: { fieldName: string }) {
  return (
    <Chip className='mx-1 mb-2h' color={ChipColor.SuccessLight}>
      {props.fieldName}
    </Chip>
  );
}

function buildModalMessage(
  applicationIds: number[],
  candidateName?: string,
  jobName?: string,
) {
  if (candidateName) {
    return `You selected ${candidateName} from the ${jobName} job to be exported.`;
  }

  const applicationCount = applicationIds.length;

  return `You selected ${getLabelForCount(
    'hired candidate',
    applicationCount,
  )} to be exported.`;
}

function ModalBody(props: {
  applicationIds: number[];
  hrisProvider: HrisProvider;
  onClose: (succeeded: boolean) => void;
  onSubmit: () => void;
  candidateName?: string;
  jobName?: string;
}) {
  return (
    <>
      <AlertMessage
        className={'rounded-1 mx-0 w-100 bg-blue-gray-light'}
        hasOverlay={true}
        text={
          <div className={'px-1 py-2h'}>
            <IconSpan
              icon={{ name: 'bi-info-circle', className: 'fs-4' }}
              spanText={buildModalMessage(
                props.applicationIds,
                props.candidateName,
                props.jobName,
              )}
              spanClassName={'fw-bold fs-5'}
            />
            <p
              className={'ms-4 fs-5 mb-0'}
            >{`The below candidate details will be shared with ${getHrisName(
              props.hrisProvider,
            )} (if available).`}</p>
          </div>
        }
      />
      <Row className={'mt-4 mx-0'}>
        <Col md={6}>
          <label className={'fw-bold fs-5 mb-2h w-100'}>
            Personal Information
          </label>
          <ChipDetail fieldName={'First Name'} />
          <ChipDetail fieldName={'Last Name'} />
          <ChipDetail fieldName={'Email Address'} />
          <ChipDetail fieldName={'Phone Number'} />
          <ChipDetail fieldName={'Location'} />
        </Col>
        <Col md={6}>
          <label className={'fw-bold fs-5 mb-2h w-100'}>
            Professional Information
          </label>
          <ChipDetail fieldName={'Job Title'} />
          <ChipDetail fieldName={'CV'} />
          <ChipDetail fieldName={'Cover Letter'} />
          <ChipDetail fieldName={'Department'} />
          <ChipDetail fieldName={'Office'} />
          <ChipDetail fieldName={'Background Check'} />
          <ChipDetail fieldName={'Reference Check'} />
        </Col>
      </Row>
      <div className='d-flex mt-5h justify-content-end'>
        <Button
          className='me-2'
          color='secondary'
          onClick={() => props.onClose(false)}
        >
          Cancel
        </Button>
        <Button color='primary' type='submit' onClick={props.onSubmit}>
          Export
        </Button>
      </div>
    </>
  );
}

function getAlertSuccessMessage(
  hrisProvider: HrisProvider,
  applicationIds: number[],
) {
  const applicationsCount = applicationIds.length;

  if (applicationsCount == 1)
    return `The candidate was successfully exported to ${getHrisName(
      hrisProvider,
    )}!`;

  return `${applicationsCount} candidates were successfully exported to ${getHrisName(
    hrisProvider,
  )}!`;
}

function getAlertErrorMessage(
  hrisProvider: HrisProvider,
  applicationIds: number[],
) {
  const applicationsCount = applicationIds.length;

  return `The ${
    applicationsCount > 1 ? 'candidates' : 'candidate'
  } could not be exported to ${getHrisName(hrisProvider)}!`;
}

export function PushToHrisModal(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(false);

  const onPushingToHris = async () => {
    try {
      if (props.applicationIds?.length > 0) {
        setIsLoading(true);

        await ApplicationService.pushToHris({
          applicationIds: props.applicationIds,
        });

        props.setAlert({
          type: AlertType.Success,
          message: getAlertSuccessMessage(
            props.allowedHrisProvider,
            props.applicationIds,
          ),
        });

        props.onClose(true);
      }
    } catch (e) {
      props.setAlert({
        type: AlertType.Danger,
        message: getAlertErrorMessage(
          props.allowedHrisProvider,
          props.applicationIds,
        ),
      });

      props.onClose(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (!props.isOpen) return null;

  return (
    <CloseableModal
      className={classNames('modal-dialog-centered', styles['modal'])}
      onClose={() => props.onClose(null)}
      isOpen={true}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle={`Export to ${getHrisName(props.allowedHrisProvider)}`}
      bodyChildren={
        <>
          {isLoading && <LoadingSpinner showBackdrop={true} />}
          <ModalBody
            applicationIds={props.applicationIds}
            candidateName={props.candidateName}
            jobName={props.jobName}
            hrisProvider={props.allowedHrisProvider}
            onClose={props.onClose}
            onSubmit={onPushingToHris}
          />
        </>
      }
    />
  );
}
