import React from 'react';

export const HEALTH_BAR_DEFAULT_COLORS = {
  0.0: '#EC776C',
  0.45: '#F2994A',
  0.75: '#51B37F',
};

const DEFAULT_MAX_VALUE = 1.0;

interface PropTypes {
  value: number;
  maxValue?: number;
  colors?: { [k: number]: string };
}

export function findColor(
  value: number,
  maxValue?: number,
  colors?: { [k: number]: string },
) {
  maxValue = maxValue || DEFAULT_MAX_VALUE;
  colors = colors || HEALTH_BAR_DEFAULT_COLORS;

  const colorKeys = Object.keys(colors);
  const colorIndex =
    colorKeys.findIndex((v) => parseFloat(v) > value) || colorKeys.length;
  const colorKeyIndex =
    colorIndex === -1 ? colorKeys.length - 1 : colorIndex - 1;

  return colors[colorKeys[colorKeyIndex]];
}

export const HealthBar = React.memo(function HealthBar(props: PropTypes) {
  if (props.maxValue === 0) {
    console.error("Invalid prop maxValue. Can't be 0");
    return null;
  }

  const color = findColor(props.value, props.maxValue, props.colors);
  const perc = (props.value / (props.maxValue || DEFAULT_MAX_VALUE)) * 100;

  return (
    <div
      style={{
        backgroundColor: '#EFF0FD',
        width: '100%',
        height: '.75rem',
        borderRadius: '.625rem',
      }}
    >
      <div
        style={{
          backgroundColor: color,
          width: `${perc}%`,
          height: '100%',
          borderRadius: '.625rem',
        }}
      />
    </div>
  );
});
