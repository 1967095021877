import React from 'react';
import { RequisitionType } from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { RadioPillGroup } from '../../../../../components/RadioPillGroup';
import { RadioPill } from '../../../../../components/RadioPill';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { titleCase } from 'humanize-plus';

interface PropTypes {
  requisitionType: RequisitionType;
  readOnly?: boolean;
  offerFlowFfEnabled?: boolean;
  onRequisitionTypeChange: (requisitionType: RequisitionType) => void;
}

function ReadOnlyChip(props: { requisitionType: RequisitionType }) {
  return (
    <Chip className='fw-semibold mt-2' color={ChipColor.OrangeLight} size='sm'>
      {titleCase(props.requisitionType)} Requisition
    </Chip>
  );
}

function InputChip(props: PropTypes) {
  return (
    <RadioPillGroup
      selectedValue={props.requisitionType}
      childrenClassName='me-2 fs-6 fw-semibold'
      onChange={(e) =>
        props.onRequisitionTypeChange(e.currentTarget.value as RequisitionType)
      }
    >
      <RadioPill
        value='offer'
        id='offer'
        label='Offer Requisition'
        selectedValue={props.requisitionType}
      />
      <RadioPill
        value='job'
        id='job'
        label='Job Requisition'
        selectedValue={props.requisitionType}
      />
    </RadioPillGroup>
  );
}

export function RequisitionTypeSelector(props: PropTypes) {
  return (
    <>
      <div className='fw-semibold fs-5 mt-4 mb-1'>Approval Type</div>
      <div className='d-flex'>
        {props.readOnly || !props.offerFlowFfEnabled ? (
          <ReadOnlyChip {...props} />
        ) : (
          <InputChip {...props} />
        )}
      </div>
    </>
  );
}
