import React, { useState } from 'react';
import { AlertObject } from '../../../../components/Alert';
import {
  MoveCandidateProps,
  MoveCandidateStageModal,
} from '../../../JobPage/components/MoveCandidateStageModal';
import {
  Application,
  ApplicationStatus,
} from '../../../../entities/Application';
import { alertManager } from '../../../JobPage/components/MoveCandidateStageModal/alertManager';
import { ActionButton } from '../ActionButton';

interface PropTypes {
  application: Application;
  disabled: boolean;
  onMoveCandidateStage: (status: string) => void;
  reloadApplication: () => void;
  setAlert: (alert: AlertObject) => void;
}

export function MoveCandidateToStageButton(props: PropTypes) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMoveCandidateToStage = (action: MoveCandidateProps) => {
    setIsModalOpen(false);
    if (action?.state) {
      alertManager(action, props.setAlert);
      props.onMoveCandidateStage(action.state);
    }
    props.reloadApplication();
  };

  return (
    <>
      <ActionButton
        iconName={'bi-arrow-return-right'}
        onClick={() => {
          setIsModalOpen(true);
        }}
        disabled={
          props.disabled ||
          props.application.status === ApplicationStatus.Rejected
        }
      />
      <MoveCandidateStageModal
        applicationId={props.application.id}
        isOpen={isModalOpen}
        candidateName={props.application.candidate.name}
        currentStage={props.application.job_stage_name}
        jobId={`${props.application.job.id}`}
        onClose={handleMoveCandidateToStage}
      />
    </>
  );
}
