import React from 'react';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';
import { getRedirectUriQueryParam } from '../../../../utils/url';

interface PropTypes {
  title: string;
  isSavable: boolean;
  cancelUrl: string;
  saveText?: string;
  titleClassNames?: string;
  classNames?: string;
  onSaveClick: () => void;
}

export function CardHeader(props: PropTypes) {
  return (
    <SetupPageHeader
      title={props.title}
      firstItems={[]}
      titleClassNames={props.titleClassNames}
      classNames={props.classNames}
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: () =>
            (window.location.href =
              getRedirectUriQueryParam() ?? props.cancelUrl),
        },
        {
          title: props.saveText ?? 'Save',
          type: 'Save',
          disabled: !props.isSavable,
          action: () => props.onSaveClick(),
        },
      ]}
    />
  );
}
