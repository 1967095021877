import classNames from 'classnames';
import React from 'react';
import { BackArrow } from '../../../components/BackArrow';
import { InterviewRatingResponse } from '../../InterviewRatingResponse';
import { Interview } from '../../../entities/Interview';
import { InterviewRating } from '../../../entities/InterviewRating';
import { longFormatDate } from '../../../utils/timeFormat';
import { PageTitle } from '../../../components/PageTitle';
import { InterviewRatingButton } from './InterviewRatingButton';
import { IconButton } from '../../../components/IconButton';

interface PropTypes {
  atsEnabled: boolean;
  interview: Interview;
  interviewMediaPresent: boolean;
  interviewRatingEnabled: boolean;
  ratingResponses: InterviewRating[];
  scorecardUrl: string;
  onRatingClick: () => void;
  onShareClick: () => void;
}

function NavActions(props: PropTypes) {
  const applicationId = props.atsEnabled
    ? props.interview.application.external_id
    : props.interview.application.id;

  return (
    <div
      className='d-flex gap-2 align-items-center'
      data-testid='interview-page-nav-actions'
    >
      <InterviewRatingButton {...props} applicationId={applicationId} />
      <IconButton
        onClick={() =>
          (window.location.href = `/applications/${applicationId}`)
        }
        iconName='bi-person'
        buttonText='View Candidate'
        color='borderless'
      />
      {props.interviewMediaPresent && (
        <IconButton
          dataTestId='interview-share'
          onClick={props.onShareClick}
          iconName='bi-share'
          buttonText='Share'
          color='borderless'
        />
      )}
    </div>
  );
}

export default function TopNavbar(props: PropTypes) {
  return (
    <div
      id='interviews'
      className='d-flex gap-3 mb-4 justify-content-between align-items-center'
    >
      <div className='d-flex me-auto align-items-center'>
        <BackArrow />
        <PageTitle
          text={props.interview.candidate.name}
          classNames={'text-truncate'}
          dataTestId={'interview-title'}
        />
        <label
          className={classNames(
            'rounded-pill bg-white mx-3 px-2h py-1 fs-6 text-truncate',
          )}
          data-testid='interview-job'
        >
          {props.interview.application.job.name}
        </label>
        <label
          className={classNames(
            'rounded-pill bg-white mx-3 px-2h py-1 fs-6 ms-0 text-truncate',
          )}
          data-testid='interview-date'
        >
          <i className='bi bi-calendar me-2'></i>
          {longFormatDate(props.interview.scheduled_date.toString())}
        </label>
        <InterviewRatingResponse
          interviewers={props.interview.interviewers}
          responses={props.ratingResponses}
          ratingOptions={props.interview.rating_options}
          classNames='bg-white mx-3'
        />
      </div>
      <NavActions {...props} />
    </div>
  );
}
