import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { getContrastColor } from '../../../utils/colors';

interface PropTypes {
  progress: number;
  progressWrapperClassName?: string;
  progressClassName?: string;
  color?: string;
  backgroundColor?: string;
}

function progressStyle(
  progress: number,
  color?: string,
  textColor?: string,
  progressBarColor?: string,
) {
  if (color != null) {
    return {
      width: `${progress}%`,
      color: textColor,
      backgroundColor: progressBarColor,
    };
  } else {
    return { width: `${progress}%` };
  }
}

export function ProgressBar(props: PropTypes) {
  const backgroundColor =
    props.backgroundColor != null ? props.backgroundColor : '#f6f8ff';
  const progressBarColor = props.color != null ? props.color : null;
  const textColor =
    props.color != null ? getContrastColor(progressBarColor) : null;

  return (
    <div
      className={classNames(styles.wrapper, props.progressWrapperClassName)}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={classNames(styles.progress, props.progressClassName)}
        style={progressStyle(
          props.progress,
          props.color,
          textColor,
          progressBarColor,
        )}
      >
        {Math.floor(props.progress)}%
      </div>
    </div>
  );
}
