import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface PropTypes {
  iconClass?: string;
  entity?: string;
}

export const AlertHighlight = React.memo(function Alert(props: PropTypes) {
  return (
    <div
      className={classNames(
        'p-2',
        'justify-content-center',
        'alert-info',
        'd-flex',
        'fs-6',
        styles.highlightAlert,
      )}
    >
      <div>
        {props.iconClass && (
          <span>
            <i className={props.iconClass}></i>
          </span>
        )}
        {props.entity} {props.entity ? 'd' : 'D'}etected through{' '}
        <b>
          <a
            target='_blank'
            className='text-info'
            href='https://www.screenloop.com/'
            rel='noreferrer'
          >
            Screenloop
          </a>
        </b>{' '}
        AI technology
      </div>
    </div>
  );
});
