import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import PhoneInputWithCountryReactForm from 'react-phone-number-input/react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input';
import { Control, UseFormRegisterReturn } from 'react-hook-form';

interface PropTypes {
  name: string;
  placeholder: string;
  disabled?: boolean;
  required?: boolean;
  formRegisterReturn?: UseFormRegisterReturn;
  control?: Control;
  onChange?: (value: string) => void;
  className?: string;
}

export function PhoneInput(props: PropTypes) {
  const disabled = props.disabled ?? false;

  return props.control ? (
    <PhoneInputWithCountryReactForm
      rules={{ required: props.required }}
      name={props.name}
      control={props.control}
      placeholder={props.placeholder}
      className={classNames(
        'mx-0',
        styles['phone-input'],
        { [styles['phone-input-disabled']]: disabled },
        'form-control',
        props.className,
      )}
      disabled={disabled}
    />
  ) : (
    <PhoneInputWithCountry
      rules={{ required: props.required }}
      name={props.name}
      placeholder={props.placeholder}
      className={classNames(
        'mx-0',
        styles['phone-input'],
        { [styles['phone-input-disabled']]: disabled },
        'form-control',
        props.className,
      )}
      disabled={disabled}
      onChange={props.onChange}
    />
  );
}
