import React, { useEffect, useRef, useState } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { Media } from '../../entities/Media';
import { MediaType } from '../../entities/MediaType';
import noVideoPlaceholder from '../../images/placeholders/screenloop-novideo-placeholder.svg';
import videoPlayerPlaceholder from '../../images/placeholders/screenloop-video-placeholder-v2.svg';
import classNames from 'classnames';

export interface PropTypes {
  media: Media;
  isPlaying: boolean;
  playerOptions: VideoJsPlayerOptions;
  startTime?: number;
  volume: number;
  muted: boolean;
  fullScreen?: boolean;
  playbackRate: number;
  className?: string;
  onPlayTimestampChange?: (v: number) => void;
  onPlayingChange?: (isPlaying: boolean) => void;
  onVolumeChange?: (volume: number, muted: boolean) => void;
  onPlaybackRateChange?: (playbackRate: number) => void;
  onFullscreenChange?: (fullScreen: boolean) => void;
}

function getTypePlayerOptions(
  media: Media,
  playerOptions: VideoJsPlayerOptions,
) {
  return {
    ...playerOptions,
    poster:
      media.type === MediaType.Video
        ? videoPlayerPlaceholder
        : noVideoPlaceholder,
    sources: [
      {
        src: media.url,
        type: media.type === MediaType.Video ? 'video/mp4' : 'audio/mp3',
      },
    ],
  };
}

export function MediaPlayer(props: PropTypes) {
  const mediaRef = useRef();
  const [player, setPlayer] = useState<VideoJsPlayer>();
  const [isFirstPlayback, setIsFirstPlayback] = useState(true);

  useEffect(() => {
    if (mediaRef.current === undefined) return;

    const player = videojs(
      mediaRef.current,
      getTypePlayerOptions(props.media, props.playerOptions),
    );
    setPlayer(player);
    player.on('volumechange', () => {
      if (props.onVolumeChange) {
        props.onVolumeChange(player.volume() * 100, player.muted());
      }
    });

    player.on('ratechange', () => {
      if (props.onPlaybackRateChange) player.playbackRate();
    });

    player.on('ended', () => {
      if (props.onPlayingChange) props.onPlayingChange(false);
    });

    player.on('fullscreenchange', () => {
      if (props.onFullscreenChange)
        props.onFullscreenChange(player.isFullscreen());
    });

    const checkInterval = setInterval(() => {
      if (!player) return;

      if (props.onPlayTimestampChange) {
        props.onPlayTimestampChange(player.currentTime() * 1000);
      }
      if (props.isPlaying === player.paused() && props.onPlayingChange) {
        props.onPlayingChange(!player.paused());
      }
    }, 500);

    return () => clearInterval(checkInterval);
  }, [mediaRef, props.isPlaying, props.onPlayingChange]);

  useEffect(() => {
    if (player === undefined || props.startTime === undefined) return;
    player.currentTime(props.startTime / 1000);
  }, [player, props.startTime]);

  // The useEffect() hook below, that controls playback, executes after the
  // component is rendered, on another tick of the event loop. Since this tick
  // isn't associated with the user's onClick event on the play button, Safari
  // disallows playback. This ensures that play() is called on the render tick.
  if (isFirstPlayback && player && props.isPlaying) {
    player.play();
    setIsFirstPlayback(false);
  }

  useEffect(() => {
    if (player === undefined || isFirstPlayback) return;

    props.isPlaying ? player.play() : player.pause();
    player.volume(props.volume / 100.0);
    player.muted(props.muted);
    player.playbackRate(props.playbackRate);
  }, [
    player,
    props.isPlaying,
    props.volume,
    props.muted,
    props.playbackRate,
    isFirstPlayback,
  ]);

  useEffect(() => {
    if (player === undefined) return;

    props.fullScreen && player.requestFullscreen();
    player.controls(props.fullScreen);
  }, [props.fullScreen]);

  return (
    <div data-vjs-player>
      {props.media.type === MediaType.Video && (
        <video
          ref={mediaRef}
          className={classNames('video-js vjs-default-skin', props.className)}
        />
      )}
      {props.media.type === MediaType.Audio && (
        <audio ref={mediaRef} className='video-js vjs-default-skin' />
      )}
    </div>
  );
}
