import classNames from 'classnames';
import React from 'react';
import { Row } from 'reactstrap';
import { ActionPoint } from '../../entities/ActionPoint';
import { Interview } from '../../entities/Interview';
import { ActionPointService } from '../../services/ActionPointService';
import { IconButton } from '../IconButton';

interface PropTypes {
  interview: Interview;
  atsEnabled: boolean;
  onUpdateActionPoints: (actionPoints: ActionPoint[]) => void;
  className?: string;
  openExternalLink?: boolean;
}

interface ActionPointIntentPropTypes {
  actionPoint: ActionPoint;
  atsEnabled: boolean;
}

function getIntentIcon(props: ActionPointIntentPropTypes) {
  switch (props.actionPoint.actionIntent) {
    case 'scorecard_url':
      return props.atsEnabled
        ? 'bi-clipboard-check fs-6'
        : 'bi-box-arrow-in-up-right';
  }
}

function getIntentLabel(props: ActionPointIntentPropTypes) {
  switch (props.actionPoint.actionIntent) {
    case 'scorecard_url':
      return props.atsEnabled ? 'View Scorecard' : 'Open in ATS';
  }
}

function ActionPointIntent(props: ActionPointIntentPropTypes) {
  return (
    <IconButton
      onClick={() => {
        window.open(
          props.actionPoint.actionIntentValue,
          props.atsEnabled ? '_blank' : '_self',
          'noopener,noreferrer',
        );
      }}
      color='borderless'
      iconName={getIntentIcon(props)}
      size='sm'
      buttonText={getIntentLabel(props)}
    />
  );
}

function disableInteraction(
  allowInteraction: boolean,
  completed: boolean,
): boolean {
  if (!allowInteraction) {
    return true;
  }

  return completed;
}

function ActionPointLabel(props: {
  onUpdateActionPoint: (id: number) => void;
  actionPoint: ActionPoint;
}) {
  return (
    <div
      className={classNames(
        'd-flex',
        'gap-2',
        'flex-nowrap',
        'align-items-top',
      )}
    >
      <input
        className='form-check-input'
        type='checkbox'
        onChange={() => props.onUpdateActionPoint(props.actionPoint.id)}
        checked={props.actionPoint.completed}
        disabled={disableInteraction(
          props.actionPoint.allowInteraction,
          props.actionPoint.completed,
        )}
        tabIndex={-1}
      />
      <div className={classNames('fs-5')}>{props.actionPoint.actionName}</div>
    </div>
  );
}

function ActionPointItem(props: {
  onUpdateActionPoint: (id: number) => void;
  actionPoint: ActionPoint;
  atsEnabled: boolean;
  openExternalLink: boolean;
}) {
  return props.openExternalLink && props.actionPoint.actionIntent ? (
    <div
      className={classNames('d-flex', 'flex-nowrap', 'justify-content-between')}
    >
      <ActionPointLabel {...props} />
      <ActionPointIntent {...props} />
    </div>
  ) : (
    <ActionPointLabel {...props} />
  );
}

export function ActionPoints(props: PropTypes) {
  if (
    props.interview.actionPoints == null ||
    props.interview.actionPoints?.length === 0
  ) {
    return null;
  }

  async function handleUpdateActionPoint(updatedActionPointId: number) {
    const updatedActionPoint = await ActionPointService.complete(
      updatedActionPointId,
    );

    const updatedActionPoints: ActionPoint[] = props.interview.actionPoints.map(
      (actionPoint) => {
        if (actionPoint.id === updatedActionPoint.id) {
          return updatedActionPoint;
        }

        return actionPoint;
      },
    );

    props.onUpdateActionPoints(updatedActionPoints);
  }

  return (
    <div
      className={classNames('d-flex', 'flex-column', 'gap-3', props.className)}
    >
      <Row className='gy-3'>
        {props.interview.actionPoints
          .sort((a, b) => a.order - b.order)
          .map((actionPoint: ActionPoint, index: number) => (
            <ActionPointItem
              key={index}
              onUpdateActionPoint={handleUpdateActionPoint}
              actionPoint={actionPoint}
              atsEnabled={props.atsEnabled}
              openExternalLink={props.openExternalLink}
            />
          ))}
      </Row>
    </div>
  );
}
