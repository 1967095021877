import React from 'react';
import classNames from 'classnames';
import { FORMAT_TEXT_COMMAND, TextFormatType } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { IS_APPLE } from '../../../../../utils/IsApple';
import { Icon } from '../../../../Icon';
import { capitalize } from '../../../../../utils/capitalize';

interface PropTypes {
  isEditable: boolean;
  isActive: boolean;
  commandName: TextFormatType;
  shortcutLetter: string;
  classNames?: string;
}

export function FormatTextButton(props: PropTypes) {
  const [editor] = useLexicalComposerContext();
  const macOsShortcut = `⌘${props.shortcutLetter}`;
  const normalShortcut = `Ctrl+${props.shortcutLetter}`;

  return (
    <button
      disabled={!props.isEditable}
      onClick={() => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, props.commandName);
      }}
      className={classNames(props.classNames, 'mx-1', {
        active: props.isActive,
      })}
      title={
        IS_APPLE
          ? `${capitalize(props.commandName)} (${macOsShortcut})`
          : `${capitalize(props.commandName)} (${normalShortcut})`
      }
      type='button'
      aria-label={`Format text as ${props.commandName}. Shortcut: ${
        IS_APPLE ? macOsShortcut : normalShortcut
      }`}
    >
      <Icon name={`bi-type-${props.commandName}`} />
    </button>
  );
}
