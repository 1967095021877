import React from 'react';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { ApprovalFlowStep } from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { AddApprovalStepButton } from '../AddApprovalStepButton';

export function Header(props: {
  steps: ApprovalFlowStep[];
  readOnly?: boolean;
  onClick: () => void;
}) {
  return (
    <div className='d-flex justify-content-between align-items-center mb-4'>
      <div>
        <div className='d-flex align-items-center'>
          <span className='fs-3 fw-semibold me-2'>Approval Steps</span>
          <InfoTooltip>
            Each step requires the approval of only one of the listed approvers.
          </InfoTooltip>
        </div>
        <div className='fs-5 text-dark-200 mt-2'>
          Define and manage your approval steps
        </div>
      </div>
      {!props.readOnly && (
        <AddApprovalStepButton steps={props.steps} onClick={props.onClick} />
      )}
    </div>
  );
}
