import React from 'react';
import classNames from 'classnames';
import styles from '../../styles.module.scss';

interface PropTypes {
  title: string;
  constraint?: string;
  mandatory?: boolean;
}

export function FormHeader(props: PropTypes) {
  return (
    <div
      className={classNames(
        styles['reject-reason-container'],
        'd-flex fw-semibold fs-5 mt-4 mb-2 justify-content-between align-items-center',
      )}
    >
      <div>
        {props.title}{' '}
        {props.mandatory && <span className='text-danger'>*</span>}
      </div>
      <div className='fs-6 text-dark-200 fw-normal'>{props.constraint}</div>
    </div>
  );
}
