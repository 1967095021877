import React from 'react';
import { Col, Row } from 'reactstrap';
import { SurveyButton } from '../../../../components/SurveyButton';
import { SurveyQuestionField } from '../../../../entities/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../entities/SurveyQuestionFieldAnswer';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer: SurveyQuestionFieldAnswer;
  onChangeAnswer: (fieldId: number, value: string) => void;
}

export function RatingField({ field, fieldAnswer, onChangeAnswer }: PropTypes) {
  return (
    <Row className='mt-4 align-items-center'>
      {field.name && (
        <Col xs='2'>
          <b>{field.name}</b>
        </Col>
      )}
      <Col xs='10' className='d-flex gap-3'>
        <SurveyButton
          active={fieldAnswer.value === '1'}
          onClick={() => onChangeAnswer(field.id, '1')}
        >
          1. Okay
        </SurveyButton>
        <SurveyButton
          active={fieldAnswer.value === '2'}
          onClick={() => onChangeAnswer(field.id, '2')}
        >
          2. Good
        </SurveyButton>
        <SurveyButton
          active={fieldAnswer.value === '3'}
          onClick={() => onChangeAnswer(field.id, '3')}
        >
          3. Really Good
        </SurveyButton>
        <SurveyButton
          active={fieldAnswer.value === '4'}
          onClick={() => onChangeAnswer(field.id, '4')}
        >
          4. Great
        </SurveyButton>
        <SurveyButton
          active={fieldAnswer.value === '5'}
          onClick={() => onChangeAnswer(field.id, '5')}
        >
          5. Best
        </SurveyButton>
      </Col>
    </Row>
  );
}
