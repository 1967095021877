import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { CheckboxPillGroupFormHook } from '../../CheckboxPillGroupFormHook';
import { useForm } from 'react-hook-form';
import { Icon } from '../../Icon';
import { buildScheduleInterviewPagePath } from '../../../utils/applicant_tracking/actions/schedulingActions';
import { appendCurrentRedirectUri } from '../../../utils/url';

const INTERVIEWER_SELECTS_SLOT_INFO =
  'The time of the interview will be set by you, according to what you have agreed with the candidate.';
const CANDIDATE_SELECTS_SLOT_INFO =
  'The time of the interview will be set by the candidate, according to the interviewers availability.';

interface PropTypes {
  isOpen: boolean;
  applicationId: number;
  interviewStageId: number;
  onClose: () => void;
}

enum ScheduleType {
  Interviewer = 'Interviewer',
  Candidate = 'Candidate',
}

export function ModalBody(props: PropTypes) {
  const [selectedOption, setSelectedOption] = useState<string>(
    ScheduleType.Interviewer,
  );
  const [infoText, setInfoText] = useState<string>(
    INTERVIEWER_SELECTS_SLOT_INFO,
  );
  const { resetField, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      scheduleType: ScheduleType.Interviewer,
    },
  });

  useEffect(() => {
    setInfoText(
      selectedOption === ScheduleType.Interviewer
        ? INTERVIEWER_SELECTS_SLOT_INFO
        : CANDIDATE_SELECTS_SLOT_INFO,
    );
  }, [selectedOption]);

  const handleSubmit = () => {
    const redirectURL = buildScheduleInterviewPagePath(
      props.applicationId,
      props.interviewStageId,
      selectedOption === ScheduleType.Candidate,
    );

    window.location.href = appendCurrentRedirectUri(redirectURL);
  };

  return (
    <>
      <div className='mb-4 fs-5'>
        <div className='mb-3'>
          <b>Choose how do you want to schedule this interview</b>
        </div>
        <form
          onChange={(e) =>
            setSelectedOption((e.target as HTMLInputElement).value)
          }
        >
          <CheckboxPillGroupFormHook
            formHookContext={{
              controllerName: 'scheduleType',
              formControl: control,
              required: true,
            }}
            childrenClassNames='fs-6 fw-semibold me-2 px-2h'
            resetField={resetField}
            childrenProps={[
              {
                id: ScheduleType.Interviewer,
                label: 'You pick the time for the candidate',
                value: ScheduleType.Interviewer,
              },
              {
                id: ScheduleType.Candidate,
                label: 'Candidate picks the time',
                value: ScheduleType.Candidate,
              },
            ]}
          />
        </form>
      </div>
      <div className='fs-5 fw-semibold'>
        <Icon name='bi-info-circle' /> {infoText}
      </div>
      <div className='d-flex mt-5 justify-content-end'>
        <Button color='danger' className='me-2' onClick={props.onClose}>
          Cancel
        </Button>
        <Button color='primary' onClick={handleSubmit}>
          Schedule
        </Button>
      </div>
    </>
  );
}
