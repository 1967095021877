import React from 'react';
import { Col, Row } from 'reactstrap';
import { SurveyButton } from '../../../../components/SurveyButton';
import { SurveyQuestionField } from '../../../../entities/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../entities/SurveyQuestionFieldAnswer';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer: SurveyQuestionFieldAnswer;
  onChangeAnswer: (fieldId: number, value: string, optionIds: number[]) => void;
}

export function OptionSetField({
  field,
  fieldAnswer,
  onChangeAnswer,
}: PropTypes) {
  return (
    <Row className='mt-4 align-items-center'>
      {field.name && (
        <Col md='2'>
          <b>{field.name}</b>
        </Col>
      )}
      <Col xs='10' className='d-flex gap-3'>
        {field.options
          .sort((a, b) => a.weight - b.weight)
          .map((opt) => (
            <SurveyButton
              key={opt.id}
              active={fieldAnswer.value === opt.weight.toString()}
              onClick={() =>
                onChangeAnswer(field.id, opt.weight.toString(), [opt.id])
              }
            >
              {`${opt.weight}. ${opt.name}`}
            </SurveyButton>
          ))}
      </Col>
    </Row>
  );
}
