import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import { Condition } from '../../..';
import { SelectOption } from '../../../../../../../components/Select';
import styles from './styles.module.scss';

interface PropTypes {
  selectedSurveyTemplate: SelectOption;
  conditions: Condition[];
  applicableConditions: SelectOption[];
  setConditions: (conditions: Condition[]) => void;
}

export function AddConditionButtonRow(props: PropTypes) {
  function handleAddConditionClick() {
    props.setConditions([
      ...props.conditions,
      {
        type: { value: null, label: 'Select Condition' },
        available: [],
        mandatory: false,
        singleSelected: null,
        multiSelected: [],
        invert: { value: 'false', label: 'Includes' },
      },
    ]);
  }

  return (
    <div className='row pt-4'>
      <div className='col-3'>
        <Button
          className={classNames('btn btn-secondary', styles.addConditionButton)}
          disabled={
            !props.selectedSurveyTemplate ||
            props.conditions.length >= props.applicableConditions.length
          }
          onClick={() => handleAddConditionClick()}
        >
          <i
            className='bi bi-plus-circle mt-1'
            style={{ width: '1em', height: '1em', top: 0 }}
          />
          <span className='ms-2'>Add Condition</span>
        </Button>
      </div>
    </div>
  );
}
