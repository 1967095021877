import React from 'react';
import { ThematicBreak } from '../../../../components/ThematicBreak';
import { Section } from '../../../../entities/training/Section';
import { SubSection } from '../../../../entities/training/SubSection';
import { sortEntitiesByOrder } from '../../../../utils/training/sortUtils';
import { SubSectionPart } from '../SubSectionPart';

interface PropTypes {
  section: Section;
}

export function SectionPart(props: PropTypes) {
  return (
    <div data-testid='training-section' className='mt-4 mb-3'>
      <h1>{props.section.name}</h1>
      <ThematicBreak />
      {sortEntitiesByOrder<SubSection>(props.section.subSections).map(
        (subSection: SubSection, i: number) => {
          return <SubSectionPart key={i} subSection={subSection} />;
        },
      )}
    </div>
  );
}
