import classNames from 'classnames';
import React, { Fragment } from 'react';

interface RadioInput {
  label: string;
  value: string;
}

interface PropTypes {
  options: RadioInput[];
  selectedValue: string;
  disabled?: boolean;
  classNames?: string;
  onSelectInput: (selectedValue: string) => void;
}

export function RadioInputFormField(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex flex-nowrap align-items-center',
        props.classNames,
      )}
    >
      {props.options.map((option: RadioInput, index: number) => (
        <Fragment key={index}>
          <input
            className='form-check-input mt-0'
            type='radio'
            name={option.value}
            id={option.value}
            value={option.value}
            checked={option.value === props.selectedValue}
            onChange={(ev) => props.onSelectInput(ev.target.value)}
          />
          <label
            className='form-check-label align-items-center fs-5 ms-2 me-3'
            htmlFor={option.value}
          >
            {option.label}
          </label>
        </Fragment>
      ))}
    </div>
  );
}
