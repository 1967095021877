import React, { ReactNode } from 'react';
import { ApprovalFlowStep } from '../../../../../../entities/applicant_tracking/ApprovalFlow';
import { ApproverAnswer } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { ContentCircle } from '../../../../../../components/ContentCircle';

interface PropTypes {
  step: ApprovalFlowStep;
  isCurrentStep?: boolean;
  declinedStepOrder?: number;
}

interface ChipProps {
  className: string;
  children: string | ReactNode;
}

function isStepAfterDeclinedStep(
  stepOrder: number,
  declinedStepOrder?: number,
) {
  if (declinedStepOrder == null) {
    return false;
  }

  return stepOrder > declinedStepOrder;
}

function buildChipProps(
  step: ApprovalFlowStep,
  isCurrentStep?: boolean,
  declinedStepOrder?: number,
): ChipProps {
  const isApproved = step.approvers.find(
    (approver) => approver.answer === ApproverAnswer.Approved,
  );

  if (isApproved) {
    return {
      className: 'text-white bg-success-500',
      children: <i className={'bi-check-lg'} />,
    };
  }

  const isDeclined = step.approvers.find(
    (approver) => approver.answer === ApproverAnswer.Declined,
  );

  if (isDeclined) {
    return {
      className: 'text-white bg-danger-500',
      children: <i className={'bi-x-lg'} />,
    };
  }

  return {
    className: `${
      isCurrentStep && !isStepAfterDeclinedStep(step.order, declinedStepOrder)
        ? 'text-blue'
        : 'text-blue-gray-900'
    } bg-gray`,
    children: step.order,
  };
}

export function OrderCircle(props: PropTypes) {
  const chipProps = buildChipProps(
    props.step,
    props.isCurrentStep,
    props.declinedStepOrder,
  );

  return (
    <ContentCircle
      content={chipProps.children}
      className={chipProps.className}
      size={'xl'}
    />
  );
}
