import { ApiService } from '../../../services/ApiService';
import { SurveyInfo } from '../../entities/SurveyInfo';
import { SurveyRequestInfo } from '../../entities/SurveyRequestInfo';

export class CandidateSurveyService {
  public static create(
    surveyRequestReferralId: number,
    params: Partial<SurveyInfo>,
  ): Promise<SurveyRequestInfo> {
    return ApiService.post<SurveyRequestInfo>(
      `/api/reference_check/candidate/surveys`,
      {
        survey: {
          ...params,
          survey_request_referral_id: surveyRequestReferralId,
        },
      },
    );
  }
}
