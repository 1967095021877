import React from 'react';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Application } from '../../../../../../../../entities/Application';
import {
  AlertObject,
  AlertType,
} from '../../../../../../../../components/Alert';
import { ApplicationService } from '../../../../../../../../services/applicant_tracking/ApplicationService';
import { BULK_REVIEW_NAVIGATION_TYPE } from '../../../../..';

interface PropTypes {
  application: Application;
  onNext: (navType: BULK_REVIEW_NAVIGATION_TYPE) => void;
  setAlert: (alert: AlertObject) => void;
}

export function MoveApplication(props: PropTypes) {
  const moveToNextStage = async () => {
    try {
      await ApplicationService.moveStage(props.application.id);
      props.setAlert({
        type: AlertType.Success,
        message: (
          <span className='fw-semibold'>
            Candidate was moved to the next stage!
          </span>
        ),
      });
      props.onNext('moved');
    } catch (error) {
      props.setAlert({
        type: AlertType.Danger,
        message: (
          <span className='fw-semibold'>
            Application could not be moved to next stage.
          </span>
        ),
      });
    }
  };

  return (
    <IconButton
      iconName='bi-arrow-return-right'
      buttonText='Move to next Stage'
      buttonClass='me-3h'
      onClick={moveToNextStage}
      color='success'
    />
  );
}
