import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { InputFormField } from '../../../../components/InputFormField';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import classNames from 'classnames';

interface PropTypes {
  errors: FieldErrors;
  formRegister: UseFormRegister<CandidateDto>;
}

export function CandidateNameInput(props: PropTypes) {
  const required = true;

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <InputFormField
          type={'text'}
          registerReturn={props.formRegister('firstName', {
            required: required,
          })}
          fieldName={'First Name'}
          placeholder={'Insert first name'}
          isRequired={required}
          classNames={classNames(
            props.errors.firstName && 'is-invalid',
            'fs-5',
          )}
          headerClassNames={'fw-semibold'}
        />
      </Col>
      <Col xs={3}>
        <InputFormField
          type={'text'}
          registerReturn={props.formRegister('lastName', {
            required: required,
          })}
          fieldName={'Last Name'}
          placeholder={'Insert last name'}
          isRequired={required}
          classNames={classNames(props.errors.lastName && 'is-invalid', 'fs-5')}
          headerClassNames={'fw-semibold'}
        />
      </Col>
    </Row>
  );
}
