import { ApiService } from '../../ApiService';
import snakecaseKeys from 'snakecase-keys';
import { Application } from '../../../entities/applicant_tracking/Application';
import { SubmitApplicationDto } from './SubmitApplicationDto';

export class CareersService {
  public static submitApplication(
    organizationSubdomain: string,
    jobPostId: number,
    props: SubmitApplicationDto,
  ): Promise<Application> {
    return ApiService.post<Application>(
      `/api/applicant_tracking/careers/${organizationSubdomain}/job_posts/${jobPostId}/submit`,
      snakecaseKeys({
        ...props,
        customQuestionAnswers: props.customQuestionAnswers?.filter((v) => v),
      }),
    );
  }
}
