import { Input } from 'reactstrap';
import { IconButton } from '../../../../../components/IconButton';
import React from 'react';
import classNames from 'classnames';
import styles from '../../styles.module.scss';
import { REJECTED_REASON_MAX_CHARACTERS } from '../../NewRejectedReasonPage';

interface PropTypes {
  reasons: string[];
  duplicates: string[];
  readonly?: boolean;
  singleInput?: boolean;
  setReasons?: (reasons: string[]) => void;
}

interface ReasonInputProps extends PropTypes {
  reason: string;
  index: number;
}

function ReasonInput(props: ReasonInputProps) {
  const hideRemoveButton = props.readonly || props.singleInput;
  const isInvalid =
    props.duplicates.some((duplicate) => duplicate === props.reason) ||
    props.reason.length > REJECTED_REASON_MAX_CHARACTERS;

  function updateReason(value: string, index: number) {
    const newReasons = [...props.reasons];
    newReasons[index] = value;
    props.setReasons(newReasons);
  }

  function removeReason(index: number) {
    const newReasons = [...props.reasons];
    newReasons.splice(index, 1);
    props.setReasons(newReasons);
  }

  return (
    <div className='d-flex mb-2h'>
      <Input
        value={props.reason}
        placeholder='Insert reject reason'
        className={classNames(
          'fs-5',
          isInvalid ? 'is-invalid text-danger-500' : 'text-dark-200',
          styles['reject-reason-container'],
        )}
        disabled={props.readonly}
        onChange={(e) => updateReason(e.target.value, props.index)}
      />
      {!hideRemoveButton && (
        <IconButton
          color='borderless'
          buttonClass='text-dark-200'
          iconName='bi-trash'
          disabled={props.reasons.length === 1}
          onClick={() => removeReason(props.index)}
        />
      )}
    </div>
  );
}

export function ReasonsInput(props: PropTypes) {
  const handleAddInput = () => {
    props.setReasons([...props.reasons, '']);
  };

  return (
    <>
      {props.reasons.map((reason, index) => (
        <ReasonInput key={index} index={index} reason={reason} {...props} />
      ))}
      {!props.singleInput && (
        <div className='mt-1'>
          <IconButton
            color='borderless'
            iconName='bi-plus-circle'
            buttonText='Add Reject Reason'
            onClick={handleAddInput}
          />
        </div>
      )}
    </>
  );
}
