import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface PropTypes {
  url?: string;
  logoUrl: string;
  tooltipText?: string;
}

export function SidekickHeaderLogo(props: PropTypes) {
  if (props.url == null) {
    return null;
  }

  return (
    <div className={classNames('ps-1', 'm-1')}>
      <a
        target='_blank'
        data-toggle='tooltip'
        data-bs-placement='bottom'
        rel='noreferrer'
        href={props.url}
        data-bs-original-title={props.tooltipText}
      >
        <img
          className={classNames('rounded-2', 'd-block', styles.provider)}
          src={props.logoUrl}
        />
      </a>
    </div>
  );
}
