import React from 'react';
import { CandidatePulseNavLink } from '../../../../../../../components/UnderlinedNavLink';

export enum HiringTeamTab {
  All = 'all',
  Recruiter = 'recruiter',
  HiringManager = 'hiring_manager',
}

enum HiringTeamTabTitle {
  All = 'All',
  Recruiters = 'Recruiters',
  HiringManagers = 'Hiring Managers',
}

function getTabTitle(tab: HiringTeamTab) {
  switch (tab) {
    case HiringTeamTab.All:
      return HiringTeamTabTitle.All;
    case HiringTeamTab.Recruiter:
      return HiringTeamTabTitle.Recruiters;
    case HiringTeamTab.HiringManager:
      return HiringTeamTabTitle.HiringManagers;
  }
}

interface PropTypes {
  activeTab: HiringTeamTab;
  updateActiveTab: (tab: HiringTeamTab) => void;
}

export function TableTopTabs(props: PropTypes) {
  return (
    <div className='d-flex ps-4 pe-4'>
      {Object.values(HiringTeamTab).map((tab, idx) => (
        <CandidatePulseNavLink
          key={idx}
          dataTestid={tab}
          tab={tab}
          handleClick={() => props.updateActiveTab(tab)}
          tabTitle={getTabTitle(tab)}
          activeTab={props.activeTab}
        />
      ))}
    </div>
  );
}
