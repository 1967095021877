import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  title: string;
  titleClasses: string;
  label?: string;
}

export function PanelHeader(props: PropTypes) {
  return (
    <div className={classNames('d-flex', 'w-100')}>
      <div>
        {props.titleClasses != null ? (
          <div className={props.titleClasses}>{props.title}</div>
        ) : (
          <h3 className='flex-nowrap mb-0'>{props.title}</h3>
        )}
      </div>
      <div className='text-end ms-auto fs-5'>
        {props.label && <label className='text-dark-200'>{props.label}</label>}
      </div>
    </div>
  );
}
