import React, { useState } from 'react';
import { Application } from '../../../../entities/Application';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { ActionButton } from '../ActionButton';
import { User } from '../../../../entities/User';
import { JobHiringMemberRole } from '../../../../entities/JobHiringMemberRole';
import { buildSchedulingActionInfo } from '../../../../utils/applicant_tracking/actions/schedulingActions';
import { ScheduleInterviewModal } from '../../../../components/ApplicantTracking/ScheduleInterviewModal';

interface PropTypes {
  application: Application;
  currentUser: User;
  jobHiringMemberRole: JobHiringMemberRole;
  isDropdownAction?: boolean;
}

export function ScheduleInterviewAction(props: PropTypes) {
  const schedulingPermission = buildSchedulingActionInfo(
    props.application,
    props.currentUser,
    props.jobHiringMemberRole,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!schedulingPermission.allowed || props.application.redacted) {
    return null;
  }

  return (
    <>
      <ScheduleInterviewModal
        isOpen={isModalOpen}
        applicationId={props.application.id}
        interviewStageId={props.application.nextInterviewStageId}
        onClose={() => setIsModalOpen(false)}
      />
      <SpanTooltip
        text={
          <ActionButton
            disabled={schedulingPermission.disabled}
            iconName={'bi-calendar4-week'}
            onClick={() => setIsModalOpen(true)}
          />
        }
        tooltipText={schedulingPermission.tooltipText}
        trigger='hover'
      />
    </>
  );
}
