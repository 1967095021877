import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import styles from '../../../styles.module.scss';

interface PropTypes {
  isEmailDomainEnabled: boolean;
  isFormActive: boolean;
  onCancelClick: (event) => void;
  onEditClick: (event) => void;
  onSaveClick: (event) => void;
}

function UpdateButtons(props: PropTypes) {
  return (
    <div className='d-flex w-100'>
      <Button type='button' color='danger' onClick={props.onCancelClick}>
        Cancel
      </Button>
      <Button
        type='button'
        color='primary'
        className='ms-4'
        onClick={props.onSaveClick}
      >
        Save
      </Button>
    </div>
  );
}

function EditButton(props: PropTypes) {
  return (
    <div
      className={classNames(
        props.isEmailDomainEnabled
          ? `${styles.cursorPointer} text-info`
          : 'text-blue-200',
      )}
      onClick={props.isEmailDomainEnabled ? props.onEditClick : undefined}
    >
      <i className='bi bi-pencil pe-2'></i>
      Edit
    </div>
  );
}

export function FormButtons(props: PropTypes) {
  return (
    <>
      {props.isEmailDomainEnabled && props.isFormActive ? (
        <UpdateButtons {...props} />
      ) : (
        <EditButton {...props} />
      )}
    </>
  );
}
