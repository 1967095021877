import { Align } from 'chart.js';

const gridColor = '#00000033';
const labelColor = '#FFFFFF';

function sortLabelsWithNA(one, two) {
  if (one.value.length === 0 || one.value === 'N/A') return 1;
  if (two.value.length === 0 || two.value === 'N/A') return -1;
  return one.attribute > two.attribute ? -1 : 1;
}

function dimensionsToLabels(
  labels: { attribute: string; value: string }[],
  max: number,
) {
  return labels
    .map((dv, _index, labels) => {
      if (dv.value.length === 0) return 'N/A';
      return labels.length > max
        ? dv.value
            .split(' ')
            .map((w) => w[0])
            .join('')
        : dv.value;
    })
    .join('+');
}

function formatLabel(elem: {
  impact: number;
  labels_dimensions_values?: { attribute: string; value: string }[];
}) {
  const labels = elem.labels_dimensions_values.sort(sortLabelsWithNA);
  switch (Math.floor(elem.impact / 25)) {
    case 0:
      return labels.length > 0
        ? labels.map((dv) => (dv.value.length > 0 ? dv.value[0] : 'N/A'))[0]
        : '';
    case 1:
      return dimensionsToLabels(labels, 1);
    default:
      return dimensionsToLabels(labels, 2);
  }
}

export function npsExplainabilityBubbleChartOptions(
  standardNpsCalculation: boolean,
) {
  return {
    maintainAspectRatio: false,
    layout: {
      autoPadding: false,
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'CNPS',
          align: 'end' as Align,
          color: gridColor,
        },
        grid: {
          drawBorder: false,
          color: function (context) {
            if (context.tick.value < 0 || context.tick.value > 10) {
              return null;
            }
            return gridColor;
          },
          borderDash: [2],
          borderDashOffset: 6.0,
        },
        min: standardNpsCalculation ? -140 : -2,
        max: standardNpsCalculation ? 140 : 12,
        ticks: {
          stepSize: standardNpsCalculation ? 50 : 2.5,
          callback: standardNpsCalculation
            ? (value) => (value >= -100 && value <= 100 ? value : '')
            : (value) =>
                value >= 0 && value <= 10 && value % 5 === 0 ? value : '',
          color: gridColor,
        },
      },
      x: {
        title: {
          display: true,
          text: 'Responses',
          align: 'end' as Align,
          color: gridColor,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 10,
          precision: 0,
          callback: (value) => (value >= 0 ? value : null),
          color: gridColor,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        formatter: (elem: { impact: number }) => {
          const rowLength = 1 + Math.floor(elem.impact / 5);
          const maxRows = Math.max(1, Math.ceil(elem.impact / 20));
          const title = formatLabel(elem);
          const splits = title.match(new RegExp('.{1,' + rowLength + '}', 'g'));
          return splits.slice(0, maxRows).join('\n');
        },
        color: labelColor,
        font: {
          family:
            "'Nunito Sans', system-ui, -apple-system, 'Segoe UI'," +
            "Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif," +
            "'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          size: 12,
        },
      },
      tooltip: {
        padding: 12,
        displayColors: false,
        callbacks: {
          label: (elem) => [
            'CNPS: ' + elem.raw.nps,
            'Impact: ' + elem.raw.impact + '%',
            elem.raw.responses + ' responses',
          ],
          title: (elems) =>
            elems[0].raw.tooltips_dimensions_values
              .sort((one, two) => (one.attribute > two.attribute ? -1 : 1))
              .map((dv) => {
                return (
                  dv.attribute.slice(0, -1) +
                  ': ' +
                  (dv.value.length > 0 ? dv.value : 'N/A')
                );
              })
              .join('\n'),
        },
      },
    },
  };
}
