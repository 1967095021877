import React, { useCallback } from 'react';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { InterviewService } from '../../../../services/applicant_tracking/InterviewService';

interface PropTypes {
  candidateName: string;
  interviewId: string;
  isModalOpen: boolean;
  setIsModalOpen: (b: boolean) => void;
  onSuccess: () => void;
  onError: () => void;
}

export function CancelInterviewConfirmationModal(props: PropTypes) {
  const onCloseModal = useCallback(async () => {
    props.setIsModalOpen(false);
  }, []);

  const onCancelInterviewModal = useCallback(async () => {
    try {
      await InterviewService.cancel(props.interviewId);

      props.onSuccess();
    } catch (e) {
      console.error(e);
      props.onError();
    }
    props.setIsModalOpen(false);
  }, [props.interviewId]);

  return (
    <ConfirmationModal
      title='Cancel Interview'
      body={
        <p>
          Are you sure you want to cancel the interview with{' '}
          {props.candidateName}?
        </p>
      }
      isOpen={props.isModalOpen}
      onConfirm={onCancelInterviewModal}
      onCancel={onCloseModal}
      size={'md'}
      confirmText={'Yes'}
      disableAfterConfirm={false}
    />
  );
}
