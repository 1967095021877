import { Condition } from '..';
import { SelectOption } from '../../../../../components/Select';

export function findAvailableConditions(
  conditions: Condition[],
  applicableConditions: SelectOption[],
) {
  const usedConditions = conditions.map((condition) => condition.type.value);
  const availableConditions = applicableConditions.filter(
    (condition) => !usedConditions.includes(condition.value),
  );
  return availableConditions;
}
