import { ApiService } from '../../ApiService';
import snakecaseKeys from 'snakecase-keys';

interface SchedulingAvailabilitySlotDto {
  day: string;
  startTime: string;
  endTime: string;
}

interface SchedulingAvailabilityDto {
  slots: SchedulingAvailabilitySlotDto[];
  timezone: string;
}

export class SchedulingAvailabilityService {
  public static create(props: SchedulingAvailabilityDto): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/scheduling_availabilities`,
      snakecaseKeys(props),
    );
  }
}
