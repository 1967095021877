import { ApiService } from '../../ApiService';
import snakecaseKeys from 'snakecase-keys';
import { Interview } from '../../../entities/applicant_tracking/Interview';

export class InterviewService {
  public static create(
    applicationId: number,
    interviewStageId: number,
    interviewerUserIds: number[],
    startTime: string,
    endTime: string,
    timezoneId: string,
    meetingProvider: string,
    meetingOrganizerId: number,
  ): Promise<Interview> {
    return ApiService.post<Interview>(
      `/api/applicant_tracking/applications/${applicationId}/interviews`,
      snakecaseKeys({
        interviewStageId: interviewStageId,
        interviewerUserIds: interviewerUserIds,
        startTime: startTime,
        endTime: endTime,
        timezoneId: timezoneId,
        meetingProvider: meetingProvider,
        meetingOrganizerId: meetingOrganizerId,
      }),
    );
  }

  public static update(
    interviewId: number,
    interviewerUserIds: number[],
    startTime: string,
    endTime: string,
    timezoneId: string,
    meetingProvider: string,
  ): Promise<Interview> {
    return ApiService.put<Interview>(
      `/api/applicant_tracking/interviews/${interviewId}`,
      snakecaseKeys({
        interviewerUserIds: interviewerUserIds,
        startTime: startTime,
        endTime: endTime,
        timezoneId: timezoneId,
        meetingProvider: meetingProvider,
      }),
    );
  }

  public static cancel(id: string): Promise<void> {
    return ApiService.put<void>(
      `/api/applicant_tracking/interviews/${id}/cancel`,
    );
  }
}
