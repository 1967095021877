import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { InputFormField } from '../../../../components/InputFormField';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';

interface PropTypes {
  formRegister: UseFormRegister<CandidateDto>;
}

export function CandidatePortfolioInput(props: PropTypes) {
  const isRequired = false;

  return (
    <Row>
      <Col xs={6}>
        <InputFormField
          type={'url'}
          registerReturn={props.formRegister('portfolioUrl', {
            required: isRequired,
          })}
          fieldName={'Portfolio / Website'}
          placeholder={'Insert your website or portfolio'}
          isRequired={isRequired}
          classNames={'fs-5'}
          headerClassNames={'fw-semibold'}
          disabled={false}
        />
      </Col>
    </Row>
  );
}
