import { OrganicPosts } from '../../../../../entities/applicant_tracking/jobboards/OrganicPost';
import { ApiService } from '../../../../ApiService';

export class OrganicPostService {
  public static list(): Promise<OrganicPosts> {
    return ApiService.get(
      '/api/v1/applicant_tracking/job_boards/organic_posts',
    );
  }
}
