import React, { useEffect, useState } from 'react';
import { User } from '../../../../../entities/User';
import { InterviewStageScorecard } from '../../../../../entities/applicant_tracking/InterviewStage';
import { ScorecardTemplateService } from '../../../../../services/v1/applicant_tracking/ScorecardTemplateService';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { ScorecardTemplateTable } from '../../../../../components/ApplicantTracking/ScorecardTemplateTable';
import { EmptyState } from '../../../../../components/EmptyState';

interface PropTypes {
  user: User;
  scorecard: InterviewStageScorecard;
}

export function ScorecardBody(props: PropTypes) {
  const [scorecardTemplate, setScorecardTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const scorecardTemplate = await ScorecardTemplateService.show(
        props.scorecard.templateId.toString(),
        {
          expand: [
            'survey_template',
            'survey_template.sections',
            'survey_template.sections.questions',
          ],
        },
      );
      setScorecardTemplate(scorecardTemplate);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!scorecardTemplate) {
    return <EmptyState title='Scorecard template not found' />;
  }

  return (
    <ScorecardTemplateTable
      showFieldLabels
      scorecardTemplate={scorecardTemplate}
    />
  );
}
