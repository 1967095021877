import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { Alert, AlertType } from '../Alert';

interface PropTypes {
  error: FieldErrors;
  errorMessage?: ErrorMessage;
}

class ErrorMessage {
  title: string;
  detail: string;
}

function getFormErrors(error: FieldErrors) {
  if (error.email) {
    if (error.email.type === 'required') {
      return null;
    }

    if (error.email.type === 'pattern') {
      return {
        title: '',
        detail: `${error.email.message}`,
      };
    }

    return {
      title: 'The candidate email already exists!',
      detail: 'Please change the candidate email',
    };
  } else if (error.phoneNumber) {
    if (error.phoneNumber.type === 'required') {
      return null;
    }

    return {
      title: 'The candidate phone number already exists!',
      detail: 'Please change the candidate phone number',
    };
  } else if (error.name) {
    if (error.name.type === 'required') {
      return null;
    }

    return {
      title: 'The job name already exists for the same department!',
      detail: 'Please change the job name or department',
    };
  } else if (error.referralEmail) {
    return {
      title: '',
      detail: `${error.referrerEmail.message}`,
    };
  }

  return null;
}

function getErrorMessage(
  error: FieldErrors,
  errorMessage?: ErrorMessage,
): ErrorMessage {
  if (error.root?.type === 'clientError') {
    return {
      title: 'An error occurred!',
      detail: 'Please review the form',
    };
  } else if (error.root?.type === 'serverError') {
    return {
      title: 'A server error occurred!',
      detail: 'Please contact support@screenloop.com',
    };
  }

  return errorMessage || getFormErrors(error);
}

export function FormErrorMessage(props: PropTypes) {
  if (props.error == null || Object.keys(props.error).length === 0) {
    return null;
  }

  const errorMessage = getErrorMessage(props.error, props.errorMessage);

  return (
    errorMessage && (
      <Alert clearable type={AlertType.Danger}>
        <span>
          <b>{errorMessage.title}</b> {errorMessage.detail}
        </span>
      </Alert>
    )
  );
}
