import React from 'react';
import { SurveyChip } from '../../../../../components/survey_engine/SurveyChip';
import { Role } from '../../../../../entities/Role';
import { SurveyQuestionField } from '../../../../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../../entities/survey_engine/SurveyQuestionFieldAnswer';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer?: SurveyQuestionFieldAnswer;
  onChangeAnswer: (
    fieldId: number,
    value: string,
    optionIds: (number | Role)[],
  ) => void;
}

const toggleOptionId = (
  optionIds: (number | Role)[],
  optionId: number | Role,
) => {
  const index = optionIds.findIndex((id) => id === optionId);
  if (index === -1) return optionIds.concat(optionId);

  return optionIds.filter((id) => id !== optionId);
};

export function MultiOptionsField(props: PropTypes) {
  const optionIds: (number | Role)[] = props.fieldAnswer?.option_ids || [];

  return (
    <div className='mt-4'>
      <div className='d-flex align-content-end flex-wrap'>
        {props.field.options.map((opt) => (
          <div key={opt.id} className='mb-2 px-2 pt-1'>
            <SurveyChip
              className='text-start fs-5 px-3 py-2'
              active={optionIds.find((optId) => optId === opt.id) !== undefined}
              onClick={() =>
                props.onChangeAnswer(
                  props.field.id,
                  undefined,
                  toggleOptionId(optionIds, opt.id),
                )
              }
            >
              {opt.name}
            </SurveyChip>
          </div>
        ))}
      </div>
    </div>
  );
}
