import React from 'react';
import { Chip, ChipColor } from '../../../components/Chip';
import { User } from '../../../entities/User';

interface Proptypes {
  user: User;
}

export function Header(props: Proptypes) {
  return (
    <div className='d-flex justify-content-between align-items-center pb-1 m-1 p-2'>
      <div className='fs-2'>
        <span className='fw-bold'>Hello, {props.user.name}!</span> Welcome.
      </div>
      <div>
        <Chip className='me-2' color={ChipColor.White}>
          <span>Overview</span>
        </Chip>
        <Chip color={ChipColor.White}>
          <span>90 days</span>
        </Chip>
      </div>
    </div>
  );
}
