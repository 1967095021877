import React from 'react';
import { VcsProvider } from '../../../../../../enums/IntegrationProvider/VcsProvider';
import { humanize } from '../../../../../../utils/humanize';
import { Select } from '../../../../../../components/Select';
import classNames from 'classnames';
import { Size } from '../../../../../../utils/types/Size';

interface PropTypes {
  meetingProvider?: VcsProvider;
  setMeetingProvider: (provider: VcsProvider | null) => void;
  meetingProviders: VcsProvider[];
  candidateView?: boolean;
  className?: string;
  selectSize?: Size;
  readOnly?: boolean;
  titleClassName?: string;
}

export function InterviewMeetingProvider(props: PropTypes) {
  const availableMeetingProviders = props.meetingProviders.map((provider) => {
    return {
      value: provider,
      label: humanize(provider),
      selected: provider === props.meetingProvider,
    };
  });

  const selectedMeetingProvider = availableMeetingProviders.find(
    (option) => option.selected,
  );

  return (
    <div
      data-testid='interview_meeting_provider'
      className={classNames(props.className)}
    >
      <p
        className={classNames(
          props.titleClassName || 'text-dark-500',
          'fs-5 mb-2',
        )}
      >
        Video Conferencing
      </p>
      {props.candidateView ? (
        <p className='text-dark-200 fs-6 mt-1'>
          {humanize(props.meetingProvider)}
        </p>
      ) : (
        <Select
          options={availableMeetingProviders}
          placeholder='Select provider'
          size={props.selectSize || 'sm'}
          menuPlacement='top'
          onChange={(option) => {
            props.setMeetingProvider(option?.value as VcsProvider);
          }}
          selected={selectedMeetingProvider}
          isClearable={true}
          disabled={!!props.readOnly}
        />
      )}
    </div>
  );
}
