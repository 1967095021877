import { Condition, ConditionsData } from '..';
import { SelectOption } from '../../../../../components/Select';

export function computeNewConditions(
  elements: {
    index: number;
    type: SelectOption;
    invert: SelectOption;
    singleSelected: SelectOption;
    multiSelected: SelectOption[];
  },
  conditions: Condition[],
  conditionsData: ConditionsData,
) {
  const updatedConditions = [...conditions];
  updatedConditions[elements.index].type = elements.type;
  updatedConditions[elements.index].invert = elements.invert;

  for (const [__key, value] of Object.entries(conditionsData)) {
    if (elements.type.value === value.value) {
      updatedConditions[elements.index].available = value.options;
      updatedConditions[elements.index].multiSelected = elements.multiSelected;
      updatedConditions[elements.index].singleSelected =
        elements.singleSelected;
    }
  }
  return updatedConditions;
}
