import React from 'react';
import { SpanTooltip } from '../../../../../components/SpanTooltip';
import { Icon } from '../../../../../components/Icon';
import classNames from 'classnames';

interface PropTypes {
  label: string;
}

export function RedactedEntry(props: PropTypes) {
  return (
    <div className={classNames('d-flex', 'align-items-center')}>
      <SpanTooltip
        text={<Icon className='text-blue-200 fs-4' name='bi-file-lock2' />}
        tooltipText='Candidate information has been redacted to reduce bias.'
      />
      <span className='ms-2'>{props.label}</span>
    </div>
  );
}
