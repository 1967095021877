import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppPlugin } from '..';
import { SelectContainer } from './SelectContainer';

export class SelectPlugin implements AppPlugin {
  public load(): void {
    document
      .querySelectorAll<HTMLSelectElement>('[data-select-plugin]')
      .forEach((el) => this.installContainer(el));
  }

  private installContainer(el: HTMLSelectElement): void {
    const props = JSON.parse(el.dataset['selectPlugin'] || '{}');
    const containerEl = document.createElement('span');
    el.before(containerEl);

    const options = Array.from(
      el.querySelectorAll<HTMLOptionElement>('option'),
    ).map((opt) => ({
      label: opt.textContent,
      value: opt.value,
      selected: opt.selected,
    }));

    createRoot(containerEl).render(
      React.createElement(SelectContainer, {
        ...props,
        name: el.name,
        className: el.className,
        options,
      }),
    );

    el.remove();
  }
}
