import React, { useState } from 'react';
import { Icon } from '../Icon';
import classNames from 'classnames';
import { PartialStar } from './PartialStar';

interface PropTypes {
  editable: boolean;
  rating: number | null;
  setRating?: (rating: number) => void;
  fillColorClass?: string;
}

function classNameByState(
  currentRating: number,
  rating: number,
  hover: number,
  fillColorClass?: string,
) {
  const fillColor: string = fillColorClass || 'text-info';

  if (currentRating > rating && currentRating > hover) {
    return 'text-blue-gray-500';
  } else if (currentRating <= rating) {
    return fillColor;
  } else if (hover > rating) {
    return 'text-blue-500';
  }

  return fillColor;
}

export function StarRating(props: PropTypes) {
  const [hover, setHover] = useState(null);

  return (
    <div className='d-flex gap-0 align-items-center'>
      {[1, 2, 3, 4, 5].map((star) => {
        const isPartial = props.rating - star < 0 && props.rating - star > -1;

        return (
          <div key={star}>
            {isPartial ? (
              <PartialStar value={props.rating} />
            ) : (
              <div
                key={star}
                className='ps-1'
                onMouseEnter={() => props.editable && setHover(star)}
                onMouseLeave={() => props.editable && setHover(props.rating)}
              >
                <Icon
                  name={
                    star > hover && star > props.rating
                      ? 'bi-star'
                      : 'bi-star-fill'
                  }
                  className={classNames(
                    props.editable && 'btn',
                    'p-0',
                    'fs-4',
                    classNameByState(
                      star,
                      props.rating,
                      hover,
                      props.fillColorClass,
                    ),
                  )}
                  onClick={() => props.editable && props.setRating(star)}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
