import React, { useState } from 'react';
import { Dropdown } from '../../components/Dropdown';
import { IconSpan } from '../../components/IconSpan';
import { InterviewListItem } from '../../entities/InterviewListItem';
import { Alert, AlertType } from '../../components/Alert';
import { pendingCandidateSchedulingInterviewActions } from './pendingCandidateSchedulingInterviewActions';
import { User } from '../../entities/User';
import { JobHiringMemberRole } from '../../entities/JobHiringMemberRole';
import { appendRedirectUri } from '../../utils/url';

interface PropTypes {
  interview: InterviewListItem;
  currentUser: User;
  jobHiringMemberRole: JobHiringMemberRole;
  onCancelInterview: (interviewId: string) => void;
}

function isUserAbleToUpdateInterview(
  currentUser: User,
  interview: InterviewListItem,
  jobHiringMemberRole: JobHiringMemberRole,
) {
  if (interview.isImported) return false;

  if (currentUser.isAdmin) return true;

  if (
    jobHiringMemberRole === 'recruiter' ||
    jobHiringMemberRole === 'hiring_manager'
  )
    return true;

  return interview.organizer?.id === currentUser.id;
}

function isUserAbleToRescheduleInterview(
  currentUser: User,
  interview: InterviewListItem,
  jobHiringMemberRole: JobHiringMemberRole,
) {
  if (interview.status !== 'scheduled') return false;

  return isUserAbleToUpdateInterview(
    currentUser,
    interview,
    jobHiringMemberRole,
  );
}

function isUserAbleToCancelInterview(
  currentUser: User,
  interview: InterviewListItem,
  jobHiringMemberRole: JobHiringMemberRole,
) {
  if (interview.status !== 'scheduled' && interview.status != 'unscheduled')
    return false;

  return isUserAbleToUpdateInterview(
    currentUser,
    interview,
    jobHiringMemberRole,
  );
}

export function renderDropdownAction(
  title: string,
  iconName: string,
  isDisabled: boolean,
  action,
) {
  return {
    action: action,
    buttonChild: (
      <IconSpan
        spanText={title}
        icon={{ name: iconName, className: 'fs-4' }}
        className={isDisabled ? 'text-muted' : 'text-info'}
      />
    ),
    isDisabled: isDisabled,
  };
}

function renderView(interview: InterviewListItem) {
  if (interview.status == 'unscheduled') return null;

  return renderDropdownAction(
    'View',
    'bi-eye',
    interview.status !== 'completed',
    () => (location.href = appendRedirectUri(interview.interviewUrl)),
  );
}

function renderReschedule(
  interview: InterviewListItem,
  currentUser: User,
  jobHiringMemberRole: JobHiringMemberRole,
) {
  if (interview.status == 'unscheduled') return null;

  return renderDropdownAction(
    'Reschedule',
    'bi-calendar4-week',
    !isUserAbleToRescheduleInterview(
      currentUser,
      interview,
      jobHiringMemberRole,
    ),
    () =>
      (window.location.href = appendRedirectUri(
        `/applicant_tracking/interviews/${interview.id}/reschedule`,
      )),
  );
}

export function renderCancel(
  interview: InterviewListItem,
  currentUser: User,
  jobHiringMemberRole: JobHiringMemberRole,
  onCancelInterview: (id: string) => void,
) {
  return renderDropdownAction(
    'Cancel',
    'bi-x-circle',
    !isUserAbleToCancelInterview(currentUser, interview, jobHiringMemberRole),
    () => onCancelInterview(interview.id.toString()),
  );
}

function renderInterviewKit(interviewKitUrl?: string) {
  if (!interviewKitUrl) return null;

  return renderDropdownAction(
    'Interview Kit',
    'bi-credit-card-2-front',
    false,
    () => (window.location.href = appendRedirectUri(interviewKitUrl)),
  );
}

function buildMenuActions(
  interview: InterviewListItem,
  currentUser: User,
  jobHiringMemberRole: JobHiringMemberRole,
  onCancelInterview: (interviewId: string) => void,
  setAlertMessage: (message: string) => void,
  setAlertType: (type: AlertType) => void,
) {
  return [
    ...pendingCandidateSchedulingInterviewActions(
      interview,
      setAlertMessage,
      setAlertType,
    ),
    renderView(interview),
    renderInterviewKit(interview.interviewKitUrl),
    renderReschedule(interview, currentUser, jobHiringMemberRole),
    renderCancel(
      interview,
      currentUser,
      jobHiringMemberRole,
      onCancelInterview,
    ),
  ].filter(function (action) {
    return action != null;
  });
}

export function DropdownInterview(props: PropTypes) {
  const [alertMessage, setAlertMessage] = useState<string>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Success);

  const menuActions = buildMenuActions(
    props.interview,
    props.currentUser,
    props.jobHiringMemberRole,
    props.onCancelInterview,
    setAlertMessage,
    setAlertType,
  );

  return (
    <>
      {alertMessage && (
        <Alert
          type={alertType}
          autoClearTimeout={4000}
          clearable={true}
          onClose={() => setAlertMessage(null)}
        >
          {alertMessage}
        </Alert>
      )}
      <Dropdown
        buttonIcon={{
          name: 'bi-three-dots',
          className: 'fs-5 text-blue',
        }}
        menuPlacementClass={'dropdown-menu-end'}
        menuActions={menuActions}
      />
    </>
  );
}
