export function toIntOrNull(value?: string): number {
  if (value == null || value?.trim() == '') {
    return null;
  }

  const parsedInt = parseInt(value);
  if (!isNaN(parsedInt)) {
    return parsedInt;
  } else {
    return null;
  }
}
