import React from 'react';
import { ScorecardTemplateList } from '../../../../entities/v1/applicant_tracking/ScorecardTemplateList';
import { ScorecardTemplateService } from '../../../../services/v1/applicant_tracking/ScorecardTemplateService';
import {
  StartingPoint,
  StartingPointSelector,
} from '../../../survey_engine/SurveyTemplates/StartingPointSelector';

interface PropTypes {
  startingPoint: StartingPoint;
  onStartingPointChange: (value: StartingPoint) => void;
}

export function ScorecardTemplateStartingPointSelector(props: PropTypes) {
  return (
    <StartingPointSelector
      startingPoint={props.startingPoint}
      initialState={() =>
        ScorecardTemplateService.list({ expand: ['survey_template'] })
      }
      filterOptions={filterScorecardTemplates}
      onStartingPointChange={props.onStartingPointChange}
      placeholder='Select scorecard'
    />
  );
}

async function filterScorecardTemplates(
  scorecards: Promise<ScorecardTemplateList>,
  q: string,
) {
  return scorecards.then((list) =>
    list.scorecardTemplates
      .filter((template) =>
        template.surveyTemplate.name.toLowerCase().includes(q.toLowerCase()),
      )
      .map((template) => ({
        value: template.id.toString(),
        label: template.surveyTemplate.name,
      })),
  );
}
