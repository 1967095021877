import { ApiService } from '../ApiService';

export interface LocationEntity {
  name: string;
  address: string;
  city: string;
  state: string;
  country: string;
  latitude?: number;
  longitude?: number;
}

export class GeoLocationService {
  public static listLocations(query: string): Promise<LocationEntity[]> {
    return ApiService.get<LocationEntity[]>('/api/geo_locations', {
      query: query,
    });
  }
}
