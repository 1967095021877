import classNames from 'classnames';
import React, { useState } from 'react';
import styles from '../../TableBody/styles.module.scss';
import { ToggleButton } from '../../../../../../../components/ToggleButton';
import { useMutation, useQueryClient } from 'react-query';
import { HiringMemberSettings } from '../../../../../../../entities/v1/HiringMemberSettings';
import { HiringMemberSettingsService } from '../../../../../../../services/v1/HiringMemberSettingsService';
import { HiringMemberSettingsType } from '../../../../../../../entities/HiringMemberSettingsType';

interface PropTypes {
  hiringMemberSettingsType: HiringMemberSettingsType;
  settingEnabled: boolean;
  class: string;
  settingsId: number;
  recordingConsentOptout: boolean;
  hiringMemberId: number;
  settingsQueryKey: string;
}

export function SelectedSettingsDataCell(props: PropTypes) {
  const [settingEnabled, setSettingEnabled] = useState(props.settingEnabled);
  const queryClient = useQueryClient();

  const updateMutation = useMutation<void, void, HiringMemberSettings>({
    mutationFn: (data) => HiringMemberSettingsService.update(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [props.settingsQueryKey],
      });
      setSettingEnabled(!settingEnabled);
    },
  });

  const createMutation = useMutation<void, void, HiringMemberSettings>({
    mutationFn: (data) => HiringMemberSettingsService.create(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [props.settingsQueryKey],
      });
      setSettingEnabled(!settingEnabled);
    },
  });

  function handleUpdate(data: HiringMemberSettings) {
    if (props.settingsId) {
      updateMutation.mutate(data);
    } else {
      createMutation.mutate(data);
    }
  }

  function handleOnToggle() {
    const data: HiringMemberSettings = {
      id: props.settingsId,
      autoRecordingEnabled: null,
      recordingConsentOptout: null,
      hiringMemberId: props.hiringMemberId,
    };

    if (props.hiringMemberSettingsType === 'auto_recording') {
      data.autoRecordingEnabled = !settingEnabled;
      data.recordingConsentOptout = props.recordingConsentOptout;
    } else {
      data.autoRecordingEnabled = null;
      data.recordingConsentOptout = !settingEnabled;
    }

    handleUpdate(data);
  }

  return (
    <td
      className={classNames(
        'pb-3',
        props.class,
        styles['table-data-cell-border-bottom'],
      )}
    >
      <div className='d-flex align-items-center'>
        <div className='fs-5 text-primary fw-normal me-2'>
          {settingEnabled ? 'Enabled' : 'Disabled'}
        </div>
        <ToggleButton
          name={'Hiring Member Settings'}
          size={'sm'}
          checked={settingEnabled}
          onChange={handleOnToggle}
        />
      </div>
    </td>
  );
}
