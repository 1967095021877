import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import styles from './styles.module.scss';
import { BULK_REVIEW_TABS } from '..';

interface PropTypes {
  activeTab: BULK_REVIEW_TABS;
  onTabChange: (tab: BULK_REVIEW_TABS) => void;
}

function TabButton(props: {
  name: string;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={classNames(
        props.active && 'border-bottom border-primary border-2',
      )}
    >
      <Button
        color='transparent'
        className={classNames(
          'px-3 fs-4 fw-bold py-2h',
          styles['tab-header-button'],
          props.active ? 'text-primary' : 'text-dark-200',
        )}
        onClick={props.onClick}
        active={props.active}
      >
        {props.name}
      </Button>
    </div>
  );
}

export function TabHeaders(props: PropTypes) {
  return (
    <div className='d-flex'>
      <TabButton
        active={props.activeTab === 'resume'}
        name='Resume / CV'
        onClick={() => props.onTabChange('resume')}
      />
      <TabButton
        active={props.activeTab === 'questions'}
        name='Application Questions'
        onClick={() => props.onTabChange('questions')}
      />
    </div>
  );
}
