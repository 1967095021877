import React from 'react';
import { Col } from 'reactstrap';
import { QuestionTopChoices } from '../../../../entities/candidate_experience/QuestionTopChoices';
import { randomColorWithoutRepetition } from '../../../../utils/randomColorWithoutRepetition';
import {
  TopChoices,
  TopChoicesChartType,
} from '../../shared_panels/TopChoices';
import stylesheetExports from '../../../../stylesheets/export.module.scss';

interface PropTypes {
  option: QuestionTopChoices;
  colors: string[];
}

export function ExtraMultiOptionsLastOdd(props: PropTypes) {
  return (
    <Col xs='12'>
      <TopChoices
        title=''
        subtitle={props.option.question_text}
        topChoices={props.option.top_choices}
        fillColor={randomColorWithoutRepetition(
          props.colors,
          stylesheetExports.pink,
        )}
        type={TopChoicesChartType.Bar}
      />
    </Col>
  );
}
