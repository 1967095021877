import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import {
  OfferLetterTemplate,
  OfferLetterTemplateList,
  OfferLetterTemplateVariables,
} from '../../../../entities/v1/applicant_tracking/OfferLetterTemplate';
import { UpsertOfferLetterTemplateDto } from '../../../../containers/applicant_tracking/OfferLetterTemplates/components/UpsertEmailTemplateDto';

export class OfferLetterTemplateService {
  public static show(id: number): Promise<OfferLetterTemplate> {
    return ApiService.get<OfferLetterTemplate>(
      `/api/v1/applicant_tracking/offer_letter_templates/${id}`,
    );
  }

  public static variables(id: number): Promise<OfferLetterTemplateVariables> {
    return ApiService.get<OfferLetterTemplateVariables>(
      `/api/v1/applicant_tracking/offer_letter_templates/${id}/variables`,
    );
  }

  public static list(): Promise<OfferLetterTemplateList> {
    return ApiService.get<OfferLetterTemplateList>(
      `/api/v1/applicant_tracking/offer_letter_templates`,
    );
  }

  public static create(
    offerLetter: UpsertOfferLetterTemplateDto,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/v1/applicant_tracking/offer_letter_templates`,
      snakecaseKeys(offerLetter),
    );
  }

  public static update(
    id: number,
    offerLetter: UpsertOfferLetterTemplateDto,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/offer_letter_templates/${id}`,
      snakecaseKeys(offerLetter),
    );
  }

  public static delete(id: number): Promise<void> {
    return ApiService.delete<void>(
      `/api/v1/applicant_tracking/offer_letter_templates/${id}`,
    );
  }
}
