import classNames from 'classnames';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import stylesheetExports from '../../stylesheets/export.module.scss';

interface PropTypes {
  labels: string[];
  activeValues: number[];
  hiredValues: number[];
  classNames?: string;
}

const CHART_HEIGHT_OFFSET = 1.2;

export function ActiveAndHiredBarChart(props: PropTypes) {
  const max =
    props.activeValues.reduce((a, b) => Math.max(a, b), -Infinity) *
    CHART_HEIGHT_OFFSET;

  const data = {
    labels: props.labels,
    datasets: [
      {
        label: 'Hired',
        data: props.hiredValues,
        backgroundColor: stylesheetExports.blue500,
      },
      {
        label: 'Active',
        data: props.activeValues,
        backgroundColor: '#CAEDE8',
      },
      {
        label: 'placeholder',
        data: Array(props.hiredValues.length).fill(max),
        backgroundColor: stylesheetExports.gray,
        hoverBackgroundColor: stylesheetExports.gray,
      },
    ],
  };

  return (
    <Bar
      className={classNames(props.classNames)}
      options={chartOptions(max)}
      data={data}
    />
  );
}

export const chartOptions = (max: number) => {
  return {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: true,
    },
    maintainAspectRatio: false,
    aspectRatio: 3,
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        display: false,
        max,
      },
    },
    elements: {
      bar: {
        borderSkipped: false,
        borderRadius: 20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex !== 2;
        },
        titleFont: {
          family: 'Nunito Sans',
        },
        bodyFont: {
          family: 'Nunito Sans',
        },
        backgroundColor: '#08243ECC',
        itemSort: function (a, b) {
          return b.datasetIndex - a.datasetIndex;
        },
        yAlign: 'bottom' as const,
        multiKeyBackground: 'transparent',
        padding: {
          top: 8,
          bottom: 8,
          left: 16,
          right: 16,
        },
        callbacks: {
          label: function (context) {
            return ` ${context.formattedValue} ${context.dataset.label}`;
          },
          labelColor: function (context) {
            return {
              backgroundColor: context.dataset.backgroundColor,
              borderRadius: 2,
              borderColor: 'transparent',
              borderWidth: 0,
            };
          },
        },
      },
    },
  };
};
