import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Tooltip } from 'reactstrap';

interface PropTypes {
  percentagePositive: number;
  percentageNeutral: number;
  percentageNegative: number;
  countNegative: number;
  countNeutral: number;
  countPositive: number;
  topic?: boolean;
}

function SentimentAnalysisTooltip(props: {
  state: React.MutableRefObject<any>;
  isTooltipOpen: boolean;
  setIsTooltipOpen: (value: boolean) => void;
  percentage: number;
  count: number;
  value: string;
  text: string;
}) {
  return (
    <Tooltip
      target={props.state}
      isOpen={props.isTooltipOpen}
      toggle={() => props.setIsTooltipOpen(!props.isTooltipOpen)}
      placement={'top'}
    >
      {props.text} {Math.round(props.percentage)}% ({props.count} {props.value}
      {props.count > 1 ? 's' : ''})
    </Tooltip>
  );
}

export function SentimentStackedBar(props: PropTypes) {
  const value = props.topic ? 'Topic' : 'Response';
  const hasSentimentData =
    props.countPositive + props.countNeutral + props.countNegative > 0;
  if (
    props.percentagePositive +
      props.percentageNeutral +
      props.percentageNegative >
    100
  ) {
    console.error(
      "Invalid prop percentage. Total percentage can't be greater than 100",
    );
    return null;
  }
  const [isTooltipOpenPositive, setIsTooltipOpenPositive] =
    useState<boolean>(false);
  const [isTooltipOpenNeutral, setIsTooltipOpenNeutral] =
    useState<boolean>(false);
  const [isTooltipOpenNegative, setIsTooltipOpenNegative] =
    useState<boolean>(false);
  const positive = useRef(null);
  const neutral = useRef(null);
  const negative = useRef(null);

  return (
    hasSentimentData && (
      <div className={classNames('d-flex w-100')}>
        <div
          className={classNames(determinePositiveStyling(props))}
          style={{
            width: `${props.percentagePositive}%`,
          }}
          ref={positive}
        ></div>
        <SentimentAnalysisTooltip
          state={positive}
          isTooltipOpen={isTooltipOpenPositive}
          setIsTooltipOpen={setIsTooltipOpenPositive}
          percentage={props.percentagePositive}
          count={props.countPositive}
          value={value}
          text={'Positive'}
        />
        <div
          className={classNames(determineNeutralStyling(props))}
          style={{ width: `${props.percentageNeutral}%` }}
          ref={neutral}
        ></div>
        <SentimentAnalysisTooltip
          state={neutral}
          isTooltipOpen={isTooltipOpenNeutral}
          setIsTooltipOpen={setIsTooltipOpenNeutral}
          percentage={props.percentageNeutral}
          count={props.countNeutral}
          value={value}
          text={'Neutral'}
        />
        <div
          className={classNames(determineNegativeStyling(props))}
          style={{ width: `${props.percentageNegative}%` }}
          ref={negative}
        ></div>
        <SentimentAnalysisTooltip
          state={negative}
          isTooltipOpen={isTooltipOpenNegative}
          setIsTooltipOpen={setIsTooltipOpenNegative}
          percentage={props.percentageNegative}
          count={props.countNegative}
          value={value}
          text={'Negative'}
        />
      </div>
    )
  );
}

function determinePositiveStyling(props: PropTypes) {
  const positiveStyle = [];
  positiveStyle.push(styles.stackedBarPositive);
  if (props.percentageNegative === 0 && props.percentageNeutral === 0) {
    {
      positiveStyle.push(styles['only-positive']);
    }
  }
  return positiveStyle;
}

function determineNegativeStyling(props: PropTypes) {
  const negativeStyle = [];
  negativeStyle.push(styles.stackedBarNegative);
  if (props.percentagePositive === 0 && props.percentageNeutral === 0) {
    {
      negativeStyle.push(styles['only-negative']);
    }
  }
  return negativeStyle;
}

function determineNeutralStyling(props: PropTypes) {
  const neutralStyle = [];
  neutralStyle.push(styles.stackedBarNeutral);
  if (props.percentagePositive === 0 && props.percentageNegative === 0) {
    neutralStyle.push(styles['only-neutral']);
  } else if (props.percentagePositive === 0) {
    neutralStyle.push(styles['neutral-and-negative']);
  } else if (props.percentageNegative === 0) {
    neutralStyle.push(styles['neutral-and-positive']);
  }
  return neutralStyle;
}
