import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Row, Tooltip } from 'reactstrap';
import { RatingHistory } from '../../../../entities/training/RatingHistory';
import styles from './styles.module.scss';
import moment from 'moment';
import { getPercentage } from '../../../../utils/getPercentage';

interface PropTypes {
  averageRating: number;
  ratingHistory: RatingHistory[];
}

function formatDate(date: Date) {
  return moment(date).format('DD/MM/yyyy');
}

function getTooltipRating(rating?: number) {
  if (rating == null) {
    return 'No rating available';
  }

  return `Average Rating ${rating}`;
}

function getTooltipMessage(week: string) {
  const startDate = new Date(week);
  const endDate = moment(new Date(week)).add(6, 'days').toDate();

  return formatDate(startDate) + ' - ' + formatDate(endDate);
}

function RatingPercentage(props: { rating?: number }) {
  return (
    <div
      style={{
        height: `${getPercentage(props.rating, 5.0)}%`,
      }}
      className={classNames('bg-pin', styles.progress)}
    />
  );
}

function RatingBar(props: { size: number; ratingElement: RatingHistory }) {
  const ref = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  return (
    <>
      <div
        ref={ref}
        style={{ width: `${getPercentage(props.size, 1.0)}%` }}
        className={classNames('bg-white', styles.wrapper)}
      >
        <RatingPercentage rating={props.ratingElement.rating} />
      </div>
      <Tooltip
        isOpen={isTooltipOpen}
        target={ref}
        placement={'top'}
        toggle={() => setIsTooltipOpen(!isTooltipOpen)}
      >
        <div className={classNames('fs-5', 'py-2', 'px-3')}>
          <Row>{getTooltipRating(props.ratingElement.rating)}</Row>
          <Row className='flex-nowrap'>
            {getTooltipMessage(props.ratingElement.week)}
          </Row>
        </div>
      </Tooltip>
    </>
  );
}

function RatingSubHeader(props: { averageRating: number }) {
  return (
    <div
      className={classNames('d-flex', 'flex-nowrap', 'justify-content-between')}
    >
      <div>
        <small className={classNames('fw-semibold', 'text-dark-200')}>
          Last 90 days
        </small>
      </div>
      <div className={classNames('ms-auto', 'text-end', 'text-info')}>
        <div
          className={classNames('align-items-center', 'd-flex', 'flex-nowrap')}
        >
          <i className={classNames('bi', 'me-1', 'bi-star')} />
          <h1 className='mb-0'>{props.averageRating}</h1>
        </div>
      </div>
    </div>
  );
}

export function AverageRatingPanel(props: PropTypes) {
  if (
    props.averageRating == null ||
    props.ratingHistory == null ||
    props.ratingHistory.length === 0
  ) {
    return null;
  }

  return (
    <div
      data-testid='training-session-rating'
      className={classNames('bg-gray', 'rounded-4', 'px-4', 'pb-4', 'pt-3')}
    >
      <h3>Average Rating</h3>
      <RatingSubHeader averageRating={props.averageRating} />
      <div className={classNames('d-flex', 'flex-nowrap', 'mt-2', 'gap-2')}>
        {props.ratingHistory.map((ratingHistory, index) => {
          return (
            <RatingBar
              key={index}
              size={props.ratingHistory.length}
              ratingElement={ratingHistory}
            />
          );
        })}
      </div>
    </div>
  );
}
