import React from 'react';
import { CompanyInfo } from '../../../../../entities/CompanyInfo';
import { SurveyQuestion } from '../../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionTitle } from '../SurveyQuestionTitle';

interface PropTypes {
  question: SurveyQuestion;
  companyInfo: CompanyInfo;
}

export function StyledSurveyQuestionTitle(props: PropTypes) {
  return (
    <SurveyQuestionTitle
      question={props.question}
      style={{
        letterSpacing: `${props.companyInfo.font_kerning / 2}em`,
      }}
    />
  );
}
