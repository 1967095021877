import momentTimezone, { Moment } from 'moment-timezone';
import { InterviewerParticipant } from '../../../../entities/applicant_tracking/InterviewerParticipant';
import { QueryPeriod } from '../EventSlot';

const DEFAULT_AVAILABILITY_START_HOUR = 9;
const DEFAULT_AVAILABILITY_END_TIME = [18, 0, 0]; // hour, minutes, seconds
const MANAGED_AVAILABILITY_END_TIME = [23, 59, 59]; // hour, minutes, seconds
const MAX_QUERY_PERIOD_ELEMENTS = 50;
const FIRST_SLOT_DELAY_MINUTES = 15;

export interface TimeConstraint {
  startTime: Date;
  endTime: Date;
}

function buildQueryPeriod(
  moment: Moment,
  dayConstraints: TimeConstraint[],
  timezone: string,
  withManagedAvailability: boolean,
) {
  let startPeriodMoment = moment.clone().utc();

  if (startPeriodMoment.dayOfYear() !== momentTimezone.utc().dayOfYear()) {
    startPeriodMoment.tz(timezone).set({
      hour: withManagedAvailability ? 0 : DEFAULT_AVAILABILITY_START_HOUR,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }

  if (startPeriodMoment < momentTimezone.utc()) {
    startPeriodMoment = momentTimezone.utc();
  }

  const endPeriodMoment = startPeriodMoment.clone();
  const endTime = withManagedAvailability
    ? MANAGED_AVAILABILITY_END_TIME
    : DEFAULT_AVAILABILITY_END_TIME;
  endPeriodMoment.tz(timezone).set({
    hour: endTime[0],
    minutes: endTime[1],
    seconds: endTime[2],
  });

  const result: QueryPeriod[] = [];
  let currentIntervalStart = startPeriodMoment.clone();

  if (dayConstraints) {
    for (const constraint of dayConstraints) {
      if (
        constraint.startTime > currentIntervalStart.toDate() &&
        constraint.startTime > startPeriodMoment.toDate()
      ) {
        result.push({
          start: momentTimezone
            .max(currentIntervalStart, startPeriodMoment)
            .utc()
            .format(),
          end: momentTimezone.utc(constraint.startTime.toUTCString()).format(),
        });
      }
      currentIntervalStart = momentTimezone.utc(
        constraint.endTime.toUTCString(),
      );
    }
  }

  if (currentIntervalStart < endPeriodMoment) {
    result.push({
      start: momentTimezone
        .max(currentIntervalStart, startPeriodMoment)
        .utc()
        .format(),
      end: endPeriodMoment.utc().format(),
    });
  }

  return result;
}

function relativeMinutesFromToday(startMoment: Moment, hours: number): number {
  const today = startMoment.clone();
  if (hours === 0) {
    return FIRST_SLOT_DELAY_MINUTES;
  } else if (hours <= 3) {
    return hours * 60;
  } else if (hours <= 24) {
    const tomorrow = today.clone().add(1, 'day').startOf('day').utc();
    // The diff time is lower bound, then we add one minute to get the diff upper bound.
    return tomorrow.diff(today, 'minutes') + 1;
  }

  // Next week
  const nextWeek = today.clone().add(1, 'weeks').startOf('isoWeek').utc();
  return nextWeek.diff(today, 'minutes') + 1;
}

function fixStartMomentForWeekends(startMoment: Moment) {
  while (startMoment.isoWeekday() >= 6) {
    startMoment.add(1, 'days');
  }

  return startMoment;
}

export function buildQueryPeriods(
  startDate: Date,
  startHours: number,
  searchDays: number,
  timeConstraints: TimeConstraint[],
  participants: InterviewerParticipant[],
  timezone: string,
) {
  let queryPeriods: QueryPeriod[] = [];

  const withManagedAvailability = participants.some(
    (p) => p.user.schedulingUserIntegration?.availabilities?.length > 0,
  );

  const startMoment = momentTimezone(startDate).utc();
  startMoment.add(relativeMinutesFromToday(startMoment, startHours), 'minutes');

  // Fix start day to not start at a weekend
  fixStartMomentForWeekends(startMoment);

  const endMoment = startMoment.clone().add(searchDays, 'days');

  while (startMoment < endMoment) {
    // Ignore weekends, except if the availability is defined.
    if (
      withManagedAvailability ||
      (startMoment.isoWeekday() !== 6 && startMoment.isoWeekday() !== 7)
    ) {
      const dayConstraints = timeConstraints
        ?.filter(
          (v) =>
            momentTimezone.utc(v.startTime.toUTCString()).dayOfYear() ===
            startMoment.dayOfYear(),
        )
        .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

      queryPeriods = queryPeriods.concat(
        buildQueryPeriod(
          startMoment.clone(),
          dayConstraints,
          timezone,
          withManagedAvailability,
        ),
      );
    }
    startMoment.add(1, 'day');
  }

  return queryPeriods.filter(Boolean).slice(0, MAX_QUERY_PERIOD_ELEMENTS);
}
