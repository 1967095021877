import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface PropTypes {
  summary: string;
  classNames: string;
}

export function SummaryPanel(props: PropTypes) {
  return (
    <div
      className={classNames(
        props.classNames,
        'bg-gray',
        'fs-5',
        'py-2',
        'px-3',
        'rounded-2',
        styles.summary,
      )}
    >
      {props.summary}
    </div>
  );
}
