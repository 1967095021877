import { Col, Row } from 'reactstrap';
import { RichTextEditor } from '../../../../../../../components/RichTextEditor';
import { CreateJobDto } from '../../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { AreaRichTextComposer } from '../../../../../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { JOB_POST_EDITOR_DEFAULT_STATE } from '../../../../../../../utils/applicant_tracking/jobDetails';
import { MarkdownNodePlugin } from '../../../../../../../components/RichTextEditor/plugins/MarkdownNodePlugin';
import { IconSpan } from '../../../../../../../components/IconSpan';
import React from 'react';
import { Control } from 'react-hook-form';

interface PropTypes {
  formControl: Control<CreateJobDto>;
  generatedJobDescription: string;
  updateGlobalStateCallback: (data: any) => void;
}

export function JobDescriptionEditor(props: PropTypes) {
  return (
    <Row className='mt-3'>
      <Col xs={8}>
        <RichTextEditor<CreateJobDto>
          formHookContext={{
            controllerName: 'editorState',
            formControl: props.formControl,
            required: true,
          }}
          render={(field) => (
            <AreaRichTextComposer
              defaultEditorState={
                (field.value as string) ?? JOB_POST_EDITOR_DEFAULT_STATE
              }
              size='lg'
              onChangeJson={(value: string) => field.onChange(value)}
              onChangeHtml={(html: string) =>
                props.updateGlobalStateCallback({ editorStateHtml: html })
              }
              externalPlugins={[
                <MarkdownNodePlugin
                  key={'markdown-node-plugin'}
                  text={props.generatedJobDescription}
                  clearState={true}
                />,
              ]}
            />
          )}
        />
      </Col>
      <Col xs={4}>
        <IconSpan
          spanText='Consider adding dedicated sections to help candidates understand the role better.'
          icon={{
            name: 'bi-info-circle',
            className: 'text-info-500 me-1',
          }}
          className={'fw-normal fs-5 bg-light-info px-3 py-2 rounded'}
        />
      </Col>
    </Row>
  );
}
