import React, { useContext, useEffect, useRef } from 'react';
import { Row } from 'reactstrap';
import { HorizontalDivider } from '../../../../../components/HorizontallDivider';
import { NameInput } from '../NameInput';
import { TemplateTypeInput } from '../TemplateTypeInput';
import { StagesInput } from '../StagesInput';
import { mapJobStageToJobStageInput } from '../../../../../utils/applicant_tracking/planTemplate';
import { JobWorkflowEditContext, JobWorkflowEditContextProps } from '../..';

interface PropTypes {
  setShowAddStage: (showAddStage: boolean) => void;
  setIsValidWorkflow: (isValidWorkflow: boolean) => void;
}

export function FormBody(props: PropTypes) {
  const isInitialRender = useRef(true);
  const pageContext: JobWorkflowEditContextProps = useContext(
    JobWorkflowEditContext,
  );

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    pageContext.setJobStages(
      mapJobStageToJobStageInput(pageContext.referenceTemplate?.jobStages),
    );
  }, [pageContext.referenceTemplate?.id]);

  useEffect(() => {
    props.setIsValidWorkflow(
      pageContext.jobStages?.length > 0 &&
        pageContext.isValid &&
        !pageContext.showAddStage &&
        !pageContext.focusedJobStage,
    );
  }, [
    pageContext.jobStages,
    pageContext.isValid,
    pageContext.showAddStage,
    pageContext.focusedJobStage,
  ]);

  return (
    <>
      <div className='fs-2 fw-bold'>Job Workflow</div>
      <Row className='mt-4'>
        <NameInput
          register={pageContext.register}
          errors={pageContext.errors}
          disabled={pageContext.viewMode}
        />
      </Row>
      {!pageContext.viewMode && !pageContext.editMode && (
        <Row className='mt-4 d-flex align-items-center'>
          <TemplateTypeInput
            control={pageContext.control}
            resetField={pageContext.resetField}
          />
        </Row>
      )}
      <HorizontalDivider classNames='mt-4' />
      <StagesInput
        stages={pageContext.jobStages}
        onAddStage={() => props.setShowAddStage(true)}
      />
    </>
  );
}
