import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { InterviewDetailTab } from '../../Entities/InterviewDetailTab';
import { InterviewTabs } from '../InterviewTabs';
import { Interview } from '../../../../entities/Interview';
import { VideoControlsPanel } from '../../VideoControlsPanel';
import { InterviewTimeline } from '../../InterviewTimeline';
import { HighlightType } from '../../Entities/HighlightType';
import { InterviewService } from '../../../../services/InterviewService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

interface PropTypes {
  activeTab: InterviewDetailTab;
  clipRange: number[];
  interview: Interview;
  interviewClipFormVisible: boolean;
  isPlaying: boolean;
  playTimestamp: number;
  selectedHighlightType: HighlightType;
  startTime: number;
  pickTime: (time: number) => void;
  setActiveTab: (tab: InterviewDetailTab) => void;
  onCreateInterviewClip: () => void;
  onInterviewClipRangeChanged: (range: number[]) => void;
  setInterviewClipFormVisible: (visible: boolean) => void;
  setInterview: (interview: Interview) => void;
  setIsPlaying: (playing: boolean) => void;
  setPlayTimestamp: (playTimestamp: number) => void;
  setSelectedHighlightType: (selectedHighlightType: HighlightType) => void;
  setStartTime: (start: number) => void;
  children: JSX.Element;
  extraComponent?: JSX.Element;
  interviewAiAssistantEnabled: boolean;
}

interface PropTypes {
  activeTab: InterviewDetailTab;
  clipRange: number[];
  interview: Interview;
  interviewClipFormVisible: boolean;
  isPlaying: boolean;
  playTimestamp: number;
  selectedHighlightType: HighlightType;
  startTime: number;
  pickTime: (time: number) => void;
  setActiveTab: (tab: InterviewDetailTab) => void;
  onCreateInterviewClip: () => void;
  onInterviewClipRangeChanged: (range: number[]) => void;
  setInterviewClipFormVisible: (visible: boolean) => void;
  setInterview: (interview: Interview) => void;
  setIsPlaying: (playing: boolean) => void;
  setPlayTimestamp: (playTimestamp: number) => void;
  setSelectedHighlightType: (selectedHighlightType: HighlightType) => void;
  setStartTime: (start: number) => void;
  children: JSX.Element;
  extraComponent?: JSX.Element;
  interviewAiAssistantEnabled: boolean;
}
interface DefaultVideoLayoutPropTypes {
  activeTab: InterviewDetailTab;
  clipRange: number[];
  interview: Interview;
  interviewClipFormVisible: boolean;
  isPlaying: boolean;
  playTimestamp: number;
  selectedHighlightType: HighlightType;
  startTime: number;
  pickTime: (time: number) => void;
  setActiveTab: (tab: InterviewDetailTab) => void;
  onCreateInterviewClip: () => void;
  onInterviewClipRangeChanged: (range: number[]) => void;
  setInterviewClipFormVisible: (visible: boolean) => void;
  setInterview: (interview: Interview) => void;
  setIsPlaying: (playing: boolean) => void;
  setPlayTimestamp: (playTimestamp: number) => void;
  setSelectedHighlightType: (selectedHighlightType: HighlightType) => void;
  setStartTime: (start: number) => void;
  setIsSwappingParticipants: (state: boolean) => void;
  children: JSX.Element;
  extraComponent?: JSX.Element;
  interviewAiAssistantEnabled: boolean;
}

function DefaultVideoLayout(props: DefaultVideoLayoutPropTypes) {
  async function handleSwapParticipants(
    interviewId: number,
    participantAId: number,
    participantBId: number,
  ) {
    // Set isLoading on entire page
    props.setIsSwappingParticipants(true);

    await InterviewService.swapParticipants(
      interviewId,
      participantAId,
      participantBId,
    );

    // When swapping participants, all the UI components change from analytics, to monologues, to highlights.
    window.location.reload();
  }

  return (
    <>
      <VideoControlsPanel
        interview={props.interview}
        startTime={props.startTime}
        isPlaying={props.isPlaying}
        playTimestamp={props.playTimestamp}
        onSelectTime={props.pickTime}
        onChangePlayTimestamp={props.setPlayTimestamp}
        onStartTime={props.setStartTime}
        onPlaying={props.setIsPlaying}
      />
      <DeprecatedPanel
        className='p-0'
        additionalInnerContainerClassNames='px-4 py-2'
      >
        <InterviewTimeline
          interview={props.interview}
          time={props.playTimestamp}
          clipRange={props.clipRange}
          onSelectTime={props.pickTime}
          onSwapParticipants={handleSwapParticipants}
          onCreateInterviewClip={props.onCreateInterviewClip}
          interviewClipsEditing={props.interviewClipFormVisible}
          onInterviewClipRangeChanged={props.onInterviewClipRangeChanged}
          selectedHighlightType={props.selectedHighlightType}
          onSelectedHighlightType={props.setSelectedHighlightType}
        />
      </DeprecatedPanel>
    </>
  );
}

export function DefaultTabLayout(props: PropTypes) {
  const [isSwappingParticipants, setIsSwappingParticipants] =
    useState<boolean>(false);

  if (isSwappingParticipants) return <LoadingSpinner />;

  return isSwappingParticipants ? (
    <LoadingSpinner />
  ) : (
    <Row>
      <Col xs='6'>
        <DefaultVideoLayout
          {...props}
          setIsSwappingParticipants={setIsSwappingParticipants}
        />
      </Col>
      <Col xs='6' data-testid={'interview-tab'}>
        <DeprecatedPanel
          className='d-flex flex-column'
          additionalInnerContainerClassNames='p-4'
        >
          <InterviewTabs
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            interviewAiAssistantEnabled={props.interviewAiAssistantEnabled}
          />
          {props.children}
        </DeprecatedPanel>
        {props.extraComponent}
      </Col>
    </Row>
  );
}
