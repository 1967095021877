import classNames from 'classnames';
import React from 'react';
import { ConditionsSelectOptions } from '..';
import { LabelledSelect } from '../../../../../../../../../components/LabelledSelect';
import { computeNewConditions } from '../../../../../utils/computeNewConditions';
import { ConditionsLabelledMultiSelect } from './ConditionsLabelledMultiSelect';
import { SINGLE_SELECT_CONDITIONS } from '../../../../../index';

interface PropTypes {
  conditionsSelectOptions: ConditionsSelectOptions;
}

export function ConditionsLabelledSelectsCol(props: PropTypes) {
  return (
    <div className='col-4'>
      {!SINGLE_SELECT_CONDITIONS.includes(
        props.conditionsSelectOptions.type.value,
      ) ? (
        <ConditionsLabelledMultiSelect {...props} />
      ) : (
        <LabelledSelect
          options={props.conditionsSelectOptions.available}
          selected={props.conditionsSelectOptions.singleSelected}
          onChange={(e) => {
            const newConditions = computeNewConditions(
              {
                index: props.conditionsSelectOptions.index,
                type: props.conditionsSelectOptions.type,
                invert: props.conditionsSelectOptions.invert,
                multiSelected: props.conditionsSelectOptions.multiSelected,
                singleSelected: e,
              },
              props.conditionsSelectOptions.conditions,
              props.conditionsSelectOptions.conditionsData,
            );
            props.conditionsSelectOptions.setConditions(newConditions);
          }}
          label=' '
          className={classNames('w-100', {
            'mt-4': props.conditionsSelectOptions.index <= 0,
          })}
          placeholder={'Choose Value'}
        />
      )}
    </div>
  );
}
