import React from 'react';
import classNames from 'classnames';
import { FormatButton } from '../FormatButtons';
import { ContentType, ContentLength } from '../types';
import styles from '../styles.module.scss';

interface PropTypes {
  contentType: ContentType;
  contentLength: ContentLength;
  disabled?: boolean;
  setContentType: (contentType: ContentType) => void;
  setContentLength: (contentLength: ContentLength) => void;
}

function MultiLineToopTipText(props: { heading: string; body: string }) {
  return (
    <>
      <div className='fs-5 fw-semibold'>{props.heading}</div>
      <div className='fs-6'>{props.body}</div>
    </>
  );
}

function ContentTypesInput(props: PropTypes) {
  return (
    <>
      <div className='text-dark-500 fs-6 mx-2 pe-1'>Format</div>
      <FormatButton
        disabled={props.disabled}
        active={props.contentType === 'paragraphs'}
        icon='bi-justify-left'
        tooltipText='Paragraphs'
        onClick={() => props.setContentType('paragraphs')}
      />
      <FormatButton
        disabled={props.disabled}
        active={props.contentType === 'bullets'}
        icon='bi-list-ul'
        tooltipText='Bullet Points'
        onClick={() => props.setContentType('bullets')}
      />
    </>
  );
}

function ContentLengthInput(props: PropTypes) {
  return (
    <>
      <div className='text-dark-500 fs-6 me-2 pe-1'>Length</div>
      <FormatButton
        text='S'
        disabled={props.disabled}
        active={props.contentLength === 'short'}
        tooltipText={<MultiLineToopTipText heading='Short' body='50 words' />}
        onClick={() => props.setContentLength('short')}
      />
      <FormatButton
        text='M'
        disabled={props.disabled}
        active={props.contentLength === 'medium'}
        tooltipText={<MultiLineToopTipText heading='Medium' body='100 words' />}
        onClick={() => props.setContentLength('medium')}
      />
      <FormatButton
        text='L'
        disabled={props.disabled}
        active={props.contentLength === 'long'}
        tooltipText={<MultiLineToopTipText heading='Long' body='150 words' />}
        onClick={() => props.setContentLength('long')}
      />
    </>
  );
}

export function FormatsRow(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex px-1 py-2 align-items-center',
        styles['formats-row'],
      )}
    >
      <ContentTypesInput {...props} />
      <div>
        <span className='border-start ms-2 me-3' />
      </div>
      <ContentLengthInput {...props} />
    </div>
  );
}
