import React from 'react';

export default function NoDataPage() {
  return (
    <div className='text-center my-4'>
      <p className='fw-semibold'>No candidate feedback available yet</p>
      <p>
        Talk with us to start collecting candidate feedback in every step of the
        way.
      </p>
    </div>
  );
}
