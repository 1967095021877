import React from 'react';
import { SurveyRequest } from '../../../../reference_check/entities/SurveyRequest';
import { longFormatDate } from '../../../../utils/timeFormat';

interface PropTypes {
  surveyRequest: SurveyRequest;
}

export default function ReportDate(props: PropTypes) {
  const surveySentDate =
    props.surveyRequest?.survey_timestamps?.survey_requested_at;
  const surveyCompletedDate =
    props.surveyRequest?.survey_timestamps?.survey_completed_at;

  return (
    <>
      {surveySentDate && (
        <>
          <span className='rounded-pill bg-white me-2 px-2h py-1 fs-6'>
            {surveySentDate && `Requested at ${longFormatDate(surveySentDate)}`}
          </span>
          <span className='rounded-pill bg-white me-2 px-2h py-1 fs-6'>
            {surveyCompletedDate
              ? `Completed at ${longFormatDate(surveyCompletedDate)}`
              : 'Completion: Pending'}
          </span>
        </>
      )}
    </>
  );
}
