import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import reconnectImage from '../../images/reconnect-email.png';
import styles from './styles.module.scss';

interface PropTypes {
  url: string;
  largeImage?: boolean;
  onClose: () => void;
}

export function ReconnectEmailIntegrationModalBody(props: PropTypes) {
  return (
    <div className='d-flex flex-column align-items-center'>
      <img
        src={reconnectImage}
        alt='Reconnect Email Integration'
        className={classNames(
          props.largeImage ? styles['main-image-large'] : styles['main-image'],
          'mb-4',
        )}
      />
      <div className='row justify-content-center text-center'>
        <div className='fw-bold fs-4 mb-2h'>
          For security reasons your email integration is expired
        </div>
        <div className='fw-semibold fs-5'>
          Reconnect the email integration in your user settings <br></br>to
          continue sending and receiving emails.
        </div>
      </div>
      <div className='d-flex justify-content-end mt-4 gap-3 w-100'>
        <Button color='danger' onClick={() => props.onClose()}>
          Close
        </Button>
        <Button
          color='primary'
          onClick={() => (window.location.href = props.url)}
        >
          Reconnect
        </Button>
      </div>
    </div>
  );
}
