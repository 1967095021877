import React from 'react';
import classNames from 'classnames';

interface PropTypes {
  /**
   * Controls how high or how low you want to render this Layout's children.
   *
   * Possible values: h-100, h-90, h-80, h-70, etc.
   */
  heightClass: string;
  className?: string;
  innerContainerClassName?: string;
  children: React.ReactNode;
  innerref?: React.Ref<HTMLDivElement>;
  style?: React.CSSProperties;
  innerContainerStyle?: React.CSSProperties;
}

export function CenteredLayout(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex',
        'align-items-center',
        props.heightClass,
        props.className,
      )}
      ref={props.innerref}
      style={props.style}
    >
      <div
        className={classNames(
          'text-center w-100',
          props.innerContainerClassName,
        )}
        style={props.innerContainerStyle}
      >
        {props.children}
      </div>
    </div>
  );
}
