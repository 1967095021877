import React, { useEffect, useState } from 'react';
import { OfferForm } from '../..';
import { Col, Row } from 'reactstrap';
import { Chip, ChipColor } from '../../../../../../components/Chip';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { Application } from '../../../../../../entities/v1/applicant_tracking/Application';
import { ApplicationService } from '../../../../../../services/v1/ApplicationService';
import { getCurrencySymbol } from '../../../../../../utils/currency';
import styles from './styles.module.scss';

interface PropTypes {
  applicationId: number;
  form: OfferForm;
  isLoading: boolean;
}

function OfferValue(props: { label: string; text?: string | number }) {
  if (!props.text) {
    return null;
  }

  return (
    <>
      <Col xs='3' className='text-nowrap'>
        <span className='fs-5'>{props.label}</span>
      </Col>
      <Col xs='3' className='text-nowrap'>
        <Chip color={ChipColor.SuccessLight}>{props.text}</Chip>
      </Col>
    </>
  );
}

export function OfferSummary(props: PropTypes) {
  const [application, setApplication] = useState<Application>(null);

  useEffect(() => {
    (async () => {
      setApplication(
        await ApplicationService.show(props.applicationId, [
          'candidate',
          'job',
        ]),
      );
    })();
  }, []);

  return (
    <div className={styles['modal-summary']}>
      {props.isLoading || application == null ? (
        <LoadingSpinner />
      ) : (
        <>
          <span className='fs-5'>
            Are you sure you want to send the <b>{application.job.name}</b>{' '}
            Offer to <b>{application.candidate.name}</b>?
          </span>
          <Row className='px-2h mt-2h gy-2h'>
            <OfferValue label='Start Date' text={props.form.startingDate} />
            <OfferValue label='Equity' text={props.form.equity} />
            <OfferValue
              label='Salary'
              text={
                props.form.salaryCurrency &&
                `${getCurrencySymbol(props.form.salaryCurrency)}${
                  props.form.salary
                }`
              }
            />
            <OfferValue
              label='Working hours'
              text={props.form.workingHours && `${props.form.workingHours}h`}
            />
          </Row>
        </>
      )}
    </div>
  );
}
