import { SurveyQuestionAnswer } from '../entities/SurveyQuestionAnswer';

export function getAnsweredQuestionAnswers(
  answers: SurveyQuestionAnswer[],
): SurveyQuestionAnswer[] {
  return answers.filter((answer) =>
    answer.fields.some(
      (field) => field.value !== null || field.option_ids.length > 0,
    ),
  );
}
