import React, { useCallback, useState } from 'react';
import { InterviewListItem } from '../../../entities/InterviewListItem';
import { DropdownInterview } from '../../DropdownInterview';
import { User } from '../../../entities/User';
import { CancelInterviewConfirmationModal } from '../../CandidatePage/InterviewsTab/CancelInterviewConfirmationModal';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';

interface PropTypes {
  currentUser: User;
  jobHiringMemberRole: JobHiringMemberRole;
  interview: InterviewListItem;
}

export default function InterviewDropdownActions(props: PropTypes) {
  const [interviewToBeCancelled, setInterviewToBeCancelled] = useState(null);
  const [isCancelInterviewModalOpen, setIsCancelInterviewModalOpen] =
    useState(false);

  const onCancelInterview = useCallback(async (interviewId: string) => {
    setInterviewToBeCancelled(interviewId);
    setIsCancelInterviewModalOpen(true);
  }, []);

  const onSuccess = useCallback(() => {
    window.location.href = '/applicant_tracking/interviews/cancel_success';
  }, []);

  const onError = useCallback(() => {
    window.location.href = '/applicant_tracking/interviews/cancel_error';
  }, []);

  return (
    <>
      <CancelInterviewConfirmationModal
        candidateName={props.interview.candidateName}
        interviewId={interviewToBeCancelled}
        isModalOpen={isCancelInterviewModalOpen}
        setIsModalOpen={setIsCancelInterviewModalOpen}
        onSuccess={onSuccess}
        onError={onError}
      />
      <DropdownInterview
        interview={props.interview}
        onCancelInterview={onCancelInterview}
        currentUser={props.currentUser}
        jobHiringMemberRole={props.jobHiringMemberRole}
      />
    </>
  );
}
