type TimeUnit =
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'months'
  | 'years';

interface TimeUnitConversion {
  unit: TimeUnit;
  value: number;
}

const UNIT_VALUES: TimeUnitConversion[] = [
  { unit: 'milliseconds', value: 1 },
  { unit: 'seconds', value: 1000 },
  { unit: 'minutes', value: 1000 * 60 },
  { unit: 'hours', value: 1000 * 60 * 60 },
  { unit: 'days', value: 1000 * 60 * 60 * 24 },
  { unit: 'months', value: 1000 * 60 * 60 * 24 * 30 },
  { unit: 'years', value: 1000 * 60 * 60 * 24 * 365 },
];

export function getDurationInMaxUnit(
  durationMs: number,
  targetUnits: TimeUnit[],
) {
  const sortedUnits = UNIT_VALUES.filter((u) =>
    targetUnits.includes(u.unit),
  ).sort((a, b) => b.value - a.value);

  let maxUnitConversion = null;

  for (const unit of sortedUnits) {
    if (durationMs >= unit.value) {
      const durationInUnit = durationMs / unit.value;
      maxUnitConversion = {
        value: Math.round(durationInUnit),
        unit: unit.unit,
      };
      break;
    }
  }

  if (maxUnitConversion == null) {
    // Smallest time unit provided is too big.
    // i.e. if 'minutes' is provided as the smallest unit, it should enter this `if` for all durationMs<60_000
    maxUnitConversion = { value: 0, unit: sortedUnits.pop().unit };
  }

  if (shouldBeSingular(maxUnitConversion)) {
    maxUnitConversion = {
      ...maxUnitConversion,
      unit: maxUnitConversion.unit.slice(0, -1),
    };
  }

  return maxUnitConversion;
}

function shouldBeSingular(timeUnitConversion: TimeUnitConversion) {
  return timeUnitConversion.value == 1;
}
