import React, { useState } from 'react';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../components/CardHeader';
import { useForm } from 'react-hook-form';
import { JobRequisitionForm } from '../components/JobRequisitionForm';
import { JobRequisitionDto } from '../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { HorizontalDivider } from '../../../../components/HorizontallDivider';
import { ApprovalFlowContainer } from '../components/ApprovalFlowContainer';
import { JobRequisitionService } from '../../../../services/v1/applicant_tracking/JobRequisitionService';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

interface PropTypes {
  currentUserId: number;
}

export default function NewJobRequisitionsPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    control,
    getValues,
    setValue,
    resetField,
    watch,
    formState,
    trigger,
  } = useForm<JobRequisitionDto>({
    mode: 'onChange',
    defaultValues: {
      ownerUserId: props.currentUserId,
    },
  });

  const isSavable = () => {
    return formState.isValid;
  };

  const onSave = async () => {
    setIsLoading(true);
    await JobRequisitionService.create(getValues());

    window.location.href =
      getRedirectUriQueryParam() ?? '/applicant_tracking/job_requisitions';
  };

  return (
    <div className='container'>
      {isLoading && <LoadingSpinner showBackdrop />}
      <h1 className='mb-4'>Add Requisition</h1>
      <Card className='px-6h'>
        <CardHeader isSavable={isSavable()} onSaveClick={onSave} />
        <h3 className={'mt-3'}>Requisition Details</h3>
        <div className='fs-6 text-dark-200 mb-4'>
          Define and configure requisition details
        </div>
        <form>
          <JobRequisitionForm
            formState={formState}
            formRegister={register}
            getValues={getValues}
            setValue={setValue}
            formControl={control}
            formResetField={resetField}
            formWatch={watch}
            reValidate={trigger}
          />
        </form>
        <HorizontalDivider classNames='my-4' />
        <ApprovalFlowContainer requisitionType='job' formControl={control} />
      </Card>
    </div>
  );
}
