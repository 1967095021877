import React, { useEffect, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { SelectFormHook } from '../../../../../components/SelectFormHook';
import { LabelledToggle } from '../../../../../components/LabelledToggle';
import { SelectOption } from '../../../../../components/Select';
import { InterviewTemplate } from '../../../../../entities/v1/applicant_tracking/InterviewTemplate';
import { InterviewTemplateService } from '../../../../../services/v1/applicant_tracking/InterviewTemplateService';

interface PropTypes {
  formControl: Control<any>;
  formResetField: (controllerName: string, options: any) => void;
  formWatch: UseFormWatch<any>;
  hasInterviews: boolean;
  setInterviewTemplate: (t: InterviewTemplate) => void;
  viewMode: boolean;
}

const INTERVIEW_TEMPLATE_ID_FORM_FIELD = 'interviewTemplateId';

function buildInterviewTemplateOptions(
  interviewTemplates: InterviewTemplate[],
): SelectOption[] {
  return interviewTemplates
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((interviewTemplate) => {
      return {
        label: interviewTemplate.name,
        value: interviewTemplate.id.toString(),
      };
    });
}

export function InterviewTemplateToggleInput(props: PropTypes) {
  const interviewTemplateId = props.formWatch(INTERVIEW_TEMPLATE_ID_FORM_FIELD);

  const [isToggleOn, setIsToggleOn] = useState<boolean>(
    props.hasInterviews && !!interviewTemplateId,
  );
  const [isSelectEnabled, setIsSelectEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [interviewTemplates, setInterviewTemplates] = useState<
    InterviewTemplate[]
  >([]);

  useEffect(() => {
    setIsSelectEnabled(isToggleOn && !props.viewMode);

    if (!isToggleOn) {
      props.formResetField(INTERVIEW_TEMPLATE_ID_FORM_FIELD, {
        defaultValue: null,
      });
    }
  }, [isToggleOn]);

  useEffect(() => {
    if (!props.hasInterviews) {
      setIsToggleOn(false);
      setIsSelectEnabled(false);
    }
  }, [props.hasInterviews]);

  useEffect(() => {
    InterviewTemplateService.list()
      .then((list) => setInterviewTemplates(list.interviewTemplates))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!interviewTemplateId) {
      return;
    }

    props.setInterviewTemplate(
      interviewTemplates.find((t) => t.id == interviewTemplateId),
    );
  }, [interviewTemplateId]);

  return (
    <div className='d-flex mt-4 gap-2 flex-column'>
      <div className='d-flex align-items-center'>
        <label className='fs-5 fw-semibold me-2'>
          Do you want to assign an Interview Template to this stage?
        </label>
        <LabelledToggle
          disabled={props.viewMode || !props.hasInterviews}
          checkedLabel='Yes'
          uncheckedLabel='No'
          checked={isToggleOn}
          inputName={'InterviewTemplateToggle'}
          labelPlacement='end'
          size='sm'
          labelClassnames='text-dark-200'
          onChange={() => setIsToggleOn(!isToggleOn)}
        />
      </div>
      <SelectFormHook
        disabled={!isSelectEnabled}
        placeholder={'Select interview template'}
        options={buildInterviewTemplateOptions(interviewTemplates)}
        formHookContext={{
          controllerName: INTERVIEW_TEMPLATE_ID_FORM_FIELD,
          formControl: props.formControl,
          required: false,
        }}
        formResetField={props.formResetField}
        isLoading={isLoading && isSelectEnabled}
        clearable={false}
      />
    </div>
  );
}
