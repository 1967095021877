import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { ActionItemList } from '../../../../entities/v1/applicant_tracking/ActionItem';

export interface ListParams {
  jobId: number;
  completed?: boolean;
  page?: number;
  pageSize?: number;
  hiringMemberIds?: number[];
  expand?: string[];
}

export class ActionItemService {
  public static list(params: ListParams): Promise<ActionItemList> {
    const { jobId, ...rest } = params;

    return ApiService.get<ActionItemList>(
      `/api/v1/applicant_tracking/jobs/${jobId}/action_items`,
      snakecaseKeys(rest),
    );
  }
}
