import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { IconButton } from '../../../../../../components/IconButton';
import {
  ApproverAnswer,
  JobRequisition,
  JobRequisitionStatus,
} from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { User } from '../../../../../../entities/User';
import { MINUTES_IN_DAY, Timedelta } from '../../../../../../utils/timedelta';
import { SpanTooltip } from '../../../../../../components/SpanTooltip';
import { SendReminderModal } from './SendReminderModal';
import { appendRedirectUri } from '../../../../../../utils/url';
import { AlertObject, AlertType } from '../../../../../../components/Alert';

interface PropTypes {
  currentUser: User;
  jobRequisition: JobRequisition;
  jobRequisitionV2FfEnabled: boolean;
  setAlertMessage: (message: AlertObject) => void;
  reloadJobRequisition: () => void;
}

interface SendReminderButtonPropTypes extends PropTypes {
  disabled?: boolean;
  onSendReminderClick: () => void;
}

function isEditable(props: PropTypes): boolean {
  return (
    props.jobRequisition.status === JobRequisitionStatus.Declined ||
    !props.jobRequisition.approvers.some(
      (approver) => approver.answer === ApproverAnswer.Approved,
    )
  );
}

function SendReminderButton(props: SendReminderButtonPropTypes) {
  return (
    <Button
      color='secondary'
      className='me-3'
      onClick={props.onSendReminderClick}
      disabled={props.disabled}
    >
      Send Reminder
    </Button>
  );
}

function SendReminder(props: SendReminderButtonPropTypes) {
  const reminderEnabled =
    !props.jobRequisition.reminderSentAt ||
    Timedelta.timeTillDate(props.jobRequisition.reminderSentAt).toMins() <
      -MINUTES_IN_DAY;

  return reminderEnabled ? (
    <SendReminderButton {...props} />
  ) : (
    <SpanTooltip
      text={<SendReminderButton disabled {...props} />}
      tooltipText='Last reminder sent less than 24 hours ago'
    />
  );
}

function SecondaryAction(props: SendReminderButtonPropTypes) {
  return (
    <>
      {props.jobRequisition.status === 'declined' ? (
        // SL-4797
        false && (
          <IconButton
            iconName='bi-trash'
            color='secondary'
            buttonText='Delete'
            onClick={null}
          />
        )
      ) : (
        <SendReminder {...props} />
      )}
    </>
  );
}

function PrimaryButton(props: PropTypes) {
  return props.jobRequisition.status === 'approved' ? (
    <Button
      color='primary'
      onClick={() =>
        (window.location.href = `/applicant_tracking/job_requisitions/${props.jobRequisition.id}/create_job/job_details`)
      }
    >
      Create Job
    </Button>
  ) : isEditable(props) ? (
    <IconButton
      iconName='bi-pencil'
      color='primary'
      buttonText='Edit'
      onClick={() =>
        (window.location.href = appendRedirectUri(
          `/applicant_tracking/job_requisitions/${props.jobRequisition.id}/edit`,
        ))
      }
    />
  ) : null;
}

function setAlert(
  succeeded: boolean,
  setAlertMessage: (message: AlertObject) => void,
) {
  if (succeeded === true) {
    setAlertMessage({
      message: 'The job requisition email reminder was successfully sent!',
      type: AlertType.Success,
    });
  } else if (succeeded === false) {
    setAlertMessage({
      message: 'Failed to send the job requisition email reminder',
      type: AlertType.Danger,
    });
  }
}

export function OwnerOrAdminActions(props: PropTypes) {
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);

  return (
    <div className='d-flex align-items-center'>
      {props.jobRequisitionV2FfEnabled && (
        <SecondaryAction
          {...props}
          onSendReminderClick={() => setIsReminderModalOpen(true)}
        />
      )}
      <PrimaryButton {...props} />
      {isReminderModalOpen && (
        <SendReminderModal
          {...props}
          jobRequisitionId={props.jobRequisition.id}
          onClose={(succeeded: boolean) => {
            setAlert(succeeded, props.setAlertMessage);
            succeeded && props.reloadJobRequisition();
            setIsReminderModalOpen(false);
          }}
        />
      )}
    </div>
  );
}
