import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ProgressBar } from '../../../../../components/survey_engine/ProgressBar';
import { SurveyLayout } from '../../../../../components/survey_engine/SurveyLayout';
import styles from '../../styles.module.scss';
import { useNavigate } from 'react-router';
import { SurveyTargetInfoService } from '../../../../services/SurveyTargetInfoService';
import classNames from 'classnames';
import { SubmitFeedbackButton } from '../../../../../containers/survey_engine/SurveyPage/SurveyScreen/SubmitFeedbackButton';
import { Survey } from '../../../../../entities/survey_engine/Survey';
import { buildInterviewerJobSurveyQuestion } from '../../../../../utils/candidate_experience/buildInterviewerJobSurveyQuestion';
import { buildInterviewerRoleSurveyQuestion } from '../../../../../utils/candidate_experience/buildInterviewerRoleSurveyQuestion';
import { getQuestionsBasedOnConditions } from '../../../../../utils/survey_engine/getQuestionsBasedOnConditions';
import { getQuestions } from '../../../../../utils/survey_engine/getQuestions';
import { SurveyQuestionAnswer } from '../../../../../entities/survey_engine/SurveyQuestionAnswer';
import { Role } from '../../../../../entities/Role';
import { SurveyQuestionTitle } from '../../../../../containers/survey_engine/SurveyPage/SurveyScreen/SurveyQuestionTitle';
import { MultiOptionsField } from '../../../../../containers/survey_engine/SurveyPage/SurveyScreen/fields/MultiOptionsField';
import { CandidateExperienceSurvey } from '../../../../../entities/candidate_experience/CandidateExperienceSurvey';

const DEFAULT_PROGRESS_BAR_COLOR = '#19ceaf';

interface PropTypes {
  survey: Survey;
  onFinishSurvey: () => void;
}

export function InterviewerSurveyScreen(props: PropTypes) {
  const surveyDetails = props.survey.surveyDetails as CandidateExperienceSurvey;
  const jobSurveyQuestion = buildInterviewerJobSurveyQuestion(
    surveyDetails.targetInfo,
  );
  const roleSurveyQuestion = buildInterviewerRoleSurveyQuestion(
    surveyDetails.targetInfo.available_roles,
  );

  const allQuestions = getQuestionsBasedOnConditions(
    getQuestions(props.survey),
    props.survey.answers,
  ).concat([jobSurveyQuestion, roleSurveyQuestion]);
  const activeSectionIds = new Set(
    allQuestions.map((question) => question.section.id),
  );

  const [targetInfo, setTargetInfo] = useState({
    jobIds: surveyDetails.targetInfo.selected_jobs.map((job) => job.id),
    role: surveyDetails.targetInfo.selected_role,
  });

  useEffect(() => {
    SurveyTargetInfoService.create(
      props.survey.id,
      targetInfo.jobIds,
      targetInfo.role,
    );
  }, [targetInfo]);

  const [jobAnswer, setJobAnswer] = useState<SurveyQuestionAnswer>({
    question_id: jobSurveyQuestion.id,
    survey_id: props.survey.id,
    fields: [
      {
        field_id: -1,
        value: null,
        option_ids: surveyDetails.targetInfo.selected_jobs.map((job) => job.id),
      },
    ],
  });
  const changeJobAnswer = useCallback(
    (fieldId: number, value: string, optionIds?: number[]) => {
      const fields = [];
      setJobAnswer({
        question_id: jobSurveyQuestion.id,
        survey_id: props.survey.id,
        fields: fields.concat({
          field_id: fieldId,
          value,
          option_ids: optionIds,
        }),
      });
      setTargetInfo({ ...targetInfo, jobIds: optionIds });
    },
    [jobAnswer, props.survey, jobSurveyQuestion],
  );

  const [roleAnswer, setRoleAnswer] = useState<SurveyQuestionAnswer>({
    question_id: roleSurveyQuestion.id,
    survey_id: props.survey.id,
    fields: [
      {
        field_id: -2,
        value: null,
        option_ids:
          surveyDetails.targetInfo.selected_role != null
            ? [surveyDetails.targetInfo.selected_role]
            : [],
      },
    ],
  });

  const changeRoleAnswer = useCallback(
    (fieldId: number, value: string, optionIds?: (number | Role)[]) => {
      const fields = [];
      setRoleAnswer({
        question_id: roleSurveyQuestion.id,
        survey_id: props.survey.id,
        fields: fields.concat({
          field_id: fieldId,
          value,
          option_ids: optionIds,
        }),
      });
      setTargetInfo({ ...targetInfo, role: optionIds[0] as Role });
    },
    [roleAnswer, props.survey, roleSurveyQuestion],
  );

  const navigate = useNavigate();
  const goToPreviousStep = useCallback(() => {
    navigate(-1);
  }, []);

  const handleOnSubmitClick = useCallback(() => {
    props.onFinishSurvey();
  }, [props.survey]);

  const progressBarColor =
    props.survey.company_info.primary_color || DEFAULT_PROGRESS_BAR_COLOR;

  return (
    <SurveyLayout
      companyInfo={props.survey.company_info}
      className={styles.surveyPage}
    >
      <Row className='mt-2 mb-5'>
        <Col>
          <ProgressBar
            progress={
              ((activeSectionIds.size - 1) / activeSectionIds.size) * 100
            }
            color={progressBarColor}
            backgroundColor={'white'}
          />
        </Col>
      </Row>
      <div className={classNames(styles.surveySection)}>
        <div className={classNames('p-5')}>
          <Row>
            <Col>
              <h1>{jobSurveyQuestion.section.name}</h1>
              <hr />
            </Col>
          </Row>
          <SurveyQuestionTitle
            question={jobSurveyQuestion}
            style={{
              letterSpacing: `${props.survey.company_info.font_kerning / 2}em`,
            }}
          />
          {jobSurveyQuestion.fields.map((f) => (
            <MultiOptionsField
              key={f.id}
              field={f}
              fieldAnswer={jobAnswer?.fields?.find(
                (qfa) => f.id === qfa.field_id,
              )}
              onChangeAnswer={changeJobAnswer}
            />
          ))}
          <Row className='mb-5' />
          <SurveyQuestionTitle question={roleSurveyQuestion} />
          {roleSurveyQuestion.fields.map((f) => (
            <MultiOptionsField
              key={f.id}
              field={f}
              fieldAnswer={roleAnswer?.fields?.find(
                (qfa) => f.id === qfa.field_id,
              )}
              onChangeAnswer={changeRoleAnswer}
            />
          ))}
        </div>
      </div>
      <Row>
        <Col xs='12' className='my-4'>
          <hr />
        </Col>
        <Col>
          <a
            href='#next'
            onClick={goToPreviousStep}
            className='btn btn-lg btn-secondary py-2 px-5'
          >
            Previous
          </a>
        </Col>
        <Col className='text-end mb-5'>
          <SubmitFeedbackButton
            surveyAnswers={props.survey.answers.concat([roleAnswer, jobAnswer])}
            allQuestions={allQuestions}
            onClick={handleOnSubmitClick}
          />
        </Col>
      </Row>
    </SurveyLayout>
  );
}
