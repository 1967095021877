import { NpsClassification } from '../../../entities/candidate_experience/NpsClassification';
import stylesheetExports from '../../../stylesheets/export.module.scss';
import { ChipColor } from '../../Chip';

export const CPS_RATING_LEGEND = [
  {
    minScore: 0,
    label: 'Needs Improvement',
    color: stylesheetExports.red500,
    bgColor: 'light-danger',
    chipColor: ChipColor.DangerLight,
  },
  {
    minScore: 26,
    label: 'Needs Improvement',
    color: stylesheetExports.chartOrange,
    bgColor: 'light-warning',
    chipColor: ChipColor.OrangeLight,
  },
  {
    minScore: 51,
    label: 'Good',
    color: stylesheetExports.chartGreen,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
  {
    minScore: 66,
    label: 'Great',
    color: stylesheetExports.green700,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
  {
    minScore: 86,
    label: 'Excellent',
    color: stylesheetExports.green700,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
];

export const CPS_RATING_LEGEND_GAUGE_CHART = [
  {
    minScore: -100,
    label: 'Poor',
    color: stylesheetExports.red500,
    bgColor: 'light-danger',
    chipColor: ChipColor.DangerLight,
  },
  {
    minScore: 1,
    label: 'Fair',
    color: stylesheetExports.chartOrange,
    bgColor: 'light-warning',
    chipColor: ChipColor.OrangeLight,
  },
  {
    minScore: 30,
    label: 'Good',
    color: stylesheetExports.green500,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
  {
    minScore: 71,
    label: 'Excellent',
    color: stylesheetExports.green900,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
];

export const DEFAULT_RATING_LEGEND = [
  {
    minScore: 0,
    label: 'Poor',
    color: stylesheetExports.red500,
    bgColor: 'light-danger',
    chipColor: ChipColor.DangerLight,
  },
  {
    minScore: 26,
    label: 'Fair',
    color: stylesheetExports.chartOrange,
    bgColor: 'light-warning',
    chipColor: ChipColor.OrangeLight,
  },
  {
    minScore: 51,
    label: 'Good',
    color: stylesheetExports.chartGreen,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
  {
    minScore: 66,
    label: 'Very Good',
    color: stylesheetExports.green700,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
  {
    minScore: 86,
    label: 'Excellent',
    color: stylesheetExports.green700,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
];

export const CPS_DEFAULT_CLASSIFICATION_LEGEND = [
  {
    minScore: -101,
    classification: NpsClassification.Detractor,
    bgColor: 'light-danger',
    chipColor: ChipColor.DangerLight,
  },
  {
    minScore: 21,
    classification: NpsClassification.Neutral,
    bgColor: 'light-warning',
    chipColor: ChipColor.OrangeLight,
  },
  {
    minScore: 61,
    classification: NpsClassification.Promoter,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
];

export const CPS_CLASSIFICATION_LEGEND = [
  {
    minScore: -1,
    classification: NpsClassification.Detractor,
    bgColor: 'light-danger',
    chipColor: ChipColor.DangerLight,
  },
  {
    minScore: 6,
    classification: NpsClassification.Neutral,
    bgColor: 'light-warning',
    chipColor: ChipColor.OrangeLight,
  },
  {
    minScore: 8,
    classification: NpsClassification.Promoter,
    bgColor: 'light-success',
    chipColor: ChipColor.SuccessLight,
  },
];
