import React from 'react';
import { Control } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { SelectOption } from '../../../../../../components/Select';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { CheckboxPillGroupFormHook } from '../../../../../../components/CheckboxPillGroupFormHook';
import { LocationService } from '../../../../../../services/applicant_tracking/LocationService';
import { AVAILABLE_WORKING_MODELS } from '../../../../../../utils/applicant_tracking/jobDetails';

interface PropTypes {
  options: SelectOption[];
  formControl: Control<CreateJobDto>;
  formResetField: (controllerName: string) => void;
  defaultValue?: string;
}

export async function loadLocationOptions(): Promise<SelectOption[]> {
  return (await LocationService.list()).map((location) => ({
    value: location.id.toString(),
    label: location.name,
  }));
}

export function LocationInput(props: PropTypes) {
  const required = true;

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <LabelledSelectFormHook<CreateJobDto>
          label={'Location'}
          placeholder={'Select location'}
          formHookContext={{
            controllerName: 'locationId',
            formControl: props.formControl,
            required: required,
          }}
          options={props.options}
        />
      </Col>
      <Col xs={3} className={'align-self-end mb-1'}>
        <CheckboxPillGroupFormHook<CreateJobDto>
          formHookContext={{
            controllerName: 'locationType',
            formControl: props.formControl,
            required: required,
          }}
          resetField={props.formResetField}
          childrenProps={AVAILABLE_WORKING_MODELS}
        />
      </Col>
    </Row>
  );
}
