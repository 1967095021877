import React, { useState } from 'react';
import { BulkApplicationReviewResultModal } from '../applicant_tracking/BulkApplicationReview/BulkApplicationReviewResultModal';

type SupportedWrapperFlows = 'bulk_application_review_results'; // Add more flows here

interface PropTypes {
  flow: SupportedWrapperFlows;
  params: any;
}

// Use this component to wrap any route that needs to render another component on top of it
// Use @route-wrap to specify the flow and pass the necessary params
export default function RouteWrapper(props: PropTypes) {
  const [wrapperActive, setWrapperActive] = useState(true);

  const handleClose = () => {
    setWrapperActive(false);
  };

  return (
    wrapperActive && (
      <div>
        {props.flow === 'bulk_application_review_results' && (
          <BulkApplicationReviewResultModal
            {...props.params}
            onClose={handleClose}
          />
        )}
      </div>
    )
  );
}
