import { Interview } from '../../../../entities/Interview';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import classNames from 'classnames';
import styles from './styles.module.scss';
import React from 'react';
import { CardEmptyState } from '../CardEmptyState';

export function SummaryCard(props: { interview: Interview }) {
  return (
    <DeprecatedPanel
      additionalInnerContainerClassNames='p-4 h-100'
      className='h-100'
    >
      <h3>Summary</h3>
      {props.interview.summary?.text ? (
        <div className={classNames(styles.interviewSummary)}>
          <p>{props.interview.summary.text}</p>
        </div>
      ) : (
        <CardEmptyState />
      )}
    </DeprecatedPanel>
  );
}
