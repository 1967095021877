import { JobPostingDetailsForm } from '..';
import { MultiLangTargetGroupSelect } from './MultiLangTargetGroupSelect';
import React from 'react';
import { TargetGroupSelect } from './TargetGroupSelect';
import { TargetGroupValues } from './TargetGroupValues';
import { FormGroup } from '../FormGroup';

interface PropTypes {
  targetGroupValues: TargetGroupValues;
  form: JobPostingDetailsForm;
  onInputChange: (name: string, value: unknown) => void;
}

export function TargetGroup(props: PropTypes) {
  return (
    <FormGroup>
      <h4>Target Group</h4>
      <MultiLangTargetGroupSelect
        name={'educationLevel'}
        label='Education Level'
        values={props.targetGroupValues?.educationLevels}
        selected={props.form.educationLevel}
        onInputChange={props.onInputChange}
      />
      <MultiLangTargetGroupSelect
        name={'seniority'}
        label='Seniority'
        values={props.targetGroupValues?.seniorities}
        selected={props.form.seniority}
        onInputChange={props.onInputChange}
      />
      <TargetGroupSelect
        name={'industry'}
        label='Industry'
        values={props.targetGroupValues?.industries}
        selected={props.form.industry}
        onInputChange={props.onInputChange}
      />
      <TargetGroupSelect
        name={'jobCategory'}
        label='Job Category'
        values={props.targetGroupValues?.jobFunctions}
        selected={props.form.jobCategory}
        onInputChange={props.onInputChange}
      />
    </FormGroup>
  );
}
