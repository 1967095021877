import React from 'react';
import { EmptyState } from '../../../../components/EmptyState';

export function SessionsEmptyResultState() {
  return (
    <EmptyState
      title='No training sessions were found'
      text={
        <p>
          Please try a different combination of filters to find the training
          sessions you are looking for.
        </p>
      }
    />
  );
}
