import React from 'react';
import { Header } from '../Header';
import { Button } from 'reactstrap';
import { SpanTooltip } from '../../../../components/SpanTooltip';

interface PropTypes {
  isSaveEnabled: boolean;
  emptyAvailabilities: boolean;
  onSave: () => void;
}

const EMPTY_AVAILABILITIES_TOOLTIP_TEXT =
  'Select at least one time slot to save your availability.';

export function EditHeader(props: PropTypes) {
  return (
    <Header
      saveButton={<SaveButton {...props} />}
      descriptionText={
        <>
          <small>
            To set your availability click on the time slots to make them
            available or unavailable.
          </small>
          <small>
            You can also click and drag to select multiple time slots.
          </small>
        </>
      }
    />
  );
}

function SaveButton(props: PropTypes) {
  return (
    <SpanTooltip
      tooltipText={EMPTY_AVAILABILITIES_TOOLTIP_TEXT}
      showTooltip={props.emptyAvailabilities}
      text={
        <Button
          color={'primary'}
          type={'submit'}
          onClick={props.onSave}
          disabled={!props.isSaveEnabled}
        >
          Save Availability
        </Button>
      }
    />
  );
}
