import React, { useCallback, useState } from 'react';
import { PublishJobConfirmationModal } from '../../../../applicant_tracking/Jobs/PublishJob/PublishJobConfirmationModal';
import { JobDetails } from '../../../../../entities/JobDetails';
import { IconButton } from '../../../../../components/IconButton';
import { SpanTooltip } from '../../../../../components/SpanTooltip';

interface PropTypes {
  jobDetails: JobDetails;
  updateEnabled: boolean;
  publishEnabled: boolean;
}

function isButtonEnabled(
  jobDetails: JobDetails,
  publishEnabled: boolean,
  updateEnabled: boolean,
) {
  if (jobDetails.status === 'published') {
    return updateEnabled;
  }

  return publishEnabled;
}

function PublishIconButton(props: {
  jobDetails: JobDetails;
  isEnabled: boolean;
  publishAction: string;
  onPublish: () => void;
}) {
  const iconButton = (
    <IconButton
      color={props.jobDetails.status === 'published' ? 'secondary' : 'success'}
      onClick={props.onPublish}
      disabled={!props.isEnabled}
      iconName={
        props.jobDetails.status === 'published'
          ? 'bi-cloud-slash'
          : 'bi-cloud-arrow-up'
      }
      buttonClass='me-3'
      iconClass='fs-4'
      buttonText={`${props.publishAction} Job`}
    />
  );

  if (props.isEnabled) {
    return iconButton;
  }

  return (
    <SpanTooltip
      text={iconButton}
      tooltipText={
        'Edit the job and fill in all mandatory fields before you can publish it'
      }
      placement='top'
      tooltipStyle={{ maxWidth: '250px' }}
    />
  );
}

export function PublishButton(props: PropTypes) {
  const [isPublishJobModalOpen, setIsPublishJobModalOpen] = useState(false);
  const onPublish = useCallback(async () => {
    setIsPublishJobModalOpen(true);
  }, []);
  const publishAction =
    props.jobDetails.status == 'published' ? 'Unpublish' : 'Publish';
  const isEnabled = isButtonEnabled(
    props.jobDetails,
    props.publishEnabled,
    props.updateEnabled,
  );

  return (
    <>
      <PublishJobConfirmationModal
        jobId={props.jobDetails.id}
        jobName={props.jobDetails.name}
        publishAction={publishAction}
        isModalOpen={isPublishJobModalOpen}
        setIsModalOpen={setIsPublishJobModalOpen}
        redirectUri={window.location.href}
      />
      <PublishIconButton
        jobDetails={props.jobDetails}
        isEnabled={isEnabled}
        publishAction={publishAction}
        onPublish={onPublish}
      />
    </>
  );
}
