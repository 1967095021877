import React from 'react';
import { CopyButtons } from '../EmailTemplateInput/CopyButtons';

export const EMAIL_TEMPLATE_VARIABLES: { [key: string]: string } = {
  "Candidate's Name": '{{{candidate_name}}}',
  'Job Title': '{{{job_title}}}',
  'Company Name': '{{{company_name}}}',
  'Sender Name': '{{{sender_name}}}',
};

export function CopyVariables() {
  return (
    <div className='mt-4'>
      <span className='fs-5'>
        Copy the following variables to personalize your email subject and body.
      </span>
      <CopyButtons variables={EMAIL_TEMPLATE_VARIABLES} />
    </div>
  );
}
