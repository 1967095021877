import React from 'react';
import { LabelledSelect } from '../../../../components/LabelledSelect';
import { FeedbackFilters } from '.';
import { SelectOption } from '../../../../components/Select';

interface PropTypes {
  isLoading: boolean;
  filters: FeedbackFilters;
  setFilters: (filters: FeedbackFilters) => void;
}

const ALL_STATUS_OPTION: SelectOption = {
  value: '',
  label: 'All Status',
};

const STATUS_OPTIONS: SelectOption[] = [
  ALL_STATUS_OPTION,
  {
    value: 'completed',
    label: 'Filled',
  },
  {
    value: 'pending',
    label: 'Not Filled',
  },
];

export function StatusFilter(props: PropTypes) {
  const selectedOption =
    STATUS_OPTIONS.find((option) => option.value === props.filters?.status) ||
    ALL_STATUS_OPTION;

  return (
    <LabelledSelect
      label='Status'
      selected={selectedOption}
      onChange={(option) =>
        props.setFilters({
          ...props.filters,
          status: option?.value,
        })
      }
      options={STATUS_OPTIONS}
      labelClassName='fs-5 fw-semibold mt-4'
      isClearable={selectedOption.value !== ALL_STATUS_OPTION.value && true}
      disabled={props.isLoading}
    />
  );
}
