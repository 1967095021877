import React from 'react';
import { EmptyState } from '../../../EmptyState';

export function NoSlotsEmptyState() {
  return (
    <div className={'h-100 d-grid align-items-center justify-content-center'}>
      <EmptyState
        title={'No slots available'}
        titleClassName={'text-center fs-3'}
        text={
          'No time slots were found for the date range, interview duration and participants you selected.'
        }
        textFontSize={'fs-6'}
        excludeImage={true}
        cardless={true}
      />
    </div>
  );
}
