import { randomInt } from '../../../utils/number';
import { getRedirectUriQueryParam } from '../../../utils/url';

export const REDIRECT_URI =
  getRedirectUriQueryParam() ?? '/organization_settings/sources';
export const SUCCESSFUL_ALERT_MESSAGE_SINGLE =
  'The source was successfully created!';
export const SUCCESSFUL_ALERT_MESSAGE_MULTIPLE =
  'The sources were successfully created!';
export const DUPLICATES_FOUND_ALERT_MESSAGE =
  'This source already exists. Change the name before proceeding.';
export const SOURCE_NAME_MAX_CHARACTERS = 65;
export const SUCCESSFUL_ALERT_UPDATE_MESSAGE =
  'The source was successfully updated!';

export interface SourceData {
  clientId: number;
  name: string;
  enabledOnCareers: boolean;
}

export function emptySourceData(): SourceData {
  return createSourceData('', true);
}

export function createSourceData(
  name: string,
  enabledOnCareers: boolean,
): SourceData {
  return {
    clientId: randomInt(),
    name,
    enabledOnCareers,
  } as SourceData;
}
