import React, { useRef } from 'react';
import { GroupBase } from 'react-select';
import {
  AsyncPaginate as AsyncPaginateBase,
  AsyncPaginateProps,
} from 'react-select-async-paginate';
import classNames from 'classnames';
import { Size } from '../../utils/types/Size';
import { selectTheme } from './selectTheme';
import { selectStyle } from './selectStyle';
import { ClearIndicator } from './ClearIndicator';
import { DropdownIndicator } from './DropdownIndicator';
import { Option } from './Option';
import { ValueContainer } from './ValueContainer';

export interface PropTypes<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
> extends Omit<
    AsyncPaginateProps<OptionType, Group, Additional, false>,
    'placeholder'
  > {
  testId?: string;
  size?: Size;
  placeholder?: string;
  isClearable?: boolean;
}

export function SinglePaginatedSelect<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
>(props: PropTypes<OptionType, Group, Additional>) {
  const containerRef = useRef(null);

  return (
    <div ref={containerRef}>
      <AsyncPaginateBase
        {...props}
        className={classNames(
          props.className,
          props.size === 'sm' ? 'fs-6' : 'fs-5',
        )}
        isMulti={false}
        isSearchable={true}
        isClearable={props.isClearable}
        id={props.testId}
        placeholder={
          <div className='text-truncate'>
            {props.placeholder || 'Select...'}
          </div>
        }
        menuPlacement={props.menuPlacement || 'auto'}
        components={{
          ClearIndicator,
          DropdownIndicator,
          ValueContainer,
          Option,
        }}
        theme={selectTheme}
        styles={selectStyle(props.size || 'md')}
        closeMenuOnSelect={true}
        aria-label={props.placeholder}
        cacheUniqs={[props.placeholder]}
      />
    </div>
  );
}
