import { InterviewAiAssistantConversation } from '../../entities/InterviewAiAssistantConversation';
import { ApiService } from '../ApiService';

export class InterviewAiAssistantConversationService {
  public static list(
    interviewId: number,
  ): Promise<InterviewAiAssistantConversation[]> {
    return ApiService.get<InterviewAiAssistantConversation[]>(
      `/api/interviews/${interviewId}/interview_ai_assistant_conversations`,
    );
  }
}
