import React from 'react';
import { Table } from 'reactstrap';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { HiringMemberSettingsType } from '../../../../../entities/HiringMemberSettingsType';
import { HiringMemberList } from '../../../../../entities/v1/HiringMemberList';

interface PropTypes {
  hiringMemberSettingsType: HiringMemberSettingsType;
  organizationAutoRecordingEnabled: boolean;
  organizationSettingEnabled: boolean;
  hiringMembersList: HiringMemberList;
  settingsQueryKey: string;
}

export function HiringMemberSettingTablePanel(props: PropTypes) {
  const columns = [
    { key: 'name', label: 'Name', last: false },
    { key: 'email', label: 'Email', last: false },
    {
      key: 'auto_recording',
      label: 'Auto-recording',
      last: props.hiringMemberSettingsType == 'auto_recording',
    },
  ];

  if (props.hiringMemberSettingsType === 'recording_consent_optout') {
    columns.push({ key: 'opt-out', label: 'Opt-out', last: true });
  }

  return (
    <Table
      borderless
      className={classNames(
        'mt-3 m-0',
        'table-fixed',
        styles['overview-table'],
        styles['all-users-table'],
      )}
    >
      <>
        <TableHeader columns={columns} />
        <TableBody
          hiringMemberSettingsType={props.hiringMemberSettingsType}
          hiringMembersList={props.hiringMembersList}
          organizationAutoRecordingEnabled={
            props.organizationAutoRecordingEnabled
          }
          organizationSettingEnabled={props.organizationSettingEnabled}
          settingsQueryKey={props.settingsQueryKey}
        />
      </>
    </Table>
  );
}
