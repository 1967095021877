import classNames from 'classnames';
import React from 'react';
import { getTrendDesign } from '../../MetricCard';

interface PropTypes {
  trend?: number;
  className?: string;
}

export function Trend(props: PropTypes) {
  const [trendColor, trendIcon] = getTrendDesign(props.trend);

  return (
    <div className={classNames(`text-${trendColor}`, props.className)}>
      <i className={classNames('bi', trendIcon)}></i>
      {props.trend != null && !isNaN(props.trend) && props.trend !== 0
        ? `${Math.abs(props.trend)}%`
        : 'N/A'}
    </div>
  );
}
