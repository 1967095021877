import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles.module.scss';
import { Tooltip } from 'reactstrap';

interface PropTypes {
  value: string | number;
  label: string;
  color?: string;
  info?: string;
  infoColor?: string;
}

export function MetricValue(props: PropTypes) {
  const ref = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const textColor = props.value ? props.color || 'primary' : 'gray-700';

  useEffect(() => {
    const el = ref.current;
    if (el === null) return;

    setIsOverflowing(el.offsetWidth < el.scrollWidth);
  }, [props.value]);

  return (
    <div className='px-0'>
      <div
        className={classNames(
          'fw-bold',
          `text-${textColor}`,
          styles['responsive-fs-6'],
          'text-truncate',
        )}
        ref={ref}
      >
        <span className={classNames(styles['responsive-fs-1'])}>
          {props.value ? props.value : 'N/A'}
        </span>
        {props.info && props.value != 0 && (
          <span
            className={classNames(
              'fw-normal ms-1 ms-lg-2',
              `text-${props.infoColor}`,
            )}
          >
            {props.info}
          </span>
        )}
      </div>
      {isOverflowing && (
        <Tooltip
          target={ref.current}
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          placement='top'
        >
          <div className='text-white'>{props.info || props.value}</div>
        </Tooltip>
      )}
    </div>
  );
}
