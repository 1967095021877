import { get, KnownKeys, pop, set } from '../localArchive';
import { appendRedirectUri, getQueryParam } from '../url';
import { BulkSelfSchedulingDto } from '../../services/dtos/BulkSelfSchedulingDto';

export const SESSION_UUID_PARAM = 'session_uuid';

export function redirectToBulkSelfSchedulingPage(
  applicationIds: number[],
  jobId: number,
  interviewStageId: number,
) {
  const sessionUUID = crypto.randomUUID();

  set(
    KnownKeys.BulkSelfScheduling,
    {
      applicationIds: applicationIds,
    },
    sessionUUID,
  );

  window.location.href = appendRedirectUri(
    `/applicant_tracking/interview_candidate_users/bulk_new?job_id=${jobId}&interview_stage_id=${interviewStageId}&${SESSION_UUID_PARAM}=${sessionUUID}`,
  );
}

export function getSessionUUID(): string {
  return getQueryParam(SESSION_UUID_PARAM);
}

export function getSessionSelfSchedulingDto(): BulkSelfSchedulingDto {
  return get<BulkSelfSchedulingDto>(
    KnownKeys.BulkSelfScheduling,
    getSessionUUID(),
  );
}

export function setSessionSelfSchedulingDto(dto: BulkSelfSchedulingDto): void {
  set<BulkSelfSchedulingDto>(
    KnownKeys.BulkSelfScheduling,
    dto,
    getSessionUUID(),
  );
}

export function popSessionSelfSchedulingDto(): BulkSelfSchedulingDto {
  return pop(KnownKeys.BulkSelfScheduling, getSessionUUID());
}
