import React from 'react';
import { Col, Row } from 'reactstrap';
import { PageTitle } from '../../../components/PageTitle';
import { EmailTemplate } from '../../../entities/EmailTemplate';
import { HorizontalDivider } from '../../../components/HorizontallDivider';
import { InputFormField } from '../../../components/InputFormField';
import { SetupPageHeader } from '../../../components/SetupPageHeader';
import { FormFieldHeader } from '../../../components/FormFieldHeader';
import { AreaRichTextComposer } from '../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { InputRichTextComposer } from '../../../components/RichTextEditor/composers/InputRichTextComposer';
import { BackArrow } from '../../../components/BackArrow';
import { IconButton } from '../../../components/IconButton';
import { Card } from '../../../components/Card';
import { AttachmentInputList } from '../../SendEmailModal/EmailModalBody/EmailFooter/AttachmentInputList';
import { appendRedirectUri } from '../../../utils/url';

interface PropTypes {
  emailTemplate: EmailTemplate;
  editEnabled: boolean;
  editEmailTemplateUrl: string;
}

function CardHeader() {
  return (
    <SetupPageHeader
      title={'Email Template'}
      firstItems={[]}
      intermediateItems={[]}
    />
  );
}

function Title(props: PropTypes) {
  return (
    <div className='d-flex justify-content-between'>
      <div className='d-flex me-auto align-items-center'>
        <BackArrow url='/organization_settings/email_templates' />
        <PageTitle text={props.emailTemplate.name} />
      </div>
      <div>
        <IconButton
          color='primary'
          buttonText='Edit'
          iconName='bi-pencil'
          onClick={() =>
            (window.location.href = appendRedirectUri(
              props.editEmailTemplateUrl,
            ))
          }
          disabled={!props.editEnabled}
        />
      </div>
    </div>
  );
}

export default function ShowEmailTemplatePage(props: PropTypes) {
  return (
    <Row className='row g-3 mb-4 align-items-center justify-content-between'>
      <Title {...props} />
      <Card>
        <CardHeader />
        <Row className='my-4'>
          <Col xs={3}>
            <InputFormField
              fieldName='Name'
              classNames='fs-5 fw-normal'
              type='text'
              registerReturn={undefined}
              value={props.emailTemplate.name}
              disabled={true}
            />
          </Col>
        </Row>
        <HorizontalDivider />
        <Row className='my-4'>
          <Col xs={6}>
            <FormFieldHeader fieldName='Subject' />
            <InputRichTextComposer
              defaultEditorState={props.emailTemplate.subject}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <>
            <Row className='mt-3'>
              <Col xs='9'>
                <>
                  <FormFieldHeader fieldName='Body' />
                  <AreaRichTextComposer
                    defaultEditorState={props.emailTemplate.template}
                    disabled={true}
                    size='md'
                  />
                  <AttachmentInputList
                    attachments={props.emailTemplate.attachments}
                  />
                </>
              </Col>
            </Row>
          </>
        </Row>
      </Card>
    </Row>
  );
}
