import React from 'react';
import { InterviewQuestion } from '../../../../entities/InterviewQuestion';
import { InterviewSidekick } from '../../../../entities/InterviewSidekick';
import { NotePanel } from '../NotePanel';
import { InterviewQuestionsPanel } from './InterviewQuestionsPanel';

interface PropTypes {
  interview: InterviewSidekick;
  onInterviewMarkerCreated: (kind: string, note?: string) => void;
  onInterviewQuestionsUpdated: (question: InterviewQuestion) => void;
}

export function InterviewQuestionsPage(props: PropTypes) {
  return (
    <>
      <InterviewQuestionsPanel
        interviewQuestions={props.interview.interview_questions}
        onInterviewQuestionsUpdated={props.onInterviewQuestionsUpdated}
      />
      <NotePanel
        interviewId={props.interview.id}
        userId={props.interview.current_user.id}
        interviewMarkers={props.interview.interview_markers}
        onInterviewMarkerUpdated={props.onInterviewMarkerCreated}
        showAcknowledgeChip={true}
      />
    </>
  );
}
