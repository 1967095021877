import React from 'react';

interface PropTypes {
  showWarning: boolean;
  valuesGenerated: boolean;
}

export function WarningOnDomainChange(props: PropTypes) {
  const message = props.valuesGenerated
    ? 'You changed the Email Domain Settings. Please update the new values on your DNS.'
    : 'You changed the Email Domain Settings. Please generate a new value and update it on your DNS.';

  return (
    <>
      {props.showWarning && (
        <div className='row mt-3 fs-6'>
          <div className='d-flex justify-content-start'>
            <i className='bi bi-exclamation-circle text-warning mx-2'></i>
            {message}
          </div>
        </div>
      )}
    </>
  );
}
