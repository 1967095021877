import React, { useEffect, useState } from 'react';
import { CloseableModal } from '../../components/CloseableModal';
import { EmailModalBody } from './EmailModalBody';
import { Email } from '../../entities/Email';
import { ConnectEmailIntegrationModal } from './ConnectEmailIntegrationModal';
import { EmailFooter } from './EmailModalBody/EmailFooter';
import { AttachmentDto, SendEmailDto } from '../../services/dtos/EmailDto';
import { SelectOption } from '../../components/Select';
import { FileContent, useImperativeFilePicker } from 'use-file-picker';
import { AttachmentInputList } from './EmailModalBody/EmailFooter/AttachmentInputList';
import { EmailSizeValidator, fileSizeBytes } from '../../utils/emails';
import { EmailUserIntegration } from '../../entities/EmailUserIntegration';
import { ReconnectEmailIntegrationModalBody } from '../../components/ReconnectEmailIntegrationModal/Modal';
import { UserService } from '../../services/v1/UserService';
import {
  getConnectionEmailUrl,
  isFailedIntegration,
} from '../../utils/espUserIntegration';
import { AlertMessage } from '../../components/AlertMessage';
import { getLabelForCount } from '../../utils/grammar';

interface PropTypes {
  applicationIds: number[];
  hasEmailConnected?: boolean;
  isOpen: boolean;
  modalTitle?: string;
  onCloseModal: () => void;
  onSendEmail: (props: SendEmailDto) => Promise<void>;
  replyToEmail?: Email;
  toEmail?: string;
  warningDescription?: string;
}

export const MAX_FILE_SIZE_MB = 10;
export const MAX_FILES = 6;

export function SendEmailModal(props: PropTypes) {
  const [attachments, setAttachments] = useState<AttachmentDto[]>([]);
  const [cc, setCc] = useState<SelectOption[]>(null);
  const [bcc, setBcc] = useState<SelectOption[]>(null);
  const [emailJson, setEmailJson] = useState<string>(null);
  const [html, setHtml] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [subjectJson, setSubjectJson] = useState<string>(null);
  const [subject, setSubjectText] = useState<string>(
    props.replyToEmail?.subject,
  );
  const [emailIntegration, setEmailIntegration] =
    useState<EmailUserIntegration>(null);
  const [emailIntegrationsLoading, setEmailIntegrationsLoading] =
    useState<boolean>(true);
  const hasEmailConnected = emailIntegrationsLoading || props.hasEmailConnected;

  const handleRemoveFile = (index: number) => {
    const fileToDelete = attachments[index];
    const plainFileToDelete = plainFiles.find(
      (i) => i.name === fileToDelete.filename,
    );

    if (plainFileToDelete !== null) {
      removeFileByReference(plainFileToDelete);
    }

    setAttachments(attachments.filter((_i, idx) => idx !== index));
  };

  const processAndSendEmail = () => {
    setIsLoading(true);

    props
      .onSendEmail({
        cc: cc?.map((option) => option.value),
        bcc: bcc?.map((option) => option.value),
        subject: subject,
        bodyHtml: html,
        attachments: attachments,
        replyToEmailId: props.replyToEmail?.id,
      })
      .finally(() => setIsLoading(false));
  };

  const [
    openFileSelector,
    {
      filesContent,
      errors,
      removeFileByReference,
      plainFiles,
      clear: clearUploadedFiles,
    },
  ] = useImperativeFilePicker({
    multiple: true,
    readAs: 'DataURL',
    maxFileSize: MAX_FILE_SIZE_MB,
    readFilesContent: true,
    limitFilesConfig: {
      max: MAX_FILES - attachments.filter((i) => i.id != null).length,
    },
    validators: [new EmailSizeValidator(fileSizeBytes(attachments))],
  });

  useEffect(() => {
    const newAttachmentDto = [...attachments];

    filesContent.forEach((fileContent: FileContent, index: number) => {
      if (
        newAttachmentDto.find((i) => fileContent.name === i.filename) == null
      ) {
        newAttachmentDto.push({
          size: plainFiles[index].size,
          filename: fileContent.name,
          contentBase64: fileContent.content,
        });
      }
    });

    setAttachments(newAttachmentDto);
  }, [filesContent]);

  useEffect(() => {
    UserService.showEmailIntegration()
      .then(setEmailIntegration)
      .finally(() => setEmailIntegrationsLoading(false));
  }, []);

  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={props.onCloseModal}
      isOpen={props.isOpen}
      size={'lg'}
      headerTitle={
        isFailedIntegration(emailIntegration)
          ? 'Email Integration Expired'
          : props.modalTitle || 'Send Email'
      }
      bodyChildren={
        isFailedIntegration(emailIntegration) ? (
          <ReconnectEmailIntegrationModalBody
            largeImage
            url={getConnectionEmailUrl()}
            onClose={props.onCloseModal}
          />
        ) : hasEmailConnected ? (
          <>
            {(props.warningDescription || props.applicationIds.length > 1) && (
              <>
                <AlertMessage
                  hasOverlay={false}
                  icon={{ name: 'bi-info-circle' }}
                  className='mb-4 mt-0 fs-5 bg-yellow-light'
                  text={
                    <span>
                      {props.warningDescription ??
                        `Emails will be sent individually to ${getLabelForCount(
                          'candidate',
                          props.applicationIds.length,
                        )}.`}
                    </span>
                  }
                />
              </>
            )}
            <EmailModalBody
              applicationIds={props.applicationIds}
              bcc={bcc}
              cc={cc}
              emailJson={emailJson}
              isLoading={isLoading}
              toEmail={props.toEmail}
              replyToEmail={props.replyToEmail}
              subjectJson={subjectJson}
              setAttachments={setAttachments}
              clearUploadedFiles={clearUploadedFiles}
              setBcc={setBcc}
              setCc={setCc}
              setEmailJson={setEmailJson}
              setHtml={setHtml}
              setIsValid={setIsValid}
              setSubjectText={setSubjectText}
              setSubjectJson={setSubjectJson}
            />
            {!isLoading && (
              <AttachmentInputList
                attachments={attachments}
                removeFileByIndex={handleRemoveFile}
              />
            )}
          </>
        ) : (
          <ConnectEmailIntegrationModal
            onClose={props.onCloseModal}
            url={getConnectionEmailUrl()}
          />
        )
      }
      footerChildren={
        hasEmailConnected &&
        !isFailedIntegration(emailIntegration) &&
        !isLoading && (
          <EmailFooter
            fileLength={attachments.length}
            errors={errors}
            onClose={props.onCloseModal}
            isValid={isValid && !isLoading}
            onSubmit={processAndSendEmail}
            isLoading={emailIntegrationsLoading}
            openFileSelector={openFileSelector}
          />
        )
      }
    />
  );
}
