import React from 'react';

interface PropTypes {
  inviterUserName: string;
  organizationName: string;
}

export function HeaderMessageCol(props: PropTypes) {
  return (
    <div className='col-auto p-3'>
      <div className='fs-4 text-center'>
        You have been invited by <strong>{props.inviterUserName}</strong> to
        join <strong>{props.organizationName}</strong> on{' '}
        <strong>Screenloop</strong>.
      </div>
    </div>
  );
}
