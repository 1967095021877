import React from 'react';
import { FormFieldHeader } from '../../../../../components/FormFieldHeader';

interface PropTypes {
  name: string;
  onName?: (value: string) => void;
  readOnly?: boolean;
}

export function NameInput(props: PropTypes) {
  return (
    <div>
      <FormFieldHeader
        fieldName='Name'
        isRequired={!props.readOnly}
        classNames='fw-semibold'
      />
      <input
        className='form-control fs-5'
        placeholder='Insert approval flow name'
        value={props.name}
        onChange={(event) => props.onName(event.target.value)}
        disabled={props.readOnly}
      />
    </div>
  );
}
