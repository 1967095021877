import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Screening } from '../../../../../entities/Screening';
import { BackgroundCheckService } from '../../../../../services/BackgroundCheckService';
import { ChecksFormGroup, SelectOption } from './ChecksFormGroup';
import { humanize } from '../../../../../utils/humanize';
import { Application } from '../../../../../entities/Application';
import { CountryFormGroup } from './CountryFormGroup';
import { EmploymentSectorFormGroup } from './EmploymentSectorFormGroup';
import { EmailFormGroup } from './EmailFormGroup';
import { RolePositionFormGroup } from './RolePositionFormGroup';

interface PropTypes {
  onCancel: () => void;
  application: Application;
}

export interface BackgroundCheckRequest {
  country: string;
  checks: { id: string; provider: string }[];
  email: string;
  optionalParams?: { id: string; value: string }[];
}

function FormActions(props: { disabled: boolean; onCancel: () => void }) {
  return (
    <div className='d-flex justify-content-end gap-3 mt-4'>
      <Button color='danger' onClick={props.onCancel} disabled={props.disabled}>
        Cancel
      </Button>
      <Button color={'primary'} type='submit' disabled={props.disabled}>
        Request
      </Button>
    </div>
  );
}

function Information() {
  return (
    <>
      <div className='label fs-6 ms-4 ps-3 mt-3 text-dark-200'>
        Please consider that after submitting we will send an email to the
        candidate requesting personal data and their consent.
      </div>
      <div className='fw-semibold fs-6 ms-4 ps-3 mt-3'>
        <i className='bi bi-info-circle me-2' />
        The price of this check will later be charged by Screenloop.
      </div>
    </>
  );
}

export function BackgroundCheckRequestForm(
  props: {
    disabled: boolean;
    onSubmit: (bgc: BackgroundCheckRequest) => Promise<boolean>;
  } & PropTypes,
) {
  const [validated, setValidation] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>('');
  const [email, setEmail] = useState<string>(props.application.candidate.email);
  const [rolePosition, setRolePosition] = useState<string>(
    props.application?.job?.name || '',
  );
  const [employmentSector, setEmploymentSector] = useState<string>('');
  const [selectedScreenings, setSelectedScreenings] = useState<SelectOption[]>(
    [],
  );
  const [screenings, setScreenings] = useState<Screening[]>([]);
  const [loadingScreenings, setLoadingScreenings] = useState<boolean>(false);
  const [employmentSectorHidden, setEmploymentSectorHidden] =
    useState<boolean>(true);
  const [rolePositionHidden, setRolePositionHidden] = useState<boolean>(true);
  const [employmentSectorsOptions, setEmploymentSectorsOptions] = useState<
    SelectOption[]
  >([]);

  const validForm = () =>
    countryCode?.length > 0 &&
    selectedScreenings?.length > 0 &&
    email?.length > 0 &&
    ((!employmentSectorHidden && employmentSector?.length > 0) ||
      employmentSectorHidden) &&
    ((!rolePositionHidden && rolePosition?.length > 0) || rolePositionHidden);

  const resetForm = () => {
    setCountryCode('');
    setSelectedScreenings([]);
    setEmail(props.application.candidate.email);
    setRolePosition(props.application.job.name);
    setEmploymentSector('');
  };

  const employmentSectors = async () => {
    const employmentSectors =
      await BackgroundCheckService.listEmploymentSectors();

    const options = employmentSectors.map((sector) => ({
      value: sector,
      label: humanize(sector),
    }));

    setEmploymentSectorsOptions(options);
  };

  function setOptionalParamsHidden(
    screenings: Screening[],
    selectedScreenings: SelectOption[],
  ) {
    let employmentSectorHidden = true;
    let rolePositionHidden = true;

    selectedScreenings.map((s) => {
      screenings.map((c) => {
        if (c.id === s.value) {
          if (c.enabled_optional_params?.includes('employment_sector')) {
            employmentSectorHidden = false;
            employmentSectors();
          }
          if (c.enabled_optional_params?.includes('role_or_position_title')) {
            rolePositionHidden = false;
          }
        }
      });
    });
    setEmploymentSectorHidden(employmentSectorHidden);
    setRolePositionHidden(rolePositionHidden);
  }

  const onSubmit = async (ev) => {
    ev.preventDefault();

    setValidation(true);

    if (!validForm()) return;

    const submitted = await props.onSubmit({
      country: countryCode,
      checks: selectedScreenings.map((b) =>
        screenings.find((c) => c.id === b.value),
      ),
      email,
      optionalParams: [
        {
          id: 'employment_sector',
          value: employmentSector,
        },
        {
          id: 'role_or_position_title',
          value: rolePosition,
        },
      ].filter((p) => p.value),
    });

    if (submitted) resetForm();
  };

  const onCountryCodeChanged = async (country: string) => {
    setLoadingScreenings(true);
    setSelectedScreenings([]);
    setCountryCode(country || '');
    if (country) {
      const screenings = await BackgroundCheckService.screenings(country);
      setScreenings(screenings);
    } else {
      setScreenings([]);
    }

    setLoadingScreenings(false);
  };

  const onSelectedScreeningsChanged = async (checks: SelectOption[]) => {
    setOptionalParamsHidden(screenings, checks);
    setSelectedScreenings(checks);
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <CountryFormGroup
        countryCode={countryCode}
        disabled={props.disabled}
        validated={validated}
        onCountryCodeChanged={onCountryCodeChanged}
      />
      <ChecksFormGroup
        screenings={screenings}
        options={screenings.map((c) => ({
          value: c.id,
          label: c.name,
        }))}
        selectedOptions={selectedScreenings}
        disabled={props.disabled || !countryCode}
        isLoading={loadingScreenings}
        validated={validated}
        onSelectedScreeningsChanged={onSelectedScreeningsChanged}
      />
      <EmploymentSectorFormGroup
        options={employmentSectorsOptions}
        employmentSector={employmentSector}
        hidden={employmentSectorHidden}
        validated={validated}
        onEmploymentSectorChanged={setEmploymentSector}
      />
      <RolePositionFormGroup
        rolePosition={rolePosition}
        hidden={rolePositionHidden}
        validated={validated}
        onRolePositionChanged={setRolePosition}
      />
      <EmailFormGroup
        email={email}
        disabled={props.disabled}
        validated={validated}
        onEmailChanged={setEmail}
      />
      <Information />
      <FormActions disabled={props.disabled} onCancel={props.onCancel} />
    </form>
  );
}
