import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { SingleAttachmentInputFormField } from '../../../components/SingleAttachmentInputFormField';
import { useForm } from 'react-hook-form';
import { OrganizationService } from '../../../services/v1/OrganizationService';
import { Alert, AlertObject, AlertType } from '../../../components/Alert';

interface PropTypes {
  companyLogoUrl?: string;
}

interface LogoDto {
  logoContent: string;
  logoFilename: string;
}

export default function CompanyLogoSettings(props: PropTypes) {
  const [alert, setAlert] = useState<AlertObject>(null);
  const [alertActive, setAlertActive] = useState<boolean>(false);
  const [logoUrl, setLogoUrl] = useState<string>(props.companyLogoUrl);

  const { handleSubmit, register, setValue, getValues, watch, resetField } =
    useForm<LogoDto>({
      mode: 'onChange',
    });

  async function onSubmit(logoDto: LogoDto) {
    try {
      const organization = await OrganizationService.createLogo(
        logoDto.logoContent,
      );
      if (organization.organizationInfo?.logoUrl != null) {
        setLogoUrl(organization.organizationInfo.logoUrl);
      }
      resetField('logoFilename');
      resetField('logoContent');

      setAlert({
        type: AlertType.Success,
        message: 'The logo has been successfully uploaded.',
      });
    } catch (e) {
      console.error(e);
      setAlert({
        type: AlertType.Danger,
        message: 'It was not possible to upload the logo.',
      });
    }

    setAlertActive(true);
  }

  const previewImage = watch('logoContent') || logoUrl;

  return (
    <>
      {alertActive && (
        <Alert
          autoClearTimeout={3000}
          type={alert.type}
          onClose={() => setAlertActive(false)}
        >
          {alert.message}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex flex-column gap-4'>
          <div className='d-flex justify-content-between'>
            <h2 className='app-card-title'>Company Logo</h2>
            <Button
              color='primary'
              type='submit'
              disabled={watch('logoContent') == null}
            >
              Save
            </Button>
          </div>
          <div className='w-60 fs-5 fw-normal text-dark-200'>
            Upload your company logo. The image you choose here will be used in
            all whitelabelled emails, surveys, job posts and careers page. This
            must be a JPG or PNG file that will be displayed horizontally.
          </div>
          {previewImage && (
            <img className='w-25' src={watch('logoContent') || logoUrl} />
          )}
          <div className='w-25'>
            <SingleAttachmentInputFormField<LogoDto>
              allowedFormats={['.jpeg', '.jpg', '.png']}
              controllerContentName={'logoContent'}
              controllerFileName={'logoFilename'}
              fieldName={''}
              isRequired={true}
              hideHeader={true}
              hideAllowedFormats={true}
              maxFileSizeMb={2}
              getValues={getValues}
              setValue={setValue}
              formRegister={register}
            />
          </div>
        </div>
      </form>
    </>
  );
}
