import React, { useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';

interface ImageLinkPropTypes {
  tooltipText: string;
  href: string;
  imageSrc: string;
}

export function ImageLink(props: ImageLinkPropTypes) {
  const ref = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div>
      <a target='_blank' rel='noreferrer' href={props.href} ref={ref}>
        <img
          className='d-block rounded-1'
          style={{ height: '16px' }}
          src={props.imageSrc}
        />
      </a>
      <Tooltip
        target={ref}
        placement='bottom'
        isOpen={tooltipOpen}
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        <div className='text-white'>{props.tooltipText}</div>
      </Tooltip>
    </div>
  );
}
