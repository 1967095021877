import classNames from 'classnames';
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface PropTypes {
  isOpen: boolean;
  size: string;
  headerTitle: string;
  bodyChildren: React.ReactNode;
  backdrop?: boolean | 'static';
  bodyClassName?: string;
  className?: string;
  centered?: boolean;
  closeDisabled?: boolean;
  contentClassName?: string;
  external?: React.ReactNode;
  footerChildren?: React.ReactNode;
  footerClassName?: string;
  keyboard?: boolean;
  onClose: () => void;
}

export function CloseableModal(props: PropTypes) {
  return (
    <Modal
      className={classNames('shadow-sm', 'w-auto', props.className)}
      isOpen={props.isOpen}
      toggle={props.onClose}
      size={props.size}
      centered={props.centered}
      contentClassName={classNames('rounded-4', props.contentClassName)}
      external={props.external}
      keyboard={props.keyboard ?? true}
      backdrop={props.backdrop ?? true}
    >
      <ModalHeader
        tag='h4'
        toggle={props.onClose}
        close={
          <button
            type='button'
            onClick={props.onClose}
            className='btn-close me-0'
            aria-label='Close'
            disabled={props.closeDisabled}
          />
        }
        className='bg-gray'
      >
        {props.headerTitle}
      </ModalHeader>
      <ModalBody className={props.bodyClassName}>
        {props.bodyChildren}
      </ModalBody>
      {props.footerChildren && (
        <ModalFooter className={classNames('pt-0', props.footerClassName)}>
          {props.footerChildren}
        </ModalFooter>
      )}
    </Modal>
  );
}
