import { Monologue } from '../entities/v1/interview_intelligence/Monologue';

export function findMonologueByOffset(
  monologues: Monologue[],
  offset: number,
): Monologue {
  return monologues
    .slice()
    .sort((a, b) => b.startOffsetMs - a.startOffsetMs)
    .find((m) => Math.floor(m.startOffsetMs / 1000) * 1000 <= offset);
}
export function findSentenceByOffset(
  monologues: Monologue[],
  offset: number,
): string {
  return findMonologueByOffset(monologues, offset)?.sentence;
}
