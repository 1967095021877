import stringToColor from 'string-to-color';
import { Participant } from '../entities/v1/interview_intelligence/Participant';

const colors = [
  '#7976FF',
  '#EC73BB',
  '#FBC08B',
  '#A7AFB6',
  '#5E718F',
  '#5348FE',
];

export function hexToRgba(hex: string, transparency: number) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgba = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: transparency,
      }
    : null;

  return rgba && `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
}

export function mapInterviewersToColor(ids: string[]) {
  const mappedColors = {};

  ids.forEach((id, idx) => {
    if (idx < colors.length) {
      mappedColors[id] = colors[idx];
    } else {
      mappedColors[id] = stringToColor(id);
    }
  });

  return mappedColors;
}

export const allInterviewersColour = '#7976FF';

export function participantsToColor(participants: Participant[]) {
  const participants_ids = [];

  participants
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .forEach((participant) => {
      participants_ids.push(participant.id);
    });

  return mapInterviewersToColor(participants_ids);
}
