import React from 'react';
import { StyledModal } from '../../../../components/StyledModal';
import img from '../../../../images/mailer-report-image.png';

interface PropTypes {
  onCancel: () => void;
  isOpen: boolean;
}

export function AvailabilityModal(props: PropTypes) {
  return (
    <StyledModal
      title='Your Interview Availability'
      isOpen={props.isOpen}
      onCancel={props.onCancel}
      size={'lg'}
      bodyChildren={
        <div className={'text-center'}>
          <h2>Your calendar was successfully connected!</h2>
          <h3>Do you want to define your interview availability?</h3>
          <img src={img} className={'my-4'} style={{ width: '464px' }} />
          <br></br>
          <a
            className='btn btn-info mb-3'
            href={'availabilities?bypass_empty_state=true'}
          >
            Define availability
          </a>
        </div>
      }
    />
  );
}
