import React, { useRef, useEffect } from 'react';

import { isVisible } from '../../../utils/isVisible';

interface PropTypes {
  selected: boolean;
  children: any;
}

export function MarkItem(props: PropTypes) {
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    if (!ref.current || !props.selected) return;

    const el = ref.current;
    const offsetParent = el?.offsetParent as HTMLElement | null;
    if (
      isVisible(el, offsetParent?.offsetParent as HTMLElement | null, {
        bottom: -400,
      })
    )
      return;

    const { top } = el.getBoundingClientRect();
    const holder = el.parentElement.parentElement.parentElement.parentElement;
    const holderRect = holder.getClientRects()[0];
    if (holderRect !== undefined) {
      holder.scrollBy(0, top - holderRect.top - 50);
    }
  }, [ref, props.selected]);

  return (
    <mark
      ref={ref}
      className={props.selected ? 'bg-warning' : undefined}
      style={{ whiteSpace: 'nowrap' }}
    >
      {props.children}
    </mark>
  );
}
