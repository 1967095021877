import React from 'react';
import { getPercentage } from '../../../utils/getPercentage';

interface PropTypes {
  elements: JSX.Element[];
  maxElements: number;
  startElement: number;
  endElement: number;
  allowHorizontalScroll?: boolean;
}

export function HorizontalCarouselItems(props: PropTypes) {
  const sharedWidth = `${getPercentage(1.0, props.maxElements)}%`;
  const itemStyle = props.allowHorizontalScroll ? {} : { width: sharedWidth };

  return (
    <>
      {props.elements.map((element: JSX.Element, index: number) => (
        <div key={index} style={itemStyle}>
          {element}
        </div>
      ))}
    </>
  );
}
