import { mapTabToQueryParam, parseTab } from './TabFilter';
import { mapPageToQueryParam, parsePage } from '../../FilterStates/PageFilter';
import { SortingColumn } from '../../../utils/sorting';

import {
  QueryParam,
  appendQueryParamToCurrentTab,
  removeQueryParamFromCurrentTab,
} from '../../../utils/url';
import { SelectOption } from '../../../components/Select';
import { mapJobToQueryParam, parseJob } from '../../FilterStates/JobFilter';
import {
  mapJobStageToQueryParam,
  parseJobStage,
} from '../../FilterStates/JobStageFilter';
import { mapNameToQueryParam, parseName } from '../../FilterStates/NameFilter';
import {
  mapSortingColumnToQueryParam,
  mapSortingDirectionToQueryParam,
  parseSortingColumn,
} from '../../FilterStates/SortingColumn';

const AVAILABLE_QUERY_PARAMS = [
  'name',
  'page',
  'tab',
  'job_id',
  'job_name',
  'job_stage_id',
  'job_stage_name',
  'sorting_column',
  'sorting_direction',
];

export type ListCandidatesFilterTab = 'all' | 'active' | 'hired' | 'rejected';

export interface FilterStates {
  page?: number;
  tab?: ListCandidatesFilterTab;
  job?: SelectOption;
  jobStage?: SelectOption;
  name?: string;
  sortingColumn?: SortingColumn;
}

export function parseCurrentFilterState(): FilterStates {
  return {
    page: parsePage(),
    tab: parseTab(),
    job: parseJob(),
    jobStage: parseJobStage(),
    name: parseName(),
    sortingColumn: parseSortingColumn(),
  };
}

export function mapToQueryParams(filterStates: FilterStates): QueryParam[] {
  return [
    mapNameToQueryParam(filterStates.name),
    mapPageToQueryParam(filterStates.page),
    mapTabToQueryParam(filterStates.tab),
    ...mapJobToQueryParam(filterStates.job),
    ...mapJobStageToQueryParam(filterStates.jobStage),
    mapSortingColumnToQueryParam(filterStates.sortingColumn.columnName),
    mapSortingDirectionToQueryParam(filterStates.sortingColumn.direction),
  ].filter((qp) => qp != null);
}

export function updateQueryParams(filterStates: FilterStates) {
  const mappedQueryParams = mapToQueryParams(filterStates);

  mapToQueryParams(filterStates)
    .filter((qp) => qp.value)
    .forEach((qp) => {
      appendQueryParamToCurrentTab(qp.key, qp.value as string);
    });

  AVAILABLE_QUERY_PARAMS.forEach((qp) => {
    if (!mappedQueryParams.find((p) => p.key === qp)?.value) {
      removeQueryParamFromCurrentTab(qp);
    }
  });
}
