import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface PropTypes {
  value: number;
  className?: string;
}

export function Badge(props: PropTypes) {
  return (
    <span
      className={classNames(
        'start-100 fw-normal position-absolute translate-middle bg-danger-500 text-white rounded-circle',
        styles['badge'],
        props.className,
      )}
    >
      <div className={styles['badge-value']}>{props.value}</div>
    </span>
  );
}
