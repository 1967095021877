import React from 'react';
import {
  ChannelType,
  ChannelTypes,
} from '../../../../../entities/applicant_tracking/Source';
import { LabelledSelect } from '../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../components/Select';
import classNames from 'classnames';
import styles from '../../styles.module.scss';
import { humanize } from '../../../../../utils/humanize';

interface PropTypes {
  channelType: ChannelType;
  disabled?: boolean;
  setChannelType?: (channelType: ChannelType) => void;
}

function channelTypeToSelectOption(channelType: ChannelType): SelectOption {
  return {
    value: channelType,
    label: humanize(channelType),
  } as SelectOption;
}

export function ChannelTypeInput(props: PropTypes) {
  return (
    <div className={classNames(styles['sources-container'], 'mt-4')}>
      <LabelledSelect
        mandatory
        disabled={props.disabled}
        options={ChannelTypes.filter(
          (value) => value !== 'employee_referrals',
        ).map(channelTypeToSelectOption)}
        selected={
          props.channelType && channelTypeToSelectOption(props.channelType)
        }
        label='Channel'
        placeholder='Select channel'
        labelClassName='fs-5 fw-semibold'
        onChange={(option) =>
          props.setChannelType?.(option.value as ChannelType)
        }
      />
    </div>
  );
}
