import { ApiService } from '../ApiService';

export class UserIntegrationService {
  public static destroy(id: number): Promise<void> {
    return ApiService.delete<void>(`/api/user_integrations/${id}`, {});
  }

  public static disconnect(id: number): Promise<void> {
    return ApiService.put<void>(`/api/user_integrations/${id}/disconnect`, {});
  }
}
