import React from 'react';
import { QueryClientProvider, useMutation } from 'react-query';
import { queryClient } from '../../../../config/queryClient';
import { HiringMemberSettings } from '../HiringMemberSettings';
import { RecordingsSettingsService } from '../../../../services/v1/RecordingsSettingsService';
import { SettingsService } from '../../../../services/v1/interview_intelligence/SettingsService';
import { Settings } from '../../../../entities/v1/interview_intelligence/Settings';
import { HiringMemberSettingsType } from '../../../../entities/HiringMemberSettingsType';

export interface PropTypes {
  recordingSettingPath: string;
  title: string;
  activateText: string;
  hiringMemberSettingsType: HiringMemberSettingsType;
  organizationAutoRecordingEnabled: boolean;
  organizationOptoutEnabled: boolean;
  settingsQueryKey: string;
}

export default function OptoutSettings(props: PropTypes) {
  return (
    <QueryClientProvider client={queryClient}>
      <OptoutSettingsPage {...props} />
    </QueryClientProvider>
  );
}

export function OptoutSettingsPage(props: PropTypes) {
  function queryFn() {
    return RecordingsSettingsService.overallStatus({
      type: 'recording_consent_optout',
    });
  }

  const mutation = useMutation<void, void, Settings>({
    mutationFn: (data) => SettingsService.update(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [props.settingsQueryKey],
      });
    },
  });

  return (
    <HiringMemberSettings
      organizationSettingEnabled={props.organizationOptoutEnabled}
      queryFn={queryFn}
      mutation={mutation}
      {...props}
    />
  );
}
