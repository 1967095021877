import React from 'react';
import { Col, Row } from 'reactstrap';
import { ProgressBar } from '../../../../../../components/survey_engine/ProgressBar';
import { SurveyQuestion } from '../../../../../../entities/survey_engine/SurveyQuestion';
import { getQuestions } from '../../../../../../utils/training/getQuestions';
import { getTotalAnsweredQuestions } from '../../../../../../utils/survey_engine/getTotalAnsweredQuestions';
import { SubmitButton } from './SubmitButton';
import { PreviousButton } from './PreviousButton';
import { CloseButton } from './CloseButton';
import styles from './styles.module.scss';
import { Session } from '../../../../../../entities/training/Session';
import { Survey } from '../../../../../../entities/survey_engine/Survey';
import { SurveyQuestionAnswer } from '../../../../../../entities/survey_engine/SurveyQuestionAnswer';

interface PropTypes {
  session: Session;
  survey: Survey;
  currentSectionIndex: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

function calculateProgress(
  answers: SurveyQuestionAnswer[],
  questions: SurveyQuestion[],
) {
  const totalAnswered = getTotalAnsweredQuestions(answers, questions);

  return (totalAnswered / questions.length) * 100;
}

export function EvaluationScreenHeader(props: PropTypes) {
  const surveyQuestions: SurveyQuestion[] = getQuestions(props.survey);

  return (
    <>
      <Row className='mb-5'>
        <Col>
          <h1>{props.session.name}</h1>
        </Col>
        <Col className='text-end'>
          <CloseButton sessionId={props.session.id} />
          <PreviousButton
            currentSectionIndex={props.currentSectionIndex}
            onPreviousClick={props.onPreviousClick}
          />
          <SubmitButton
            survey={props.survey}
            surveyQuestions={surveyQuestions}
            currentSectionIndex={props.currentSectionIndex}
            onClick={props.onNextClick}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ProgressBar
            progress={calculateProgress(props.survey.answers, surveyQuestions)}
            progressClassName={styles.progressBar}
            progressWrapperClassName={styles.progressBarWrapper}
          />
        </Col>
      </Row>
    </>
  );
}
