import React from 'react';
import { UpsertLocationDto } from '../UpsertLocationDto';
import { Col, Row } from 'reactstrap';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';
import { SetupPageHeader } from '../../../../../components/SetupPageHeader';
import { InputFormField } from '../../../../../components/InputFormField';
import classNames from 'classnames';
import {
  LocationOption,
  LocationSelect,
} from '../../../../../components/LocationSelect';
import { FormFieldHeader } from '../../../../../components/FormFieldHeader';
import { Location } from '../../../../../entities/applicant_tracking/Location';
import { LocationEntity } from '../../../../../services/GeoLocationService';

interface PropTypes {
  formControl: Control<UpsertLocationDto, any>;
  location: Location;
  errors: FieldErrors;
  isValid: boolean;
  edit: boolean;
  onCancel: () => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<UpsertLocationDto>;
}

function CardHeader(props: {
  edit: boolean;
  isValid: boolean;
  onCancel: () => void;
}) {
  return (
    <SetupPageHeader
      title={props.edit ? 'Edit Location' : 'New Location'}
      firstItems={[]}
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: props.onCancel,
        },
        {
          title: 'Save',
          type: 'Save',
          disabled: !props.isValid,
        },
      ]}
    />
  );
}

function LineWrapper(props: { children: JSX.Element }) {
  return (
    <Row className='mt-4 mt-4h'>
      <Col className='col-sm-8 col-md-6 col-lg-4 msb-sm-0'>
        {props.children}
      </Col>
    </Row>
  );
}

function getSelectedOption(selectedLocation?: LocationEntity): LocationOption {
  if (selectedLocation == null) {
    return null;
  }

  return {
    value: selectedLocation.name,
    label: selectedLocation.name,
    selected: true,
    address: selectedLocation.address,
    city: selectedLocation.city,
    state: selectedLocation.state,
    country: selectedLocation.country,
  };
}

export function Body(props: PropTypes) {
  return (
    <form onSubmit={props.onSubmit}>
      <CardHeader
        edit={props.edit}
        isValid={props.isValid}
        onCancel={props.onCancel}
      />
      <LineWrapper>
        <InputFormField
          registerReturn={props.register('name', { required: true })}
          type={'text'}
          fieldName='Location Name'
          classNames={classNames(
            props.errors.name && 'is-invalid',
            'fs-5 fw-normal',
          )}
          headerClassNames='fw-semibold'
          isRequired={true}
          maxLength={32}
          showMaxLengthCounter={true}
        />
      </LineWrapper>
      <LineWrapper>
        <>
          <FormFieldHeader
            fieldName='Location'
            isRequired={true}
            classNames='fw-semibold'
          />
          <Controller
            control={props.formControl}
            name={'location'}
            rules={{ required: true }}
            render={({ field }) => (
              <LocationSelect
                selected={getSelectedOption(field.value)}
                onChange={(option: LocationOption) => {
                  if (option == null) {
                    field.onChange(null);
                    return;
                  }

                  field.onChange({
                    name: option.label,
                    address: option.address,
                    city: option.city,
                    state: option.state,
                    country: option.country,
                  });
                }}
                className='fs-5'
                isClearable={true}
              />
            )}
          />
        </>
      </LineWrapper>
    </form>
  );
}
