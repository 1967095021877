import { useEffect, useState } from 'react';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import React from 'react';
import { Card } from '../../../../components/Card';
import { SectionData } from '../../../survey_engine/SurveyTemplates/Section';
import { NameInput } from '../../../survey_engine/SurveyTemplates/NameInput';
import { SectionList } from '../../../survey_engine/SurveyTemplates/SectionList';
import { IconSpan } from '../../../../components/IconSpan';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';
import {
  appendRedirectUri,
  getRedirectUriQueryParam,
} from '../../../../utils/url';
import { BASE_FIELD_CONFIGS, fetchTemplate } from '../data';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ConsentFieldAlert } from '../ConsentFieldAlert';

const REDIRECT_URI =
  getRedirectUriQueryParam() ??
  '/organization_settings/application_form_templates';

interface PropTypes {
  id: number;
}

export default function ShowApplicationFormTemplatePage(props: PropTypes) {
  const [applicationFormTemplate, setApplicationFormTemplate] = useState({
    name: '',
    default: true,
    sections: [],
    candidateConsentEnabled: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function load() {
      const template = await fetchTemplate(props.id.toString());
      setApplicationFormTemplate(template);
      setIsLoading(false);
    }

    load();
  }, [props.id]);

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <UI
        id={props.id}
        name={applicationFormTemplate.name}
        default={applicationFormTemplate.default}
        sections={applicationFormTemplate.sections}
        candidateConsentEnabled={
          applicationFormTemplate.candidateConsentEnabled
        }
      />
    </>
  );
}

function UI(props: {
  id: number;
  name: string;
  default: boolean;
  sections: SectionData[];
  candidateConsentEnabled: boolean;
}) {
  return (
    <div className='container'>
      <PageHeader title={props.name} backUrl={REDIRECT_URI} />
      <Card>
        <SetupPageHeader
          title='Application Form'
          firstItems={[]}
          intermediateItems={[
            {
              title: <IconSpan spanText='Edit' icon={{ name: 'bi-pencil' }} />,
              type: 'Navigate',
              action: () =>
                (window.location.href = appendRedirectUri(
                  `/organization_settings/application_form_templates/${props.id}/edit`,
                )),
              disabled: props.default,
            },
          ]}
        />
        <div className='border-bottom'>
          <div className='d-flex flex-column w-50 fs-5 pt-2 pb-4 gap-4'>
            <NameInput name={props.name} readOnly={true} />
          </div>
        </div>
        <SectionList
          title='Sections'
          subtitle='Define and manage your application form sections'
          fieldConfigs={BASE_FIELD_CONFIGS}
          singleTypeSections={false}
          sections={props.sections}
          columns={[
            { fieldName: 'type', className: 'col-3' },
            { fieldName: 'text', className: 'col' },
            { fieldName: 'mandatory', className: 'col-1' },
          ]}
          enableDescription={true}
          readOnly={true}
        />
        {props.candidateConsentEnabled && <ConsentFieldAlert />}
      </Card>
    </div>
  );
}
