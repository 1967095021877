import React from 'react';
import classNames from 'classnames';
import { Avatar, PropTypes as AvatarPropTypes } from '../Avatar';

interface PropTypes extends AvatarPropTypes {
  label: string;
  labelClassName?: string;
}

export function LabelledAvatar(props: PropTypes) {
  return (
    <div
      className={classNames('d-flex align-items-center', props.labelClassName)}
    >
      <Avatar {...props} />
      <span className='fs-5 fw-semibold text-primary text-truncate'>
        {props.label}
      </span>
    </div>
  );
}
