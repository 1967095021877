import { ActionPoint } from '../../entities/ActionPoint';
import { ApiService } from '../ApiService';

export class ActionPointService {
  public static complete(id: number): Promise<ActionPoint> {
    return ApiService.put<ActionPoint>(
      `/api/interview_intelligence/interview_action_points/${id}/complete`,
    );
  }
}
