import React from 'react';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';
import { ColumnConfig } from '../Field';

interface PropTypes {
  readOnly?: boolean;
  column: ColumnConfig;
}

export function TextHeader(props: PropTypes) {
  return (
    <div className={props.column.className}>
      <div className='d-flex'>
        <FormFieldHeader
          classNames='fw-semibold'
          fieldName={props.column.label ?? 'Field Label'}
          isRequired={!props.readOnly}
        />
        {!props.readOnly && (
          <div className='ms-auto text-muted fs-5'>
            {props.column.helperText ?? 'Maximum 128 characters'}
          </div>
        )}
      </div>
    </div>
  );
}
