import React, { useEffect, useState } from 'react';
import MetricCard, {
  Body,
  Head,
  Title,
} from '../../../../../components/MetricCard';
import { NpsExplainabilityBubble } from './NpsExplainabilityBubble';
import { Col, Row } from 'reactstrap';
import { LabelledMultiSelect } from '../../../../../components/LabelledMultiSelect';
import {
  FilterStates,
  SelectOption,
  OnChangeFilterStatesCallback,
} from '../../../../../components/candidate_experience/Filters/filterState';
import { NpsExplainability } from '../../../../../entities/candidate_experience/NpsExplainability';

interface PropTypes {
  title: string;
  tooltipText: string;
  filterStates: FilterStates;
  onApplyFilter: OnChangeFilterStatesCallback;
  entryState: NpsExplainability;
  onUpdateEntryState: () => void;
  standardNpsCalculation: boolean;
}

export function NpsExplainabilityBubbleChart(props: PropTypes) {
  const [state, setState] = useState({ ...props.filterStates });

  useEffect(() => {
    props.onUpdateEntryState();
    syncFilter();
  }, [props.filterStates.dateRange, props.filterStates.npsAttributes.selected]);

  useEffect(() => {
    props.onApplyFilter(state, false);
  }, [state.npsAttributes.selected]);

  function syncFilter() {
    setState({
      ...state,
      npsAttributes: {
        ...state.npsAttributes,
        selected: props.filterStates.npsAttributes.selected,
      },
      dateRange: props.filterStates.dateRange,
    });
  }

  function handleFilterUpdate(option: SelectOption[]) {
    setState({
      ...state,
      npsAttributes: { ...state.npsAttributes, selected: option },
    });
  }

  if (props.entryState === null) return null;
  return (
    <MetricCard className='mt-3'>
      <Head>
        <Row>
          <Col>
            <Title value={props.title} tooltip={props.tooltipText} />
          </Col>
          <Col md='4' lg='2'>
            <LabelledMultiSelect
              options={state.npsAttributes.all}
              selected={state.npsAttributes.selected}
              label={null}
              placeholder={'All Attributes'}
              singleSelectedText={'1 selected attribute'}
              manySelectedText={'%d selected attributes'}
              onChange={handleFilterUpdate}
              disableSearch={true}
            />
          </Col>
        </Row>
      </Head>
      <Body className='d-flex'>
        <NpsExplainabilityBubble
          topEntries={props.entryState.top_entries}
          bottomEntries={props.entryState.bottom_entries}
          standardNpsCalculation={props.standardNpsCalculation}
        />
      </Body>
    </MetricCard>
  );
}
