import React from 'react';
import { SelectOption } from '../../../components/Select';
import { SinglePaginatedSelect } from '../../../components/Select/SinglePaginatedSelect';
import { InterviewerService } from '../../../services/v1/InterviewerService';

interface PropTypes {
  selected: SelectOption;
  disabled: boolean;
  onChange: (opt: SelectOption) => void;
}

interface AdditionalType {
  page: number;
}

const defaultAdditional: AdditionalType = {
  page: 1,
};

export function InterviewerSelect(props: PropTypes) {
  const loadOptions = async (
    search: string,
    _loadedOptions: unknown,
    { page }: AdditionalType,
  ) => {
    const result = await InterviewerService.list({
      page: page,
      name: search,
      sort_column: 'name',
      sort_direction: 'ASC',
    });

    return {
      options: result.hiringMembers.map(
        (hm) =>
          ({
            label: hm.name,
            value: hm.id.toString(),
          } as SelectOption),
      ),
      hasMore: !!result.nextPage,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <SinglePaginatedSelect
      additional={defaultAdditional}
      isClearable={true}
      value={props.selected}
      loadOptions={loadOptions}
      onChange={props.onChange}
      debounceTimeout={300}
      placeholder='Select Interviewer'
      closeMenuOnSelect={true}
    />
  );
}
