import { ApiService } from '../ApiService';
import { CreateIntegrationDto } from './CreateIntegrationDto';
import { DeleteIntegrationDto } from './DeleteIntegrationDto';
import { Integration } from '../../entities/Integration';
import snakecaseKeys from 'snakecase-keys';

export class IntegrationService {
  public static create(props: CreateIntegrationDto): Promise<Integration> {
    return ApiService.post<Integration>(
      `/organization_settings/integrations/${props.provider}`,
      snakecaseKeys(props),
    );
  }

  public static delete(props: DeleteIntegrationDto): Promise<void> {
    return ApiService.delete<void>(
      `/organization_settings/integrations/${props.provider}/${props.id}`,
    );
  }

  public static enable(props: CreateIntegrationDto): Promise<Integration> {
    return IntegrationService.create(props);
  }

  public static disable(props: DeleteIntegrationDto): Promise<void> {
    return IntegrationService.delete(props);
  }
}
