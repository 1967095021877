import React, { useState } from 'react';
import { InterviewAssistantTab } from '../../../InterviewPage/InterviewAssistantTab';
import { Interview } from '../../../../entities/Interview';

interface PropTypes {
  interview: Interview;
}

export function ScorecardAssistant(props: PropTypes) {
  const aiInitResponses = [
    {
      aiAssistant: true,
      text: `Hey, I'm Screenloop's AI Assistant.`,
      auto: true,
    },
    {
      aiAssistant: true,
      text: 'I can help you find information and answer any questions about this interview.',
      auto: true,
    },
    { aiAssistant: true, text: 'Where shall we get started?', auto: true },
  ];

  const [responses, setResponses] = useState(aiInitResponses);
  const [hasServerResponses, setHasServerResponses] = useState(false);

  return (
    <InterviewAssistantTab
      interview={props.interview}
      responses={responses}
      onResponses={setResponses}
      hasServerResponses={hasServerResponses}
      onHasServerResponses={setHasServerResponses}
    />
  );
}
