import React, { useState } from 'react';
import classNames from 'classnames';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { editorConfig } from '../../config/editorConfig';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import styles from './styles.module.scss';
import { Placeholder } from '../Placeholder';
import { PluginLoader } from './PluginLoader';

interface PropTypes {
  disabled?: boolean;
  placeholder?: string;
  externalPlugins?: JSX.Element[];
  defaultEditorState?: string;
  onChangeJson?: (value: string) => void;
  onChangeText?: (value: string) => void;
}

function InputTextPlaceholder(props: { placeholder: string }) {
  return (
    <Placeholder
      className={classNames(styles['editor-placeholder'])}
      placeholder={props.placeholder}
    />
  );
}

export function InputRichTextComposer(props: PropTypes) {
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onRef = (floatingAnchorElemParam: HTMLDivElement) => {
    if (floatingAnchorElemParam !== null) {
      setFloatingAnchorElem(floatingAnchorElemParam);
    }
  };

  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
        editable: !props.disabled,
        editorState: props.defaultEditorState,
      }}
    >
      <div className={classNames(styles['editor-container'])}>
        <RichTextPlugin
          contentEditable={
            <div className='editor-scroller'>
              <div className='editor' ref={onRef}>
                <ContentEditable
                  disabled={props.disabled}
                  className={classNames(
                    'form-control overflow-hidden text-nowrap',
                    styles['editor-container'],
                    props.disabled && styles['editor-disabled'],
                  )}
                />
              </div>
            </div>
          }
          placeholder={<InputTextPlaceholder placeholder={props.placeholder} />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <PluginLoader
          externalPlugins={props.externalPlugins}
          disabled={props.disabled}
          onChangeJson={props.onChangeJson}
          onChangeText={props.onChangeText}
          floatingAnchorElem={floatingAnchorElem}
          isLinkEditMode={isLinkEditMode}
          setIsLinkEditMode={setIsLinkEditMode}
        />
      </div>
    </LexicalComposer>
  );
}
