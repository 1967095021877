import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { TableHeader as TableHeaderComponent } from '../../../../../../../../components/TableHeader';

interface PropTypes {
  columns: { key: string; label: string; tooltipText?: string; type: string }[];
  sortColumn: string;
  sortDirection: number;
  onSortUpdate: (sort: string) => void;
}

const sortIcons = ['chevron-down', 'chevron-expand', 'chevron-up'];

function sortIcon(column: string, sortColumn: string, sortDirection: number) {
  const direction = sortColumn === column ? sortDirection : 0;

  return sortIcons[direction + 1];
}

function TableHeaderColumnName(
  props: PropTypes & {
    tag: string;
    label: string;
    idx: number;
    type: string;
    tooltipText: string;
    isSortable: boolean;
  },
) {
  return (
    <a
      className={classNames(
        `d-flex ${
          props.type === 'string'
            ? 'justify-content-start'
            : 'justify-content-end'
        }`,
      )}
      href='#'
      onClick={() => props.isSortable && props.onSortUpdate(props.tag)}
    >
      <TableHeaderComponent
        label={props.label}
        tooltipText={props.tooltipText}
      />
      {props.isSortable && (
        <i
          className={`bi bi-${sortIcon(
            props.tag,
            props.sortColumn,
            props.sortDirection,
          )} ${
            props.tag === 'name' || props.tag === 'role' ? 'ms-5' : ' ms-2'
          } `}
        ></i>
      )}
    </a>
  );
}

export function TableHeader(props: PropTypes) {
  return (
    <thead className={classNames('text-nowrap')}>
      <tr className={classNames('fs-5')}>
        {props.columns.map(({ key, label, tooltipText, type }, idx) => {
          return (
            <th
              className={classNames(
                styles['table-head-border-bottom'],
                'pb-3 fw-semibold',
                key == 'name' && 'ps-4 col-2',
                key == 'sentiment_analysis' && 'ps-5 col-2',
                key != 'name' && key != 'sentiment_analysis' && 'col-1',
              )}
              key={idx}
            >
              <TableHeaderColumnName
                {...props}
                tag={key}
                label={label}
                idx={idx}
                tooltipText={tooltipText}
                type={type}
                isSortable={key !== 'sentiment_analysis'}
              />
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
