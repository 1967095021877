import { Survey } from '../../entities/v1/survey_engine/Survey';
import { FieldType, Question } from '../../entities/v1/survey_engine/Question';
import { Section } from '../../entities/v1/survey_engine/Section';

export function updateSurveyAnswers(
  survey: Survey,
  attribute: Question,
  updateExitingAnswers: boolean,
  value?: string,
) {
  let newSurvey: Survey;
  const normalizedValue = value || null; // This will avoid sending empty string as a value.
  const answers = survey.answers ?? [];

  const answerIdx = answers.findIndex(
    (answer) => answer.questionId === attribute.id,
  );

  if (answerIdx != -1) {
    const answer = answers[answerIdx];

    if (!updateExitingAnswers && answer.value) {
      return survey;
    }

    answers[answerIdx] = {
      questionId: answer.questionId,
      surveyId: answer.surveyId,
      value: normalizedValue,
    };
    newSurvey = {
      ...survey,
      answers: answers,
    };
  } else {
    newSurvey = {
      ...survey,
      answers: [
        ...answers,
        {
          questionId: attribute.id,
          surveyId: survey.id,
          value: normalizedValue,
        },
      ],
    };
  }

  return newSurvey;
}

export function findSectionType(section: Section): FieldType {
  return section.questions?.[0].fieldType;
}
