import React, { useCallback, useState } from 'react';
import { IconSpan } from '../../../../../components/IconSpan';
import { ArchiveJobConfirmationModal } from '../ArchiveJobConfirmationModal';
import { DropdownAction } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';

interface PropTypes {
  jobId: number;
  jobName: string;
  jobArchived: boolean;
  disabled?: boolean;
}

export default function ArchiveJobButton(props: PropTypes) {
  if (props.disabled) {
    return null;
  }

  const archiveAction = props.jobArchived ? 'Unarchive' : 'Archive';

  const [isArchiveJobModalOpen, setIsArchiveJobModalOpen] = useState(false);

  const onArchivingJob = useCallback(async () => {
    setIsArchiveJobModalOpen(true);
  }, []);

  return (
    <>
      <ArchiveJobConfirmationModal
        jobId={props.jobId}
        jobName={props.jobName}
        archiveAction={archiveAction}
        isModalOpen={isArchiveJobModalOpen}
        redirectUri={window.location.href}
        setIsModalOpen={setIsArchiveJobModalOpen}
      />
      <DropdownAction
        action={onArchivingJob}
        buttonChild={
          <IconSpan
            className='ms-2'
            spanText={archiveAction}
            icon={{ name: 'bi-archive' }}
          />
        }
      />
    </>
  );
}
