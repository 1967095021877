import React, { useEffect, useState } from 'react';
import { SourceService } from '../../../../services/v1/applicant_tracking/SourceService';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Card } from '../../../../components/Card';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { appendRedirectUri } from '../../../../utils/url';
import { ChannelTypeInput } from '../components/ChannelTypeInput';
import { FormHeader } from '../components/FormHeader';
import { IconSpan } from '../../../../components/IconSpan';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Source } from '../../../../entities/applicant_tracking/Source';
import { SourcesInput } from '../components/SourcesInput';
import { REDIRECT_URI, createSourceData } from '../util';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';

interface PropTypes {
  id: number;
  default: boolean;
}

export default function ShowSourcePage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [source, setSource] = useState<Source>(null);

  useEffect(() => {
    async function load() {
      const source = await SourceService.show(props.id);
      setSource(source);
      setIsLoading(false);
    }

    load();
  }, [props.id]);

  if (isLoading) {
    return <LoadingSpinner showBackdrop />;
  }

  return (
    <div className='container'>
      <PageHeader title={source.name} backUrl={REDIRECT_URI} />
      <Card className={classNames(styles['card'])}>
        <SetupPageHeader
          title='Sources'
          firstItems={[]}
          intermediateItems={[
            {
              title: <IconSpan spanText='Edit' icon={{ name: 'bi-pencil' }} />,
              type: 'Navigate',
              action: () =>
                (window.location.href = appendRedirectUri(
                  `/organization_settings/sources/${props.id}/edit`,
                )),
              disabled: props.default,
            },
          ]}
        />
        <ChannelTypeInput disabled channelType={source.channelType} />
        <FormHeader title='Source' />
        <SourcesInput
          singleInput
          readonly
          duplicates={[]}
          sources={[createSourceData(source.name, source.enabledOnCareers)]}
        />
      </Card>
    </div>
  );
}
