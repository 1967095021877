import { Interview } from '../../../../../entities/Interview';
import { EmptyTab } from '../../../../../components/EmptyTab';
import UndrawLost from '../../../../../images/undraw_empty_state.svg';
import React from 'react';
import { ScorecardAttributeSummary } from '../../../../../entities/ScorecardAttributeSummary';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CollapsableText } from '../../../../../components/CollapsableText';

interface PropTypes {
  interview: Interview;
}

function AttributeSummary(props: { attribute: ScorecardAttributeSummary }) {
  if (!props.attribute.summary) return null;

  return (
    <>
      <div>
        <i className='bi bi-stars text-dark-500 me-2 fs-4'></i>
        <span className='fw-semibold text-primary fs-5'>
          {props.attribute.title}
        </span>
      </div>
      <div
        className={classNames('bg-gray', 'fs-5', 'py-2', 'px-3', 'rounded-2')}
      >
        <CollapsableText
          collapseWords={50}
          maxWords={350}
          expandWordsStep={props.attribute.summary.length}
          text={props.attribute.summary}
          expandedByDefault={true}
          buttonSize='lg'
        />
      </div>
    </>
  );
}

export function AttributeNotes(props: PropTypes) {
  if (
    props.interview.scorecard_attribute_summaries == null ||
    props.interview.scorecard_attribute_summaries.filter((s) => s.summary)
      .length === 0
  ) {
    return (
      <EmptyTab
        title='No attribute summaries available yet'
        text='We are processing attribute summaries. Please check back later.'
        imageSrc={UndrawLost}
        size='medium'
      />
    );
  }
  return (
    <div
      className={classNames(
        'd-flex',
        'flex-column',
        'gap-3',
        'mt-2',
        styles.expandableNotes,
      )}
    >
      <div className='fw-bold fs-4'>Attributes</div>
      {props.interview.scorecard_attribute_summaries.map(
        (attribute: ScorecardAttributeSummary, index: number) => (
          <AttributeSummary key={index} attribute={attribute} />
        ),
      )}
    </div>
  );
}
