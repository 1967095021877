import { Moment } from 'moment';
import React, { useState } from 'react';
import { SelectOption } from '../../../../../../components/Select';
import { DateProps } from '../CardBody';
import { CardHeaderRow } from '../CardHeaderRow';
import { ApplicationUpdatesFormRow } from './ApplicationUpdatesFormRow';
import { MaxRemindersAndDelayDaysRow } from './MaxRemindersAndDelayDaysRow';

interface PropType {
  selectedMaxReminders: SelectOption;
  setSelectedMaxReminders: (selectedMaxReminder: SelectOption) => void;
  selectedDelayDays: SelectOption;
  setSelectedDelayDays: (selectedDelayDay: SelectOption) => void;
  setStartDate: (day: Moment) => void;
  edit: boolean;
  processingStartsAt: Moment;
  dateProps: DateProps;
}

export function CardSettingRow(props: PropType) {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  return (
    <div className='row py-3 justify-content-between align-items-center'>
      <div className='col'>
        <CardHeaderRow
          title={'Settings'}
          subtitle={'Define and configure additional settings.'}
        />
        <div className='row pt-4'>
          <MaxRemindersAndDelayDaysRow
            selectedMaxReminders={props.selectedMaxReminders}
            setSelectedMaxReminders={props.setSelectedMaxReminders}
            selectedDelayDays={props.selectedDelayDays}
            setSelectedDelayDays={props.setSelectedDelayDays}
          />
          <ApplicationUpdatesFormRow
            startDate={props.dateProps.startDate}
            lastSevenDays={props.dateProps.lastSevenDays}
            today={props.dateProps.today}
            setStartDate={props.setStartDate}
            setShowCalendar={setShowCalendar}
            showCalendar={showCalendar}
            edit={props.edit}
            processingStartsAt={props.processingStartsAt}
          />
        </div>
      </div>
    </div>
  );
}
