import classNames from 'classnames';
import React from 'react';

export function CardEmptyState(props: {
  title?: string;
  label?: string;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        'h-100 pb-4 d-flex flex-column align-items-center justify-content-center',
        props.className,
      )}
    >
      <h2>{props.title || 'No data is available yet'}</h2>
      <p>
        {props.label ||
          'We are processing your interview. Please check back later for more insights.'}
      </p>
    </div>
  );
}
