import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { Row } from 'reactstrap';
import { CandidateDto } from '../../../../../services/applicant_tracking/CandidateService/CandidateDto';
import classNames from 'classnames';
import { SelectFormHook } from '../../../../../components/SelectFormHook';
import styles from './styles.module.scss';
import { SelectOption } from '../../../../../components/Select';
import { UserService } from '../../../../../services/v1/UserService';
import { UserList } from '../../../../../entities/v1/User';

interface PropTypes {
  formControl: Control<CandidateDto, any>;
  errors: FieldErrors;
  formRegister: UseFormRegister<CandidateDto>;
  formResetField?: (controllerName: string, options: any) => void;
  watch: UseFormWatch<CandidateDto>;
  getValues: () => CandidateDto;
}

export function ReferralInputData(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userOptions, setUserOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    UserService.list({
      ignorePagination: true,
      includeAllActiveUsers: true,
    })
      .then((response: UserList) => {
        setUserOptions(
          response.users.map((user) => ({
            value: user.id.toString(),
            label: user.name,
          })),
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const referralFormError = props.errors.referrerEmail?.message;
  const validateEmailAndReferral = () => {
    const formData = props.getValues();
    if (formData['email'] == null) return true;
    if (formData['email'] === formData['referrerEmail']) {
      return 'The referral email address can not be the same as that of the person who gets the credit.';
    }
    return true;
  };

  const referrerEmail = props.watch('referrerEmail');

  return (
    <Row>
      <div className={styles.column}>
        <SelectFormHook
          placeholder={'Select referrer'}
          options={userOptions}
          disabled={!!props.watch('referrerEmail') || isLoading}
          formHookContext={{
            controllerName: 'referrerUserId',
            formControl: props.formControl,
            required: referrerEmail == null || referrerEmail == '',
          }}
          isLoading={isLoading}
          formResetField={props.formResetField}
        />
      </div>
      <div
        className={classNames(
          'fs-5 text-dark-200 fw-normal px-0 align-self-start pt-2',
          styles['or-column'],
        )}
      >
        OR
      </div>
      <div className={styles.column}>
        <input
          className={classNames('form-control', 'fs-5')}
          disabled={!!props.watch('referrerUserId')}
          type='email'
          placeholder='Insert referrer email'
          {...props.formRegister('referrerEmail', {
            required: props.watch('referrerUserId') == null,
            validate: validateEmailAndReferral,
          })}
        />
        {referralFormError && (
          <div className='mt-2 text-danger fs-6'>{referralFormError}</div>
        )}
      </div>
    </Row>
  );
}
