import { ListCandidatesFilterTab } from '..';
import { getQueryParam, QueryParam } from '../../../../../utils/url';

export const TAB_QUERY_PARAM = 'tab';

function defaultTab(): ListCandidatesFilterTab {
  return 'active';
}

export function parseTab(): ListCandidatesFilterTab {
  const value = getQueryParam(TAB_QUERY_PARAM);

  const parsedTab: ListCandidatesFilterTab = value as ListCandidatesFilterTab;

  if (parsedTab != null) return parsedTab;

  return defaultTab();
}

export function mapTabToQueryParam(value: ListCandidatesFilterTab): QueryParam {
  return {
    key: TAB_QUERY_PARAM,
    value: value,
  };
}
