import { SurveyQuestion } from '../../../../reference_check/entities/SurveyQuestion';
import { SurveyTemplate } from '../../../../reference_check/entities/SurveyTemplate';
import { getQuestionsFromSurveyTemplate } from './getQuestionsFromSurveyTemplate';

export function findSurveyQuestion(
  surveyTemplate: SurveyTemplate,
  questionId: number,
): SurveyQuestion {
  const questions = getQuestionsFromSurveyTemplate(surveyTemplate);
  return questions.find((v) => v.id === questionId);
}
