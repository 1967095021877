import React, { RefObject, useEffect, useState } from 'react';
import { PaginatedButtonIcon } from '../PaginatedButtonIcon';
import styles from '../styles.module.scss';
import classNames from 'classnames';
import { getScrollStates } from '../../../utils/scroll';

const DEFAULT_SCROLL_WIDTH = 250;

interface PropTypes {
  maxSize: number;
  startElement: number;
  endElement: number;
  scrollContainerRef: RefObject<HTMLDivElement>;
  buttonColor: string;
  allowHorizontalScroll?: boolean;
  scrollWidth?: number;
  setStartElement: (a: number) => void;
  setEndElement: (a: number) => void;
}

function ScrollButtons(props: PropTypes) {
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement>(null);
  const [allowLeftScroll, setAllowLeftScroll] = useState<boolean>(false);
  const [allowRightScroll, setAllowRightScroll] = useState<boolean>(false);

  const setScrollStates = (currentScrollContainer: HTMLElement) => {
    const { allowLeftScroll, allowRightScroll } = getScrollStates(
      currentScrollContainer,
    );
    setAllowLeftScroll(allowLeftScroll);
    setAllowRightScroll(allowRightScroll);
  };

  scrollContainer?.addEventListener('scroll', () =>
    setScrollStates(scrollContainer),
  );

  useEffect(() => {
    setScrollContainer(props.scrollContainerRef.current);
    setScrollStates(props.scrollContainerRef.current);
  }, [props.scrollContainerRef.current, props.maxSize]);

  return (
    <div
      className={classNames(
        'gap-2 d-flex flex-column',
        props.allowHorizontalScroll && styles['paginated-buttons'],
      )}
    >
      <PaginatedButtonIcon
        disabled={!allowLeftScroll}
        iconName='bi-chevron-compact-left'
        iconTextClass='text-blue'
        iconColor={props.buttonColor}
        onClick={() => {
          scrollContainer?.scrollBy({
            left: -props.scrollWidth || DEFAULT_SCROLL_WIDTH,
            behavior: 'smooth',
          });
        }}
      />
      <PaginatedButtonIcon
        disabled={!allowRightScroll}
        iconName='bi-chevron-compact-right'
        iconTextClass='text-blue'
        iconColor={props.buttonColor}
        onClick={() => {
          scrollContainer?.scrollBy({
            left: props.scrollWidth || DEFAULT_SCROLL_WIDTH,
            behavior: 'smooth',
          });
        }}
      />
    </div>
  );
}

export function PaginatedButtons(props: PropTypes) {
  const nextButtonDisabled = props.endElement + 1 >= props.maxSize;
  const previousButtonDisabled = props.startElement === 0;

  if (props.allowHorizontalScroll) {
    return <ScrollButtons {...props} />;
  }

  return (
    <div className='gap-1 d-flex flex-column ms-3'>
      <PaginatedButtonIcon
        disabled={previousButtonDisabled}
        iconName='bi-chevron-compact-left'
        iconColor={props.buttonColor}
        iconTextClass={previousButtonDisabled ? 'text-blue-200' : 'text-blue'}
        onClick={() => {
          props.setStartElement(props.startElement - 1);
          props.setEndElement(props.endElement - 1);
        }}
      />
      <PaginatedButtonIcon
        disabled={nextButtonDisabled}
        iconName='bi-chevron-compact-right'
        iconColor={props.buttonColor}
        iconTextClass={nextButtonDisabled ? 'text-blue-200' : 'text-blue'}
        onClick={() => {
          props.setStartElement(props.startElement + 1);
          props.setEndElement(props.endElement + 1);
        }}
      />
    </div>
  );
}
