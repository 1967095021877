import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { ClearIcon } from '../../../../../../../components/ClearIcon';
import { ScorecardAttribute } from '../../../../../../../entities/ScorecardAttribute';
import styles from './styles.module.scss';

interface PropTypes {
  activeAttribute?: ScorecardAttribute;
  onActiveAttribute: (index: number) => void;
  children: JSX.Element;
}

function CloseButton(props: { onActiveAttribute: (index: number) => void }) {
  return (
    <button
      onClick={() => props.onActiveAttribute(undefined)}
      className='btn p-0 border-0'
    >
      <ClearIcon />
    </button>
  );
}

export function ScorecardAttributeToast(props: PropTypes) {
  return (
    <div
      data-testid='sidekick-attribute-score-toast'
      className={classNames(
        'fade',
        'bg-light-info',
        'rounded-2',
        'fs-5',
        'mt-3',
        'mx-3',
        'px-3',
        'py-2',
        props.children && 'show',
        styles.toast,
      )}
    >
      <Row className='d-flex justify-content-between flex-nowrap'>
        <Col className='d-flex align-items-center flex-nowrap' xs='11'>
          {props.children}
        </Col>
        <Col xs='1'>
          <CloseButton onActiveAttribute={props.onActiveAttribute} />
        </Col>
      </Row>
    </div>
  );
}
