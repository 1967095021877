import React from 'react';
import classNames from 'classnames';
import { DropdownAction, PropTypes as ActionPropTypes } from './DropdownAction';
import styles from './styles.module.scss';

interface PropTypes {
  placementClass?: string;
  actions: ActionPropTypes[];
}

export const DropdownMenu = (props: PropTypes) => {
  return (
    <ul
      className={classNames(
        'dropdown-menu',
        styles['dropdown-menu'],
        props.placementClass || '',
      )}
      aria-labelledby='user-dropdown-toggle'
    >
      {props.actions.map((action: ActionPropTypes, index: number) => (
        <DropdownAction key={index} {...action} />
      ))}
    </ul>
  );
};
