import { SurveyModuleVersion } from '../entities/SurveyModuleVersion';
import { SurveyQuestion } from '../entities/SurveyQuestion';

export function extractQuestionsFromModuleVersions(
  surveyModuleVersions: SurveyModuleVersion[],
) {
  return surveyModuleVersions.reduce<SurveyQuestion[]>(
    (prev, curr) => prev.concat(curr.questions),
    [],
  );
}
