import React from 'react';
import { User } from '../../../../entities/User';
import { InterviewTemplate } from '../../../../entities/applicant_tracking/InterviewTemplate';
import InterviewTemplatePage from '../InterviewTemplatePage';
import { ActionType } from '../../../../enums/ActionType';

interface PropTypes {
  interviewTemplate: InterviewTemplate;
  availableParticipants: User[];
}

export default function EditInterviewTemplatePage(props: PropTypes) {
  return (
    <InterviewTemplatePage
      interviewTemplate={props.interviewTemplate}
      meetingOrganizer={props.interviewTemplate.meetingOrganizer}
      availableParticipants={props.availableParticipants}
      actionType={ActionType.Edit}
    />
  );
}
