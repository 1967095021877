export const handlePasswordSubmission = async (
  password: string,
  password_confirmation: string,
  handlePasswordError: (message: string, isPasswordInvalid?: boolean) => void,
  submitService: Promise<void>,
  redirectUrl: string,
) => {
  if (password !== password_confirmation) {
    handlePasswordError(
      'The passwords you entered do not match. Please double-check and try again.',
    );
    return;
  }

  try {
    await submitService;
    window.location.href = redirectUrl;
  } catch (e) {
    if (e.body?.current_password) {
      handlePasswordError(
        'Could not update password, please fix the following issues: Current password is invalid',
        true,
      );
    } else if (e.status === 422) {
      handlePasswordError(
        'Password does not meet the requirements. Please enter a valid password.',
      );
    } else {
      handlePasswordError('An unexpected error occurred. Please try again.');
    }
  }
};
