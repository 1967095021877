import { generatePath } from 'react-router';

export const routes = {
  referenceCheck: {
    path: 'reference_check',
    surveys: {
      path: 'surveys',
      survey: { path: ':id/*' },
    },
    candidate: {
      path: 'candidate',
      surveyRequests: {
        path: 'survey_requests',
        show: { path: ':id' },
      },
    },
  },
};

export const routeBuilder = {
  surveyPath: (id: string) =>
    build(['referenceCheck', 'surveys', 'survey'], { id }),
  candidateSurveyRequestPath: (id: string) =>
    build(['referenceCheck', 'candidate', 'surveyRequest', 'show'], { id }),
};

function build(keys: string[], options: { [key: string]: any }): string {
  let obj = routes;
  let key: string;
  let path = '';

  while ((key = keys.shift())) {
    obj = obj[key];
    path = `${path}/${obj['path']}`;
  }
  return generatePath(path, options);
}
