import React from 'react';
import classNames from 'classnames';
import { Row } from 'reactstrap';
import { Pagination } from '../../../../components/Pagination';
import { LoadingSpinnerIcon } from '../../../../components/LoadingSpinnerIcon';

interface PropTypes {
  page: number;
  pageSize: number;
  totalRows: number;
  disabled?: boolean;
  isLoadingCount: boolean;
  onPageChanged: (number) => void;
  className?: string;
  additionalInfo?: React.ReactNode;
}

function FooterCounter(props: PropTypes) {
  return (
    <ul className='col page-entries'>
      {props.totalRows > 0 || props.isLoadingCount ? (
        <>
          {' '}
          <span className='d-none d-sm-inline'>Showing </span>
          {(props.page - 1) * props.pageSize + 1}
          &nbsp;-&nbsp;
          {props.isLoadingCount
            ? '?'
            : Math.min(props.page * props.pageSize, props.totalRows)}{' '}
          of {props.isLoadingCount ? <LoadingSpinnerIcon /> : props.totalRows}{' '}
          candidates
        </>
      ) : (
        'No Candidates found'
      )}
    </ul>
  );
}

export function TableFooter(props: PropTypes) {
  const pageCount = Math.ceil(props.totalRows / props.pageSize);
  return (
    <Row
      className={classNames(
        'table-responsive-footer',
        'align-items-center',
        'fs-5',
        props.className,
      )}
    >
      {!props.disabled && <FooterCounter {...props} />}
      <div className='col mb-3'>
        {props.totalRows > 0 && (
          <Pagination
            page={props.page}
            pageCount={pageCount}
            visiblePageCount={3}
            disabled={props.disabled}
            onPageClicked={props.onPageChanged}
          />
        )}
      </div>
    </Row>
  );
}
