import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Row, Col } from 'reactstrap';
import { ProgressBar } from '../../../components/ProgressBar';
import { SurveyLayout } from '../../../components/SurveyLayout';
import { Survey } from '../../../entities/Survey';
import { SurveyService } from '../../../services/SurveyService';
import { ProgressRow } from '../ProgressRow';
import styles from '../styles.module.scss';
import { Form } from './Form';
import { Question } from './Question';
import classNames from 'classnames';

interface PropTypes {
  survey: Survey;
  onNextClick: () => void;
  onSurveyUpdate: (newSurvey: Survey) => void;
}

export function historyConfirmationEnabled(survey: Survey) {
  return (
    survey != null &&
    survey.relationship !== 'candidate' && // Not self-assessment
    survey.candidate_collaboration_started_on != null // Candidate has filled his form
  );
}

function parseDates(survey: Survey) {
  const cStartedOn =
    survey.candidate_collaboration_started_on &&
    moment(survey.candidate_collaboration_started_on);
  const cEndedOn =
    survey.candidate_collaboration_ended_on &&
    moment(survey.candidate_collaboration_ended_on);
  const rStartedOn =
    survey.reference_collaboration_started_on &&
    moment(survey.reference_collaboration_started_on);
  const rEndedOn =
    survey.reference_collaboration_ended_on &&
    moment(survey.reference_collaboration_ended_on);
  return [cStartedOn, cEndedOn, rStartedOn, rEndedOn];
}

function initialAnswer(
  cJobTitle: string,
  rJobTitle: string,
  cStartedOn: moment.Moment,
  cEndedOn: moment.Moment,
  rStartedOn?: moment.Moment,
  rEndedOn?: moment.Moment,
) {
  if (rStartedOn == null || rEndedOn == null) return null;
  if (
    cJobTitle === rJobTitle &&
    cStartedOn.isSame(rStartedOn) &&
    cEndedOn.isSame(rEndedOn)
  )
    return true;
  return false;
}

export function HistoryConfirmationScreen(props: PropTypes) {
  const navigate = useNavigate();

  // Initial dates.
  const [cStartedOn, cEndedOn, rStartedOn, rEndedOn] = parseDates(props.survey);

  // Form state.
  const [answer, setAnswer] = useState(
    initialAnswer(
      props.survey.candidate_job_title,
      props.survey.reference_job_title,
      cStartedOn,
      cEndedOn,
      rStartedOn,
      rEndedOn,
    ),
  );
  const [rJobTitle, setRJobTitle] = useState(
    props.survey.reference_job_title || '',
  );
  const [rStartedOnMonth, setRStartedOnMonth] = useState(rStartedOn?.month());
  const [rStartedOnYear, setRStartedOnYear] = useState(rStartedOn?.year());
  const [rEndedOnMonth, setREndedOnMonth] = useState(rEndedOn?.month());
  const [rEndedOnYear, setREndedOnYear] = useState(rEndedOn?.year());

  function isSubmittable() {
    return (
      answer === true ||
      (answer === false &&
        rJobTitle != null &&
        rJobTitle != '' &&
        rStartedOnMonth != null &&
        rStartedOnYear != null &&
        rEndedOnMonth != null &&
        rEndedOnYear != null)
    );
  }

  async function onNext() {
    const newSurvey = await SurveyService.patch(
      props.survey.secure_id,
      rJobTitle || props.survey.candidate_job_title,
      moment()
        .year(rStartedOnYear || cStartedOn.year())
        .month(rStartedOnMonth || cStartedOn.month())
        .startOf('month')
        .format('YYYY-MM-DD'),
      moment()
        .year(rEndedOnYear || cEndedOn.year())
        .month(rEndedOnMonth || cEndedOn.month())
        .endOf('month')
        .format('YYYY-MM-DD'),
    );
    props.onSurveyUpdate(newSurvey);
    props.onNextClick();
  }

  function handleChangeAnswer(value: 'true' | 'false') {
    setAnswer(value === 'true');
    setRJobTitle('');
    setRStartedOnMonth(undefined);
    setRStartedOnYear(undefined);
    setREndedOnMonth(undefined);
    setREndedOnYear(undefined);
  }

  return (
    <SurveyLayout
      companyInfo={props.survey.company_info}
      className={styles.surveyPage}
    >
      <Row className='mt-2 mb-5'>
        <Col>
          <ProgressBar
            progress={0}
            color={props.survey.company_info.primary_color}
          />
        </Col>
      </Row>
      <div className={classNames(styles['survey-section'])}>
        <div className={classNames('p-5')}>
          <Row>
            <Col>
              <h4>Confirmation of employment history</h4>
              <hr />
            </Col>
          </Row>
          <Question
            survey={props.survey}
            answer={answer}
            cStartedOn={cStartedOn}
            cEndedOn={cEndedOn}
            onChangeAnswer={(_fieldId, value) => handleChangeAnswer(value)}
          />
          <Form
            answer={answer}
            rJobTitle={rJobTitle}
            rStartedOnMonth={rStartedOnMonth}
            rStartedOnYear={rStartedOnYear}
            rEndedOnMonth={rEndedOnMonth}
            rEndedOnYear={rEndedOnYear}
            onChangeRJobTitle={setRJobTitle}
            onChangeRStartedOnMonth={setRStartedOnMonth}
            onChangeRStartedOnYear={setRStartedOnYear}
            onChangeREndedOnMonth={setREndedOnMonth}
            onChangeREndedOnYear={setREndedOnYear}
          />
        </div>
      </div>
      <ProgressRow
        survey={props.survey}
        nextTitle={'Next'}
        isNextDisabled={!isSubmittable()}
        onPrevious={() => navigate('../start')}
        onNext={() => onNext()}
      />
    </SurveyLayout>
  );
}
