import React from 'react';
import { Survey } from '../../../entities/Survey';
import { ReferenceSurveyFinalScreen } from './ReferenceSurveyFinalScreen';
import { isSelfAssessmentSurvey } from '../../../utils/isSelfAssessmentSurvey';
import { SelfAssessmentSurveyFinalScreen } from './SelfAssessmentSurveyFinalScreen';
import { SurveyLayout } from '../../../components/SurveyLayout';

interface PropTypes {
  survey: Survey;
}

export function SurveyFinalScreen({ survey }: PropTypes) {
  return (
    <SurveyLayout companyInfo={survey.company_info}>
      {isSelfAssessmentSurvey(survey) ? (
        <SelfAssessmentSurveyFinalScreen survey={survey} />
      ) : (
        <ReferenceSurveyFinalScreen survey={survey} />
      )}
    </SurveyLayout>
  );
}
