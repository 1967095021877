import React, { useReducer, useState } from 'react';
import { Card } from '../../../../components/Card';
import { NameInput } from '../../../survey_engine/SurveyTemplates/NameInput';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import { SectionList } from '../../../survey_engine/SurveyTemplates/SectionList';
import { SectionData } from '../../../survey_engine/SurveyTemplates/Section';
import {
  Action,
  initSections,
  reducer,
} from '../../../survey_engine/SurveyTemplates/dispatcher';
import { ScorecardTemplateService } from '../../../../services/v1/applicant_tracking/ScorecardTemplateService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { FIELD_CONFIGS, fetchTemplate, areSectionsSavable } from '../data';
import { StartingPoint } from '../../../survey_engine/SurveyTemplates/StartingPointSelector';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { ScorecardTemplateStartingPointSelector } from '../ScorecardTemplateStartingPointSelector';
import { InterviewRatingAlert } from '../InterviewRatingAlert';

const REDIRECT_URI =
  getRedirectUriQueryParam() ?? '/organization_settings/scorecard_templates';

export default function NewScorecardTemplatePage() {
  // State
  const [name, setName] = useState<string>('');
  const [startingPoint, setStartingPoint] = useState<StartingPoint>(null);
  const [sections, dispatch] = useReducer(reducer, null, initSections);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Derived State
  function isSavable() {
    if (!name.trim()) return false;
    if (startingPoint === undefined) return false;
    if (!areSectionsSavable(sections)) return false;

    return true;
  }

  // Handlers
  async function handleStartingPointChange(startingPoint: StartingPoint) {
    setStartingPoint(startingPoint);

    if (startingPoint == null) return;

    if (typeof startingPoint === 'string' && startingPoint === 'FROM_SCRATCH') {
      dispatch({ type: 'reset', newState: initSections() });
      return;
    }

    setIsLoading(true);

    const template = await fetchTemplate(startingPoint.value);
    dispatch({ type: 'reset', newState: template.sections });

    setIsLoading(false);
  }

  async function handleSave() {
    setIsLoading(true);

    await ScorecardTemplateService.create({
      name,
      sections: sections.map((section) => ({
        name: section.name,
        questions: section.fields.map((field) => ({
          title: field.title,
          fieldType: field.fieldType,
          max: field.max,
          aiFillEnabled: field.aiFillEnabled,
        })),
      })),
    });

    window.location.href = REDIRECT_URI;
  }

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <UI
        isSavable={isSavable()}
        name={name}
        onName={setName}
        startingPoint={startingPoint}
        onStartingPointChange={handleStartingPointChange}
        sections={sections}
        dispatch={dispatch}
        onSave={handleSave}
      />
    </>
  );
}

function UI(props: {
  isSavable: boolean;
  name: string;
  onName: (value: string) => void;
  startingPoint: StartingPoint;
  onStartingPointChange: (value: StartingPoint) => void;
  sections: SectionData[];
  dispatch: React.Dispatch<Action>;
  onSave: () => void;
}) {
  return (
    <div className='container'>
      <PageHeader title='Add Scorecard' backUrl={REDIRECT_URI} />
      <Card>
        <CardHeader
          title='Scorecard'
          isSavable={props.isSavable}
          onSaveClick={props.onSave}
          cancelUrl={REDIRECT_URI}
        />
        <div className='border-bottom'>
          <div className='d-flex flex-column w-50 fs-5 pt-2 pb-4 gap-4'>
            <NameInput
              name={props.name}
              placeholder='Insert scorecard name'
              onName={props.onName}
            />
            <ScorecardTemplateStartingPointSelector
              startingPoint={props.startingPoint}
              onStartingPointChange={props.onStartingPointChange}
            />
          </div>
        </div>
        <SectionList
          fieldConfigs={FIELD_CONFIGS}
          singleTypeSections={true}
          sections={props.sections}
          dispatch={props.dispatch}
          title='Sections'
          subtitle='Define and manage your scorecard sections'
          columns={[
            { fieldName: 'type', className: 'col-3' },
            { fieldName: 'text', className: 'col' },
          ]}
        />
        <InterviewRatingAlert />
      </Card>
    </div>
  );
}
