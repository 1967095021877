import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { Col, Row } from 'reactstrap';

interface PropTypes {
  daysOnStage?: number;
  actionsPending?: number;
}

function actionsPendingColourClass(actions: number): string {
  if (actions <= 3) {
    return 'text-dark-200';
  } else {
    return 'text-danger-500';
  }
}

export function ActionsPendingDetail(props: PropTypes) {
  if (props.daysOnStage == null && props.actionsPending == null) {
    return null;
  }

  return (
    <Row className='flex-nowrap'>
      <Col
        className={classNames(
          styles['smallest-font-size'],
          'text-truncate',
          'text-start',
          actionsPendingColourClass(props.actionsPending),
        )}
      >
        {props.actionsPending == null
          ? ''
          : `${props.actionsPending} actions pending`}
      </Col>
      <Col
        xs='auto'
        className={classNames(
          styles['smallest-font-size'],
          'text-end',
          'text-dark-200',
        )}
      >
        {props.daysOnStage} days on stage
      </Col>
    </Row>
  );
}
