import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { UserInvitationService } from '../../../services/UserInvitationService';
import { HeaderMessageCol } from './HeaderMessageCol';
import { NameAndLastNameRow } from './NameAndLastNameRow';
import { EmailRow } from './EmailRow';
import { InvitationButtons } from './InvitationButtons';
import { Row } from 'reactstrap';
import { Card } from '../../../components/Card';
import { Alert, AlertType } from '../../../components/Alert';
import { usePasswordErrorHandling } from '../usePasswordErrorHandling';
import { handlePasswordSubmission } from '../handlePasswordSubmission';
import { PasswordRow } from '../PasswordRow';
import { PasswordRequirementsList } from '../PasswordRequirementsList';

interface PropTypes {
  inviterUserName: string;
  currentUserFirstName: string;
  currentUserLastName: string;
  currentUserEmail: string;
  organizationName: string;
  allowPassword: boolean;
}

export interface FormValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  passwordConfirmation?: string;
  role?: string;
  currentPassword?: string;
}

export default function UserInvitationPage(props: PropTypes) {
  const { register, handleSubmit, watch, resetField } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      firstName: props.currentUserFirstName,
      lastName: props.currentUserLastName,
      email: props.currentUserEmail,
    },
  });

  const {
    alertActive,
    setAlertActive,
    hasPasswordValidationFailed,
    setPasswordValidationFailed,
    errorMessage,
    handlePasswordError,
  } = usePasswordErrorHandling(resetField);

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const password = props.allowPassword ? watch('password') : '';
  const passwordConfirmation = props.allowPassword
    ? watch('passwordConfirmation')
    : '';

  const isFormValid =
    firstName &&
    lastName &&
    email &&
    (!props.allowPassword || (password && passwordConfirmation));

  useEffect(() => {
    if (password || passwordConfirmation) {
      setPasswordValidationFailed(false);
    }
  }, [password, passwordConfirmation]);

  const onSubmit = async (data: FormValues) => {
    const { firstName, lastName, password, passwordConfirmation } = data;

    await handlePasswordSubmission(
      password!,
      passwordConfirmation!,
      handlePasswordError,
      UserInvitationService.patch(
        firstName,
        lastName,
        password!,
        passwordConfirmation!,
      ),
      '/',
    );
  };

  const handleSignOut = async () => {
    await UserInvitationService.destroy();
    window.location.href = '/';
  };

  return (
    <>
      {alertActive && (
        <Alert
          type={AlertType.Danger}
          clearable={true}
          onClose={() => setAlertActive(false)}
          autoClearTimeout={4000}
        >
          {errorMessage}
        </Alert>
      )}
      <div className='row justify-content-center'>
        <div className='col-12 col-md-6'>
          <Card className={'mb-3'}>
            <div className='app-card-header'>
              <Row className='align-items-center gx-3'>
                <HeaderMessageCol
                  inviterUserName={props.inviterUserName}
                  organizationName={props.organizationName}
                />
              </Row>
            </div>
            <div className='app-card-body px-3 w-100'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='item pt-3'>
                  <NameAndLastNameRow
                    register={register}
                    currentUserFirstName={props.currentUserFirstName}
                    currentUserLastName={props.currentUserLastName}
                    isRequired={true}
                  />
                  <EmailRow
                    register={register}
                    currentUserEmail={props.currentUserEmail}
                    isDisabled={true}
                  />
                  {props.allowPassword && (
                    <PasswordRow
                      register={register}
                      invalidInput={hasPasswordValidationFailed}
                    />
                  )}
                  <PasswordRequirementsList />
                </div>
                <InvitationButtons
                  handleSignOut={handleSignOut}
                  isFormValid={isFormValid}
                />
              </form>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
