import React from 'react';
import styles from './styles.module.scss';
import { Button } from 'reactstrap';

interface PropTypes {
  isTranscriptVisible: boolean;
  onDisplayTranscript: () => void;
  onDisplayVideo: () => void;
}

export const TranscriptToggle = React.memo(function TranscriptToggle(
  props: PropTypes,
) {
  return (
    <div
      className={`${styles.transcriptToggle} w-100 d-flex justify-content-end fs-6`}
    >
      <Button
        color='borderless'
        size='sm'
        id={'video-button'}
        className={`${styles.transcriptToggleButtons} ${
          props.isTranscriptVisible ? '' : styles.active
        } me-2`}
        onClick={() => props.onDisplayVideo()}
      >
        <div className='d-flex align-items-center'>
          <i className='bi bi-camera-video'></i>
          <span className='ms-2'>Video</span>
        </div>
      </Button>
      <Button
        color='borderless'
        size='sm'
        id={'transcript-button'}
        className={`${styles.transcriptToggleButtons} ${
          props.isTranscriptVisible ? styles.active : ''
        }`}
        onClick={() => props.onDisplayTranscript()}
      >
        <div className='d-flex align-items-center'>
          <i className='bi bi-text-left'></i>
          <span className='ms-2'>Transcript</span>
        </div>
      </Button>
    </div>
  );
});
