import React from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { QuestionsRoute } from './QuestionsRoute';
import { Evaluation } from '../../../../entities/training/Evaluation';

interface PropTypes {
  evaluation: Evaluation;
}

const QUESTIONS_PATH = 'step';
const START_PATH = `${QUESTIONS_PATH}/1`;

export function EvaluationRoutes(props: PropTypes) {
  if (props.evaluation === undefined) return <LoadingSpinner showBackdrop />;

  return (
    <>
      <Routes>
        <Route
          index
          element={
            props.evaluation.survey?.status === 'pending' ? (
              <Navigate to={START_PATH} />
            ) : null
          }
        />
        <Route
          path={`${QUESTIONS_PATH}/:step`}
          element={<QuestionsRoute evaluation={props.evaluation} />}
        />
      </Routes>
    </>
  );
}
