import React from 'react';
import { Application } from '../../../../../../entities/Application';
import { ApplicationQuestionsPanel } from '../../../../../CandidatePage/ProfileTab/ApplicationQuestionsPanel';
import styles from '../../styles.module.scss';
import classNames from 'classnames';
import { EmptyState } from '../../../../../../components/EmptyState';

interface PropTypes {
  application: Application;
}

function EmptyApplicationQuestions() {
  return (
    <div className='d-flex align-items-center h-100 w-100 justify-content-center'>
      <EmptyState
        title='Custom application questions not available'
        text='No custom application questions were defined for this job post.'
        classNames='w-100'
      />
    </div>
  );
}

function hasApplicationQuestions(application: Application) {
  const survey = application.survey;
  if (!survey) return false;

  const questions = survey.sections
    .flatMap((section) => section.questions)
    .filter((question) => question.fields.length > 0);
  if (questions.length === 0) return false;

  return true;
}

export function ApplicationQuestions(props: PropTypes) {
  return (
    <>
      {hasApplicationQuestions(props.application) ? (
        <div
          className={classNames(
            'm-4 rounded-3',
            styles['application-questions-panel'],
          )}
        >
          <ApplicationQuestionsPanel {...props} />
        </div>
      ) : (
        <EmptyApplicationQuestions />
      )}
    </>
  );
}
