import React, { useState } from 'react';
import { BULK_REVIEW_NAVIGATION_TYPE } from '..';
import { AlertObject } from '../../../../components/Alert';
import { ApplicationPage } from '../ApplicationPage';
import { CSSTransition } from 'react-transition-group';
import styles from './styles.module.scss';

const APPLICATIONS_LOAD_LIMIT = 2; // Number of applications in the background
const ANIMATION_DURATION_MS = 750;

interface PropTypes {
  currentIndex: number;
  preSelectedRejectReasonId: number;
  applicationIds: number[];
  exitedApplicationId: number;
  feedbackFFEnabled: boolean;
  onClose: () => void;
  setAlert: (alert: AlertObject) => void;
  setPreSelectedRejectReasonId: (id: number) => void;
  onNext: (navType: BULK_REVIEW_NAVIGATION_TYPE) => void;
}

interface ApplicationContainerPropTypes extends PropTypes {
  applicationId: number;
  loadedIndex: number;
  feedbackFFEnabled: boolean;
}

function ApplicationContainer(props: ApplicationContainerPropTypes) {
  const isRendering = props.loadedIndex === 1;
  const [exited, setExited] = useState<boolean>(false);
  const isLastApplication = props.currentIndex >= props.applicationIds.length;

  return (
    <CSSTransition
      key={props.applicationId}
      in={isRendering}
      onExited={() => setExited(true)}
      timeout={{
        enter: 0,
        exit: ANIMATION_DURATION_MS,
      }}
      classNames={{
        enter: styles['application-body'],
        exit: styles['application-body'],
        enterActive: styles.entering,
        enterDone: styles.entered,
        exitActive: !isLastApplication && styles.exiting,
        exitDone: !isLastApplication && styles.exited,
      }}
    >
      <ApplicationPage
        {...props}
        loadedIndex={props.loadedIndex}
        applicationId={props.applicationId}
        totalApplications={props.applicationIds.length}
        exited={exited}
      />
    </CSSTransition>
  );
}

export function ApplicationsContainer(props: PropTypes) {
  const applicationListToRender = [
    props.exitedApplicationId,
    ...props.applicationIds.slice(
      props.currentIndex,
      props.currentIndex + APPLICATIONS_LOAD_LIMIT,
    ),
  ];

  return (
    <>
      {applicationListToRender.map((applicationId, loadedIndex) => {
        return (
          <ApplicationContainer
            key={applicationId}
            applicationId={applicationId}
            loadedIndex={loadedIndex}
            {...props}
          />
        );
      })}
    </>
  );
}
