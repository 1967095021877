import { JobWorkflowEditContextProps } from '..';

function shouldSaveMove(
  from: string,
  to: string,
  pageContext: JobWorkflowEditContextProps,
) {
  const validMoveStages = from && to && from !== to;
  const isEditing = pageContext.editMode;
  const activeApplicationsExist =
    pageContext.planTemplate?.activeApplications > 0;

  return validMoveStages && isEditing && activeApplicationsExist;
}

// silent is used to indicate if the move should be silent or not
// If silent, activity feed is not recorded for the move
export function saveStageMoves(
  from: string,
  to: string,
  silent: boolean,
  pageContext: JobWorkflowEditContextProps,
) {
  if (shouldSaveMove(from, to, pageContext)) {
    pageContext.setStageMoves([
      ...pageContext.stageMoves,
      { from, to, silent },
    ]);
  }
}
