import React, { useState } from 'react';
import { Col, Nav, NavLink, Row } from 'reactstrap';
import { Highlight } from '../../../../entities/Highlight';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { HighlightCard } from '../HighlightCard';
import { EmptyState } from '../../../../components/EmptyState';
import UndrawLost from '../../../../images/undraw_lost_re_xqjt.svg';
import { Interview } from '../../../../entities/Interview';

interface PropTypes {
  interview: Interview;
}

interface HeaderPropTypes {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

interface CardsPropTypes {
  highlights: Highlight[];
}

interface NavPropTypes {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const tabs = [
  { key: 'automatic_question', label: 'Questions' },
  { key: 'attribute', label: 'Attributes' },
  { key: 'note', label: 'Notes' },
  { key: 'thumbs_up', label: 'Likes' },
  { key: 'flag', label: 'Flags' },
];

function filterHighlightsWithFilters(
  highlights: Highlight[],
  activeTab: string,
) {
  let predicate: (value: Highlight) => boolean;

  switch (activeTab) {
    case 'automatic_question':
      predicate = (h: Highlight) => h.type === 'automatic_question';
      break;
    case 'attribute':
      predicate = (h) =>
        h.type === 'scorecard_attribute' ||
        h.type === 'automatic_scorecard_attribute';
      break;
    case 'note':
      predicate = (h) => h.type === 'note';
      break;
    case 'thumbs_up':
      predicate = (h) => h.type === 'thumbs_up';
      break;
    case 'flag':
      predicate = (h) => h.type === 'flag';
      break;
    default:
      predicate = (_h: Highlight) => true;
      break;
  }

  return highlights.filter(predicate);
}

function HighlightEmptyState() {
  return (
    <EmptyState
      title='No results found'
      text={
        <p>
          Start taking advantage of the Sidekick markers
          <br />
          available during the interview.
        </p>
      }
      imageSrc={UndrawLost}
    />
  );
}

function HighlightNav(props: NavPropTypes) {
  return (
    <Nav className='app-nav-tabs'>
      {tabs.map((tab) => (
        <NavLink
          key={tab.key}
          href='#'
          active={props.activeTab === tab.key}
          className='text-sm-center'
          data-testid={tab.key}
          onClick={() => props.setActiveTab(tab.key)}
        >
          {tab.label}
        </NavLink>
      ))}
    </Nav>
  );
}

function HighlightCards(props: CardsPropTypes) {
  return (
    <Row data-testid='highlight-cards'>
      {props.highlights?.length > 0 ? (
        props.highlights.map((highlight, i) => (
          <Col
            data-testid='highlight-card'
            key={i}
            xs='12'
            sm='6'
            lg='3'
            className='mt-4'
          >
            <HighlightCard {...highlight} />
          </Col>
        ))
      ) : (
        <HighlightEmptyState />
      )}
    </Row>
  );
}

function HighlightHeader(props: HeaderPropTypes) {
  return (
    <HighlightNav
      activeTab={props.activeTab}
      setActiveTab={props.setActiveTab}
    />
  );
}

function Highlights(props: { highlights: Highlight[] }) {
  const [activeTab, setActiveTab] = useState('automatic_question');
  const highlights = props.highlights.filter((h) => !h.redacted);

  return (
    <>
      <HighlightHeader activeTab={activeTab} setActiveTab={setActiveTab} />
      <HighlightCards
        highlights={filterHighlightsWithFilters(highlights, activeTab)}
      />
    </>
  );
}

export function HighlightsSection(props: PropTypes) {
  return (
    <div className='mt-4'>
      <h3>Highlights</h3>
      <DeprecatedPanel additionalInnerContainerClassNames='p-4'>
        <Highlights
          highlights={props.interview.highlights.filter((h) => !h.redacted)}
        />
      </DeprecatedPanel>
    </div>
  );
}
