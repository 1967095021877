import React from 'react';
import {
  ApprovalFlowApprover,
  ApprovalFlowStep,
} from '../../../../../../entities/applicant_tracking/ApprovalFlow';
import { IconButton } from '../../../../../../components/IconButton';
import { User } from '../../../../../../entities/v1/User';
import { SelectOption } from '../../../../../../components/Select';
import { ApproverSelect } from './ApproverSelect';
import classNames from 'classnames';

const APPROVERS_PER_ROW = 3;
const MAX_APPROVERS_PER_STEP = 5;

interface PropTypes {
  step: ApprovalFlowStep;
  index: number;
  users: User[];
  userOptions: SelectOption[];
  readOnly?: boolean;
  onChange: (step: ApprovalFlowStep) => void;
  onDelete: () => void;
}

function removeSelectedUsersFromOptions(
  userOptions: SelectOption[],
  step: ApprovalFlowStep,
  approver: ApprovalFlowApprover,
) {
  return userOptions.filter(
    (option) =>
      !step.approvers
        .filter((a) => a.user?.id != approver.user?.id)
        .some((approver) => approver.user?.id.toString() === option.value),
  );
}

export function ApprovalStep(props: PropTypes) {
  const approverLimitReached =
    props.step.approvers.length >= MAX_APPROVERS_PER_STEP;

  const onChange = (approver: ApprovalFlowApprover, index: number) => {
    const newApprovers = [...props.step.approvers];
    newApprovers[index] = approver;
    props.onChange({ ...props.step, approvers: newApprovers });
  };

  const onDelete = (index: number) => {
    const newApprovers = [...props.step.approvers];
    newApprovers.splice(index, 1);
    props.onChange({ ...props.step, approvers: newApprovers });
  };

  const addNewApprover = () => {
    if (props.step.approvers.length >= MAX_APPROVERS_PER_STEP) return;

    const newApprovers = [...props.step.approvers, { user: null }];
    props.onChange({ ...props.step, approvers: newApprovers });
  };

  return (
    <div className='mb-4 p-4 rounded-3 border'>
      <div className='d-flex justify-content-between mb-4'>
        <h4 className='mb-0'>Step {props.index + 1}</h4>
        {!props.readOnly && (
          <IconButton
            color='secondary'
            size='sm'
            iconName='bi-trash'
            disabled={props.index === 0}
            onClick={props.onDelete}
          />
        )}
      </div>
      <div className={`row row-cols-${APPROVERS_PER_ROW} gy-4`}>
        {props.step.approvers.map((approver, index) => (
          <ApproverSelect
            key={index}
            index={index}
            approver={approver}
            isLastItem={index === props.step.approvers.length - 1}
            users={props.users}
            userOptions={removeSelectedUsersFromOptions(
              props.userOptions,
              props.step,
              approver,
            )}
            readOnly={props.readOnly}
            addNewApprover={addNewApprover}
            onChange={(approver) => onChange(approver, index)}
            onDelete={() => onDelete(index)}
          />
        ))}
        <div
          className={classNames(
            'col d-flex align-items-end',
            props.step.approvers.length % APPROVERS_PER_ROW !== 0 && 'px-0',
          )}
        >
          {!props.readOnly && (
            <IconButton
              color='borderless'
              iconName='bi-plus-circle'
              buttonText='Add Approver'
              disabled={approverLimitReached}
              tooltipText={
                approverLimitReached &&
                'This step reached the maximum number of approvers.'
              }
              onClick={addNewApprover}
            />
          )}
        </div>
      </div>
    </div>
  );
}
