import { ApplicationFormTemplateService } from '../../../services/v1/applicant_tracking/ApplicationFormTemplateService';
import { randomInt } from '../../../utils/number';
import {
  FieldConfig,
  FieldData,
} from '../../survey_engine/SurveyTemplates/Field';
import { SectionData } from '../../survey_engine/SurveyTemplates/Section';

export const DEFAULT_FIELD_CONFIGS: FieldConfig[] = [
  {
    label: 'Name',
    fieldType: 'text',
    tag: 'ats_application_form_name',
    group: 'Default Fields',
    removable: false,
    mandatory: true,
    skipQuestionField: true,
  },
  {
    label: 'Email',
    fieldType: 'email',
    tag: 'ats_application_form_email',
    group: 'Default Fields',
    removable: false,
    mandatory: true,
    skipQuestionField: true,
  },
  {
    label: 'Academic Degree',
    fieldType: 'multi_options',
    max: 1,
    tag: 'ats_application_form_academic_degree',
    group: 'Default Fields',
    skipQuestionField: true,
    skipOptionDefinition: true,
  },
  {
    label: 'Cover Letter',
    fieldType: 'attachment',
    tag: 'ats_application_form_cover_letter',
    group: 'Default Fields',
    skipQuestionField: true,
  },
  {
    label: 'Current Company',
    fieldType: 'text',
    tag: 'ats_application_form_company',
    group: 'Default Fields',
    skipQuestionField: true,
  },
  {
    label: 'Experience',
    fieldType: 'multi_options',
    max: 1,
    tag: 'ats_application_form_years_experience',
    group: 'Default Fields',
    skipQuestionField: true,
    skipOptionDefinition: true,
  },
  {
    label: 'Job Title',
    fieldType: 'text',
    tag: 'ats_application_form_job_title',
    group: 'Default Fields',
    skipQuestionField: true,
  },
  {
    label: 'LinkedIn',
    fieldType: 'url',
    tag: 'ats_application_form_linkedin_url',
    group: 'Default Fields',
    skipQuestionField: true,
  },
  {
    label: 'Location',
    fieldType: 'location',
    tag: 'ats_application_form_location',
    group: 'Default Fields',
    skipQuestionField: true,
  },
  {
    label: 'Phone',
    fieldType: 'phone',
    tag: 'ats_application_form_phone',
    group: 'Default Fields',
    skipQuestionField: true,
  },
  {
    label: 'Portfolio / Website',
    fieldType: 'url',
    tag: 'ats_application_form_portfolio_url',
    group: 'Default Fields',
    skipQuestionField: true,
  },
  {
    label: 'Resume / Curriculum Vitae',
    fieldType: 'attachment',
    tag: 'ats_application_form_resume',
    group: 'Default Fields',
    skipQuestionField: true,
  },
  {
    label: 'Salary',
    fieldType: 'number',
    tag: 'ats_application_form_salary_expectation',
    group: 'Default Fields',
    skipQuestionField: true,
  },
  {
    label: 'Source',
    fieldType: 'multi_options',
    max: 1,
    tag: 'ats_application_form_source',
    group: 'Default Fields',
    skipQuestionField: true,
    skipOptionDefinition: true,
  },
  {
    label: 'Right to Work',
    fieldType: 'boolean',
    tag: 'ats_application_form_has_working_visa',
    group: 'Default Fields',
  },
];

const CUSTOM_FIELD_CONFIGS: FieldConfig[] = [
  {
    label: 'Boolean (Yes/No) Answer',
    fieldType: 'boolean',
    group: 'Custom Fields',
  },
  {
    label: 'Multiple Choice',
    fieldType: 'multi_options',
    group: 'Custom Fields',
  },
  {
    label: 'Single Choice',
    fieldType: 'multi_options',
    max: 1,
    group: 'Custom Fields',
  },
  {
    label: 'Text (long)',
    fieldType: 'text',
    group: 'Custom Fields',
  },
  {
    label: 'Text (short)',
    fieldType: 'text',
    max: 32,
    group: 'Custom Fields',
  },
];

export const BASE_FIELD_CONFIGS: FieldConfig[] = [
  ...DEFAULT_FIELD_CONFIGS,
  ...CUSTOM_FIELD_CONFIGS,
];

export function findSelectableFieldConfigs(sections: SectionData[]) {
  const fields = sections.flatMap((section) => section.fields);
  const defaultConfigs = DEFAULT_FIELD_CONFIGS.filter(
    (config) =>
      !fields.some(
        (field) =>
          field.fieldType === config.fieldType && field?.tag === config?.tag,
      ),
  );
  return [...defaultConfigs, ...CUSTOM_FIELD_CONFIGS];
}

export function makeBlankApplicationForm(): SectionData[] {
  return [
    {
      sId: null,
      cId: randomInt(),
      name: 'Personal Information',
      removable: false,
      fields: [
        {
          serverId: null,
          clientId: randomInt(),
          title: 'Name',
          mandatory: true,
          aiFillEnabled: false,
          ...BASE_FIELD_CONFIGS[0],
        },
        {
          serverId: null,
          clientId: randomInt(),
          title: 'Email',
          mandatory: true,
          aiFillEnabled: false,
          ...BASE_FIELD_CONFIGS[1],
        },
      ],
    },
  ];
}

export async function fetchTemplate(id: string) {
  const template = await ApplicationFormTemplateService.show(id, {
    expand: [
      'survey_template',
      'survey_template.sections',
      'survey_template.sections.questions',
      'survey_template.sections.questions.options',
    ],
  });

  const cSections: SectionData[] = [];
  for (const sSection of template.surveyTemplate.sections) {
    const cFields: FieldData[] = [];
    let removable = true;
    for (const sField of sSection.questions) {
      const config = BASE_FIELD_CONFIGS.find(
        (config) =>
          config.fieldType === sField.fieldType &&
          config.tag === sField?.tag &&
          config.max === sField?.max,
      );
      cFields.push({
        serverId: sField.id,
        clientId: randomInt(),
        title: sField.title,
        aiFillEnabled: sField.aiFillEnabled,
        mandatory: sField.mandatory,
        options: sField.options?.map((option) => ({
          serverId: option.id,
          clientId: randomInt(),
          name: option.name,
        })),
        ...config,
      });
      if (config.tag === 'ats_application_form_name') removable = false;
    }

    cSections.push({
      sId: sSection.id,
      cId: randomInt(),
      name: sSection.name,
      description: sSection.description,
      fields: cFields,
      removable,
    });
  }

  return {
    id: template.id,
    name: template.surveyTemplate.name,
    sections: cSections,
    default: template.default,
    candidateConsentEnabled: template.candidateConsentEnabled,
  };
}

export function areSectionsSavable(sections: SectionData[]) {
  for (const section of sections) {
    if (!section.name.trim()) return false;

    for (const field of section.fields) {
      if (!field.fieldType) return false;
      if (!field.title.trim()) return false;

      if (field.fieldType === 'multi_options' && !field.skipOptionDefinition) {
        for (const option of field.options) {
          if (!option.name.trim()) return false;
        }
      }
    }
  }

  return true;
}
