import React from 'react';
import {
  RequisitionType,
  ApprovalAssignedTo,
  ApprovalFlowStep,
} from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { ApprovalSteps } from '../ApprovalSteps';
import { AssignedToSelector } from '../AssignedToSelector';
import { NameInput } from '../NameInput';
import { RequisitionTypeSelector } from '../RequisitionTypeSelector';

export function ApprovalFlowBody(props: {
  name: string;
  requisitionType: RequisitionType;
  assignedLocationOrDepartmentIds: number[];
  disabledLocationIds: number[];
  disabledDepartmentIds: number[];
  organizationFlowExists: boolean;
  assignedTo: ApprovalAssignedTo;
  approvalSteps: ApprovalFlowStep[];
  editingMode?: boolean;
  readOnly?: boolean;
  offerFlowFfEnabled?: boolean;
  onName?: (value: string) => void;
  setAssignedLocationOrDepartmentIds?: (ids: number[]) => void;
  setApprovalSteps?: (steps: ApprovalFlowStep[]) => void;
  setRequisitionType?: (requisitionType: RequisitionType) => void;
  onAssignedToChange?: (value: ApprovalAssignedTo) => void;
}) {
  return (
    <>
      <div className='border-bottom pb-4 mb-4'>
        <div className='d-flex flex-column w-30 fs-5 pt-2 gap-4'>
          <NameInput
            name={props.name}
            onName={props.onName}
            readOnly={props.readOnly}
          />
        </div>
        <RequisitionTypeSelector
          offerFlowFfEnabled={props.offerFlowFfEnabled}
          requisitionType={props.requisitionType}
          readOnly={props.readOnly || props.editingMode}
          onRequisitionTypeChange={props.setRequisitionType}
        />
        <AssignedToSelector
          assignedTo={props.assignedTo}
          selectedIds={props.assignedLocationOrDepartmentIds}
          organizationFlowExists={props.organizationFlowExists}
          disabledLocationIds={props.disabledLocationIds}
          disabledDepartmentIds={props.disabledDepartmentIds}
          readOnly={
            props.readOnly ||
            (props.editingMode && props.assignedTo === 'organization')
          }
          onAssignedToChange={props.onAssignedToChange}
          setSelectedIds={props.setAssignedLocationOrDepartmentIds}
        />
      </div>
      <ApprovalSteps
        steps={props.approvalSteps}
        readOnly={props.readOnly}
        setSteps={props.setApprovalSteps}
      />
    </>
  );
}
