import React, { useState } from 'react';
import { ApplicationRating } from '../../../../../../entities/v1/applicant_tracking/ApplicationRating';
import { User } from '../../../../../../entities/User';
import { AccordionPanel } from '../../../../../../components/AccordionPanel';
import styles from './styles.module.scss';
import { CandidateRatingHeader } from './CandidateRatingHeader';
import { CandidateRatingBody } from './CandidateRatingBody';
import { CandidateRatingModal } from '../../../../../../components/ApplicantTracking/CandidateRatingModal';
import { Application } from '../../../../../../entities/Application';

interface PropTypes {
  application: Application;
  applicationRating: ApplicationRating;
  currentUser: User;
  opened?: boolean;
  refreshList: () => void;
}

export function CandidateRatingBlock(props: PropTypes) {
  const [candidateRatingModalOpen, setCandidateRatingModalOpen] =
    useState(false);

  return (
    <>
      <CandidateRatingModal
        application={props.application}
        applicationRating={props.applicationRating}
        isOpen={candidateRatingModalOpen}
        onClose={() => {
          props.refreshList();
          setCandidateRatingModalOpen(false);
        }}
      />
      <AccordionPanel
        title={
          <CandidateRatingHeader
            currentUser={props.currentUser}
            applicationRating={props.applicationRating}
            setCandidateRatingModalOpen={setCandidateRatingModalOpen}
          />
        }
        className={'mb-4'}
        id={`${props.applicationRating.id}`}
        highlightOnHover={true}
        opened={props.opened}
        itemClassName={styles['box-shadow']}
      >
        <CandidateRatingBody
          currentUser={props.currentUser}
          applicationRating={props.applicationRating}
        />
      </AccordionPanel>
    </>
  );
}
