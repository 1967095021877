import { ApiService } from '../../ApiService';
import { Session } from '../../../entities/training/Session';
import { Evaluation } from '../../../entities/training/Evaluation';

export class EvaluationService {
  public static create(session_id: number): Promise<Session> {
    return ApiService.post<Session>(
      `/api/training/sessions/${session_id}/evaluations`,
    );
  }

  public static submit(session_id: number, id: number): Promise<Evaluation> {
    return ApiService.put<Evaluation>(
      `/api/training/sessions/${session_id}/evaluations/${id}/submit`,
    );
  }
}
