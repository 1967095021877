import { FilterStates } from '../../../../components/candidate_experience/Filters/filterState';

export function filterStatesToParams(filterStates: FilterStates) {
  return {
    filter_selector: filterStates.filterSelector.selected.value,
    job_names: filterStates.jobs.selected.map((option) => option.value),
    department_names: filterStates.departments.selected.map(
      (option) => option.value,
    ),
    location_names: filterStates.locations.selected.map(
      (option) => option.value,
    ),
    survey_template_ids: filterStates.surveyTemplates.selected.map(
      (option) => option.value,
    ),
    benchmark_type: filterStates.benchmarkType.selected.value,
    dateRange: filterStates.dateRange,
    ethnicities: filterStates.ethnicities.selected.map(
      (option) => option.value,
    ),
    genders: filterStates.genders.selected.map((option) => option.value),
    ageRanges: filterStates.ageRanges.selected.map((option) => option.value),
    npsAttributes: filterStates.npsAttributes.selected.map(
      (option) => option.value,
    ),
  };
}
