import React from 'react';
import { SurveyRoute } from '../../SurveyRoute';
import { EvaluationScreen } from './EvaluationScreen';
import { Evaluation } from '../../../../entities/training/Evaluation';

interface PropTypes {
  evaluation: Evaluation;
}

export function QuestionsRoute(props: PropTypes) {
  return (
    <SurveyRoute
      survey={props.evaluation.survey}
      status='pending'
      mismatchPath='..'
    >
      <EvaluationScreen evaluation={props.evaluation} />
    </SurveyRoute>
  );
}
