import { useCallback, useState } from 'react';
import { User } from '../../../../../../../entities/User';
import { Select, SelectOption } from '../../../../../../../components/Select';
import { IconButton } from '../../../../../../../components/IconButton';
import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { InterviewerParticipant } from '../../../../../../../entities/applicant_tracking/InterviewerParticipant';

interface PropTypes {
  availableParticipants: User[];
  participants: InterviewerParticipant[];
  setParticipants: (participants: InterviewerParticipant[]) => void;
}

function getAvailableParticipantsToSelect(
  availableParticipants: User[],
  participants: InterviewerParticipant[],
) {
  const availableParticipantsUserIds = availableParticipants.map(
    (user) => user.id,
  );
  const participantUserIds = participants.map(
    (participant) => participant.user.id,
  );
  const optionsIds = availableParticipantsUserIds.filter(
    (userId) => participantUserIds.indexOf(userId) < 0,
  );

  return optionsIds.map((id) => {
    const user = availableParticipants.find((u) => u.id === id);
    return { value: user.id.toString(), label: user.name };
  });
}

export function AddParticipant(props: PropTypes) {
  const availableParticipantsToSelect = getAvailableParticipantsToSelect(
    props.availableParticipants,
    props.participants,
  );

  const [addingParticipant, setAddingParticipant] = useState<boolean>(false);

  const onParticipantSelection = useCallback(
    (option: SelectOption) => {
      if (option === null) return;

      props.setParticipants(
        props.participants.concat({
          user: props.availableParticipants.find(
            (user) => user.id.toString() === option.value,
          ),
          isMeetingOrganizer: false,
          ignoreCalendar: false,
        }),
      );
      setAddingParticipant(false);
    },
    [props.participants],
  );

  return (
    <div className={'mt-2h w-60'}>
      {addingParticipant && (
        <Select
          options={availableParticipantsToSelect}
          placeholder='Select participant'
          onChange={onParticipantSelection}
          selected={null}
          className='mt-3'
          isClearable={false}
          maxMenuHeight={150}
        />
      )}
      {!addingParticipant && (
        <IconButton
          color='borderless'
          size='sm'
          iconName='bi-plus-circle'
          buttonText={'Add Participant'}
          buttonClass={classNames(styles['add-participant'], 'fs-5')}
          disabled={availableParticipantsToSelect.length < 1}
          onClick={() => {
            setAddingParticipant(true);
          }}
        />
      )}
    </div>
  );
}
