import { ApiService } from '../../ApiService';
import { ListCandidates } from '../../../entities/applicant_tracking/ListCandidate';
import {
  FilterStates,
  mapToQueryParams,
} from '../../../containers/applicant_tracking/Candidates/ListCandidatesPage/FilterStates';

export class ListCandidateService {
  public static list(filterStates: FilterStates): Promise<ListCandidates> {
    return ApiService.get<ListCandidates>(
      '/api/v1/applicant_tracking/list_candidates',
      Object.fromEntries(
        mapToQueryParams(filterStates).map(({ key, value }) => [key, value]),
      ),
    );
  }
}
