import React from 'react';
import { SurveyChip } from '../../../../../../../components/survey_engine/SurveyChip';
import { SurveyQuestionField } from '../../../../../../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../../../../entities/survey_engine/SurveyQuestionFieldAnswer';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer?: SurveyQuestionFieldAnswer;
  chipClassName?: string;
  onChangeAnswer: (
    fieldId: number,
    value: string,
    optionIds: (number | string)[],
  ) => void;
}

function BooleanChip(props: {
  className: string;
  text: string;
  active: boolean;
  fieldId: number;
  value: string;
  onClick: (
    fieldId: number,
    value: string,
    optionIds: (number | string)[],
  ) => void;
}) {
  return (
    <div className='mb-2 px-2 pt-1'>
      <SurveyChip
        className={`text-start fs-5 fw-semibold py-2 px-3 ${props.className}`}
        colorClassName={'blue'}
        active={props.active}
        onClick={() => props.onClick(props.fieldId, props.value, [])}
      >
        {props.text}
      </SurveyChip>
    </div>
  );
}

export function BooleanField(props: PropTypes) {
  return (
    <div>
      <div className='ms-3 d-flex align-content-end flex-wrap'>
        <BooleanChip
          className={props.chipClassName}
          text={'True'}
          active={props.fieldAnswer?.value === 'true'}
          fieldId={props.field.id}
          value={'true'}
          onClick={props.onChangeAnswer}
        />
        <BooleanChip
          className={props.chipClassName}
          text={'False'}
          active={props.fieldAnswer?.value === 'false'}
          fieldId={props.field.id}
          value={'false'}
          onClick={props.onChangeAnswer}
        />
      </div>
    </div>
  );
}
