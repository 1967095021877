import React from 'react';
import { formatAsPercentage } from '../../../../utils/formatAsPercentage';

interface PropTypes {
  label: string;
  value: number;
}

export function NpsBreakdownMetric(props: PropTypes) {
  return (
    <div className='col'>
      <span className='fw-semibold d-flex justify-content-center'>
        {formatAsPercentage(props.value)}%
      </span>
      <span className='d-flex justify-content-center'>{props.label}</span>
    </div>
  );
}
