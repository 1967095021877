import React, { Fragment } from 'react';
import { ProgressBar } from '../../../../../components/ProgressBar';
import styles from './styles.module.scss';

interface PropTypes {
  passthroughRate?: number;
}

function PassThroughRatePercentage(props: { passthroughRate: number }) {
  return (
    <Fragment>
      <ProgressBar
        labelClasses='fs-6 fw-normal text-dark-500'
        label='Pass-through Rate'
        progress={props.passthroughRate}
        wrapperClasses={styles['wrapper-overrides']}
      />
      <small className='ms-2 text-dark-500'>{`${Math.round(
        props.passthroughRate,
      )}%`}</small>
    </Fragment>
  );
}

export function JobStageApproval(props: PropTypes) {
  return (
    <div className='d-flex px-4 pt-2h'>
      {props.passthroughRate != null ? (
        <PassThroughRatePercentage passthroughRate={props.passthroughRate} />
      ) : (
        <div className='d-flex w-100 justify-content-center'>
          <small className='text-dark-200'>No details yet</small>
        </div>
      )}
    </div>
  );
}
