import React from 'react';
import { MetricIcon } from '../MetricIcon';
import { Timedelta } from '../../../utils/timedelta';
import classNames from 'classnames';

interface PropTypes {
  className?: string;
  durationMillis: number;
  views: number;
  rating?: number;
  showRating: boolean;
}

export function Metrics(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex',
        'flex-nowrap',
        'text-dark-200',
        'fs-6',
        'gap-3',
      )}
    >
      <MetricIcon
        iconClass='bi-stopwatch'
        metricUnity={'mins'}
        metricValue={Math.ceil(Timedelta.fromMs(props.durationMillis).toMins())}
      />
      <MetricIcon
        iconClass='bi-eye'
        metricUnity={'Views'}
        metricValue={props.views}
      />
      {props.showRating && (
        <MetricIcon iconClass='bi-star' metricValue={props.rating} />
      )}
    </div>
  );
}
