import React, { useEffect, useState } from 'react';
import {
  FilterStates,
  updateQueryParams,
  parseCurrentFilterState,
} from './FilterStates';
import { PageTitle } from '../../components/PageTitle';
import { SearchBox } from './SearchBox';
import { PageBody } from './PageBody';
import { Filters } from './Filters';
import { Table } from './Table';
import { Application } from '../../entities/v1/Application';
import { ApplicationService } from '../../services/v1/ApplicationService';
import { defaultPage } from '../FilterStates/PageFilter';
import {
  defaultSortingColumn,
  defaultSortingDirection,
} from '../FilterStates/SortingColumn';

interface PropTypes {
  interviewIntelligenceAccess: boolean;
  referenceCheckAccess: boolean;
}

export default function ListCandidatesPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingCount, setIsLoadingCount] = useState<boolean>(true);
  const [applications, setApplications] = useState<Application[]>([]);

  const [filterStates, setFilterStates] = useState<FilterStates>(
    parseCurrentFilterState(),
  );
  const [totalRows, setTotalRows] = useState<number>();

  const candidateFilters = () => {
    return {
      name: filterStates.name,
      jobId: filterStates.job?.value,
      jobStageName: filterStates.jobStage?.value,
      status: filterStates.tab === 'all' ? null : filterStates.tab,
      page: filterStates.page || defaultPage(),
      sort_column:
        filterStates.sortingColumn?.columnName || defaultSortingColumn(),
      sort_direction:
        filterStates.sortingColumn?.direction || defaultSortingDirection(),
      expand: ['candidate', 'job_stage', 'job', 'survey_request'],
    };
  };

  const loadCandidates = async () => {
    setIsLoading(true);

    const result = await ApplicationService.listWithoutCount(
      candidateFilters(),
    );

    setApplications(result.data);
    setIsLoading(false);
  };

  const loadCandidatesCount = async () => {
    setTotalRows(null);
    setIsLoadingCount(true);

    const result = await ApplicationService.count(candidateFilters());

    setTotalRows(result.entriesCount);
    setIsLoadingCount(false);
  };

  useEffect(() => {
    loadCandidates();
    loadCandidatesCount();
    updateQueryParams(filterStates);
  }, [filterStates]);

  return (
    <>
      <div className='row g-3 mb-4 align-items-center justify-content-between'>
        <div className='col-auto'>
          <PageTitle text={'Candidates'} />
        </div>
        <div className='col-auto'>
          <SearchBox
            disabled={isLoading}
            value={filterStates.name}
            onSearch={(name) => {
              setFilterStates({
                ...filterStates,
                name: name,
                page: 1,
              });
            }}
          />
        </div>
      </div>
      <PageBody>
        <Filters
          filterStates={filterStates}
          onFilterStatesUpdate={setFilterStates}
          disabled={isLoading}
        />
        <Table
          interviewIntelligenceAccess={props.interviewIntelligenceAccess}
          referenceCheckAccess={props.referenceCheckAccess}
          applications={applications}
          totalRows={totalRows}
          onFilterStatesUpdate={setFilterStates}
          filterStates={filterStates}
          isLoading={isLoading}
          isLoadingCount={isLoadingCount}
        />
      </PageBody>
    </>
  );
}
