import React from 'react';
import { Col, Row } from 'reactstrap';
import { SurveyQuestionField } from '../../../../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../../entities/survey_engine/SurveyQuestionFieldAnswer';
import { DebounceInput } from 'react-debounce-input';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer: SurveyQuestionFieldAnswer;
  onChangeAnswer: (fieldId: number, value: string) => void;
}

export function TextField(props: PropTypes) {
  const maxLength = 512;
  return (
    <Row className='my-4 col-6 mx-auto'>
      <div className='d-flex text-gray-900 align-items-center'>
        <Col className='text-start'>Optional</Col>
        <Col className='text-end ms-auto'>Max. {maxLength} chars</Col>
      </div>
      <Col xs='12'>
        <DebounceInput
          element='textarea'
          type='textarea'
          className='form-control'
          rows={5}
          autoFocus
          value={
            props.fieldAnswer?.value != null ? props.fieldAnswer.value : ''
          }
          onChange={(e) =>
            props.onChangeAnswer(props.field?.id, e.target.value)
          }
          placeholder='Leave you comment or feedback here.'
          maxLength={maxLength}
          debounceTimeout={5000}
        />
      </Col>
    </Row>
  );
}
