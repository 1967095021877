import React, { useEffect, useRef, useState } from 'react';
import {
  CardTitle,
  CardSubtitle,
  CardText,
  CardBody,
  Tooltip,
} from 'reactstrap';
import styles from './styles.module.scss';
import { DeprecatedPanel } from '../DeprecatedPanel';
import classNames from 'classnames';
import { SpanTooltip } from '../SpanTooltip';

interface PropTypes {
  className?: string;
  children?: React.ReactNode;
}

export function getTrendDesign(trend) {
  if (trend > 0) {
    return ['success', 'bi-arrow-up-short'];
  } else if (trend < 0) {
    return ['danger', 'bi-arrow-down-short'];
  }

  return ['muted', 'bi-dash'];
}

function Value(props: { value: string }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  useEffect(() => {
    const el = ref.current;
    if (el === null) return;
    setIsOverflowing(el.offsetWidth < el.scrollWidth);
  }, [props.value]);

  return (
    <>
      <CardText
        className='display1 my-0 text-nowrap overflow-hidden text-overflow-ellipsis'
        ref={ref.current}
      >
        {props.value}
      </CardText>
      {isOverflowing && (
        <Tooltip
          target={ref.current}
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          placement='bottom'
        >
          <div className='text-white'>{props.value}</div>
        </Tooltip>
      )}
    </>
  );
}

const MetricValue = (props: { value: string; trend?: number }) => {
  const [trendColor, trendIcon] = getTrendDesign(props.trend);
  return (
    <div className='d-flex align-items-center mt-0 mb-2'>
      {props.value != null && <Value value={props.value.toString()}></Value>}
      {props.trend != null && (
        <CardBody tag='div' className={`py-0 px-1 text-${trendColor}`}>
          <div>
            <i className={`bi ${trendIcon}`}></i>
            {props.trend != 0 ? `${Math.abs(props.trend)}%` : `N/A`}
          </div>
        </CardBody>
      )}
    </div>
  );
};

const Info = React.memo(function Info(props: { tooltip: string }) {
  return (
    <>
      {props.tooltip && (
        <div className={classNames('mb-0', 'fw-bold')}>
          <SpanTooltip
            className='ms-2 text-info'
            placement='right'
            text={<i className='bi bi-info-circle'></i>}
            tooltipText={props.tooltip}
            tooltipStyle={{ maxWidth: '250px' }}
          />
        </div>
      )}
    </>
  );
});

const Head = (props: { children: React.ReactNode; className?: string }) => (
  <div className={props.className}>{props.children}</div>
);

const Body = (props: { children: React.ReactNode; className?: string }) => (
  <div className={props.className}>{props.children}</div>
);

const Title = (props: { value: string; tooltip?: string }) => (
  <div className='d-flex align-items-center mb-4'>
    <CardTitle tag='h2' className='mb-0'>
      {props.value}
    </CardTitle>
    <Info tooltip={props.tooltip} />
  </div>
);

const Subtitle = (props: { value: string }) => {
  const ref = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (el === null) return;

    setIsOverflowing(el.offsetWidth < el.scrollWidth);
  }, [props.value]);

  return (
    <div className='px-0'>
      <CardSubtitle>
        <div
          className='text-nowrap overflow-hidden text-overflow-ellipsis'
          ref={ref}
        >
          {props.value}
        </div>
      </CardSubtitle>
      {isOverflowing && (
        <Tooltip
          target={ref.current}
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          placement='bottom'
        >
          <div className='text-white'>{props.value}</div>
        </Tooltip>
      )}
    </div>
  );
};

const Benchmark = (props: { name: string; value: string }) => (
  <div className='text-end align-items-end'>
    <CardSubtitle className='benchmark-subtitle mt-0 align-items-end'>
      {props.name}
    </CardSubtitle>
    <div className='d-flex justify-content-end align-items-start'>
      <i className='bi bi-activity vector-image mx-2'></i>
      <CardText className='benchmark-value pt-0 my-0'>{props.value}</CardText>
    </div>
  </div>
);

export { Title, Subtitle, Body, Head, Benchmark, MetricValue, Info };

export default React.memo(function MetricCard(props: PropTypes) {
  return (
    <DeprecatedPanel
      additionalInnerContainerClassNames={`p-3 p-xxl-4 ${styles.metricCard}`}
      className={props.className || ''}
    >
      <>{props.children}</>
    </DeprecatedPanel>
  );
});
