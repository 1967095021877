import React, { useEffect, useState } from 'react';
import { JobDetails } from '../../../../entities/JobDetails';
import { Product, Wallet } from '@vonq/hapi-elements-types';
import { loadProducts, loadTargetGroup } from './../utils/loaders';
import {
  JobPostingDetailsForm,
  prefillCampaignForm,
  saveForm,
} from './JobPostingDetails';
import { Organization } from '../../../../entities/Organization';
import { PartnerService } from '../../../../services/applicant_tracking/analytics/jobboards/PartnerService';
import { User } from '../../../../entities/User';
import { JobBoardsEmptyState } from './JobBoardsEmptyState';
import { PaymentData, PaymentModal } from './PaymentModal';
import {
  JobBoardAlert,
  JobBoardAlertObject,
  JobBoardAlertType,
} from './JobBoardAlert';
import { MarketplaceView } from './MarketplaceView';
import { CampaignReportingView } from './CampaignReportingView';
import { PostDetails } from '../../../../entities/applicant_tracking/jobboards/PostDetails';
import {
  EMPTY_FILTERS,
  EMPTY_SELECTED_FILTERS,
  FilterState,
  SelectedFilterState,
} from './../utils/filterState';
import {
  EMPTY_PRODUCTS,
  ProductsWithPagination,
} from './../utils/paginationState';
import { createCampaign } from '../utils/campaignCreator';
import { JobBoardView, WindowWithHapi } from '..';
import { JobBoardsHeader } from './JobBoardsHeader';
import { PostDetailsService } from '../../../../services/applicant_tracking/analytics/jobboards/PostDetailsService';
import { JobPostService } from '../../../../services/applicant_tracking/JobPostService';
import { mapTargetGroupToFilterState } from './Marketplace/FiltersAndSorting';
import { getErrorMessage } from '../utils/errors';
import { TargetGroupValues } from './JobPostingDetails/TargetGroup/TargetGroupValues';

interface PropTypes {
  cartAmount: number;
  jobDetails: JobDetails;
  organization: Organization;
  selectedProducts: Product[];
  user: User;
  view: JobBoardView;
  wallet: Wallet;
  window: WindowWithHapi;
  setView: (view: JobBoardView) => void;
  setWallet: (wallet: Wallet) => void;
}

export function JobBoardsTabUI(props: PropTypes) {
  const [alert, setAlert] = useState<JobBoardAlertObject>(null);
  const [form, setForm] = useState<JobPostingDetailsForm>(null);
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [targetGroupValues, setTargetGroupValues] =
    useState<TargetGroupValues>(null);
  const [hasSubdomain, setHasSubdomain] = useState<boolean>(false);
  const [postDetails, setPostDetails] = useState<PostDetails>(null);
  const [paymentData, setPaymentData] = useState<PaymentData>(null);
  const [errors, setErrors] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<SelectedFilterState>(null);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(true);
  const [products, setProducts] =
    useState<ProductsWithPagination>(EMPTY_PRODUCTS);
  const [filterState, setFilterState] = useState<FilterState>(EMPTY_FILTERS);

  async function handlePay() {
    try {
      setPaymentModalOpen(true);

      const partnerData = await PartnerService.get();
      const campaign = await createCampaign(
        form,
        props.jobDetails,
        props.organization,
        props.user,
        props.wallet,
        props.window,
      );
      setErrors(null);
      saveFormBeforePay();

      setPaymentData({
        campaignId: campaign.campaignId,
        campaignName: form.campaignName,
        marketplaceUrl: partnerData.marketplaceUrl,
        partnerId: partnerData.partnerId,
        walletId: props.wallet.id,
      });
    } catch (e: any) {
      const errorRecord = e?.response?.data?.errors || e?.response?.data;
      setErrors(errorRecord?.postingDetails);
      setPaymentModalOpen(false);
      setAlert({
        type: JobBoardAlertType.FormError,
        message: getErrorMessage(errorRecord),
      });
    }
  }

  async function saveFormBeforePay() {
    try {
      await saveForm(form, props.jobDetails); // Saves the form
    } catch (e: any) {
      // We don't want to break the flow, since this is called after creating the campaign
      console.error('The form was not saved.', e);
    }
  }

  async function handleLoadProducts() {
    setIsLoadingProducts(true);
    try {
      const newProducts = await loadProducts(
        props.window,
        props.wallet.balance.currency,
        products.pagination,
        form,
        selectedState,
      );

      setProducts({
        products: products.products.concat(newProducts?.products || []),
        pagination: {
          offset: products.pagination.offset + products.pagination.limit,
          limit: products.pagination.limit,
          totalElements: newProducts.paginationMeta.count,
        },
      });
      setIsLoadingProducts(false);
    } catch (e: any) {
      console.log('Loading products error', e);
      props.setView(JobBoardView.ErrorState);
    }
  }

  function handlePaymentCompletion(success: boolean): void {
    if (success) {
      props.setView(JobBoardView.AdsReporting);
      props.window.hapi.basket.service.clear();
    }
  }

  useEffect(() => {
    (async () => {
      setSelectedState(EMPTY_SELECTED_FILTERS);
      let dataPostDetails = null,
        jobPost = null;
      try {
        const postDetailsList = await PostDetailsService.list({
          jobId: props.jobDetails.id,
        });
        dataPostDetails = postDetailsList.postDetails?.[0];
        setPostDetails(dataPostDetails);
      } catch (e: any) {
        // If not found, means that the post detail was not previously saved.
      }

      try {
        jobPost = await JobPostService.show(
          props.jobDetails.jobPosts[0].id,
          dataPostDetails == null ? ['location'] : undefined,
        );

        setHasSubdomain(jobPost.applicationFormUrl != null);
      } catch (e: any) {
        console.error(
          'An error ocurred while trying to get the job information.',
        );
        // Some information needed for creating the campaign are missing.
      }

      setForm(prefillCampaignForm(props.jobDetails, jobPost, dataPostDetails));

      try {
        const targetGroup = await loadTargetGroup(props.window);
        setTargetGroupValues(targetGroup);
        setFilterState(
          mapTargetGroupToFilterState(props.window, filterState, targetGroup),
        );
      } catch (error) {
        console.error(error);
        props.setView(JobBoardView.ErrorState);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (props.view !== JobBoardView.Loading) {
        await handleLoadProducts();
      }
    })();
  }, [
    selectedState?.industries,
    selectedState?.jobFunctions,
    selectedState?.sortBy,
    selectedState?.search,
  ]);

  return (
    <>
      {alert != null && <JobBoardAlert message={alert} setMessage={setAlert} />}
      {paymentModalOpen && (
        <PaymentModal
          isOpen={paymentModalOpen}
          paymentData={paymentData}
          onComplete={handlePaymentCompletion}
          setIsOpen={setPaymentModalOpen}
          setAlert={setAlert}
        />
      )}
      <JobBoardsHeader
        cartAmount={props.cartAmount}
        form={form}
        hasLogo={props.organization?.organizationInfo?.logoUrl != null}
        hasSubdomain={hasSubdomain}
        jobStatus={props.jobDetails.status}
        products={props.selectedProducts}
        view={props.view}
        wallet={props.wallet}
        window={props.window}
        onPay={handlePay}
        setAlert={setAlert}
        setView={props.setView}
        setWallet={props.setWallet}
      />
      {props.view === JobBoardView.ErrorState ? (
        <JobBoardsEmptyState />
      ) : props.view === JobBoardView.Marketplace ? (
        <MarketplaceView
          currency={props.wallet.balance.currency}
          errors={errors}
          filterState={filterState}
          jobDetails={props.jobDetails}
          isLoadingProducts={isLoadingProducts}
          postDetails={postDetails}
          form={form}
          products={products}
          selectedProducts={props.selectedProducts}
          selectedState={selectedState}
          targetGroupValues={targetGroupValues}
          window={props.window}
          onLoadElements={handleLoadProducts}
          setAlert={setAlert}
          setFilterState={setFilterState}
          setForm={setForm}
          setProducts={setProducts}
          setSelectedState={setSelectedState}
        />
      ) : (
        <CampaignReportingView
          window={props.window}
          jobId={props.jobDetails.id.toString()}
        />
      )}
    </>
  );
}
