import classNames from 'classnames';
import React, { useCallback } from 'react';
import { NavLink } from 'reactstrap';
import { FollowUpResponsesTab } from '../models/FollowUpResponsesTab';
import styles from './styles.module.scss';

type ChildElement = JSX.Element | string;

interface PropTypes {
  tab: FollowUpResponsesTab;
  activeTab: FollowUpResponsesTab;
  children: ChildElement;
  className?: string;
  onClick?: (tab: FollowUpResponsesTab) => void;
}

export const FollowUpResponsesTabLink = React.memo(
  function FollowUpResponsesTabLink(props: PropTypes) {
    const handleTabClick = useCallback(
      (e: React.MouseEvent, tab: FollowUpResponsesTab) => {
        e.preventDefault();
        props.onClick?.call(null, tab);
      },
      [props.onClick],
    );

    return (
      <NavLink
        active={props.tab === props.activeTab}
        href={`?tab=${props.tab}`}
        className={classNames(styles.tabLink, props.className)}
        onClick={(e) => handleTabClick(e, props.tab)}
      >
        {props.children}
      </NavLink>
    );
  },
);
