import React, { useState } from 'react';
import { Session } from '../../../entities/training/Session';
import { User } from '../../../entities/User';
import { SessionsEmptyState } from './SessionsEmptyState';
import { SessionsHeader } from './SessionsHeader';
import { SessionsTabType } from './SessionsHeader/SessionsTabs';
import { OverviewTab, PropTypes as OverviewTabPropTypes } from './OverviewTab';
import { BrowserRouter, useSearchParams } from 'react-router-dom';
import { SessionsListTab } from './SessionListTab';

interface PropTypes {
  overview: OverviewTabPropTypes;
  sessions: Session[];
  currentUser: User;
}

function SessionsBody(props: PropTypes) {
  if (props.sessions.length === 0) {
    return <SessionsEmptyState />;
  }
  const DEFAULT_TAB: SessionsTabType = props.currentUser.isAdmin
    ? 'overview'
    : 'sessions';

  const [searchParams] = useSearchParams();
  const currentTab =
    (searchParams.get('tab') as SessionsTabType) || DEFAULT_TAB;
  const [activeTab, setActiveTab] = useState<SessionsTabType>(currentTab);

  return (
    <div data-testid='sessions-page'>
      <SessionsHeader
        user={props.currentUser}
        activeTab={activeTab}
        onTabChanged={(tab) => setActiveTab(tab)}
      />
      {currentTab === 'overview' ? (
        <OverviewTab {...props.overview} />
      ) : (
        <SessionsListTab {...props} />
      )}
    </div>
  );
}
export default function SessionsPage(props: PropTypes) {
  return (
    <BrowserRouter>
      <SessionsBody {...props} />
    </BrowserRouter>
  );
}
