import { ApiService } from '../../../services/ApiService';
import { Survey } from '../../entities/Survey';

export class SurveyService {
  public static get(surveyId: string): Promise<Survey> {
    return ApiService.get<Survey>(`/api/reference_check/surveys/${surveyId}`);
  }

  public static submit(surveyId: string): Promise<Survey> {
    return ApiService.post<Survey>(
      `/api/reference_check/surveys/${surveyId}/submit`,
      {},
    );
  }

  public static patch(
    surveyId: string,
    reference_job_title: string,
    reference_collaboration_started_on: string,
    reference_collaboration_ended_on: string,
  ): Promise<Survey> {
    return ApiService.patch<Survey>(
      `/api/reference_check/surveys/${surveyId}`,
      {
        reference_job_title,
        reference_collaboration_started_on,
        reference_collaboration_ended_on,
      },
    );
  }
}
