import React from 'react';
import { CloseableModal } from '../../../../../../../components/CloseableModal';
import { ModalBody } from './ModalBody';
import { ApplicationFeedComment } from '../../../../../../../entities/ApplicationFeedComment';
import { AlertObject, AlertType } from '../../../../../../../components/Alert';

interface PropTypes {
  comment: ApplicationFeedComment;
  showDeleteModal: boolean;
  reloadApplication: (state: boolean) => void;
  setShowDeleteModal: (state: boolean) => void;
  setAlert: (data: AlertObject) => void;
}

export function DeleteCommentModal(props: PropTypes) {
  const handleDelete = (state: boolean) => {
    if (state === true) {
      props.reloadApplication(state);
      props.setAlert({
        type: AlertType.Success,
        message: 'The comment was successfully deleted.',
      });
    } else if (state === false) {
      props.setAlert({
        type: AlertType.Danger,
        message: 'The comment could not be deleted successfully.',
      });
    }
    props.setShowDeleteModal(false);
  };

  return (
    <>
      {props.showDeleteModal && (
        <CloseableModal
          className='modal-dialog-centered'
          onClose={() => props.setShowDeleteModal(false)}
          isOpen={true}
          size='md'
          closeDisabled={false}
          keyboard={true}
          backdrop={true}
          headerTitle='Delete Comment'
          bodyChildren={
            <ModalBody comment={props.comment} onClose={handleDelete} />
          }
        />
      )}
    </>
  );
}
