import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import buttonStyles from '../SurveyButton/styles.module.scss';

type ChildElement = JSX.Element | string;

interface PropTypes {
  active?: boolean;
  className?: string;
  onClick?: () => void;
  children: ChildElement | ChildElement[];
}

export function SurveyChip(props: PropTypes) {
  const color = props.active ? 'primary' : 'secondary';

  return (
    <Button
      color={color}
      className={classNames(
        'rounded-pill border',
        `border-${color}`,
        props.className,
        buttonStyles.button,
      )}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}
