import { ApiService } from '../ApiService';
import { Note } from '../../entities/Note';

export class NoteService {
  public static createNoteReply(
    interviewMarkerId: number,
    text: string,
  ): Promise<Note> {
    return ApiService.post<Note>('/notes', {
      interview_marker_id: interviewMarkerId,
      text,
    });
  }

  public static createQuestionReply(
    interviewAutomaticQuestionId: number,
    text: string,
  ): Promise<Note> {
    return ApiService.post<Note>('/notes', {
      interview_automatic_question_id: interviewAutomaticQuestionId,
      text,
    });
  }

  public static createAutomaticAttributeReply(
    interviewAutomaticAttributeId: number,
    text: string,
  ): Promise<Note> {
    return ApiService.post<Note>('/notes', {
      interview_automatic_attribute_id: interviewAutomaticAttributeId,
      text,
    });
  }

  public static destroy(id: number): Promise<void> {
    return ApiService.delete<void>(`/notes/${id}`);
  }
}
