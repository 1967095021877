import { DateRange } from '../components/DateRangePicker';

export function dateRangeToString(dateRange: DateRange) {
  switch (dateRange) {
    case DateRange.Today:
      return 'Today';
    case DateRange.Yesterday:
      return 'Yesterday';
    case DateRange.Last7Days:
      return 'Last 7 Days';
    case DateRange.MonthToDate:
      return 'Month to date';
    case DateRange.LastMonth:
      return 'Last Month';
    case DateRange.Last30Days:
      return 'Last 30 Days';
    case DateRange.Last90Days:
      return 'Last 90 Days';
    default:
      return 'Custom Range';
  }
}
