import React from 'react';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';

interface PropTypes {
  className?: string;
  iconClassName: string;
  text: string;
}

export function IconText(props: PropTypes) {
  return (
    <Row className={classnames('align-items-center mb-3', props.className)}>
      <Col xs='auto'>
        <i
          className={classnames('bi', props.iconClassName)}
          style={{ fontSize: '1.3em' }}
        />
      </Col>
      <Col>{props.text}</Col>
    </Row>
  );
}
