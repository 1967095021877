import snakecaseKeys from 'snakecase-keys';
import {
  CreatePostDetails,
  PostDetails,
} from '../../../../../entities/applicant_tracking/jobboards/PostDetails';
import { ApiService } from '../../../../ApiService';
import { PostDetailList } from '../../../../../entities/applicant_tracking/jobboards/PostDetailList';

interface ListParams {
  jobId?: number;
  order?: string;
  limit?: number;
  withCompanyId?: boolean;
  expand?: string[];
}

export class PostDetailsService {
  public static list(params: ListParams): Promise<PostDetailList> {
    return ApiService.get(
      `/api/v1/applicant_tracking/job_boards/post_details`,
      snakecaseKeys(params),
    );
  }

  public static create(
    params: CreatePostDetails & { jobId: number },
  ): Promise<PostDetails> {
    return ApiService.post(
      `/api/v1/applicant_tracking/job_boards/post_details`,
      snakecaseKeys(params),
    );
  }
}
