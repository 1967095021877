import React from 'react';
import { RadioPillGroup } from '../../../../components/RadioPillGroup';
import { RadioPill } from '../../../../components/RadioPill';
import { AINotesSubTab } from '../../../../entities/AINotesSubTab';

interface PropTypes {
  selectedAINotesSubTab: AINotesSubTab;
  onSelectedAINotesSubTab: (val: AINotesSubTab) => void;
}

export function InterviewSummaryHeader(props: PropTypes) {
  function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
    props.onSelectedAINotesSubTab(e.currentTarget.value as AINotesSubTab);
  }
  return (
    <div className='row align-items-left p-2 my-2'>
      <RadioPillGroup
        selectedValue={props.selectedAINotesSubTab as string}
        onChange={handleOnChange}
      >
        <RadioPill id='Summary' label='Summary' value='summary' />
        <RadioPill id='Questions' label='Questions' value='questions' />
        <RadioPill id='Attributes' label='Attributes' value='attributes' />
        <RadioPill
          id='Action Points'
          label='Action Points'
          value='action_points'
        />
      </RadioPillGroup>
    </div>
  );
}
