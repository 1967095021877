import editorStyles from './editor.module.scss';
import listStyles from './list.module.scss';
import textStyles from './text.module.scss';

export const defaultTheme = {
  ltr: editorStyles.ltr,
  rtl: editorStyles.rtl,
  paragraph: editorStyles.paragraph,
  link: editorStyles.link,
  text: {
    italic: textStyles['text-italic'],
    underline: textStyles['text-underline'],
  },
  heading: {
    h1: textStyles['h1'],
    h2: textStyles['h2'],
    h3: textStyles['h3'],
    h4: textStyles['h4'],
    h5: textStyles['h5'],
    h6: textStyles['h6'],
  },
  list: {
    listitem: listStyles['list-item'],
    nested: {
      listitem: listStyles['nested-list-item'],
    },
    ol: listStyles.ol,
    olDepth: [
      listStyles.ol1,
      listStyles.ol2,
      listStyles.ol3,
      listStyles.ol4,
      listStyles.ol5,
    ],
    ul: listStyles.ul,
  },
};
