import classNames from 'classnames';
import React from 'react';
import { Chip, ChipColor } from '../../../../components/Chip';
import { Button } from 'reactstrap';
import { Card } from '../../../../components/Card';

export interface PropTypes {
  title: string;
  status: string;
  subtitle: string;
  path: string;
}

const STATUS_ELEMENTS = {
  Enabled: {
    title: 'Enabled',
    chipColor: ChipColor.SuccessLight,
  },
  Disabled: {
    title: 'Disabled',
    chipColor: ChipColor.DangerLight,
  },
  Partially: {
    title: 'Partially Enabled',
    chipColor: ChipColor.BlueLight,
  },
};

export function RecordingsSettingsPanel(props: PropTypes) {
  return (
    <div className='mt-3'>
      <Card className='px-5 py-xxl-4'>
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <div className='d-flex align-items-center'>
              <div className='fs-5 fw-semibold me-2'>{props.title}</div>
              {props.status && (
                <Chip
                  className={classNames('fw-semibold')}
                  color={STATUS_ELEMENTS[props.status].chipColor}
                >
                  {STATUS_ELEMENTS[props.status].title}
                </Chip>
              )}
            </div>
            <div className='fs-5 fw-normal text-dark-200 mt-2'>
              {props.subtitle}
            </div>
          </div>
          <Button
            color='primary'
            size='md'
            onClick={() => (window.location.href = props.path)}
          >
            Setup
          </Button>
        </div>
      </Card>
    </div>
  );
}
