import React from 'react';
import { Label as InputLabel, LabelProps as InputLabelProps } from 'reactstrap';

export function Label(props: { isRequired?: boolean } & InputLabelProps) {
  const { isRequired, ...labelProps } = props;
  return (
    <>
      <InputLabel {...labelProps}>{props.children}</InputLabel>
      {props.isRequired && (
        <span className='invalid-feedback ps-1' style={{ display: 'inline' }}>
          *
        </span>
      )}
    </>
  );
}
