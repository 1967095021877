import classNames from 'classnames';
import React from 'react';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';

interface PropTypes {
  department: string;
  location: string;
  jobOpenings: number;
  locationType?: string;
  employmentType?: string;
}

export function JobInfo(props: PropTypes) {
  const jobInfoText = [props.location, props.employmentType, props.locationType]
    .filter(Boolean)
    .join(' - ');

  return (
    <DeprecatedPanel className='p-4 my-2'>
      <div className='fs-3 fw-semibold mb-4'>Job Info</div>
      <div className='text-dark-200 fs-5'>
        <div className='my-2'>
          <i className='bi bi-people pe-2' /> {props.department}
        </div>
        {jobInfoText?.length > 0 && (
          <div className='my-2'>
            <i className='bi bi-pin-map pe-2' /> {jobInfoText}
          </div>
        )}
      </div>
      {props.jobOpenings > 0 && (
        <div
          className={classNames(
            'd-flex justify-content-center mt-4 px-5 py-2 bg-gray rounded-3 fs-5',
          )}
        >
          <span className='fw-bold'>{props.jobOpenings}</span>&nbsp; Job
          Openings
        </div>
      )}
    </DeprecatedPanel>
  );
}
