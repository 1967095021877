import React, { useState } from 'react';
import { BackgroundCheck } from '../../../../entities/BackgroundCheck';
import { Application } from '../../../../entities/Application';
import { CloseableModal } from '../../../../components/CloseableModal';
import { Alert, AlertType } from '../../../../components/Alert';
import { BackgroundCheckService } from '../../../../services/BackgroundCheckService';
import {
  BackgroundCheckRequestForm,
  BackgroundCheckRequest,
} from './BackgroundCheckRequestForm';

interface PropTypes {
  isOpen: boolean;
  onClose: () => void;
  onBackgroundCheckRequested: (backgroundCheck: BackgroundCheck[]) => void;
  application: Application;
}

async function invite(
  applicationId: number,
  request: BackgroundCheckRequest,
): Promise<{ backgroundChecks?: BackgroundCheck[]; errorType?: string }> {
  try {
    const backgroundChecks = await BackgroundCheckService.invite({
      applicationId: applicationId,
      ...request,
    });

    return { backgroundChecks };
  } catch (e) {
    return {
      errorType: e?.body?.type,
    };
  }
}

function ModalBody(
  props: {
    onSubmit: (bgc: BackgroundCheckRequest) => Promise<boolean>;
    error: string;
    onAlertClose: () => void;
    formDisabled: boolean;
  } & PropTypes,
) {
  return (
    <>
      {props.error != null ? (
        <Alert
          type={AlertType.Danger}
          clearable={true}
          autoClearTimeout={3000}
          onClose={props.onAlertClose}
        >
          <p>{props.error}</p>
        </Alert>
      ) : null}
      <BackgroundCheckRequestForm
        application={props.application}
        onCancel={props.onClose}
        onSubmit={props.onSubmit}
        disabled={props.formDisabled}
      />
    </>
  );
}

export function BackgroundCheckRequestModal(props: PropTypes) {
  const [error, setError] = useState<string>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (bgc: BackgroundCheckRequest) => {
    setSubmitting(true);

    const { backgroundChecks, errorType } = await invite(
      props.application.id,
      bgc,
    );
    const isValid = errorType == null || errorType.length == 0;

    if (!isValid) {
      if (errorType === 'background_checks_some_created') {
        setError(
          'Not all background checks were requested due to an error. Please contact support at support@screenloop.com for more information',
        );
      } else {
        setError(
          'An error occurred please contact support at support@screenloop.com',
        );
      }
    } else {
      props.onBackgroundCheckRequested(backgroundChecks);
      props.onClose();
    }

    setSubmitting(false);

    return isValid;
  };

  return (
    <CloseableModal
      onClose={props.onClose}
      isOpen={props.isOpen}
      size='md'
      bodyClassName='ps-4 pe-5 pb-4'
      closeDisabled={submitting}
      keyboard={submitting}
      backdrop={submitting}
      headerTitle='Request Background Check'
      bodyChildren={
        <ModalBody
          {...props}
          onAlertClose={() => setError(undefined)}
          formDisabled={submitting}
          error={error}
          onSubmit={onSubmit}
        />
      }
    />
  );
}
