import React, { useState } from 'react';
import { Avatar } from '../../../../components/Avatar';
import { SelectOption } from '../../../../components/Select';
import { InterviewAccess } from '../../../../entities/InterviewAccess';
import { InterviewAccessService } from '../../../../services/InterviewAccessService';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';
import { Button } from 'reactstrap';
import { IconButton } from '../../../../components/IconButton';
import { User, UserList } from '../../../../entities/v1/User';
import { SinglePaginatedSelect } from '../../../../components/Select/SinglePaginatedSelect';
import { InterviewService } from '../../../../services/v1/interview_intelligence/InterviewService';

interface PropTypes {
  interviewId: number;
  interviewAccesses: InterviewAccess[];
  onChangeAccesses: (newAccesses: InterviewAccess[]) => void;
}

function mapUserToSelectOption(user: User): SelectOption {
  return {
    value: user.id.toString(),
    label: user.name,
  };
}

function mapUsersToSelectOption(users: User[]): SelectOption[] {
  return users.map(mapUserToSelectOption);
}

export function mapResultToSelectOption(result: Promise<UserList>) {
  return result.then((filter) => {
    const { users, ...rest } = filter;

    return {
      ...rest,
      options: mapUsersToSelectOption(users),
    };
  });
}

function SharedUserRow(props: {
  access: InterviewAccess;
  onDelete: (interviewAccess: InterviewAccess) => void;
}) {
  const deleteAccess = () => {
    InterviewAccessService.delete(props.access.id);
    props.onDelete(props.access);
  };

  return (
    <div className='d-flex p-2 align-items-center border-bottom my-2'>
      <div className='me-2'>
        <Avatar
          type={props.access.user_photo ? 'photo' : 'initials'}
          name={props.access.user_photo || props.access.user_name}
        />
      </div>
      <div className='flex-grow-1'>{props.access.user_name}</div>
      <div className='text-end'>
        <IconButton
          color='borderless'
          iconName={'bi-trash'}
          onClick={deleteAccess}
        />
      </div>
    </div>
  );
}

export function ShareModalBody(props: PropTypes) {
  const shareInterview = async () => {
    // If interview access didn't exist
    if (
      props.interviewAccesses.find(
        (ia) => ia.user_id == Number(selectedUserOption.value),
      ) == null
    ) {
      const interviewAccess = await InterviewAccessService.create(
        props.interviewId,
        Number(selectedUserOption.value),
      );

      props.onChangeAccesses(props.interviewAccesses.concat(interviewAccess));
    }
  };

  const handleDelete = (interviewAccess) => {
    props.onChangeAccesses(
      props.interviewAccesses.filter((e) => e.id != interviewAccess.id),
    );
  };

  const [selectedUserOption, setSelectedUserOption] =
    useState<SelectOption>(null);

  async function loadOptions(
    search: string,
    _loadedOptions: unknown,
    { page },
  ) {
    const result = await mapResultToSelectOption(
      InterviewService.listShareUsers({
        interviewId: props.interviewId,
        name: search,
        page: page,
      }),
    );

    const selectedOptionLoaded = result.options.find(
      (option) =>
        selectedUserOption?.value == option.value &&
        selectedUserOption !== option,
    );
    const changeSelected = !!selectedOptionLoaded;
    const selected = selectedOptionLoaded ?? selectedUserOption;

    if (changeSelected) {
      setSelectedUserOption(selected);
    }

    return {
      options: result.options,
      hasMore: !!result.nextPage,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <div>
      <div>
        <div className='row align-items-center'>
          <div className='col-9' data-testid='users-share-select'>
            <SinglePaginatedSelect
              additional={{ page: 1 }}
              isClearable={true}
              value={selectedUserOption}
              loadOptions={loadOptions}
              onChange={setSelectedUserOption}
              debounceTimeout={300}
              placeholder={'Select a member to share with...'}
              closeMenuOnSelect={true}
            />
          </div>
          <div className='col-auto text-right'>
            <Button type='button' color='primary' onClick={shareInterview}>
              Share
            </Button>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-12 table-responsive'>
            <h4>Shared with</h4>
            {props.interviewAccesses.length > 0 ? (
              <div data-testid='shared-users'>
                {props.interviewAccesses.map((access) => (
                  <SharedUserRow
                    key={access.id}
                    access={access}
                    onDelete={handleDelete}
                  />
                ))}
              </div>
            ) : (
              <small>
                This interview has not been shared with any members.
                Participants, managers and site admins may access this
                interview.
              </small>
            )}
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-12'>
            <h4>Share Link</h4>
            <small>
              Share the link below with your colleagues. Only members with
              access will be able to watch the interview.
            </small>
          </div>
        </div>
        <div className='row align-items-center border-bottom pb-3 mb-3'>
          <div className='col-9'>
            <input
              type='text'
              disabled
              value={window.location.href}
              className='form-control'
            />
          </div>
          <div className='col-3'>
            <CopyToClipboard
              content={window.location.href}
              className='btn btn-primary'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
