import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SurveyLayout } from '../../../../components/survey_engine/SurveyLayout';
import { SurveyPanel } from '../../../../components/survey_engine/SurveyPanel';
import feedbackScreenImage from '../../../../images/candidate-experience-survey-feedback.svg';
import { SurveyLoadingStatus } from '../../../../types/survey_engine/SurveyLoadingStatus';
import { Survey } from '../../../../entities/survey_engine/Survey';
import { SurveyQuestion } from '../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../../../entities/survey_engine/SurveyQuestionAnswer';
import { getCpsQuestion } from '../../../../utils/candidate_experience/getCpsQuestion';
import { hasAnsweredQuestion } from '../../../../utils/survey_engine/hasAnsweredQuestion';
import { SurveyQuestionTitle } from '../../../../containers/survey_engine/SurveyPage/SurveyScreen/SurveyQuestionTitle';
import { TextField } from '../../../../containers/survey_engine/SurveyPage/SurveyScreen/fields/TextField';
import { Button } from 'reactstrap';

interface PropTypes {
  survey: Survey;
  questions: SurveyQuestion[];
  onUpdateSurvey: (survey: Survey) => void;
  onChangeStatus: (status: SurveyLoadingStatus) => void;
  onFinishSurvey: () => void;
  submitAnswer: (
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ) => Promise<Survey>;
}

function findCpsValue(
  cpsQuestion: SurveyQuestion,
  answers: SurveyQuestionAnswer[],
) {
  const cpsAnswer = answers.find(
    (answer) => answer.question_id === cpsQuestion.id,
  );
  const cpsValue = cpsAnswer.fields.find(
    (answer) => answer.field_id === cpsQuestion.fields[0].id,
  );
  return parseInt(cpsValue.value as string, 10);
}

export function SurveyFeedbackScreen(props: PropTypes) {
  const [answer, setAnswer] = useState<SurveyQuestionAnswer>();
  const cpsQuestion = getCpsQuestion(props.questions);
  const cpsValue = findCpsValue(cpsQuestion, props.survey.answers);
  const surveyQuestions = props.questions.filter(
    (question) => question.tag === 'cnps_follow_up',
  );

  const surveyQuestion =
    surveyQuestions.length > 1
      ? props.questions.find(
          (question) =>
            question.tag === 'cnps_follow_up' &&
            question.conditions[0].min_value <= cpsValue &&
            question.conditions[0].max_value >= cpsValue,
        )
      : surveyQuestions[0];

  const handleOnNextClick = useCallback(() => {
    props.onFinishSurvey();
  }, [props.survey]);

  useEffect(() => {
    if (surveyQuestion === undefined) props.onFinishSurvey();
  }, []);

  useEffect(() => {
    if (!hasAnsweredQuestion(answer, surveyQuestion, true)) return;

    props
      .submitAnswer(props.survey.secure_id, answer)
      .then((updatedSurvey) => props.onUpdateSurvey(updatedSurvey));
  }, [answer]);

  useEffect(() => {
    if (surveyQuestion === undefined) return;

    const answer = props.survey.answers.find(
      (ans) => ans.question_id === surveyQuestion.id,
    );
    setAnswer(answer);
  }, []);

  const changeAnswer = useCallback(
    (fieldId: number, value: string, optionIds?: number[]) => {
      const fields = answer?.fields.filter((f) => f.field_id !== fieldId);
      setAnswer({
        question_id: surveyQuestion.id,
        survey_id: props.survey.id,
        fields: fields.concat({
          field_id: fieldId,
          value,
          option_ids: optionIds,
        }),
      });
    },
    [answer, props.survey, surveyQuestion],
  );

  if (surveyQuestion === undefined) return null;

  return (
    <SurveyLayout companyInfo={props.survey.company_info}>
      <SurveyPanel className='text-center'>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <img className='mt-5' src={feedbackScreenImage} />
          <h1 className='my-4'>Help us to improve the process</h1>
          <div
            className='w-75 mt-2'
            style={{
              letterSpacing: `${props.survey.company_info.font_kerning / 2}em`,
            }}
          >
            {(cpsValue >= 9 || surveyQuestions.length === 1) && (
              <div style={{ maxWidth: '40em' }} className='mx-auto'>
                <h3 className='fw-normal'>{surveyQuestion.title}</h3>
              </div>
            )}
            {cpsValue < 9 && surveyQuestions.length > 1 && (
              <>
                <div className='mb-0'>
                  <SurveyQuestionTitle
                    question={surveyQuestion}
                    classNames='mb-0'
                  />
                </div>
                <p className='mt-0'>&quot;{cpsQuestion.title}&quot;</p>
              </>
            )}
            {surveyQuestion.fields.map((f) => (
              <TextField
                key={f.id}
                field={f}
                fieldAnswer={answer?.fields?.find(
                  (qfa) => f.id === qfa.field_id,
                )}
                onChangeAnswer={changeAnswer}
              />
            ))}
          </div>
          <SubmitFeedbackButton onClick={handleOnNextClick} />
        </div>
      </SurveyPanel>
    </SurveyLayout>
  );
}

function SubmitFeedbackButton(props: { onClick: () => void }) {
  const ref = useRef(null);

  return (
    <div>
      <div ref={ref} className='d-inline-block'>
        <Button color='primary' size='lg' onClick={props.onClick}>
          Submit Feedback
        </Button>
      </div>
    </div>
  );
}
