import React from 'react';
import { DropdownButton } from './DropdownButton';
import { DropdownMenu } from './DropdownMenu';
import { PropTypes as IconPropTypes } from '../Icon';
import { PropTypes as ActionPropTypes } from './DropdownMenu/DropdownAction';
import classNames from 'classnames';

interface PropTypes {
  containerClassName?: string;
  buttonIcon?: IconPropTypes;
  buttonText?: string;
  buttonClassName?: string;
  hasChevron?: boolean;
  menuPlacementClass?: string;
  menuActions: ActionPropTypes[];
}

export function Dropdown(props: PropTypes) {
  return (
    <div className={classNames('dropdown', props.containerClassName)}>
      <DropdownButton
        text={props.buttonText}
        icon={props.buttonIcon}
        className={props.buttonClassName}
        hasChevron={props.hasChevron}
      />
      <DropdownMenu
        placementClass={props.menuPlacementClass}
        actions={props.menuActions}
      />
    </div>
  );
}
