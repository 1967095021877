import { generatePath, Params } from 'react-router';

export const routes = {
  candidateExperience: {
    path: 'candidate_experience',
    surveys: {
      path: 'surveys',
      survey: { path: ':id/*' },
    },
  },
};

export const routeBuilder = {
  surveyPath: (id: string) =>
    build(['candidateExperience', 'surveys', 'survey'], { id }),
};

function build(keys: string[], options: Params): string {
  let obj = routes;
  let key: string;
  let path = '';

  while ((key = keys.shift())) {
    obj = obj[key];
    path = `${path}/${obj['path']}`;
  }
  return generatePath(path, options);
}
