import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  LexicalEditor,
  CAN_REDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  REDO_COMMAND,
} from 'lexical';
import { IS_APPLE } from '../../../../../utils/IsApple';
import { Icon } from '../../../../Icon';

interface PropTypes {
  activeEditor: LexicalEditor;
  isEditable: boolean;
  updateToolbar: () => void;
  classNames?: string;
}

export function RedoAction(props: PropTypes) {
  const [editor] = useLexicalComposerContext();
  const [canRedo, setCanRedo] = useState(false);

  useEffect(() => {
    return mergeRegister(
      props.activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    );
  }, [props.activeEditor, editor, props.updateToolbar]);

  return (
    <button
      disabled={!canRedo || !props.isEditable}
      onClick={() => {
        props.activeEditor.dispatchCommand(REDO_COMMAND, undefined);
      }}
      title={IS_APPLE ? 'Redo (⌘Z)' : 'Redo (Ctrl+Z)'}
      type='button'
      className={classNames(props.classNames, 'me-1')}
      aria-label='Redo'
    >
      <Icon name={'bi-arrow-clockwise'} />
    </button>
  );
}
