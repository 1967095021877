import { Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { CandidatePulseEmptyState } from '../CandidatePulseEmptyState';
import {
  FilterStates,
  OnChangeFilterStatesCallback,
} from '../../../../components/candidate_experience/Filters/filterState';
import { PageWideSpinner } from '../../../../components/PageWideSpinner';
import { NpsExplainability } from '../../../../entities/candidate_experience/NpsExplainability';
import { AnalyticsNpsExplainabilityService } from '../../../../services/candidate_experience/AnalyticsNpsExplainabilityService';
import { PageBreak } from '../PageBreak';
import { NpsExplainabilityBubbleChart } from './NpsExplainabilityBubbleChart';
import { NPSExplainabilityTopImpact } from './NpsExplainabilityTopImpact';

interface PropTypes {
  filterStates: FilterStates;
  onApplyFilter: OnChangeFilterStatesCallback;
  standardNpsCalculation: boolean;
}

function hasNPSExplainabilityAnalytics(nps_explainability: NpsExplainability) {
  return (
    Object.keys(nps_explainability).length > 0 &&
    (nps_explainability.top_entries.length > 0 ||
      nps_explainability.bottom_entries.length > 0)
  );
}

export function NPSExplainabilityAnalytics(props: PropTypes) {
  const [npsExplainabilityJobs, setNpsExplainabilityJobs] =
    useState<NpsExplainability>(null);

  const [npsExplainabilityDepartments, setNpsExplainabilityDepartments] =
    useState<NpsExplainability>(null);

  const [
    npsExplainabilityBubbleChartEntries,
    setNpsExplainabilityBubbleChartEntries,
  ] = useState<NpsExplainability>(null);

  function updateNpsExplainabilityBubbleChartEntries() {
    AnalyticsNpsExplainabilityService.get(
      props.filterStates.dateRange,
      props.filterStates.npsAttributes.selected.map((option) => option.value),
    ).then((analytics) => setNpsExplainabilityBubbleChartEntries(analytics));
  }

  useEffect(() => {
    AnalyticsNpsExplainabilityService.get(props.filterStates.dateRange, [
      'Jobs',
    ]).then((analytics) => setNpsExplainabilityJobs(analytics));
    AnalyticsNpsExplainabilityService.get(props.filterStates.dateRange, [
      'Departments',
    ]).then((analytics) => setNpsExplainabilityDepartments(analytics));
  }, [props.filterStates.dateRange]);

  if (npsExplainabilityJobs === null || npsExplainabilityDepartments === null) {
    return <PageWideSpinner />;
  }

  return (
    <>
      {hasNPSExplainabilityAnalytics(npsExplainabilityJobs) ||
      hasNPSExplainabilityAnalytics(npsExplainabilityDepartments) ? (
        <div className='flex-column gap-4 mt-3'>
          <Row className='gy-4 h-100'>
            <Col xs='12' md='12' lg='6'>
              <NPSExplainabilityTopImpact
                title={'Best CNPS Impact'}
                jobEntries={npsExplainabilityJobs.top_entries}
                departmentEntries={npsExplainabilityDepartments.top_entries}
                badgeStyle={'green'}
                tooltipText={
                  'Best CNPS Impact displays the top groups that contribute positively to the overall Candidate Net Promoter Score (CNPS).'
                }
                standardNpsCalculation={props.standardNpsCalculation}
              />
            </Col>
            <Col xs='12' md='12' lg='6'>
              <NPSExplainabilityTopImpact
                title={'Worst CNPS Impact'}
                jobEntries={npsExplainabilityJobs.bottom_entries}
                departmentEntries={npsExplainabilityDepartments.bottom_entries}
                badgeStyle={'red'}
                tooltipText={
                  'Worst CNPS impact displays the top groups that negatively contribute to the overall Candidate Net Promoter Score (CNPS).'
                }
                standardNpsCalculation={props.standardNpsCalculation}
              />
            </Col>
          </Row>
          <PageBreak />
          <Row>
            <Col>
              <NpsExplainabilityBubbleChart
                title={'Overall CNPS Impact'}
                tooltipText={
                  'Best and worst CNPS impact groups for the selected dimensions'
                }
                filterStates={props.filterStates}
                onApplyFilter={props.onApplyFilter}
                entryState={npsExplainabilityBubbleChartEntries}
                onUpdateEntryState={updateNpsExplainabilityBubbleChartEntries}
                standardNpsCalculation={props.standardNpsCalculation}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <CandidatePulseEmptyState
          title={'No candidate feedback available yet'}
        />
      )}
    </>
  );
}
