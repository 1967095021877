import { Moment } from 'moment';
import React from 'react';
import { Condition, ConditionsData, SelectedConditions } from '..';
import { SelectOption } from '../../../../../components/Select';
import { SurveyTemplate } from '../../../../../entities/candidate_experience/SurveyTemplate';
import { PageHeaderRow } from '../PageHeaderRow';
import { CardBody } from './CardBody';

export interface CardConditionsProps {
  labelledSurveyTemplates: SelectOption[];
  selectedSurveyTemplate: SelectOption;
  setSelectedSurveyTemplate: (selectedSurveyTemplate: SelectOption) => void;
  conditions: Condition[];
  setConditions: (condition: Condition[]) => void;
  edit: boolean;
  surveyTemplates: SurveyTemplate[];
  conditionsData: ConditionsData;
  applicableConditions: SelectOption[];
}

interface PropTypes {
  setModalIsOpen: (modalIsOpen: boolean) => void;
  surveyTriggerName: string;
  selectedConditions: SelectedConditions;
  setAlertMessage: (message: string) => void;
  surveyTriggerId: number;
  onSurveyTriggerNameChange: (name: string) => void;
  surveyTriggerStatus: boolean;
  onSurveyTriggerStatusChange: (status: boolean) => void;
  selectedMaxReminders: SelectOption;
  setSelectedMaxReminders: (selectedMaxReminder: SelectOption) => void;
  selectedDelayDays: SelectOption;
  setSelectedDelayDays: (selectedDelayDay: SelectOption) => void;
  startDate: Moment;
  lastSevenDays: Moment;
  setStartDate: (day: Moment) => void;
  today: Moment;
  cardProps: CardConditionsProps;
  processingStartsAt: Moment;
}

export function PageCard(props: PropTypes) {
  return (
    <div className='card shadow-sm d-flex flex-column align-items-start mt-3 p-3 p-xxl-4'>
      <PageHeaderRow
        setModalIsOpen={props.setModalIsOpen}
        edit={props.cardProps.edit}
        surveyTriggerName={props.surveyTriggerName}
        surveyTemplate={props.cardProps.selectedSurveyTemplate}
        conditions={props.cardProps.conditions}
        selectedConditions={props.selectedConditions}
        setAlertMessage={props.setAlertMessage}
        surveyTriggerId={props.surveyTriggerId}
      />
      <CardBody
        dateProps={{
          today: props.today,
          startDate: props.startDate,
          lastSevenDays: props.lastSevenDays,
        }}
        {...props}
      />
    </div>
  );
}
