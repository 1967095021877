import React from 'react';
import { SpanTooltip } from '../../../../../../../../components/SpanTooltip';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';
import { InterviewerParticipant } from '../../../../../../../../entities/applicant_tracking/InterviewerParticipant';
import { InterviewerParticipantIdentification } from './InterviewerParticipantIdentification';
import { MeetingOrganizerButton } from './MeetingOrganizerButton';

interface PropTypes {
  participant: InterviewerParticipant;
  candidateView: boolean;
  allowChangingMeetingOrganizer: boolean;
  onChangingHost: (participant: InterviewerParticipant) => void;
}

function MeetingOrganizerChip(props: {
  participant: InterviewerParticipant;
  candidateView: boolean;
}) {
  if (props.candidateView || !props.participant.isMeetingOrganizer) return;

  return (
    <Chip className={'ms-2h w-10'} color={ChipColor.OrangeLight}>
      Host
    </Chip>
  );
}

function DisconnectedCalendarSpanIcon(props: {
  participant: InterviewerParticipant;
  candidateView: boolean;
}) {
  if (props.candidateView || props.participant.user.hasCalendarConnected)
    return;

  return (
    <SpanTooltip
      className={'d-flex align-items-center ms-2h fs-4'}
      text={
        <div
          className={
            'd-flex align-items-center justify-content-center rounded-circle me-2'
          }
        >
          <i className={'bi bi-calendar2-x text-muted'} />
        </div>
      }
      tooltipText={
        <p className={'text-white'}>
          This participant does not have the calendar connected.
        </p>
      }
      tooltipFade={true}
    />
  );
}

export function InterviewerParticipantDetails(props: PropTypes) {
  return (
    <>
      <InterviewerParticipantIdentification
        participant={props.participant}
        candidateView={props.candidateView}
      />
      <MeetingOrganizerButton
        participant={props.participant}
        candidateView={props.candidateView}
        allowChangingMeetingOrganizer={props.allowChangingMeetingOrganizer}
        onChangingHost={props.onChangingHost}
      />
      <MeetingOrganizerChip
        participant={props.participant}
        candidateView={props.candidateView}
      />
      <DisconnectedCalendarSpanIcon
        participant={props.participant}
        candidateView={props.candidateView}
      />
    </>
  );
}
