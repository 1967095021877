import { HighlightType } from '../containers/InterviewPage/Entities/HighlightType';
import { Interview } from '../entities/Interview';
import { InterviewMarker } from '../entities/InterviewMarker';
import { InterviewSecret } from '../entities/InterviewSecret';
import { SeekbarMarker } from '../entities/SeekbarMarker';
import { AutomaticQuestion } from '../entities/v1/interview_intelligence/AutomaticQuestion';
import {
  hideMarkersInRedactedRegion,
  acceptTimestampMarker,
} from './markerFilter';

function convertInterviewMarkerToSeekbarMarker(
  interviewMarker: InterviewMarker,
): SeekbarMarker {
  return {
    id: `interview_marker_${interviewMarker.id}`,
    offset_ms: interviewMarker.offset_ms,
    kind: interviewMarker.kind,
    icon_classes: interviewMarker.icon_classes,
  };
}

function convertAutomaticQuestionToSeekbarMarker(
  automaticQuestion: AutomaticQuestion,
): SeekbarMarker {
  return {
    id: `interview_automatic_questions_${automaticQuestion.id}`,
    offset_ms:
      automaticQuestion.contextStartOffsetMs || automaticQuestion.startOffsetMs,
    kind: 'automatic_question',
    icon_classes: 'bi bi-question-square-fill text-warning',
  };
}

function convertInterviewSecretsToSeekbarMarker(
  interviewSecret: InterviewSecret,
): SeekbarMarker {
  return {
    id: `interview_secrets_${interviewSecret.id}`,
    offset_ms: interviewSecret.start_time,
    end_offset_ms: interviewSecret.end_time,
    kind: 'interview_secret',
    icon_classes: 'bi bi-file-lock2 text-pin',
  };
}

export default function buildSeekbarMarkers(
  interview: Interview,
  selectedHighlightType: HighlightType,
  automaticQuestions?: AutomaticQuestion[],
) {
  if (automaticQuestions == null) return [];

  const interviewMarkers = interview.interview_markers.map((interviewMarker) =>
    convertInterviewMarkerToSeekbarMarker(interviewMarker),
  );

  const interviewAutomaticQuestions = automaticQuestions?.map((aq) =>
    convertAutomaticQuestionToSeekbarMarker(aq),
  );

  const interviewSecrets = interview.interview_secrets.map((interviewSecret) =>
    convertInterviewSecretsToSeekbarMarker(interviewSecret),
  );

  const hideRedactedMarkers = (seekbarMarker) =>
    hideMarkersInRedactedRegion(interview.interview_secrets, seekbarMarker);

  const filterSelectedMarkers = (seekbarMarker) =>
    acceptTimestampMarker(selectedHighlightType, seekbarMarker);

  return interviewMarkers
    .concat(interviewAutomaticQuestions)
    .filter(hideRedactedMarkers)
    .concat(interviewSecrets)
    .filter(filterSelectedMarkers);
}
