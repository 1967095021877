import React from 'react';
import { Integration } from '../../entities/Integration';
import { IntegrationService } from '../../services/IntegrationService';
import { LabelledToggle } from '../LabelledToggle';

export interface PropTypes {
  integration: Integration;
  setIntegration: (i: Integration) => void;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const IntegrationToggle = React.memo(function IntegrationToggle(
  props: PropTypes,
) {
  async function toggleEventHandler() {
    if (props.integration.connected) {
      await IntegrationService.disable({
        provider: props.integration.provider,
        id: props.integration.id,
      });
      props.setIntegration({
        ...props.integration,
        connected: false,
        id: null,
      });
    } else {
      const integration = await IntegrationService.enable({
        provider: props.integration.provider,
      });
      props.setIntegration({
        ...props.integration,
        id: integration.id,
        connected: true,
      });
    }
  }

  return (
    <LabelledToggle
      checked={props.integration.connected}
      onChange={toggleEventHandler}
      inputName='IntegrationToggle'
    />
  );
});
