import React from 'react';
import { Application } from '../../../../../entities/Application';
import { CloseableModal } from '../../../../../components/CloseableModal';
import { ModalBody } from './ModalBody';

interface PropTypes {
  application: Application;
  isOpen: boolean;
  onClose: () => void;
}

export function AddScorecardModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={props.onClose}
      isOpen={props.isOpen}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle='Fill in Ad-hoc Scorecard'
      bodyChildren={<ModalBody {...props} />}
    />
  );
}
