import classNames from 'classnames';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ProgressBar } from '../../../../components/survey_engine/ProgressBar';
import { SurveyLayout } from '../../../../components/survey_engine/SurveyLayout';
import { Survey } from '../../../../entities/survey_engine/Survey';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import { useNavigate } from 'react-router-dom';

interface PropTypes {
  survey: Survey;
  step: number;
  totalSteps: number;
  title: string;
  nextDisabled: boolean;
  nextText: string;
  showPrevious: boolean;
  onNextClick: () => void;
  children: React.ReactNode;
}

const DEFAULT_PROGRESS_BAR_COLOR = '#19ceaf';

export function CustomStepScaffold(props: PropTypes) {
  const navigate = useNavigate();
  const progressBarColor =
    props.survey.company_info.primary_color || DEFAULT_PROGRESS_BAR_COLOR;

  function handlePreviousClick() {
    navigate(`../step/${props.step - 1}`);
  }

  return (
    <SurveyLayout companyInfo={props.survey.company_info}>
      <Row className='mt-2 mb-5'>
        <Col>
          <ProgressBar
            progress={((props.step - 1) / props.totalSteps) * 100}
            color={progressBarColor}
            backgroundColor={'white'}
          />
        </Col>
      </Row>
      <div className='bg-white rounded-5'>
        <div className={classNames('p-5')}>
          <Row>
            <Col>
              <h1>{props.title}</h1>
            </Col>
          </Row>
          <div className={classNames('px-4 pt-5')}>{props.children}</div>
        </div>
      </div>
      <Row className={'mt-5'}>
        {props.showPrevious && (
          <Col>
            <a
              href='#next'
              onClick={handlePreviousClick}
              className='btn btn-lg btn-secondary py-2 px-5'
            >
              Previous
            </a>
          </Col>
        )}
        <Col className='text-end mb-5'>
          <Button
            color='primary'
            size='lg'
            className='py-2 px-5 opacity-100'
            onClick={props.onNextClick}
            disabled={props.nextDisabled}
            style={{
              letterSpacing: `${props.survey.company_info.font_kerning}em`,
              background: props.nextDisabled
                ? stylesheetExports.gray700
                : stylesheetExports.primary,
            }}
          >
            {props.nextText}
          </Button>
        </Col>
      </Row>
    </SurveyLayout>
  );
}
