import { EmptyState } from '../../../components/EmptyState';
import React from 'react';

interface PropTypes {
  title: string;
  text?: string;
}

function DefaultText() {
  return (
    <p>
      Please try a different combination of filters or{' '}
      <a className='link-info' href='mailto:support@screenloop.com'>
        talk with us
      </a>{' '}
      to start collecting candidate feedback in every step of the way.
    </p>
  );
}

export function CandidatePulseEmptyState(props: PropTypes) {
  return (
    <EmptyState
      title={props.title}
      text={props.text == null ? <DefaultText /> : props.text}
    />
  );
}
