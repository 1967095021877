import React from 'react';
import { CloseableModal } from '../CloseableModal';
import { ReconnectEmailIntegrationModalBody } from './Modal';

interface PropTypes {
  isOpen: boolean;
  url: string;
  onClose: (succeeded?: boolean) => void;
}

export function ReconnectEmailIntegrationModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-top'
      onClose={props.onClose}
      isOpen={props.isOpen}
      size='md'
      headerTitle='Email Integration Expired'
      centered={false}
      bodyChildren={
        <ReconnectEmailIntegrationModalBody
          url={props.url}
          onClose={props.onClose}
        />
      }
    />
  );
}
