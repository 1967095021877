import { ApiService } from '../../ApiService';
import { Scorecard } from '../../../entities/applicant_tracking/Scorecard';
import snakecaseKeys from 'snakecase-keys';
import {
  ContentLength,
  ContentType,
} from '../../../components/TextAreaWithFormats/types';
import { buildUrl } from '../../../utils/url';
import { Answer } from '../../../entities/v1/survey_engine/Answer';

interface ScorecardDto {
  id: number;
  score: string;
  isEditing: boolean;
  answers: Answer[];
}

export interface AiGenerateProps {
  id: number;
  contentLength: ContentLength;
  contentType: ContentType;
  prompt: string;
  scorecardAttribute: string;
  onMessage: (message: MessageEvent) => void;
  onEnd: () => void;
}

export class ScorecardService {
  public static create(props: ScorecardDto): Promise<Scorecard> {
    return ApiService.post<Scorecard>(
      `/api/applicant_tracking/scorecards/${props.id}/submit`,
      snakecaseKeys(props),
    );
  }

  public static sendReminder(id: number): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/scorecards/${id}/send_reminder`,
    );
  }

  public static aiGenerate(props: AiGenerateProps): void {
    const streamUrl = buildUrl(
      window.location.origin +
        `/api/stream/applicant_tracking/scorecards/${props.id}/generate_attribute`,
      [
        { key: 'prompt', value: props.prompt || '' },
        { key: 'content_type', value: props.contentType },
        { key: 'content_length', value: props.contentLength },
        { key: 'scorecard_attribute', value: props.scorecardAttribute },
      ],
    );

    const eventSource = new EventSource(streamUrl);

    eventSource.onmessage = props.onMessage;

    eventSource.onerror = (event) => {
      if (event.eventPhase === EventSource.CLOSED) {
        eventSource.close();
        props.onEnd();
      }
    };
  }
}
