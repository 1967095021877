import React from 'react';
import { Col } from 'reactstrap';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { SingleAttachmentInputFormField } from '../../../../../../../components/SingleAttachmentInputFormField';

interface PropTypes {
  getValues: UseFormGetValues<JobRequisitionDto>;
  setValue: UseFormSetValue<JobRequisitionDto>;
  formRegister: UseFormRegister<JobRequisitionDto>;
}

export function DocumentAttachment(props: PropTypes) {
  return (
    <Col xs={6}>
      <SingleAttachmentInputFormField<JobRequisitionDto>
        inputBodyClassNames='p-0'
        getValues={props.getValues}
        setValue={props.setValue}
        formRegister={props.formRegister}
        controllerFileName='attachmentFileName'
        controllerContentName='attachmentContent'
        fieldName='Document Attachment'
        allowedFormats={['.pdf', '.doc', '.docx', '.ott', '.odt']}
        maxFileSizeMb={10}
        isRequired={false}
        headerClassNames='fw-semibold'
      />
    </Col>
  );
}
