import classNames from 'classnames';
import { Button } from 'reactstrap';
import React from 'react';

export function CollapseButton(props: {
  collapsed: boolean;
  elementNamePlural: string;
  maxElements: number;
  elementsLength: number;
  onCollapse: (flag: boolean) => void;
  showBorder?: boolean;
}) {
  if (props.elementsLength <= props.maxElements) {
    return null;
  }

  return (
    <div
      className={classNames(
        'd-flex',
        'w-100',
        'mt-4',
        'justify-content-center',
      )}
    >
      <Button
        color='borderless'
        onClick={() => props.onCollapse(!props.collapsed)}
      >
        {props.collapsed ? (
          <span>Hide {props.elementNamePlural}</span>
        ) : (
          <span>View more {props.elementNamePlural}</span>
        )}
      </Button>
    </div>
  );
}
