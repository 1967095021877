import { ApiService } from '../../../services/ApiService';
import { SurveyQuestionAnswer } from '../../entities/SurveyQuestionAnswer';

export class SurveyQuestionService {
  public static answer(
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ): Promise<any> {
    return ApiService.post<any>(
      `/api/reference_check/surveys/${surveyId}/survey_questions/${answer.question_id}/answer`,
      { answer },
    );
  }
}
