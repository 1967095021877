import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Nav } from 'reactstrap';
import { ScorecardCategory } from '../../../../../../entities/ScorecardCategory';
import {
  getFirstOverflowIndex,
  isOverflowing,
} from '../../../../../../utils/overflow';
import { ScorecardCategoriesOverflow } from '../ScorecardCategoriesOverflow';
import { ScorecardCategoryNav } from '../ScorecardCategoryNav';
import { getDropdownIndex, getShowIndex } from './utils';

const CATEGORY_PIXELS_PER_CHARACTER = 9;

interface PropTypes {
  scorecardCategories: ScorecardCategory[];
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}

interface ReorderedCategory {
  scorecardCategory: ScorecardCategory;
  index: number;
  showIndex: number;
}

// The active category should always be shown, so we will keep the original index and a showIndex,
// to reorder the categories.
function getReorderedCategories(
  activeTabIndex: number,
  dropDownIndex: number,
  scorecardCategories: ScorecardCategory[],
): ReorderedCategory[] {
  return scorecardCategories
    .map((category: ScorecardCategory, index: number) => {
      return {
        scorecardCategory: category,
        index: index,
        showIndex: getShowIndex(activeTabIndex, dropDownIndex, index),
      };
    })
    .sort(
      (a: ReorderedCategory, b: ReorderedCategory) => a.showIndex - b.showIndex,
    );
}

export function ScorecardCategoriesNavBar(props: PropTypes) {
  const { width, ref } = useResizeDetector();
  const firstOverflowIndex = getFirstOverflowIndex(
    width,
    props.scorecardCategories.map(
      (category: ScorecardCategory) => category.name,
    ),
    CATEGORY_PIXELS_PER_CHARACTER,
  );

  const dropDownIndex = getDropdownIndex(
    firstOverflowIndex,
    props.scorecardCategories.length,
  );

  const reorderedCategory = getReorderedCategories(
    props.activeTabIndex,
    dropDownIndex,
    props.scorecardCategories,
  );

  return (
    <div ref={ref}>
      <Nav className='flex-nowrap px-3 app-nav-tabs'>
        {reorderedCategory.slice(0, dropDownIndex).map((reorderedCategory) => (
          <ScorecardCategoryNav
            key={reorderedCategory.scorecardCategory.id}
            index={reorderedCategory.index}
            activeTab={props.scorecardCategories[props.activeTabIndex]}
            category={reorderedCategory.scorecardCategory}
            setActiveTabIndex={props.setActiveTabIndex}
          />
        ))}
        {isOverflowing(firstOverflowIndex) && (
          <ScorecardCategoriesOverflow
            scorecardCategories={reorderedCategory.slice(dropDownIndex)}
            activeTabIndex={props.activeTabIndex}
            setActiveTabIndex={props.setActiveTabIndex}
          />
        )}
      </Nav>
    </div>
  );
}
