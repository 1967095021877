import React from 'react';
import { longFormatDate } from '../../../../../utils/timeFormat';
import { User } from '../../../../../entities/User';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { User as Userv1 } from '../../../../../entities/v1/User';

interface PropTypes {
  currentUser: User;
  entryUser: Userv1;
  updatedAt: string | Date;
}

function Name(props: { currentUser: User; ratingUser: Userv1 }) {
  return (
    <>
      <span className='fw-semibold text-primary'>{props.ratingUser.name}</span>
      {props.ratingUser.id === props.currentUser.id && (
        <>
          &nbsp;
          <Chip className='fw-semibold' color={ChipColor.Gray}>
            My Own
          </Chip>
        </>
      )}
    </>
  );
}

export function FeedbackBlockFooter(props: PropTypes) {
  return (
    <div className='d-flex justify-content-end text-dark-200 fs-5 pb-4 mt-3'>
      <Name currentUser={props.currentUser} ratingUser={props.entryUser} />
      &nbsp;filled Feedback on {longFormatDate(props.updatedAt)}
    </div>
  );
}
