import { SelectOption } from '../../../../../components/Select';
import { QueryParam } from '../../../../../utils/url';
import {
  mapSingleValueSelectToQueryParam,
  parseSingleValueSelect,
} from './SingleValueSelectFilter';

export const JOB_QUERY_PARAM = 'job';

export function parseJob(options: SelectOption[]) {
  return parseSingleValueSelect(JOB_QUERY_PARAM, options);
}

export function mapJobToQueryParam(value?: string): QueryParam | null {
  return mapSingleValueSelectToQueryParam(JOB_QUERY_PARAM, value);
}
