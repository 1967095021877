import { SelectOption } from '../../../../../components/Select';
import { getQueryParam, QueryParam } from '../../../../../utils/url';

export interface SingleValueSelectState {
  all: SelectOption[];
  selected: SelectOption;
}

function defaultSingleValueSelect(options: SelectOption[]) {
  return {
    all: options,
    selected: null,
  };
}

export function parseSingleValueSelect(
  queryParamName: string,
  options: SelectOption[],
) {
  const value = getQueryParam(queryParamName);

  if (value != null) {
    return {
      all: options,
      selected: options.find((opt) => opt.value == value),
    };
  }

  return defaultSingleValueSelect(options);
}

export function mapSingleValueSelectToQueryParam(
  name: string,
  value?: string,
): QueryParam | null {
  if (value == null) return null;

  return {
    key: name,
    value: value,
  };
}
