import React, { useEffect, useState } from 'react';
import { MultiOptionsField } from '../../../survey_engine/SurveyPage/SurveyScreen/fields/MultiOptionsField';
import { SurveyQuestionFieldType } from '../../../../entities/survey_engine/SurveyQuestionFieldType';
import { Survey } from '../../../../entities/survey_engine/Survey';
import {
  HiringManagerEmployeeEvalSurvey,
  LeftReason,
} from '../../../../entities/survey_engine/HiringManagerEmployeeEvalSurvey';
import { HiringManagerEmployeeEvalSurveyService } from '../../../../services/quality_of_hire/HiringManagerEmployeeEvalSurveyService';
import { buildQuestion } from '../../../../utils/survey_engine/buildQuestion';
import { useNavigate } from 'react-router-dom';
import { CustomStepScaffold } from '../../../survey_engine/SurveyPage/CustomStepScaffold';
import { SurveyQuestionFieldOption } from '../../../../entities/survey_engine/SurveyQuestionFieldOption';
import { StyledSurveyQuestionTitle } from '../../../survey_engine/SurveyPage/SurveyScreen/StyledSurveyQuestionTitle';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';

interface CustomOption extends SurveyQuestionFieldOption {
  value: string;
}

interface PropTypes {
  step: number;
  survey: Survey;
  totalSteps: number;
  onUpdateSurvey: (survey: Survey) => void;
  onFinishSurvey: () => void;
}

function PartOfTeamQuestion(props: {
  survey: Survey;
  surveyDetails: HiringManagerEmployeeEvalSurvey;
  partOfTeam?: boolean;
  setPartOfTeam: (value: boolean) => void;
}) {
  const partOfTeamQuestion = buildQuestion(
    `Is ${props.surveyDetails.employee.name} part of your team?`,
    SurveyQuestionFieldType.MultiOptions,
    [
      {
        name: 'Yes',
        value: 'true',
      },
      {
        name: 'No',
        value: 'false',
      },
    ],
  );

  return (
    <>
      <StyledSurveyQuestionTitle
        question={partOfTeamQuestion}
        companyInfo={props.survey.company_info}
      />
      <MultiOptionsField
        field={partOfTeamQuestion.fields[0]}
        fieldAnswer={{
          field_id: -1,
          option_ids: (() => {
            const option = partOfTeamQuestion.fields[0].options.find(
              (option) =>
                (option as CustomOption).value === props.partOfTeam?.toString(),
            );
            if (option) {
              return [option.id as number];
            } else {
              return [];
            }
          })(),
        }}
        onChangeAnswer={(_fieldId, _value, optionIds) => {
          const option = partOfTeamQuestion.fields[0].options.find(
            (option) => option.id === optionIds[optionIds.length - 1],
          ) as CustomOption;
          props.setPartOfTeam(option?.value === 'true');
        }}
      />
    </>
  );
}

function LeftReasonQuestion(props: {
  survey: Survey;
  leftReason?: LeftReason;
  setLeftReason: (value: LeftReason) => void;
}) {
  const leftReasonQuestion = buildQuestion(
    'Can you tell us why they are not part of your team?',
    SurveyQuestionFieldType.MultiOptions,
    [
      {
        name: "I'm not the manager",
        value: LeftReason.CanNotAnswer,
      },
      {
        name: `Left ${props.survey.company_info.name}`,
        value: LeftReason.LeftOrganization,
      },
      {
        name: 'Prefer not to answer',
        value: LeftReason.PreferNotToAnswer,
      },
    ],
  );

  return (
    <>
      <hr className={'my-4'} />
      <StyledSurveyQuestionTitle
        question={leftReasonQuestion}
        companyInfo={props.survey.company_info}
      />
      <MultiOptionsField
        field={leftReasonQuestion.fields[0]}
        fieldAnswer={{
          field_id: -1,
          option_ids: (() => {
            const option = leftReasonQuestion.fields[0].options.find(
              (option) => (option as CustomOption).value === props.leftReason,
            );
            if (option) {
              return [option.id as number];
            } else {
              return [];
            }
          })(),
        }}
        onChangeAnswer={(_fieldId, _value, optionIds) => {
          const option = leftReasonQuestion.fields[0].options.find(
            (option) => option.id === optionIds[optionIds.length - 1],
          ) as CustomOption;
          props.setLeftReason(option.value as LeftReason);
        }}
      />
    </>
  );
}

export default function EmployeeDataStep1(props: PropTypes) {
  const navigate = useNavigate();
  const surveyDetails = props.survey
    .surveyDetails as HiringManagerEmployeeEvalSurvey;

  const [partOfTeam, setPartOfTeam] = useState<boolean>(
    surveyDetails.partOfTeam,
  );
  const [leftReason, setLeftReason] = useState<LeftReason>(
    surveyDetails.leftReason,
  );

  async function handleNextClick() {
    const resp = await HiringManagerEmployeeEvalSurveyService.update(
      surveyDetails.id,
      partOfTeam,
      leftReason,
    );
    props.onUpdateSurvey({
      ...props.survey,
      surveyDetails: resp,
    });
    if (partOfTeam === false) {
      props.onFinishSurvey();
    } else {
      navigate(`../step/2`);
    }
  }

  const allAnswered =
    partOfTeam === true || (partOfTeam === false && leftReason != null);

  useEffect(() => {
    const employee = surveyDetails.employee;
    if (Object.values(HrisProvider).includes(employee.provider)) {
      navigate(`../step/3`);
    }
  }, []);

  return (
    <CustomStepScaffold
      survey={props.survey}
      step={props.step}
      totalSteps={props.totalSteps}
      nextDisabled={!allAnswered}
      nextText={partOfTeam === true ? 'Next' : 'Submit'}
      title='Employee data'
      onNextClick={handleNextClick}
      showPrevious={true}
    >
      <PartOfTeamQuestion
        survey={props.survey}
        surveyDetails={surveyDetails}
        partOfTeam={partOfTeam}
        setPartOfTeam={setPartOfTeam}
      />
      {partOfTeam === false && (
        <LeftReasonQuestion
          survey={props.survey}
          leftReason={leftReason}
          setLeftReason={setLeftReason}
        />
      )}
    </CustomStepScaffold>
  );
}
