import React, { useState, useEffect } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useFormContext, Controller, Control } from 'react-hook-form';
import styles from './ProspectFormModal.styles.module.scss';
import { Button, Col, Row } from 'reactstrap';
import { ProspectInfo } from '../../../entities/ProspectInfo';
import { CloseableModal } from '../../../../components/CloseableModal';
import { InputFormField } from '../../../../components/InputFormField';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';
import PhoneInputWithCountry, {
  DefaultFormValues,
} from 'react-phone-number-input/react-hook-form';
import classNames from 'classnames';

interface PropTypes {
  onClose: () => void;
  onSubmit: (v: ProspectInfo) => void;
  isOpen: boolean;
  surveySecureId: string;
}

const isValidPhoneNumber = (phoneNumber?: string): boolean => {
  if (phoneNumber == null || phoneNumber === '') return true;

  return isPossiblePhoneNumber(phoneNumber);
};

export function ProspectFormModal(props: PropTypes) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<ProspectInfo>();

  const [country, setCountry] = useState<string>(undefined);
  const [region, setRegion] = useState<string>(undefined);
  const [hasRemoteLocation, setHasRemoteLocation] = useState<boolean>(false);

  useEffect(() => {
    setValue('country', country);
    setCountry(country);
    setValue('region', region);
    setValue('wants_remote', hasRemoteLocation);
  }, [hasRemoteLocation, country, region]);

  return (
    <CloseableModal
      onClose={props.onClose}
      isOpen={props.isOpen}
      size='md'
      bodyClassName='px-5'
      headerTitle='Tell us a little bit more about yourself...'
      bodyChildren={
        <form id='NewProspectForm' onSubmit={handleSubmit(props.onSubmit)}>
          <Row className='mb-3'>
            <Col>
              <InputFormField
                type='text'
                registerReturn={register('company', { required: true })}
                fieldName='Employer'
                placeholder='Current company'
                isRequired={true}
                classNames={`${errors.company && 'is-invalid'}`}
              />
              {errors.company && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide an employer
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <InputFormField
                type='text'
                registerReturn={register('job_title', { required: true })}
                fieldName='Job Title'
                placeholder='e.g. Senior Account Executive'
                isRequired={true}
                classNames={`${errors.job_title && 'is-invalid'}`}
              />
              {errors.job_title && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a job title
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormFieldHeader fieldName='Location' isRequired={true} />
              <Row>
                <Col>
                  <Controller
                    name='country'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CountryDropdown
                        classes={`form-select ${
                          errors.country &&
                          country === undefined &&
                          'is-invalid'
                        }`}
                        {...field}
                        onChange={setCountry}
                      />
                    )}
                  />
                </Col>
                <Col>
                  <Controller
                    name='region'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <RegionDropdown
                        classes={`form-select ${errors.region && 'is-invalid'}`}
                        country={country}
                        {...field}
                        onChange={setRegion}
                      />
                    )}
                  />
                </Col>
              </Row>
              <div className='form-check'>
                <input
                  id='wants_remote'
                  name='wants_remote'
                  type='checkbox'
                  className='form-check-input'
                  checked={hasRemoteLocation}
                  onChange={(e) => setHasRemoteLocation(e.target.checked)}
                />
                <label className='form-check-label' htmlFor='wants_remote'>
                  I prefer to work remotely
                </label>
              </div>
              {(errors.country || errors.region) && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please select a country and a region
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormFieldHeader fieldName='Phone' isRequired={false} />
              <PhoneInputWithCountry
                name='phone_number'
                className={classNames('form-control', styles.PhoneInputInput, {
                  'is-invalid': errors.phone_number,
                })}
                // The `control` prop wants a generic Record while we want a
                // specialization of a Record. So, this cast is safe.
                control={control as unknown as Control<DefaultFormValues, any>}
                international={true}
                rules={{ validate: isValidPhoneNumber }}
              />
              {errors.phone_number && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a valid phone number
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <InputFormField
                type='date'
                registerReturn={register('availability_date', {
                  required: true,
                })}
                fieldName='Availability Date'
                isRequired={true}
                classNames={`${errors.availability_date && 'is-invalid'}`}
              />
              {errors.availability_date && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a date
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <InputFormField
                type='text'
                registerReturn={register('linkedin_profile_url')}
                fieldName='LinkedIn'
                placeholder='LinkedIn profile'
                isRequired={false}
                classNames={`${errors.linkedin_profile_url && 'is-invalid'}`}
              />
            </Col>
          </Row>
        </form>
      }
      footerChildren={
        <>
          <Button
            disabled={
              !country ||
              !region ||
              !watch('company') ||
              !watch('job_title') ||
              !watch('availability_date')
            }
            color='primary'
            form='NewProspectForm'
            type='submit'
            className='mb-4 py-2 w-75'
          >
            Send
          </Button>
        </>
      }
      footerClassName='justify-content-center'
    />
  );
}
