import React from 'react';
import classNames from 'classnames';
import { Icon } from '../../Icon';
import styles from '../styles.module.scss';
import { SpanTooltip } from '../../SpanTooltip';
import { Button } from 'reactstrap';

interface FormatButtonProps {
  active: boolean;
  tooltipText: JSX.Element | string;
  icon?: string;
  text?: string;
  disabled?: boolean;
  onClick: () => void;
}

export function FormatButton(props: FormatButtonProps) {
  return (
    <SpanTooltip
      tooltipFade
      placement='top'
      text={
        <Button
          type='button'
          color='borderless'
          onClick={props.onClick}
          size='sm'
          disabled={props.disabled}
          className={classNames(
            'me-2',
            styles['format-button'],
            props.icon && styles['format-button-icon'],
            props.active && styles['active-format-button'],
          )}
        >
          <div className='d-flex'>
            {props.text ? (
              <div className='text-dark-500 fs-6'>{props.text}</div>
            ) : (
              <Icon name={props.icon} />
            )}
          </div>
        </Button>
      }
      tooltipText={props.tooltipText}
    />
  );
}
