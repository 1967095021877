import React from 'react';
import { Col, Row, Table, Badge } from 'reactstrap';
import MetricCard, {
  Head,
  Title,
  Subtitle,
  Body,
  MetricValue,
} from '../../components/MetricCard';
import { RepeatedQuestion } from '../../entities/RepeatedQuestion';
import { RepeatedQuestionMention } from '../../entities/RepeatedQuestionMention';
import { SubMetric } from './SubMetric';

interface EnquiryQualityMetrics {
  questions: number;
  repeated_questions: number;
  interviews: number;
  interviewers: number;
  candidates: number;
}

interface PropTypes {
  repeated_questions: RepeatedQuestion[];
  metrics: EnquiryQualityMetrics;
}

function sortQuestionsOnMentionCount(repeatedQuestions: RepeatedQuestion[]) {
  return repeatedQuestions.sort((a, b) => {
    return b.mentions.length - a.mentions.length;
  });
}

export default React.memo(function EnquiryQualityPage(props: PropTypes) {
  const QUESTIONS_TO_DISPLAY = 10;

  const repeatedQuestions = sortQuestionsOnMentionCount(
    props.repeated_questions,
  );

  const repeatedQuestionsToDisplay = repeatedQuestions.slice(
    0,
    QUESTIONS_TO_DISPLAY,
  );

  return (
    <div>
      <Row>
        <Col xs='12' sm='6' lg='4' className='mt-4'>
          <MetricCard>
            <Head>
              <Title
                value='Questions'
                tooltip='AI detected questions by interviewer'
              />
            </Head>
            <Body>
              <MetricValue value={`${props.metrics.questions}`} />
              <Subtitle value='AVG QUESTIONS PER INTERVIEW' />
            </Body>
          </MetricCard>
        </Col>
        <Col xs='12' sm='6' lg='4' className='mt-4'>
          <MetricCard>
            <Head>
              <Title value='Repetition' />
            </Head>
            <Body>
              <MetricValue value={`${props.metrics.repeated_questions}`} />
              <Subtitle value='AVG REPEATED QUESTIONS' />
            </Body>
          </MetricCard>
        </Col>
        <Col xs='12' sm='6' lg='4' className='mt-4'>
          <MetricCard>
            <Head>
              <Title value='Process Overview' />
            </Head>
            <Body>
              <Row className='pb-xxxl-3 pb-xxl-2 pb-xl-1'>
                <Col className={`d-flex justify-content-center`}>
                  <SubMetric
                    label='INTERVIEWS'
                    value={`${props.metrics.interviews}`}
                    icon='bi-person-square'
                  />
                </Col>
                <Col className={`d-flex justify-content-center`}>
                  <SubMetric
                    label='CANDIDATES'
                    value={`${props.metrics.candidates}`}
                    icon='bi-people-fill'
                  />
                </Col>
                <Col className={`d-flex justify-content-center`}>
                  <SubMetric
                    label='INTERVIEWERS'
                    value={`${props.metrics.interviewers}`}
                    icon='bi-person-lines-fill'
                  />
                </Col>
              </Row>
            </Body>
          </MetricCard>
        </Col>
      </Row>
      <MetricCard className='mt-3'>
        <Head>
          <Title value='Most Repeated Questions' />
        </Head>
        <Body>
          <Table className='fs-5 text-primary'>
            <thead className='fw-bold'>
              <tr>
                <th scope='col' className='col-6'>
                  Questions
                </th>
                <th scope='col' className='col-2 text-center'>
                  Mentions
                </th>
                <th scope='col' className='col-4'>
                  Jobs
                </th>
              </tr>
            </thead>
            <tbody>
              {repeatedQuestionsToDisplay.map(
                (repeatedQuestion: RepeatedQuestion) => {
                  const distinctJobNames = new Set(
                    repeatedQuestion.mentions.map(
                      (mention: RepeatedQuestionMention) => mention.job.name,
                    ),
                  );
                  return (
                    <tr key={repeatedQuestion.id}>
                      <td className=' fw-normal'>{repeatedQuestion.title}</td>
                      <td className='fw-normal text-center'>
                        {repeatedQuestion.mentions.length}
                      </td>
                      <td>
                        {Array.from(distinctJobNames).map((jobName: string) => {
                          return (
                            <Badge
                              key={jobName}
                              className='rounded-pill me-1 bg-gray text-primary fw-normal'
                            >
                              {jobName}
                            </Badge>
                          );
                        })}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </Table>
        </Body>
      </MetricCard>
    </div>
  );
});
