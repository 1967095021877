import React from 'react';
import { Col } from 'reactstrap';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { SurveyQuestion } from '../../../../reference_check/entities/SurveyQuestion';
import styles from './styles.module.scss';

interface PropTypes {
  question: SurveyQuestion;
  children: JSX.Element | JSX.Element[];
  exportToPDF?: boolean;
}

export function PagePanel(props: PropTypes) {
  return (
    <Col xs='12' lg>
      <DeprecatedPanel
        className={getPanelClasses(props.exportToPDF)}
        additionalInnerContainerClassNames='p-4'
      >
        <h2 className='mb-3'>
          {props.question.display_name || props.question.title}
        </h2>
        <div className={getInnerPanelClasses(props.exportToPDF)}>
          {props.children}
        </div>
      </DeprecatedPanel>
    </Col>
  );
}

function getPanelClasses(exportToPDF: boolean) {
  if (exportToPDF === true) {
    return styles.pdf;
  } else {
    return `h-100 ${styles.resultsPanel}`;
  }
}

function getInnerPanelClasses(exportToPDF: boolean) {
  if (exportToPDF === true) {
    return styles.pdf;
  } else {
    return styles.resultsInnerPanel;
  }
}
