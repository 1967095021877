import React from 'react';
import { CardSummary } from '..';
import { ToggleButton } from '../../../../../../../components/ToggleButton';
interface PropTypes {
  cardSummary: CardSummary;
}

export function CardSummarySectionInpuRow(props: PropTypes) {
  return (
    <div className='row align-items-center'>
      <div className='col-3'>
        <input
          type='text'
          className='form-control form-control-sm'
          id='surveyTriggerName'
          aria-label='surveyTriggerName'
          value={
            props.cardSummary.surveyTriggerName
              ? props.cardSummary.surveyTriggerName
              : ''
          }
          onChange={(e) =>
            props.cardSummary.onSurveyTriggerNameChange(e.target.value)
          }
        />
      </div>
      <div className='col-3'>
        <ToggleButton
          name='SurveyTriggerSettings'
          checked={props.cardSummary.surveyTriggerStatus}
          onChange={() => {
            props.cardSummary.onSurveyTriggerStatusChange(
              !props.cardSummary.surveyTriggerStatus,
            );
          }}
          color='info'
        />
        <span className='ms-3'>
          {props.cardSummary.surveyTriggerStatus ? 'Enabled' : 'Disabled'}
        </span>
      </div>
    </div>
  );
}
