import { ApiService } from '../../ApiService';
import { HrisEmployees } from '../../../entities/applicant_tracking/HrisEmployees';
import snakecaseKeys from 'snakecase-keys';

interface HrisEmployeesListProps {
  applicationIds: number[];
}

export class HrisEmployeesService {
  public static list(props: HrisEmployeesListProps): Promise<HrisEmployees> {
    return ApiService.get<HrisEmployees>(
      '/api/v1/applicant_tracking/hris_employees',
      snakecaseKeys(props),
    );
  }
}
