import React from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { SurveyPage } from '../SurveyPage';
import { routes } from '../../config/routes';
import { SurveyService } from '../../../services/candidate_experience/SurveyService';
import { SurveyQuestionService } from '../../../services/candidate_experience/SurveyQuestionService';

export function CandidateExperienceApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.candidateExperience.path}>
          <Route path={routes.candidateExperience.surveys.path}>
            <Route
              path={routes.candidateExperience.surveys.survey.path}
              element={
                <SurveyPage
                  getSurvey={SurveyService.get}
                  submitSurvey={SurveyService.submit}
                  submitAnswer={SurveyQuestionService.answer}
                />
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
