import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppPlugin } from '..';

export class ReactPlugin implements AppPlugin {
  public load(): void {
    document
      .querySelectorAll<HTMLElement>('[data-react-class]')
      .forEach((el) => this.installContainer(el));
  }

  private installContainer(el: HTMLElement): void {
    const componentName = el.dataset['reactClass'];
    if (!componentName) return;

    const props = JSON.parse(el.dataset['reactProps']);
    const component = this.findComponent(componentName);

    const root = createRoot(el);
    root.render(React.createElement(component, props));
  }

  private findComponent(componentName: string) {
    try {
      return require(`../../containers/${componentName}`).default; // eslint-disable-line @typescript-eslint/no-var-requires
    } catch (e) {
      if (e.code !== 'MODULE_NOT_FOUND') throw e;

      const [module, name] = componentName.split('.');
      return require(`../../components/${module}`)[name]; // eslint-disable-line @typescript-eslint/no-var-requires
    }
  }
}
