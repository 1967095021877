import React from 'react';
import { Col, Row } from 'reactstrap';
import { SurveyQuestionField } from '../../../../../../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../../../../entities/survey_engine/SurveyQuestionFieldAnswer';
import { SurveyQuestionFieldOption } from '../../../../../../../entities/survey_engine/SurveyQuestionFieldOption';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer?: SurveyQuestionFieldAnswer;
  onChangeAnswer: (
    fieldId: number,
    value: string,
    optionIds: (number | string)[],
  ) => void;
}

const toggleOptionId = (
  optionIds: (number | string)[],
  optionId: number | string,
  exclusive: boolean,
) => {
  const index = optionIds.findIndex((id) => id === optionId);
  if (index === -1) {
    if (exclusive) return [optionId];
    return optionIds.concat(optionId);
  }
  return optionIds.filter((id) => id !== optionId);
};

function isChecked(optionIds: (number | string)[], optionId: number | string) {
  return optionIds.find((optId) => optId === optionId) !== undefined;
}

function InputLine(props: {
  option: SurveyQuestionFieldOption;
  onInputChange: (option: SurveyQuestionFieldOption) => void;
  type: string;
  checked: boolean;
}) {
  return (
    <div className='form-check d-flex gap-3 align-items-center'>
      <input
        id={`checkbox-${props.option.id}`}
        className='form-check-input mt-0'
        style={{ width: '24px', height: '24px' }}
        type={props.type}
        onChange={() => props.onInputChange(props.option)}
        checked={props.checked}
        tabIndex={-1}
      />
      <label
        className='d-block form-check-label'
        htmlFor={`checkbox-${props.option.id}`}
      >
        {props.option.name}
      </label>
    </div>
  );
}

export function MultiOptionsField(props: PropTypes) {
  const answeredOptionIds: (number | string)[] =
    props.fieldAnswer?.option_ids || [];
  const hasSingleAnswer = props.field.correct_answers_count === 1;

  function handleInputChange(option: SurveyQuestionFieldOption) {
    props.onChangeAnswer(
      props.field.id,
      undefined,
      toggleOptionId(answeredOptionIds, option.id, hasSingleAnswer),
    );
  }

  return (
    <div className='row justify-content-end mt-4 gap-2'>
      {props.field.options.map((opt) => (
        <Row key={opt.id}>
          <Col xs='12'>
            <InputLine
              option={opt}
              onInputChange={handleInputChange}
              type={hasSingleAnswer ? 'radio' : 'checkbox'}
              checked={isChecked(answeredOptionIds, opt.id)}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
}
