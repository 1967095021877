import React, { useEffect } from 'react';
import { HeaderRow } from '../HeaderRow';
import { EmailTemplate } from '../../../../../entities/EmailTemplate';
import { UpdateStatePlugin } from '../../../../../components/RichTextEditor/plugins/UpdateStatePlugin';
import { InputRichTextComposer } from '../../../../../components/RichTextEditor/composers/InputRichTextComposer';
import { InputFormField } from '../../../../../components/InputFormField';
import { Email } from '../../../../../entities/Email';
import { hasData } from '../../../../../components/RichTextEditor/validation';

interface PropTypes {
  emailTemplate?: EmailTemplate;
  subjectJson?: string;
  replyToEmail?: Email;
  setIsValid: (valid: boolean) => void;
  setSubjectText: (text: string) => void;
  setSubjectJson?: (json: string) => void;
}

function DisabledSubject(props: { subject: string }) {
  return (
    <InputFormField
      hideHeader={true}
      disabled
      classNames='fs-5 fw-normal'
      type='text'
      registerReturn={null}
      value={props.subject}
    />
  );
}

export function Subject(props: PropTypes) {
  useEffect(() => {
    if (props.replyToEmail != null) {
      props.setIsValid(true);
    } else {
      props.setIsValid(hasData(props.subjectJson));
    }
  }, [props.subjectJson]);

  return (
    <HeaderRow label='Subject'>
      {props.replyToEmail != null ? (
        <DisabledSubject subject={props.replyToEmail.subject} />
      ) : (
        <InputRichTextComposer
          defaultEditorState={props.subjectJson}
          externalPlugins={[
            <UpdateStatePlugin
              key={1}
              editorState={props.emailTemplate?.subject}
              showEmptyUpdate={props.subjectJson == null}
            />,
          ]}
          placeholder='Insert subject'
          onChangeJson={props.setSubjectJson}
          onChangeText={props.setSubjectText}
        />
      )}
    </HeaderRow>
  );
}
