import React from 'react';
import { Col, Row } from 'reactstrap';
import { MetricCard } from '../../components/MetricCard';
import { ProgressBar } from '../../components/ProgressBar';
import { formatAsPercentage } from '../../../../utils/formatAsPercentage';
import {
  OverviewBestSessionsMetric,
  SessionOverview,
} from './OverviewBestSessionsMetric';
import {
  OverviewBestTopicsMetric,
  TopicOverview,
} from './OverviewBestTopicsMetric';
import { OverviewAllUsersMetric } from './OverviewAllUsersMetric';

export interface PropTypes {
  avg_accuracy: number;
  avg_completion_rate: number;
  best_sessions: SessionOverview[];
  best_topics: TopicOverview[];
  total_training_sessions: number;
  total_training_session_views: number;
}

function TrainingSessionsMetric(props: { totalTrainingSessions: number }) {
  return (
    <MetricCard title='Training Sessions' className='mb-4'>
      <div className='mt-2 fw-bold fs-3'>
        <i className='bi bi-journal-text text-blue-gray-900 me-2'></i>
        {props.totalTrainingSessions}
      </div>
    </MetricCard>
  );
}

function SessionViewsMetric(props: { totalTrainingSessionViews: number }) {
  return (
    <MetricCard
      title='Session Views'
      tooltip='The total number of sessions viewed by all users.'
      className='mb-4'
    >
      <div className='mt-2 fw-bold fs-3'>
        <i className='bi bi-eye text-blue-gray-900 me-2'></i>
        {props.totalTrainingSessionViews}
      </div>
    </MetricCard>
  );
}

function AvgAccuracyMetric(props: { avgAccuracyRate?: number }) {
  return (
    <MetricCard
      title='AVG Accuracy'
      tooltip='The average accuracy percentage for all session evaluations.'
      className='mb-4'
    >
      <div className='mt-2 fw-bold fs-3'>
        <i className='bi bi-trophy text-blue-gray-900 me-2'></i>
        {props.avgAccuracyRate == null
          ? 'N/A'
          : `${props.avgAccuracyRate.toFixed(1)}%`}
      </div>
    </MetricCard>
  );
}

function AvgCompletionRateMetric(props: { avgCompletionRate?: number }) {
  return (
    <MetricCard
      title='AVG Completion Rate'
      tooltip='The completion percentage of all assigned sessions.'
      className='mb-4'
    >
      <Row className='mt-2 align-items-center'>
        <Col xs='6'>
          <div className='fw-bold fs-3'>
            <i className='bi bi-bar-chart-line text-blue-gray-900 me-2'></i>
            {props.avgCompletionRate == null
              ? 'N/A'
              : `${formatAsPercentage(props.avgCompletionRate, 1)}%`}
          </div>
        </Col>
        <Col xs='6'>
          {props.avgCompletionRate != null && (
            <ProgressBar percentage={props.avgCompletionRate * 100} />
          )}
        </Col>
      </Row>
    </MetricCard>
  );
}

function TopRow(props: {
  totalTrainingSessions: number;
  totalTrainingSessionsViews: number;
  avgCompletionRate: number;
  avgAccuracyRate: number;
}) {
  return (
    <Row>
      <Col xs='12' lg='3'>
        <TrainingSessionsMetric
          totalTrainingSessions={props.totalTrainingSessions}
        />
      </Col>
      <Col xs='12' lg='3'>
        <SessionViewsMetric
          totalTrainingSessionViews={props.totalTrainingSessionsViews}
        />
      </Col>
      <Col xs='12' lg='3'>
        <AvgAccuracyMetric avgAccuracyRate={props.avgAccuracyRate} />
      </Col>
      <Col xs='12' lg='3'>
        <AvgCompletionRateMetric avgCompletionRate={props.avgCompletionRate} />
      </Col>
    </Row>
  );
}

export function OverviewTab(props: PropTypes) {
  return (
    <>
      <h2 className='mb-4 ms-3'>Overview</h2>
      <TopRow
        totalTrainingSessions={props.total_training_sessions}
        totalTrainingSessionsViews={props.total_training_session_views}
        avgCompletionRate={props.avg_completion_rate}
        avgAccuracyRate={props.avg_accuracy}
      />
      <Row className='mb-4'>
        <Col xs='12' lg='6'>
          <div className='mb-4'>
            <OverviewBestSessionsMetric bestSessions={props.best_sessions} />
          </div>
          <OverviewBestTopicsMetric bestTopics={props.best_topics} />
        </Col>
        <Col xs='12' lg='6'>
          <OverviewAllUsersMetric />
        </Col>
      </Row>
    </>
  );
}
