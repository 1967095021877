import React from 'react';
import { DEFAULT_FILTER_BY, FILTER_BY_OPTIONS } from '../Filters';
import { DateRange } from '../../../../components/DateRangePicker';
import moment from 'moment';
import { getArrayQueryParam, getQueryParam } from '../../../../utils/url';
import { SelectOption } from '../../../../components/Select';
import { Body } from '../Body';

// PDF export shouldn't render filters, so it is fine to add any value as the label.
function stringArrayToSelectOptions(strs: string[]): SelectOption[] {
  return strs.map((id) => ({
    label: '',
    value: id,
  }));
}

export default function PdfExportBody() {
  const filterBy =
    FILTER_BY_OPTIONS.find((v) => v['value'] == getQueryParam('filter_by')) ??
    DEFAULT_FILTER_BY;

  const filterState = {
    filterBy: filterBy,
    entries: stringArrayToSelectOptions(getArrayQueryParam('filter_by_ids[]')),
    dateRange: {
      start: moment(getQueryParam('start_date')),
      end: moment(getQueryParam('end_date')),
      range: DateRange.CustomRange,
    },
    locations: stringArrayToSelectOptions(getArrayQueryParam('location_ids[]')),
  };

  return <Body filterState={filterState} exportToPDF={true} />;
}
