import React from 'react';
import { Application } from '../../../../../entities/v1/Application';
import { humanize } from '../../../../../utils/humanize';
import { SurveyRequestStatus } from '../../../../../entities/v1/reference_check/SurveyRequest';
import { Icon } from '../../../../../components/Icon';
import classNames from 'classnames';

interface SurveyRequestStatusPropTypes {
  application: Application;
}

function surveyRequestStatus(application: Application) {
  const surveyRequest = application.surveyRequest;

  if (
    surveyRequest == null ||
    surveyRequest.status == SurveyRequestStatus.Cancelled
  ) {
    return SurveyRequestStatus.NotRequested;
  }

  return surveyRequest.status;
}

function statusBadge(status: SurveyRequestStatus) {
  if (status == null) return 'gray-300';

  switch (status) {
    case SurveyRequestStatus.Cancelled:
      return 'gray-300';
    case SurveyRequestStatus.NotSet:
      return 'danger';
    case SurveyRequestStatus.Pending:
      return 'warning';
    case SurveyRequestStatus.Completed:
      return 'success';
    default:
      return 'unknown';
  }
}

export function SurveyRequestStatusCell(props: SurveyRequestStatusPropTypes) {
  const status = surveyRequestStatus(props.application);

  return (
    <div className='text-truncate'>
      <Icon
        name='bi-circle-fill'
        className={classNames(
          'me-1 align-middle',
          `text-${statusBadge(status)}`,
        )}
        style={{ fontSize: '.5em' }}
      />
      {humanize(status)}
    </div>
  );
}
