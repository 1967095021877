import { NpsExplainabilityEntry } from '../../../../../entities/candidate_experience/NpsExplainabilityEntry';

function getXLimits(entries: NpsExplainabilityEntry[]) {
  return {
    xMax: Math.max(
      ...entries.map((o) => {
        return o.count;
      }),
    ),
    xMin: Math.min(
      ...entries.map((o) => {
        return o.count;
      }),
    ),
  };
}

export function getLimitsInfo(entries: NpsExplainabilityEntry[]) {
  const axisLimits = getXLimits(entries);
  return {
    axisLimits: axisLimits,
    xRange: Math.max(1, axisLimits.xMax - axisLimits.xMin),
  };
}

export function updateNpsExplainabilityBubbleChartOptionAxis(
  axisInfo,
  options,
) {
  options.scales.x.min = Math.floor(
    axisInfo.axisLimits.xMin - axisInfo.xRange / 10,
  );
  options.scales.x.max = Math.ceil(
    axisInfo.axisLimits.xMax + axisInfo.xRange / 10,
  );
}
