import React from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Evaluation } from '../../../entities/training/Evaluation';
import { EvaluationRoutes } from './EvaluationRoutes';

interface PropTypes {
  evaluation: Evaluation;
}

export default function EvaluationPage(props: PropTypes) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='training'>
          <Route path='sessions'>
            <Route path=':session_id'>
              <Route path='evaluations'>
                <Route
                  path=':id/*'
                  element={<EvaluationRoutes evaluation={props.evaluation} />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
