import React from 'react';
import styles from './styles.module.scss';

interface Proptypes {
  value: string;
  label: string;
  icon: string;
}

export function SubMetric(props: Proptypes) {
  return (
    <div className={styles.subMetric}>
      <div className='d-flex align-items-center'>
        <i className={`bi ${props.icon} icon`} />
        <span className='display1'>{props.value}</span>
      </div>
      <p className='mt-1 mb-0'>{props.label}</p>
    </div>
  );
}
