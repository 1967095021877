import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry, {
  DefaultFormValues,
} from 'react-phone-number-input/react-hook-form';
import { Col, Row } from 'reactstrap';
import isEmail from 'validator/lib/isEmail';
import { SurveyInfoSubmission } from '..';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';
import { InputFormField } from '../../../../components/InputFormField';
import { MonthSelect } from '../../../../components/MonthSelect';
import { YearSelect } from '../../../../components/YearSelect';
import { humanize } from '../../../../utils/humanize';
import { SurveyInfo } from '../../../entities/SurveyInfo';
import { SelectedReference } from '../SelectedReference';
import styles from './styles.module.scss';

interface PropTypes {
  reference: SelectedReference;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (v: SurveyInfo) => void;
}

const isValidPhoneNumber = (phoneNumber?: string): boolean => {
  if (phoneNumber == null || phoneNumber === '') return true;

  return isPossiblePhoneNumber(phoneNumber);
};

export function ReferenceFormModal({
  reference,
  isOpen,
  onClose,
  onSubmit,
}: PropTypes) {
  const survey = reference.referral.surveys[0];

  const collabStart =
    survey?.candidate_collaboration_started_on &&
    moment(survey.candidate_collaboration_started_on);
  const collabEnd =
    survey?.candidate_collaboration_ended_on &&
    moment(survey.candidate_collaboration_ended_on);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<SurveyInfoSubmission>({
    mode: 'onBlur',
    defaultValues: {
      first_name: survey?.first_name,
      last_name: survey?.last_name,
      email: survey?.email,
      mobile_number: survey?.mobile_number,
      company: survey?.company,
      job_title: survey?.job_title,
      note: survey?.note,
      candidate_job_title: survey?.candidate_job_title,
      candidate_collaboration_started_on_month: collabStart?.month(),
      candidate_collaboration_started_on_year: collabStart?.year(),
      candidate_collaboration_ended_on_month: collabEnd?.month(),
      candidate_collaboration_ended_on_year: collabEnd?.year(),
    },
  });

  const watchCollabStart = watch([
    'candidate_collaboration_started_on_month',
    'candidate_collaboration_started_on_year',
  ]);
  const watchCollabEnd = watch([
    'candidate_collaboration_ended_on_month',
    'candidate_collaboration_ended_on_year',
  ]);
  const collabEndDisabled =
    watchCollabStart[0] == null ||
    watchCollabStart[0].toString() == '' ||
    watchCollabStart[1] == null ||
    watchCollabStart[1].toString() == '';

  return (
    <ConfirmationModal
      title={`Reference ${reference.index + 1}, ${humanize(
        reference.referral.relationship,
      )}`}
      bodyClassName='px-4 pt-4'
      isOpen={isOpen}
      onConfirm={handleSubmit(onSubmit)}
      onCancel={onClose}
      size='lg'
      confirmText='Save'
      body={
        <form id='NewSurveyForm'>
          <h4 className='fw-bold mb-3'>About the reference</h4>
          <Row className='mb-3'>
            <Col>
              <InputFormField
                type='text'
                registerReturn={register('first_name', { required: true })}
                fieldName='First Name'
                placeholder='First name'
                isRequired={true}
                classNames={`${errors.first_name && 'is-invalid'}`}
              />
              {errors.first_name && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a first name
                </div>
              )}
            </Col>
            <Col>
              <InputFormField
                type='text'
                registerReturn={register('last_name', { required: true })}
                fieldName='Last Name'
                placeholder='Last name'
                isRequired={true}
                classNames={`${errors.last_name && 'is-invalid'}`}
              />
              {errors.last_name && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a last name
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs='6'>
              <InputFormField
                type='email'
                registerReturn={register('email', {
                  required: true,
                  validate: isEmail,
                })}
                fieldName='Email'
                placeholder='email@domain.com'
                isRequired={true}
                classNames={`${errors.email && 'is-invalid'}`}
              />
              {errors.email && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a valid email
                </div>
              )}
            </Col>
            <Col xs='6'>
              <div>
                <FormFieldHeader fieldName='Phone' isRequired={false} />
                <PhoneInputWithCountry
                  name='mobile_number'
                  className={classNames(
                    'form-control',
                    styles.PhoneInputInput,
                    {
                      'is-invalid': errors.mobile_number,
                    },
                  )}
                  // The `control` prop wants a generic Record while we want a
                  // specialization of a Record. So, this cast is safe.
                  control={
                    control as unknown as Control<DefaultFormValues, any>
                  }
                  international={true}
                  rules={{ validate: isValidPhoneNumber }}
                />
                {errors.mobile_number && (
                  <div
                    className='invalid-feedback'
                    style={{ display: 'block' }}
                  >
                    Please provide a valid phone number
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <InputFormField
                type='text'
                registerReturn={register('company', { required: true })}
                fieldName='Company'
                placeholder='Where you worked together'
                isRequired={true}
                classNames={`${errors.company && 'is-invalid'}`}
              />
              {errors.company && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a company name
                </div>
              )}
            </Col>
            <Col>
              <InputFormField
                type='text'
                registerReturn={register('job_title', { required: true })}
                fieldName='Job Title'
                placeholder='Their job title'
                isRequired={true}
                classNames={`${errors.job_title && 'is-invalid'}`}
              />
              {errors.job_title && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a job title
                </div>
              )}
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <FormFieldHeader
                fieldName='Why is this a good reference?'
                isRequired={true}
              />
              <textarea
                className={`form-control ${errors.note && 'is-invalid'}`}
                placeholder='Describe your reference'
                rows={4}
                {...register('note', { required: true })}
              />
              {errors.note && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please describe this reference
                </div>
              )}
            </Col>
          </Row>
          <h4 className='fw-bold my-3'>About you</h4>
          <Row className='mb-3'>
            <Col>
              <InputFormField
                type='text'
                registerReturn={register('candidate_job_title', {
                  required: true,
                })}
                fieldName='What was your job title?'
                placeholder='Your job title when you worked together'
                isRequired={true}
                classNames={`${errors.candidate_job_title && 'is-invalid'}`}
              />
              {errors.candidate_job_title && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide your previous job title
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <FormFieldHeader
              fieldName='When did you work together?'
              isRequired={true}
            />
          </Row>
          <Row>
            <Col>
              <small className='fw-semibold'>From</small>
              <div className='d-flex'>
                <Controller
                  name='candidate_collaboration_started_on_year'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <YearSelect
                      className={classNames('me-2', 'w-50', {
                        'is-invalid':
                          errors.candidate_collaboration_started_on_year,
                      })}
                      name={field.name}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
                <Controller
                  name='candidate_collaboration_started_on_month'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MonthSelect
                      className={classNames('w-50', {
                        'is-invalid':
                          errors.candidate_collaboration_started_on_month,
                      })}
                      max={
                        Number(watchCollabStart[1]) === new Date().getFullYear()
                          ? new Date().getMonth()
                          : undefined
                      }
                      name={field.name}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </div>
              {errors.candidate_collaboration_started_on_year && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a start year
                </div>
              )}
              {errors.candidate_collaboration_started_on_month && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide a start month
                </div>
              )}
            </Col>
            <Col>
              <small className='fw-semibold'>To</small>
              <div className='d-flex'>
                <Controller
                  name='candidate_collaboration_ended_on_year'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <YearSelect
                      className={classNames('me-2', 'w-50', {
                        'is-invalid':
                          errors.candidate_collaboration_ended_on_year,
                      })}
                      disabled={collabEndDisabled}
                      min={watchCollabStart[1]}
                      name={field.name}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
                <Controller
                  name='candidate_collaboration_ended_on_month'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MonthSelect
                      className={classNames('w-50', {
                        'is-invalid':
                          errors.candidate_collaboration_ended_on_month,
                      })}
                      disabled={collabEndDisabled}
                      min={
                        watchCollabStart[1] === watchCollabEnd[1]
                          ? Number(watchCollabStart[0])
                          : undefined
                      }
                      max={
                        Number(watchCollabEnd[1]) === new Date().getFullYear()
                          ? new Date().getMonth()
                          : undefined
                      }
                      name={field.name}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              </div>
              {errors.candidate_collaboration_ended_on_year &&
                errors.candidate_collaboration_ended_on_year.type ===
                  'required' && (
                  <div
                    className='invalid-feedback'
                    style={{ display: 'block' }}
                  >
                    Please provide an end year
                  </div>
                )}
              {errors.candidate_collaboration_ended_on_month && (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                  Please provide an end month
                </div>
              )}
            </Col>
          </Row>
        </form>
      }
    />
  );
}
