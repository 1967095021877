import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Col } from 'reactstrap';
import { AnalyticsPanel } from '../../../../../../components/AnalyticsPanel';
import { EmptyState } from '../../../../../../components/EmptyState';
import { QuestionTopChoices } from '../../../../../../entities/candidate_experience/QuestionTopChoices';

interface PropTypes {
  recruitmentImprovements: QuestionTopChoices;
  title: string;
  children: ReactElement;
}

export function TopChoicesOrEmptyState(props: PropTypes) {
  return (
    <>
      <Col sm='12' lg='6'>
        {props.recruitmentImprovements.top_choices.length > 0 ? (
          props.children
        ) : (
          <AnalyticsPanel
            title={
              <div className={classNames('mb-0', 'fw-bold')}>{props.title}</div>
            }
            subtitle={
              <small className='text-muted'>
                {props.recruitmentImprovements.question_text}
              </small>
            }
          >
            <EmptyState
              title='No data available yet'
              text={
                <p>No replies were submitted by this group of respondents.</p>
              }
              excludeImage={true}
            />
          </AnalyticsPanel>
        )}
      </Col>
    </>
  );
}
