import React from 'react';
import { SurveyLayout } from '../../../../components/survey_engine/SurveyLayout';
import { SurveyPanel } from '../../../../components/survey_engine/SurveyPanel';
import classNames from 'classnames';
import { Survey } from '../../../../entities/survey_engine/Survey';

interface PropTypes {
  survey: Survey;
  text: string;
  finishScreenImage?: string;
  headingStyle?: React.CSSProperties;
}

export function SubmittedAnswersScreen(props: PropTypes) {
  return (
    <SurveyLayout companyInfo={props.survey.company_info}>
      <SurveyPanel className='text-center'>
        <img className='mt-5 w-25' src={props.finishScreenImage} />
        <h1 className='mt-4' style={props.headingStyle}>
          Done. Thank you for your help!
        </h1>
        <p className={classNames('mt-1 mb-4 mx-auto')}>{props.text}</p>
      </SurveyPanel>
    </SurveyLayout>
  );
}
