import React from 'react';
import { InterviewQuestion } from '../../../../../../entities/InterviewQuestion';

interface PropTypes {
  interviewQuestion: InterviewQuestion;
  onInterviewQuestionsUpdated: (question: InterviewQuestion) => void;
}

export function InterviewQuestionPart(props: PropTypes) {
  return (
    <div className='d-flex fs-5'>
      <input
        defaultChecked={props.interviewQuestion.selected}
        disabled={props.interviewQuestion.selected}
        className='border-info form-check-input me-2h'
        type='checkbox'
        value={props.interviewQuestion.id}
        id={props.interviewQuestion.id}
        key={props.interviewQuestion.id}
        onChange={(_e) =>
          props.onInterviewQuestionsUpdated(props.interviewQuestion)
        }
      />
      <label className='form-check-label' htmlFor={props.interviewQuestion.id}>
        {props.interviewQuestion.question}
      </label>
    </div>
  );
}
