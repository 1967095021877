import React, { useContext, useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { RadioInputFormField } from '../../../../../components/RadioInputFormField';
import { SelectFormHook } from '../../../../../components/SelectFormHook';
import { UpsertPlanTemplateDto } from '../../DTOs/UpsertPlanTemplateDto';
import { Control } from 'react-hook-form';
import { SelectOption } from '../../../../../components/Select';
import { PlanTemplate } from '../../../../../entities/v1/applicant_tracking/PlanTemplate';
import { JobWorkflowEditContext } from '../..';
import { PlanTemplateService } from '../../../../../services/v1/applicant_tracking/PlanTemplateService';

interface PropTypes {
  control: Control<UpsertPlanTemplateDto, any>;
  resetField: (name: string) => void;
}

function mapPlanTemplatesToOptions(
  planTemplates: PlanTemplate[],
): SelectOption[] {
  return planTemplates
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((planTemplate: PlanTemplate) => {
      return {
        value: planTemplate.id.toString(),
        label: planTemplate.name,
      };
    });
}

export function TemplateTypeInput(props: PropTypes) {
  const pageContext = useContext(JobWorkflowEditContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [planTemplates, setPlanTemplates] = useState<PlanTemplate[]>([]);

  const loadPlanTemplates = async () => {
    setIsLoading(true);
    PlanTemplateService.list({
      publishState: 'published',
    })
      .then((list) => setPlanTemplates(list.planTemplates))
      .catch((e) => console.error('Could not load plan templates', e))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    pageContext.templateType === 'start_from_scratch' &&
      props.resetField('planTemplateReferenceId');
    pageContext.templateType === 'template' && loadPlanTemplates();
  }, [pageContext.templateType]);

  return (
    <>
      <Col xs='auto' className='pe-0'>
        <RadioInputFormField
          options={[
            {
              value: 'start_from_scratch',
              label: 'Start from scratch',
            },
            {
              value: 'template',
              label: 'Based on template',
            },
          ]}
          selectedValue={pageContext.templateType}
          onSelectInput={pageContext.setTemplateType}
        />
      </Col>
      <Col xs={3}>
        <SelectFormHook
          clearable={false}
          disabled={
            pageContext.templateType === 'start_from_scratch' || isLoading
          }
          placeholder={'Select template'}
          options={mapPlanTemplatesToOptions(planTemplates)}
          formHookContext={{
            controllerName: 'planTemplateReferenceId',
            formControl: props.control,
            required: false,
          }}
          isLoading={isLoading}
        />
      </Col>
    </>
  );
}
