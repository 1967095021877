import React, { useState, useEffect } from 'react';
import { BackgroundCheck } from '../../../entities/BackgroundCheck';
import { Application } from '../../../entities/Application';
import { BackgroundCheckEmptyState } from './BackgroundCheckEmptyState';
import { RequestBackgroundCheckButton } from './RequestBackgroundCheckButton';
import { BackgroundCheckRequestModal } from './BackgroundCheckRequestModal';
import { BackgroundCheckCard } from './BackgroundCheckCard';
import { BackgroundCheckService } from '../../../services/BackgroundCheckService';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Alert, AlertType } from '../../../components/Alert';
import { CandidatePageSubHeader } from '../CandidatePageSubHeader';

interface PropTypes {
  application: Application;
  applicantTrackingEnabled: boolean;
  integrationEnabled: boolean;
}

function BackgrounCheckList(props: { backgroundChecks: BackgroundCheck[] }) {
  return (
    <>
      {props.backgroundChecks?.map((backgroundCheck, i) => (
        <BackgroundCheckCard
          key={i}
          data-testid='backgroundCheck-card'
          backgroundCheck={backgroundCheck}
        />
      ))}
    </>
  );
}

export function BackgroundChecksTab(props: PropTypes) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [backgroundChecks, setBackgroundChecks] = useState<BackgroundCheck[]>(
    [],
  );
  const [created, setCreated] = useState<boolean>(false);

  useEffect(() => {
    fetchBackgroundChecks();
  }, []);

  const fetchBackgroundChecks = async () => {
    try {
      const bgcs = await BackgroundCheckService.list(props.application.id);
      setBackgroundChecks(bgcs);
    } catch (e: any) {
      if (
        props.applicantTrackingEnabled &&
        !props.application.hasPlatformApplication
      ) {
        setBackgroundChecks([]);
      } else {
        throw e;
      }
    }
    setLoading(false);
  };

  const canRequestBackgroundCheck =
    props.integrationEnabled &&
    (!props.applicantTrackingEnabled ||
      props.application.hasPlatformApplication);

  return (
    <>
      {created && (
        <Alert
          type={AlertType.Success}
          clearable={true}
          autoClearTimeout={4000}
          onClose={() => setCreated(false)}
        >
          Background check request was successfully sent to{' '}
          {props.application.candidate.name}
        </Alert>
      )}
      <BackgroundCheckRequestModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        application={props.application}
        onBackgroundCheckRequested={(bgc) => {
          setCreated(true);
          setBackgroundChecks([...bgc, ...backgroundChecks]);
        }}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <CandidatePageSubHeader text='Background Checks' />
            <RequestBackgroundCheckButton
              color='secondary'
              onClick={() => setIsModalOpen(true)}
              disabled={!canRequestBackgroundCheck}
            />
          </div>
          <BackgrounCheckList backgroundChecks={backgroundChecks} />
          {(backgroundChecks == null || backgroundChecks.length === 0) && (
            <BackgroundCheckEmptyState
              integrationEnabled={props.integrationEnabled}
              canRequestBackgroundCheck={canRequestBackgroundCheck}
              onRequestClick={() => setIsModalOpen(true)}
            />
          )}
        </>
      )}
    </>
  );
}
