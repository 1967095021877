import React from 'react';
import { camelCase } from 'lodash';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { SingleAttachmentInputFormField } from '../../../../../../../../components/SingleAttachmentInputFormField';
import { SurveyQuestion } from '../../../../../../../../entities/survey_engine/SurveyQuestion';

interface PropTypes {
  question: SurveyQuestion;
  formRegister: UseFormRegister<any>;
  getValues?: UseFormGetValues<any>;
  setValue?: UseFormSetValue<any>;
  disabled?: boolean;
}

export function AttachmentQuestionInput(props: PropTypes) {
  const disabled = props.disabled ?? false;

  return (
    <SingleAttachmentInputFormField<any>
      inputBodyClassNames='p-0'
      getValues={props.getValues}
      setValue={props.setValue}
      formRegister={props.formRegister}
      controllerFileName={`${camelCase(props.question.tag)}Filename`}
      controllerContentName={`${camelCase(props.question.tag)}Content`}
      fieldName={''}
      allowedFormats={['.pdf', '.doc', '.docx']}
      maxFileSizeMb={10}
      isRequired={props.question.mandatory}
      hideHeader={true}
      disabled={disabled}
    />
  );
}
