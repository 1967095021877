import React from 'react';
import { ListCandidate } from '../../../../../../../../entities/applicant_tracking/ListCandidate';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';

interface PropTypes {
  candidate: ListCandidate;
}

export function Jobs(props: PropTypes) {
  if (
    props.candidate.application == null ||
    props.candidate.application.job == null
  )
    return (
      <div className='d-flex justify-content-center'>
        <span>-</span>
      </div>
    );

  return (
    <div className='d-flex align-items-center fs-5'>
      <div className='text-truncate'>
        {props.candidate.application.job.name}
      </div>
      {props.candidate.totalApplications > 1 && (
        <Chip
          className='ms-2 py-1 px-2h fw-semibold'
          color={ChipColor.BlueLight}
        >
          +{props.candidate.totalApplications - 1}
        </Chip>
      )}
    </div>
  );
}
