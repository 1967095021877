import React from 'react';
import { Button } from 'reactstrap';
import { StyledModal } from '../../../../components/StyledModal';

interface PropTypes {
  title: string;
  content: string;
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}

export function DisconnectModal(props: PropTypes) {
  return (
    <StyledModal
      title={props.title}
      isOpen={props.isOpen}
      onCancel={props.onCancel}
      size={'md'}
      bodyChildren={
        <>
          <p>{props.content}</p>
          <div className='d-flex gap-2 justify-content-end'>
            <Button color='danger' onClick={props.onCancel}>
              Cancel
            </Button>
            <Button color='primary' onClick={props.onConfirm}>
              Disconnect
            </Button>
          </div>
        </>
      }
    />
  );
}
