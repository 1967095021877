import React from 'react';
import { InterviewStageSection } from '../../../entities/applicant_tracking/InterviewStage';
import classNames from 'classnames';
import { EmptyState } from '../../EmptyState';
import { InterviewQuestionsBody } from './InterviewQuestionsBody';

interface PropTypes {
  sections: InterviewStageSection[];
  recruiterName?: string;
}

function InterviewKitEmptyState(props: { recruiterName?: string }) {
  return (
    <EmptyState
      classNames={classNames(
        'col-9',
        'd-grid',
        'align-content-center',
        'mx-4 mt-8 pb-8',
      )}
      cardless={true}
      imageClassNames='mx-auto'
      title='No Interview Questions Assigned'
      text={
        <span className='fw-normal'>
          It looks like there are no interview questions assigned to this
          interview yet. Please check back later or contact{' '}
          {props.recruiterName || 'the recruiter'}.
        </span>
      }
    />
  );
}

function SectionBody(props: { section: InterviewStageSection }) {
  return (
    <>
      <div className='my-4 fs-3 fw-semibold'>{props.section.name}</div>
      <InterviewQuestionsBody questions={props.section.questions} />
    </>
  );
}

export function InterviewKitPanel(props: PropTypes) {
  return (
    <>
      {props.sections.length > 0 ? (
        <>
          {props.sections.map((section) => (
            <SectionBody key={section.id} section={section} />
          ))}
        </>
      ) : (
        <InterviewKitEmptyState recruiterName={props.recruiterName} />
      )}
    </>
  );
}
