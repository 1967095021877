import React from 'react';
import { SurveyRoute } from '../../../containers/survey_engine/SurveyPage/SurveyRoute';
import { Survey } from '../../../entities/survey_engine/Survey';
import { InterviewerSurveyScreen } from './SurveyScreen/InterviewerSurveyScreen';

interface PropTypes {
  survey: Survey;
  onFinishSurvey: () => void;
}

export function InterviewerRoute(props: PropTypes) {
  return (
    <SurveyRoute survey={props.survey} status='pending' mismatchPath='..'>
      <InterviewerSurveyScreen
        survey={props.survey}
        onFinishSurvey={props.onFinishSurvey}
      />
    </SurveyRoute>
  );
}
