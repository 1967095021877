import snakecaseKeys from 'snakecase-keys';
import { ActivityTabFilter } from '../../../containers/CandidatePage/ActivityTab/CandidateProcessActivityPanel/ActivityTabFilters';
import { ApplicationFeed } from '../../../entities/ApplicationFeed';
import { ApiService } from '../../ApiService';

export interface ApplicationFeedList {
  currentPage: number;
  nextPage: number;
  totalPages: number;
  totalCount: number;
  data: ApplicationFeed[];
}

export class ApplicationFeedService {
  public static list(
    applicationId: string,
    page: number,
    filters: ActivityTabFilter,
  ): Promise<ApplicationFeedList> {
    return ApiService.get<ApplicationFeedList>(
      `/api/applicant_tracking/application_feeds`,
      {
        application_id: applicationId,
        page,
        ...snakecaseKeys({
          startDate: filters?.dateFilter.start.toJSON(),
          endDate: filters?.dateFilter.end.toJSON(),
          dateRange: filters?.dateFilter.range,
          activityTypes: filters?.activityFilter.map((f) => f.value),
        }),
      },
    );
  }
}
