import React, { useCallback, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { Interview } from '../../../entities/Interview';
import { InterviewInsightsTab } from '../InterviewInsightsTab';
import { InterviewMarker } from '../../../entities/InterviewMarker';
import { InterviewTimelineTab } from '../InterviewTimelineTab';
import { InterviewParticipant } from '../../../entities/InterviewParticipant';
import { queryClient } from '../../../config/queryClient';
import { applyLargestRemainderMethod } from '../../../utils/largestRemainderMethod';
import { ActionPoint } from '../../../entities/ActionPoint';
import { InterviewDetailTab } from '../Entities/InterviewDetailTab';
import { InterviewClipsTab } from '../InterviewClipsTab';
import { InterviewClip } from '../../../entities/InterviewClip';
import { DefaultTabLayout } from './DefaultTabLayout';
import { HighlightType } from '../Entities/HighlightType';
import { DeprecatedPanel } from '../../../components/DeprecatedPanel';
import { NotePanel } from '../../../components/Interview/NotePanel';
import { AINotesSubTab } from '../../../entities/AINotesSubTab';
import { InterviewAssistantTab } from '../InterviewAssistantTab';
import { Summary } from '../../../entities/Summary';
import { InterviewAINotesTab } from '../InterviewAINotesTab';

const DEFAULT_CLIP_LENGTH_SECONDS = 60 * 1000;
interface PropTypes {
  interview: Interview;
  atsEnabled: boolean;
  interviewAiAssistantEnabled: boolean;
  customSummaryEnabled: boolean;
}

const aiInitResponses = [
  {
    aiAssistant: true,
    text: `Hey, I'm Screenloop's AI Assistant.`,
    auto: true,
  },
  {
    aiAssistant: true,
    text: 'I can help you find information and answer any questions about this interview.',
    auto: true,
  },
  { aiAssistant: true, text: 'Where shall we get started?', auto: true },
];

function updateParticipantsTalkRatio(participants: InterviewParticipant[]) {
  const talkRatio = participants.map(
    (participant) => participant.insights.talk_ratio,
  );
  const updatedTalkRatio = applyLargestRemainderMethod(talkRatio, 100);

  participants.forEach((participant, i) => {
    participant.insights.talk_ratio = updatedTalkRatio[i];
  });
}

export default function InterviewDetail(props: PropTypes) {
  const [activeTab, setActiveTab] = useState(InterviewDetailTab.AINotes);
  const [interview, setInterview] = useState<Interview>(props.interview);

  const [startTime, setStartTime] = useState<number>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [playTimestamp, setPlayTimestamp] = useState<number>(0);
  const [clipRange, setClipRange] = useState<number[]>([
    playTimestamp,
    Math.min(
      playTimestamp + DEFAULT_CLIP_LENGTH_SECONDS,
      props.interview.media.duration,
    ),
  ]);
  const [interviewClipFormVisible, setInterviewClipFormVisible] =
    useState<boolean>(false);
  const [selectedHighlightType, setSelectedHightlightType] = useState(
    'all' as HighlightType,
  );
  const [selectedAINotesSubTab, setSelectedAINotesSubTab] = useState(
    'summary' as AINotesSubTab,
  );

  const [responses, setResponses] = useState(aiInitResponses);
  const [hasServerResponses, setHasServerResponses] = useState(false);

  const pickTime = useCallback((time: number) => {
    setStartTime(undefined);
    setImmediate(() => {
      setStartTime(time);
      setIsPlaying(true);
    });
  }, []);

  function handleActionPoints(actionPoints: ActionPoint[]) {
    setInterview({
      ...interview,
      actionPoints: actionPoints,
    });
  }

  function handleInterviewClips(clips: InterviewClip[]) {
    setInterview({
      ...interview,
      interviewClips: clips,
    });
  }

  function handleCreateInterviewClip() {
    setActiveTab(InterviewDetailTab.Clips);
    setClipRange([
      playTimestamp,
      Math.min(
        playTimestamp + DEFAULT_CLIP_LENGTH_SECONDS,
        props.interview.media.duration,
      ),
    ]);
    setInterviewClipFormVisible(true);
  }

  const updateInterviewMarkers = useCallback(
    (interview_markers: InterviewMarker[]) => {
      setInterview({ ...interview, interview_markers: interview_markers });
    },
    [props.interview],
  );

  function handleSummaryChange(summary: Summary) {
    setInterview({
      ...interview,
      summary: summary,
    });
  }

  updateParticipantsTalkRatio(props.interview.interview_participants);

  return (
    <QueryClientProvider client={queryClient}>
      <DefaultTabLayout
        activeTab={activeTab}
        clipRange={clipRange}
        interview={interview}
        interviewClipFormVisible={interviewClipFormVisible}
        isPlaying={isPlaying}
        selectedHighlightType={selectedHighlightType}
        startTime={startTime}
        pickTime={pickTime}
        playTimestamp={playTimestamp}
        setActiveTab={setActiveTab}
        onInterviewClipRangeChanged={setClipRange}
        onCreateInterviewClip={handleCreateInterviewClip}
        setInterviewClipFormVisible={setInterviewClipFormVisible}
        setInterview={setInterview}
        setIsPlaying={setIsPlaying}
        setSelectedHighlightType={setSelectedHightlightType}
        setStartTime={setStartTime}
        setPlayTimestamp={setPlayTimestamp}
        extraComponent={
          activeTab === InterviewDetailTab.Timeline &&
          (selectedHighlightType == 'all' ||
            selectedHighlightType == 'note') && (
            <DeprecatedPanel
              className='mt-4'
              additionalInnerContainerClassNames='p-4'
            >
              <NotePanel
                playTimestamp={playTimestamp}
                interview={interview}
                onSelectTimestamp={pickTime}
                onUpdateInterviewMarkers={updateInterviewMarkers}
              />
            </DeprecatedPanel>
          )
        }
        interviewAiAssistantEnabled={props.interviewAiAssistantEnabled}
      >
        {activeTab === InterviewDetailTab.Timeline ? (
          <InterviewTimelineTab
            interview={interview}
            playTimestamp={playTimestamp}
            menuType={'reply'}
            selectedHighlightType={selectedHighlightType}
            onSelectTimestamp={pickTime}
            onUpdateInterviewMarkers={updateInterviewMarkers}
            setSelectedHighlightType={setSelectedHightlightType}
          />
        ) : activeTab === InterviewDetailTab.Analytics ? (
          <InterviewInsightsTab
            interviewId={interview.id}
            interviewStartDate={interview.start_date}
            currentUserId={interview.current_user.id}
          />
        ) : activeTab === InterviewDetailTab.AINotes ? (
          <InterviewAINotesTab
            interview={interview}
            onUpdateActionPoints={handleActionPoints}
            onSelectTimestamp={pickTime}
            atsEnabled={props.atsEnabled}
            customSummaryEnabled={props.customSummaryEnabled}
            onSummaryChange={handleSummaryChange}
            selectedAINotesSubTab={selectedAINotesSubTab}
            setSelectedAINotesSubTab={setSelectedAINotesSubTab}
            playTimestamp={playTimestamp}
          />
        ) : activeTab === InterviewDetailTab.Clips ? (
          <InterviewClipsTab
            formVisible={interviewClipFormVisible}
            clipRange={clipRange}
            onFormVisibilityChanged={(visible: boolean) =>
              setInterviewClipFormVisible(visible)
            }
            onInterviewClipsUpdate={handleInterviewClips}
            onSelectClip={(clip) => pickTime(clip.startOffsetMs)}
            onClipRangeChanged={(range) => setClipRange(range)}
            interview={interview}
          />
        ) : activeTab === InterviewDetailTab.Assistant ? (
          <InterviewAssistantTab
            interview={props.interview}
            responses={responses}
            onResponses={setResponses}
            hasServerResponses={hasServerResponses}
            onHasServerResponses={setHasServerResponses}
          />
        ) : null}
      </DefaultTabLayout>
    </QueryClientProvider>
  );
}
