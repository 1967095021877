import snakecaseKeys from 'snakecase-keys';
import { OrganicPostSkillList } from '../../../../../entities/v1/applicant_tracking/OrganicPostSkill';
import { ApiService } from '../../../../ApiService';

export interface ListParams {
  name?: string;
  page?: number;
  selectedIds?: string[];
  pageSize?: number;
}

export class OrganicPostSkillsFilterService {
  public static list(params: ListParams) {
    return ApiService.get<OrganicPostSkillList>(
      '/api/v1/applicant_tracking/filters/organic_post_skills',
      snakecaseKeys(params),
    );
  }
}
