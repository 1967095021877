import classNames from 'classnames';
import React from 'react';
import { InputFormField } from '../../../../../components/InputFormField';
import { PasswordRequirementsList } from '../../../PasswordRequirementsList';
import { UseFormRegister } from 'react-hook-form';
import { FormValues } from '../../../UserInvitationPage';

interface PropTypes {
  title: string;
  register: UseFormRegister<FormValues>;
  hasPasswordValidationFailed: boolean;
  hasMinChars?: boolean;
  field: keyof FormValues;
  placeholder: string;
  hasPasswordRequirementsList?: boolean;
  colClassName?: string;
}

export function InputCol(props: PropTypes) {
  return (
    <>
      {props.hasMinChars ? (
        <div className={classNames('col-6 d-flex', props.colClassName)}>
          <div className='text-primary fs-5'>{props.title}</div>
          <div className='text-dark-200 fs-6 ms-3'>{'(8 chars min.)'}</div>
        </div>
      ) : (
        <div className='col-6 text-primary fs-5'>{props.title}</div>
      )}
      <div className='col-6'>
        <InputFormField
          type={'password'}
          registerReturn={props.register(props.field, {
            required: true,
          })}
          placeholder={props.placeholder}
          invalidInput={props.hasPasswordValidationFailed}
        />
        {props.hasPasswordRequirementsList && (
          <div className='mt-2'>
            <PasswordRequirementsList />
          </div>
        )}
      </div>
    </>
  );
}
