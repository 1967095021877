import React from 'react';
import classNames from 'classnames';
import styles from '../../styles.module.scss';
import { InfoTooltip } from '../../../../../components/InfoTooltip';

interface PropTypes {
  title: string;
  constraint?: string;
  mandatory?: boolean;
}

export function FormHeader(props: PropTypes) {
  return (
    <div className='d-flex fw-semibold fs-5 mt-4 mb-2 align-items-center'>
      <div
        className={classNames(
          styles['sources-container'],
          'me-4 d-flex justify-content-between align-items-center',
        )}
      >
        <div>
          {props.title}{' '}
          {props.mandatory && <span className='text-danger'>*</span>}
        </div>
        <div className='fs-6 text-dark-200 fw-normal'>{props.constraint}</div>
      </div>
      <InfoTooltip
        tooltipInnerClassName={classNames(styles['visibility-tooltip'])}
      >
        <>
          When set to public, the Source is visible to candidates in the
          Application Form. <br></br>
          If set to private, the Source is only available when creating
          candidates in Screenloop.
        </>
      </InfoTooltip>{' '}
      <span className='ms-2'>Visibility</span>
    </div>
  );
}
