import React from 'react';
import { EmptyState } from '../../../../../components/EmptyState';
import { BackToCareersPageButton } from '../BackToCareersPageButton';
import OlderInterviewWoman from '../../../../../images/older_interview_woman_image.png';

export default function SuccessPage() {
  return (
    <EmptyState
      title={'Your application was not submitted'}
      text={'Your application was not successful. Please try again.'}
      cardless
      imageSrc={OlderInterviewWoman}
      actionButton={
        <BackToCareersPageButton
          color={'primary'}
          size={'md'}
          className={'mt-4'}
          onClick={() => (window.location.href = '../../..')}
        />
      }
    />
  );
}
