import React, { useCallback, useState } from 'react';
import styles from './styles.module.scss';
import { Interview } from '../../../entities/Interview';
import { NoteBlock } from '../NoteBlock';
import { NoteService } from '../../../services/NoteService';
import { InterviewService } from '../../../services/InterviewService';
import { InterviewMarkerService } from '../../../services/InterviewMarkerService';
import { ThreadData } from '../../../entities/ThreadData';
import { MenuType } from '../MenuType';
import { AutomaticNotesBlock } from './AutomaticNotesBlock';
import {
  AutomaticQuestion,
  AutomaticQuestionList,
} from '../../../entities/v1/interview_intelligence/AutomaticQuestion';
import { InterviewMarker } from '../../../entities/InterviewMarker';
import { QueryObserverResult } from 'react-query';
import { CommentsListRow } from './CommentsListRow';
import { AddReplyBlock } from './AddReplyBlock';
import { RepliesBlock } from './RepliesBlock';
import { ConfirmationModal } from '../../ConfirmationModal';

export interface PropTypes {
  playTimestamp?: number;
  interview: Interview;
  automaticQuestions?: AutomaticQuestion[];
  threadData: ThreadData;
  showIcon: boolean;
  menuType?: MenuType;
  refetchAutomaticQuestions: (
    options?: unknown,
  ) => Promise<QueryObserverResult<AutomaticQuestionList, unknown>>;
  onSelectTimestamp: (timestamp: number) => void;
  onUpdateInterviewMarkers?: (InterviewMarkers: InterviewMarker[]) => void;
  showResponses: boolean;
  showNameAndOffset: boolean;
  showContextQuestion: boolean;
}

export const SubBlock = ({ children }: { children: JSX.Element }) => {
  return (
    <div className='col-12'>
      <div className='row'>
        <div className='col mt-2'>{children}</div>
      </div>
    </div>
  );
};

function hasBody(threadData: ThreadData) {
  if (threadData.kind === 'automatic_scorecard_attribute') {
    return true;
  }

  return (
    threadData.userId !== undefined &&
    threadData.userId === threadData.note?.user?.id
  );
}

function extractBody(threadData: ThreadData) {
  if (threadData.redacted) {
    return `This content was redacted and it's not available for your user permissions.`;
  }

  if (threadData.question != null) {
    return threadData.question;
  }

  if (threadData.scorecardAttribute != null) {
    return threadData.scorecardAttribute?.name;
  }

  if (hasBody(threadData)) return threadData.note.text;

  return undefined;
}

function extractReplies(threadData: ThreadData) {
  return threadData.replies || [];
}

function extractAutomaticNotes(threadData: ThreadData) {
  return threadData.automaticNotes || [];
}

export const NotesThread = React.memo(function NotesThread(props: PropTypes) {
  const noteActive = props.playTimestamp !== undefined;
  const [noteText, setNoteText] = useState<string>('');
  const [replyActive, setReplyActive] = useState<boolean>(false);
  const [isSubmittingReply, setIsSubmittingReply] = useState<boolean>(false);
  const body = extractBody(props.threadData);
  const replies = extractReplies(props.threadData);
  const automaticNotes = extractAutomaticNotes(props.threadData);
  const [isRepliesExpanded, setIsRepliesExpanded] = useState<boolean>(false);

  const [deleteNoteId, setDeleteNoteId] = useState<number>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  async function handlePostReply(text: string) {
    setIsSubmittingReply(true);

    if (props.threadData.kind === 'automatic_scorecard_attribute') {
      await NoteService.createAutomaticAttributeReply(
        props.threadData.itemId,
        text,
      );
    } else if (props.threadData.kind === 'automatic_question') {
      await NoteService.createQuestionReply(props.threadData.itemId, text);
      props.refetchAutomaticQuestions();
    } else {
      await NoteService.createNoteReply(props.threadData.itemId, text);
    }

    const interview = await InterviewService.get(props.interview.id);

    if (props.threadData.kind !== 'automatic_question') {
      props.onUpdateInterviewMarkers?.(interview.interview_markers);
    }

    setReplyActive(false);
    setNoteText('');
    setIsSubmittingReply(false);
  }

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handlePostReply(noteText);
      setIsRepliesExpanded(true);
    },
    [noteText],
  );

  function handleClickDelete(noteId: number | null) {
    setDeleteNoteId(noteId);
    setDeleteModalOpen(true);
  }

  async function handleDeleteInterviewMarkerOrNote() {
    let updatedNoteReplies;
    if (deleteNoteId === null) {
      await InterviewMarkerService.destroy(props.threadData.itemId);

      updatedNoteReplies = props.interview.interview_markers.filter(
        (iv) => iv.id !== props.threadData.id,
      );
    } else {
      await NoteService.destroy(deleteNoteId);

      if (props.threadData.kind === 'automatic_question') {
        props.refetchAutomaticQuestions();
        updatedNoteReplies = JSON.parse(
          JSON.stringify(props.automaticQuestions),
        );
      } else {
        updatedNoteReplies = JSON.parse(
          JSON.stringify(props.interview.interview_markers),
        );
      }
      const thisInterviewNote = updatedNoteReplies.find(
        (im) => im.id === props.threadData.itemId,
      );
      thisInterviewNote.replies = thisInterviewNote.replies.filter(
        (n) => n.id !== deleteNoteId,
      );
    }
    setDeleteModalOpen(false);

    if (props.threadData.kind !== 'automatic_question') {
      props.onUpdateInterviewMarkers(updatedNoteReplies);
    }
  }

  const kind = props.threadData.redacted
    ? 'interview_secret'
    : props.threadData.kind;

  const uniqueUserNames = [...new Set(replies.map((reply) => reply.user.name))];

  if (uniqueUserNames.length > 5) {
    uniqueUserNames.splice(5, 0, `+ ${uniqueUserNames.length - 5}`);
  }

  return (
    <div
      className={`p-3 mb-3 ${styles.notesThreadWrapper} ${
        noteActive ? styles.notesThreadWrapperActive : ''
      }`}
      data-testid={`NotesThread-${props.threadData.id}`}
    >
      <div className='col-12' data-testid={`NoteBlock-${props.threadData.id}`}>
        <NoteBlock
          icon={props.showIcon ? props.threadData.iconClasses : undefined}
          offsetMs={props.threadData.offset_ms}
          totalTime={props.interview.media.duration}
          displayName={props.threadData.displayName}
          body={body}
          scoreBody={props.threadData.score}
          contextBody={props.threadData.contextQuestion}
          kind={kind}
          noteId={null}
          deleteAccess={props.threadData.note?.deleteAccess}
          menuType={props.menuType}
          onSelectTimestamp={props.onSelectTimestamp}
          onClickReply={() => setReplyActive(true)}
          onClickDelete={handleClickDelete}
          showNameAndOffset={props.showNameAndOffset}
          showContextQuestion={props.showContextQuestion}
          marginClass={'me-2'}
        />
      </div>
      {props.showResponses && (
        <AutomaticNotesBlock
          typeName='Response'
          automaticNotes={automaticNotes}
        />
      )}

      {isRepliesExpanded && (
        <RepliesBlock
          replies={replies}
          duration={props.interview.media.duration}
          menuType={props.menuType}
          onSelectTimestamp={props.onSelectTimestamp}
          handleClickDelete={handleClickDelete}
          showNameAndOffset={props.showNameAndOffset}
          showContextQuestion={props.showContextQuestion}
          setIsRepliesExpanded={setIsRepliesExpanded}
        />
      )}

      {!isRepliesExpanded && !props.showResponses && (
        <CommentsListRow
          uniqueUserNames={uniqueUserNames}
          replies={replies}
          setIsRepliesExpanded={setIsRepliesExpanded}
        />
      )}

      {replyActive && (
        <>
          <SubBlock>
            <AddReplyBlock
              onSubmit={onSubmit}
              noteText={noteText}
              setNoteText={setNoteText}
              setReplyActive={setReplyActive}
              isSubmittingReply={isSubmittingReply}
            />
          </SubBlock>
        </>
      )}
      <ConfirmationModal
        isOpen={deleteModalOpen}
        title='Delete Note'
        body={<span>This message will be permanently deleted</span>}
        cancelText='Cancel'
        confirmText='Delete'
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={() => handleDeleteInterviewMarkerOrNote()}
        size={'md'}
      />
    </div>
  );
});
