import { Application } from '../../entities/Application';

export function applicationAverageRating(
  application: Application,
  precision: number = 1,
): string | null {
  const attributeRating = application.attributeRating;
  const applicationRating = application.applicationRating;

  if (!attributeRating && !applicationRating) {
    return null;
  }

  if (!attributeRating) {
    return applicationRating.toFixed(precision);
  }

  if (!applicationRating) {
    return attributeRating.toFixed(precision);
  }

  return ((applicationRating + attributeRating) / 2).toFixed(precision);
}
