import React, { ReactNode } from 'react';
import { Card, CardTitle } from '../../../../../components/Card';

interface PropTypes {
  title: string | ReactNode;
  status?: ReactNode;
  children: ReactNode;
  customStyles?: React.CSSProperties;
}

export const JOB_REQUISITION_CARD_HEIGHT = '486px';

export default function JobRequisitionCard(props: PropTypes) {
  return (
    <Card style={props.customStyles ?? { height: JOB_REQUISITION_CARD_HEIGHT }}>
      <div className='d-flex flex-column gap-2h h-100'>
        <div className='d-flex fs-3 fw-semibold'>
          <CardTitle>{props.title}</CardTitle>
          <div className={'ms-auto'}>{props.status}</div>
        </div>
        {props.children}
      </div>
    </Card>
  );
}
