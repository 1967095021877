import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { BulkSendEmailModal } from './BulkSendEmailModal';
import { User } from '../../../../../entities/User';
import { BulkSelfSchedulingDto } from '../../../../../services/dtos/BulkSelfSchedulingDto';
import { SpanTooltip } from '../../../../../components/SpanTooltip';
import { Alert, AlertObject, AlertType } from '../../../../../components/Alert';
import { getSessionSelfSchedulingDto } from '../../../../../utils/applicant_tracking/buildSelfScheduling';

interface PropTypes {
  currentUser: User;
}

function SendEmailButton(props: {
  bulkSelfSchedulingDto: BulkSelfSchedulingDto;
  setSendEmailModalOpen: (b: boolean) => void;
}) {
  const isDisabled =
    !props.bulkSelfSchedulingDto.applicationInterviewCandidateUsers ||
    props.bulkSelfSchedulingDto.applicationInterviewCandidateUsers.length == 0;

  return (
    <SpanTooltip
      text={
        <div className='d-flex justify-content-center mt-4'>
          <Button
            type='button'
            color='primary'
            onClick={() => props.setSendEmailModalOpen(true)}
            disabled={isDisabled}
            tool
          >
            Send Email to Candidates
          </Button>
        </div>
      }
      tooltipText={
        'All selected candidates have already received the self-scheduling link.'
      }
      showTooltip={isDisabled}
      placement='top'
    />
  );
}

export default function BulkSendSelfSchedulingEmail(props: PropTypes) {
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const defaultAlert = { message: null, type: AlertType.Success };
  const [alert, setAlert] = useState<AlertObject>(defaultAlert);

  const bulkSelfSchedulingDto = getSessionSelfSchedulingDto();

  return (
    <>
      {alert.message && (
        <Alert
          type={alert.type}
          clearable={true}
          autoClearTimeout={3000}
          onClose={() => setAlert(defaultAlert)}
        >
          {alert.message}
        </Alert>
      )}
      <SendEmailButton
        bulkSelfSchedulingDto={bulkSelfSchedulingDto}
        setSendEmailModalOpen={setSendEmailModalOpen}
      />
      <BulkSendEmailModal
        bulkSelfSchedulingDto={bulkSelfSchedulingDto}
        currentUser={props.currentUser}
        isOpen={sendEmailModalOpen}
        onClose={() => {
          setSendEmailModalOpen(false);
        }}
        setAlert={setAlert}
      />
    </>
  );
}
