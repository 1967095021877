import React from 'react';
import { Col, Row } from 'reactstrap';
import { SurveyButton } from '../../../../components/SurveyButton';
import { SurveyQuestionField } from '../../../../entities/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../entities/SurveyQuestionFieldAnswer';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer: SurveyQuestionFieldAnswer;
  onChangeAnswer: (fieldId: number, value: string, optionIds: number[]) => void;
}

const toggleOptionId = (optionIds: number[], optionId: number) => {
  const index = optionIds.findIndex((id) => id === optionId);
  if (index === -1) return optionIds.concat(optionId);

  return optionIds.filter((id) => id !== optionId);
};

export function OptionsField({
  field,
  fieldAnswer,
  onChangeAnswer,
}: PropTypes) {
  const optionIds = fieldAnswer.option_ids || [];

  return (
    <Row className='mt-4 align-items-center'>
      {field.name && (
        <Col xs='2' className='mb-2'>
          <b>{field.name}</b>
        </Col>
      )}
      <Col xs='10' className='mb-2 d-flex gap-3'>
        {field.options.map((opt) => (
          <SurveyButton
            key={opt.id}
            className='text-start'
            active={optionIds.find((opt_id) => opt_id === opt.id) !== undefined}
            onClick={() =>
              onChangeAnswer(
                field.id,
                undefined,
                toggleOptionId(optionIds, opt.id),
              )
            }
          >
            {opt.name}
          </SurveyButton>
        ))}
      </Col>
    </Row>
  );
}
