import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'reactstrap';
import { ScorecardCategory } from '../../../../../../entities/ScorecardCategory';
import styles from './styles.module.scss';

interface PropTypes {
  category: ScorecardCategory;
  index: number;
  setActiveTabIndex: (index: number) => void;
  activeTab: ScorecardCategory;
}

export function ScorecardCategoryNav(props: PropTypes) {
  return (
    <NavLink
      id={props.category.id}
      active={props.category.id === props.activeTab.id}
      href='#'
      className={classNames(
        'nav-item',
        'nav-link',
        'text-truncate',
        'px-2',
        'fs-5',
        'fw-normal',
        styles.link,
        {
          'active bg-transparent': props.activeTab.id == props.category.id,
        },
      )}
      onClick={() => props.setActiveTabIndex(props.index)}
    >
      {props.category.name}
    </NavLink>
  );
}
