import React from 'react';
import { Select, SelectOption } from '../../../../components/Select';
import { ClientId, ColumnConfig, FieldConfig, FieldData } from '.';
import { Action } from '../dispatcher';
import _ from 'lodash';
import {
  GroupedOption,
  GroupedSelect,
} from '../../../../components/GroupedReactSelect';

interface FieldConfigSelectOption extends SelectOption, FieldConfig {}

interface PropTypes {
  sectionId: ClientId;
  configs: FieldConfig[];
  singleTypeSections: boolean;
  disabledSelect: boolean;
  data: FieldData;
  dispatch: React.Dispatch<Action>;
  readOnly?: boolean;
  column: ColumnConfig;
}

export function TypeCol(props: PropTypes) {
  function handleConfigChange(fieldConfig: FieldConfig) {
    props.dispatch({
      type: 'field_config_change',
      sectionId: props.sectionId,
      fieldId: props.singleTypeSections ? 'ALL' : props.data.clientId,
      fieldConfig,
    });
  }

  const groups = _.groupBy(props.configs, (config) => config.group);

  if (Object.keys(groups).length === 1) {
    return (
      <div className={props.column.className}>
        <Select
          placeholder='Select field type'
          options={props.configs.map((config) => ({
            value: config.label,
            ...config,
          }))}
          selected={
            props.data.fieldType != null &&
            ({
              value: props.data.label,
              ...props.data,
            } as FieldConfigSelectOption)
          }
          onChange={(option: FieldConfigSelectOption) => {
            handleConfigChange(option);
          }}
          disabled={props.readOnly || props.disabledSelect}
        />
      </div>
    );
  } else {
    const options: GroupedOption[] = [];
    for (const [key, value] of Object.entries(groups)) {
      options.push({
        label: key,
        options: value.map((config) => ({
          value: config.label,
          ...config,
        })),
      });
    }

    return (
      <div className={props.column.className}>
        <GroupedSelect
          placeholder='Select field type'
          options={options}
          selected={
            props.data.fieldType != null &&
            ({
              value: props.data.label,
              ...props.data,
            } as FieldConfigSelectOption)
          }
          onChange={(option: FieldConfigSelectOption) => {
            handleConfigChange(option);
          }}
          disabled={props.readOnly || props.disabledSelect}
          isClearable={false}
        />
      </div>
    );
  }
}
