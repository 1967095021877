import React from 'react';
import { Button } from 'reactstrap';

export function AssignUserFooter(props: {
  onCancel: () => void;
  onAssign: () => void;
}) {
  return (
    <>
      <Button color='danger' onClick={props.onCancel}>
        Cancel
      </Button>
      <Button color='primary' onClick={props.onAssign}>
        Submit
      </Button>
    </>
  );
}
