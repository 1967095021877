import React from 'react';
import { Col, Row } from 'reactstrap';
import { Doughnut, Bar } from 'react-chartjs-2';
import { EmptyTab } from '../../../components/EmptyTab';
import UndrawLost from '../../../images/undraw_lost_re_xqjt.svg';
import styles from './styles.module.scss';
import { participantsToColor } from '../../../utils/participantsToColor';
import { Participant } from '../../../entities/v1/interview_intelligence/Participant';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useSpeakers } from '../../../queries/v1/interview_intelligence/useSpeakers';
import { useParticipants } from '../../../queries/v1/interview_intelligence/useParticipants';
import classNames from 'classnames';

interface PropTypes {
  interviewId: number;
  currentUserId: number;
  interviewStartDate?: Date;
}

interface UIPropTypes {
  interviewId: number;
  currentUserId: number;
  interviewStartDate?: Date;
  speakers: Participant[];
  participants: Participant[];
}

const getSortedSpeakers = (speakers: Participant[]) => {
  return speakers.sort((a, b) => a.displayName.localeCompare(b.displayName));
};

const getAttendants = (participants: Participant[]) => {
  return participants.filter((ip) => ip.joinedAt != null);
};

const getSortedAttendants = (participants: Participant[]) => {
  return getAttendants(participants).sort((a, b) =>
    a.displayName.localeCompare(b.displayName),
  );
};

const getLabels = (speakers: Participant[]) => {
  return getSortedSpeakers(speakers).map((p) => p.displayName);
};

const getSelfTalkRatio = (
  participants: Participant[],
  currentUserId: number,
) => {
  const selfParticipant = participants.find(
    (p) => p.user?.id === currentUserId,
  );

  return selfParticipant?.insights?.talkRatio;
};

const getSelfInterviewerTalkSpeed = (
  participants: Participant[],
  currentUserId: number,
) => {
  const selfParticipant = participants.find(
    (p) => p.user?.id === currentUserId,
  );

  return selfParticipant?.insights?.talkSpeed;
};

const getParticipantTalkSpeed = (participant: Participant) => {
  return participant.insights?.talkSpeed;
};

const getTalkRatioData = (speakers: Participant[]) => {
  const talkRatioData = [];
  const colors = participantsToColor(speakers);

  getSortedSpeakers(speakers).forEach((p) => {
    talkRatioData.push([colors[p.id], p.insights?.talkRatio]);
  });

  return talkRatioData;
};

const hasAnyData = (speakers: Participant[]) => {
  return (
    speakers.some((ip) => ip.insights?.talkRatio > 0) ||
    speakers.some((ip) => ip.insights?.talkSpeed != null)
  );
};

const getTalkRatioDataSet = (speakers: Participant[]) => {
  const talkRatioData = getTalkRatioData(speakers);

  return {
    label: 'Talk Ratio',
    data: talkRatioData.map((d) => d[1]),
    backgroundColor: talkRatioData.map((d) => d[0]),
    borderWidth: 0,
    cutout: '70%',
  };
};

const getParticipantDataSet = (
  participants: Participant[],
  participant: Participant,
  getParticipantData: (p: Participant, interviewStartDate?: Date) => void,
  interviewStartDate?: Date,
) => {
  return {
    label: participant.displayName,
    data: [getParticipantData(participant, interviewStartDate)],
    backgroundColor: participantsToColor(participants)[participant.id],
    borderWidth: 0,
    barPercentage: 0.6,
    categoryPercentage: 0.3,
  };
};

const getTalkSpeedDataSets = (
  speakers: Participant[],
  participants: Participant[],
) => {
  return getSortedSpeakers(speakers).map((p) => {
    return getParticipantDataSet(participants, p, getParticipantTalkSpeed);
  });
};

const getPunctualityDataSets = (
  participants: Participant[],
  interviewStartDate?: Date,
) => {
  return getSortedAttendants(participants).map((p) =>
    getParticipantDataSet(
      participants,
      p,
      getParticipantPunctuality,
      interviewStartDate,
    ),
  );
};

const hasExcellentPunctuality = (participants: Participant[]) => {
  return participants.find((p) => p.insights?.punctuality > 0) === undefined;
};

const getParticipantPunctuality = (
  participant: Participant,
  interviewStartDate?: Date,
) => {
  if (interviewStartDate == null) {
    return undefined;
  }

  return participant.insights?.punctuality;
};

const getSelfPunctuality = (
  participants: Participant[],
  currentUserId: number,
) => {
  const selfParticipant = participants.find(
    (p) => p.user?.id === currentUserId,
  );

  return selfParticipant?.insights?.punctuality;
};

const renderTalkSpeed = (props: UIPropTypes) => {
  const selfTalkSpeed = getSelfInterviewerTalkSpeed(
    props.participants,
    props.currentUserId,
  );
  return (
    <Col id='TalkSpeed' className={classNames(styles['talk-charts'])}>
      <div className='w-100 h-100'>
        <div className='d-flex'>
          <div className='me-auto'>
            <h5 className='mb-0 text-dark'>Talk speed</h5>
          </div>
          {selfTalkSpeed !== undefined && (
            <div className='d-flex flex-column justify-content-end align-items-end text-dark'>
              <h5 className='mb-0'>{selfTalkSpeed} WPM</h5>
              <small className='fw-semibold'>Self</small>
            </div>
          )}
        </div>
        <div>
          <Bar
            data={{
              labels: ['Words per Minute'],
              datasets: getTalkSpeedDataSets(
                props.speakers,
                props.participants,
              ),
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom',
                  align: 'start',
                  labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </Col>
  );
};

const renderTalkRatio = (props: UIPropTypes) => {
  const selfTalkRatio = getSelfTalkRatio(
    props.participants,
    props.currentUserId,
  );
  return (
    <Col id='TalkRatio' className={classNames(styles['talk-charts'])}>
      <div className='w-100 h-100'>
        <div className='d-flex'>
          <div className='me-auto'>
            <h5 className='text-dark'>Talk Ratio</h5>
          </div>
          {selfTalkRatio !== undefined && (
            <div className='d-flex flex-column justify-content-end align-items-end text-dark'>
              <h5 className='mb-0'>{selfTalkRatio}%</h5>
              <small className='fw-semibold'>Self</small>
            </div>
          )}
        </div>
        <div>
          <Doughnut
            data={{
              labels: getLabels(props.speakers),
              datasets: [getTalkRatioDataSet(props.speakers)],
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom',
                  align: 'start',
                  labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                  },
                  maxWidth: 1000,
                },
              },
            }}
          />
        </div>
      </div>
    </Col>
  );
};

const renderPunctuality = (props: UIPropTypes) => {
  const selfPunctuality = getSelfPunctuality(
    props.participants,
    props.currentUserId,
  );
  return (
    <Col id='Punctuality' className={classNames(styles['talk-charts'])}>
      {hasExcellentPunctuality(props.participants) ? (
        <EmptyTab
          title='Excellent Punctuality'
          text='No data available, everyone arrived on time.'
          size='medium'
        />
      ) : (
        <div className='w-100 h-100'>
          <div className='d-flex '>
            <div className='me-auto'>
              <h5 className='text-dark'>Punctuality</h5>
            </div>
            {selfPunctuality !== undefined && (
              <div className='d-flex flex-column justify-content-end align-items-end text-dark'>
                <h5 className='mb-0'>{selfPunctuality} mins</h5>
                <small className='fw-semibold'>Self</small>
              </div>
            )}
          </div>
          <div>
            <Bar
              data={{
                labels: ['Punctuality'],
                datasets: getPunctualityDataSets(
                  props.participants,
                  props.interviewStartDate,
                ),
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                indexAxis: 'y',
                plugins: {
                  legend: {
                    position: 'bottom',
                    align: 'start',
                    labels: {
                      boxWidth: 10,
                      boxHeight: 10,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </Col>
  );
};

function InterviewAnalyticsCharts(props: {
  renderProps: {
    participants: Participant[];
    speakers: Participant[];
    interviewId: number;
    currentUserId: number;
    interviewStartDate?: Date;
  };
}) {
  return (
    <div>
      <Row className='mt-3 p-3 shadow-sm'>
        <Col sm={6} className='ps-0'>
          {renderTalkRatio(props.renderProps)}
        </Col>
        <Col sm={6} className='pe-0'>
          {renderTalkSpeed(props.renderProps)}
        </Col>
      </Row>
      <Row className='p-3 shadow-sm'>
        {renderPunctuality(props.renderProps)}
      </Row>
    </div>
  );
}

export function InterviewInsightsTab(props: PropTypes) {
  const { isLoading: isLoadingSpeakers, data: speakerList } = useSpeakers(
    props.interviewId,
    ['insights'],
  );
  const { isLoading: isLoadingParticipants, data: participants } =
    useParticipants(props.interviewId, ['user', 'insights']);

  const isLoading = isLoadingParticipants || isLoadingSpeakers;

  const renderProps = {
    ...props,
    participants: participants?.participants,
    speakers: speakerList?.participants,
  };

  return isLoading ? (
    <div style={{ minHeight: '250px' }}>
      <LoadingSpinner />
    </div>
  ) : (
    <>
      {hasAnyData(speakerList?.participants) ? (
        <InterviewAnalyticsCharts renderProps={renderProps} />
      ) : (
        <EmptyTab
          title='No data is available yet'
          text='We are processing your interview. Please check back later for more insights.'
          imageSrc={UndrawLost}
          size='medium'
        />
      )}
    </>
  );
}
