import { ProductSortBy } from '@vonq/hapi-elements-types';
import { SelectOption } from '../../../../components/Select';

export interface FilterState {
  industries: SelectOption[];
  jobFunctions: SelectOption[];
  sortBy: SelectOption[];
}

export interface SelectedFilterState {
  industries?: string;
  jobFunctions?: string;
  sortBy: string;
  search?: string;
}

export const EMPTY_SELECTED_FILTERS = {
  industries: null,
  jobFunctions: null,
  sortBy: buildSortBy()[0].value,
  search: '',
};

export const EMPTY_FILTERS = {
  industries: [],
  jobFunctions: [],
  sortBy: buildSortBy(),
  search: '',
};

function buildSortBy() {
  return [
    {
      value: ProductSortBy.relevant,
      label: 'Relevant',
    },
    {
      value: ProductSortBy.recommendedFirst,
      label: 'Recommended',
    },
    {
      value: ProductSortBy.createdNewestToOldest,
      label: 'Newest',
    },
    {
      value: ProductSortBy.createdOldestToNewest,
      label: 'Oldest',
    },
    {
      value: ProductSortBy.priceHighToLow,
      label: 'High Price',
    },
    {
      value: ProductSortBy.priceLowToHigh,
      label: 'Low Price',
    },
  ];
}
