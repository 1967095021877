import React from 'react';
import { Col, Row } from 'reactstrap';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { SurveyModuleVersion } from '../../../../reference_check/entities/SurveyModuleVersion';
import { SurveyQuestionAnswer } from '../../../../reference_check/entities/SurveyQuestionAnswer';
import { SurveyQuestionFieldAnswer } from '../../../../reference_check/entities/SurveyQuestionFieldAnswer';
import { SummaryScoreTable } from '../SummaryScoreTable';

interface PropTypes {
  surveyModuleVersions: SurveyModuleVersion[];
  answers: SurveyQuestionAnswer[];
  className?: string;
  exportToPDF?: boolean;
}

const getMaxRating = (answers: SurveyQuestionFieldAnswer[]) => {
  return answers
    .map((v) => parseInt(`${v.value}`))
    .sort()
    .pop();
};

function Body(props: {
  surveyModuleVersions: SurveyModuleVersion[];
  answers: SurveyQuestionAnswer[];
  exportToPDF?: boolean;
}) {
  return (
    <SummaryScoreTable
      surveyModuleVersions={props.surveyModuleVersions}
      answers={props.answers}
      filter={(v) => getMaxRating(v.fieldAnswers) < 3}
      sort={(a, b) => {
        if (a.rating === b.rating) {
          const attrA =
            a.field.name || a.question.display_name || a.question.title;
          const attrB =
            b.field.name || b.question.display_name || b.question.title;
          return attrA.localeCompare(attrB);
        }

        return a.rating - b.rating;
      }}
      inputLimit={5}
      descriptionWhenEmpty='References did not provide any matching lower ranks.'
      exportToPDF={props.exportToPDF}
    />
  );
}

export const NegativeSummaryScoreCard = React.memo(
  function NegativeSummaryScoreCard(props: PropTypes) {
    if (props.answers.length === 0) return null;

    return (
      <DeprecatedPanel
        style={{ minHeight: '19.25rem' }}
        className={props.className}
        additionalInnerContainerClassNames='p-4'
      >
        <Row>
          <Col xs='12'>
            <h2 className='mb-3'> Lower Rank</h2>
          </Col>
          <Col xs='12'>
            <Body {...props} />
          </Col>
        </Row>
      </DeprecatedPanel>
    );
  },
);
