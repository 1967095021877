import React, { useState } from 'react';
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal';
import { SelectOption } from '../../../../../../components/Select';
import { OfferService } from '../../../../../../services/applicant_tracking/OfferService';
import { MultiPaginatedSelect } from '../../../../../../components/Select/MultiPaginatedSelect';

interface PropTypes {
  id: string;
  isOpen: boolean;
  onRejection: (reasons: string[]) => void;
  setIsOpen: (isOpen: boolean) => void;
}

function DeclineOfferBody(props: {
  id: string;
  selectedReasons: SelectOption[];
  setSelectedReasons: (r: SelectOption[]) => void;
}) {
  async function loadOptions(search: string, _loadedOptions: unknown) {
    const result = await OfferService.listRejectReasons(props.id);

    return {
      options: result
        .filter(
          (r: string) =>
            search == null ||
            (search != null && r?.toLowerCase().includes(search.toLowerCase())),
        )
        .map((reason: string) => {
          return {
            value: reason,
            label: reason,
          };
        }),
      hasMore: false,
    };
  }

  return (
    <div>
      <span className='fs-4 fw-semibold'>
        Tell us why are you declining this Offer so we can better accomodate
        your feedback. This is optional.
      </span>
      <MultiPaginatedSelect
        className='mt-4'
        value={props.selectedReasons}
        loadOptions={loadOptions}
        onChange={(opts) => props.setSelectedReasons(opts as SelectOption[])}
        debounceTimeout={300}
        placeholder='Select reasons'
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
    </div>
  );
}

export function DeclineOfferModal(props: PropTypes) {
  const [selectedReasons, setSelectedReasons] = useState<SelectOption[]>(null);

  return (
    <ConfirmationModal
      title='Decline Offer'
      body={
        <DeclineOfferBody
          id={props.id}
          setSelectedReasons={setSelectedReasons}
          selectedReasons={selectedReasons}
        />
      }
      size='md'
      overflowVisible={true}
      isOpen={props.isOpen}
      onConfirm={() =>
        props.onRejection(
          selectedReasons?.map((option: SelectOption) => option.value),
        )
      }
      onCancel={() => props.setIsOpen(!props.isOpen)}
    />
  );
}
