import React, { useState } from 'react';
import { WindowWithHapi } from '../../..';
import { FilterState, SelectedFilterState } from '../../../utils/filterState';
import { Select, SelectOption } from '../../../../../../components/Select';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { TargetGroupValues } from '../../JobPostingDetails/TargetGroup/TargetGroupValues';

interface PropTypes {
  filterState: FilterState;
  window: WindowWithHapi;
  targetGroupValues: TargetGroupValues;
  selectedState: SelectedFilterState;
  setFilterState: (filterState: FilterState) => void;
  setSelectedState: (selected: SelectedFilterState) => void;
  onReset: () => void;
}

export function mapTargetGroupToFilterState(
  window: WindowWithHapi,
  filterState: FilterState,
  targetGroupValues: TargetGroupValues,
): FilterState {
  return {
    ...filterState,
    jobFunctions: window.hapiUtils.getFlattenedTree(
      targetGroupValues.jobFunctions,
      'children',
      'name',
      'id',
    ),
    industries: targetGroupValues.industries.map((v) => {
      return {
        value: v.id.toString(),
        label: v.name,
        selected: false,
      };
    }),
  };
}

function SelectFilter(props: {
  clearable?: boolean;
  selectOptions: SelectOption[];
  placeholder: string;
  selected?: string;
  onChange: (o: SelectOption) => void;
}) {
  return (
    <Select
      isLoading={props.selectOptions?.length === 0}
      className={styles['select']}
      placeholder={props.placeholder}
      size='sm'
      isClearable={props.clearable}
      options={props.selectOptions}
      selected={props.selectOptions?.find((o) => o.value === props.selected)}
      onChange={(o: SelectOption) => props.onChange(o)}
    />
  );
}

function SearchInput(props: {
  onChangeSearch: (search: string) => SelectedFilterState;
  selectedState: SelectedFilterState;
  setSelectedState: (selected: SelectedFilterState) => void;
}) {
  const [searchValue, setSearchValue] = useState(
    props.selectedState?.search ?? '',
  );

  return (
    <input
      type='search'
      className={classNames(
        'form-control',
        'search-input',
        'w-25',
        styles['search-input-sm'],
      )}
      placeholder='Search'
      onChange={(ev) => setSearchValue(ev.target.value)}
      onKeyDown={(ev) => {
        if (ev.key !== 'Enter') return;
        props.setSelectedState(props.onChangeSearch(searchValue));
      }}
    />
  );
}

export function FiltersAndSorting(props: PropTypes) {
  const onChangeIndustry = (industry?: SelectOption) => {
    if (industry?.value !== props.selectedState?.industries) {
      props.onReset();
    }

    return {
      ...props.selectedState,
      industries: industry?.value,
    };
  };

  const onChangeJobFunction = (jobFunction?: SelectOption) => {
    if (jobFunction?.value !== props.selectedState?.jobFunctions) {
      props.onReset();
    }

    return {
      ...props.selectedState,
      jobFunctions: jobFunction?.value,
    };
  };

  const onChangeSortBy = (sortBy: SelectOption) => {
    if (sortBy.value !== props.selectedState.sortBy) {
      props.onReset();
    }

    return {
      ...props.selectedState,
      sortBy: sortBy.value,
    };
  };

  const onChangeSearch = (search?: string) => {
    if (search !== props.selectedState.search) {
      props.onReset();
    }

    return {
      ...props.selectedState,
      search: search,
    };
  };

  return (
    <div className='d-flex flex-nowrap mb-3h align-items-center justify-content-between'>
      <SearchInput
        onChangeSearch={onChangeSearch}
        setSelectedState={props.setSelectedState}
        selectedState={props.selectedState}
      />
      <div className='d-flex gap-2h'>
        <SelectFilter
          clearable
          selectOptions={props.filterState?.jobFunctions}
          selected={props.selectedState?.jobFunctions}
          placeholder='All job functions'
          onChange={(o: SelectOption) =>
            props.setSelectedState(onChangeJobFunction(o))
          }
        />
        <SelectFilter
          clearable
          selectOptions={props.filterState?.industries}
          selected={props.selectedState?.industries}
          placeholder='All industries'
          onChange={(o: SelectOption) =>
            props.setSelectedState(onChangeIndustry(o))
          }
        />
        <span className='d-flex align-items-center fs-6'>Sort by</span>
        <SelectFilter
          selectOptions={props.filterState?.sortBy}
          selected={props.selectedState?.sortBy}
          placeholder='Select sorting'
          onChange={(o: SelectOption) =>
            props.setSelectedState(onChangeSortBy(o))
          }
        />
      </div>
    </div>
  );
}
