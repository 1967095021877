import { NpsClassification } from '../../../entities/candidate_experience/NpsClassification';
import { ChipColor } from '../../../components/Chip';

export function findRatingClassificationByScore(
  ratingLegend: {
    minScore: number;
    bgColor: string;
    classification: NpsClassification;
    chipColor: ChipColor;
  }[],
  score: number,
) {
  const legend = findRatingLegendByScore(ratingLegend, score);
  return legend.classification;
}

export function findRatingBgColorByScore(
  ratingLegend: {
    minScore: number;
    label?: string;
    bgColor: string;
    chipColor: ChipColor;
  }[],
  score: number,
) {
  const legend = findRatingLegendByScore(ratingLegend, score);
  return legend?.bgColor || 'light-danger';
}

export function findRatingChipColorByScore(
  ratingLegend: {
    minScore: number;
    label?: string;
    bgColor: string;
    chipColor: ChipColor;
  }[],
  score: number,
): ChipColor {
  const legend = findRatingLegendByScore(ratingLegend, score);
  return legend?.chipColor || ChipColor.DangerLight;
}

function findRatingLegendByScore(
  ratingLegend: {
    minScore: number;
    label?: string;
    color?: string;
    classification?: NpsClassification;
    bgColor: string;
    chipColor: ChipColor;
  }[],
  score: number,
) {
  const reversedLegend = [...ratingLegend].reverse();
  return reversedLegend.find((rl) => rl.minScore < score);
}
