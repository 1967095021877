import React from 'react';
import { Application } from '../../../../../entities/Application';
import { getLocationString } from '../../../../../components/LocationSelect';
import { channelTypeLabel } from '../../../../../utils/applicant_tracking/sources';
import { PortfolioAndCVAndCoverLetter } from '../PortfolioAndCVAndCoverLetter';
import { Entry } from '../Entry';
import { IconEntry } from '../IconEntry';
import { EmailDetails } from './EmailDetails';
import { LinkedinDetails } from './LinkedinDetails';
import { ExpectedSalaryEntry } from './ExpectedSalaryDetails';

interface PropTypes {
  application: Application;
  hideCV?: boolean;
  setIsLoading?: (v: boolean) => void;
}

function workingVisaValue(hasWorkingVisa?: boolean) {
  if (hasWorkingVisa == null) return null;

  return hasWorkingVisa ? 'Yes' : 'No';
}

export function ApplicationDetails(props: PropTypes) {
  const location = getLocationString(props.application.candidate);

  return (
    <div className='fs-5'>
      <PortfolioAndCVAndCoverLetter
        application={props.application}
        hideCV={props.hideCV}
        setIsLoading={props.setIsLoading}
      />
      <hr />
      <div className='d-flex flex-column gap-2h'>
        {props.application.candidate.phone_number && (
          <IconEntry
            label={props.application.candidate.phone_number}
            redacted={props.application.redacted}
            icon='bi-telephone'
          >
            {props.application.candidate.phone_number}
          </IconEntry>
        )}
        <EmailDetails
          email={props.application.candidate.email}
          redacted={props.application.redacted}
        />
        <LinkedinDetails
          redacted={props.application.redacted}
          linkedinUrl={props.application.candidate.linkedin_profile_url}
        />
        {location && (
          <IconEntry
            icon='bi-pin-map'
            redacted={props.application.redacted}
            label='Location'
          >
            {location}
          </IconEntry>
        )}
      </div>
      {(props.application.academicDegree || props.application.experience) && (
        <hr />
      )}
      <div className='d-flex flex-column gap-3'>
        <Entry
          label='Academic Degree'
          value={props.application.academicDegree}
        />
        <Entry
          label='Experience'
          redacted={props.application.redacted}
          value={props.application.experience}
          valueLabel='Years'
        />
      </div>
      {(props.application.currentCompany ||
        props.application.currentTitle ||
        props.application.expectedSalary ||
        props.application.hasWorkingVisa != null) && <hr />}
      <div className='d-flex flex-column gap-3'>
        <Entry
          label='Current Company'
          redacted={props.application.redacted}
          value={props.application.currentCompany}
        />
        <Entry label='Job Title' value={props.application.currentTitle} />
        <Entry
          label='Right to Work'
          value={workingVisaValue(props.application.hasWorkingVisa)}
        />
        <ExpectedSalaryEntry
          expectedSalary={props.application.expectedSalary}
        />
      </div>
      {(props.application.source?.name ||
        props.application.source?.channelType) && <hr />}
      <div className='d-flex flex-column gap-3'>
        <Entry
          label='Channel'
          value={channelTypeLabel(props.application.source?.channelType)}
        />
        <Entry label='Source' value={props.application.source?.displayName} />
      </div>
    </div>
  );
}
