import { ApiService } from '../ApiService';
import { EmailDomain } from '../../entities/EmailDomain';

export class EmailDomainService {
  public static create(
    domain: string,
    sender_email_alias: string,
    sender_name: string,
  ): Promise<EmailDomain> {
    return ApiService.post<EmailDomain>(
      `/organization_settings/email_domains`,
      {
        domain: domain,
        sender_email_alias: sender_email_alias,
        sender_name: sender_name,
      },
    );
  }

  public static enable(emailDomainId: number): Promise<EmailDomain> {
    return ApiService.put<EmailDomain>(
      `/organization_settings/email_domains/${emailDomainId}/enable`,
    );
  }

  public static disable(emailDomainId: number): Promise<void> {
    return ApiService.put<void>(
      `/organization_settings/email_domains/${emailDomainId}/disable`,
    );
  }
}
