import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Col } from 'reactstrap';
import { InputFormField } from '../../../../../../../components/InputFormField';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';

interface PropTypes {
  formRegister: UseFormRegister<JobRequisitionDto>;
}

export function RequisitionId(props: PropTypes) {
  return (
    <Col xs={3}>
      <InputFormField
        type={'text'}
        registerReturn={props.formRegister('uid', { required: false })}
        classNames='fs-5'
        fieldName='Requisition ID'
        placeholder='Auto-generated'
        isRequired={false}
        fontSize='fs-5'
        headerClassNames='fw-semibold'
        disabled={true}
      />
    </Col>
  );
}
