import snakecaseKeys from 'snakecase-keys';
import { PlanTemplateList } from '../../../../entities/v1/applicant_tracking/PlanTemplateList';
import { ApiService } from '../../../ApiService';

interface ListParams {
  publishState?: 'archived' | 'published';
}

export class PlanTemplateService {
  public static list(params?: ListParams): Promise<PlanTemplateList> {
    return ApiService.get<PlanTemplateList>(
      '/api/v1/applicant_tracking/plan_templates',
      snakecaseKeys(params),
    );
  }
}
