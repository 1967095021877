import React from 'react';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Input } from 'reactstrap';

interface PropTypes {
  setWorkingHours: (workingHours: number) => void;
  workingHours: number;
}

export function WorkingHoursInput(props: PropTypes) {
  return (
    <>
      <FormFieldHeader fieldName={'Working hours'} isRequired={true} />
      <Input
        type='number'
        className='fs-5'
        placeholder='Define weekly working hours (e.g. 35)'
        defaultValue={props.workingHours}
        onChange={(event) =>
          props.setWorkingHours(event.target.value as unknown as number)
        }
      />
    </>
  );
}
