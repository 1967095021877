import classNames from 'classnames';
import React from 'react';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import styles from './styles.module.scss';

interface PropTypes {
  submitOtherReferral: () => void;
  finish: () => void;
  isOpen: boolean;
}

export function ReferralConfirmationModal(props: PropTypes) {
  return (
    <ConfirmationModal
      title='Fill Referral'
      body={
        <div className='text-center'>
          <span className={classNames(styles.check)}>
            <i className='bi bi-check-circle'></i>
          </span>
          <p className='mb-0'>
            Referral was submitted successfully, thank you!
          </p>
        </div>
      }
      isOpen={props.isOpen}
      confirmText='Submit Other Referral'
      cancelText='Finish'
      onConfirm={props.submitOtherReferral}
      onCancel={props.finish}
      size={'md'}
    />
  );
}
