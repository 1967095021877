import { ApiService } from '../ApiService';

export class UserInvitationService {
  public static patch(
    firstName: string,
    lastName: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<void> {
    return ApiService.patch<void>(`/users/invitations`, {
      user: {
        first_name: firstName,
        last_name: lastName,
        password: password,
        password_confirmation: passwordConfirmation,
      },
    });
  }

  public static destroy(): Promise<void> {
    return ApiService.delete<void>(`/auth/sign_out`);
  }
}
