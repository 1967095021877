import React from 'react';
import { Select, SelectOption } from '../../../../../../../components/Select';

interface PropTypes {
  options: SelectOption[];
  selected: SelectOption;
  onChange: (opt: SelectOption) => void;
}

export function JobSelect(props: PropTypes) {
  return (
    <Select
      name={'Job'}
      placeholder={'All Jobs'}
      options={props.options}
      selected={props.selected}
      isClearable={true}
      onChange={props.onChange}
    />
  );
}
