import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface PropTypes {
  isDisabled: boolean;
  menuIsOpen: boolean;
  classNames?: string;
}

export const DropdownIcon = (props: PropTypes) => {
  return (
    <i
      className={classNames(
        'bi bi-chevron-down',
        props.isDisabled && 'text-muted',
        props.classNames,
        {
          [`${styles.dropdownInverted} mb-1`]: props.menuIsOpen,
        },
      )}
    ></i>
  );
};
