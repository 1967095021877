import React from 'react';
import { Col, Row } from 'reactstrap';
import { Survey } from '../../../../reference_check/entities/Survey';
import { SurveyQuestionAnswer } from '../../../../reference_check/entities/SurveyQuestionAnswer';
import { SurveyQuestionField } from '../../../../reference_check/entities/SurveyQuestionField';
import styles from './styles.module.scss';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { humanize } from '../../../../utils/humanize';
import { SurveyQuestion } from '../../../../reference_check/entities/SurveyQuestion';
import { PagePanel } from '../PagePanel';
import { Avatar } from '../../../../components/Avatar';

interface PropTypes {
  surveys: Survey[];
  question: SurveyQuestion;
  questionField: SurveyQuestionField;
  answers: SurveyQuestionAnswer[];
  exportToPDF?: boolean;
}

function QuoteItem(props: { surveys; answer }) {
  const survey = props.surveys.find((s) => s.id === props.answer.survey_id);

  return (
    <Row className='d-flex align-items-start'>
      <Col xs='4' className='d-flex align-items-center'>
        <Avatar size='lg' name={survey.referral.name} className='me-3' />
        <div className={styles.referralName}>
          <SpanTooltip
            text={survey.referral.name}
            placement='left'
            className='fw-semibold'
          />
          <small className='d-block mb-0 text-sm fw-normal'>
            {humanize(survey.relationship)}
          </small>
        </div>
      </Col>
      <Col className='h-100'>
        <div className='h-100 bg-note bg-opacity-10 rounded py-2 px-3 small'>
          {props.answer.fields[0].value}
        </div>
      </Col>
    </Row>
  );
}

export const TextResultsTable = React.memo(function TextResultsTable(
  props: PropTypes,
) {
  if (props.answers.length === 0) return null;

  return (
    <PagePanel question={props.question} exportToPDF={props.exportToPDF}>
      <div className='mt-2 d-grid gap-3 overflow-hidden'>
        {props.answers.map((answer, i) => (
          <QuoteItem
            key={`QuoteItem_${i}`}
            surveys={props.surveys}
            answer={answer}
          />
        ))}
      </div>
    </PagePanel>
  );
});
