/**
 * Truncates the number to the unit if the first decimal place is 0, else
 * truncates to the first decimal place.
 */
export function truncateNumber(x: number): string {
  const unit = Math.trunc(x);
  const dec = Math.trunc(x * 10) / 10;

  if (unit === dec) {
    return x.toFixed(0);
  } else {
    return x.toFixed(1);
  }
}

/**
 * Returns a random integer. Optionally allows specifying the interval.
 */
export function randomInt(min?: number, max?: number) {
  const minCeiled = Math.ceil(min ?? 0);
  const maxFloored = Math.floor(max ?? Number.MAX_SAFE_INTEGER);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
}

export function getOrdinal(n: number): string {
  let suffix = 'th';

  if (n % 10 == 1 && n % 100 != 11) {
    suffix = 'st';
  } else if (n % 10 == 2 && n % 100 != 12) {
    suffix = 'nd';
  } else if (n % 10 == 3 && n % 100 != 13) {
    suffix = 'rd';
  }

  return n + suffix;
}
