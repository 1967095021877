import React from 'react';
import styles from './styles.module.scss';

export function CardSummarySectionTitlesRow() {
  return (
    <div className='row'>
      <div className='col-3'>
        <label htmlFor='name' className='form-label'>
          <h4 className={styles.regularFontSize}>
            Survey Trigger Name{' '}
            <span className='ps-1' style={{ color: 'red' }}>
              *
            </span>
          </h4>
        </label>
      </div>
      <div className='col-3'>
        <label htmlFor='name' className='form-label'>
          <h4 className={styles.regularFontSize}>Trigger Status</h4>
        </label>
      </div>
    </div>
  );
}
