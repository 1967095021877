import { ProductDuration } from '@vonq/hapi-elements-types';
import { Chip, ChipColor } from '../../../../../../../components/Chip';
import { IconSpan } from '../../../../../../../components/IconSpan';
import { getDuration } from '../../../../utils/productDetails';
import React from 'react';

interface PropTypes {
  duration: ProductDuration;
}

export function DurationChip(props: PropTypes) {
  return (
    <Chip color={ChipColor.BlueLight}>
      <IconSpan
        spanText={getDuration(props.duration)}
        icon={{ name: 'bi-calendar4-range' }}
      />
    </Chip>
  );
}
