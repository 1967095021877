import classNames from 'classnames';
import React from 'react';
import { CheckboxButton } from '../../../../components/CheckboxButton';

interface PropTypes {
  leftChildElement: JSX.Element;
  assignedEnabled: boolean;
  onChangeAssigned: (event: React.FormEvent<HTMLInputElement>) => void;
}

function ViewAssignedButton(props: {
  enabled: boolean;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}) {
  return (
    <CheckboxButton
      name={'assigned'}
      label={'View only assigned to me'}
      checked={props.enabled}
      onChange={props.onChange}
    />
  );
}

export function SessionsFilterHeader(props: PropTypes) {
  return (
    <div
      className={classNames(
        'mx-3',
        'mt-4',
        'd-flex',
        'flex-nowrap',
        'justify-content-between',
      )}
    >
      {props.leftChildElement}
      <ViewAssignedButton
        enabled={props.assignedEnabled}
        onChange={props.onChangeAssigned}
      />
    </div>
  );
}
