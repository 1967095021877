import React from 'react';
import { MultiPaginatedSelect } from '../../../../../../../components/Select/MultiPaginatedSelect';
import { SelectOption } from '../../../../../../../components/Select';
import { OrganicPostSkillsFilterService } from '../../../../../../../services/v1/applicant_tracking/filters/OrganicPostSkillsFilterService';
import { OrganicPostSkill } from '../../../../../../../entities/v1/applicant_tracking/OrganicPostSkill';

interface PropTypes {
  name: string;
  selected?: OrganicPostSkill[];
  onInputChange: (name: string, value: OrganicPostSkill[]) => void;
}

interface AdditionalType {
  page: number;
}

const defaultAdditional: AdditionalType = {
  page: 1,
};

const MAX_SKILLS_PER_POST = 10;

function mapSkillToSelectOption(
  organicPostSkill: OrganicPostSkill,
): SelectOption {
  return {
    value: organicPostSkill.id.toString(),
    label: organicPostSkill.name,
  };
}

function mapSelectOptionToSkill(option: SelectOption): OrganicPostSkill {
  return {
    id: parseInt(option.value),
    name: option.label,
  };
}

export function SkillsInput(props: PropTypes) {
  const selectedSkills = props.selected?.map(mapSkillToSelectOption) || [];

  async function loadOptions(
    search: string,
    _loadedOptions: unknown,
    { page }: AdditionalType,
  ) {
    const result = await OrganicPostSkillsFilterService.list({
      name: search,
      selectedIds: selectedSkills.map((s) => s.value),
      page: page,
    });
    const options: SelectOption[] = result.skills.map(mapSkillToSelectOption);

    if (!search && page == 1) {
      // When search is empty and it is the first page
      //   prepend selected skills so they are visible on top.
      // This only works because of the following MultiPaginatedSelect options:
      //   - hideSelectedOptions={false}
      //   - disableDefaultListCacheOnChange={true}
      return {
        options: selectedSkills.concat(options),
        hasMore: !!result.nextPage,
        additional: {
          page: page + 1,
        },
      };
    }

    return {
      options: options,
      hasMore: !!result.nextPage,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <div className='gap-0'>
      <div className='d-flex flex-nowrap align-items-end form-label'>
        <label className='mb-0 form-label me-auto text-truncate'>Skills</label>
        <small className='text-muted text-nowrap'>
          Max. {MAX_SKILLS_PER_POST}
        </small>
      </div>
      <MultiPaginatedSelect
        additional={defaultAdditional}
        isMulti={true}
        value={selectedSkills}
        hideSelectedOptions={false}
        disableDefaultListCacheOnChange={true}
        loadOptions={loadOptions}
        onChange={(opts: SelectOption[]) => {
          if (!MAX_SKILLS_PER_POST || opts.length <= MAX_SKILLS_PER_POST) {
            props.onInputChange(
              props.name,
              opts.map(mapSelectOptionToSkill) || [],
            );
          }
        }}
        debounceTimeout={300}
        placeholder={'Select Skills'}
        closeMenuOnSelect={false}
      />
    </div>
  );
}
