import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'reactstrap';
import { SurveyPanel } from '../../../../../components/survey_engine/SurveyPanel';
import { CandidateExperienceSurvey } from '../../../../../entities/candidate_experience/CandidateExperienceSurvey';
import { Survey } from '../../../../../entities/survey_engine/Survey';
import { CandidateUserService } from '../../../../../services/CandidateUser';
import { buildLabelledDemographicOptions } from '../../../../../utils/buildLabelledDemographicOptions';
import { DisplayDemographicOptions } from '../DisplayDemographicOptions';
import styles from './styles.module.scss';

interface PropTypes {
  survey: Survey;
}

const SUBMITTED_DEMOGRAPHIC_ANSWERS_PATH = 'demographic-done';

export function DemographicPanel(props: PropTypes) {
  const surveyDetails = props.survey.surveyDetails as CandidateExperienceSurvey;

  const [ageRange, setAgeRange] = useState<string>(
    surveyDetails.candidateUser.age_range,
  );
  const [ethnicityOption, setEthnicity] = useState<string>(
    surveyDetails.candidateUser.ethnicity,
  );
  const [genderOption, setGender] = useState<string>(
    surveyDetails.candidateUser.gender,
  );
  const [skip, setSkip] = useState<boolean>(false);
  const navigate = useNavigate();
  const ageQuestionOptions = buildLabelledDemographicOptions(
    surveyDetails.demographics.age_ranges,
  );
  const ethnicityQuestionOptions = buildLabelledDemographicOptions(
    surveyDetails.demographics.ethnicities,
  );
  const genderQuestionOptions = buildLabelledDemographicOptions(
    surveyDetails.demographics.genders,
  );

  const handleOnSubmitClick = useCallback(async () => {
    await CandidateUserService.update(
      ageRange,
      ethnicityOption,
      genderOption,
      surveyDetails.candidateUser.sign_in_token,
    );

    navigate(SUBMITTED_DEMOGRAPHIC_ANSWERS_PATH);
  }, [ageRange, ethnicityOption, genderOption, surveyDetails.candidateUser]);

  const handleOnSkipClick = useCallback(() => {
    setSkip(!skip);
  }, []);

  return (
    <SurveyPanel className='mt-4 mb-4 container'>
      {!skip ? (
        <>
          <div className='mt-4 text-center'>
            <h1>Diversity, equity and inclusion</h1>
            <p className={classNames('mt-3 mb-4 mx-auto', styles.text)}>
              We strive to provide a fair and equitable process. We ask you to
              answer the questions below to ensure that we provide a consistent
              experience regardless of age, gender, racial or ethnic
              identification. Your answers are anonymous and will not be linked
              to your application.
            </p>
          </div>

          <DisplayDemographicOptions
            questionOptions={ageQuestionOptions}
            option={ageRange}
            setOption={setAgeRange}
            question={'What is your age?'}
            subject={'age-options'}
          />

          <DisplayDemographicOptions
            questionOptions={ethnicityQuestionOptions}
            option={ethnicityOption}
            setOption={setEthnicity}
            question={'What is your ethnic identification?'}
            subject={'ethnic-options'}
          />

          <DisplayDemographicOptions
            questionOptions={genderQuestionOptions}
            option={genderOption}
            setOption={setGender}
            question={'What is your gender identification?'}
            subject={'gender-options'}
          />

          <div className='d-flex justify-content-center'>
            <div>
              <Button
                size='lg'
                className='btn btn-lg btn-secondary py-2 px-5 me-3'
                onClick={handleOnSkipClick}
                disabled={false}
              >
                Skip
              </Button>
            </div>
            <div>
              <Button
                color='primary'
                size='lg'
                className='py-2 px-5'
                onClick={handleOnSubmitClick}
                disabled={!(ageRange || ethnicityOption || genderOption)}
              >
                Submit
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='mt-4 text-center'>
            <h1>That’s ok. Thank you.</h1>
            <p className={classNames('mt-3 mb-4 mx-auto', styles.text)}>
              We leverage demographic data to make sure we deliver a fair and
              consistent recruitment process for everyone.
            </p>
          </div>
        </>
      )}
    </SurveyPanel>
  );
}
