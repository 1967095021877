import React from 'react';
import { Application } from '../../../../../entities/Application';
import { BulkApplicationReviewHeader } from './BulkApplicationReviewHeader';
import { AlertObject } from '../../../../../components/Alert';
import { BULK_REVIEW_NAVIGATION_TYPE } from '../..';

interface PropTypes {
  application: Application;
  index: number;
  isLoadingResume: boolean;
  preSelectedRejectReasonId: number;
  totalApplications: number;
  resumeUrl?: string;
  onNext: (navType: BULK_REVIEW_NAVIGATION_TYPE) => void;
  onClose: () => void;
  setAlert: (alert: AlertObject) => void;
  setPreSelectedRejectReasonId: (id: number) => void;
}

export function DetailedPanel(props: PropTypes) {
  return (
    <div className='d-flex flex-column vh-100'>
      <BulkApplicationReviewHeader {...props} />
    </div>
  );
}
