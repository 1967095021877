import { ConditionsData } from '..';
import { SurveyTemplate } from '../../../../../entities/candidate_experience/SurveyTemplate';
import { setSortedConditionsDatabyMandatory } from './setSortedConditionsDatabyMandatory';
import { computeUpdateCondition } from './computeUpdateCondition';

export function computeUpdateConditions(
  conditionsData: ConditionsData,
  surveyTemplate: SurveyTemplate,
) {
  const conditions = [];

  const sortedConditionsDatabyMandatory = setSortedConditionsDatabyMandatory(
    conditionsData,
    surveyTemplate.trigger_requirements,
  );

  sortedConditionsDatabyMandatory.forEach((condition) => {
    computeUpdateCondition(conditions, condition.hasInvertCondition, {
      value: condition.value,
      label: condition.label,
      options: condition.options,
      singleSelected: condition.singleSelected,
      multiSelected: condition.multiSelected,
      mandatory:
        surveyTemplate.trigger_requirements[condition.value] === 'mandatory',
      invertCondition: condition.invertCondition,
    });
  });

  return conditions;
}
