import React from 'react';
import {
  Section,
  SectionData,
} from '../../../../../../../survey_engine/SurveyTemplates/Section';
import { BASE_FIELD_CONFIGS } from '../../../../../../ApplicationFormTemplates/data';
import { LoadingSpinner } from '../../../../../../../../components/LoadingSpinner';

export interface ApplicationFormTemplateView {
  id: number;
  name: string;
  default: boolean;
  candidateConsentEnabled: boolean;
  sections: SectionData[];
}

interface PropTypes {
  applicationFormTemplate?: ApplicationFormTemplateView;
  isLoading: boolean;
}

export function SectionListView(props: PropTypes) {
  if (props.isLoading)
    return (
      <div style={{ position: 'relative', height: '250px' }}>
        <LoadingSpinner />
      </div>
    );

  const sections = props.applicationFormTemplate.sections;

  return (
    <div className='vstack gap-4'>
      {sections.map((section) => (
        <Section
          key={section.cId}
          fieldConfig={BASE_FIELD_CONFIGS}
          singleTypeSections={false}
          data={section}
          dispatch={undefined}
          removeDisabled={true}
          upDisabled={true}
          downDisabled={true}
          readOnly={true}
          addFieldButtonText={''}
          fieldDeleteTooltip={''}
          enableDescription={true}
          columns={[
            { fieldName: 'type', className: 'col-3' },
            { fieldName: 'text', className: 'col' },
            { fieldName: 'mandatory', className: 'col-1' },
          ]}
        />
      ))}
    </div>
  );
}
