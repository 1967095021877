import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { InterviewAssistantChat } from './InterviewAssistantChat';
import { generateAiResponse } from './generateAiResponse';
import { Interview } from '../../../entities/Interview';
import { InterviewAssistantFooter } from './InterviewAssistantFooter';
import { InterviewAiAssistantConversationService } from '../../../services/InterviewAiAssistantConversationService';
import { InterviewAiAssistantConversation } from '../../../entities/InterviewAiAssistantConversation';
import { LoadingSpinner } from '../../../components/LoadingSpinner';

export interface AssistantResponse {
  aiAssistant: boolean;
  text: string;
  auto: boolean;
}
interface PropTypes {
  interview: Interview;
  responses: AssistantResponse[];
  onResponses: (
    updateFunction: (prevResponses: AssistantResponse[]) => AssistantResponse[],
  ) => void;
  hasServerResponses: boolean;
  onHasServerResponses: (value: boolean) => void;
}

function generateConversationHistoric(
  serverResponses: InterviewAiAssistantConversation[],
  responses: AssistantResponse[],
) {
  return serverResponses.reduce(
    (prevResponses, conversation) => [
      ...prevResponses,
      { aiAssistant: false, text: conversation.input, auto: false },
      { aiAssistant: true, text: conversation.aiResponse, auto: false },
    ],
    responses,
  );
}

export function InterviewAssistantTab(props: PropTypes) {
  const [userInput, setUserInput] = useState('');
  const [isAiGenerating, setIsAiGenerating] = useState(false);

  useEffect(() => {
    const chatContainer = document.querySelector('.h-100.overflow-auto');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [props.responses]);

  useEffect(() => {
    if (props.hasServerResponses) return;

    InterviewAiAssistantConversationService.list(props.interview.id).then(
      (serverResponses) => {
        const updatedResponses = generateConversationHistoric(
          serverResponses,
          props.responses,
        );
        props.onResponses(() => updatedResponses);

        props.onHasServerResponses(true);
      },
    );
  }, []);

  const handleAskAI = async () => {
    props.onResponses((prevResponses: AssistantResponse[]) => [
      ...prevResponses,
      { aiAssistant: false, text: userInput, auto: false },
      { aiAssistant: true, text: '', auto: false },
    ]);

    await new Promise<void>((resolve) => {
      generateAiResponse(
        props.interview.id,
        userInput,
        (response) => {
          props.onResponses((prevResponses: AssistantResponse[]) => {
            const updatedResponses = [...prevResponses];
            updatedResponses[updatedResponses.length - 1] = {
              aiAssistant: true,
              text: response,
              auto: false,
            };
            return updatedResponses;
          });
          resolve();
        },
        setIsAiGenerating,
      );
    });

    setUserInput('');
  };

  return (
    <div
      className={classNames(
        'd-flex',
        'flex-column',
        'align-items-end',
        'interview_detail_assistant',
      )}
    >
      {props.hasServerResponses ? (
        <>
          <InterviewAssistantChat
            responses={props.responses}
            userName={props.interview.current_user.name}
            isAiGenerating={isAiGenerating}
          />
          <InterviewAssistantFooter
            userInput={userInput}
            setUserInput={setUserInput}
            isAiGenerating={isAiGenerating}
            handleAskAI={handleAskAI}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}
