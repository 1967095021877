import { ApiService } from '../ApiService';

export class AccountSettingsSecurityService {
  public static patch(
    currentPassword: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<void> {
    return ApiService.patch<void>(`/account_settings/security`, {
      user: {
        current_password: currentPassword,
        password: password,
        password_confirmation: passwordConfirmation,
      },
    });
  }
}
