import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import {
  ClientId,
  ColumnConfig,
  Field,
  FieldConfig,
  FieldData,
} from '../Field';
import { Action } from '../dispatcher';

interface PropTypes {
  sectionId: ClientId;
  fieldTypeConfigs: FieldConfig[];
  singleTypeSections: boolean;
  fields: FieldData[];
  dispatch: React.Dispatch<Action>;
  readOnly?: boolean;
  columns: ColumnConfig[];
  placeholder?: string;
  fieldDeleteTooltip?: string;
}

export function FieldList(props: PropTypes) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id === over.id) return;

    props.dispatch({
      type: 'field_order_change',
      sectionId: props.sectionId,
      fromId: active.id,
      toId: over.id,
    });
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={props.fields.map((field) => field.clientId)}
        strategy={verticalListSortingStrategy}
        disabled={props.readOnly}
      >
        <div className='vstack gap-3'>
          {props.fields.map((field, i) => (
            <Field
              key={field.clientId}
              sectionId={props.sectionId}
              configs={props.fieldTypeConfigs}
              singleTypeSections={props.singleTypeSections}
              disabledSelect={props.singleTypeSections && i !== 0}
              removable={props.fields.length != 1}
              data={field}
              dispatch={props.dispatch}
              readOnly={props.readOnly || field.removable === false}
              columns={props.columns}
              placeholder={props.placeholder}
              fieldDeleteTooltip={props.fieldDeleteTooltip}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}
