export enum SurveyQuestionFieldType {
  Attachment = 'attachment',
  Boolean = 'boolean',
  Email = 'email',
  MultiOptions = 'multi_options',
  Options = 'options',
  Phone = 'phone',
  Rating = 'rating',
  Text = 'text',
  Url = 'url',
  Location = 'location',
}
