import { ApiService } from '../../ApiService';

export class SchedulingIntegrationsService {
  public static destroy(): Promise<void> {
    return ApiService.delete<void>(
      `/api/account_settings/integrations/scheduling_integrations/`,
      {},
    );
  }
}
