import React, { useState } from 'react';
import { FilterStates } from '../../../../components/candidate_experience/Filters/filterState';
import {
  ExportsService,
  ExportType,
} from '../../../../services/candidate_experience/ExportsService';
import { triggerDownload } from '../../../../utils/triggerDownload';
import { filterStatesToParams } from './filterStatesToParams';
import { ExportModal as BaseExportModal } from '../../../../components/ExportModal';

interface PropTypes {
  isOpen: boolean;
  filterStates: FilterStates;
  onCancel: () => void;
}

const EXPORT_OPTIONS = [
  { value: 'PDF', label: 'PDF (*.pdf)' },
  { value: 'CSV', label: 'CSV (*.csv)' },
];

export function ExportModal(props: PropTypes) {
  const [selectedOption, setSelectedOption] = useState(EXPORT_OPTIONS[0]);

  async function handleDownload() {
    const params = filterStatesToParams(props.filterStates);
    const dateFormat = 'D MMM, YYYY';
    const fileName = `Pulse Export - ${params.dateRange.start.format(
      dateFormat,
    )} - ${params.dateRange.end.format(dateFormat)}`;

    if (selectedOption.value === 'CSV') {
      const blob = await ExportsService.export(params, ExportType.CSV);

      triggerDownload(blob, `${fileName}.csv`);
    } else if (selectedOption.value === 'PDF') {
      const blob = await ExportsService.export(params, ExportType.PDF);

      triggerDownload(blob, `${fileName}.pdf`);
    } else {
      throw 'Not yet implemented.';
    }
  }

  return (
    <BaseExportModal
      isOpen={props.isOpen}
      selected={selectedOption}
      options={EXPORT_OPTIONS}
      onSelect={(newOption) => setSelectedOption(newOption)}
      onDownload={handleDownload}
      onCancel={props.onCancel}
    />
  );
}
