import React from 'react';
import { AlertMessage } from '../../../../components/AlertMessage';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ScorecardTemplateTable } from '../../../../components/ApplicantTracking/ScorecardTemplateTable';
import { ScorecardScore } from '../../Scorecards/ScorecardPage/ScorecardBody/ScorecardScore';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useScorecardTemplates } from '../../../../queries/v1/applicant_tracking/useScorecardTemplates';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../../../../config/queryClient';

interface PropTypes {
  id: number;
}

export default function PreviewScorecardTemplatePage(props: PropTypes) {
  return (
    <QueryClientProvider client={queryClient}>
      <PageBody {...props} />
    </QueryClientProvider>
  );
}

function PageBody(props: PropTypes) {
  const { isLoading: isLoading, data: scorecardTemplate } =
    useScorecardTemplates(props.id, [
      'survey_template',
      'survey_template.sections',
      'survey_template.sections.questions',
    ]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className='container pb-4'>
      <div className='d-flex justify-content-center mt-4 mb-4h'>
        <AlertMessage
          text='This is a scorecard preview'
          icon={{ name: 'bi-info-circle' }}
          className='bg-light-warning fs-5 px-3h'
          hasOverlay={false}
        />
      </div>
      <div className='d-flex justify-content-center'>
        <div className={classNames(styles['preview-container'], 'w-100')}>
          <div className='fw-bold fs-1 mt-2 mb-2h'>
            {scorecardTemplate.surveyTemplate.name}
          </div>
          <ScorecardTemplateTable
            preview
            scorecardTemplate={scorecardTemplate}
          />
          <div className='pt-4'>
            <ScorecardScore readonly={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
