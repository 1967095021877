const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(
    hex,
  );
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: result[4] ? parseInt(result[4], 16) / 255 : 1,
      }
    : null;
};

export const getContrastColor = (background: string) => {
  const rgb = hexToRgb(background);
  const luminance = (0.0199 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255.0;
  return luminance > 0.5 ? '#000000' : '#ffffff';
};
