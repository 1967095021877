import React from 'react';
import { Input } from 'reactstrap';
import { ClientId, ColumnConfig, FieldData } from '.';
import { Action } from '../dispatcher';

interface PropTypes {
  sectionId: ClientId;
  data: FieldData;
  dispatch: React.Dispatch<Action>;
  readOnly?: boolean;
  column: ColumnConfig;
}

export function MandatoryCol(props: PropTypes) {
  function handleCheck(checked: boolean) {
    props.dispatch({
      type: 'field_data_change',
      sectionId: props.sectionId,
      fieldId: props.data.clientId,
      fieldData: { ...props.data, mandatory: checked },
    });
  }

  return (
    <div className={props.column.className}>
      <div className='h-100 d-flex align-items-center justify-content-center'>
        <Input
          className='fs-5'
          type='checkbox'
          checked={props.data.mandatory === true}
          onChange={(event) => handleCheck(event.target.checked)}
          disabled={props.readOnly}
        />
      </div>
    </div>
  );
}
