import { ApplicationStatus } from '../../../entities/Application';
import { JobState } from '../../../entities/JobDetails';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';

interface ActionableApplicationInfo {
  status: ApplicationStatus;
  job?: { status: string };
}

export interface ApplicationActionInfo {
  allowed: boolean;
  disabled: boolean;
  tooltipText?: string;
}

function buildTooltipText(
  validJobStatus: boolean,
  validAplicationStatus: boolean,
  action?: string,
): string {
  if (validJobStatus && validAplicationStatus) {
    return action;
  }

  if (!validJobStatus) {
    return `${action} is only available for published or opened jobs.`;
  }

  if (!validAplicationStatus) {
    return `${action} is not available for draft candidates.`;
  }
}

export function getApplicationActionInfo(
  application: ActionableApplicationInfo,
  isAdmin: boolean,
  role?: JobHiringMemberRole,
  action?: string,
): ApplicationActionInfo {
  const allowed = isAdmin || allowedJobRole(role);
  const validJobStatus = hasActionableJobStatus(application);
  const validApplicationStatus = hasActionableApplicationStatus(application);

  return {
    allowed: allowed,
    disabled: !(validJobStatus && validApplicationStatus),
    tooltipText: buildTooltipText(
      validJobStatus,
      validApplicationStatus,
      action,
    ),
  };
}

function hasActionableJobStatus(application: ActionableApplicationInfo) {
  const status = application.job.status as JobState;
  return status === 'open' || status === 'published';
}

function hasActionableApplicationStatus(
  application: ActionableApplicationInfo,
) {
  const status = application.status;
  return status !== ApplicationStatus.Draft;
}

export function allowedJobRole(role?: JobHiringMemberRole) {
  if (role == null) return false;

  return role === 'recruiter' || role === 'hiring_manager';
}
