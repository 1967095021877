import React from 'react';
import { Integration } from '../../../entities/Integration';
import { IntegrationPanel } from '../../../components/IntegrationPanel';
import { PageTitle } from '../../../components/PageTitle';

interface PropTypes {
  ats_integrations: Integration[];
  hris_integrations: Integration[];
  vcs_integrations: Integration[];
  chat_integrations: Integration[];
  background_check_integrations: Integration[];
}

export default React.memo(function IntegrationSettings(props: PropTypes) {
  return (
    <>
      <PageTitle text={'Integrations'} />
      <IntegrationPanel
        title={'Applicant tracking systems'}
        integrations={props.ats_integrations}
      />
      <IntegrationPanel
        title={'Human resources information systems'}
        integrations={props.hris_integrations}
      />
      <IntegrationPanel
        title={'Video communication systems'}
        integrations={props.vcs_integrations}
      />
      <IntegrationPanel
        title={'Chat and collaboration systems'}
        integrations={props.chat_integrations}
      />
      <IntegrationPanel
        title={'Background check systems'}
        integrations={props.background_check_integrations}
      />
    </>
  );
});
