import React from 'react';
import classNames from 'classnames';

interface PropTypes {
  page: number;
  pageCount: number;
  visiblePageCount: number;
  disabled?: boolean;
  onPageClicked: (page: number) => void;
  backLabel?: string;
  nextLabel?: string;
  hideFirstAndLastPageLinks?: boolean;
  align?: string;
}

function calcStartPage(props: {
  page: number;
  pageCount: number;
  visiblePageCount: number;
}) {
  const offsetLeft = Math.floor(props.visiblePageCount / 2);
  const offsetRight = props.visiblePageCount - 1 - offsetLeft;

  if (props.page - offsetLeft < 1) return 1; // offset to the left exceeds starting page

  if (props.page + offsetRight > props.pageCount) {
    // offset to the right overflows page count
    return Math.max(1, props.pageCount - props.visiblePageCount + 1);
  }
  // otherwise it has enough room to show pages
  return props.page - offsetLeft;
}

function visiblePages(props: {
  page: number;
  pageCount: number;
  visiblePageCount: number;
}): number[] {
  const startPage = calcStartPage({ ...props });

  const endPage = Math.min(
    startPage + props.visiblePageCount - 1,
    props.pageCount,
  );

  return [...Array(endPage - startPage + 1)].map((_, idx) => startPage + idx);
}

function EllipsisPage() {
  return (
    <li className='page-item disabled'>
      <a href='#' className='page-link fs-6 p-1'>
        …
      </a>
    </li>
  );
}

function PageLink(props: {
  page: number;
  onPageClicked: (page: number) => void;
  children: JSX.Element | JSX.Element[] | string | number;
  className?: string;
}) {
  function pageClick(ev, page: number) {
    ev.preventDefault();
    props.onPageClicked(page);
  }

  return (
    <a
      href='#'
      className={classNames('page-link', props.className)}
      onClick={(ev) => pageClick(ev, props.page)}
    >
      {props.children}
    </a>
  );
}

function PageItem(props: {
  disabled?: boolean;
  active?: boolean;
  children: JSX.Element;
}) {
  return (
    <li
      className={classNames(
        'page-item',
        props.disabled && 'disabled',
        props.active && 'active',
      )}
    >
      {props.children}
    </li>
  );
}

function PagesPrefix(props: {
  page: number;
  disabled: boolean;
  onPageClicked: (page: number) => void;
  backLabel?: string;
  hideFirstAndLastPageLinks?: boolean;
}) {
  return (
    <>
      {!props.hideFirstAndLastPageLinks && (
        <PageItem disabled={props.disabled}>
          <PageLink
            className='fs-6'
            page={1}
            onPageClicked={props.onPageClicked}
          >
            First
          </PageLink>
        </PageItem>
      )}
      <PageItem disabled={props.disabled}>
        <PageLink
          className='text-nowrap p-1 semibold'
          page={props.page - 1}
          onPageClicked={props.onPageClicked}
        >
          <i className='bi bi-chevron-left pe-2'></i>
          <span>{props.backLabel || 'Back'}</span>
        </PageLink>
      </PageItem>
    </>
  );
}

function PagesPostfix(props: {
  page: number;
  pageCount: number;
  disabled: boolean;
  onPageClicked: (page: number) => void;
  nextLabel?: string;
  hideFirstAndLastPageLinks?: boolean;
}) {
  return (
    <>
      <PageItem disabled={props.disabled}>
        <PageLink
          className='text-nowrap p-1 semibold'
          page={props.page + 1}
          onPageClicked={props.onPageClicked}
        >
          <span>{props.nextLabel || 'Next'}</span>
          <i className='bi bi-chevron-right ps-2'></i>
        </PageLink>
      </PageItem>
      {!props.hideFirstAndLastPageLinks && (
        <PageItem disabled={props.disabled}>
          <PageLink
            className='fs-6'
            page={props.pageCount}
            onPageClicked={props.onPageClicked}
          >
            Last
          </PageLink>
        </PageItem>
      )}
    </>
  );
}

function Pages(props: {
  page: number;
  pageCount: number;
  visiblePageCount: number;
  disabled?: boolean;
  onPageClicked: (page: number) => void;
}) {
  const navigationPages = visiblePages(props);
  const firstPageVisible = navigationPages[0] === 1;
  const lastPageVisible =
    navigationPages[navigationPages.length - 1] === props.pageCount;
  return (
    <>
      {!firstPageVisible && <EllipsisPage />}
      {navigationPages.map((page) => (
        <PageItem
          key={page}
          disabled={props.disabled}
          active={page === props.page}
        >
          <PageLink
            page={page}
            className='fs-6 p-1'
            onPageClicked={props.onPageClicked}
          >
            {page}
          </PageLink>
        </PageItem>
      ))}
      {!lastPageVisible && <EllipsisPage />}
    </>
  );
}

export function Pagination(props: PropTypes) {
  if (isNaN(props.pageCount)) return null;
  const firstPage = props.page === 1;
  const lastPage = props.page >= props.pageCount;

  return (
    <nav className='app-pagination'>
      <ul
        className={`pagination align-items-center justify-content-${
          props.align || 'end'
        } mb-0`}
      >
        <PagesPrefix
          page={props.page}
          disabled={props.disabled || firstPage}
          onPageClicked={props.onPageClicked}
          backLabel={props.backLabel}
          hideFirstAndLastPageLinks={props.hideFirstAndLastPageLinks}
        />
        <Pages {...props} />
        <PagesPostfix
          page={props.page}
          pageCount={props.pageCount}
          disabled={props.disabled || lastPage}
          onPageClicked={props.onPageClicked}
          nextLabel={props.nextLabel}
          hideFirstAndLastPageLinks={props.hideFirstAndLastPageLinks}
        />
      </ul>
    </nav>
  );
}
