import React from 'react';
import { JobStageTemplateInput } from '../..';
import { SetupPageHeader } from '../../../../../components/SetupPageHeader';
import { JobStageTemplate } from '../../../../../entities/applicant_tracking/JobStageTemplate';

interface PropTypes {
  jobStage?: JobStageTemplateInput;
  onClose: (jobStage?: JobStageTemplate, error?: Error) => void;
  isValid: boolean;
  viewMode: boolean;
}

export function CardHeader(props: PropTypes) {
  if (props.jobStage && props.viewMode) {
    return <div className='fs-2 fw-bold'>{props.jobStage?.name}</div>;
  }

  return (
    <SetupPageHeader
      title={props.jobStage ? `Edit ${props.jobStage?.name}` : 'Add Stage'}
      firstItems={[]}
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: () => props.onClose(),
        },
        {
          title: 'Submit',
          type: 'Save',
          disabled: !props.isValid,
        },
      ]}
    />
  );
}
