import React from 'react';
import { Select, PropTypes as SelectPropTypes, SelectOption } from '../Select';
import { getData } from 'country-list';

interface CountryDropdownPropTypes {
  onChange: (countryCode: string) => void;
  value: string;
}

type PropTypes = CountryDropdownPropTypes &
  Omit<SelectPropTypes, 'options' | 'value' | 'onChange' | 'selected'>;

export function CountryDropdown(props: PropTypes) {
  const countryOptions: SelectOption[] = getData().map((c) => ({
    label: c['name'],
    value: c['code'],
  }));

  return (
    <Select
      {...props}
      options={countryOptions}
      onChange={(option) => props.onChange(option.value)}
      selected={countryOptions.find((o) => o.value === props.value)}
    />
  );
}
