import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { CreateSurveyTemplateRequest } from '../../survey_engine/CreateSurveyTemplateRequest';
import { ScorecardTemplateList } from '../../../../entities/v1/applicant_tracking/ScorecardTemplateList';
import { ScorecardTemplate } from '../../../../entities/v1/applicant_tracking/ScorecardTemplate';
import { UpdateSurveyTemplateRequest } from '../../survey_engine/UpdateSurveyTemplateRequest';

interface Params {
  expand: string[];
  jobId?: number;
}

export class ScorecardTemplateService {
  public static list(params: Params): Promise<ScorecardTemplateList> {
    return ApiService.get<ScorecardTemplateList>(
      '/api/v1/applicant_tracking/scorecard_templates',
      snakecaseKeys(params),
    );
  }

  public static show(
    id: number | string,
    params: Params,
  ): Promise<ScorecardTemplate> {
    return ApiService.get<ScorecardTemplate>(
      `/api/v1/applicant_tracking/scorecard_templates/${id}`,
      snakecaseKeys(params),
    );
  }

  public static create(
    surveyTemplate: CreateSurveyTemplateRequest,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/v1/applicant_tracking/scorecard_templates`,
      snakecaseKeys({ surveyTemplate }),
    );
  }

  public static update(
    id: number,
    surveyTemplate: UpdateSurveyTemplateRequest,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/scorecard_templates/${id}`,
      snakecaseKeys({ surveyTemplate }),
    );
  }
}
