import { SelectOption } from '../components/Select';

export function filteredSelectedOptionsByValue(
  options: SelectOption[],
  selectedOptions: number[],
) {
  if (!selectedOptions) {
    return null;
  }

  return options.filter((option) =>
    selectedOptions.includes(parseInt(option.value)),
  );
}
