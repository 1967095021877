import React, { useCallback, useEffect, useState } from 'react';
import { SurveyLayout } from '../../../../components/survey_engine/SurveyLayout';
import { SurveyPanel } from '../../../../components/survey_engine/SurveyPanel';
import { Button, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router';
import { ReferralConfirmationModal } from './ReferralConfirmationModal';
import { ProspectService } from '../../../services/ProspectService';
import { ProspectInfo } from '../../../../reference_check/entities/ProspectInfo';
import { Controller, useForm } from 'react-hook-form';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';
import { InputFormField } from '../../../../components/InputFormField';
import { Survey } from '../../../../entities/survey_engine/Survey';

interface PropTypes {
  survey: Survey;
}

const SUBMITTED_REFERRAL_PATH = 'referral-done';

export function ReferralScreen(props: PropTypes) {
  const formFieldClassNames = 'mb-3 mt-0';
  const headerFormFieldClassNames = 'mb-2 fs-5';

  const navigate = useNavigate();

  const handleOnCancelClick = useCallback(() => {
    navigate('..');
  }, []);

  const handleSubmitOtherReferral = useCallback(() => {
    reset();
    setIsReferralConfirmationModalOpen(false);
  }, []);

  const handleFinishClick = useCallback(() => {
    navigate(SUBMITTED_REFERRAL_PATH);
  }, []);

  const { register, handleSubmit, formState, reset, control, setValue } =
    useForm<ProspectInfo>({
      mode: 'onBlur',
    });

  const [country, setCountry] = useState<string>(undefined);
  const [region, setRegion] = useState<string>(undefined);
  const [isReferralConfirmationModalOpen, setIsReferralConfirmationModalOpen] =
    useState<boolean>(false);

  const onSubmit = async (prospectInfo: Partial<ProspectInfo>) => {
    await ProspectService.create({
      survey_secure_id: props.survey.secure_id,
      ...prospectInfo,
    });
    setIsReferralConfirmationModalOpen(true);
  };

  useEffect(() => {
    setValue('country', country);
    setCountry(country);
    setValue('region', region);
  }, [country, region]);

  return (
    <SurveyLayout companyInfo={props.survey.company_info}>
      <SurveyPanel className='text-center'>
        <h1 className='mt-4 mb-0'>Fill Referral</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classNames('mx-auto', styles.container)}>
            <Row className='mt-5'>
              <Col>
                <InputFormField
                  type={'text'}
                  registerReturn={register('first_name', { required: true })}
                  fieldName={'First Name'}
                  placeholder={'First Name'}
                  isRequired={true}
                  classNames={formFieldClassNames}
                  headerClassNames={headerFormFieldClassNames}
                />
              </Col>
              <Col>
                <InputFormField
                  type={'text'}
                  registerReturn={register('last_name', { required: true })}
                  fieldName={'Last Name'}
                  placeholder={'Last Name'}
                  isRequired={true}
                  classNames={formFieldClassNames}
                  headerClassNames={headerFormFieldClassNames}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFormField
                  type={'email'}
                  registerReturn={register('email', { required: true })}
                  fieldName={'Email'}
                  placeholder={'referral@email.com'}
                  isRequired={true}
                  classNames={formFieldClassNames}
                  headerClassNames={headerFormFieldClassNames}
                />
              </Col>
              <Col>
                <InputFormField
                  type={'text'}
                  registerReturn={register('job_title', { required: true })}
                  fieldName={'Job Title'}
                  placeholder={'e.g. Software Engineer'}
                  isRequired={true}
                  classNames={formFieldClassNames}
                  headerClassNames={headerFormFieldClassNames}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputFormField
                  type={'url'}
                  registerReturn={register('linkedin_profile_url')}
                  fieldName={'LinkedIn'}
                  placeholder={'LinkedIn profile'}
                  isRequired={false}
                  classNames={formFieldClassNames}
                  headerClassNames={headerFormFieldClassNames}
                />
              </Col>
              <Col>
                <InputFormField
                  type={'text'}
                  registerReturn={register('company')}
                  fieldName={'Company'}
                  placeholder={'Company'}
                  isRequired={false}
                  classNames={formFieldClassNames}
                  headerClassNames={headerFormFieldClassNames}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormFieldHeader
                  fieldName={'Country'}
                  isRequired={false}
                  classNames={headerFormFieldClassNames}
                />
                <Controller
                  name='country'
                  control={control}
                  render={({ field }) => (
                    <CountryDropdown
                      classes={'form-select'}
                      {...field}
                      onChange={setCountry}
                    />
                  )}
                />
              </Col>
              <Col>
                <FormFieldHeader
                  fieldName={'Region'}
                  isRequired={false}
                  classNames={headerFormFieldClassNames}
                />
                <Controller
                  name='region'
                  control={control}
                  render={({ field }) => (
                    <RegionDropdown
                      classes={'form-select'}
                      country={country}
                      {...field}
                      onChange={setRegion}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
          <ReferralConfirmationModal
            submitOtherReferral={handleSubmitOtherReferral}
            finish={handleFinishClick}
            isOpen={isReferralConfirmationModalOpen}
          />
          <div className='d-flex justify-content-center mt-5'>
            <div>
              <Button
                size='lg'
                color='danger'
                className='px-5 me-3'
                onClick={handleOnCancelClick}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                color='primary'
                size='lg'
                className='px-5'
                type='submit'
                disabled={!formState.isValid}
              >
                Fill Referral
              </Button>
            </div>
          </div>
        </form>
      </SurveyPanel>
    </SurveyLayout>
  );
}
