import { Col, Row } from 'reactstrap';
import React from 'react';
import { CnpsFollowUp } from '../../../../../entities/candidate_experience/CnpsFollowUp';
import { AnalyticsPanel } from '../../../../../components/AnalyticsPanel';
import classNames from 'classnames';
import { FollowUpResponsesListTable } from './FollowUpResponsesListTable';
import { FollowUpResponsesTab } from './models/FollowUpResponsesTab';
import { REPORTING_SECTION_CLASS } from '../../../../../utils/pdfHandler';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { FollowUpResponsesListTabNavigation } from './FollowUpResponsesListTabNavigation';
import { PageBreak } from '../../PageBreak';
import { ExpandButton } from '../../../../../components/ExpandButton';
import { NpsClassification } from '../../../../../entities/candidate_experience/NpsClassification';
import { findRatingClassificationByScore } from '../../utils';
import {
  CPS_CLASSIFICATION_LEGEND,
  CPS_DEFAULT_CLASSIFICATION_LEGEND,
} from '../../../../../components/survey_engine/RatingLegend';

const VALID_ANSWERS_MIN_TEXT_LENGTH = 5;

export interface TableEntry {
  text: string;
  score: number;
  jobName: string;
  metadata?: {
    updatedAt?: Date;
  };
}

function mapEntries(cnpsFollowUpAnswers: CnpsFollowUp[]) {
  return cnpsFollowUpAnswers.map((qa) => ({
    text: qa.text,
    score: qa.score,
    jobName: qa.job_name,
    metadata: {
      updatedAt: qa.updated_at_date,
    },
  }));
}

function answersToDisplay(
  currentTab: FollowUpResponsesTab,
  followUpAnswers: TableEntry[],
  standardNpsCalculation: boolean,
) {
  const ratingLegend = standardNpsCalculation
    ? CPS_DEFAULT_CLASSIFICATION_LEGEND
    : CPS_CLASSIFICATION_LEGEND;

  const buildFollowUpAnswersFilter = (classification) => {
    return (answer) =>
      findRatingClassificationByScore(ratingLegend, answer.score) ===
      classification;
  };

  switch (currentTab) {
    case 'detractors':
      return followUpAnswers.filter(
        buildFollowUpAnswersFilter(NpsClassification.Detractor),
      );
    case 'neutrals':
      return followUpAnswers.filter(
        buildFollowUpAnswersFilter(NpsClassification.Neutral),
      );
    case 'promoters':
      return followUpAnswers.filter(
        buildFollowUpAnswersFilter(NpsClassification.Promoter),
      );
    default:
      return followUpAnswers;
  }
}

function questionToDisplay(
  currentTab: FollowUpResponsesTab,
  organizationName: string,
) {
  switch (currentTab) {
    case 'detractors':
      return `What could we improve to get a higher CNPS score at ${organizationName}?`;
    case 'neutrals':
      return `What could we improve to get a higher CNPS score at ${organizationName}?`;
    case 'promoters':
      return `Since there is always room for improvement, what could we additionally do to enhance your experience at ${organizationName}?`;
    default:
      return null;
  }
}

export function FollowUpResponsesListPanel(props: {
  cnpsFollowUpAnswers: CnpsFollowUp[];
  organizationName: string;
  maxRowsCollapsed: number;
  standardNpsCalculation: boolean;
}) {
  const [question, setQuestion] = useState<string | null>(null);
  const [cnpsFollowUpAnswers, setCnpsFollowUpAnswers] = useState<TableEntry[]>(
    [],
  );
  const [maxElements, setMaxElements] = useState<number>(
    props.maxRowsCollapsed,
  );
  const [enabledViewLess, setEnabledViewLess] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const currentTab = (searchParams.get('tab') as FollowUpResponsesTab) || 'all';

  if (props.cnpsFollowUpAnswers.length === 0) return null;

  const validFollowUpAnswers = mapEntries(
    props.cnpsFollowUpAnswers
      .filter((answer) => answer.text.length >= VALID_ANSWERS_MIN_TEXT_LENGTH)
      .sort((a, b) => (a.updated_at_date < b.updated_at_date ? 1 : -1)),
  );
  useEffect(() => {
    setCnpsFollowUpAnswers(
      answersToDisplay(
        currentTab,
        validFollowUpAnswers,
        props.standardNpsCalculation,
      ).slice(0, props.maxRowsCollapsed * 400),
    );
    setQuestion(questionToDisplay(currentTab, props.organizationName));
  }, [currentTab]);

  const totalVisibleElements = cnpsFollowUpAnswers.slice(
    0,
    maxElements * 2,
  ).length;

  return (
    <>
      <PageBreak />
      <Row className={classNames(REPORTING_SECTION_CLASS)}>
        <Col xs='12'>
          <AnalyticsPanel
            title={
              <div className={classNames('mb-0', 'fw-bold')}>
                CNPS Follow-up Question
              </div>
            }
            collapsable={true}
            isOpen={true}
          >
            <>
              <FollowUpResponsesListTabNavigation
                currentTab={currentTab}
                searchParams={searchParams}
                initialMaxElements={props.maxRowsCollapsed}
                setMaxElements={setMaxElements}
                setEnabledViewLess={setEnabledViewLess}
              />
              <FollowUpResponsesListTable
                followUpAnswers={cnpsFollowUpAnswers}
                question={question}
                maxRowsCollapsed={maxElements}
                standardNpsCalculation={props.standardNpsCalculation}
              />
              <ExpandButton
                initialMaxElements={props.maxRowsCollapsed}
                maxElements={maxElements}
                setMaxElements={setMaxElements}
                setEnabledViewLess={setEnabledViewLess}
                elementNamePlural={null}
                enabledViewLess={enabledViewLess}
                showBorder={true}
                elementsLength={cnpsFollowUpAnswers.length}
                totalVisibleElements={totalVisibleElements}
              />
            </>
          </AnalyticsPanel>
        </Col>
      </Row>
    </>
  );
}
