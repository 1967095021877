import { Col } from 'reactstrap';
import React from 'react';

export function CampaignReportingHeader() {
  return (
    // This is a way to mimicate the accordion margins (CampaignRow)
    <div className='d-flex flex-nowrap fw-semibold fs-5 mx-4 pe-3 my-3'>
      <Col xs='3'>Campaign / Job Post</Col>
      <Col xs='2'>Updated at</Col>
      <Col xs='2'>Date Posted</Col>
      <Col xs='1'>End Date</Col>
      <Col xs='1'>Clicks</Col>
      <Col xs='1'>Cost</Col>
      <Col xs='2'>Status</Col>
    </div>
  );
}
