import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface PropTypes {
  justifyContentClass?: string;
  label?: string;
  labelClasses?: string;
  wrapperClasses?: string;
  progress: number;
  progressBarColorClass?: string;
}

function ProgressPercentageBar(props: {
  progressBarColorClass: string;
  progress: number;
  wrapperClasses: string;
}) {
  return (
    <div
      className={classNames(
        'fw-semibold',
        'bg-blue-gray-light',
        'text-xs-end',
        'text-xl-start',
        props.wrapperClasses,
        styles.wrapper,
      )}
    >
      <div
        className={classNames(
          props.progressBarColorClass || 'bg-pin',
          'text-end',
          'align-items-center',
          'h-100',
          styles.progress,
        )}
        style={{ width: `${props.progress}%` }}
      />
    </div>
  );
}

function ProgressLabel(props: {
  labelClasses: string;
  label: string;
  progress: number;
}) {
  return (
    <span
      className={classNames(
        'text-end pe-3',
        props.labelClasses || 'fs-5 fw-semibold',
      )}
    >
      {props.label ? props.label : `${props.progress}%`}
    </span>
  );
}

export function ProgressBar(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex',
        'text-nowrap',
        'align-items-center',
        props.justifyContentClass || 'justify-content-center',
      )}
    >
      <ProgressLabel
        labelClasses={props.labelClasses}
        label={props.label}
        progress={props.progress}
      />
      <ProgressPercentageBar
        progressBarColorClass={props.progressBarColorClass}
        progress={props.progress}
        wrapperClasses={props.wrapperClasses}
      />
    </div>
  );
}
