import React from 'react';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Input } from 'reactstrap';

interface PropTypes {
  setEquity: (equity: number) => void;
  equity: number;
}

export function EquityInput(props: PropTypes) {
  return (
    <>
      <FormFieldHeader fieldName={'Equity'} isRequired={true} />
      <Input
        type='number'
        className='fs-5'
        placeholder='Insert equity (e.g. 1500)'
        defaultValue={props.equity}
        onChange={(event) =>
          props.setEquity(event.target.value as unknown as number)
        }
      />
    </>
  );
}
