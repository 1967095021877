import { Row } from 'reactstrap';
import classNames from 'classnames';
import { NameInput } from './NameInput';
import styles from './styles.module.scss';
import { useForm, useWatch } from 'react-hook-form';
import { CardHeader } from './CardHeader';
import { InterviewToggleInput } from './InterviewToggleInput';
import { ScoreCardTemplateInput } from './ScoreCardTemplateInput';
import { InterviewKitTemplateInput } from './InterviewKitTemplateInput';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import {
  JobWorkflowEditContext,
  JobWorkflowEditContextProps,
  JobStageTemplateInput,
} from '..';
import { UpsertStagePlanTemplateDto } from '../DTOs/UpsertStagePlanTemplateDto';
import { JobStageTemplate } from '../../../../entities/applicant_tracking/JobStageTemplate';
import { StagePlanTemplate } from '../../../../entities/applicant_tracking/StagePlanTemplate';
import { BriefScorecardTemplate } from '../../../../entities/applicant_tracking/ScorecardTemplate';
import { InterviewKitTemplate } from '../../../../entities/applicant_tracking/InterviewKitTemplate';
import { StagePlanTemplateService } from '../../../../services/applicant_tracking/StagePlanTemplateService';
import { InterviewKitTemplateService } from '../../../../services/applicant_tracking/InterviewKitTemplateService';
import { saveStageMoves } from './utils';
import { AlertMessage } from '../../../../components/AlertMessage';
import { ScorecardTemplateService } from '../../../../services/v1/applicant_tracking/ScorecardTemplateService';
import { ScorecardTemplate } from '../../../../entities/v1/applicant_tracking/ScorecardTemplate';
import { OfferToggleInput } from './OfferToggleInput';
import { BulkReviewToggle } from './BulkReviewToggle';
import { RedactionCandidateToggle } from './RedactionCandidateToggle';
import { InterviewTemplateToggleInput } from './InterviewTemplateToggleInput';
import { HorizontalDivider } from '../../../../components/HorizontallDivider';
import { InterviewTemplate } from '../../../../entities/v1/applicant_tracking/InterviewTemplate';

interface PropTypes {
  redactionEnabled: boolean;
  jobStage?: JobStageTemplateInput;
  onClose: (jobStage?: JobStageTemplate, error?: Error) => void;
  interviewTemplateEnabled: boolean;
}

const defaultFormValues = (jobStage: JobStageTemplateInput) => {
  const nameAsOption = {
    value: jobStage?.name,
    label: jobStage?.name,
  };

  return {
    name: jobStage?.name ? nameAsOption : null,
    interviewTemplateId: jobStage?.interviewTemplate?.id ?? null,
    interviewKitTemplateId: jobStage?.interviewKitTemplate?.id ?? null,
    scorecardTemplateId: jobStage?.scorecardTemplate?.id ?? null,
    bulkReviewEnabled: jobStage?.bulkReviewEnabled ?? false,
    offerEnabled: jobStage?.offerEnabled ?? false,
    redactionEnabled: jobStage?.redactionEnabled ?? false,
  };
};

const findScorecardTemplate = (
  id: number,
  scorecardTemplates: ScorecardTemplate[],
) => {
  const template = scorecardTemplates.find((template) => template.id == id);
  return template
    ? ({
        id: template.id,
        name: template.surveyTemplate.name,
      } as BriefScorecardTemplate)
    : null;
};

const createJobStageTemplateInput = (jobStages: JobStageTemplateInput[]) => {
  const highestOrder = jobStages.reduce((maxOrder, jobStage) => {
    return Math.max(maxOrder, jobStage.order);
  }, 0);
  const highestIndex = jobStages.reduce((maxIndex, jobStage) => {
    return Math.max(maxIndex, jobStage.index);
  }, 0);

  return {
    name: '',
    id: null,
    order: highestOrder + 1,
    index: highestIndex + 1,
    interviewTemplate: null,
    interviewKitTemplate: null,
    scorecardTemplate: null,
    uniqueKey: window.crypto.randomUUID(),
  } as JobStageTemplateInput;
};

function EditStageAlert(props: {
  jobStage: JobStageTemplate;
  pageContext: JobWorkflowEditContextProps;
}) {
  if (!props.jobStage || !props.pageContext.editMode) {
    return;
  }

  return (
    <AlertMessage
      hasOverlay={false}
      icon={{ name: 'bi-info-circle' }}
      className='w-100 mt-3 fs-5 bg-light-danger'
      text={
        <span>
          <b>Important! </b>Editing a template will impact all jobs that are
          assigned to it.
        </span>
      }
    />
  );
}

function disableOffer(pageContext: JobWorkflowEditContextProps) {
  if (pageContext.viewMode) {
    return true;
  }

  const alreadyEnabled = pageContext.jobStages?.find((v) => v.offerEnabled);

  if (alreadyEnabled && alreadyEnabled.id !== pageContext.focusedJobStage?.id) {
    return true;
  }

  return false;
}

function disableRedaction(viewMode: boolean, hasInterviews: boolean) {
  if (viewMode || hasInterviews) {
    return true;
  }

  return false;
}

export function StagePlanTemplateBody(props: PropTypes) {
  const ref = useRef(null);
  const interviewKitTemplateFormName = 'interviewKitTemplateId';
  const scorecardTemplateFormName = 'scorecardTemplateId';
  const redactionEnabledFormName = 'redactionEnabled';

  const pageContext: JobWorkflowEditContextProps = useContext(
    JobWorkflowEditContext,
  );
  const [hasInterviews, setHasInterviews] = useState<boolean>(
    !!props.jobStage?.interviewKitTemplate?.id,
  );
  const [interviewTemplate, setInterviewTemplate] = useState<InterviewTemplate>(
    props.jobStage?.interviewTemplate,
  );
  const [interviewKitTemplates, setInterviewKitTemplates] = useState<
    InterviewKitTemplate[]
  >([]);
  const [scoreCardTemplates, setScoreCardTemplates] = useState<
    ScorecardTemplate[]
  >([]);
  const [stagePlanTemplates, setStagePlanTemplates] = useState<
    StagePlanTemplate[]
  >([]);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    resetField,
    reset,
    watch,
  } = useForm<UpsertStagePlanTemplateDto>({
    mode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultFormValues(props.jobStage);
    }, [props.jobStage?.uniqueKey]),
  });

  useEffect(() => {
    pageContext.setIsLoading(true);
    Promise.all([
      InterviewKitTemplateService.list(),
      ScorecardTemplateService.list({
        expand: [
          'survey_template',
          'survey_template.sections',
          'survey_template.sections.questions',
        ],
      }),
      StagePlanTemplateService.list(),
    ])
      .then(
        ([interviewKitTemplates, scoreCardTemplates, stagePlanTemplates]) => {
          setInterviewKitTemplates(interviewKitTemplates);
          setScoreCardTemplates(scoreCardTemplates.scorecardTemplates);
          setStagePlanTemplates(stagePlanTemplates);
        },
      )
      .finally(() => {
        pageContext.setIsLoading(false);
        ref?.current?.scrollIntoView({ behavior: 'smooth' });
      });
  }, []);

  useEffect(() => {
    setHasInterviews(!!props.jobStage?.interviewKitTemplate?.id);
    reset(defaultFormValues(props.jobStage));
  }, [props.jobStage?.uniqueKey]);

  useEffect(() => {
    if (!hasInterviews) {
      resetField(interviewKitTemplateFormName, { defaultValue: null });
      resetField(scorecardTemplateFormName, { defaultValue: null });
    }
  }, [hasInterviews]);

  const onSubmit = handleSubmit((data) => {
    const stages = pageContext.jobStages || [];
    const stageToUpdate =
      pageContext.focusedJobStage || createJobStageTemplateInput(stages);

    saveStageMoves(stageToUpdate.name, data.name.value, true, pageContext);

    stageToUpdate.name = data.name.value;
    stageToUpdate.bulkReviewEnabled = data.bulkReviewEnabled ?? false;
    stageToUpdate.offerEnabled = data.offerEnabled ?? false;
    stageToUpdate.redactionEnabled = data.redactionEnabled ?? false;
    stageToUpdate.interviewTemplate = interviewTemplate;
    stageToUpdate.interviewKitTemplate = interviewKitTemplates.find(
      (template) => template.id == data.interviewKitTemplateId,
    );
    stageToUpdate.scorecardTemplate = findScorecardTemplate(
      data.scorecardTemplateId,
      scoreCardTemplates,
    );

    !props.jobStage?.uniqueKey &&
      pageContext.setJobStages([...stages, stageToUpdate]);

    props.onClose();
  });

  const interviewKitTemplateId = useWatch({
    control: control,
    name: interviewKitTemplateFormName,
  });
  const scorecardTemplateId = useWatch({
    control: control,
    name: scorecardTemplateFormName,
  });
  const redactionEnabled = useWatch({
    control: control,
    name: redactionEnabledFormName,
  });
  const isFormValid =
    isValid &&
    (!hasInterviews || (interviewKitTemplateId && scorecardTemplateId));

  return (
    <form onSubmit={onSubmit} ref={ref}>
      <DeprecatedPanel
        className='border border-info'
        additionalInnerContainerClassNames={classNames(
          styles['job-stage-container'],
        )}
      >
        <CardHeader
          {...props}
          isValid={!!isFormValid}
          viewMode={pageContext.viewMode}
        />
        <div className={'w-50'}>
          <EditStageAlert jobStage={props.jobStage} pageContext={pageContext} />
          <Row className='mt-4'>
            <NameInput
              control={control}
              register={register}
              errors={errors}
              selectedStagePlanTemplates={pageContext.jobStages}
              availableStagePlanTemplates={stagePlanTemplates}
              disabled={pageContext.viewMode}
              focusedStage={props.jobStage}
            />
          </Row>
          <BulkReviewToggle
            disabled={pageContext.viewMode}
            formControl={control}
          />
          <OfferToggleInput
            disabled={disableOffer(pageContext)}
            formControl={control}
          />
          <RedactionCandidateToggle
            tooltip={
              !pageContext.viewMode &&
              hasInterviews &&
              'Including an interview in this stage will disable the candidate information redaction.'
            }
            disabled={disableRedaction(pageContext.viewMode, hasInterviews)}
            formControl={control}
            redactionEnabled={props.redactionEnabled}
          />
          <HorizontalDivider classNames='my-4' />
          <InterviewToggleInput
            tooltip={
              !pageContext.viewMode &&
              redactionEnabled &&
              'Allowing interviews for this stage is disabled when Anonymised Candidate Screening is enabled.'
            }
            checked={hasInterviews}
            onToggle={setHasInterviews}
            disabled={pageContext.viewMode || redactionEnabled}
          />
          {!pageContext.isLoading && (
            <>
              <InterviewKitTemplateInput
                control={control}
                interviewKitTemplates={interviewKitTemplates}
                enabled={hasInterviews && !pageContext.viewMode}
                formResetField={resetField}
              />
              <ScoreCardTemplateInput
                control={control}
                scoreCardTemplates={scoreCardTemplates}
                enabled={hasInterviews && !pageContext.viewMode}
                formResetField={resetField}
              />
            </>
          )}
          {props.interviewTemplateEnabled && (
            <InterviewTemplateToggleInput
              formControl={control}
              formResetField={resetField}
              formWatch={watch}
              hasInterviews={hasInterviews}
              setInterviewTemplate={setInterviewTemplate}
              viewMode={pageContext.viewMode}
            />
          )}
        </div>
      </DeprecatedPanel>
    </form>
  );
}
