import React from 'react';
import { Control } from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { SelectFormHook } from '../../../../../../../components/SelectFormHook';
import { buildJobOpeningsOptions } from '../../../../../Jobs/SetupJobPageRoutes/SetupJobDetailsPage/JobOpeningsInput';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';

interface PropTypes {
  formControl: Control<JobRequisitionDto>;
}

export function JobOpenings(props: PropTypes) {
  const required = true;

  return (
    <Col xs={3}>
      <FormFieldHeader
        fieldName='Job Openings'
        isRequired={required}
        classNames='fw-semibold'
      />
      <SelectFormHook<JobRequisitionDto>
        placeholder='Select number of openings'
        formHookContext={{
          controllerName: 'jobOpenings',
          formControl: props.formControl,
          required: required,
        }}
        options={buildJobOpeningsOptions()}
      />
    </Col>
  );
}
