import { ApiService } from '../../ApiService';
import { StagePlanTemplate } from '../../../entities/applicant_tracking/StagePlanTemplate';

export class StagePlanTemplateService {
  public static list(): Promise<StagePlanTemplate[]> {
    return ApiService.get<StagePlanTemplate[]>(
      '/api/applicant_tracking/stage_plan_templates',
    );
  }
}
