import React from 'react';
import { Input } from 'reactstrap';
import { ClientId, ColumnConfig, FieldData } from '.';
import { Action } from '../dispatcher';

interface PropTypes {
  sectionId: ClientId;
  data: FieldData;
  dispatch: React.Dispatch<Action>;
  readOnly?: boolean;
  column: ColumnConfig;
}

export function TextCol(props: PropTypes) {
  function handleTitleChange(title: string) {
    props.dispatch({
      type: 'field_data_change',
      sectionId: props.sectionId,
      fieldId: props.data.clientId,
      fieldData: { ...props.data, title },
    });
  }

  return (
    <div className={props.column.className}>
      <Input
        className='fs-5'
        placeholder={props.column.placeholder ?? 'Insert field label'}
        value={props.data.title}
        onChange={(event) => handleTitleChange(event.target.value)}
        disabled={props.readOnly}
      />
    </div>
  );
}
