import React from 'react';
import { shortFormatDate } from '../../../utils/timeFormat';
import { Interview } from '../../../entities/Interview';
import { Chip, ChipColor } from '../../../components/Chip';
import { IconSpan } from '../../../components/IconSpan';

interface PropTypes {
  interview: Interview;
}

export function ScorecardAssistantHeader(props: PropTypes) {
  const interviewURL = `${new URL(window.location.href).origin}/interviews/${
    props.interview.id
  }`;

  return (
    <div className='mb-3'>
      <div className='d-flex row justify-content-between align-items-center mt-2 mb-3'>
        <div className='col-7'>
          <h2 className='mb-0 fs-2'>
            {props.interview.application.candidate.name}
          </h2>
        </div>
        <div className='col-5 text-end fs-6'>
          <a
            href={interviewURL}
            target='_blank'
            rel='noreferrer'
            className='text-blue'
          >
            View Interview <i className='bi-box-arrow-up-right ms-2'></i>
          </a>
        </div>
      </div>
      <div className='d-flex gap-2'>
        <Chip className='fw-normal' color={ChipColor.DangerLight}>
          {props.interview.stage}
        </Chip>
        <Chip className='fw-semibold' color={ChipColor.BlueLight}>
          <IconSpan
            spanText={shortFormatDate(props.interview.scheduled_date)}
            icon={{ name: 'bi-calendar' }}
          />
        </Chip>
      </div>
    </div>
  );
}
