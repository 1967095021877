import React from 'react';
import { SurveyQuestionAnswer } from '../../../entities/SurveyQuestionAnswer';
import { SurveyQuestionField } from '../../../entities/SurveyQuestionField';
import { BooleanField } from './fields/BooleanField';
import { OptionSetField } from './fields/OptionSetField';
import { OptionsField } from './fields/OptionsField';
import { MultiOptionsField } from './fields/MultiOptionsField';
import { RatingField } from './fields/RatingField';
import { TextField } from './fields/TextField';

interface PropTypes {
  field: SurveyQuestionField;
  answer: SurveyQuestionAnswer;
  onChangeAnswer: (
    fieldId: number,
    value: string,
    optionIds?: number[],
  ) => void;
}

export function SurveyField({ field, answer, onChangeAnswer }: PropTypes) {
  const fieldAnswer = answer?.fields?.find((f) => f.field_id === field.id);
  if (fieldAnswer === undefined) return null;

  if (field.type === 'rating') {
    return (
      <RatingField
        field={field}
        fieldAnswer={fieldAnswer}
        onChangeAnswer={onChangeAnswer}
      />
    );
  } else if (field.type === 'options') {
    return (
      <OptionsField
        field={field}
        fieldAnswer={fieldAnswer}
        onChangeAnswer={onChangeAnswer}
      />
    );
  } else if (field.type === 'multi_options') {
    return (
      <MultiOptionsField
        field={field}
        fieldAnswer={fieldAnswer}
        onChangeAnswer={onChangeAnswer}
      />
    );
  } else if (field.type === 'option_set') {
    return (
      <OptionSetField
        field={field}
        fieldAnswer={fieldAnswer}
        onChangeAnswer={onChangeAnswer}
      />
    );
  } else if (field.type === 'text') {
    return (
      <TextField
        field={field}
        fieldAnswer={fieldAnswer}
        onChangeAnswer={onChangeAnswer}
      />
    );
  } else if (field.type === 'boolean') {
    return (
      <BooleanField
        field={field}
        fieldAnswer={fieldAnswer}
        onChangeAnswer={onChangeAnswer}
      />
    );
  }

  return null;
}
