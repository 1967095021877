import classNames from 'classnames';
import React from 'react';
import { Icon } from '../../../../components/Icon';

export function AIDisclaimer(props: { text: string }) {
  return (
    <div
      className={classNames(
        'bg-gray',
        'fs-5',
        'py-2',
        'px-3',
        'rounded-2',
        'text-center',
      )}
    >
      <Icon name='bi-stars' className='text-info' /> {props.text}
    </div>
  );
}
