import React, { ReactElement } from 'react';
import { Controller, ControllerRenderProps, Path } from 'react-hook-form';
import { ControllerContext } from '../../utils/reactHookForms';
import { validationRule } from './validation';

export interface PropTypes<ControlType> {
  formHookContext?: ControllerContext<ControlType>;
  render: (
    field: ControllerRenderProps<ControlType, Path<ControlType>>,
  ) => ReactElement;
}

export function RichTextEditor<ControlType>(props: PropTypes<ControlType>) {
  if (props.formHookContext == null) return null;

  return (
    <Controller
      control={props.formHookContext.formControl}
      name={props.formHookContext.controllerName as Path<ControlType>}
      rules={validationRule(props.formHookContext.required)}
      render={({ field }) => props.render(field)}
    />
  );
}
