import { UseFormRegister } from 'react-hook-form';
import { FormValues } from '../UserInvitationPage';
import React from 'react';
import { SelectOption } from '../../../components/Select';
import { NameAndLastNameRow } from '../UserInvitationPage/NameAndLastNameRow';
import { EmailRow } from '../UserInvitationPage/EmailRow';
import { Col } from 'reactstrap';
import { LabelledSelect } from '../../../components/LabelledSelect';

interface PropTypes {
  register: UseFormRegister<FormValues>;
  selectedRole: SelectOption;
  setSelectedRole: (option: SelectOption) => void;
  invalidInput: boolean;
}

export function InviteUserForm(props: PropTypes) {
  return (
    <form>
      <div className='item pt-3'>
        <NameAndLastNameRow register={props.register} isRequired={false} />
        <EmailRow
          register={props.register}
          isDisabled={false}
          placeholder={'Insert email'}
          isRequired={true}
          invalidInput={props.invalidInput}
        />
      </div>
      <div className='row mb-3'>
        <Col sm={12}>
          <LabelledSelect
            options={[
              { value: 'basic', label: 'Basic' },
              { value: 'admin', label: 'Admin' },
            ]}
            selected={props.selectedRole}
            label={'Role'}
            onChange={(value) => props.setSelectedRole(value)}
            labelClassName={'fs-5'}
            testId={'role_select'}
          />
        </Col>
      </div>
    </form>
  );
}
