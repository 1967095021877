import React from 'react';
import { FormEvent } from 'react';
import { CheckboxPillGroup } from '../../../../components/CheckboxPillGroup';
import { FeedbackFilters, FeedbackType } from '.';
import { CheckboxPill } from '../../../../components/CheckboxPill';

interface PropTypes {
  filters: FeedbackFilters;
  setFilters: (filters: FeedbackFilters) => void;
}

const FEEDBACK_TYPE_OPTIONS = [
  { id: 'scorecard', label: 'Scorecard', value: 'scorecard' },
  {
    id: 'candidate_rating',
    label: 'Candidate Rating',
    value: 'candidate_rating',
  },
];

export function FeedbackTypeFilter(props: PropTypes) {
  return (
    <CheckboxPillGroup
      selectedValue={props.filters?.feedbackType || 'scorecard'}
      onChange={(event: FormEvent<HTMLInputElement>) =>
        props.setFilters({
          ...props.filters,
          feedbackType: event.currentTarget.value as FeedbackType,
        })
      }
      childrenClassName='me-2 mt-0'
    >
      {FEEDBACK_TYPE_OPTIONS.map((option) => (
        <CheckboxPill
          key={option.id}
          id={option.id}
          label={option.label}
          value={option.value}
        />
      ))}
    </CheckboxPillGroup>
  );
}
