import React from 'react';
import { Col } from 'reactstrap';
import { InputFormField } from '../../../../../components/InputFormField';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { UpsertOfferLetterTemplateDto } from '../UpsertEmailTemplateDto';
import classNames from 'classnames';

interface PropTypes {
  errors: FieldErrors;
  value?: string;
  disabled?: boolean;
  register?: UseFormRegister<UpsertOfferLetterTemplateDto>;
}

export function NameInput(props: PropTypes) {
  return (
    <Col xs={6}>
      <InputFormField
        placeholder='Insert email template name'
        isRequired={true}
        disabled={props.disabled}
        value={props.value}
        fieldName='Name'
        classNames={classNames(
          props.errors.name && 'is-invalid',
          'fs-5 fw-normal',
        )}
        registerReturn={props.register?.('name', { required: true })}
        type='text'
      />
    </Col>
  );
}
