import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EMPTY_STATE_EDITOR_A } from '../../validation';

interface PropTypes {
  showEmptyUpdate?: boolean;
  editorState?: string;
}

export function UpdateStatePlugin(props: PropTypes) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // The setTimeout is needed to avoid the warning "Warning: flushSync was called from inside a
    // lifecycle method". Reference: https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1546629928
    setTimeout(() => {
      if (props.editorState != null) {
        editor.setEditorState(editor.parseEditorState(props.editorState));
      } else {
        if (props.showEmptyUpdate) {
          editor.setEditorState(editor.parseEditorState(EMPTY_STATE_EDITOR_A));
        }
      }
    });
  }, [editor, props.editorState]);

  return null;
}
