import React from 'react';
import styles from './styles.module.scss';

const MIN_RATE = 1.0;
const MAX_RATE = 2.5;
const STEP = 0.25;

interface PropTypes {
  playbackRate: number;
  onPlaybackRateChange: (playbackRate: number) => void;
}

export const PlaybackRatePicker = React.memo(function PlaybackRatePicker(
  props: PropTypes,
) {
  const options = [];
  for (let i = MAX_RATE; i >= MIN_RATE; i -= STEP) options.push(i);

  const activeStyle = `${styles.ratePickerOption} ${styles.active}`;

  return (
    <div className={styles.ratePicker}>
      <div className={styles.ratePickerOptions}>
        {options.map((rate) => (
          <a
            key={rate}
            href='#playbackRate'
            onClick={() => props.onPlaybackRateChange(rate)}
          >
            <div
              className={
                props.playbackRate === rate
                  ? activeStyle
                  : styles.ratePickerOption
              }
            >
              {rate}x
            </div>
          </a>
        ))}
      </div>
      <div>
        <a className={styles['rate-picker-link']} href='#speed'>
          {props.playbackRate}x
        </a>
      </div>
    </div>
  );
});
