import React from 'react';
import { User } from '../../entities/User';
import { Header } from './Header';
import { Body } from './Body';

interface Proptypes {
  user: User;
  widgets: JSX.Element[];
}

export function HomePageLayout(props: Proptypes) {
  return (
    <>
      <Header user={props.user} />
      <Body widgets={props.widgets} />
    </>
  );
}
