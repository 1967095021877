import snakecaseKeys from 'snakecase-keys';
import { appendRedirectUri } from '../url';

export function bulkApplicationReviewUrl(
  applicationIds: number[],
  jobStageId?: number,
  jobPostId?: number,
) {
  const baseUrl = '/applicant_tracking/bulk_application_review';

  const encodedParams = btoa(
    JSON.stringify(snakecaseKeys({ applicationIds, jobPostId, jobStageId })),
  );

  return appendRedirectUri(baseUrl) + `#${encodedParams}`;
}

export function getApplicationIdsToBulkReview(): number[] {
  const urlHash = window.location.hash;

  if (!urlHash) {
    return [];
  }

  const json = JSON.parse(atob(urlHash.slice(1)));

  return json['application_ids'];
}
