import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import classNames from 'classnames';
import { Label } from '../Label';

export function RolePositionFormGroup(props: {
  rolePosition: string;
  hidden: boolean;
  validated: boolean;
  onRolePositionChanged: (rolePosition: string) => void;
}) {
  return (
    !props.hidden && (
      <FormGroup check className='mt-3'>
        <Label name='role' check size='sm' isRequired>
          Role or Position Title
        </Label>
        <Input
          className={classNames('form-control', {
            'is-invalid': props.validated && !props.rolePosition?.length,
          })}
          id='bgc-email'
          onChange={(v) => props.onRolePositionChanged(v.target.value)}
          value={props.rolePosition}
        />
      </FormGroup>
    )
  );
}
