import { Role } from '../../entities/Role';
import { SurveyQuestionAnswer } from '../../entities/survey_engine/SurveyQuestionAnswer';
import { SurveyQuestionCondition } from '../../entities/survey_engine/SurveyQuestionCondition';
import { SurveyQuestionFieldType } from '../../entities/survey_engine/SurveyQuestionFieldType';

function isRatingValid(
  answerValue: number,
  condition: SurveyQuestionCondition,
): boolean {
  return (
    answerValue !== null &&
    answerValue >= condition.min_value &&
    answerValue <= condition.max_value
  );
}

function isMultiOptionValid(
  answerOption: number[] | Role[],
  condition: SurveyQuestionCondition,
): boolean {
  return (
    answerOption.length === 1 &&
    answerOption[0] === condition.questionFieldOptionId
  );
}

export function setQuestionVisible(
  answer: SurveyQuestionAnswer,
  condition: SurveyQuestionCondition,
): boolean {
  if (condition.type === SurveyQuestionFieldType.Rating) {
    return isRatingValid(answer.fields[0].value as number, condition);
  } else if (condition.type === SurveyQuestionFieldType.MultiOptions) {
    return isMultiOptionValid(answer.fields[0].option_ids, condition);
  }

  return false;
}
