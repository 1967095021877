import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import { HiringMemberSettingsType } from '../../../../../entities/HiringMemberSettingsType';
import { UseMutationResult } from 'react-query';

interface PropTypes {
  title: string;
  activateText: string;
  hiringMemberSettingsType: HiringMemberSettingsType;
  organizationAutoRecordingEnabled?: boolean;
  organizationOptoutEnabled?: boolean;
  setSettingEnabled: (settingEnabled: boolean) => void;
  mutation: UseMutationResult;
  settingsStatus: string;
}

function SubText(props: { title: string; activateText: string }) {
  return (
    <div>
      <div className='fs-4 fw-semibold'>
        Enable {props.title} for all hiring members
      </div>
      <div className='fw-normal fs-5 text-dark-200'>{props.activateText}</div>
    </div>
  );
}

function EnableButtons(props: {
  handleOnClick: (enabled: boolean) => void;
  settingsStatus: string;
}) {
  return (
    <div>
      <Button
        color='secondary'
        size='md'
        onClick={() => props.handleOnClick(false)}
        className='me-3'
        disabled={props.settingsStatus == 'Disabled'}
      >
        Disable All
      </Button>
      <Button
        color='primary'
        size='md'
        onClick={() => props.handleOnClick(true)}
        disabled={props.settingsStatus == 'Enabled'}
      >
        Enable All
      </Button>
    </div>
  );
}

export function HiringMemberSettingsOrganizationPanel(props: PropTypes) {
  function handleOnClick(enable: boolean) {
    props.setSettingEnabled(enable);
    if (props.hiringMemberSettingsType == 'recording_consent_optout') {
      props.mutation.mutate({
        recordingConsentType: enable ? 'optout' : null,
      });
    } else {
      props.mutation.mutate(enable);
    }
  }
  return (
    <div className='app-card app-card-account shadow-sm d-flex flex-column align-items-start mt-3 mb-4 p-3 p-xxl-4'>
      <div className={classNames('app-card-body w-100 px-3')}>
        <h2>Organization</h2>
        <div className='mt-4 d-flex align-items-center justify-content-between'>
          <SubText title={props.title} activateText={props.activateText} />
          <EnableButtons
            handleOnClick={handleOnClick}
            settingsStatus={props.settingsStatus}
          />
        </div>
      </div>
    </div>
  );
}
