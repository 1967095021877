import React from 'react';
import { Interview } from '../../../../entities/Interview';
import { ActionPoint } from '../../../../entities/ActionPoint';
import { AINotesSubTab } from '../../../../entities/AINotesSubTab';
import { OverviewSummary } from './OverviewSummary';
import { AttributeNotes } from './AttributeNotes';
import { ActionPointsTab } from './ActionPoints';
import { Questions } from './Questions';

interface PropTypes {
  interview: Interview;
  atsEnabled: boolean;
  onSelectTimestamp: (timestamp: number) => void;
  onUpdateActionPoints: (actionPoints: ActionPoint[]) => void;
  selectedAINotesSubTab: AINotesSubTab;
  onCustomizing: (customizing: boolean) => void;
  isCustomizingSummary: boolean;
  customSummaryEnabled: boolean;
  playTimestamp: number;
  openExternalLink?: boolean;
}

export function SelectedSummary(props: PropTypes) {
  let subTab = null;

  switch (props.selectedAINotesSubTab) {
    case 'summary':
      subTab = (
        <OverviewSummary
          interview={props.interview}
          atsEnabled={props.atsEnabled}
          onSelectTimestamp={props.onSelectTimestamp}
          onUpdateActionPoints={props.onUpdateActionPoints}
          onCustomizing={props.onCustomizing}
          isCustomizingSummary={props.isCustomizingSummary}
          customSummaryEnabled={props.customSummaryEnabled}
        />
      );
      break;
    case 'attributes':
      subTab = <AttributeNotes interview={props.interview} />;
      break;
    case 'action_points':
      subTab = (
        <ActionPointsTab
          interview={props.interview}
          atsEnabled={props.atsEnabled}
          onSelectTimestamp={props.onSelectTimestamp}
          onUpdateActionPoints={props.onUpdateActionPoints}
          openExternalLink={props.openExternalLink}
        />
      );
      break;
    case 'questions':
      subTab = (
        <Questions
          interview={props.interview}
          playTimestamp={props.playTimestamp}
          onSelectTimestamp={props.onSelectTimestamp}
        />
      );
  }

  return subTab;
}
