import React, { useCallback, useEffect } from 'react';
import { PlaybackRatePicker } from '../PlaybackRatePicker';
import { VolumeSlider } from '../VolumeSlider';
import forwardButton from '../../../images/forward_10_black_24dp.svg';
import backwardButton from '../../../images/replay_10_black_24dp.svg';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { SpanTooltip } from '../../../components/SpanTooltip';

interface PropTypes {
  isPlaying: boolean;
  volume: number;
  muted: boolean;
  playbackRate: number;
  onPressPlay: () => void;
  onPressPause: () => void;
  onSkipForward: () => void;
  onSkipBackward: () => void;
  onVolumeChange: (volume: number, muted: boolean) => void;
  onPlaybackRateChange: (playbackRate: number) => void;
  onFullScreen: (enable: boolean) => void;
}

function PlayPauseButton(props: {
  isPlaying: boolean;
  onPressPlay: () => void;
  onPressPause: () => void;
}) {
  return (
    <div
      style={{
        fontSize: '2rem',
      }}
    >
      {props.isPlaying ? (
        <a
          href='#pause'
          className={classNames('bi', 'bi-pause-circle', styles.controlButtons)}
          onClick={() => props.onPressPause()}
        />
      ) : (
        <a
          href='#play'
          className={classNames('bi', 'bi-play-circle', styles.controlButtons)}
          onClick={() => props.onPressPlay()}
        />
      )}
    </div>
  );
}

function SkipBackwardButton(props: { onSkipBackward: () => void }) {
  return (
    <a
      className={classNames(styles.controlButtonsSvg)}
      href='#skip-backward'
      onClick={() => props.onSkipBackward()}
    >
      <img src={backwardButton} />
    </a>
  );
}

function SkipForwardButton(props: { onSkipForward: () => void }) {
  return (
    <a
      className={classNames(styles.controlButtonsSvg)}
      href='#skip-forward'
      onClick={() => props.onSkipForward()}
    >
      <img src={forwardButton} />
    </a>
  );
}

export const Controls = React.memo(function Controls(props: PropTypes) {
  const togglePlay = useCallback(
    (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;
      if (e.key !== ' ') return;
      if (target.nodeName == 'INPUT' || target.nodeName == 'TEXTAREA') return;
      if (target.isContentEditable) return;

      e.preventDefault();
      if (props.isPlaying) return props.onPressPause();
      props.onPressPlay();
    },
    [props.isPlaying, props.onPressPlay, props.onPressPause],
  );

  useEffect(() => {
    document.addEventListener('keydown', togglePlay);
    return () => document.removeEventListener('keydown', togglePlay);
  }, [togglePlay]);

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div />
      <div className='d-flex align-items-center gap-4'>
        <PlaybackRatePicker
          playbackRate={props.playbackRate}
          onPlaybackRateChange={props.onPlaybackRateChange}
        />
        <SkipBackwardButton onSkipBackward={props.onSkipBackward} />
        <PlayPauseButton
          isPlaying={props.isPlaying}
          onPressPause={props.onPressPause}
          onPressPlay={props.onPressPlay}
        />
        <SkipForwardButton onSkipForward={props.onSkipForward} />
        <VolumeSlider
          volume={props.volume}
          muted={props.muted}
          onVolumeChange={props.onVolumeChange}
        />
      </div>
      <SpanTooltip
        className='min-w-16 me-3'
        text={
          <a
            href='#fullscreen'
            className={classNames('bi', 'bi-fullscreen', styles.controlButtons)}
            onClick={() => props.onFullScreen(true)}
          />
        }
        tooltipText='Fullscreen'
      />
    </div>
  );
});
