import React from 'react';
import { LoadingSpinnerIcon } from '../LoadingSpinnerIcon';
import { IconButton } from '../IconButton';

interface PropTypes {
  isLoading: boolean;
  loadElements: (...args: any[]) => Promise<void>;
}

export function LoadingData() {
  return (
    <div className={'d-flex justify-content-center align-items-center h-100'}>
      <div className='me-2'>
        <LoadingSpinnerIcon className='text-info' />
      </div>
      <span className='fs-5 fw-normal text-dark-200'>Loading</span>
    </div>
  );
}

export function LoadMoreButton(props: PropTypes) {
  return (
    <div className='d-flex col-12 justify-content-center'>
      {props.isLoading ? (
        <LoadingData />
      ) : (
        <IconButton
          buttonText='Load More'
          color='borderless'
          iconName='bi-plus-lg'
          onClick={async () => {
            props.loadElements();
          }}
        />
      )}
    </div>
  );
}
