import React from 'react';
import { AlertMessage } from '../../../../../components/AlertMessage';

interface PropTypes {
  offerFlowFFEnabled: boolean;
}

export default function EnableRequisitionsAlert(props: PropTypes) {
  return (
    <AlertMessage
      text={
        <div className='fs-5'>
          <a
            href='/organization_settings/requisitions'
            className='text-blue fw-bold'
          >
            Click here
          </a>{' '}
          to enable {props.offerFlowFFEnabled ? 'offer and ' : ''}job
          requisitions settings.
        </div>
      }
      icon={{ name: 'bi-info-circle' }}
      className='bg-light-info'
      hasOverlay={false}
    />
  );
}
