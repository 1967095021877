import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../entities/survey_engine/SurveyQuestionAnswer';

export function getTotalAnsweredQuestions(
  surveyAnswers: SurveyQuestionAnswer[],
  questions: SurveyQuestion[],
) {
  const questionIds = questions.map((questions) => questions.id);
  return surveyAnswers
    .filter((answer) => questionIds.includes(answer.question_id))
    .map((answer) =>
      answer.fields.every(
        (field) =>
          (field.value != null && field.value != '') ||
          (field.option_ids != null && field.option_ids.length > 0),
      ),
    )
    .filter((hasAnsweredQuestion) => hasAnsweredQuestion === true).length;
}
