import React from 'react';
import { EmptyState } from '../../../../components/EmptyState';
import UndrawLost from '../../../../images/undraw_empty_state.svg';
import { RequestBackgroundCheckButton } from '../RequestBackgroundCheckButton';

interface PropTypes {
  integrationEnabled: boolean;
  canRequestBackgroundCheck: boolean;
  onRequestClick: () => void;
}

export function BackgroundCheckEmptyState(props: PropTypes) {
  if (!props.integrationEnabled) {
    return (
      <EmptyState
        title={'Background Checks are not enabled'}
        text={
          <>
            <p>
              Go to the integrations page to enable this feature and request
              your first Background Check
            </p>
            <RequestBackgroundCheckButton color='primary' disabled />
          </>
        }
        imageSrc={UndrawLost}
      />
    );
  }

  return (
    <EmptyState
      title={'No Background Checks were requested yet'}
      text={
        <>
          <p>Press the button below to request your first Background Check</p>
          <RequestBackgroundCheckButton
            color='primary'
            onClick={props.onRequestClick}
            disabled={!props.canRequestBackgroundCheck}
          />
        </>
      }
      imageSrc={UndrawLost}
    />
  );
}
