import React from 'react';
import styles from '../../TableBody/styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  name: string;
}

export function NameDataCell(props: PropTypes) {
  return (
    <td
      className={classNames(
        'ps-4 pb-3',
        styles['table-data-cell-border-bottom'],
      )}
    >
      <div className='fs-5 text-primary fw-semibold'>{props.name}</div>
    </td>
  );
}
