import React, { useCallback, useEffect, useState } from 'react';
import { isMouseWithinElement } from '../../utils/mouse';
import styles from './styles.module.scss';
import { formatTime } from '../../utils/timeFormat';
import { SeekbarMarker } from '../../entities/SeekbarMarker';
import { clamp } from '../../utils/clamp';
import classNames from 'classnames';

interface PropTypes {
  totalTime: number;
  time: number;
  previewTime: number;
  seekbarMarkers: SeekbarMarker[];
  onSelectTime: (time: number) => void;
  onSelectPreviewTime: (time: number) => void;
}

const findPosition = (time: number, totalTime: number) => {
  return clamp((time / totalTime) * 100, 0, 100);
};

// The higher, the more important
const markersComparisonValue = (marker: SeekbarMarker) => {
  switch (marker.kind) {
    case 'interview_secret':
      return 6;
    case 'scorecard_attribute':
    case 'automatic_scorecard_attribute':
      return 5;
    case 'flag':
      return 4;
    case 'thumbs_up':
      return 3;
    case 'automatic_question':
      return 2;
    case 'note':
      return 1;
    default:
      return 0;
  }
};

const getMarkerClass = (markerKind: string) => {
  switch (markerKind) {
    case 'interview_secret':
      return styles.interviewSecret;
    case 'scorecard_attribute':
    case 'automatic_scorecard_attribute':
      return styles.scorecardAttribute;
    case 'thumbs_up':
      return styles.thumbsUp;
    case 'flag':
      return styles.flag;
    case 'automatic_question':
      return styles.question;
    case 'note':
      return styles.note;
    default:
      return '';
  }
};

export const Seekbar = React.memo(function Seekbar(props: PropTypes) {
  const [ref, setRef] = useState<HTMLElement>();
  const watchedRatio = findPosition(props.time, props.totalTime);
  const previewTimePerc = findPosition(props.previewTime, props.totalTime);

  const pickTime = useCallback(
    (e: MouseEvent) => {
      if (!isMouseWithinElement(e, ref)) return;

      const { x, width } = ref.getBoundingClientRect();
      const time = ((e.clientX - x) / width) * props.totalTime;
      props.onSelectTime(time);
    },
    [ref, props.totalTime, props.onSelectTime],
  );

  const handleHover = useCallback(
    (e: MouseEvent) => {
      if (ref === undefined) return;
      if (!isMouseWithinElement(e, ref))
        return props.onSelectPreviewTime(undefined);

      const { x, width } = ref.getBoundingClientRect();
      const time = ((e.clientX - x) / width) * props.totalTime;
      props.onSelectPreviewTime(time);
    },
    [ref, props.totalTime, props.onSelectTime],
  );

  useEffect(() => {
    document.addEventListener('mousemove', handleHover);
    document.addEventListener('mousedown', pickTime);
    return () => {
      document.removeEventListener('mousemove', handleHover);
      document.removeEventListener('mousedown', pickTime);
    };
  });

  const getPosition = (marker: SeekbarMarker) => {
    if (marker.offset_ms != null) {
      return findPosition(marker.offset_ms, props.totalTime);
    } else {
      return findPosition(props.totalTime, props.totalTime);
    }
  };

  return (
    <div ref={setRef} className={styles.seekbar}>
      {props.previewTime && props.previewTime !== 0 ? (
        <div className={styles.preview} style={{ left: `${previewTimePerc}%` }}>
          <div className={styles.timeWrapper}>
            <span className={classNames(styles.time)}>
              {formatTime(props.previewTime)}
            </span>
          </div>
        </div>
      ) : undefined}
      <div
        className={styles.seekbarWatched}
        style={{ width: `${watchedRatio}%` }}
      />
      {props.seekbarMarkers
        .sort((a, b) => markersComparisonValue(a) - markersComparisonValue(b))
        .map((marker) => (
          <div
            key={`${marker.kind}_${marker.id}`}
            className={`${styles.seekbarMarker} ${getMarkerClass(marker.kind)}`}
            style={{
              left: `${getPosition(marker)}%`,
              width: `${
                props.totalTime !== 0 &&
                ((marker.end_offset_ms - marker.offset_ms) / props.totalTime) *
                  100
              }%`,
            }}
            onClick={() =>
              marker.kind !== 'interview_secret' &&
              props.onSelectTime(marker.offset_ms)
            }
          >
            <i
              className={`${styles.markerIcon} ${
                marker.icon_classes
              } ${getMarkerClass(marker.kind)}`}
            />
          </div>
        ))}
      <div
        className={`${styles.currentTime}`}
        style={{ left: `${findPosition(props.time, props.totalTime)}%` }}
      />
      <div
        className={`${styles.currentTimeInner}`}
        style={{ left: `${findPosition(props.time, props.totalTime)}%` }}
      />
    </div>
  );
});
