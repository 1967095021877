import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

export function PageWideSpinner() {
  return (
    <div
      className={classNames(
        'w-100 d-flex flex-column gap-3 align-items-center justify-content-center',
        styles.container,
      )}
    >
      <div
        className={classNames('spinner-border', styles.spinner)}
        role='status'
      >
        <span className='visually-hidden'>Loading...</span>
      </div>
      <p>Loading</p>
    </div>
  );
}
