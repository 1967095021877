import React from 'react';
import { IconButton } from '../../../../../components/IconButton';
import { MAX_STEPS_PER_FLOW } from '../ApprovalSteps';
import { ApprovalFlowStep } from '../../../../../entities/applicant_tracking/ApprovalFlow';

interface PropTypes {
  steps: ApprovalFlowStep[];
  borderless?: boolean;
  onClick: () => void;
}

export function AddApprovalStepButton(props: PropTypes) {
  const disabled = props.steps.length >= MAX_STEPS_PER_FLOW;

  return (
    <IconButton
      color={props.borderless ? 'borderless' : 'secondary'}
      iconName='bi-layers'
      buttonText='Add Approval Step'
      disabled={disabled}
      tooltipText={
        disabled &&
        'This approval flow has reached the maximum number of steps.'
      }
      onClick={props.onClick}
    />
  );
}
