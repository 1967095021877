import React from 'react';
import { CandidateCard } from '../../CandidateCard';
import { MoveCandidateProps } from '../../../components/MoveCandidateStageModal';
import { HiringMember } from '../../../../../entities/HiringMember';
import { User } from '../../../../../entities/User';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { appendRedirectUri } from '../../../../../utils/url';
import { PipelineApplication } from '../../../../../entities/applicant_tracking/PipelineApplication';
import { JobState } from '../../../../../entities/JobDetails';
import { AlertObject } from '../../../../../components/Alert';
import { AccountIntegration } from '../../../../../entities/AccountIntegration';

interface PropTypes {
  applications?: PipelineApplication[];
  currentHiringMember?: HiringMember;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobStatus: JobState;
  offerEnabled?: boolean;
  onMoveCandidate?: (action?: MoveCandidateProps) => void;
  reloadJobStages: () => Promise<void>;
  setAlert: (alert: AlertObject) => void;
}

export function JobStageApplications(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex gap-2 h-100 pb-2 pt-2h px-3 overflow-scroll flex-column',
        styles['application-list'],
      )}
    >
      {props.applications?.map(
        (application: PipelineApplication, index: number) => (
          <CandidateCard
            key={index}
            application={application}
            currentStage={application.jobStage.name}
            currentHiringMember={props.currentHiringMember}
            currentUser={props.currentUser}
            daysOnStage={application.daysInCurrentJobStage}
            emailAuthorizeUrl={props.emailAccountIntegration.authorizeUrl}
            name={application.candidate.name}
            jobStatus={props.jobStatus}
            offerEnabled={props.offerEnabled}
            profileLink={appendRedirectUri(`/applications/${application.id}`)}
            onMoveCandidate={props.onMoveCandidate}
            setAlert={props.setAlert}
            reloadJobStages={props.reloadJobStages}
          />
        ),
      )}
    </div>
  );
}
