import React from 'react';
import { BackArrow } from '../../../components/BackArrow';
import { NavActions } from './NavActions';
import { JobDetails } from '../../../entities/JobDetails';
import { PageTitle } from '../../../components/PageTitle';
import { User } from '../../../entities/User';
import { allowedJobRole } from '../../../utils/applicant_tracking/actions/applicationActions';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { JobHeaderBadge } from './JobHeaderBadge';
import { JobStateBadge } from './JobStatePill';
import { ChipColor } from '../../../components/Chip';

interface PropTypes {
  currentUser: User;
  jobDetails: JobDetails;
  subdomain: string;
  publishEnabled: boolean;
}

function allowEditJob(currentUser: User, jobDetails: JobDetails) {
  const hiringMember = jobDetails.hiringMembers.find(
    (hm) => hm.userId == currentUser.id,
  );

  if (jobDetails.status == 'archived') {
    return false;
  }

  if (
    currentUser.isAdmin ||
    allowedJobRole(hiringMember?.role as JobHiringMemberRole)
  ) {
    return true;
  }
}

export function TopNavbar(props: PropTypes) {
  return (
    <div
      id='jobs'
      className='d-flex g-3 mb-4 justify-content-between align-items-center'
    >
      <div className='d-flex me-auto align-items-center'>
        <BackArrow />
        <PageTitle text={props.jobDetails.name} />
        <JobHeaderBadge
          data-testid='interview-job'
          classNames='mx-2h'
          chipColor={ChipColor.White}
          text={props.jobDetails.department}
        />
        <JobStateBadge state={props.jobDetails.status} />
      </div>
      <NavActions
        jobDetails={props.jobDetails}
        subdomain={props.subdomain}
        updateEnabled={allowEditJob(props.currentUser, props.jobDetails)}
        publishEnabled={props.publishEnabled}
      />
    </div>
  );
}
