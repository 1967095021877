import React from 'react';
import { CloseableModal } from '../../../../../../components/CloseableModal';
import { DeleteModal } from './DeleteModal';
import { ArchiveModal } from './ArchiveModal';

interface PropTypes {
  candidateId: number;
  candidateName: string;
  isOpen: boolean;
  action: 'Archive' | 'Unarchive' | 'Delete';
  onClose: (action?: CandidateActionProps) => void;
}

export interface CandidateActionProps {
  candidateName: string;
  action: 'Archive' | 'Unarchive' | 'Delete';
  state: 'Success' | 'Error';
}

export function CandidateActionModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={props.onClose}
      isOpen={props.isOpen}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle={`${props.action} Candidate`}
      bodyChildren={
        props.action === 'Delete' ? (
          <DeleteModal
            candidateId={props.candidateId}
            candidateName={props.candidateName}
            onClose={props.onClose}
          />
        ) : (
          <ArchiveModal
            candidateId={props.candidateId}
            candidateName={props.candidateName}
            action={props.action}
            onClose={props.onClose}
          />
        )
      }
    />
  );
}
