import {
  PaginationResponseV1,
  Product,
  ProductPriceCurrency,
  ProductSortBy,
} from '@vonq/hapi-elements-types';
import { WindowWithHapi } from '..';
import { PaginationState } from './paginationState';
import { SelectedFilterState } from './filterState';
import { JobPostingDetailsForm } from '../JobBoardsTabUI/JobPostingDetails';
import { TargetGroupValues } from '../JobBoardsTabUI/JobPostingDetails/TargetGroup/TargetGroupValues';

interface ProductsWithPagination {
  products: Product[];
  paginationMeta?: PaginationResponseV1;
}

async function getLocationId(
  window: WindowWithHapi,
  form: JobPostingDetailsForm,
): Promise<string | null> {
  if (form.city == null || form.country == null) {
    return null;
  }

  const search = [form.city, form.country].join(',');

  try {
    const locations = await window.hapi.product.api.getLocations(search);

    return locations[0].id.toString();
  } catch (e: any) {
    // Ignore the exception, and return null
    console.error(e);
  }
}

export async function loadProducts(
  window: WindowWithHapi,
  currency: ProductPriceCurrency,
  paginationState: PaginationState,
  form: JobPostingDetailsForm,
  selectedFilterState?: SelectedFilterState,
): Promise<ProductsWithPagination> {
  const queryParams = new URLSearchParams({
    currency: currency,
    offset: paginationState.offset.toString(),
    limit: paginationState.limit.toString(),
  });

  if (selectedFilterState?.sortBy != null) {
    if (selectedFilterState?.sortBy === ProductSortBy.recommendedFirst) {
      queryParams.append('sortBy', ProductSortBy.relevant);
      queryParams.append('recommended', true.toString());

      const locationId = await getLocationId(window, form);

      if (locationId != null) {
        queryParams.append('includeLocationId', locationId);
      }
    } else {
      queryParams.append('sortBy', selectedFilterState.sortBy);
      queryParams.append('recommended', false.toString());
    }
  }

  if (selectedFilterState?.search != null) {
    queryParams.append('name', selectedFilterState.search);
  }

  if (selectedFilterState?.industries != null) {
    queryParams.append('industryId', selectedFilterState.industries);
  }

  if (selectedFilterState?.jobFunctions != null) {
    queryParams.append('jobFunctionId', selectedFilterState.jobFunctions);
  }

  // This is the URL:
  // /products/search/?currency=EUR&durationFrom=&durationTo=&exactLocationId=&excludeRecommended=0&format=json&includeLocationId=&industryId=&jobFunctionId=&jobTitleId=&limit=50&name=&offset=50&recommended=0&sortBy="
  return await window.hapi.product.service.getProducts.run(queryParams, false);
}

export async function loadTargetGroup(
  window: WindowWithHapi,
): Promise<TargetGroupValues> {
  const [industries, jobFunctions, educationLevels, seniorities] =
    await Promise.all([
      window.hapi.product.api.getIndustries(),
      window.hapi.product.api.getJobFunctions(),
      window.hapi.campaign.api.getEducationLevels(),
      window.hapi.campaign.api.getSeniorities(),
    ]);

  return {
    industries,
    jobFunctions,
    educationLevels,
    seniorities,
  };
}
