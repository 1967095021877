import React from 'react';
import classNames from 'classnames';
import { IntegrationRow } from '../IntegrationRow';
import { Integration } from '../../entities/Integration';

export interface PropTypes {
  title: string;
  subtitle?: string;
  integrations?: Integration[];
  headerClassName?: string;
  bodyClassName?: string;
  children?: React.ReactNode;
}

function IntegrationBody(props: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames('app-card-body w-100', props.className || 'px-3')}
    >
      {props.children}
    </div>
  );
}

function IntegrationHeader(props: {
  title: string;
  subtitle: string;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        'app-card-header border-bottom-0',
        props.className || 'mb-3',
      )}
    >
      <div className='row align-items-center gx-3'>
        <div className='col-auto'>
          <h2 className='card-title'>{props.title}</h2>
          {props.subtitle && (
            <small className='text-muted'>{props.subtitle}</small>
          )}
        </div>
      </div>
    </div>
  );
}

export function IntegrationPanel(props: PropTypes) {
  const ffEnabledIntegrations = props.integrations?.filter(
    (integration) => integration.ffEnabled !== false,
  );

  return (
    <div className='app-card app-card-account shadow-sm d-flex flex-column align-items-start mt-3 p-3 p-xxl-4'>
      <IntegrationHeader
        title={props.title}
        subtitle={props.subtitle}
        className={props.headerClassName}
      />
      <IntegrationBody className={props.bodyClassName}>
        {props.children != null
          ? props.children
          : ffEnabledIntegrations.map((integration, idx) => (
              <IntegrationRow
                key={idx}
                integration={integration}
                lastElement={idx + 1 >= ffEnabledIntegrations.length}
              />
            ))}
      </IntegrationBody>
    </div>
  );
}
