export function getShowIndex(
  activeTabIndex: number,
  overflowIndex: number,
  currentIndex: number,
) {
  // If the active tab is visible, or we don't have space to show, there is no need to reorder.
  if (activeTabIndex < overflowIndex || overflowIndex == 0) {
    return currentIndex;
  }
  // The activeTabIndex should be the last element to be shown.
  else if (currentIndex === activeTabIndex) {
    return overflowIndex - 1;
  }
  // Handle the swapped element.
  else if (currentIndex === overflowIndex - 1) {
    return activeTabIndex;
  } else {
    return currentIndex;
  }
}

export function getDropdownIndex(
  firstOverflowIndex: number | null,
  arrayLength: number,
) {
  if (firstOverflowIndex == null) {
    return arrayLength;
  } else if (firstOverflowIndex === 0) {
    return Math.min(1, arrayLength);
  }

  return firstOverflowIndex;
}
