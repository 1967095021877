import { Moment } from 'moment';
import { SurveyTrigger } from '../../entities/candidate_experience/SurveyTrigger';
import { ApiService } from '../ApiService';

interface Params {
  active: boolean;
  name: string;
  maxReminders: number;
  delayDays: number;
  processingStartsAt: Moment;
  SurveyTemplateId: number;
  jobIds: number[];
  invertJobCondition: boolean;
  locationIds: number[];
  invertLocationCondition: boolean;
  jobStageName: string[];
  invertJobStageCondition: boolean;
  applicationRejectReason: string[];
  applicationStatus: string[];
  jobConfidential: string[];
}

function generateParams(params: Params) {
  return {
    active: params.active,
    name: params.name,
    max_reminders: params.maxReminders,
    delay_days: params.delayDays,
    processing_starts_at: params.processingStartsAt.startOf('day').utc(true),
    survey_template_id: params.SurveyTemplateId,
    conditions: {
      job_ids: params.jobIds,
      invert_job_condition: params.invertJobCondition,
      location_ids: params.locationIds,
      invert_location_condition: params.invertLocationCondition,
      job_stage_name: params.jobStageName.join(','),
      invert_job_stage_condition: params.invertJobStageCondition,
      application_reject_reason: params.applicationRejectReason.join(','),
      application_status: params.applicationStatus.join(','),
      job_confidential:
        params.jobConfidential.length === 0
          ? null
          : params.jobConfidential[0] === 'true',
    },
  };
}
export class SurveyTriggerService {
  public static create(params: Params): Promise<SurveyTrigger> {
    return ApiService.post<SurveyTrigger>(
      `/organization_settings/survey_triggers`,
      generateParams(params),
    );
  }

  public static update(surveyTriggerId: number, params: Params): Promise<any> {
    return ApiService.put<any>(
      `/organization_settings/survey_triggers/${surveyTriggerId}`,
      generateParams(params),
    );
  }

  public static validate(
    surveyTriggerId: number,
    params: Params,
  ): Promise<any> {
    return ApiService.get<any>(
      `/organization_settings/survey_triggers/validate`,
      {
        ...generateParams(params),
        survey_trigger_id: surveyTriggerId,
      },
    );
  }
}
