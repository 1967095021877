import React from 'react';
import { AlertObject, AlertType } from '../../../../../../components/Alert';
import { CommentInput } from '../../CommentInput';
import { ApplicationFeed } from '../../../../../../entities/ApplicationFeed';
import { ApplicationFeedComment } from '../../../../../../entities/ApplicationFeedComment';
import { User } from '../../../../../../entities/User';

interface PropTypes {
  applicationFeed: ApplicationFeed;
  comment: ApplicationFeedComment;
  currentUser: User;
  isEditing: boolean;
  setIsEditing: (state: boolean) => void;
  reloadApplication: (state: boolean) => void;
  setAlert: (data: AlertObject) => void;
}

export function EditComment(props: PropTypes) {
  const handleCommentInputClose = (state: boolean) => {
    if (state === true) {
      props.reloadApplication(state);
      props.setAlert({
        type: AlertType.Success,
        message: 'The comment was successfully saved.',
      });
    } else if (state === false) {
      props.setAlert({
        type: AlertType.Danger,
        message: 'The comment could not be saved successfully.',
      });
    }
    props.setIsEditing(false);
  };

  return (
    <>
      {props.isEditing && (
        <div className='mt-4'>
          <CommentInput
            entry={props.applicationFeed}
            currentUser={props.currentUser}
            onClose={handleCommentInputClose}
            value={props.comment}
          />
        </div>
      )}
    </>
  );
}
