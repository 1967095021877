import React from 'react';
import { Col, Row } from 'reactstrap';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { Control } from 'react-hook-form';
import { AcademicDegreeInput } from '../AcademicDegreeInput';

interface PropTypes {
  formControl: Control<CandidateDto>;
}

export function CandidateAcademicInput(props: PropTypes) {
  return (
    <Row>
      <Col xs='3'>
        <AcademicDegreeInput
          label='Academic Degree'
          required={false}
          formControl={props.formControl}
        />
      </Col>
    </Row>
  );
}
