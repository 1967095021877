import React from 'react';
import { Label } from 'reactstrap';
import { JobPostingDetailsForm } from '..';
import { Select, SelectOption } from '../../../../../../components/Select';
import { AVAILABLE_CURRENCIES } from '../../../../../../utils/currency';

interface PropTypes {
  form: JobPostingDetailsForm;
  mandatory?: boolean;
  disabled?: boolean;
  onInputChange: (name: string, value: unknown) => void;
}

function selectedSalaryCurrency(form: JobPostingDetailsForm) {
  if (form.salaryCurrency == null) {
    return null;
  }

  return {
    label: form.salaryCurrency,
    value: form.salaryCurrency,
  };
}

export function SalaryCurrency(props: PropTypes) {
  const field = 'salaryCurrency';

  return (
    <div>
      <Label>
        Salary Currency{' '}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </Label>
      <div className='d-flex fs-5'>
        <Select
          placeholder={'Select currency'}
          options={AVAILABLE_CURRENCIES}
          onChange={(option: SelectOption) => {
            props.onInputChange(field, option.value?.toUpperCase());
          }}
          selected={selectedSalaryCurrency(props.form)}
          isClearable={false}
          className='w-100'
          disabled={props.disabled}
        />
      </div>
    </div>
  );
}
