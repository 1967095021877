import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import classNames from 'classnames';
import { Label } from '../Label';

export function EmailFormGroup(props: {
  email: string;
  disabled: boolean;
  validated: boolean;
  onEmailChanged: (email: string) => void;
}) {
  return (
    <FormGroup check className='mt-3'>
      <Label for='bgc-email' name='email' check size='sm' isRequired>
        Candidate Email
      </Label>
      <Input
        className={classNames('form-control', {
          'is-invalid': props.validated && !props.email?.length,
        })}
        id='bgc-email'
        onChange={(v) => props.onEmailChanged(v.target.value)}
        value={props.email}
        disabled={props.disabled}
      />
    </FormGroup>
  );
}
