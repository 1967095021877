import { Integration } from '../../entities/Integration';
import { BackgroundCheckProvider } from '../../enums/IntegrationProvider/BackgroundCheckProvider';
import { CertnIntegrationToggle } from '../background_check/CertnIntegrationToggle';
import { IntegrationToggle } from '../IntegrationToggle';
import { ButtonType } from '../../entities/ButtonType';
import { UnifiedApiButton } from '../UnifiedApiButton';
import classnames from 'classnames';
import React from 'react';

export interface PropTypes {
  integration: Integration;
  setIntegration: (i: Integration) => void;
}

function IntegrationSwitch(props: PropTypes) {
  switch (props.integration.provider) {
    case BackgroundCheckProvider.Certn:
      return (
        <CertnIntegrationToggle
          integration={props.integration}
          setIntegration={props.setIntegration}
        />
      );
    default:
      return (
        <IntegrationToggle
          integration={props.integration}
          setIntegration={props.setIntegration}
        />
      );
  }
}

export function IntegrationButton(props: PropTypes) {
  if (!props.integration.buttonOptions) return null;

  switch (props.integration.buttonOptions.type) {
    case ButtonType.Anchor:
      return (
        <a
          className={classnames(props.integration.buttonOptions.classNames)}
          href={props.integration.buttonOptions.href}
        >
          {props.integration.buttonOptions.label}
        </a>
      );
    case ButtonType.Toggle:
      return <IntegrationSwitch {...props} />;
    case ButtonType.UnifiedApiButton:
      return (
        <UnifiedApiButton
          integration={props.integration}
          setIntegration={props.setIntegration}
        />
      );
    default:
      return (
        <form
          className='button_to'
          method={props.integration.buttonOptions.method}
          action={props.integration.buttonOptions.href}
        >
          <input
            className={classnames(props.integration.buttonOptions.classNames)}
            data-turbo={
              props.integration.buttonOptions.method === 'get'
                ? 'true'
                : 'false'
            }
            disabled={props.integration.buttonOptions.disabled}
            type='submit'
            value={props.integration.buttonOptions.label}
          />
          {props.integration.buttonOptions.authenticityToken && (
            <input
              type='hidden'
              name='authenticity_token'
              value={props.integration.buttonOptions.authenticityToken}
            />
          )}
        </form>
      );
  }
}
