import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  name: string;
}

export function AuthorName(props: PropTypes) {
  const ref = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (el === null) return;
    setIsOverflowing(el.offsetWidth < el.scrollWidth);
  }, [props.name]);

  return (
    <>
      <span
        ref={ref}
        className={classNames(
          'fw-bold text-nowrap overflow-hidden text-overflow-ellipsis d-inline-block',
          styles['author-name'],
        )}
      >
        {props.name}
      </span>
      {isOverflowing && (
        <Tooltip
          target={ref.current}
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          placement='top'
        >
          <div className='text-white'>{props.name}</div>
        </Tooltip>
      )}
    </>
  );
}
