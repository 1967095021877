import React from 'react';

interface PropTypes {
  elements: JSX.Element[];
  elementsClassName?: string;
  maxElements: number;
  collapsed?: boolean;
}

export function CollapsableElements(props: PropTypes) {
  return (
    <>
      {props.elements.slice(0, props.maxElements).map((element, index) => (
        <React.Fragment key={index}>{element}</React.Fragment>
      ))}
      {props.collapsed &&
        props.elements
          .slice(props.maxElements)
          .map((element, index) => (
            <React.Fragment key={index + props.maxElements}>
              {element}
            </React.Fragment>
          ))}
    </>
  );
}
