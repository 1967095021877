import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { InputFormField } from '../../../../components/InputFormField';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';

interface PropTypes {
  formRegister: UseFormRegister<CandidateDto>;
}

export function CandidateAddressInput(props: PropTypes) {
  const required = false;

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <InputFormField
          type={'text'}
          registerReturn={props.formRegister('address', { required: required })}
          fieldName={'Address'}
          placeholder={'Insert address'}
          isRequired={required}
          classNames={'fs-5'}
          headerClassNames={'fw-semibold'}
        />
      </Col>
    </Row>
  );
}
