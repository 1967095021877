import React, { useEffect, useState } from 'react';
import { Alert, AlertType } from '../../../../components/Alert';

interface PropTypes {
  type: AlertType;
  message: string;
  onClose: () => void;
}

export default function AlertToast(props: PropTypes) {
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    setAlertMessage(props.message);
  }, [props.message]);

  function handleAlertClose() {
    setAlertMessage(null);
    props.onClose();
  }

  return (
    <>
      {alertMessage && (
        <Alert
          type={props.type}
          clearable={true}
          autoClearTimeout={3000}
          onClose={handleAlertClose}
        >
          {alertMessage}
        </Alert>
      )}
    </>
  );
}
