import { Survey } from '../../entities/survey_engine/Survey';
import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveySection } from '../../entities/survey_engine/SurveySection';

export function getQuestions(
  survey: Survey,
  surveySections: SurveySection[] = null,
): SurveyQuestion[] {
  if (survey === undefined) return [];

  const sections = surveySections != null ? surveySections : survey.sections;

  return sections
    .map((s) => s.questions)
    .reduce((prev, curr) => prev.concat(curr), [])
    .filter((q) => q.id != null);
}
