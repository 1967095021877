import React from 'react';
import CustomDomainToggle from './CustomDomainToggle';

export interface PropTypes {
  isEmailDomainEnabled: boolean;
  onToggleChange: (enabled: boolean) => void;
}

export function CustomDomainRow(props: PropTypes) {
  return (
    <div className='row justify-content-between align-items-center'>
      <div className='col'>
        <div className='row'>
          <div className='col-6 fs-5'>
            <h4>Custom Domain</h4>
          </div>
          <div className='col-12 fs-5 fw-2 text-dark-200'>
            Create and manage a custom domain to use with Screenloop emails.
          </div>
        </div>
      </div>
      <div className='col-auto text-end d-flex'>
        <CustomDomainToggle
          enabled={props.isEmailDomainEnabled}
          onToggleChange={props.onToggleChange}
        />
      </div>
    </div>
  );
}
