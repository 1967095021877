import React from 'react';
import { AddSectionButton } from '../AddSectionButton';

interface PropTypes {
  title: string;
  subtitle: string;
  onAddSection: () => void;
  readOnly?: boolean;
}

export function SectionListHeader(props: PropTypes) {
  return (
    <div className='d-flex align-items-center'>
      <div className='me-auto'>
        <div className='fs-3 fw-semibold'>{props.title}</div>
        <div className='fs-5 text-dark-200'>{props.subtitle}</div>
      </div>
      {!props.readOnly && (
        <div className='d-flex gap-3'>
          <AddSectionButton border={true} onClick={props.onAddSection} />
        </div>
      )}
    </div>
  );
}
