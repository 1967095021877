import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import { Session } from '../../../../entities/training/Session';
import { User } from '../../../../entities/User';
import { AssignUserModal } from '../../AssignUserModal';
import { isAssignable } from '../../AssignUserModal/utils';
import styles from './styles.module.scss';

interface PropTypes {
  assignModalVisible: boolean;
  onAssignModalVisible: (flag: boolean) => void;
  currentUser: User;
  session: Session;
  onAssign: (sessionId: number, users: number[]) => void;
}

function ViewButton(props: { sessionId: number; visible: boolean }) {
  return (
    <Button
      color='primary'
      onClick={() => (location.href = `/training/sessions/${props.sessionId}`)}
      disabled={!props.visible}
    >
      View
    </Button>
  );
}

function DropdownAction(props: {
  action: () => void;
  iconClass: string;
  title: string;
}) {
  return (
    <div>
      <a
        className={classNames(
          'nav-item',
          'nav-link',
          'text-truncate',
          'px-2',
          'fs-5',
          'fw-normal',
          'text-info',
          styles.overflowitem,
        )}
        onClick={props.action}
        href='#'
      >
        <i className={classNames('bi', 'me-2', props.iconClass)} />
        <span>{props.title}</span>
      </a>
    </div>
  );
}

function DropdownIcon(props: { className?: string }) {
  return (
    <i
      className={classNames('bi', 'bi-three-dots', props.className)}
      title='More options'
      data-toggle='tooltip'
      data-bs-placement='left'
      data-bs-delay='1000'
    />
  );
}

function DropdownButton(props: { visible: boolean }) {
  if (!props.visible) {
    return <DropdownIcon className='text-gray-300' />;
  }

  return (
    <a
      className={classNames('dropdown-toggle', styles.overflow)}
      data-bs-toggle='dropdown'
      href={'#'}
      role={'button'}
      aria-expanded='false'
      aria-disabled={true}
    >
      <DropdownIcon className='text-info' />
    </a>
  );
}

function DropdownMenu(props: PropTypes) {
  return (
    <ul className='dropdown-menu' aria-labelledby='user-dropdown-toggle'>
      <DropdownAction
        action={() =>
          (location.href = `/training/sessions/${props.session.id}`)
        }
        iconClass={'bi-eye'}
        title={'View'}
      />
      {isAssignable(props.session) && (
        <>
          <DropdownAction
            action={() => props.onAssignModalVisible(true)}
            iconClass={'bi bi-arrow-left-right'}
            title={'Assign'}
          />
          <AssignUserModal
            visible={props.assignModalVisible}
            sessionId={props.session.id}
            sessionName={props.session.name}
            trainingUsers={props.session.trainingUsers}
            onClose={() => props.onAssignModalVisible(false)}
            onAssign={props.onAssign}
          />
        </>
      )}
    </ul>
  );
}

export function SessionActionsMenu(props: PropTypes) {
  if (props.currentUser.isAdmin) {
    return (
      <div className='align-self-center ms-auto'>
        <div className='dropdown'>
          <DropdownButton visible={!props.session.comingSoon} />
          <DropdownMenu {...props} />
        </div>
      </div>
    );
  } else {
    return (
      <ViewButton
        sessionId={props.session.id}
        visible={!props.session.comingSoon && !props.session.draft}
      />
    );
  }
}
