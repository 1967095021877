import React, { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { LabelledSelectFormHook } from '../../../../../../../components/LabelledSelectFormHook';
import { DepartmentService } from '../../../../../../../services/applicant_tracking/DepartmentService';
import { Department } from '../../../../../../../entities/applicant_tracking/Department';

interface PropTypes {
  formControl: Control<JobRequisitionDto>;
}

export function Department(props: PropTypes) {
  const required = true;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    DepartmentService.list().then((departments: Department[]) => {
      setOptions(
        departments.map((department) => ({
          label: department.name,
          value: department.id.toString(),
        })),
      );
    });
  }, []);

  return (
    <Col xs={3}>
      <LabelledSelectFormHook<JobRequisitionDto>
        label='Department'
        placeholder='Select department'
        formHookContext={{
          controllerName: 'departmentId',
          formControl: props.formControl,
          required: required,
        }}
        options={options}
      />
    </Col>
  );
}
