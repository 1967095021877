import React from 'react';
import { SuggestionDataItem } from 'react-mentions';
import styles from './styles.module.scss';
import { Avatar } from '../../Avatar';

interface PropTypes {
  suggestion: SuggestionDataItem;
  search: string;
  highlightDisplay: React.ReactNode;
  index: number;
  focused: boolean;
}

export const MentionSuggestion = React.memo(function MentionSuggestion(
  props: PropTypes,
) {
  return (
    <div
      className={`row align-items-center ${styles.suggestion} ${
        props.focused ? styles.active : ''
      }`}
    >
      <div className='col-auto pe-0'>
        <Avatar size='lg' name={props.suggestion.display} />
      </div>
      <div className='col'>{props.highlightDisplay}</div>
    </div>
  );
});
