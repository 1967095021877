import React from 'react';
import { IconButton } from '../../../../components/IconButton';
import { Size } from '../../../../utils/types/Size';

interface PropTypes {
  color: string;
  disabled?: boolean;
  className?: string;
  size?: Size;
  onClick?: () => void;
}

export function CreateInterviewClipButton(props: PropTypes) {
  return (
    <IconButton
      buttonText='Create Clip'
      iconName='bi-person-video2'
      color={props.color}
      onClick={props.onClick}
      disabled={props.disabled}
      buttonClass={props.className}
      size={props.size}
    />
  );
}
