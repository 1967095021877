import { Nav } from 'reactstrap';
import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { HiringTeamPulseNavLink } from './HiringTeamPulseNavLink';

interface PropTypes {
  activeTab: string;
  interviewer_pulse_enabled: boolean;
  hiring_manager_pulse_enabled: boolean;
}

export function TopTabNavigation(props: PropTypes) {
  const navigate = useNavigate();

  function handleClick(tab: string) {
    navigate(
      `/analytics/hiring_team_pulse/${tab.replace(/\s+/g, '_').toLowerCase()}/${
        window.location.search
      }`,
    );
  }
  return (
    <Nav
      className={classNames('flex-wrap mb-4 px-3 app-nav-tabs bg-transparent')}
    >
      {props.interviewer_pulse_enabled && (
        <HiringTeamPulseNavLink
          dataTestid='Interviewer'
          tab='Interviewer'
          handleClick={handleClick}
          tabTitle='Interviewer'
          activeTab={props.activeTab}
        />
      )}
      {props.hiring_manager_pulse_enabled && (
        <HiringTeamPulseNavLink
          dataTestid='Hiring Manager'
          tab='Hiring Manager'
          handleClick={handleClick}
          tabTitle='Hiring Manager'
          activeTab={props.activeTab}
        />
      )}
    </Nav>
  );
}
