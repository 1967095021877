import React, { useEffect, useRef, useState } from 'react';
import {
  CardTitle,
  CardSubtitle,
  CardText,
  CardBody,
  Tooltip,
} from 'reactstrap';
import { ChartProps } from 'react-chartjs-2/dist/types';
import styles from './styles.module.scss';
import { DeprecatedPanel } from '../../../components/DeprecatedPanel';
import classNames from 'classnames';
import { ChartSection } from '../ChartsSections/index';
import { SelectOption } from '../../../components/Select';
import { getTrendDesign, Info, Subtitle } from '../../../components/MetricCard';
import { ChartType } from 'chart.js';

interface PropTypes<T extends ChartType> {
  title: string;
  subtitle: string;
  value: string | number;
  tooltipInfo?: string;
  benchmarkName?: string;
  benchmarkValue?: string | number;
  chartOptions?: ChartProps<T>;
  trend?: number;
  breakdownValues?: { [key: number]: number };
  colors?: { [index: string]: string };
  interviewers?: SelectOption[];
  chartType?: string;
  score?: number;
}

function MetricValue(props: { value: string }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  useEffect(() => {
    const el = ref.current;
    if (el === null) return;
    setIsOverflowing(el.offsetWidth < el.scrollWidth);
  }, [props.value]);

  return (
    <>
      <CardText tag='div'>
        <div
          className='display1 text-nowrap overflow-hidden text-overflow-ellipsis'
          ref={ref}
        >
          {props.value}
        </div>
      </CardText>
      {isOverflowing && (
        <Tooltip
          target={ref.current}
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          placement='bottom'
        >
          <div className='text-white'>{props.value}</div>
        </Tooltip>
      )}
    </>
  );
}

export default function AnalyticsMetricCard<T extends ChartType>(
  props: PropTypes<T>,
) {
  const [trendColor, trendIcon] = getTrendDesign(props.trend);
  return (
    <DeprecatedPanel
      additionalInnerContainerClassNames={`p-3 p-xxl-4 ${styles.metricCard}`}
    >
      <div className='row d-flex justify-content-between align-items-end'>
        <div className='col-7 d-flex align-items-center'>
          <h2 className='mb-0'>
            <CardTitle>{props.title}</CardTitle>
          </h2>
          <Info tooltip={props.tooltipInfo} />
        </div>
        {props.benchmarkName && (
          <div className='col-5 text-end align-items-end'>
            <CardSubtitle className='benchmark-subtitle mt-0 align-items-end'>
              {props.benchmarkName}
            </CardSubtitle>
          </div>
        )}
      </div>
      <div className='row d-flex justify-content-between align-items-start'>
        <div className='col-7'>
          <div className='d-flex align-items-center'>
            {props.value != null && (
              <MetricValue value={props.value.toString()}></MetricValue>
            )}
            {props.trend != null && (
              <CardBody
                tag='div'
                className={classNames(
                  'py-0 ',
                  'px-1',
                  'mt-2',
                  `text-${trendColor}`,
                )}
              >
                <div>
                  <i className={classNames('bi', trendIcon)}></i>
                  {props.trend != 0 ? `${Math.abs(props.trend)}%` : `N/A`}
                </div>
              </CardBody>
            )}
          </div>
        </div>
        {props.benchmarkName && (
          <div className='col-5 text-end'>
            <div className='d-flex justify-content-end align-items-start'>
              <i className='bi bi-activity vector-image mx-2'></i>
              <CardText className='benchmark-value pt-0 mt-0'>
                {props.benchmarkValue}
              </CardText>
            </div>
          </div>
        )}
      </div>
      <Subtitle value={props.subtitle} />
      <ChartSection
        chartType={props.chartType}
        chartOptions={props.chartOptions}
        breakdownValues={props.breakdownValues}
        colors={props.colors}
        interviewers={props.interviewers}
        score={props.score}
      />
    </DeprecatedPanel>
  );
}
