import React from 'react';
import { Row } from 'reactstrap';
import { InputCol } from '../InputCol';
import { UseFormRegister } from 'react-hook-form';
import { FormValues } from '../../../UserInvitationPage';

interface PropTypes {
  register: UseFormRegister<FormValues>;
  hasPasswordValidationFailed: boolean;
  isPasswordInvalid: boolean;
}

export function InputRows(props: PropTypes) {
  return (
    <div className='pt-1 px-3'>
      <div className='py-4 border-bottom'>
        <Row className='justify-content-between align-items-center'>
          <InputCol
            title={'Current Password'}
            register={props.register}
            hasPasswordValidationFailed={props.isPasswordInvalid}
            field={'currentPassword'}
            placeholder={'Insert current password'}
            colClassName={'align-items-center'}
          />
        </Row>
      </div>
      <div className='py-4 border-bottom'>
        <Row className='justify-content-between align-items-center'>
          <InputCol
            title={'New Password'}
            register={props.register}
            hasPasswordValidationFailed={props.hasPasswordValidationFailed}
            field={'password'}
            placeholder={'Insert new password'}
            hasMinChars={true}
            colClassName={'align-items-center'}
          />
        </Row>
      </div>
      <div className='py-4'>
        <Row className='justify-content-between'>
          <InputCol
            title={'Confirm New Password'}
            register={props.register}
            hasPasswordValidationFailed={props.hasPasswordValidationFailed}
            field={'passwordConfirmation'}
            placeholder={'Confirm new password'}
            hasMinChars={true}
            hasPasswordRequirementsList={true}
            colClassName={'pt-3'}
          />
        </Row>
      </div>
    </div>
  );
}
