import { ApiService } from '../../ApiService';
import { UpsertJobPostDto } from './UpsertJobPostDto';
import { JobPost } from '../../../entities/applicant_tracking/JobPost';
import { JobPost as JobPostV1 } from '../../../entities/v1/applicant_tracking/JobPost';
import snakecaseKeys from 'snakecase-keys';

export class JobPostService {
  public static list(
    params: {
      excludingCandidateId?: number;
      restrictedAccess?: boolean;
    } = {},
  ): Promise<JobPost[]> {
    return ApiService.get<JobPost[]>(
      '/api/applicant_tracking/job_posts',
      snakecaseKeys(params),
    );
  }

  public static show(id: number, expand?: string[]): Promise<JobPostV1> {
    return ApiService.get<JobPostV1>(
      `/api/v1/applicant_tracking/job_posts/${id}`,
      snakecaseKeys({ expand: expand }),
    );
  }

  public static upsert(props: UpsertJobPostDto): Promise<JobPost> {
    return ApiService.post<JobPost>(
      '/api/applicant_tracking/job_posts',
      snakecaseKeys(props),
    );
  }

  public static publish(id: string): Promise<JobPost> {
    return ApiService.post<JobPost>(
      `/api/applicant_tracking/job_posts/${id}/publish`,
    );
  }
}
