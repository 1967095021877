import React from 'react';
import { Col, Row } from 'reactstrap';
import { Chip, ChipColor } from '../../../../../components/Chip';

interface PropTypes {
  totpEnabled: boolean;
}

export function AuthenticatorTextCol(props: PropTypes) {
  return (
    <Col xs={8}>
      <Row>
        <Col xs={6}>
          <h5>
            Authenticator App
            {props.totpEnabled ? (
              <Chip className='fw-semibold ms-2' color={ChipColor.SuccessLight}>
                Enabled
              </Chip>
            ) : (
              <Chip className='fw-semibold ms-2' color={ChipColor.DangerLight}>
                Disabled
              </Chip>
            )}
          </h5>
        </Col>
        <div className='col-12 text-dark-200 fs-5'>
          An authenticator application that supports TOTP (like Google
          Authenticator or 1Password) can be used to conveniently secure your
          account. A new token is generated every 30 seconds.
        </div>
      </Row>
    </Col>
  );
}
