import React from 'react';
import { Application } from '../../../../../../../entities/Application';
import { IconButton } from '../../../../../../../components/IconButton';
import { VerticalDivider } from '../../../../../../../components/VerticalDivider';
import { RejectApplication } from './RejectApplication';
import { AlertObject } from '../../../../../../../components/Alert';
import { MoveApplication } from './MoveApplication';
import { AddNote } from './AddNote';
import { BULK_REVIEW_NAVIGATION_TYPE } from '../../../..';

interface PropTypes {
  application: Application;
  preSelectedRejectReasonId: number;
  onNext: (navType: BULK_REVIEW_NAVIGATION_TYPE) => void;
  onClose: () => void;
  setAlert: (alert: AlertObject) => void;
  setPreSelectedRejectReasonId: (id: number) => void;
}

export function ApplicationActions(props: PropTypes) {
  return (
    <div className='pb-2'>
      <AddNote {...props} />
      <RejectApplication {...props} />
      <MoveApplication {...props} />
      <VerticalDivider />
      <IconButton
        iconName='bi-skip-end'
        buttonText='Skip'
        buttonClass='ms-3h'
        color='secondary'
        position='end'
        onClick={() => props.onNext('skipped')}
      />
    </div>
  );
}
