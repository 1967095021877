import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HeaderWithLogo } from '../HeaderWithLogo';
import styles from './styles.module.scss';
import { CompanyInfo } from '../../../reference_check/entities/CompanyInfo';

type ChildElement = JSX.Element | string | JSX.Element[] | string[];

interface PropTypes {
  companyInfo: CompanyInfo;
  className?: string;
  children: ChildElement | ChildElement[];
}

export function SurveyLayout(props: PropTypes) {
  const backgroundColor = props.companyInfo?.background_color ?? '#f6f9fa';

  return (
    <div
      className={`${styles.root} ${props.className}`}
      style={{
        backgroundColor: backgroundColor,
        letterSpacing: `${props.companyInfo.font_kerning}em`,
      }}
    >
      <Container>
        <Row>
          <Col lg='10' className='offset-lg-1' style={{ marginTop: '30px' }}>
            <HeaderWithLogo logoUrl={props.companyInfo.logo_url} />
            <Row className='mt-4'>
              <Col xs='12'>{props.children}</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
