import { Nav, NavLink } from 'reactstrap';
import React from 'react';

export interface PropTypes {
  activeTab: string;
  onTabClick: (name: string) => void;
}

export function NPSExplainabilityTabNavigation(props: PropTypes) {
  return (
    <Nav className='d-inline-flex mb-3 px-3 app-nav-tabs'>
      <NavLink
        data-testid='jobs'
        href='#'
        active={props.activeTab === 'Jobs'}
        className='text-sm-center'
        onClick={() => props.onTabClick('Jobs')}
      >
        Jobs
      </NavLink>
      <NavLink
        data-testid='departments'
        href='#'
        active={props.activeTab === 'Departments'}
        className='text-sm-center'
        onClick={() => props.onTabClick('Departments')}
      >
        Departments
      </NavLink>
    </Nav>
  );
}
