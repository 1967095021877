import React from 'react';
import { JobWorkflowEditContextProps } from '../..';
import { SetupPageHeader } from '../../../../../components/SetupPageHeader';
import { BackArrow } from '../../../../../components/BackArrow';
import { PageTitle } from '../../../../../components/PageTitle';
import { IconButton } from '../../../../../components/IconButton';
import {
  appendRedirectUri,
  getRedirectUriQueryParam,
} from '../../../../../utils/url';

interface PropTypes {
  isValid: boolean;
  onCancel: () => void;
  pageContext: JobWorkflowEditContextProps;
}

function Title(props: {
  pageContext: JobWorkflowEditContextProps;
  children?: React.ReactNode;
}) {
  const title =
    props.pageContext.viewMode || props.pageContext.editMode
      ? props.pageContext.planTemplate?.name
      : 'Add Job Workflow';

  return (
    <div className='d-flex align-items-center justify-content-between'>
      <div className='d-flex ps-0'>
        <BackArrow
          url={
            getRedirectUriQueryParam() ?? '/organization_settings/job_workflow'
          }
        />
        <PageTitle text={title || 'Add Job Workflow'} />
      </div>
      {props.children}
    </div>
  );
}

function ViewModeHeader(props: PropTypes) {
  const editUrl = appendRedirectUri(window.location.pathname + '/edit');

  return (
    <Title pageContext={props.pageContext}>
      <IconButton
        color='primary'
        onClick={() => (window.location.href = editUrl)}
        buttonText='Edit'
        iconName='bi-pencil'
      />
    </Title>
  );
}

export function CardHeader(props: PropTypes) {
  if (props.pageContext.planTemplate?.globalTemplate) {
    return <Title pageContext={props.pageContext} />;
  }

  if (props.pageContext.viewMode) {
    return <ViewModeHeader {...props} />;
  }

  return (
    <SetupPageHeader
      title={<Title pageContext={props.pageContext} />}
      firstItems={[]}
      classNames='align-items-center'
      titleClassNames='mb-0'
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: props.onCancel,
        },
        {
          title: 'Save',
          type: 'Save',
          disabled: !props.isValid,
        },
      ]}
    />
  );
}
