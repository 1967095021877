import React from 'react';
import { Condition, ConditionsData } from '../../..';
import { LabelledSelect } from '../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../components/Select';
import { SurveyTemplate } from '../../../../../../../entities/candidate_experience/SurveyTemplate';
import { findMandatoryConditions } from '../../../utils/findMandatoryConditions';

interface PropTypes {
  labelledSurveyTemplates: SelectOption[];
  selectedSurveyTemplate: SelectOption;
  setSelectedSurveyTemplate: (selectedSurveyTemplate: SelectOption) => void;
  surveyTemplates: SurveyTemplate[];
  conditionsData: ConditionsData;
  setConditions: (condition: Condition[]) => void;
  edit: boolean;
}

export function SurveyTemplateLabelledSelectRow(props: PropTypes) {
  return (
    <div className='row pt-4'>
      <div className='row align-items-center'>
        <div className='col-3'>
          <LabelledSelect
            options={props.labelledSurveyTemplates}
            selected={props.selectedSurveyTemplate}
            placeholder={'Select Track'}
            onChange={(e) => {
              props.setSelectedSurveyTemplate(e);
              const mandatoryConditions = findMandatoryConditions(
                e,
                props.surveyTemplates,
                props.conditionsData,
              );
              props.setConditions(mandatoryConditions);
            }}
            label='Survey Track'
            className={'w-100'}
            mandatory={true}
            disabled={props.edit}
          />
        </div>
      </div>
    </div>
  );
}
