import React from 'react';
import { ColumnConfig } from '../Field';
import { TruncateText } from '../../../../components/TruncateText';

interface PropTypes {
  readOnly?: boolean;
  column: ColumnConfig;
}

export function MandatoryHeader(props: PropTypes) {
  return (
    <div className={props.column.className}>
      <TruncateText
        className='fw-semibold text-center fs-5'
        text='Mandatory'
        tooltip={true}
        ellipsize={true}
        maxLines={1}
      />
    </div>
  );
}
