import classNames from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FormFieldHeader } from '../FormFieldHeader';
import styles from './styles.module.scss';

interface PropTypes {
  registerReturn: UseFormRegisterReturn;
  fieldName?: string;
  placeholder?: string;
  isRequired?: boolean;
  classNames?: string;
  headerClassNames?: string;
  maxLength?: number;
  rows?: number;
  initialValue?: string;
  disabled?: boolean;
  maxLengthAtTop?: boolean;
  preventResize?: boolean;
}

function recalculateCharacters(event: ChangeEvent<HTMLTextAreaElement>) {
  return event.target.value.length;
}

function MaxLengthCount(props: { count: number; maxLength?: number }) {
  if (!props.maxLength) return null;

  return (
    <small className='mt-2 d-flex justify-content-end text-dark-200'>
      {props.count}/{props.maxLength}
    </small>
  );
}

export function TextAreaInputFormField(props: PropTypes) {
  const [textAreaCount, setTextAreaCount] = useState(
    props.initialValue?.length || 0,
  );

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        {props.fieldName ? (
          <FormFieldHeader
            fieldName={props.fieldName}
            isRequired={props.isRequired}
            classNames={props.headerClassNames}
          />
        ) : (
          <></>
        )}
        {props.maxLengthAtTop && (
          <div className='mb-2'>
            <MaxLengthCount count={textAreaCount} maxLength={props.maxLength} />
          </div>
        )}
      </div>
      <textarea
        className={classNames(
          'form-control',
          props.classNames,
          props.preventResize && styles['prevent-resize'],
        )}
        placeholder={props.placeholder}
        {...props.registerReturn}
        onChange={(e) => {
          setTextAreaCount(recalculateCharacters(e));
          props.registerReturn.onChange(e);
        }}
        disabled={props.disabled}
        rows={props.rows}
        maxLength={props.maxLength}
      />
      {!props.maxLengthAtTop && (
        <MaxLengthCount count={textAreaCount} maxLength={props.maxLength} />
      )}
    </>
  );
}
