import { Moment } from 'moment';
import React from 'react';
import { CardConditionsProps } from '..';
import { SelectedConditions } from '../..';
import { SelectOption } from '../../../../../../components/Select';
import { CardConditionsRow } from '../CardConditionsRow';
import { CardSettingRow } from '../CardSettingsRow';
import { CardSummaryRow } from '../CardSummaryRow';

export interface DateProps {
  today: Moment;
  startDate: Moment;
  lastSevenDays: Moment;
}
interface PropTypes {
  surveyTriggerName: string;
  selectedConditions: SelectedConditions;
  onSurveyTriggerNameChange: (name: string) => void;
  surveyTriggerStatus: boolean;
  onSurveyTriggerStatusChange: (status: boolean) => void;
  selectedMaxReminders: SelectOption;
  setSelectedMaxReminders: (selectedMaxReminder: SelectOption) => void;
  selectedDelayDays: SelectOption;
  setSelectedDelayDays: (selectedDelayDay: SelectOption) => void;
  setStartDate: (day: Moment) => void;
  cardProps: CardConditionsProps;
  processingStartsAt: Moment;
  dateProps: DateProps;
}

export function CardBody(props: PropTypes) {
  return (
    <div className='app-card-body px-3 w-100'>
      <CardSummaryRow
        cardSummary={{
          onSurveyTriggerNameChange: props.onSurveyTriggerNameChange,
          surveyTriggerStatus: props.surveyTriggerStatus,
          onSurveyTriggerStatusChange: props.onSurveyTriggerStatusChange,
          surveyTriggerName: props.surveyTriggerName,
        }}
      />
      <CardConditionsRow
        cardProps={{
          labelledSurveyTemplates: props.cardProps.labelledSurveyTemplates,
          selectedSurveyTemplate: props.cardProps.selectedSurveyTemplate,
          setSelectedSurveyTemplate: props.cardProps.setSelectedSurveyTemplate,
          conditions: props.cardProps.conditions,
          setConditions: props.cardProps.setConditions,
          edit: props.cardProps.edit,
          surveyTemplates: props.cardProps.surveyTemplates,
          conditionsData: props.cardProps.conditionsData,
          applicableConditions: props.cardProps.applicableConditions,
        }}
      />
      <CardSettingRow
        selectedMaxReminders={props.selectedMaxReminders}
        setSelectedMaxReminders={props.setSelectedMaxReminders}
        selectedDelayDays={props.selectedDelayDays}
        setSelectedDelayDays={props.setSelectedDelayDays}
        setStartDate={props.setStartDate}
        dateProps={props.dateProps}
        edit={props.cardProps.edit}
        processingStartsAt={props.processingStartsAt}
      />
    </div>
  );
}
