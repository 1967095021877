import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

type ChildElement = JSX.Element | string | JSX.Element[] | string[];

interface PropTypes {
  className?: string;
  children: ChildElement | ChildElement[];
}

export function SurveyPanel(props: PropTypes) {
  return (
    <Card className={`shadow-sm ${props.className}`}>
      <CardBody className='w-100 p-4'>
        <Row>
          <Col xs='12'>{props.children}</Col>
        </Row>
      </CardBody>
    </Card>
  );
}
