import React from 'react';
import {
  Offcanvas as RSOffcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from 'reactstrap';
import { HorizontalDivider } from '../HorizontallDivider';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  header: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export function Offcanvas(props: PropTypes) {
  return (
    <RSOffcanvas
      direction='end'
      backdrop={false}
      scrollable
      className={classNames('border-blue-gray-200', styles['custom-offcanvas'])}
      fade={false}
      isOpen={props.isOpen}
      toggle={() => props.setIsOpen(!props.isOpen)}
    >
      <OffcanvasHeader
        className='p-4 d-flex flex-nowrap justify-content-between'
        tag={'div'}
        toggle={() => props.setIsOpen(!props.isOpen)}
      >
        {props.header}
      </OffcanvasHeader>
      <HorizontalDivider />
      <OffcanvasBody>{props.children}</OffcanvasBody>
      {props.footer}
    </RSOffcanvas>
  );
}
