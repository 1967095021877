import { DateFilter } from '../../../components/DateRangePicker';
import { ApiService } from '../../ApiService';
import { NpsExplainability } from '../../../entities/candidate_experience/NpsExplainability';

export class AnalyticsNpsExplainabilityService {
  public static get(
    dateRange: DateFilter,
    npsAttributes: string[],
  ): Promise<NpsExplainability> {
    return ApiService.get<NpsExplainability>(
      '/api/candidate_experience/candidate_analytics/nps_explainability',
      {
        start_date: dateRange.start.toJSON(),
        end_date: dateRange.end.toJSON(),
        date_range: dateRange.range,
        nps_attributes: npsAttributes,
      },
    );
  }
}
