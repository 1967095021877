import { NavLink } from 'reactstrap';
import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export function HiringTeamPulseNavLink(props: {
  dataTestid: string;
  activeTab: string;
  tab: string;
  tabTitle: string;
  handleClick: (tab: string) => void;
}) {
  return (
    <NavLink
      data-testid={props.dataTestid}
      href='#'
      active={props.activeTab === props.tab}
      className={classNames(
        'text-sm-center fw-bold',
        props.activeTab === props.tab
          ? styles.borderBottomDarkPrimary
          : styles.borderBottomTransparent,
      )}
      onClick={() => props.handleClick(props.tab)}
    >
      {props.tabTitle}
    </NavLink>
  );
}
