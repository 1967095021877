import { BenchmarkType } from '../entities/candidate_experience/BenchmarkType';

export function benchmarkTypeToString(benchmarkType: BenchmarkType) {
  switch (benchmarkType) {
    case BenchmarkType.BestPractice:
      return 'Best Practice';
    case BenchmarkType.CompanyAverage:
      return 'Company AVG';
    case BenchmarkType.CustomerAverage:
      return 'Customers AVG';
  }
}

export function stringToBenchmarkLabel(benchmarkType: string) {
  switch (benchmarkType) {
    case 'best_practice':
      return 'Best Practice';
    case 'company_average':
      return 'Company AVG';
    case 'customer_average':
      return 'Customers AVG';
  }
}
