import React from 'react';
import { InterviewQuestionRow } from './InterviewQuestionRow';
import { InterviewStageQuestion } from '../../../../entities/applicant_tracking/InterviewStage';
import { HorizontalDivider } from '../../../HorizontallDivider';

interface PropTypes {
  questions?: InterviewStageQuestion[];
}

export function InterviewQuestionsBody(props: PropTypes) {
  return (
    <>
      {props.questions
        .sort(
          (a: InterviewStageQuestion, b: InterviewStageQuestion) =>
            a.order - b.order,
        )
        .map((question: InterviewStageQuestion, index: number) => {
          return (
            <div key={index}>
              <InterviewQuestionRow
                key={index}
                number={index + 1}
                question={question.question}
              />
              {index !== props.questions.length - 1 && (
                <HorizontalDivider classNames='my-2h' />
              )}
            </div>
          );
        })}
    </>
  );
}
