import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { InterviewClip } from '../../../../entities/InterviewClip';
import { Button } from 'reactstrap';
import { InputFormField } from '../../../../components/InputFormField';
import { TruncateText } from '../../../../components/TruncateText';
import {
  formatTime,
  convertDurationFormatToMs,
} from '../../../../utils/timeFormat';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { findSentenceByOffset } from '../../../../utils/monologueSelector';
import { Monologue } from '../../../../entities/v1/interview_intelligence/Monologue';

const TIME_REGEX = /^(\d{1,2}:)?\d{2}:\d{2}$/;

export type UpsertInterviewClip = Omit<
  InterviewClip,
  'id' | 'jobName' | 'candidateName' | 'videoUrl' | 'status'
> & {
  id?: string;
};

interface FormModel {
  title: string;
  startOffset: string;
  endOffset: string;
}

interface PropTypes {
  clipRange: number[];
  maxOffset: number;
  clip?: InterviewClip;
  monologues: Monologue[];
  onCancel: () => void;
  onSubmit: (clip: UpsertInterviewClip) => Promise<void>;
  onClipRangeChanged: (range: number[]) => void;
}

function ActionButtons(props: { onCancel }) {
  return (
    <div className='d-inline-flex justify-content-end align-items-center w-100 mt-4'>
      <Button color='danger' onClick={props.onCancel}>
        Cancel
      </Button>
      <Button
        type='submit'
        color='primary'
        className='ms-3 px-2h fw-normal py-2'
      >
        Save
      </Button>
    </div>
  );
}

export function InterviewClipForm(props: PropTypes) {
  const startOffsetMs = props.clip?.startOffsetMs || props.clipRange[0];
  const endOffsetMs = props.clip?.endOffsetMs || props.clipRange[1];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    getFieldState,
    trigger,
  } = useForm<FormModel>({ mode: 'onChange' });

  useEffect(() => {
    setValue('startOffset', formatTime(props.clipRange[0]));
    setValue('endOffset', formatTime(props.clipRange[1]));

    trigger('startOffset');
    trigger('endOffset');

    setSentence(
      findSentenceByOffset(props.monologues, props.clipRange[0]) || '',
    );
  }, [props.clipRange]);

  useEffect(() => {
    setValue('title', props.clip?.title);
  }, [props.clip]);

  const [sentence, setSentence] = useState<string>();

  const timestampRegister = (name, options: any) => {
    return register(name, {
      ...options,
      pattern: TIME_REGEX,
    });
  };

  const submitForm = async (model: FormModel) => {
    await props.onSubmit({
      id: props.clip?.id,
      title: model.title,
      startOffsetMs: convertDurationFormatToMs(model.startOffset),
      endOffsetMs: convertDurationFormatToMs(model.endOffset),
    });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className='rounded-4 border border-black p-4'>
        <InputFormField
          type={'text'}
          registerReturn={register('title', { required: true })}
          fieldName={'Title'}
          placeholder={'Insert Title'}
          isRequired
          hideRequireIndicator
          defaultValue={props.clip?.title}
          classNames={classNames('fs-5', { 'is-invalid': errors.title })}
          headerClassNames='fw-semibold'
        />
        <div className='d-inline-flex align-items-center my-3'>
          <i
            className='bi bi-person-video2 me-2 text-dark-200'
            style={{ width: '1em', height: '1em', top: 0 }}
          />
          <InputFormField
            type={'text'}
            registerReturn={timestampRegister('startOffset', {
              required: true,
              deps: ['endOffset'],
              validate: (value) =>
                convertDurationFormatToMs(value) <
                convertDurationFormatToMs(getValues('endOffset')),
              onChange: async (e) => {
                await trigger(['startOffset', 'endOffset']);
                const validStart = !getFieldState('startOffset').error;
                const validEnd = !getFieldState('endOffset').error;
                validStart &&
                  props.onClipRangeChanged([
                    convertDurationFormatToMs(e.target.value),
                    validEnd
                      ? convertDurationFormatToMs(getValues('endOffset'))
                      : endOffsetMs,
                  ]);
              },
            })}
            defaultValue={formatTime(startOffsetMs)}
            isRequired
            hideRequireIndicator
            classNames={classNames('fs-5', styles['offset-input'], {
              'is-invalid': errors.startOffset,
            })}
            headerClassNames={'fw-semibold'}
          />
          <span className='mx-2'>-</span>
          <InputFormField
            type={'text'}
            registerReturn={timestampRegister('endOffset', {
              required: true,
              deps: ['startOffset'],
              validate: {
                higherThan: (value) =>
                  convertDurationFormatToMs(value) >
                  convertDurationFormatToMs(getValues('startOffset')),
                max: (value) =>
                  convertDurationFormatToMs(value) <= props.maxOffset,
              },
              onChange: async (e) => {
                await trigger(['startOffset', 'endOffset']);
                const validStart = !getFieldState('startOffset').error;
                const validEnd = !getFieldState('endOffset').error;
                validEnd &&
                  props.onClipRangeChanged([
                    validStart
                      ? convertDurationFormatToMs(getValues('startOffset'))
                      : startOffsetMs,
                    convertDurationFormatToMs(e.target.value),
                  ]);
              },
            })}
            defaultValue={formatTime(endOffsetMs)}
            isRequired
            hideRequireIndicator
            classNames={classNames('fs-5', styles['offset-input'], {
              'is-invalid': errors.endOffset,
            })}
            headerClassNames={'fw-semibold'}
          />
        </div>
        <div className='fs-5 text-primary m-0' style={{ height: '42px' }}>
          <TruncateText maxLines={2} text={sentence || ''} />
        </div>
      </div>
      <ActionButtons
        onCancel={() => {
          reset();
          props.onCancel();
        }}
      />
    </form>
  );
}
