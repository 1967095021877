import { Role } from '../../entities/Role';
import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionField } from '../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestionFieldType } from '../../entities/survey_engine/SurveyQuestionFieldType';
import { humanize } from '../../utils/humanize';

function buildFields(roles: Role[]): SurveyQuestionField[] {
  return [
    {
      id: -2,
      type: SurveyQuestionFieldType.MultiOptions,
      positive: true,
      options: roles.map((role) => ({ id: role, name: humanize(role) })),
    },
  ];
}

export function buildInterviewerRoleSurveyQuestion(
  roles: Role[],
): SurveyQuestion {
  return {
    id: -2,
    title: 'What was your role in the process(es)?',
    fields: buildFields(roles),
    min_options: 1,
    max_options: 1,
    order: 1,
    section: {
      id: 'recruitment_process_feedback',
      name: 'Recruitment process feedback',
    },
    conditions: [],
    default_flow: false,
    mandatory: true,
    type: SurveyQuestionFieldType.Options,
  };
}
