import React from 'react';

export function CardHeaderRow(props: { title: string; subtitle: string }) {
  return (
    <div className='row'>
      <div className='col-6'>
        <h4 className='fw-bold'>{props.title}</h4>
      </div>
      <div className='col-12 fs-5 fw-2 text-dark-200'>{props.subtitle}</div>
    </div>
  );
}
