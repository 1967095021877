import { InterviewStage } from '../../entities/applicant_tracking/InterviewStage';

export function getInterviewStageName(interviewStage: InterviewStage) {
  const name = interviewStage.name;
  const regex = /interview$/i;

  if (regex.test(name)) {
    return name;
  } else {
    return `${name} Interview`;
  }
}
