import classNames from 'classnames';
import React from 'react';
import { SpanTooltip } from '../../../SpanTooltip';
import styles from './styles.module.scss';
import stylesheetExports from '../../../../stylesheets/export.module.scss';

interface PropTypes {
  columns: { key: string; label: string; tooltipText?: string; type: string }[];
}

function TableHeaderColumnName(
  props: PropTypes & {
    tag: string;
    label: string;
    idx: number;
    type: string;
    tooltipText: string;
  },
) {
  return (
    <a
      className={classNames(
        `d-flex ${
          props.type === 'string'
            ? 'justify-content-start'
            : 'justify-content-end'
        }`,
        props.idx === 0 && 'ps-4',
      )}
      style={{
        color:
          props.idx === 0
            ? stylesheetExports.darkSideBar
            : stylesheetExports.dark200,
      }}
      href='#'
    >
      <span className='text-truncate d-block'>{props.label}</span>
      {props.tooltipText && (
        <SpanTooltip
          className='ms-2 pe-2 text-info'
          placement='top'
          text={<i className='bi bi-info-circle'></i>}
          tooltipText={props.tooltipText}
          tooltipStyle={{ maxWidth: '250px' }}
        />
      )}
    </a>
  );
}

export function TableHeader(props: PropTypes) {
  return (
    <thead className={classNames('text-nowrap')}>
      <tr className={classNames('fs-5')}>
        {props.columns.map(({ key, label, tooltipText, type }, idx) => {
          return (
            <th
              className={classNames(
                styles['table-head-border-bottom'],
                'pb-3 fw-semibold',
              )}
              key={idx}
            >
              <TableHeaderColumnName
                {...props}
                tag={key}
                label={label}
                idx={idx}
                tooltipText={tooltipText}
                type={type}
              />
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
