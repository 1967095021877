import { UseFilePickerConfig, Validator } from 'use-file-picker';
import { FileWithPath } from 'file-selector';

export const MAX_EMAIL_FILES_SIZE_MB = 20;

export const EMAIL_FILES_SIZE_EXCEEDED_ERROR_NAME = 'email_files_size_exceeded';

export function fileSizeBytes(files: { size: number }[]) {
  if (files.length == 0) {
    return 0;
  }
  return files.map((f) => f.size).reduce((a, b) => a + b);
}

export class EmailSizeValidator implements Validator {
  initialFileSize: number;

  constructor(initialFileSize: number) {
    this.initialFileSize = initialFileSize;
  }

  async validateBeforeParsing(
    _config: UseFilePickerConfig,
    plainFiles: File[],
  ) {
    return new Promise<void>((res, rej) =>
      Array.from(plainFiles)
        .map((file) => file.size)
        .reduce((size1, size2) => size1 + size2) +
        this.initialFileSize <=
      MAX_EMAIL_FILES_SIZE_MB * 1000000
        ? res()
        : rej({ name: EMAIL_FILES_SIZE_EXCEEDED_ERROR_NAME }),
    );
  }

  async validateAfterParsing(
    _config: UseFilePickerConfig,
    _file: FileWithPath,
    _reader: FileReader,
  ) {
    return new Promise<void>((res) => res());
  }
}
