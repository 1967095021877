import { SurveyQuestion } from '../../../../reference_check/entities/SurveyQuestion';
import { SurveyTemplate } from '../../../../reference_check/entities/SurveyTemplate';

export function getQuestionsFromSurveyTemplate(
  surveyTemplate: SurveyTemplate,
): SurveyQuestion[] {
  return surveyTemplate.modules.reduce<SurveyQuestion[]>(
    (prev, curr) => prev.concat(curr.questions),
    [],
  );
}
