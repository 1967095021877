import { ScorecardTemplateService } from '../../../services/v1/applicant_tracking/ScorecardTemplateService';
import { randomInt } from '../../../utils/number';
import { FieldConfig } from '../../survey_engine/SurveyTemplates/Field';
import { SectionData } from '../../survey_engine/SurveyTemplates/Section';

export const FIELD_CONFIGS: FieldConfig[] = [
  {
    label: '5 Star Scale Response',
    fieldType: 'rating',
    max: 5,
  },
  {
    label: 'Open Text Response',
    fieldType: 'text',
  },
];

export async function fetchTemplate(id: string) {
  const template = await ScorecardTemplateService.show(id, {
    expand: [
      'survey_template',
      'survey_template.sections',
      'survey_template.sections.questions',
    ],
  });

  const cSections: SectionData[] = template.surveyTemplate.sections?.map(
    (sSection) => ({
      sId: sSection.id,
      cId: randomInt(),
      name: sSection.name,
      fields: sSection.questions.map((sField) => ({
        serverId: sField.id,
        clientId: randomInt(),
        title: sField.title,
        aiFillEnabled: sField.aiFillEnabled,
        ...FIELD_CONFIGS.find(
          (config) => config.fieldType === sField.fieldType,
        ),
      })),
    }),
  );

  return {
    name: template.surveyTemplate.name,
    sections: cSections ?? [],
    default: template.default,
  };
}

export function areSectionsSavable(sections: SectionData[]) {
  for (const section of sections) {
    if (!section.name.trim()) return false;

    for (const field of section.fields) {
      if (!field.fieldType) return false;
      if (!field.title.trim()) return false;
    }
  }

  return true;
}
