import { EmailUserIntegration } from '../entities/EmailUserIntegration';
import { appendQueryParam } from './url';

export function isFailedIntegration(emailIntegration: EmailUserIntegration) {
  return emailIntegration?.connectionStatus === 'failed_not_authorized';
}

export function getConnectionEmailUrl() {
  return appendQueryParam(
    '/account_settings/email_integrations',
    'state',
    window.location.href,
  );
}
