import React from 'react';
import { SurveyRoute } from './SurveyRoute';
import { SurveyFinalScreen } from './SurveyFinalScreen';
import defaultFinishScreenImage from '../../../images/woman-success.svg';
import { Survey } from '../../../entities/survey_engine/Survey';

interface PropTypes {
  survey: Survey;
  surveyFinalScreen?: React.ReactNode;
}

export function FinishRoute(props: PropTypes) {
  const CustomSurveyFinalScreen =
    props.surveyFinalScreen as React.FC<PropTypes>;
  const finishScreenImage =
    props.survey.company_info.ce_survey_completed_image_url ??
    defaultFinishScreenImage;
  const headingStyle = {
    letterSpacing: props.survey.company_info.font_kerning * 2 + 'em',
  };

  return (
    <SurveyRoute survey={props.survey} status='completed' mismatchPath='..'>
      {CustomSurveyFinalScreen ? (
        <CustomSurveyFinalScreen survey={props.survey} />
      ) : (
        <SurveyFinalScreen
          survey={props.survey}
          finishScreenImage={finishScreenImage}
          headingStyle={headingStyle}
        />
      )}
    </SurveyRoute>
  );
}
