import React from 'react';
import { IconButton } from '../../../../components/IconButton';

interface PropTypes {
  color: string;
  disabled?: boolean;
  onClick?: () => void;
}

export function RequestBackgroundCheckButton(props: PropTypes) {
  return (
    <IconButton
      color={props.color}
      onClick={props.onClick}
      disabled={props.disabled}
      iconName='bi-file-earmark-arrow-up'
      buttonText='Request Background Check'
    />
  );
}
