import React from 'react';
import { ActionItemBody } from './ActionItemBody';
import { User } from '../../../../../entities/User';
import { ActionItem } from '../../../../../entities/v1/applicant_tracking/ActionItem';

interface PropTypes {
  actionItem: ActionItem;
  currentUser: User;
}

export function ActionItemPanel(props: PropTypes) {
  const bgColor = props.actionItem.completed ? 'light-success' : 'gray';
  const actionIconColor = props.actionItem.completed
    ? 'success'
    : 'blue-gray-900';

  return (
    <div
      className={`bg-${bgColor} p-4 rounded-3 d-flex justify-content-between align-items-start mt-1 mb-2`}
    >
      <div className='d-flex me-2'>
        <i
          className={`bi bi-check2-circle me-3 fs-4 text-${actionIconColor}`}
        ></i>
        <div>
          <span className='fw-bold'>{props.actionItem.hiringMember.name}</span>
          &nbsp;
          <ActionItemBody {...props} />
        </div>
      </div>
      <div className='fs-6 text-nowrap'>
        {props.actionItem.completed ? (
          <span className='text-success'>Done</span>
        ) : (
          <span className='text-dark-200'>Pending</span>
        )}
      </div>
    </div>
  );
}
