import classNames from 'classnames';
import React from 'react';
import { Chip, ChipColor } from '../../../../../../../../../components/Chip';
import { HiringMemberAnalytics } from '../../../../../../../../../entities/candidate_experience/HiringMemberAnalytics';
import styles from '../../TableBody/styles.module.scss';

interface PropTypes {
  hiring_member: HiringMemberAnalytics;
}

export function JobsCount(props: PropTypes) {
  return (
    <td
      className={classNames(
        styles['table-data-cell-border-bottom'],
        'text-end pb-4',
      )}
    >
      <Chip size={'md'} color={ChipColor.Gray}>
        {props.hiring_member.jobs_count}
      </Chip>
    </td>
  );
}
