import React, { useEffect, useReducer, useState } from 'react';
import {
  Action,
  reducer,
} from '../../../survey_engine/SurveyTemplates/dispatcher';
import { Card } from '../../../../components/Card';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { SectionData } from '../../../survey_engine/SurveyTemplates/Section';
import { SectionList } from '../../../survey_engine/SurveyTemplates/SectionList';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { StartingPoint } from '../../../survey_engine/SurveyTemplates/StartingPointSelector';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import { NameInput } from '../../../survey_engine/SurveyTemplates/NameInput';
import { StartingPointSelector } from '../StartingPointSelector';
import {
  areSectionsSavable,
  fetchTemplate,
  findSelectableFieldConfigs,
  makeBlankApplicationForm,
} from '../data';
import { FieldConfig } from '../../../survey_engine/SurveyTemplates/Field';
import { ApplicationFormTemplateService } from '../../../../services/v1/applicant_tracking/ApplicationFormTemplateService';
import { ConsentFieldAlert } from '../ConsentFieldAlert';

const REDIRECT_URI =
  getRedirectUriQueryParam() ??
  '/organization_settings/application_form_templates';

export default function NewApplicationFormTemplatePage() {
  // State
  const [name, setName] = useState<string>('');
  const [startingPoint, setStartingPoint] = useState<StartingPoint>(null);
  const [sections, dispatch] = useReducer(
    reducer,
    null,
    makeBlankApplicationForm,
  );
  const [fieldConfigs, setFieldConfigs] = useState<FieldConfig[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Derived State
  function isSavable() {
    if (!name.trim()) return false;
    if (startingPoint === undefined) return false;
    if (!areSectionsSavable(sections)) return false;

    return true;
  }

  // Handlers
  useEffect(() => {
    const newFieldConfigs = findSelectableFieldConfigs(sections);
    setFieldConfigs(newFieldConfigs);
  }, [sections]);

  async function handleStartingPointChange(startingPoint: StartingPoint) {
    setStartingPoint(startingPoint);

    if (startingPoint == null) return;

    if (startingPoint === 'FROM_SCRATCH') {
      dispatch({ type: 'reset', newState: makeBlankApplicationForm() });
      return;
    }

    setIsLoading(true);

    const template = await fetchTemplate(startingPoint.value);
    dispatch({ type: 'reset', newState: template.sections });

    setIsLoading(false);
  }

  async function handleSave() {
    setIsLoading(true);

    await ApplicationFormTemplateService.create({
      name,
      sections: sections.map((section) => ({
        name: section.name,
        description: section.description,
        questions: section.fields.map((field) => ({
          fieldType: field.fieldType,
          max: field.max,
          skipQuestionField: field.skipQuestionField,
          tag: field.tag,
          title: field.title,
          mandatory: field.mandatory,
          aiFillEnabled: field.aiFillEnabled,
          options: field.options?.map((option) => ({
            name: option.name,
          })),
        })),
      })),
    });

    window.location.href = REDIRECT_URI;
  }

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <UI
        isSavable={isSavable()}
        name={name}
        onName={setName}
        startingPoint={startingPoint}
        onStartingPointChange={handleStartingPointChange}
        fieldConfigs={fieldConfigs}
        sections={sections}
        dispatch={dispatch}
        onSave={handleSave}
      />
    </>
  );
}

function UI(props: {
  isSavable: boolean;
  name: string;
  onName: (value: string) => void;
  startingPoint: StartingPoint;
  onStartingPointChange: (value: StartingPoint) => void;
  fieldConfigs: FieldConfig[];
  sections: SectionData[];
  dispatch: React.Dispatch<Action>;
  onSave: () => void;
}) {
  return (
    <div className='container'>
      <PageHeader title='Add Application Form' backUrl={REDIRECT_URI} />
      <Card>
        <CardHeader
          title='Application Form'
          isSavable={props.isSavable}
          onSaveClick={props.onSave}
          cancelUrl={REDIRECT_URI}
        />
        <div className='border-bottom'>
          <div className='d-flex flex-column w-50 fs-5 pt-2 pb-4 gap-4'>
            <NameInput
              name={props.name}
              onName={props.onName}
              placeholder='Insert application form name'
            />
            <StartingPointSelector
              startingPoint={props.startingPoint}
              onStartingPointChange={props.onStartingPointChange}
            />
          </div>
        </div>
        <SectionList
          title='Sections'
          subtitle='Define and manage your application form sections'
          fieldConfigs={props.fieldConfigs}
          singleTypeSections={false}
          sections={props.sections}
          dispatch={props.dispatch}
          columns={[
            { fieldName: 'type', className: 'col-3' },
            { fieldName: 'text', className: 'col' },
            { fieldName: 'mandatory', className: 'col-1' },
          ]}
          enableDescription={true}
        />
        <ConsentFieldAlert />
      </Card>
    </div>
  );
}
