import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import 'react-loading-skeleton/dist/skeleton.css';
import { Interview } from '../../../entities/Interview';
import { Transcript } from '../../../entities/v1/interview_intelligence/Transcript';
import { Monologues } from './Monologues';

interface PropTypes {
  playTimestamp: number;
  interview: Interview;
  findString: string;
  classNames?: string;
  maxHeightPx?: number;
  activeMonologueOffset: number;
  selectedSearchOccurence: { monologue: number; mark: number; global: number };
  onSelectTimestamp?: (timestamp: number) => void;
  onUpdateMonologueSearchOccurences?: (index: number, count: number) => void;
}

function getDivHeight(maxHeight?: number): string {
  if (maxHeight == null) return '24rem';

  // 56px is a fixed size for the auto-scroll div.
  return `${maxHeight - 56}px`;
}

export function Transcript(props: PropTypes) {
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const onChangeAutoScroll = useCallback(
    () => setAutoScroll(!autoScroll),
    [autoScroll],
  );

  useEffect(() => {
    setAutoScroll(props.findString === '');
  }, [props.findString]);

  return (
    <div className='d-flex flex-column align-items-start'>
      <div className='row align-items-center border-bottom-0 w-100 py-3 ps-4'>
        <div className='col text-end'>
          <label className='fs-5'>
            <input
              type='checkbox'
              checked={autoScroll}
              onChange={onChangeAutoScroll}
              className='me-1'
              style={{ verticalAlign: 'middle' }}
            />{' '}
            <span style={{ verticalAlign: 'middle' }}>Auto scroll</span>
          </label>
        </div>
      </div>
      <div
        className={classNames(
          'px-3',
          'w-100',
          'overflow-auto',
          props.classNames,
        )}
        style={{
          scrollBehavior: 'smooth',
          height: getDivHeight(props.maxHeightPx),
          maxHeight: getDivHeight(props.maxHeightPx),
        }}
      >
        <Monologues {...props} autoScroll={autoScroll} />
      </div>
    </div>
  );
}
