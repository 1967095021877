export const MINUTES_IN_DAY = 1440;

export class Timedelta {
  private timeMs: number;

  public static fromMins(timeMins: number): Timedelta {
    return new Timedelta(timeMins * 60 * 1000);
  }

  public static fromSecs(timeSecs: number): Timedelta {
    return new Timedelta(timeSecs * 1000);
  }

  public static fromMs(timeMs: number): Timedelta {
    return new Timedelta(timeMs);
  }

  public static timeTillDate(date: Date | string): Timedelta {
    const now = new Date();
    const parsedDate = new Date(date);

    return new Timedelta(+parsedDate - +now);
  }

  private constructor(timeMs: number) {
    this.timeMs = timeMs;
  }

  public toMs(): number {
    return this.timeMs;
  }

  public toSecs(): number {
    return this.timeMs / 1000;
  }

  public toMins(): number {
    return this.timeMs / 60000;
  }
}
