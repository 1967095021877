import React from 'react';
import { QueryObserverResult } from 'react-query';
import { HighlightType } from '../../../containers/InterviewPage/Entities/HighlightType';
import { Interview } from '../../../entities/Interview';
import { InterviewMarker } from '../../../entities/InterviewMarker';
import { ThreadData } from '../../../entities/ThreadData';
import {
  AutomaticQuestion,
  AutomaticQuestionList,
} from '../../../entities/v1/interview_intelligence/AutomaticQuestion';
import { acceptTimestampMarker } from '../../../utils/markerFilter';
import { Timedelta } from '../../../utils/timedelta';
import { MenuType } from '../MenuType';
import { NotesThread } from '../NotesThread';
import { EmptyState } from './EmptyState';
import { UnsupportedState } from './UnsupportedState';

interface PropTypes {
  selectedHighlightType: HighlightType;
  interview: Interview;
  playTimestamp: number;
  menuType?: MenuType;
  automaticQuestions?: AutomaticQuestion[];
  refetchAutomaticQuestions: (
    options?: unknown,
  ) => Promise<QueryObserverResult<AutomaticQuestionList, unknown>>;
  onSelectTimestamp?: (ts: number) => void;
  onUpdateInterviewMarkers?: (interviewMarkers: InterviewMarker[]) => void;
  showResponses: boolean;
  showNameAndOffset: boolean;
  showContextQuestion: boolean;
}

enum TimelineHighlightType {
  All = 'all',
  AutomaticQuestion = 'automatic_question',
  ScorecardAttribute = 'scorecard_attribute',
  Note = 'note',
  Reaction = 'reaction',
}

function findActiveNote(threadData: ThreadData[], time_ms: number): number {
  let activeIndex = -1;
  const timeInSeconds = Timedelta.fromMs(time_ms).toSecs();

  for (const index in threadData) {
    const marker = threadData[index];
    const offsetInSeconds = Timedelta.fromMs(marker.offset_ms).toSecs();
    if (offsetInSeconds > timeInSeconds) break;
    activeIndex = parseInt(index, 10);
  }
  return activeIndex;
}

function convertInterviewMarkerToThreadData(
  interviewMarker: InterviewMarker,
): ThreadData {
  const displayName = interviewMarker.user
    ? interviewMarker.user.name
    : 'Screenloop';
  const userId = interviewMarker.user ? interviewMarker.user.id : null;

  return {
    id: interviewMarker.id,
    itemId: interviewMarker.id,
    note:
      interviewMarker.note != null
        ? {
            id: interviewMarker.note.id,
            text: interviewMarker.note.text,
            user: interviewMarker.note.user,
            createdAt: interviewMarker.note.created_at,
            deleteAccess: interviewMarker.note.delete_access,
          }
        : null,
    replies: interviewMarker.replies?.map((reply) => {
      return {
        id: reply.id,
        text: reply.text,
        user: reply.user,
        createdAt: reply.created_at,
        deleteAccess: reply.delete_access,
      };
    }),
    scorecardAttribute: interviewMarker.scorecard_attribute,
    score: interviewMarker.score,
    kind: interviewMarker.kind,
    iconClasses: interviewMarker.icon_classes,
    offset_ms: interviewMarker.offset_ms,
    displayName: displayName,
    userId: userId,
    redacted: interviewMarker.redacted,
  };
}

function convertAutomaticQuestionToThreadData(
  automaticQuestion: AutomaticQuestion,
): ThreadData {
  return {
    id: -automaticQuestion.id,
    itemId: automaticQuestion.id,
    replies: automaticQuestion.replies,
    kind: 'automatic_question',
    iconClasses: 'bi bi-question-square-fill text-warning',
    offset_ms:
      automaticQuestion.contextStartOffsetMs || automaticQuestion.startOffsetMs,
    displayName: automaticQuestion.participant?.name,
    question: automaticQuestion.question,
    contextQuestion: automaticQuestion.contextQuestion,
    redacted: automaticQuestion.redacted,
    automaticNotes: automaticQuestion.automaticNotes,
  };
}

function isUnsupported(
  selectedHighlightType: HighlightType,
  interview: Interview,
) {
  return (
    (selectedHighlightType === 'scorecard_attribute' &&
      interview.attribute_detection_supported === false) ||
    (selectedHighlightType === 'automatic_question' &&
      interview.question_detection_supported === false)
  );
}
function isEmpty(threadData: ThreadData[], selectedHighlightType: string) {
  const emptyStatableTypes = [
    'all',
    'scorecard_attribute',
    'note',
    'reaction',
    'automatic_question',
  ];

  return (
    threadData.length === 0 &&
    emptyStatableTypes.includes(selectedHighlightType)
  );
}

function getDisplayText(type: HighlightType) {
  switch (type) {
    case TimelineHighlightType.All:
      return 'All';
    case TimelineHighlightType.AutomaticQuestion:
      return 'Questions';
    case TimelineHighlightType.ScorecardAttribute:
      return 'Attributes';
    case TimelineHighlightType.Note:
      return 'Notes';
    case TimelineHighlightType.Reaction:
      return 'Reactions';
    default:
      return '';
  }
}

export function InterviewMarkerList(props: PropTypes) {
  const threadData = props.interview.interview_markers
    .map((interviewMarker) =>
      convertInterviewMarkerToThreadData(interviewMarker),
    )
    .concat(
      props.automaticQuestions?.map((aq) =>
        convertAutomaticQuestionToThreadData(aq),
      ) || [],
    )
    .filter((threadData) =>
      acceptTimestampMarker(props.selectedHighlightType, threadData),
    )
    .sort(
      (a, b) =>
        (a.offset_ms != null ? a.offset_ms : Infinity) -
        (b.offset_ms != null ? b.offset_ms : Infinity),
    );

  const activeIndex = findActiveNote(threadData, props.playTimestamp);

  if (isUnsupported(props.selectedHighlightType, props.interview)) {
    return <UnsupportedState type={props.selectedHighlightType} />;
  }
  if (isEmpty(threadData, props.selectedHighlightType)) {
    return <EmptyState type={props.selectedHighlightType} />;
  }

  return (
    <>
      <div className='d-flex flex-column gap-3'>
        <span className='fs-4 fw-bold'>
          {getDisplayText(props.selectedHighlightType)}
        </span>
        <div>
          {threadData.map((td, i) => (
            <NotesThread
              key={`${td.kind}_${td.id}`}
              playTimestamp={
                i === activeIndex ? props.playTimestamp : undefined
              }
              interview={props.interview}
              threadData={td}
              showIcon={true}
              menuType={props.menuType}
              onSelectTimestamp={props.onSelectTimestamp}
              onUpdateInterviewMarkers={props.onUpdateInterviewMarkers}
              automaticQuestions={props.automaticQuestions}
              refetchAutomaticQuestions={props.refetchAutomaticQuestions}
              showResponses={props.showResponses}
              showNameAndOffset={props.showNameAndOffset}
              showContextQuestion={props.showContextQuestion}
            />
          ))}
        </div>
      </div>
    </>
  );
}
