import classNames from 'classnames';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styles from './styles.module.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';

export interface DateFilter {
  start: Moment;
}

interface PropTypes {
  /** Classes to apply to the wrapping element. */
  className?: string;
  /** The startDate and the endDate filters. */
  dateFilter: DateFilter;
  /** The minDate filter */
  minDate: Moment;
  /** Called when the input changes, with the newly selected options. */
  onChange: (start: Moment) => void;
}

function generatePlaceholder(dateFilter: DateFilter) {
  if (dateFilter?.start != null) {
    return dateFilter?.start.format('DD/MM/YYYY');
  }
  return 'Select Start Date';
}

export function SingleDatePicker(props: PropTypes) {
  const [state, setState] = useState(props.dateFilter);
  useEffect(() => {
    props.onChange(state.start);
  }, [state]);
  return (
    <DateRangePicker
      initialSettings={{
        startDate: props.dateFilter?.start?.toDate(),
        singleDatePicker: true,
        minDate: props.minDate,
      }}
      onApply={(_e, picker: any) => {
        setState({
          start: picker.startDate.utc(true),
        });
      }}
    >
      <button
        id='reportrange'
        className={classNames(
          'btn border-primary bg-white text-nowrap w-100',
          styles.inputDateRangePicker,
          props.className,
        )}
      >
        <i className={`bi bi-calendar pe-2 ${styles.bi}`} />
        <span className={classNames('color-dark-500', 'fw-normal')}>
          {generatePlaceholder(props.dateFilter)}
        </span>
      </button>
    </DateRangePicker>
  );
}
