import {
  DecoratorNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import React from 'react';
import { Chip, ChipColor } from '../../Chip';

export type SerializedVariableNode = Spread<
  {
    variable: string;
  },
  SerializedLexicalNode
>;

function convertVariablelement(
  domNode: HTMLDivElement,
): DOMConversionOutput | null {
  const variable = domNode.getAttribute('data-lexical-variable');
  if (variable) {
    const node = $createVariableNode(variable);
    return { node };
  }
  return null;
}

export class VariableNode extends DecoratorNode<JSX.Element> {
  __variable: string;

  static getType(): string {
    return 'variable';
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(node.__variable, node.__key);
  }

  constructor(variable: string, key?: NodeKey) {
    super(key);
    this.__variable = variable;
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const span = document.createElement('span');
    span.classList.add('align-items-center');

    return span;
  }

  static importDOM(): DOMConversionMap<HTMLDivElement> | null {
    return {
      div: (domNode: HTMLDivElement) => {
        if (!domNode.hasAttribute('data-lexical-variable')) {
          return null;
        }
        return {
          conversion: convertVariablelement,
          priority: 2,
        };
      },
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-variable', this.__variable);
    element.innerText = this.__variable;
    return { element };
  }

  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    const node = $createVariableNode(serializedNode.variable);

    return node;
  }

  exportJSON(): SerializedVariableNode {
    return {
      variable: this.__variable,
      type: this.getType(),
      version: 1,
    };
  }

  getTextContent(): string {
    return this.__variable;
  }

  updateDOM(): false {
    return false;
  }

  setVariable(variable: string): void {
    const writable = this.getWritable();
    writable.__variable = variable;
  }

  decorate(_editor: LexicalEditor, _config: EditorConfig): JSX.Element {
    return (
      <Chip color={ChipColor.BlueLight}>
        {this.__variable.replace('{{{', '').replace('}}}', '')}
      </Chip>
    );
  }
}

export function $createVariableNode(variable: string): VariableNode {
  return new VariableNode(variable);
}

export function $isVariableNode(node: LexicalNode): boolean {
  return node instanceof VariableNode;
}
