import React, { useState } from 'react';
import { ConnectionStatus, EmailDomain } from '../../../entities/EmailDomain';
import { EmailDomainService } from '../../../services/EmailDomainService';
import { DnsSettingsRow } from './DnsSettingsRow';
import { DomainSettingsRow } from './DomainSettingsRow';
import { CustomDomainRow } from './CustomDomainRow';

export interface PropTypes {
  email_domain: EmailDomain;
}

function isEmailDomainValid(emailDomain: EmailDomain) {
  return Object.keys(emailDomain).length > 0;
}

async function toggleEmailDomain(
  emailDomainId: number,
  isEmailDomainEnabled: boolean,
  setEmailDomain: (emailDomain: EmailDomain) => void,
) {
  if (emailDomainId === undefined) return;

  if (isEmailDomainEnabled) {
    await EmailDomainService.disable(emailDomainId);
  } else {
    await EmailDomainService.enable(emailDomainId).then((emailDomain) =>
      setEmailDomain(emailDomain),
    );
  }
}

export default React.memo(function EmailDomainSettings(props: PropTypes) {
  const [emailDomain, setEmailDomain] = useState(props.email_domain);
  const [isEmailDomainEnabled, setEmailDomainEnabled] = useState(
    isEmailDomainValid(emailDomain) &&
      emailDomain.connection_status !== ConnectionStatus.Disconnected,
  );

  async function onToggleChange() {
    setEmailDomainEnabled(!isEmailDomainEnabled);

    if (!isEmailDomainValid(emailDomain)) return;

    await toggleEmailDomain(
      emailDomain.id,
      isEmailDomainEnabled,
      setEmailDomain,
    );
  }

  return (
    <div className='app-card-body w-100'>
      <div className='item border-bottom py-3'>
        <CustomDomainRow
          isEmailDomainEnabled={isEmailDomainEnabled}
          onToggleChange={onToggleChange}
        />
      </div>
      <div className='item border-bottom py-3'>
        <DomainSettingsRow
          isEmailDomainEnabled={isEmailDomainEnabled}
          emailDomain={emailDomain}
          setEmailDomain={setEmailDomain}
        />
      </div>
      <div className='item py-3'>
        <DnsSettingsRow emailDomain={emailDomain} />
      </div>
    </div>
  );
});
