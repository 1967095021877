import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  classNames?: string;
}

export function VerticalDivider(props: PropTypes) {
  return <span className={classNames('border-start pb-2', props.classNames)} />;
}
