import React from 'react';

interface PropTypes {
  isFormActive: boolean;
  senderName: string;
  setSenderName: (name: string) => void;
}

export function SenderNameInput(props: PropTypes) {
  return (
    <input
      type='text'
      className='form-control form-control-sm'
      placeholder='Name'
      id='name'
      aria-label='name'
      disabled={!props.isFormActive}
      value={props.senderName}
      onChange={(e) => props.setSenderName(e.target.value)}
    />
  );
}
