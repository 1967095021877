import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';

interface PropTypes {
  activeRating?: number;
  onActiveRating: (rating: number | null) => void;
}

function RatingNumber(props: {
  activeRating?: number;
  value: number;
  onActiveRating: (rating: number | null) => void;
}) {
  const isActive = props.activeRating === props.value;

  function handleClick() {
    props.onActiveRating(isActive ? null : props.value);
  }

  return (
    <Button
      size='sm'
      color={isActive ? 'primary' : 'secondary'}
      onClick={handleClick}
    >
      {props.value}
    </Button>
  );
}

export function FeedbackRating(props: PropTypes) {
  return (
    <div className='text-center'>
      <h4 className={classNames('fw-bold', 'mb-2')}>
        On a scale of 1 to 5, how would you
        <br />
        rate this training session?
      </h4>
      <div
        className={classNames(
          'd-flex',
          'flex-nowrap',
          'justify-content-center',
          'gap-2',
        )}
      >
        {[1, 2, 3, 4, 5].map((value: number) => {
          return <RatingNumber {...props} key={value} value={value} />;
        })}
      </div>
    </div>
  );
}
