import { getQueryParam, QueryParam } from '../../../utils/url';
import { ListCandidatesFilterTab } from '../FilterStates';

export const TAB_QUERY_PARAM = 'tab';

export function parseTab(): ListCandidatesFilterTab {
  const value = getQueryParam(TAB_QUERY_PARAM);

  const parsedTab: ListCandidatesFilterTab = value as ListCandidatesFilterTab;

  if (parsedTab != null) return parsedTab;

  return null;
}

export function mapTabToQueryParam(
  value?: ListCandidatesFilterTab,
): QueryParam {
  if (value == null) return null;

  return {
    key: TAB_QUERY_PARAM,
    value: value,
  };
}
