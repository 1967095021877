import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Alert, AlertType } from '../../../components/Alert';
import { EmptyState } from '../../../components/EmptyState';
import UndrawEmptyStateCactus from '../../../images/undraw_empty_state_cactus.svg';
import { getNounWithPossessiveCase } from '../../../utils/grammar';
import { SurveyLayout } from '../../components/SurveyLayout';
import { SurveyInfo } from '../../entities/SurveyInfo';
import { SurveyRequestInfo } from '../../entities/SurveyRequestInfo';
import { SurveyRequestReferralInfo } from '../../entities/SurveyRequestReferralInfo';
import { CandidateSurveyRequestService } from '../../services/CandidateSurveyRequestService';
import { CandidateSurveyService } from '../../services/CandidateSurveyService';
import { getReferencesReferrals } from '../../utils/getReferencesReferrals';
import { getSelfAssessmentSurvey } from '../../utils/getSelfAssessmentSurvey';
import { CandidateReferralRow } from './CandidateReferralRow';
import { ReferenceFormModal } from './ReferenceFormModal';
import { SelectedReference } from './SelectedReference';
import styles from './styles.module.scss';
import classNames from 'classnames';

export interface SurveyInfoSubmission extends SurveyInfo {
  candidate_collaboration_started_on_month: number;
  candidate_collaboration_started_on_year: number;
  candidate_collaboration_ended_on_month: number;
  candidate_collaboration_ended_on_year: number;
}

export function CandidateSurveyRequestPage() {
  const { id } = useParams<'id'>();
  const [surveyRequestInfo, setSurveyRequestInfo] =
    useState<SurveyRequestInfo>();
  const [selectedReference, setSelectedReference] =
    useState<SelectedReference>();
  const [isReferenceFormModalOpen, setIsReferenceFormModalOpen] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>(null);

  useEffect(() => {
    (async () => {
      const surveyRequest = await CandidateSurveyRequestService.get(
        parseInt(id, 10),
      );
      setSurveyRequestInfo(surveyRequest);
    })();
  }, [id]);

  const handleSelectReference = useCallback(
    (referral: SurveyRequestReferralInfo, index?: number) => {
      if (referral === undefined) {
        setIsReferenceFormModalOpen(false);
        return setSelectedReference(undefined);
      }

      setIsReferenceFormModalOpen(true);
      setSelectedReference({ referral, index });
    },
    [id],
  );

  const onSubmit = useCallback(
    async (surveyForm: Partial<SurveyInfoSubmission>) => {
      const prevReferral = selectedReference.referral;
      const prevSurvey = prevReferral.surveys[0];

      const collabStart =
        surveyForm.candidate_collaboration_started_on_year &&
        moment()
          .year(surveyForm.candidate_collaboration_started_on_year)
          .month(surveyForm.candidate_collaboration_started_on_month)
          .startOf('month');
      const collabEnd =
        surveyForm.candidate_collaboration_ended_on_year &&
        moment()
          .year(surveyForm.candidate_collaboration_ended_on_year)
          .month(surveyForm.candidate_collaboration_ended_on_month)
          .endOf('month');

      const surveyRequestInfo = await CandidateSurveyService.create(
        selectedReference.referral.id,
        {
          ...surveyForm,
          candidate_collaboration_started_on: collabStart?.format('YYYY-MM-DD'),
          candidate_collaboration_ended_on: collabEnd?.format('YYYY-MM-DD'),
        },
      );

      setSurveyRequestInfo(surveyRequestInfo);
      setSelectedReference(undefined);

      const newReferral = surveyRequestInfo.referrals.find(
        (r) => r.id === prevReferral.id,
      );
      const newSurvey = newReferral.surveys[0];

      if (prevSurvey?.email !== newSurvey.email) {
        setAlertMessage(
          'Reference request successfully submitted and a new email was sent.',
        );
      } else {
        setAlertMessage('References changes was successfully saved!');
      }
    },
    [selectedReference],
  );

  if (surveyRequestInfo === undefined) return null;

  const selfAssessmentSurvey = getSelfAssessmentSurvey(surveyRequestInfo);
  const referencesReferrals = getReferencesReferrals(surveyRequestInfo);

  return (
    <SurveyLayout
      companyInfo={surveyRequestInfo.company_info}
      className={styles.candidateSurveyRequestPage}
    >
      {surveyRequestInfo.status !== 'cancelled' ? (
        <>
          {alertMessage && (
            <Alert
              type={AlertType.Success}
              clearable={true}
              autoClearTimeout={4000}
              onClose={() => setAlertMessage(null)}
            >
              {alertMessage}
            </Alert>
          )}
          {selectedReference && (
            <ReferenceFormModal
              reference={selectedReference}
              isOpen={isReferenceFormModalOpen}
              onSubmit={onSubmit}
              onClose={() => handleSelectReference(undefined)}
            />
          )}
          <div className={classNames(styles.referencesCard)}>
            <div className={classNames('p-5')}>
              <Row>
                <Col xs='12'>
                  <Row>
                    <Col xs='12'>
                      <div className='d-flex align-items-start'>
                        <div className='me-auto'>
                          <h2>
                            {getNounWithPossessiveCase(
                              surveyRequestInfo.candidate.name,
                            )}{' '}
                            Feedback
                          </h2>
                          {selfAssessmentSurvey ? (
                            <p className='fw-normal mb-0'>
                              Please add your references and complete your
                              self-assessment.
                            </p>
                          ) : (
                            <p className='fw-normal mb-0'>
                              Please add your references.
                            </p>
                          )}
                        </div>
                        <div>
                          {selfAssessmentSurvey !== undefined && (
                            <div>
                              {selfAssessmentSurvey &&
                              selfAssessmentSurvey.status === 'pending' ? (
                                <a
                                  className='btn btn-secondary'
                                  href={`/reference_check/surveys/${selfAssessmentSurvey.secure_id}/start`}
                                >
                                  Fill Self-Assessment
                                </a>
                              ) : (
                                <Button color='secondary' disabled>
                                  <i
                                    className='bi bi-check-circle me-2'
                                    style={{
                                      width: '1em',
                                      height: '1em',
                                      top: 0,
                                    }}
                                  />
                                  Fill Self-Assessment
                                </Button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className='my-4'>
                    <Col>
                      <div className='border-bottom'></div>
                    </Col>
                  </Row>
                  {referencesReferrals
                    .sort((a, b) => a.id - b.id)
                    .map((ref, i) => (
                      <CandidateReferralRow
                        key={ref.id}
                        referral={ref}
                        index={i}
                        onSelectReference={handleSelectReference}
                      />
                    ))}
                </Col>
              </Row>
            </div>
          </div>
        </>
      ) : (
        <EmptyState
          title={'This reference request was cancelled'}
          text={
            <p>
              The reference request you are trying to access was cancelled.
              <br />
              To know more, please get in touch with&nbsp;
              {surveyRequestInfo.company_info.name}.
            </p>
          }
          imageSrc={UndrawEmptyStateCactus}
        />
      )}
    </SurveyLayout>
  );
}
