import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  innerProps?: any;
  classNames?: string;
  onClick?: () => void;
}

export const ClearIcon = (props: PropTypes) => {
  return (
    <div onClick={props.onClick}>
      <i
        style={{ top: '0px' }}
        className={classNames('bi', 'bi-x', props.classNames)}
        {...props.innerProps}
      ></i>
    </div>
  );
};
