import React, { useEffect, useState } from 'react';
import { LabelledMultiSelect } from '../../../../../../components/LabelledMultiSelect';
import { SelectOption } from '../../../../../../components/Select';
import { ApprovalAssignedTo } from '../../../../../../entities/applicant_tracking/ApprovalFlow';
import { Department } from '../../../../../../entities/applicant_tracking/Department';
import { Location } from '../../../../../../entities/applicant_tracking/Location';
import { DepartmentService } from '../../../../../../services/applicant_tracking/DepartmentService';
import { LocationService } from '../../../../../../services/applicant_tracking/LocationService';
import { humanize } from '../../../../../../utils/humanize';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';

interface SelectInputPropTypes {
  assignedTo: ApprovalAssignedTo;
  selectedIds: number[];
  disabledLocationIds: number[];
  disabledDepartmentIds: number[];
  readOnly?: boolean;
  setSelectedIds: (ids: number[]) => void;
}

async function loadDepartments(disabledIds: number[]) {
  return await DepartmentService.list().then((departments) => {
    return departments
      .sort((a: Department, b: Department) => a.name.localeCompare(b.name))
      .map((department) => ({
        label: department.name,
        value: department.id.toString(),
        disabled: disabledIds?.includes(department.id),
      }));
  });
}

async function loadLocations(disabledIds: number[]) {
  return await LocationService.list().then((locations: Location[]) => {
    return locations
      .sort((a: Location, b: Location) => a.name.localeCompare(b.name))
      .map((location) => ({
        label: location.name,
        value: location.id.toString(),
        disabled: disabledIds?.includes(location.id),
      }));
  });
}

export function LocationOrDepartmentSelectInput(props: SelectInputPropTypes) {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    props.assignedTo === 'department' &&
      loadDepartments(props.disabledDepartmentIds).then(setOptions);
    props.assignedTo === 'location' &&
      loadLocations(props.disabledLocationIds).then(setOptions);
    setIsLoading(false);
  }, [props.assignedTo]);

  if (props.assignedTo === 'organization') {
    return null;
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <LabelledMultiSelect
        disabled={props.readOnly}
        options={options}
        selected={options.filter((option) =>
          props.selectedIds.includes(parseInt(option.value)),
        )}
        label={humanize(props.assignedTo)}
        onChange={(newOptions) => {
          props.setSelectedIds(newOptions.map((opt) => parseInt(opt.value)));
        }}
        placeholder={`Select ${props.assignedTo}s`}
        singleSelectedText={`1 selected ${props.assignedTo}`}
        manySelectedText={`%d selected ${props.assignedTo}s`}
        className='w-30 mt-4'
        labelClassName='fw-semibold fs-5'
      />
    </>
  );
}
