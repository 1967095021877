import React from 'react';
import { PageTitle } from '../../../../../components/PageTitle';

export function Title(props: { edit: boolean; name?: string }) {
  if (props.edit) {
    return (
      <PageTitle
        text={
          <>
            <span>Edit / </span>
            <span className='text-dark-200'>{props.name}</span>
          </>
        }
      />
    );
  } else {
    return <PageTitle text={'Add Offer Letter'} />;
  }
}
