import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { CollapseButton } from '../../CollapseButton';
import { CollapsableElements } from '../../CollapsableElements';
import { PanelHeader } from '../PanelHeader';
import { PanelSubHeader } from '../PanelSubHeader';

interface PropTypes {
  contextElement?: JSX.Element;
  elements: JSX.Element[];
  elementsClassName?: string;
  elementNamePlural: string;
  maxElements: number;
  title: string;
  titleClasses?: string;
  label?: string;
  classNames: string;
}

export function CollapsablePanel(props: PropTypes) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div
      className={classNames(
        props.classNames,
        'rounded-4',
        'pt-3',
        styles.collapsable,
      )}
    >
      <PanelHeader
        titleClasses={props.titleClasses}
        title={props.title}
        label={props.label}
      />
      <PanelSubHeader contextElement={props.contextElement} />
      <div
        className={classNames(
          'd-flex',
          'flex-column',
          'mt-3',
          'w-100',
          'gap-2',
          props.elementsClassName,
        )}
      >
        <CollapsableElements
          collapsed={collapsed}
          elements={props.elements}
          elementsClassName={props.elementsClassName}
          maxElements={props.maxElements}
        />
      </div>
      <CollapseButton
        collapsed={collapsed}
        elementNamePlural={props.elementNamePlural}
        maxElements={props.maxElements}
        elementsLength={props.elements?.length}
        onCollapse={setCollapsed}
      />
    </div>
  );
}
