import moment from 'moment';
import { DateFilter } from '../components/DateRangePicker';

interface DatedFilter {
  dateRange: DateFilter;
}

function base64ToBytes(base64: string) {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

function bytesToBase64(bytes: Uint8Array) {
  const binString = Array.from(bytes, (byte) =>
    String.fromCodePoint(byte as number),
  ).join('');
  return btoa(binString);
}

export function readHash<T extends DatedFilter>(defaultFilter: T): T {
  if (window.location.hash === '') return defaultFilter;

  const bytes = base64ToBytes(window.location.hash.substring(1));
  const decoded = atob(new TextDecoder().decode(bytes));

  const parsed: T = JSON.parse(decodeURIComponent(decoded));
  parsed.dateRange.start = moment(parsed.dateRange.start);
  parsed.dateRange.end = moment(parsed.dateRange.end);
  return parsed;
}

export function writeHash<T extends DatedFilter>(filterState: T) {
  const json = encodeURIComponent(JSON.stringify(filterState));
  const hash = btoa(bytesToBase64(new TextEncoder().encode(json)));

  window.location.hash = hash;
}
