import React from 'react';
import { Button } from 'reactstrap';

interface ModalProps {
  stageName: string;
  onClose: (state: boolean) => void;
}

export function ConfirmDeleteStageModalBody(props: ModalProps) {
  return (
    <>
      <div className='mb-2 fs-5'>
        Are you sure you want to delete the{' '}
        <span className='fw-bold'>{props.stageName}</span> stage?
      </div>
      <div className='d-flex mt-4 justify-content-end'>
        <Button
          color='danger'
          className='me-2'
          onClick={() => props.onClose(false)}
        >
          Cancel
        </Button>
        <Button color='primary' onClick={() => props.onClose(true)}>
          Delete
        </Button>
      </div>
    </>
  );
}
