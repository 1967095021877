import React from 'react';
import { Icon } from '../../../../../../components/Icon';
import { Application } from '../../../../../../entities/Application';
import { SpanTooltip } from '../../../../../../components/SpanTooltip';
import { applicationAverageRating } from '../../../../../../utils/applicant_tracking/application';

interface PropTypes {
  application: Application;
}

function TootipBody(props: PropTypes) {
  return (
    <div className='px-2 py-2h'>
      <h5 className='text-primary'>Score Breakdown</h5>
      <div className='fs-5 text-dark-200 mb-2h'>
        {props.application.attributeRating && (
          <>
            <b className='text-primary'>{props.application.attributeRating}</b>{' '}
            AVG Attribute Rating
            <br />
          </>
        )}
        {props.application.applicationRating && (
          <>
            <b className='text-primary'>
              {props.application.applicationRating}
            </b>{' '}
            AVG Candidate Rating
          </>
        )}
      </div>
      <h6 className='text-dark-200 mb-0'>
        The AVG Score is calculated by averaging the available ratings above.
      </h6>
    </div>
  );
}

export function AverageRating(props: PropTypes) {
  const rating = applicationAverageRating(props.application);
  if (!rating) return null;

  return (
    <div className='d-flex align-items-center'>
      <Icon name='bi-star-fill' className='p-0 fs-4 text-yellow' />
      <div className='fs-1 fw-bold mx-2'>{rating}</div>
      <div className='fs-6 text-dark-200 me-2'>AVG Score</div>
      <SpanTooltip
        placement='right'
        tooltipInnerClassName='rounded-3'
        tooltipText={<TootipBody {...props} />}
        text={
          <Icon
            name='bi-info-circle'
            className='text-info fs-4'
            style={{ cursor: 'pointer' }}
          />
        }
        popperClassName='light-tooltip ms-3'
      />
    </div>
  );
}
