import React from 'react';
import { JobPostingDetailsForm } from '.';
import { TextField } from './TextField';
import { FormGroup } from './FormGroup';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: Record<string, string>;
  onInputChange: (name: string, value: unknown) => void;
}

export function LocationFields(props: PropTypes) {
  return (
    <FormGroup>
      <h4>Location</h4>
      <TextField
        label='Address Line 1'
        value={props.form.address1}
        name='address1'
        onInputChange={props.onInputChange}
        mandatory={true}
        error={props.errors['workingLocation']?.['addressLine1']}
        placeholder={'Insert Address'}
      />
      <TextField
        label='Address Line 2'
        value={props.form.address2}
        name='address2'
        error={props.errors['workingLocation']?.['addressLine2']}
        onInputChange={props.onInputChange}
        placeholder={'Insert Address'}
      />
      <div className='d-flex gap-2'>
        <TextField
          className='w-50'
          label='City'
          value={props.form.city}
          name='city'
          error={props.errors['workingLocation']?.['city']}
          onInputChange={props.onInputChange}
          mandatory={true}
          placeholder={'Insert City'}
        />
        <TextField
          className='w-50'
          label='Zip Code'
          value={props.form.postcode}
          name='postcode'
          error={props.errors['workingLocation']?.['postcode']}
          onInputChange={props.onInputChange}
          mandatory={true}
          placeholder={'Insert Zip Code'}
        />
      </div>
      <TextField
        label='Country'
        value={props.form.country}
        name='country'
        error={props.errors['workingLocation']?.['country']}
        onInputChange={props.onInputChange}
        mandatory={true}
        placeholder={'Insert Country'}
      />
    </FormGroup>
  );
}
