import React from 'react';
import { Button } from 'reactstrap';
import { Survey } from '../../../../../../entities/survey_engine/Survey';
import { SurveyQuestion } from '../../../../../../entities/survey_engine/SurveyQuestion';
import { hasAnsweredAllQuestions } from '../../../../../../utils/training/hasAnsweredAllQuestions';

interface PropTypes {
  survey: Survey;
  surveyQuestions: SurveyQuestion[];
  currentSectionIndex: number;
  onClick: () => void;
}

function shouldDisable(
  survey: Survey,
  surveyQuestions: SurveyQuestion[],
  currentSectionIndex: number,
): boolean {
  if (currentSectionIndex == survey.sections.length - 1) {
    // Check all questions were answered on the entire evaluation.
    return !hasAnsweredAllQuestions(survey.answers, surveyQuestions);
  } else {
    // Check all questions were answered on the current section.
    return !hasAnsweredAllQuestions(
      survey.answers,
      survey.sections[currentSectionIndex].questions,
    );
  }
}

function buttonText(survey: Survey, currentSectionIndex: number): string {
  // The last section should display "Submit"
  if (currentSectionIndex == survey.sections.length - 1) {
    return 'Submit';
  } else {
    return 'Next';
  }
}

export function SubmitButton(props: PropTypes) {
  return (
    <Button
      color='primary'
      disabled={shouldDisable(
        props.survey,
        props.surveyQuestions,
        props.currentSectionIndex,
      )}
      onClick={props.onClick}
      type='submit'
    >
      {buttonText(props.survey, props.currentSectionIndex)}
    </Button>
  );
}
