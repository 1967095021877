import React from 'react';
import { Chip, ChipColor } from '../../../../Chip';
import { Col } from 'reactstrap';

export function InterviewQuestionRow(props: {
  number: number;
  question: string;
}) {
  return (
    <>
      <div className='d-flex flex-nowrap mb-auto'>
        <Col xs='auto'>
          <Chip className='fw-semibold' color={ChipColor.Gray}>
            {props.number}
          </Chip>
        </Col>
        <Col className='ms-2h'>
          <span className='fs-5'>{props.question}</span>
        </Col>
      </div>
    </>
  );
}
