import { TrainingSortable } from '../../entities/training/TrainingSortable';

export function sortEntitiesByOrder<E extends TrainingSortable>(
  entities: E[],
): E[] {
  if (entities == null) {
    return [];
  }

  return entities.sort(
    (e1: TrainingSortable, e2: TrainingSortable) => e1.order - e2.order,
  );
}
