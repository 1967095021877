import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { getJobStageTriggers, TriggersIcon } from '../../TriggersIcon';
import { JobStage } from '../../../../../entities/JobStage';
import { Trigger } from '../../../../../entities/applicant_tracking/Trigger';
import { buildDropDownActions, JobStageDropdown } from './JobStageDropdown';
import { PipelineApplication } from '../../../../../entities/applicant_tracking/PipelineApplication';

interface PropTypes {
  applications: PipelineApplication[];
  jobStage: JobStage;
  triggers: Trigger[];
  bulkSelfSchedulingEnabled: boolean;
}

export function JobStageHeader(props: PropTypes) {
  const jobStageTriggers = getJobStageTriggers(props.jobStage, props.triggers);
  const jobStageHasTriggers = jobStageTriggers?.length > 0;

  const dropDownActions = buildDropDownActions(
    props.jobStage,
    props.applications,
    props.bulkSelfSchedulingEnabled,
  );

  return (
    <div className={'d-flex align-items-center border-bottom'}>
      <h4
        className={classNames(
          'p-4',
          'mb-0',
          'text-truncate',
          'text-start',
          'bg-white',
          'fw-bold',
          styles['pipeline-job-stage-header'],
        )}
      >
        {props.jobStage.name}
      </h4>
      <TriggersIcon
        className={dropDownActions.length == 0 && 'me-2h'}
        jobStage={props.jobStage}
        jobStageTriggers={jobStageTriggers}
      />
      <JobStageDropdown
        applications={props.applications}
        className={!jobStageHasTriggers && 'ms-auto'}
        jobStage={props.jobStage}
        menuActions={dropDownActions}
      />
    </div>
  );
}
