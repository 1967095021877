import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { InputFormField } from '../../../../../../components/InputFormField';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';

interface PropTypes {
  formRegister: UseFormRegister<CreateJobDto>;
  onUpdateGlobalState?: (state: any) => void;
}

export function JobTitleInput(props: PropTypes) {
  const required = true;

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <InputFormField
          type={'text'}
          registerReturn={props.formRegister('name', { required: required })}
          fieldName={'Job Title'}
          placeholder={'Insert job title'}
          isRequired={required}
          classNames={'fs-5'}
          headerClassNames={'fw-semibold'}
        />
      </Col>
    </Row>
  );
}
