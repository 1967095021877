import React from 'react';
import { findSectionType } from '../../../../../../utils/applicant_tracking/scorecardSurvey';
import { OpenTextSection } from '../OpenTextSection';
import { User } from '../../../../../../entities/User';
import { Scorecard } from '../../../../../../entities/applicant_tracking/Scorecard';
import { Section } from '../../../../../../entities/v1/survey_engine/Section';
import { RatingSection } from '../RatingSection';

interface PropTypes {
  user: User;
  scorecard: Scorecard;
  prefilledAttributesCount?: number;
  aiScorecardFill?: boolean;
  section: Section;
  fillColorClass?: string;
  readonly?: boolean;
  setScorecard?: (
    value: Scorecard | ((prevScorecard: Scorecard) => Scorecard),
  ) => void;
}

export function ScorecardQuestion(props: PropTypes) {
  const allowedToEdit = props.user.id === props.scorecard.user.id;

  switch (findSectionType(props.section)) {
    case 'rating':
      return (
        <RatingSection
          {...props}
          key={props.section.id}
          section={props.section}
          fillColorClass={props.fillColorClass}
        />
      );
    case 'text':
      return (
        <OpenTextSection
          {...props}
          key={props.section.id}
          section={props.section}
          readonly={props.readonly}
          aiScorecardFill={props.aiScorecardFill && allowedToEdit}
        />
      );
    default:
      return null;
  }
}
