import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { IconButton } from '../../../../../../../components/IconButton';
import { Row } from 'reactstrap';
import React from 'react';
import { FormState } from 'react-hook-form';
import { CreateJobDto } from '../../../../../../../services/applicant_tracking/JobService/CreateJobDto';

interface PropTypes {
  formState: FormState<CreateJobDto>;
  previewUrl: string;
  isGeneratorOpened: boolean;
  handleFillWithAIButtonClick: () => void;
  generateJobDescriptionEnabled: boolean;
}

export function JobDescriptionHeader(props: PropTypes) {
  return (
    <Row className='mt-4'>
      <div className='d-flex justify-content-between align-items-center col-8 mt-4 '>
        <div>
          <FormFieldHeader
            fieldName={'Description'}
            isRequired={true}
            classNames={'fw-semibold'}
            fontSize={'fs-3'}
          />
          <small className='text-muted'>
            Add job description, requirements and benefits
          </small>
        </div>
        {!props.isGeneratorOpened && (
          <div className='d-flex align-items-right'>
            {props.generateJobDescriptionEnabled && (
              <IconButton
                color='borderless'
                buttonText='Fill with AI'
                iconName='bi-stars'
                size='md'
                onClick={props.handleFillWithAIButtonClick}
              />
            )}
            <IconButton
              iconName='bi-box-arrow-up-right'
              color='secondary'
              buttonClass='ms-3 text-info border-info'
              buttonText='Preview'
              disabled={!props.formState.isValid}
              onClick={() => {
                window.open(props.previewUrl, '_blank');
              }}
            />
          </div>
        )}
      </div>
    </Row>
  );
}
