import React from 'react';
import { Table as BoostrapTable } from 'reactstrap';
import { TableBody } from './Body';
import { TableHeader } from './Header';
import { TableFooter } from './Footer';
import { FilterStates } from '../FilterStates';
import { Application } from '../../../entities/v1/Application';
import { defaultPage } from '../../FilterStates/PageFilter';

import { LoadingSpinner } from '../../../components/LoadingSpinner';
import {
  defaultSortingColumn,
  defaultSortingDirection,
} from '../../FilterStates/SortingColumn';

interface PropTypes {
  applications: Application[];
  interviewIntelligenceAccess: boolean;
  referenceCheckAccess: boolean;
  filterStates: FilterStates;
  totalRows: number | null;
  isLoading: boolean;
  isLoadingCount: boolean;
  onFilterStatesUpdate: (fs: FilterStates) => void;
}

export interface ColumnPropTypes {
  key: string;
  label: string;
  tooltipText?: string;
  isSortable?: boolean;
  hidden?: boolean;
  className?: string;
}

function columns(props: {
  interviewIntelligenceAccess: boolean;
  referenceCheckAccess: boolean;
}): ColumnPropTypes[] {
  return [
    {
      key: 'name',
      label: 'Candidate',
      isSortable: true,
      className: 'left-col col-3',
    },
    {
      key: 'job_name',
      label: 'Jobs',
      isSortable: true,
      className: 'col-2',
    },
    {
      key: 'applied_at',
      label: 'Applied At',
      isSortable: true,
      className: 'col-2',
    },
    {
      key: 'job_stage_name',
      label: 'Stages',
      isSortable: true,
      className: 'col-2',
    },
    {
      key: 'survey_request_status',
      label: 'Feedback',
      isSortable: true,
      className: 'col-2',
      hidden: !props.referenceCheckAccess,
    },
    {
      key: 'actions',
      label: '',
      isSortable: false,
      className: 'right-col col-auto',
      hidden: !props.interviewIntelligenceAccess && !props.referenceCheckAccess,
    },
  ];
}

export function Table(props: PropTypes) {
  return (
    <>
      {props.isLoading && <LoadingSpinner />}
      <BoostrapTable className='mb-0 table-fixed'>
        <TableHeader
          columns={columns(props)}
          sortColumn={
            props.filterStates.sortingColumn?.columnName ??
            defaultSortingColumn()
          }
          sortDirection={
            props.filterStates.sortingColumn.direction ??
            defaultSortingDirection()
          }
          onSortUpdate={(column: string) => {
            props.onFilterStatesUpdate({
              ...props.filterStates,
              sortingColumn: {
                columnName: column,
                direction:
                  props.filterStates.sortingColumn.direction === 'asc'
                    ? 'desc'
                    : 'asc',
              },
            });
          }}
        />

        <TableBody
          columns={columns(props)}
          applications={props.applications}
          interviewIntelligenceAccess={props.interviewIntelligenceAccess}
          referenceCheckAccess={props.referenceCheckAccess}
        />
      </BoostrapTable>
      <TableFooter
        page={props.filterStates.page || defaultPage()}
        pageSize={10}
        totalRows={props.totalRows}
        isLoadingCount={props.isLoadingCount}
        onPageChanged={(newPage: number) =>
          props.onFilterStatesUpdate({
            ...props.filterStates,
            page: newPage,
          })
        }
      />
    </>
  );
}
