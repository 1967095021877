import React, { useEffect, useState, useCallback } from 'react';
import { Route, Routes, useParams, useNavigate, Navigate } from 'react-router';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { SurveyLoadingStatus } from '../../../types/survey_engine/SurveyLoadingStatus';
import { ApiError } from '../../../services/ApiService/errors/ApiError';
import { Survey } from '../../../entities/survey_engine/Survey';
import { InterviewerRoute } from '../../../candidate_experience/containers/SurveyPage/InterviewerRoute';
import { ReferralRoute } from '../../../candidate_experience/containers/SurveyPage/ReferralRoute';
import { FeedbackRoute } from '../../../candidate_experience/containers/SurveyPage/FeedbackRoute';
import { SurveyTarget } from '../../../entities/survey_engine/SurveyTarget';
import { QuestionsRoute } from '../../../containers/survey_engine/SurveyPage/QuestionsRoute';
import { StartRoute } from '../../../containers/survey_engine/SurveyPage/StartRoute';
import { SurveyQuestionAnswer } from '../../../entities/survey_engine/SurveyQuestionAnswer';
import { FinishRoute } from './CandidateExperienceFinishRoute';
import { DisabledRoute } from '../../../containers/survey_engine/SurveyPage/DisabledRoute';

type Params = 'id';

const START_PATH = 'start';
const QUESTIONS_PATH = 'step';
const FEEDBACK_PATH = 'feedback';
const INTERVIEWER_PATH = 'interviewer-follow-up';
const FINAL_PATH = 'thank-you';
const SUBMITTED_DEMOGRAPHIC_ANSWERS_PATH = 'demographic-done';
const REFERRAL_PATH = 'referral';
const SUBMITTED_REFERRAL_PATH = 'referral-done';
const DISABLED_PATH = 'disabled';

function getFollowUpPath(target: SurveyTarget) {
  return target == 'interviewer' ? INTERVIEWER_PATH : FEEDBACK_PATH;
}

interface Proptypes {
  getSurvey: (id: string) => Promise<Survey>;
  submitSurvey: (surveyId: string) => Promise<Survey>;
  submitAnswer: (
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ) => Promise<Survey>;
}

export function SurveyPage(props: Proptypes) {
  const [survey, setSurvey] = useState<Survey>();
  const [status, setStatus] = useState<SurveyLoadingStatus>('LOADING');
  const { id } = useParams<Params>();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setStatus('LOADING');
      try {
        const survey = await props.getSurvey(id);
        setSurvey(survey);
        setStatus('OK');
      } catch (err) {
        if ((err as ApiError).status === 404) setStatus('SURVEY_NOT_FOUND');
        else setStatus('LOAD_ERROR');
      }
    })();
  }, [id]);

  useEffect(() => {
    if (survey === undefined) return;
  }, [survey]);

  const startSurvey = useCallback(() => navigate(`${QUESTIONS_PATH}/1`), []);
  const followUpSurvey = useCallback(
    () => navigate(`${getFollowUpPath(survey.target)}`),
    [survey],
  );
  const finishSurvey = useCallback(async () => {
    setStatus('LOADING');
    const updatedSurvey = await props.submitSurvey(survey.secure_id);
    setSurvey(updatedSurvey);

    navigate(FINAL_PATH);
    setStatus('OK');
  }, [survey]);

  if (survey === undefined) return <LoadingSpinner showBackdrop />;
  return (
    <>
      {status !== 'OK' ? <LoadingSpinner showBackdrop /> : null}
      <Routes>
        <Route
          index
          element={
            survey?.disabled ? (
              <Navigate to={DISABLED_PATH} />
            ) : survey?.status === 'pending' ? (
              <Navigate to={START_PATH} />
            ) : survey?.status === 'completed' ? (
              <Navigate to={FINAL_PATH} />
            ) : null
          }
        />
        <Route
          path={START_PATH}
          element={<StartRoute survey={survey} onStartSurvey={startSurvey} />}
        />
        <Route
          path={`${QUESTIONS_PATH}/:step`}
          element={
            <QuestionsRoute
              survey={survey}
              onChangeStatus={setStatus}
              onUpdateSurvey={setSurvey}
              onFinishSurvey={finishSurvey}
              onFollowUpSurvey={followUpSurvey}
              submitAnswer={props.submitAnswer}
            />
          }
        />
        <Route
          path={FINAL_PATH}
          element={
            <FinishRoute survey={survey} isDemographicsSubmitted={false} />
          }
        />

        <Route
          path={FEEDBACK_PATH}
          element={
            <FeedbackRoute
              survey={survey}
              onChangeStatus={setStatus}
              onUpdateSurvey={setSurvey}
              onFinishSurvey={finishSurvey}
              submitAnswer={props.submitAnswer}
            />
          }
        />
        <Route
          path={`${FINAL_PATH}/${SUBMITTED_DEMOGRAPHIC_ANSWERS_PATH}`}
          element={
            <FinishRoute survey={survey} isDemographicsSubmitted={true} />
          }
        />
        <Route
          path={`${FINAL_PATH}/${REFERRAL_PATH}/${SUBMITTED_REFERRAL_PATH}`}
          element={<FinishRoute survey={survey} isReferralsSubmitted={true} />}
        />
        <Route
          path={`${FINAL_PATH}/${REFERRAL_PATH}`}
          element={<ReferralRoute survey={survey} />}
        />
        <Route
          path={INTERVIEWER_PATH}
          element={
            <InterviewerRoute survey={survey} onFinishSurvey={finishSurvey} />
          }
        />
        <Route
          path={DISABLED_PATH}
          element={<DisabledRoute survey={survey} />}
        />
      </Routes>
    </>
  );
}
