import classNames from 'classnames';
import React from 'react';
import { OptionProps, components } from 'react-select';
import { Col } from 'reactstrap';
import { Avatar } from '../../Avatar';
import { SelectOption } from '../../Select';

export function Option(props: OptionProps) {
  const optionData: SelectOption = props.data as SelectOption;

  return (
    <components.Option {...props}>
      <div
        className={classNames(
          'd-flex flex-nowrap align-items-center',
          optionData.classNames || 'justify-content-between',
        )}
      >
        <Col xs='auto'>
          {optionData.subLabel == null ? (
            <Avatar
              avatarClassName='text-dark-200 bg-blue-gray-500'
              type='icon'
              name={'bi-person'}
            />
          ) : (
            <Avatar name={optionData.subLabel as string} />
          )}
        </Col>
        <Col>
          <div className='text-truncate'>
            {optionData.subLabel || optionData.value}
          </div>
        </Col>
      </div>
    </components.Option>
  );
}
