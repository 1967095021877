import React from 'react';
import { ScorecardLabel } from '../../../../containers/applicant_tracking/InterviewKits/InterviewKitPage/ScorecardBody/ScorecardLabel';
import { ScorecardAttributeValue } from '../../../../containers/applicant_tracking/Scorecards/ScorecardPage/ScorecardBody/RatingSection/ScorecardAttribute/ScorecardAttributeValue';
import { Section } from '../../../../entities/v1/survey_engine/Section';
import { Question } from '../../../../entities/v1/survey_engine/Question';

interface PropTypes {
  section: Section;
  showFieldLabels?: boolean;
  preview?: boolean;
}

export function RatingSection(props: PropTypes) {
  return (
    <div className='w-100'>
      <ScorecardLabel
        classNames='mt-5h mb-3'
        title={props.section.name}
        label='1-5 Scale Rating'
        showFieldLabels={props.showFieldLabels}
      />
      <div className='border border-blue-gray-200 rounded-2 pt-4h pb-3h px-4'>
        {props.section.questions.map((question: Question) => {
          return (
            <div
              key={question.id}
              className='d-flex flex-column gap-2 w-100 mb-2'
            >
              <ScorecardAttributeValue
                key={question.id}
                id={`${question.id}`}
                rating={5}
                editable={false}
                name={question.title}
                fillColorClass='text-blue-gray-500'
                preview={props.preview}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
