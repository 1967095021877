import classNames from 'classnames';
import React from 'react';

export function NumberField(props: {
  label: string;
  value: string;
  name: string;
  className?: string;
  mandatory?: boolean;
  placeholder?: string;
  error?: string;
  children?: React.ReactNode;
  onInputChange: (name: string, value: string) => void;
}) {
  return (
    <div className={props.className}>
      <label className='form-label'>
        {props.label}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </label>
      <input
        type={'text'}
        inputMode={'numeric'}
        className={classNames(
          props.error && 'border-danger',
          'form-control fs-5',
          { 'is-invalid': props.error },
        )}
        value={props.value}
        onChange={(event) =>
          props.onInputChange(props.name, event.target.value)
        }
        placeholder={props.placeholder}
      />
      {props.error && (
        <div className='text-danger-500 mt-2 fs-6'>{props.error}</div>
      )}
      <div className='mt-1 fs-6'>{props.children}</div>
    </div>
  );
}
