import React from 'react';
import { ApprovalFlowApprover } from '../../../../../../../entities/applicant_tracking/ApprovalFlow';
import { Select, SelectOption } from '../../../../../../../components/Select';
import { User } from '../../../../../../../entities/v1/User';
import { IconButton } from '../../../../../../../components/IconButton';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  approver: ApprovalFlowApprover;
  index: number;
  isLastItem: boolean;
  users: User[];
  userOptions: SelectOption[];
  readOnly?: boolean;
  addNewApprover: () => void;
  onChange: (approver: ApprovalFlowApprover) => void;
  onDelete: () => void;
}

function findUserById(id: string, users: User[]) {
  return users.find((user) => user.id.toString() === id);
}

function findSelectedOption(
  approver: ApprovalFlowApprover,
  options: SelectOption[],
) {
  return options.find(
    (option: SelectOption) => option.value === approver.user?.id.toString(),
  );
}

export function ApproverSelect(props: PropTypes) {
  return (
    <>
      <div className='col'>
        <div className='fs-5 fw-semibold mb-2'>Approver</div>
        <div className='d-flex align-items-center'>
          <Select
            disabled={props.readOnly}
            className='w-100'
            options={props.userOptions}
            selected={findSelectedOption(props.approver, props.userOptions)}
            onChange={(option) => {
              props.onChange({
                user: findUserById(option.value, props.users),
              });
            }}
          />
          {!props.readOnly && (
            <IconButton
              color='borderless'
              size='md'
              iconName='bi-trash'
              disabled={props.index === 0}
              onClick={props.onDelete}
            />
          )}
          <div
            className={classNames(
              'fs-6 text-dark-200',
              props.readOnly && 'ms-4',
              props.isLastItem && styles['last-select-item'],
            )}
          >
            {!props.isLastItem && 'OR'}
          </div>
        </div>
      </div>
    </>
  );
}
