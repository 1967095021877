import React from 'react';
import { FormGroup } from 'reactstrap';
import classNames from 'classnames';
import { Select, SelectOption } from '../../../../../../components/Select';
import { Label } from '../Label';

export function EmploymentSectorFormGroup(props: {
  options: SelectOption[];
  employmentSector: string;
  hidden: boolean;
  validated: boolean;
  onEmploymentSectorChanged: (employmentSector: string) => void;
}) {
  return (
    !props.hidden && (
      <FormGroup check className='mt-3'>
        <Label check size='sm' isRequired>
          Employment Sector
        </Label>
        <Select
          name={'Employment Sector'}
          placeholder={'Select Employment Sector'}
          options={props.options}
          selected={props.options.find(
            (o) => o.value === props.employmentSector,
          )}
          onChange={(v) => props.onEmploymentSectorChanged(v.value)}
          className={classNames('form-control p-0', {
            'is-invalid': props.validated && !props.employmentSector?.length,
          })}
        />
      </FormGroup>
    )
  );
}
