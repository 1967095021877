import React from 'react';
import { FeedbackFilters } from '.';
import { MultiSelectAsyncFilter } from '../../../../components/MultiSelectAsyncFilter';
import { Application } from '../../../../entities/Application';
import { mapResultToSelectOption } from '../../../../utils/mapResultToSelectOption';
import { HiringMemberService } from '../../../../services/v1/applicant_tracking/HiringMemberService';

interface PropTypes {
  application: Application;
  isLoading: boolean;
  filters: FeedbackFilters;
  setFilters: (filters: FeedbackFilters) => void;
}

export function HiringTeamFilter(props: PropTypes) {
  const loadHiringMembers = (name: string, page: number) =>
    mapResultToSelectOption(
      HiringMemberService.list({ name, page }),
      (filter) => filter.hiringMembers,
      false,
    );

  return (
    <MultiSelectAsyncFilter
      isMulti
      label='Hiring Team'
      loadOptions={loadHiringMembers}
      onChange={(hiringMembers) =>
        props.setFilters({ ...props.filters, hiringMembers })
      }
      placeholder='All Hiring Team'
      className='mt-4 ps-0 fs-5 fw-semibold'
      disabled={props.isLoading}
      selected={props.filters?.hiringMembers || []}
    />
  );
}
