import { Nav } from 'reactstrap';
import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { CandidatePulseNavLink } from '../../../components/UnderlinedNavLink';

interface PropTypes {
  activeTab: string;
}

export function TopTabNavigation(props: PropTypes) {
  const navigate = useNavigate();

  function handleClick(tab: string) {
    navigate(
      `/analytics/candidate_experience/${tab
        .replace(/\s+/g, '_')
        .toLowerCase()}/${window.location.search}`,
    );
  }
  return (
    <Nav
      className={classNames('flex-wrap mb-4 px-3 app-nav-tabs bg-transparent')}
    >
      <CandidatePulseNavLink
        dataTestid={'overview'}
        tab={'Overview'}
        handleClick={handleClick}
        tabTitle={'Overview'}
        activeTab={props.activeTab}
      />
      <CandidatePulseNavLink
        dataTestid={'follow-up-questions'}
        tab={'Follow-up Questions'}
        handleClick={handleClick}
        tabTitle={'Sentiment Analysis'}
        activeTab={props.activeTab}
      />
      <CandidatePulseNavLink
        dataTestid={'cnps-explainability'}
        tab={'CNPS Explainability'}
        handleClick={handleClick}
        tabTitle={'CNPS Explainability'}
        activeTab={props.activeTab}
      />
      <CandidatePulseNavLink
        dataTestid={'hiring-team'}
        tab={'Team Leaderboard'}
        handleClick={handleClick}
        tabTitle={'Team Leaderboard'}
        activeTab={props.activeTab}
      />
    </Nav>
  );
}
