import React from 'react';
import { InterviewSidekick } from '../../../../entities/InterviewSidekick';
import { ScorecardAttribute } from '../../../../entities/ScorecardAttribute';
import { NotePanel } from '../NotePanel';
import { InterviewMarkersPanel } from './InterviewMarkersPanel';
import { InterviewRatingPanel } from './InterviewRatingPanel';
import { ScorecardCategoriesPanel } from './ScorecardCategoriesPanel';

interface PropTypes {
  interview: InterviewSidekick;
  interviewRatingEnabled: boolean;
  onScorecardAttributesUpdated: (
    attribute: ScorecardAttribute,
    score?: number,
  ) => void;
  onInterviewMarkerCreated: (kind: string, note?: string) => void;
}

export function CandidateAssessmentPage(props: PropTypes) {
  return (
    <>
      <InterviewRatingPanel
        interview={props.interview}
        isEnabled={props.interviewRatingEnabled}
      />
      {props.interview.scorecard_categories?.length > 0 && (
        <ScorecardCategoriesPanel
          interviewId={props.interview.id}
          scorecardCategories={props.interview.scorecard_categories}
          title={`${props.interview.provider || ''} Attributes`}
          userId={props.interview.current_user.id}
          onScorecardAttributesUpdated={props.onScorecardAttributesUpdated}
        />
      )}
      <InterviewMarkersPanel
        interviewMarkers={props.interview.interview_markers}
      />
      <NotePanel
        interviewId={props.interview.id}
        userId={props.interview.current_user.id}
        interviewMarkers={props.interview.interview_markers}
        showAcknowledgeChip={false}
        onInterviewMarkerUpdated={props.onInterviewMarkerCreated}
      />
    </>
  );
}
