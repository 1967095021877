import React from 'react';
import { CloseableModal } from '../../../../../../../components/CloseableModal';
import { ModalBody } from '../../../../../../CandidatePage/CandidateActions/RejectCandidate/ModalBody';

interface PropTypes {
  applicationIds: number[];
  selectionCountLabel: string;
  onClose: (succeeded?: boolean) => void;
}

export function BulkRejectModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={() => props.onClose(null)}
      isOpen={true}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle='Reject Candidate'
      bodyChildren={
        <ModalBody
          applicationIds={props.applicationIds}
          description={
            <>
              Are you sure you want to reject{' '}
              {props.applicationIds.length > 1 ? 'these ' : 'this '}
              {props.selectionCountLabel}?
            </>
          }
          onClose={props.onClose}
        />
      }
    />
  );
}
