import { BenchmarkType } from '../../../entities/candidate_experience/BenchmarkType';
import { FilterSelector } from '../../../entities/candidate_experience/FilterSelector';
import {
  benchmarkTypeToString,
  stringToBenchmarkLabel,
} from '../../../utils/benchmarkTypeToString';
import { getDefaultSelectedFilter } from '../../../utils/getDefaultSelectedFilter';
import { humanize } from '../../../utils/humanize';
import { buildLabelledDemographicOptions } from '../../../utils/buildLabelledDemographicOptions';
import { defaultDateRange } from './filterState';
import moment from 'moment';
import { DateRange, getDateFilterFromDateRange } from '../../DateRangePicker';
import { NpsExplainabilityAttribute } from '../../../entities/candidate_experience/NpsExplainabilityAttribute';

export interface FilterState {
  benchmarkTypes: BenchmarkType[];
  ethnicities?: { [index: string]: string };
  genders?: { [index: string]: string };
  age_ranges?: { [index: string]: string };
  npsAttributes?: NpsExplainabilityAttribute[];
}

export interface FilterStateUpdates {
  filterSelector: string;
  jobs: string[];
  departments: string[];
  hiringManagers: string[];
  recruiters: string[];
  surveyTemplates: string[];
  benchmarkType: string;
  startDate: string;
  endDate: string;
  dateRange: string;
  locations: string[];
  ethnicities: string[];
  genders: string[];
  ageRanges: string[];
  npsAttributes: string[];
}

const emptyFilterState = {
  all: [],
  selected: [],
};

function mapToFilterState(selectedList: string[]) {
  return selectedList.map((listItem) => ({
    value: listItem,
    label: listItem,
  }));
}

export function updateFilterSelector(selectedFilter: string) {
  return {
    all: Object.values(FilterSelector).map((filter) => ({
      value: filter,
      label: humanize(filter),
    })),
    selected: selectedFilter
      ? {
          value: selectedFilter,
          label: humanize(selectedFilter),
        }
      : getDefaultSelectedFilter(),
  };
}

export function updateJobs(selectedJobs: string[]) {
  return {
    selected: selectedJobs ? mapToFilterState(selectedJobs) : [],
  };
}

export function updateDepartments(selectedDepartments: string[]) {
  return {
    selected: selectedDepartments ? mapToFilterState(selectedDepartments) : [],
  };
}

export function updateHiringManagers(selectedHiringManagers: string[]) {
  return {
    selected: selectedHiringManagers
      ? mapToFilterState(selectedHiringManagers)
      : [],
  };
}

export function updateRecruiters(selectedRecruiters: string[]) {
  return {
    selected: selectedRecruiters ? mapToFilterState(selectedRecruiters) : [],
  };
}

export function updateLocations(selectedLocations: string[]) {
  return {
    selected: selectedLocations ? mapToFilterState(selectedLocations) : [],
  };
}

export function updateSurveyTemplates(selectedSurveyTemplates: string[]) {
  return {
    selected: selectedSurveyTemplates
      ? mapToFilterState(selectedSurveyTemplates)
      : [],
  };
}

export function updateBenchmarkTypes(
  benchmarkTypes: BenchmarkType[],
  selectedBenchmarkType: string,
) {
  return {
    all: benchmarkTypes.map((bt) => ({
      value: bt,
      label: benchmarkTypeToString(bt),
    })),
    selected: selectedBenchmarkType
      ? {
          value: selectedBenchmarkType,
          label: stringToBenchmarkLabel(selectedBenchmarkType),
        }
      : {
          value: BenchmarkType.BestPractice,
          label: benchmarkTypeToString(BenchmarkType.BestPractice),
        },
  };
}

export function updateNpsAttributes(
  npsAttributes: NpsExplainabilityAttribute[],
  selectedNpsAttributes: string[],
) {
  if (!npsAttributes) {
    return emptyFilterState;
  }
  return {
    all: npsAttributes.map((npsAttribute) => ({
      value: npsAttribute,
      label: humanize(npsAttribute),
    })),
    selected: selectedNpsAttributes
      ? mapToFilterState(selectedNpsAttributes)
      : [],
  };
}

export function updateDemographics(
  demographics: { [index: string]: string },
  selectedDemographics: string[],
) {
  if (!demographics) {
    return emptyFilterState;
  }

  const all = buildLabelledDemographicOptions(demographics);
  all.pop();
  return {
    all: all,
    selected: selectedDemographics
      ? mapToFilterState(selectedDemographics)
      : [],
  };
}

export function updateDateRange(
  dateRange: string,
  startDate: string,
  endDate: string,
) {
  return startDate && endDate
    ? {
        start: moment(startDate),
        end: moment(endDate),
        range: dateRange as DateRange,
      }
    : dateRange
    ? {
        range: dateRange as DateRange,
        start: getDateFilterFromDateRange(dateRange as DateRange).start,
        end: getDateFilterFromDateRange(dateRange as DateRange).end,
      }
    : defaultDateRange();
}
