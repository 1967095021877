import React from 'react';
import { CenteredLayout } from '../../../../../components/CenteredLayout';
import { StartPageIconButton } from '../../../StartPageIconButton';

export function CreateJobStartPage() {
  return (
    <CenteredLayout heightClass={'h-70'}>
      <div className={'fw-semibold fs-display2'}>
        Select your starting point
      </div>
      <div className={'fs-4 mt-2 mb-3'}>
        Add a job from scratch or based on an existing job.
      </div>
      <StartPageIconButton
        iconName={'bi-file-earmark-plus'}
        buttonText={'Add Blank'}
        onClick={() => {
          window.location.href = '#';
        }}
      />
      <StartPageIconButton
        iconName={'bi-file-text'}
        buttonText={'Add from an existing job'}
        onClick={() => {
          window.location.href = '#';
        }}
      />
    </CenteredLayout>
  );
}
