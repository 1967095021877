import screenloopLogo from '../images/logo-white.png';
import leverLogo from '../images/logos/lever.png';
import greenhouseLogo from '../images/logos/greenhouse.png';
import workableLogo from '../images/logos/workable.png';
import teamtailorLogo from '../images/logos/teamtailor.png';
import ashbyLogo from '../images/logos/ashby.png';
import pinpointLogo from '../images/logos/pinpoint.png';

export function getProviderLogo(provider: string): string {
  switch (provider.toLowerCase()) {
    case 'greenhouse':
      return greenhouseLogo;
    case 'lever':
      return leverLogo;
    case 'workable':
      return workableLogo;
    case 'ashby':
      return ashbyLogo;
    case 'teamtailor':
      return teamtailorLogo;
    case 'pinpoint':
      return pinpointLogo;
    case 'screenloop':
      return screenloopLogo;
    default:
      return '';
  }
}
