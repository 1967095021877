import React from 'react';
import { ClientId } from '../Field';
import { IconButton } from '../../../../components/IconButton';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import classNames from 'classnames';

interface PropTypes {
  sectionId: ClientId;
  removeDisabled: boolean;
  upDisabled: boolean;
  downDisabled: boolean;
  onUp: (sectionId: ClientId) => void;
  onDown: (sectionId: ClientId) => void;
  onRemove: (sectionId: ClientId) => void;
  className?: string;
  readOnly?: boolean;
}

export function SectionHeader(props: PropTypes) {
  if (props.readOnly) {
    return (
      <div className={classNames('d-flex align-items-center', props.className)}>
        <div className='fs-4 me-auto fw-bold'>Section</div>
      </div>
    );
  }

  return (
    <div className={classNames('d-flex align-items-center', props.className)}>
      <div className='fs-4 me-auto fw-bold'>
        Section <span className='text-danger'>*</span>
      </div>
      <div className='d-flex gap-2'>
        <SpanTooltip
          tooltipText='Remove Section'
          text={
            <IconButton
              color='secondary'
              size='sm'
              iconName='bi-trash'
              disabled={props.removeDisabled}
              onClick={() => props.onRemove(props.sectionId)}
            />
          }
        />
        <SpanTooltip
          tooltipText='Move Section Up'
          text={
            <IconButton
              color='secondary'
              size='sm'
              iconName='bi-arrow-up'
              disabled={props.upDisabled}
              onClick={() => props.onUp(props.sectionId)}
            />
          }
        />
        <SpanTooltip
          tooltipText='Move Section Down'
          text={
            <IconButton
              color='secondary'
              size='sm'
              iconName='bi-arrow-down'
              disabled={props.downDisabled}
              onClick={() => props.onDown(props.sectionId)}
            />
          }
        />
      </div>
    </div>
  );
}
