import React, { useCallback, useState } from 'react';
import { Controls } from './Controls';
import { Media } from '../../entities/Media';
import { ShortMediaPlayer } from '../MediaPlayer/ShortMediaPlayer.tsx';

interface PropTypes {
  media: Media;
  startTime: number;
  isPlaying: boolean;
  playTimestamp: number;
  onStartTimeChanged: (number: number) => void;
  onPlaying: (flag: boolean) => void;
  onSelectTime: (time: number) => void;
  onChangePlayTimestamp: (time: number) => void;
}

export function VideoPlayer(props: PropTypes) {
  const [playbackRate, setPlaybackRate] = useState<number>(1);

  const [fullScreenEnabled, setFullScreenEnabled] = useState<boolean>(false);

  const skipBackward = useCallback(
    () => props.onStartTimeChanged(props.playTimestamp - 10000),
    [props.playTimestamp],
  );
  const skipForward = useCallback(
    () => props.onStartTimeChanged(props.playTimestamp + 10000),
    [props.playTimestamp],
  );
  const [volume, setVolume] = useState(100);
  const [muted, setMuted] = useState(false);

  const play = useCallback(() => props.onPlaying(true), []);
  const pause = useCallback(() => props.onPlaying(false), []);

  const setVolumeAndMuted = useCallback((volume: number, muted: boolean) => {
    setVolume(volume);
    setMuted(muted);
  }, []);

  return (
    <>
      <div className='px-4 rounded-4 bg-primary'>
        <ShortMediaPlayer
          isPlaying={props.isPlaying}
          volume={volume}
          muted={muted}
          media={props.media}
          playbackRate={playbackRate}
          fullScreen={fullScreenEnabled}
          playerOptions={{
            fill: true,
            fluid: true,
            controls: fullScreenEnabled,
            preload: 'auto',
            playbackRates: [1, 1.25, 1.5, 1.75, 2, 2.25, 2.5],
          }}
          startTime={props.startTime}
          onPlayTimestampChange={props.onChangePlayTimestamp}
          onPlayingChange={props.onPlaying}
          onVolumeChange={setVolumeAndMuted}
          onFullscreenChange={setFullScreenEnabled}
        />
      </div>
      <div className='mt-2'>
        <Controls
          isPlaying={props.isPlaying}
          muted={muted}
          volume={volume}
          playbackRate={playbackRate}
          onPressPlay={play}
          onPressPause={pause}
          onSkipBackward={skipBackward}
          onSkipForward={skipForward}
          onVolumeChange={setVolumeAndMuted}
          onPlaybackRateChange={setPlaybackRate}
          onFullScreen={setFullScreenEnabled}
        />
      </div>
    </>
  );
}
