import { ApiService } from '../ApiService';
import { InterviewClip } from '../../entities/InterviewClip';

export class InterviewClipService {
  public static list(interviewId: number): Promise<InterviewClip[]> {
    return ApiService.get<InterviewClip[]>(
      `/api/interviews/${interviewId}/interview_clips`,
    );
  }

  public static create(
    interviewId: number,
    data: {
      title: string;
      startOffsetMs: number;
      endOffsetMs: number;
    },
  ): Promise<InterviewClip[]> {
    return ApiService.post(`/api/interviews/${interviewId}/interview_clips`, {
      title: data.title,
      start_offset_ms: data.startOffsetMs,
      end_offset_ms: data.endOffsetMs,
    });
  }

  public static update(
    interviewId: number,
    id: string,
    data: {
      title: string;
      startOffsetMs: number;
      endOffsetMs: number;
    },
  ): Promise<InterviewClip> {
    return ApiService.put<InterviewClip>(
      `/api/interviews/${interviewId}/interview_clips/${id}`,
      {
        title: data.title,
        start_offset_ms: data.startOffsetMs,
        end_offset_ms: data.endOffsetMs,
      },
    );
  }

  public static delete(
    interviewId: number,
    id: string,
  ): Promise<InterviewClip> {
    return ApiService.delete(
      `/api/interviews/${interviewId}/interview_clips/${id}`,
    );
  }

  public static generateLink(
    interviewId: string,
    interviewClipId: string,
  ): Promise<{ url: string }> {
    return ApiService.post<{ url: string }>(
      `/api/interviews/${interviewId}/interview_clips/${interviewClipId}/links`,
    );
  }
}
