import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { ParticipantList } from '../../../../entities/v1/interview_intelligence/Participant';

export class ParticipantService {
  public static list(
    interviewId: number,
    onTranscript: boolean,
    expand?: string[],
  ): Promise<ParticipantList> {
    return ApiService.get<ParticipantList>(
      `/api/v1/interview_intelligence/participants`,
      snakecaseKeys({
        interviewId: interviewId,
        onTranscript: onTranscript,
        expand: expand,
      }),
    );
  }
}
