import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { CardTitle, Table } from 'reactstrap';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { TitledPanel } from '../TitledPanel';
import { TopSources } from '../../../../../entities/applicant_tracking/analytics/TopSources';
import { TopSourcesService } from '../../../../../services/applicant_tracking/analytics/TopSourcesService';
import { FilterState } from '../../Filters';
import stylesheetExports from '../../../../../stylesheets/export.module.scss';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { TruncateText } from '../../../../../components/TruncateText';
import { WidgetEmptyStateContent } from '../../../../survey_engine/WidgetEmptyStateContent';

interface PropTypes {
  filterState?: FilterState;
  exportToPDF?: boolean;
  height: string;
}

interface LazyContentPropTypes {
  filterState: FilterState;
  exportToPDF: boolean;
}

interface ContentPropTypes {
  data: TopSources;
  exportToPDF: boolean;
}

export function TopSourcesWidget(props: PropTypes) {
  const exportToPDF = props.exportToPDF ?? false;

  return (
    <TitledPanel
      title={<CardTitle>Top Sources</CardTitle>}
      height={props.height}
    >
      <LazyContent filterState={props.filterState} exportToPDF={exportToPDF} />
    </TitledPanel>
  );
}

function LazyContent(props: LazyContentPropTypes) {
  const [data, setData] = useState<TopSources>(null);

  useEffect(() => {
    setData(null);
    TopSourcesService.show(props.filterState).then((topSources) =>
      setData(topSources),
    );
  }, [props.filterState]);

  if (!data) return <LoadingSpinner />;

  if (data.topSources.length === 0) return <WidgetEmptyStateContent />;

  return <Content data={data} exportToPDF={props.exportToPDF} />;
}

function Content(props: ContentPropTypes) {
  return (
    <div className='d-flex h-100 gap-4'>
      <Chart data={props.data} />
      <EntryTable data={props.data} exportToPDF={props.exportToPDF} />
    </div>
  );
}

function Chart(props: { data: TopSources }) {
  const labels = props.data.topSources.map((topSource) => topSource.name);
  const values = props.data.topSources.map(
    (topSource) => topSource.qualifiedCount,
  );

  const options = {
    cutout: '70%',
    plugins: {
      legend: { display: false },
      tooltip: {
        bodyFont: {
          size: 10,
        },
        displayColors: false,
        callbacks: {
          label: function (ctx) {
            return ctx.label;
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: [
          stylesheetExports.chartBlue,
          stylesheetExports.chartGreen,
          stylesheetExports.chartOrange,
          stylesheetExports.chartPink,
          '#FFE898',
        ],
        hoverOffset: 0,
      },
    ],
  };

  return (
    <div className='align-self-center' style={{ maxWidth: '120px' }}>
      <Doughnut options={options} data={data} />
    </div>
  );
}

function EntryTable(props: ContentPropTypes) {
  return (
    <Table
      borderless
      size='sm'
      className='fs-6 mb-0 align-self-start table-fixed'
    >
      <thead className='text-dark-200'>
        <tr>
          <th className='fw-normal fs-6'>Source</th>
          <th className='fw-normal fs-6'>Channel</th>
          <th className='fw-normal fs-6 text-center text-nowrap'>
            <span className='me-1'>Qualified</span>
            {props.exportToPDF !== true && (
              <InfoTooltip>
                Number of candidates that were moved from the first job stage
                onwards
              </InfoTooltip>
            )}
          </th>
          <th className='fw-normal fs-6 text-center text-nowrap col-2'>
            <span className='me-1'>PTR</span>
            {props.exportToPDF !== true && (
              <InfoTooltip>
                Pass-through rate is calculated by dividing hired applicants by
                the number of applicants.
              </InfoTooltip>
            )}
          </th>
          <th className='fw-normal fs-6 text-center col-auto'>Hired</th>
        </tr>
      </thead>
      <tbody className='text-primary'>
        {props.data.topSources.map((topSource) => (
          <tr key={topSource.name}>
            <td className='fw-semibold'>
              <TruncateText text={topSource.name} tooltip={true} />
            </td>
            <td>
              <TruncateText text={topSource.channelName} tooltip={true} />
            </td>
            <td className='text-center'>
              <Chip color={ChipColor.Gray}>{topSource.qualifiedCount}</Chip>
            </td>
            <td className='text-center'>
              <Chip color={ChipColor.Gray}>
                {topSource.passThroughRate != null ? (
                  <>{(topSource.passThroughRate * 100).toFixed(0)}%</>
                ) : (
                  <div>-</div>
                )}
              </Chip>
            </td>
            <td className='text-center'>
              <Chip color={ChipColor.Gray}>
                {topSource.hiredCount === 0 ? '-' : topSource.hiredCount}
              </Chip>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
