import { SortingColumn } from '../../utils/sorting';
import { SortingDirection } from '../../utils/types/SortingDirection';
import { getQueryParam, QueryParam } from '../../utils/url';

export const SORTING_COLUMN_NAME_QUERY_PARAM = 'sorting_column';
export const SORTING_DIRECTION_QUERY_PARAM = 'sorting_direction';

export function defaultSortingColumn(): string {
  return 'applied_at';
}

export function defaultSortingDirection(): SortingDirection {
  return 'desc';
}

export function parseSortingColumn(): SortingColumn {
  return {
    columnName: parseSortingColumnName(),
    direction: parseSortingDirection(),
  };
}

function parseSortingColumnName(): string {
  const value = getQueryParam(SORTING_COLUMN_NAME_QUERY_PARAM);

  if (value != null) return value;

  return null;
}

function parseSortingDirection(): SortingDirection {
  const value = getQueryParam(SORTING_DIRECTION_QUERY_PARAM);

  if (value == null) return null;

  const parsedSortingDirection: SortingDirection = value as SortingDirection;

  if (parsedSortingDirection != null) return parsedSortingDirection;

  return null;
}

export function mapSortingColumnToQueryParam(value?: string): QueryParam {
  if (value == null) return null;

  return {
    key: SORTING_COLUMN_NAME_QUERY_PARAM,
    value: value,
  };
}

export function mapSortingDirectionToQueryParam(
  value?: SortingDirection,
): QueryParam {
  if (value == null) return null;

  return {
    key: SORTING_DIRECTION_QUERY_PARAM,
    value: value.toString(),
  };
}
