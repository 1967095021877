import { Product, ProductPriceCurrency } from '@vonq/hapi-elements-types';
import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Chip, ChipColor } from '../../../../../../components/Chip';
import { LoadingSpinnerIcon } from '../../../../../../components/LoadingSpinnerIcon';
import { Icon } from '../../../../../../components/Icon';
import { DetailModal } from './DetailModal';
import { TruncateText } from '../../../../../../components/TruncateText';
import { getPrice } from '../../../utils/productDetails';
import { Button } from 'reactstrap';
import { DurationChip } from './DurationChip';

interface PropTypes {
  currency: ProductPriceCurrency;
  product: Product;
  selectedProducts: Product[];
  onAddProduct: (product: Product) => Promise<void>;
}

// The existing IconButton is not using our Button component. Should be be done in this task https://screenloop.atlassian.net/browse/SL-3967
function JobBoardsIcon(props: {
  iconName: string;
  text: string;
  loading?: boolean;
}) {
  return (
    <div className='d-flex'>
      <div className='me-2'>
        {props.loading ? (
          <LoadingSpinnerIcon />
        ) : (
          <Icon name={props.iconName} />
        )}
      </div>
      {props.text}
    </div>
  );
}

export function JobBoardCard(props: PropTypes) {
  const productSelected =
    props.selectedProducts
      ?.map((p) => p.product_id)
      .find((v) => v === props.product.product_id) != null;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await props.onAddProduct(props.product);
    setLoading(false);
  };

  return (
    <>
      <DetailModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        product={props.product}
      />
      <div
        className={classNames(
          'd-flex flex-column border rounded-1 border-blue-gray-500 p-3',
          styles['product-card'],
        )}
      >
        {props.product.logo_url?.length > 0 && (
          <img
            className={classNames(
              styles['product-image'],
              'mt-3h mb-5h align-self-center w-60',
            )}
            src={props.product.logo_url[0].url}
          />
        )}
        <TruncateText
          tooltip
          ellipsize
          maxLines={2}
          className='fw-bold'
          text={props.product.title}
        />
        <div className='d-flex flex-nowrap gap-2h mt-auto'>
          <DurationChip duration={props.product.duration} />
          <Chip color={ChipColor.Success}>
            {getPrice(props.product.vonq_price, props.currency)}
          </Chip>
        </div>
        <div className='d-flex justify-content-between mt-2'>
          <Button
            size='md'
            color='borderless'
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <JobBoardsIcon iconName='bi-info-circle' text={'More Info'} />
          </Button>
          <Button
            color={productSelected ? 'borderless' : 'secondary'}
            className={classNames(
              productSelected && 'bg-light-success text-primary opacity-100',
            )}
            size='md'
            disabled={productSelected || loading}
            onClick={handleClick}
          >
            <JobBoardsIcon
              loading={loading}
              iconName={productSelected ? 'bi-check2' : 'bi-plus-lg'}
              text={productSelected ? 'Added' : 'Add'}
            />
          </Button>
        </div>
      </div>
    </>
  );
}
