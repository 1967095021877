import React from 'react';
import { RadioPill } from '../../../../../components/RadioPill';
import { RadioPillGroup } from '../../../../../components/RadioPillGroup';
import { RejectedBy } from '../../../../../entities/v1/applicant_tracking/RejectedReason';

interface PropTypes {
  rejectedBy: RejectedBy;
  setRejectedBy: (rejectedBy: RejectedBy) => void;
}

export function RejectedByPills(props: PropTypes) {
  return (
    <div className='d-flex'>
      <RadioPillGroup
        selectedValue={props.rejectedBy}
        childrenClassName='me-2 fs-6 fw-semibold'
        onChange={(e) =>
          props.setRejectedBy(e.currentTarget.value as RejectedBy)
        }
      >
        <RadioPill
          value='organization'
          id='organization'
          label='We rejected them'
          selectedValue={props.rejectedBy}
        />
        <RadioPill
          value='candidate'
          id='candidate'
          label='They rejected us'
          selectedValue={props.rejectedBy}
        />
      </RadioPillGroup>
    </div>
  );
}
