import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../entities/survey_engine/SurveyQuestionAnswer';

export function hasAnsweredQuestion(
  answer: SurveyQuestionAnswer,
  question: SurveyQuestion,
  allowEmptyOptions = false,
): boolean {
  if (answer == null || answer.fields == null) return false;
  if (answer.fields.length === 0) return false;

  return answer.fields
    .map((field) => {
      const questionField = question.fields.find(
        (questionField) => questionField.id == field.field_id,
      );

      if (questionField === null) return;

      if (
        questionField.type === 'rating' &&
        (field.value == null || field.value == '')
      )
        return false;

      if (field.value != null && field.value != '') return true;

      if (field.option_ids == null) return false;

      if (allowEmptyOptions) return true;

      if (field.option_ids.length < question.min_options) return false;

      return !(
        question.max_options != null &&
        field.option_ids.length > question.max_options
      );
    })
    .every((v) => v === true);
}
