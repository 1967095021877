import React, { useState } from 'react';
import { JobStageTemplateInput } from '../../../../../../containers/PlanTemplates/SetupPlanTemplatePage';
import { JobCardContainer } from './JobCardContainer';
import { JobStageJob } from '../../../../../../entities/applicant_tracking/JobStageJob';
import { AlertMessage } from '../../../../../AlertMessage';
import { getLabelForCount } from '../../../../../../utils/grammar';
import { Button } from 'reactstrap';
import { Select } from '../../../../../Select';

interface PropTypes {
  stage: JobStageTemplateInput;
  jobStages: JobStageTemplateInput[];
  jobStageJobs: JobStageJob[];
  onClose: (state?: boolean, moveDestinationStageName?: string) => void;
}

function buildOptionsFromTemplate(
  jobStages: JobStageTemplateInput[],
  excludeStage: JobStageTemplateInput,
) {
  return jobStages
    .filter((stage) => stage.id !== excludeStage.id)
    .map((stage) => ({ label: stage.name, value: stage.name }));
}

export function DeleteStageWithCandidatesModalBody(props: PropTypes) {
  const [selectedStageToMove, setSelectedStageToMove] = useState(null);
  const totalCandidates = props.jobStageJobs.reduce(
    (acc, job) => acc + job.applicationsCount,
    0,
  );

  return (
    <>
      <AlertMessage
        hasOverlay={false}
        icon={{ name: 'bi-info-circle' }}
        className='mb-4 mt-0 fs-5 bg-light-danger'
        text={
          <span>
            To remove any stage you will first need to move all Candidates to a
            different stage. Select the new stage below.
          </span>
        }
      />
      <div className='fs-5 mb-2'>
        <span className='fw-bold'>
          {getLabelForCount('candidate', totalCandidates)}
        </span>{' '}
        on {props.stage.name}, move them to a new stage.
      </div>
      <Select
        options={buildOptionsFromTemplate(props.jobStages, props.stage)}
        selected={selectedStageToMove}
        onChange={(option) => setSelectedStageToMove(option)}
        placeholder='Select New Stage'
        isClearable={false}
      />
      <JobCardContainer {...props} />
      <div className='d-flex justify-content-end mt-4'>
        <Button
          type='button'
          color='secondary'
          className='me-3'
          onClick={() => props.onClose(false)}
        >
          Cancel
        </Button>
        <Button
          type='button'
          color='danger'
          disabled={!selectedStageToMove}
          onClick={() => props.onClose(true, selectedStageToMove?.value)}
        >
          Remove Stage
        </Button>
      </div>
    </>
  );
}
