import React from 'react';
import { HiringMemberAnalytics } from '../../../../../../../../entities/candidate_experience/HiringMemberAnalytics';
import { JobsCount } from './JobsCount';
import { Cnps } from './Cnps';
import { Name } from './Name';
import { Role } from './Role';
import { AvgScore } from './AvgScore';
import { SentimentAnalysis } from './SentimentAnalysis';

interface PropTypes {
  hiring_members: HiringMemberAnalytics[];
  standardNpsCalculation: boolean;
}

export function TableBody(props: PropTypes) {
  return (
    <tbody>
      {props.hiring_members.map((hiring_member, idx) => (
        <tr key={idx}>
          <Name hiring_member={hiring_member} />
          <Role hiring_member={hiring_member} />
          <Cnps
            hiring_member={hiring_member}
            standardNpsCalculation={props.standardNpsCalculation}
          />
          <JobsCount hiring_member={hiring_member} />
          <AvgScore hiring_member={hiring_member} />
          <SentimentAnalysis hiring_member={hiring_member} />
        </tr>
      ))}
    </tbody>
  );
}
