import React, { useEffect, useState, useCallback } from 'react';
import { Route, Routes, useParams, useNavigate, Navigate } from 'react-router';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { SurveyLoadingStatus } from '../../../types/survey_engine/SurveyLoadingStatus';
import { ApiError } from '../../../services/ApiService/errors/ApiError';
import { StartRoute } from './StartRoute';
import { FinishRoute } from './FinishRoute';
import { QuestionsRoute } from './QuestionsRoute';
import { Survey } from '../../../entities/survey_engine/Survey';
import { SurveyQuestionAnswer } from '../../../entities/survey_engine/SurveyQuestionAnswer';
import { StartPanelFields } from '../../../entities/survey_engine/StartPanelFields';
import { DisabledRoute } from './DisabledRoute';

type Params = 'id';

const START_PATH = 'start';
const QUESTIONS_PATH = 'step';
const FINAL_PATH = 'thank-you';
const DISABLED_PATH = 'disabled';

interface Proptypes {
  getSurvey: (id: string) => Promise<Survey>;
  submitSurvey: (surveyId: string) => Promise<Survey>;
  submitAnswer: (
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ) => Promise<Survey>;
  getStartScreenFields?: (SurveyTypes) => StartPanelFields;
  surveyFinalScreen?: React.ReactNode;
}

export function SurveyPage(props: Proptypes) {
  const [survey, setSurvey] = useState<Survey>();
  const [status, setStatus] = useState<SurveyLoadingStatus>('LOADING');
  const { id } = useParams<Params>();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setStatus('LOADING');
      try {
        const survey = await props.getSurvey(id);
        setSurvey(survey);
        setStatus('OK');
      } catch (err) {
        if ((err as ApiError).status === 404) setStatus('SURVEY_NOT_FOUND');
        else setStatus('LOAD_ERROR');
      }
    })();
  }, [id]);

  useEffect(() => {
    if (survey === undefined) return;
  }, [survey]);

  const startSurvey = useCallback(() => navigate(`${QUESTIONS_PATH}/1`), []);

  const finishSurvey = useCallback(async () => {
    setStatus('LOADING');
    const updatedSurvey = await props.submitSurvey(survey.secure_id);
    setSurvey(updatedSurvey);

    navigate(FINAL_PATH);
    setStatus('OK');
  }, [survey]);

  if (survey === undefined) return <LoadingSpinner showBackdrop />;
  return (
    <>
      {status !== 'OK' ? <LoadingSpinner showBackdrop /> : null}
      <Routes>
        <Route
          index
          element={
            survey?.disabled ? (
              <Navigate to={DISABLED_PATH} />
            ) : survey?.status === 'pending' ? (
              <Navigate to={START_PATH} />
            ) : survey?.status === 'completed' ? (
              <Navigate to={FINAL_PATH} />
            ) : null
          }
        />
        <Route
          path={START_PATH}
          element={
            <StartRoute
              survey={survey}
              onStartSurvey={startSurvey}
              getStartScreenFields={props.getStartScreenFields}
            />
          }
        />
        <Route
          path={`${QUESTIONS_PATH}/:step`}
          element={
            <QuestionsRoute
              survey={survey}
              onChangeStatus={setStatus}
              onUpdateSurvey={setSurvey}
              onFinishSurvey={finishSurvey}
              submitAnswer={props.submitAnswer}
            />
          }
        />
        <Route
          path={FINAL_PATH}
          element={
            <FinishRoute
              survey={survey}
              surveyFinalScreen={props.surveyFinalScreen}
            />
          }
        />
        <Route
          path={DISABLED_PATH}
          element={<DisabledRoute survey={survey} />}
        />
      </Routes>
    </>
  );
}
