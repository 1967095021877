import { useEffect, useState } from 'react';
import { OfferLetterTemplateService } from '../../../../../../services/v1/applicant_tracking/OfferLetterTemplateService';
import React from 'react';
import { OfferLetterTemplate } from '../../../../../../entities/v1/applicant_tracking/OfferLetterTemplate';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { AlertMessage } from '../../../../../../components/AlertMessage';
import { Col, Row } from 'reactstrap';
import { SalaryInput } from './SalaryInput';
import { StartingDateInput } from './StartingDateInput';
import { OfferForm } from '../..';
import { WorkingHoursInput } from './WorkingHoursInput';
import { EquityInput } from './EquityInput';
import { EmptyState } from '../../../../../../components/EmptyState';
import styles from './styles.module.scss';

interface PropTypes {
  form: OfferForm;
  offerLetterTemplate: OfferLetterTemplate;
  setForm: (form: OfferForm) => void;
  setIsValid: (valid: boolean) => void;
}

const ACCEPTED_VARIABLES = [
  'salary',
  'working_hours',
  'starting_date',
  'equity',
];

function getValidVariables(variables: string[]): string[] {
  return variables?.filter((v) => ACCEPTED_VARIABLES.includes(v));
}

function isFormValid(form: OfferForm, variables?: string[]) {
  if (variables == null) {
    return false;
  }

  for (const variable of variables) {
    switch (variable) {
      case 'salary':
        if (!form.salary || !form.salaryCurrency) {
          return false;
        }
        break;
      case 'working_hours':
        if (!form.workingHours) {
          return false;
        }
        break;
      case 'starting_date':
        if (!form.startingDate) {
          return false;
        }
        break;
      case 'equity':
        if (!form.equity) {
          return false;
        }
        break;
    }
  }

  return true;
}

function VariableSelector(props: { name: string } & PropTypes) {
  switch (props.name) {
    case 'salary':
      return (
        <SalaryInput
          currency={props.form.salaryCurrency}
          salary={props.form.salary}
          setCurrency={(currency: string) =>
            props.setForm({ ...props.form, salaryCurrency: currency })
          }
          setSalary={(salary: number) =>
            props.setForm({ ...props.form, salary: salary })
          }
        />
      );
    case 'working_hours':
      return (
        <WorkingHoursInput
          setWorkingHours={(workingHours: number) =>
            props.setForm({ ...props.form, workingHours: workingHours })
          }
          workingHours={props.form.workingHours}
        />
      );
    case 'starting_date':
      return (
        <StartingDateInput
          setStartingDate={(startingDate: string) =>
            props.setForm({ ...props.form, startingDate: startingDate })
          }
          startingDate={props.form.startingDate}
        />
      );
    case 'equity':
      return (
        <EquityInput
          setEquity={(equity: number) =>
            props.setForm({ ...props.form, equity: equity })
          }
          equity={props.form.equity}
        />
      );
  }
}

export function OfferDetails(props: PropTypes) {
  const [variables, setVariables] = useState<string[]>(null);

  useEffect(() => {
    (async () => {
      setVariables(
        (
          await OfferLetterTemplateService.variables(
            props.offerLetterTemplate.id,
          )
        ).offerLetterTemplateVariables,
      );
    })();
  }, []);

  useEffect(() => {
    props.setIsValid(isFormValid(props.form, getValidVariables(variables)));
  }, [props.form, variables]);

  if (variables == null) {
    return <LoadingSpinner />;
  }

  return variables.length > 0 ? (
    <>
      <AlertMessage
        icon={{ name: 'bi-info-circle' }}
        className='bg-light-info'
        textClassName='fs-5'
        text='The Offer Letter has been configured with the following fields. All are required to extend the offer.'
        hasOverlay={false}
      />
      <Row className='mt-2h px-5 g-4'>
        {getValidVariables(variables).map((v, index) => (
          <Col key={index} xs='6'>
            <VariableSelector {...props} name={v} />
          </Col>
        ))}
      </Row>
    </>
  ) : (
    <EmptyState
      classNames={styles['empty-state']}
      cardless
      title={'There are no variables to setup'}
    />
  );
}
