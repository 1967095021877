import classNames from 'classnames';
import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import { TableEntry } from './index';
import { FollowUpResponsesListTableRows } from './FollowUpResponsesListTableRows';

export interface PropTypes {
  followUpAnswers: TableEntry[];
  question: string | null;
  tableClassName?: string;
  maxRowsCollapsed: number;
  standardNpsCalculation: boolean;
}

export function FollowUpResponsesListTable(props: PropTypes) {
  const rightTableResponses = props.followUpAnswers.filter(
    (_e, i) => i % 2 == 0,
  );
  const leftTableResponses = props.followUpAnswers.filter(
    (_e, i) => i % 2 == 1,
  );

  return (
    <>
      <div className='fw-semibold mx-0 mb-4'>
        {props.question && (
          <Row className={classNames('gy-4')}>
            <Col>
              <span
                className={classNames(
                  'px-3',
                  'py-2',
                  'rounded-2',
                  'shadow-sm',
                  'bg-light-info',
                  'fs-5',
                  'justify-content-left',
                )}
              >
                <b>Follow-up question:&nbsp;</b>
                {props.question}
              </span>
            </Col>
          </Row>
        )}
      </div>
      <Table
        className={classNames(
          'align-middle fs-5 text-primary table-borderless',
          props.tableClassName,
        )}
      >
        <tbody>
          <tr className='label'>
            <td className='col-5 ps-0 align-top'>
              <FollowUpResponsesListTableRows
                answers={rightTableResponses}
                maxRowsCollapsed={props.maxRowsCollapsed}
                standardNpsCalculation={props.standardNpsCalculation}
              />
            </td>
            <td className='col-5 ps-5 align-top'>
              <FollowUpResponsesListTableRows
                answers={leftTableResponses}
                maxRowsCollapsed={props.maxRowsCollapsed}
                standardNpsCalculation={props.standardNpsCalculation}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
