/** @file Typed wrappers for localStorage. */

export enum KnownKeys {
  BulkSelfScheduling = 'bulk_self_scheduling',
  OfferLetterTemplate = 'offer_letter_template',
}

export function get<T>(key: KnownKeys, customKeySuffix: string = null): T {
  return JSON.parse(localStorage.getItem(buildKey(key, customKeySuffix)));
}

export function set<T>(
  key: KnownKeys,
  value: T,
  customKeySuffix: string = null,
): T {
  localStorage.setItem(buildKey(key, customKeySuffix), JSON.stringify(value));
  return value;
}

export function pop<T>(key: KnownKeys, customKeySuffix: string = null): T {
  const value: T = get(key, customKeySuffix);
  localStorage.removeItem(buildKey(key, customKeySuffix));
  return value;
}

function buildKey(key: KnownKeys, customKeySuffix: string = null): string {
  return [key.toString(), customKeySuffix].filter(Boolean).join('_');
}
