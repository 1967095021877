import { UseFormSetError } from 'react-hook-form';
import { ApiError } from '../errors/ApiError';
import { ErrorCodes } from '../errors/ErrorCodes';

function handleClientErrors(error: ApiError, setError: UseFormSetError<any>) {
  if (error.body?.code != null) {
    if (error.body?.code === ErrorCodes.ATS_CANDIDATE_EMAIL_DUPLICATED) {
      setError('email', { type: 'unique', message: error.body.message });
    } else if (
      error.body?.code === ErrorCodes.ATS_CANDIDATE_PHONE_NUMBER_DUPLICATED
    ) {
      setError('phoneNumber', { type: 'unique' });
    } else if (error.body?.code === ErrorCodes.ATS_JOB_DUPLICATED) {
      setError('name', { type: 'unique' });
    } else if (
      error?.body.code === ErrorCodes.ATS_PLAN_TEMPLATE_NAME_DUPLICATED
    ) {
      setError('name', { type: 'unique', message: error.body.message });
    } else if (
      error.body?.code === ErrorCodes.ATS_CANDIDATE_AND_REFERRER_EMAIL_MATCH
    ) {
      setError('referrerEmail', {
        type: 'unique',
        message: error.body.message,
      });
    } else if (error.body?.code === ErrorCodes.ATS_LOCATION_DUPLICATED) {
      setError('name', { type: 'unique', message: error.body.message });
    } else if (
      error.body?.code === ErrorCodes.ATS_OFFER_LETTER_TEMPLATE_NAME_DUPLICATED
    ) {
      setError('name', { type: 'unique', message: error.body.message });
    } else {
      // This happens if the backend throws an unknown code for the frontend, or a condition is missing.
      setError('root', { type: 'clientError' });
    }
  } else {
    setError('root', { type: 'clientError' });
  }
}

export function handleFormErrors(e: unknown, setError: UseFormSetError<any>) {
  const error = e as ApiError;

  if (error.status < 400) {
    // Shouldn't happen, since it's not an error, just log
    console.log('Api thrown error, ', JSON.stringify(error));
  } else if (error.status >= 400 && error.status < 500) {
    handleClientErrors(error, setError);
  } else {
    setError('root', { type: 'serverError' });
  }
}
