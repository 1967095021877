import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';
import { ProgressBar } from '../../../components/ProgressBar';
import { SurveyLayout } from '../../../components/SurveyLayout';
import { Survey } from '../../../entities/Survey';
import { SurveyQuestion } from '../../../entities/SurveyQuestion';
import { SurveyQuestionField } from '../../../entities/SurveyQuestionField';
import { SurveyQuestionAnswer } from '../../../entities/SurveyQuestionAnswer';
import { SurveyLoadingStatus } from '../SurveyLoadingStatus';
import { SurveyQuestionService } from '../../../services/SurveyQuestionService';
import { SurveyField } from './SurveyField';
import styles from '../styles.module.scss';
import { isSelfAssessmentSurvey } from '../../../utils/isSelfAssessmentSurvey';
import { ProgressRow } from '../ProgressRow';
import { historyConfirmationEnabled } from '../HistoryConfirmationScreen';
import classNames from 'classnames';

interface PropTypes {
  survey: Survey;
  questions: SurveyQuestion[];
  outOfFlowSteps: number;
  onUpdateSurvey: (survey: Survey) => void;
  onChangeStatus: (status: SurveyLoadingStatus) => void;
  onFinishSurvey: () => void;
}

const hasAnswered = (
  answer: SurveyQuestionAnswer,
  question: SurveyQuestion,
): boolean => {
  if (answer == null || answer.fields == null) return false;
  if (answer.fields.length === 0) return false;

  return answer.fields
    .map((field) => {
      if (field.value != null && field.value != '') return true;

      if (field.option_ids == null) return false;

      if (field.option_ids.length < question.min_options) return false;

      return !(
        question.max_options != null &&
        field.option_ids.length > question.max_options
      );
    })
    .every((v) => v === true);
};

const hasLegend = (fields: SurveyQuestionField[]): boolean => {
  return fields
    .map((field) =>
      field.options
        .map((opt) => opt.description != null && opt.description != '')
        .every((v) => v === true),
    )
    .some((v) => v === true);
};

const getFieldForLegend = (
  fields: SurveyQuestionField[],
): SurveyQuestionField => {
  return fields.find((field) =>
    field.options
      .map((opt) => opt.description != null)
      .every((v) => v === true),
  );
};

export function SurveyScreen(props: PropTypes) {
  const [answer, setAnswer] = useState<SurveyQuestionAnswer>();
  const { step: stepParam } = useParams<'step'>();
  const navigate = useNavigate();
  const step = parseInt(stepParam, 10);
  const surveyQuestion = props.questions[step - 1];

  const navigateToStep = (step: number | string) => {
    navigate(`../step/${step}`);
  };

  const submitAnswer = useCallback(
    async (answer: SurveyQuestionAnswer) => {
      props.onChangeStatus('LOADING');
      const updatedSurvey = await SurveyQuestionService.answer(
        props.survey.secure_id,
        answer,
      );
      props.onUpdateSurvey(updatedSurvey);
      props.onChangeStatus('OK');

      if (step === props.questions.length) props.onFinishSurvey();
      else navigateToStep(step + 1);
    },
    [step, props.survey],
  );

  useEffect(() => {
    if (surveyQuestion === undefined) return;

    const answer = props.survey.answers.find(
      (ans) => ans.question_id === surveyQuestion.id,
    );
    setAnswer(answer);
  }, [step]);

  useEffect(() => {
    if (isNaN(step) || step === 0 || step > props.questions.length)
      navigate('..');
  }, [step]);

  const goToPreviousStep = useCallback(() => {
    const prevStep = step - 1;
    if (prevStep === 0 && historyConfirmationEnabled(props.survey)) {
      navigateToStep('history_confirmation');
    } else {
      navigateToStep(step - 1);
    }
  }, [step]);

  const changeAnswer = useCallback(
    (fieldId: number, value: string, optionIds?: number[]) => {
      const fields = answer.fields.filter((f) => f.field_id !== fieldId);
      setAnswer({
        question_id: surveyQuestion.id,
        survey_id: props.survey.id,
        fields: fields.concat({
          field_id: fieldId,
          value,
          option_ids: optionIds,
        }),
      });
    },
    [answer, props.survey, surveyQuestion],
  );

  if (surveyQuestion === undefined) return null;

  const progressBarStep = step + props.outOfFlowSteps;
  const progressBarQuestionCount =
    props.questions.length + props.outOfFlowSteps;

  return (
    <SurveyLayout
      companyInfo={props.survey.company_info}
      className={styles.surveyPage}
    >
      <Row className='mt-2 mb-5'>
        <Col>
          <ProgressBar
            progress={((progressBarStep - 1) / progressBarQuestionCount) * 100}
            color={props.survey.company_info.primary_color}
          />
        </Col>
      </Row>
      <div className={classNames(styles['survey-section'])}>
        <div className={classNames('p-5')}>
          <Row>
            <Col>
              <h4>{surveyQuestion.module.name}</h4>
              <small className='text-muted'>
                {surveyQuestion.module.description}
              </small>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col
              xs='12'
              className={classnames({
                'mb-2': hasLegend(surveyQuestion.fields),
              })}
            >
              <h2
                style={{
                  letterSpacing: `${
                    props.survey.company_info.font_kerning / 2
                  }em`,
                }}
              >
                {surveyQuestion.title}
              </h2>
            </Col>
            {surveyQuestion.fields.find((field) => field.type === 'rating') && (
              <Col xs='12'>
                <b>1. OKAY</b>, but things could have been better.
                <br />
                <b>2. GOOD</b>, met almost all of the needs of the job.
                <br />
                <b>3. REALLY GOOD</b>, performance was better than average.
                <br />
                <b>4. GREAT</b>, one of the best 3-5 people on the team.
                <br />
                <b>5. BEST</b>, the best person on team.
                <br />
              </Col>
            )}

            {hasLegend(surveyQuestion.fields) && (
              <Col xs='12'>
                {getFieldForLegend(surveyQuestion.fields)
                  .options.sort((a, b) => a.weight - b.weight)
                  .map((opt, idx) => (
                    <React.Fragment key={idx}>
                      <b>
                        {opt.weight}. {opt.name}
                      </b>
                      , {opt.description}.<br />
                    </React.Fragment>
                  ))}
              </Col>
            )}
          </Row>
          {surveyQuestion.fields.map((f) => (
            <SurveyField
              key={f.id}
              field={f}
              answer={answer}
              onChangeAnswer={changeAnswer}
            />
          ))}
        </div>
      </div>
      <ProgressRow
        survey={props.survey}
        nextTitle={
          step === props.questions.length
            ? isSelfAssessmentSurvey(props.survey)
              ? 'Submit Self-Assessment'
              : 'Submit Reference'
            : 'Next'
        }
        isNextDisabled={hasAnswered(answer, surveyQuestion) === false}
        onPrevious={goToPreviousStep}
        onNext={() => submitAnswer(answer)}
      />
    </SurveyLayout>
  );
}
