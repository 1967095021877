import { ApiService } from '../ApiService';
import { Interview } from '../../entities/Interview';
import { buildUrl } from '../../utils/url';
import { SummaryLengthOption } from '../../containers/InterviewPage/InterviewAINotesTab/CustomizeSummary';

export interface InterviewAssistantAiGenerateRequest {
  id: number;
  prompt: string;
  onMessage: (message: MessageEvent) => void;
  onEnd: () => void;
}

export interface CustomSummaryAiGenerateRequest {
  id: number;
  topics: string[];
  length: SummaryLengthOption;
  onMessage: (message: MessageEvent) => void;
  onEnd: () => void;
}
export class InterviewService {
  public static get(id: number | string): Promise<Interview> {
    return ApiService.get<Interview>(`/interviews/${id}`);
  }

  public static getByUrl(url: string): Promise<Interview> {
    return ApiService.get<Interview>(url);
  }

  public static swapParticipants(
    interviewId: number,
    participantAId: number,
    participantBId: number,
  ): Promise<void> {
    const params = {
      participant_a_id: participantAId,
      participant_b_id: participantBId,
    };
    return ApiService.put<void>(`/interviews/${interviewId}/swap`, params);
  }

  public static createInterviewMarker(
    interviewId: number,
    offsetMs: number,
    kind: string,
    text: string,
  ): Promise<Interview> {
    const params = {
      interview_marker: {
        offset_ms: offsetMs,
        kind: kind,
        note: {
          text: text,
        },
      },
    };
    return ApiService.post<Interview>(
      `/interviews/${interviewId}/interview_markers`,
      params,
    );
  }

  public static aiGenerateAssistantAnswer(
    request: InterviewAssistantAiGenerateRequest,
  ): void {
    const streamUrl = buildUrl(
      window.location.origin +
        `/api/stream/interview_intelligence/interviews/${request.id}/generate_assistant_answer`,
      [{ key: 'prompt', value: request.prompt || '' }],
    );

    const eventSource = new EventSource(streamUrl);

    eventSource.onmessage = request.onMessage;

    eventSource.onerror = (event) => {
      if (event.eventPhase === EventSource.CLOSED) {
        eventSource.close();
        request.onEnd();
      }
    };
  }

  public static aiGenerateCustomSummary(
    request: CustomSummaryAiGenerateRequest,
  ): void {
    const streamUrl = buildUrl(
      window.location.origin +
        `/api/stream/interview_intelligence/interviews/${request.id}/generate_custom_summary`,
      [
        { key: 'topics[]', value: request.topics ?? [] },
        { key: 'length', value: request.length ?? '' },
      ],
    );

    const eventSource = new EventSource(streamUrl);

    eventSource.onmessage = request.onMessage;

    eventSource.onerror = (event) => {
      if (event.eventPhase === EventSource.CLOSED) {
        eventSource.close();
        request.onEnd();
      }
    };
  }

  public static customSummary(
    interviewId: number,
    customSummary: string,
    topics: string[],
  ) {
    return ApiService.post<Interview>(
      `/interviews/${interviewId}/custom_summary`,
      {
        custom_summary: customSummary,
        topics: topics,
      },
    );
  }
}
