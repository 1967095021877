import classNames from 'classnames';
import React from 'react';
import { Table } from 'reactstrap';
import styles from './styles.module.scss';
import { TableHeader } from './TableHeader';

interface PropTypes {
  columns: { key: string; label: string; tooltipText?: string; type: string }[];
  tableBody: JSX.Element;
}

export function SharedTablePanel(props: PropTypes) {
  return (
    <div>
      <Table
        borderless
        className={classNames(
          'm-0',
          'table-fixed',
          styles['overview-table'],
          styles['all-users-table'],
        )}
      >
        <TableHeader columns={props.columns} />
        {props.tableBody}
      </Table>
    </div>
  );
}
