import React from 'react';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { PhoneInputFormField } from '../../../../components/PhoneInputFormField';
import classNames from 'classnames';

interface PropTypes {
  control: Control<CandidateDto, any>;
  formRegister: UseFormRegister<CandidateDto>;
  errors: FieldErrors;
}

export function CandidatePhoneInput(props: PropTypes) {
  const isRequired = false;

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <PhoneInputFormField<CandidateDto>
          control={props.control}
          disabled={false}
          required={isRequired}
          fieldName={'Phone Number'}
          controllerName={'phoneNumber'}
          placeholder={'Insert phone'}
          registerReturn={props.formRegister('phoneNumber', {
            required: isRequired,
          })}
          className={classNames(props.errors.phoneNumber && 'is-invalid')}
        />
      </Col>
    </Row>
  );
}
