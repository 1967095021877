import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppPlugin } from '..';
import { QualityOfHireApp } from '../../quality_of_hire/containers/QualityOfHireApp';

export class QualityOfHireAppPlugin implements AppPlugin {
  public load(): void {
    const el = document.getElementById('qualityOfHireApp');

    if (el) this.installContainer(el);
  }

  private installContainer(el: HTMLElement): void {
    const props = JSON.parse(el.dataset['props'] || '{}');

    createRoot(el).render(React.createElement(QualityOfHireApp, props));
  }
}
