import React from 'react';
import { IconSpan } from '../../../../../../../../components/IconSpan';
import { Button } from 'reactstrap';

export type GenerateState = 'Generate' | 'Generating' | 'Regenerate';

interface PropTypes {
  state: GenerateState;
  onClick: () => void;
}

function GenerateState(props: PropTypes) {
  return (
    <Button type='button' color='borderless' onClick={props.onClick} size='sm'>
      <IconSpan
        spanText='Fill with AI'
        className='text-nowrap ms-2'
        icon={{
          name: 'bi-stars',
        }}
      />
    </Button>
  );
}

function GeneratingState() {
  return (
    <IconSpan
      className='text-dark-500 fs-6 text-nowrap ms-2'
      spanText='AI is writing...'
      icon={{
        name: 'bi-stars',
      }}
    />
  );
}

function RegenerateState(props: PropTypes) {
  return (
    <div className='d-flex align-items-center ms-2'>
      <div className='fs-6 text-dark-200 me-2 pe-1 text-nowrap'>
        Want to generate another response?
      </div>
      <Button
        type='button'
        color='borderless'
        onClick={props.onClick}
        size='sm'
      >
        <IconSpan
          spanText='Regenerate with AI'
          className='text-nowrap'
          icon={{
            name: 'bi-arrow-repeat',
          }}
        />
      </Button>
    </div>
  );
}

export function FillWithAIButton(props: PropTypes) {
  switch (props.state) {
    case 'Generate':
      return <GenerateState {...props} />;
    case 'Generating':
      return <GeneratingState />;
    case 'Regenerate':
      return <RegenerateState {...props} />;
  }
}
