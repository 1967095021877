import React from 'react';
import { ListCandidate } from '../../../../../../entities/applicant_tracking/ListCandidate';
import { getLabelForCount } from '../../../../../../utils/grammar';
import { MoveToStageButton } from './MoveToStageButton';
import { RejectCandidateButton } from './RejectCandidateButton';
import { SendEmailButton } from './SendEmailButton';
import { AccountIntegration } from '../../../../../../entities/AccountIntegration';
import { User } from '../../../../../../entities/User';
import { AlertObject } from '../../../../../../components/Alert';
import { PushToHrisButton } from './PushToHrisButton';
import { HrisEmployee } from '../../../../../../entities/applicant_tracking/HrisEmployees';
import { HrisProvider } from '../../../../../../enums/IntegrationProvider/HrisProvider';
import { BulkReviewButton } from './BulkReviewButton';
import { SelfSchedulingButton } from './SelfSchedulingButton';

interface PropTypes {
  candidates: ListCandidate[];
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  selectedCandidates: ListCandidate[];
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
  hrisEmployees: HrisEmployee[];
  hrisProvider?: HrisProvider;
  bulkSelfSchedulingEnabled: boolean;
}

export const BULK_ACTION_ERROR_MESSAGE =
  'An error occurred while processing the request. Please try again later or contact support for assistance.';

export function BulkActionButtons(props: PropTypes) {
  const selectionCountLabel = getLabelForCount(
    'Candidate',
    props.selectedCandidates.length,
  );

  return (
    <>
      <BulkReviewButton {...props} />
      <SelfSchedulingButton
        selectedCandidates={props.selectedCandidates}
        bulkSelfSchedulingEnabled={props.bulkSelfSchedulingEnabled}
      />
      <PushToHrisButton {...props} />
      <SendEmailButton {...props} selectionCountLabel={selectionCountLabel} />
      <MoveToStageButton {...props} selectionCountLabel={selectionCountLabel} />
      <RejectCandidateButton
        {...props}
        selectionCountLabel={selectionCountLabel}
      />
    </>
  );
}
