import React from 'react';
import { Button } from 'reactstrap';
import { NoteService } from '../../../../../services/applicant_tracking/NoteService';

interface ModalProps {
  noteId: number;
  onClose: (state: boolean) => void;
}

export function ModalBody(props: ModalProps) {
  const deleteNote = async () => {
    try {
      await NoteService.delete(props.noteId);
      props.onClose(true);
    } catch (error) {
      props.onClose(false);
      console.error(error);
    }
  };

  return (
    <>
      <div className='fs-4 fw-semibold'>
        Are you sure you want to delete this note?
      </div>
      <div className='d-flex mt-5 justify-content-end'>
        <Button
          className='me-2'
          color='danger'
          onClick={() => props.onClose(null)}
        >
          Cancel
        </Button>
        <Button color='primary' onClick={deleteNote}>
          Delete
        </Button>
      </div>
    </>
  );
}
