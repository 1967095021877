import { ListCandidatesFilterTab } from '..';
import { DateFilter } from '../../../../../components/DateRangePicker';
import { SelectOption } from '../../../../../components/Select';
import { mapDateRangeToQueryParams, parseDateRange } from './DateRangeFilter';
import { mapJobToQueryParam, parseJob } from './JobFilter';
import { mapJobStageToQueryParam, parseJobStage } from './JobStageFilter';
import { SingleValueSelectState } from './SingleValueSelectFilter';
import { mapTabToQueryParam, parseTab } from './TabFilter';
import { defaultPage, mapPageToQueryParam, parsePage } from './PageFilter';
import { mapNameToQueryParam, parseName } from './NameFilter';
import { SortingColumn } from '../../../../../utils/sorting';
import {
  mapSortingColumnToQueryParam,
  mapSortingDirectionToQueryParam,
  parseSortingColumn,
} from './SortingColumn';
import _ from 'lodash';
import {
  appendQueryParamToCurrentTab,
  removeQueryParamFromCurrentTab,
} from '../../../../../utils/url';

const AVAILABLE_QUERY_PARAMS = [
  'name',
  'page',
  'tab',
  'job',
  'job_stage',
  'sorting_column',
  'sorting_direction',
  'date_range',
  'start_date',
  'end_date',
];

export interface FilterStates {
  page?: number;
  tab?: ListCandidatesFilterTab;
  dateRange?: DateFilter;
  job?: SingleValueSelectState;
  jobStage?: SingleValueSelectState;
  name?: string;
  sortingColumn?: SortingColumn;
}

export function parseCurrentFilterState(
  jobOptions: SelectOption[],
  jobStageOptions: SelectOption[],
): FilterStates {
  return {
    page: parsePage(),
    tab: parseTab(),
    dateRange: parseDateRange(),
    job: parseJob(jobOptions),
    jobStage: parseJobStage(jobStageOptions),
    name: parseName(),
    sortingColumn: parseSortingColumn(),
  };
}

export function mapToQueryParams(filterStates: FilterStates) {
  return [
    mapNameToQueryParam(filterStates.name),
    mapPageToQueryParam(filterStates.page),
    mapTabToQueryParam(filterStates.tab),
    mapJobToQueryParam(filterStates.job.selected?.value),
    mapJobStageToQueryParam(filterStates.jobStage.selected?.value),
    mapSortingColumnToQueryParam(filterStates.sortingColumn.columnName),
    mapSortingDirectionToQueryParam(filterStates.sortingColumn.direction),
    ...mapDateRangeToQueryParams(filterStates.dateRange),
  ].filter((qp) => qp != null);
}

export function resetPageIfFiltersChanged(
  previousFilterStates: FilterStates,
  currentFilterStates: FilterStates,
): void {
  const previousFiltersWithoutPage = _.omit(previousFilterStates, 'page');
  const currentFiltersWithoutPage = _.omit(currentFilterStates, 'page');

  if (
    areFiltersDifferent(currentFiltersWithoutPage, previousFiltersWithoutPage)
  ) {
    currentFilterStates.page = defaultPage();
  }
}

export function areFiltersDifferent(object1, object2): boolean {
  return JSON.stringify(object1) !== JSON.stringify(object2);
}

export function appendFilterStatesToCurrentTab(filterStates: FilterStates) {
  const mappedQueryParams = mapToQueryParams(filterStates);

  mapToQueryParams(filterStates)
    .filter((qp) => qp.value)
    .forEach((qp) => {
      appendQueryParamToCurrentTab(qp.key, qp.value as string);
    });

  AVAILABLE_QUERY_PARAMS.forEach((qp) => {
    if (!mappedQueryParams.find((p) => p.key === qp)?.value) {
      removeQueryParamFromCurrentTab(qp);
    }
  });
}
