import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { CreateSurveyTemplateRequest } from '../../survey_engine/CreateSurveyTemplateRequest';
import { InterviewKitTemplateList } from '../../../../entities/v1/applicant_tracking/InterviewKitTemplateList';
import { InterviewKitTemplate } from '../../../../entities/v1/applicant_tracking/InterviewKitTemplate';
import { UpdateSurveyTemplateRequest } from '../../survey_engine/UpdateSurveyTemplateRequest';

interface Params {
  expand: string[];
}

export class InterviewKitTemplateService {
  public static list(params: Params): Promise<InterviewKitTemplateList> {
    return ApiService.get<InterviewKitTemplateList>(
      '/api/v1/applicant_tracking/interview_kit_templates',
      snakecaseKeys(params),
    );
  }

  public static show(
    id: string,
    params: Params,
  ): Promise<InterviewKitTemplate> {
    return ApiService.get<InterviewKitTemplate>(
      `/api/v1/applicant_tracking/interview_kit_templates/${id}`,
      snakecaseKeys(params),
    );
  }

  public static create(
    surveyTemplate: CreateSurveyTemplateRequest,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/v1/applicant_tracking/interview_kit_templates`,
      snakecaseKeys({ surveyTemplate }),
    );
  }

  public static update(
    id: number,
    surveyTemplate: UpdateSurveyTemplateRequest,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/interview_kit_templates/${id}`,
      snakecaseKeys({ surveyTemplate }),
    );
  }
}
