import classNames from 'classnames';
import { Nav, NavItem } from 'reactstrap';
import { FollowUpResponsesTabLink } from './FollowUpResponsesTabLink';
import React, { useCallback } from 'react';
import { FollowUpResponsesTab } from './models/FollowUpResponsesTab';
import { useNavigate } from 'react-router-dom';

export interface PropTypes {
  searchParams: URLSearchParams;
  currentTab: FollowUpResponsesTab;
  initialMaxElements: number;
  setMaxElements: (maxElements: number) => void;
  setEnabledViewLess: (enabledViewLess: boolean) => void;
}

function navItem(
  tabName: FollowUpResponsesTab,
  currentTab: FollowUpResponsesTab,
  handleTabClick: (tab: FollowUpResponsesTab) => void,
) {
  return (
    <NavItem>
      <FollowUpResponsesTabLink
        tab={tabName}
        activeTab={currentTab}
        onClick={handleTabClick}
      >
        {tabName.charAt(0).toUpperCase() + tabName.slice(1)}
      </FollowUpResponsesTabLink>
    </NavItem>
  );
}

export function FollowUpResponsesListTabNavigation(props: PropTypes) {
  const navigate = useNavigate();
  const handleTabClick = useCallback((tab: FollowUpResponsesTab) => {
    props.searchParams.delete('tab');
    navigate(`?${props.searchParams.toString()}&tab=${tab}`);
    props.setEnabledViewLess(false);
    props.setMaxElements(props.initialMaxElements);
  }, []);

  return (
    <Nav
      className={classNames('flex-wrap mb-5 px-3 app-nav-tabs bg-transparent')}
    >
      {navItem('all', props.currentTab, handleTabClick)}
      {navItem('detractors', props.currentTab, handleTabClick)}
      {navItem('neutrals', props.currentTab, handleTabClick)}
      {navItem('promoters', props.currentTab, handleTabClick)}
    </Nav>
  );
}
