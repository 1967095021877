import React from 'react';
import classNames from 'classnames';
import { Link } from '../Link';

interface PropTypes<TabType> {
  text: string;
  tab: TabType;
  activeTab: TabType;
  setActiveTab: (at: TabType) => void;
  disabled?: boolean;
}

export function NavLinkTab<TabType>(props: PropTypes<TabType>) {
  return (
    <Link
      onClick={() => props.setActiveTab(props.tab)}
      classNames={classNames('text-sm-center nav-link', {
        active: props.activeTab == props.tab,
      })}
      disabled={props.disabled}
    >
      {props.text}
    </Link>
  );
}
