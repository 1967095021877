import React from 'react';
import { User } from '../../../../../../../entities/User';
import { ApplicationRating } from '../../../../../../../entities/v1/applicant_tracking/ApplicationRating';
import { Col } from 'reactstrap';
import { FeedbackBlockFooter } from '../../../FeedbackBlockFooter';

interface PropTypes {
  applicationRating: ApplicationRating;
  currentUser: User;
}

export function CandidateRatingBody(props: PropTypes) {
  return (
    <>
      <Col xs='12' className='align-items-stretch'>
        <h5>What&apos;s your overall impression of this candidate?</h5>
        <div
          className='p-2h bg-gray rounded-2 flex-fill fs-5 text-dark-500 mt-1 text-dark-500'
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {props.applicationRating.comment ? (
            props.applicationRating.comment
          ) : (
            <span className='text-dark-200'>No comments were added.</span>
          )}
        </div>
      </Col>
      <FeedbackBlockFooter
        currentUser={props.currentUser}
        entryUser={props.applicationRating.user}
        updatedAt={props.applicationRating.updatedAt}
      />
    </>
  );
}
