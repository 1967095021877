import classNames from 'classnames';
import React, { useState } from 'react';
import { AlertType } from '../../../../../components/Alert';
import { EmailDomainService } from '../../../../../services/EmailDomainService';
import { EmailDomain } from '../../../../../entities/EmailDomain';
import { emailAlias } from '../../../../../utils/emailAlias';
import { GenerateValueButton } from './GenerateValueButton';
import { WarningOnDomainChange } from './WarningOnDomainChange';
import { FormButtons } from './FormButtons';
import { EmailDomainInput } from './EmailDomainInput';
import { EmailAliasInput } from './EmailAliasInput';
import { SenderNameInput } from './SenderNameInput';
import { EmailSender } from '../../../../../entities/EmailSender';
import { isDomainValid } from '../../../../../utils/isDomainValid';

interface PropTypes {
  isEmailDomainEnabled: boolean;
  emailDomain: EmailDomain;
  setEmailDomain: (emailDomain: EmailDomain) => void;
  onAlert: (message: string, alertType: AlertType) => void;
}

function emailSenderName(emailSender: EmailSender) {
  return emailSender !== undefined ? emailSender.name : '';
}

function emailSenderAlias(emailSender: EmailSender) {
  return emailSender !== undefined ? emailAlias(emailSender.email) : '';
}

function emailSenderDomain(emailDomain: EmailDomain) {
  return emailDomain !== undefined ? emailDomain.domain : '';
}

export function EmailSenderForm(props: PropTypes) {
  const [isFormActive, setFormActive] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const emailSender = props.emailDomain.email_sender;

  const [senderNameInput, setSenderNameInput] = useState(
    emailSenderName(emailSender),
  );
  const [emailAliasInput, setEmailAliasInput] = useState(
    emailSenderAlias(emailSender),
  );
  const [emailDomainInput, setEmailDomainInput] = useState(
    emailSenderDomain(props.emailDomain),
  );

  function onEditClick() {
    setFormActive(true);
  }

  function onCancelClick() {
    setFormActive(false);
    setSenderNameInput(emailSender.name);
    setEmailAliasInput(emailSender.email.split('@')[0]);
    setEmailDomainInput(props.emailDomain.domain);
  }

  async function onSaveClick() {
    if (!senderNameInput || !emailAliasInput || !emailDomainInput) {
      props.onAlert('Please fill out all fields.', AlertType.Danger);
    } else {
      await saveFormInput();
    }
  }

  async function saveFormInput() {
    if (!isDomainValid(emailDomainInput)) {
      props.onAlert('Please insert a valid domain.', AlertType.Warning);
      return;
    }

    await EmailDomainService.create(
      emailDomainInput,
      emailAliasInput,
      senderNameInput,
    ).then((emailDomain) => {
      props.setEmailDomain(emailDomain);

      if (props.emailDomain.domain !== emailDomainInput) {
        setShowWarning(true);
      }

      props.onAlert('Domain settings have been saved!', AlertType.Success);
      setFormActive(false);
    });
  }

  return (
    <>
      <div className='row'>
        <div className='col-3'>
          <label htmlFor='name' className='form-label'>
            Name
          </label>
        </div>
        <div className='col-3'>
          <label htmlFor='emailAlias' className='form-label'>
            Email Alias
          </label>
        </div>
        <div className='col-3 ms-4'>
          <label htmlFor='emailDomain' className='form-label'>
            Email Domain
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col-3'>
          <SenderNameInput
            isFormActive={isFormActive}
            senderName={senderNameInput}
            setSenderName={setSenderNameInput}
          />
        </div>
        <div className='col-3 pe-0'>
          <EmailAliasInput
            isFormActive={isFormActive}
            emailAlias={emailAliasInput}
            setEmailAlias={setEmailAliasInput}
          />
        </div>
        <div className='col-3 d-flex align-items-center'>
          <div className='pe-2'>@</div>
          <EmailDomainInput
            isFormActive={isFormActive}
            emailDomain={emailDomainInput}
            setEmailDomain={setEmailDomainInput}
          />
        </div>
        <div className={classNames('col-3 d-flex align-items-center')}>
          <FormButtons
            isEmailDomainEnabled={props.isEmailDomainEnabled}
            isFormActive={isFormActive}
            onEditClick={onEditClick}
            onCancelClick={onCancelClick}
            onSaveClick={onSaveClick}
          />
        </div>
      </div>
      <WarningOnDomainChange
        showWarning={showWarning}
        valuesGenerated={props.emailDomain?.dkim_host != null}
      />
      <div className='row'>
        <GenerateValueButton
          isEmailDomainEnabled={props.isEmailDomainEnabled}
          emailDomain={props.emailDomain}
          setEmailDomain={props.setEmailDomain}
          setShowWarning={setShowWarning}
          onAlert={props.onAlert}
        />
      </div>
    </>
  );
}
