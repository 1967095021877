import { SelectOption } from '../../../../../components/Select';
import { SurveyTemplate } from '../../../../../entities/candidate_experience/SurveyTemplate';

export function filterSurveyTemplate(
  st: SelectOption,
  surveyTemplates: SurveyTemplate[],
) {
  const filteredSurveyTemplate = surveyTemplates.filter(
    (surveyTemplate) => surveyTemplate.id === parseInt(st.value),
  );

  if (filteredSurveyTemplate.length === 0) {
    return null;
  }

  return filteredSurveyTemplate[0];
}
