import React from 'react';
import { Col } from 'reactstrap';

export function MoreInfoFooter() {
  return (
    <Col xs='12'>
      <div>
        <p className='fw-bold mb-0 fs-5'>
          Would you like to use Screenloop at your company?
        </p>
        <a
          href='https://www.screenloop.com/#call-to-action'
          className='fw-bold text-decoration-underline fs-5'
          target='_blank'
          rel='noreferrer'
        >
          Click here
        </a>{' '}
        to request more information.
      </div>
    </Col>
  );
}
