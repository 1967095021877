import React from 'react';
import { SurveyRequest } from '../../reference_check/entities/SurveyRequest';

export function ReferencesStatus(props: { surveyRequest: SurveyRequest }) {
  const totalReferrals = props?.surveyRequest?.referrals?.length;
  const sentReferrals = props?.surveyRequest?.referrals
    ?.map((r) => +(r.surveys.length > 0))
    ?.reduce((a, b) => a + b, 0);

  return (
    sentReferrals !== totalReferrals && (
      <>
        <strong>
          {sentReferrals || 0}/{totalReferrals || 0}
        </strong>{' '}
        Requested
      </>
    )
  );
}
