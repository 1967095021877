import React, { useEffect, useState } from 'react';
import { EmailRichTextEditor } from './EmailRichTextEditor';
import { EmailHeader } from './EmailHeader';
import { SelectOption } from '../../../components/Select';
import { Email } from '../../../entities/Email';
import { EmailTemplate } from '../../../entities/EmailTemplate';
import { EmailTemplateService } from '../../../services/EmailTemplateService';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { AttachmentDto } from '../../../services/dtos/EmailDto';
import { getLabelForCount } from '../../../utils/grammar';

interface PropTypes {
  applicationIds: number[];
  bcc: SelectOption[];
  cc: SelectOption[];
  toEmail?: string;
  subjectJson?: string;
  emailJson?: string;
  isLoading?: boolean;
  replyToEmail?: Email;
  clearUploadedFiles: () => void;
  setBcc: (bcc: SelectOption[]) => void;
  setCc: (cc: SelectOption[]) => void;
  setEmailJson?: (json: string) => void;
  setHtml: (html: string) => void;
  setIsValid: (valid: boolean) => void;
  setSubjectText: (subject: string) => void;
  setSubjectJson?: (json: string) => void;
  setAttachments: (attachments: AttachmentDto[]) => void;
}

function buildRecipientString(applicationIds: number[], toEmail?: string) {
  if (toEmail) {
    return toEmail;
  }

  return getLabelForCount('Candidate', applicationIds.length);
}

export function EmailModalBody(props: PropTypes) {
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>(null);

  const [validSubject, setValidSubject] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);

  const singleEmailRecipient = props.applicationIds.length === 1;

  async function handleEmailTemplate(emailTemplate: EmailTemplate) {
    const template = singleEmailRecipient
      ? await EmailTemplateService.replaceVariables(
          emailTemplate.id,
          props.applicationIds[0],
        )
      : emailTemplate;

    const emailTemplateWithAttachments = await EmailTemplateService.get(
      emailTemplate.id,
    );

    setEmailTemplate(template);
    props.clearUploadedFiles();
    props.setAttachments(emailTemplateWithAttachments.attachments);
  }

  useEffect(() => {
    props.setIsValid(validEmail && validSubject);
  }, [validEmail, validSubject]);

  return (
    <div
      className={classNames(
        'd-flex flex-column gap-3 fw-semibold fs-5',
        styles['email-modal-body'],
      )}
    >
      {props.isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <EmailHeader
            cc={props.cc}
            bcc={props.bcc}
            replyToEmail={props.replyToEmail}
            to={buildRecipientString(props.applicationIds, props.toEmail)}
            emailTemplate={emailTemplate}
            subjectJson={props.subjectJson}
            setEmailTemplate={handleEmailTemplate}
            setCc={props.setCc}
            setBcc={props.setBcc}
            setIsValid={setValidSubject}
            setSubjectText={props.setSubjectText}
            setSubjectJson={props.setSubjectJson}
          />
          <EmailRichTextEditor
            emailTemplate={emailTemplate}
            setIsValid={setValidEmail}
            json={props.emailJson}
            onChangeHtml={props.setHtml}
            onChangeJson={props.setEmailJson}
          />
        </>
      )}
    </div>
  );
}
