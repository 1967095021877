import React from 'react';
import { InterviewClip } from '../../../../entities/InterviewClip';
import { formatTime } from '../../../../utils/timeFormat';
import { TruncateText } from '../../../../components/TruncateText';
import { findSentenceByOffset } from '../../../../utils/monologueSelector';
import { Button } from 'reactstrap';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { Icon } from '../../../../components/Icon';
import { IconSpan } from '../../../../components/IconSpan';
import { Chip, ChipColor } from '../../../../components/Chip';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { Dropdown } from '../../../../components/Dropdown';
import { Monologue } from '../../../../entities/v1/interview_intelligence/Monologue';

interface PropTypes {
  clip: InterviewClip;
  loadingLink: boolean;
  monologues: Monologue[];
  onClick: () => void;
  onDelete: () => void;
  onCopyLink: () => void;
  onEdit: () => void;
}

function Actions(props: {
  loadingLink: boolean;
  onDelete: () => void;
  onEdit: () => void;
  onCopyLink: () => void;
}) {
  const menuActions = [
    {
      action: props.onEdit,
      buttonChild: (
        <IconSpan
          icon={{ name: 'bi-pencil', className: 'fs-4' }}
          spanText='Edit'
          className='text-info fs-5'
          spanClassName='ms-2h'
        />
      ),
    },
    {
      action: props.onDelete,
      buttonChild: (
        <IconSpan
          icon={{ name: 'bi-trash', className: 'fs-4' }}
          spanText='Delete'
          className='text-info fs-5'
          spanClassName='ms-2h'
        />
      ),
    },
  ];

  return (
    <div className='d-flex align-items-center'>
      {props.loadingLink ? (
        <LoadingSpinner />
      ) : (
        <Button color='borderless' onClick={props.onCopyLink}>
          <SpanTooltip
            placement='top'
            text={<Icon name='bi-files' className='text-info fs-2' />}
            tooltipText='Copy Link'
          />
        </Button>
      )}
      <Dropdown
        buttonIcon={{
          name: 'bi-three-dots',
          className: 'fs-5 text-blue',
        }}
        menuPlacementClass={'dropdown-menu-end'}
        menuActions={menuActions}
      ></Dropdown>
    </div>
  );
}

export function InterviewClipItem(props: PropTypes) {
  return (
    <div className='rounded-4 border border-black p-2h mb-3'>
      <div className='d-flex justify-content-between align-items-center text-info fs-5'>
        <div className='d-flex'>
          <Button
            color='link'
            onClick={props.onClick}
            className='text-decoration-none p-0'
          >
            <TruncateText
              text={props.clip.title}
              maxLines={1}
              className='fs-5 fw-semibold text-info'
              tooltip
            />
          </Button>

          {props.clip.status !== 'processed' && (
            <Chip
              className='fw-semibold ms-2 mb-1'
              color={ChipColor.DangerLight}
            >
              Processing
            </Chip>
          )}
        </div>
        <Actions
          loadingLink={props.loadingLink}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
          onCopyLink={props.onCopyLink}
        />
      </div>
      <div className='text-dark-500 fs-6 my-1'>
        <TruncateText
          maxLines={2}
          text={findSentenceByOffset(
            props.monologues,
            props.clip.startOffsetMs,
          )}
          tooltip
        />
      </div>
      <span className='text-dark-200 fs-6'>{`${formatTime(
        props.clip.startOffsetMs,
      )} - ${formatTime(props.clip.endOffsetMs)}`}</span>
    </div>
  );
}
