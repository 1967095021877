import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Icon, PropTypes as IconProptypes } from '../Icon';
import styles from './styles.module.scss';

interface PropTypes {
  text: ReactNode;
  icon?: IconProptypes;
  className?: string;
  textClassName?: string;
  hasOverlay?: boolean;
}

export function AlertMessage(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex align-items-center rounded-1 px-3 py-2h',
        { 'shadow-bmd': props.hasOverlay },
        styles['alert-content'],
        props.className,
      )}
    >
      {props.icon && <Icon {...props.icon} />}
      <span className={classNames({ 'ms-3': props.icon }, props.textClassName)}>
        {props.text}
      </span>
    </div>
  );
}
