import React from 'react';
import { TextField } from '../TextField';
import { JobPostingDetailsForm } from '..';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: Record<string, string>;
  onInputChange: (name: string, value: unknown) => void;
}

export function ContactName(props: PropTypes) {
  return (
    <TextField
      label='Contact Name'
      value={props.form.contactName}
      name='contactName'
      error={props.errors['contactInfo']?.['name']}
      onInputChange={props.onInputChange}
      placeholder={'Insert Name'}
    />
  );
}
