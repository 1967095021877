import React from 'react';
import { Row, Col } from 'reactstrap';
import { Survey } from '../../../../entities/Survey';
import { BooleanField } from '../../SurveyScreen/fields/BooleanField';

interface PropTypes {
  survey: Survey;
  cStartedOn: moment.Moment;
  cEndedOn: moment.Moment;
  answer: boolean;
  onChangeAnswer(fieldId: number, value: 'true' | 'false'): void;
}

export function Question(props: PropTypes) {
  return (
    <>
      <Row>
        <Col xs='12'>
          <h2
            className='fw-normal'
            style={{
              letterSpacing: `${props.survey.company_info.font_kerning / 2}em`,
            }}
          >
            Did {props.survey.candidate.name} work with you as a{' '}
            <strong>{props.survey.candidate_job_title}</strong> from{' '}
            <strong>{props.cStartedOn.format('MM/YYYY')}</strong> to{' '}
            <strong>{props.cEndedOn.format('MM/YYYY')}</strong>?
          </h2>
        </Col>
      </Row>
      <BooleanField
        field={{
          id: -1,
          name: null,
          type: 'boolean',
          positive: true,
          options: [],
        }}
        fieldAnswer={{
          field_id: -1,
          value: props.answer?.toString(),
          option_ids: [],
        }}
        onChangeAnswer={props.onChangeAnswer}
      />
    </>
  );
}
