import { Interview } from '../../../../../entities/Interview';
import { EmptyTab } from '../../../../../components/EmptyTab';
import UndrawLost from '../../../../../images/undraw_empty_state.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ActionPoints } from '../../../../../components/ActionPoints';
import React from 'react';
import { ActionPoint } from '../../../../../entities/ActionPoint';

interface PropTypes {
  interview: Interview;
  atsEnabled: boolean;
  onSelectTimestamp: (timestamp: number) => void;
  onUpdateActionPoints: (actionPoints: ActionPoint[]) => void;
  openExternalLink?: boolean;
}

export function ActionPointsTab(props: PropTypes) {
  if (props.interview.actionPoints.length === 0) {
    return (
      <EmptyTab
        title='No summary available yet'
        text='We are processing your interview summary. Please check back later.'
        imageSrc={UndrawLost}
        size='medium'
      />
    );
  }

  return (
    <div
      className={classNames(
        'd-flex',
        'flex-column',
        'gap-3',
        'mt-2',
        styles.expandableNotes,
      )}
    >
      {props.interview.actionPoints?.length > 0 && (
        <div className='fs-4 fw-bold'>Action Points</div>
      )}
      <ActionPoints
        interview={props.interview}
        onUpdateActionPoints={props.onUpdateActionPoints}
        atsEnabled={props.atsEnabled}
        openExternalLink={props.openExternalLink}
      />
    </div>
  );
}
