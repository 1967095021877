import MetricCard, {
  Body,
  Head,
  Title,
} from '../../../../components/MetricCard';
import React from 'react';
import { Table } from 'reactstrap';
import { FollowUpResponsesTopicsPanelRow } from './FollowUpResponsesTopicsPanelRow';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { AutomaticAttribute } from '../../../../entities/candidate_experience/AutomaticAttribute';
import { EmptyState } from '../../../../components/EmptyState';

interface PropTypes {
  attributes: AutomaticAttribute[];
}

export function FollowUpResponsesTopicsPanel(props: PropTypes) {
  return (
    <MetricCard className='h-100'>
      <Head>
        <Title
          value='Topics / Mentions'
          tooltip={
            'Analysis of the free-text responses to what could be improved to get a higher CNPS score'
          }
        />
      </Head>
      <Body>
        {props.attributes?.length > 0 ? (
          <Table className='fs-5 text-primary table-borderless'>
            <thead className='fw-bold'>
              <tr>
                <th
                  scope='col'
                  className={classNames(
                    'col col-9 fs-6 ps-4',
                    styles.topicsHeader,
                  )}
                >
                  Topic
                </th>
                <th
                  scope='col'
                  className={classNames(
                    'col col-auto text-center fs-6',
                    styles.topicsHeader,
                  )}
                >
                  Mentions
                </th>
                <th
                  scope='col'
                  className={classNames(
                    'col col-auto text-center fs-6',
                    styles.topicsHeader,
                  )}
                >
                  % of Total
                </th>
              </tr>
            </thead>
            <tbody>
              {props.attributes.map((attribute) => (
                <FollowUpResponsesTopicsPanelRow
                  key={attribute.title}
                  topicName={attribute.title}
                  totalMentions={attribute.mentions}
                  percentageOfTotal={attribute.percentage_of_mentions}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyState
            title='No data available yet'
            text={<p>There may be no data or we may still be processing.</p>}
          />
        )}
      </Body>
    </MetricCard>
  );
}
