import { AlertType } from '../../../components/Alert';
import { InterviewListItem } from '../../../entities/InterviewListItem';
import { editAvailability } from './editAvailability';
import { sendEmailReminder } from './sendEmailReminder';
import { copySchedulingLink } from './copySchedulingLink';

export function shouldActionBeVisible(interview: InterviewListItem) {
  return interview.status == 'unscheduled' && interview.interviewCandidateUser;
}

export function pendingCandidateSchedulingInterviewActions(
  interview: InterviewListItem,
  setAlertMessage: (message: string) => void,
  setAlertType: (type: AlertType) => void,
) {
  return [
    editAvailability(interview),
    copySchedulingLink(interview, setAlertMessage, setAlertType),
    sendEmailReminder(interview, setAlertMessage, setAlertType),
  ];
}
