import React from 'react';
import { Dropdown } from '../../../../../components/Dropdown';
import { IconSpan } from '../../../../../components/IconSpan';
import { PropTypes as ActionPropTypes } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';
import { appendRedirectUri } from '../../../../../utils/url';
import { AlertType } from '../../../../../components/Alert';
import { SourceService } from '../../../../../services/v1/applicant_tracking/SourceService';
import { redirectWithAlert } from '../../../../../services/FlashService';

interface PropTypes {
  id: number;
  global: boolean;
  editEnabled: boolean;
  editUrl: string;
  viewUrl: string;
}

const REDIRECT_URL = '/organization_settings/sources';

function view(props: PropTypes): ActionPropTypes {
  return {
    action: () => (window.location.href = appendRedirectUri(props.viewUrl)),
    buttonChild: (
      <IconSpan
        spanText={'View'}
        icon={{ name: 'bi-eye', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function editAction(props: PropTypes): ActionPropTypes {
  return {
    action: () => (window.location.href = appendRedirectUri(props.editUrl)),
    buttonChild: (
      <IconSpan
        spanText={'Edit'}
        icon={{ name: 'bi-pencil', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function _deleteAction(props: PropTypes): ActionPropTypes {
  const deleteSources = async () => {
    try {
      await SourceService.destroy(props.id);
      redirectWithAlert(REDIRECT_URL, 'The source was successfully deleted!');
    } catch (e) {
      redirectWithAlert(
        REDIRECT_URL,
        'Could not delete source. Please try again later.',
        AlertType.Danger,
      );
    }
  };

  return {
    action: deleteSources,
    buttonChild: (
      <IconSpan
        spanText={'Delete'}
        icon={{ name: 'bi-trash', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

export default function RowActionsDropdown(props: PropTypes) {
  const menuActions = [view(props)];

  if (props.editEnabled) {
    menuActions.push(editAction(props));
    // menuActions.push(deleteAction(props));
  }

  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-4 text-info',
      }}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={menuActions}
    />
  );
}
