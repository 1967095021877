import { SurveyModuleVersion } from '../../../../reference_check/entities/SurveyModuleVersion';
import { SurveyQuestion } from '../../../../reference_check/entities/SurveyQuestion';
import { SurveyQuestionField } from '../../../../reference_check/entities/SurveyQuestionField';
import { extractQuestionsFromModuleVersions } from '../../../../reference_check/utils/extractQuestionsFromModuleVersions';

interface FindSurveyQuestionFieldResult {
  question: SurveyQuestion;
  field: SurveyQuestionField;
}

export function findSurveyQuestionField(
  surveyModuleVersions: SurveyModuleVersion[],
  fieldId: number,
): FindSurveyQuestionFieldResult {
  const questions = extractQuestionsFromModuleVersions(surveyModuleVersions);
  const fields = questions.reduce<FindSurveyQuestionFieldResult[]>(
    (prev, curr) => {
      return prev.concat(
        curr.fields.map((f) => ({ question: curr, field: f })),
      );
    },
    [],
  );
  return fields.find((v) => v.field.id === fieldId);
}
