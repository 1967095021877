import { buildMembers } from './buildMembers';
import { buildQueryPeriods } from './buildQueryPeriods';
import { CronofyOptions } from '../../CronofyOptions';
import { InterviewListItem } from '../../../../entities/InterviewListItem';
import { addMillisecondsToTime } from '../../../../utils/timeFormat';
import { Interview } from '../../../../entities/applicant_tracking/Interview';
import { InterviewerParticipant } from '../../../../entities/applicant_tracking/InterviewerParticipant';

const SEARCH_DAYS = 60;

function getTimeConstraints(
  applicationInterviews: InterviewListItem[],
  currentInterview?: Interview,
) {
  const scheduledInterviews = applicationInterviews?.filter(
    (i) => i.status == 'scheduled' && i.id != currentInterview?.id,
  );

  return scheduledInterviews
    ?.filter((i: InterviewListItem) => i.date != null && i.duration != null)
    .map((i: InterviewListItem) => {
      return {
        startTime: new Date(i.date),
        endTime: new Date(addMillisecondsToTime(i.date, i.duration)),
      };
    });
}

function addExcludedEvents(availabilityQuery, currentInterview?: Interview) {
  if (currentInterview?.externalId) {
    const excludedEvents = [];

    // exclude interviewer event from organizer's calendar
    excludedEvents.push({ event_id: currentInterview.externalId });

    // exclude candidate event from organizer's calendar
    if (currentInterview.interviewCandidate?.externalId) {
      excludedEvents.push({
        event_id: currentInterview.interviewCandidate.externalId,
      });
    }

    // exclude interviewer event from remaining participants' calendar
    currentInterview.interviewers.forEach((ihm) => {
      if (ihm.externalId && ihm.externalId != currentInterview.externalId) {
        excludedEvents.push({ event_uid: ihm.externalId });
      }
    });

    availabilityQuery['excluded_events'] = excludedEvents;
  }
}

export function buildAvailabilityQuery(
  options: CronofyOptions,
  participants: InterviewerParticipant[],
  startDate: Date,
  startHours: number,
  durationMinutes: number,
  applicationInterviews: InterviewListItem[],
  timezone: string,
  searchDays?: number,
  currentInterview?: Interview,
) {
  const availabilityQuery = {
    participants: [
      {
        required: 'all',
        members: buildMembers(participants, options),
      },
    ],
    response_format: 'overlapping_slots',
    start_interval: { minutes: 15 },
    required_duration: { minutes: durationMinutes },
    query_periods: buildQueryPeriods(
      startDate,
      startHours,
      searchDays || SEARCH_DAYS,
      getTimeConstraints(applicationInterviews, currentInterview),
      participants,
      timezone,
    ),
  };

  addExcludedEvents(availabilityQuery, currentInterview);

  return availabilityQuery;
}
