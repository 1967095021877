import React from 'react';
import { NumberField } from '../NumberField';
import { JobPostingDetailsForm } from '..';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: any;
  mandatory?: boolean;
  onInputChange: (name: string, value: unknown) => void;
}

export function SalaryRange(props: PropTypes) {
  return (
    <div className='d-flex gap-2'>
      <NumberField
        className='w-50'
        label='Salary Range'
        value={props.form.salaryRangeFrom}
        name='salaryRangeFrom'
        onInputChange={props.onInputChange}
        placeholder='From'
        error={props.errors['salaryIndication']?.['range']?.['from']}
      />
      <NumberField
        className='w-50'
        label='To'
        value={props.form.salaryRangeTo}
        name='salaryRangeTo'
        onInputChange={props.onInputChange}
        mandatory={props.mandatory}
        placeholder='To'
        error={props.errors['salaryIndication']?.['range']?.['to']}
      />
    </div>
  );
}
