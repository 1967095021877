import { Col, Row } from 'reactstrap';
import React from 'react';
import { OverallNps } from './OverallNps';
import { Nps } from '../../../entities/survey_engine/Nps';
import { NpsHistory } from '../../../entities/survey_engine/NpsHistory';
import { NpsBenchmark } from '../../../entities/candidate_experience/NpsBenchmark';
import { DateFilter } from '../../DateRangePicker';
import classNames from 'classnames';
import { REPORTING_SECTION_CLASS } from '../../../utils/pdfHandler';
import { OverallNpsHistory } from './OverallNpsHistory';
import { OverallNpsTooltipText } from './OverallNps/OverallNpsTooltipText';

export function OverallNpsPanels(props: {
  npsTitle: string;
  npsDescription: string;
  npsPromotersText: string;
  npsNeutralsText: string;
  npsDetractorsText: string;
  nps: Nps;
  npsHistory: NpsHistory[];
  npsBenchmark: NpsBenchmark;
  npsTrend: number;
  dateRange: DateFilter;
  exportToPDF: boolean;
  employeeNps?: Nps;
  hiringManagerNps?: Nps;
  standardNpsCalculation: boolean;
}) {
  return (
    <Row className={classNames('gy-4 mb-3', REPORTING_SECTION_CLASS)}>
      <Col sm='12' lg='5'>
        <OverallNps
          nps={props.nps}
          npsTrend={props.npsTrend}
          title={props.npsTitle}
          detractorsText={props.npsDetractorsText}
          neutralsText={props.npsNeutralsText}
          promotersText={props.npsPromotersText}
          tooltipText={<OverallNpsTooltipText {...props} />}
          standardNpsCalculation={props.standardNpsCalculation}
        />
      </Col>
      <Col sm='12' lg='7'>
        <OverallNpsHistory
          npsHistory={props.npsHistory}
          npsBenchmark={props.npsBenchmark}
          dateRange={props.dateRange}
          title={`${props.npsTitle} History`}
          exportToPDF={props.exportToPDF}
          standardNpsCalculation={props.standardNpsCalculation}
        />
      </Col>
    </Row>
  );
}
