import React from 'react';
import { FilterState } from '..';
import { EmptyState } from '../../../../components/EmptyState';
import { QualityOfHireAnalytics } from '../../../../entities/quality_of_hire/QualityOfHireAnalytics';
import { PageWideSpinner } from '../../../../components/PageWideSpinner';
import { FirstRow } from './FirstRow';
import { SecondRow } from './SecondRow';

interface PagePropTypes {
  analytics: QualityOfHireAnalytics;
  filterState: FilterState;
  loading: boolean;
}

function hasAnalytics(analytics: QualityOfHireAnalytics) {
  return (
    analytics != null &&
    Object.keys(analytics).length > 0 &&
    analytics.nhs.value !== null
  );
}

function Body(props: PagePropTypes) {
  return (
    <div className='d-flex flex-column mt-3'>
      <FirstRow
        npsTitle='Overall NHS'
        npsDescription='Quality of Hire Score reflects the success of the recruitment and selection process based on the new employee performance and fit.'
        npsPromotersText='Strong Fit'
        npsNeutralsText='Moderate Fit'
        npsDetractorsText='Poor Fit'
        nps={props.analytics.nhs}
        npsHistory={props.analytics.nhsHistory}
        npsTrend={props.analytics.nhsTrend}
        surveyMetrics={props.analytics.surveyMetrics}
        npsBenchmark={{
          name: 'Best Practice',
          value: 7.0,
        }} // Placeholder
        dateRange={props.filterState.dateRange}
        exportToPDF={false}
        nhsTeamBreakdown={props.analytics.nhsTeamBreakdown}
        hasNpsTeamBreakdown={true}
      />
      {props.analytics.questionAverages != null && (
        <SecondRow questionAverages={props.analytics.questionAverages} />
      )}
    </div>
  );
}

export function AnalyticsSection(props: PagePropTypes) {
  if (props.loading) {
    return <PageWideSpinner />;
  }

  return (
    <main>
      {hasAnalytics(props.analytics) ? (
        <Body {...props} />
      ) : (
        <EmptyState
          title='No Quality of Hire feedback available yet'
          text={
            <p>
              Please try a different combination of filters or{' '}
              <a className='link-info' href='mailto:support@screenloop.com'>
                talk with us
              </a>{' '}
              to start collecting quality of hire data in every step of the
              onboarding process.
            </p>
          }
        />
      )}
    </main>
  );
}
