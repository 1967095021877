import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { FieldData } from './Field';
import { Action } from './dispatcher';
import { FieldOption } from './FieldOption';
import { Button } from 'reactstrap';

interface PropTypes {
  sectionId: number;
  fieldData: FieldData;
  dispatch: React.Dispatch<Action>;
  readOnly?: boolean;
}

export function OptionList(props: PropTypes) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleAddClick() {
    props.dispatch({
      type: 'option_add',
      sectionId: props.sectionId,
      fieldId: props.fieldData.clientId,
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id === over.id) return;

    props.dispatch({
      type: 'option_order_change',
      sectionId: props.sectionId,
      fieldId: props.fieldData.clientId,
      fromId: active.id,
      toId: over.id,
    });
  }

  return (
    <div className='ps-5'>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={props.fieldData.options.map((option) => option.clientId)}
          strategy={verticalListSortingStrategy}
          disabled={props.readOnly}
        >
          <div className='vstack gap-3'>
            {props.fieldData.options.map((option) => (
              <FieldOption
                key={option.clientId}
                sectionId={props.sectionId}
                fieldId={props.fieldData.clientId}
                data={option}
                dispatch={props.dispatch}
                readOnly={props.readOnly}
                removable={props.fieldData.options.length != 1}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      {!props.readOnly && (
        <div className='mt-2'>
          <Button size='sm' color='borderless' onClick={handleAddClick}>
            Add Option
          </Button>
        </div>
      )}
    </div>
  );
}
