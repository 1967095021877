import { arrayMove } from '@dnd-kit/sortable';
import { ClientId, FieldConfig, FieldData } from './Field';
import { SectionData } from './Section';
import { randomInt } from '../../../utils/number';
import { FieldType } from '../../../entities/v1/survey_engine/Question';
import { FieldOptionData } from './FieldOption';

export type Action =
  | { type: 'section_add'; defaultFieldType?: FieldType }
  | { type: 'section_remove'; sectionId: ClientId }
  | { type: 'section_up'; sectionId: ClientId }
  | { type: 'section_down'; sectionId: ClientId }
  | {
      type: 'section_attrs_change';
      sectionId: ClientId;
      attrs: Partial<Pick<SectionData, 'name' | 'description'>>;
    }
  | { type: 'field_add'; sectionId: ClientId; copy: boolean }
  | {
      type: 'field_remove';
      sectionId: ClientId;
      fieldId: ClientId;
    }
  | {
      type: 'field_order_change';
      sectionId: ClientId;
      fromId: ClientId;
      toId: ClientId;
    }
  | {
      type: 'field_config_change';
      sectionId: ClientId;
      fieldId: ClientId | 'ALL';
      fieldConfig: FieldConfig;
    }
  | {
      type: 'field_data_change';
      sectionId: ClientId;
      fieldId: ClientId;
      fieldData: FieldData;
    }
  | {
      type: 'option_add';
      sectionId: ClientId;
      fieldId: ClientId;
    }
  | {
      type: 'option_remove';
      sectionId: ClientId;
      fieldId: ClientId;
      optionId: ClientId;
    }
  | {
      type: 'option_order_change';
      sectionId: ClientId;
      fieldId: ClientId;
      fromId: ClientId;
      toId: ClientId;
    }
  | {
      type: 'option_data_change';
      sectionId: ClientId;
      fieldId: ClientId;
      optionId: ClientId;
      data: FieldOptionData;
    }
  | { type: 'reset'; newState: SectionData[] };

export function reducer(state: SectionData[], action: Action): SectionData[] {
  switch (action.type) {
    case 'section_add':
      return handleSectionAdd(state, action.defaultFieldType);
    case 'section_remove':
      return handleSectionRemove(state, action.sectionId);
    case 'section_up':
      return handleSectionUp(state, action.sectionId);
    case 'section_down':
      return handleSectionDown(state, action.sectionId);
    case 'section_attrs_change':
      return handleSectionAttrsChange(state, action.sectionId, action.attrs);
    case 'field_add':
      return handleFieldAdd(state, action.sectionId, action.copy);
    case 'field_remove':
      return handleFieldRemove(state, action.sectionId, action.fieldId);
    case 'field_order_change':
      return handleFieldOrderChange(
        state,
        action.sectionId,
        action.fromId,
        action.toId,
      );
    case 'field_config_change':
      return handleFieldConfigChange(
        state,
        action.sectionId,
        action.fieldId,
        action.fieldConfig,
      );
    case 'field_data_change':
      return handleFieldDataChange(
        state,
        action.sectionId,
        action.fieldId,
        action.fieldData,
      );
    case 'option_add':
      return handleOptionAdd(state, action.sectionId, action.fieldId);
    case 'option_remove':
      return handleOptionRemove(
        state,
        action.sectionId,
        action.fieldId,
        action.optionId,
      );
    case 'option_order_change':
      return handleOptionOrderChange(
        state,
        action.sectionId,
        action.fieldId,
        action.fromId,
        action.toId,
      );
    case 'option_data_change':
      return handleOptionDataChange(
        state,
        action.sectionId,
        action.fieldId,
        action.optionId,
        action.data,
      );
    case 'reset':
      return action.newState;
  }
}

function handleSectionAdd(
  sections: SectionData[],
  defaultFieldType?: FieldType,
) {
  return [...sections, makeEmptySection(defaultFieldType)];
}

function handleSectionRemove(sections: SectionData[], sectionId: ClientId) {
  return sections.filter((section) => section.cId !== sectionId);
}

function handleSectionUp(sections: SectionData[], sectionId: ClientId) {
  const fromIdx = sections.findIndex((section) => section.cId === sectionId);
  const toIdx = fromIdx - 1;

  return arrayMove(sections, fromIdx, toIdx);
}

function handleSectionDown(sections: SectionData[], sectionId: ClientId) {
  const fromIdx = sections.findIndex((section) => section.cId === sectionId);
  const toIdx = fromIdx + 1;

  return arrayMove(sections, fromIdx, toIdx);
}

function handleSectionAttrsChange(
  sections: SectionData[],
  sectionId: ClientId,
  attrs: Partial<Pick<SectionData, 'name' | 'description'>>,
) {
  return sections.map((section) => {
    if (section.cId !== sectionId) return section;

    return { ...section, ...attrs };
  });
}

function handleFieldAdd(
  sections: SectionData[],
  sectionId: ClientId,
  copy: boolean,
) {
  return sections.map((section) => {
    if (section.cId !== sectionId) return section;

    const newField = makeEmptyField();
    if (copy) {
      const template = section.fields[0];
      newField.label = template.label;
      newField.fieldType = template.fieldType;
      newField.max = template.max;
    }

    return {
      ...section,
      fields: [...section.fields, newField],
    };
  });
}

function handleFieldRemove(
  sections: SectionData[],
  sectionId: ClientId,
  fieldId: ClientId,
) {
  return sections.map((section) => {
    if (section.cId !== sectionId) return section;

    return {
      ...section,
      fields: section.fields.filter((field) => field.clientId !== fieldId),
    };
  });
}

function handleFieldOrderChange(
  sections: SectionData[],
  sectionId: ClientId,
  fromId: ClientId,
  toId: ClientId,
) {
  return sections.map((section) => {
    if (section.cId !== sectionId) return section;

    const fields = section.fields;
    const oldIndex = fields.findIndex((v) => v.clientId === fromId);
    const newIndex = fields.findIndex((v) => v.clientId === toId);

    return {
      ...section,
      fields: arrayMove(section.fields, oldIndex, newIndex),
    };
  });
}

function handleFieldConfigChange(
  sections: SectionData[],
  sectionId: ClientId,
  fieldId: ClientId | 'ALL',
  newConfig: FieldConfig,
) {
  return updateField(sections, sectionId, fieldId, {
    label: newConfig.label,
    fieldType: newConfig.fieldType,
    max: newConfig.max,
    tag: newConfig.tag,
    group: newConfig.group,
    removable: newConfig.removable,
    skipQuestionField: newConfig.skipQuestionField,
    skipOptionDefinition: newConfig.skipOptionDefinition,
    options:
      newConfig.fieldType === 'multi_options'
        ? [
            {
              serverId: null,
              clientId: randomInt(),
              name: '',
            },
          ]
        : undefined,
  });
}

function handleFieldDataChange(
  sections: SectionData[],
  sectionId: ClientId,
  fieldId: ClientId,
  fieldData: FieldData,
) {
  return updateField(sections, sectionId, fieldId, {
    title: fieldData.title,
    aiFillEnabled: fieldData.aiFillEnabled,
    mandatory: fieldData.mandatory,
    // options: Options are handled through their own actions, so don't add them
    // here.
  });
}

function handleOptionAdd(
  sections: SectionData[],
  sectionId: ClientId,
  fieldId: ClientId,
) {
  return sections.map((section) => {
    if (section.cId !== sectionId) return section;

    const fields = section.fields.map((field) => {
      if (field.clientId !== fieldId) return field;

      const newOption: FieldOptionData = {
        serverId: null,
        clientId: randomInt(),
        name: '',
      };

      return { ...field, options: [...field.options, newOption] };
    });

    return { ...section, fields };
  });
}

function handleOptionRemove(
  sections: SectionData[],
  sectionId: ClientId,
  fieldId: ClientId,
  optionId: ClientId,
) {
  return sections.map((section) => {
    if (section.cId !== sectionId) return section;

    const fields = section.fields.map((field) => {
      if (field.clientId !== fieldId) return field;

      return {
        ...field,
        options: field.options.filter((option) => option.clientId !== optionId),
      };
    });

    return { ...section, fields };
  });
}

function handleOptionOrderChange(
  sections: SectionData[],
  sectionId: ClientId,
  fieldId: ClientId,
  fromId: ClientId,
  toId: ClientId,
) {
  return sections.map((section) => {
    if (section.cId !== sectionId) return section;

    const fields = section.fields.map((field) => {
      if (field.clientId !== fieldId) return field;

      const options = field.options;
      const oldIndex = options.findIndex((v) => v.clientId === fromId);
      const newIndex = options.findIndex((v) => v.clientId === toId);

      return { ...field, options: arrayMove(options, oldIndex, newIndex) };
    });

    return { ...section, fields };
  });
}

function handleOptionDataChange(
  sections: SectionData[],
  sectionId: ClientId,
  fieldId: ClientId,
  optionId: ClientId,
  data: FieldOptionData,
) {
  return sections.map((section) => {
    if (section.cId !== sectionId) return section;

    const fields = section.fields.map((field) => {
      if (field.clientId !== fieldId) return field;

      const options = field.options.map((option) => {
        if (option.clientId !== optionId) return option;

        return { ...option, ...data };
      });

      return { ...field, options };
    });

    return { ...section, fields };
  });
}

function updateField(
  sections: SectionData[],
  sectionId: ClientId,
  fieldId: ClientId | 'ALL',
  obj: Partial<FieldData>,
) {
  return sections.map((section) => {
    if (section.cId !== sectionId) return section;

    const newFields = section.fields.map((field) => {
      if (fieldId != 'ALL' && field.clientId !== fieldId) return field;

      return { ...field, ...obj };
    });

    return { ...section, fields: newFields };
  });
}

export function initSections(defaultFieldType?: FieldType) {
  return [makeEmptySection(defaultFieldType)];
}

function makeEmptySection(defaultFieldType?: FieldType): SectionData {
  return {
    sId: null,
    cId: randomInt(),
    name: '',
    fields: [makeEmptyField(defaultFieldType)],
  };
}

function makeEmptyField(defaultFieldType?: FieldType): FieldData {
  return {
    serverId: null,
    clientId: randomInt(),
    title: '',
    aiFillEnabled: true,
    fieldType: defaultFieldType,
  };
}
