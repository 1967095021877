import { ApiService } from '../../../services/ApiService';
import { RecordId } from '../../../entities/RecordId';

export class SurveyShareService {
  public static create(
    applicationId: number,
    emailBody: string,
  ): Promise<RecordId> {
    return ApiService.post<RecordId>(
      `/api/candidate_experience/applications/${applicationId}/survey_emails`,
      { email_body: emailBody },
    );
  }
}
