import React from 'react';
import { SurveyPanel } from '../../../../components/survey_engine/SurveyPanel';
import { StartPanelFooter } from './StartPanelFooter';
import { IconText } from './IconText';
import { CompanyInfo } from '../../../../reference_check/entities/CompanyInfo';
import { SurveyTypes } from '../../../../entities/survey_engine/SurveyTypes';
import { defaultStartPanelFields } from './defaultStartPanelFields';
import { StartPanelFields } from '../../../../entities/survey_engine/StartPanelFields';

interface PropTypes {
  surveyStartBody: string;
  companyInfo: CompanyInfo;
  surveyDetails: SurveyTypes;
  template: string;
  onStartSurvey: () => void;
  getStartScreenFields?: (SurveyTypes) => StartPanelFields;
}

export function StartPanel(props: PropTypes) {
  const fields = props.getStartScreenFields
    ? props.getStartScreenFields(props.surveyDetails)
    : defaultStartPanelFields;

  return (
    <SurveyPanel>
      <h3>{fields.title}</h3>
      <div style={{ letterSpacing: `${props.companyInfo.font_kerning / 2}em` }}>
        <div style={{ whiteSpace: 'pre-line' }} className='mb-4'>
          {props.surveyStartBody}
        </div>
        {fields.notes.map((note, index) => (
          <IconText key={index} iconClassName={note.icon} text={note.text} />
        ))}
        <hr />
        <div className='text-center mb-4'>
          <a
            href='#start'
            onClick={props.onStartSurvey}
            className='btn btn-primary p-3 px-5'
          >
            Start Survey
          </a>
        </div>
        <StartPanelFooter text={fields.footerText} />
      </div>
    </SurveyPanel>
  );
}
