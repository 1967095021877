import { Session } from '../../../entities/training/Session';
import { User as TrainingUser } from '../../../entities/training/User';

export function isAssignable(session: Session): boolean {
  return !session.draft && !session.comingSoon;
}

export function groupUsers(
  users: TrainingUser[],
  groupSize: number,
): TrainingUser[][] {
  if (users == null) {
    return [];
  }

  const finalArray = [];

  for (let i = 0; i < users.length; i += groupSize) {
    const groupArray = users.slice(i, i + groupSize);

    finalArray.push(groupArray);
  }

  return finalArray;
}
