import React from 'react';
import {
  CandidateActionModal,
  CandidateActionProps,
} from '../../../CandidateActionModals';
import { ListCandidate } from '../../../../../../../../entities/applicant_tracking/ListCandidate';
import { Alert, AlertType } from '../../../../../../../../components/Alert';

interface PropTypes {
  deleteModalActive: boolean;
  candidate: ListCandidate;
  onClose: () => void;
}

export function DeleteCandidate(props: PropTypes) {
  const [errorAlertActive, setErrorAlertActive] = React.useState(false);

  const handleDeleteAction = (action: CandidateActionProps) => {
    if (action?.state == 'Success') {
      window.location.reload();
    } else if (action?.state == 'Error') {
      setErrorAlertActive(true);
    }
    props.onClose();
  };

  return (
    <>
      {errorAlertActive && (
        <Alert
          type={AlertType.Danger}
          autoClearTimeout={4000}
          clearable={true}
          onClose={() => setErrorAlertActive(false)}
        >
          <span>
            The candidate{' '}
            <span className='fw-bold'>{`${props.candidate.firstName} ${props.candidate.lastName}`}</span>{' '}
            could not be deleted.
          </span>
        </Alert>
      )}
      {props.deleteModalActive && (
        <CandidateActionModal
          candidateId={props.candidate.id}
          candidateName={`${props.candidate.firstName} ${props.candidate.lastName}`}
          isOpen={props.candidate !== null}
          action={'Delete'}
          onClose={handleDeleteAction}
        />
      )}
    </>
  );
}
