import { ApiService } from '../../../services/ApiService';
import { SurveyQuestionAnswer } from '../../../entities/survey_engine/SurveyQuestionAnswer';
import { Survey } from '../../../entities/survey_engine/Survey';

export class SurveyQuestionAnswerService {
  public static answer(
    surveyId: number,
    answer: SurveyQuestionAnswer,
  ): Promise<Survey> {
    return ApiService.post<Survey>(
      `/api/survey_engine/surveys/${surveyId}/survey_questions/${answer.question_id}/survey_question_answers`,
      { answer },
    );
  }
}
