import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { getRedirectUriQueryParam } from '../../utils/url';

export interface PropTypes {
  url?: string;
}

function handleBack(url?: string) {
  if (url) {
    window.location.href = url;
    return;
  }

  history.back();
}

export function BackArrow(props: PropTypes) {
  return (
    <a
      onClick={() => handleBack(getRedirectUriQueryParam() ?? props.url)}
      className={classNames('h1', 'mb-0', styles.back)}
    >
      <i
        className={classNames('bi', 'bi-arrow-left-short', styles['back-icon'])}
      />
    </a>
  );
}
