import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface PropTypes {
  className: string;
  imgSrc?: string;
  iconClassName?: string;
}

export function IntegrationIcon(props: PropTypes) {
  if (!props.imgSrc && !props.iconClassName) return null;

  return (
    <div
      className={classNames(
        styles['wrapper'],
        `me-2 rounded-circle d-flex align-items-center justify-content-center ${props.className}`,
      )}
    >
      {props.imgSrc ? (
        <img src={props.imgSrc} className={classNames(styles['content'])} />
      ) : (
        <i className={classNames(styles['content'], props.iconClassName)}></i>
      )}
    </div>
  );
}
