export function waitForElement(
  selector: string,
  timeoutMs: number,
  searchSpace?: Element,
): Promise<Element> {
  return new Promise((resolve) => {
    const timer = setTimeout(function () {
      observer.disconnect();
    }, timeoutMs);

    const searchDocumentSpace = searchSpace || document;
    const foundElement = searchDocumentSpace.querySelector(selector);
    if (foundElement) {
      return resolve(foundElement);
    }

    const observer = new MutationObserver(() => {
      const foundObservableElement =
        searchDocumentSpace.querySelector(selector);
      if (foundObservableElement) {
        clearTimeout(timer);
        resolve(foundObservableElement);
        observer.disconnect();
      }
    });

    observer.observe(searchDocumentSpace, {
      subtree: true,
      childList: true,
    });
  });
}
