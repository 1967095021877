import { CandidateExperienceSurvey } from '../../entities/candidate_experience/CandidateExperienceSurvey';
import { Survey } from '../../entities/survey_engine/Survey';
import { SurveySection } from '../../entities/survey_engine/SurveySection';
import { buildInterviewerSurveySection } from '../candidate_experience/buildInterviewerSurveySection';

function wasSectionAlreadyPushed(sections, section) {
  const sectionsIds = sections.map((s) => s.id);

  return sectionsIds.includes(section.id);
}

export function getSurveySections(survey: Survey): SurveySection[] {
  if (survey === undefined) return [];

  const sections = survey.sections;

  if (survey.target === 'interviewer') {
    const surveyDetails = survey.surveyDetails as CandidateExperienceSurvey;
    const interviewerSurveySection = buildInterviewerSurveySection(
      surveyDetails.targetInfo,
    );
    if (!wasSectionAlreadyPushed(sections, interviewerSurveySection)) {
      sections.push(interviewerSurveySection);
    }
  }

  return sections;
}
