import React from 'react';
import { IconButton } from '../../../../components/IconButton';

interface PropTypes {
  setDisableEditEmailBody: (disableEditEmailBody: boolean) => void;
  disableEditEmailBody: boolean;
}

export function EditButton(props: PropTypes) {
  return (
    <IconButton
      buttonClass='d-flex justify-content-end'
      size='sm'
      iconName='bi-pencil'
      iconClass={!props.disableEditEmailBody && 'text-muted'}
      textClass={!props.disableEditEmailBody && 'text-muted'}
      buttonText='Edit'
      onClick={() => props.setDisableEditEmailBody(!props.disableEditEmailBody)}
    />
  );
}
