import React, { useState } from 'react';
import { ModalBody } from './ModalBody';
import { CloseableModal } from '../../CloseableModal';
import { Application } from '../../../entities/Application';
import { Alert, AlertObject, AlertType } from '../../Alert';
import { ApplicationRating } from '../../../entities/v1/applicant_tracking/ApplicationRating';

interface PropTypes {
  application: Application;
  isOpen: boolean;
  applicationRating?: ApplicationRating;
  onClose: () => void;
}

export function CandidateRatingModal(props: PropTypes) {
  const [alertObject, setAlertObject] = useState<AlertObject>(null);

  const onClose = (succeeded?: boolean) => {
    if (succeeded) {
      setAlertObject({
        message: (
          <>
            The feedback about <b>{props.application.candidate.name}</b> was
            successfully {props.applicationRating ? 'updated!' : 'added!'}
          </>
        ),
        type: AlertType.Success,
      });
    }
    props.onClose();
  };

  return (
    <>
      {alertObject && (
        <Alert
          type={alertObject.type}
          clearable={true}
          autoClearTimeout={4000}
          onClose={() => {
            setAlertObject(null);
          }}
        >
          {alertObject.message}
        </Alert>
      )}
      <CloseableModal
        className='modal-dialog-centered'
        onClose={props.onClose}
        isOpen={props.isOpen}
        size='md'
        closeDisabled={false}
        keyboard={true}
        backdrop={true}
        headerTitle={`${
          props.applicationRating ? 'Edit' : 'Add'
        } Candidate Rating`}
        bodyChildren={<ModalBody {...props} onClose={onClose} />}
      />
    </>
  );
}
