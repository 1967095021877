import { SelectOption } from '../../components/Select';
import { getQueryParam, QueryParam } from '../../utils/url';

export function parseSingleValueSelect(
  queryParamName: string,
): SelectOption | null {
  const value = getQueryParam(`${queryParamName}_id`);
  const label = getQueryParam(`${queryParamName}_name`);

  if (value != null) {
    return { label, value };
  }

  return null;
}

export function mapSingleValueSelectToQueryParam(
  key: string,
  option?: SelectOption,
): QueryParam[] {
  if (option == null) return [];

  return [
    {
      key: `${key}_id`,
      value: option.value,
    },
    {
      key: `${key}_name`,
      value: option.label,
    },
  ];
}
