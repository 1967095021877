import classNames from 'classnames';
import React from 'react';
import { OptionProps, components } from 'react-select';
import { TruncateText } from '../../../../../../components/TruncateText';
import { SelectOption } from '../../../../../../components/Select';

export function Option(props: OptionProps) {
  const optionData: SelectOption = props.data as SelectOption;

  return (
    <components.Option {...props}>
      <div className='d-flex'>
        <div
          className={classNames(
            'd-flex flex-nowrap',
            optionData.classNames || 'justify-content-between',
          )}
        >
          <TruncateText
            text={optionData.label}
            ellipsize={true}
            tooltip={true}
          />
          {optionData.subLabel}
        </div>
      </div>
    </components.Option>
  );
}
