import React from 'react';
import { Filters } from '../../../../components/candidate_experience/Filters';
import {
  FilterStates,
  OnChangeFilterStatesCallback,
} from '../../../../components/candidate_experience/Filters/filterState';
import { FollowUpQuestionsAnalytics } from './FollowUpQuestionsAnalytics';

interface PropTypes {
  filterStates: FilterStates;
  onApplyFilter: OnChangeFilterStatesCallback;
  organizationName: string;
  moreFiltersShown: boolean;
  pulseStandardNpsCalculation: boolean;
}

export function FollowUpQuestionsTab(props: PropTypes) {
  return (
    <>
      <Filters
        filterStates={props.filterStates}
        onApplyFilter={props.onApplyFilter}
        moreFiltersShown={props.moreFiltersShown}
        hideJob={false}
        hideMoreFiltersButton={false}
        candidateDemographicQuestionsEnabled={false}
        hideBenchmark={true}
        className='mb-4'
      />
      <FollowUpQuestionsAnalytics
        filterStates={props.filterStates}
        organizationName={props.organizationName}
        pulseStandardNpsCalculation={props.pulseStandardNpsCalculation}
      />
    </>
  );
}
