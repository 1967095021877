import React from 'react';
import { Dropdown } from '../../../components/Dropdown';
import { IconSpan } from '../../../components/IconSpan';
import { PropTypes as ActionPropTypes } from '../../../components/Dropdown/DropdownMenu/DropdownAction';
import { PlanTemplateService } from '../../../services/applicant_tracking/PlanTemplateService';
import { appendRedirectUri } from '../../../utils/url';

const ARCHIVE_SUCCESS_PATH =
  '/applicant_tracking/plan_templates/archive_success';
const UNARCHIVE_SUCCESS_PATH =
  '/applicant_tracking/plan_templates/unarchive_success';
const ARCHIVE_ERROR_PATH = '/applicant_tracking/plan_templates/archive_error';
const UNARCHIVE_ERROR_PATH =
  '/applicant_tracking/plan_templates/unarchive_error';

interface PropTypes {
  id: number;
  viewPlanTemplateUrl: string;
  editEnabled: boolean;
  editPlanTemplateUrl: string;
  globalPipeline: boolean;
  archived: boolean;
}

async function archiveAction(id: number) {
  await PlanTemplateService.archive(id)
    .then(() => (window.location.href = ARCHIVE_SUCCESS_PATH))
    .catch(() => (window.location.href = ARCHIVE_ERROR_PATH));
}

async function unArchiveAction(id: number) {
  await PlanTemplateService.unArchive(id)
    .then(() => (window.location.href = UNARCHIVE_SUCCESS_PATH))
    .catch(() => (window.location.href = UNARCHIVE_ERROR_PATH));
}

function viewPipeline(props: PropTypes): ActionPropTypes {
  return {
    action: () =>
      (window.location.href = appendRedirectUri(props.viewPlanTemplateUrl)),
    buttonChild: (
      <IconSpan
        spanText={'View'}
        icon={{ name: 'bi-eye', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function editPipeline(props: PropTypes): ActionPropTypes {
  const isDisabled = props.globalPipeline;
  const editUrl = appendRedirectUri(
    props.editPlanTemplateUrl,
    window.location.pathname,
  );

  return {
    action: () => (window.location.href = appendRedirectUri(editUrl)),
    buttonChild: (
      <IconSpan
        spanText={'Edit'}
        icon={{ name: 'bi-pencil', className: 'fs-4' }}
        className={isDisabled ? 'text-blue-200' : 'text-blue'}
      />
    ),
    isDisabled: isDisabled,
  };
}

function archivePipeline(props: PropTypes): ActionPropTypes {
  const isDisabled = props.globalPipeline;

  return {
    action: () => archiveAction(props.id),
    buttonChild: (
      <IconSpan
        spanText={'Archive'}
        icon={{ name: 'bi-archive', className: 'fs-4' }}
        className={isDisabled ? 'text-blue-200' : 'text-blue'}
      />
    ),
    isDisabled: isDisabled,
  };
}

function unArchivePipeline(props: PropTypes): ActionPropTypes {
  const isDisabled = props.globalPipeline;

  return {
    action: () => unArchiveAction(props.id),
    buttonChild: (
      <IconSpan
        spanText={'Unarchive'}
        icon={{ name: 'bi-archive', className: 'fs-4' }}
        className={isDisabled ? 'text-blue-200' : 'text-blue'}
      />
    ),
    isDisabled: isDisabled,
  };
}

export default function RowActionsDropdown(props: PropTypes) {
  const menuActions = [viewPipeline(props)];

  if (props.editEnabled) {
    menuActions.push(editPipeline(props));
    props.archived
      ? menuActions.push(unArchivePipeline(props))
      : menuActions.push(archivePipeline(props));
  }

  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots',
        className: 'fs-4 text-info',
      }}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={menuActions}
    />
  );
}
