export function getNounWithPossessiveCase(noun: string) {
  return noun + (noun.endsWith('s') ? "'" : "'s");
}

export function getLabelForCount(label: string, count: number) {
  if (count === 0) {
    return `No ${label}`;
  }

  return `${count} ${label}${count !== 1 ? 's' : ''}`;
}
