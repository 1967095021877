import { Controller } from 'stimulus';

/**
 * Hides the tooltip on an element if the element is not truncated.
 */
export default class extends Controller {
  connect(): void {
    const child = this.element as HTMLElement;
    const target = child.dataset.tooltipTarget
      ? (document.querySelector(child.dataset.tooltipTarget) as HTMLElement)
      : child;

    if (target && !isTruncated(target)) {
      child.dataset.bsOriginalTitle = '';
    }
  }
}

function isTruncated(element: HTMLElement) {
  return element.offsetWidth < element.scrollWidth;
}
