import React from 'react';
import { Button } from 'reactstrap';
import { SummaryLengthOption } from '..';
import styles from './styles.module.scss';

interface PropTypes {
  selectedLength: SummaryLengthOption;
  onChange: (selectedLength: SummaryLengthOption) => void;
  isAiGenerating: boolean;
}

export function SummaryLength(props: PropTypes) {
  return (
    <>
      <div className='fw-semibold fs-5'>Summary Length</div>
      <div className='d-flex flex-row'>
        <Button
          size='sm'
          color='borderless'
          className={`${styles.summaryLengthButtons} ${
            props.selectedLength === 'short' ? styles.active : ''
          }`}
          onClick={() => props.onChange('short')}
          disabled={props.isAiGenerating}
        >
          Short
        </Button>
        <Button
          size='sm'
          color='borderless'
          className={`${styles.summaryLengthButtons} ${
            props.selectedLength === 'medium' ? styles.active : ''
          }`}
          onClick={() => props.onChange('medium')}
          disabled={props.isAiGenerating}
        >
          Medium
        </Button>
        <Button
          size='sm'
          color='borderless'
          className={`${styles.summaryLengthButtons} ${
            props.selectedLength === 'long' ? styles.active : ''
          }`}
          onClick={() => props.onChange('long')}
          disabled={props.isAiGenerating}
        >
          Long
        </Button>
      </div>
    </>
  );
}
