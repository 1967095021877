import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { BooleanInput } from '../../../../../../../../components/BooleanInput';
import { SurveyQuestion } from '../../../../../../../../entities/survey_engine/SurveyQuestion';
import { QuestionAnswer } from '../../../../../../../../services/applicant_tracking/CareersService/SubmitApplicationDto';

interface PropTypes {
  question: SurveyQuestion;
  control?: Control<any, any>;
  disabled?: boolean;
}

export function CustomBooleanQuestionInput(props: PropTypes) {
  function handleChange(onChange: (...event: any[]) => void, v: boolean) {
    const answer: QuestionAnswer = {
      question_id: props.question.id,
      fields: [
        {
          field_id: props.question.fields[0].id,
          value: v.toString(),
        },
      ],
    };
    onChange(answer);
  }

  function parseValue(value: QuestionAnswer) {
    if (value == null) return undefined;

    return value?.fields?.[0]?.value === 'true';
  }

  return (
    <Controller
      name={`customQuestionAnswers.${props.question.id}`}
      control={props.control}
      rules={{
        validate: props.question.mandatory ? (x) => x != null : undefined,
      }}
      render={({ field }) => (
        <BooleanInput
          name={field.name}
          value={parseValue(field.value)}
          onChange={(v) => {
            handleChange(field.onChange, v);
          }}
          trueLabel='Yes'
          falseLabel='No'
          disabled={props.disabled}
          className='p-0 fw-normal'
        />
      )}
    />
  );
}
