import React from 'react';
import { Badge } from 'reactstrap';
import { BackgroundCheck } from '../../../../entities/BackgroundCheck';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import classNames from 'classnames';
import moment from 'moment';
import { statusToColor, statusToLabel } from './utils';

interface PropTypes {
  backgroundCheck: BackgroundCheck;
}

export function BackgroundCheckCard(props: PropTypes) {
  const reportUrlEnabled = ['Completed', 'Returned', 'COMPLETE'].includes(
    props.backgroundCheck.status,
  );

  return (
    <DeprecatedPanel additionalInnerContainerClassNames='p-4' className='mb-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <div className='d-flex gap-2 mb-2 align-items-center'>
            <h4 className='mb-0'>{props.backgroundCheck.name}</h4>
            <Badge
              pill
              color={statusToColor(props.backgroundCheck.status)}
              className='me-1 text-primary fw-normal'
            >
              {statusToLabel(props.backgroundCheck.status)}
            </Badge>
          </div>
          <p className='label text-dark-200 mb-0'>
            Requested at{' '}
            {moment(props.backgroundCheck.created_at).format(
              'MMM DD, YYYY kk:mm',
            )}
          </p>
        </div>
        <a
          className={classNames('nav-link', {
            active: reportUrlEnabled,
            'text-blue-200': !reportUrlEnabled,
            'text-blue': reportUrlEnabled,
          })}
          download
          href={reportUrlEnabled ? props.backgroundCheck.report_url : null}
        >
          Download Report
        </a>
      </div>
    </DeprecatedPanel>
  );
}
