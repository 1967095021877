import { UseFormRegister } from 'react-hook-form';
import { FormValues } from '..';
import React from 'react';
import { Col } from 'reactstrap';
import { InputFormField } from '../../../../components/InputFormField';

interface PropTypes {
  register: UseFormRegister<FormValues>;
  currentUserFirstName?: string;
  currentUserLastName?: string;
  isRequired: boolean;
}

export function NameAndLastNameRow(props: PropTypes) {
  return (
    <div className='row mb-3'>
      <Col sm={6}>
        <InputFormField
          type={'text'}
          registerReturn={props.register('firstName', {
            required: props.isRequired,
          })}
          fieldName={'First Name'}
          placeholder={props.currentUserFirstName || 'Insert first name'}
          isRequired={props.isRequired}
        />
      </Col>
      <Col sm={6}>
        <InputFormField
          type={'text'}
          registerReturn={props.register('lastName', {
            required: props.isRequired,
          })}
          fieldName={'Last Name'}
          placeholder={props.currentUserLastName || 'Insert last name'}
          isRequired={props.isRequired}
        />
      </Col>
    </div>
  );
}
