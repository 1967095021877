import { ApiService } from '../../ApiService';
import { HiringMemberRole } from '../../../entities/applicant_tracking/JobHiringMember';

export class JobHiringMemberService {
  public static listRoles(): Promise<HiringMemberRole[]> {
    return ApiService.get<HiringMemberRole[]>(
      `/api/applicant_tracking/job_hiring_members/roles`,
    );
  }
}
