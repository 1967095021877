import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { NewPasswordForm } from './NewPasswordForm';
import { Alert, AlertType } from '../../../components/Alert';
import { TwoFactorAuthenticationCard } from './TwoFactorAuthenticationCard';

interface PropTypes {
  allowPassword: boolean;
  totpEnabled: boolean;
  supportEmail: string;
}

export default function UserSecurityPage(props: PropTypes) {
  const queryParams = new URLSearchParams(location.search);
  const successMessage = queryParams.get('success');

  const [alertActive, setAlertActive] = useState<boolean>(!!successMessage);

  return (
    <>
      {alertActive && (
        <Alert
          type={AlertType.Success}
          clearable={true}
          onClose={() => setAlertActive(false)}
          autoClearTimeout={4000}
        >
          {successMessage}
        </Alert>
      )}
      <Row className='row mb-4'>
        <h1 className='mb-0'>Security</h1>
      </Row>
      <NewPasswordForm
        allowPassword={props.allowPassword}
        supportEmail={props.supportEmail}
      />
      <TwoFactorAuthenticationCard totpEnabled={props.totpEnabled} />
    </>
  );
}
