import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppPlugin } from '..';
import { ReferenceCheckApp } from '../../reference_check/containers/ReferenceCheckApp';

export class ReferenceCheckAppPlugin implements AppPlugin {
  public load(): void {
    const el = document.getElementById('referenceCheckApp');

    if (el) this.installContainer(el);
  }

  private installContainer(el: HTMLElement): void {
    const props = JSON.parse(el.dataset['props'] || '{}');

    createRoot(el).render(React.createElement(ReferenceCheckApp, props));
  }
}
