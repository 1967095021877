import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Panel } from '../../../components/Panel';
import { SurveyRequestReferralInfo } from '../../../entities/SurveyRequestReferralInfo';
import { formatLongRelationship } from '../../../../utils/formatRelationship';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Chip, ChipColor } from '../../../../components/Chip';
import { SurveyInfo } from '../../../entities/SurveyInfo';

interface PropTypes {
  referral: SurveyRequestReferralInfo;
  index: number;
  onSelectReference: (
    referral: SurveyRequestReferralInfo,
    index?: number,
  ) => void;
}

function StatusChip(props: { survey?: SurveyInfo }) {
  if (props.survey == null) {
    return null;
  }

  if (props.survey.status === 'pending') {
    return (
      <Chip className='fw-semibold' color={ChipColor.Orange}>
        Pending
      </Chip>
    );
  }

  return (
    <Chip className='fw-semibold' color={ChipColor.Success}>
      Completed
    </Chip>
  );
}

export function CandidateReferralRow({
  referral,
  index,
  onSelectReference,
}: PropTypes) {
  const survey = referral.surveys[0];

  return (
    <Panel
      className={classNames('mb-3 shadow-none border border-primary rounded-2')}
    >
      <Row
        className='align-items-center'
        data-testid={`Reference-${index + 1}`}
      >
        <Col xs='8'>
          <div className='d-flex align-items-center gap-2'>
            <h2 className='mb-0'>
              {survey === undefined
                ? `Reference ${index + 1}`
                : `${survey.first_name} ${survey.last_name}`}
              , {formatLongRelationship(referral.relationship)}
            </h2>
            <StatusChip survey={survey} />
          </div>
        </Col>
        <Col xs='4' className='text-end'>
          {survey === undefined ? (
            <Button
              color='primary'
              onClick={() => onSelectReference(referral, index)}
            >
              Add Reference
            </Button>
          ) : (
            <Row className='align-items-center'>
              <Col
                className={classNames(
                  'd-flex align-items-center justify-content-end',
                  styles.buttonPadding,
                )}
              >
                <Button
                  className={classNames(
                    'btn-link',
                    'text-info',
                    'border-0',
                    'd-flex',
                    'align-items-center',
                    'justify-content-center',
                    'gap-2',
                    styles.edit,
                  )}
                  disabled={survey.status === 'completed'}
                  onClick={() => onSelectReference(referral, index)}
                >
                  <i
                    className={classNames(
                      'bi bi-pencil d-inline-block',
                      styles.bi,
                    )}
                  />
                  Edit
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Panel>
  );
}
