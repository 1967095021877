import { Col, Row } from 'reactstrap';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import React from 'react';
import styles from './styles.module.scss';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import classNames from 'classnames';

interface PropTypes {
  neutralPercentage: number;
  detractorPercentage: number;
  neutralCount: number;
  detractorCount: number;
  responsesNumber: number;
}

export function FollowUpResponsesCircleChart(props: PropTypes) {
  return (
    <Row>
      <Col xs={7} className='px-4 d-flex flex-column'>
        <div className='h-100 d-flex align-items-center'>
          <CircularProgressbarWithChildren
            value={props.neutralPercentage}
            styles={buildStyles({
              pathColor: stylesheetExports.gray500,
              trailColor: stylesheetExports.red500,
            })}
          >
            <div className='text-center p-2'>
              <h1 className={classNames(styles.circleChartNumber)}>
                {props.responsesNumber}
              </h1>
              <h4>{'Responses'}</h4>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </Col>
      <Col xs={5} className='d-flex flex-column'>
        <Row>
          <div className='text-center my-4'>
            <h1
              className={classNames(styles.circleChartNumber)}
              style={{
                color: stylesheetExports.gray500,
              }}
            >
              {props.neutralPercentage.toFixed(0)}%
            </h1>
            <p className={'fs-5'}>
              <b>{props.neutralCount}</b>
              {' Neutrals'}
            </p>
          </div>
        </Row>
        <Row>
          <div className='text-center my-4'>
            <h1
              className={classNames(styles.circleChartNumber)}
              style={{
                color: stylesheetExports.red500,
              }}
            >
              {props.detractorPercentage.toFixed(0)}%
            </h1>
            <p className={'fs-5'}>
              <b>{props.detractorCount}</b>
              {' Detractors'}
            </p>
          </div>
        </Row>
      </Col>
    </Row>
  );
}
