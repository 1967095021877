import { SelectOption } from '../../components/Select';
import { QueryParam } from '../../utils/url';
import {
  mapSingleValueSelectToQueryParam,
  parseSingleValueSelect,
} from './SingleValueSelectFilter';

export const JOB_STAGE_QUERY_PARAM = 'job_stage';

export function parseJobStage() {
  return parseSingleValueSelect(JOB_STAGE_QUERY_PARAM);
}

export function mapJobStageToQueryParam(
  option?: SelectOption,
): QueryParam[] | null {
  return mapSingleValueSelectToQueryParam(JOB_STAGE_QUERY_PARAM, option);
}
