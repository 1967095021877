import React, { useState } from 'react';
import classNames from 'classnames';
import { InterviewMarkerList } from '../../../../components/Interview/InterviewMarkerList';
import styles from './styles.module.scss';
import { Card } from 'reactstrap';
import { Interview } from '../../../../entities/Interview';
import { InterviewTimelineHeader } from '../../../InterviewPage/InterviewTimelineTab/InterviewTimelineHeader';
import { HighlightType } from '../../../InterviewPage/Entities/HighlightType';
import { useAutomaticQuestions } from '../../../../queries/v1/interview_intelligence/useAutomaticQuestions';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import {
  AutomaticQuestion,
  AutomaticQuestionList,
} from '../../../../entities/v1/interview_intelligence/AutomaticQuestion';
import { QueryObserverResult } from 'react-query';

interface PropTypes {
  interview: Interview;
  playTimestamp: number;
  onTimestampChange: (timestamp: number) => void;
}

interface ScorecardTimelineUIPropTypes {
  interview: Interview;
  automaticQuestions?: AutomaticQuestion[];
  refetchAutomaticQuestions: (
    options?: unknown,
  ) => Promise<QueryObserverResult<AutomaticQuestionList, unknown>>;
  playTimestamp: number;
  onTimestampChange: (timestamp: number) => void;
}

export function ScorecardTimeline(props: PropTypes) {
  const {
    isLoading: isLoadingAutomaticQuestions,
    data: automaticQuestions,
    refetch,
  } = useAutomaticQuestions(props.interview.id, [
    'participant',
    'automatic_notes',
    'replies',
    'replies.user',
  ]);

  return isLoadingAutomaticQuestions ? (
    <div style={{ minHeight: '700px' }}>
      <LoadingSpinner />
    </div>
  ) : (
    <ScorecardTimelineUI
      {...props}
      automaticQuestions={automaticQuestions?.automaticQuestions}
      refetchAutomaticQuestions={refetch}
    />
  );
}

function ScorecardTimelineUI(props: ScorecardTimelineUIPropTypes) {
  const [selectedHighlightType, setSelectedHighlightType] = useState(
    'all' as HighlightType,
  );

  return (
    <>
      <InterviewTimelineHeader
        selectedHighlightType={selectedHighlightType}
        onSelectedHighlightType={setSelectedHighlightType}
      />
      <Card className={classNames('p-3', 'h-100', 'fs-5', styles.container)}>
        <InterviewMarkerList
          selectedHighlightType={selectedHighlightType}
          interview={props.interview}
          playTimestamp={props.playTimestamp}
          automaticQuestions={props.automaticQuestions}
          refetchAutomaticQuestions={props.refetchAutomaticQuestions}
          onSelectTimestamp={props.onTimestampChange}
          showResponses={false}
          showNameAndOffset={true}
          showContextQuestion={true}
        />
      </Card>
    </>
  );
}
