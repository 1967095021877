import { Condition } from '..';
import { SelectOption } from '../../../../../components/Select';

export function findMandatoryCondition(
  mandatoryConditions: Condition[],
  value: string,
  label: string,
  available: SelectOption[],
) {
  mandatoryConditions.push({
    type: { value: value, label: label },
    available: available,
    mandatory: true,
    singleSelected: null,
    multiSelected: [],
    invert: { value: 'false', label: 'Includes' },
  });
}
