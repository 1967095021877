import React, { useState, useEffect } from 'react';
import { CardTitle, Table } from 'reactstrap';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { TitledPanel } from '../TitledPanel';
import { TopInterviewers } from '../../../../../entities/applicant_tracking/analytics/TopInterviewers';
import { TopInterviewersService } from '../../../../../services/applicant_tracking/analytics/TopInterviewersService';
import { FilterState } from '../../Filters';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { TruncateText } from '../../../../../components/TruncateText';
import { fullName } from '../../../../../utils/name';
import { truncateNumber } from '../../../../../utils/number';
import { WidgetEmptyStateContent } from '../../../../survey_engine/WidgetEmptyStateContent';

interface PropTypes {
  filterState?: FilterState;
  exportToPDF?: boolean;
  height: string;
}

export function TopInterviewersWidget(props: PropTypes) {
  return (
    <TitledPanel
      title={<Title exportToPDF={props.exportToPDF} />}
      height={props.height}
    >
      <LazyContent filterState={props.filterState} />
    </TitledPanel>
  );
}

function Title(props: { exportToPDF?: boolean }) {
  return (
    <div className='hstack gap-2'>
      <CardTitle>Top Interviewers</CardTitle>
      {props.exportToPDF !== true && (
        <InfoTooltip>
          Number of interviews and interview approval rate for the top 5
          interviewers.
        </InfoTooltip>
      )}
    </div>
  );
}

function LazyContent(props: { filterState: FilterState }) {
  const [data, setData] = useState<TopInterviewers>(null);

  useEffect(() => {
    setData(null);
    TopInterviewersService.show(props.filterState).then((topInterviewers) =>
      setData(topInterviewers),
    );
  }, [props.filterState]);

  if (!data) return <LoadingSpinner />;

  if (data.topInterviewers.length === 0) return <WidgetEmptyStateContent />;

  return <Content data={data} />;
}

function Content(props: { data: TopInterviewers }) {
  return <EntryTable data={props.data} />;
}

function EntryTable(props: { data: TopInterviewers }) {
  return (
    <Table
      borderless
      size='sm'
      className='fs-6 mb-0 align-self-start table-fixed'
    >
      <thead className='text-dark-200'>
        <tr>
          <th className='fw-normal fs-6 col-4'>Interviewers</th>
          <th className='fw-normal fs-6 text-center'>Interviews</th>
          <th className='fw-normal fs-6 text-center'>Approved</th>
        </tr>
      </thead>
      <tbody className='text-primary'>
        {props.data.topInterviewers.map((topInterviewer, index) => (
          <tr key={index}>
            <td className='fw-semibold'>
              <TruncateText
                text={fullName([
                  topInterviewer.firstName,
                  topInterviewer.lastName,
                ])}
                tooltip={true}
              />
            </td>
            <td className='text-center'>
              <Chip color={ChipColor.Gray}>
                {topInterviewer.totalInterviews}
              </Chip>
            </td>
            <td className='text-center'>
              <Chip color={ChipColor.Gray}>
                {truncateNumber(topInterviewer.approvalRate * 100)}%
              </Chip>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
