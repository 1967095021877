import React from 'react';

export function ReferralInputHeader() {
  return (
    <div className='mb-4'>
      <div className='d-flex'>
        <h3 className='mb-0'>Referral Information</h3>
      </div>
      <small className='text-muted'>
        Fill-in with your referral information.
      </small>
    </div>
  );
}
