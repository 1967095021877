import React from 'react';
import { IconButton } from '../../../../components/IconButton';
import { Icon } from '../../../../components/Icon';
import { ColumnConfig } from '../Field';
import { TypeHeader } from './TypeHeader';
import { TextHeader } from './TextHeader';
import { MandatoryHeader } from './MandatoryHeader';

export interface PropTypes {
  singleTypeSections: boolean;
  readOnly?: boolean;
  columns: ColumnConfig[];
}

export function Headers(props: PropTypes) {
  return (
    <div className='row'>
      <div className='col-auto'>
        {/* Invisible, just for sizing. */}
        <Icon name='bi-grip-vertical' className='invisible' />
      </div>
      {props.columns.map((column, i) => (
        <ColumnSwitch
          key={i}
          singleTypeSections={props.singleTypeSections}
          readOnly={props.readOnly}
          column={column}
        />
      ))}
      <div className='col-auto'>
        {/* Invisible, just for sizing. */}
        <IconButton
          color='borderless'
          size='sm'
          buttonClass='invisible'
          iconName='bi-trash'
        />
      </div>
    </div>
  );
}

interface ColumnSwitchPropTypes {
  singleTypeSections: boolean;
  readOnly?: boolean;
  column: ColumnConfig;
}

function ColumnSwitch(props: ColumnSwitchPropTypes) {
  switch (props.column.fieldName) {
    case 'type':
      return <TypeHeader {...props} />;
    case 'text':
      return <TextHeader {...props} />;
    case 'mandatory':
      return <MandatoryHeader {...props} />;
  }
}
