import React from 'react';
import { ApplicationFormTemplate } from '../../../../../entities/applicant_tracking/ApplicationFormTemplate';
import { SurveySection } from '../../../../../entities/survey_engine/SurveySection';
import { ApplicationFormSection } from './ApplicationFormSection';
import { ApplicationFormApplyButton } from './ApplicationFormApplyButton';
import { ApplicationFormCandidateConsent } from './ApplicationFormCandidateConsent';
import {
  Control,
  FieldErrors,
  FormState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { Source } from '../../../../../entities/applicant_tracking/Source';
import { PreviewJobPost } from '../../../../../entities/applicant_tracking/PreviewJobPost';
import { SubmitApplicationDto } from '../../../../../services/applicant_tracking/CareersService/SubmitApplicationDto';
import { SingleAttachmentInputFormField } from '../../../../../components/SingleAttachmentInputFormField';
import { ResumeParserInputBody } from '../../../../../components/SingleAttachmentInputFormField/ResumeParserInputBody';

interface PropTypes {
  applicationFormTemplate?: ApplicationFormTemplate;
  sources: Source[];
  previewMode: boolean;
  previewJobPost: PreviewJobPost;
  formState: FormState<any>;
  applicationFormTemplateConsent?: boolean;
  candidateConsentHtml?: string;
  formRegister: UseFormRegister<any>;
  formControl?: Control<any, any>;
  formWatch: UseFormWatch<any>;
  formStateValid: boolean;
  errors: FieldErrors;
  getValues?: UseFormGetValues<any>;
  setValue?: UseFormSetValue<any>;
  formResetField: (controllerName: string) => void;
  reValidate: UseFormTrigger<any>;
  disableSource: boolean;
  enableApplicationFormAutofill: boolean;
}

export function ApplicationFormTab(props: PropTypes) {
  if (props.applicationFormTemplate == null) return null;

  const consentEnabled: boolean =
    props.applicationFormTemplateConsent ??
    props.applicationFormTemplate.candidateConsentEnabled;

  return (
    <div className='mb-5'>
      {props.enableApplicationFormAutofill && (
        <div className='pb-4'>
          <SingleAttachmentInputFormField<SubmitApplicationDto>
            getValues={props.getValues}
            setValue={props.setValue}
            formRegister={props.formRegister}
            headerClassNames='fw-semibold'
            controllerContentName='parserResumeContent'
            controllerFileName='parserResumeFilename'
            fieldName={'Resume / Curriculum Vitae'}
            hideHeader={true}
            allowedFormats={['.pdf', '.doc', '.docx']}
            maxFileSizeMb={10}
            isRequired={false}
            disabled={false}
            Body={ResumeParserInputBody}
          />
        </div>
      )}

      {props.applicationFormTemplate.template.sections.map(
        (section: SurveySection) => (
          <ApplicationFormSection
            key={section.id}
            section={section}
            previewMode={props.previewMode}
            previewJobPost={props.previewJobPost}
            formState={props.formState}
            sources={props.sources}
            formRegister={props.formRegister}
            getValues={props.getValues}
            setValue={props.setValue}
            control={props.formControl}
            formWatch={props.formWatch}
            formResetField={props.formResetField}
            disableSource={props.disableSource}
            errors={props.errors}
          />
        ),
      )}

      <ApplicationFormCandidateConsent
        candidateConsentEnabled={consentEnabled}
        previewMode={props.previewMode}
        candidateConsentHtml={props.candidateConsentHtml}
        formRegister={props.formRegister}
        reValidate={props.reValidate}
      />

      <ApplicationFormApplyButton
        previewMode={props.previewMode}
        disabled={!props.formStateValid}
      />
    </div>
  );
}
