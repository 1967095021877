import { Interview } from '../../../../../entities/Interview';
import { ActionPoint } from '../../../../../entities/ActionPoint';
import { EmptyTab } from '../../../../../components/EmptyTab';
import UndrawLost from '../../../../../images/undraw_empty_state.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { KeyMoments } from '../../../../../components/KeyMoments';
import React from 'react';
import { CollapsableSummaryPanel } from '../../../../../components/SummaryPanel/CollapsableSummaryPanel';

interface PropTypes {
  interview: Interview;
  atsEnabled: boolean;
  onSelectTimestamp: (timestamp: number) => void;
  onUpdateActionPoints: (actionPoints: ActionPoint[]) => void;
  onCustomizing: (customizing: boolean) => void;
  isCustomizingSummary: boolean;
  customSummaryEnabled: boolean;
}

function OverviewSummaryEmptyState() {
  return (
    <EmptyTab
      title='No summary available yet'
      text='We are processing your interview summary. Please check back later.'
      imageSrc={UndrawLost}
      size='medium'
    />
  );
}

export function OverviewSummary(props: PropTypes) {
  if (!props.interview.summary?.text) {
    return <OverviewSummaryEmptyState />;
  }

  return (
    <div
      className={classNames(
        'd-flex',
        'flex-column',
        'gap-3',
        'mt-2',
        styles.expandableSummary,
      )}
    >
      <CollapsableSummaryPanel
        summary={props.interview.summary}
        classNames={''}
        onCustomizing={props.onCustomizing}
        isCustomizingSummary={props.isCustomizingSummary}
        customSummaryEnabled={props.customSummaryEnabled}
      />
      {props.interview.keyMoments.length !== 0 && (
        <KeyMoments
          keyMoments={props.interview.keyMoments}
          onSelectTimestamp={props.onSelectTimestamp}
        />
      )}
    </div>
  );
}
