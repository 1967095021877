import React from 'react';
import { Alert, AlertType } from '../../../../components/Alert';

interface PropTypes {
  failedAlertActive: boolean;
  setFailedAlertActive: (active: boolean) => void;
  errorMessage: string;
  successAlertActive: boolean;
  setSuccessAlertActive: (active: boolean) => void;
  email: string;
}

export function NewUserAlerts(props: PropTypes) {
  return (
    <>
      {props.failedAlertActive && (
        <Alert
          type={AlertType.Danger}
          clearable={true}
          onClose={() => props.setFailedAlertActive(false)}
          autoClearTimeout={4000}
        >
          {props.errorMessage}
        </Alert>
      )}
      {props.successAlertActive && (
        <Alert
          type={AlertType.Success}
          clearable={true}
          onClose={() => props.setSuccessAlertActive(false)}
          autoClearTimeout={4000}
        >
          {`Invitation sent to ${props.email}!`}
        </Alert>
      )}
    </>
  );
}
