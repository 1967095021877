import React from 'react';
import { FillWithAIButton, GenerateState } from '../FillWithAIButton';

interface PropTypes {
  title: JSX.Element;
  aiScorecardFill: boolean;
  regeneratePrompt: string;
  showRegeneratePanel: boolean;
  aiGenerateState: GenerateState;
  generateResponse: (prompt: string) => void;
}

export function ScorecardTextQuestionTitle(props: PropTypes) {
  return (
    <div className='mb-2h'>
      {props.aiScorecardFill && !props.showRegeneratePanel ? (
        <div className='d-flex justify-content-between'>
          {props.title}
          <FillWithAIButton
            state={props.aiGenerateState}
            onClick={() => props.generateResponse(props.regeneratePrompt)}
          />
        </div>
      ) : (
        props.title
      )}
    </div>
  );
}
