export const defaultStartPanelFields = {
  title: 'Thank you for taking the time to provide feedback!',
  notes: [
    {
      icon: 'bi-clock',
      text: 'We estimate that completing this survey will take you less than 5 minutes.',
    },
    {
      icon: 'bi-lock',
      text: 'Your answers are confidential and anonymized. Please be honest and thoughtful when answering these questions.',
    },
    {
      icon: 'bi-star',
      text: 'Your feedback will be used to help us improve our overall candidate experience and interview process.',
    },
  ],
  footerText:
    'Your privacy is our top priority. All of the data we collect is completely secure and confidential. Your personal information is not shared with the employer.',
};
