import React from 'react';
import { SetupPageHeader } from '../../../../../components/SetupPageHeader';
import { getRedirectUriQueryParam } from '../../../../../utils/url';

interface PropTypes {
  isSavable: boolean;
  saveText?: string;
  onSaveClick: () => void;
}

export function CardHeader(props: PropTypes) {
  return (
    <SetupPageHeader
      title='Requisition Request'
      firstItems={[]}
      titleClassNames='mb-0'
      classNames='align-items-center mb-2'
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: () =>
            (window.location.href =
              getRedirectUriQueryParam() ??
              '/applicant_tracking/job_requisitions'),
        },
        {
          title: props.saveText ?? 'Request Approval',
          type: 'Save',
          disabled: !props.isSavable,
          action: () => props.onSaveClick(),
        },
      ]}
    />
  );
}
