import React from 'react';
import { Select } from '../Select';

interface PropTypes {
  name: string;
  className?: string;
  disabled?: boolean;
  value?: string | number;
  min?: number;
  menuPlacement?: 'top' | 'bottom';
  onChange?: (value: number) => void;
}

export function YearSelect(props: PropTypes) {
  const currentYear = new Date().getFullYear();
  const minYear = props.min == null ? currentYear - 10 : props.min;

  const options = [];
  for (let i = currentYear; i >= minYear; --i) {
    options.push({ value: i, label: i });
  }

  return (
    <Select
      className={props.className}
      options={options}
      selected={
        props.value && {
          value: props.value.toString(),
          label: props.value.toString(),
        }
      }
      name={props.name}
      testId={props.name}
      placeholder='Year'
      disabled={props.disabled === true}
      menuPlacement={props.menuPlacement}
      onChange={(newOption) => props.onChange(Number(newOption.value))}
    />
  );
}
