import React from 'react';
import { Col, Row } from 'reactstrap';
import { MonthSelect } from '../../../../../components/MonthSelect';
import { YearSelect } from '../../../../../components/YearSelect';

interface PropTypes {
  answer: boolean;
  rJobTitle: string;
  rStartedOnMonth: number;
  rStartedOnYear: number;
  rEndedOnMonth: number;
  rEndedOnYear: number;
  onChangeRJobTitle(title: string): void;
  onChangeRStartedOnMonth(value: number): void;
  onChangeRStartedOnYear(value: number): void;
  onChangeREndedOnMonth(value: number): void;
  onChangeREndedOnYear(value: number): void;
}

function RequiredLabel(props: { text: string }) {
  return (
    <label className='form-label'>
      {props.text} <span className='text-danger'>*</span>
    </label>
  );
}

export function Form(props: PropTypes) {
  if (props.answer !== false) return null;

  return (
    <div className='mt-5'>
      <Row>
        <Col xs='12'>
          <p>Please update the information that is not correct.</p>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <div className='d-flex gap-2'>
            <div>
              <RequiredLabel text='Job Title' />
              <input
                type='text'
                className='form-control'
                id='reference_job_title'
                value={props.rJobTitle}
                onChange={(event) =>
                  props.onChangeRJobTitle(event.target.value)
                }
              />
            </div>
            <div>
              <RequiredLabel text='From' />
              <div className='d-flex gap-1'>
                <YearSelect
                  name='rStartedOnYear'
                  value={props.rStartedOnYear}
                  onChange={(value) => props.onChangeRStartedOnYear(value)}
                />
                <MonthSelect
                  max={
                    props.rStartedOnYear === new Date().getFullYear()
                      ? new Date().getMonth()
                      : undefined
                  }
                  name='rStartedOnMonth'
                  value={props.rStartedOnMonth}
                  onChange={(value) => props.onChangeRStartedOnMonth(value)}
                />
              </div>
            </div>
            <div>
              <RequiredLabel text='To' />
              <div className='d-flex gap-1'>
                <YearSelect
                  name='rEndedOnYear'
                  value={props.rEndedOnYear}
                  min={props.rStartedOnYear}
                  onChange={(value) => props.onChangeREndedOnYear(value)}
                />
                <MonthSelect
                  min={
                    props.rStartedOnYear === props.rEndedOnYear
                      ? props.rStartedOnMonth
                      : undefined
                  }
                  max={
                    props.rEndedOnYear === new Date().getFullYear()
                      ? new Date().getMonth()
                      : undefined
                  }
                  name='rEndedOnMonth'
                  value={props.rEndedOnMonth}
                  onChange={(value) => props.onChangeREndedOnMonth(value)}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
