import React from 'react';
import { CheckboxButton } from '../../../../../components/CheckboxButton';
import { CandidateDto } from '../../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { UseFormRegister } from 'react-hook-form';

interface PropTypes {
  formRegister: UseFormRegister<CandidateDto>;
}

export function ReferralKnowledge(props: PropTypes) {
  const { onChange, onBlur, name, ref } =
    props.formRegister('referralKnowledge');

  return (
    <CheckboxButton
      label={"Referral knows they're being referred."}
      onBlur={onBlur}
      name={name}
      checkboxRef={ref}
      onChange={(ev: React.FormEvent<HTMLInputElement>) => {
        onChange(ev ?? null);
      }}
      disabled={false}
    />
  );
}
