import React from 'react';
import { EmptyState } from '../../../../../components/EmptyState';
import UndrawEmptyState from '../../../../../images/undraw_empty_state_cactus.svg';
import { ListCandidate } from '../../../../../entities/applicant_tracking/ListCandidate';
import { FilterStates } from '../FilterStates';
import { ListCandidatesFilters } from './ListCandidatesFilters';
import { ListCandidatesTable } from './ListCandidatesTable';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { User } from '../../../../../entities/User';
import { HrisEmployee } from '../../../../../entities/applicant_tracking/HrisEmployees';
import { HrisProvider } from '../../../../../enums/IntegrationProvider/HrisProvider';
import { AlertObject } from '../../../../../components/Alert';
import { EmailAccountIntegration } from '../../../../../entities/EmailAccountIntegration';

interface PropTypes {
  candidates: ListCandidate[];
  currentUser: User;
  emailAccountIntegration: EmailAccountIntegration;
  filterStates: FilterStates;
  hrisEmployees: HrisEmployee[];
  totalFilteredCandidates: number;
  selectedCandidates: ListCandidate[];
  hrisProvider?: HrisProvider;
  isLoading?: boolean;
  setSelectedCandidates: (candidates: ListCandidate[]) => void;
  onFilterStatesUpdate: (fs: FilterStates) => void;
  reloadPage: () => void;
  setAlert: (alert: AlertObject) => void;
}

export function ListCandidatesPageBody(props: PropTypes) {
  return (
    <div className='tab-content pb-1' id='candidates-table-tab-content'>
      <div
        className='tab-pane fade show active'
        id='candidates-all'
        role='tabpanel'
        aria-labelledby='candidates-all-tab'
      >
        <div className='app-card app-card-table shadow-sm mb-4'>
          <div className='app-card-body'>
            <ListCandidatesFilters
              filterStates={props.filterStates}
              onFilterStatesUpdate={props.onFilterStatesUpdate}
            />
            {props.isLoading ? (
              <div className={classNames(styles['loading-spinner-container'])}>
                <LoadingSpinner />
              </div>
            ) : (
              <ListCandidatesTable
                candidates={props.candidates}
                currentUser={props.currentUser}
                emailAccountIntegration={props.emailAccountIntegration}
                hrisEmployees={props.hrisEmployees}
                hrisProvider={props.hrisProvider}
                filterStates={props.filterStates}
                totalFilteredCandidates={props.totalFilteredCandidates}
                selectedCandidates={props.selectedCandidates}
                setSelectedCandidates={props.setSelectedCandidates}
                onFilterStatesUpdate={props.onFilterStatesUpdate}
                reloadPage={props.reloadPage}
                setAlert={props.setAlert}
                emptyState={
                  <EmptyState
                    title='No results found'
                    text={'Please refine your search or filters and try again.'}
                    imageSrc={UndrawEmptyState}
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
