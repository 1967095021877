import { Organization } from '../../../entities/Organization';
import { ApiService } from '../../ApiService';

interface Params {
  expand: string[];
}

export class OrganizationService {
  public static current(params: Params): Promise<Organization> {
    return ApiService.get<Organization>(
      `/api/v1/organizations/current`,
      params,
    );
  }

  public static createLogo(content: string): Promise<Organization> {
    return ApiService.post(
      '/api/v1/organizations/logo?expand[]=organization_info',
      {
        logo_content: content,
      },
    );
  }

  public static updateAutoRecord(autoRecordEnabled: boolean): Promise<void> {
    return ApiService.put('/api/v1/organizations/auto_record_enabled', {
      auto_record_enabled: autoRecordEnabled,
    });
  }
}
