export const isMouseWithinElement = (
  e: MouseEvent,
  element: HTMLElement,
): boolean => {
  const { top, bottom, left, right } = element.getBoundingClientRect();
  return (
    e.clientX >= left &&
    e.clientX <= right &&
    e.clientY >= top &&
    e.clientY <= bottom
  );
};
