import React, { useEffect, useState } from 'react';
import { SourceService } from '../../../../services/v1/applicant_tracking/SourceService';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../survey_engine/SurveyTemplates/CardHeader';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ChannelTypeInput } from '../components/ChannelTypeInput';
import { FormHeader } from '../components/FormHeader';
import { Alert, AlertType } from '../../../../components/Alert';
import { IconSpan } from '../../../../components/IconSpan';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import {
  ChannelType,
  Source,
} from '../../../../entities/applicant_tracking/Source';
import { SourcesInput } from '../components/SourcesInput';
import {
  DUPLICATES_FOUND_ALERT_MESSAGE,
  REDIRECT_URI,
  SOURCE_NAME_MAX_CHARACTERS,
  SUCCESSFUL_ALERT_MESSAGE_MULTIPLE,
  SUCCESSFUL_ALERT_MESSAGE_SINGLE,
  SourceData,
  emptySourceData,
} from '../util';
import { redirectWithAlert } from '../../../../services/FlashService';

export default function NewRejectedSourcePage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertActive, setAlertActive] = useState<boolean>(false);
  const [channelType, setChannelType] = useState<ChannelType>(null);
  const [sources, setSources] = useState<SourceData[]>([emptySourceData()]);
  const [duplicates, setDuplicates] = useState<SourceData[]>([]);
  useEffect(() => setDuplicates([]), [channelType]);

  function isSavable() {
    const sourceNames = sources.map((source) => source.name);
    return (
      sources.length > 0 &&
      sources.every((source) => source.name.length > 0) &&
      duplicates.every((duplicate) => !sourceNames.includes(duplicate.name)) &&
      channelType !== null
    );
  }

  async function handleSave() {
    setIsLoading(true);
    const trimmedSources = sources.map((source) => {
      source.name = source.name.trim();
      return source;
    });
    setSources(trimmedSources);

    try {
      await SourceService.batchCreate(channelType, trimmedSources);
      redirectWithAlert(
        REDIRECT_URI,
        sources.length === 1
          ? SUCCESSFUL_ALERT_MESSAGE_SINGLE
          : SUCCESSFUL_ALERT_MESSAGE_MULTIPLE,
      );
    } catch (error) {
      const response = error.body;
      if (response.code !== 'duplicate_sources_error') throw error;

      setDuplicates(response.body.map((source: Source) => source));
      setAlertActive(true);
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      {alertActive && (
        <Alert
          type={AlertType.Danger}
          clearable
          autoClearTimeout={4000}
          onClose={() => setAlertActive(false)}
        >
          <IconSpan
            spanText={DUPLICATES_FOUND_ALERT_MESSAGE}
            icon={{ name: 'bi-info-circle' }}
            spanClassName='ms-3'
          />
        </Alert>
      )}
      <div className='container'>
        <PageHeader title='Add Sources' backUrl={REDIRECT_URI} />
        <Card className={classNames(styles['card'])}>
          <CardHeader
            title='Sources'
            isSavable={isSavable()}
            onSaveClick={handleSave}
            cancelUrl={REDIRECT_URI}
          />
          <ChannelTypeInput
            channelType={channelType}
            setChannelType={setChannelType}
          />
          <FormHeader
            mandatory
            title='Sources'
            constraint={`Maximum ${SOURCE_NAME_MAX_CHARACTERS} characters`}
          />
          <SourcesInput
            sources={sources}
            duplicates={duplicates}
            setSources={setSources}
          />
        </Card>
      </div>
    </>
  );
}
