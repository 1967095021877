import React from 'react';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { JobRequisitionStatus } from '../../../../../entities/v1/applicant_tracking/JobRequisition';

export function StatusChip(props: { status: JobRequisitionStatus }) {
  let statusChipColor: ChipColor;
  let statusText: string;

  switch (props.status.toString()) {
    case JobRequisitionStatus.Approved.toString():
      statusChipColor = ChipColor.Success;
      statusText = 'Approved';
      break;
    case JobRequisitionStatus.Completed.toString():
      statusChipColor = ChipColor.Blue;
      statusText = 'Completed';
      break;
    case JobRequisitionStatus.Declined.toString():
      statusChipColor = ChipColor.Danger;
      statusText = 'Declined';
      break;
    case JobRequisitionStatus.Pending.toString():
      statusChipColor = ChipColor.Blue500;
      statusText = 'Awaiting for approval';
      break;
  }

  if (statusText == null) return;

  return (
    <Chip className={'fw-semibold mx-3'} color={statusChipColor}>
      {statusText}
    </Chip>
  );
}
