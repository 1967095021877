import { Medium } from './Medium';
import { RatingHistory } from './RatingHistory';
import { Section } from './Section';
import { User } from './User';
import { Comment } from './Comment';

export enum SessionEvaluationStatus {
  NoTemplate = 'no_template',
  NoEvaluation = 'no_evaluation',
  Pending = 'pending',
  Submitted = 'submitted',
  Completed = 'completed',
}

export interface Session {
  id: number;
  name: string;
  topics: string[];
  thumbnail?: Medium;
  durationMillis: number;
  views: number;
  goal: string;
  comingSoon: boolean;
  draft: boolean;
  completed: boolean;
  assigned: boolean;
  trainingUsers: User[];
  averageRating?: number;
  sections?: Section[];
  comments?: Comment[];
  ratingHistory?: RatingHistory[];
  evaluation: {
    id?: number;
    status?: SessionEvaluationStatus;
    accuracy?: number;
    alert?: boolean;
  };
}
