import React from 'react';
import classNames from 'classnames';
import { SpanTooltip } from '../../../../../../../../components/SpanTooltip';
import { Button } from 'reactstrap';
import { Icon } from '../../../../../../../../components/Icon';
import styles from './styles.module.scss';
import { InterviewerParticipant } from '../../../../../../../../entities/applicant_tracking/InterviewerParticipant';

interface PropTypes {
  participant: InterviewerParticipant;
  participants: InterviewerParticipant[];
  setParticipants: (participants: InterviewerParticipant[]) => void;
  isReadOnly: boolean;
}

function removeParticipant(
  setParticipants: (participants: InterviewerParticipant[]) => void,
  participants: InterviewerParticipant[],
  participant: InterviewerParticipant,
): void {
  const newParticipants = participants.filter(
    (p) => p.user.id !== participant.user.id,
  );
  setParticipants(newParticipants);
}

export function RemoveParticipantButton(props: PropTypes) {
  if (props.isReadOnly) return;

  return (
    <SpanTooltip
      text={
        <Button
          color={'muted'}
          className={classNames(
            styles['trash'],
            'border-0 w-10 pe-0 py-0 ps-2',
          )}
          disabled={props.participant.isMeetingOrganizer}
          onClick={() =>
            removeParticipant(
              props.setParticipants,
              props.participants,
              props.participant,
            )
          }
        >
          <Icon name={'bi-trash'} topStyle={'2px'} />
        </Button>
      }
      tooltipText={
        props.participant.isMeetingOrganizer
          ? "The meeting host can't be removed."
          : 'Remove participant'
      }
      trigger='hover'
    />
  );
}
