import React from 'react';
import { HiringMemberAnalytics } from '../../../../../../../../../entities/candidate_experience/HiringMemberAnalytics';
import { ValueWithTrend } from '../ValueWithTrend';
import {
  CPS_RATING_LEGEND,
  CPS_RATING_LEGEND_GAUGE_CHART,
} from '../../../../../../../../../components/survey_engine/RatingLegend';
import { findRatingChipColorByScore } from '../../../../../../utils';

interface PropTypes {
  hiring_member: HiringMemberAnalytics;
  standardNpsCalculation: boolean;
}

export function Cnps(props: PropTypes) {
  const ratingLegend = props.standardNpsCalculation
    ? CPS_RATING_LEGEND_GAUGE_CHART
    : CPS_RATING_LEGEND;

  const chipColor = props.standardNpsCalculation
    ? findRatingChipColorByScore(ratingLegend, props.hiring_member.cnps)
    : findRatingChipColorByScore(ratingLegend, props.hiring_member.cnps * 10);

  return (
    <ValueWithTrend
      value={props.hiring_member.cnps}
      trend={props.hiring_member.cnps_trend}
      chipColor={chipColor}
    />
  );
}
