import * as ActiveStorage from '@rails/activestorage';
import 'chartkick/chart.js';
import '@hotwired/turbo-rails';
import 'channels';
import 'bootstrap';
import 'controllers';
import LocalTime from 'local-time';
import { PluginManager } from '../plugins';
import '../vendor/portal/app';
import '../stylesheets/application.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { ConfirmModalPlugin } from '../plugins/ConfirmModalPlugin';
import { ReactPlugin } from '../plugins/ReactPlugin';
import { UploadBoxPlugin } from '../plugins/UploadBoxPlugin';
import { SelectPlugin } from '../plugins/SelectPlugin';
import { CopyToClipboardPlugin } from '../plugins/CopyToClipboardPlugin';
import { ApplicantTrackingJobsAppPlugin } from '../plugins/ApplicantTrackingJobsAppPlugin';
import { ApplicantTrackingJobRequisitionsAppPlugin } from '../plugins/ApplicantTrackingJobRequisitionsAppPlugin';
import { CandidateExperienceAppPlugin } from '../plugins/CandidateExperienceAppPlugin';
import { QualityOfHireAppPlugin } from '../plugins/QualityOfHireAppPlugin';
import { ReferenceCheckAppPlugin } from '../plugins/RefereceCheckAppPlugin';

ActiveStorage.start();
LocalTime.start();

const pluginManager = new PluginManager();

pluginManager.register(new ConfirmModalPlugin());
pluginManager.register(new ReactPlugin());
pluginManager.register(new ApplicantTrackingJobsAppPlugin());
pluginManager.register(new ApplicantTrackingJobRequisitionsAppPlugin());
pluginManager.register(new CandidateExperienceAppPlugin());
pluginManager.register(new QualityOfHireAppPlugin());
pluginManager.register(new ReferenceCheckAppPlugin());
pluginManager.register(new UploadBoxPlugin());
pluginManager.register(new SelectPlugin());
pluginManager.register(new CopyToClipboardPlugin());

pluginManager.install();

(require as any).context('../images', true);

/**
 * This fix is in place to avoid Turbo Drive from reloading
 * pages with "hash" links. It will prevent the link to be
 * followed and scroll to the element if it exists until
 * the real solution is out.
 *
 * https://github.com/hotwired/turbo/pull/152
 */
function findAnchorElement(el: HTMLElement): HTMLElement {
  while (el) {
    if (el.tagName === 'A') return el;
    el = el.parentElement;
  }
  return null;
}

document.addEventListener('click', (e) => {
  const el = findAnchorElement(e.target as HTMLElement);
  if (el === null) return;

  const anchor = el as HTMLAnchorElement;
  const href = anchor.getAttribute('href');
  if (href && href[0] === '#') {
    e.preventDefault();
    try {
      const target = document.querySelector(href);
      target?.scrollIntoView();
    } catch {} // eslint-disable-line no-empty
  }
});
