import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../entities/survey_engine/SurveyQuestionAnswer';
import { hasAnsweredQuestion } from './hasAnsweredQuestion';

export function hasAnsweredAllQuestions(
  surveyAnswers: SurveyQuestionAnswer[],
  questions: SurveyQuestion[],
) {
  const questionIds = questions.map((question) => question.id);

  return surveyAnswers
    .filter((answer) => questionIds.includes(answer.question_id))
    .every((answer) => {
      const question = questions.find(
        (question) => question.id === answer.question_id,
      );
      return hasAnsweredQuestion(answer, question);
    });
}
