import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'reactstrap';
import { SidekickTab } from '../../SidekickTab';

interface PropTypes {
  tab: SidekickTab;
  activeTab: SidekickTab;
  setActiveTab: (Assessment: SidekickTab) => void;
}

export function SidekickNav(props: PropTypes) {
  return (
    <NavLink
      id={
        Object.keys(SidekickTab)[Object.values(SidekickTab).indexOf(props.tab)]
      }
      active={props.tab === props.activeTab}
      href='#'
      className={classNames(
        'nav-item',
        'nav-link',
        'text-truncate',
        'px-2',
        'fs-4',
        'fw-bold',
        {
          'active bg-transparent': props.tab == props.activeTab,
        },
      )}
      onClick={() => props.setActiveTab(props.tab)}
    >
      {props.tab}
    </NavLink>
  );
}
