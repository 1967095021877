import React from 'react';
import { LexicalCommand } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Icon } from '../../../../Icon';

interface PropTypes {
  isEditable: boolean;
  command: LexicalCommand<void>;
  direction: 'left' | 'right';
  shortcut: string;
}

export function IndentationButton(props: PropTypes) {
  const [editor] = useLexicalComposerContext();

  return (
    <button
      disabled={!props.isEditable}
      onClick={() => {
        editor.dispatchCommand(props.command, undefined);
      }}
      className={'mx-1'}
      title={`Text indent ${props.direction} (${props.shortcut})`}
      type='button'
      aria-label={`Text indent ${props.direction}. Shortcut: ${props.shortcut}`}
    >
      <Icon name={`bi-text-indent-${props.direction}`} />
    </button>
  );
}
