import React from 'react';
import { CardConditionsProps } from '..';
import { CardHeaderRow } from '../CardHeaderRow';
import { AddConditionButtonRow } from './AddConditionButtonRow';
import { ConditionRows } from './ConditionRows';
import { SurveyTemplateLabelledSelectRow } from './SurveyTemplateLabelledSelectRow';

interface PropTypes {
  cardProps: CardConditionsProps;
}

const SUBTITLE = 'Define and manage the conditions to trigger your surveys.';

export function CardConditionsRow(props: PropTypes) {
  return (
    <div className='item border-bottom py-3'>
      <div className='row justify-content-between align-items-center'>
        <div className='col'>
          <CardHeaderRow title={'Conditions'} subtitle={SUBTITLE} />
          <SurveyTemplateLabelledSelectRow
            labelledSurveyTemplates={props.cardProps.labelledSurveyTemplates}
            selectedSurveyTemplate={props.cardProps.selectedSurveyTemplate}
            setSelectedSurveyTemplate={
              props.cardProps.setSelectedSurveyTemplate
            }
            edit={props.cardProps.edit}
            surveyTemplates={props.cardProps.surveyTemplates}
            conditionsData={props.cardProps.conditionsData}
            setConditions={props.cardProps.setConditions}
          />
          <ConditionRows
            conditions={props.cardProps.conditions}
            applicableConditions={props.cardProps.applicableConditions}
            setConditions={props.cardProps.setConditions}
            selectedSurveyTemplate={props.cardProps.selectedSurveyTemplate}
            conditionsData={props.cardProps.conditionsData}
          />
          <AddConditionButtonRow
            selectedSurveyTemplate={props.cardProps.selectedSurveyTemplate}
            conditions={props.cardProps.conditions}
            setConditions={props.cardProps.setConditions}
            applicableConditions={props.cardProps.applicableConditions}
          />
        </div>
      </div>
    </div>
  );
}
