import classNames from 'classnames';
import React from 'react';
import FailedEvaluationScoreImage from '../../../../../../../images/training-failed-evaluation-score-modal.svg';
import styles from '../styles.module.scss';
import { displayEvaluationAccuracy } from '../utils/displayEvaluationAccuracy';

interface PropTypes {
  evaluationAccuracy?: number;
  questionsCount: number;
  correctlyAnsweredCount: number;
}

export function FailedEvaluationScoreBody(props: PropTypes) {
  const accuracyMessage = displayEvaluationAccuracy(props.evaluationAccuracy);

  return (
    <>
      <div className='text-center'>
        <img
          className={classNames('text-center', styles.illustration)}
          src={FailedEvaluationScoreImage}
        />
      </div>
      <div className='mt-4 text-center'>
        <span>
          You have achieved <strong>{accuracyMessage} Accuracy</strong> with{' '}
          <strong>
            {props.correctlyAnsweredCount} out of {props.questionsCount}
          </strong>{' '}
          correct answers. You should retry your evaluation.
        </span>
      </div>
    </>
  );
}
