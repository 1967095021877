import React from 'react';
import { IconSpan } from '../../../../components/IconSpan';
import { appendRedirectUri } from '../../../../utils/url';
import { DropdownAction } from '../../../../components/Dropdown/DropdownMenu/DropdownAction';

interface PropTypes {
  jobId: number;
  disabled?: boolean;
}

export default function EditJobButton(props: PropTypes) {
  if (props.disabled) {
    return null;
  }

  return (
    <DropdownAction
      action={() =>
        (window.location.href = appendRedirectUri(
          `/jobs/${props.jobId}/edit/job_details`,
        ))
      }
      buttonChild={
        <IconSpan
          className='ms-2'
          spanText='Edit'
          icon={{ name: 'bi-pencil' }}
        />
      }
    />
  );
}
