import React from 'react';
import { Col, Row } from 'reactstrap';
import { AutomaticAttribute } from '../../../../../entities/candidate_experience/AutomaticAttribute';
import classNames from 'classnames';
import stylesheetExports from '../../../../../stylesheets/export.module.scss';
import { REPORTING_SECTION_CLASS } from '../../../../../utils/pdfHandler';
import { ResponsesCounterPanel } from './ResponsesCounterPanel';
import { SentimentCounterPanel } from './SentimentCounterPanel';

interface PropTypes {
  attributes: AutomaticAttribute[];
  totalCNPSPromoters: number;
  totalCNPSNeutrals: number;
  totalCNPSDetractors: number;
  totalResponses: number;
  totalPositiveSentiment: number;
  totalNeutralSentiment: number;
  totalNegativeSentiment: number;
}

export function CounterPanels(props: PropTypes) {
  const totalSentiment =
    props.totalPositiveSentiment +
    props.totalNeutralSentiment +
    props.totalNegativeSentiment;

  return (
    <>
      {props.totalResponses > 0 && (
        <Row className={classNames('gy-4', REPORTING_SECTION_CLASS)}>
          <Col sm='12' lg='3'>
            <ResponsesCounterPanel
              totalPromoters={props.totalCNPSPromoters}
              totalResponses={props.totalResponses}
              totalNeutrals={props.totalCNPSNeutrals}
              totalDetractors={props.totalCNPSDetractors}
            />
          </Col>
          {totalSentiment > 0 && (
            <>
              <Col sm='12' lg='3'>
                <SentimentCounterPanel
                  sentimentQuantity={props.totalPositiveSentiment}
                  sentimentPercentage={(
                    (100 * props.totalPositiveSentiment) /
                    totalSentiment
                  ).toFixed(0)}
                  sentimentTitle={'Positive'}
                  sentimentColor={stylesheetExports.greenSuccess500}
                  sentimentEmoji={'bi bi-emoji-laughing'}
                />
              </Col>
              <Col sm='12' lg='3'>
                <SentimentCounterPanel
                  sentimentQuantity={props.totalNeutralSentiment}
                  sentimentPercentage={(
                    (100 * props.totalNeutralSentiment) /
                    totalSentiment
                  ).toFixed(0)}
                  sentimentTitle={'Neutral'}
                  sentimentColor={stylesheetExports.blueGray500}
                  sentimentEmoji={'bi bi-emoji-neutral'}
                />
              </Col>
              <Col sm='12' lg='3'>
                <SentimentCounterPanel
                  sentimentQuantity={props.totalNegativeSentiment}
                  sentimentPercentage={(
                    (100 * props.totalNegativeSentiment) /
                    totalSentiment
                  ).toFixed(0)}
                  sentimentTitle={'Negative'}
                  sentimentColor={stylesheetExports.red500}
                  sentimentEmoji={'bi bi-emoji-frown'}
                />
              </Col>
            </>
          )}
        </Row>
      )}
    </>
  );
}
