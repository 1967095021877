import React from 'react';
import { JobPost } from '../../../../../entities/JobPost';
import { IconSpan } from '../../../../../components/IconSpan';
import { DropdownAction } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';

interface PropTypes {
  jobPosts: JobPost[];
  jobStatus: string;
  subdomain?: string;
}

export function jobPostLink(jobPosts: JobPost[], subdomain?: string): string {
  if (subdomain == null || jobPosts == null || jobPosts.length === 0) {
    return null;
  }

  return `/careers/${subdomain}/job_posts/${jobPosts[0].id}`;
}

export default function JobPostButton(props: PropTypes) {
  if (props.jobStatus !== 'published') {
    return null;
  }

  const redirectUrl = jobPostLink(props.jobPosts, props.subdomain);

  return (
    <DropdownAction
      action={() => window.open(redirectUrl, '_blank', 'noopener,noreferrer')}
      isDisabled={!redirectUrl}
      buttonChild={
        <IconSpan
          className='ms-2'
          spanText='View Job Post'
          icon={{ name: 'bi-box-arrow-up-right' }}
        />
      }
    />
  );
}
