import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['timestamp'];

  connect() {
    this._updateTimestamp();
    setInterval(() => this._updateTimestamp(), 1000);
  }

  _updateTimestamp() {
    const date = new Date();
    const hour = date.getHours();
    const min = date.getMinutes();
    const timestamp =
      (hour > 9 ? hour : `0${hour}`) + ':' + (min > 9 ? min : `0${min}`);
    this.timestampTarget.textContent = timestamp;
  }
}
