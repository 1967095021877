import { ApiService } from '../../ApiService';

const BASE_URL = '/api/applicant_tracking/applicant_tracking_settings/';

export class ApplicantTrackingSettingsService {
  public static jobRequisitionEnabled(enabled: boolean): Promise<void> {
    return ApiService.put(`${BASE_URL}job_requisition`, { enabled });
  }

  public static jobRequisitionMandatory(enabled: boolean): Promise<void> {
    return ApiService.put(`${BASE_URL}job_requisition_mandatory`, { enabled });
  }

  public static offerRequisitionEnabled(enabled: boolean): Promise<void> {
    return ApiService.put(`${BASE_URL}offer_requisition`, { enabled });
  }
}
