export enum SurveyRequestStatus {
  Unknown = 'unknown',
  NotRequested = 'not_requested',
  NotSet = 'not_set',
  Pending = 'pending',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export interface SurveyRequest {
  id: number;
  status: SurveyRequestStatus;
}
