import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  percentage: number;
}

export function ProgressBar(props: PropTypes) {
  return (
    <div
      className={classNames(
        'my-auto',
        'w-100',
        'bg-gray',
        styles.progressBarContainer,
      )}
    >
      <div className='position-relative h-100'>
        <span
          className={classNames(
            'h-100 ms-0 position-absolute',
            styles.progressBar,
          )}
          style={{
            width: `${
              isNaN(props.percentage) ? 0 : Math.round(props.percentage)
            }%`,
          }}
        />
      </div>
    </div>
  );
}
