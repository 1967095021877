import { SelectOption } from '../components/Select';

export function filteredSelectedOptionsByLabel(
  options: SelectOption[],
  selectedOptions?: string,
) {
  const splitOptions = selectedOptions
    ?.split(',')
    ?.map((option) => option.trim().toLowerCase());
  if (splitOptions == null) {
    return null;
  }

  return options.filter((option) =>
    splitOptions.includes(option.label.toLowerCase()),
  );
}
