import classNames from 'classnames';
import React from 'react';
import { JobStage } from '../../../../../entities/JobStage';
import { Dropdown } from '../../../../../components/Dropdown';
import { PropTypes as DropdownActionPropTypes } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';
import { IconSpan } from '../../../../../components/IconSpan';
import { bulkApplicationReviewUrl } from '../../../../../utils/applicant_tracking/bulkApplicationReview';
import { PipelineApplication } from '../../../../../entities/applicant_tracking/PipelineApplication';
import { redirectToBulkSelfSchedulingPage } from '../../../../../utils/applicant_tracking/buildSelfScheduling';

interface PropTypes {
  applications: PipelineApplication[];
  className?: string;
  jobStage: JobStage;
  menuActions: DropdownActionPropTypes[];
}

function renderBulkSelfSchedulingAction(
  jobStage: JobStage,
  applications: PipelineApplication[],
): DropdownActionPropTypes {
  if (!jobStage.interviewStageId) {
    return null;
  }

  const jobId = applications[0].job.id;

  return {
    action: () => {
      redirectToBulkSelfSchedulingPage(
        applications.map((a) => a.id),
        jobId,
        jobStage.interviewStageId,
      );
    },
    buttonChild: (
      <IconSpan
        spanText={'Send Self-scheduling Link'}
        icon={{ name: 'bi-calendar2-check', className: 'fs-5' }}
        className={'text-info'}
      />
    ),
  };
}

function renderReviewCandidates(
  jobStage: JobStage,
  applications: PipelineApplication[],
): DropdownActionPropTypes {
  if (!jobStage.bulkReviewEnabled) {
    return null;
  }

  return {
    action: () =>
      (window.location.href = bulkApplicationReviewUrl(
        applications.map((a) => a.id),
      )),
    buttonChild: (
      <IconSpan
        spanText={'Bulk Review'}
        icon={{ name: 'bi-people', className: 'fs-5' }}
        className={'text-info'}
      />
    ),
  };
}

export function buildDropDownActions(
  jobStage: JobStage,
  applications: PipelineApplication[],
  bulkSelfSchedulingEnabled: boolean,
): DropdownActionPropTypes[] {
  if (!bulkSelfSchedulingEnabled || applications.length == 0) {
    return [];
  }

  return [
    renderBulkSelfSchedulingAction(jobStage, applications),
    renderReviewCandidates(jobStage, applications),
  ].filter(Boolean);
}

export function JobStageDropdown(props: PropTypes) {
  if (props.menuActions.length == 0) {
    return null;
  }

  return (
    <Dropdown
      buttonClassName={classNames('btn btn-borderless bg-white')}
      buttonIcon={{
        name: 'bi-three-dots-vertical',
        className: 'text-info',
        topStyle: '-2px',
      }}
      containerClassName={classNames('me-1', props.className)}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={props.menuActions}
    />
  );
}
