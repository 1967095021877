import React, { useState } from 'react';
import { RejectCandidateStageModal } from '../../RejectCandidate';
import { Application } from '../../../../../entities/Application';
import { User } from '../../../../../entities/User';
import { Alert, AlertObject, AlertType } from '../../../../../components/Alert';

interface PropTypes {
  application: Application;
  isOpen: boolean;
  sendEmailEnabled: boolean;
  user: User;
  onClose: (sendEmail: boolean) => void;
  reloadApplication: () => void;
}

export function RejectCandidateModal(props: PropTypes) {
  const [alertObject, setAlertObject] = useState<AlertObject>(null);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  function handleClose(sendEmail: boolean) {
    // Restore the default states
    setAlertObject(null);
    setShowConfirmationModal(false);
    props.onClose(sendEmail);
  }

  function handleRejectModalClose(succeeded: boolean, _rejectedReson?: string) {
    if (succeeded) {
      setAlertObject({
        type: AlertType.Success,
        message: (
          <span>
            <b>{props.application.candidate.name}</b> was successfully rejected
            for the <b>{props.application.job?.name}</b> job!
          </span>
        ),
      });
    } else {
      setAlertObject({
        type: AlertType.Danger,
        message: (
          <span>
            <b>{props.application.candidate.name}</b> could not be rejected for
            the <b>{props.application.job?.name}</b> job
          </span>
        ),
      });
    }

    if (succeeded) {
      props.reloadApplication();
    }

    if (props.sendEmailEnabled && props.user.hasEmailConnected) {
      setShowConfirmationModal(true);
    } else {
      props.onClose(false);
    }
  }

  return (
    <>
      {alertObject && (
        <Alert
          type={alertObject.type}
          autoClearTimeout={3000}
          clearable={true}
          onClose={() => setAlertObject(null)}
        >
          {alertObject.message}
        </Alert>
      )}
      <RejectCandidateStageModal
        application={props.application}
        isOpen={props.isOpen}
        showConfirmationModal={showConfirmationModal}
        onReject={handleRejectModalClose}
        onClose={handleClose}
      />
    </>
  );
}
