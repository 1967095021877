import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { CreateSurveyTemplateRequest } from '../../survey_engine/CreateSurveyTemplateRequest';
import { ApplicationFormTemplateList } from '../../../../entities/v1/applicant_tracking/ApplicationFormTemplateList';
import { ApplicationFormTemplate } from '../../../../entities/v1/applicant_tracking/ApplicationFormTemplate';
import { UpdateSurveyTemplateRequest } from '../../survey_engine/UpdateSurveyTemplateRequest';

interface Params {
  name?: string;
  expand: string[];
}

export class ApplicationFormTemplateService {
  public static list(params: Params): Promise<ApplicationFormTemplateList> {
    return ApiService.get<ApplicationFormTemplateList>(
      '/api/v1/applicant_tracking/application_form_templates',
      snakecaseKeys(params),
    );
  }

  public static show(
    id: string,
    params: Params,
  ): Promise<ApplicationFormTemplate> {
    return ApiService.get<ApplicationFormTemplate>(
      `/api/v1/applicant_tracking/application_form_templates/${id}`,
      snakecaseKeys(params),
    );
  }

  public static create(
    surveyTemplate: CreateSurveyTemplateRequest,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/v1/applicant_tracking/application_form_templates`,
      snakecaseKeys({ surveyTemplate }),
    );
  }

  public static update(
    id: number,
    surveyTemplate: UpdateSurveyTemplateRequest,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/application_form_templates/${id}`,
      snakecaseKeys({ surveyTemplate }),
    );
  }
}
