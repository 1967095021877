import { DateFilter } from '../../../components/DateRangePicker';
import { ApiService } from '../../ApiService';

export enum ExportType {
  CSV = 'csv',
  PDF = 'pdf',
}

interface Params {
  filter_selector: string;
  job_names: string[];
  department_names: string[];
  location_names: string[];
  survey_template_ids: string[];
  benchmark_type: string;
  dateRange: DateFilter;
  ethnicities: string[];
  genders: string[];
  ageRanges: string[];
}

function generateParams(params: Params) {
  const { dateRange, ...filtered_params } = params;

  return {
    ...filtered_params,
    start_date: dateRange.start.toJSON(),
    end_date: dateRange.end.toJSON(),
    date_range: dateRange.range,
  };
}

export class ExportsService {
  public static export(params: Params, exportType: ExportType): Promise<Blob> {
    return ApiService.get<Blob>(
      `/exports/candidate_pulse_reports/${exportType}_export`,
      generateParams(params),
    );
  }
}
