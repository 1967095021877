import React from 'react';
import { FollowUpResponsesCircleChart } from './FollowUpResponsesCircleChart';
import classNames from 'classnames';
import { AnalyticsPanel } from '../../../../components/AnalyticsPanel';

interface PropTypes {
  neutralPercentage: number;
  detractorPercentage: number;
  neutralCount: number;
  detractorCount: number;
  responsesNumber: number;
}

export function FollowUpResponsesCountsPanel(props: PropTypes) {
  return (
    <AnalyticsPanel
      title={
        <div className='d-flex'>
          <div>
            <div className={classNames('mb-0', 'fw-bold')}>Responses</div>
          </div>
        </div>
      }
    >
      <div className='flex-grow-1 d-flex align-items-center justify-content-center'>
        <FollowUpResponsesCircleChart
          responsesNumber={props.responsesNumber}
          neutralCount={props.neutralCount}
          neutralPercentage={props.neutralPercentage}
          detractorCount={props.detractorCount}
          detractorPercentage={props.detractorPercentage}
        />
      </div>
    </AnalyticsPanel>
  );
}
