import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppPlugin } from '..';
import { CopyToClipboard } from '../../components/CopyToClipboard';

export class CopyToClipboardPlugin implements AppPlugin {
  public load(): void {
    document
      .querySelectorAll<HTMLSelectElement>('[data-copy-to-clipboard]')
      .forEach((el) => this.installContainer(el));
  }

  private installContainer(el: HTMLSelectElement): void {
    const content = el.dataset['copyToClipboard'];
    const props = { content, className: el.className };
    createRoot(el).render(React.createElement(CopyToClipboard, props));
    el.className = '';
    el.removeAttribute('data-copy-to-clipboard');
  }
}
