import React, { useEffect, useState } from 'react';
import { Application } from '../../../entities/Application';
import { IconButton } from '../../IconButton';
import { CandidateRatingModal } from '../CandidateRatingModal';
import { ApplicationRating } from '../../../entities/v1/applicant_tracking/ApplicationRating';
import { ApplicationRatingService } from '../../../services/v1/applicant_tracking/ApplicationRatingService';

interface PropTypes {
  application: Application;
  className?: string;
  onClose?: () => void;
}

export function AddCandidateRatingButton(props: PropTypes) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [existingRating, setExistingRating] = useState<ApplicationRating>(null);

  useEffect(() => {
    if (!props.application.candidateRatingId) return;

    ApplicationRatingService.show(props.application.candidateRatingId).then(
      setExistingRating,
    );
  }, [props.application]);

  if (
    !props.application.createRatingEnabled &&
    !props.application.candidateRatingId
  ) {
    return null;
  }

  return (
    <>
      <IconButton
        color='borderless'
        iconName='bi-clipboard-heart'
        buttonText={`${
          props.application.candidateRatingId ? 'Edit' : 'Add'
        } Candidate Rating`}
        buttonClass={props.className}
        size='sm'
        onClick={() => setModalOpen(true)}
      />
      <CandidateRatingModal
        application={props.application}
        applicationRating={existingRating}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          props.onClose?.();
        }}
      />
    </>
  );
}
