import { InterviewAccess } from '../../entities/InterviewAccess';
import { ApiService } from '../ApiService';

export class InterviewAccessService {
  public static create(
    interviewId: number,
    userId: number,
  ): Promise<InterviewAccess> {
    return ApiService.post(`/interview_accesses`, {
      interview_id: interviewId,
      user_id: userId,
    });
  }

  public static delete(id: number): Promise<InterviewAccess> {
    return ApiService.delete(`/interview_accesses/${id}`);
  }
}
