import React from 'react';
import { LabelledMultiSelect } from '../../../../components/LabelledMultiSelect';
import styles from './styles.module.scss';

interface PropTypes {
  sessionName: string;
  options: UserOption[];
  selected: UserOption[];
  onSelected: (newoOtions: AssignedUserOption[]) => void;
}

interface UserOption {
  value: string;
  label: string;
  subLabel?: React.ReactNode;
  disabled?: boolean;
}

export interface AssignedUserOption extends UserOption {
  selected: boolean;
}

function mapSelectedUsersToAssignUserOptions(
  user: UserOption,
): AssignedUserOption {
  return {
    label: user.label,
    subLabel: user.subLabel,
    value: user.value,
    disabled: user.disabled,
    selected: true,
  };
}

export function AssignUserSelect(props: PropTypes) {
  function handleSelect(options: UserOption[]) {
    props.onSelected(
      options.map((option: UserOption) => {
        return mapSelectedUsersToAssignUserOptions(option);
      }),
    );
  }

  return (
    <div className='mb-5'>
      <div className='fs-4 mb-4'>
        Select multiple users, assign or unassign <b>{props.sessionName}</b>{' '}
        session.
      </div>
      <LabelledMultiSelect
        options={props.options}
        selected={props.selected}
        hasSelectAll={true}
        label={'Users'}
        placeholder={'Select user'}
        singleSelectedText={'1 User selected'}
        manySelectedText={'%d Users selected'}
        onChange={handleSelect}
        className={styles.multiselect}
      />
    </div>
  );
}
