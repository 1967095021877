import React from 'react';
import { ScorecardFill } from '../../../../entities/ApplicationFeed';
import { Application } from '../../../../entities/Application';
import { User } from '../../../../entities/User';
import { AlertObject } from '../../../../components/Alert';
import { AdHocScorecardEntry } from './AdHocScorecardEntry';
import { InterviewScorecardEntry } from './InterviewScorecardEntry';

interface PropTypes {
  application: Application;
  currentUser: User;
  entry: ScorecardFill;
  isLastItemInPage: boolean;
  editedScorecard: boolean;
  setAlert: (data: AlertObject) => void;
  reloadApplication: () => void;
}

export function ScorecardFillEntry(props: PropTypes) {
  return props.entry.scorecard.interview ? (
    <InterviewScorecardEntry {...props} />
  ) : (
    <AdHocScorecardEntry {...props} />
  );
}
