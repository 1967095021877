import React from 'react';
import { Controller, Path } from 'react-hook-form';
import { ControllerContext } from '../../utils/reactHookForms';
import { CheckboxPillGroup } from '../CheckboxPillGroup';
import {
  CheckboxPill,
  PropTypes as CheckboxPillPropTypes,
} from '../CheckboxPill';

interface PropTypes<ControlType> {
  formHookContext?: ControllerContext<ControlType>;
  childrenProps: CheckboxPillPropTypes[];
  defaultValue?: string;
  childrenClassNames?: string;
  disabled?: boolean;
  resetField: (controllerName: string) => void;
}

export function CheckboxPillGroupFormHook<ControlType>(
  props: PropTypes<ControlType>,
) {
  if (props.formHookContext == null) return null;

  return (
    <Controller
      control={props.formHookContext.formControl}
      name={props.formHookContext.controllerName as Path<ControlType>}
      rules={{ required: props.formHookContext.required }}
      render={({ field }) => (
        <CheckboxPillGroup
          childrenClassName={props.childrenClassNames}
          selectedValue={(field?.value as string) || props.defaultValue}
          onChange={(ev: React.FormEvent<HTMLInputElement>) => {
            field.onChange(ev.currentTarget.value);
          }}
          resetValue={() => {
            props.resetField(props.formHookContext.controllerName);
          }}
          disabled={props.disabled}
        >
          {props.childrenProps.map(
            (childProps: CheckboxPillPropTypes, index: number) => (
              <CheckboxPill key={index} {...childProps} />
            ),
          )}
        </CheckboxPillGroup>
      )}
    />
  );
}
