import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { UserList } from '../../../../entities/v1/User';

export interface ListParams {
  interviewId: number;
  name?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
}

export class InterviewService {
  public static listShareUsers(params: ListParams): Promise<UserList> {
    return ApiService.get<UserList>(
      `/api/v1/interview_intelligence/interviews/${params.interviewId}/share_users`,
      snakecaseKeys(params),
    );
  }
}
