import classNames from 'classnames';
import React from 'react';
import { Size } from '../../utils/types/Size';

interface PropTypes {
  size?: Size;
  className?: string;
}

function sizeToLength(size: Size) {
  // Sizing based on em works best for the export PDF button use case. Review it in case you want to change units.
  const smallSize = '1em';

  switch (size) {
    case 'sm':
      return smallSize;
    case 'md':
      return '1.3em';
    case 'lg':
      return '20px';
    case 'xl':
      return '32px';
    default:
      return smallSize;
  }
}

export function LoadingSpinnerIcon(props: PropTypes) {
  const size = props.size || 'sm';
  const height = sizeToLength(size);

  return (
    <span
      className={classNames(
        'spinner-border',
        `spinner-border-${size}`,
        props.className,
      )}
      style={{ width: height, height: height, top: 0 }}
      role={'status'}
      aria-hidden={'true'}
    />
  );
}
