import React, { useState } from 'react';
import { Interview } from '../../../entities/Interview';
import { ActionPoint } from '../../../entities/ActionPoint';
import { AINotesSubTab } from '../../../entities/AINotesSubTab';
import classNames from 'classnames';
import { InterviewSummaryHeader } from './InterviewSummaryHeader';
import { SelectedSummary } from './SelectedSummary';
import { CustomizeSummary } from './CustomizeSummary';
import { Summary } from '../../../entities/Summary';

interface PropTypes {
  interview: Interview;
  atsEnabled: boolean;
  onSelectTimestamp: (timestamp: number) => void;
  onUpdateActionPoints: (actionPoints: ActionPoint[]) => void;
  customSummaryEnabled: boolean;
  onSummaryChange: (summary: Summary) => void;
  selectedAINotesSubTab: AINotesSubTab;
  setSelectedAINotesSubTab: (selectedAINotesSubTab: AINotesSubTab) => void;
  playTimestamp: number;
}

export function InterviewAINotesTab(props: PropTypes) {
  const [customizingSummary, setCustomizingSummary] = useState(false);

  return (
    <>
      {!customizingSummary ? (
        <>
          <div className={classNames('d-flex', 'flex-column')}>
            <InterviewSummaryHeader
              selectedAINotesSubTab={props.selectedAINotesSubTab}
              onSelectedAINotesSubTab={props.setSelectedAINotesSubTab}
            />
          </div>
          <SelectedSummary
            interview={props.interview}
            atsEnabled={props.atsEnabled}
            onSelectTimestamp={props.onSelectTimestamp}
            onUpdateActionPoints={props.onUpdateActionPoints}
            selectedAINotesSubTab={props.selectedAINotesSubTab}
            onCustomizing={setCustomizingSummary}
            isCustomizingSummary={customizingSummary}
            customSummaryEnabled={props.customSummaryEnabled}
            playTimestamp={props.playTimestamp}
            openExternalLink={true}
          />
        </>
      ) : (
        <CustomizeSummary
          interview={props.interview}
          onCustomizing={setCustomizingSummary}
          onSummaryChange={props.onSummaryChange}
        />
      )}
    </>
  );
}
