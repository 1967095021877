import { ChipColor } from '../components/Chip';

export function applicationStatusChipColor(name: string): ChipColor {
  switch (name) {
    case 'active':
      return ChipColor.Success;
    case 'archived':
      return ChipColor.Danger;
    case 'referral':
      return ChipColor.BlueLight;
    case 'draft':
      return ChipColor.OrangeLight;
    case 'hired':
      return ChipColor.SuccessLight;
    case 'rejected':
      return ChipColor.DangerLight;
  }
}
