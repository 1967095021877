import MetricCard, { Info } from '../../../../../components/MetricCard';
import classNames from 'classnames';
import styles from './styles.module.scss';
import React from 'react';
import { ResponsePercentage } from './ResponsePercentage';
import { CardTitle, Col, Row } from 'reactstrap';

interface PropTypes {
  totalPromoters: number;
  totalNeutrals: number;
  totalDetractors: number;
  totalResponses: number;
}

export function ResponsesCounterPanel(props: PropTypes) {
  return (
    <MetricCard className='h-100'>
      <div className='d-flex align-items-center mb-2'>
        <CardTitle tag='h2' className='mb-1'>
          {'CNPS Responses'}
        </CardTitle>
        <Info
          tooltip={
            'Count of CNPS follow-up question responses and percentage for each kind of response.'
          }
        />
      </div>
      <Row className={classNames('d-flex justify-content-center gx-0')}>
        <Col sm={5} className={classNames('text-left')}>
          <p
            className={classNames(
              styles['count-number'],
              'fw-bold',
              'lh-1',
              'mb-0',
            )}
          >
            {props.totalResponses}
          </p>
          <p className={classNames('fs-6', 'fw-normal', 'text-dark-200')}>
            Responses
          </p>
        </Col>
        <Col sm={7} className={classNames('text-start')}>
          <ResponsePercentage
            categoryCount={props.totalDetractors}
            totalResponses={props.totalResponses}
            category={'Detractors'}
          />
          <ResponsePercentage
            categoryCount={props.totalNeutrals}
            totalResponses={props.totalResponses}
            category={'Neutrals'}
          />
          <ResponsePercentage
            categoryCount={props.totalPromoters}
            totalResponses={props.totalResponses}
            category={'Promoters'}
          />
        </Col>
      </Row>
    </MetricCard>
  );
}
