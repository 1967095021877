import { EmptyState } from '../../../../components/EmptyState';
import React from 'react';

export function InterviewerPulseEmptyState() {
  return (
    <EmptyState
      title='No interviewer feedback available yet'
      text={
        <p>
          Please try a different combination of filters or{' '}
          <a className='link-info' href='mailto:support@screenloop.com'>
            talk with us
          </a>{' '}
          to start collecting interviewer feedback in every step of the way.
        </p>
      }
    />
  );
}
