import React from 'react';
import { Row, Col } from 'reactstrap';
import { getDefaultWidgets } from '../Widgets';
import classNames from 'classnames';

interface Proptypes {
  widgets: JSX.Element[];
}

interface ColumnProptypes {
  widgets: JSX.Element[];
  className?: string;
}

function Column(props: ColumnProptypes) {
  return (
    <Col sm='12' md='4' className={classNames('px-0', props.className)}>
      {props.widgets.map((widget, index) => (
        <div key={index} className={classNames('mx-2 mb-4')}>
          {widget}
        </div>
      ))}
    </Col>
  );
}

export function Body(props: Proptypes) {
  const leftColumnWidgets = props.widgets.filter((_, idx) => idx % 2 === 0);
  const rightColumnWidgets = props.widgets.filter((_, idx) => idx % 2 !== 0);

  return (
    <Row className='gx-4 my-4 mx-0'>
      <Column widgets={leftColumnWidgets} className='pe-1' />
      <Column widgets={rightColumnWidgets} className='px-1' />
      <Column widgets={getDefaultWidgets()} className='ps-1' />
    </Row>
  );
}
