import React from 'react';
import { AreaRichTextComposer } from '../../../../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { UpdateStatePlugin } from '../../../../../../components/RichTextEditor/plugins/UpdateStatePlugin';
import { PasteVariablePlugin } from '../../../../../../components/RichTextEditor/plugins/PasteVariablePlugin';
import { CUSTOMIZATION_VARIABLES, OFFER_VARIABLES } from '../CopyVariables';
import { OfferLetterTemplate } from '../../../../../../entities/v1/applicant_tracking/OfferLetterTemplate';

interface PropTypes {
  editor: string;
  disabled?: boolean;
  referredEmailTemplate?: OfferLetterTemplate;
  setHtml?: (html: string) => void;
  onChangeJson?: (value: string) => void;
}

export function OfferLetterRichTextComposer(props: PropTypes) {
  return (
    <AreaRichTextComposer
      defaultEditorState={props.editor}
      placeholder='Insert offer letter text'
      onChangeHtml={props.setHtml}
      onChangeJson={props.onChangeJson}
      disabled={props.disabled}
      size='md'
      externalPlugins={[
        <UpdateStatePlugin
          key={1}
          editorState={props.referredEmailTemplate?.template}
        />,
        <PasteVariablePlugin
          key={2}
          variables={Object.values(CUSTOMIZATION_VARIABLES).concat(
            Object.values(OFFER_VARIABLES),
          )}
        />,
      ]}
    />
  );
}
