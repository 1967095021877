import React from 'react';
import { Avatar } from '../../../Avatar';
import { SubBlock } from '..';
import { NoteBlock } from '../../NoteBlock';
import { Button } from 'reactstrap';
import { Note } from '../../../../entities/v1/interview_intelligence/Note';
import { MenuType } from '../../MenuType';

export interface PropTypes {
  replies: Note[];
  duration: number;
  menuType: MenuType;
  onSelectTimestamp: (timestamp: number) => void;
  handleClickDelete(noteId: number | null): void;
  showNameAndOffset: boolean;
  showContextQuestion: boolean;
  setIsRepliesExpanded: (value: React.SetStateAction<boolean>) => void;
}

function ViewLessButton(props: {
  setIsRepliesExpanded: (value: React.SetStateAction<boolean>) => void;
}) {
  return (
    <Button
      color='borderless'
      onClick={() => props.setIsRepliesExpanded(false)}
    >
      View Less
    </Button>
  );
}

export function RepliesBlock(props: PropTypes) {
  return (
    <>
      {props.replies.map((note) => (
        <SubBlock key={note.id}>
          <div
            className='d-flex align-items-start'
            data-testid={`NoteBlock-reply-${note.id}`}
          >
            <Avatar name={note.user?.name} type={'initials'} size={'md'} />
            <NoteBlock
              kind='note'
              displayName={note.user?.name}
              totalTime={props.duration}
              body={note.text}
              noteId={note.id}
              deleteAccess={note.deleteAccess}
              menuType={props.menuType}
              onSelectTimestamp={props.onSelectTimestamp}
              onClickDelete={props.handleClickDelete}
              showNameAndOffset={props.showNameAndOffset}
              showContextQuestion={props.showContextQuestion}
              createdDate={note.createdAt}
              replyBox={true}
            />
          </div>
        </SubBlock>
      ))}
      {props.replies.length > 0 && (
        <div className='mt-2'>
          <ViewLessButton setIsRepliesExpanded={props.setIsRepliesExpanded} />
        </div>
      )}
    </>
  );
}
