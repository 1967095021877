import { TaxonomyEducationAndSeniorityLevel } from '@vonq/hapi-elements-types';
import { multiLangTargetGroupFromOption } from './utils';
import { Select, SelectOption } from '../../../../../../components/Select';
import React from 'react';

interface PropTypes {
  name: string;
  label: string;
  values: TaxonomyEducationAndSeniorityLevel[];
  selected?: { vonqId: string | number; description: string };
  onInputChange: (
    name: string,
    value: { vonqId: string | number; description: string },
  ) => void;
}

export function MultiLangTargetGroupSelect(props: PropTypes) {
  const options = props.values?.map((value) => ({
    value: value.id.toString(),
    label: value.name.find((value) => value.languageCode === 'en_GB').value,
  }));
  const selected = options?.find(
    (option) => option.value === props.selected?.vonqId,
  );

  return (
    <div>
      <label className='form-label'>
        {props.label} <span className='ps-1 text-danger'>*</span>
      </label>
      <Select
        options={options}
        isLoading={options == null}
        selected={selected}
        placeholder={`Select ${props.label}`}
        onChange={(option: SelectOption) =>
          props.onInputChange(
            props.name,
            multiLangTargetGroupFromOption(option),
          )
        }
      />
    </div>
  );
}
