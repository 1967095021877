import classNames from 'classnames';
import React from 'react';
import { ConditionsSelectOptions } from '..';
import { ConditionsData } from '../../../../..';
import { LabelledSelect } from '../../../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../../../components/Select';
import { computeNewConditions } from '../../../../../utils/computeNewConditions';

interface PropTypes {
  conditionsSelectOptions: ConditionsSelectOptions;
}

const OPTIONS = [
  { value: 'false', label: 'Includes' },
  { value: 'true', label: 'Excludes' },
];

function hasInvertCondition(
  conditionsData: ConditionsData,
  condition: SelectOption,
) {
  return conditionsData[condition.value]?.hasInvertCondition;
}

export function IncludesLabelledSelectCol(props: PropTypes) {
  return (
    <div className='col-2'>
      <LabelledSelect
        options={OPTIONS}
        selected={props.conditionsSelectOptions.invert}
        onChange={(e) => {
          const newConditions = computeNewConditions(
            {
              index: props.conditionsSelectOptions.index,
              type: props.conditionsSelectOptions.type,
              invert: e,
              singleSelected: props.conditionsSelectOptions.singleSelected,
              multiSelected: props.conditionsSelectOptions.multiSelected,
            },
            props.conditionsSelectOptions.conditions,
            props.conditionsSelectOptions.conditionsData,
          );
          props.conditionsSelectOptions.setConditions(newConditions);
        }}
        label=' '
        className={classNames('w-100', {
          'mt-4': props.conditionsSelectOptions.index <= 0,
        })}
        placeholder={'Includes'}
        disabled={
          !props.conditionsSelectOptions.selectedSurveyTemplate ||
          !hasInvertCondition(
            props.conditionsSelectOptions.conditionsData,
            props.conditionsSelectOptions.type,
          )
        }
      />
    </div>
  );
}
