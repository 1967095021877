import React, { useState } from 'react';
import { Session } from '../../../../entities/training/Session';
import { Col, Row } from 'reactstrap';
import { Metrics } from '../../../../components/training/Metrics';
import { Topics } from '../../../../components/training/Topics';
import { SessionActionsMenu } from '../SessionActionsMenu';
import { User } from '../../../../entities/User';
import SessionService from '../../../../services/training/SessionService';
import { ApiError } from '../../../../services/ApiService/errors/ApiError';
import { ActionSessionAlert } from '../../ActionSessionAlert';

interface PropTypes {
  currentUser: User;
  session: Session;
  onChangeSession: (session: Session) => void;
}

function SessionBody(props: {
  session: Session;
  currentUser: User;
  assignModalVisible: boolean;
  onAssignModalVisible: (flag: boolean) => void;
  onAssign: (sessionId: number, users: number[]) => void;
}) {
  return (
    <Row className='align-items-center justify-content-between mt-3'>
      <Col>
        <Metrics
          durationMillis={props.session.durationMillis}
          views={props.session.views}
          rating={props.session.averageRating}
          showRating={props.currentUser.isAdmin}
        />
      </Col>
      <Col xs='auto'>
        <SessionActionsMenu
          assignModalVisible={props.assignModalVisible}
          onAssignModalVisible={props.onAssignModalVisible}
          session={props.session}
          currentUser={props.currentUser}
          onAssign={props.onAssign}
        />
      </Col>
    </Row>
  );
}

export function SessionCardBody(props: PropTypes) {
  const [assignedSuccessfully, setAssignedSuccessfully] = useState(null);
  const [assignModalVisible, setAssignModalVisible] = useState<boolean>(false);

  async function handleAssign(sessionId: number, users: number[]) {
    try {
      const sessionResult = await SessionService.assign(sessionId, users);
      props.onChangeSession(sessionResult);
      setAssignedSuccessfully(true);
      setAssignModalVisible(false);
    } catch (err: unknown) {
      if (err instanceof ApiError) {
        setAssignedSuccessfully(false);
        setAssignModalVisible(false);
      }

      throw err;
    }
  }

  return (
    <div className='p-2'>
      <ActionSessionAlert
        successful={assignedSuccessfully}
        sessionName={props.session.name}
        onClose={() => setAssignedSuccessfully(null)}
        successfulMessage={'was successfully assigned'}
        errorMessage={'was not assigned successfully'}
      />
      <Topics fixedHeight topics={props.session.topics} />
      <SessionBody
        session={props.session}
        currentUser={props.currentUser}
        assignModalVisible={assignModalVisible}
        onAssignModalVisible={setAssignModalVisible}
        onAssign={handleAssign}
      />
    </div>
  );
}
