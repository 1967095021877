import React from 'react';
import { Button } from 'reactstrap';

interface PropTypes {
  onClose: () => void;
}

export function CloseButton(props: PropTypes) {
  return (
    <div className='d-flex justify-content-end'>
      <Button
        type='button'
        color='transparent'
        className='btn-close'
        data-bs-dismiss='alert'
        data-dismiss='alert'
        aria-label='Close'
        onClick={props.onClose}
      ></Button>
    </div>
  );
}
