import React from 'react';
import { Filters } from '../../../../components/candidate_experience/Filters';
import {
  FilterStates,
  OnChangeFilterStatesCallback,
} from '../../../../components/candidate_experience/Filters/filterState';
import { NPSExplainabilityAnalytics } from './NPSExplainabilityAnalytics';

interface PropTypes {
  filterStates: FilterStates;
  onApplyFilter: OnChangeFilterStatesCallback;
  pulseStandardNpsCalculation: boolean;
}

export function NPSExplainabilityTab(props: PropTypes) {
  return (
    <>
      <Filters
        filterStates={props.filterStates}
        onApplyFilter={props.onApplyFilter}
        moreFiltersShown={false}
        hideJob={true}
        hideMoreFiltersButton={true}
        candidateDemographicQuestionsEnabled={false}
        hideBenchmark={true}
        className='mb-4'
      />
      <NPSExplainabilityAnalytics
        filterStates={props.filterStates}
        onApplyFilter={props.onApplyFilter}
        standardNpsCalculation={props.pulseStandardNpsCalculation}
      />
    </>
  );
}
