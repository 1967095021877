import React, { useEffect } from 'react';
import { DisabledPasswordAuthenticationAlert } from '../DisabledPasswordAuthenticationAlert';
import { Alert, AlertType } from '../../../../components/Alert';
import { handlePasswordSubmission } from '../../handlePasswordSubmission';
import { AccountSettingsSecurityService } from '../../../../services/AccountSettingsSecurityService';
import { usePasswordErrorHandling } from '../../usePasswordErrorHandling';
import { useForm } from 'react-hook-form';
import { Card } from 'reactstrap';
import { ChangePasswordButton } from './ChangePasswordButton';
import { InputRows } from './InputRows';
import { FormValues } from '../../UserInvitationPage';

interface PropTypes {
  allowPassword: boolean;
  supportEmail: string;
}

export function NewPasswordForm(props: PropTypes) {
  const { register, handleSubmit, watch, resetField } = useForm<FormValues>({
    mode: 'onSubmit',
  });

  const {
    alertActive,
    setAlertActive,
    hasPasswordValidationFailed,
    setPasswordValidationFailed,
    errorMessage,
    handlePasswordError,
    isPasswordInvalid,
    setIsPasswordInvalid,
  } = usePasswordErrorHandling(resetField);

  const currentPassword = watch('currentPassword');
  const password = watch('password');
  const passwordConfirmation = watch('passwordConfirmation');

  const isFormValid = !!(currentPassword && password && passwordConfirmation);

  const onSubmit = async (data: FormValues) => {
    const { currentPassword, password, passwordConfirmation } = data;

    await handlePasswordSubmission(
      password!,
      passwordConfirmation!,
      handlePasswordError,
      AccountSettingsSecurityService.patch(
        currentPassword,
        password!,
        passwordConfirmation!,
      ),
      '/account_settings/security?success=Password updated successfully',
    );
  };

  useEffect(() => {
    if (password || passwordConfirmation) {
      setPasswordValidationFailed(false);
    }
    if (currentPassword) {
      setIsPasswordInvalid(false);
    }
  }, [password, passwordConfirmation, currentPassword]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {alertActive && (
        <Alert
          type={AlertType.Danger}
          clearable={true}
          onClose={() => setAlertActive(false)}
          autoClearTimeout={4000}
        >
          {errorMessage}
        </Alert>
      )}
      {!props.allowPassword && (
        <DisabledPasswordAuthenticationAlert
          setAlertActive={setAlertActive}
          supportEmail={props.supportEmail}
        />
      )}
      <Card className='p-4'>
        <div className='pt-3 px-3'>
          <h2>Password</h2>
        </div>
        <InputRows
          register={register}
          hasPasswordValidationFailed={hasPasswordValidationFailed}
          isPasswordInvalid={isPasswordInvalid}
        />
        <ChangePasswordButton
          isFormValid={isFormValid}
          allowPassword={props.allowPassword}
        />
      </Card>
    </form>
  );
}
