import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  text: string | ReactNode;
  className?: string;
}

export function TextPanel(props: PropTypes) {
  return (
    <div
      className={classNames(
        'bg-gray',
        'fs-5',
        'p-3',
        'rounded-2',
        'h-100',
        props.className || styles.summary,
      )}
    >
      <span>{props.text}</span>
    </div>
  );
}
