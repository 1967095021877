import React, { useState } from 'react';
import { Application } from '../../../../../../../../entities/Application';
import {
  AlertObject,
  AlertType,
} from '../../../../../../../../components/Alert';
import { IconButton } from '../../../../../../../../components/IconButton';
import { NoteModal } from '../../../../../../../../components/ApplicantTracking/NoteModal';
import { NoteService } from '../../../../../../../../services/applicant_tracking/NoteService';

interface PropTypes {
  application: Application;
  setAlert: (alert: AlertObject) => void;
}

export function AddNote(props: PropTypes) {
  const [isNoteModalOpen, setIsNoteModalOpen] = useState<boolean>(false);

  const onAddNote = async (note: string) => {
    try {
      await NoteService.create({
        applicationId: props.application.id,
        note: note,
      });
      props.setAlert({
        type: AlertType.Success,
        message: (
          <>
            <span className='fw-semibold'>Note successfully added.</span> To
            follow-up, go to candidate activity page!
          </>
        ),
      });
    } catch (error) {
      props.setAlert({
        type: AlertType.Danger,
        message: 'There was an error adding the note.',
      });
    }
  };

  return (
    <>
      <NoteModal
        mode='Add'
        onSubmit={onAddNote}
        isOpen={isNoteModalOpen}
        disableAlerts={true}
        onClose={() => setIsNoteModalOpen(false)}
      />
      <IconButton
        iconName='bi-file-earmark-text'
        buttonText='Add Note'
        buttonClass='me-2h'
        color='borderless'
        onClick={() => setIsNoteModalOpen(true)}
      />
    </>
  );
}
