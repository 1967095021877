import { Nav } from 'reactstrap';
import React from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router';
import { JobDetailsNavLink } from './JobDetailsNavLink';
import { JobPageTab } from '../JobPageTab';

interface PropTypes {
  activeTab: JobPageTab;
  jobBoardEnabled: boolean;
}

export function TopTabNavigation(props: PropTypes) {
  const navigate = useNavigate();
  const jobId = useParams().id;

  function handleClick(tab: string) {
    navigate(
      `/jobs/${jobId}/${tab.replace(/\s+/g, '_').toLowerCase()}/${
        window.location.search
      }`,
    );
  }
  return (
    <>
      <Nav className={classNames('flex-wrap mb-4 app-nav-tabs bg-transparent')}>
        <JobDetailsNavLink
          dataTestid={'overview'}
          tab={'Overview'}
          handleClick={handleClick}
          tabTitle={'Overview'}
          activeTab={props.activeTab}
        />
        <JobDetailsNavLink
          dataTestid={'pipeline'}
          tab={'Pipeline'}
          handleClick={handleClick}
          tabTitle={'Pipeline'}
          activeTab={props.activeTab}
        />
        {props.jobBoardEnabled && (
          <JobDetailsNavLink
            dataTestid={'jobboard'}
            tab={'Job Boards'}
            handleClick={handleClick}
            tabTitle={'Job Boards'}
            activeTab={props.activeTab}
          />
        )}
      </Nav>
    </>
  );
}
