import React from 'react';
import { CardHeaderRow } from '../CardHeaderRow';
import { CardSummarySectionInpuRow } from './CardSummarySectionInpuRow';
import { CardSummarySectionTitlesRow } from './CardSummarySectionTitlesRow';

export interface CardSummary {
  onSurveyTriggerNameChange: (value: string) => void;
  surveyTriggerStatus: boolean;
  onSurveyTriggerStatusChange: (value: boolean) => void;
  surveyTriggerName?: string;
}
interface PropTypes {
  cardSummary: CardSummary;
}

export function CardSummaryRow(props: PropTypes) {
  return (
    <div className='item border-bottom py-3'>
      <div className='row justify-content-between align-items-center'>
        <CardHeaderRow
          title={'Summary'}
          subtitle={'Basic details and status.'}
        />
        <div className='row pt-4'>
          <CardSummarySectionTitlesRow />
          <CardSummarySectionInpuRow cardSummary={props.cardSummary} />
        </div>
      </div>
    </div>
  );
}
