import React from 'react';
import { Col } from 'reactstrap';
import { SelectOption } from '../../../../components/Select';
import { QuestionTopChoices } from '../../../../entities/candidate_experience/QuestionTopChoices';
import { randomColorWithoutRepetition } from '../../../../utils/randomColorWithoutRepetition';
import { TopChoiceTablePanel } from '../../shared_panels/TablePanel/TopChoiceTablePanel';
import stylesheetExports from '../../../../stylesheets/export.module.scss';

interface PropTypes {
  option: QuestionTopChoices;
  benchmarkType: SelectOption;
  colors: string[];
}

export function ExtraMultiOptionsLastEven(props: PropTypes) {
  return (
    <Col sm='12' lg='6' className='pb-4'>
      <TopChoiceTablePanel
        title=''
        subtitle={props.option.question_text}
        tableClassName={'table-borderless'}
        entries={props.option.top_choices.map((tc) => ({
          text: tc.name,
          average: parseFloat(tc.weight),
          benchmarkAverage: parseFloat(tc.benchmark_weight),
        }))}
        benchmarkType={props.benchmarkType}
        multiplier={100}
        displayPercentage={true}
        fillColor={randomColorWithoutRepetition(
          props.colors,
          stylesheetExports.pink,
        )}
      />
    </Col>
  );
}
