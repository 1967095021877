import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Application } from '../../../entities/Application';
import { TextAreaInputFormField } from '../../TextAreaInputFormField';
import { useForm, useWatch } from 'react-hook-form';
import { StarRating } from '../../StarRating';
import { Avatar } from '../../Avatar';
import { ApplicationRatingService } from '../../../services/v1/applicant_tracking/ApplicationRatingService';
import { ApplicationRating } from '../../../entities/v1/applicant_tracking/ApplicationRating';

interface PropTypes {
  application: Application;
  isOpen: boolean;
  applicationRating?: ApplicationRating;
  onClose: (succeeded?: boolean) => void;
}

export function ModalBody(props: PropTypes) {
  const [rating, setRating] = useState<number | null>(
    props.applicationRating?.rating || null,
  );

  const { register, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      comment: props.applicationRating?.comment || null,
    },
  });
  const comment = useWatch({ name: 'comment', control });

  const submitRating = () => {
    ApplicationRatingService.upsert({
      id: props.applicationRating?.id,
      applicationId: props.application.id,
      rating: rating,
      comment: comment,
    })
      .then(() => props.onClose(true))
      .catch(() => props.onClose(false));
  };

  return (
    <>
      <div className='d-flex mb-4 align-items-center'>
        <Avatar name={props.application.candidate.name} />
        <h4 className='ms-1 me-3 mb-0'>{props.application.candidate.name}</h4>
        <StarRating
          editable={true}
          rating={rating}
          setRating={setRating}
          fillColorClass='text-info'
        />
      </div>
      <TextAreaInputFormField
        rows={6}
        maxLength={1024}
        isRequired={false}
        preventResize={true}
        maxLengthAtTop={true}
        headerClassNames='fw-semibold'
        registerReturn={register('comment')}
        classNames='fs-5 text-dark-500'
        placeholder='Insert your overall impression'
        fieldName={`What's your overall impression of this candidate?`}
      />
      <div className='d-flex justify-content-end mt-4 gap-3 w-100'>
        <Button color='danger' onClick={() => props.onClose(null)}>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={submitRating}
          disabled={rating === null}
        >
          Add
        </Button>
      </div>
    </>
  );
}
