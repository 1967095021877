import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { DepartmentList } from '../../../../entities/v1/DepartmentList';

export interface ListParams {
  name?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
}

export class DepartmentService {
  public static list(params: ListParams) {
    return ApiService.get<DepartmentList>(
      '/api/v1/applicant_tracking/departments',
      snakecaseKeys(params),
    );
  }
}
