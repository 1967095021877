import React from 'react';
import { Col, Row } from 'reactstrap';
import { Panel } from '../../../components/Panel';
import { Survey } from '../../../entities/Survey';

interface PropTypes {
  survey: Survey;
}

export function SelfAssessmentSurveyFinalScreen(props: PropTypes) {
  return (
    <Panel>
      <Row className='text-center mt-5'>
        <Col>
          <h1>
            <i
              className='bi bi-check-circle text-success'
              style={{ width: '1em', height: '1em' }}
            />
          </h1>
          <Row className='mb-2'>
            <Col>
              <h1>Success!</h1>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col md='8' className='offset-md-2'>
              <p color='secondary'>Thank you for filling your assessment.</p>
            </Col>
          </Row>
          <Row>
            <Col xs='12' className='mb-3'>
              <a
                className='btn btn-primary px-5 py-3'
                href={`/reference_check/candidate/survey_requests/${props.survey.survey_request_id}`}
              >
                Go back to References
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Panel>
  );
}
