import {
  Campaign,
  CampaignProductSpecs,
  CampaignStatus,
} from '@vonq/hapi-elements-types';
import React from 'react';
import { AccordionPanel } from '../../../../../../components/AccordionPanel';
import { CampaignHeader } from './CampaignHeader';
import { CampaignRow } from '../CampaignRow';
import { humanize } from '../../../../../../utils/humanize';
import { Chip, ChipColor } from '../../../../../../components/Chip';
import { IconButton } from '../../../../../../components/IconButton';

interface PropTypes {
  campaign: Campaign;
  opened: boolean;
}

function Status(props: { product: CampaignProductSpecs }) {
  return (
    <div className='d-flex flex-nowrap gap-2'>
      <Chip color={getStatusChipColor(props.product.status)}>
        {humanize(props.product.status)}
      </Chip>
      {props.product.jobBoardLink && (
        <IconButton
          color='borderless'
          size='sm'
          iconName={'bi-box-arrow-up-right'}
          buttonText='View Post'
          onClick={() =>
            window.open(
              props.product.jobBoardLink,
              '_blank',
              'noopener,noreferrer',
            )
          }
        />
      )}
    </div>
  );
}

function getPosting(campaign: Campaign, productId: string, index: number) {
  const posting = campaign.postings?.[index];
  if (posting?.productId === productId) {
    return posting;
  }

  return null;
}

function getName(campaign: Campaign, productId: string, index: number) {
  return getPosting(campaign, productId, index)?.name;
}

function getClicks(campaign: Campaign, productId: string, index: number) {
  return getPosting(campaign, productId, index)?.clicks;
}

function getStatusChipColor(status: CampaignStatus): ChipColor {
  switch (status) {
    case 'online':
      return ChipColor.SuccessLight;
    case 'offline':
      return ChipColor.DangerLight;
    case 'in progress':
      return ChipColor.BlueLight;
    case 'not processed':
      return ChipColor.OrangeLight;
  }
}

export function CampaignDetail(props: PropTypes) {
  return (
    <AccordionPanel
      title={<CampaignHeader campaign={props.campaign} />}
      className={'mb-3'}
      id={`${props.campaign.campaignId}`}
      highlightOnHover={true}
      opened={props.opened}
    >
      <div className='d-flex flex-column gap-3h pb-4'>
        {props.campaign.orderedProductsSpecs.map(
          (product: CampaignProductSpecs, index: number) => (
            <CampaignRow
              key={index}
              classNames='pe-3'
              highlightName={false}
              name={getName(props.campaign, product.productId, index)}
              datePosted={product.deliveredOn}
              duration={product.durationPeriod}
              clicks={getClicks(props.campaign, product.productId, index)}
              status={<Status product={product} />}
            />
          ),
        )}
      </div>
    </AccordionPanel>
  );
}
