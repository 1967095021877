import classNames from 'classnames';
import React from 'react';
import { InterviewMarker } from '../../../../../entities/InterviewMarker';
import { CommonListPanel } from '../../CommonListPanel';
import { InterviewMarkerPart } from './InterviewMarkerPart';

interface PropTypes {
  interviewMarkers: InterviewMarker[];
}

function HighlightsHeader() {
  return <h2 className={classNames('ms-3', 'mb-3', 'fs-4')}>Highlights</h2>;
}

export function InterviewMarkersPanel(props: PropTypes) {
  return (
    <CommonListPanel
      header={<HighlightsHeader />}
      classNames='pt-3 rounded-4 h-100'
    >
      <div id='interview_markers'>
        {props.interviewMarkers.map((interviewMarker) => (
          <InterviewMarkerPart
            key={interviewMarker.id}
            interviewMarker={interviewMarker}
          />
        ))}
      </div>
    </CommonListPanel>
  );
}
