import { Survey } from '../../entities/survey_engine/Survey';
import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';

export function getQuestions(survey: Survey): SurveyQuestion[] {
  if (survey === undefined) return [];

  const questions = survey.sections
    .map((s) => s.questions)
    .reduce((prev, curr) => prev.concat(curr), [])
    .filter((q) => q.id != null);

  return questions;
}
