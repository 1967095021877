import { ConditionsData } from '..';
import { SelectOption } from '../../../../../components/Select';
import { SurveyTemplate } from '../../../../../entities/candidate_experience/SurveyTemplate';
import { filterSurveyTemplate } from './filterSurveyTemplate';
import { findMandatoryCondition } from './findMandatoryCondition';

export function findMandatoryConditions(
  st: SelectOption,
  surveyTemplates: SurveyTemplate[],
  conditionsData: ConditionsData,
) {
  const mandatoryConditions = [];
  const surveyTemplate = filterSurveyTemplate(st, surveyTemplates);

  for (const [key, value] of Object.entries(
    surveyTemplate.trigger_requirements,
  )) {
    if (value === 'mandatory') {
      findMandatoryCondition(
        mandatoryConditions,
        conditionsData[key].value,
        conditionsData[key].label,
        conditionsData[key].options,
      );
    }
  }

  return mandatoryConditions;
}
