import { BackgroundCheckStatus } from '../../../../entities/BackgroundCheck';

const statusMapping: {
  [idx in BackgroundCheckStatus]: { color: string; label: string };
} = {
  WAITING_ON_CANDIDATE: {
    color: 'light-warning',
    label: 'Waiting on candidate',
  },
  IN_PROGRESS: { color: 'light-info', label: 'In progress' },
  ACTION_REQUIRED: { color: 'light-warning', label: 'Action required' },
  COMPLETE: { color: 'light-success', label: 'Complete' },
  CANCELLED: { color: 'grey', label: 'Cancelled' },
  Analyzing: { color: 'light-warning', label: 'Analyzing' },
  Rejected: { color: 'light-danger', label: 'Rejected' },
  Partial: { color: 'light-warning', label: 'Partial' },
  Pending: { color: 'light-warning', label: 'Waiting on candidate' },
  Accepted: { color: 'light-warning', label: 'Accepted' },
  Returned: { color: 'light-success', label: 'Returned' },
  Cancelled: { color: 'light-danger', label: 'Cancelled' },
  None: { color: 'grey', label: 'None' },
  ActionRequired: { color: 'light-warning', label: 'ActionRequired' },
  Unresponsive: { color: 'grey', label: 'Unresponsive' },
  'Awaiting allocation': {
    color: 'light-warning',
    label: 'Awaiting allocation',
  },
  'Awaiting data entry': {
    color: 'light-warning',
    label: 'Awaiting data entry',
  },
  'Awaiting consent': { color: 'light-warning', label: 'Awaiting consent' },
  'In-Progress': { color: 'light-warning', label: 'In-Progress' },
  'On hold': { color: 'light-warning', label: 'On hold' },
  'Under Review': { color: 'light-warning', label: 'Under Review' },
  'Waiting on Candidate': {
    color: 'light-warning',
    label: 'Waiting on Candidate',
  },
  'Waiting on Client': { color: 'light-warning', label: 'Waiting on Client' },
  'Further Enquiries': { color: 'light-warning', label: 'Further Enquiries' },
  Completed: { color: 'light-success', label: 'Completed' },
  Archived: { color: 'grey', label: 'Archived' },
  Allocated: { color: 'light-warning', label: 'Allocated' },
  'Client Approval': { color: 'light-warning', label: 'Client Approval' },
  Unknown: { color: 'grey', label: 'Unknown' },
};

export function statusToColor(status: string): string {
  return statusMapping[status]['color'];
}

export function statusToLabel(status: string): string {
  return statusMapping[status]['label'];
}
