import { Input } from 'reactstrap';
import { IconButton } from '../../../../../components/IconButton';
import React from 'react';
import classNames from 'classnames';
import styles from '../../styles.module.scss';
import {
  SOURCE_NAME_MAX_CHARACTERS,
  SourceData,
  emptySourceData,
} from '../../util';
import { ToggleButton } from '../../../../../components/ToggleButton';

interface PropTypes {
  sources: SourceData[];
  duplicates: SourceData[];
  readonly?: boolean;
  singleInput?: boolean;
  setSources?: (sources: SourceData[]) => void;
}

interface SourceInputProps extends PropTypes {
  source: SourceData;
  index: number;
}

function SourceInput(props: SourceInputProps) {
  const hideRemoveButton = props.readonly || props.singleInput;
  const isInvalid =
    props.duplicates.some(
      (duplicate) => duplicate.name === props.source.name,
    ) || props.source.name.length > SOURCE_NAME_MAX_CHARACTERS;

  function updateSource(
    name: string,
    enabledOnCareers: boolean,
    index: number,
  ) {
    const newSources = [...props.sources];
    newSources[index].name = name;
    newSources[index].enabledOnCareers = enabledOnCareers;
    props.setSources(newSources);
  }

  function removeSource(index: number) {
    const newSources = [...props.sources];
    newSources.splice(index, 1);
    props.setSources(newSources);
  }

  return (
    <div className='d-flex mb-2h align-items-center'>
      <Input
        value={props.source.name}
        placeholder='Insert source'
        className={classNames(
          'fs-5 me-4',
          isInvalid ? 'is-invalid text-danger-500' : 'text-dark-200',
          styles['sources-container'],
        )}
        disabled={props.readonly}
        onChange={(e) =>
          updateSource(
            e.target.value,
            props.source.enabledOnCareers,
            props.index,
          )
        }
      />
      <ToggleButton
        checked={props.source.enabledOnCareers}
        name='enabledOnCareers'
        size='sm'
        disabled={props.readonly}
        onChange={() => {
          updateSource(
            props.source.name,
            !props.source.enabledOnCareers,
            props.index,
          );
        }}
      />
      <div
        className={classNames('ms-2 text-dark-500 fw-semibold fs-5', {
          'text-muted': props.readonly,
        })}
      >
        Public
      </div>
      {!hideRemoveButton && (
        <IconButton
          color='borderless'
          buttonClass='text-dark-200 ms-2h'
          iconName='bi-trash'
          disabled={props.sources.length === 1}
          onClick={() => removeSource(props.index)}
        />
      )}
    </div>
  );
}

export function SourcesInput(props: PropTypes) {
  const handleAddInput = () => {
    props.setSources([...props.sources, emptySourceData()]);
  };

  return (
    <>
      {props.sources.map((source, index) => (
        <SourceInput key={index} index={index} source={source} {...props} />
      ))}
      {!props.singleInput && (
        <div className='mt-1'>
          <IconButton
            color='borderless'
            iconName='bi-plus-circle'
            buttonText='Add Source'
            onClick={handleAddInput}
          />
        </div>
      )}
    </>
  );
}
