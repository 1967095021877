import classNames from 'classnames';
import { Dropdown } from '../../../../components/Dropdown';
import { PropTypes as ActionPropTypes } from '../../../../components/Dropdown/DropdownMenu/DropdownAction';
import styles from './styles.module.scss';
import React, { useState } from 'react';
import { IconSpan } from '../../../../components/IconSpan';
import { Note } from '../../../../entities/ApplicationFeed';
import { DeleteNoteModal } from './DeleteNoteModal';
import { AlertObject, AlertType } from '../../../../components/Alert';
import { User } from '../../../../entities/User';
import { NoteModal } from '../../../../components/ApplicantTracking/NoteModal';
import { NoteService } from '../../../../services/applicant_tracking/NoteService';

interface PropTypes {
  entry: Note;
  currentUser: User;
  reloadApplication: () => void;
  setAlert: (data: AlertObject) => void;
}

function editNote(action: () => void): ActionPropTypes {
  return {
    action: action,
    buttonChild: (
      <IconSpan
        spanText='Edit'
        icon={{ name: 'bi-pencil', className: 'fs-4' }}
        className='text-info px-1'
      />
    ),
  };
}

function deleteNote(action: () => void): ActionPropTypes {
  return {
    action: action,
    buttonChild: (
      <IconSpan
        spanText='Delete'
        icon={{ name: 'bi-trash', className: 'fs-4' }}
        className='text-info px-1'
      />
    ),
  };
}

export function NoteActionDropdown(props: PropTypes) {
  if (props.currentUser.id !== props.entry.authorId || props.entry.deletedAt) {
    return null;
  }

  const [isEditNoteModalOpen, setIsEditNoteModalOpen] = useState(false);
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);

  const onDeleteNote = (succeeded?: boolean) => {
    if (succeeded) {
      props.reloadApplication();
      props.setAlert({
        message: 'The note was successfully deleted.',
        type: AlertType.Success,
      });
    } else if (succeeded === false) {
      props.setAlert({
        message: 'There was an error deleting the note.',
        type: AlertType.Danger,
      });
    }
    setIsDeleteNoteModalOpen(false);
  };

  const onEditNote = async (note: string) => {
    await NoteService.edit({
      id: props.entry.id,
      note: note,
    });
  };

  const onCloseEditNote = (succeeded?: boolean) => {
    succeeded && props.reloadApplication();
    setIsEditNoteModalOpen(false);
  };

  return (
    <>
      <Dropdown
        buttonIcon={{
          name: 'bi-three-dots',
          className: classNames(styles['action-dots'], 'text-info fs-6'),
        }}
        menuPlacementClass='dropdown-menu-end'
        buttonClassName={classNames('ms-2 px-0 py-1', styles['action-button'])}
        menuActions={[
          editNote(() => setIsEditNoteModalOpen(true)),
          deleteNote(() => setIsDeleteNoteModalOpen(true)),
        ]}
      />
      <NoteModal
        mode='Edit'
        initNote={props.entry.note}
        isOpen={isEditNoteModalOpen}
        onClose={onCloseEditNote}
        onSubmit={onEditNote}
        customValidator={(note: string) => note === props.entry.note}
      />
      <DeleteNoteModal
        noteId={props.entry.id}
        isOpen={isDeleteNoteModalOpen}
        onClose={onDeleteNote}
      />
    </>
  );
}
