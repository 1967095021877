import { ApiService } from '../../ApiService';
import { Location } from '../../../entities/applicant_tracking/Location';
import { UpsertLocationDto } from '../../../containers/applicant_tracking/Locations/SetupLocationPage/UpsertLocationDto';
import snakecaseKeys from 'snakecase-keys';

export class LocationService {
  public static list(): Promise<Location[]> {
    return ApiService.get<Location[]>('/api/applicant_tracking/locations');
  }

  public static get(id: number | string): Promise<Location> {
    return ApiService.get<Location>(`/api/applicant_tracking/locations/${id}`);
  }

  public static upsert(location: UpsertLocationDto): Promise<Location> {
    if (location.id == null) {
      return ApiService.post<Location>(
        '/api/applicant_tracking/locations',
        snakecaseKeys(flatten(location)),
      );
    } else {
      return ApiService.put<Location>(
        `/api/applicant_tracking/locations/${location.id}`,
        snakecaseKeys(flatten(location)),
      );
    }
  }
}

function flatten(location: UpsertLocationDto) {
  return {
    name: location.name,
    address: location.location.address,
    city: location.location.city,
    state: location.location.state,
    country: location.location.country,
    latitude: location.location.latitude,
    longitude: location.location.longitude,
  };
}
