import { ApiService } from '../../ApiService';
import snakecaseKeys from 'snakecase-keys';
import { CreateCandidateDto } from './CreateCandidateDto';
import { UpdateCandidateDto } from './UpdateCandidateDto';
import { Candidate } from '../../../entities/applicant_tracking/Candidate';
import { CandidateDto } from './CandidateDto';

export class CandidateService {
  public static get(id: string): Promise<Candidate> {
    return ApiService.get<Candidate>(
      `/api/applicant_tracking/candidates/${id}`,
    );
  }

  public static upsertCandidate(
    data: CandidateDto,
    isEditing: boolean,
  ): Promise<Candidate> {
    let address = data.address;
    if (data.location?.country != null) {
      address = data.location.address;
    }

    if (data.id == null) {
      return CandidateService.create(
        {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          status: 'draft',
          address: address,
          location: data.location,
          linkedinUrl: data.linkedinUrl,
        },
        isEditing,
      );
    } else {
      return CandidateService.update(
        {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          status: data.status,
          address: address,
          location: data.location,
          linkedinUrl: data.linkedinUrl,
        },
        isEditing,
      );
    }
  }

  public static create(
    candidate: CreateCandidateDto,
    isEditing: boolean,
  ): Promise<Candidate> {
    return ApiService.post<Candidate>(
      '/api/applicant_tracking/candidates',
      snakecaseKeys({
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        email: candidate.email,
        phoneNumber: candidate.phoneNumber,
        status: 'draft',
        address: candidate.address,
        city: candidate.location?.city,
        state: candidate.location?.state,
        country: candidate.location?.country,
        linkedinUrl: candidate.linkedinUrl,
        isEditing,
      }),
    );
  }

  public static update(
    candidate: CreateCandidateDto & UpdateCandidateDto,
    isEditing: boolean,
  ): Promise<Candidate> {
    return ApiService.put<Candidate>(
      `/api/applicant_tracking/candidates/${candidate.id}`,
      snakecaseKeys({
        id: candidate.id,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        email: candidate.email,
        phoneNumber: candidate.phoneNumber,
        status: candidate.status,
        address: candidate.address,
        city: candidate.location?.city,
        state: candidate.location?.state,
        country: candidate.location?.country,
        linkedinUrl: candidate.linkedinUrl,
        isEditing,
      }),
    );
  }

  public static archive(id: number): Promise<void> {
    return ApiService.put(`/api/applicant_tracking/candidates/${id}/archive`);
  }

  public static delete(id: number): Promise<void> {
    return ApiService.delete(`/api/applicant_tracking/candidates/${id}`);
  }

  public static unarchive(id: number): Promise<void> {
    return ApiService.put(`/api/applicant_tracking/candidates/${id}/unarchive`);
  }
}
