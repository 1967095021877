import React from 'react';
import { QuestionAverage } from '../../../../../../entities/quality_of_hire/QuestionAverage';
import { Category } from './Category';
import { OverallAverage } from './OverallAverage';
import { OverallAverageHealthBar } from './OverallAverageHealthBar';
import { SpecificAverage } from './SpecificAverage';
import { ChipColor } from '../../../../../../components/Chip';

interface PropTypes {
  questionAverages: QuestionAverage[];
}

export function TableBody(props: PropTypes) {
  return (
    <tbody>
      {props.questionAverages.map((questionAverage, idx) => (
        <tr key={idx}>
          <Category tag={questionAverage.tag} />
          <SpecificAverage
            average={questionAverage.hiring_manager_average}
            trend={questionAverage.hiring_manager_average_trend}
            chipColor={ChipColor.BlueLight}
          />
          <SpecificAverage
            average={questionAverage.employee_average}
            trend={questionAverage.employee_average_trend}
            chipColor={ChipColor.Gray}
          />
          <OverallAverage
            overallAverage={questionAverage.overall_average}
            trend={questionAverage.overall_average_trend}
          />
          <OverallAverageHealthBar
            overallAverage={questionAverage.overall_average}
          />
        </tr>
      ))}
    </tbody>
  );
}
