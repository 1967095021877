import React, { useEffect, useState } from 'react';
import { Card } from '../../../../components/Card';
import { SelectOption } from '../../../../components/Select';
import { Application } from '../../../../entities/Application';
import { StatusFilter } from './StatusFilter';
import { JobStagesFilter } from './JobStagesFilter';
import { HiringTeamFilter } from './HiringTeamFilter';
import { FeedbackTypeFilter } from './FeedbackTypeFilter';
import { Button } from 'reactstrap';

interface PropTypes {
  application: Application;
  filters: FeedbackFilters;
  isLoading: boolean;
  onSearch: (filters: FeedbackFilters) => void;
}

export type FeedbackType = 'scorecard' | 'candidate_rating';

export interface FeedbackFilters {
  feedbackType: FeedbackType;
  status: string;
  jobStages: SelectOption[];
  hiringMembers: SelectOption[];
}

export function Filters(props: PropTypes) {
  const [filters, setFilters] = useState<FeedbackFilters>(props.filters);

  useEffect(() => setFilters(props.filters), [props.filters]);
  useEffect(() => props.onSearch(filters), [filters?.feedbackType]);

  return (
    <Card>
      <div className='fs-3 fw-semibold mb-4'>Scorecards and Feedback</div>
      <FeedbackTypeFilter filters={filters} setFilters={setFilters} />
      {filters?.feedbackType !== 'candidate_rating' && (
        <StatusFilter
          isLoading={props.isLoading}
          filters={filters}
          setFilters={setFilters}
        />
      )}
      <JobStagesFilter
        application={props.application}
        isLoading={props.isLoading}
        filters={filters}
        setFilters={setFilters}
      />
      <HiringTeamFilter
        application={props.application}
        isLoading={props.isLoading}
        filters={filters}
        setFilters={setFilters}
      />
      <div className='d-flex justify-content-end mt-4'>
        <Button
          color='secondary me-3'
          onClick={() => {
            setFilters(null);
            props.onSearch(null);
          }}
        >
          Reset
        </Button>
        <Button color='primary' onClick={() => props.onSearch(filters)}>
          Search
        </Button>
      </div>
    </Card>
  );
}
