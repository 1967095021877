import React from 'react';
import { ConditionsSelectOptions } from '..';
import { Condition } from '../../../../..';
import { LabelledSelect } from '../../../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../../../components/Select';
import { computeNewConditions } from '../../../../../utils/computeNewConditions';
interface PropTypes {
  conditionsSelectOptions: ConditionsSelectOptions;
  mandatory: boolean;
  availableConditions: SelectOption[];
}

function resetMultiselectOnChange(
  index: number,
  conditions: Condition[],
  setConditions: (condition: Condition[]) => void,
) {
  const updatedConditions = [...conditions];
  updatedConditions[index].multiSelected = [];
  setConditions(updatedConditions);
}

export function AvailableConditionsLabelledSelectCol(props: PropTypes) {
  return (
    <div className='col-3'>
      <LabelledSelect
        options={props.availableConditions}
        selected={props.conditionsSelectOptions.type}
        onChange={(e) => {
          const newConditions = computeNewConditions(
            {
              index: props.conditionsSelectOptions.index,
              singleSelected: props.conditionsSelectOptions.singleSelected,
              multiSelected: props.conditionsSelectOptions.multiSelected,
              invert: props.conditionsSelectOptions.invert,
              type: e,
            },
            props.conditionsSelectOptions.conditions,
            props.conditionsSelectOptions.conditionsData,
          );
          props.conditionsSelectOptions.setConditions(newConditions);
          resetMultiselectOnChange(
            props.conditionsSelectOptions.index,
            props.conditionsSelectOptions.conditions,
            props.conditionsSelectOptions.setConditions,
          );
        }}
        label={props.conditionsSelectOptions.index > 0 ? '' : 'Conditions'}
        className={'w-100'}
        mandatory={!(props.conditionsSelectOptions.index > 0)}
        placeholder={'Select Condition'}
        disabled={
          !props.conditionsSelectOptions.selectedSurveyTemplate ||
          props.mandatory
        }
      />
    </div>
  );
}
