import React from 'react';
import { SurveyChip } from '../../../../components/SurveyChip';
import { SurveyQuestionField } from '../../../../entities/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../entities/SurveyQuestionFieldAnswer';
import { SurveyQuestionFieldOption } from '../../../../entities/SurveyQuestionFieldOption';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer: SurveyQuestionFieldAnswer;
  onChangeAnswer: (fieldId: number, value: string, optionIds: number[]) => void;
}

const toggleOptionId = (optionIds: number[], optionId: number) => {
  const index = optionIds.findIndex((id) => id === optionId);
  if (index === -1) return optionIds.concat(optionId);

  return optionIds.filter((id) => id !== optionId);
};

const optionFieldName = (option: SurveyQuestionFieldOption) => {
  if (option.weight == null) return option.name;

  return `${option.weight}. ${option.name}`;
};

export function MultiOptionsField({
  field,
  fieldAnswer,
  onChangeAnswer,
}: PropTypes) {
  const optionIds = fieldAnswer.option_ids || [];

  return (
    <div className='mt-4'>
      {field.name && (
        <div className='mb-2 p-2'>
          <b>{field.name}</b>
        </div>
      )}
      <div className='d-flex align-content-end flex-wrap'>
        {field.options.map((opt) => (
          <div key={opt.id} className='mb-2 px-2 pt-1'>
            <SurveyChip
              className='text-start fs-4'
              active={
                optionIds.find((opt_id) => opt_id === opt.id) !== undefined
              }
              onClick={() =>
                onChangeAnswer(
                  field.id,
                  undefined,
                  toggleOptionId(optionIds, opt.id),
                )
              }
            >
              {optionFieldName(opt)}
            </SurveyChip>
          </div>
        ))}
      </div>
    </div>
  );
}
