import React from 'react';
import { EmptyState } from '../../../../../components/EmptyState';
import { BackToCareersPageButton } from '../BackToCareersPageButton';

export function UnpublishedJobEmptyState() {
  return (
    <>
      <div className='d-flex justify-content-between'>
        <BackToCareersPageButton
          color='borderless'
          size='lg'
          className='mt-4 ms-3'
          onClick={() => (window.location.href = '..')}
        />
      </div>
      <EmptyState
        title='This job is no longer available'
        text='This job post has been removed and the opening is no longer available.'
        classNames='mt-8'
      />
    </>
  );
}
