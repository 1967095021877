import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { IconButton } from '../../../components/IconButton';

interface PropTypes {
  iconName: string;
  buttonText: string;
  onClick: () => void;
}

export function StartPageIconButton(props: PropTypes) {
  return (
    <div className='w-100'>
      <IconButton
        color='secondary'
        size='lg'
        iconName={props.iconName}
        iconClass={'d-flex fs-display1'}
        buttonClass={classNames(
          'mt-3',
          'ps-4',
          'py-3h',
          'fs-3',
          'border-gray-700',
          'rounded-2',
          styles.button,
        )}
        iconTextClass={classNames(
          'fs-3',
          'fw-semibold',
          'align-items-center',
          'text-info',
        )}
        buttonText={props.buttonText}
        onClick={props.onClick}
      />
    </div>
  );
}
