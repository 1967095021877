import React from 'react';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  DEPRECATED_$isGridSelection,
  LexicalEditor,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $setBlocksType } from '@lexical/selection';
import { $createHeadingNode, HeadingTagType } from '@lexical/rich-text';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { IconSpan } from '../../../../IconSpan';
import classNames from 'classnames';
import { Dropdown } from '../../../../Dropdown';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  number: 'Numbered List',
  paragraph: 'Normal',
};

const blockTypeToIconName = {
  bullet: 'bi-list-ul',
  h1: 'bi-type-h1',
  h2: 'bi-type-h2',
  h3: 'bi-type-h3',
  number: 'bi-list-ol',
  paragraph: 'bi-text-paragraph',
};

export type TextStyleType = keyof typeof blockTypeToBlockName;
export const textStyleKeys = Object.keys(blockTypeToBlockName);

interface PropTypes {
  textStyle: TextStyleType;
}

function formatParagraph(editor: LexicalEditor) {
  editor.update(() => {
    const selection = $getSelection();
    if (
      $isRangeSelection(selection) ||
      DEPRECATED_$isGridSelection(selection)
    ) {
      $setBlocksType(selection, () => $createParagraphNode());
    }
  });
}

function formatHeading(
  editor: LexicalEditor,
  textStyle: TextStyleType,
  headingSize: HeadingTagType,
) {
  if (textStyle !== headingSize) {
    editor.update(() => {
      const selection = $getSelection();
      if (
        $isRangeSelection(selection) ||
        DEPRECATED_$isGridSelection(selection)
      ) {
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      }
    });
  }
}

function formatList(
  editor: LexicalEditor,
  targetListType: 'bullet' | 'number',
  selectedStyle: TextStyleType,
) {
  const listCommand =
    targetListType === 'bullet'
      ? INSERT_UNORDERED_LIST_COMMAND
      : INSERT_ORDERED_LIST_COMMAND;

  if (selectedStyle !== targetListType) {
    editor.dispatchCommand(listCommand, undefined);
  } else {
    editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
  }
}

function MenuAction(action: () => void, textStyle: TextStyleType) {
  return {
    action: action,
    buttonChild: (
      <IconSpan
        spanText={blockTypeToBlockName[textStyle]}
        icon={{ name: blockTypeToIconName[textStyle] }}
      />
    ),
  };
}

export function TextStyleDropDown(props: PropTypes): JSX.Element {
  const [editor] = useLexicalComposerContext();

  const formatParagraphCallback = () => formatParagraph(editor);

  const formatHeadingCallback = (headingSize: HeadingTagType) => {
    formatHeading(editor, props.textStyle, headingSize);
  };

  const formatListCallback = (targetListType: 'bullet' | 'number') => {
    formatList(editor, targetListType, props.textStyle);
  };

  return (
    <Dropdown
      containerClassName={'mx-1'}
      buttonClassName={classNames(
        'block-controls',
        'rounded-3',
        'px-2',
        'py-1',
        'fs-5',
      )}
      buttonText={blockTypeToBlockName[props.textStyle]}
      buttonIcon={{
        name: blockTypeToIconName[props.textStyle],
        className: 'mx-1',
      }}
      menuPlacementClass={'dropdown-menu-start'}
      hasChevron={true}
      menuActions={[
        MenuAction(formatParagraphCallback, 'paragraph'),
        MenuAction(() => formatHeadingCallback('h1'), 'h1'),
        MenuAction(() => formatHeadingCallback('h2'), 'h2'),
        MenuAction(() => formatHeadingCallback('h3'), 'h3'),
        MenuAction(() => formatListCallback('bullet'), 'bullet'),
        MenuAction(() => formatListCallback('number'), 'number'),
      ]}
    />
  );
}
