import React from 'react';
import { ActionItem } from '../../../../../entities/ActionItem';
import { HiringMember } from '../../../../../entities/HiringMember';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { User } from '../../../../../entities/User';
import { HiringTeamRow } from './HiringTeamRow';
import { PendingActionsEmptyState } from '..';

interface PropTypes {
  actionItems: ActionItem[];
  currentUser: User;
}

export interface UserGroup {
  user: HiringMember;
  actionItems: ActionItem[];
}

function filterByRole(actionItems: ActionItem[], role: string) {
  return actionItems.filter(
    (actionItem) => actionItem.hiringMember.role === role,
  );
}

function groupByUser(actionItems: ActionItem[]) {
  const grouped = actionItems.reduce((acc, actionItem) => {
    const user = actionItem.hiringMember;
    if (acc[user.id] == null) {
      acc[user.id] = {
        user,
        actionItems: [actionItem],
      };
    } else {
      acc[user.id].actionItems.push(actionItem);
    }
    return acc;
  }, {} as { [key: string]: UserGroup });

  return Object.values(grouped);
}

function UserGroupByRole(props: { users: UserGroup[]; title: string }) {
  return (
    <>
      {props.users.length > 0 && (
        <div className='mb-2 pb-1'>
          <div className='fs-5 text-dark-200 mb-1'>{props.title}</div>
          <div>
            {props.users.map((hiringMember, idx) => (
              <HiringTeamRow key={idx} {...hiringMember} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export function HiringTeamActionsItems(props: PropTypes) {
  const filteredActionItems = props.actionItems.filter(
    (actionItem) =>
      actionItem.hiringMember.userId !== props.currentUser.id &&
      !actionItem.completed,
  );

  const recruiters = groupByUser(
    filterByRole(filteredActionItems, 'recruiter'),
  );
  const hiringManagers = groupByUser(
    filterByRole(filteredActionItems, 'hiring_manager'),
  );
  const interviewers = groupByUser(
    filterByRole(filteredActionItems, 'interviewer'),
  );

  return (
    <>
      {[recruiters, hiringManagers, interviewers].every(
        (arr) => !arr.length,
      ) ? (
        <PendingActionsEmptyState />
      ) : (
        <div
          className={classNames(
            'mt-3 overflow-auto',
            styles['pending-items-body'],
          )}
        >
          {recruiters.length > 0 && (
            <UserGroupByRole users={recruiters} title='Recruiters' />
          )}
          {hiringManagers.length > 0 && (
            <UserGroupByRole users={hiringManagers} title='Hiring Managers' />
          )}
          {interviewers.length > 0 && (
            <UserGroupByRole users={interviewers} title='Interviewers' />
          )}
        </div>
      )}
    </>
  );
}
