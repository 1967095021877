import React, { useCallback } from 'react';
import { Select, SelectOption } from '../Select';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Size } from '../../utils/types/Size';

interface PropTypes {
  dateRange: number;
  setDateRange: (dateRange: number) => void;
  className?: string;
  titleClassName?: string;
  selectClassName?: string;
  selectSize?: Size;
  readOnly?: boolean;
}

const AVAILABLE_DATE_RANGES = [1, 5, 10, 15, 20, 30];
export const DEFAULT_DATE_RANGE = 10;

export function DateRangeSelect(props: PropTypes) {
  const dateRangeOptions = AVAILABLE_DATE_RANGES.map((dateRange) => {
    return {
      value: dateRange.toString(),
      label: `${dateRange} ${dateRange > 1 ? 'days' : 'day'}`,
      selected: dateRange === props.dateRange,
    };
  });
  const selectedDateRange = dateRangeOptions.find((option) => option.selected);

  const onDateRangeSelection = useCallback(
    (option: SelectOption) => {
      props.setDateRange(parseInt(option.value));
    },
    [props.dateRange],
  );

  return (
    <div
      data-testid='daterange_select'
      className={classNames(props.className, 'align-items-center')}
    >
      <span className={classNames(props.titleClassName)}>Date range</span>
      <Select
        options={dateRangeOptions}
        size={props.selectSize || 'sm'}
        placeholder='Select date range'
        onChange={onDateRangeSelection}
        selected={selectedDateRange}
        className={classNames(
          styles['date-range-select'],
          props.selectClassName,
        )}
        isClearable={false}
        disabled={!!props.readOnly}
      />
    </div>
  );
}
