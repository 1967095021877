import { multiSelectStyle } from '../../../../../components/Select/multiSelectStyle';
import stylesheetExports from '../../../../../stylesheets/export.module.scss';

export const topicsSelectStyle = {
  ...multiSelectStyle,
  control: (base) => ({
    ...base,
    maxHeight: '5.5rem',
    overflow: 'scroll',
    boxShadow: 'none',
    backgroundColor: stylesheetExports.white,
  }),
  option: (base) => ({
    ...base,
    color: stylesheetExports.blue,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '4px 8px',
  }),
};
