import React, { useState } from 'react';
import { Nav } from 'reactstrap';
import { InterviewQuestion } from '../../../../entities/InterviewQuestion';
import { InterviewSidekick } from '../../../../entities/InterviewSidekick';
import { ScorecardAttribute } from '../../../../entities/ScorecardAttribute';
import { SidekickTab } from '../SidekickTab';
import { CandidateAssessmentPage } from '../CandidateAssessmentPage';
import { InterviewQuestionsPage } from '../InterviewQuestionsPage';
import { SidekickNav } from './SidekickNav';

interface PropTypes {
  activeTab: SidekickTab;
  interview: InterviewSidekick;
  interviewRatingEnabled: boolean;
  onScorecardAttributesUpdated: (
    attribute: ScorecardAttribute,
    score?: number,
  ) => void;
  onInterviewQuestionsUpdated: (question: InterviewQuestion) => void;
  onInterviewMarkerCreated: (kind: string, note?: string) => void;
  setActiveTab: (activeTab: SidekickTab) => void;
}

function SidekickTabs(props: {
  activeTab: SidekickTab;
  setActiveTab: (activeTab: SidekickTab) => void;
}) {
  return (
    <>
      <SidekickNav
        tab={SidekickTab.Assessment}
        activeTab={props.activeTab}
        setActiveTab={props.setActiveTab}
      />
      <SidekickNav
        tab={SidekickTab.Questions}
        activeTab={props.activeTab}
        setActiveTab={props.setActiveTab}
      />
    </>
  );
}

export function SidekickNavBar(props: PropTypes) {
  const [activeTab, setActiveTab] = useState(SidekickTab.Assessment);

  return (
    <>
      <Nav className='app-nav-tabs px-4 flex-nowrap'>
        <SidekickTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      </Nav>
      {activeTab === SidekickTab.Assessment ? (
        <CandidateAssessmentPage
          interview={props.interview}
          interviewRatingEnabled={props.interviewRatingEnabled}
          onScorecardAttributesUpdated={props.onScorecardAttributesUpdated}
          onInterviewMarkerCreated={props.onInterviewMarkerCreated}
        />
      ) : (
        <InterviewQuestionsPage
          interview={props.interview}
          onInterviewMarkerCreated={props.onInterviewMarkerCreated}
          onInterviewQuestionsUpdated={props.onInterviewQuestionsUpdated}
        />
      )}
    </>
  );
}
