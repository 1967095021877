import React from 'react';
import { HiringMemberAnalytics } from '../../../../../../../../../entities/candidate_experience/HiringMemberAnalytics';
import { ValueWithTrend } from '../ValueWithTrend';
import { ChipColor } from '../../../../../../../../../components/Chip';

interface PropTypes {
  hiring_member: HiringMemberAnalytics;
}

export function AvgScore(props: PropTypes) {
  return (
    <ValueWithTrend
      value={props.hiring_member.avg_score}
      trend={props.hiring_member.avg_score_trend}
      chipColor={ChipColor.BlueLight}
    />
  );
}
