import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../../components/FormFieldHeader';
import { RichTextEditor } from '../../../../../components/RichTextEditor';
import { UpsertEmailTemplateDto } from '../../UpsertEmailTemplateDto';
import { Control } from 'react-hook-form';
import { UpdateStatePlugin } from '../../../../../components/RichTextEditor/plugins/UpdateStatePlugin';
import { PasteVariablePlugin } from '../../../../../components/RichTextEditor/plugins/PasteVariablePlugin';
import { EMAIL_TEMPLATE_VARIABLES } from '../CopyVariables';
import { AreaRichTextComposer } from '../../../../../components/RichTextEditor/composers/AreaRichTextComposer';

interface PropTypes {
  control: Control<UpsertEmailTemplateDto, any>;
  template?: string;
  setHtml: (html: string) => void;
}

export function EmailTemplateInput(props: PropTypes) {
  return (
    <>
      <Row className='mt-3'>
        <Col xs='9'>
          <>
            <FormFieldHeader fieldName='Body' isRequired={true} />
            <RichTextEditor<UpsertEmailTemplateDto>
              formHookContext={{
                controllerName: 'template',
                formControl: props.control,
                required: true,
              }}
              render={(field) => (
                <AreaRichTextComposer
                  defaultEditorState={field.value as string}
                  placeholder='Insert email text'
                  onChangeHtml={props.setHtml}
                  onChangeJson={(value: string) => field.onChange(value)}
                  size='md'
                  externalPlugins={[
                    <UpdateStatePlugin key={1} editorState={props.template} />,
                    <PasteVariablePlugin
                      key={2}
                      variables={Object.values(EMAIL_TEMPLATE_VARIABLES)}
                    />,
                  ]}
                />
              )}
            />
          </>
        </Col>
      </Row>
    </>
  );
}
