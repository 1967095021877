import React from 'react';
import { Col, Row } from 'reactstrap';
import { SurveyButton } from '../../../../../components/survey_engine/SurveyButton';
import { sortOptionsByWeight } from '../../../../../utils/sortOptionsByWeight';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { SurveyQuestionField } from '../../../../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../../entities/survey_engine/SurveyQuestionFieldAnswer';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer?: SurveyQuestionFieldAnswer;
  onChangeAnswer: (
    fieldId: number,
    value: string,
    optionIds: (number | string)[],
  ) => void;
}

export function RatingField(props: PropTypes) {
  const sortedOptions = sortOptionsByWeight(props.field.options);

  return (
    <div className={classNames('mt-4 d-inline-block')}>
      <Row className={classNames(styles.surveyRatingOptions)}>
        {sortedOptions.map((opt) => (
          <Col key={opt.id}>
            <SurveyButton
              active={props.fieldAnswer?.value === opt.weight.toString()}
              onClick={() =>
                props.onChangeAnswer(props.field.id, opt.weight.toString(), [
                  opt.id,
                ])
              }
            >
              {opt.name}
            </SurveyButton>
          </Col>
        ))}
      </Row>
      <Row className={classNames(styles.legends, 'mt-3 fs-5')}>
        <Col className='text-start'>{props.field?.min_legend}</Col>
        <Col className='text-end'>{props.field?.max_legend}</Col>
      </Row>
    </div>
  );
}
