import React from 'react';
import { Chip, ChipColor } from '../../../../components/Chip';
import { JobState } from '../../../../entities/JobDetails';
import { humanize } from '../../../../utils/humanize';

interface PropTypes {
  state: JobState;
}

function getChipColor(state: JobState): ChipColor {
  switch (state) {
    case 'archived':
    case 'closed':
      return ChipColor.Danger;
    case 'draft':
    case 'unknown':
      return ChipColor.Orange;
    case 'open':
      return ChipColor.Blue;
    case 'published':
      return ChipColor.Success;
  }
}

export function JobStateBadge(props: PropTypes) {
  return (
    <Chip data-testid='job-state' color={getChipColor(props.state)}>
      {humanize(props.state)}
    </Chip>
  );
}
