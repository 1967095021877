import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';

interface PropTypes {
  onChangeText?: (text: string) => void;
}

export function ExportPlainTextPlugin(props: PropTypes) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const stringifiedEditorState = JSON.stringify(
      editor.getEditorState().toJSON(),
    );
    const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
    const editorStateTextString = parsedEditorState.read(() =>
      $getRoot().getTextContent(),
    );

    props.onChangeText?.(editorStateTextString);
  }, [editor.getEditorState()]);

  return null;
}
