import { InterviewService } from '../../../services/InterviewService';

export function generateAiResponse(
  interviewdId: number,
  prompt: string,
  setValue: (value: string) => void,
  setIsAiGenerating: (state: boolean) => void,
) {
  let response = '';
  setIsAiGenerating(true);

  const onMessage = (message: MessageEvent) => {
    response += message.data;
    setValue(response);
  };

  InterviewService.aiGenerateAssistantAnswer({
    id: interviewdId,
    prompt,
    onMessage,
    onEnd: () => {
      setIsAiGenerating(false);
    },
  });
}
