import React from 'react';
import { Icon } from '../../../../../components/Icon';
import { RedactedEntry } from '../RedactedEntry';

interface PropTypes {
  redacted: boolean;
  icon: string;
  children: React.ReactNode;
  label?: string;
}

export function IconEntry(props: PropTypes) {
  if (props.redacted) {
    return <RedactedEntry label={props.label} />;
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='fs-4'>
        <Icon name={props.icon} className='text-dark-200 me-2 fs-4' />
      </div>
      <div className='text-truncate'>{props.children}</div>
    </div>
  );
}
