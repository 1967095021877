import React, { useState } from 'react';
import { CloseableModal } from '../../../../components/CloseableModal';
import { ModalBody } from './ModalBody';
import { Alert, AlertType } from '../../../../components/Alert';
import { Application } from '../../../../entities/Application';

interface PropTypes {
  application: Application;
  isOpen: boolean;
  onClose: (succeeded?: boolean) => void;
}

export type AlertData = {
  active: boolean;
  type: AlertType;
  message: React.ReactNode;
};

export function UnrejectCandidateStageModal(props: PropTypes) {
  const [alert, setAlert] = useState<AlertData>(null);

  const handleClose = (succeeded?: boolean) => {
    if (succeeded) {
      setAlert({
        active: true,
        type: AlertType.Success,
        message: (
          <span>
            <b>{props.application.candidate.name}</b> was successfully
            unrejected for the <b>{props.application.job?.name}</b> job!
          </span>
        ),
      });
    } else {
      setAlert({
        active: true,
        type: AlertType.Danger,
        message: (
          <span>
            <b>{props.application.candidate.name}</b> could not be unrejected
            for the <b>{props.application.job?.name}</b> job.
          </span>
        ),
      });
    }

    props.onClose(succeeded);
  };

  return (
    <>
      {alert?.active && (
        <Alert
          type={alert.type}
          autoClearTimeout={3000}
          clearable={true}
          onClose={() => setAlert({ ...alert, active: false })}
        >
          {alert.message}
        </Alert>
      )}
      {props.isOpen && (
        <CloseableModal
          className='modal-dialog-centered'
          onClose={props.onClose}
          isOpen={true}
          size='md'
          closeDisabled={false}
          keyboard={true}
          backdrop={true}
          headerTitle='Unreject Candidate'
          bodyChildren={
            <ModalBody application={props.application} onClose={handleClose} />
          }
        />
      )}
    </>
  );
}
