import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { Transcript } from '../../../../entities/v1/interview_intelligence/Transcript';

export class TranscriptService {
  public static show(
    interviewId: number,
    expand?: string[],
  ): Promise<Transcript> {
    return ApiService.get<Transcript>(
      `/api/v1/interview_intelligence/transcripts`,
      snakecaseKeys({ interviewId: interviewId, expand: expand }),
    );
  }
}
