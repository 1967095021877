import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  className?: string;
  iconClass: string;
  metricUnity?: string;
  metricValue?: number;
}

export function MetricIcon(props: PropTypes) {
  if (props.metricValue == null) {
    return null;
  }

  return (
    <div className={classNames('align-items-center', props.className)}>
      <i className={classNames('bi', 'me-1', props.iconClass)} />
      <span>
        {props.metricValue} {props.metricUnity}
      </span>
    </div>
  );
}
