import React from 'react';
import classNames from 'classnames';

interface PropTypes {
  className: string;
  placeholder?: string;
}

export function Placeholder(props: PropTypes) {
  return (
    <div className={classNames(props.className, 'fw-normal')}>
      {props.placeholder ?? 'Start typing'}
    </div>
  );
}
