import React from 'react';
import { TableCellLayout } from '../TableCellLayout';
import classNames from 'classnames';

interface PropTypes {
  children: React.ReactNode[];
  classNames?: string;
}

export function TableRowLayout(props: PropTypes) {
  const compactList = props.children.filter((child) => child);

  return (
    <tr className={classNames('pb-4', props.classNames)}>
      {compactList.map((child, idx) => (
        <TableCellLayout
          classNames={classNames(
            { 'left-col': idx === 0 },
            {
              'd-flex align-items-center flex-row-reverse pe-4':
                idx === compactList.length - 1,
            },
          )}
          key={idx}
        >
          {child}
        </TableCellLayout>
      ))}
    </tr>
  );
}
