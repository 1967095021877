import React from 'react';
import { FinalScreenStatus } from '..';
import { StyledModal } from '../../../../../../components/StyledModal';
import { SuccessfulEvaluationScoreFooter } from './SuccessfulEvaluationScoreFooter';
import { FailedEvaluationScoreFooter } from './FailedEvaluationScoreFooter';
import { SuccessfulEvaluationScoreBody } from './SuccessfulEvaluationScoreBody';
import { FailedEvaluationScoreBody } from './FailedEvaluationScoreBody';
import { isSuccessfulEvaluationAccuracy } from './utils/isSuccessfulEvaluationAccuracy';

interface PropTypes {
  finalScreenStatus: FinalScreenStatus;
  evaluationAccuracy?: number;
  questionsCount: number;
  correctlyAnsweredCount: number;
  onClose: () => void;
  onSkipEvaluation: () => void;
  onRetryEvaluation: () => void;
  onSubmitFinishEvaluation: () => void;
}

export function EvaluationScoreModal(props: PropTypes) {
  return (
    <StyledModal
      isOpen={props.finalScreenStatus === 'FINISH_EVALUATION_MODAL'}
      title='Your Evaluation Score'
      onCancel={props.onClose}
      size={'lg'}
      bodyChildren={
        isSuccessfulEvaluationAccuracy(props.evaluationAccuracy) ? (
          <SuccessfulEvaluationScoreBody
            evaluationAccuracy={props.evaluationAccuracy}
          />
        ) : (
          <FailedEvaluationScoreBody
            evaluationAccuracy={props.evaluationAccuracy}
            questionsCount={props.questionsCount}
            correctlyAnsweredCount={props.correctlyAnsweredCount}
          />
        )
      }
      footerChildren={
        isSuccessfulEvaluationAccuracy(props.evaluationAccuracy) ? (
          <SuccessfulEvaluationScoreFooter
            onSubmit={props.onSubmitFinishEvaluation}
          />
        ) : (
          <FailedEvaluationScoreFooter
            onSkipEvaluation={props.onSkipEvaluation}
            onSubmit={props.onRetryEvaluation}
          />
        )
      }
      footerClassName='justify-content-center'
    />
  );
}
