import React from 'react';

export function boldFormatter(text: string) {
  const boldRegex = /\*\*(.*?)\*\*/g;

  return text.split(boldRegex).map((part, index) => {
    return index % 2 === 1 ? (
      <span className='fw-bold' key={index}>
        {part}
      </span>
    ) : (
      part.replace(/\\(\*)/g, '*')
    );
  });
}
