import classNames from 'classnames';
import React from 'react';
import { RateOption } from '../../../../entities/RateOption';
import styles from './styles.module.scss';

interface PropTypes {
  classNames?: string;
  rateOption: RateOption;
  active?: boolean;
  readonly?: boolean;
  onClick?: () => void;
}

export function RatingOption(props: PropTypes) {
  return (
    <div
      className={classNames(
        'd-flex flex-column justify-content-center text-center p-3 border border-1',
        styles['rating-option'],
        { [styles[props.rateOption.color]]: !props.readonly },
        props.active && styles[props.rateOption.color + '-active'],
        props.classNames,
      )}
      onClick={props.onClick}
    >
      <div className={classNames('mb-1', styles.icon)}>
        <i className={`bi ${props.rateOption.icon}`}></i>
      </div>
      <div className={classNames('my-auto fs-6', styles.title)}>
        {props.rateOption.title}
      </div>
    </div>
  );
}
