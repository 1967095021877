import React from 'react';
import { Avatar } from '../../../../../../../components/Avatar';
import { ApplicationFeedComment } from '../../../../../../../entities/ApplicationFeedComment';

const MAX_UNIQUE_AVATAR_COUNT = 4;

interface PropTypes {
  comments: ApplicationFeedComment[];
}

export function AvatarList(props: PropTypes) {
  const uniqueAuthorNames = new Set<string>();

  props.comments.forEach((comment: ApplicationFeedComment) => {
    uniqueAuthorNames.add(comment.authorName);
  });

  return (
    <div className='d-flex'>
      {Array.from(uniqueAuthorNames)
        .slice(0, MAX_UNIQUE_AVATAR_COUNT)
        .map((authorName: string, index) => (
          <Avatar name={authorName} key={index} />
        ))}
    </div>
  );
}
