import React from 'react';
import { Scorecard } from '../../../../../entities/applicant_tracking/Scorecard';
import { ScorecardScore } from './ScorecardScore';
import { User } from '../../../../../entities/User';
import { IconSpan } from '../../../../../components/IconSpan';
import { Section } from '../../../../../entities/v1/survey_engine/Section';
import { Row } from 'reactstrap';
import { ScorecardQuestion } from './ScorecardQuestion';
import { Chip, ChipColor } from '../../../../../components/Chip';

interface PropTypes {
  user: User;
  scorecard: Scorecard;
  prefilledAttributesCount?: number;
  aiScorecardFill: boolean;
  isEditing: boolean;
  setScorecard: (
    value: Scorecard | ((prevScorecard: Scorecard) => Scorecard),
  ) => void;
}

function ScorecardTitle(props: PropTypes) {
  if (props.scorecard.interviewStage) {
    return <h1 className='mb-0'>Scorecard</h1>;
  }

  return (
    <div className='d-flex align-items-center'>
      <h1 className='mb-0 me-3'>
        {props.isEditing ? 'Edit Ad-hoc' : 'Fill in Ad-hoc'} Scorecard
      </h1>
      <Chip color={ChipColor.Gray}>{props.scorecard.jobStage.name}</Chip>
    </div>
  );
}

export function ScorecardBody(props: PropTypes) {
  return (
    <>
      <div className='d-flex justify-content-between'>
        <ScorecardTitle {...props} />
        {props.prefilledAttributesCount > 0 && (
          <IconSpan
            className='fs-6 text-dark-500'
            icon={{ name: 'bi-info-circle' }}
            spanText='All pre-filled attributes were rated during the interview'
          />
        )}
      </div>
      <Row>
        {props.scorecard.survey?.sections.map((section: Section) => {
          return (
            <ScorecardQuestion {...props} key={section.id} section={section} />
          );
        })}
      </Row>
      <ScorecardScore
        rating={props.scorecard.score}
        setRating={(score: string) =>
          props.setScorecard((scorecard) => ({ ...scorecard, score }))
        }
      />
    </>
  );
}
