import React, { useEffect } from 'react';
import { TopNavbar } from './TopNavbar';
import { JobDetailsPanel } from './JobDetailsPanel';
import { useParams } from 'react-router';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { EmptyState } from '../../components/EmptyState';
import { JobService } from '../../services/JobService';
import { JobPageTab } from './JobPageTab';
import { User } from '../../entities/User';
import { UserService } from '../../services/UserService';
import { OrganizationService } from '../../services/v1/OrganizationService';
import { Organization } from '../../entities/Organization';
import { AccountIntegration } from '../../entities/AccountIntegration';

interface PropTypes {
  activeTab: JobPageTab;
  emailAccountIntegration: AccountIntegration;
  jobBoardEnabled: boolean;
  publishEnabled: boolean;
  standardNpsCalculation: boolean;
  bulkSelfSchedulingEnabled: boolean;
}

export function JobPage(props: PropTypes) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [jobDetails, setJobDetails] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState<User>(null);
  const [organization, setOrganization] = React.useState<Organization>(null);
  const [loadingFailed, setLoadingFailed] = React.useState(false);

  const jobId = useParams().id;

  useEffect(() => {
    Promise.all([
      JobService.get(jobId),
      UserService.get(),
      OrganizationService.current({ expand: ['organization_info'] }),
    ])
      .then(([jobDetails, currentUser, organization]) => {
        setJobDetails(jobDetails);
        setCurrentUser(currentUser);
        setOrganization(organization);
      })
      .catch((error) => {
        console.error(error);
        setLoadingFailed(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : loadingFailed ? (
        <EmptyState
          title='We have encountered an error'
          text='Please try again. If the error persists contact our team at support@screenloop.com'
          cardless={true}
        />
      ) : (
        <>
          <TopNavbar
            currentUser={currentUser}
            jobDetails={jobDetails}
            subdomain={organization.subdomain}
            publishEnabled={props.publishEnabled}
          />
          <JobDetailsPanel
            activeTab={props.activeTab}
            currentUser={currentUser}
            emailAccountIntegration={props.emailAccountIntegration}
            jobBoardEnabled={props.jobBoardEnabled}
            jobDetails={jobDetails}
            organization={organization}
            standardNpsCalculation={props.standardNpsCalculation}
            bulkSelfSchedulingEnabled={props.bulkSelfSchedulingEnabled}
          />
        </>
      )}
    </>
  );
}
