import React from 'react';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { ReferenceRequestStatus } from './ReferenceRequestStatus';

interface PropTypes {
  name: string;
  referenceRequestStatus: ReferenceRequestStatus;
  external?: React.ReactNode;
  submitAskForReference: () => void;
  revertAskForReference: () => void;
}

export function ReferenceRequestConfirmationModal(props: PropTypes) {
  return (
    <ConfirmationModal
      title='Request Reference Check'
      body={
        <p className='mb-0'>
          Are you sure you want to send an email to {props.name} asking for
          references&apos; feedback?
        </p>
      }
      external={props.external}
      isOpen={props.referenceRequestStatus === 'submitting'}
      confirmText='Send'
      disableAfterConfirm={props.referenceRequestStatus === 'success'}
      onConfirm={props.submitAskForReference}
      onCancel={props.revertAskForReference}
      size={'md'}
    />
  );
}
