import React from 'react';
import { Row } from 'reactstrap';
import { SurveyLayout } from '../../../../components/survey_engine/SurveyLayout';
import { StartPanel } from './StartPanel';
import { CompanyInfo } from '../../../../reference_check/entities/CompanyInfo';
import { SurveyTypes } from '../../../../entities/survey_engine/SurveyTypes';
import { StartPanelFields } from '../../../../entities/survey_engine/StartPanelFields';

interface PropTypes {
  companyInfo: CompanyInfo;
  surveyStartBody: string;
  surveyDetails: SurveyTypes;
  template: string;
  onStartSurvey: () => void;
  getStartScreenFields?: (SurveyTypes) => StartPanelFields;
}

export function SurveyStartScreen(props: PropTypes) {
  return (
    <SurveyLayout companyInfo={props.companyInfo}>
      <Row>
        <StartPanel
          surveyStartBody={props.surveyStartBody}
          companyInfo={props.companyInfo}
          surveyDetails={props.surveyDetails}
          template={props.template}
          onStartSurvey={props.onStartSurvey}
          getStartScreenFields={props.getStartScreenFields}
        />
      </Row>
    </SurveyLayout>
  );
}
