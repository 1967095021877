import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface PropTypes {
  volume: number;
  muted: boolean;
  onVolumeChange: (volume: number, muted: boolean) => void;
}

export const VolumeSlider = React.memo(function VolumeSlider(props: PropTypes) {
  const [sliderRef, setSliderRef] = useState<HTMLElement>();
  const [isDragging, setIsDragging] = useState(false);

  const volume = props.muted ? 0 : props.volume;
  const levelLabel: string =
    volume === 0 || props.muted ? 'mute' : props.volume < 50 ? 'down' : 'up';

  const volumeInverse = `${Math.max(100 - volume - 10, 0)}%`;

  const handleSliderClick = (e: MouseEvent) => {
    (window as any).e = e;
    const target = sliderRef;
    const diff = e.clientY - target.getBoundingClientRect().top;
    const volumeCalc =
      ((target.offsetHeight - diff) / target.offsetHeight) * 100;
    const volume = volumeCalc < 15 ? 0 : volumeCalc > 85 ? 100 : volumeCalc;
    props.onVolumeChange(volume, false);
  };

  const stopDragging = useCallback(() => setIsDragging(false), []);
  const changeVolume = useCallback(
    (e: MouseEvent) => {
      if (sliderRef === undefined || !isDragging) return;
      handleSliderClick(e);
    },
    [isDragging, sliderRef],
  );

  useEffect(() => {
    document.addEventListener('mouseup', stopDragging);
    document.addEventListener('mousemove', changeVolume);

    return () => {
      document.removeEventListener('mouseup', stopDragging);
      document.removeEventListener('mousemove', changeVolume);
    };
  }, [isDragging]);

  return (
    <div className={`${styles.volume}`}>
      <div className={styles.volumeSliderWrapper}>
        <div
          ref={setSliderRef}
          className={styles.slider}
          onClick={handleSliderClick.bind(this)}
        >
          <div className={styles.sliderTrack}>
            <div
              className={styles.sliderTrackEmpty}
              style={{ height: volumeInverse }}
            />
          </div>
          <div
            className={styles.sliderHandle}
            style={{ top: volumeInverse }}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={() => setIsDragging(true)}
          />
        </div>
      </div>
      <div>
        <a
          className={classNames(
            `bi bi-volume-${levelLabel}`,
            styles['volume-link'],
          )}
          href='#volume'
          onClick={() => props.onVolumeChange(props.volume, !props.muted)}
        />
      </div>
    </div>
  );
});
