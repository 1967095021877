import React, { useCallback } from 'react';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { JobService } from '../../../../../services/applicant_tracking/JobService';

interface PropTypes {
  jobId: number;
  jobName: string;
  publishAction: 'Publish' | 'Unpublish';
  isModalOpen: boolean;
  redirectUri?: string;
  setIsModalOpen: (b: boolean) => void;
}

function redirectTo(path: string, redirectUri?: string, jobName?: string) {
  const baseUrl = `/applicant_tracking/jobs/${path}/?`;
  const jobNameParam = jobName && `job_name=${jobName}&`;
  const redirectUrlParam = redirectUri && `redirect_uri=${redirectUri}&`;

  window.location.href = baseUrl + jobNameParam + redirectUrlParam;
}

export function PublishJobConfirmationModal(props: PropTypes) {
  const modalText =
    props.publishAction === 'Publish'
      ? 'This job will be displayed on the Careers page and you will receive direct applications for it.'
      : 'This job will be removed from the Careers page and you will only be able to add candidates manually.';
  const onJobPublish = useCallback(async () => {
    try {
      await JobService.publish(`${props.jobId}`, true);
      redirectTo('publish_success', props.redirectUri, props.jobName);
    } catch (e) {
      redirectTo('publish_error', props.redirectUri);
    }
    props.setIsModalOpen(false);
  }, [props.jobId]);

  const onJobUnpublish = useCallback(async () => {
    try {
      await JobService.unpublish(`${props.jobId}`, true);
      redirectTo('unpublish_success', props.redirectUri, props.jobName);
    } catch (e) {
      redirectTo('unpublish_error', props.redirectUri);
    }
    props.setIsModalOpen(false);
  }, [props.jobId]);

  return (
    <ConfirmationModal
      title={`${props.publishAction} Job`}
      body={
        <div className='fs-5'>
          <p className='mb-0'>
            Are you sure you want to {props.publishAction.toLowerCase()} the{' '}
            <span className='fw-bold'>{props.jobName}</span> job?
          </p>
          <br></br>
          <p>{modalText}</p>
        </div>
      }
      isOpen={props.isModalOpen}
      onConfirm={
        props.publishAction === 'Publish' ? onJobPublish : onJobUnpublish
      }
      onCancel={() => props.setIsModalOpen(false)}
      size={'md'}
      confirmText={props.publishAction}
      disableAfterConfirm={false}
      contentClassName='w-100'
    />
  );
}
