import React from 'react';
import { JobPostingDetailsForm } from './..';
import { FormGroup } from './../FormGroup';
import { ContactName } from './ContactName';
import { ContactEmail } from './ContactEmail';
import { ContactPhone } from './ContactPhone';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: Record<string, string>;
  onInputChange: (name: string, value: unknown) => void;
}

export function ContactInformationFields(props: PropTypes) {
  return (
    <FormGroup>
      <h4>Contact Information</h4>
      <ContactName
        form={props.form}
        errors={props.errors}
        onInputChange={props.onInputChange}
      />
      <ContactEmail
        form={props.form}
        errors={props.errors}
        onInputChange={props.onInputChange}
      />
      <ContactPhone
        form={props.form}
        errors={props.errors}
        onInputChange={props.onInputChange}
      />
    </FormGroup>
  );
}
