import { Theme } from 'react-select';
import stylesheetExports from '../../stylesheets/export.module.scss';

export function selectTheme(theme: Theme) {
  return {
    ...theme,
    borderRadius: 5,
    controlHeight: '24px',
    colors: {
      ...theme.colors,
      primary: stylesheetExports.blue,
      primary25: stylesheetExports.blueLight,
      primary50: stylesheetExports.blueLight,
      primary75: stylesheetExports.blueLight,
      neutral20: stylesheetExports.blueGray500,
      neutral30: stylesheetExports.gray900,
      neutral50: stylesheetExports.dark500,
    },
  };
}
