import React from 'react';
import { Row, Col } from 'reactstrap';
import { SpanTooltip } from '../../../../../../components/SpanTooltip';

interface PropTypes {
  title: string;
}

export function ScorecardCategoriesHeader(props: PropTypes) {
  return (
    <Row className='justify-content-between align-items-center ms-3 me-3'>
      <Col className='px-0' xs='auto'>
        <h2 className='mb-0 pt-3 pb-2 fs-4'>{props.title}</h2>
      </Col>
      <Col className='text-end px-0' xs='1'>
        <SpanTooltip
          className='text-info text-end'
          placement='top'
          text={<i className='bi bi-info-circle'></i>}
          tooltipText={
            <>
              These attributes are fetched from your ATS so that you can tag and
              rate them here.
            </>
          }
          tooltipStyle={{ maxWidth: '214px' }}
        />
      </Col>
    </Row>
  );
}
