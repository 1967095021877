import React from 'react';
import { Nav, NavLink } from 'reactstrap';
import { ScorecardTabs } from '../ScorecardTabs';
import RobotIcon from '../../../../images/icons/robot.svg';

interface PropTypes {
  activeTab: ScorecardTabs;
  setActiveTab: (tab: ScorecardTabs) => void;
}

export function ScorecardAssistantTabs(props: PropTypes) {
  const tabs = [
    ScorecardTabs.AINotes,
    ScorecardTabs.Timeline,
    ScorecardTabs.Assistant,
  ];

  return (
    <Nav className='app-nav-tabs fs-5'>
      {tabs.map((tab) => {
        return (
          <NavLink
            key={tab}
            data-testid={tab}
            href='#'
            active={props.activeTab === tab}
            className='text-sm-center'
            onClick={() => props.setActiveTab(tab)}
          >
            {tab === 'Assistant' && (
              <img
                src={RobotIcon}
                className='me-2'
                style={{ width: '16px', height: '16px' }}
              />
            )}
            {tab}
          </NavLink>
        );
      })}
    </Nav>
  );
}
