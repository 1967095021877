import { Moment } from 'moment';
import { Condition } from '..';

export function buildSelectedConditions(
  conditions: Condition[],
  surveyTriggerStatus: boolean,
  surveyTemplateId: number,
  name: string,
  startDate: Moment,
  MaxReminder: string,
  DelayDays: string,
) {
  const selectedConditions = {
    name: name,
    invertJobCondition: false,
    jobIds: [],
    locationIds: [],
    invertLocationCondition: false,
    jobStageName: [],
    applicationRejectReason: [],
    applicationStatus: [],
    jobConfidential: [],
    active: surveyTriggerStatus,
    processingStartsAt: startDate,
    invertJobStageCondition: false,
    surveyTemplateId: surveyTemplateId,
    maxReminders: parseInt(MaxReminder),
    delayDays: parseInt(DelayDays),
  };

  conditions.forEach((condition) => {
    if (condition.type.value === 'job') {
      condition.multiSelected.forEach((selected) =>
        selectedConditions.jobIds.push(selected.value),
      );
      selectedConditions.invertJobCondition = condition.invert.value === 'true';
    } else if (condition.type.value === 'location') {
      condition.multiSelected.forEach((selected) =>
        selectedConditions.locationIds.push(selected.value),
      );
      selectedConditions.invertLocationCondition =
        condition.invert.value === 'true';
    } else if (condition.type.value === 'stage') {
      condition.multiSelected.forEach((selected) =>
        selectedConditions.jobStageName.push(selected.label),
      );
      selectedConditions.invertJobStageCondition =
        condition.invert.value === 'true';
    } else if (condition.type.value === 'reject_reason') {
      condition.multiSelected.forEach((selected) =>
        selectedConditions.applicationRejectReason.push(selected.label),
      );
    } else if (condition.type.value === 'status' && condition.singleSelected) {
      selectedConditions.applicationStatus.push(condition.singleSelected.value);
    } else if (
      condition.type.value === 'job_confidential' &&
      condition.singleSelected
    ) {
      selectedConditions.jobConfidential.push(condition.singleSelected.value);
    }
  });

  return selectedConditions;
}
