import { CandidatePulseEmptyState } from '../CandidatePulseEmptyState';
import { FilterStates } from '../../../../components/candidate_experience/Filters/filterState';
import React, { useEffect, useState } from 'react';
import { PageWideSpinner } from '../../../../components/PageWideSpinner';
import { FollowUpResponsesListPanel } from './FollowUpResponsesListPanel';
import { Col, Row } from 'reactstrap';
import { PageBreak } from '../PageBreak';
import { AnalyticsFollowUpService } from '../../../../services/candidate_experience/AnalyticsFollowUpService';
import { FollowUpAnalytics } from '../../../../entities/candidate_experience/FollowUpAnalytics';
import { CounterPanels } from './CounterPanels';
import { SentimentsByTopicPanel } from './SentimentsByTopicPanel';

const FOLLOWUP_ANSWERS_MAX_ROWS_COLLAPSED = 5;

interface PropTypes {
  filterStates: FilterStates;
  organizationName: string;
  standardNpsCalculation: boolean;
}

function useAnalytics(props: PropTypes) {
  const [analytics, setAnalytics] = useState<FollowUpAnalytics>(null);

  useEffect(() => {
    setAnalytics(null);

    const filterSelector = props.filterStates.filterSelector.selected.value;
    AnalyticsFollowUpService.get(
      filterSelector,
      props.filterStates.jobs.selected.map((option) => option.value),
      props.filterStates.departments.selected.map((option) => option.value),
      props.filterStates.hiringManagers.selected.map((option) => option.value),
      props.filterStates.recruiters.selected.map((option) => option.value),
      props.filterStates.locations.selected.map((option) => option.value),
      props.filterStates.surveyTemplates.selected.map((option) => option.value),
      props.filterStates.benchmarkType.selected.value,
      props.filterStates.dateRange,
      props.filterStates.ethnicities.selected.map((option) => option.value),
      props.filterStates.genders.selected.map((option) => option.value),
      props.filterStates.ageRanges.selected.map((option) => option.value),
    ).then((analytics) => {
      setAnalytics(analytics);
    });
  }, [props.filterStates]);

  return analytics;
}

export function FollowUpQuestionsAnalytics(props: PropTypes) {
  const analytics = useAnalytics(props);

  if (analytics === null) {
    return <PageWideSpinner />;
  }

  return (
    <>
      {analytics?.total_responses > 0 ? (
        <div className='flex-column gap-4 mt-3'>
          <CounterPanels
            attributes={analytics.attributes}
            totalCNPSPromoters={
              analytics.total_responses -
              analytics.total_cnps_detractors -
              analytics.total_cnps_neutrals
            }
            totalCNPSDetractors={analytics.total_cnps_detractors}
            totalCNPSNeutrals={analytics.total_cnps_neutrals}
            totalResponses={analytics.total_responses}
            totalPositiveSentiment={analytics.total_positive_sentiment}
            totalNeutralSentiment={analytics.total_neutral_sentiment}
            totalNegativeSentiment={analytics.total_negative_sentiment}
          />
          <PageBreak />
          <Row>
            <Col sm='12' lg='12'>
              <SentimentsByTopicPanel attributes={analytics.attributes} />
            </Col>
          </Row>
          <PageBreak />
          <FollowUpResponsesListPanel
            cnpsFollowUpAnswers={analytics.responses}
            organizationName={props.organizationName}
            maxRowsCollapsed={FOLLOWUP_ANSWERS_MAX_ROWS_COLLAPSED}
            standardNpsCalculation={props.standardNpsCalculation}
          />
        </div>
      ) : (
        <CandidatePulseEmptyState
          title={'No follow-up responses available yet'}
        />
      )}
    </>
  );
}
