import React from 'react';
import { Button } from 'reactstrap';
import { IconButton } from '../../../../../components/IconButton';
import { AttachmentInput } from '../../../../SendEmailModal/EmailModalBody/EmailFooter/AttachmentInput';
import { FileError } from 'use-file-picker';
import { EmailIntegrationFetchState } from '../../../../SendEmailModal/EmailModalBody/EmailFooter/EmailIntegrationFetchState';
import classNames from 'classnames';

interface PropTypes {
  attachmentErrors: FileError[];
  files: File[];
  maxFiles: number;
  maxFileSizeMb: number;
  step: number;
  isValid: boolean;
  isLoading: boolean;
  onClose: () => void;
  onPreview: () => void;
  onSubmit: () => void;
  openFileSelector: () => void;
  setStep: (step: number) => void;
}

function PreviewOrAttachmentOffer(props: PropTypes) {
  if (props.step < 2) {
    return null;
  } else if (props.step == 2) {
    return (
      <AttachmentInput
        classNames='me-auto'
        filesLength={props.files?.length}
        errors={props.attachmentErrors}
        maxFiles={props.maxFiles}
        maxFileSizeMb={props.maxFileSizeMb}
        openFileSelector={props.openFileSelector}
      />
    );
  }

  return (
    <IconButton
      color='borderless'
      onClick={props.onPreview}
      iconName={'bi-eye'}
      buttonText='Preview'
    />
  );
}

function SubmitButton(props: PropTypes) {
  if (props.step < 3) {
    return (
      <Button
        color='primary'
        disabled={!props.isValid || props.isLoading}
        onClick={() => props.setStep(props.step + 1)}
      >
        {props.step < 2 ? 'Next' : 'Review and Send'}
      </Button>
    );
  }

  return (
    <Button color='primary' onClick={props.onSubmit}>
      Send
    </Button>
  );
}

export function Footer(props: PropTypes) {
  return (
    <>
      <PreviewOrAttachmentOffer {...props} />
      <div
        className={classNames(
          'd-flex justify-content-between align-items-center',
          props.isLoading && 'w-100',
        )}
      >
        <div>{props.isLoading && <EmailIntegrationFetchState />}</div>
        <div className='d-flex gap-3'>
          {
            <Button color='danger' onClick={props.onClose}>
              Cancel
            </Button>
          }
          {props.step > 0 && (
            <Button
              color='secondary'
              onClick={() => props.setStep(props.step - 1)}
            >
              Previous
            </Button>
          )}
          <SubmitButton {...props} />
        </div>
      </div>
    </>
  );
}
