import React from 'react';
import { Col, Row } from 'reactstrap';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { Control } from 'react-hook-form';
import { YearsExperienceInput } from '../YearsExperienceInput';

interface PropTypes {
  formControl: Control<CandidateDto>;
}

export function CandidateYearsExperienceInput(props: PropTypes) {
  return (
    <Row>
      <Col xs='3'>
        <YearsExperienceInput
          label={'Years of Relevant Experience'}
          required={false}
          formControl={props.formControl}
        />
      </Col>
    </Row>
  );
}
