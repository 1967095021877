import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';

type ChildElement = JSX.Element | string;

interface PropTypes {
  active?: boolean;
  className?: string;
  isChip?: boolean;
  onClick?: () => void;
  children: ChildElement | ChildElement[];
}

export function SurveyButton(props: PropTypes) {
  return (
    <Button
      className={classNames(
        props.className,
        props.isChip && 'rounded-pill',
        'w-100',
      )}
      color={props.active ? 'primary' : 'secondary'}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}
