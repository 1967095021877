import {
  Product,
  ProductIndustry,
  ProductJobFunctionPartial,
} from '@vonq/hapi-elements-types';
import { CloseableModal } from '../../../../../../../components/CloseableModal';
import React from 'react';
import { Chip, ChipColor } from '../../../../../../../components/Chip';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  product: Product;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

function IndustryFunctionChip(props: { name: string }) {
  return <Chip color={ChipColor.BlueLight}>{props.name}</Chip>;
}

function ModalBody(props: { product: Product }) {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        {props.product.logo_url && (
          <img
            className={classNames(styles['product-image'])}
            src={props.product.logo_url[0].url}
          />
        )}
        <div className='d-flex gap-2 align-items-center'>
          <small className='text-dark-200'>Processing Time</small>
          <Chip
            color={ChipColor.SuccessLight}
          >{`${props.product.time_to_process.period} ${props.product.time_to_process.range}`}</Chip>
        </div>
      </div>
      <div className='mt-2h text-dark-200 fs-5 fw-semibold text-wrap overflow-hidden'>
        {props.product.description}
      </div>
      <div className='d-flex gap-2h mt-3 flex-wrap'>
        {props.product.industries.map((i: ProductIndustry, index: number) => (
          <IndustryFunctionChip key={`i_${index}`} name={i.name} />
        ))}
        {props.product.job_functions.map(
          (jf: ProductJobFunctionPartial, index: number) => (
            <IndustryFunctionChip key={`jf_${index}`} name={jf.name} />
          ),
        )}
      </div>
    </>
  );
}

export function DetailModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={() => props.setIsOpen(false)}
      isOpen={props.isOpen}
      size='lg'
      bodyClassName='p-5h'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle={props.product.title}
      bodyChildren={<ModalBody {...props} />}
    />
  );
}
