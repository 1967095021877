import { ApiService } from '../../../services/ApiService';
import { ProspectInfo } from '../../entities/ProspectInfo';

export class ProspectService {
  public static create(prospectForm): Promise<ProspectInfo> {
    return ApiService.post<ProspectInfo>(
      '/api/reference_check/prospects',
      prospectForm,
    );
  }
}
