import React from 'react';
import { InterviewerParticipant } from '../../../../../../../../../entities/applicant_tracking/InterviewerParticipant';
import { LabelledAvatar } from '../../../../../../../../../components/LabelledAvatar';
import { SpanTooltip } from '../../../../../../../../../components/SpanTooltip';

interface PropTypes {
  participant: InterviewerParticipant;
  candidateView: boolean;
}

function ParticipantLabelledAvatar(props: {
  participant: InterviewerParticipant;
  labelClassName?: string;
}) {
  return (
    <LabelledAvatar
      type={props.participant.user.photoUrl ? 'photo' : 'initials'}
      name={props.participant.user.photoUrl || props.participant.user.name}
      label={props.participant.user.name}
      labelClassName={props.labelClassName}
    />
  );
}

function IgnoredParticipantLabelledAvatar(props: {
  participant: InterviewerParticipant;
}) {
  return (
    <SpanTooltip
      text={
        <ParticipantLabelledAvatar
          participant={props.participant}
          labelClassName={'opacity-50'}
        />
      }
      tooltipText={'This participant calendar has been ignored'}
      trigger='hover'
    />
  );
}

export function InterviewerParticipantIdentification(props: PropTypes) {
  if (props.participant.ignoreCalendar)
    return <IgnoredParticipantLabelledAvatar participant={props.participant} />;

  return <ParticipantLabelledAvatar participant={props.participant} />;
}
