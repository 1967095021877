import React from 'react';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';
import { SurveyQuestion } from '../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionField } from '../../../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestionFieldType } from '../../../../entities/survey_engine/SurveyQuestionFieldType';

interface PropTypes {
  question: SurveyQuestion;
  classNames?: string;
  style?: React.CSSProperties;
}

const hasLegend = (fields: SurveyQuestionField[]): boolean => {
  return fields
    .map((field) =>
      field.options
        .map((opt) => opt.description != null && opt.description != '')
        .every((v) => v === true),
    )
    .some((v) => v === true);
};

const getFieldForLegend = (
  fields: SurveyQuestionField[],
): SurveyQuestionField => {
  return fields.find((field) =>
    field.options
      .map((opt) => opt.description != null)
      .every((v) => v === true),
  );
};

const generateMinMaxOptionsText = (question: SurveyQuestion): string => {
  if (question.max_options == null) return '';

  const type = question.fields[0].type;
  if (
    (type === SurveyQuestionFieldType.MultiOptions ||
      type === SurveyQuestionFieldType.Options) &&
    question.min_options === 1 &&
    question.max_options === 1 &&
    question.min_options === question.max_options
  )
    return ' (Choose one)';

  if (question.max_options <= question.min_options) return '';

  if (question.min_options === 1) {
    return ` (You can select up to ${question.max_options})`;
  } else {
    return ` (You can select between ${question.min_options} and ${question.max_options})`;
  }
};

export function SurveyQuestionTitle(props: PropTypes) {
  return (
    <Row>
      <Col
        xs='12'
        className={classnames({
          'mb-2 ':
            hasLegend(props.question.fields) &&
            !props.classNames?.includes('mb-0'),
        })}
        style={props.style}
      >
        <h3 className={`${props.classNames}`}>
          {props.question.title}
          {generateMinMaxOptionsText(props.question)}
        </h3>
      </Col>
      {hasLegend(props.question.fields) && (
        <Col xs='12'>
          {getFieldForLegend(props.question.fields)
            .options.sort((a, b) => a.weight - b.weight)
            .map((opt) => (
              <>
                <b>
                  {opt.weight}. {opt.name}
                </b>
                , {opt.description}.<br />
              </>
            ))}
        </Col>
      )}
    </Row>
  );
}
