import React, { useEffect, useState } from 'react';
import { HiringTeamAnalytics } from '../../../../../entities/candidate_experience/HiringTeamAnalytics';
import { AnalyticsHiringTeamService } from '../../../../../services/candidate_experience/AnalyticsHiringTeamService';
import { FilterStates } from '../../../../../components/candidate_experience/Filters/filterState';
import { PageWideSpinner } from '../../../../../components/PageWideSpinner';
import { HiringTeamTab } from './HiringTeamPanel/HiringTeamTable/TableTopTabs';
import { HiringTeamPanel } from './HiringTeamPanel';
import { PageFilters } from '..';
interface PropTypes {
  filterStates: FilterStates;
  pageFilters: PageFilters;
  setPageFilters: (pageFilters: PageFilters) => void;
}

function setAnalyticsUseEffect(
  props: PropTypes,
  setAnalytics: (analytics: HiringTeamAnalytics) => void,
  pageFilters: PageFilters,
) {
  useEffect(() => {
    AnalyticsHiringTeamService.get(
      props.filterStates.jobs.selected.map((option) => option.value),
      props.filterStates.departments.selected.map((option) => option.value),
      props.filterStates.locations.selected.map((option) => option.value),
      props.filterStates.surveyTemplates.selected.map((option) => option.value),
      props.filterStates.dateRange,
      pageFilters.name,
      pageFilters.page,
      10,
      pageFilters.sortColumn,
      pageFilters.sortDirection == 1 ? 'ASC' : 'DESC',
      pageFilters.activeTab,
    ).then((analytics) => {
      setAnalytics(analytics);
    });
  }, [
    props.filterStates,
    pageFilters.name,
    pageFilters.page,
    pageFilters.sortColumn,
    pageFilters.sortDirection,
    pageFilters.activeTab,
  ]);
}

export function HiringTeamAnalyticsPanel(props: PropTypes) {
  const [analytics, setAnalytics] = useState<HiringTeamAnalytics>(null);

  function updateHiringMembers(name: string) {
    props.setPageFilters({ ...props.pageFilters, name, page: 1 });
  }

  function updatePage(page: number) {
    props.setPageFilters({ ...props.pageFilters, page });
  }

  async function updateSort(key: string) {
    props.setPageFilters({
      ...props.pageFilters,
      sortColumn: key,
      sortDirection: props.pageFilters.sortDirection === 1 ? -1 : 1,
    });
  }

  function updateActiveTab(activeTab: HiringTeamTab) {
    props.setPageFilters({ ...props.pageFilters, activeTab, page: 1 });
  }

  setAnalyticsUseEffect(props, setAnalytics, props.pageFilters);

  if (analytics === null) {
    return <PageWideSpinner />;
  }

  return (
    <HiringTeamPanel
      updateHiringMembers={updateHiringMembers}
      analytics={analytics}
      page={props.pageFilters.page}
      updatePage={updatePage}
      sortColumn={props.pageFilters.sortColumn}
      sortDirection={props.pageFilters.sortDirection}
      onSortUpdate={updateSort}
      activeTab={props.pageFilters.activeTab}
      updateActiveTab={updateActiveTab}
    />
  );
}
