interface ScoreLegend {
  minScore: number;
  label: string;
  color: string;
}

export const SCORE_LEGEND: ScoreLegend[] = [
  { minScore: 0.0, label: 'Poor', color: '#ED6060' },
  { minScore: 0.26, label: 'Fair', color: '#F2994A' },
  { minScore: 0.51, label: 'Good', color: '#51B37F' },
  { minScore: 0.71, label: 'Very Good', color: '#51B37F' },
  { minScore: 0.91, label: 'Excellent', color: '#51B37F' },
];
