import React from 'react';
import { FormState } from 'react-hook-form';
import { Button } from 'reactstrap';
import { CandidateDto } from '../../services/applicant_tracking/CandidateService/CandidateDto';
import { LoadingSpinnerIcon } from '../LoadingSpinnerIcon';

interface PropTypes {
  formState: FormState<CandidateDto>;
  children: React.ReactNode;
  value?: string;
}

export function SubmitButton(props: PropTypes) {
  return (
    <Button
      className='d-flex align-items-center gap-2'
      type='submit'
      color='primary'
      disabled={!props.formState.isValid || props.formState.isSubmitting}
      value={props.value}
    >
      {props.formState.isSubmitting && <LoadingSpinnerIcon size='sm' />}
      {props.children}
    </Button>
  );
}
