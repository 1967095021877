import React, { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { EmailTemplate } from '../../../../../../../../entities/EmailTemplate';
import { PlanTemplate } from '../../../../../../../../entities/applicant_tracking/PlanTemplate';
import {
  SendSelfSchedulingEmailTrigger,
  Trigger,
} from '../../../../../../../../entities/applicant_tracking/Trigger';
import { DelayDropdown } from '../Dropdown/DelayDropdown';
import { StagesDropdown } from '../Dropdown/StagesDropdown';
import { EmailTemplateDropdown } from '../Dropdown/EmailTemplateDropdown';
import { InterviewTemplateDropdown } from '../Dropdown/InterviewTemplateDropdown';

interface PropTypes {
  emailTemplates: EmailTemplate[];
  counter: number;
  trigger: Trigger;
  formData: Trigger[];
  planTemplate?: PlanTemplate;
  onUpdateTrigger: (trigger: Trigger, index: number) => void;
}

function prefillInterviewTemplateIfAssignedToStage(
  planTemplate: PlanTemplate,
  selectedStages: SelectOption[],
  interviewTemplateOptions: SelectOption[],
) {
  const selectedInterviewTemplate = interviewTemplateOptions.find(
    (o) => o.selected,
  );

  // do not change the input if already selected
  if (selectedInterviewTemplate) {
    return;
  }

  const selectedJobStageIds = selectedStages
    .map(
      (s) =>
        planTemplate.jobStages.find(
          (jst) => jst.jobStageId.toString() === s.value,
        )?.jobStageId,
    )
    .filter(Boolean);

  const defaultInterviewTemplateIds = new Set(
    planTemplate.jobStages
      .filter((js) => selectedJobStageIds.includes(js.jobStageId))
      ?.map((js) => js.interviewTemplate?.id)
      ?.filter(Boolean),
  );

  if (defaultInterviewTemplateIds?.size > 0) {
    const defaultInterviewTemplateId = defaultInterviewTemplateIds
      .values()
      .next().value;
    const interviewTemplateOption = interviewTemplateOptions.find(
      (o) => o.value === defaultInterviewTemplateId.toString(),
    );

    if (interviewTemplateOption) {
      interviewTemplateOption.selected = true;
    }
  }
}

export function SendSelfSchedulingLinkTriggerRow(props: PropTypes) {
  const [interviewTemplatesLoading, setInterviewTemplatesLoading] =
    useState<boolean>(true);
  const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
  const [interviewTemplateOptions, setInterviewTemplateOptions] = useState<
    SelectOption[]
  >([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [delayOptions, setDelayOptions] = useState([]);

  const selectedStages = stageOptions.filter((o) => o.selected);

  useEffect(() => {
    prefillInterviewTemplateIfAssignedToStage(
      props.planTemplate,
      selectedStages,
      interviewTemplateOptions,
    );
  }, [selectedStages]);

  const updateTrigger = () => {
    if (interviewTemplatesLoading) {
      return;
    }

    props.onUpdateTrigger(
      {
        ...props.trigger,
        type: 'send_self_scheduling_email',
        emailTemplateId: parseInt(
          emailTemplateOptions.find((v) => v.selected)?.value,
        ),
        interviewTemplateId: parseInt(
          interviewTemplateOptions.find((v) => v.selected)?.value,
        ),
        applicationStatuses: ['active'],
        stageIds: stageOptions
          .filter((v) => v.selected)
          .map((v) => parseInt(v.value)),
        delayDays: parseInt(delayOptions.find((v) => v.selected)?.value ?? ''),
      },
      props.counter,
    );
  };

  useEffect(updateTrigger, [
    emailTemplateOptions,
    interviewTemplateOptions,
    stageOptions,
    delayOptions,
  ]);

  return (
    <>
      <EmailTemplateDropdown
        trigger={props.trigger as SendSelfSchedulingEmailTrigger}
        emailTemplates={props.emailTemplates}
        emailTemplateOptions={emailTemplateOptions}
        setEmailTemplateOptions={setEmailTemplateOptions}
        isMandatory={false}
      />
      <StagesDropdown
        label={'Stage'}
        trigger={props.trigger}
        planTemplate={props.planTemplate}
        stageOptions={stageOptions}
        setStageOptions={setStageOptions}
        onlyInterviewStages={true}
      />
      <InterviewTemplateDropdown
        trigger={props.trigger as SendSelfSchedulingEmailTrigger}
        interviewTemplateOptions={interviewTemplateOptions}
        setInterviewTemplateOptions={setInterviewTemplateOptions}
        interviewTemplatesLoading={interviewTemplatesLoading}
        setInterviewTemplatesLoading={setInterviewTemplatesLoading}
      />
      <DelayDropdown
        trigger={props.trigger}
        delayOptions={delayOptions}
        setDelayOptions={setDelayOptions}
      />
    </>
  );
}
