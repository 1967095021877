import classNames from 'classnames';
import React from 'react';
import { Metrics } from '../../../../components/training/Metrics';
import { Topics } from '../../../../components/training/Topics';

interface PropTypes {
  topics: string[];
  durationMillis: number;
  views: number;
}

export function SessionSubinfo(props: PropTypes) {
  return (
    <div
      id='session-subinfo'
      className={classNames(
        'd-flex',
        'g-3',
        'mb-4',
        'text-truncate',
        'justify-content-between',
        'align-items-center',
      )}
    >
      <Topics topics={props.topics} />
      <div className='ms-3'>
        <Metrics
          durationMillis={props.durationMillis}
          views={props.views}
          showRating={false}
        />
      </div>
    </div>
  );
}
