import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'reactstrap';
import { SurveyPanel } from '../../../../../components/survey_engine/SurveyPanel';
import { Survey } from '../../../../../entities/survey_engine/Survey';
import styles from './styles.module.scss';

interface PropTypes {
  survey: Survey;
}

const REFERRAL_PATH = 'referral';

export function ReferralPanel(props: PropTypes) {
  const [skip, setSkip] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleOnFillReferralClick = useCallback(async () => {
    navigate(REFERRAL_PATH);
  }, [props.survey]);

  const handleOnSkipClick = useCallback(() => {
    setSkip(!skip);
  }, []);

  return (
    <SurveyPanel className='mt-4 mb-4 container'>
      {!skip ? (
        <>
          <div className='mt-4 text-center'>
            <h1>Do you know someone we should meet?</h1>
            <p className={classNames('mt-3 mb-4 mx-auto', styles.text)}>
              We’re always looking to connect talent with amazing jobs. It’s
              that easy, just leave a referral and help us make our/your team
              even stronger.
            </p>
          </div>

          <div className='d-flex justify-content-center'>
            <div>
              <Button
                size='lg'
                className='btn btn-lg btn-secondary py-2 px-5 me-3'
                onClick={handleOnSkipClick}
              >
                Skip
              </Button>
            </div>
            <div>
              <Button
                color='primary'
                size='lg'
                className='py-2 px-5'
                onClick={handleOnFillReferralClick}
              >
                Fill Referral
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='mt-4 text-center'>
            <h1>Maybe next time...</h1>
            <p className={classNames('mt-3 mb-4 mx-auto', styles.text)}>
              We are always looking for great talent so keep us posted. See you
              soon!
            </p>
          </div>
        </>
      )}
    </SurveyPanel>
  );
}
