import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import styles from './styles.module.scss';
import { ContentLength, ContentType, TextAreaFormatState } from './types';
import { FormatsRow } from './FormatsRow';

interface PropTypes {
  placeholder: string;
  className?: string;
  disabled?: boolean;
  includeFormats?: boolean;
  value?: string;
  formatChangesDisabled?: boolean;
  readOnly?: boolean;
  onChange: (state: TextAreaFormatState) => void;
}

export function TextAreaWithFormats(props: PropTypes) {
  const [contentType, setContentType] = useState<ContentType>('paragraphs');
  const [contentLength, setContentLength] = useState<ContentLength>('medium');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const focusTextArea =
    isFocused && !props.readOnly && !props.disabled && props.includeFormats;

  const handleChange = (value?: string) => {
    props.onChange({ value: value ?? props.value, contentType, contentLength });
  };

  useEffect(handleChange, [contentType, contentLength]);

  return (
    <div
      className={classNames(
        'rounded-1',
        focusTextArea && styles['focused-outer-container'],
      )}
    >
      {props.includeFormats && (
        <FormatsRow
          disabled={props.formatChangesDisabled}
          contentType={contentType}
          contentLength={contentLength}
          setContentType={setContentType}
          setContentLength={setContentLength}
        />
      )}
      <Input
        disabled={props.disabled}
        readOnly={props.readOnly}
        className={classNames(
          props.className,
          'form-control fs-5',
          props.includeFormats && styles['textarea-with-formats'],
        )}
        value={props.value || undefined}
        placeholder={props.placeholder}
        type='textarea'
        onChange={(v) => handleChange(v.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  );
}
