import { Controller } from 'stimulus';

import { ApiService } from '../services/ApiService';

export default class extends Controller {
  static values = {
    joinUrl: String,
    meetingUrl: String,
  };

  declare readonly joinUrlValue: string;
  declare readonly meetingUrlValue: string;

  async launch() {
    await ApiService.put(this.joinUrlValue, {
      interview: {
        meeting_provider: null,
        meeting_url: this.meetingUrlValue,
      },
    });

    window.location.href = this.meetingUrlValue;
  }
}
