import React, { useRef, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { SurveyQuestion } from '../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../../../entities/survey_engine/SurveyQuestionAnswer';
import { hasAnsweredAllQuestions } from '../../../../utils/survey_engine/hasAnsweredAllQuestions';

function ButtonWithProps(props: {
  onClick: () => void;
  fontKerning?: number;
  canSubmitSurvey: boolean;
}) {
  return (
    <Button
      size='lg'
      onClick={props.onClick}
      disabled={!props.canSubmitSurvey}
      style={{
        letterSpacing: props.fontKerning ? `${props.fontKerning}em` : null,
      }}
    >
      Submit
    </Button>
  );
}

export function SubmitFeedbackButton(props: {
  surveyAnswers: SurveyQuestionAnswer[];
  allQuestions: SurveyQuestion[];
  style?: React.CSSProperties;
  fontKerning?: number;
  onClick: () => void;
}) {
  const ref = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const canSubmitSurvey = hasAnsweredAllQuestions(
    props.surveyAnswers,
    props.allQuestions,
  );

  return (
    <div>
      <div ref={ref} className='d-inline-block'>
        <ButtonWithProps
          onClick={props.onClick}
          canSubmitSurvey={canSubmitSurvey}
          fontKerning={props.fontKerning}
        />
      </div>
      <Tooltip
        target={ref}
        isOpen={!canSubmitSurvey && isTooltipOpen}
        toggle={() => setIsTooltipOpen(!isTooltipOpen)}
      >
        You must answer all questions to submit.
      </Tooltip>
    </div>
  );
}
