import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Icon } from '../../../../Icon';
import { sanitizeUrl } from '../../../utils/url';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { IS_APPLE } from '../../../../../utils/IsApple';
import classNames from 'classnames';

interface PropTypes {
  isEditable: boolean;
  isLink: boolean;
  setIsLinkEditMode: React.Dispatch<boolean>;
}

export function HyperlinkButton(props: PropTypes) {
  const [editor] = useLexicalComposerContext();

  return (
    <button
      disabled={!props.isEditable}
      onClick={() => {
        if (!props.isLink) {
          props.setIsLinkEditMode(true);
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
        } else {
          props.setIsLinkEditMode(false);
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
      }}
      className={classNames('mx-1', { active: props.isLink })}
      title={IS_APPLE ? 'Insert link (⌘K)' : 'Insert link (Ctrl+K)'}
      type='button'
      aria-label={'Insert link'}
    >
      <Icon name={'bi-link-45deg'} />
    </button>
  );
}
