import React, { useEffect, useState } from 'react';
import { CreateJobDto } from '../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { GlobalState, useStateMachine } from 'little-state-machine';
import { JobDetails } from '../../../../../services/applicant_tracking/JobService/EditJobDto';
import { JobAndJobPost, initCreateJobDto } from '..';
import { getReferrer } from '../referrers';
import { useForm } from 'react-hook-form';
import { handleFormErrors } from '../../../../../services/ApiService/handler';
import { JobService } from '../../../../../services/applicant_tracking/JobService';
import { Card } from '../../../../../components/Card';
import { JobOrganicPostsHeader } from './JobOrganicPostsHeader';
import { Col, Row } from 'reactstrap';
import { JobOrganicPostsToggle } from './JobOrganicPostsToggle';
import { AlertMessage } from '../../../../../components/AlertMessage';
import {
  JobOrganicPostsDetails,
  prefillCampaignForm,
} from './JobOrganicPostsDetails';
import { JobPostingDetailsForm } from '../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails';
import { initialize, windowHapi } from '../../../../../config/vonq';
import { TokenService } from '../../../../../services/applicant_tracking/analytics/jobboards/TokenService';
import { LocationService } from '../../../../../services/applicant_tracking/LocationService';
import { Location } from '../../../../../entities/applicant_tracking/Location';
import { WindowWithHapi } from '../../../../JobPage/JobBoardsTab';
import { TargetGroupValues } from '../../../../JobPage/JobBoardsTab/JobBoardsTabUI/JobPostingDetails/TargetGroup/TargetGroupValues';
import {
  formIsValid,
  validateForm,
} from '../../../../JobPage/JobBoardsTab/utils/formIsValid';
import { PostDetailsService } from '../../../../../services/applicant_tracking/analytics/jobboards/PostDetailsService';
import { PostDetails } from '../../../../../entities/applicant_tracking/jobboards/PostDetails';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { NotPublishedJobWarningModal } from './NotPublishedJobWarningModal';
import { OrganizationService } from '../../../../../services/v1/OrganizationService';

interface PropTypes {
  setupStep: number;
  initJobDto: CreateJobDto;
  isEditFlow: boolean;
  jobDetails: JobDetails;
  organicPostSkillsEnabled: boolean;
  onSubmit: (state: CreateJobDto) => Promise<JobAndJobPost>;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
  updateLocalStorage: (state: GlobalState, payload: any) => GlobalState;
}

function OrganicPostsAlert() {
  return (
    <AlertMessage
      hasOverlay={false}
      icon={{ name: 'bi-info-circle' }}
      className='fs-5 bg-light-warning'
      text={
        'Organic posts are cost-free and significantly boost the reach of your job postings. To maximize visibility, ensure that all job and job posting detail fields are fully completed.'
      }
    />
  );
}

const OPTIONAL_FIELDS = [
  'title',
  'address2',
  'companyId',
  'remote',
  'contactName',
  'contactEmail',
  'contactPhone',
  'salaryPeriod',
  'salaryCurrency',
  'salaryRangeFrom',
  'salaryRangeTo',
  'weeklyHoursFrom',
  'editorStateHtml',
  'jobPostUrl',
  'applicationFormUrl',
  'yearsOfExperience',
  'educationLevel',
  'seniority',
];

async function loadTargetGroup(
  window: WindowWithHapi,
): Promise<TargetGroupValues> {
  const [industries, jobFunctions] = await Promise.all([
    window.hapi.product.api.getIndustries(),
    window.hapi.product.api.getJobFunctions(),
  ]);

  return {
    industries,
    jobFunctions,
    educationLevels: [], // Not needed for organic purposes
    seniorities: [], // Not needed for organic purposes
  };
}

export function SetupJobOrganicPostsPage(props: PropTypes) {
  const updateLocalStorage = props.updateLocalStorage;
  const { actions, state } = useStateMachine({ updateLocalStorage });
  const [isLoading, setIsLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [warningModal, setWarningModal] = useState(false);
  const [form, setForm] = useState<JobPostingDetailsForm>(
    props.initJobDto.organicPostForm,
  );
  const [postDetails, setPostDetails] = useState<PostDetails>(null);
  const [targetGroup, setTargetGroup] = useState(null);

  const { handleSubmit, formState, getValues, setError } =
    useForm<CreateJobDto>({
      mode: 'onChange',
      defaultValues: props.initJobDto,
    });

  const resetAndExit = () => {
    actions.updateLocalStorage(initCreateJobDto);

    window.location.href = getReferrer();
  };

  const submitData = async (data: CreateJobDto) => {
    try {
      await props.onSubmit({ ...data, organicPostForm: form });

      return true;
    } catch (e: unknown) {
      handleFormErrors(e, setError);
    }

    return false;
  };

  const onSubmit = handleSubmit(async (data: CreateJobDto) => {
    const isSuccessful = await submitData(data);
    if (!isSuccessful) return;

    await JobService.publish(state.setupJob.jobId.toString(), true);
    resetAndExit();
  });

  const onSave = handleSubmit(async (data: CreateJobDto) => {
    const isSuccessful = await submitData(data);
    if (!isSuccessful) return;

    await JobService.submit(state.setupJob.jobId.toString());
    resetAndExit();
  });

  const onNavigate = () => {
    actions.updateLocalStorage({ ...getValues(), organicPostForm: form });
  };

  const window = windowHapi();

  function handleInputChange(name: string, value: unknown) {
    const newForm = { ...form, [name]: value };
    setForm(newForm);

    setFormErrors({ ...formErrors, ...validateForm(newForm) });
  }

  async function injectVonqElements() {
    const token = await TokenService.get();
    const organization = await OrganizationService.current({
      expand: ['organization_info'],
    });

    try {
      if (token) {
        await initialize(token.token, organization.organizationInfo?.currency);
        setIsLoading(false);
      } else {
        // handle the error
      }
    } catch (e: any) {
      // handle the error
    }
  }

  useEffect(() => {
    (async () => {
      const callback = async () => {
        await injectVonqElements();
        removeEventListener('hapi:load:injector', callback);
      };

      if (window.hapiInjector == null) {
        window.addEventListener('hapi:load:injector', callback);
      } else {
        await injectVonqElements();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isLoading) {
        if (form == null) {
          let jobPostData: PostDetails = null;
          let location: Location = null;

          if (state.setupJob?.jobId) {
            const postDetailList = await PostDetailsService.list({
              jobId: state.setupJob.jobId,
              expand: ['organic_post_skills'],
            });
            jobPostData = postDetailList.postDetails?.[0];
            setPostDetails(jobPostData);
          }

          if (!props.isEditFlow) {
            // Prefill LinkedIn with the previous Job's value.
            const postDetailList = await PostDetailsService.list({
              order: 'created_at desc',
              limit: 1,
              withCompanyId: true,
            });
            const prevPostDetail = postDetailList.postDetails?.[0];
            if (jobPostData) {
              jobPostData.companyId = prevPostDetail?.companyId;
            } else {
              jobPostData = { id: null, companyId: prevPostDetail?.companyId };
            }
          }

          try {
            if (state.setupJob?.locationId != null) {
              location = await LocationService.get(state.setupJob.locationId);
            }
          } catch (e: any) {
            // ignore
          }

          setForm(prefillCampaignForm(state.setupJob, location, jobPostData));
        }

        setTargetGroup(await loadTargetGroup(window));
      }
    })();
  }, [isLoading]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <NotPublishedJobWarningModal
        isOpen={warningModal}
        onSubmit={onSave}
        setIsOpen={setWarningModal}
      />
      <Card>
        <form>
          <JobOrganicPostsHeader
            organicEnabled={form?.organicEnabled}
            setWarningModal={setWarningModal}
            organicPostValid={
              form?.organicEnabled ? formIsValid(form, OPTIONAL_FIELDS) : true
            }
            formState={formState}
            {...props}
            onCancel={() => {
              actions.updateLocalStorage(initCreateJobDto);
              window.location.href = getReferrer();
            }}
            onSave={onSave}
            onSubmit={onSubmit}
            jobDetails={props.jobDetails}
            onNavigate={onNavigate}
          />
          <div className='d-flex flex-column gap-2h mt-4'>
            <OrganicPostsAlert />
            <Row>
              <Col xs='8'>
                <JobOrganicPostsToggle
                  enabled={form?.organicEnabled}
                  form={form}
                  setEnabled={() =>
                    handleInputChange('organicEnabled', !form?.organicEnabled)
                  }
                />
              </Col>
              <Col xs='4'>
                <JobOrganicPostsDetails
                  job={state.setupJob}
                  isLoading={isLoading}
                  form={form}
                  postDetails={postDetails}
                  targetGroup={targetGroup}
                  organicPostSkillsEnabled={props.organicPostSkillsEnabled}
                  onInputChange={handleInputChange}
                  setForm={setForm}
                  formErrors={formErrors}
                />
              </Col>
            </Row>
          </div>
        </form>
      </Card>
    </>
  );
}
