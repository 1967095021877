import React from 'react';
import { Alert, AlertType } from '../../../../../components/Alert';

export enum JobBoardAlertType {
  ProductAdded = 'product_added_success',
  ProductAddedError = 'product_added_error',
  ProductRemoved = 'product_removed_success',
  ProductRemovedError = 'product_removed_error',
  FormError = 'form_error',
  FormSaved = 'form_saved_success',
  FormSavedError = 'form_saved_error',
  PaymentSuccess = 'payment_success',
  PaymentError = 'payment_error',
}

export interface JobBoardAlertObject {
  type: JobBoardAlertType;
  message: React.ReactNode;
}

interface PropTypes {
  message: JobBoardAlertObject | null;
  setMessage: (message: JobBoardAlertObject | null) => void;
}

function getAlertType(type: JobBoardAlertType): AlertType {
  switch (type) {
    case JobBoardAlertType.FormError:
    case JobBoardAlertType.FormSavedError:
    case JobBoardAlertType.PaymentError:
    case JobBoardAlertType.ProductRemovedError:
    case JobBoardAlertType.ProductAddedError:
      return AlertType.Danger;
    case JobBoardAlertType.FormSaved:
    case JobBoardAlertType.PaymentSuccess:
    case JobBoardAlertType.ProductAdded:
    case JobBoardAlertType.ProductRemoved:
      return AlertType.Success;
  }
}

// This is not being used yet! Still need designs.
export function JobBoardAlert(props: PropTypes) {
  return (
    props.message && (
      <Alert
        type={getAlertType(props.message?.type)}
        clearable={true}
        autoClearTimeout={3000}
        onClose={() => props.setMessage(null)}
      >
        {props.message.message}
      </Alert>
    )
  );
}
