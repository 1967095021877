import {
  ContentType,
  ContentLength,
} from '../../../../../../../components/TextAreaWithFormats/types';
import { ScorecardService } from '../../../../../../../services/applicant_tracking/ScorecardService';
import { GenerateState } from './FillWithAIButton';

export function generateAiResponse(
  scorecardId: number,
  prompt: string,
  contentType: ContentType,
  contentLength: ContentLength,
  scorecardAttribute: string,
  setValue: (value: string) => void,
  setAiGenerateState: (state: GenerateState) => void,
) {
  let response = '';
  setAiGenerateState('Generating');

  const onMessage = (message: MessageEvent) => {
    response += message.data;
    setValue(response);
  };

  ScorecardService.aiGenerate({
    id: scorecardId,
    contentType,
    contentLength,
    prompt,
    scorecardAttribute,
    onMessage,
    onEnd: () => setAiGenerateState('Regenerate'),
  });
}
