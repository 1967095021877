import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes } from '@lexical/html';

interface PropTypes {
  onChangeText?: (html: string) => void;
}

export function ExportHtmlPlugin(props: PropTypes) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.getEditorState().read(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);

      props.onChangeText?.(htmlString);
    });
  }, [editor.getEditorState()]);

  return null;
}
