import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../ApiService';

export class OfferService {
  public static accept(id: string): Promise<void> {
    return ApiService.put<void>(`/api/applicant_tracking/offers/${id}/accept`);
  }

  public static reject(id: string, reasons?: string[]): Promise<void> {
    return ApiService.put<void>(
      `/api/applicant_tracking/offers/${id}/reject`,
      snakecaseKeys({ rejectReasons: reasons }),
    );
  }

  public static listRejectReasons(id: string): Promise<string[]> {
    return ApiService.get<string[]>(
      `/api/applicant_tracking/offers/${id}/reject_reasons`,
    );
  }
}
