import classNames from 'classnames';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { SurveyQuestion } from '../../../../../../../../entities/survey_engine/SurveyQuestion';
import { QuestionAnswer } from '../../../../../../../../services/applicant_tracking/CareersService/SubmitApplicationDto';
import { placeholder } from '../../../utils/placeholder';

interface PropTypes {
  question: SurveyQuestion;
  control?: Control<any, any>;
  disabled?: boolean;
}

export function CustomTextQuestionInput(props: PropTypes) {
  return (
    <Controller
      name={`customQuestionAnswers.${props.question.id}`}
      control={props.control}
      rules={{
        validate: (v: QuestionAnswer) => {
          if (!props.question.mandatory) return true;
          if (!v) return false;

          return v.fields[0].value != '';
        },
      }}
      render={({ field }) => (
        <textarea
          className={classNames('form-control fs-5')}
          placeholder={placeholder(props.question)}
          rows={props.question.maxOptions ? 1 : 3}
          onChange={(e) => {
            const answer: QuestionAnswer = {
              question_id: props.question.id,
              fields: [
                {
                  field_id: props.question.fields[0].id,
                  value: e.target.value?.trim(),
                },
              ],
            };
            field.onChange(answer);
          }}
          name={field.name}
          ref={field.ref}
          disabled={props.disabled}
        />
      )}
    />
  );
}
