import React from 'react';
import { Table } from 'reactstrap';
import { NpsExplainabilityEntry } from '../../../../../entities/candidate_experience/NpsExplainabilityEntry';
import { NPSExplainabilityTopImpactTableRow } from './NPSExplainabilityTopImpactTableRow';

export interface PropTypes {
  tableDimensionTitle: string;
  npsType: string;
  badgeStyle: string;
  entries: NpsExplainabilityEntry[];
  activeTab: string;
  onTabClick: (name: string) => void;
  standardNpsCalculation: boolean;
}

function hasValidDimensionName(entry: NpsExplainabilityEntry) {
  return (
    entry.dimensions_values?.length > 0 &&
    entry.dimensions_values[0].value?.length > 0
  );
}

export function NPSExplainabilityTopImpactTable(props: PropTypes) {
  const ENTRIES_TO_DISPLAY = 5;

  const entriesToDisplay = props.entries
    .filter((entry) => hasValidDimensionName(entry))
    .slice(0, ENTRIES_TO_DISPLAY);

  return (
    <Table className='fs-5 text-primary table-borderless table-fixed'>
      <thead className='fs-6 text-dark-200'>
        <tr>
          <th scope='col' className='col'>
            {props.tableDimensionTitle}
          </th>
          <th scope='col' className='col col-2 text-center'>
            {props.npsType}
          </th>
          <th scope='col' className='col col-2'>
            Impact
          </th>
          <th scope='col' className='col col-2 text-center'>
            Responses
          </th>
          <th scope='col' className='col col-2 text-center'>
            % of Total
          </th>
        </tr>
      </thead>
      <tbody>
        {entriesToDisplay.map((entry: NpsExplainabilityEntry) => {
          return (
            <NPSExplainabilityTopImpactTableRow
              key={entry.dimensions_values[0]?.value}
              entry={entry}
              badgeStyle={props.badgeStyle}
              standardNpsCalculation={props.standardNpsCalculation}
            />
          );
        })}
      </tbody>
    </Table>
  );
}
