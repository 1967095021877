import React from 'react';
import classNames from 'classnames';
import { CollapsablePanel } from '../ElementPanel/CollapsablePanel';
import { KeyMoment } from '../../entities/KeyMoment';
import styles from './styles.module.scss';
import { KeyMomentBox } from './KeyMomentBox';

interface PropTypes {
  keyMoments?: KeyMoment[];
  onSelectTimestamp: (timestamp: number) => void;
}

export function KeyMoments(props: PropTypes) {
  if (props.keyMoments == null || props.keyMoments?.length === 0) {
    return null;
  }

  return (
    <CollapsablePanel
      elements={props.keyMoments?.map((keyMoment: KeyMoment, index: number) => (
        <KeyMomentBox
          onSelectTimestamp={props.onSelectTimestamp}
          key={index}
          keyMoment={keyMoment}
        />
      ))}
      elementNamePlural={''}
      elementsClassName={classNames(styles['key-moments'])}
      maxElements={4}
      title={'Key Moments'}
      titleClasses={'fs-5 fw-semibold'}
      classNames={'bg-white border-0 rounded-0 p-0'}
    />
  );
}
