import React from 'react';
import { BlankState } from './BlankState';

interface PropTypes {
  type: string;
}

export function UnsupportedState(props: PropTypes) {
  let name;
  switch (props.type) {
    case 'automatic_question':
      name = 'Questions';
      break;
    case 'scorecard_attribute':
      name = 'Attributes';
      break;
  }

  return (
    <BlankState
      title={`${name} are not available yet`}
      subtitle={`We still don't support automatic detection
  for ${name.toLowerCase()} in this language.`}
    />
  );
}
