import { useSortable } from '@dnd-kit/sortable';
import { ClientId } from './Field';
import { Action } from './dispatcher';
import React from 'react';
import { Icon } from '../../../components/Icon';
import classNames from 'classnames';
import { SpanTooltip } from '../../../components/SpanTooltip';
import { IconButton } from '../../../components/IconButton';
import { CSS } from '@dnd-kit/utilities';
import { Input } from 'reactstrap';

export interface FieldOptionData {
  /** Server-side ID. Null if the field is not yet persisted. */
  serverId: number | null;
  /** Client-side ID. Always filled. */
  clientId: ClientId;
  name: string;
}

interface PropTypes {
  sectionId: ClientId;
  fieldId: ClientId;
  data: FieldOptionData;
  dispatch: React.Dispatch<Action>;
  removable: boolean;
  readOnly?: boolean;
}

export function FieldOption(props: PropTypes) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.data.clientId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function handleOptionRemove() {
    props.dispatch({
      type: 'option_remove',
      sectionId: props.sectionId,
      fieldId: props.fieldId,
      optionId: props.data.clientId,
    });
  }

  function handleOptionChange(text: string) {
    props.dispatch({
      type: 'option_data_change',
      sectionId: props.sectionId,
      fieldId: props.fieldId,
      optionId: props.data.clientId,
      data: { ...props.data, name: text },
    });
  }

  return (
    <div ref={setNodeRef} style={style} className='row'>
      <div
        {...listeners}
        {...attributes}
        className='col-auto d-flex align-items-center'
      >
        <Icon
          name='bi-grip-vertical'
          className={classNames({ 'text-muted': props.readOnly })}
        />
      </div>
      <div className='col-4'>
        <Input
          className='fs-5'
          placeholder='Insert option'
          value={props.data.name}
          onChange={(event) => handleOptionChange(event.target.value)}
          disabled={props.readOnly}
        />
      </div>
      <div
        className={classNames('col-auto d-flex align-items-center', {
          invisible: props.readOnly,
        })}
      >
        <SpanTooltip
          tooltipText='Remove Field'
          text={
            <IconButton
              color='borderless'
              size='sm'
              disabled={!props.removable}
              onClick={handleOptionRemove}
              iconName='bi-trash'
            />
          }
        />
      </div>
    </div>
  );
}
