import { Condition } from '..';
import { SelectOption } from '../../../../../components/Select';

export function computeUpdateCondition(
  conditions: Condition[],
  hasInvertCondition: boolean,
  elements: {
    value: string;
    label: string;
    options: SelectOption[];
    singleSelected: SelectOption;
    multiSelected: SelectOption[];
    mandatory: boolean;
    invertCondition?: boolean;
  },
) {
  return conditions.push({
    type: { value: elements.value, label: elements.label },
    available: elements.options,
    singleSelected: elements.singleSelected,
    multiSelected: elements.multiSelected,
    invert: hasInvertCondition
      ? {
          value: elements.invertCondition.toString(),
          label: elements.invertCondition ? 'Excludes' : 'Includes',
        }
      : {
          value: 'false',
          label: 'Includes',
        },
    mandatory: elements.mandatory,
  });
}
