import React from 'react';
import classNames from 'classnames';

interface PropTypes {
  title: string;
  whitelabelEnabled: boolean;
}

export function JobPostCardChip(props: PropTypes) {
  const chipsBackgroundClass = props.whitelabelEnabled
    ? 'bg-gray'
    : 'bg-light-success';

  return (
    <span
      className={classNames(
        'badge',
        'rounded-pill',
        'fw-normal',
        'text-primary',
        'mw-100',
        'text-truncate',
        'fs-6',
        chipsBackgroundClass,
      )}
    >
      {props.title}
    </span>
  );
}
