const platform =
  navigator?.['userAgentData']?.['platform'] || navigator?.platform;

export const CAN_USE_DOM: boolean =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined';

export const IS_APPLE: boolean =
  CAN_USE_DOM && /mac|Mac|iPod|iPhone|iPad/.test(platform);
