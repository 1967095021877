import React, { useState } from 'react';

interface PropTypes {
  value: string;
  onSearch: (text: string) => void;
  disabled: boolean;
}

export function SearchBox(props: PropTypes) {
  const [searchText, setSearchText] = useState<string>(props.value ?? '');
  return (
    <div className='col-auto'>
      <div className='row g-2 justify-content-start justify-content-md-end align-items-center'>
        <div className='col-auto'>
          <form
            className='row gx-1 align-items-center'
            onSubmit={(ev) => {
              props.onSearch(searchText);
              ev.preventDefault();
            }}
          >
            <div className='col-auto'>
              <input
                type='text'
                name='name'
                value={searchText}
                disabled={props.disabled}
                onChange={(ev) => setSearchText(ev.target.value)}
                placeholder='Search Candidates'
                className='form-control search-input'
                style={{ width: '250px' }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
