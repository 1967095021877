import React from 'react';
import { CloseableModal } from '../../../../../components/CloseableModal';
import { ApprovalFlowStep } from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { ModalBody } from './ModalBody';

interface PropTypes {
  steps: ApprovalFlowStep[];
  isActivelyUsed?: boolean;
  onCancel: () => void;
  onSave: () => void;
}

export function ApprovalReviewModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={props.onCancel}
      isOpen={true}
      size='lg'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle='Review and Save'
      bodyChildren={<ModalBody {...props} />}
    />
  );
}
