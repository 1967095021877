import React from 'react';
import { Benchmark } from '../../../../../../components/candidate_experience/Benchmark';
import { DeprecatedPanel } from '../../../../../../components/DeprecatedPanel';
import { PanelFooter } from '../../../../../../components/PanelFooter';
import { PanelHeader } from '../../../../../../components/PanelHeader';
import { HiringTeamAnalytics } from '../../../../../../entities/candidate_experience/HiringTeamAnalytics';
import { CandidatePulseEmptyState } from '../../../CandidatePulseEmptyState';
import { HiringTeamTable } from './HiringTeamTable';
import { HiringTeamTab, TableTopTabs } from './HiringTeamTable/TableTopTabs';

function hasAnalytics(analytics: HiringTeamAnalytics) {
  return Object.keys(analytics.hiring_members).length > 0;
}

export function HiringTeamPanel(props: {
  updateHiringMembers: (name: string) => void;
  analytics: HiringTeamAnalytics;
  page: number;
  updatePage: (page: number) => void;
  sortColumn: string;
  sortDirection: number;
  onSortUpdate: (sortColumn: string) => void;
  activeTab: HiringTeamTab;
  updateActiveTab: (tab: HiringTeamTab) => void;
}) {
  return (
    <DeprecatedPanel additionalInnerContainerClassNames='pt-4'>
      <div>
        <PanelHeader
          onSearchUpdated={(name) => props.updateHiringMembers(name)}
          className={'ps-4 pe-4'}
          title={'Leaderboard'}
          placeholder={'Search Hiring Team'}
          searchInputSize={'md'}
        />
        <>
          <div className='pt-3 d-flex pe-4'>
            <TableTopTabs
              activeTab={props.activeTab}
              updateActiveTab={props.updateActiveTab}
            />
            <div className='ms-auto d-flex text-end gap-3'>
              <Benchmark
                label='CNPS Company AVG'
                value={props.analytics.cnps_company_avg}
              />
              <Benchmark
                label='Company Score AVG'
                value={props.analytics.company_score_avg}
              />
            </div>
          </div>
          {hasAnalytics(props.analytics) ? (
            <>
              <HiringTeamTable
                hiring_members={props.analytics.hiring_members}
                sortColumn={props.sortColumn}
                sortDirection={props.sortDirection}
                onSortUpdate={props.onSortUpdate}
                standardNpsCalculation={
                  props.analytics.ffPulseStandardNpsCalculation
                }
              />
              <PanelFooter
                page={props.page}
                pageSize={10}
                totalRows={props.analytics.hiring_members_count}
                disabled={false}
                onPageChanged={(page) => props.updatePage(page)}
                className={'ps-4 pe-4 pb-4'}
              />
            </>
          ) : (
            <CandidatePulseEmptyState
              title={'No Hiring Team members found'}
              text="You don't have any team members with this name, try to search another name or contact your manager."
            />
          )}
        </>
      </div>
    </DeprecatedPanel>
  );
}
