import React from 'react';
import { IconButton } from '../../../../../components/IconButton';
import { bulkApplicationReviewUrl } from '../../../../../utils/applicant_tracking/bulkApplicationReview';
import { PipelineApplication } from '../../../../../entities/applicant_tracking/PipelineApplication';

interface PropTypes {
  applications: PipelineApplication[];
}

export function JobStageBulkReviewButton(props: PropTypes) {
  return (
    <div className='pt-2h d-flex justify-content-center'>
      <IconButton
        iconName='bi-people'
        buttonText='Review Candidates'
        color='borderless'
        size='sm'
        onClick={() =>
          (window.location.href = bulkApplicationReviewUrl(
            props.applications.map((a) => a.id),
          ))
        }
      />
    </div>
  );
}
