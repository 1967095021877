import React from 'react';
import { SurveyLayout } from '../../../../components/survey_engine/SurveyLayout';
import { SurveyPanel } from '../../../../components/survey_engine/SurveyPanel';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { DemographicPanel } from './DemographicPanel';
import { ReferralPanel } from './ReferralPanel';
import { Survey } from '../../../../entities/survey_engine/Survey';
import { getCpsQuestion } from '../../../../utils/candidate_experience/getCpsQuestion';
import { getSurveyQuestions } from '../../../../utils/survey_engine/getSurveyQuestions';
import { SurveyTarget } from '../../../../entities/survey_engine/SurveyTarget';
import { CandidateExperienceSurvey } from '../../../../entities/candidate_experience/CandidateExperienceSurvey';
import { DefaultEndScreen } from './DefaultEndScreen';
import { GlassdoorLinkScreen } from './GlassdoorLinkScreen';

interface PropTypes {
  survey: Survey;
  finishScreenImage?: string;
  headingStyle?: React.CSSProperties;
}

const shouldDisplayGlassDoorLink = (survey: Survey) => {
  const PROMOTER_ANSWER_VALUES = ['9', '10'];

  const cpsQuestion = getCpsQuestion(getSurveyQuestions(survey.sections));
  const promoterSurveyAnswer = survey.answers.find(
    (answer) => answer.question_id === cpsQuestion?.['id'],
  );

  const promoterSurveyAnswerValue =
    promoterSurveyAnswer?.fields[0].value.toString();

  return (
    PROMOTER_ANSWER_VALUES.includes(promoterSurveyAnswerValue) &&
    survey.company_info.glassdoor_interview_review_url &&
    survey.survey_template != 'Post Application Track' &&
    survey.target == SurveyTarget.Candidate
  );
};

export function SurveyFinalScreen(props: PropTypes) {
  const surveyDetails = props.survey.surveyDetails as CandidateExperienceSurvey;
  const finishScreenImageClass = props.survey.company_info
    .enable_candidate_demographic_questions
    ? styles.reducedFinishScreenImage
    : 'mt-5 w-25';
  const fillReferralEnabled =
    surveyDetails.fillReferralEnabled &&
    props.survey.company_info.survey_referral_enabled;

  return (
    <SurveyLayout companyInfo={props.survey.company_info}>
      <SurveyPanel className='text-center'>
        <>
          <img
            className={classNames('mt-5 w-25', finishScreenImageClass)}
            src={props.finishScreenImage}
          />
          <h1 className='mt-3' style={props.headingStyle}>
            Your feedback was submitted!
          </h1>
          {shouldDisplayGlassDoorLink(props.survey) ? (
            <GlassdoorLinkScreen survey={props.survey} />
          ) : (
            <DefaultEndScreen survey={props.survey} />
          )}
        </>
      </SurveyPanel>
      {fillReferralEnabled && <ReferralPanel survey={props.survey} />}
      {props.survey.target === SurveyTarget.Candidate &&
        props.survey.company_info.enable_candidate_demographic_questions &&
        !fillReferralEnabled && <DemographicPanel survey={props.survey} />}
    </SurveyLayout>
  );
}
