import { PipelineApplication } from '../../../entities/applicant_tracking/PipelineApplication';
import {
  Application,
  ApplicationStatus,
  BriefApplication,
} from '../../../entities/Application';
import { JobHiringMemberRole } from '../../../entities/JobHiringMemberRole';
import { User } from '../../../entities/User';
import {
  ApplicationActionInfo,
  getApplicationActionInfo,
} from './applicationActions';

export function buildScheduleInterviewPagePath(
  applicationId: number | string,
  interviewStageId?: number | string,
  selfSchedule?: boolean,
) {
  const schedulingType = selfSchedule
    ? 'interview_candidate_users/new'
    : 'interviews/schedule';
  return (
    `/applicant_tracking/applications/${applicationId}/${schedulingType}?` +
    `interview_stage_id=${interviewStageId}`
  );
}

export function buildSchedulingActionInfo(
  application: Application | BriefApplication | PipelineApplication,
  currentUser: User,
  jobHiringMemberRole: JobHiringMemberRole,
): ApplicationActionInfo {
  const baseApplicationInfo = getApplicationActionInfo(
    application,
    currentUser.isAdmin,
    jobHiringMemberRole,
    'Schedule an interview',
  );

  if (!baseApplicationInfo.allowed || baseApplicationInfo.disabled) {
    return baseApplicationInfo;
  } else if (!application.nextInterviewStageId) {
    return {
      allowed: true,
      disabled: true,
      tooltipText: 'Scheduling is only available for stages with interviews.',
    };
  } else if (application.status === ApplicationStatus.Rejected) {
    return {
      allowed: true,
      disabled: true,
      tooltipText: 'Scheduling is not available for rejected applications.',
    };
  } else {
    return {
      allowed: true,
      disabled: false,
      tooltipText: 'Schedule an interview',
    };
  }
}
