import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form';
import { Button } from 'reactstrap';
import { BackArrow } from '../../../../components/BackArrow';
import { Card } from '../../../../components/Card';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { PageTitle } from '../../../../components/PageTitle';
import { Application } from '../../../../entities/applicant_tracking/Application';
import { Candidate } from '../../../../entities/applicant_tracking/Candidate';
import { JobPost } from '../../../../entities/applicant_tracking/JobPost';
import { Source } from '../../../../entities/applicant_tracking/Source';
import { ApplicationService } from '../../../../services/applicant_tracking/ApplicationService';
import { CandidateService } from '../../../../services/applicant_tracking/CandidateService';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { JobPostService } from '../../../../services/applicant_tracking/JobPostService';
import { SourceService } from '../../../../services/applicant_tracking/SourceService';
import { ApplicationForm } from '../ApplicationForm';
import { CandidatePageHeaderStatusInfo } from '../../../CandidatePage/CandidatePageHeader/CandidatePageHeaderStatusInfo';
import { CandidatePageHeaderJobInfo } from '../../../CandidatePage/CandidatePageHeader/CandidatePageHeaderJobInfo';
import { mergeCandidateApplications } from '../../Candidates/utils/mergeCandidateApplications';
import { SubmitButton } from '../../../../components/SubmitButton';

interface PropTypes {
  applicationId: string;
}

export default function EditApplicationPage(props: PropTypes) {
  const [application, setApplication] = useState<Application>(null);
  const [candidate, setCandidate] = useState<Candidate>(null);
  const [jobPosts, setJobPosts] = useState<JobPost[]>(null);
  const [sources, setSources] = useState<Source[]>(null);

  const [candidateDto, setCandidateDto] = useState<CandidateDto>(null);

  useEffect(() => {
    const fetchData = async () => {
      const application = await ApplicationService.show(props.applicationId);
      const candidatePromise = CandidateService.get(
        application.candidateId.toString(),
      );
      const jobPostsPromise = JobPostService.list({ restrictedAccess: true });
      const sourcesPromise = SourceService.list();

      const [candidate, jobPosts, sources] = await Promise.all([
        candidatePromise,
        jobPostsPromise,
        sourcesPromise,
      ]);

      setApplication(application);
      setCandidate(candidate);
      setJobPosts(jobPosts);
      setSources(sources);

      const values = mergeCandidateApplications(null, [application]);
      setCandidateDto(values);
    };

    fetchData();
  }, []);

  async function handleSuccessfulSubmit(application: Application) {
    window.location.href = `/applications/${application.id}`;
  }

  if (application == null || candidate == null) return <LoadingSpinner />;

  return (
    <div className='d-flex flex-column'>
      <PageHeader
        candidate={candidate}
        application={application}
        jobPost={application.jobPost}
      />
      <Card className='mt-4'>
        <ApplicationForm
          candidateId={candidate.id}
          applicationId={application.id}
          jobPosts={jobPosts}
          sources={sources}
          onSuccessfulSubmit={handleSuccessfulSubmit}
          headerFactory={(formState) => (
            <FormHeader formState={formState} application={application} />
          )}
          candidateDto={candidateDto}
        />
      </Card>
    </div>
  );
}

function PageHeader(props: {
  candidate: Candidate;
  application: Application;
  jobPost: JobPost;
}) {
  return (
    <div className='d-flex align-items-center'>
      <div className='me-auto d-flex align-items-center'>
        <BackArrow />
        <PageTitle
          text={`${props.candidate.firstName} ${props.candidate.lastName}`}
        />
        <CandidatePageHeaderStatusInfo
          status={props.application.status}
          classNames={'ms-2h'}
        />
        <CandidatePageHeaderJobInfo
          jobId={props.jobPost.job.id}
          jobName={props.jobPost.name}
        />
      </div>
      <div className='fs-5 text-muted'>
        Last Updated at{' '}
        {moment(props.application.lastInteractionAt).format(
          'MMMM DD, YYYY, hh:mm A',
        )}
      </div>
    </div>
  );
}

function FormHeader(props: {
  formState: FormState<CandidateDto>;
  application: Application;
}) {
  return (
    <div className='d-flex'>
      <h2>Edit Job Application</h2>
      <div className='ms-auto d-flex gap-2'>
        <Button
          color='danger'
          onClick={() =>
            (window.location.href = `/applications/${props.application.id}`)
          }
        >
          Cancel
        </Button>
        <SubmitButton formState={props.formState}>Save and Close</SubmitButton>
      </div>
    </div>
  );
}
