import React from 'react';
import { JobPostingDetailsForm } from '.';
import { TextField } from './TextField';
import { FormGroup } from './FormGroup';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: Record<string, string>;
  onInputChange: (name: string, value: string) => void;
}

export function NameFields(props: PropTypes) {
  return (
    <FormGroup>
      <TextField
        label='Campaign Name'
        value={props.form.campaignName}
        name='campaignName'
        onInputChange={props.onInputChange}
        mandatory={true}
        error={props.errors['campaignName']}
      />
      <TextField
        label='Title'
        value={props.form.title}
        name='title'
        onInputChange={props.onInputChange}
        mandatory={true}
        error={props.errors['title']}
      />
    </FormGroup>
  );
}
