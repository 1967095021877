import classNames from 'classnames';
import { Chip, ChipColor } from '../../../../components/Chip';
import React from 'react';

interface PropTypes {
  classNames?: string;
  chipColor: ChipColor;
  text: React.ReactNode;
}

export function CandidateHeaderBadge(props: PropTypes) {
  return (
    <Chip
      className={classNames('fw-semibold', props.classNames)}
      color={props.chipColor}
    >
      {props.text}
    </Chip>
  );
}
