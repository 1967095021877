import classNames from 'classnames';
import React from 'react';
import { Input, Label } from 'reactstrap';

interface PropTypes {
  onChangeFeedbackText: (value: string) => void;
}

export function FeedbackForm(props: PropTypes) {
  return (
    <>
      <Label
        for='feedback-message'
        className={classNames('fw-semibold', 'text-center', 'mt-4')}
      >
        Leave us a comment or feedback
      </Label>
      <Input
        id='feedback-message'
        className={classNames('form-control', 'fs-6')}
        placeholder='Insert your comment here'
        type='textarea'
        onChange={(value) =>
          props.onChangeFeedbackText(value.currentTarget.value)
        }
      />
    </>
  );
}
