import React from 'react';
import { PreviewJobPost } from '../../../../../entities/applicant_tracking/PreviewJobPost';
import { CompanyInfo } from '../../../../../entities/CompanyInfo';
import { JobPostPageTab } from '..';
import { NavLinkTabs } from '../../../../../components/NavLinkTabs';
import { humanize } from '../../../../../utils/humanize';
import { BackToCareersPageButton } from '../BackToCareersPageButton';
import classNames from 'classnames';

interface PropTypes {
  previewMode: boolean;
  previewJobPost: PreviewJobPost;
  activeTab: JobPostPageTab;
  setActiveTab: (activeTab: JobPostPageTab) => void;
  companyInfo?: CompanyInfo;
}

interface BackButtonPropTypes {
  className: string;
  previewMode: boolean;
  hidden: boolean;
}

function subtitle(post: PreviewJobPost) {
  return [
    post.department,
    post.location,
    humanize(post.locationType),
    humanize(post.employmentType),
  ]
    .filter((el) => el != null)
    .join(' - ');
}

function BackButton(props: BackButtonPropTypes) {
  return (
    <BackToCareersPageButton
      color={'borderless'}
      size={'lg'}
      className={classNames(props.className)}
      previewMode={props.previewMode}
      hidden={props.hidden}
      onClick={() => (window.location.href = '..')}
    />
  );
}

export default function JobPostPageHeader(props: PropTypes) {
  return (
    <div className='mb-4'>
      <div className='d-flex justify-content-between flex-column flex-md-row align-content-center align-items-center mt-sm-5 mt-6h'>
        <BackButton
          className={'ms-md-3 mb-6h mb-md-0'}
          previewMode={props.previewMode}
          hidden={false}
        />
        <h1 className='mb-0'>{props.previewJobPost.name}</h1>
        <BackButton
          className={'me-3 mb-3'}
          previewMode={props.previewMode}
          hidden={true}
        />
      </div>
      <small className='d-flex justify-content-center text-dark-200 px-3'>
        {subtitle(props.previewJobPost)}
      </small>
      <NavLinkTabs<JobPostPageTab>
        navClassNames={'justify-content-center fs-4 mt-4'}
        activeTab={props.activeTab}
        setActiveTab={props.setActiveTab}
        tabTypeMapping={[
          {
            text: 'Overview',
            tab: 'overview',
          },
          {
            text: 'Application Form',
            tab: 'application_form',
          },
        ]}
        disabled={props.previewMode}
      />
    </div>
  );
}
