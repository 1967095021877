import React, { useCallback, useState } from 'react';
import { SurveyShareService } from '../../../candidate_experience/services/SurveyShareService';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { AlertSuccess } from './AlertSucess';
import { Body } from './Body';

interface PropTypes {
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  applicationId: number;
  jobStageName: string;
  candidateName: string;
  external?: React.ReactNode;
}

export function SharePulseSurveyModal(props: PropTypes) {
  const placeholder =
    'As previously mentioned, we want to learn from your feedback and improve our hiring process. Would you mind filling out the following survey? It will take you less than 2 minutes to complete.\n\nThank you!';
  const [emailBody, setEmailBody] = useState<string>(placeholder);
  const [disableEditEmailBody, setDisableEditEmailBody] =
    useState<boolean>(true);
  const [alertSuccess, setAlertSuccess] = useState<boolean>(false);
  const handleOnSubmitClick = useCallback(async () => {
    await SurveyShareService.create(props.applicationId, emailBody);
    setAlertSuccess(true);
    props.onSubmit();
  }, [emailBody]);
  const onCancel = () => {
    props.onCancel();
    setEmailBody(placeholder);
    setDisableEditEmailBody(true);
  };

  return (
    <>
      <ConfirmationModal
        title='Share Candidate Survey'
        body={
          <Body
            jobStageName={props.jobStageName}
            candidateName={props.candidateName}
            setDisableEditEmailBody={setDisableEditEmailBody}
            disableEditEmailBody={disableEditEmailBody}
            emailBody={emailBody}
            setEmailBody={setEmailBody}
            defaultEmailLength={placeholder.length}
          />
        }
        isOpen={props.isOpen}
        disableAfterConfirm={false}
        onConfirm={handleOnSubmitClick}
        onCancel={() => onCancel()}
        size='lg'
        confirmText='Send'
        external={props.external}
      />
      <AlertSuccess
        alertSucess={alertSuccess}
        setAlertSuccess={setAlertSuccess}
      />
    </>
  );
}
