import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { EmptyState } from '../../components/EmptyState';
import { Interview } from '../../entities/Interview';
import { InterviewRating } from '../../entities/InterviewRating';
import InterviewDetail from './InterviewDetail';
import { RatingModal } from './RatingModal';
import { ShareModal } from './ShareModal';
import TopNavbar from './TopNavbar';

interface PropTypes {
  interview: Interview;
  interviewRatingEnabled: boolean;
  interviewMediaPresent: boolean;
  scorecardUrl: string;
  atsEnabled: boolean;
  interviewAiAssistantEnabled: boolean;
  customSummaryEnabled: boolean;
}

export default function InterviewPage(props: PropTypes) {
  const searchParams = new URLSearchParams(window.location.search);
  const [ratingModalActive, setRatingModalActive] = useState(
    searchParams?.get('rateInterview') == 'true',
  );
  const [shareModalActive, setShareModalActive] = useState(false);
  const [ratingResponses, setRatingResponses] = useState(
    props.interview.rating_responses,
  );
  const [ratingEnabled, setRatingEnabled] = useState(
    props.interviewRatingEnabled,
  );

  const closeRatingModal = (response: InterviewRating) => {
    if (response) {
      setRatingResponses(ratingResponses.concat(response));
      setRatingEnabled(false);
    }
    setRatingModalActive(false);
    searchParams.delete('rateInterview');
    history.replaceState(null, '', window.location.pathname);
  };

  const closeShareModal = () => {
    setShareModalActive(false);
  };

  return (
    <BrowserRouter>
      <TopNavbar
        atsEnabled={props.atsEnabled}
        interview={props.interview}
        interviewRatingEnabled={ratingEnabled}
        interviewMediaPresent={props.interviewMediaPresent}
        onRatingClick={() => setRatingModalActive(true)}
        onShareClick={() => setShareModalActive(true)}
        ratingResponses={ratingResponses}
        scorecardUrl={props.scorecardUrl}
      />
      <RatingModal
        interviewId={props.interview.id}
        ratingOptions={props.interview.rating_options}
        isOpen={ratingModalActive}
        onClose={closeRatingModal}
      />
      <ShareModal
        interviewId={props.interview.id}
        interviewAccesses={props.interview.interview_accesses}
        isOpen={shareModalActive}
        onClose={closeShareModal}
      />
      {props.interviewMediaPresent ? (
        <InterviewDetail
          interview={props.interview}
          atsEnabled={props.atsEnabled}
          interviewAiAssistantEnabled={props.interviewAiAssistantEnabled}
          customSummaryEnabled={props.customSummaryEnabled}
        />
      ) : (
        <EmptyState title='This interview has not been recorded' />
      )}
    </BrowserRouter>
  );
}
