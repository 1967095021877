import React, { useCallback, useState } from 'react';
import styles from './styles.module.scss';
import { Interview } from '../../../entities/Interview';
import {
  InterviewMarker,
  InterviewMarkerKind,
} from '../../../entities/InterviewMarker';
import { NoteInput } from '../../../components/NoteInput';
import { InterviewService } from '../../../services/InterviewService';
import { formatTime } from '../../../utils/timeFormat';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { IconButton } from '../../IconButton';

export interface PropTypes {
  playTimestamp?: number;
  interview: Interview;
  onSelectTimestamp: (timestamp: number) => void;
  onUpdateInterviewMarkers: (InterviewMarkers: InterviewMarker[]) => void;
}

export const NotePanel = React.memo(function NotesThread(props: PropTypes) {
  const [noteText, setNoteText] = useState<string>('');

  async function handlePostNote(kind: InterviewMarkerKind, text: string) {
    const interview = await InterviewService.createInterviewMarker(
      props.interview.id,
      props.playTimestamp,
      kind,
      text,
    );
    props.onUpdateInterviewMarkers(interview.interview_markers);
    setNoteText('');
  }

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handlePostNote('note', noteText);
    },
    [noteText],
  );

  return (
    <div className='w-100'>
      <form onSubmit={(e) => onSubmit(e)}>
        <Row className='mt-1'>
          <Col xs={12}>
            <NoteInput
              className={classNames('p-2', 'fs-5', styles.noteText)}
              value={noteText}
              onChange={(v) => setNoteText(v)}
            />
          </Col>
        </Row>
        <Row xs={4} className='mt-4 align-items-center justify-content-between'>
          <Col
            className={`col-auto ${styles.noteTimestamp}`}
            data-interview-marker-list-target='timestamp'
          >
            {formatTime(props.playTimestamp)}
          </Col>
          <Col className='col-auto text-end'>
            <IconButton
              color='primary'
              type='submit'
              iconName='bi-send'
              buttonText='Send'
              dataTestId='interview-marker-submit'
            />
          </Col>
        </Row>
      </form>
    </div>
  );
});
