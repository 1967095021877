import React from 'react';
import defaultFinishScreenImage from '../../../images/undraw_empty_state_cactus.svg';
import { Survey } from '../../../entities/survey_engine/Survey';
import { SurveyLayout } from '../../../components/survey_engine/SurveyLayout';
import { SurveyPanel } from '../../../components/survey_engine/SurveyPanel';

interface PropTypes {
  survey: Survey;
  surveyFinalScreen?: React.ReactNode;
}

export function DisabledRoute(props: PropTypes) {
  const headingStyle = {
    letterSpacing: props.survey.company_info.font_kerning * 2 + 'em',
  };
  return (
    <SurveyLayout companyInfo={props.survey.company_info}>
      <SurveyPanel className='text-center'>
        <img className='mt-5 w-25' src={defaultFinishScreenImage} />
        <h1 className='mt-3' style={headingStyle}>
          This survey is no longer available.
        </h1>
        <p className={'mt-1 mb-4 mx-auto'} style={{ maxWidth: '711px' }}>
          This survey is no longer available for completion. For more
          information please reach us at support@screenloop.com. Thank you.
        </p>
      </SurveyPanel>
    </SurveyLayout>
  );
}
