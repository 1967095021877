import React, { useState } from 'react';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import VideoPlaceholder from '../../../../images/placeholders/screenloop-video-placeholder-v2.svg';
import PlayButton from '../../../../images/placeholders/play-button-2.svg';
import { VideoModal } from '../../../../components/VideoModal';
import { MediaType } from '../../../../entities/MediaType';

export function WelcomePanel() {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState('40%');

  function handleMouseEnter() {
    setSize('45%');
  }

  function handleMouseLeave() {
    setSize('40%');
  }

  return (
    <DeprecatedPanel className='p-4 mb-0' style={{ height: '239px' }}>
      <div className='fs-3 fw-semibold mb-3'>How to start using Screenloop</div>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ cursor: 'pointer' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setOpen(true)}
      >
        <img
          src={VideoPlaceholder}
          className='rounded'
          style={{ height: '150px', width: '100%', objectFit: 'cover' }}
          role='button'
        />
        <img
          src={PlayButton}
          style={{ position: 'absolute', height: size, width: size }}
        />
      </div>
      <VideoModal
        interviewMarkerOffset={0}
        isOpen={open}
        setIsOpen={setOpen}
        media={{
          duration: 0,
          type: MediaType.Video,
          url: '/how_to_start_using_screenloop.mp4',
        }}
      />
    </DeprecatedPanel>
  );
}
