import React from 'react';
import { NotePill } from '../../../../../components/candidate_experience/NotePill';
import { TableEntry } from './index';
import { CollapsableElements } from '../../../../../components/CollapsableElements';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { findRatingBgColorByScore } from '../../utils';
import {
  CPS_CLASSIFICATION_LEGEND,
  CPS_DEFAULT_CLASSIFICATION_LEGEND,
} from '../../../../../components/survey_engine/RatingLegend';

export interface PropTypes {
  answers: TableEntry[];
  maxRowsCollapsed: number;
  standardNpsCalculation: boolean;
}

export function FollowUpResponsesListTableRows(props: PropTypes) {
  const ratingLegend = props.standardNpsCalculation
    ? CPS_DEFAULT_CLASSIFICATION_LEGEND
    : CPS_CLASSIFICATION_LEGEND;

  return (
    <div className='d-flex flex-column gap-2h'>
      {props.answers.length !== 0 && (
        <>
          <div className='fs-6 text-gray-900 d-flex justify-content-between mt-2 px-h'>
            <span className={classNames(styles['sub-headings'])}>
              Responses
            </span>
            <span className={classNames(styles['sub-headings'], 'mx-3')}>
              CNPS
            </span>
          </div>
          <CollapsableElements
            elements={props.answers.map((question) => (
              <NotePill
                key={question.text}
                body={question.text}
                score={question.score}
                updatedAt={question.metadata?.updatedAt}
                jobName={question.jobName}
                bgColorScore={findRatingBgColorByScore(
                  ratingLegend,
                  question.score,
                )}
              />
            ))}
            maxElements={props.maxRowsCollapsed}
          />
        </>
      )}
    </div>
  );
}
