import React, { MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';

export type LinkTarget = '_blank' | '_self' | '_parent' | '_top' | 'framename';

export interface PropTypes {
  children?: ReactNode | string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  classNames?: string;
  textClassNames?: string;
  disabled?: boolean;
  href?: string;
  download?: boolean;
  target?: LinkTarget;
}

export function Link(props: PropTypes) {
  const disabled = props.disabled ?? false;
  const download = props.download ?? false;

  return (
    <a
      className={classNames(props.classNames, {
        disabled: disabled,
      })}
      onClick={(e) => {
        if (!disabled && props.onClick) props.onClick(e);
      }}
      href={props.href}
      download={download}
      target={props.target}
    >
      <div className={classNames(props.textClassNames)}>{props.children}</div>
    </a>
  );
}
