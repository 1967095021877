import { Controller } from 'stimulus';

import { ApiService } from '../services/ApiService';

function openSidekickWindow(sidekickUrlValue: string, meetingUrlValue: string) {
  return window.open(
    sidekickUrlValue + `?meeting_url=${meetingUrlValue}`,
    meetingUrlValue,
    'width=477,height=800,popup=1,noopener,noreferrer',
  );
}
export default class extends Controller {
  static values = {
    joinUrl: String,
    sidekickUrl: String,
    meetingUrl: String,
    unableToJoinUrl: String,
  };
  declare readonly joinUrlValue: string;
  declare readonly sidekickUrlValue: string;
  declare readonly meetingUrlValue: string;
  declare readonly unableToJoinUrlValue: string;

  async launch() {
    try {
      await ApiService.put(this.joinUrlValue, {
        interview: {
          meeting_provider: null,
          meeting_url: this.meetingUrlValue,
        },
      });

      openSidekickWindow(this.sidekickUrlValue, this.meetingUrlValue);
      window.location.href = this.meetingUrlValue;
    } catch {
      window.location.href = this.unableToJoinUrlValue;
    }
  }
}
