import { JobPostingDetailsForm } from '../JobBoardsTabUI/JobPostingDetails';

const FORM_OPTIONAL_FIELDS = [
  'address2',
  'companyId',
  'remote',
  'contactName',
  'contactEmail',
  'contactPhone',
  'salaryPeriod',
  'salaryCurrency',
  'salaryRangeFrom',
  'salaryRangeTo',
  'weeklyHoursFrom',
  'organicEnabled',
];

const NUMBER_REGEX = /^\d+$/;
const MAX_WEEKLY_HOURS = 168;

export function isSalaryRangeFilled(form: JobPostingDetailsForm): boolean {
  return !!(form.salaryRangeTo || form.salaryRangeFrom);
}

function validateSalaryPeriod(form: JobPostingDetailsForm): boolean {
  if (isSalaryRangeFilled(form)) {
    return !!form.salaryPeriod;
  }

  return true;
}

function validateSalaryCurrency(form: JobPostingDetailsForm): boolean {
  if (isSalaryRangeFilled(form)) {
    return !!form.salaryCurrency;
  }

  return true;
}

function validateNumberInput(value?: string) {
  if (value == null || value?.trim() == '') {
    return;
  }

  const isValid = NUMBER_REGEX.exec(value);
  if (!isValid) {
    return 'Value must be a number';
  }
}

function validateSalaryFromInput(form: JobPostingDetailsForm) {
  const err = validateNumberInput(form.salaryRangeFrom);

  if (err != null) {
    return err;
  }

  if (form.salaryRangeTo != null && NUMBER_REGEX.exec(form.salaryRangeTo)) {
    const parsedValue = parseInt(form.salaryRangeFrom);
    const parsedMaxValue = parseInt(form.salaryRangeTo);

    if (parsedValue > parsedMaxValue) {
      return 'Min should be lower than max';
    }
  }
}

function validateSalaryToInput(form: JobPostingDetailsForm) {
  const err = validateNumberInput(form.salaryRangeTo);

  if (err != null) {
    return err;
  }

  if (form.salaryRangeFrom != null && NUMBER_REGEX.exec(form.salaryRangeFrom)) {
    const parsedValue = parseInt(form.salaryRangeTo);
    const parsedMinValue = parseInt(form.salaryRangeFrom);

    if (parsedValue < parsedMinValue) {
      return ' ';
    }
  }
}

function validateWeeklyHoursFromInput(form: JobPostingDetailsForm) {
  const err = validateNumberInput(form.weeklyHoursFrom);

  if (err != null) {
    return err;
  }

  if (form.weeklyHoursTo != null && NUMBER_REGEX.exec(form.weeklyHoursTo)) {
    const parsedValue = parseInt(form.weeklyHoursFrom);
    const parsedMaxValue = parseInt(form.weeklyHoursTo);

    if (parsedValue > parsedMaxValue) {
      return 'Min should be lower than max';
    }
  }
}

function validateWeeklyHoursToInput(form: JobPostingDetailsForm) {
  const err = validateNumberInput(form.weeklyHoursTo);

  if (err != null) {
    return err;
  }

  const parsedValue = parseInt(form.weeklyHoursTo);
  if (parsedValue > MAX_WEEKLY_HOURS) {
    return `Cannot exceed ${MAX_WEEKLY_HOURS} hours`;
  }

  if (form.weeklyHoursFrom != null && NUMBER_REGEX.exec(form.weeklyHoursFrom)) {
    const parsedMinValue = parseInt(form.weeklyHoursFrom);

    if (parsedValue < parsedMinValue) {
      return ' ';
    }
  }
}

export function validateForm(form: JobPostingDetailsForm) {
  return {
    salaryIndication: {
      range: {
        from: validateSalaryFromInput(form),
        to: validateSalaryToInput(form),
      },
    },
    weeklyWorkingHours: {
      from: validateWeeklyHoursFromInput(form),
      to: validateWeeklyHoursToInput(form),
    },
    linkedinCompanyId: validateNumberInput(form.companyId),
  };
}

export function formIsValid(
  form: JobPostingDetailsForm,
  optionalFields?: string[],
): boolean {
  if (form == null) {
    return false;
  }

  const ignoredFields = optionalFields ?? FORM_OPTIONAL_FIELDS;

  for (const [key, value] of Object.entries(form)) {
    if (ignoredFields.includes(key)) {
      continue;
    }

    if (!value) {
      return false;
    }
  }

  if (!validateSalaryCurrency(form)) {
    return false;
  }

  if (!validateSalaryPeriod(form)) {
    return false;
  }

  if (validateSalaryFromInput(form)) {
    return false;
  }

  if (validateSalaryToInput(form)) {
    return false;
  }

  if (validateSalaryFromInput(form)) {
    return false;
  }

  if (validateWeeklyHoursFromInput(form)) {
    return false;
  }

  if (validateWeeklyHoursToInput(form)) {
    return false;
  }

  // Validate Linkedin Company Id
  if (validateNumberInput(form.companyId)) {
    return false;
  }

  return true;
}
