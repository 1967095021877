import React from 'react';
import { Col } from 'reactstrap';
import { Marketplace } from '../Marketplace';
import { JobPostingDetails, JobPostingDetailsForm } from '../JobPostingDetails';
import { Product, ProductPriceCurrency } from '@vonq/hapi-elements-types';
import { WindowWithHapi } from '../..';
import { JobBoardAlertObject } from '../JobBoardAlert';
import { JobDetails } from '../../../../../entities/JobDetails';
import { PostDetails } from '../../../../../entities/applicant_tracking/jobboards/PostDetails';
import { FilterState, SelectedFilterState } from '../../utils/filterState';
import { ProductsWithPagination } from '../../utils/paginationState';
import { TargetGroupValues } from '../JobPostingDetails/TargetGroup/TargetGroupValues';

interface PropTypes {
  currency: ProductPriceCurrency;
  errors: any;
  jobDetails: JobDetails;
  filterState: FilterState;
  form: JobPostingDetailsForm;
  isLoadingProducts: boolean;
  postDetails: PostDetails;
  products: ProductsWithPagination;
  selectedProducts: Product[];
  selectedState: SelectedFilterState;
  targetGroupValues: TargetGroupValues;
  window: WindowWithHapi;
  onLoadElements: (...args: any[]) => Promise<void>;
  setAlert: (alert: JobBoardAlertObject) => void;
  setFilterState: (filterState: FilterState) => void;
  setForm: (form: JobPostingDetailsForm) => void;
  setProducts: (products: ProductsWithPagination) => void;
  setSelectedState: (selectedState: SelectedFilterState) => void;
}

export function MarketplaceView(props: PropTypes) {
  return (
    <div className='d-flex gap-3 mt-4'>
      <Col xs='8'>
        <Marketplace
          currency={props.currency}
          isLoadingProducts={props.isLoadingProducts}
          filterState={props.filterState}
          products={props.products}
          selectedProducts={props.selectedProducts}
          selectedState={props.selectedState}
          targetGroupValues={props.targetGroupValues}
          window={props.window}
          onLoadElements={props.onLoadElements}
          setAlert={props.setAlert}
          setFilterState={props.setFilterState}
          setProducts={props.setProducts}
          setSelectedState={props.setSelectedState}
        />
      </Col>
      <Col xs='4'>
        <JobPostingDetails
          form={props.form}
          setForm={props.setForm}
          setAlert={props.setAlert}
          errors={props.errors}
          postDetails={props.postDetails}
          targetGroupValues={props.targetGroupValues}
          jobDetails={props.jobDetails}
        />
      </Col>
    </div>
  );
}
