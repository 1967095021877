import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, COMMAND_PRIORITY_EDITOR, PASTE_COMMAND } from 'lexical';
import { $createVariableNode, VariableNode } from '../../nodes/VariableNode';

interface PropTypes {
  variables: string[];
}

function handleCopy(variables: string[], event: ClipboardEvent): VariableNode {
  const pastedText = event.clipboardData?.getData('Text');

  if (pastedText != null && variables.includes(pastedText)) {
    return $createVariableNode(pastedText);
  }

  return null;
}

export function PasteVariablePlugin(props: PropTypes) {
  const [editor] = useLexicalComposerContext();

  editor.registerCommand<ClipboardEvent>(
    PASTE_COMMAND,
    (payload: ClipboardEvent) => {
      const value = handleCopy(props.variables, payload);

      if (value != null) {
        // Get the selection from the EditorState
        const selection = $getSelection();

        // Finally, append the paragraph to the root
        selection.insertNodes([value]);

        return true;
      }

      return false;
    },
    COMMAND_PRIORITY_EDITOR,
  );

  return null;
}
