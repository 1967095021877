import { useEffect, useState } from 'react';
import { OfferLetterTemplateService } from '../../../../../../services/v1/applicant_tracking/OfferLetterTemplateService';
import React from 'react';
import { Select, SelectOption } from '../../../../../../components/Select';
import { OfferLetterTemplate } from '../../../../../../entities/v1/applicant_tracking/OfferLetterTemplate';
import { AreaRichTextComposer } from '../../../../../../components/RichTextEditor/composers/AreaRichTextComposer';
import { UpdateStatePlugin } from '../../../../../../components/RichTextEditor/plugins/UpdateStatePlugin';

interface PropTypes {
  offerLetterTemplate?: OfferLetterTemplate;
  setIsValid: (valid: boolean) => void;
  setOfferLetterTemplate: (offerLetterTemplate: OfferLetterTemplate) => void;
}

function mapLoaded(values: OfferLetterTemplate[]): SelectOption[] {
  if (values == null) {
    return null;
  }

  return values.map((v) => {
    return {
      value: v.id.toString(),
      label: v.name,
    };
  });
}

export function OfferLetter(props: PropTypes) {
  const [loadedTemplates, setLoaded] = useState<OfferLetterTemplate[]>(null);
  const options = mapLoaded(loadedTemplates);

  useEffect(() => {
    (async () => {
      setLoaded((await OfferLetterTemplateService.list()).offerLetterTemplates);
    })();
  }, []);

  const handleChangeSelected = (selected: SelectOption) => {
    props.setOfferLetterTemplate(
      loadedTemplates?.find(
        (v) => v.id.toString() === selected?.value?.toString(),
      ),
    );
  };

  useEffect(() => {
    props.setIsValid(props.offerLetterTemplate != null);
  }, [props.offerLetterTemplate]);

  return (
    <>
      <div className='d-flex align-items-center flex-nowrap gap-3 mb-3h w-100'>
        <label className='fw-semibold text-nowrap fs-5'>
          Select Offer Letter
        </label>
        <Select
          className='w-100'
          isLoading={loadedTemplates == null}
          isClearable
          placeholder='Select the offer letter'
          onChange={handleChangeSelected}
          options={options}
          selected={options?.find(
            (i) =>
              i.value.toString() === props.offerLetterTemplate?.id.toString(),
          )}
        />
      </div>
      <AreaRichTextComposer
        disabled
        placeholder=''
        externalPlugins={[
          <UpdateStatePlugin
            key={1}
            editorState={props.offerLetterTemplate?.template}
          />,
        ]}
        size='md'
      />
    </>
  );
}
