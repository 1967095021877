import React, { useState } from 'react';
import { ActionItem } from '../../../../../../entities/ActionItem';
import { ActionItemLink } from '../ActionItemLink';
import { ScheduleInterviewModal } from '../../../../../../components/ApplicantTracking/ScheduleInterviewModal';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  applicationId: number;
  interviewStageId: number;
  pendingActionItems: ActionItem[];
  completedActionItems: ActionItem[];
  showScheduleInterviewItem: boolean;
}

function CompletedScheduleInterview() {
  return (
    <ActionItemLink
      text='Schedule Interview'
      icon='bi-calendar-week'
      className='text-blue-200'
      completed
    />
  );
}

export function ScheduleInterviewItem(props: PropTypes) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (!props.showScheduleInterviewItem) {
    return null;
  }

  const pendingActionItem = props.pendingActionItems.find(
    (actionItem) => actionItem.intent === 'schedule_interview',
  );

  const completedActionItem = props.completedActionItems.find(
    (actionItem) => actionItem.intent === 'schedule_interview',
  );

  if (pendingActionItem == null && completedActionItem == null) {
    return null;
  } else if (pendingActionItem == null) {
    return <CompletedScheduleInterview />;
  }

  return (
    <>
      <ScheduleInterviewModal
        isOpen={isModalOpen}
        applicationId={props.applicationId}
        interviewStageId={props.interviewStageId}
        onClose={() => setIsModalOpen(false)}
      />
      <a
        onClick={() => setIsModalOpen(true)}
        className={classNames('text-blue', styles['cursor-pointer'])}
      >
        <ActionItemLink
          text='Schedule Interview'
          icon='bi-calendar-week'
          className='text-info'
        />
      </a>
    </>
  );
}
