import React from 'react';
import { InfoTooltip } from '../../../../../../components/InfoTooltip';
import { Col, Row } from 'reactstrap';
import { CopyButton } from '../../../../../../components/CopyButton';

export const CUSTOMIZATION_VARIABLES: { [key: string]: string } = {
  "Candidate's Name": '{{{candidate_name}}}',
  'Job Title': '{{{job_title}}}',
  'Company Name': '{{{company_name}}}',
  'Sender Name': '{{{sender_name}}}',
};

export const OFFER_VARIABLES: { [key: string]: string } = {
  Salary: '{{{salary}}}',
  Equity: '{{{equity}}}',
  'Starting date': '{{{starting_date}}}',
  'Working hours': '{{{working_hours}}}',
};

function VaribleGroup(props: {
  title: string;
  tooltipText: string;
  children: React.ReactNode;
}) {
  return (
    <div>
      <span className='d-flex flex-nowrap gap-2'>
        <h4 className='mb-0'>{props.title}</h4>
        <InfoTooltip>{props.tooltipText}</InfoTooltip>
      </span>
      {props.children}
    </div>
  );
}

function CopyButtons(props: { variables: { [key: string]: string } }) {
  return (
    <Row className='mt-1 gy-2h'>
      {Object.entries(props.variables).map(([key, value]) => (
        <Col className='text-nowrap' key={key} xs='6'>
          <CopyButton
            size='md'
            hideText={false}
            position='start'
            text={key}
            textToBeCopied={value}
          />
        </Col>
      ))}
    </Row>
  );
}

export function CopyVariables() {
  return (
    <div className='d-flex flex-column gap-4'>
      <VaribleGroup
        title='Offer variables'
        tooltipText='Customise your offer letter by copying and pasting these variables. This information will be requested when sending the offer letter.'
      >
        <CopyButtons variables={OFFER_VARIABLES} />
      </VaribleGroup>
      <VaribleGroup
        title='Customization variables'
        tooltipText='Copy and paste these variables to customize your Offer Letter.'
      >
        <CopyButtons variables={CUSTOMIZATION_VARIABLES} />
      </VaribleGroup>
    </div>
  );
}
