import React from 'react';
import { SpanTooltip } from '../../../../../../components/SpanTooltip';
import { IconButton } from '../../../../../../components/IconButton';

interface PropTypes {
  enabled: boolean;
  color: string;
  disabledMessage?: string;
  onAddStage: () => void;
}

function ButtonComponent(props: PropTypes) {
  return (
    <IconButton
      color={props.color}
      disabled={!props.enabled}
      iconName='bi-plus-circle'
      buttonText='Add Stage'
      onClick={props.onAddStage}
    />
  );
}

export function AddStageButton(props: PropTypes) {
  return (
    <>
      {props.disabledMessage ? (
        <SpanTooltip
          text={<ButtonComponent {...props} />}
          tooltipText={props.disabledMessage}
          placement='top'
        />
      ) : (
        <ButtonComponent {...props} />
      )}
    </>
  );
}
