import React, { useState } from 'react';
import { NoteInput } from '../NoteInput';

interface PropTypes {
  name: string;
  autofocus?: boolean;
  placeholder?: string;
  singleLine?: boolean;
}

export default function NoteInputEmbedded(props: PropTypes) {
  const [value, setValue] = useState<string>('');

  return (
    <>
      <input type='hidden' value={value} name={props.name} />
      <NoteInput
        value={value}
        placeholder={props.placeholder}
        autofocus={props.autofocus}
        singleLine={props.singleLine}
        onChange={setValue}
      />
    </>
  );
}
