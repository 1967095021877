import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { LocationList } from '../../../../entities/v1/LocationList';

export interface ListParams {
  name?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
}

export class LocationService {
  public static list(params: ListParams) {
    return ApiService.get<LocationList>(
      '/api/v1/applicant_tracking/locations',
      snakecaseKeys(params),
    );
  }
}
