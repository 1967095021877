import React from 'react';
import { Select } from '../Select';

interface PropTypes {
  name: string;
  className?: string;
  disabled?: boolean;
  value?: number;
  min?: number; // 0 to 11. Defaults to 0
  max?: number; // 0 to 11. Defaults to 11
  menuPlacement?: 'top' | 'bottom';
  onChange?: (value: number) => void;
}

export function MonthSelect(props: PropTypes) {
  const min = props.min == null ? 0 : props.min;
  const max = props.max == null ? 11 : props.max;

  const options = [];
  for (let i = min; i <= max; ++i) {
    options.push({ value: i, label: i + 1 });
  }

  return (
    <Select
      className={props.className}
      options={options}
      selected={
        props.value != null && {
          value: props.value.toString(),
          label: (Number(props.value) + 1).toString(),
        }
      }
      name={props.name}
      testId={props.name}
      placeholder='Month'
      disabled={props.disabled === true}
      menuPlacement={props.menuPlacement}
      onChange={(newOption) => props.onChange(Number(newOption.value))}
    />
  );
}
