import React from 'react';
import { Alert, AlertType } from '../../../components/Alert';

interface PropTypes {
  successful: boolean | null;
  sessionName: string;
  successfulMessage: string;
  errorMessage: string;
  onClose: () => void;
}

interface AlertPropTypes {
  alertType: AlertType;
  message: string;
  sessionName: string;
  onClose: () => void;
}

function AlertMessage(props: AlertPropTypes) {
  return (
    <Alert
      autoClearTimeout={4000}
      clearable
      type={props.alertType}
      onClose={props.onClose}
    >
      <div>
        <b>{props.sessionName}</b> {props.message}
      </div>
    </Alert>
  );
}

export function ActionSessionAlert(props: PropTypes) {
  if (props.successful == null) {
    return null;
  }

  return (
    <AlertMessage
      sessionName={props.sessionName}
      alertType={
        props.successful === true ? AlertType.Success : AlertType.Danger
      }
      message={
        props.successful === true ? props.successfulMessage : props.errorMessage
      }
      onClose={props.onClose}
    />
  );
}
