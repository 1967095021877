import React from 'react';

interface PropTypes {
  alertSucess: boolean;
  setAlertSuccess: (alertSucess: boolean) => void;
}

export function AlertSuccess(props: PropTypes) {
  return (
    <>
      {props.alertSucess && (
        <div
          className='alert alert-toast alert-success alert-dismissible fade show text-start'
          role='alert'
        >
          Survey email was successfully sent!
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='alert'
            data-dismiss='alert'
            aria-label='Close'
            onClick={() => props.setAlertSuccess(false)}
          ></button>
        </div>
      )}
    </>
  );
}
