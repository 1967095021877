import React, { useState } from 'react';
import { EmailSenderForm } from './EmailSenderForm';
import { EmailDomain } from '../../../../entities/EmailDomain';
import { AlertType } from '../../../../components/Alert';
import AlertToast from '../AlertToast';

export interface PropTypes {
  isEmailDomainEnabled: boolean;
  emailDomain: EmailDomain;
  setEmailDomain: (emailDomain: EmailDomain) => void;
}

export function DomainSettingsRow(props: PropTypes) {
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(AlertType.Success);

  function onAlert(message: string, alertType: AlertType = AlertType.Success) {
    setAlertType(alertType);
    setAlertMessage(message);
  }

  return (
    <div className='row justify-content-between align-items-center'>
      <div className='col'>
        <div className='row'>
          <div className='col-6 fs-5'>
            <h4>Domain Settings</h4>
          </div>
          <div className='col-12 fs-5 fw-2 text-dark-200'>
            Manage the selected domain to be used for Screenloop emails.
          </div>
        </div>
        <div className='row pt-4'>
          <AlertToast
            type={alertType}
            message={alertMessage}
            onClose={() => {
              setAlertMessage(null);
              setAlertType(AlertType.Success);
            }}
          />
          <EmailSenderForm
            isEmailDomainEnabled={props.isEmailDomainEnabled}
            emailDomain={props.emailDomain}
            setEmailDomain={props.setEmailDomain}
            onAlert={onAlert}
          />
        </div>
      </div>
    </div>
  );
}
