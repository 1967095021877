import React from 'react';
import { GenericEntry } from '..';
import { Application } from '../../../../entities/Application';
import { TriggerActions } from '../../../../entities/ApplicationFeed';
import { User } from '../../../../entities/User';
import { AutoRejectTrigger } from '../../../../entities/applicant_tracking/Trigger';
import { AlertObject } from '../../../../components/Alert';

interface PropTypes {
  application: Application;
  currentUser: User;
  entry: TriggerActions;
  isLastItemInPage: boolean;
  setAlert: (data: AlertObject) => void;
  reloadApplication: () => void;
}
export function AutoRejectEntry(props: PropTypes) {
  const trigger = props.entry.trigger as AutoRejectTrigger;
  const answer = trigger.rejectAnswer ? 'Yes' : 'No';

  return (
    <GenericEntry
      icon='bi-file-x'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          <strong>{props.application.candidate.name}</strong> was auto-rejected
          for <strong>{props.application.job.name}</strong> because application
          form field <strong>{`"${trigger.rejectReason}"`}</strong> was answered{' '}
          <strong>{`"${answer}"`}</strong>
        </>
      }
      date={props.entry.createdAt}
      {...props}
    />
  );
}
