import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { Select, SelectOption } from '../../../../components/Select';
import { Icon } from '../../../../components/Icon';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { FilterState } from '..';

interface PropTypes {
  filterState: FilterState;
  setFilterState: (filterState: FilterState) => void;
}

const SORT_OPTIONS = [
  { value: 'days_on_stage', label: 'Days on stage', selected: false },
  { value: 'name', label: 'Name', selected: false },
  { value: 'applied_at', label: 'Applied at', selected: false },
  { value: 'pending_actions', label: 'Pending actions', selected: false },
];

export function SortFilter(props: PropTypes) {
  const iconDirection =
    props.filterState.sortingColumn.direction === 'asc' ? 'down' : 'up';

  return (
    <div className='d-flex ms-4 align-items-center me-2 pe-1'>
      <div className='me-1 text-dark-500 fs-5'>Sort by</div>
      <Select
        options={SORT_OPTIONS}
        selected={SORT_OPTIONS.find(
          (option) =>
            option.value === props.filterState.sortingColumn.columnName,
        )}
        className={classNames('ms-2', styles['sort-filter'])}
        isSearchable={false}
        onChange={(option: SelectOption) =>
          props.setFilterState({
            sortingColumn: {
              direction: props.filterState.sortingColumn.direction,
              columnName: option.value,
            },
          })
        }
      />
      <SpanTooltip
        placement='top'
        text={
          <Icon
            name={`bi-sort-${iconDirection}-alt`}
            className={classNames(
              'ms-2 rounded-1 text-info',
              styles['sort-direction'],
            )}
            onClick={() =>
              props.setFilterState({
                sortingColumn: {
                  direction:
                    props.filterState.sortingColumn.direction === 'asc'
                      ? 'desc'
                      : 'asc',
                  columnName: props.filterState.sortingColumn.columnName,
                },
              })
            }
          />
        }
        tooltipText={iconDirection === 'up' ? 'Ascending' : 'Descending'}
      />
    </div>
  );
}
