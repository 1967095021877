import React from 'react';
import { FieldErrors, UseFormRegister, UseFormTrigger } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { InputFormField } from '../../../../components/InputFormField';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import classNames from 'classnames';

interface PropTypes {
  errors: FieldErrors;
  formRegister: UseFormRegister<CandidateDto>;
  trigger: UseFormTrigger<CandidateDto>;
}

export function CandidateEmailInput(props: PropTypes) {
  const required = true;

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <InputFormField
          type={'email'}
          registerReturn={props.formRegister('email', {
            required: required,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email address',
            },
            onChange: () => props.trigger('referrerEmail'), // trigger referrerEmail validation when email changes
          })}
          fieldName={'Email'}
          placeholder={'Insert email'}
          isRequired={required}
          classNames={classNames(props.errors.email && 'is-invalid', 'fs-5')}
          headerClassNames={'fw-semibold'}
        />
      </Col>
    </Row>
  );
}
