import React from 'react';
import { Avatar, PropTypes as AvatarPropTypes } from '../Avatar';

export interface PropTypes extends AvatarPropTypes {
  url: string;
}

export function AvatarLink(props: PropTypes) {
  return (
    <a href={props.url} target='_blank' rel='noreferrer'>
      <Avatar {...props} />
    </a>
  );
}
