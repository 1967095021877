import { ApiService } from '../../ApiService';
import snakecaseKeys from 'snakecase-keys';
import { UpsertApplicationDto } from './UpsertApplicationDto';
import { Application } from '../../../entities/applicant_tracking/Application';
import { BulkActionResponse } from '../../../entities/BulkActionResponse';
import { SendEmailDto } from '../../dtos/EmailDto';

interface RejectProps {
  applicationId: number;
  rejectReasonId: number;
}

interface BulkRejectProps {
  applicationIds: number[];
  rejectReasonId: number;
}

interface PushToHrisProps {
  applicationIds: number[];
}

export class ApplicationService {
  public static show(id: string): Promise<Application> {
    return ApiService.get<Application>(
      `/api/applicant_tracking/applications/${id}`,
    );
  }

  public static get(candidateId: string): Promise<Application[]> {
    return ApiService.get<Application[]>(
      `/api/applicant_tracking/applications`,
      { candidate_id: candidateId },
    );
  }

  public static moveStage(
    applicationId: number,
    stageId?: number,
  ): Promise<void> {
    return ApiService.post(
      `/api/applicant_tracking/applications/${applicationId}/move`,
      stageId && snakecaseKeys({ jobStageId: stageId }),
    );
  }

  public static bulkMoveStage(props: {
    applicationIds: number[];
    jobStageId: number;
  }): Promise<BulkActionResponse> {
    return ApiService.post(
      `/api/applicant_tracking/applications/bulk_move`,
      snakecaseKeys(props),
    );
  }

  public static reject(props: RejectProps): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/applications/${props.applicationId}/reject`,
      snakecaseKeys(props),
    );
  }

  public static bulkReject(props: BulkRejectProps): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/applications/bulk_reject`,
      snakecaseKeys(props),
    );
  }

  public static unreject(applicationId: number): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/applications/${applicationId}/unreject`,
    );
  }

  public static sendEmails(
    applicationIds: number[],
    props: SendEmailDto,
  ): Promise<void> {
    if (applicationIds.length === 1) {
      return this.sendSingleEmail(applicationIds[0], props);
    }

    return this.sendBulkEmails(applicationIds, props);
  }

  private static sendSingleEmail(
    applicationId: number,
    props: SendEmailDto,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/applications/${applicationId}/send_email`,
      snakecaseKeys(props),
    );
  }

  private static sendBulkEmails(
    applicationIds: number[],
    props: SendEmailDto,
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/applications/send_bulk_emails`,
      snakecaseKeys({ applicationIds, ...props }),
    );
  }

  public static upsert(
    props: UpsertApplicationDto,
    applicationId: number = null,
  ): Promise<Application[]> {
    if (applicationId == null) {
      return this.create(props);
    } else {
      return this.update(applicationId, props);
    }
  }

  public static create(props: UpsertApplicationDto): Promise<Application[]> {
    return ApiService.post<Application[]>(
      '/api/applicant_tracking/applications',
      this.makeParams(props),
    );
  }

  public static update(
    applicationId: number,
    props: UpsertApplicationDto,
  ): Promise<Application[]> {
    return ApiService.put<Application[]>(
      `/api/applicant_tracking/applications/${applicationId}`,
      this.makeParams(props),
    );
  }

  public static pushToHris(props: PushToHrisProps): Promise<void> {
    if (props.applicationIds.length == 1)
      return this.singlePushToHris(props.applicationIds[0]);

    return this.bulkPushToHris(props);
  }

  private static singlePushToHris(id: number): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/applications/${id}/push_to_hris`,
    );
  }

  private static bulkPushToHris(props: PushToHrisProps): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/applications/bulk_push_to_hris`,
      snakecaseKeys(props),
    );
  }

  private static makeParams(props: UpsertApplicationDto): object {
    return snakecaseKeys({
      ...props,
      resumeFilename: undefined,
      coverLetterFilename: undefined,
    });
  }
}
