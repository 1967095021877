import React from 'react';
import { NameDataCell } from './NameDataCell';
import { EmailDataCell } from './EmailDataCell';
import { AutoRecordingDataCell } from './AutoRecordingDataCell';
import { SelectedSettingsDataCell } from './SelectedSettingsDataCell';
import { HiringMemberSettingsType } from '../../../../../../entities/HiringMemberSettingsType';
import { HiringMemberList } from '../../../../../../entities/v1/HiringMemberList';

interface PropTypes {
  hiringMemberSettingsType: HiringMemberSettingsType;
  hiringMembersList: HiringMemberList;
  organizationAutoRecordingEnabled: boolean;
  organizationSettingEnabled: boolean;
  settingsQueryKey: string;
}

function setSettingsStatus(
  settingsEnabled: boolean,
  settingsTypeEnabled: boolean,
) {
  if (settingsEnabled != undefined) {
    return settingsEnabled;
  } else if (settingsTypeEnabled) {
    return settingsTypeEnabled;
  } else {
    return false;
  }
}

export function TableBody(props: PropTypes) {
  return (
    <tbody>
      {props.hiringMembersList.hiringMembers.map((hiringMember, idx) => (
        <tr key={idx}>
          <NameDataCell name={hiringMember.name} />
          <EmailDataCell email={hiringMember.email} />
          {props.hiringMemberSettingsType == 'recording_consent_optout' && (
            <AutoRecordingDataCell
              autoRecording={setSettingsStatus(
                hiringMember.hiringMemberSettings?.autoRecordingEnabled,
                props.organizationAutoRecordingEnabled,
              )}
            />
          )}
          <SelectedSettingsDataCell
            settingEnabled={
              props.hiringMemberSettingsType == 'auto_recording'
                ? setSettingsStatus(
                    hiringMember.hiringMemberSettings?.autoRecordingEnabled,
                    props.organizationSettingEnabled,
                  )
                : setSettingsStatus(
                    hiringMember.hiringMemberSettings?.recordingConsentOptout,
                    props.organizationSettingEnabled,
                  )
            }
            class={props.hiringMemberSettingsType == 'auto_recording' && 'ps-5'}
            settingsId={hiringMember.hiringMemberSettings?.id}
            hiringMemberSettingsType={props.hiringMemberSettingsType}
            recordingConsentOptout={
              hiringMember.hiringMemberSettings?.recordingConsentOptout
            }
            hiringMemberId={hiringMember.id}
            settingsQueryKey={props.settingsQueryKey}
          />
        </tr>
      ))}
    </tbody>
  );
}
