import React from 'react';
import { IconButton } from '../IconButton';

interface PropTypes {
  className?: string;
  onClick(): void;
}

export function PreviewButton(props: PropTypes) {
  return (
    <IconButton
      color='secondary'
      iconName='bi-eye'
      buttonText='Preview'
      buttonClass={props.className}
      onClick={props.onClick}
    />
  );
}
