import React from 'react';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { JobService } from '../../../../../services/applicant_tracking/JobService';
import { IconSpan } from '../../../../../components/IconSpan';
import { appendRedirectUri } from '../../../../../utils/url';

interface PropTypes {
  jobId: number;
  jobName: string;
  isModalOpen: boolean;
  setIsModalOpen: (b: boolean) => void;
}

export function DeleteJobConfirmationModal(props: PropTypes) {
  const currentUrl = window.location.href;

  const handleJobDelete = async () => {
    try {
      await JobService.delete(props.jobId);
      window.location.href = appendRedirectUri(
        `/applicant_tracking/jobs/delete_success?job_name=${props.jobName}`,
        currentUrl,
      );
    } catch (e) {
      console.error(e);
      window.location.href = appendRedirectUri(
        '/applicant_tracking/jobs/delete_error',
        currentUrl,
      );
    }

    props.setIsModalOpen(false);
  };

  return (
    <ConfirmationModal
      title='Delete Job'
      body={
        <>
          <div className='mb-3h'>
            Are you sure you want to delete the job{' '}
            <span className='fw-bold'>{props.jobName}</span>?
          </div>
          <div className='mt-4 fs-5 bg-light-warning py-2 px-3 rounded align-self-start'>
            <IconSpan
              spanText='Deleting this job will permanently remove it from Screenloop. This action cannot be undone.'
              icon={{ name: 'bi-info-circle' }}
            />
          </div>
        </>
      }
      isOpen={props.isModalOpen}
      onConfirm={handleJobDelete}
      onCancel={() => props.setIsModalOpen(false)}
      size={'md'}
      confirmText={'Delete'}
      disableAfterConfirm={false}
      contentClassName='w-100'
    />
  );
}
