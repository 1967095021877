import { Moment } from 'moment';
import React from 'react';
import { SelectOption } from '../../../../../components/Select';
import {
  Condition,
  SINGLE_SELECT_CONDITIONS,
} from '../../SurveyTriggerAddPage';

interface PropTypes {
  surveyTriggerName: string;
  surveyTemplate: string;
  conditions: Condition[];
  maxReminders: string;
  delayDays: string;
  startDate: Moment;
  edit: boolean;
}

function setMultiSelectedConditions(selectedConditions: SelectOption[]) {
  return selectedConditions.map((selectedCondition, index) => (
    <span key={index}>{selectedCondition.label} </span>
  ));
}

function setSingleSelectedCondition(selectedCondition: SelectOption) {
  return <span>{selectedCondition.label} </span>;
}

function SummarySection(props: { surveyTriggerName: string }) {
  return (
    <>
      <p className='mt-4 fw-bold'>Summary</p>
      <div className='mt-3 d-flex'>
        <p className='me-3 fw-semibold'>
          <span className='text-dark-200'>Survey Trigger Name: </span>
          {props.surveyTriggerName ? props.surveyTriggerName : 'MISSING'}
        </p>
        <p className='fw-semibold'>
          <span className='text-dark-200'>Survey Type: </span>Pulse
        </p>
      </div>
    </>
  );
}

function ConditionsSection(props: {
  surveyTemplate: string;
  conditions: Condition[];
}) {
  return (
    <>
      <p className='mt-3 fw-bold'>Conditions</p>
      <p className='fw-semibold'>
        <span className='text-dark-200'>Survey Track: </span>{' '}
        {props.surveyTemplate}
      </p>
      {props.conditions.map((condition, index: number) => (
        <p key={index} className='fw-semibold'>
          <span className='text-dark-200'>Condition {index + 1}: </span>
          {condition.type.label}, {condition.invert.label},{' '}
          {!SINGLE_SELECT_CONDITIONS.includes(condition.type.value)
            ? setMultiSelectedConditions(condition.multiSelected)
            : setSingleSelectedCondition(condition.singleSelected)}
        </p>
      ))}
    </>
  );
}

function SettingsSection(props: {
  maxReminders: string;
  delayDays: string;
  startDate: Moment;
}) {
  return (
    <>
      <p className='mt-4 fw-bold'>Settings</p>
      <div className='mt-3 d-flex'>
        <p className='me-3 fw-semibold'>
          <span className='text-dark-200'>Max Reminders: </span>{' '}
          {props.maxReminders}
        </p>
        <p className='me-3 fw-semibold'>
          <span className='text-dark-200'>Delay Days: </span> {props.delayDays}
        </p>
        <p className='fw-semibold'>
          <span className='text-dark-200'>Start Date: </span>{' '}
          {props.startDate.format('DD-MM-YYYY')}
        </p>
      </div>
    </>
  );
}

export function Body(props: PropTypes) {
  const title = `Are you sure that you want to
    ${props.edit ? 'update' : 'add'}
    the following trigger?`;

  return (
    <>
      <p>{title}</p>
      <SummarySection surveyTriggerName={props.surveyTriggerName} />
      <ConditionsSection
        surveyTemplate={props.surveyTemplate}
        conditions={props.conditions}
      />
      <SettingsSection
        maxReminders={props.maxReminders}
        delayDays={props.delayDays}
        startDate={props.startDate}
      />
    </>
  );
}
