import React from 'react';
import { AlertObject, AlertType } from '../../../../components/Alert';
import { MoveCandidateProps } from '.';

function SuccessAlert(action: MoveCandidateProps) {
  return (
    <span>
      The candidate <span className='fw-bold'>{action.candidateName}</span> was
      successfully moved to the {action.jobStageName} stage!
    </span>
  );
}

function FailureAlert(action: MoveCandidateProps) {
  return (
    <span>
      The candidate <span className='fw-bold'>{action.candidateName}</span>{' '}
      could not be moved to the {action.jobStageName} stage!
    </span>
  );
}

export function alertManager(
  action: MoveCandidateProps,
  setAlert: (alert: AlertObject) => void,
) {
  if (action.state === 'Success') {
    setAlert({
      type: AlertType.Success,
      message: <SuccessAlert {...action} />,
    });
  } else {
    setAlert({
      type: AlertType.Danger,
      message: <FailureAlert {...action} />,
    });
  }
}
