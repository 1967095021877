import React from 'react';
import classNames from 'classnames';
import { NavLinkTab } from '../NavLinkTab';

interface TabTypeMapping<TabType> {
  text: string;
  tab: TabType;
}

interface PropTypes<TabType> {
  navigationId?: string;
  navClassNames?: string;
  tabTypeMapping: TabTypeMapping<TabType>[];
  activeTab: TabType;
  setActiveTab: (at: TabType) => void;
  disabled?: boolean;
}

export function NavLinkTabs<TabType>(props: PropTypes<TabType>) {
  return (
    <nav
      id={props.navigationId}
      className={classNames('app-nav-tabs', 'nav', props.navClassNames)}
    >
      {props.tabTypeMapping.map((ttm, index) => (
        <NavLinkTab<TabType>
          key={index}
          text={ttm.text}
          tab={ttm.tab}
          activeTab={props.activeTab}
          setActiveTab={props.setActiveTab}
          disabled={props.disabled}
        />
      ))}
    </nav>
  );
}
