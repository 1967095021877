import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Size } from '../../utils/types/Size';

interface PropTypes {
  content: number | string | ReactNode;
  className?: string;
  size?: Size;
}

function getSizeClassNames(size?: Size) {
  switch (size) {
    case 'md':
      return classNames('fs-6', styles['circle-md']);
    case 'lg':
      return classNames('fs-5', styles['circle-lg']);
    case 'xl':
      return classNames('fs-4', styles['circle-xl']);
    default:
      return classNames('fs-5', styles['circle-lg']);
  }
}

export function ContentCircle(props: PropTypes) {
  return (
    <span
      className={classNames(
        'd-flex',
        'align-items-center',
        'justify-content-center',
        'badge',
        'rounded-circle',
        'm-0',
        props.className,
        getSizeClassNames(props.size),
      )}
    >
      {props.content}
    </span>
  );
}
