import { DateRange } from '../components/DateRangePicker';

export function stringToDateRange(label: string) {
  switch (label.toLowerCase()) {
    case 'today':
      return DateRange.Today;
    case 'yesterday':
      return DateRange.Yesterday;
    case 'last 7 days':
      return DateRange.Last7Days;
    case 'month to date':
      return DateRange.MonthToDate;
    case 'last month':
      return DateRange.LastMonth;
    case 'last 30 days':
      return DateRange.Last30Days;
    case 'last 90 days':
      return DateRange.Last90Days;
    default:
      return DateRange.CustomRange;
  }
}
