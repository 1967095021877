import React from 'react';
import { Control, UseFormRegister } from 'react-hook-form';
import { PhoneInputFormField } from '../../../../../../../../components/PhoneInputFormField';
import { SurveyQuestion } from '../../../../../../../../entities/survey_engine/SurveyQuestion';
import { placeholder } from '../../../utils/placeholder';

interface PropTypes {
  question: SurveyQuestion;
  formRegister: UseFormRegister<any>;
  control?: Control<any, any>;
  disabled?: boolean;
}

export function PhoneQuestionInput(props: PropTypes) {
  return (
    <PhoneInputFormField<any>
      control={props.control}
      disabled={props.disabled}
      required={props.question.mandatory}
      fieldName={props.question.title}
      controllerName={'phoneNumber'}
      placeholder={placeholder(props.question)}
      registerReturn={props.formRegister('phoneNumber', {
        required: props.question.mandatory,
      })}
      hideHeader={true}
    />
  );
}
