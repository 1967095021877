import React from 'react';
import { SurveyRoute } from '../../../containers/survey_engine/SurveyPage/SurveyRoute';
import { Survey } from '../../../entities/survey_engine/Survey';
import { SurveyQuestionAnswer } from '../../../entities/survey_engine/SurveyQuestionAnswer';
import { SurveyLoadingStatus } from '../../../types/survey_engine/SurveyLoadingStatus';
import { getQuestions } from '../../../utils/survey_engine/getQuestions';
import { SurveyFeedbackScreen } from './SurveyFeedbackScreen';

interface PropTypes {
  survey: Survey;
  onUpdateSurvey: (survey: Survey) => void;
  onChangeStatus: (status: SurveyLoadingStatus) => void;
  onFinishSurvey: () => void;
  submitAnswer: (
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ) => Promise<Survey>;
}

export function FeedbackRoute(props: PropTypes) {
  return (
    <SurveyRoute survey={props.survey} status='pending' mismatchPath='..'>
      <SurveyFeedbackScreen
        survey={props.survey}
        questions={getQuestions(props.survey)}
        onChangeStatus={props.onChangeStatus}
        onUpdateSurvey={props.onUpdateSurvey}
        onFinishSurvey={props.onFinishSurvey}
        submitAnswer={props.submitAnswer}
      />
    </SurveyRoute>
  );
}
