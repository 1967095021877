import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { SelectFormHook } from '../../../../../../../components/SelectFormHook';
import { SelectOption } from '../../../../../../../components/Select';
import { MONTHS } from '../../../../../../../utils/date';

interface PropTypes {
  formControl: Control<JobRequisitionDto>;
  formResetField: (controllerName: string) => void;
}

function buildYearOptions(currentYear: number): SelectOption[] {
  const options: SelectOption[] = [];

  for (let i = 0; i < 5; i++) {
    options.push({
      label: (currentYear + i).toString(),
      value: (currentYear + i).toString(),
    });
  }

  return options;
}

function buildOptionsForMonths(months: string[]): SelectOption[] {
  return months.map((month) => ({
    label: month,
    value: (MONTHS.indexOf(month) + 1).toString(),
  }));
}

function buildAvailableMonths(
  selectedYear: string | undefined,
): SelectOption[] {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const availableMonths = MONTHS.slice(currentMonth, MONTHS.length);

  if (!selectedYear || selectedYear !== currentYear.toString()) {
    return buildOptionsForMonths(MONTHS);
  } else {
    return buildOptionsForMonths(availableMonths);
  }
}

export function StartDate(props: PropTypes) {
  const currentYear = new Date().getFullYear();
  const selectedYear = useWatch({
    control: props.formControl,
    name: 'startYear',
  });

  return (
    <Col xs={3}>
      <div className='fs-5 fw-semibold mb-2'>Planned Start Date</div>
      <Row className='gx-2h'>
        <Col xs={7}>
          <SelectFormHook<JobRequisitionDto>
            placeholder='Select month'
            formHookContext={{
              controllerName: 'startMonth',
              formControl: props.formControl,
              required: false,
            }}
            options={buildAvailableMonths(selectedYear?.toString())}
            formResetField={props.formResetField}
          />
        </Col>
        <Col xs={5}>
          <SelectFormHook<JobRequisitionDto>
            placeholder='Select year'
            formHookContext={{
              controllerName: 'startYear',
              formControl: props.formControl,
              required: false,
            }}
            options={buildYearOptions(currentYear)}
            formResetField={props.formResetField}
          />
        </Col>
      </Row>
    </Col>
  );
}
