import React, { ReactNode, useState } from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { JobRequisition } from '../../../../entities/v1/applicant_tracking/JobRequisition';
import RequisitionInfoCard from './JobRequisitionCard/RequisitionInfoCard';
import JobInfoCard from './JobRequisitionCard/JobInfoCard';
import ApprovalFlowCard from './JobRequisitionCard/ApprovalFlowCard';
import { Header } from './Header';
import { User } from '../../../../entities/User';
import { JobRequisitionService } from '../../../../services/v1/applicant_tracking/JobRequisitionService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

interface PropTypes {
  currentUser: User;
  jobRequisition: JobRequisition;
  jobRequisitionV2FfEnabled: boolean;
}

interface CardPropTypes {
  className: string;
  children: ReactNode;
}

function Card(props: CardPropTypes) {
  return (
    <Col sm='12' md='4' className={classNames('px-0', props.className)}>
      {props.children}
    </Col>
  );
}

export default function ShowJobRequisitionPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobRequisition, setJobRequisition] = useState<JobRequisition>(
    props.jobRequisition,
  );

  const reloadJobRequisition = () => {
    JobRequisitionService.show({
      id: jobRequisition.id.toString(),
      expand: [
        'owner_user',
        'approvers',
        'approvers.user',
        'department',
        'location',
        'hiring_manager_user',
      ],
    })
      .then(setJobRequisition)
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Header
        jobRequisition={jobRequisition}
        currentUser={props.currentUser}
        jobRequisitionV2FfEnabled={props.jobRequisitionV2FfEnabled}
        setLoadingState={setIsLoading}
        reloadJobRequisition={reloadJobRequisition}
      />
      <Row className='gx-4 my-4 mx-0'>
        <Card className='pe-2h'>
          <RequisitionInfoCard jobRequisition={jobRequisition} />
        </Card>
        <Card className='px-2h'>
          <JobInfoCard jobRequisition={jobRequisition} />
        </Card>
        <Card className='ps-2h'>
          <ApprovalFlowCard
            approvers={jobRequisition.approvers}
            currentUser={props.currentUser}
          />
        </Card>
      </Row>
    </>
  );
}
