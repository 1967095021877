import classNames from 'classnames';
import React, { useCallback } from 'react';
import { NavLink } from 'reactstrap';
import { CandidateTab } from '../CandidateTab';
import styles from './styles.module.scss';

type ChildElement = JSX.Element | string;

interface PropTypes {
  tab: CandidateTab;
  activeTab: CandidateTab;
  children: ChildElement;
  className?: string;
  onClick?: (tab: CandidateTab) => void;
}

export const CandidateTabLink = React.memo(function CandidateTabLink(
  props: PropTypes,
) {
  const handleTabClick = useCallback(
    (e: React.MouseEvent, tab: CandidateTab) => {
      e.preventDefault();
      props.onClick?.call(null, tab);
    },
    [props.onClick],
  );

  return (
    <NavLink
      active={props.tab === props.activeTab}
      href={`?tab=${props.tab}`}
      className={classNames(styles.tabLink, props.className)}
      onClick={(e) => handleTabClick(e, props.tab)}
    >
      {props.children}
    </NavLink>
  );
});
