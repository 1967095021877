import { Moment } from 'moment';
import React, { useCallback } from 'react';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { SurveyTriggerService } from '../../../../services/SurveyTriggerService';
import { Condition, SelectedConditions } from '../SurveyTriggerAddPage';
import { Body } from './Body';

interface PropTypes {
  isOpen: boolean;
  setModalIsOpen: (modalIsOpen: boolean) => void;
  surveyTriggerName: string;
  surveyTriggerId: number;
  surveyTemplate: string;
  conditions: Condition[];
  maxReminders: string;
  delayDays: string;
  startDate: Moment;
  surveyTriggerStatus: boolean;
  surveyTemplateId: number;
  edit: boolean;
  selectedConditions: SelectedConditions;
  setAlertMessage: (message: string) => void;
}

export function SurveyTriggerConfirmationModal(props: PropTypes) {
  const handleOnAdd = useCallback(async () => {
    try {
      await SurveyTriggerService.create({
        active: props.selectedConditions.active,
        name: props.selectedConditions.name,
        maxReminders: props.selectedConditions.maxReminders,
        delayDays: props.selectedConditions.delayDays,
        processingStartsAt: props.selectedConditions.processingStartsAt,
        SurveyTemplateId: props.selectedConditions.surveyTemplateId,
        jobIds: props.selectedConditions.jobIds,
        invertJobCondition: props.selectedConditions.invertJobCondition,
        locationIds: props.selectedConditions.locationIds,
        invertLocationCondition:
          props.selectedConditions.invertLocationCondition,
        jobStageName: props.selectedConditions.jobStageName,
        invertJobStageCondition:
          props.selectedConditions.invertJobStageCondition,
        applicationRejectReason:
          props.selectedConditions.applicationRejectReason,
        applicationStatus: props.selectedConditions.applicationStatus,
        jobConfidential: props.selectedConditions.jobConfidential,
      });
      window.location.href = '/organization_settings/survey_triggers';
    } catch (e) {
      props.setAlertMessage(e.body['errors']);
    }
  }, [props.selectedConditions]);

  const handleOnSave = useCallback(async () => {
    try {
      await SurveyTriggerService.update(props.surveyTriggerId, {
        active: props.selectedConditions.active,
        name: props.selectedConditions.name,
        maxReminders: props.selectedConditions.maxReminders,
        delayDays: props.selectedConditions.delayDays,
        processingStartsAt: props.selectedConditions.processingStartsAt,
        SurveyTemplateId: props.selectedConditions.surveyTemplateId,
        jobIds: props.selectedConditions.jobIds,
        invertJobCondition: props.selectedConditions.invertJobCondition,
        locationIds: props.selectedConditions.locationIds,
        invertLocationCondition:
          props.selectedConditions.invertLocationCondition,
        jobStageName: props.selectedConditions.jobStageName,
        invertJobStageCondition:
          props.selectedConditions.invertJobStageCondition,
        applicationRejectReason:
          props.selectedConditions.applicationRejectReason,
        applicationStatus: props.selectedConditions.applicationStatus,
        jobConfidential: props.selectedConditions.jobConfidential,
      });
      window.location.href = '/organization_settings/survey_triggers';
    } catch (e) {
      props.setAlertMessage(e.body['errors']);
    }
  }, [props.surveyTriggerId, props.selectedConditions]);
  return (
    <ConfirmationModal
      title={props.edit ? 'Update Trigger' : 'Add New Trigger'}
      body={
        <Body
          surveyTriggerName={props.surveyTriggerName}
          surveyTemplate={props.surveyTemplate}
          conditions={props.conditions}
          maxReminders={props.maxReminders}
          delayDays={props.delayDays}
          startDate={props.startDate}
          edit={props.edit}
        />
      }
      isOpen={props.isOpen}
      onConfirm={() => {
        if (!props.edit) {
          handleOnAdd();
        } else {
          handleOnSave();
        }
      }}
      onCancel={() => props.setModalIsOpen(false)}
      size={'lg'}
      confirmText={'Yes'}
    />
  );
}
