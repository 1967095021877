import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../../components/FormFieldHeader';
import { RichTextEditor } from '../../../../../components/RichTextEditor';
import { UpsertOfferLetterTemplateDto } from '../UpsertEmailTemplateDto';
import { OfferLetterTemplate } from '../../../../../entities/v1/applicant_tracking/OfferLetterTemplate';
import { Control } from 'react-hook-form';
import { OfferLetterRichTextComposer } from './OfferLetterRichTextComposer';
import { CopyVariables } from './CopyVariables';
import { IconButton } from '../../../../../components/IconButton';

interface PropTypes {
  control: Control<UpsertOfferLetterTemplateDto, any>;
  isValid: boolean;
  referredEmailTemplate?: OfferLetterTemplate;
  setHtml: (html: string) => void;
  onPreview: () => void;
}

export function OfferLetterRichTextEditor(props: PropTypes) {
  return (
    <>
      <Row>
        <Col xs='8'>
          <div className='d-flex justify-content-between mb-3'>
            <FormFieldHeader fieldName='Body' isRequired={true} />
            <IconButton
              disabled={!props.isValid}
              iconName={'bi-eye'}
              buttonText='Preview'
              onClick={props.onPreview}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs='8'>
          <RichTextEditor<UpsertOfferLetterTemplateDto>
            formHookContext={{
              controllerName: 'template',
              formControl: props.control,
              required: true,
            }}
            render={(field) => (
              <OfferLetterRichTextComposer
                referredEmailTemplate={props.referredEmailTemplate}
                editor={field.value as string}
                setHtml={props.setHtml}
                onChangeJson={field.onChange}
              />
            )}
          />
        </Col>
        <Col xs='4'>
          <CopyVariables />
        </Col>
      </Row>
    </>
  );
}
