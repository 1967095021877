import React from 'react';
import { User } from '../../../../../../../../entities/User';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';
import { ApplicationRating } from '../../../../../../../../entities/v1/applicant_tracking/ApplicationRating';
import { User as Userv1 } from '../../../../../../../../entities/v1/User';

interface PropTypes {
  currentUser: User;
  applicationRating: ApplicationRating;
}

function memberName(currentUser: User, ratingUser: Userv1) {
  if (ratingUser?.id === currentUser.id) {
    return 'My Own';
  }

  return ratingUser?.name;
}

export function CandidateRatingBlockTitle(props: PropTypes) {
  return (
    <>
      <div className='fw-semibold fs-3 me-3'>
        {memberName(props.currentUser, props.applicationRating.user)}
      </div>
      <Chip color={ChipColor.BlueLight}>
        {props.applicationRating.jobStage?.name}
      </Chip>
    </>
  );
}
