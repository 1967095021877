import classNames from 'classnames';
import { CollapsableText } from '../../CollapsableText';
import React from 'react';
import styles from '../styles.module.scss';
import { IconButton } from '../../IconButton';
import { Summary } from '../../../entities/Summary';

interface PropTypes {
  summary: Summary;
  classNames: string;
  onCustomizing: (customizing: boolean) => void;
  isCustomizingSummary: boolean;
  customSummaryEnabled: boolean;
}

export function CollapsableSummaryPanel(props: PropTypes) {
  function handleCustomizeButtonClick() {
    props.onCustomizing(!props.isCustomizingSummary);
  }

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='fs-4 fw-bold'>Summary</div>
        {props.customSummaryEnabled && (
          <IconButton
            color='borderless'
            buttonText='Customize'
            iconName='bi-stars'
            size='sm'
            onClick={handleCustomizeButtonClick}
          />
        )}
      </div>
      <div
        className={classNames(
          props.classNames,
          'bg-gray',
          'fs-5',
          'py-2',
          'px-3',
          'rounded-2',
          styles.summary,
        )}
      >
        <CollapsableText
          collapseWords={50}
          maxWords={350}
          expandWordsStep={props.summary.text.length}
          text={props.summary.text}
          expandedByDefault={true}
          buttonSize='lg'
        />
      </div>
    </>
  );
}
