import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionFieldType } from '../../entities/survey_engine/SurveyQuestionFieldType';

export function buildQuestion(
  title: string,
  type: SurveyQuestionFieldType,
  options: { name: string; value: string }[] = [],
): SurveyQuestion {
  return {
    id: -1,
    title: title,
    fields: [
      {
        id: -1,
        type: type,
        positive: true,
        options: options.map((option, i) => ({
          id: -i,
          name: option.name,
          value: option.value,
        })),
      },
    ],
    min_options: 0,
    order: 0,
    section: {
      id: '',
      name: '',
      description: '',
    },
    conditions: [],
    default_flow: false,
    mandatory: true,
    type: type,
  };
}
