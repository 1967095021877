import { SurveyQuestionAnswer } from '../../../../reference_check/entities/SurveyQuestionAnswer';
import { SurveyQuestionFieldAnswer } from '../../../../reference_check/entities/SurveyQuestionFieldAnswer';

export interface AnswersByField {
  questionId: number;
  fieldId: number;
  fieldAnswers: SurveyQuestionFieldAnswer[];
}

export function groupAnswersByField(
  answers: SurveyQuestionAnswer[],
): AnswersByField[] {
  const answersByField = answers.reduce<
    { questionId: number; field: SurveyQuestionFieldAnswer }[]
  >((prev, curr) => {
    const fields = curr.fields.map((f) => ({
      questionId: curr.question_id,
      field: f,
    }));
    return prev.concat(fields);
  }, []);

  return answersByField.reduce<AnswersByField[]>((prev, curr) => {
    const existing = prev.find((x) => x.fieldId === curr.field.field_id);
    const field = existing || {
      questionId: curr.questionId,
      fieldId: curr.field.field_id,
      fieldAnswers: [],
    };
    if (existing === undefined) prev.push(field);
    field.fieldAnswers.push(curr.field);
    return prev;
  }, []);
}
