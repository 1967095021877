import { Interview } from '../../../../entities/Interview';
import { ActionPoint } from '../../../../entities/ActionPoint';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { ActionPoints } from '../../../../components/ActionPoints';
import { CardEmptyState } from '../CardEmptyState';
import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export function ActionPointsCard(props: {
  interview: Interview;
  atsEnabled: boolean;
  handleActionPoints: (actionPoints: ActionPoint[]) => void;
}) {
  return (
    <DeprecatedPanel
      additionalInnerContainerClassNames='p-4'
      className='mt-4'
      style={{ height: '226px' }}
    >
      <h3>Action Points</h3>
      {props.interview.actionPoints != null &&
      props.interview.actionPoints.length !== 0 ? (
        <div className={classNames('mt-3', styles['action-points-container'])}>
          <ActionPoints
            interview={props.interview}
            onUpdateActionPoints={props.handleActionPoints}
            atsEnabled={props.atsEnabled}
          />
        </div>
      ) : (
        <CardEmptyState />
      )}
    </DeprecatedPanel>
  );
}
