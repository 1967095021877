import classNames from 'classnames';
import React from 'react';
import { SelectOption } from '..';
import { OptionProps, components } from 'react-select';
import { TruncateText } from '../../TruncateText';

export function Option(props: OptionProps) {
  const optionData: SelectOption = props.data as SelectOption;

  return (
    <components.Option {...props}>
      <div
        className={classNames(
          'd-flex flex-nowrap',
          { 'justify-content-between': !props.isMulti },
          optionData.classNames,
        )}
      >
        {props.isMulti && (
          <input
            type='checkbox'
            checked={props.isSelected}
            onChange={(e) => {
              e.stopPropagation();
            }}
            disabled={props.isDisabled}
            className='form-check-input me-2'
          />
        )}
        <TruncateText text={optionData.label} ellipsize={true} tooltip={true} />
        {optionData.subLabel}
      </div>
    </components.Option>
  );
}
