import React, { useState } from 'react';
import { InterviewerParticipantDetails } from './InterviewerParticipantDetails';
import { RemoveParticipantButton } from './RemoveParticipantButton';
import { IgnoreParticipantCalendarButton } from './IgnoreParticipantCalendarButton';
import { InterviewerParticipant } from '../../../../../../../entities/applicant_tracking/InterviewerParticipant';

interface PropTypes {
  participant: InterviewerParticipant;
  participants: InterviewerParticipant[];
  candidateView: boolean;
  setParticipants: (participants: InterviewerParticipant[]) => void;
  onChangingHost: (participant: InterviewerParticipant) => void;
  onIgnoringCalendar: (participant: InterviewerParticipant) => void;
  allowChangingMeetingOrganizer: boolean;
  allowIgnoreParticipantCalendar: boolean;
  isReadOnly?: boolean;
}

export function InterviewerParticipantRow(props: PropTypes) {
  const [buttonsStyle, setButtonsStyle] = useState({ display: 'none' });

  return (
    <div
      className={'mt-2 d-flex w-100'}
      onMouseEnter={() => setButtonsStyle({ display: 'flex' })}
      onMouseLeave={() => setButtonsStyle({ display: 'none' })}
    >
      <div className={'d-flex w-100'}>
        <InterviewerParticipantDetails
          participant={props.participant}
          candidateView={props.candidateView}
          onChangingHost={props.onChangingHost}
          allowChangingMeetingOrganizer={props.allowChangingMeetingOrganizer}
        />
      </div>
      <div style={buttonsStyle}>
        <IgnoreParticipantCalendarButton
          participant={props.participant}
          candidateView={props.candidateView}
          allowIgnoreParticipantCalendar={props.allowIgnoreParticipantCalendar}
          onIgnoringCalendar={props.onIgnoringCalendar}
        />
        <RemoveParticipantButton
          participant={props.participant}
          participants={props.participants}
          setParticipants={props.setParticipants}
          isReadOnly={props.candidateView || props.isReadOnly}
        />
      </div>
    </div>
  );
}
