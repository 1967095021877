import classNames from 'classnames';
import React from 'react';
import CloseEvaluationImage from '../../../../../../../images/training-close-evaluation-modal.svg';
import styles from './styles.module.scss';

export function CloseEvaluationBody() {
  return (
    <>
      <div className='text-center'>
        <img
          className={classNames('text-center', styles.illustration)}
          src={CloseEvaluationImage}
        />
      </div>
      <div className='mt-4 text-center'>
        Are you sure you want to leave this evaluation?
        <br />
        You can always finish it later though.
      </div>
    </>
  );
}
