import React from 'react';
import { ToggleButton } from '../../../../../components/ToggleButton';

interface PropTypes {
  enabled: boolean;
  onToggleChange: (event) => void;
}

export default function CustomDomainToggle(props: PropTypes) {
  return (
    <>
      <span className='me-3'>{props.enabled ? 'Enabled' : 'Disabled'}</span>
      <ToggleButton
        name='EmailDomainSettings'
        checked={props.enabled}
        onChange={props.onToggleChange}
        color='info'
      />
    </>
  );
}
