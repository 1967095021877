import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import styles from './styles.module.scss';
import { InterviewDatePickerFooterContext } from '..';
import { User } from '../../../../../../entities/User';

interface PropTypes {
  buttonText: string;
  description: React.ReactNode;
  onConfirm: (data: UpsertSelfScheduleInterviewPropTypes) => void;
}

export interface UpsertSelfScheduleInterviewPropTypes {
  interviewerUserIds: number[];
  meetingProvider: string;
  durationMinutes: number;
  dateRange: number;
  scheduleFromHours: number;
  timezone: string;
  meetingOrganizer: User;
  slotsAvailable: boolean;
}

export function InterviewDatePickerFooter(props: PropTypes) {
  const selfScheduleInterviewData = useContext(
    InterviewDatePickerFooterContext,
  );
  const [confirmButtonEnabled, setConfirmButtonEnabled] =
    useState<boolean>(true);
  const onClick = () => {
    setConfirmButtonEnabled(false);
    props.onConfirm(selfScheduleInterviewData);
  };

  useEffect(() => {
    setConfirmButtonEnabled(selfScheduleInterviewData.slotsAvailable);
  }, [selfScheduleInterviewData.slotsAvailable]);

  return (
    <div
      className={classNames(
        'd-flex justify-content-between mt-4',
        styles['footer'],
      )}
    >
      <div className='text-dark-200 fs-5'>{props.description}</div>
      <Button
        type='button'
        color='primary'
        onClick={onClick}
        disabled={!confirmButtonEnabled}
        className={classNames(styles['confirm-button'])}
      >
        {props.buttonText}
      </Button>
    </div>
  );
}
