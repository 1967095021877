import React from 'react';
import { ConfirmationModal } from '../../../../components/ConfirmationModal';
import { InviteUserForm } from '../../InviteUserForm';
import {
  SubmitErrorHandler,
  SubmitHandler,
  UseFormRegister,
} from 'react-hook-form';
import { FormValues } from '../../UserInvitationPage';
import { SelectOption } from '../../../../components/Select';

interface PropTypes {
  register: UseFormRegister<FormValues>;
  selectedRole: SelectOption;
  setSelectedRole: (role: SelectOption) => void;
  invalidInput: boolean;
  openModal: boolean;
  handleSubmit: (
    onValid: SubmitHandler<FormValues>,
    onInvalid?: SubmitErrorHandler<FormValues>,
  ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
  onSubmit: (data: FormValues) => Promise<void>;
  setOpenModal: (value: boolean) => void;
  email: string;
}

export function NewUserModal(props: PropTypes) {
  return (
    <ConfirmationModal
      title='Add User'
      body={
        <InviteUserForm
          register={props.register}
          selectedRole={props.selectedRole}
          setSelectedRole={props.setSelectedRole}
          invalidInput={props.invalidInput}
        />
      }
      isOpen={props.openModal}
      disableAfterConfirm={false}
      onConfirm={props.handleSubmit(async (data) => {
        await props.onSubmit(data);
      })}
      onCancel={() => props.setOpenModal(false)}
      size='md'
      confirmText='Submit'
      isConfirmButtonEnabled={!!props.email}
    />
  );
}
