import React, { useEffect, useState } from 'react';
import { ScorecardAttribute } from '../../../../../entities/ScorecardAttribute';
import { ScorecardCategory } from '../../../../../entities/ScorecardCategory';
import { ScorecardAttributeChip } from './ScorecardAttributes/ScorecardAttributeChip';
import { ScorecardAttributeScore } from './ScorecardAttributes/ScorecardAttributeScore';
import { ScorecardAttributeToast } from './ScorecardAttributes/ScorecardAttributeToast';
import { ScorecardAttributeScoreSuccess } from './ScorecardAttributes/ScorecardAttributeScoreSuccess';
import { ScorecardCategoriesHeader } from './ScorecardCategoriesHeader';
import { ScorecardCategoriesNavBar } from './ScorecardCategoriesNavBar';
import classNames from 'classnames';
import { Row } from 'reactstrap';
import styles from './styles.module.scss';

interface PropTypes {
  interviewId: number;
  scorecardCategories: ScorecardCategory[];
  title: string;
  userId: number;
  onScorecardAttributesUpdated: (
    attribute: ScorecardAttribute,
    score?: number,
  ) => void;
}

function AttributeList(props: {
  scorecardCategories: ScorecardCategory[];
  activeTabIndex: number;
  setSelectedAttributeIndex: (index: number) => void;
}) {
  return (
    <Row
      data-testid='sidekick-scorecard-attributes'
      className={classNames(
        'row',
        'ms-3',
        'mt-2',
        'me-0',
        'align-content-start',
        styles.attributes,
      )}
    >
      {props.scorecardCategories[props.activeTabIndex].scorecard_attributes.map(
        (attribute: ScorecardAttribute, index: number) => (
          <ScorecardAttributeChip
            key={attribute.id}
            index={index}
            attribute={attribute}
            setClicked={props.setSelectedAttributeIndex}
          />
        ),
      )}
    </Row>
  );
}

function ScorecardToast(props: {
  selectedAttribute: ScorecardAttribute;
  setSelectedAttributeIndex: (index: number) => void;
  handleScorecardAttributesUpdated: (score?: number) => void;
}) {
  return (
    <ScorecardAttributeToast
      activeAttribute={props.selectedAttribute}
      onActiveAttribute={props.setSelectedAttributeIndex}
    >
      {props.selectedAttribute != null &&
        (props.selectedAttribute.score != null ? (
          <ScorecardAttributeScoreSuccess
            activeAttribute={props.selectedAttribute}
          />
        ) : (
          <ScorecardAttributeScore
            activeAttribute={props.selectedAttribute}
            onScorecardAttributesUpdated={
              props.handleScorecardAttributesUpdated
            }
          />
        ))}
    </ScorecardAttributeToast>
  );
}

export function ScorecardCategoriesPanel(props: PropTypes) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedAttributeIndex, setSelectedAttributeIndex] =
    useState(undefined);

  const selectedAttribute =
    props.scorecardCategories[activeTabIndex]?.scorecard_attributes[
      selectedAttributeIndex
    ];

  useEffect(() => {
    setSelectedAttributeIndex(undefined);
  }, [activeTabIndex]);

  useEffect(() => {
    if (selectedAttributeIndex == null) return;

    props.onScorecardAttributesUpdated(selectedAttribute);
  }, [selectedAttributeIndex]);

  function handleScorecardAttributesUpdated(score?: number) {
    props.onScorecardAttributesUpdated(selectedAttribute, score);
  }

  return (
    <div className='mx-3 mt-4 rounded-4 bg-white'>
      <ScorecardCategoriesHeader title={props.title} />
      <ScorecardCategoriesNavBar
        scorecardCategories={props.scorecardCategories}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      <div className='pb-3'>
        <AttributeList
          scorecardCategories={props.scorecardCategories}
          activeTabIndex={activeTabIndex}
          setSelectedAttributeIndex={setSelectedAttributeIndex}
        />
        <ScorecardToast
          selectedAttribute={selectedAttribute}
          setSelectedAttributeIndex={setSelectedAttributeIndex}
          handleScorecardAttributesUpdated={handleScorecardAttributesUpdated}
        />
      </div>
    </div>
  );
}
