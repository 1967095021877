import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppPlugin } from '..';
import { ApplicantTrackingJobsApp } from '../../containers/applicant_tracking/Jobs/ApplicantTrackingJobsApp';

export class ApplicantTrackingJobsAppPlugin implements AppPlugin {
  public load(): void {
    const el = document.getElementById('applicantTrackingJobsApp');

    if (el) this.installContainer(el);
  }

  private installContainer(el: HTMLElement): void {
    const props = JSON.parse(el.dataset['props'] || '{}');

    createRoot(el).render(React.createElement(ApplicantTrackingJobsApp, props));
  }
}
