import React, { useEffect } from 'react';
import { JobBoardAlertObject } from '../../JobBoardAlert';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  marketplaceUrl: string;
  url: string;
  onComplete: (success: boolean) => void;
  setAlert: (alert: JobBoardAlertObject) => void;
}

enum PAYMENT_STATUS {
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_ACTION = 'requires_action',
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  CANCELED = 'canceled',
}

export function PaymentModalBody(props: PropTypes) {
  function handlePaymentStatusUpdate(payload: any) {
    switch (payload.value) {
      case PAYMENT_STATUS.SUCCEEDED:
        props.onComplete(true);
        break;
      default:
        props.onComplete(false);
        break;
    }
  }

  function handleMessage(message: MessageEvent) {
    if (
      message.origin !== 'https://pkb.web-production.vonq-aws.com' &&
      message.origin !== 'https://pkb-sandbox.web-production.vonq-aws.com' &&
      message.origin !== props.marketplaceUrl
    ) {
      return;
    }

    const data = JSON.parse(message.data);

    switch (data.type) {
      case 'payment_status':
        handlePaymentStatusUpdate(data.payload);
        break;
      case 'error':
        console.error(data);
        props.onComplete(false);
        break;
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <iframe
      className={classNames('d-flex m-auto', styles['payment-iframe'])}
      src={props.url}
      allow='payment *'
    ></iframe>
  );
}
