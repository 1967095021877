import React from 'react';
import { Table } from 'reactstrap';
import { HealthBar } from '../../../../components/HealthBar';
import { Survey } from '../../../../reference_check/entities/Survey';
import { SurveyQuestion } from '../../../../reference_check/entities/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../../../reference_check/entities/SurveyQuestionAnswer';
import { SurveyQuestionField } from '../../../../reference_check/entities/SurveyQuestionField';
import { SCORE_LEGEND } from '../ScoreLegend';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { PagePanel } from '../PagePanel';
import { findCandidateSurvey } from '../../../../reference_check/utils/findCandidateSurvey';
import { formatRelationship } from '../../../../utils/formatRelationship';

interface PropTypes {
  questions: SurveyQuestion[];
  surveys: Survey[];
  answers: SurveyQuestionAnswer[];
  exportToPDF?: boolean;
}

interface FieldRatings {
  field: SurveyQuestionField;
  ratings: { survey: Survey; value: number }[];
}

const groupFieldRatings = (question: SurveyQuestion, props: PropTypes) => {
  const questionAnswers = props.answers.filter(
    (a) => a.question_id === question.id,
  );
  return question.fields.map((field) => {
    const fieldRatings: FieldRatings = { field, ratings: [] };
    fieldRatings.ratings = questionAnswers.map((a) => ({
      survey: props.surveys.find((s) => s.id === a.survey_id),
      value: parseInt(
        a.fields.find((f) => f.field_id === field.id).value + '',
        10,
      ),
    }));
    return fieldRatings;
  });
};

const calculateAverage = (fieldRatings: FieldRatings) => {
  const avg =
    fieldRatings.ratings.reduce((prev, r) => prev + r.value, 0) /
    fieldRatings.ratings.length;
  return avg;
};

const renderQuestionResult = (question: SurveyQuestion, props: PropTypes) => {
  const fieldRatings = groupFieldRatings(question, props);

  return (
    <React.Fragment key={question.id}>
      {fieldRatings.map((field) => (
        <tr key={field.field.id} className={styles.tableRow}>
          <td>{field.field.name || question.display_name || question.title}</td>
          {field.ratings.map((ratings) => (
            <td className='text-center' key={ratings.survey.id}>
              {ratings.value}
            </td>
          ))}
          <td className='fw-bold text-center'>
            {calculateAverage(field).toFixed(1)}
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <HealthBar
              value={calculateAverage(field) / 5.0}
              colors={mapColors()}
            />
          </td>
        </tr>
      ))}
    </React.Fragment>
  );
};

function Body(props: PropTypes) {
  return (
    <div className={getScrollingClasses(props.exportToPDF)}>
      <Table
        className={classNames(
          'fs-5',
          'text-primary',
          'align-middle',
          styles.tableFixed,
        )}
      >
        <thead className='fw-semibold'>
          <tr>
            <th scope='col'>Question</th>
            {props.surveys.map((survey) => (
              <th scope='col' key={survey.id} className='text-center'>
                {formatRelationship(survey.relationship)}
              </th>
            ))}
            <th scope='col' className='text-center'>
              AVG
            </th>
            <th scope='col' style={{ width: '20%' }}></th>
          </tr>
        </thead>
        <tbody>
          {props.questions.map((q) => renderQuestionResult(q, props))}
        </tbody>
      </Table>
    </div>
  );
}

export function RatingResultsTable(props: PropTypes) {
  if (
    props.answers.filter((answer) =>
      answer.fields.some((field) => field.value != null),
    ).length === 0
  ) {
    return null;
  }

  let surveys: Survey[] = JSON.parse(JSON.stringify(props.surveys));
  let answers: SurveyQuestionAnswer[] = JSON.parse(
    JSON.stringify(props.answers),
  );

  const candidateSurvey = findCandidateSurvey(props.surveys);
  if (candidateSurvey) {
    const candidateAnswers = props.answers.find(
      (answers) => answers.survey_id === candidateSurvey.id,
    );
    const hasCandidateData = candidateAnswers.fields.some(
      (field) => field.value != null,
    );
    if (!hasCandidateData) {
      surveys = surveys.filter((survey) => survey.relationship !== 'candidate');
      answers = answers.filter(
        (answer) => answer.survey_id !== candidateSurvey.id,
      );
    }
  }

  if (props.questions.length > 1) {
    return (
      <Body
        surveys={surveys}
        questions={props.questions}
        answers={answers}
        exportToPDF={props.exportToPDF}
      />
    );
  } else {
    return (
      <PagePanel question={props.questions[0]} exportToPDF={props.exportToPDF}>
        <Body
          surveys={surveys}
          questions={props.questions}
          answers={answers}
          exportToPDF={props.exportToPDF}
        />
      </PagePanel>
    );
  }
}

function mapColors() {
  const colors: { [k: number]: string } = {};

  SCORE_LEGEND.forEach((item) => {
    colors[item.minScore] = item.color;
  });

  return colors;
}

function getScrollingClasses(exportToPDF: boolean) {
  if (exportToPDF === true) {
    return styles.extended;
  } else {
    return styles.scrollable;
  }
}
