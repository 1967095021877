import React from 'react';
import {
  FilterStates,
  OnChangeFilterStatesCallback,
} from '../../../../components/candidate_experience/Filters/filterState';
import { Filters } from '../../../../components/candidate_experience/Filters';
import OverviewAnalytics from './OverviewAnalytics';

interface PropTypes {
  filterStates: FilterStates;
  moreFiltersShown: boolean;
  onApplyFilter: OnChangeFilterStatesCallback;
  candidateDemographicQuestionsEnabled: boolean;
  organizationName: string;
  setAnalyticsEmpty: (state: boolean) => void;
  pulseStandardNpsCalculation: boolean;
}

export function OverviewAnalyticsTab(props: PropTypes) {
  return (
    <>
      <Filters {...props} className='mb-4' />
      <OverviewAnalytics
        filterStates={props.filterStates}
        moreFiltersShown={props.moreFiltersShown}
        onApplyFilter={props.onApplyFilter}
        exportToPDF={false}
        setAnalyticsEmpty={(state) => props.setAnalyticsEmpty(state)}
        organizationName={props.organizationName}
        pulseStandardNpsCalculation={props.pulseStandardNpsCalculation}
      />
    </>
  );
}
