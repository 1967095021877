import { renderDropdownAction } from '..';
import { AlertType } from '../../../components/Alert';
import { InterviewListItem } from '../../../entities/InterviewListItem';
import { shouldActionBeVisible } from './index';

export function copySchedulingLink(
  interview: InterviewListItem,
  setAlertMessage: (message: string) => void,
  setAlertType: (type: AlertType) => void,
) {
  const onCopyInterviewLink = () => {
    const url = `${window.location.origin}/applicant_tracking/interview_candidate_users/${interview.interviewCandidateUser.secureId}/schedule`;
    navigator.clipboard.writeText(url);
    setAlertMessage('The link has been successfully copied to your clipboard.');
    setAlertType(AlertType.Success);
  };

  if (
    !shouldActionBeVisible(interview) ||
    interview.interviewCandidateUser?.isExpired
  )
    return null;

  return renderDropdownAction(
    'Copy self-scheduling link',
    'bi-files',
    false,
    onCopyInterviewLink,
  );
}
