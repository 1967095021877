import React from 'react';
import { JobStageTemplateInput } from '../../../../../../../containers/PlanTemplates/SetupPlanTemplatePage';
import { JobStageJob } from '../../../../../../../entities/applicant_tracking/JobStageJob';
import { JobCard } from './Jobcard';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  stage: JobStageTemplateInput;
  jobStageJobs: JobStageJob[];
}

export function JobCardContainer(props: PropTypes) {
  return (
    <div className={classNames('mt-3', styles['job-card-container'])}>
      {props.jobStageJobs.map((jobInfo) => (
        <JobCard
          key={jobInfo.job.id}
          jobName={jobInfo.job.name}
          departmentName={jobInfo.job.departmentName}
          applicationsCount={jobInfo.applicationsCount}
        />
      ))}
    </div>
  );
}
