import { ApplicationFeedComment } from '../../../../../../entities/ApplicationFeedComment';
import { User } from '../../../../../../entities/User';
import React from 'react';
import { IconSpan } from '../../../../../../components/IconSpan';
import { Dropdown } from '../../../../../../components/Dropdown';
import styles from './styles.module.scss';

interface PropTypes {
  entry: ApplicationFeedComment;
  currentUser: User;
  onEdit: () => void;
  onDelete: () => void;
}

function editComment(props: PropTypes) {
  const disabled = props.entry.userId !== props.currentUser.id;

  return {
    action: props.onEdit,
    buttonChild: (
      <IconSpan
        spanText={'Edit'}
        icon={{ name: 'bi-pencil', className: 'fs-4' }}
        className={disabled ? 'text-muted' : 'text-blue'}
      />
    ),
    isDisabled: disabled,
  };
}

function deleteComment(props: PropTypes) {
  const disabled = !(
    props.entry.userId === props.currentUser.id ||
    props.currentUser.isAdmin === true
  );

  return {
    action: props.onDelete,
    buttonChild: (
      <IconSpan
        spanText={'Delete'}
        icon={{ name: 'bi-trash', className: 'fs-4' }}
        className={disabled ? 'text-muted' : 'text-blue'}
      />
    ),
    isDisabled: disabled,
  };
}

export function CommentActions(props: PropTypes) {
  const menuActions = [editComment(props), deleteComment(props)];
  const allActionsDisabled = menuActions.every((action) => action.isDisabled);

  return (
    <>
      {!allActionsDisabled && (
        <Dropdown
          containerClassName='ms-2'
          buttonIcon={{
            name: 'bi-three-dots',
            className: 'fs-6 text-blue',
          }}
          buttonClassName={styles['dropdown-button']}
          menuActions={menuActions}
          menuPlacementClass={'dropdown-menu-end'}
        />
      )}
    </>
  );
}
