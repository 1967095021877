import classNames from 'classnames';
import styles from './styles.module.scss';
import { PanelHeader } from './PanelHeader';
import { PanelSubHeader } from './PanelSubHeader';
import React from 'react';

interface PropTypes {
  contextElement?: JSX.Element;
  element: JSX.Element;
  elementClassName?: string;
  title: string;
  titleClasses?: string;
  label?: string;
  classNames: string;
}

export function ElementPanel(props: PropTypes) {
  return (
    <div className={classNames(props.classNames, 'rounded-4', styles.element)}>
      <PanelHeader
        titleClasses={props.titleClasses}
        title={props.title}
        label={props.label}
      />
      <PanelSubHeader contextElement={props.contextElement} />
      <div
        className={classNames(
          'd-flex',
          'flex-column',
          'mt-3',
          'w-100',
          'gap-2',
          props.elementClassName,
        )}
      >
        {props.element}
      </div>
    </div>
  );
}
