import React from 'react';
import { InterviewKitTemplateService } from '../../../../services/v1/applicant_tracking/InterviewKitTemplateService';
import { InterviewKitTemplateList } from '../../../../entities/v1/applicant_tracking/InterviewKitTemplateList';
import {
  StartingPoint,
  StartingPointSelector,
} from '../../../survey_engine/SurveyTemplates/StartingPointSelector';

interface PropTypes {
  startingPoint: StartingPoint;
  onStartingPointChange: (value: StartingPoint) => void;
}

async function filterInterviewKitTemplates(
  interviewKitTemplates: Promise<InterviewKitTemplateList>,
  q: string,
) {
  return interviewKitTemplates.then((list) =>
    list.interviewKitTemplates
      .filter((template) =>
        template.surveyTemplate.name.toLowerCase().includes(q.toLowerCase()),
      )
      .map((template) => ({
        value: template.id.toString(),
        label: template.surveyTemplate.name,
      })),
  );
}

export function InterviewKitTemplateStartingPointSelector(props: PropTypes) {
  return (
    <StartingPointSelector
      startingPoint={props.startingPoint}
      initialState={() =>
        InterviewKitTemplateService.list({ expand: ['survey_template'] })
      }
      filterOptions={filterInterviewKitTemplates}
      onStartingPointChange={props.onStartingPointChange}
      placeholder='Select Questions Template'
    />
  );
}
