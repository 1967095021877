import React from 'react';

interface PropTypes {
  goal: string;
}

export function SessionOverviewPanel(props: PropTypes) {
  return (
    <div
      data-testid='training-session-overview'
      className='bg-light-info rounded-4 p-4'
    >
      <h2 className='mb-3'>Session Overview</h2>
      <h4 className='mb-2 fw-bold'>Goal</h4>
      <label className='text-dark-500 fs-5' style={{ whiteSpace: 'pre-line' }}>
        {props.goal}
      </label>
    </div>
  );
}
