import React from 'react';
import { CloseableModal } from '../../../../components/CloseableModal';
import { removeQueryParamFromCurrentTab } from '../../../../utils/url';
import styles from './styles.module.scss';
import classNames from 'classnames';
import modalImage from '../../../../images/candidate-experience-mailer-survey-feedback.png';
import { getLabelForCount } from '../../../../utils/grammar';
import { Chip, ChipColor } from '../../../../components/Chip';

export interface PropTypes {
  rejected: string;
  moved: string;
  skipped: string;
  onClose: () => void;
}

export function BulkApplicationReviewResultModal(props: PropTypes) {
  removeQueryParamFromCurrentTab('rejected');
  removeQueryParamFromCurrentTab('moved');
  removeQueryParamFromCurrentTab('skipped');
  const reviewedCount = parseInt(props.rejected) + parseInt(props.moved);
  const totalCount = reviewedCount + parseInt(props.skipped);

  return (
    <CloseableModal
      isOpen
      className={classNames('modal-dialog-centered', styles['modal'])}
      onClose={props.onClose}
      size='lg'
      bodyClassName='p-4'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle='Review Summary'
      bodyChildren={
        <div>
          <div className='d-flex justify-content-center'>
            <img
              src={modalImage}
              className={classNames(styles['modal-image'])}
            />
          </div>
          <div className='fs-2 fw-bold d-flex justify-content-center mb-5h'>
            Congrats! You&#39;ve reviewed {reviewedCount} of{' '}
            {getLabelForCount('Candidate', totalCount)}
          </div>
          <div className='d-flex justify-content-center mb-4 fs-semibold'>
            <Chip size='md' className='me-2' color={ChipColor.SuccessLight}>
              <span className='fw-bold'>{props.moved}</span> Moved to next Stage
            </Chip>
            <Chip size='md' className='me-2' color={ChipColor.DangerLight}>
              <span className='fw-bold'>{props.rejected}</span> Rejected
            </Chip>
            <Chip size='md' color={ChipColor.Gray}>
              <span className='fw-bold'>{props.skipped}</span> Skipped
            </Chip>
          </div>
        </div>
      }
    />
  );
}
