import classNames from 'classnames';
import React from 'react';
import { Row } from 'reactstrap';
import NoteTextEmbedded from '../../../../../../components/NoteTextEmbedded';
import { InterviewMarker } from '../../../../../../entities/InterviewMarker';
import { dateToHours } from '../../../../../../utils/timeFormat';

interface PropTypes {
  interviewMarker: InterviewMarker;
}

export function InterviewMarkerPart(props: PropTypes) {
  return (
    <Row
      key={props.interviewMarker.id}
      className={classNames('g-3', 'ms-3', 'd-flex')}
      data-controller='interview-marker'
    >
      <div className={classNames('flex-nowrap', 'mb-3', 'fs-5')}>
        <span className={classNames('me-3', 'text-dark-200')}>
          {dateToHours(props.interviewMarker.date_time)}
        </span>
        <i
          className={classNames('me-3', props.interviewMarker.icon_classes)}
        ></i>
        <NoteTextEmbedded
          text={
            props.interviewMarker.scorecard_attribute?.name ||
            props.interviewMarker.note?.text
          }
          score={props.interviewMarker.score}
        />
      </div>
    </Row>
  );
}
