import React, { useState } from 'react';
import { StyledModal } from '../../../components/StyledModal';
import { FeebackSessionBody } from './FeedbackSessionBody';
import { FeedbackSessionFooter } from './FeedbackSessionFooter';

interface PropTypes {
  visible: boolean;
  onClose: () => void;
  onSkip: () => void;
  onSubmit: (comment: string, rating: number) => void;
}

export function FeedbackSessionModal(props: PropTypes) {
  const [feedbackText, setFeedbackText] = useState<string | null>(null);
  const [activeRating, setActiveRating] = useState<number | null>(null);

  function handleSubmitFeedback() {
    // No need to send the feedback to the server
    if (!feedbackText && activeRating == null) {
      props.onClose();
    } else {
      props.onSubmit(feedbackText, activeRating);

      // Reset the feedback and rating values
      setFeedbackText(null);
      setActiveRating(null);

      props.onClose();
    }
  }

  return (
    <StyledModal
      isOpen={props.visible}
      title='Give us your feedback'
      onCancel={props.onClose}
      size={'lg'}
      bodyChildren={
        <FeebackSessionBody
          onChangeFeedbackText={setFeedbackText}
          rating={activeRating}
          onChangeRating={setActiveRating}
        />
      }
      footerChildren={
        <FeedbackSessionFooter
          submitDisabled={!feedbackText && activeRating == null}
          onSkip={props.onSkip}
          onSubmit={handleSubmitFeedback}
        />
      }
      footerClassName='justify-content-center'
    />
  );
}
