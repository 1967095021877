import React from 'react';

export function WelcomeMessage(props: { userName: string; text: string }) {
  return (
    <div className='ms-2 my-2 d-inline-flex'>
      <h4>
        Hello, {props.userName}.{' '}
        <span className='label ms-1 mb-2 pt-1'>{props.text}</span>
      </h4>
    </div>
  );
}
