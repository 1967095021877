import snakecaseKeys from 'snakecase-keys';
import { HiringMemberList } from '../../../entities/v1/HiringMemberList';
import { ApiService } from '../../ApiService';

interface Params {
  name?: string;
  page?: number;
}

export class HiringMemberService {
  public static list(params: Params): Promise<HiringMemberList> {
    const snakeCasedParams = snakecaseKeys(params);

    const stringParams: Record<string, string> = {};
    for (const key in snakeCasedParams) {
      if (snakeCasedParams[key] !== undefined) {
        stringParams[key] = String(snakeCasedParams[key]);
      }
    }

    const queryParams = new URLSearchParams(stringParams);

    queryParams.append('expand[]', 'hiring_member_settings');

    const url = `/api/v1/interview_intelligence/hiring_members?${queryParams.toString()}`;

    return ApiService.get(url);
  }
}
