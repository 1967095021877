import React from 'react';
import { InterviewKitPanel } from '../../../../components/ApplicantTracking/InterviewKitPanel';
import { InterviewKitTemplate } from '../../../../entities/applicant_tracking/InterviewKitTemplate';
import { AlertMessage } from '../../../../components/AlertMessage';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  interviewKitTemplate: InterviewKitTemplate;
}

export default function PreiewInterviewKitTemplatePage(props: PropTypes) {
  return (
    <div className='container pb-4'>
      <div className='d-flex justify-content-center mt-4 mb-4h'>
        <AlertMessage
          text='This is a preview of your interview questions'
          icon={{ name: 'bi-info-circle' }}
          className='bg-light-warning fs-5 px-3h'
          hasOverlay={false}
        />
      </div>
      <div className='d-flex justify-content-center'>
        <div className={classNames(styles['preview-container'], 'w-100')}>
          <div className='fw-bold fs-1 mt-2'>
            {props.interviewKitTemplate.name} preview
          </div>
          <InterviewKitPanel sections={props.interviewKitTemplate.sections} />
        </div>
      </div>
    </div>
  );
}
