import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Interview } from '../../../../entities/Interview';
import { Monologue } from '../../../../entities/v1/interview_intelligence/Monologue';
import { Participant } from '../../../../entities/v1/interview_intelligence/Participant';
import { Transcript } from '../../../../entities/v1/interview_intelligence/Transcript';
import { useSpeakers } from '../../../../queries/v1/interview_intelligence/useSpeakers';
import { useTranscript } from '../../../../queries/v1/interview_intelligence/useTranscript';
import { EmptyState } from '../../../EmptyState';
import { MonologueBlock } from '../../MonologueBlock';

interface PropTypes {
  autoScroll: boolean;
  playTimestamp: number;
  interview: Interview;
  findString: string;
  activeMonologueOffset: number;
  selectedSearchOccurence: { monologue: number; mark: number; global: number };
  onSelectTimestamp?: (timestamp: number) => void;
  onUpdateMonologueSearchOccurences?: (index: number, count: number) => void;
}

interface MonologuesUIPropTypes {
  speakers: Participant[];
  transcriptWithTokens: Transcript;
  isLoading: boolean;
  autoScroll: boolean;
  playTimestamp: number;
  interview: Interview;
  findString: string;
  activeMonologueOffset: number;
  selectedSearchOccurence: { monologue: number; mark: number; global: number };
  onSelectTimestamp?: (timestamp: number) => void;
  onUpdateMonologueSearchOccurences?: (index: number, count: number) => void;
}

const EMPTY_STATE_NUMBER_MONOLOGUES = 8;

const findActiveMonologue = (monologues: Monologue[], time: number): number => {
  let activeIndex = -1;

  for (const index in monologues) {
    const monologue = monologues[index];
    if (monologue.startOffsetMs >= time) break;
    activeIndex = parseInt(index, 10);
  }
  return activeIndex;
};

function emptyMonologueWidth(index: number, total: number): string {
  const widthPerc = ((total - index) / total) * 100;

  return `${widthPerc}%`;
}

function EmptyMonologues() {
  const totalMonologues = EMPTY_STATE_NUMBER_MONOLOGUES;

  return (
    <>
      {[...Array(totalMonologues / 2)].map((_, i) => (
        <div key={i} className='mb-3'>
          <Skeleton width={emptyMonologueWidth(i, totalMonologues)} />
          <Skeleton width={emptyMonologueWidth(i + 1, totalMonologues)} />
        </div>
      ))}
    </>
  );
}

export function Monologues(props: PropTypes) {
  const { isLoading: isLoadingSpeakers, data: speakerList } = useSpeakers(
    props.interview.id,
  );
  const { isLoading: isLoadingTranscript, data: transcript } = useTranscript(
    props.interview.id,
    ['tokens'],
  );

  const speakers = speakerList?.participants;

  const isLoading = isLoadingSpeakers || isLoadingTranscript;

  return (
    <MonologuesUI
      {...props}
      speakers={speakers}
      transcriptWithTokens={transcript}
      isLoading={isLoading}
    />
  );
}

export function MonologuesUI(props: MonologuesUIPropTypes) {
  if (props.isLoading) return <EmptyMonologues />;

  if (props.transcriptWithTokens.monologues.length === 0) {
    return (
      <EmptyState
        title={'No transcript available yet'}
        titleClassName={'text-center fs-3'}
        text={
          'We are processing your interview transcript. Please check back later.'
        }
        textFontSize={'fs-5'}
        excludeImage={true}
        cardless={true}
      />
    );
  }

  const monologues = props.transcriptWithTokens.monologues.sort(
    (a, b) => a.startOffsetMs - b.startOffsetMs,
  );

  const activeIndex = findActiveMonologue(monologues, props.playTimestamp);

  return (
    <div className='py-3'>
      {monologues.map((monologue, i) => (
        <MonologueBlock
          key={i}
          index={i}
          playTimestamp={i == activeIndex ? props.playTimestamp : undefined}
          autoScroll={props.autoScroll}
          speakers={props.speakers}
          monologue={monologue}
          findString={props.findString}
          activeMonologueOffset={props.activeMonologueOffset}
          selectedSearchOccurence={props.selectedSearchOccurence}
          onSelectTimestamp={props.onSelectTimestamp}
          onUpdateMonologueSearchOccurences={
            props.onUpdateMonologueSearchOccurences
          }
        />
      ))}
    </div>
  );
}
