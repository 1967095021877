import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  classNames?: string;
}

export function HorizontalDivider(props: PropTypes) {
  return (
    <span
      className={classNames(props.classNames, 'd-flex w-100 border-bottom')}
    />
  );
}
