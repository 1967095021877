import React, { useState } from 'react';
import { InterviewQuestion } from '../../../entities/InterviewQuestion';
import { InterviewSidekick } from '../../../entities/InterviewSidekick';
import { ScorecardAttribute } from '../../../entities/ScorecardAttribute';
import { SidekickTab } from './SidekickTab';
import { SidekickService } from '../../../services/SidekickService';
import { CandidateAssessmentPage } from './CandidateAssessmentPage';
import { SidekickNavBar } from './SidekickNavBar';

interface PropTypes {
  interview: InterviewSidekick;
  supportsInterviewQuestions: boolean;
  interviewRatingEnabled: boolean;
}

export function SidekickBody(props: PropTypes) {
  const [interview, setInterview] = useState(props.interview);
  const [activeTab, setActiveTab] = useState(SidekickTab.Assessment);

  async function handleInterviewQuestionsUpdated(question: InterviewQuestion) {
    const updatedInterview = await SidekickService.updateInterviewQuestion(
      props.interview.id,
      question.id,
    );

    setInterview(updatedInterview);
  }

  async function handleScorecardAttributesUpdated(
    attribute: ScorecardAttribute,
    score?: number,
  ) {
    const updatedInterview = await SidekickService.updateScorecardAttribute(
      props.interview.id,
      attribute.id,
      score,
    );

    setInterview(updatedInterview);
  }

  async function handleInterviewMarkerCreated(kind: string, note?: string) {
    const updatedInterview = await SidekickService.createInterviewMarker(
      props.interview.id,
      kind,
      note,
    );

    setInterview(updatedInterview);
  }

  return props.supportsInterviewQuestions ? (
    <SidekickNavBar
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      interview={interview}
      interviewRatingEnabled={props.interviewRatingEnabled}
      onScorecardAttributesUpdated={handleScorecardAttributesUpdated}
      onInterviewQuestionsUpdated={handleInterviewQuestionsUpdated}
      onInterviewMarkerCreated={handleInterviewMarkerCreated}
    />
  ) : (
    <CandidateAssessmentPage
      interview={interview}
      interviewRatingEnabled={props.interviewRatingEnabled}
      onScorecardAttributesUpdated={handleScorecardAttributesUpdated}
      onInterviewMarkerCreated={handleInterviewMarkerCreated}
    />
  );
}
