import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { formatDateToDDMMYYYY } from '../../../utils/timeFormat';

type ChildElement = JSX.Element | string;

interface PropTypes {
  body: ChildElement | ChildElement[];
  score: number;
  updatedAt?: Date;
  jobName?: string;
  bgColorScore?: string;
}

export function NotePill(props: PropTypes) {
  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div
          className={classNames(
            styles['answer-pill'],
            'bg-note',
            'p-3',
            'w-100',
          )}
        >
          “{props.body}”
        </div>
        <div
          className={classNames(
            'mx-3',
            styles['score-pill'],
            `bg-${props.bgColorScore}`,
          )}
        >
          {props.score}
        </div>
      </div>
      <div
        className={classNames(
          'fs-6',
          'text-gray-900',
          'd-flex',
          'justify-content-between',
          'mt-2',
          'px-2h',
        )}
      >
        {props.jobName && (
          <span className={classNames('mx-1')}>{props.jobName}</span>
        )}
        {props.updatedAt && (
          <span className={classNames('mx-6')}>
            {formatDateToDDMMYYYY(props.updatedAt)}
          </span>
        )}
      </div>
    </div>
  );
}
