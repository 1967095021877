import React from 'react';
import { CloseableModal } from '../../../../../../components/CloseableModal';
import { User } from '../../../../../../entities/User';
import { BulkSelfSchedulingDto } from '../../../../../../services/dtos/BulkSelfSchedulingDto';
import { redirectWithAlert } from '../../../../../../services/FlashService';
import { getRedirectUriQueryParam } from '../../../../../../utils/url';
import { AlertObject, AlertType } from '../../../../../../components/Alert';
import { getLabelForCount } from '../../../../../../utils/grammar';
import { SendEmailModal } from '../../../../../SendEmailModal';
import { SendEmailDto } from '../../../../../../services/dtos/EmailDto';
import { InterviewCandidateUserService } from '../../../../../../services/applicant_tracking/InterviewCandidateUserService';
import { popSessionSelfSchedulingDto } from '../../../../../../utils/applicant_tracking/buildSelfScheduling';

interface PropTypes {
  bulkSelfSchedulingDto: BulkSelfSchedulingDto;
  currentUser: User;
  isOpen: boolean;
  onClose: () => void;
  setAlert: (o: AlertObject) => void;
}

function buildWarningDescription(
  bulkSelfSchedulingDto: BulkSelfSchedulingDto,
): string {
  const selectedApplicationCount = bulkSelfSchedulingDto.applicationIds.length;
  const createdSelfSchedulingLinksCount =
    bulkSelfSchedulingDto.applicationInterviewCandidateUsers.length;
  const allSelfSchedulingLinksCreated =
    selectedApplicationCount == createdSelfSchedulingLinksCount;

  if (allSelfSchedulingLinksCreated) return null;

  return `Individual emails will be sent to ${createdSelfSchedulingLinksCount} out of ${selectedApplicationCount} candidates. 
    Remaining candidates were already invited.`;
}

export function BulkSendEmailModal(props: PropTypes) {
  const onSendEmail = async (sendEmailDto: SendEmailDto) => {
    await InterviewCandidateUserService.bulkSendSelfScheduleEmail(
      props.bulkSelfSchedulingDto.applicationInterviewCandidateUsers.map(
        (icu) => icu.interviewCandidateUserId,
      ),
      sendEmailDto,
    )
      .then(() => {
        popSessionSelfSchedulingDto();

        redirectWithAlert(
          getRedirectUriQueryParam() ?? '/candidates',
          `The Self-scheduling link has been successfully sent to ${getLabelForCount(
            'Candidate',
            props.bulkSelfSchedulingDto.applicationInterviewCandidateUsers
              .length,
          )}`,
          AlertType.Success,
        );
      })
      .catch(() => {
        props.setAlert({
          message: 'The email could not be sent to the candidates.',
          type: AlertType.Danger,
        });
      })
      .finally(() => props.onClose());
  };

  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={props.onClose}
      isOpen={props.isOpen}
      size='md'
      bodyClassName='p-4 pb-6'
      closeDisabled={false}
      backdrop={true}
      headerTitle='Send Self-Scheduling Link'
      bodyChildren={
        <SendEmailModal
          applicationIds={props.bulkSelfSchedulingDto.applicationInterviewCandidateUsers.map(
            (c) => c.applicationId,
          )}
          hasEmailConnected={props.currentUser.hasEmailConnected}
          isOpen={props.isOpen}
          onCloseModal={props.onClose}
          onSendEmail={onSendEmail}
          warningDescription={buildWarningDescription(
            props.bulkSelfSchedulingDto,
          )}
        />
      }
    />
  );
}
