import React, { useCallback } from 'react';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { JobService } from '../../../../../services/applicant_tracking/JobService';

interface PropTypes {
  jobId: number;
  jobName: string;
  archiveAction: 'Archive' | 'Unarchive';
  isModalOpen: boolean;
  redirectUri: string;
  setIsModalOpen: (b: boolean) => void;
}

function redirectTo(path: string, redirectUri?: string) {
  const baseUrl = `/applicant_tracking/jobs/${path}/?`;
  const redirectUrlParam = redirectUri && `redirect_uri=${redirectUri}&`;

  window.location.href = baseUrl + redirectUrlParam;
}

export function ArchiveJobConfirmationModal(props: PropTypes) {
  const onArchivingJob = useCallback(async () => {
    try {
      await JobService.archive(props.jobId);
      redirectTo('archive_success', props.redirectUri);
    } catch (e) {
      redirectTo('archive_error', props.redirectUri);
    }
    props.setIsModalOpen(false);
  }, [props.jobId]);

  const onUnarchivingJob = useCallback(async () => {
    try {
      await JobService.unarchive(props.jobId);
      redirectTo('unarchive_success', props.redirectUri);
    } catch (e) {
      redirectTo('unarchive_error', props.redirectUri);
    }
    props.setIsModalOpen(false);
  }, [props.jobId]);

  return (
    <ConfirmationModal
      title={`${props.archiveAction} Job`}
      body={
        <p>
          Are you sure you want to {props.archiveAction.toLowerCase()} the{' '}
          {props.jobName} job?
        </p>
      }
      isOpen={props.isModalOpen}
      onConfirm={
        props.archiveAction === 'Archive' ? onArchivingJob : onUnarchivingJob
      }
      onCancel={() => props.setIsModalOpen(false)}
      size={'md'}
      confirmText={props.archiveAction}
      disableAfterConfirm={false}
    />
  );
}
