import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { TitledPanel } from '../TitledPanel';
import { CardTitle } from '../../../../../components/Card';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { ApplicationsCountHistory } from '../../../../../entities/applicant_tracking/analytics/ApplicationsCountHistory';
import { ApplicationsCountHistoryService } from '../../../../../services/applicant_tracking/analytics/ApplicationsCountHistoryService';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { shortFormatDate } from '../../../../../utils/timeFormat';
import { ActiveAndHiredBarChart } from '../../../../../components/ActiveAndHiredBarChart';
import { FilterState } from '../../Filters';
import { WidgetEmptyStateContent } from '../../../../survey_engine/WidgetEmptyStateContent';

interface PropTypes {
  filterState?: FilterState;
  exportToPDF?: boolean;
  height: string;
  setReportHasData?: (reportHasData: boolean) => void;
}

interface LazyContentPropTypes {
  filterState?: FilterState;
  exportToPDF?: boolean;
  setReportHasData?: (reportHasData: boolean) => void;
}

interface ContentPropTypes {
  data: ApplicationsCountHistory;
  exportToPDF: boolean;
}

export function ApplicationsCountHistoryWidget(props: PropTypes) {
  return (
    <TitledPanel
      title={<Title exportToPDF={props.exportToPDF} />}
      height={props.height}
    >
      <LazyContent
        filterState={props.filterState}
        exportToPDF={props.exportToPDF ?? false}
        setReportHasData={props.setReportHasData}
      />
    </TitledPanel>
  );
}

function Title(props: { exportToPDF?: boolean }) {
  return (
    <div className='hstack gap-2'>
      <div className='hstack gap-2 me-auto'>
        <CardTitle>History</CardTitle>
        {props.exportToPDF !== true && (
          <InfoTooltip>
            Weekly hired vs active applicants comparison.
          </InfoTooltip>
        )}
      </div>
      <div className='d-flex gap-3'>
        <Legend iconClassName='bg-green-500'>Active Candidates</Legend>
        <Legend iconClassName='bg-blue-500'>Hires</Legend>
      </div>
    </div>
  );
}

function Legend(props: { iconClassName: string; children: ReactNode }) {
  return (
    <div className='hstack gap-2'>
      <span
        style={{ width: '14px', height: '14px' }}
        className={classNames('rounded', props.iconClassName)}
      />
      <span className='fs-6 fw-normal'>{props.children}</span>
    </div>
  );
}

function sumDateChunkValue(
  grouppedData: { from: Date; to: Date; value: number }[],
): number {
  // Sum all 'value' elements.
  return grouppedData
    .map((v) => v['value'])
    .filter((v) => v > 0)
    .reduce((a, b) => a + b, 0);
}

function isWidgetEmpty(history: ApplicationsCountHistory): boolean {
  if (history.hiredCount > 0) return false;

  if (sumDateChunkValue(history.activePerInterval) > 0) return false;

  if (sumDateChunkValue(history.hiredPerInterval) > 0) return false;

  return true;
}

function LazyContent(props: LazyContentPropTypes) {
  const [data, setData] = useState<ApplicationsCountHistory>(null);

  useEffect(() => {
    if (props.filterState == null) return;

    ApplicationsCountHistoryService.show(props.filterState).then((history) => {
      if (!isWidgetEmpty(history)) {
        props.setReportHasData?.(true);
      }

      setData(history);
    });
  }, [props.filterState]);

  if (!data) return <LoadingSpinner />;

  return <Content data={data} exportToPDF={props.exportToPDF} />;
}

function Content(props: ContentPropTypes) {
  const labels = props.data.hiredPerInterval.map((history) => {
    if (history.from === history.to) {
      return shortFormatDate(history.from);
    }

    return `${shortFormatDate(history.from)} - ${shortFormatDate(history.to)}`;
  });
  const activeValues = props.data.activePerInterval.map(
    (history) => history.value,
  );
  const hiredValues = props.data.hiredPerInterval.map(
    (history) => history.value,
  );

  const max = activeValues.reduce((a, b) => Math.max(a, b), -Infinity);

  if (max === 0) {
    return <WidgetEmptyStateContent />;
  }

  return (
    <div className='vstack gap-2'>
      <div>
        <h2 className='mb-0'>
          {props.data.hiredCount === 0 ? 'No' : props.data.hiredCount}{' '}
          {props.data.hiredCount === 1 ? 'Hire' : 'Hires'}
        </h2>
      </div>
      <div className='h-100'>
        <ActiveAndHiredBarChart
          labels={labels}
          activeValues={activeValues}
          hiredValues={hiredValues}
          classNames={classNames({ 'w-100': props.exportToPDF })}
        />
      </div>
    </div>
  );
}
