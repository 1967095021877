import React from 'react';
import { IconButton } from '../../../../components/IconButton';
import { Size } from '../../../../utils/types/Size';
import { Button } from 'reactstrap';

interface PropTypes {
  icon?: string;
  url: string;
  text: string;
  className?: string;
  openInNewTab?: boolean;
  size?: Size;
}

function onClick(url: string, openInNewTab?: boolean): () => void {
  if (openInNewTab) {
    return () => window.open(url, '_blank', 'noopener,noreferrer');
  }

  return () => (window.location.href = url);
}

export function ActivityButton(props: PropTypes) {
  return props.icon ? (
    <IconButton
      buttonText={props.text}
      buttonClass={props.className}
      size={props.size}
      color='borderless'
      onClick={onClick(props.url, props.openInNewTab)}
      iconName={props.icon}
    />
  ) : (
    <Button
      size={props.size}
      color='borderless'
      className={props.className}
      onClick={onClick(props.url, props.openInNewTab)}
    >
      {props.text}
    </Button>
  );
}
