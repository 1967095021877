import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';

export function FeedbackSessionFooter(props: {
  submitDisabled: boolean;
  onSkip: () => void;
  onSubmit: () => void;
}) {
  return (
    <>
      <Button
        outline
        className={classNames('px-2h', 'fw-normal', 'py-2', 'btn-secondary')}
        onClick={props.onSkip}
      >
        Skip
      </Button>
      <Button
        color='primary'
        disabled={props.submitDisabled}
        onClick={props.onSubmit}
      >
        Submit
      </Button>
    </>
  );
}
