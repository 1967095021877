import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';

export interface PropTypes {
  initialMaxElements: number;
  maxElements: number;
  setMaxElements: (maxElements: number) => void;
  enabledViewLess: boolean;
  setEnabledViewLess: (enabledViewLess: boolean) => void;
  showBorder?: boolean;
  elementNamePlural: string;
  elementsLength: number;
  totalVisibleElements: number;
}

function ButtonWithProps(props: {
  className?: string;
  onClick: () => void;
  text: string;
  elementNamePlural: string;
  showBorder: boolean;
  iconName: string;
}) {
  return (
    <Button
      color={props.showBorder ? 'secondary' : 'borderless'}
      className={props.className}
      size={'md'}
      type={'button'}
      onClick={props.onClick}
    >
      {`${props.text} ${
        props.elementNamePlural ? props.elementNamePlural : ''
      }`}
    </Button>
  );
}

export function ExpandButton(props: PropTypes) {
  if (Math.ceil(props.elementsLength / 2) <= props.initialMaxElements) {
    return null;
  }

  const handleViewMoreClick = () => {
    props.setMaxElements(props.maxElements + props.initialMaxElements);
    props.setEnabledViewLess(true);
  };

  const handleViewLessClick = () => {
    props.setMaxElements(props.initialMaxElements);
    props.setEnabledViewLess(false);
  };

  return (
    <div
      className={classNames(
        'd-flex',
        'w-100',
        'mt-4',
        'justify-content-center',
      )}
    >
      {props.elementsLength > props.totalVisibleElements && (
        <ButtonWithProps
          className='me-3'
          showBorder={props.showBorder}
          onClick={handleViewMoreClick}
          text='View more'
          elementNamePlural={props.elementNamePlural}
          iconName='view-stacked'
        />
      )}
      {props.enabledViewLess && (
        <ButtonWithProps
          showBorder={props.showBorder}
          onClick={handleViewLessClick}
          text='View less'
          elementNamePlural={props.elementNamePlural}
          iconName='view-list'
        />
      )}
    </div>
  );
}
