import { ApiService } from '../../ApiService';
import { InterviewKitTemplate } from '../../../entities/applicant_tracking/InterviewKitTemplate';

export class InterviewKitTemplateService {
  public static list(): Promise<InterviewKitTemplate[]> {
    return ApiService.get<InterviewKitTemplate[]>(
      '/api/applicant_tracking/interview_kit_templates',
    );
  }
}
