import classNames from 'classnames';
import React from 'react';
import { HiringMember } from '../../../../../entities/HiringMember';
import { LabelledAvatar } from '../../../../../components/LabelledAvatar';

interface PropTypes {
  members: HiringMember[];
  title: string;
  bgColor?: string;
  paddingBottomValue?: number;
  classNames?: string;
}

export function HiringMembersTab(props: PropTypes) {
  return (
    <>
      {props.members.length > 0 && (
        <div
          className={classNames(
            'my-2',
            'px-3',
            'py-2',
            'rounded-3',
            props.bgColor,
            props.classNames,
          )}
        >
          <div className='mb-2'>{props.title}</div>
          {props.members.map((member, index) => (
            <div
              className={classNames(
                'd-flex align-items-center mb-2 text-truncate',
                `pb-${props.paddingBottomValue || 1}`,
              )}
              key={index}
            >
              <LabelledAvatar
                type={member.userPhotoUrl ? 'photo' : 'initials'}
                name={member.userPhotoUrl || member.name}
                label={member.name}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
}
