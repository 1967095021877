import React from 'react';
import styles from './styles.module.scss';
import stylesheetExports from '../../stylesheets/export.module.scss';
import { computeInitials } from '../../utils/computeInitials';
import { Icon } from '../Icon';
import classNames from 'classnames';
import { Size } from '../../utils/types/Size';

export interface PropTypes {
  name?: string;
  size?: Size;
  number?: number;
  className?: string;
  avatarClassName?: string; // Not available if the type is 'initials'.
  type?: 'initials' | 'photo' | 'icon';
  sideAvatarImg?: string;
  sideAvatarUrl?: string;
  disabled?: boolean;
}

const colors = [
  stylesheetExports.pink500,
  stylesheetExports.blueGray900,
  stylesheetExports.blue,
  stylesheetExports.orange,
  stylesheetExports.dark200,
  stylesheetExports.green900,
];

function getColorByInitials(initials: string) {
  const index = initials.charCodeAt(0) % colors.length;
  return colors[index];
}

function getWidth(size: Size) {
  switch (size) {
    case 'sm':
      return '20px';
    case 'md':
      return '24px';
    case 'lg':
      return '32px';
    case 'xl':
      return '48px';
  }
}

function getSideIconWidth(size: Size) {
  switch (size) {
    case 'sm':
      return '6px';
    case 'md':
      return '10px';
    case 'lg':
      return '14px';
    case 'xl':
      return '18px';
  }
}

function getSideIconDistance(size: Size) {
  switch (size) {
    case 'sm':
      return '-2px';
    case 'md':
      return '-3px';
    case 'lg':
      return '-4px';
    case 'xl':
      return '-5px';
  }
}

// Should be coherent with the app/helpers/avatars_helper.rb component
function AvatarInitials(props: {
  size: Size;
  name: string;
  disabled?: boolean;
}) {
  const initials = computeInitials(props.name);

  return (
    <svg className='avatar' viewBox='0 0 100 100'>
      <rect
        x='0'
        y='0'
        width='100'
        height='100'
        fill={
          props.disabled ? stylesheetExports.gray : getColorByInitials(initials)
        }
      />
      <text
        x='50%'
        y='50%'
        fill={props.disabled ? stylesheetExports.gray700 : '#ffffff'}
        fontSize='36'
        fontWeight='600'
        textAnchor='middle'
        dominantBaseline='central'
      >
        {initials}
      </text>
    </svg>
  );
}

function AvatarType(props: PropTypes) {
  const type = props.type || 'initials';
  const width = getWidth(props.size);

  switch (type) {
    case 'photo':
      return (
        <img
          className={classNames(
            'avatar',
            props.avatarClassName,
            props.disabled && styles['avatar-disabled'],
          )}
          src={props.name}
        />
      );
    case 'icon':
      return (
        <Icon
          style={{ height: width, lineHeight: width }}
          className={classNames(
            'avatar',
            'text-center',
            props.avatarClassName,
            props.disabled && styles['avatar-disabled'],
          )}
          name={props.name}
        />
      );
    case 'initials':
    default:
      return (
        <AvatarInitials
          size={props.size}
          name={props.name}
          disabled={props.disabled}
        />
      );
  }
}

function SideAvatar(props: { size: Size; img?: string; url?: string }) {
  if (!props.img) return null;

  const sideIconWidth = getSideIconWidth(props.size);
  const sideIconDistance = getSideIconDistance(props.size);

  const imgNode = (
    <img
      className={classNames('avatar')}
      src={props.img}
      style={{
        width: sideIconWidth,
        position: 'absolute',
        bottom: sideIconDistance,
        right: sideIconDistance,
      }}
    />
  );

  if (props.url) {
    return (
      <a href={props.url} target='_blank' rel='noreferrer'>
        {imgNode}
      </a>
    );
  }

  return imgNode;
}

export function Avatar(props: PropTypes) {
  const size = props.size || 'md';
  const width = getWidth(size);

  return (
    <span
      className={classNames('d-flex align-items-center me-2', props.className)}
      style={{
        width: width,
        minWidth: width,
        maxWidth: width,
        position: 'relative',
      }}
    >
      <AvatarType {...props} size={size} />
      <SideAvatar
        size={size}
        img={props.sideAvatarImg}
        url={props.sideAvatarUrl}
      />
    </span>
  );
}
