import React from 'react';
import { ApplicationSidekick } from '../../entities/ApplicationSidekick';
import { InterviewSidekick } from '../../entities/InterviewSidekick';
import { SidekickBody } from './SidekickBody';
import { SidekickHeader } from './SidekickHeader';

interface PropTypes {
  application: ApplicationSidekick;
  interview: InterviewSidekick;
  supportsInterviewQuestions: boolean;
  interviewRatingEnabled: boolean;
}

export default function SidekickPage(props: PropTypes) {
  return (
    <div className='app p-0 m-0 d-flex flex-column vh-100'>
      <SidekickHeader application={props.application} />
      <SidekickBody
        interview={props.interview}
        supportsInterviewQuestions={props.supportsInterviewQuestions}
        interviewRatingEnabled={props.interviewRatingEnabled}
      />
    </div>
  );
}
