import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { Medium } from '../../../../../entities/training/Medium';
import { SubSectionImage } from './SubSectionImage';
import { SubSectionVideo } from './SubSectionVideo';

interface PropTypes {
  medium: Medium;
}

export function SubSectionMedium(props: PropTypes) {
  return props.medium.type == 'video' ? (
    <Row
      className={classNames(
        'border',
        'rounded-4',
        'bg-primary',
        'mt-4',
        'mx-0',
      )}
    >
      <Col>
        <SubSectionVideo videoUrl={props.medium.url} />
      </Col>
    </Row>
  ) : (
    <SubSectionImage
      imageUrl={props.medium.url}
      imageDescription={props.medium.description}
    />
  );
}
