import { RejectReason } from '../../../entities/applicant_tracking/RejectReason';
import { ApiService } from '../../ApiService';

export class RejectReasonService {
  public static list(): Promise<RejectReason[]> {
    return ApiService.get<RejectReason[]>(
      '/api/applicant_tracking/reject_reasons',
    );
  }
}
