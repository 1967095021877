import moment, { MomentBuiltinFormat } from 'moment';
import { Moment } from 'moment';

export function dateStrToMoment(
  date?: string,
  format?: MomentBuiltinFormat,
): Moment {
  if (date == null) {
    return null;
  }

  return moment(date, format ?? moment.ISO_8601, true);
}
