import React from 'react';
import { Nps } from '../../../../entities/survey_engine/Nps';
import { SpanTooltip } from '../../../SpanTooltip';
import { AnalyticsPanel } from '../../../AnalyticsPanel';
import { OverallNpsContent } from './OverallNpsContent';
import { LazyContent } from '../../LazyContent';
import { CardTitle } from '../../../Card';

interface PropTypes {
  nps?: Nps;
  npsTrend?: number;
  title?: string;
  detractorsText: string;
  neutralsText: string;
  promotersText: string;
  tooltipText: JSX.Element;
  standardNpsCalculation?: boolean;
  hasCandidatePulseCnps?: boolean;
}

export function OverallNps(props: PropTypes) {
  return (
    <AnalyticsPanel
      title={
        <div>
          <div className='mb-0 fw-bold d-inline-flex align-items-center'>
            <CardTitle>{props.title}</CardTitle>
            <SpanTooltip
              className='ms-2 text-info'
              placement='right'
              text={<i className='bi bi-info-circle'></i>}
              tooltipText={props.tooltipText}
              tooltipStyle={{ maxWidth: '250px' }}
            />
          </div>
        </div>
      }
      subtitle={null}
      marginTop={props.standardNpsCalculation ? 'mt-0' : 'mt-3'}
      collapseClassName={'h-100 d-flex'}
    >
      <LazyContent
        isLoading={props.nps == null}
        isEmpty={props.nps?.value == null}
      >
        <OverallNpsContent
          nps={props.nps}
          npsTrend={props.npsTrend}
          detractorsText={props.detractorsText}
          neutralsText={props.neutralsText}
          promotersText={props.promotersText}
          standardNpsCalculation={props.standardNpsCalculation}
          hasCandidatePulseCnps={props.hasCandidatePulseCnps}
        />
      </LazyContent>
    </AnalyticsPanel>
  );
}
