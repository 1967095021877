import React from 'react';
import { SpanTooltip } from '../../../../../../../components/SpanTooltip';
import { ScorecardAttribute } from '../../../../../../../entities/ScorecardAttribute';

interface PropTypes {
  activeAttribute: ScorecardAttribute;
}

export function ScorecardAttributeScoreSuccess(props: PropTypes) {
  return (
    <>
      <i className='text-success bi bi-check2-circle me-2'></i>
      <SpanTooltip
        tooltipText={`The attribute ${props.activeAttribute.name} was rated with ${props.activeAttribute.score}`}
        className='text-truncate'
        text={
          <span>
            The attribute <b>{props.activeAttribute.name}</b> was rated with{' '}
            {props.activeAttribute.score}
          </span>
        }
      />
    </>
  );
}
