import React from 'react';
import { Col, Row } from 'reactstrap';
import { NavLinkTabs } from '../../../components/NavLinkTabs';
import { SelectOption } from '../../../components/Select';
import { FilterStates, ListCandidatesFilterTab } from '../FilterStates';
import { humanize } from '../../../utils/humanize';
import { JobSelect } from '../../Filters/JobSelect';
import { JobStageSelect } from '../../Filters/JobStageSelect';

interface PropTypes {
  filterStates: FilterStates;
  disabled: boolean;
  onFilterStatesUpdate: (fs: FilterStates) => void;
}

function RightFilters(props: PropTypes) {
  return (
    <Row className='col-7 justify-content-end'>
      <Col xs={4}>
        <JobSelect
          disabled={props.disabled}
          selected={props.filterStates.job ?? null}
          onChange={(opt: SelectOption) => {
            if (props.filterStates.job?.value !== opt?.value) {
              props.onFilterStatesUpdate({
                ...props.filterStates,
                job: opt,
                page: 1,
              });
            }
          }}
        />
      </Col>
      <Col xs={4}>
        <JobStageSelect
          disabled={props.disabled}
          selected={props.filterStates.jobStage ?? null}
          onChange={(opt: SelectOption) => {
            if (props.filterStates.jobStage?.value !== opt?.value) {
              props.onFilterStatesUpdate({
                ...props.filterStates,
                jobStage: opt,
                page: 1,
              });
            }
          }}
        />
      </Col>
    </Row>
  );
}

export function Filters(props: PropTypes) {
  return (
    <>
      <div className='d-flex justify-content-between px-4 pt-4'>
        <NavLinkTabs<ListCandidatesFilterTab>
          disabled={props.disabled}
          navigationId={'candidates-table-tab'}
          activeTab={props.filterStates.tab ?? 'all'}
          setActiveTab={(at) => {
            if (props.filterStates.tab !== at) {
              props.onFilterStatesUpdate({
                ...props.filterStates,
                tab: at,
                page: 1,
              });
            }
          }}
          tabTypeMapping={['all', 'active', 'hired', 'rejected'].map(
            (tab: ListCandidatesFilterTab) => ({
              text: humanize(tab),
              tab: tab,
            }),
          )}
        />
        <RightFilters {...props} />
      </div>
    </>
  );
}
