import classNames from 'classnames';
import React from 'react';
import { Row, Col } from 'reactstrap';
import { NpsBreakdownMetric } from './NpsBreakdownMetric';
import { Nps } from '../../../../entities/survey_engine/Nps';
import { AnalyticsPanel } from '../../../../components/AnalyticsPanel';
import { EmptyState } from '../../../../components/EmptyState';
import { DateRange } from '../../../../components/DateRangePicker';
import { buildUrl } from '../../../../utils/url';
import { BenchmarkType } from '../../../../entities/candidate_experience/BenchmarkType';
import { Card } from '../../../../components/Card';
import { IconButton } from '../../../../components/IconButton';
import { OverallNpsCharts } from '../../../../components/survey_engine/OverallNpsPanels/OverallNps/OverallNpsCharts';

interface PropTypes {
  benchmarkValue: number;
  jobName: string;
  nps: Nps;
  npsTrend: number;
  standardNpsCalculation: boolean;
}

const DEFALT_FILTER_DAYS = DateRange.Last90Days;
const DEFALT_BENCHMARK_TYPE = BenchmarkType.BestPractice;

function buildPulseDashboardUrl(jobName: string) {
  return buildUrl(
    `${window.location.origin}/analytics/candidate_experience/overview`,
    [
      { key: 'benchmarkType', value: DEFALT_BENCHMARK_TYPE },
      { key: 'dateRange', value: DEFALT_FILTER_DAYS },
      { key: 'filter_selector', value: 'jobs' },
      { key: 'jobs', value: jobName },
    ],
  );
}

export function CNPSPanel(props: PropTypes) {
  return (
    <div className='my-2 px-0'>
      {props.nps?.value ? (
        <AnalyticsPanel
          title={
            <div className='d-flex mt-2'>
              <div className={classNames('mb-0', 'fw-bold')}>CNPS</div>
              <div className='ms-auto' data-testid='benchmark-cps-history'>
                <small className='d-block text-muted'>Company AVG</small>
                <div className='text-end'>
                  <span className='me-1 text-green-500'>
                    <i className='bi bi-activity'></i>
                  </span>
                  <span className='fw-semibold d-inline-block mb-0'>
                    {props.benchmarkValue
                      ? Number(props.benchmarkValue).toFixed(1)
                      : 'N/A'}
                  </span>
                </div>
              </div>
            </div>
          }
          marginTop={'mt-3'}
        >
          <div className='flex-grow-1 d-flex'>
            <Row>
              <Col
                xs={8}
                className='d-flex mx-auto justify-content-center align-items-center mb-3'
              >
                <OverallNpsCharts
                  nps={props.nps}
                  standardNpsCalculation={props.standardNpsCalculation}
                />
              </Col>
              <Col
                xs={12}
                className='d-flex flex-column text-blue-gray-900 fs-6 px-4'
              >
                <div className='row row-cols-3'>
                  <NpsBreakdownMetric
                    label='Detractors'
                    value={props.nps.detractors}
                  />
                  <NpsBreakdownMetric
                    label='Neutrals'
                    value={props.nps.neutrals}
                  />
                  <NpsBreakdownMetric
                    label='Promoters'
                    value={props.nps.promoters}
                  />
                </div>
              </Col>
              <Col className='d-flex justify-content-center mt-3' xs={12}>
                <IconButton
                  buttonText='View Pulse Dashboard'
                  color='borderless'
                  iconName='bi-clipboard-data'
                  onClick={() =>
                    (window.location.href = buildPulseDashboardUrl(
                      props.jobName,
                    ))
                  }
                />
              </Col>
            </Row>
          </div>
        </AnalyticsPanel>
      ) : (
        <Card className='p-3 my-2'>
          <div className='fs-3 fw-semibold mb-2 p-2'>CNPS</div>
          <EmptyState
            title='No candidate feedback available yet'
            text='Talk with us to start collecting candidate feedback in every step of the way.'
            excludeImage={true}
            titleClassName='fs-3'
            textFontSize='fs-6'
          />
        </Card>
      )}
    </div>
  );
}
