import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { Icon } from '../../../../components/Icon';
import { Action } from '../dispatcher';
import classNames from 'classnames';
import { IconButton } from '../../../../components/IconButton';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { TypeCol } from './TypeCol';
import { TextCol } from './TextCol';
import { FieldType } from '../../../../entities/v1/survey_engine/Question';
import { OptionList } from '../OptionList';
import { FieldOptionData } from '../FieldOption';
import { MandatoryCol } from './MandatoryCol';

/** Uniquely identifies a SurveyEngine related entity client-side. */
export type ClientId = number;

export interface FieldConfig {
  label: string;
  fieldType: FieldType;
  max?: number;
  tag?: string;
  group?: string;
  removable?: boolean;
  mandatory?: boolean;
  skipQuestionField?: boolean;
  /**
   * True if the UI for defining options should not be shown. Only useful for
   * "multi_options" fields.
   */
  skipOptionDefinition?: boolean;
}

export interface FieldData extends Partial<FieldConfig> {
  /** Server-side ID. Null if the field is not yet persisted. */
  serverId: number | null;
  /** Client-side ID. Always filled. */
  clientId: ClientId;
  title: string;
  aiFillEnabled: boolean;
  options?: FieldOptionData[];
}

export type StandardColumnName = 'type' | 'text' | 'mandatory';

export type ColumnConfig = {
  fieldName: StandardColumnName;
  className: string;
  label?: string;
  helperText?: string;
  placeholder?: string;
};

interface PropTypes {
  sectionId: ClientId;
  configs: FieldConfig[];
  singleTypeSections: boolean;
  disabledSelect: boolean;
  data: FieldData;
  removable: boolean;
  dispatch: React.Dispatch<Action>;
  readOnly?: boolean;
  columns: ColumnConfig[];
  placeholder?: string;
  fieldDeleteTooltip?: string;
}

export function Field(props: PropTypes) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.data.clientId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function handleFieldRemove() {
    props.dispatch({
      type: 'field_remove',
      sectionId: props.sectionId,
      fieldId: props.data.clientId,
    });
  }

  return (
    <div>
      <div ref={setNodeRef} style={style} className='row'>
        <div
          {...listeners}
          {...attributes}
          className='col-auto d-flex align-items-center'
        >
          <Icon
            name='bi-grip-vertical'
            className={classNames({ 'text-muted': props.readOnly })}
          />
        </div>
        {props.columns.map((column, i) => (
          <ColumnSwitch key={i} {...props} column={column} />
        ))}
        <div
          className={classNames('col-auto d-flex align-items-center', {
            invisible: props.readOnly,
          })}
        >
          <SpanTooltip
            tooltipText={props.fieldDeleteTooltip || 'Remove Field'}
            text={
              <IconButton
                color='borderless'
                size='sm'
                disabled={!props.removable}
                onClick={handleFieldRemove}
                iconName='bi-trash'
              />
            }
          />
        </div>
      </div>
      {props.data.fieldType === 'multi_options' &&
        props.data.skipOptionDefinition !== true && (
          <div className='pt-3'>
            <OptionList
              sectionId={props.sectionId}
              fieldData={props.data}
              dispatch={props.dispatch}
              readOnly={props.readOnly}
            />
          </div>
        )}
    </div>
  );
}

interface ColumnSwitchPropTypes extends Omit<PropTypes, 'columns'> {
  column: ColumnConfig;
}

function ColumnSwitch(props: ColumnSwitchPropTypes) {
  switch (props.column.fieldName) {
    case 'type':
      return <TypeCol {...props} />;
    case 'text':
      return <TextCol {...props} />;
    case 'mandatory':
      return <MandatoryCol {...props} />;
  }
}
