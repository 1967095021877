import React from 'react';
import { SchedulingSettings } from '../index';
import { ScheduleFromType } from '../ScheduleFromSelect/ScheduleFromTypes';
import { humanize } from '../../../../../utils/humanize';

function buildStartingFromText(scheduleFrom: ScheduleFromType): string {
  switch (scheduleFrom) {
    case 'now':
      return 'from now';
    case 'one_hour':
    case 'two_hours':
    case 'three_hours':
      return `${humanize(scheduleFrom.toString()).toLowerCase()} from now`;
    default:
      return `${humanize(scheduleFrom.toString()).toLowerCase()}`;
  }
}

export function ShareTeamAvailabilityFooter(props: {
  schedulingSettings: SchedulingSettings;
}) {
  if (!props.schedulingSettings) {
    return null;
  }

  const startingFrom = buildStartingFromText(
    props.schedulingSettings.scheduleFrom,
  );

  return (
    <>
      {`Your team availability for the next ${props.schedulingSettings.dateRange} 
      days starting ${startingFrom} is ready`}
      <br></br>
      to be shared with the candidate. No communication will be sent at this
      stage.
    </>
  );
}
