import React from 'react';
import { Integration } from '../../entities/Integration';
import { ViewUnifiedApiButton } from './ViewUnifiedApiButton';
import { ConnectUnifiedApiButton } from './ConnectUnifiedApiButton';

export interface PropTypes {
  integration: Integration;
  setIntegration: (i: Integration) => void;
}

export const UnifiedApiButton = React.memo(function UnifiedApiButton(
  props: PropTypes,
) {
  if (props.integration.connected) {
    return <ViewUnifiedApiButton integration={props.integration} />;
  }

  return <ConnectUnifiedApiButton {...props} />;
});
