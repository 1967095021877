import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { CreateOfferDto } from '../../../../containers/applicant_tracking/Offers/CreateOfferDto';
import { Offer } from '../../../../entities/v1/applicant_tracking/Offer';
import * as queryString from 'query-string';

export class OfferService {
  public static create(offer: CreateOfferDto): Promise<Offer> {
    const path = `/api/v1/applicant_tracking/offers?${this.getOfferExpandableParams()}`;

    return ApiService.post<Offer>(path, snakecaseKeys(offer));
  }

  public static revoke(id: number): Promise<Offer> {
    return ApiService.put<Offer>(
      `/api/v1/applicant_tracking/offers/${id}/revoke?${this.getOfferExpandableParams()}`,
    );
  }

  private static getOfferExpandableParams(): string {
    const params = {
      expand: ['application', 'application.job', 'application.candidate'],
    };

    return queryString.stringify(params, {
      arrayFormat: 'bracket',
    });
  }
}
