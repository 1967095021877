import React from 'react';
import { EmptyState } from '../../../../../components/EmptyState';
import { BackToCareersPageButton } from '../BackToCareersPageButton';
import WomanSuccess from '../../../../../images/woman-success-yellow-bg.svg';

interface PropTypes {
  jobPostName: string;
}

export default function SuccessPage(props: PropTypes) {
  return (
    <EmptyState
      title={'Your application has been submitted'}
      text={
        <div className='px-6'>
          Thank you for applying to {props.jobPostName} role. Our team will be
          in touch shortly.
        </div>
      }
      cardless
      imageSrc={WomanSuccess}
      actionButton={
        <BackToCareersPageButton
          color={'primary'}
          size={'md'}
          className={'mt-4'}
          onClick={() => (window.location.href = '../../..')}
        />
      }
    />
  );
}
