import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { CommonSelectPropTypes, SelectOption } from '../Select';
import { Option } from '../Select/Option';
import { selectTheme } from '../Select/selectTheme';
import { selectStyle } from '../Select/selectStyle';
import { ClearIndicator } from '../Select/ClearIndicator';
import { DropdownIndicator } from '../Select/DropdownIndicator';
import classNames from 'classnames';
import {
  GeoLocationService,
  LocationEntity,
} from '../../services/GeoLocationService';
import { debounce } from 'lodash';

export interface LocationOption extends SelectOption {
  address: string;
  city: string;
  state: string;
  country: string;
  latitude?: number;
  longitude?: number;
}

interface PropTypes extends CommonSelectPropTypes<LocationOption> {
  debounceMs?: number;
}

async function retrieveSuggestions(
  query: string,
  selected?: LocationOption,
): Promise<LocationOption[]> {
  return GeoLocationService.listLocations(query)
    .then((response) =>
      response.map((location: LocationEntity) => {
        return {
          value: location.name,
          label: location.name,
          selected: selected?.value === location.name,
          address: location.address,
          city: location.city,
          state: location.state,
          country: location.country,
          latitude: location.latitude,
          longitude: location.longitude,
        };
      }),
    )
    .catch((_e) => {
      return [];
    });
}

function toArray(selected?: LocationOption) {
  if (selected == null) {
    return null;
  }

  return [selected];
}

function clearSelectedOptions(
  loadedSuggestions: LocationOption[],
  setLoadedSuggestions: (loadedSuggestions: LocationOption[]) => void,
) {
  const previousSelected = loadedSuggestions?.find((s) => s.selected);
  if (previousSelected && loadedSuggestions.length === 1) {
    setLoadedSuggestions([
      {
        value: previousSelected.value,
        label: previousSelected.label,
        selected: false,
        address: previousSelected.address,
        city: previousSelected.city,
        state: previousSelected.state,
        country: previousSelected.country,
        latitude: previousSelected.latitude,
        longitude: previousSelected.longitude,
      },
    ]);
  }
}

export function getLocationString(candidate: {
  address?: string;
  city?: string;
  state?: string;
  country?: string;
}) {
  if (candidate == null) {
    return null;
  }

  const data = [
    candidate.address,
    candidate.city,
    candidate.state,
    candidate.country,
  ];

  return data.filter((v) => !!v).join(', ');
}

// Based on https://www.carlrippon.com/using-lodash-debounce-with-react-and-ts/
export function LocationSelect(props: PropTypes) {
  const [loadedSuggestions, setLoadedSuggestions] = useState<LocationOption[]>(
    toArray(props.selected),
  );

  const loadSuggestions = (
    query: string,
    callback: (results: LocationOption[]) => void,
  ) => {
    retrieveSuggestions(query, props.selected)
      .then((suggestions: LocationOption[]) => {
        setLoadedSuggestions(suggestions);
        callback(suggestions);
      })
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const debouncedSearch = debounce(loadSuggestions, props.debounceMs || 300);

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <AsyncSelect
      cacheOptions
      name={props.name}
      className={classNames(props.className, 'fs-5')}
      classNamePrefix='tags'
      id={props.testId}
      value={props.selected}
      defaultOptions={loadedSuggestions}
      loadOptions={debouncedSearch}
      placeholder={props.placeholder ?? 'Start typing to choose an option'}
      isClearable={props.isClearable}
      isDisabled={props.disabled}
      onChange={(option: LocationOption) => {
        if (option == null) {
          clearSelectedOptions(loadedSuggestions, setLoadedSuggestions);
        }
        props.onChange?.(option);
      }}
      menuPlacement={props.menuPlacement || 'auto'}
      noOptionsMessage={(_obj) => <>No results</>}
      components={{
        ClearIndicator,
        DropdownIndicator,
        Option,
      }}
      theme={selectTheme}
      styles={selectStyle('md')}
    />
  );
}
