import React, { useState } from 'react';
import { Interview } from '../../../../entities/Interview';
import classNames from 'classnames';
import { AINotesSubTab } from '../../../../entities/AINotesSubTab';
import { InterviewSummaryHeader } from '../../../InterviewPage/InterviewAINotesTab/InterviewSummaryHeader';
import { SelectedSummary } from '../../../InterviewPage/InterviewAINotesTab/SelectedSummary';
import { ActionPoint } from '../../../../entities/ActionPoint';

interface PropTypes {
  interview: Interview;
  onTimestampChange: (timestamp: number) => void;
  playTimestamp: number;
  atsEnabled: boolean;
}

export function ScorecardAINotes(props: PropTypes) {
  const [selectedAINotesSubTab, setSelectedAINotesSubTab] = useState(
    'summary' as AINotesSubTab,
  );
  const [customizingSummary, setCustomizingSummary] = useState(false);
  const [interview, setInterview] = useState<Interview>(props.interview);

  function handleActionPoints(actionPoints: ActionPoint[]) {
    setInterview({
      ...interview,
      actionPoints: actionPoints,
    });
  }

  return (
    <>
      <div className={classNames('d-flex', 'flex-column')}>
        <InterviewSummaryHeader
          selectedAINotesSubTab={selectedAINotesSubTab}
          onSelectedAINotesSubTab={setSelectedAINotesSubTab}
        />
      </div>
      <SelectedSummary
        interview={interview}
        atsEnabled={props.atsEnabled}
        onSelectTimestamp={props.onTimestampChange}
        onUpdateActionPoints={handleActionPoints}
        selectedAINotesSubTab={selectedAINotesSubTab}
        onCustomizing={setCustomizingSummary}
        isCustomizingSummary={customizingSummary}
        customSummaryEnabled={false}
        playTimestamp={props.playTimestamp}
        openExternalLink={false}
      />
    </>
  );
}
