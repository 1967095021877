import { PropTypes, TablePanel } from '../index';
import React from 'react';
import stylesheetExports from '../../../../../stylesheets/export.module.scss';

export function TopChoiceTablePanel(props: PropTypes) {
  return (
    <TablePanel
      {...props}
      questionTitleValueClassName={'fw-semibold'}
      questionAvgValueColor={stylesheetExports.dark500}
    />
  );
}
