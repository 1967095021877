import React from 'react';
import { Col, Row } from 'reactstrap';
import { Path, UseFormGetValues, UseFormRegister } from 'react-hook-form';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { TextAreaInputFormField } from '../../../../components/TextAreaInputFormField';

interface PropTypes {
  formRegister: UseFormRegister<CandidateDto>;
  getValues: UseFormGetValues<CandidateDto>;
}

export function CandidateDetailInput(props: PropTypes) {
  const required = false;
  const controllerName = 'candidateDetail';

  return (
    <Row>
      <Col xs='6'>
        <TextAreaInputFormField
          fieldName={'Tell us more about your candidate'}
          isRequired={required}
          headerClassNames='fw-semibold mb-2'
          classNames={'fs-5'}
          placeholder='What else it’s valuable to know about the candidate?'
          registerReturn={props.formRegister(controllerName, {
            required: required,
          })}
          initialValue={
            props.getValues(controllerName as Path<CandidateDto>) as string
          }
          maxLength={500}
          rows={4}
        />
      </Col>
    </Row>
  );
}
