import React from 'react';
import { ExpectedSalary } from '../../../../../../entities/Application';
import { formatSalary } from '../../../../../../utils/formatSalary';
import { Entry } from '../../Entry';

export function ExpectedSalaryEntry(props: { expectedSalary: ExpectedSalary }) {
  if (!props.expectedSalary) return null;

  const expectedSalary = formatSalary(
    props.expectedSalary.value,
    props.expectedSalary.currency,
  );

  return (
    <Entry label='Expected Salary' value={expectedSalary} redacted={false} />
  );
}
