import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Survey } from '../../../entities/Survey';

interface PropTypes {
  survey: Survey;
  isNextDisabled: boolean;
  nextTitle: string;
  onPrevious(): void;
  onNext(): void;
}

export function ProgressRow(props: PropTypes) {
  return (
    <Row>
      <Col xs='12' className='my-4'>
        <hr />
      </Col>
      <Col>
        <Button size='lg' color='secondary' onClick={() => props.onPrevious()}>
          Previous
        </Button>
      </Col>
      <Col className='text-end mb-5'>
        <Button
          size='lg'
          onClick={() => props.onNext()}
          disabled={props.isNextDisabled}
          style={{
            letterSpacing: `${props.survey.company_info.font_kerning}em`,
          }}
        >
          {props.nextTitle}
        </Button>
      </Col>
    </Row>
  );
}
