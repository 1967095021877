import classNames from 'classnames';
import React from 'react';
import { Table } from 'reactstrap';
import { HiringMemberAnalytics } from '../../../../../../../entities/candidate_experience/HiringMemberAnalytics';
import styles from './styles.module.scss';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';

interface PropTypes {
  hiring_members: HiringMemberAnalytics[];
  sortColumn: string;
  sortDirection: number;
  onSortUpdate: (sort: string) => void;
  standardNpsCalculation: boolean;
}

const columns = [
  { key: 'name', label: 'Name', type: 'string' },
  { key: 'role', label: 'Role', type: 'string' },
  {
    key: 'nps',
    label: 'CNPS',
    tooltipText:
      "Candidate Net Promoter Score (CNPS) is the candidate's score of the overall process experience.",
    type: 'number',
  },
  { key: 'jobs_count', label: 'Jobs', type: 'number' },
  {
    key: 'avg_score',
    label: 'AVG Score',
    tooltipText:
      'Average score of all rating type answers with the exception of the CNPS question.',
    type: 'number',
  },
  { key: 'sentiment_analysis', label: 'Sentiment Analysis', type: 'string' },
];

export function HiringTeamTable(props: PropTypes) {
  return (
    <div>
      <Table
        borderless
        className={classNames(
          'mt-3 m-0',
          'table-fixed',
          styles['overview-table'],
          styles['all-users-table'],
        )}
      >
        <TableHeader
          columns={columns}
          sortColumn={props.sortColumn}
          sortDirection={props.sortDirection}
          onSortUpdate={props.onSortUpdate}
        />
        <TableBody
          hiring_members={props.hiring_members}
          standardNpsCalculation={props.standardNpsCalculation}
        />
      </Table>
    </div>
  );
}
