import {
  FilterStates,
  OnChangeFilterStatesCallback,
} from '../../../components/candidate_experience/Filters/filterState';
import React, { useState } from 'react';
import { TopTabNavigation } from './TopTabNavigation';
import { Button } from 'reactstrap';
import { ExportModal } from './ExportModal';
import { OverviewAnalyticsTab } from './OverviewAnalyticsTab';
import { NPSExplainabilityTab } from './NPSExplainabilityAnalyticsTab';
import { FollowUpQuestionsSentimentTab } from './FollowUpQuestionsSentimentTab';
import { HiringTeamAnalyticsTab } from './HiringTeamAnalyticsTab';

interface PropTypes {
  organizationName: string;
  filterStates: FilterStates;
  moreFiltersShown: boolean;
  onApplyFilter: OnChangeFilterStatesCallback;
  candidateDemographicQuestionsEnabled: boolean;
  activeTab: string;
  pulseStandardNpsCalculation: boolean;
}

export function CandidatePulseAnalytics(props: PropTypes) {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [isAnalyticsEmpty, setAnalyticsEmpty] = useState(true);

  return (
    <main>
      <div className='d-flex align-items-start mb-4'>
        <h1 className='mb-0 me-auto'>Candidate Pulse</h1>
        {props.activeTab === 'Overview' && (
          <Button
            onClick={() => setExportModalOpen(true)}
            disabled={isAnalyticsEmpty}
          >
            <i
              className='bi bi-download position-static me-1'
              style={{ top: 0 }}
            ></i>
            Export
          </Button>
        )}
        {exportModalOpen && (
          <ExportModal
            isOpen={exportModalOpen}
            onCancel={() => setExportModalOpen(false)}
            filterStates={props.filterStates}
          />
        )}
      </div>
      <TopTabNavigation activeTab={props.activeTab} />
      {props.activeTab === 'Overview' && (
        <OverviewAnalyticsTab
          setAnalyticsEmpty={setAnalyticsEmpty}
          {...props}
        />
      )}
      {props.activeTab === 'CNPS Explainability' && (
        <NPSExplainabilityTab
          filterStates={props.filterStates}
          onApplyFilter={props.onApplyFilter}
          pulseStandardNpsCalculation={props.pulseStandardNpsCalculation}
        />
      )}
      {props.activeTab === 'Follow-up Questions' && (
        <FollowUpQuestionsSentimentTab
          filterStates={props.filterStates}
          onApplyFilter={props.onApplyFilter}
          organizationName={props.organizationName}
          moreFiltersShown={props.moreFiltersShown}
          pulseStandardNpsCalculation={props.pulseStandardNpsCalculation}
        />
      )}
      {props.activeTab === 'Team Leaderboard' && (
        <HiringTeamAnalyticsTab
          filterStates={props.filterStates}
          onApplyFilter={props.onApplyFilter}
        />
      )}
    </main>
  );
}
