import React from 'react';

export function AvailabilityRow() {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex align-items-center gap-3 me-auto'>
        <small className='text-muted'>Define your interview availability</small>
      </div>
      <a
        className='btn btn-info'
        href={'availabilities?bypass_empty_state=true'}
      >
        Define availability
      </a>
    </div>
  );
}
