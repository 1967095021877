import React from 'react';
import { ScorecardTemplate } from '../../../entities/v1/applicant_tracking/ScorecardTemplate';
import { findSectionType } from '../../../utils/applicant_tracking/scorecardSurvey';
import { OpenTextSection } from './OpenTextSection';
import { Section } from '../../../entities/v1/survey_engine/Section';
import { RatingSection } from './RatingSection';

interface PropTypes {
  scorecardTemplate: ScorecardTemplate;
  showFieldLabels?: boolean;
  preview?: boolean;
}

function ScorecardQuestion(props: PropTypes & { section: Section }) {
  switch (findSectionType(props.section)) {
    case 'rating':
      return (
        <RatingSection
          section={props.section}
          showFieldLabels={props.showFieldLabels}
          preview={props.preview}
        />
      );
    case 'text':
      return (
        <OpenTextSection
          section={props.section}
          showFieldLabels={props.showFieldLabels}
        />
      );
    default:
      return null;
  }
}

export function ScorecardTemplateTable(props: PropTypes) {
  return (
    <>
      {props.scorecardTemplate.surveyTemplate.sections?.map(
        (section: Section, index: number) => {
          return (
            <div key={index}>
              <ScorecardQuestion {...props} section={section} />
            </div>
          );
        },
      )}
    </>
  );
}
