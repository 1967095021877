import React from 'react';

export function FormCheck(props: {
  defaultChecked: boolean;
  onClick: () => void;
  title: string;
}) {
  return (
    <div className='form-check'>
      <label className='me-3'>
        <input
          type='radio'
          name='react-tips'
          defaultChecked={props.defaultChecked}
          className='form-check-input'
          onClick={() => {
            props.onClick();
          }}
        />
        {props.title}
      </label>
    </div>
  );
}
