import { ApiService } from '../../ApiService';
import { VcsProviders } from '../../../entities/VcsProviders';

export class VcsProvidersService {
  public static updateDefault(value: string): Promise<void> {
    return ApiService.put<void>(
      `/api/account_settings/integrations/vcs_providers/update_default`,
      {
        value,
      },
    );
  }

  public static get(): Promise<VcsProviders> {
    return ApiService.get<VcsProviders>(
      `/api/account_settings/integrations/vcs_providers`,
    );
  }
}
