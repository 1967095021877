import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import logo from '../../../images/logo.png';
import prospectImage from '../../../images/mailer-survey-request-new-prospect.png';
import { Button } from 'reactstrap';

interface PropTypes {
  url: string;
}

export default function ConnectCalendar(props: PropTypes) {
  const handleConnectCalendar = () => {
    window.location.href = props.url;
  };

  return (
    <>
      <div className='mx-6'>
        <div className='d-flex flex-column align-items-center'>
          <div className='justify-content-center mx-4 my-4 w-75'>
            <div className='row'>
              <div className='mb-4 text-left'>
                <img
                  src={logo}
                  alt='Screenloop'
                  className={classNames(styles['header-logo'])}
                />
              </div>
            </div>
            <div className='row'>
              <div className='card rounded-4 shadow-sm h-100'>
                <div className='app-card-body w-100'>
                  <div className='row h-100'>
                    <div className='col-12'>
                      <div className='py-5 d-flex flex-column'>
                        <div className='row justify-content-center text-center'>
                          <h1 className='mb-4'>
                            You need to connect your calendar
                          </h1>
                          <div className='row my-2 py-1 justify-content-center'>
                            <img
                              src={prospectImage}
                              alt='Screenloop'
                              className={classNames(styles['main-image'])}
                            />
                          </div>
                        </div>
                        <div className='row mt-4 justify-content-center text-center'>
                          <span>
                            To be able to schedule interviews, click the button
                            below
                            <br></br>
                            and connect your calendar.
                          </span>
                        </div>
                        <div className='justify-content-center text-center'>
                          <Button
                            color='primary'
                            size='lg'
                            className='mt-4'
                            onClick={handleConnectCalendar}
                          >
                            Connect Calendar
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column align-items-center mt-4'>
        <div className='text-dark-200 text-center'>
          We take security and privacy seriously. All of the data we collect is
          completely secure and confidential.
          <br></br>
          If you have any questions, please contact us at{' '}
          <a href='mailto:support@screenloop.com'>support@screenloop.com</a>.
        </div>
      </div>
    </>
  );
}
