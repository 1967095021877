import classNames from 'classnames';
import React from 'react';
import { ScorecardAttribute } from '../../../../../../../entities/ScorecardAttribute';
import { Button } from 'reactstrap';

interface PropTypes {
  attribute: ScorecardAttribute;
  index: number;
  setClicked: (index: number) => void;
}

export function ScorecardAttributeChip(props: PropTypes) {
  return (
    <div
      id={`attr_${props.attribute.id}`}
      className='col-auto p-0'
      title={props.attribute.name}
      data-toggle='tooltip'
      data-bs-placement='top'
      data-bs-delay='1000'
      data-controller='disable-tooltip'
      data-tooltip-target={`#attr_${props.attribute.id} [type=submit]`}
    >
      <Button
        color={'secondary'}
        className={classNames('chip', {
          'chip--selected': props.attribute.selected,
        })}
        disabled={props.attribute.selected}
        onClick={() => props.setClicked(props.index)}
      >
        {props.attribute.name}
      </Button>
    </div>
  );
}
