import React from 'react';
import {
  Product,
  ProductPriceCurrency,
} from '@vonq/hapi-elements-types/product';
import { getPrice } from '../../../utils/productDetails';
import { TruncateText } from '../../../../../../components/TruncateText';
import { HorizontalDivider } from '../../../../../../components/HorizontallDivider';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { DurationChip } from '../../Marketplace/JobBoardCard/DurationChip';

interface PropTypes {
  index: number;
  product: Product;
  currency: ProductPriceCurrency;
  onRemoveProduct: (product: Product) => void;
}

function CartLine(props: { children: React.ReactNode; className?: string }) {
  return (
    <div
      className={classNames(
        'd-flex flex-nowrap justify-content-between align-items-center',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}

export function CartProduct(props: PropTypes) {
  return (
    <div>
      {props.index != 0 && <HorizontalDivider classNames='mb-3' />}
      <CartLine className='mb-2h me-1'>
        <TruncateText className='fw-semibold fs-5' text={props.product.title} />
        <span className='fw-semibold fs-6'>
          {getPrice(props.product.vonq_price, props.currency)}
        </span>
      </CartLine>
      <CartLine>
        <DurationChip duration={props.product.duration} />
        <Button
          color='borderless'
          size='sm'
          onClick={() => props.onRemoveProduct(props.product)}
        >
          Remove
        </Button>
      </CartLine>
    </div>
  );
}
