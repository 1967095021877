import React from 'react';
import { Col, Row } from 'reactstrap';
import { SurveyQuestionField } from '../../../../entities/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../entities/SurveyQuestionFieldAnswer';

interface PropTypes {
  field: SurveyQuestionField;
  fieldAnswer: SurveyQuestionFieldAnswer;
  onChangeAnswer: (fieldId: number, value: string) => void;
}

export function TextField({ field, fieldAnswer, onChangeAnswer }: PropTypes) {
  return (
    <Row className='mt-4 align-items-center'>
      {field.name && (
        <Col xs='2'>
          <b>{field.name}</b>
        </Col>
      )}
      <Col xs='12'>
        <textarea
          className='form-control'
          rows={5}
          autoFocus
          value={fieldAnswer.value != null ? fieldAnswer.value : ''}
          onChange={(e) => onChangeAnswer(field.id, e.target.value)}
        />
      </Col>
    </Row>
  );
}
