import React, { useState } from 'react';
import { ActionItemPanel } from './ActionItemPanel';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { EmptyState } from '../../../../components/EmptyState';
import { Pagination } from '../../../../components/Pagination';
import { PaginationCount } from '../../../../components/PanelFooter';
import { User } from '../../../../entities/User';
import { SelectOption } from '../../../../components/Select';
import { GroupedMultiSelect } from '../../../../components/GroupedMultiSelect';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { HiringMember } from '../../../../entities/HiringMember';
import { hiringMemberFilterOptions } from './utils';
import { useActionItems } from '../../../../queries/v1/applicant_tracking/useActionItems';
import { ActionItemList } from '../../../../entities/v1/applicant_tracking/ActionItem';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

const ACTION_ITEMS_PAGE_SIZE = 4;

interface PropTypes {
  jobId: number;
  currentUser: User;
  hiringMembers: HiringMember[];
}

interface UIPropTypes {
  jobId: number;
  currentUser: User;
  hiringMembers: HiringMember[];
  isLoadingActionItems: boolean;
  actionItemList?: ActionItemList;
  setCurrentPage: (page: number) => void;
  hiringMemberFilter: SelectOption[];
  setHiringMemberFilter: (options: SelectOption[]) => void;
  setHiringMemberIds: (members: number[]) => void;
}

function getActionItemList(
  isLoading: boolean,
  completed: ActionItemList,
  pending: ActionItemList,
) {
  if (isLoading) return null;

  if (pending.actionItems == null || pending.entriesCount === 0)
    return completed;

  return pending;
}

export function ActionItems(props: PropTypes) {
  const [currentPage, setCurrentPage] = useState(null);
  const [hiringMemberFilter, setHiringMemberFilter] = useState<SelectOption[]>(
    [],
  );
  const [hiringMemberIds, setHiringMemberIds] = useState<number[]>([]);

  const {
    isLoading: isLoadingPendingActionItems,
    data: pendingActionItemList,
  } = useActionItems({
    jobId: props.jobId,
    page: currentPage || 1,
    pageSize: ACTION_ITEMS_PAGE_SIZE,
    completed: false,
    hiringMemberIds: hiringMemberIds,
    expand: ['application', 'application.candidate', 'hiring_member'],
  });
  const {
    isLoading: isLoadingCompletedActionItems,
    data: completedActionItemList,
  } = useActionItems({
    jobId: props.jobId,
    page: currentPage || 1,
    pageSize: ACTION_ITEMS_PAGE_SIZE,
    completed: true,
    hiringMemberIds: hiringMemberIds,
    expand: ['application', 'application.candidate', 'hiring_member'],
  });

  const isLoading =
    isLoadingCompletedActionItems || isLoadingPendingActionItems;

  return (
    <ActionItemsUI
      {...props}
      isLoadingActionItems={isLoading}
      actionItemList={getActionItemList(
        isLoading,
        completedActionItemList,
        pendingActionItemList,
      )}
      setCurrentPage={setCurrentPage}
      hiringMemberFilter={hiringMemberFilter}
      setHiringMemberFilter={setHiringMemberFilter}
      setHiringMemberIds={setHiringMemberIds}
    />
  );
}

export function ActionItemsUI(props: UIPropTypes) {
  return (
    <DeprecatedPanel className='p-3 my-2'>
      <div className='d-flex justify-content-between align-items-center mb-3 pb-1'>
        <div className='fs-3 fw-semiboldp-2'>Pending Actions</div>
        <div className={classNames('w-30', styles.filters)}>
          <GroupedMultiSelect
            options={hiringMemberFilterOptions(
              props.hiringMembers,
              props.currentUser,
            )}
            sortCategoriesDirection='desc'
            selected={props.hiringMemberFilter}
            label={null}
            placeholder='All Hiring Team'
            singleSelectedText={props.hiringMemberFilter[0]?.label}
            manySelectedText='%d selected'
            onChange={props.setHiringMemberFilter}
            className='fs-6'
            labelClassName='mb-0'
            size='sm'
            onClose={() => {
              props.setHiringMemberIds(
                props.hiringMemberFilter.map((hm) => Number(hm.value)),
              );
              props.setCurrentPage(1);
            }}
          />
        </div>
      </div>
      <div className='fs-5'>
        {props.isLoadingActionItems ? (
          <LoadingSpinner minHeight='250px' />
        ) : props.actionItemList.entriesCount > 0 ? (
          <>
            {props.actionItemList.actionItems.map((actionItem, index) => (
              <ActionItemPanel
                key={index}
                actionItem={actionItem}
                currentUser={props.currentUser}
              />
            ))}
            {props.actionItemList.pageCount > 1 && (
              <div className='d-flex justify-content-between mt-3'>
                <div className='text-dark-200'>
                  <PaginationCount
                    currentPage={props.actionItemList.page}
                    total={props.actionItemList.entriesCount}
                    pageSize={ACTION_ITEMS_PAGE_SIZE}
                  />
                </div>
                <Pagination
                  page={props.actionItemList.page}
                  pageCount={props.actionItemList.pageCount}
                  visiblePageCount={3}
                  disabled={false}
                  onPageClicked={(page) => props.setCurrentPage(page)}
                  backLabel='Prev'
                  align='center'
                />
              </div>
            )}
          </>
        ) : (
          <EmptyState
            title='No action required yet'
            text='Candidate related actions will appear here when available.'
            titleClassName='fs-2'
            textFontSize='fs-5'
          />
        )}
      </div>
    </DeprecatedPanel>
  );
}
