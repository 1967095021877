import moment from 'moment';
import {
  DateFilter,
  DateRange,
} from '../../../../../components/DateRangePicker';
import { getQueryParam, QueryParam } from '../../../../../utils/url';

export const DATE_RANGE_QUERY_PARAM = 'date_range';
export const START_DATE_QUERY_PARAM = 'start_date';
export const END_DATE_QUERY_PARAM = 'end_date';

function emptyDateRange() {
  return {
    start: null,
    end: null,
    range: null,
  };
}

export function parseDateRange() {
  const startDate = getQueryParam('start_date');
  const endDate = getQueryParam('end_date');
  const range = getQueryParam('date_range');

  if (range != null) {
    return {
      start: moment(new Date(startDate)).utc(true),
      end: moment(new Date(endDate)).utc(true),
      range: (range as DateRange) ?? DateRange.Last90Days,
    };
  }

  return emptyDateRange();
}

export function mapDateRangeToQueryParams(
  dateFilter: DateFilter,
): QueryParam[] {
  return [
    {
      key: DATE_RANGE_QUERY_PARAM,
      value: dateFilter.range,
    },
    {
      key: START_DATE_QUERY_PARAM,
      value: dateFilter.start?.utc()?.format(),
    },
    {
      key: END_DATE_QUERY_PARAM,
      value: dateFilter.end?.utc()?.format(),
    },
  ];
}
