import moment from 'moment';
import { getDefaultSelectedFilter } from '../../../utils/getDefaultSelectedFilter';
import { DateFilter, DateRange } from '../../DateRangePicker';

export interface SelectOption {
  value: string;
  label: string;
}

export interface FilterState {
  all?: SelectOption[];
  selected: SelectOption[];
}

export interface SingleValueFilterState {
  all?: SelectOption[];
  selected: SelectOption;
}

export interface FilterStates {
  filterSelector: SingleValueFilterState;
  jobs: FilterState;
  departments: FilterState;
  hiringManagers: FilterState;
  recruiters: FilterState;
  benchmarkType: SingleValueFilterState;
  dateRange: DateFilter;
  surveyTemplates?: FilterState;
  locations?: FilterState;
  ethnicities: FilterState;
  genders: FilterState;
  ageRanges: FilterState;
  npsAttributes: FilterState;
}

export type OnChangeFilterStatesCallback = (
  newFilterStates: FilterStates,
  newMoreFilters: boolean,
) => void;

export function defaultDateRange() {
  return {
    start: moment().subtract(30, 'days').startOf('day').utc(true),
    end: moment().endOf('day').utc(true),
    range: DateRange.Last30Days,
  };
}

export function generateResetFilterStates(prev: FilterStates) {
  return {
    ...prev,
    filterSelector: {
      ...prev.filterSelector,
      selected: getDefaultSelectedFilter(),
    },
    jobs: {
      ...prev.jobs,
      selected: [],
    },
    departments: {
      ...prev.departments,
      selected: [],
    },
    hiringManagers: {
      ...prev.hiringManagers,
      selected: [],
    },
    recruiters: {
      ...prev.recruiters,
      selected: [],
    },
    locations: {
      ...prev.locations,
      selected: [],
    },
    surveyTemplates: {
      ...prev.surveyTemplates,
      selected: [],
    },
    ethnicities: {
      ...prev.ethnicities,
      selected: [],
    },
    genders: {
      ...prev.genders,
      selected: [],
    },
    ageRanges: {
      ...prev.ageRanges,
      selected: [],
    },
    benchmarkType: {
      ...prev.benchmarkType,
      selected: getDefaultBenchmarkType(prev.benchmarkType.all),
    },
    npsAttributes: {
      ...prev.npsAttributes,
      selected: [],
    },
    dateRange: defaultDateRange(),
  };
}

function getDefaultBenchmarkType(options: SelectOption[]) {
  return options[0];
}
