import classNames from 'classnames';
import React from 'react';
import { InterviewMarkerList } from '../../../components/Interview/InterviewMarkerList';
import { MenuType } from '../../../components/Interview/MenuType';
import { Interview } from '../../../entities/Interview';
import { InterviewMarker } from '../../../entities/InterviewMarker';
import styles from './styles.module.scss';
import { InterviewTimelineHeader } from './InterviewTimelineHeader';
import { HighlightType } from '../Entities/HighlightType';
import { useAutomaticQuestions } from '../../../queries/v1/interview_intelligence/useAutomaticQuestions';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import {
  AutomaticQuestion,
  AutomaticQuestionList,
} from '../../../entities/v1/interview_intelligence/AutomaticQuestion';
import { QueryObserverResult } from 'react-query';

export interface PropTypes {
  playTimestamp: number;
  interview: Interview;
  menuType: MenuType;
  selectedHighlightType: HighlightType;
  onSelectTimestamp?: (timestamp: number) => void;
  onUpdateInterviewMarkers: (interview_markers: InterviewMarker[]) => void;
  setSelectedHighlightType: (highlighType: HighlightType) => void;
}

export interface InterviewTimelineTabUIPropTypes {
  playTimestamp: number;
  interview: Interview;
  automaticQuestions?: AutomaticQuestion[];
  menuType: MenuType;
  selectedHighlightType: HighlightType;
  refetchAutomaticQuestions: (
    options?: unknown,
  ) => Promise<QueryObserverResult<AutomaticQuestionList, unknown>>;
  onSelectTimestamp?: (timestamp: number) => void;
  onUpdateInterviewMarkers: (interview_markers: InterviewMarker[]) => void;
  setSelectedHighlightType: (highlighType: HighlightType) => void;
}

export function InterviewTimelineTab(props: PropTypes) {
  const {
    isLoading: isLoadingAutomaticQuestions,
    data: automaticQuestions,
    refetch,
  } = useAutomaticQuestions(props.interview.id, [
    'participant',
    'automatic_notes',
    'replies',
    'replies.user',
  ]);

  return isLoadingAutomaticQuestions ? (
    <div style={{ minHeight: '250px' }}>
      <LoadingSpinner />
    </div>
  ) : (
    <InterviewTimelineTabUI
      {...props}
      automaticQuestions={automaticQuestions?.automaticQuestions}
      refetchAutomaticQuestions={refetch}
    />
  );
}

export function InterviewTimelineTabUI(props: InterviewTimelineTabUIPropTypes) {
  return (
    <>
      <div
        className={classNames(
          'd-flex',
          'flex-column',
          'align-items-start',
          styles.notesWrapper,
          'interview_detail_highlights',
        )}
      >
        <InterviewTimelineHeader
          selectedHighlightType={props.selectedHighlightType}
          onSelectedHighlightType={props.setSelectedHighlightType}
        />
        <div
          className={classNames(
            'p-2',
            'mb-2',
            'w-100',
            'h-100',
            'overflow-auto',
          )}
          style={{ scrollBehavior: 'smooth' }}
        >
          <InterviewMarkerList
            playTimestamp={props.playTimestamp}
            interview={props.interview}
            selectedHighlightType={props.selectedHighlightType}
            automaticQuestions={props.automaticQuestions}
            refetchAutomaticQuestions={props.refetchAutomaticQuestions}
            onSelectTimestamp={props.onSelectTimestamp}
            onUpdateInterviewMarkers={props.onUpdateInterviewMarkers}
            menuType={'reply'}
            showResponses={false}
            showNameAndOffset={true}
            showContextQuestion={true}
          />
        </div>
      </div>
    </>
  );
}
