import React, { useState } from 'react';
import { Card } from '../../../../components/Card';
import { useForm } from 'react-hook-form';
import { JobRequisitionDto } from '../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { HorizontalDivider } from '../../../../components/HorizontallDivider';
import { JobRequisitionService } from '../../../../services/v1/applicant_tracking/JobRequisitionService';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { ApprovalFlowContainer } from '../components/ApprovalFlowContainer';
import { JobRequisitionForm } from '../components/JobRequisitionForm';
import { CardHeader } from '../components/CardHeader';
import { JobRequisition } from '../../../../entities/v1/applicant_tracking/JobRequisition';
import { AlertMessage } from '../../../../components/AlertMessage';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';

interface PropTypes {
  jobRequisition: JobRequisition;
}

export default function EditJobRequisitionsPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(false);
  const plannedStartDate = props.jobRequisition.plannedStartDate
    ? new Date(props.jobRequisition.plannedStartDate)
    : null;

  const {
    register,
    control,
    getValues,
    setValue,
    resetField,
    watch,
    formState,
    trigger,
  } = useForm<JobRequisitionDto>({
    mode: 'onChange',
    defaultValues: {
      ...props.jobRequisition,
      startMonth: plannedStartDate?.getMonth() + 1,
      startYear: plannedStartDate?.getFullYear(),
      jobOpenings: props.jobRequisition.openings,
    },
  });

  const isSavable = () => {
    return formState.isValid;
  };

  const onSave = async () => {
    setIsLoading(true);
    await JobRequisitionService.update(props.jobRequisition.id, getValues());

    window.location.href =
      getRedirectUriQueryParam() ?? '/applicant_tracking/job_requisitions';
  };

  return (
    <div className='container'>
      {isLoading && <LoadingSpinner showBackdrop />}
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <h1 className='mb-0'>{props.jobRequisition.name}</h1>
        <AlertMessage
          className='bg-light-danger fs-5'
          icon={{ name: 'bi-info-circle' }}
          text='Resubmitting a job requisition will restart the approval process.'
        />
      </div>
      <Card className='px-6h'>
        <CardHeader
          saveText='Update Request'
          isSavable={isSavable()}
          onSaveClick={onSave}
        />
        <h3 className='mt-3'>Edit Requisition Details</h3>
        <div className='fs-6 text-dark-200 mb-4'>
          Define and configure requisition details
        </div>
        <form>
          <JobRequisitionForm
            formState={formState}
            formRegister={register}
            getValues={getValues}
            setValue={setValue}
            formControl={control}
            formResetField={resetField}
            formWatch={watch}
            reValidate={trigger}
          />
        </form>
        <HorizontalDivider classNames='my-4' />
        <ApprovalFlowContainer requisitionType='job' formControl={control} />
      </Card>
    </div>
  );
}
