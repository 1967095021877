import React from 'react';
import { Application as DeprecatedApplication } from '../../../../../entities/Application';
import { Select, SelectOption } from '../../../../../components/Select';
import { CandidatePageHeader } from '../../../../CandidatePage/CandidatePageHeader';
import { Col } from 'reactstrap';
import { Application } from '../../../../../entities/v1/applicant_tracking/Application';

interface PropTypes {
  application: DeprecatedApplication | Application;
  currentInterviewStageId?: number;
  interviewStagesOptions: SelectOption[];
}

function generateLink(interviewStageId: string, applicationId: number) {
  return `/applicant_tracking/applications/${applicationId}/interview_stages/${interviewStageId}`;
}

export function Header(props: PropTypes) {
  return (
    <CandidatePageHeader
      applicantTrackingEnabled={true}
      application={props.application}
    >
      {props.interviewStagesOptions.length > 1 && (
        <Col className='d-flex align-items-center' xs='3'>
          <span className='text-nowrap me-3 fs-5 fw-semibold'>
            Select Interview Kit
          </span>
          <Select
            className='w-100'
            options={props.interviewStagesOptions}
            placeholder='Select Interview Kit'
            selected={props.interviewStagesOptions.find((v) => v.selected)}
            onChange={(o: SelectOption) =>
              (window.location.href = generateLink(
                o.value,
                props.application.id,
              ))
            }
          />
        </Col>
      )}
    </CandidatePageHeader>
  );
}
