import { User } from '../../../entities/User';
import { VcsProvider } from '../../../enums/IntegrationProvider/VcsProvider';

export function getDefaultMeetingProvider(user: User): VcsProvider {
  return user.schedulingUserIntegration?.defaultMeetingProvider;
}

export function getAvailableMeetingProviders(
  user: User,
  defaultMeetingProviders: VcsProvider[] = [],
): VcsProvider[] {
  return (
    user.schedulingUserIntegration?.availableMeetingProviders ??
    defaultMeetingProviders
  );
}
