import { Relationship } from '../reference_check/entities/Relationship';
import { humanize } from './humanize';

export function formatRelationship(relationship: Relationship) {
  return relationship === 'direct_report' ? 'Report' : humanize(relationship);
}

export function formatLongRelationship(relationship: Relationship) {
  return relationship === 'direct_report'
    ? 'Direct Report'
    : humanize(relationship);
}
