import { CandidateUser } from '../../entities/CandidateUser';
import { ApiService } from '../ApiService';

export class CandidateUserService {
  public static update(
    ageRange: string,
    ethnicity: string,
    gender: string,
    signInToken: string,
  ): Promise<CandidateUser> {
    const params = {
      age_range: ageRange,
      ethnicity: ethnicity,
      gender: gender,
      sign_in_token: signInToken,
    };
    return ApiService.put<CandidateUser>(
      `/api/candidate_users/${signInToken}`,
      params,
    );
  }
}
