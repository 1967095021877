import React, { useCallback } from 'react';
import { FormGroup, Label, Row } from 'reactstrap';
import { SurveyRequest } from '../../reference_check/entities/SurveyRequest';
import { capitalize } from '../../utils/capitalize';
import { SpanTooltip } from '../../components/SpanTooltip';
import { formatRelationship } from '../../utils/formatRelationship';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { ToggleButton } from '../../components/ToggleButton';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ReferenceRequestStatus } from './ReferenceRequestStatus';
import { CancelSuccessRequestModal } from './CancelSuccessRequestModal';
import moment from 'moment';

interface PropTypes {
  surveyRequest: SurveyRequest;
  onClose: () => void;
  isOpen: boolean;
  external?: React.ReactNode;
  excludeReferencesWithIpWarning: boolean;
  onExcludeReferencesWithIpWarning: (val: boolean) => void;
  referenceRequestStatus: ReferenceRequestStatus;
  setReferenceRequestStatus: (status: ReferenceRequestStatus) => void;
  loadSurveyRequests: () => void;
}

function getRowStyle(array, index) {
  if (index + 1 < array.length) return {};

  return {
    border: 0,
  };
}

function isAbleToCancelRequest(surveyRequest: SurveyRequest) {
  const referralSurveys = surveyRequest.referrals.filter(
    (referral) => referral.relationship !== 'candidate',
  );

  return referralSurveys.every((referral) => referral.surveys.length == 0);
}

function ExcludeReferencesWithSameIpForm(props: {
  excludeReferencesWithIpWarning: boolean;
  onExcludeReferencesWithIpWarning: (val: boolean) => void;
}) {
  return (
    <FormGroup check className={'ps-0'}>
      <ToggleButton
        name={'exclude-references-with-ip-warning-toggle'}
        checked={props.excludeReferencesWithIpWarning}
        onChange={() =>
          props.onExcludeReferencesWithIpWarning(
            !props.excludeReferencesWithIpWarning,
          )
        }
        color='info'
      />
      <Label
        for='exclude-references-with-ip-warning-checkbox'
        check
        className='fw-normal ms-3 fs-5'
      >
        Exclude data from responses using the same IP address
      </Label>
    </FormGroup>
  );
}

function ReferralsTable(props: { surveyRequest: SurveyRequest }) {
  const displayableSurveys = generateReferralsStatus(props.surveyRequest);

  return (
    <table
      className={classNames(
        'table',
        'fs-5',
        'text-nowrap',
        'text-primary',
        styles.referenceRequestStatusModalBody,
      )}
    >
      <thead>
        <tr>
          <th scope='col' className={'col-3'}>
            Name
          </th>
          <th scope='col' className='col text-center'>
            Job Title
          </th>
          <th scope='col' className='col text-center'>
            Working Period
          </th>
          <th scope='col'>Status</th>
          <th scope='col' className={'text-center'}>
            IP
          </th>
        </tr>
      </thead>
      <tbody>
        {displayableSurveys.map((refStat, i) => (
          <tr key={`ReferenceStatus${i}`} className={'align-middle'}>
            <td style={getRowStyle(displayableSurveys, i)}>
              <div className='d-flex flex-column'>
                <label>{refStat.name}</label>
                <small className='text-dark-500'>{refStat.email}</small>
              </div>
            </td>
            <td
              style={getRowStyle(displayableSurveys, i)}
              className='align-middle text-center'
            >
              <JobTitleValidation refStat={refStat} />
            </td>
            <td
              style={getRowStyle(displayableSurveys, i)}
              className='align-middle text-center'
            >
              <WorkingPeriodValidation refStat={refStat} />
            </td>
            <td style={getRowStyle(displayableSurveys, i)}>
              {refStat.status}
              <i
                className={`bi bi-dot fs-1 align-middle text-${getStatusIconTextColor(
                  refStat.status,
                )}`}
              />
            </td>
            <td
              className='align-middle'
              style={getRowStyle(displayableSurveys, i)}
            >
              {refStat.status === 'Completed' ? (
                <IpWarning names={refStat.ipMatchingNames} />
              ) : (
                <small>N/A</small>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function JobTitleValidation(props: { refStat: any }) {
  if (props.refStat.cJobTitle == null || props.refStat.rJobTitle == null)
    return <span>N/A</span>;

  return (
    <SpanTooltip
      text={
        props.refStat.cJobTitle.toLowerCase() ===
        props.refStat.rJobTitle.toLowerCase() ? (
          <SuccessIcon />
        ) : (
          <FailureIcon />
        )
      }
      tooltipText={
        <>
          <div>Candidate: {props.refStat.cJobTitle}</div>
          <div>Reference: {props.refStat.rJobTitle}</div>
        </>
      }
    />
  );
}

function WorkingPeriodValidation(props: { refStat: any }) {
  if (
    props.refStat.cStarted == null ||
    props.refStat.cEnded == null ||
    props.refStat.rStarted == null ||
    props.refStat.rEnded == null
  ) {
    return <span>N/A</span>;
  }

  const cStarted = moment(props.refStat.cStarted);
  const cEnded = moment(props.refStat.cEnded);
  const rStarted = moment(props.refStat.rStarted);
  const rEnded = moment(props.refStat.cEnded);

  return (
    <SpanTooltip
      text={
        props.refStat.cStarted == props.refStat.rStarted &&
        props.refStat.cEnded === props.refStat.rEnded ? (
          <SuccessIcon />
        ) : (
          <FailureIcon />
        )
      }
      tooltipText={
        <>
          <div>
            Candidate: {cStarted.format('MM/YYYY')}-{cEnded.format('MM/YYYY')}
          </div>
          <div>
            Reference: {rStarted.format('MM/YYYY')}-{rEnded.format('MM/YYYY')}
          </div>
        </>
      }
    />
  );
}

function FooterInfo() {
  return (
    <div className={'d-flex'}>
      <i className='bi bi-info-circle text-dark'></i>
      <span className={'ms-2 fs-5 text-dark-200'}>
        Requests can be cancelled while candidate has not assigned references.
      </span>
    </div>
  );
}

export function ReferenceRequestStatusModal(props: PropTypes) {
  if (props.surveyRequest == null) return null;

  const cancelRequest = useCallback(async () => {
    props.setReferenceRequestStatus('cancelling');
    props.onClose();
  }, [props.surveyRequest]);

  return (
    <>
      <CancelSuccessRequestModal
        surveyRequest={props.surveyRequest}
        referenceRequestStatus={props.referenceRequestStatus}
        setReferenceRequestStatus={props.setReferenceRequestStatus}
        loadSurveyRequests={props.loadSurveyRequests}
      />
      <ConfirmationModal
        title='Feedback Request Status'
        bodyClassName={'px-4 pt-4 pb-0'}
        body={
          <>
            <Row className={'gx-0'}>
              <ExcludeReferencesWithSameIpForm
                excludeReferencesWithIpWarning={
                  props.excludeReferencesWithIpWarning
                }
                onExcludeReferencesWithIpWarning={
                  props.onExcludeReferencesWithIpWarning
                }
              />
            </Row>
            <Row className={classNames('mt-3', 'gx-0')}>
              <ReferralsTable surveyRequest={props.surveyRequest} />
            </Row>
          </>
        }
        isOpen={props.isOpen}
        disableAfterConfirm={false}
        isConfirmButtonEnabled={isAbleToCancelRequest(props.surveyRequest)}
        onCancel={props.onClose}
        onConfirm={cancelRequest}
        confirmText={'Cancel Request'}
        showCancelButton={false}
        size={'lg'}
        footerInfo={<FooterInfo />}
      />
    </>
  );
}

function IpWarning(props: { names }) {
  return (
    <div>
      {props.names.length > 0 ? (
        <SpanTooltip
          text={<FailureIcon />}
          tooltipText={
            <>
              This feedback has the same IP address as {addCommas(props.names)}.
            </>
          }
          placement='bottom'
        />
      ) : (
        <SuccessIcon />
      )}
    </div>
  );
}

function SuccessIcon() {
  return <i className='bi bi-check2-circle text-success'></i>;
}

function FailureIcon() {
  return <i className='bi bi-exclamation-circle text-danger' />;
}

function addCommas(arr: string[]) {
  if (arr.length < 2) return arr[0];
  if (arr.length < 3) return arr.join(' and ');
  return arr.slice(0, -1).join(', ') + ` and ${arr.slice(-1)}`;
}

function generateReferralsStatus(surveyRequest: SurveyRequest) {
  const statuses = [];
  for (const referral of surveyRequest.referrals) {
    const status = {
      name: formatRelationship(referral.relationship),
      email: '',
      status: 'Not set',
      relationship: referral.relationship,
      ipMatchingNames: [],
      cJobTitle: null,
      rJobTitle: null,
      cStarted: null,
      cEnded: null,
      rStarted: null,
      rEnded: null,
    };

    if (referral.surveys.length > 0) {
      const survey = referral.surveys[0];
      status.name = survey.referral.name;
      status.email = survey.referral.email;
      status.status = capitalize(survey.status);
      status.ipMatchingNames = survey.ip_matching_names;
      status.cJobTitle = survey.candidate_job_title;
      status.rJobTitle = survey.reference_job_title;
      status.cStarted = survey.candidate_collaboration_started_on;
      status.cEnded = survey.candidate_collaboration_ended_on;
      status.rStarted = survey.reference_collaboration_started_on;
      status.rEnded = survey.reference_collaboration_ended_on;
    }

    statuses.push(status);
  }
  return statuses;
}

function getStatusIconTextColor(status) {
  switch (status) {
    case 'Completed':
      return 'success';
    case 'Pending':
      return 'warning';
    default:
      return 'danger';
  }
}
