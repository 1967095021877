import snakecaseKeys from 'snakecase-keys';
import { UpsertEmailTemplateDto } from '../../containers/EmailTemplates/SetupEmailTemplatePage/UpsertEmailTemplateDto';
import { EmailTemplate } from '../../entities/EmailTemplate';
import { ApiService } from '../ApiService';
import { EmailTemplateList } from '../../entities/EmailTemplateList';

export class EmailTemplateService {
  public static upsert(props: UpsertEmailTemplateDto): Promise<EmailTemplate> {
    if (props.id == null) {
      return ApiService.post<EmailTemplate>(
        '/api/v1/email_templates',
        snakecaseKeys({ emailTemplate: props }),
      );
    } else {
      return ApiService.put<EmailTemplate>(
        `/api/v1/email_templates/${props.id}`,
        snakecaseKeys({ emailTemplate: props }),
      );
    }
  }

  public static get(id: number): Promise<EmailTemplate> {
    return ApiService.get<EmailTemplate>(
      `/api/v1/email_templates/${id}`,
      snakecaseKeys({ expand: ['attachments'] }),
    );
  }

  public static list(): Promise<EmailTemplateList> {
    return ApiService.get<EmailTemplateList>(`/api/v1/email_templates`);
  }

  public static replaceVariables(
    emailTemplateId: number,
    applicationId: number,
  ): Promise<EmailTemplate> {
    return ApiService.put<EmailTemplate>(
      `/api/v1/email_templates/${emailTemplateId}/replace_email_variables`,
      {
        application_id: applicationId,
      },
    );
  }
}
