import React, { useState } from 'react';
import { Application } from '../../../../../../entities/Application';
import { getLabelForCount } from '../../../../../../utils/grammar';
import { CloseButton } from './CloseButton';
import { TabHeaders } from './TabHeaders';
import { ApplicationActions } from './ApplicationActions';
import { ApplicationQuestions } from '../ApplicationQuestions';
import { Resume } from '../Resume';
import { AlertObject } from '../../../../../../components/Alert';
import { BULK_REVIEW_NAVIGATION_TYPE } from '../../..';
import styles from '../../styles.module.scss';
import classNames from 'classnames';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';

export type BULK_REVIEW_TABS = 'resume' | 'questions';

interface PropTypes {
  application: Application;
  index: number;
  isLoadingResume: boolean;
  preSelectedRejectReasonId: number;
  totalApplications: number;
  resumeUrl?: string;
  onNext: (navType: BULK_REVIEW_NAVIGATION_TYPE) => void;
  onClose: () => void;
  setAlert: (alert: AlertObject) => void;
  setPreSelectedRejectReasonId: (id: number) => void;
}

function LoadingState(props: { redacted: boolean }) {
  return (
    <LoadingSpinner
      message={
        props.redacted ? 'Redacting candidate data from the CV...' : null
      }
    />
  );
}

function Content(props: {
  activeTab: BULK_REVIEW_TABS;
  application: Application;
  isLoadingResume: boolean;
  resumeUrl?: string;
}) {
  if (props.resumeUrl == null && props.isLoadingResume) {
    return <LoadingState redacted={props.application.redacted} />;
  }

  return (
    <div className={classNames('flex-grow-1', styles['hidden-scrollbar'])}>
      {props.activeTab === 'resume' ? (
        <Resume
          loadingElement={
            <LoadingState redacted={props.application.redacted} />
          }
          url={props.resumeUrl}
        />
      ) : (
        <ApplicationQuestions application={props.application} />
      )}
    </div>
  );
}

export function BulkApplicationReviewHeader(props: PropTypes) {
  const [activeTab, setActiveTab] = useState<BULK_REVIEW_TABS>('resume');

  return (
    <>
      <div className='border-bottom px-4 mt-4'>
        <CloseButton onClose={props.onClose} />
        <div className='fs-5 text-dark-200 flex-shrink-0'>
          Reviewing {props.index + 1} out of{' '}
          {getLabelForCount('Candidate', props.totalApplications)}
        </div>
        <div className='d-flex mt-4 justify-content-between align-items-center'>
          <TabHeaders activeTab={activeTab} onTabChange={setActiveTab} />
          <ApplicationActions {...props} />
        </div>
      </div>
      <Content
        activeTab={activeTab}
        application={props.application}
        resumeUrl={props.resumeUrl}
        isLoadingResume={props.isLoadingResume}
      />
    </>
  );
}
