import React, { useCallback, useEffect, useState } from 'react';
import { TranscriptToggle } from '../../../components/Interview/TranscriptToggle';
import { TranscriptSearchBox } from '../TranscriptSearchBox';
import { Col, Row } from 'reactstrap';
import forwardButton from '../../../images/forward_10_black_24dp.svg';
import backwardButton from '../../../images/replay_10_black_24dp.svg';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { PlaybackRatePicker } from '../../../components/VideoPlayer/PlaybackRatePicker';
import { VolumeSlider } from '../../../components/VideoPlayer/VolumeSlider';

interface PropTypes {
  isPlaying: boolean;
  volume: number;
  muted: boolean;
  playbackRate: number;
  isTranscriptVisible: boolean;
  searchOccurrences: number;
  selectedSearchOccurenceIndex: number | null;
  onPressPlay: () => void;
  onPressPause: () => void;
  onSkipForward: () => void;
  onSkipBackward: () => void;
  onVolumeChange: (volume: number, muted: boolean) => void;
  onPlaybackRateChange: (playbackRate: number) => void;
  onDisplayTranscript: () => void;
  onDisplayVideo: () => void;
  onFindString: (findString: string) => void;
  onGoToPreviousSearchOccurence: () => void;
  onGoToNextSearchOccurence: () => void;
  onFullScreen: (enable: boolean) => void;
}

export const Controls = React.memo(function Controls(props: PropTypes) {
  const togglePlay = useCallback(
    (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;
      if (e.key !== ' ') return;
      if (target.nodeName == 'INPUT' || target.nodeName == 'TEXTAREA') return;
      if (target.isContentEditable) return;

      e.preventDefault();
      if (props.isPlaying) return props.onPressPause();
      props.onPressPlay();
    },
    [props.isPlaying, props.onPressPlay, props.onPressPause],
  );

  const [searchExpanded, setSearchExpanded] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', togglePlay);
    return () => document.removeEventListener('keydown', togglePlay);
  }, [togglePlay]);

  return (
    <Row className='h-100 align-items-center justify-content-between'>
      <Col xs={searchExpanded ? '9' : '3'}>
        <TranscriptSearchBox
          onFindString={props.onFindString}
          searchOccurrences={props.searchOccurrences}
          selectedSearchOccurenceIndex={
            props.searchOccurrences !== 0
              ? props.selectedSearchOccurenceIndex
              : null
          }
          onGoToPreviousSearchOccurence={props.onGoToPreviousSearchOccurence}
          onGoToNextSearchOccurence={props.onGoToNextSearchOccurence}
          expanded={searchExpanded}
          onExpand={setSearchExpanded}
        />
      </Col>
      {!searchExpanded && (
        <Col xs='6' xl='auto'>
          <Row className='no-wrap text-center justify-content-center align-items-center'>
            <Col xs='auto px-1'>
              <PlaybackRatePicker
                playbackRate={props.playbackRate}
                onPlaybackRateChange={props.onPlaybackRateChange}
              />
            </Col>
            <Col xs='auto px-1'>
              <a
                className={classNames(styles.controlButtonsSvg)}
                href='#skip-backward'
                onClick={() => props.onSkipBackward()}
              >
                <img
                  className={styles['back-and-forward-options']}
                  src={backwardButton}
                />
              </a>
            </Col>
            <Col xs='auto' className='pt-1' style={{ fontSize: '1.5rem' }}>
              {props.isPlaying ? (
                <a
                  href='#pause'
                  className={classNames(
                    'bi',
                    'bi-pause-circle',
                    styles.controlButtons,
                  )}
                  onClick={() => props.onPressPause()}
                />
              ) : (
                <a
                  href='#play'
                  className={classNames(
                    'bi',
                    'bi-play-circle',
                    styles.controlButtons,
                  )}
                  onClick={() => props.onPressPlay()}
                />
              )}
            </Col>
            <Col xs='auto px-1'>
              <a
                className={classNames(styles.controlButtonsSvg)}
                href='#skip-forward'
                onClick={() => props.onSkipForward()}
              >
                <img
                  className={styles['back-and-forward-options']}
                  src={forwardButton}
                />
              </a>
            </Col>
            <Col xs='auto px-1'>
              <VolumeSlider
                volume={props.volume}
                muted={props.muted}
                onVolumeChange={props.onVolumeChange}
              />
            </Col>
          </Row>
        </Col>
      )}
      <Col xs='3'>
        <Row className='g-0 align-items-end'>
          <Col xs='12'>
            <TranscriptToggle
              isTranscriptVisible={props.isTranscriptVisible}
              onDisplayTranscript={props.onDisplayTranscript}
              onDisplayVideo={props.onDisplayVideo}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
});
