import { InterviewRating } from '../../entities/InterviewRating';
import { ApiService } from '../ApiService';

export class InterviewRatingService {
  public static create(
    interviewId: number,
    value: string,
  ): Promise<InterviewRating> {
    return ApiService.post(
      `/api/interview_intelligence/interview_ratings/${interviewId}`,
      { rating: value },
    );
  }
}
