import React from 'react';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import ChromeStore from '../../../../images/logos/chrome-store.png';

const EXTENSION_LINK =
  'https://chrome.google.com/webstore/detail/screenloop/gdpkbebflcgjacfhhekofebajjacgidj';

export function ExtensionPanel() {
  if (document.querySelector('meta[name="screenloop-extension"]')) return null;

  return (
    <DeprecatedPanel className='px-4 py-3 mb-0'>
      <div className='fs-4 fw-semibold mb-1'>Screenloop Chrome Extension</div>
      <div className='d-flex align-items-center'>
        <a
          href={EXTENSION_LINK}
          className='d-block me-auto'
          target='_blank'
          rel='noreferrer'
        >
          <img src={ChromeStore} style={{ width: '160px' }} />
        </a>
        <a
          href={EXTENSION_LINK}
          className='btn btn-info'
          target='_blank'
          rel='noreferrer'
        >
          Add to Chrome
        </a>
      </div>
    </DeprecatedPanel>
  );
}
