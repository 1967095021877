import { RedactedResume } from '../../../../entities/v1/applicant_tracking/RedactedResume';
import { ApiService } from '../../../ApiService';

export class RedactedResumeService {
  public static show(id: number): Promise<RedactedResume> {
    return ApiService.get<RedactedResume>(
      `/api/v1/applicant_tracking/redacted_resumes/${id}`,
    );
  }
}
