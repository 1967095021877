import classNames from 'classnames';
import React from 'react';
import { ApplicationSidekick } from '../../../entities/ApplicationSidekick';
import screenloopLogo from '../../../images/logo-white.svg';
import leverLogo from '../../../images/logos/lever.png';
import greenhouseLogo from '../../../images/logos/greenhouse.png';
import workableLogo from '../../../images/logos/workable.png';
import linkedinLogo from '../../../images/logos/linkedin.png';
import styles from './styles.module.scss';
import { SidekickHeaderLogo } from './SidekickHeaderLogo';
import { humanize } from '../../../utils/humanize';

interface PropTypes {
  application: ApplicationSidekick;
}

function getProviderLogo(provider: string): string {
  switch (provider.toLowerCase()) {
    case 'greenhouse':
      return greenhouseLogo;
    case 'lever':
      return leverLogo;
    case 'workable':
      return workableLogo;
    default:
      return '';
  }
}

function CandidateName(props: { candidateName: string }) {
  return (
    <div>
      <h3 className='mb-0'>{props.candidateName}</h3>
    </div>
  );
}

function JobName(props: { jobName: string }) {
  return (
    <label
      className={classNames(
        'rounded-pill',
        'mx-2',
        'px-2h',
        'bg-light-info',
        'fs-6',
      )}
    >
      {props.jobName}
    </label>
  );
}

function ScreenloopLogo() {
  return (
    <div className={classNames('p-2', 'ps-3', 'bg-dark-sidebar')}>
      <a className='app-logo' href='#'>
        <img
          className={classNames('logo-icon', styles.screenloop)}
          src={screenloopLogo}
        />
      </a>
    </div>
  );
}

function CandidateInfoLogos(props: PropTypes) {
  return (
    <>
      <SidekickHeaderLogo
        url={props.application.candidateProfileUrl}
        logoUrl={getProviderLogo(props.application.applicationProvider)}
        tooltipText={`Open candidate in ${humanize(
          props.application.applicationProvider,
        )}`}
      />
      <SidekickHeaderLogo
        url={props.application.candidateLinkedinUrl}
        logoUrl={linkedinLogo}
        tooltipText={`Open candidate LinkedIn profile`}
      />
    </>
  );
}

export function SidekickHeader(props: PropTypes) {
  return (
    <>
      <ScreenloopLogo />
      <div
        className={classNames(
          'd-flex',
          'align-items-center',
          'p-3',
          'bg-white',
        )}
      >
        <CandidateName candidateName={props.application.candidateName} />
        <JobName jobName={props.application.jobName} />
        <CandidateInfoLogos {...props} />
      </div>
    </>
  );
}
