import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { ApprovalFlowBody } from '../components/ApprovalFlowBody';
import { ApprovalFlow } from '../../../../entities/applicant_tracking/ApprovalFlow';
import { Card } from '../../../../components/Card';
import { appendRedirectUri } from '../../../../utils/url';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';
import { IconSpan } from '../../../../components/IconSpan';
import { ViewOrEditHeader } from '../components/ViewOrEditHeader';
import { loadApprovalFlow, mapAssignedLocationOrDepartmentIds } from '../utils';

interface PropTypes {
  id: number;
}

export default function ShowApprovalFlowPage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState(true);
  const [approvalFlow, setApprovalFlow] = useState<ApprovalFlow>(null);

  useEffect(() => {
    loadApprovalFlow(props.id).then((approvalFlow) => {
      setApprovalFlow(approvalFlow);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className='container'>
      <ViewOrEditHeader approvalFlow={approvalFlow} />
      <Card>
        <SetupPageHeader
          title='Approval flow'
          firstItems={[]}
          titleClassNames='mb-0'
          classNames='align-items-center'
          intermediateItems={[
            {
              title: <IconSpan spanText='Edit' icon={{ name: 'bi-pencil' }} />,
              type: 'Navigate',
              action: () =>
                (window.location.href = appendRedirectUri(
                  `/organization_settings/approval_flows/${props.id}/edit`,
                )),
            },
          ]}
        />
        <ApprovalFlowBody
          readOnly
          name={approvalFlow.name}
          assignedTo={approvalFlow.assignedTo}
          requisitionType={approvalFlow.requisitionType}
          approvalSteps={approvalFlow.steps}
          assignedLocationOrDepartmentIds={mapAssignedLocationOrDepartmentIds(
            approvalFlow,
          )}
          disabledLocationIds={[]}
          disabledDepartmentIds={[]}
          organizationFlowExists={true}
        />
      </Card>
    </div>
  );
}
