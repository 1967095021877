import React from 'react';
import classNames from 'classnames';

const MAX_LENGTH = 512;
const ROWS = 5;

interface PropTypes {
  disableEditEmailBody: boolean;
  emailBody: string;
  setEmailBody: (arg0: string) => void;
  setCount: (arg0: number) => void;
  count: number;
}

export function TextArea(props: PropTypes) {
  return (
    <div className='mb-0'>
      <textarea
        className={classNames(
          'form-control fs-5',
          props.disableEditEmailBody
            ? 'bg-gray text-gray-900'
            : 'bg-white text-dark',
        )}
        value={props.emailBody}
        onChange={(e) => {
          props.setEmailBody(e.target.value);
          props.setCount(e.target.value.length);
        }}
        rows={ROWS}
        disabled={props.disableEditEmailBody}
        maxLength={MAX_LENGTH}
      ></textarea>
      <p
        className={classNames(
          'd-flex justify-content-end mt-2 fs-6 fw-normal text-dark-200',
        )}
      >{`${props.count}/${MAX_LENGTH}`}</p>
    </div>
  );
}
