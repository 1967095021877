import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import _ from 'lodash';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ChartArea,
  ChartData,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NpsExplainabilityEntry } from '../../../../../entities/candidate_experience/NpsExplainabilityEntry';
import { npsExplainabilityBubbleChartOptions } from './npsExplainabilityBubbleChartOptions';
import {
  getLimitsInfo,
  updateNpsExplainabilityBubbleChartOptionAxis,
} from './updateNpsExplainabilityBubbleChartOptionAxis';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const ENTRIES_TO_DISPLAY = 5;

function adjustCoordinates(
  bubbles: {
    x: number;
    y: number;
    r: number;
    labels_dimensions_values: { attribute: string; value: string }[];
  }[],
  xStep: number,
  yStep: number,
) {
  function getY(bubbleIndex: number, groupSize: number, radius: number) {
    return Math.sin(((2 * Math.PI) / groupSize) * bubbleIndex) * radius;
  }
  function getX(bubbleIndex: number, groupSize: number, radius: number) {
    return Math.cos(((2 * Math.PI) / groupSize) * bubbleIndex) * radius;
  }
  function reduceBubbleLabel(
    dimensions_values: { attribute: string; value: string }[],
  ) {
    return dimensions_values.map((dv) => {
      dv.value = dv.value.split(' ')[0].slice(0, 1);
      return dv;
    });
  }

  for (let i = 0; i < bubbles.length; i++) {
    bubbles[i].x =
      bubbles[i].x + getX(i, bubbles.length, xStep * (bubbles[i].r / 100));
    bubbles[i].y =
      bubbles[i].y + getY(i, bubbles.length, yStep * (bubbles[i].r / 100));
    bubbles[i].labels_dimensions_values = reduceBubbleLabel(
      bubbles[i].labels_dimensions_values,
    );
  }
  return bubbles;
}

function filterOverlappedGroups(groups: NpsExplainabilityEntry[]) {
  function checkOverlappedDimensions(
    g: NpsExplainabilityEntry,
    index: number,
    groups: NpsExplainabilityEntry[],
  ) {
    const remainingGroups = groups
      .slice(0, index)
      .concat(groups.slice(index + 1, groups.length));
    return remainingGroups.some((r) =>
      r.dimensions_values.every((val) =>
        g.dimensions_values.some(
          (item) =>
            item.attribute === val.attribute && item.value === val.value,
        ),
      ),
    );
  }

  function filterGroups(groups: NpsExplainabilityEntry[]) {
    return groups.filter((g, index, groups) =>
      groups.length === 1 ? true : !checkOverlappedDimensions(g, index, groups),
    );
  }

  const grouped = _(groups).groupBy(
    (item) => `${item.nps}-${item.count}-${item.impact}`,
  );
  const merged = grouped.map((g, _x) => g).value();
  const filtered = merged.map((g) =>
    filterGroups(g).sort((a, b) =>
      a.dimensions_values.length > b.dimensions_values.length ? 1 : -1,
    ),
  );
  return filtered.flat();
}

function showOverlappedGroups(
  groups: {
    x: number;
    y: number;
    r: number;
    labels_dimensions_values: { attribute: string; value: string }[];
  }[],
  xRange: number,
) {
  const tmp = _(groups).groupBy((item) => `${item.x}-${item.y}`);
  const merged = tmp
    .map((g, _x) => {
      return g;
    })
    .value();
  merged.map((m) => {
    if (m.length > 1) {
      adjustCoordinates(m, xRange * 0.05, 1);
    }
  });
  return merged.flat();
}

function convertImpactToBubbleSize(impact: number, min = 10, max = 100) {
  return (impact * (max - min)) / 100 + min;
}

function mapGroupData(props: NpsExplainabilityEntry) {
  return {
    x: props.count,
    y: +props.nps,
    r: convertImpactToBubbleSize(props.impact),

    responses: props.count,
    nps: props.nps,
    impact: props.impact,
    tooltips_dimensions_values: props.dimensions_values,
    labels_dimensions_values: props.dimensions_values.map((dv) => ({
      attribute: dv.attribute,
      value: dv.value,
    })),
  };
}

const BottomGroupColors = ['#E76A6BCC', '#FA8485CC', '#FA8485CC'];
const TopGroupColors = ['#40C74DCC', '#5DD868CC', '#5DD868CC'];

function createGradient(
  ctx: CanvasRenderingContext2D,
  area: ChartArea,
  colorGroup,
) {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorGroup[2]);
  gradient.addColorStop(0.4, colorGroup[1]);
  gradient.addColorStop(1, colorGroup[0]);

  return gradient;
}
function mapData(
  topEntries: NpsExplainabilityEntry[],
  bottomEntries: NpsExplainabilityEntry[],
  chart,
  xRange,
) {
  return {
    datasets: [
      {
        label: 'Top Groups',
        data: showOverlappedGroups(
          topEntries.map((e) => mapGroupData(e)),
          xRange,
        ),
        backgroundColor: chart
          ? createGradient(chart.ctx, chart.chartArea, TopGroupColors)
          : null,
      },
      {
        label: 'Bottom Groups',
        data: showOverlappedGroups(
          bottomEntries.map((e) => mapGroupData(e)),
          xRange,
        ),
        backgroundColor: chart
          ? createGradient(chart.ctx, chart.chartArea, BottomGroupColors)
          : null,
      },
    ],
  };
}

interface PropTypes {
  topEntries: NpsExplainabilityEntry[];
  bottomEntries: NpsExplainabilityEntry[];
  standardNpsCalculation: boolean;
}
export function NpsExplainabilityBubble(props: PropTypes) {
  const chartRef = useRef<ChartJS>(null);
  const setChartRef = useCallback((chart) => {
    chartRef.current = chart;
  }, []);
  const [chartData, setChartData] = useState<ChartData<'bubble'>>({
    datasets: [],
  });

  const topEntriesToDisplay = useMemo(() => {
    return filterOverlappedGroups(props.topEntries).slice(
      0,
      ENTRIES_TO_DISPLAY,
    );
  }, [props.topEntries]);

  const bottomEntriesToDisplay = useMemo(() => {
    return filterOverlappedGroups(props.bottomEntries).slice(
      0,
      ENTRIES_TO_DISPLAY,
    );
  }, [props.bottomEntries]);

  const axisInfo = getLimitsInfo(
    topEntriesToDisplay.concat(bottomEntriesToDisplay),
  );
  updateNpsExplainabilityBubbleChartOptionAxis(
    axisInfo,
    npsExplainabilityBubbleChartOptions(props.standardNpsCalculation),
  );

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = mapData(
      topEntriesToDisplay,
      bottomEntriesToDisplay,
      chartRef.current,
      axisInfo.xRange,
    );

    setChartData(chartData);
  }, [topEntriesToDisplay, bottomEntriesToDisplay]);

  updateNpsExplainabilityBubbleChartOptionAxis(
    axisInfo,
    npsExplainabilityBubbleChartOptions(props.standardNpsCalculation),
  );
  return (
    <div style={{ height: '512px', width: '100%' }}>
      <Bubble
        plugins={[ChartDataLabels]}
        options={npsExplainabilityBubbleChartOptions(
          props.standardNpsCalculation,
        )}
        data={chartData}
        ref={setChartRef}
      />
    </div>
  );
}
