import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { SelectOption } from '../../../../../../components/Select';
import { Control, useWatch } from 'react-hook-form';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { Chip, ChipColor } from '../../../../../../components/Chip';
import { JobDetails } from '../../../../../../services/applicant_tracking/JobService/EditJobDto';
import { PipelineDetail } from './WorkflowDetail';
import { JobStageTemplateInput } from '../../../../../PlanTemplates/SetupPlanTemplatePage';
import { AlertMessage } from '../../../../../../components/AlertMessage';
import { PlanTemplateList } from '../../../../../../entities/v1/applicant_tracking/PlanTemplateList';
import { PlanTemplate } from '../../../../../../entities/v1/applicant_tracking/PlanTemplate';
import { PlanTemplate as OldPlanTemplate } from '../../../../../../entities/applicant_tracking/PlanTemplate';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';

interface PropTypes {
  jobDetails: JobDetails;
  formControl: Control<CreateJobDto>;
  planTemplateList: PlanTemplateList;
  planTemplate: OldPlanTemplate | undefined;
  focusedJobStage: JobStageTemplateInput;
  setFocusedJobStage: (jobStage: JobStageTemplateInput) => void;
}

function GlobalTemplateSelectedAlert() {
  return (
    <AlertMessage
      hasOverlay={false}
      icon={{ name: 'bi-info-circle' }}
      className='mt-3 fs-5 bg-light-danger'
      text={
        <span>
          <b>Important!</b> You have selected a default template that you
          won&#39;t be able to edit in the future. Please select or create
          another workflow if you plan to make changes!
        </span>
      }
    />
  );
}

function mapPlanTemplateToOption(pt: PlanTemplate): SelectOption {
  return {
    value: pt.id.toString(),
    label: pt.name,
    subLabel: (
      <Col xs='4'>
        <Chip
          className='d-block mw-100 text-truncate fw-semibold'
          color={ChipColor.Gray}
        >
          {pt.stagePlanTemplateIds.length} stages
        </Chip>
      </Col>
    ),
  };
}

export function JobWorkflowPipeline(props: PropTypes) {
  const jobWorkflowIdControllerName = 'jobWorkflowId';
  const watchWorkflowId = useWatch({
    control: props.formControl,
    name: jobWorkflowIdControllerName,
  });
  const options = props.planTemplateList.planTemplates.map((pt) =>
    mapPlanTemplateToOption(pt),
  );

  useEffect(() => props.setFocusedJobStage(null), [watchWorkflowId]);

  return (
    <>
      <Row>
        <Col xs='3'>
          <LabelledSelectFormHook<CreateJobDto>
            label={'Job Workflow'}
            placeholder={'Select job workflow'}
            formHookContext={{
              controllerName: jobWorkflowIdControllerName,
              formControl: props.formControl,
              required: true,
            }}
            options={options}
            disabled={props.jobDetails?.applicationsCount > 0}
          />
        </Col>
      </Row>
      <Detail
        id={watchWorkflowId}
        planTemplate={props.planTemplate}
        focusedJobStage={props.focusedJobStage}
        onFocusJobStage={props.setFocusedJobStage}
      />
    </>
  );
}

function Detail(props: {
  id: string | undefined;
  planTemplate: OldPlanTemplate | undefined;
  focusedJobStage: JobStageTemplateInput;
  onFocusJobStage: (jobStage: JobStageTemplateInput) => void;
}) {
  if (!props.id) return null;
  if (!props.planTemplate) return <LoadingSpinner />;

  return (
    <div>
      {props.planTemplate?.globalTemplate && <GlobalTemplateSelectedAlert />}
      {props.planTemplate && (
        <PipelineDetail
          planTemplate={props.planTemplate}
          focusedJobStage={props.focusedJobStage}
          setFocusedJobStage={props.onFocusJobStage}
        />
      )}
    </div>
  );
}
