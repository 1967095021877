/**
 * A number with 5 digits (EXXYY)
 * E: Engine Identifier: 0 (PLAT), 1 (ATS), 2 (SE), ...
 * XX: Entity error
 * YY: Error sub-type
 *
 * The handler handleFormErrors should handle all errors present in this enum.
 */
export enum ErrorCodes {
  INVALID_PARAMETER_VALUE = 'invalid_param_value',

  ATS_CANDIDATE_EMAIL_DUPLICATED = 10101,
  ATS_CANDIDATE_PHONE_NUMBER_DUPLICATED = 10102,
  ATS_CANDIDATE_AND_REFERRER_EMAIL_MATCH = 10103,
  ATS_JOB_DUPLICATED = 10201,

  ATS_LOCATION_DUPLICATED = 10301,

  ATS_PLAN_TEMPLATE_NAME_DUPLICATED = 10401,

  ATS_OFFER_LETTER_TEMPLATE_NAME_DUPLICATED = 10501,
}
