import React from 'react';
import { RadioPill } from '../../../../components/RadioPill';

export type FILTER_TYPE = 'self' | 'hiring_team';

interface PropTypes {
  selectedValue?: FILTER_TYPE;
  onChange: (value: FILTER_TYPE) => void;
}

export function FilterByPills(props: PropTypes) {
  return (
    <div className='d-flex align-items-center'>
      <RadioPill
        id='self'
        label='My Own'
        value='self'
        selectedValue={props.selectedValue}
        onChange={() => {
          props.onChange('self');
        }}
        className='me-2'
      />
      <RadioPill
        id='hiring_team'
        label='Hiring Team'
        value='hiring_team'
        selectedValue={props.selectedValue}
        onChange={() => {
          props.onChange('hiring_team');
        }}
      />
    </div>
  );
}
