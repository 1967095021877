import React from 'react';
import { Icon } from '../Icon';
import { SpanTooltip } from '../SpanTooltip';

interface PropTypes {
  label: string;
  tooltipText?: string;
}

export function TableHeader(props: PropTypes) {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      {props.tooltipText && (
        <SpanTooltip
          className='pe-2 me-1 text-info'
          placement='top'
          text={<Icon name={'bi-info-circle'} />}
          tooltipText={props.tooltipText}
          tooltipStyle={{ maxWidth: '250px' }}
        />
      )}
      <span className='text-truncate d-block'>{props.label}</span>
    </div>
  );
}
