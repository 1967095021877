import { getQueryParam, QueryParam } from '../../../../../utils/url';

export const NAME_QUERY_PARAM = 'name';

export function parseName(): string {
  return getQueryParam(NAME_QUERY_PARAM) ?? null;
}

export function mapNameToQueryParam(value?: string): QueryParam {
  return {
    key: NAME_QUERY_PARAM,
    value: value,
  };
}
