import { Moment } from 'moment';
import React from 'react';
import { FormCheck } from './FormCheck';

interface PropTypes {
  startDate: Moment;
  lastSevenDays: Moment;
  setStartDate: (day: Moment) => void;
  setShowCalendar: (showCalendar: boolean) => void;
  today: Moment;
}

export function ApplicationUpdatesForm(props: PropTypes) {
  function handleClick(date: Moment) {
    props.setStartDate(date);
    props.setShowCalendar(false);
  }
  return (
    <form className='d-flex align-items-center'>
      <FormCheck
        defaultChecked={props.startDate === props.lastSevenDays}
        onClick={() => {
          handleClick(props.lastSevenDays);
        }}
        title={'Last 7 days'}
      />
      <FormCheck
        defaultChecked={props.startDate === props.today}
        onClick={() => {
          handleClick(props.today);
        }}
        title={'Today'}
      />
      <FormCheck
        defaultChecked={
          props.startDate !== props.today &&
          props.startDate !== props.lastSevenDays
        }
        onClick={() => {
          props.setShowCalendar(true);
        }}
        title={'Apply Custom Date'}
      />
    </form>
  );
}
