import { SourceReferrer } from './SourceReferrer';

export const ChannelTypes = [
  'agencies',
  'company_marketing',
  'employee_referrals',
  'events_and_programs',
  'external_job_boards',
  'sourcing',
];

export type ChannelType = typeof ChannelTypes[number];
export interface Source {
  id: number;
  displayName?: string;
  name: string;
  channelType: ChannelType;
  enabledOnCareers: boolean;
  sourceReferrer: SourceReferrer;
}
