import classNames from 'classnames';
import React from 'react';

export function TextField(props: {
  label: string;
  value: string;
  name: string;
  className?: string;
  mandatory?: boolean;
  placeholder?: string;
  error?: string;
  onInputChange: (name: string, value: unknown) => void;
}) {
  return (
    <div className={props.className}>
      <label className='form-label'>
        {props.label}
        {props.mandatory && <span className='ps-1 text-danger'>*</span>}
      </label>
      <input
        type='text'
        className={classNames(
          props.error && 'border-danger',
          'form-control fs-5',
        )}
        value={props.value ?? undefined}
        onChange={(event) =>
          props.onInputChange(props.name, event.target.value)
        }
        placeholder={props.placeholder}
      />
      {props.error && (
        <div className='text-danger-500 mt-2 fs-5'>{props.error}</div>
      )}
    </div>
  );
}
