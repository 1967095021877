import React, { useState } from 'react';
import { EmailDomain } from '../../../../entities/EmailDomain';
import { DnsTable } from './DnsTable';
import { AlertType } from '../../../../components/Alert';
import AlertToast from '../AlertToast';

export interface PropTypes {
  emailDomain: EmailDomain;
}

export function DnsSettingsRow(props: PropTypes) {
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(AlertType.Success);

  function onAlert(message: string, alertType: AlertType = AlertType.Success) {
    setAlertType(alertType);
    setAlertMessage(message);
  }

  return (
    <div className='row justify-content-between align-items-center'>
      <div className='col'>
        <div className='row'>
          <div className='col-6 fs-5'>
            <h4>DNS Settings</h4>
          </div>
          <div className='col-12 fs-5 fw-2 text-dark-200'>
            Settings to configure and verify your domain.
          </div>
        </div>
      </div>
      <div className='pt-4'>
        <AlertToast
          type={alertType}
          message={alertMessage}
          onClose={() => {
            setAlertMessage(null);
            setAlertType(AlertType.Success);
          }}
        />
        <DnsTable emailDomain={props.emailDomain} onAlert={onAlert} />
      </div>
    </div>
  );
}
