import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../ApiService';
import { OverallStatus } from '../../../entities/v1/OverallStatus';

export interface ListParams {
  type?: string;
}

export class RecordingsSettingsService {
  public static overallStatus(params: ListParams): Promise<OverallStatus> {
    return ApiService.get<OverallStatus>(
      '/api/v1/recordings_settings/overall_status',
      snakecaseKeys(params),
    );
  }
}
