import React from 'react';
import { Badge } from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  topicName: string;
  totalMentions: number;
  percentageOfTotal: number;
}

export function FollowUpResponsesTopicsPanelRow(props: PropTypes) {
  return (
    <tr>
      <td>
        <Badge
          className={classNames(
            'd-flex rounded-pill me-0 fs-5 text-left',
            styles.grayBadge,
          )}
        >
          {props.topicName}
        </Badge>
      </td>
      <td>
        <Badge
          className={classNames(
            'd-flex rounded-pill me-0 fs-5 justify-content-center',
            styles.grayBadge,
          )}
        >
          {props.totalMentions}
        </Badge>
      </td>
      <td>
        <Badge
          className={classNames(
            'd-flex rounded-pill me-0 fs-5 justify-content-center',
            styles.grayBadge,
          )}
        >
          {props.percentageOfTotal.toFixed(0)}%
        </Badge>
      </td>
    </tr>
  );
}
