import { DateFilter } from '../../../components/DateRangePicker';
import { CandidateAnalytics } from '../../../entities/candidate_experience/CandidateAnalytics';
import { ApiService } from '../../ApiService';

export class AnalyticsService {
  public static get(
    filter_selector: string,
    job_names: string[],
    department_names: string[],
    hiring_manager_names: string[],
    recruiter_names: string[],
    location_names: string[],
    survey_template_ids: string[],
    benchmark_type: string,
    dateRange: DateFilter,
    ethnicities: string[],
    genders: string[],
    ageRanges: string[],
  ): Promise<CandidateAnalytics> {
    return ApiService.get<CandidateAnalytics>(
      '/api/candidate_experience/candidate_analytics/overview',
      {
        filter_selector,
        job_names,
        department_names,
        hiring_manager_names,
        recruiter_names,
        location_names,
        survey_template_ids,
        benchmark_type,
        start_date: dateRange.start.toJSON(),
        end_date: dateRange.end.toJSON(),
        date_range: dateRange.range,
        ethnicities: ethnicities,
        genders: genders,
        age_ranges: ageRanges,
      },
    );
  }
}
