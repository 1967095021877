import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { DateFilter } from '../../../../../components/DateRangePicker';
import { OverallNps } from '../../../../../components/survey_engine/OverallNpsPanels/OverallNps';
import { OverallNpsTooltipText } from '../../../../../components/survey_engine/OverallNpsPanels/OverallNps/OverallNpsTooltipText';
import { OverallNpsHistory } from '../../../../../components/survey_engine/OverallNpsPanels/OverallNpsHistory';
import { SurveyMetricsPanel } from '../../../../../components/survey_engine/SurveyMetricsPanel';
import { NpsBenchmark } from '../../../../../entities/candidate_experience/NpsBenchmark';
import { NhsTeamBreakdown } from '../../../../../entities/quality_of_hire/NhsTeamBreakdown';
import { Nps } from '../../../../../entities/survey_engine/Nps';
import { NpsHistory } from '../../../../../entities/survey_engine/NpsHistory';
import { SurveyMetrics } from '../../../../../entities/survey_engine/SurveyMetrics';
import { REPORTING_SECTION_CLASS } from '../../../../../utils/pdfHandler';
import { NpsTeamBreakdown } from './NpsTeamBreakdown';

interface PropTypes {
  npsTitle: string;
  npsDescription: string;
  npsPromotersText: string;
  npsNeutralsText: string;
  npsDetractorsText: string;
  nps: Nps;
  npsHistory: NpsHistory[];
  npsBenchmark: NpsBenchmark;
  npsTrend: number;
  dateRange: DateFilter;
  exportToPDF: boolean;
  surveyMetrics: SurveyMetrics;
  nhsTeamBreakdown: NhsTeamBreakdown;
  hasNpsTeamBreakdown: boolean;
}

export function FirstRow(props: PropTypes) {
  return (
    <Row className={classNames('gy-4 mb-3', REPORTING_SECTION_CLASS)}>
      <Col sm='12' lg='5'>
        <div className='d-flex flex-column h-100'>
          <Row className='mb-3'>
            <Col sm='12' lg='12'>
              <SurveyMetricsPanel
                surveyMetrics={props.surveyMetrics}
                benchmarkLabel={'Best Practice'} // PLACEHOLDER
              />
            </Col>
          </Row>
          <OverallNps
            nps={props.nps}
            npsTrend={props.npsTrend}
            title={props.npsTitle}
            detractorsText={props.npsDetractorsText}
            neutralsText={props.npsNeutralsText}
            promotersText={props.npsPromotersText}
            tooltipText={<OverallNpsTooltipText {...props} />}
          />
        </div>
      </Col>
      <Col sm='12' lg='7'>
        <div className='d-flex flex-column'>
          <OverallNpsHistory
            npsHistory={props.npsHistory}
            npsBenchmark={props.npsBenchmark}
            dateRange={props.dateRange}
            title={`${props.npsTitle} History`}
            exportToPDF={props.exportToPDF}
          />
          <div className='mt-3'>
            <NpsTeamBreakdown npsTeamBreakdown={props.nhsTeamBreakdown} />
          </div>
        </div>
      </Col>
    </Row>
  );
}
