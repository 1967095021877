import React from 'react';
import { AlertType } from '../../../../../../../components/Alert';
import { IconButton } from '../../../../../../../components/IconButton';

export interface PropTypes {
  content: string;
  contentType?: string;
  onAlert: (message: string, alertType: AlertType) => void;
}

export default function CopyButton(props: PropTypes) {
  async function handleCopyButtonClick() {
    await navigator.clipboard.writeText(props.content);
    props.onAlert(`${props.contentType} has been copied!`, AlertType.Success);
  }

  return (
    <IconButton
      iconName='bi-files'
      color='borderless'
      onClick={handleCopyButtonClick}
    />
  );
}
