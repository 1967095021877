import React from 'react';
import { QueryClientProvider, useMutation } from 'react-query';
import { queryClient } from '../../../../config/queryClient';
import { HiringMemberSettings } from '../HiringMemberSettings';
import { RecordingsSettingsService } from '../../../../services/v1/RecordingsSettingsService';
import { HiringMemberSettingsType } from '../../../../entities/HiringMemberSettingsType';
import { OrganizationService } from '../../../../services/v1/OrganizationService';

export interface PropTypes {
  recordingSettingPath: string;
  title: string;
  activateText: string;
  hiringMemberSettingsType: HiringMemberSettingsType;
  organizationAutoRecordingEnabled: boolean;
  organizationOptoutEnabled?: boolean;
  settingsQueryKey: string;
}

export default function AutoRecordSettings(props: PropTypes) {
  return (
    <QueryClientProvider client={queryClient}>
      <AutoRecordSettingsPage {...props} />
    </QueryClientProvider>
  );
}

export function AutoRecordSettingsPage(props: PropTypes) {
  function queryFn() {
    return RecordingsSettingsService.overallStatus({
      type: 'auto_recording',
    });
  }

  const mutation = useMutation<void, void, boolean>({
    mutationFn: (data) => OrganizationService.updateAutoRecord(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [props.settingsQueryKey],
      });
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <HiringMemberSettings
        organizationSettingEnabled={props.organizationAutoRecordingEnabled}
        queryFn={queryFn}
        mutation={mutation}
        {...props}
      />
    </QueryClientProvider>
  );
}
