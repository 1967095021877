import classNames from 'classnames';
import React from 'react';
import { Row } from 'reactstrap';
import { Card, CardTitle } from '../../../../components/Card';
import { Application } from '../../../../entities/Application';
import { SurveyQuestion } from '../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../../../entities/survey_engine/SurveyQuestionAnswer';
import { SurveyQuestionFieldType } from '../../../../entities/survey_engine/SurveyQuestionFieldType';

interface PropTypes {
  application: Application;
}

export function ApplicationQuestionsPanel(props: PropTypes) {
  const survey = props.application.survey;
  if (survey == null) return null;

  // Questions without QuestionFields are not answerable.
  const questions = survey.sections
    .flatMap((section) => section.questions)
    .filter((question) => question.fields.length > 0);
  const answers = survey.answers.filter((answer) => answer.fields.length > 0);
  if (answers.length === 0) return null;

  return (
    <Row className='mb-4'>
      <Card className={classNames('p-4 h5 mb-0')}>
        <CardTitle>Application Questions</CardTitle>
        <div className='mt-4 d-flex flex-column gap-4h'>
          {answers.map((answer) => (
            <Answer
              key={answer.question_id}
              answer={answer}
              question={questions.find(
                (question) => question.id === answer.question_id,
              )}
            />
          ))}
        </div>
      </Card>
    </Row>
  );
}

interface QuestionAnswerPropTypes {
  question: SurveyQuestion;
  answer: SurveyQuestionAnswer;
}

function Answer(props: QuestionAnswerPropTypes) {
  switch (props.question.type) {
    case SurveyQuestionFieldType.Boolean:
      return <BooleanAnswer {...props} />;
    case SurveyQuestionFieldType.MultiOptions:
      return <MultiOptionsAnswer {...props} />;
    case SurveyQuestionFieldType.Text:
      return <TextAnswer {...props} />;
  }
}

function BooleanAnswer(props: QuestionAnswerPropTypes) {
  const value = props.answer.fields[0].value;
  if (value == null) return null;

  const text = value === 'true' ? 'Yes' : 'No';

  return (
    <Layout question={props.question}>
      <div className='d-inline-block fw-normal bg-gray px-3 py-2 rounded-4'>
        {text}
      </div>
    </Layout>
  );
}

function MultiOptionsAnswer(props: QuestionAnswerPropTypes) {
  const options = props.question.fields[0].options;
  const answers = props.answer.fields[0].option_ids as number[];
  const selected = options.filter((option) =>
    answers.includes(option.id as number),
  );
  if (selected.length === 0) return null;

  return (
    <Layout question={props.question}>
      <div className='d-flex flex-column align-items-start gap-3'>
        {selected.map((option) => (
          <div
            key={option.id}
            className='fw-normal bg-gray px-3 py-2 rounded-4'
          >
            {option.name}
          </div>
        ))}
      </div>
    </Layout>
  );
}

function TextAnswer(props: QuestionAnswerPropTypes) {
  const text = props.answer.fields[0].value;
  if (text == null) return null;

  return (
    <Layout question={props.question}>
      <div
        className='d-inline-block fw-normal bg-gray px-3 py-2 rounded-4'
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {text}
      </div>
    </Layout>
  );
}

function Layout(props: {
  question: SurveyQuestion;
  children: React.ReactNode;
}) {
  return (
    <div>
      <div className='fw-semibold mb-3'>{props.question.title}</div>
      {props.children}
    </div>
  );
}
