import { useState } from 'react';
import { User } from '../../../../entities/User';
import React from 'react';
import { ReconnectEmailIntegrationModal } from '../../../../components/ReconnectEmailIntegrationModal';
import { appendQueryParam } from '../../../../utils/url';

interface PropTypes {
  user: User;
  isFreshLogin: boolean;
}

export function ReconnectEmailIntegrationPopup(props: PropTypes) {
  const showPopup = props.user.emailReauthRequired && props.isFreshLogin;
  if (!showPopup) {
    return null;
  }

  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(true);

  return (
    <ReconnectEmailIntegrationModal
      isOpen={isEmailModalOpen}
      url={appendQueryParam(
        '/account_settings/email_integrations',
        'state',
        window.location.href,
      )}
      onClose={() => setIsEmailModalOpen(false)}
    />
  );
}
