import React from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import {
  LocationOption,
  LocationSelect,
} from '../../../../../../../../components/LocationSelect';
import { SurveyQuestion } from '../../../../../../../../entities/survey_engine/SurveyQuestion';
import { getSelectedOption } from '../../../../../../Candidates/LocationInput';

interface PropTypes {
  question: SurveyQuestion;
  formRegister: UseFormRegister<any>;
  control?: Control<any, any>;
  disabled?: boolean;
}

export function LocationQuestionInput(props: PropTypes) {
  return (
    <Controller
      control={props.control}
      name={'location'}
      render={({ field }) => (
        <LocationSelect
          selected={getSelectedOption(field.value)}
          disabled={props.disabled}
          onChange={(option: LocationOption) => {
            if (option == null) {
              field.onChange(null);
              return;
            }

            field.onChange({
              name: option.label,
              address: option.address,
              city: option.city,
              state: option.state,
              country: option.country,
            });
          }}
          className='p-0 fs-5'
          isClearable={true}
        />
      )}
    />
  );
}
