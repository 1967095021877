import { DateFilter } from '../../../components/DateRangePicker';
import { HiringTeamAnalytics } from '../../../entities/candidate_experience/HiringTeamAnalytics';
import { ApiService } from '../../ApiService';

export class AnalyticsHiringTeamService {
  public static get(
    job_names: string[],
    department_names: string[],
    location_names: string[],
    survey_template_ids: string[],
    dateRange: DateFilter,
    name: string,
    page: number,
    pageSize: number,
    sortColumn: string,
    sortDirection: string,
    hiringTeamSelectedType: string,
  ): Promise<any> {
    return ApiService.get<HiringTeamAnalytics>(
      '/api/candidate_experience/candidate_analytics/hiring_team',
      {
        job_names,
        department_names,
        location_names,
        survey_template_ids,
        start_date: dateRange.start.toJSON(),
        end_date: dateRange.end.toJSON(),
        date_range: dateRange.range,
        name: name,
        page: page,
        page_size: pageSize,
        sort_column: sortColumn,
        sort_direction: sortDirection,
        hiring_team_selected_type: hiringTeamSelectedType,
      },
    );
  }
}
