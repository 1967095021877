import { ConditionsData } from '..';
import { TriggerRequirements } from '../../../../../entities/candidate_experience/TriggerRequirements';

export function setSortedConditionsDatabyMandatory(
  conditionsData: ConditionsData,
  triggerRequirement: TriggerRequirements,
) {
  const sortedConditionsDatabyMandatory = [];

  for (const [_key, value] of Object.entries(conditionsData)) {
    if (
      triggerRequirement[value.value] === 'mandatory' &&
      (value.multiSelected.length > 0 || value.singleSelected)
    ) {
      sortedConditionsDatabyMandatory.unshift(value);
    } else if (value.multiSelected?.length > 0 || value.singleSelected) {
      sortedConditionsDatabyMandatory.push(value);
    }
  }
  return sortedConditionsDatabyMandatory;
}
