import React, { useState } from 'react';
import { IntegrationPanel } from '../../../components/IntegrationPanel';
import { humanize } from '../../../utils/humanize';
import GoogleMeetLogo from '../../../images/logos/google_meet.svg';
import ZoomLogo from '../../../images/logos/zoom_rounded.svg';
import MicrosoftTeamsLogo from '../../../images/logos/microsoft_teams.png';
import { Chip, ChipColor } from '../../../components/Chip';
import { VcsProvidersService } from '../../../services/AccountSettingsService/./VcsProvidersService';
import { SchedulingIntegrationRow } from '././SchedulingIntegrationRow';
import { PageTitle } from '../../../components/PageTitle';
import { EmailIntegrationRow } from '././EmailIntegrationRow';
import { Alert, AlertType } from '../../../components/Alert';
import { EmailAccountIntegration } from '../../../entities/EmailAccountIntegration';
import { SchedulingAccountIntegration } from '../../../entities/SchedulingAccountIntegration';
import { AvailabilityRow } from './AvailabilityRow';
import { AvailabilityModal } from './AvailabilityModal';
import { getQueryParam } from '../../../utils/url';
import { IntegrationProvider } from '../../../enums/IntegrationProvider';
import { VcsProvider } from '../../../enums/IntegrationProvider/VcsProvider';

interface PropTypes {
  showAvailability: boolean;
  schedulingIntegration: SchedulingAccountIntegration;
  emailIntegration: EmailAccountIntegration;
  vcsIntegration: {
    providers: VcsProvider[];
    defaultProvider: VcsProvider;
    enabled: boolean;
  };
}

function RadioRow(props: {
  integrationProvider: IntegrationProvider;
  checked: boolean;
  disabled: boolean;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}) {
  let logo: string;
  if (props.integrationProvider === 'google_meet') {
    logo = GoogleMeetLogo;
  } else if (props.integrationProvider === 'microsoft_teams') {
    logo = MicrosoftTeamsLogo;
  } else {
    logo = ZoomLogo;
  }

  return (
    <div className='form-check d-flex align-items-center'>
      <input
        className='form-check-input mt-0'
        type='radio'
        name={props.integrationProvider}
        id={props.integrationProvider}
        value={props.integrationProvider}
        disabled={props.disabled}
        checked={props.checked}
        onChange={props.onChange}
      />
      <label
        className='h5 mb-0 fw-semibold form-check-label d-flex align-items-center ms-3'
        htmlFor={props.integrationProvider}
      >
        {props.integrationProvider != 'zoom' ? (
          <div
            className='me-2 rounded-circle d-flex align-items-center justify-content-center bg-gray'
            style={{ width: '32px', height: '32px' }}
          >
            <img src={logo} style={{ width: '16px', height: '16px' }} />
          </div>
        ) : (
          <img
            src={logo}
            className='me-2'
            style={{ width: '32px', height: '32px' }}
          />
        )}
        {humanize(props.integrationProvider)}
      </label>
      {props.checked && (
        <Chip className='ms-4 fw-semibold' color={ChipColor.BlueLight}>
          Default
        </Chip>
      )}
    </div>
  );
}

export default function Integrations(props: PropTypes) {
  const [alertMessage, setAlertMessage] = useState<string>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Success);

  const [schedulingIntegration, setSchedulingIntegration] = useState(
    props.schedulingIntegration.integration,
  );
  const [emailIntegration, setEmailIntegration] = useState(
    props.emailIntegration.integration,
  );
  const [checkedVcsProvider, setCheckedVcsProvider] = useState(
    props.vcsIntegration.defaultProvider,
  );
  const [vcsProviders, setVcsProviders] = useState(
    props.vcsIntegration.providers,
  );

  const event = getQueryParam('event');
  const [isAvailabilityModalOpen, setAvailabilityModalOpen] = useState<boolean>(
    props.showAvailability && event == 'calendar_connected',
  );

  const reloadVcsProviders = async () => {
    const newVcsProviders = await VcsProvidersService.get();
    setCheckedVcsProvider(newVcsProviders?.defaultProvider);
    setVcsProviders(newVcsProviders?.providers);
  };

  function handleMeetingProviderChange(
    event: React.FormEvent<HTMLInputElement>,
  ) {
    const value = event.currentTarget.value;
    setCheckedVcsProvider(value as VcsProvider);

    (async () => {
      await VcsProvidersService.updateDefault(value);
    })();
  }

  return (
    <div>
      <PageTitle text={'Integrations'} />
      {alertMessage && (
        <Alert
          type={alertType}
          clearable={true}
          autoClearTimeout={4000}
          onClose={() => {
            setAlertMessage(null);
            setAlertType(AlertType.Success);
          }}
        >
          {alertMessage}
        </Alert>
      )}
      {props.schedulingIntegration.enabled && (
        <>
          <AvailabilityModal
            onCancel={() => setAvailabilityModalOpen(false)}
            isOpen={isAvailabilityModalOpen}
          />
          <IntegrationPanel title={'Calendars'}>
            <SchedulingIntegrationRow
              integration={schedulingIntegration}
              onIntegration={setSchedulingIntegration}
              authorizeUrl={props.schedulingIntegration.authorizeUrl}
              setAlertMessage={setAlertMessage}
              setAlertType={setAlertType}
              reloadVcsProviders={reloadVcsProviders}
            />
          </IntegrationPanel>
          {props.showAvailability &&
            schedulingIntegration?.connectionStatus == 'connected' &&
            schedulingIntegration?.availabilities?.length <= 0 && (
              <IntegrationPanel
                title={'Your interview availability'}
                headerClassName={'mb-1'}
                bodyClassName={'pe-3'}
              >
                <AvailabilityRow />
              </IntegrationPanel>
            )}
        </>
      )}
      {props.emailIntegration.enabled && (
        <IntegrationPanel title={'Email'}>
          <EmailIntegrationRow
            integration={emailIntegration}
            onIntegration={setEmailIntegration}
            authorizeUrl={props.emailIntegration.authorizeUrl}
            setAlertMessage={setAlertMessage}
            setAlertType={setAlertType}
          />
        </IntegrationPanel>
      )}
      {props.vcsIntegration.enabled && vcsProviders?.length > 0 && (
        <IntegrationPanel
          title='Video communication systems'
          subtitle='Select your default VCS'
        >
          <div className='d-flex flex-column gap-2'>
            {vcsProviders.map((provider) => (
              <RadioRow
                key={provider}
                integrationProvider={provider}
                checked={provider === checkedVcsProvider}
                disabled={vcsProviders.length === 1}
                onChange={handleMeetingProviderChange}
              />
            ))}
          </div>
        </IntegrationPanel>
      )}
    </div>
  );
}
