import React, { useState } from 'react';
import { SettingRow } from './SettingRow';
import { HorizontalDivider } from '../../../components/HorizontallDivider';
import { ApplicantTrackingSettingsService } from '../../../services/applicant_tracking/ApplicantTrackingSettingsService';

interface PropTypes {
  jobRequisitionEnabled: boolean;
  jobRequisitionMandatory: boolean;
  offerRequisitionEnabled: boolean;
  jobApprovalFlowExists: boolean;
  offerApprovalFlowExists: boolean;
  offerFlowFfEnabled: boolean;
}

export type RequisitionSettingType = 'job' | 'offer';

function updateSetting(setting: string, value: boolean) {
  switch (setting) {
    case 'jobRequisitionEnabled':
      ApplicantTrackingSettingsService.jobRequisitionEnabled(value).catch(
        (error) =>
          console.error('Error updating jobRequisitionEnabled:', error),
      );
      break;
    case 'jobRequisitionMandatory':
      ApplicantTrackingSettingsService.jobRequisitionMandatory(value).catch(
        (error) =>
          console.error('Error updating jobRequisitionMandatory:', error),
      );
      break;
    case 'offerRequisitionEnabled':
      ApplicantTrackingSettingsService.offerRequisitionEnabled(value).catch(
        (error) =>
          console.error('Error updating offerRequisitionEnabled', error),
      );
      break;
  }
}

export default function RequisitionSettings(props: PropTypes) {
  const [jobRequisitionEnabled, setJobRequisitionEnabled] = useState<boolean>(
    props.jobRequisitionEnabled,
  );
  const [jobRequisitionMandatory, setJobRequisitionMandatory] =
    useState<boolean>(props.jobRequisitionMandatory);
  const [offerRequisitionEnabled, setOfferRequisitionEnabled] =
    useState<boolean>(props.offerRequisitionEnabled);

  return (
    <div>
      <h1 className='mb-4'>Requisitions</h1>
      <div className='rounded-3 p-4 bg-white mb-4'>
        <SettingRow
          type='job'
          disabled={!props.jobApprovalFlowExists}
          title='Job Approval Requisition'
          description='Enable this feature to allow users to create and approve job requisitions before publishing.'
          enabled={jobRequisitionEnabled}
          onChange={(value) => {
            updateSetting('jobRequisitionEnabled', value);
            setJobRequisitionEnabled(value);

            if (!value) {
              updateSetting('jobRequisitionMandatory', false);
              setJobRequisitionMandatory(false);
            }
          }}
        />
        <HorizontalDivider classNames='my-3' />
        <SettingRow
          useYesNoTags
          disabled={!jobRequisitionEnabled}
          title='Mandatory'
          description='When enabled, jobs can not be opened without an approved requisition.'
          enabled={jobRequisitionMandatory}
          onChange={(value) => {
            updateSetting('jobRequisitionMandatory', value);
            setJobRequisitionMandatory(value);
          }}
        />
      </div>
      {props.offerFlowFfEnabled && (
        <div className='rounded-3 p-4 bg-white'>
          <SettingRow
            type='offer'
            disabled={!props.offerApprovalFlowExists}
            title='Offer Approval Requisition'
            description='Enable this feature to require offer approvals before sending them to candidates. Define a Offer Approval Flow first.'
            enabled={offerRequisitionEnabled}
            onChange={(value) => {
              updateSetting('offerRequisitionEnabled', value);
              setOfferRequisitionEnabled(value);
            }}
          />
        </div>
      )}
    </div>
  );
}
