import React from 'react';
import { CompanyInfo } from '../../../../entities/CompanyInfo';
import { CenteredLayout } from '../../../../components/CenteredLayout';
import { LogoWithBackgroundHeader } from '../../../Headers/LogoWithBackgroundHeader';
import { CareersPageBody } from './CareersPageBody';
import { JobPost } from '../../../../entities/applicant_tracking/JobPost';
import { CareersPageFooter } from './CareersPageFooter';

interface PropTypes {
  jobPosts: JobPost[];
  companyInfo?: CompanyInfo;
}

export default function CareersPage(props: PropTypes) {
  const whitelabelEnabled = [
    props.companyInfo?.backgroundColor == null ||
      props.companyInfo?.backgroundColor == '',
    props.companyInfo?.logoUrl == null,
  ].some((el) => el != true);

  return (
    <CenteredLayout
      heightClass='h-100'
      innerContainerClassName='d-flex flex-column h-100'
    >
      <LogoWithBackgroundHeader
        companyInfo={props.companyInfo}
        whitelabelEnabled={whitelabelEnabled}
      />
      <CareersPageBody
        jobPosts={props.jobPosts}
        whitelabelEnabled={whitelabelEnabled}
      />
      <CareersPageFooter />
    </CenteredLayout>
  );
}
