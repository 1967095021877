import React from 'react';
import TooltipSlider from '../TooltipSlider';
import 'rc-slider/assets/index.css';
import styles from './styles.module.scss';

interface PropTypes {
  min: number;
  max: number;
  step: number;
  value: number[];
  defaultValue?: number[];
  tooltipFormatter?: (value: number) => React.ReactNode;
  onChange: (value: number[]) => void;
}

export default function RangeSlider(props: PropTypes) {
  return (
    <TooltipSlider
      range
      {...props}
      value={props.value}
      tipFormatter={props.tooltipFormatter}
      onChange={props.onChange}
      className={styles['range-slider']}
    />
  );
}
