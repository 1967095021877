import React from 'react';
import { InputFormField } from '../../../../../components/InputFormField';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { UpsertPlanTemplateDto } from '../../DTOs/UpsertPlanTemplateDto';
import { Col } from 'reactstrap';
import classNames from 'classnames';

interface PropTypes {
  errors: FieldErrors;
  register: UseFormRegister<UpsertPlanTemplateDto>;
  disabled?: boolean;
}

export function NameInput(props: PropTypes) {
  return (
    <Col xs={3}>
      <InputFormField
        placeholder='Insert job workflow name'
        isRequired={true}
        fieldName='Name'
        classNames={classNames(
          props.errors.name && 'is-invalid',
          'fs-5 fw-normal',
        )}
        registerReturn={props.register('name', {
          required: true,
          validate: (value) => value.trim() !== '' || 'Name cannot be blank',
        })}
        type='text'
        error={props.errors.name?.message?.toString()}
        disabled={props.disabled}
      />
    </Col>
  );
}
