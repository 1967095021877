import React from 'react';
import { Button } from 'reactstrap';
import { Integration } from '../../../entities/Integration';

export interface PropTypes {
  integration: Integration;
}

export const ViewUnifiedApiButton = React.memo(function ViewUnifiedApiButton(
  props: PropTypes,
) {
  return (
    <a
      href={`/organization_settings/integrations/${props.integration.provider}/${props.integration.id}/edit`}
    >
      <Button color='secondary' type='submit'>
        View
      </Button>
    </a>
  );
});
