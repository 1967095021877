import React from 'react';
import { JobDetails } from '../../../../entities/JobDetails';
import { appendRedirectUri } from '../../../../utils/url';
import { PublishButton } from './PublishButton';
import { jobPostLink } from './JobPostButton';
import { IconButton } from '../../../../components/IconButton';

interface PropTypes {
  jobDetails: JobDetails;
  updateEnabled: boolean;
  subdomain?: string;
  publishEnabled: boolean;
}

export function NavActions(props: PropTypes) {
  const editJob = () => {
    const jobId = window.location.pathname.split('/')[2];

    window.location.href = appendRedirectUri(`/jobs/${jobId}/edit/job_details`);
  };

  return (
    <div
      className='d-flex align-items-center text-nowrap'
      data-testid='job-page-nav-actions'
    >
      {props.jobDetails.status === 'published' && (
        <IconButton
          color='borderless'
          onClick={() =>
            window.open(
              jobPostLink(props.jobDetails.jobPosts, props.subdomain),
              '_blank',
              'noopener,noreferrer',
            )
          }
          disabled={!props.updateEnabled}
          iconName='bi-box-arrow-up-right'
          buttonText='View Job Post'
          buttonClass='me-3'
        />
      )}
      <PublishButton {...props} />
      <IconButton
        color='primary'
        onClick={editJob}
        disabled={!props.updateEnabled}
        iconName='bi-pencil'
        buttonText='Edit'
      />
    </div>
  );
}
