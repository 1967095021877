import {
  CampaignTotalPrice,
  ProductDuration,
  ProductPrice,
  ProductPriceCurrency,
} from '@vonq/hapi-elements-types';

export function getCurrencySymbol(currency: ProductPriceCurrency): string {
  switch (currency) {
    case ProductPriceCurrency.EUR:
      return '€';
    case ProductPriceCurrency.USD:
      return '$';
    case ProductPriceCurrency.GBP:
      return '£';
    case ProductPriceCurrency.AUD:
      return 'A$';
    default:
      return '€'; // Should be the default since it's the one configured.
  }
}

export function getDuration(duration: ProductDuration): string {
  if (duration == null) {
    return 'N/A';
  }

  return `${duration.period} ${duration.range}`;
}

export function getPrice(
  price: ProductPrice[] | CampaignTotalPrice[],
  targetCurrency?: ProductPriceCurrency,
  divisionPrice?: number,
) {
  // Read more here: https://docs.elements-sandbox.hapi.vonq.com/docs/changelog/version-1-3/product-prices-display/#ratecard-price-is-hidden
  if (price == null || price.length === 0) {
    return 'N/A';
  }

  const targetPrice =
    price.find((v) => v.currency === targetCurrency) || price[0];

  return `${getCurrencySymbol(targetPrice.currency)}${
    targetPrice.amount / (divisionPrice || 1)
  }`;
}
