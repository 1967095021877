import React from 'react';
import { Button } from 'reactstrap';

interface PropTypes {
  isFormValid: boolean;
  allowPassword: boolean;
}

export function ChangePasswordButton(props: PropTypes) {
  return (
    <div className='w-100 text-end pb-3 px-3'>
      <Button
        color='primary'
        type='submit'
        size={'md'}
        disabled={!props.isFormValid && props.allowPassword}
      >
        Change Password
      </Button>
    </div>
  );
}
