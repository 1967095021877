import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Col } from 'reactstrap';
import { InputFormField } from '../../../../../../../components/InputFormField';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';

interface PropTypes {
  formRegister: UseFormRegister<JobRequisitionDto>;
}

export function Name(props: PropTypes) {
  const required = true;

  return (
    <Col xs={3}>
      <InputFormField
        type={'text'}
        registerReturn={props.formRegister('name', { required: required })}
        classNames='fs-5'
        fieldName='Requisition Name / Job Title'
        placeholder='Insert name'
        isRequired={required}
        fontSize='fs-5'
        headerClassNames='fw-semibold mb-0'
      />
    </Col>
  );
}
