import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { ParsedResume } from '../../../../entities/v1/applicant_tracking/ParsedResume';

export class ParsedResumesService {
  public static create(params: {
    base64File: string;
    subdomain?: string;
  }): Promise<ParsedResume> {
    return ApiService.post<ParsedResume>(
      '/api/v1/applicant_tracking/parsed_resumes',
      snakecaseKeys(params),
    );
  }
}
