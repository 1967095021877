import React from 'react';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { SingleDatePicker } from '../../../../../../../components/SingleDatePicker';
import moment, { Moment } from 'moment';

interface PropTypes {
  setStartingDate: (startingDate: string) => void;
  startingDate?: string;
}

const DATE_FORMAT = 'DD/MM/yyyy';

export function StartingDateInput(props: PropTypes) {
  return (
    <>
      <FormFieldHeader fieldName={'Starting date'} isRequired={true} />
      <SingleDatePicker
        className='fs-5'
        dateFilter={{
          start: props.startingDate && moment(props.startingDate, DATE_FORMAT),
        }}
        minDate={moment.utc()}
        onChange={(moment: Moment) =>
          moment != null && props.setStartingDate(moment.format(DATE_FORMAT))
        }
      />
    </>
  );
}
