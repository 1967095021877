import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Size } from '../../utils/types/Size';

export interface PropTypes {
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  name: string;
  checked?: boolean;
  color?: string;
  size?: Size;
  disabled?: boolean;
  classNames?: string;
}

export function ToggleButton(props: PropTypes) {
  const disabledClass = props.disabled
    ? styles['toggle-switch-disabled']
    : null;
  const sizeClass =
    props.size != null ? `toggle-switch-${props.size}` : 'toggle-switch-md';
  const colorClass =
    props.color != null ? `toggle-${props.color}` : 'toggle-info';

  return (
    <label className={classNames('toggle', props.classNames)}>
      <input
        type='checkbox'
        role='switch'
        className={classNames(styles['toggle-checkbox'])}
        name={props.name}
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled ?? false}
      />
      <span
        className={classNames(
          'bg-white',
          styles['toggle-switch'],
          styles[sizeClass],
          styles[colorClass],
          disabledClass,
        )}
      ></span>
    </label>
  );
}
