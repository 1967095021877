export class ApiError extends Error {
  public status: number;
  public body: any;

  constructor(status: number, body: any) {
    super(`API returned error status ${status}`);
    this.status = status;
    this.body = body;

    // Set the prototype explicitly: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
