import React from 'react';
import { Button } from 'reactstrap';

export function CloseEvaluationFooter(props: {
  onStay: () => void;
  onSubmit: () => void;
}) {
  return (
    <>
      <Button color='secondary' onClick={props.onStay}>
        Stay
      </Button>
      <Button color='primary' onClick={props.onSubmit}>
        Yes, I want to leave
      </Button>
    </>
  );
}
