import React from 'react';
import { Button } from 'reactstrap';
import { EmailDomain } from '../../../../../../entities/EmailDomain';
import { EmailDomainService } from '../../../../../../services/EmailDomainService';
import { AlertType } from '../../../../../../components/Alert';

interface PropTypes {
  isEmailDomainEnabled: boolean;
  emailDomain: EmailDomain;
  setEmailDomain: (emailDomain: EmailDomain) => void;
  setShowWarning: (showWarning: boolean) => void;
  onAlert: (message: string, alertType: AlertType) => void;
}

export function GenerateValueButton(props: PropTypes) {
  async function onGenerateValueClick() {
    try {
      await EmailDomainService.enable(props.emailDomain.id).then(
        (emailDomain) => {
          props.setEmailDomain(emailDomain);
          props.onAlert('Value has been generated!', AlertType.Success);
        },
      );
      props.setShowWarning(false);
    } catch (e) {
      handleError(e);
    }
  }

  function handleError(e) {
    if (e.status === 422) {
      props.onAlert(
        'Something went wrong. Please contact us at support@screenloop.com.',
        AlertType.Danger,
      );
    } else {
      throw e;
    }
  }

  return (
    <div>
      <Button
        type='button'
        color='primary'
        className='mt-4'
        onClick={onGenerateValueClick}
        disabled={
          !(props.isEmailDomainEnabled && props.emailDomain.dkim_host === null)
        }
      >
        Generate Value
      </Button>
    </div>
  );
}
