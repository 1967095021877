import { AppPlugin } from '..';

export class UploadBoxPlugin implements AppPlugin {
  public load(): void {
    document
      .querySelectorAll<HTMLElement>('.uploadbox')
      .forEach((el) => this.installContainer(el));
  }

  private installContainer(form: HTMLElement): void {
    const input = form.querySelector<HTMLInputElement>('input[type=file]');
    input.addEventListener('change', this.showFile.bind(this, form));

    form.addEventListener('drag', this.onDragStart.bind(this, form));
    form.addEventListener('dragover', this.onDragStart.bind(this, form));
    form.addEventListener('dragenter', this.onDragStart.bind(this, form));
    form.addEventListener('dragleave', this.onDragStop.bind(this, form));
    form.addEventListener('dragend', this.onDragStop.bind(this, form));
    form.addEventListener('drop', this.onDragStop.bind(this, form));
    form.addEventListener('drop', (e) => {
      const droppedFiles = e.dataTransfer.files;
      form.querySelector<HTMLInputElement>('input[type=file]').files =
        droppedFiles;
      this.showFile(form);
    });
  }

  private showFile(el: HTMLElement): void {
    const droppedFiles =
      el.querySelector<HTMLInputElement>('input[type=file]').files;
    el.querySelector<HTMLElement>('.upload__instruction').innerText =
      droppedFiles[0].name;
  }

  private onDragStart(el: HTMLElement, e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    el.classList.add('is-dragover');
  }

  private onDragStop(el: HTMLElement, e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    el.classList.remove('is-dragover');
  }
}
