import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { DebounceInput } from 'react-debounce-input';
import styles from './styles.module.scss';

export function PanelHeader(props: {
  onSearchUpdated: (name: string) => void;
  className?: string;
  title: string;
  placeholder: string;
  searchInputSize: string;
  searchValue?: string;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.searchValue]);

  return (
    <div
      className={classNames('d-flex justify-content-between', props.className)}
    >
      <div className='mb-0 fs-2 fw-bold'>{props.title}</div>
      <div className='input-group w-30'>
        <DebounceInput
          inputRef={inputRef}
          type='text'
          placeholder={props.placeholder}
          minLength={3}
          debounceTimeout={300}
          className={classNames(
            'form-control',
            'search-input',
            props.searchInputSize === 'sm' ? styles['search-input-sm'] : '',
          )}
          onChange={(ev) => props.onSearchUpdated(ev.target.value)}
          value={props.searchValue}
        />
      </div>
    </div>
  );
}
