import React, { useState } from 'react';
import { SetupPageHeader } from '../../../../../components/SetupPageHeader';
import { UpsertOfferLetterTemplateDto } from '../../components/UpsertEmailTemplateDto';
import {
  OfferLetterTemplate,
  OfferLetterTemplateList,
} from '../../../../../entities/v1/applicant_tracking/OfferLetterTemplate';
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import {
  StartingPoint,
  StartingPointSelector,
} from '../../../../survey_engine/SurveyTemplates/StartingPointSelector';
import { OfferLetterTemplateService } from '../../../../../services/v1/applicant_tracking/OfferLetterTemplateService';
import { NameInput } from '../../components/NameInput';
import { OfferLetterRichTextEditor } from '../../components/OfferLetterRichTextEditor';

interface PropTypes {
  control: Control<UpsertOfferLetterTemplateDto, any>;
  errors: FieldErrors;
  isValid: boolean;
  showBasedOnTemplate?: boolean;
  onPreview: () => void;
  getValues: UseFormGetValues<UpsertOfferLetterTemplateDto>;
  setValue: UseFormSetValue<UpsertOfferLetterTemplateDto>;
  onCancel: () => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<UpsertOfferLetterTemplateDto>;
  setHtml: (html: string) => void;
}

async function filterOfferLetterTemplates(
  offerLetterTemplateList: Promise<OfferLetterTemplateList>,
  q: string,
) {
  return offerLetterTemplateList.then((list) =>
    list.offerLetterTemplates
      .filter((template) =>
        template.name.toLowerCase().includes(q.toLowerCase()),
      )
      .map((template) => ({
        value: template.id.toString(),
        label: template.name,
      })),
  );
}

async function getInitialReferenceTemplate(
  id: number,
): Promise<OfferLetterTemplate> {
  if (id == null) {
    return null;
  }

  return await OfferLetterTemplateService.show(id);
}

export function Body(props: PropTypes) {
  const [startingPoint, setStartingPoint] = useState<StartingPoint>(null);
  const [referenceOfferTemplate, setReferenceOfferTemplate] =
    useState<OfferLetterTemplate>(null);

  async function handleStartingPointChange(startingPoint: StartingPoint) {
    setStartingPoint(startingPoint);

    if (startingPoint == null) return;

    if (typeof startingPoint === 'string' && startingPoint === 'FROM_SCRATCH') {
      return;
    }

    const referenceId = startingPoint.value as unknown as number;

    props.setValue('offerLetterTemplateReferenceId', referenceId);
    setReferenceOfferTemplate(await getInitialReferenceTemplate(referenceId));
  }

  return (
    <form onSubmit={props.onSubmit}>
      <SetupPageHeader
        title={'Offer Letter'}
        firstItems={[]}
        intermediateItems={[
          {
            title: 'Cancel',
            type: 'Cancel',
            action: props.onCancel,
          },
          {
            title: 'Save',
            type: 'Save',
            disabled: !props.isValid,
            action: () => props.onSubmit,
          },
        ]}
      />
      <div className='d-flex flex-column w-50 fs-5 pt-2 pb-4 gap-4'>
        <NameInput errors={props.errors} register={props.register} />
        {props.showBasedOnTemplate && (
          <StartingPointSelector
            startingPoint={startingPoint}
            initialState={() => OfferLetterTemplateService.list()}
            filterOptions={filterOfferLetterTemplates}
            onStartingPointChange={handleStartingPointChange}
            placeholder='Select template'
          />
        )}
      </div>
      <OfferLetterRichTextEditor
        control={props.control}
        isValid={props.isValid}
        referredEmailTemplate={referenceOfferTemplate}
        onPreview={props.onPreview}
        setHtml={props.setHtml}
      />
    </form>
  );
}
