import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon';
import { SortingDirection } from '../../utils/types/SortingDirection';

interface PropTypes {
  sortDirection: SortingDirection;
  isSelected: boolean;
  className?: string;
}

function getIconName(isSelected: boolean, sortDirection: SortingDirection) {
  if (!isSelected || sortDirection === 'neutral') return 'chevron-expand';

  if (sortDirection === 'desc') return 'chevron-down';

  return 'chevron-up';
}

export function SortingIcon(props: PropTypes) {
  return (
    <Icon
      name={`bi-${getIconName(props.isSelected, props.sortDirection)}`}
      className={classNames(props.className)}
    />
  );
}
