import React from 'react';
import { Alert, AlertType } from '../../../../components/Alert';

interface PropTypes {
  setAlertActive: (active: boolean) => void;
  supportEmail: string;
}

export function DisabledPasswordAuthenticationAlert(props: PropTypes) {
  return (
    <Alert
      type={AlertType.Warning}
      clearable={true}
      onClose={() => props.setAlertActive(false)}
    >
      <p className='mb-0'>
        <b>Password authentication is disabled for your organization.</b>
      </p>
      <p className='mb-0'>
        You need to setup and use Two-Factor Authentication, but you will not be
        able to use your password for signing in. For further questions, please
        contact us at{' '}
        <a
          href={`mailto:${props.supportEmail}`}
          target='_blank'
          rel='noopener noreferrer'
          className='fw-bold text-info'
        >
          {props.supportEmail}
        </a>
        .
      </p>
    </Alert>
  );
}
