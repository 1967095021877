import React from 'react';
import { OverviewTab } from '../OverviewTab';
import { TopTabNavigation } from '../TabNavigator/TopTabNavigation';
import { JobDetails } from '../../../entities/JobDetails';
import { JobPageTab } from '../JobPageTab';
import { PipelineTab } from '../PipelineTab';
import { User } from '../../../entities/User';
import { JobBoardsTab } from '../JobBoardsTab';
import { Organization } from '../../../entities/Organization';
import { AccountIntegration } from '../../../entities/AccountIntegration';

interface PropTypes {
  activeTab: JobPageTab;
  currentUser: User;
  emailAccountIntegration: AccountIntegration;
  jobBoardEnabled: boolean;
  jobDetails: JobDetails;
  organization: Organization;
  standardNpsCalculation: boolean;
  bulkSelfSchedulingEnabled: boolean;
}

export function JobDetailsPanel(props: PropTypes) {
  return (
    <main>
      <TopTabNavigation
        activeTab={props.activeTab}
        jobBoardEnabled={props.jobBoardEnabled}
      />
      {props.activeTab === 'Overview' && (
        <OverviewTab
          currentUser={props.currentUser}
          jobDetails={props.jobDetails}
          subDomain={props.organization?.subdomain}
          standardNpsCalculation={props.standardNpsCalculation}
        />
      )}
      {props.activeTab === 'Pipeline' && (
        <PipelineTab
          currentUser={props.currentUser}
          emailAccountIntegration={props.emailAccountIntegration}
          jobDetails={props.jobDetails}
          bulkSelfSchedulingEnabled={props.bulkSelfSchedulingEnabled}
        />
      )}
      {props.activeTab === 'Job Boards' && (
        <JobBoardsTab
          jobDetails={props.jobDetails}
          organization={props.organization}
          user={props.currentUser}
        />
      )}
    </main>
  );
}
