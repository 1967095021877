import React, { useEffect, useRef } from 'react';
import { IconButton } from '../../../../components/IconButton';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  userInput: string;
  setUserInput: (userInput: string) => void;
  isAiGenerating: boolean;
  handleAskAI: () => void;
}

function FooterTextArea(props: PropTypes) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [props.isAiGenerating]);

  return (
    <textarea
      ref={textAreaRef}
      className={classNames(
        'w-100 mt-4 form-control text-primary border-blue-gray-500 ',
        styles['assistant-text-area'],
      )}
      placeholder='Ask a question...'
      disabled={props.isAiGenerating}
      value={props.userInput}
      onChange={(e) => {
        props.setUserInput(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey && props.userInput.trim()) {
          e.preventDefault();
          props.handleAskAI();
        }
      }}
    />
  );
}

function FooterButton(props: PropTypes) {
  return (
    <div className='d-flex justify-content-end'>
      {props.isAiGenerating ? (
        <div className='fs-5 text-dark-500 mt-3'>
          <i className='bi bi-stars'></i>
          <span>AI is writing...</span>
        </div>
      ) : (
        <IconButton
          buttonText='Ask AI'
          iconName='bi-stars'
          onClick={props.handleAskAI}
          buttonClass={'mt-3'}
          color='primary'
          disabled={!props.userInput.trim() || props.isAiGenerating}
        />
      )}
    </div>
  );
}

export function InterviewAssistantFooter(props: PropTypes) {
  return (
    <div className='w-100'>
      <FooterTextArea {...props} />
      <FooterButton {...props} />
    </div>
  );
}
