import React, { useCallback, useEffect, useState } from 'react';
import { FilterState, Filters } from '../Filters';
import { defaultDateRange } from '../../../../components/candidate_experience/Filters/filterState';
import cloneDeep from 'lodash.clonedeep';
import { readHash, writeHash } from '../../../../utils/location';
import { Body } from '../Body';
import { LoadingSpinnerIcon } from '../../../../components/LoadingSpinnerIcon';
import { Icon } from '../../../../components/Icon';
import { triggerDownload } from '../../../../utils/triggerDownload';
import { ExportsService } from '../../../../services/applicant_tracking/analytics/OverviewService/ExportsService';
import { momentToDateStr } from '../../../../utils/momentToDateStr';
import { Button } from 'reactstrap';

const DEFAULT_FILTER_STATE: Readonly<FilterState> = {
  filterBy: { label: 'Departments', value: 'department' },
  entries: [],
  dateRange: defaultDateRange(),
  locations: [],
};

interface PropTypes {
  atsAnalyticsExportPdfEnabled: boolean;
}

function hashedFilterState() {
  return readHash<FilterState>(DEFAULT_FILTER_STATE);
}

export default function Page(props: PropTypes) {
  const [appliedFilterState, setAppliedFilterState] = useState<FilterState>(
    hashedFilterState(),
  );
  const [draftFilterState, setDraftFilterState] = useState<FilterState>(
    cloneDeep(appliedFilterState),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [reportHasData, setReportHasData] = useState<boolean>(false);

  const handleClick = useCallback(async () => {
    setLoading(true);

    const blob = await ExportsService.pdf(appliedFilterState);
    const filename = [
      'ats_overview_report',
      momentToDateStr(appliedFilterState.dateRange.start),
      momentToDateStr(appliedFilterState.dateRange.end),
    ].join('_');

    triggerDownload(blob, `${filename}.pdf`);

    setLoading(false);
  }, [appliedFilterState]);

  function handleFilterUpdate(
    name: keyof FilterState,
    value: FilterState[keyof FilterState],
  ) {
    const newFilterState = cloneDeep(draftFilterState);
    newFilterState[name] = value as any; // Whatever.
    if (name === 'filterBy') {
      if (newFilterState.filterBy.value === appliedFilterState.filterBy.value) {
        newFilterState.entries = appliedFilterState.entries;
      } else {
        newFilterState.entries = [];
      }
    }
    setDraftFilterState(newFilterState);
  }

  function handleResetClick() {
    setDraftFilterState(DEFAULT_FILTER_STATE);
    setAppliedFilterState(DEFAULT_FILTER_STATE);
  }

  function handleApplyClick() {
    setAppliedFilterState(draftFilterState);
  }

  useEffect(() => {
    writeHash(appliedFilterState);
  }, [appliedFilterState]);

  useEffect(() => {
    function handlePopState() {
      setAppliedFilterState(hashedFilterState());
    }

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  return (
    <main>
      <div className='d-flex flex-column align-items-start gap-3'>
        <div className='d-flex w-100'>
          <h1 className='my-0 me-auto'>Overview</h1>
          {props.atsAnalyticsExportPdfEnabled && (
            <Button
              type='button'
              color='borderless'
              disabled={!reportHasData || loading}
              onClick={handleClick}
            >
              <div className='d-flex'>
                <div className='me-2'>
                  {loading ? (
                    <LoadingSpinnerIcon />
                  ) : (
                    <Icon name='bi-download' />
                  )}
                </div>
                Export PDF
              </div>
            </Button>
          )}
        </div>
        <Filters
          filterState={draftFilterState}
          onFilterUpdate={handleFilterUpdate}
          onResetClick={handleResetClick}
          onApplyClick={handleApplyClick}
        />
        <Body
          filterState={appliedFilterState}
          exportToPDF={false}
          setReportHasData={setReportHasData}
        />
      </div>
    </main>
  );
}
