import React, { useState } from 'react';
import { Interview } from '../../../entities/Interview';
import { ScorecardTabs } from './ScorecardTabs';
import { ScorecardAssistantTabs } from './ScorecardAssistantTabs';
import { ScorecardTimeline } from './ScorecardTimeline';
import { ScorecardAINotes } from './ScorecardAINotes';
import { ScorecardAssistant } from './ScorecardAssistant';

interface PropTypes {
  interview: Interview;
  playTimestamp: number;
  onTimestampChange: (timestamp: number) => void;
  atsEnabled: boolean;
}

function ScorecardAssistantBodyTab(props: PropTypes & { activeTab: string }) {
  switch (props.activeTab) {
    case ScorecardTabs.Timeline:
      return <ScorecardTimeline {...props} />;
    case ScorecardTabs.AINotes:
      return (
        <ScorecardAINotes
          interview={props.interview}
          onTimestampChange={props.onTimestampChange}
          playTimestamp={props.playTimestamp}
          atsEnabled={props.atsEnabled}
        />
      );
    case ScorecardTabs.Assistant:
      return <ScorecardAssistant interview={props.interview} />;
    default:
      return null;
  }
}

export function ScorecardAssistantBody(props: PropTypes) {
  const [activeTab, setActiveTab] = useState(ScorecardTabs.AINotes);

  return (
    <div className='m-4'>
      <ScorecardAssistantTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <ScorecardAssistantBodyTab {...props} activeTab={activeTab} />
    </div>
  );
}
