import { FilterState } from '../../../../containers/applicant_tracking/Analytics/Filters';
import { TopSources } from '../../../../entities/applicant_tracking/analytics/TopSources';
import { ApiService } from '../../../ApiService';
import { mapToParams } from '../mapToParams';

export class TopSourcesService {
  public static show(filterState: FilterState): Promise<TopSources> {
    return ApiService.get(
      '/api/v1/applicant_tracking/analytics/top_sources',
      mapToParams(filterState),
    );
  }
}
