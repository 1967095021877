import { Role } from '../../../entities/Role';
import { Survey } from '../../../entities/survey_engine/Survey';
import { ApiService } from '../../../services/ApiService';

export class SurveyTargetInfoService {
  public static create(
    surveyId: number,
    jobIds: number[],
    role: Role,
  ): Promise<Survey> {
    return ApiService.post<Survey>(
      `/api/candidate_experience/surveys/${surveyId}/survey_target_infos`,
      { job_ids: jobIds, role: role },
    );
  }
}
