import React from 'react';
import { Nav, NavLink } from 'reactstrap';
import { InterviewDetailTab } from '../../Entities/InterviewDetailTab';
import RobotIcon from '../../../../images/icons/robot.svg';

export function InterviewTabs(props: {
  activeTab: InterviewDetailTab;
  setActiveTab: (tab: InterviewDetailTab) => void;
  interviewAiAssistantEnabled: boolean;
}) {
  const tabs = [
    InterviewDetailTab.AINotes,
    InterviewDetailTab.Timeline,
    InterviewDetailTab.Analytics,
    InterviewDetailTab.Clips,
  ];

  if (props.interviewAiAssistantEnabled) {
    tabs.push(InterviewDetailTab.Assistant);
  }

  return (
    <Nav className='app-nav-tabs'>
      {tabs.map((tab) => {
        return (
          <NavLink
            key={tab}
            data-testid={tab}
            href='#'
            active={props.activeTab === tab}
            className='text-sm-center'
            onClick={() => props.setActiveTab(tab)}
          >
            {tab === 'Assistant' && (
              <img
                src={RobotIcon}
                className='me-2'
                style={{ width: '16px', height: '16px' }}
              />
            )}
            {tab}
          </NavLink>
        );
      })}
    </Nav>
  );
}
