import React from 'react';
import { VideoJsPlayerOptions } from 'video.js';
import { Media } from '../../../../../../entities/Media';
import { MediaType } from '../../../../../../entities/MediaType';
import { ShortMediaPlayer } from '../../../../../../components/MediaPlayer/ShortMediaPlayer.tsx';

interface PropTypes {
  videoUrl: string;
}

function getMedia(videoUrl: string): Media {
  return {
    url: videoUrl,
    type: MediaType.Video,
  };
}

function getMediaPlayerOptions(): VideoJsPlayerOptions {
  return {
    aspectRatio: '16:9',
    controls: true,
    preload: 'auto',
    playbackRates: [1, 1.5, 2],
  };
}

export function SubSectionVideo(props: PropTypes) {
  return (
    <ShortMediaPlayer
      isPlaying={false}
      volume={100}
      muted={false}
      playbackRate={1}
      playerOptions={getMediaPlayerOptions()}
      media={getMedia(props.videoUrl)}
    />
  );
}
