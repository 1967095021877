import React from 'react';

interface PropTypes {
  text: string;
}

export function StartPanelFooter(props: PropTypes) {
  return (
    <div className='text-center text-muted' style={{ fontSize: '0.9em' }}>
      {props.text}
      <br />
      If you have any questions, please contact us at{' '}
      <a href='mailto:support@screenloop.com'>support@screenloop.com</a>.
    </div>
  );
}
