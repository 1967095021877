import React from 'react';
import { Card } from '../../../../components/Card';
import { FieldList } from '../FieldList';
import { SectionHeader } from '../SectionHeader';
import { ClientId, ColumnConfig, FieldConfig, FieldData } from '../Field';
import { Action } from '../dispatcher';
import { IconButton } from '../../../../components/IconButton';
import { Headers } from './Headers';

export interface SectionData {
  /** Server-side ID. Null if the field is not yet persisted. */
  sId: number | null;
  /** Client-side ID. Always filled. */
  cId: ClientId;
  name: string;
  description?: string;
  fields: FieldData[];
  removable?: boolean;
}

interface PropTypes {
  singleTypeSections?: boolean;
  fieldConfig: FieldConfig[];
  data: SectionData;
  dispatch: React.Dispatch<Action>;
  removeDisabled: boolean;
  upDisabled: boolean;
  downDisabled: boolean;
  readOnly?: boolean;
  columns: ColumnConfig[];
  addFieldButtonText?: string;
  fieldDeleteTooltip?: string;
  enableDescription?: boolean;
}

export function Section(props: PropTypes) {
  function handleRemove() {
    props.dispatch({ type: 'section_remove', sectionId: props.data.cId });
  }

  function handleUp() {
    props.dispatch({ type: 'section_up', sectionId: props.data.cId });
  }

  function handleDown() {
    props.dispatch({ type: 'section_down', sectionId: props.data.cId });
  }

  function handleTitleChange(event) {
    props.dispatch({
      type: 'section_attrs_change',
      sectionId: props.data.cId,
      attrs: { name: event.target.value },
    });
  }

  function handleDescriptionChange(event) {
    props.dispatch({
      type: 'section_attrs_change',
      sectionId: props.data.cId,
      attrs: { description: event.target.value },
    });
  }

  function handleFieldAdd() {
    props.dispatch({
      type: 'field_add',
      sectionId: props.data.cId,
      copy: props.singleTypeSections,
    });
  }

  return (
    <Card className='border'>
      <SectionHeader
        sectionId={props.data.cId}
        upDisabled={props.upDisabled}
        downDisabled={props.downDisabled}
        removeDisabled={props.removeDisabled}
        onRemove={handleRemove}
        onUp={handleUp}
        onDown={handleDown}
        readOnly={props.readOnly}
        className='mb-3'
      />
      <div className='row mb-3'>
        <div className='col-3'>
          <div className='fs-5 fw-semibold mb-1'>
            Title <span className='text-danger'>*</span>
          </div>
          <input
            className='form-control fs-5'
            placeholder='Insert section title'
            value={props.data.name}
            onChange={handleTitleChange}
            disabled={props.readOnly}
          />
        </div>
        {props.enableDescription && (
          <div className='col'>
            <div className='fs-5 fw-semibold mb-1'>Description</div>
            <input
              className='form-control fs-5'
              placeholder='Insert section description'
              value={props.data.description}
              onChange={handleDescriptionChange}
              disabled={props.readOnly}
            />
          </div>
        )}
      </div>
      <Headers
        singleTypeSections={props.singleTypeSections}
        readOnly={props.readOnly}
        columns={props.columns}
      />
      <FieldList
        sectionId={props.data.cId}
        fields={props.data.fields}
        fieldTypeConfigs={props.fieldConfig}
        dispatch={props.dispatch}
        singleTypeSections={props.singleTypeSections}
        readOnly={props.readOnly}
        columns={props.columns}
        fieldDeleteTooltip={props.fieldDeleteTooltip}
      />
      {!props.readOnly && (
        <div className='mt-2'>
          <IconButton
            iconName='bi-plus-circle'
            buttonText={props.addFieldButtonText || 'Add Field'}
            color='borderless'
            disabled={
              props.singleTypeSections
                ? props.data.fields[0]?.fieldType == null
                : false
            }
            onClick={handleFieldAdd}
          />
        </div>
      )}
    </Card>
  );
}
