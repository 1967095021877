import React from 'react';
import styles from './styles.module.scss';
import { getContrastColor } from '../../../utils/colors';

const DEFAULT_PROGRESS_BAR_COLOR = '#19ceaf';

interface PropTypes {
  progress: number;
  color?: string;
}

export function ProgressBar(props: PropTypes) {
  const backgroundColor = props.color || DEFAULT_PROGRESS_BAR_COLOR;
  const textColor = getContrastColor(backgroundColor);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.progress}
        style={{
          width: `${props.progress}%`,
          color: textColor,
          backgroundColor,
        }}
      >
        {Math.floor(props.progress)}%
      </div>
    </div>
  );
}
