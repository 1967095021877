import { SelectOption } from '../../components/Select';
import { QueryParam } from '../../utils/url';
import {
  mapSingleValueSelectToQueryParam,
  parseSingleValueSelect,
} from './SingleValueSelectFilter';

export const JOB_QUERY_PARAM = 'job';

export function parseJob() {
  return parseSingleValueSelect(JOB_QUERY_PARAM);
}

export function mapJobToQueryParam(option?: SelectOption): QueryParam[] | null {
  return mapSingleValueSelectToQueryParam(JOB_QUERY_PARAM, option);
}
