import React from 'react';
import { Button } from 'reactstrap';

export function FailedEvaluationScoreFooter(props: {
  onSkipEvaluation: () => void;
  onSubmit: () => void;
}) {
  return (
    <>
      <Button color={'danger'} onClick={props.onSkipEvaluation}>
        Skip for now
      </Button>
      <Button color='primary' onClick={props.onSubmit}>
        Retry
      </Button>
    </>
  );
}
