import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { QueryClientProvider } from 'react-query';
import { Transcript } from '../../components/Interview/Transcript';
import { TranscriptToggle } from '../../components/Interview/TranscriptToggle';
import { queryClient } from '../../config/queryClient';
import { Interview } from '../../entities/Interview';
import { ShortMediaPlayer } from '../../components/MediaPlayer/ShortMediaPlayer.tsx';
import { useResizeDetector } from 'react-resize-detector';
import { ScorecardAssistantHeader } from './ScorecardAssistantHeader';
import { ScorecardAssistantBody } from './ScorecardAssistantBody';
import { IconButton } from '../../components/IconButton';

interface PropTypes {
  interview: Interview;
  header?: JSX.Element;
  classNames?: string;
  heightClass?: string;
  atsEnabled: boolean;
}

export default function ScorecardAssistantPage(props: PropTypes) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTranscriptVisible, setIsTranscriptVisible] = useState(false);
  const [startTime, setStartTime] = useState<number>(0);
  const [playTimestamp, setPlayTimestamp] = useState<number>(0);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const localStorageKey = `playbackStartTime-${props.interview.id}`;
  const [showVideo, setShowVideo] = useState<boolean>(true);
  const showVideoText = showVideo ? 'Hide Video' : 'Show Video';
  const showVideoIcon = showVideo ? 'bi-eye-slash' : 'bi-eye';

  useEffect(() => {
    if (playTimestamp > 0) {
      localStorage.setItem(localStorageKey, playTimestamp.toString());
    }
  }, [playTimestamp]);

  useEffect(() => {
    const startTime = localStorage.getItem(localStorageKey);
    if (startTime) {
      const ts = parseInt(startTime, 10);
      if (!isNaN(ts)) {
        setStartTime(ts);
      }
    }
  }, []);

  const [validHeight, setValidHeight] = useState(null);
  const { height, ref } = useResizeDetector();

  useEffect(() => {
    if (!isTranscriptVisible && showVideo) {
      setValidHeight(height);
    }
  }, [height]);

  function handleTimestampChange(ts: number) {
    setStartTime(undefined);
    setImmediate(() => {
      setStartTime(ts);
      setIsPlaying(true);
      setPlayTimestamp(ts);
    });
  }

  return (
    <QueryClientProvider client={queryClient}>
      <main
        className={classNames(
          props.classNames,
          props.heightClass || 'vh-100',
          'd-flex flex-column bg-light',
        )}
      >
        <div className={classNames(props.classNames, 'bg-white px-4 pt-4')}>
          {props.header || <ScorecardAssistantHeader {...props} />}
          <div className='mt-3'>
            <div
              ref={ref}
              className={classNames({ 'd-none': isTranscriptVisible })}
            >
              <ShortMediaPlayer
                isPlaying={isPlaying}
                volume={100}
                muted={false}
                playbackRate={playbackRate}
                playerOptions={{
                  aspectRatio: '16:9',
                  controls: true,
                  preload: 'auto',
                  playbackRates: [1, 1.5, 2],
                  audioOnlyMode: !showVideo,
                }}
                startTime={startTime}
                onPlayTimestampChange={setPlayTimestamp}
                onPlaybackRateChange={setPlaybackRate}
                media={props.interview.media}
                onPlayingChange={setIsPlaying}
                key={showVideo ? 'video' : 'audio'}
              />
            </div>
            <div className={classNames({ 'd-none': !isTranscriptVisible })}>
              <Transcript
                interview={props.interview}
                findString={''}
                activeMonologueOffset={-600}
                maxHeightPx={validHeight}
                selectedSearchOccurence={{
                  monologue: undefined,
                  mark: undefined,
                  global: undefined,
                }}
                playTimestamp={playTimestamp}
                onSelectTimestamp={handleTimestampChange}
              />
            </div>
          </div>
          <div
            className={classNames(
              'd-flex align-items-center py-2',
              isTranscriptVisible
                ? 'justify-content-end'
                : 'justify-content-between',
            )}
          >
            {!isTranscriptVisible && (
              <IconButton
                buttonClass='text-nowrap'
                buttonText={showVideoText}
                color='borderless'
                size='sm'
                iconName={showVideoIcon}
                onClick={() => {
                  setShowVideo(!showVideo);
                  setPlayTimestamp(0);
                }}
              />
            )}
            <TranscriptToggle
              isTranscriptVisible={isTranscriptVisible}
              onDisplayTranscript={() => setIsTranscriptVisible(true)}
              onDisplayVideo={() => setIsTranscriptVisible(false)}
            />
          </div>
        </div>
        <ScorecardAssistantBody
          interview={props.interview}
          playTimestamp={playTimestamp}
          onTimestampChange={handleTimestampChange}
          atsEnabled={props.atsEnabled}
        />
      </main>
    </QueryClientProvider>
  );
}
