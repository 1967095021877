import { ChartData, ChartOptions, Color } from 'chart.js';
import 'chartjs-adapter-moment';
import React from 'react';
import moment from 'moment';
import { Bar, Chart } from 'react-chartjs-2';
import { NpsHistory } from '../../../../entities/survey_engine/NpsHistory';
import { mapInterviewersToColor } from '../../../../utils/participantsToColor';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import { NpsBenchmark } from '../../../../entities/candidate_experience/NpsBenchmark';
import { DateFilter } from '../../../DateRangePicker';

const MIN_CPS_TRANSITION = 0;
const MAX_CPS_TRANSITION = 10;
const MAX_CPS = 100;
const MIN_CPS = -100;
const MAX_DATAPOINTS = 24;

interface PropTypes {
  npsHistory: NpsHistory[];
  npsBenchmark: NpsBenchmark;
  dateRange: DateFilter;
  exportToPDF: boolean;
  standardNpsCalculation?: boolean;
}

function periodicityValue(dateRange: DateFilter) {
  const start = dateRange.start;
  const end = dateRange.end;

  if (
    start.clone().startOf('month').add(MAX_DATAPOINTS, 'months') <=
    end.clone().startOf('month')
  ) {
    return 'year';
  }
  if (
    start.clone().startOf('week').add(MAX_DATAPOINTS, 'weeks') <=
    end.clone().startOf('week')
  ) {
    return 'month';
  }
  if (
    start.clone().startOf('day').add(MAX_DATAPOINTS, 'days') <=
    end.clone().startOf('day')
  ) {
    return 'week';
  }

  return 'day';
}

export const OverallNpsHistoryContent = React.memo(
  function OverallNpsHistoryContent(props: PropTypes) {
    const colors = mapInterviewersToColor(
      props.npsHistory.map((history) => history.label),
    );

    const periodicityMapper = (date: moment.Moment) => {
      return date.format('MMM DD, YYYY');
    };

    const periodicity = periodicityValue(props.dateRange);
    const min = props.dateRange.start.clone().subtract(1, periodicity);
    const max = props.dateRange.end.clone().add(1, periodicity);

    const data = {
      datasets: [
        ...props.npsHistory.map((history) => ({
          label: history.label,
          data: history.data.map((datum) => ({
            x: datum.created_at_date,
            y: Number(datum.total).toFixed(1),
          })),
          type: 'bar',
          borderColor: colors[history.label],
          backgroundColor: colors[history.label],
          borderWidth: undefined, // Use the default.
          barThickness: 16,
          borderRadius: {
            topLeft: 5,
            topRight: 5,
          },
          pointRadius: undefined,
        })),
        {
          label: props.npsBenchmark.name,
          data: [
            {
              x: min.clone().subtract(2, 'd').toDate(),
              y: props.npsBenchmark?.value?.toString(),
            },
            {
              x: max.clone().add(2, 'd').toDate(),
              y: props.npsBenchmark?.value?.toString(),
            },
          ],
          type: 'line',
          borderColor: stylesheetExports.green700,
          backgroundColor: stylesheetExports.green700,
          borderWidth: 2,
          barThickness: undefined,
          borderRadius: undefined,
          pointRadius: 0,
        },
      ],
    };

    const options: ChartOptions<'line' | 'bar'> = {
      elements: { line: { borderWidth: 2 } },
      scales: {
        xAxes: {
          type: 'time' as const,
          min: min.toDate().getTime(),
          max: max.toDate().getTime(),
          time: {
            unit: periodicity,
            displayFormats: { day: 'MMM DD, yyyy' },
            isoWeekday: true,
          },
          grid: { display: false },
        },
        yAxis: {
          min: props.standardNpsCalculation ? MIN_CPS : MIN_CPS_TRANSITION,
          max: props.standardNpsCalculation ? MAX_CPS : MAX_CPS_TRANSITION,
          ticks: { stepSize: props.standardNpsCalculation ? 50 : 5 },
          grid: {
            color: (ctx, _options) =>
              props.standardNpsCalculation
                ? ((ctx.tick.value === 0 || ctx.tick.value === 100
                    ? Chart.defaults.borderColor
                    : '#FFF') as Color)
                : ((ctx.tick.value % 5 === 0
                    ? Chart.defaults.borderColor
                    : '#FFF') as Color),
          },
          afterDataLimits: (scale) => {
            scale.min = props.standardNpsCalculation
              ? MIN_CPS
              : MIN_CPS_TRANSITION;
            scale.max = props.standardNpsCalculation
              ? MAX_CPS + 0.5
              : MAX_CPS_TRANSITION + 0.5;
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          align: 'start',
          labels: { boxWidth: 12, padding: 30 },
        },
        tooltip: {
          callbacks: {
            title: function (context) {
              return (
                context[0] &&
                periodicityMapper(moment(context[0].label, 'MMM DD, YYYY'))
              );
            },
          },
        },
      },
    };

    if (props.exportToPDF) {
      options.animation = false;
    }

    return (
      <Bar
        data={data as ChartData<'bar', { x: Date; y: string }[], unknown>}
        options={options}
      />
    );
  },
);
