import classNames from 'classnames';
import React from 'react';
import { SubSection } from '../../../../entities/training/SubSection';
import { SubSectionMedium } from './SubSectionMedium';

interface PropTypes {
  subSection: SubSection;
}

export function SubSectionPart(props: PropTypes) {
  return (
    <>
      <h2 className={classNames('text-dark-500', 'mt-4', 'mb-3')}>
        {props.subSection.title}
      </h2>
      <div style={{ whiteSpace: 'pre-line' }}>{props.subSection.text}</div>
      {props.subSection.medium && (
        <SubSectionMedium medium={props.subSection.medium} />
      )}
    </>
  );
}
