import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { Session } from '../../../../entities/training/Session';
import { SessionStatusChip } from '../../SessionsPage/SessionCardHeader/SessionStatusChip';
import { BackArrow } from '../../../../components/BackArrow';

interface PropTypes {
  session: Session;
}

function SessionName(props: { name: string }) {
  return (
    <div className='text-wrap mb-0 display2' data-testid='session-info'>
      {props.name}
    </div>
  );
}

export function SessionInfo(props: PropTypes) {
  return (
    <Row
      id='session-info'
      className={classNames(
        'd-flex',
        'g-3',
        'mb-4',
        'justify-content-between',
        'align-items-center',
      )}
    >
      <Col
        md={'10'}
        xs='12'
        className={classNames('d-flex', 'me-auto', 'align-items-center')}
      >
        <BackArrow url='/training/sessions?tab=sessions' />
        <SessionName name={props.session.name} />
      </Col>
      <Col
        md={'2'}
        xs='12'
        className={classNames(
          'd-flex',
          'justify-content-end',
          'align-items-center',
        )}
      >
        <SessionStatusChip
          className='position-relative'
          session={props.session}
        />
      </Col>
    </Row>
  );
}
