import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Select, SelectOption } from '../Select';
import { StyledModal } from '../StyledModal';

interface PropTypes {
  isOpen: boolean;
  selected: SelectOption;
  options: SelectOption[];
  onSelect(newOption: SelectOption): void;
  onDownload(): void;
  onCancel(): void;
}

type Phase = 'selecting' | 'downloading' | 'finished';

function SelectExportBody(props: {
  selected: SelectOption;
  options: SelectOption[];
  onChange: (option: SelectOption) => void;
}) {
  return (
    <>
      <p>Select the file type you want to export.</p>
      <Select
        options={props.options}
        selected={props.selected}
        onChange={(newOption) => props.onChange(newOption)}
      />
    </>
  );
}

function SelectExportFooter(props: {
  onCancel: () => void;
  onDownload: () => void;
  downloadEnabled: boolean;
}) {
  return (
    <>
      <Button color='danger' onClick={props.onCancel}>
        Cancel
      </Button>
      <Button
        color='primary'
        disabled={!props.downloadEnabled}
        onClick={props.onDownload}
      >
        Download
      </Button>
    </>
  );
}

function SuccessBody(props: { onClose: () => void }) {
  return (
    <div className='text-center'>
      <i
        className='bi bi-check-circle text-success'
        style={{ fontSize: '48px' }}
      ></i>
      <h2>File exported successfully!</h2>
      <p>The download will start soon.</p>
      <Button color='primary' onClick={props.onClose}>
        Close
      </Button>
    </div>
  );
}

export function ExportModal(props: PropTypes) {
  const [phase, setPhase] = useState<Phase>('selecting');

  async function handleDownload() {
    setPhase('downloading');
    props.onDownload();
    setPhase('finished');
  }

  return (
    <StyledModal
      isOpen={props.isOpen}
      title='Export File'
      onCancel={props.onCancel}
      size={'md'}
      bodyChildren={
        phase !== 'finished' ? (
          <SelectExportBody
            selected={props.selected}
            options={props.options}
            onChange={(option) => props.onSelect(option)}
          />
        ) : (
          <SuccessBody onClose={props.onCancel} />
        )
      }
      footerChildren={
        phase !== 'finished' && (
          <SelectExportFooter
            onCancel={props.onCancel}
            onDownload={handleDownload}
            downloadEnabled={phase !== 'downloading'}
          />
        )
      }
      footerClassName='pe-4'
    />
  );
}
