import React, { useState } from 'react';
import { IconButton } from '../../../../../../components/IconButton';
import copy from 'copy-to-clipboard';
import { Row } from 'reactstrap';
import { SpanTooltip } from '../../../../../../components/SpanTooltip';

interface PropTypes {
  variables: { [key: string]: string };
}

function handleCopy(value: string, setIsOpen: (open: boolean) => void) {
  copy(value);
  setIsOpen(true);
  setTimeout(() => setIsOpen(false), 2000);
}

function CopyButton(props: { label: string; value: string }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <SpanTooltip
      className='w-auto'
      text={
        <IconButton
          key={props.value}
          iconName='bi-files'
          color='borderless'
          buttonText={props.label}
          onClick={() => handleCopy(props.value, setIsOpen)}
        />
      }
      tooltipText='Copied to Clipboard'
      controlledTooltip={{ isOpen: isOpen }}
    />
  );
}

export function CopyButtons(props: PropTypes) {
  return (
    <Row className='d-flex flex-nowrap mt-2h px-0'>
      {Object.entries(props.variables).map(([key, value]) => (
        <CopyButton key={key} label={key} value={value} />
      ))}
    </Row>
  );
}
