import classNames from 'classnames';
import React from 'react';
import { NoteText } from '../NoteText';
import styles from './styles.module.scss';

interface PropTypes {
  contextText?: string;
  text: string;
  score: number;
  kind?: string;
}

export default function NoteTextEmbedded(props: PropTypes) {
  return (
    <>
      <NoteText
        contextText={props.contextText}
        text={props.text}
        className={props.kind === 'interview_secret' && 'text-gray-900'}
      />
      {props.score && (
        <span
          className={classNames(
            'px-1',
            'text-info',
            'bg-blue-gray-light',
            styles.score,
          )}
        >
          {props.score}
        </span>
      )}
    </>
  );
}
