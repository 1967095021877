import classNames from 'classnames';
import React from 'react';
import { CloseableModal } from '../CloseableModal';

interface PropTypes {
  title: string;
  isOpen: boolean;
  onCancel(): void;
  bodyChildren: JSX.Element | string;
  bodyClassName?: string;
  contentClassName?: string;
  overflowVisible?: boolean;
  footerChildren?: JSX.Element;
  footerClassName?: string;
  external?: React.ReactNode;
  size: string;
}

export function StyledModal(props: PropTypes) {
  return (
    <CloseableModal
      className='justify-content-center'
      onClose={props.onCancel}
      isOpen={props.isOpen}
      size={props.size}
      external={props.external}
      headerTitle={props.title}
      bodyClassName={props.bodyClassName}
      centered={true}
      contentClassName={classNames(
        props.overflowVisible === true ? 'overflow-visible' : 'overflow-hidden',
        props.contentClassName,
      )}
      bodyChildren={props.bodyChildren}
      footerChildren={props.footerChildren}
      footerClassName={props.footerClassName}
    />
  );
}
