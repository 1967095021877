import React from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { JobPostService } from '../../../../../../../../services/applicant_tracking/JobPostService';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';

interface PropTypes {
  jobPostOptions: SelectOption[];
  selectedJobPostOption: SelectOption;
  onSelectedJobPostUpdate: (selectedJobPost) => void;
  isLoading: boolean;
}

export async function loadJobPostOptions(): Promise<SelectOption[]> {
  return (await JobPostService.list()).map((jobPost) => ({
    value: jobPost.id.toString(),
    label: jobPost.name,
  }));
}

export function JobPostTemplateInput(props: PropTypes) {
  return (
    <LabelledSelect
      label={'Based on Job Post'}
      placeholder={'Select job'}
      className='fs-5'
      labelClassName='me-auto fw-semibold'
      options={props.jobPostOptions}
      selected={props.selectedJobPostOption}
      onChange={(selected) => props.onSelectedJobPostUpdate(selected)}
      isLoading={props.isLoading}
    />
  );
}
