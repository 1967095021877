import React from 'react';
import JobRequisitionCard from '../index';
import { JobRequisition } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { CardEntry } from '../CardEntry';
import { humanize } from '../../../../../../utils/humanize';
import classNames from 'classnames';
import { formatSalary } from '../../../../../../utils/formatSalary';
import { InfoTooltip } from '../../../../../../components/InfoTooltip';

interface PropTypes {
  jobRequisition: JobRequisition;
}

function buildSalaryRangeText(
  from: number,
  to: number,
  currency: string,
): string {
  return `${formatSalary(from, currency)} - ${formatSalary(to, currency)}`;
}

function TotalBudgetTextPanel(props: { jobRequisition: JobRequisition }) {
  return (
    <div
      className={classNames(
        'd-flex align-items-center mt-6h p-3 bg-light-info rounded-3 fs-5',
      )}
    >
      <h5 className='fw-bold mb-0 me-2'>Total Budget</h5>
      <InfoTooltip>
        Total budget is calculated by multiplying the annual salary range by the
        number of job openings.
      </InfoTooltip>
      <span className={'fw-semibold ms-auto'}>
        {buildSalaryRangeText(
          props.jobRequisition.salaryRangeFrom * props.jobRequisition.openings,
          props.jobRequisition.salaryRangeTo * props.jobRequisition.openings,
          props.jobRequisition.salaryRangeCurrency,
        )}
      </span>
    </div>
  );
}

export default function JobInfoCard(props: PropTypes) {
  return (
    <JobRequisitionCard title={'Job Info'}>
      <CardEntry
        label={'Department'}
        value={props.jobRequisition.department.name}
        className={'mt-3'}
      />
      <CardEntry
        label={'Job Openings'}
        value={props.jobRequisition.openings.toString()}
      />
      <CardEntry
        label={'Hiring Manager'}
        value={props.jobRequisition.hiringManagerUser.name}
      />
      <hr className={'my-1'} />
      <CardEntry
        label={'Location'}
        value={props.jobRequisition.location.name}
      />
      <CardEntry
        label={'Employment Type'}
        value={humanize(props.jobRequisition.employmentType)}
      />
      <CardEntry
        label={'Working Model'}
        value={humanize(props.jobRequisition.locationType)}
      />
      <hr className={'my-1'} />
      <CardEntry
        label={'Annual Salary Range'}
        value={buildSalaryRangeText(
          props.jobRequisition.salaryRangeFrom,
          props.jobRequisition.salaryRangeTo,
          props.jobRequisition.salaryRangeCurrency,
        )}
      />
      <TotalBudgetTextPanel jobRequisition={props.jobRequisition} />
    </JobRequisitionCard>
  );
}
