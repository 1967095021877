import React from 'react';
import { SurveyButton } from '../SurveyButton';

type ChildElement = JSX.Element | string;

interface PropTypes {
  active?: boolean;
  className?: string;
  colorClassName?: string;
  onClick?: () => void;
  children: ChildElement | ChildElement[];
}

export function SurveyChip(props: PropTypes) {
  const { children, ...buttonProps } = props;

  return (
    <SurveyButton {...{ ...buttonProps, ...{ isChip: true } }}>
      {children}
    </SurveyButton>
  );
}
