import { ConfirmationModal } from '../../../components/ConfirmationModal';
import React, { useCallback } from 'react';
import { SurveyRequestService } from '../../../reference_check/services/SurveyRequestService';
import { SurveyRequest } from '../../../reference_check/entities/SurveyRequest';
import { ReferenceRequestStatus } from '../ReferenceRequestStatus';
import { ConfirmationAlert } from './ConfirmationAlert';

interface PropTypes {
  surveyRequest: SurveyRequest;
  referenceRequestStatus: ReferenceRequestStatus;
  setReferenceRequestStatus: (status: ReferenceRequestStatus) => void;
  loadSurveyRequests: () => void;
}

export function CancelSuccessRequestModal(props: PropTypes) {
  const cancelRequest = useCallback(async () => {
    try {
      await SurveyRequestService.cancel(props.surveyRequest.id);

      props.setReferenceRequestStatus('cancelled');
    } catch (e) {
      props.setReferenceRequestStatus('cancelling_error');
    }
  }, [props.surveyRequest]);

  const onClose = useCallback(async () => {
    props.setReferenceRequestStatus('completed');
  }, []);

  return (
    <>
      <ConfirmationAlert
        referenceRequestStatus={props.referenceRequestStatus}
        loadSurveyRequests={props.loadSurveyRequests}
      />
      <ConfirmationModal
        title='Cancel Request'
        body={<p>Are you sure you want to cancel this request?</p>}
        isOpen={props.referenceRequestStatus === 'cancelling'}
        onConfirm={cancelRequest}
        onCancel={onClose}
        size={'md'}
        confirmText={'Delete'}
        disableAfterConfirm={false}
      />
    </>
  );
}
