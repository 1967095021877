import React from 'react';
import { Alert, AlertType } from '../../../components/Alert';
import { ReferenceRequestStatus } from '../ReferenceRequestStatus';

interface PropTypes {
  referenceRequestStatus: ReferenceRequestStatus;
  loadSurveyRequests: () => void;
}

export function ConfirmationAlert(props: PropTypes) {
  return (
    <>
      {props.referenceRequestStatus === 'cancelled' && (
        <Alert
          type={AlertType.Success}
          clearable={true}
          autoClearTimeout={4000}
          onClose={props.loadSurveyRequests}
        >
          Request successfully canceled.
        </Alert>
      )}
      {props.referenceRequestStatus === 'cancelling_error' && (
        <Alert
          type={AlertType.Danger}
          clearable={true}
          autoClearTimeout={4000}
          onClose={props.loadSurveyRequests}
        >
          Request can no longer be cancelled. References have been set.
        </Alert>
      )}
    </>
  );
}
