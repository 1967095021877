import React from 'react';
import { Input } from 'reactstrap';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { IconButton } from '../../../../../../components/IconButton';
import { InterviewCandidateUser } from '../../../../../../entities/applicant_tracking/InterviewCandidateUser';
import { AlertType } from '../../../../../../components/Alert';
import { InterviewCandidateUserService } from '../../../../../../services/applicant_tracking/InterviewCandidateUserService';

interface PropTypes {
  interviewCandidateUser: InterviewCandidateUser;
  candidateName: string;
  setAlertMessage: (message: string) => void;
  setAlertType: (type: AlertType) => void;
  onClose: () => void;
}

export function ModalBody(props: PropTypes) {
  const onSend = async () => {
    try {
      await InterviewCandidateUserService.sendSelfScheduleEmails([
        props.interviewCandidateUser.id,
      ]);

      props.setAlertType(AlertType.Success);
      props.setAlertMessage(
        'The email has been successfully sent to the candidate.',
      );
    } catch (e) {
      props.setAlertType(AlertType.Danger);
      props.setAlertMessage('The email could not be sent to the candidate.');
    } finally {
      props.onClose();
    }
  };

  return (
    <div>
      <div className='fs-5 fw-bold mb-3'>
        Are you sure you want to send an email to {props.candidateName}?
      </div>
      <div className='d-flex'>
        <Input
          type='text'
          disabled
          value={props.interviewCandidateUser.email}
          className='bg-white w-100 fs-5'
        />
        <IconButton
          iconName='bi-send'
          onClick={onSend}
          textClass='text-white'
          color='primary'
          buttonClass={classNames('ms-2', styles['send-button'])}
          buttonText='Send'
        />
      </div>
    </div>
  );
}
