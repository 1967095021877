import React from 'react';
import { AlertMessage } from '../../../components/AlertMessage';

export function InterviewRatingAlert() {
  return (
    <div className='d-flex justify-content-center'>
      <AlertMessage
        icon={{ name: 'bi-info-circle' }}
        text='Interview rating is added to all scorecards by default! The rating includes: Definitely Not, No, Yes and Definitely Yes.'
        hasOverlay={false}
        className='mt-6 bg-light-info rounded align-self-start fs-5'
      />
    </div>
  );
}
