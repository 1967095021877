import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { Link, LinkTarget } from '../Link';

export function IconLink(props: {
  text: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  linkClassName?: string;
  textClassName?: string;
  disabled?: boolean;
  iconClassName: string;
  url?: string;
  download?: boolean;
  target?: LinkTarget;
}) {
  return (
    <div className={classNames('d-flex align-items-center', props.className)}>
      <Link
        onClick={props.onClick}
        classNames={props.linkClassName}
        textClassNames={props.textClassName}
        disabled={props.disabled}
        href={props.url}
        target={props.target}
        download={props.download}
      >
        <i className={classNames('me-2', props.iconClassName)} />
        {props.text}
      </Link>
    </div>
  );
}
