import React from 'react';
import copy from 'copy-to-clipboard';
import styles from './styles.module.scss';

interface PropTypes {
  content: string;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
}

export const CopyToClipboard = (props: PropTypes) => (
  <a
    style={props.style}
    onClick={() => copy(props.content)}
    className={`${props.className} ${styles.clickableIcon}`}
  >
    {props.label || 'Copy'}
  </a>
);
