import React from 'react';
import { EmptyState } from '../../../../../components/EmptyState';
import { AddStageButton } from './AddStageButton';
import { TemplateType } from '../..';

interface PropTypes {
  actionEnabled: boolean;
  hideAddStageButton: boolean;
  templateType: TemplateType;
  disabledMessage?: string;
  onAddStage: () => void;
}

function BasedOnTemplateEmptyState() {
  return (
    <EmptyState
      title='Select a template'
      text='Select a template to manage job workflows and interviews.'
    />
  );
}

function StartFromScratchEmptyState(props: PropTypes) {
  return (
    <EmptyState
      title='No stages added yet'
      text='Create and manage your job workflow stages
      and interviews.'
      actionButton={
        <>
          {!props.hideAddStageButton && (
            <div className='mt-4'>
              <AddStageButton
                enabled={props.actionEnabled}
                color='primary'
                disabledMessage={props.disabledMessage}
                onAddStage={props.onAddStage}
              />
            </div>
          )}
        </>
      }
    />
  );
}

export function JobWorkflowEmptyState(props: PropTypes) {
  return (
    <>
      {props.templateType === 'start_from_scratch' ? (
        <StartFromScratchEmptyState {...props} />
      ) : (
        <BasedOnTemplateEmptyState />
      )}
    </>
  );
}
