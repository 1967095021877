import React from 'react';

interface PropTypes {
  imageUrl: string;
  imageDescription: string;
}

export function SubSectionImage(props: PropTypes) {
  return (
    <img
      className='img-fluid rounded-4 mt-4'
      src={props.imageUrl}
      alt={props.imageDescription}
    />
  );
}
