import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, AlertType } from '../../../components/Alert';
import { Button } from 'reactstrap';
import { ChangePasswordService } from '../../../services/ChangePasswordService';
import { usePasswordErrorHandling } from '../usePasswordErrorHandling';
import { FormValues } from '../UserInvitationPage';
import { handlePasswordSubmission } from '../handlePasswordSubmission';
import { PasswordRow } from '../PasswordRow';
import { PasswordRequirementsList } from '../PasswordRequirementsList';

interface PropTypes {
  resetPasswordToken: string;
}

export default function ChangePasswordPage(props: PropTypes) {
  const { register, handleSubmit, watch, resetField } = useForm<FormValues>({
    mode: 'onSubmit',
  });

  const {
    alertActive,
    setAlertActive,
    hasPasswordValidationFailed,
    setPasswordValidationFailed,
    errorMessage,
    handlePasswordError,
  } = usePasswordErrorHandling(resetField);

  const password = watch('password');
  const passwordConfirmation = watch('passwordConfirmation');

  const isFormValid = password && passwordConfirmation;

  useEffect(() => {
    if (password || passwordConfirmation) {
      setPasswordValidationFailed(false);
    }
  }, [password, passwordConfirmation]);

  const onSubmit = async (data: FormValues) => {
    const { password, passwordConfirmation } = data;

    await handlePasswordSubmission(
      password!,
      passwordConfirmation!,
      handlePasswordError,
      ChangePasswordService.put(
        password!,
        passwordConfirmation!,
        props.resetPasswordToken,
      ),
      `/interviews?success_message=Your password has been changed successfully. You are now signed in.`,
    );
  };

  return (
    <>
      {alertActive && (
        <Alert
          type={AlertType.Danger}
          clearable={true}
          onClose={() => setAlertActive(false)}
          autoClearTimeout={4000}
        >
          {errorMessage}
        </Alert>
      )}
      <h2 className='auth-heading text-center mb-5'>Change your password</h2>
      <div className='auth-form-container text-start'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PasswordRow
            register={register}
            invalidInput={hasPasswordValidationFailed}
            passwordFieldName={'New Password'}
            confirmPasswordFieldName={'Confirm New Password'}
            passwordPlaceholder={'Insert new password'}
            passwordConfirmationPlaceholder={'Confirm new password'}
          />
          <PasswordRequirementsList />
          <div className='text-center'>
            <Button
              color='primary'
              className='me-2 w-100'
              type='submit'
              size={'md'}
              disabled={!isFormValid}
            >
              Change Password
            </Button>
          </div>
        </form>

        <div
          className='text-blue text-center fs-4 fw-semibold mt-5'
          onClick={() => (window.location.href = '/auth/sign_in')}
          style={{ cursor: 'pointer' }}
        >
          Back to Log In
        </div>
      </div>
    </>
  );
}
