import { ApiService } from '../../ApiService';
import snakecaseKeys from 'snakecase-keys';

interface NoteDto {
  note: string;
}
interface CreateNoteDto extends NoteDto {
  applicationId: number;
}

interface EditNoteDto extends NoteDto {
  id: number;
}

export class NoteService {
  public static create(props: CreateNoteDto): Promise<void> {
    return ApiService.post<void>(
      `/api/applicant_tracking/notes`,
      snakecaseKeys(props),
    );
  }

  public static edit(props: EditNoteDto): Promise<void> {
    return ApiService.put<void>(`/api/applicant_tracking/notes/${props.id}`, {
      note: props.note,
    });
  }

  public static delete(id: number): Promise<void> {
    return ApiService.delete<void>(`/api/applicant_tracking/notes/${id}`);
  }
}
