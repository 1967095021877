import React from 'react';
import { Col, Row } from 'reactstrap';
import { SurveyQuestionTitle } from './SurveyQuestionTitle';
import { SurveyField } from './SurveyField';
import { SurveySection } from '../../../../../../entities/survey_engine/SurveySection';
import { SurveyQuestion } from '../../../../../../entities/survey_engine/SurveyQuestion';
import { ThematicBreak } from '../../../../../../components/ThematicBreak';
import { SurveyQuestionAnswer } from '../../../../../../entities/survey_engine/SurveyQuestionAnswer';
import { Survey } from '../../../../../../entities/survey_engine/Survey';

interface PropTypes {
  surveySection: SurveySection;
  surveyAnswers: SurveyQuestionAnswer[];
  onUpdateSurvey: (survey: Survey) => void;
  onUpdateSurveyAnswers: (surveyAnswers: SurveyQuestionAnswer[]) => void;
}

function Question(props: {
  question: SurveyQuestion;
  answer: SurveyQuestionAnswer;
  onUpdateSurvey: (survey: Survey) => void;
  onUpdateSurveyAnswers: (surveyAnswers: SurveyQuestionAnswer[]) => void;
}) {
  const wrongAnswer = props.answer.fields.some(
    (answer) => answer.correct === false,
  );

  return (
    <>
      <SurveyQuestionTitle
        classNames='fw-semibold'
        question={props.question}
        wrongAnswer={wrongAnswer}
      />
      {props.question.fields.map((qf) => (
        <SurveyField
          key={qf.id}
          question={props.question}
          questionField={qf}
          surveyAnswer={props.answer}
          chipClassName={''}
          onUpdateSurvey={props.onUpdateSurvey}
          onUpdateSurveyAnswers={props.onUpdateSurveyAnswers}
        />
      ))}
      <ThematicBreak className='mt-3 mb-4' />
    </>
  );
}

export function EvaluationScreenBody(props: PropTypes) {
  return (
    <>
      <Row className='mt-4 mb-4'>
        <Col>
          <h3 className='fw-bold'>{props.surveySection.section_name}</h3>
        </Col>
      </Row>
      {props.surveySection.questions.map(
        (surveyQuestion: SurveyQuestion, index: number) => (
          <div key={index}>
            <Question
              question={surveyQuestion}
              answer={props.surveyAnswers.find(
                (a) => surveyQuestion.id === a.question_id,
              )}
              onUpdateSurvey={props.onUpdateSurvey}
              onUpdateSurveyAnswers={props.onUpdateSurveyAnswers}
            />
          </div>
        ),
      )}
    </>
  );
}
