import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Link, PropTypes as LinkPropTypes } from '../Link';

interface PropTypes {
  color: string;
  link: LinkPropTypes;
  children?: ReactNode;
}

export function LinkButton(props: PropTypes) {
  return (
    <Link
      {...props.link}
      classNames={classNames(props.link.classNames, `btn btn-${props.color}`)}
    >
      {props.children}
    </Link>
  );
}
