import React from 'react';
import { UserIntegration } from '../../../../entities/UserIntegration';
import { UserIntegrationService } from '../../../../services/UserIntegrationService';
import { AlertType } from '../../../../components/Alert';
import { AccountIntegrationRow } from '.././AccountIntegrationRow';
import GmailLogo from '../../../../images/logos/google_gmail.svg';
import OutlookLogo from '../../../../images/logos/microsoft_outlook.svg';
import { EmailProvider } from '../../../../enums/IntegrationProvider/EmailProvider';
import { humanize } from '../../../../utils/humanize';
import { IntegrationIcon } from '../../../../components/./IntegrationIcon';

interface PropTypes {
  integration: UserIntegration;
  authorizeUrl: string;
  onIntegration: (ui: UserIntegration) => void;
  setAlertMessage: (message: string) => void;
  setAlertType: (type: AlertType) => void;
}

function getIconPath(emailProvider?: string): string {
  switch (emailProvider) {
    case EmailProvider.Gmail.toString():
      return GmailLogo;
    case EmailProvider.Outlook.toString():
      return OutlookLogo;
    default:
      return null;
  }
}

function getHumanizedEmailProviderName(emailProvider?: string): string {
  if (!emailProvider) return 'Email';

  return humanize(emailProvider);
}

function Icon(props: { emailProvider: string }) {
  const logoPath = getIconPath(props.emailProvider);
  const backgroundClassName = logoPath ? 'bg-gray' : 'bg-green-700';

  return (
    <IntegrationIcon
      className={backgroundClassName}
      imgSrc={logoPath}
      iconClassName={'bi bi-envelope text-white'}
    />
  );
}

export function EmailIntegrationRow(props: PropTypes) {
  const humanizedEmailProviderName = getHumanizedEmailProviderName(
    props.integration?.provider,
  );

  async function handleEmailDisconnectClick() {
    try {
      await UserIntegrationService.disconnect(props.integration.id);
      props.onIntegration(null);
      props.setAlertMessage(
        `${humanizedEmailProviderName} integration was disconnected successfully!`,
      );
      props.setAlertType(AlertType.Success);
    } catch (e) {
      props.setAlertMessage(
        `The ${humanizedEmailProviderName} integration could not be disconnected!`,
      );
      props.setAlertType(AlertType.Danger);
      console.error(e);
    }
  }

  return (
    <AccountIntegrationRow
      integration={props.integration}
      integrationIcon={<Icon emailProvider={props.integration?.provider} />}
      authorizeUrl={props.authorizeUrl}
      onDisconnect={handleEmailDisconnectClick}
      connectedMessage={`Connected with ${humanizedEmailProviderName}`}
      disconnectedMessage={'Connect your account with an email provider'}
      selectionMessage={'Select an email provider to integrate'}
      modalTitle={`Disconnect ${humanizedEmailProviderName}`}
      modalContent={`Are you sure you want to disconnect the ${humanizedEmailProviderName} account?`}
    />
  );
}
