import { SurveyQuestion } from '../../../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionFieldType } from '../../../../../../entities/survey_engine/SurveyQuestionFieldType';
import { isCustomQuestion } from './isCustomQuestion';

export function placeholder(question: SurveyQuestion) {
  if (question.placeholder != null && question.placeholder != '')
    return question.placeholder;

  if (question.type === SurveyQuestionFieldType.Boolean) {
    return 'Pick yes or no';
  }

  if (question.type === SurveyQuestionFieldType.MultiOptions) {
    if (question.maxOptions === 1) {
      return 'Pick a single option';
    } else {
      return 'Pick multiple options';
    }
  }

  if (
    question.type === SurveyQuestionFieldType.Text &&
    isCustomQuestion(question)
  ) {
    return 'Insert your response';
  }

  return `Insert your ${question.title.toLowerCase()}`;
}
