import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface PropTypes {
  action: () => void;
  buttonChild: React.ReactNode;
  isDisabled?: boolean;
}

export function DropdownAction(props: PropTypes) {
  return (
    <div>
      <a
        className={classNames(
          'nav-item',
          'nav-link',
          'text-truncate',
          'px-2',
          'fs-5',
          'fw-normal',
          styles['overflow-item'],
          styles['dropdown-action'],
          props.isDisabled === true
            ? styles['dropdown-action-disabled']
            : styles['overflow-item-hover'],
        )}
        onClick={props.action}
        href='#'
      >
        {props.buttonChild}
      </a>
    </div>
  );
}
