import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { InterviewerParticipant } from '../../../../../../../../../entities/applicant_tracking/InterviewerParticipant';
import { SpanTooltip } from '../../../../../../../../../components/SpanTooltip';
import { Icon } from '../../../../../../../../../components/Icon';

interface PropTypes {
  participant: InterviewerParticipant;
  candidateView: boolean;
  allowChangingMeetingOrganizer: boolean;
  onChangingHost: (participant: InterviewerParticipant) => void;
}

export function MeetingOrganizerButton(props: PropTypes) {
  if (
    props.candidateView ||
    !props.participant.user.hasCalendarConnected ||
    !props.allowChangingMeetingOrganizer
  )
    return;

  const organizer = props.participant.isMeetingOrganizer;

  return (
    <SpanTooltip
      text={
        <Icon
          name={`bi-star${organizer ? '-fill' : ''}`}
          className={classNames(
            !organizer && 'btn text-dark-200',
            !organizer && styles['host-star'],
            organizer && 'text-orange',
            'p-0',
            'fs-4',
            'ms-2',
          )}
          onClick={() => !organizer && props.onChangingHost(props.participant)}
        />
      }
      tooltipText={
        organizer
          ? 'The participant is the meeting host.'
          : 'Define as meeting host'
      }
      trigger='hover'
    />
  );
}
