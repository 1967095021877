import React from 'react';
import { HiringMember } from '../../entities/HiringMember';
import { InterviewRating } from '../../entities/InterviewRating';
import { SingleScore } from './SingleScore';
import { UnratedInterviewer } from './UnratedInterviewer';
import classNames from 'classnames';
import { RateOption } from '../../entities/RateOption';

export interface PropTypes {
  interviewers: HiringMember[];
  responses: InterviewRating[];
  ratingOptions: RateOption[];
  classNames?: string;
  hideLabel?: boolean;
}

function mapResponseToRateOption(
  response: InterviewRating,
  ratingOptions: RateOption[],
): RateOption | null {
  return ratingOptions.find((option) => option.value === response.rating);
}

function NotRated(props: { classNames?: string; hideLabel?: boolean }) {
  return (
    !props.hideLabel && (
      <div
        className={classNames(
          'rounded-pill',
          'px-2h',
          'py-1',
          'fs-6',
          'ms-0',
          'text-truncate',
          props.classNames,
        )}
      >
        Not Rated
      </div>
    )
  );
}

export function InterviewRatingResponse(props: PropTypes) {
  if (props.interviewers.length <= 1 && props.responses.length === 0) {
    return <NotRated {...props} />;
  }

  const interviewerIdsFromResponses = props.responses.map((res) => res.userId);
  const interviewersMissingRating = props.interviewers.filter(
    (interviewer) => !interviewerIdsFromResponses.includes(interviewer.userId),
  );

  return (
    <>
      <div
        className={classNames(
          'rounded-pill',
          'fs-6',
          'd-flex',
          'align-items-center',
          'ms-0',
          'text-truncate',
          {
            'ps-2h py-1': !props.hideLabel,
          },
          props.classNames,
        )}
      >
        {!props.hideLabel && 'Rating'}
        <div className='ms-2 ps-1 d-flex align-items-center'>
          {props.responses.map((response) => (
            <SingleScore
              score={mapResponseToRateOption(response, props.ratingOptions)}
              username={response.userName}
              key={response.id}
            />
          ))}
          {interviewersMissingRating.map((interviewer) => (
            <UnratedInterviewer user={interviewer} key={interviewer.id} />
          ))}
        </div>
      </div>
    </>
  );
}
