import React, { useState } from 'react';
import { EmailTemplate } from '../../../../../entities/EmailTemplate';
import { Col } from 'reactstrap';
import { SelectOption } from '../../../../../components/Select';
import { EmailTemplateService } from '../../../../../services/EmailTemplateService';
import {
  StartingPoint,
  StartingPointSelector,
} from '../../../../survey_engine/SurveyTemplates/StartingPointSelector';
import { EmailTemplateList } from '../../../../../entities/EmailTemplateList';

interface PropTypes {
  setEmailTemplate: (emailTemplate: EmailTemplate) => void;
}

async function filterEmailTemplates(
  emailTemplates: Promise<EmailTemplateList>,
  q: string,
): Promise<SelectOption[]> {
  return emailTemplates.then((list) =>
    list.emailTemplates
      .filter((template) =>
        template.name.toLowerCase().includes(q.toLowerCase()),
      )
      .map((template) => ({
        value: template.id.toString(),
        label: template.name,
      })),
  );
}

async function getInitialEmailTemplate(id: number): Promise<EmailTemplate> {
  if (id == null) {
    return null;
  }

  return await EmailTemplateService.get(id);
}

export function ReferredTemplateInput(props: PropTypes) {
  const [startingPoint, setStartingPoint] = useState<StartingPoint>(null);

  async function handleStartingPointChange(startingPoint: StartingPoint) {
    setStartingPoint(startingPoint);

    if (startingPoint == null) return;

    if (typeof startingPoint === 'string' && startingPoint === 'FROM_SCRATCH') {
      return;
    }

    const referenceId = startingPoint.value as unknown as number;

    props.setEmailTemplate(await getInitialEmailTemplate(referenceId));
  }

  return (
    <Col className='fs-5' xs={6}>
      <StartingPointSelector
        startingPoint={startingPoint}
        initialState={() => EmailTemplateService.list()}
        filterOptions={filterEmailTemplates}
        onStartingPointChange={handleStartingPointChange}
        placeholder='Select template'
      />
    </Col>
  );
}
