import React from 'react';
import classNames from 'classnames';
import { AlertMessage } from '../../../../components/AlertMessage';

export function Helper() {
  return (
    <AlertMessage
      text={
        'Consider your personal agenda, define your interview availability and our ' +
        'calendar integration will find the best time to schedule your interviews.'
      }
      icon={{
        name: 'bi-info-circle',
      }}
      className={classNames('border-radius-2', 'bg-light-info', 'ms-5')}
      textClassName={'fs-5'}
      hasOverlay={false}
    />
  );
}
