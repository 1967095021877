import classNames from 'classnames';
import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import styles from './styles.module.scss';

interface PropTypes {
  title: JSX.Element;
  className?: string;
  itemClassName?: string;
  id: string;
  children: JSX.Element;
  opened: boolean;
  highlightOnHover?: boolean;
}

function itemInitialState(id: string, opened: boolean) {
  if (opened) return id;

  return null;
}

export function AccordionPanel(props: PropTypes) {
  const [activeItem, setActiveItem] = useState<string>(
    itemInitialState(props.id, props.opened),
  );

  return (
    <Accordion className={classNames(props.className)} open={activeItem}>
      <AccordionItem
        className={classNames(styles['accordion-item'], props.itemClassName, {
          [styles['blue-border']]: !activeItem && props.highlightOnHover,
        })}
      >
        <AccordionHeader
          onClick={() =>
            setActiveItem(activeItem === props.id ? null : props.id)
          }
          targetId={props.id}
        >
          {props.title}
        </AccordionHeader>
        <AccordionBody accordionId={props.id}>{props.children}</AccordionBody>
      </AccordionItem>
    </Accordion>
  );
}
