import React, { useContext } from 'react';
import { AddStageButton } from './AddStageButton';
import {
  JobWorkflowEditContext,
  JobStageTemplateInput,
  JobWorkflowEditContextProps,
} from '../..';
import { JobWorkflowEmptyState } from './JobWorkflowEmptyState';
import { JobStagesList } from '../../../../../components/ApplicantTracking/JobStagesList';
import { saveStageMoves } from '../../StagePlanTemplateBody/utils';

interface PropTypes {
  stages?: JobStageTemplateInput[];
  onAddStage: () => void;
}

function deleteStage(
  pageContext: JobWorkflowEditContextProps,
  index: number,
  stages: JobStageTemplateInput[],
) {
  const updatedStages = stages.filter((stage) => stage.index !== index);
  pageContext.setJobStages(updatedStages);
}

function handleDeleteStage(
  pageContext: JobWorkflowEditContextProps,
  index: number,
  jobStages: JobStageTemplateInput[],
  moveDestinationStageName?: string,
) {
  const stageToDelete = pageContext.jobStages.find(
    (stage) => stage.index === index,
  );
  stageToDelete &&
    saveStageMoves(
      stageToDelete.name,
      moveDestinationStageName,
      false,
      pageContext,
    );

  deleteStage(pageContext, index, jobStages);
}

function referenceTemplateNotSelected(
  pageContext: JobWorkflowEditContextProps,
) {
  return (
    pageContext.templateType === 'template' && !pageContext.referenceTemplate
  );
}

function getDisabledMessage(pageContext: JobWorkflowEditContextProps) {
  if (!pageContext.isValid) {
    return 'Give your job workflow a name before adding stages.';
  }

  if (referenceTemplateNotSelected(pageContext)) {
    return 'Select a template before adding stages.';
  }

  return null;
}

export function StagesInput(props: PropTypes) {
  const pageContext = useContext(JobWorkflowEditContext);
  const disabledMessage = getDisabledMessage(pageContext);
  const allowInput =
    pageContext.isValid &&
    !pageContext.showAddStage &&
    !pageContext.focusedJobStage &&
    !referenceTemplateNotSelected(pageContext);

  return (
    <div className='mt-4 pt-2'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='fw-bold fs-3'>Stages</div>
        {!pageContext.viewMode && (
          <AddStageButton
            enabled={allowInput}
            color='secondary'
            disabledMessage={disabledMessage}
            onAddStage={props.onAddStage}
          />
        )}
      </div>
      {props.stages?.length > 0 ? (
        <JobStagesList
          viewMode={pageContext.viewMode}
          focusedJobStage={pageContext.focusedJobStage}
          jobStages={pageContext.jobStages}
          planTemplate={pageContext.planTemplate}
          stageMoves={pageContext.stageMoves}
          setFocusedJobStage={pageContext.setFocusedJobStage}
          onAddStage={props.onAddStage}
          setJobStages={pageContext.setJobStages}
          onDeleteStage={(index, moveDestinationStageName) =>
            handleDeleteStage(
              pageContext,
              index,
              pageContext.jobStages,
              moveDestinationStageName,
            )
          }
        />
      ) : (
        <JobWorkflowEmptyState
          actionEnabled={allowInput}
          hideAddStageButton={pageContext.viewMode}
          disabledMessage={disabledMessage}
          templateType={pageContext.templateType}
          onAddStage={props.onAddStage}
        />
      )}
    </div>
  );
}
