import classNames from 'classnames';
import React from 'react';
import { IconButton } from '../../IconButton';
import styles from './styles.module.scss';

interface PropTypes {
  disabled: boolean;
  iconName: string;
  iconTextClass: string;
  iconColor: string;
  onClick: () => void;
}

export function PaginatedButtonIcon(props: PropTypes) {
  return (
    <IconButton
      disabled={props.disabled}
      color={props.iconColor}
      size='lg'
      iconName={props.iconName}
      iconClass={'fs-1'}
      buttonClass={classNames(
        props.iconTextClass,
        styles['paginated-button'],
        'py-3',
        'border-0',
        'rounded-4',
        `bg-${props.iconColor}`,
      )}
      onClick={props.onClick}
    />
  );
}
