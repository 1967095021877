import React from 'react';
import { TipsPanel } from './Widgets/TipsPanel';
import { WelcomePanel } from './Widgets/WelcomePanel';
import { ExtensionPanel } from './Widgets/ExtensionPanel';
import { OverviewWidget } from '../applicant_tracking/Analytics/Widgets/OverviewWidget';
import { ApplicationsCountHistoryPanel } from './Widgets/ApplicationsCountHistoryPanel';
import { PendingActionsPanel } from './Widgets/PendingActionsPanel';
import { TopSourcingChannelsPanel } from './Widgets/TopSourcingChannelsPanel';

export const WIDGET_HEIGHT = '306px';

export function getDefaultWidgets(): JSX.Element[] {
  const widgets: JSX.Element[] = [];

  widgets.push(<TipsPanel key='tips-panel' />);
  widgets.push(<WelcomePanel key='welcome-panel' />);
  widgets.push(<ExtensionPanel key='extension-panel' />);

  return widgets;
}

export const APPLICANT_TRACKING_WIDGETS: JSX.Element[] = [
  <OverviewWidget key='overview-widget' height={WIDGET_HEIGHT} />,
  <ApplicationsCountHistoryPanel key='history-panel' height={WIDGET_HEIGHT} />,
  <TopSourcingChannelsPanel
    key='top-sourcing-channels-panel'
    height={WIDGET_HEIGHT}
  />,
  <PendingActionsPanel key='pending-actions-panel' height={WIDGET_HEIGHT} />,
];

export const CANDIDATE_EXPERIENCE_WIDGETS: JSX.Element[] = [];

export const INTERVIEW_INTELLIGENCE_WIDGETS: JSX.Element[] = [];
