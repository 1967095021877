import { CandidatePulseEmptyState } from '../CandidatePulseEmptyState';
import { FilterStates } from '../../../../components/candidate_experience/Filters/filterState';
import React, { useEffect, useState } from 'react';
import { PageWideSpinner } from '../../../../components/PageWideSpinner';
import { FollowUpResponsesListPanel } from '../FollowUpQuestionsSentimentTab/FollowUpResponsesListPanel';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { REPORTING_SECTION_CLASS } from '../../../../utils/pdfHandler';
import { PageBreak } from '../PageBreak';
import { FollowUpResponsesCountsPanel } from './FollowUpResponsesCountsPanel';
import { FollowUpResponsesTopicsPanel } from './FollowUpResponsesTopicsPanel';
import { AnalyticsFollowUpService } from '../../../../services/candidate_experience/AnalyticsFollowUpService';
import { FollowUpAnalytics } from '../../../../entities/candidate_experience/FollowUpAnalytics';

const FOLLOWUP_ANSWERS_MAX_ROWS_COLLAPSED = 5;

interface PropTypes {
  filterStates: FilterStates;
  organizationName: string;
  pulseStandardNpsCalculation: boolean;
}

function useAnalytics(props: PropTypes) {
  const [analytics, setAnalytics] = useState<FollowUpAnalytics>(null);

  useEffect(() => {
    setAnalytics(null);

    const filterSelector = props.filterStates.filterSelector.selected.value;
    AnalyticsFollowUpService.get(
      filterSelector,
      props.filterStates.jobs.selected.map((option) => option.value),
      props.filterStates.departments.selected.map((option) => option.value),
      props.filterStates.hiringManagers.selected.map((option) => option.value),
      props.filterStates.recruiters.selected.map((option) => option.value),
      props.filterStates.locations.selected.map((option) => option.value),
      props.filterStates.surveyTemplates.selected.map((option) => option.value),
      props.filterStates.benchmarkType.selected.value,
      props.filterStates.dateRange,
      props.filterStates.ethnicities.selected.map((option) => option.value),
      props.filterStates.genders.selected.map((option) => option.value),
      props.filterStates.ageRanges.selected.map((option) => option.value),
    ).then((analytics) => {
      setAnalytics(analytics);
    });
  }, [props.filterStates]);

  return analytics;
}

export function FollowUpQuestionsAnalytics(props: PropTypes) {
  const analytics = useAnalytics(props);

  if (analytics === null) {
    return <PageWideSpinner />;
  }

  return (
    <>
      {analytics?.total_responses > 0 ? (
        <div className='flex-column gap-4 mt-3'>
          <PageBreak />
          <Row className={classNames('gy-4', REPORTING_SECTION_CLASS)}>
            <Col sm='12' lg='5'>
              <FollowUpResponsesCountsPanel
                neutralPercentage={
                  (analytics.total_cnps_neutrals / analytics.total_responses) *
                  100
                }
                detractorPercentage={
                  (analytics.total_cnps_detractors /
                    analytics.total_responses) *
                  100
                }
                neutralCount={analytics.total_cnps_neutrals}
                detractorCount={analytics.total_cnps_detractors}
                responsesNumber={analytics.total_responses}
              />
            </Col>
            <Col sm='12' lg='7'>
              <FollowUpResponsesTopicsPanel attributes={analytics.attributes} />
            </Col>
          </Row>
          <FollowUpResponsesListPanel
            cnpsFollowUpAnswers={analytics.responses}
            organizationName={props.organizationName}
            maxRowsCollapsed={FOLLOWUP_ANSWERS_MAX_ROWS_COLLAPSED}
            standardNpsCalculation={props.pulseStandardNpsCalculation}
          />
        </div>
      ) : (
        <CandidatePulseEmptyState
          title={'No follow-up responses available yet'}
        />
      )}
    </>
  );
}
