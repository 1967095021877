import React from 'react';
import { IconButton } from '../../../../../../components/IconButton';
import { ApplicationFeedType } from '../../../../../../entities/ApplicationFeed';

interface PropTypes {
  showNewCommentBox: boolean;
  entryType: ApplicationFeedType;
  setShowNewCommentBox: (state: boolean) => void;
  setShowReplyToEmail: (state: boolean) => void;
}

interface ButtonPropTypes {
  buttonText: string;
  iconName: string;
  onClick: () => void;
}

function CommentIcon(props: ButtonPropTypes) {
  return (
    <IconButton
      iconName={props.iconName}
      buttonText={props.buttonText}
      color='borderless'
      size='sm'
      onClick={props.onClick}
    />
  );
}

function isEmailFeedItem(entryType: ApplicationFeedType) {
  return [
    ApplicationFeedType.EmailReceived,
    ApplicationFeedType.EmailSent,
  ].includes(entryType);
}

export function CommentButton(props: PropTypes) {
  return (
    <>
      {props.showNewCommentBox === false && (
        <div className='d-flex justify-content-end'>
          {isEmailFeedItem(props.entryType) && (
            <CommentIcon
              iconName='bi-arrow-90deg-left'
              buttonText='Reply Email'
              onClick={() => props.setShowReplyToEmail(true)}
            />
          )}
          <CommentIcon
            iconName='bi-chat'
            buttonText='Comment'
            onClick={() => props.setShowNewCommentBox(true)}
          />
        </div>
      )}
    </>
  );
}
