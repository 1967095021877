import React from 'react';
import { HighlightType } from '../../Entities/HighlightType';
import { RadioPillGroup } from '../../../../components/RadioPillGroup';
import { RadioPill } from '../../../../components/RadioPill';

interface PropTypes {
  selectedHighlightType: HighlightType;
  onSelectedHighlightType: (val: HighlightType) => void;
}

export function InterviewTimelineHeader(props: PropTypes) {
  function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
    props.onSelectedHighlightType(e.currentTarget.value as HighlightType);
  }

  return (
    <div className='row align-items-left p-2 my-2'>
      <RadioPillGroup
        selectedValue={props.selectedHighlightType as string}
        onChange={handleOnChange}
      >
        <RadioPill id='All' label='All' value='all'></RadioPill>
        <RadioPill
          id='Questions'
          label='Questions'
          value='automatic_question'
        />
        <RadioPill
          id='Attributes'
          label='Attributes'
          value='scorecard_attribute'
        />
        <RadioPill id='Notes' label='Notes' value='note'></RadioPill>
        <RadioPill
          id='Reactions'
          label='Reactions'
          value='reaction'
        ></RadioPill>
      </RadioPillGroup>
    </div>
  );
}
