import { renderDropdownAction } from '..';
import { InterviewListItem } from '../../../entities/InterviewListItem';
import { shouldActionBeVisible } from './index';

function getTitle(isLinkExpired: boolean): string {
  if (isLinkExpired) return 'Regenerate self-scheduling link';

  return 'Edit Availability';
}

function getIcon(isLinkExpired: boolean): string {
  if (isLinkExpired) return 'bi-arrow-repeat';

  return 'bi-pencil';
}

export function editAvailability(interview: InterviewListItem) {
  if (!shouldActionBeVisible(interview)) return null;

  return renderDropdownAction(
    getTitle(interview.interviewCandidateUser.isExpired),
    getIcon(interview.interviewCandidateUser.isExpired),
    false,
    () => {
      window.location.href = `/applicant_tracking/interview_candidate_users/${interview.interviewCandidateUser.id}/edit`;
    },
  );
}
