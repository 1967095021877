import { Controller } from 'stimulus';

const turboFrameConnectedEvent = new Event('turbo-frame:connected');

/**
 * The `TurboController` will issue a "turbo-frame:connected" event
 * when connected. This allows js plugins to be installed when a turbo
 * frame is loaded. This controller may be used in views that may be
 * loaded within frames and require the use of plugins.
 */
export default class extends Controller {
  connect() {
    setImmediate(() => document.dispatchEvent(turboFrameConnectedEvent));
  }
}
