import React from 'react';
import {
  Control,
  FormState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { SurveyQuestion } from '../../../../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionFieldType } from '../../../../../../../entities/survey_engine/SurveyQuestionFieldType';
import { PhoneQuestionInput } from './PhoneQuestionInput';
import { AttachmentQuestionInput } from './AttachmentQuestionInput';
import { DefaultQuestionInput } from './DefaultQuestionInput';
import { LocationQuestionInput } from './LocationQuestionInput';
import { CustomBooleanQuestionInput } from './CustomBooleanQuestionInput';
import { CustomMultiOptionsQuestionInput } from './CustomMultiOptionsQuestionInput';
import { CustomTextQuestionInput } from './CustomTextQuestionInput';
import { isCustomQuestion } from '../../utils/isCustomQuestion';

interface PropTypes {
  question: SurveyQuestion;
  formState: FormState<any>;
  formRegister: UseFormRegister<any>;
  control?: Control<any, any>;
  getValues?: UseFormGetValues<any>;
  setValue?: UseFormSetValue<any>;
  disabled?: boolean;
}

export function QuestionInput(props: PropTypes) {
  if (isCustomQuestion(props.question)) {
    switch (props.question.type) {
      case SurveyQuestionFieldType.Boolean:
        return <CustomBooleanQuestionInput {...props} />;
      case SurveyQuestionFieldType.MultiOptions:
        return <CustomMultiOptionsQuestionInput {...props} />;
      case SurveyQuestionFieldType.Text:
        return <CustomTextQuestionInput {...props} />;
    }
  }

  switch (props.question.type) {
    case SurveyQuestionFieldType.Phone:
      return <PhoneQuestionInput {...props} />;
    case SurveyQuestionFieldType.Attachment:
      return <AttachmentQuestionInput {...props} />;
    case SurveyQuestionFieldType.Location:
      return <LocationQuestionInput {...props} />;
    default:
      return <DefaultQuestionInput {...props} />;
  }
}
