import { Scorecard } from '../../entities/applicant_tracking/Scorecard';

export function calcScorecardAverage(scorecard: Scorecard): number {
  const ratingQuestions = scorecard.survey.sections
    .flatMap((section) => section.questions)
    .filter((question) => question.fieldType === 'rating');
  let sum = 0;
  let count = 0;
  for (const answer of scorecard.survey.answers) {
    const question = ratingQuestions.find(
      (question) => question.id === answer.questionId,
    );
    if (!question) continue;

    sum += +answer.value;
    count += 1;
  }
  if (count === 0) return 0;
  return sum / count;
}
