import React, { useEffect, useState } from 'react';
import { UpsertEmailTemplateDto } from '../UpsertEmailTemplateDto';
import { Row } from 'reactstrap';
import { SetupPageHeader } from '../../../../components/SetupPageHeader';
import { HorizontalDivider } from '../../../../components/HorizontallDivider';
import { NameInput } from './NameInput';
import { ReferredTemplateInput } from './ReferredTemplateInput';
import { SubjectInput } from './SubjectInput';
import { EmailTemplateInput } from './EmailTemplateInput';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { CopyVariables } from './CopyVariables';
import { AttachmentDto } from '../../../../services/dtos/EmailDto';

interface PropTypes {
  control: Control<UpsertEmailTemplateDto, any>;
  errors: FieldErrors;
  isValid: boolean;
  onCancel: () => void;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<UpsertEmailTemplateDto>;
  setHtml: (html: string) => void;
  setSubjectText: (text: string) => void;
  setAttachments: (attachments: AttachmentDto[]) => void;
  setEmailTemplateReferenceId: (value: number) => void;
}

function CardHeader(props: { isValid: boolean; onCancel: () => void }) {
  return (
    <SetupPageHeader
      title={'Email Template'}
      firstItems={[]}
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: props.onCancel,
        },
        {
          title: 'Save',
          type: 'Save',
          disabled: !props.isValid,
        },
      ]}
    />
  );
}

export function Body(props: PropTypes) {
  const [referenceTemplate, setReferenceTemplate] = useState(null);

  useEffect(() => {
    if (referenceTemplate != null) {
      props.setEmailTemplateReferenceId(referenceTemplate.id);
      props.setAttachments(referenceTemplate.attachments);
    }
  }, [referenceTemplate]);

  return (
    <form onSubmit={props.onSubmit}>
      <CardHeader isValid={props.isValid} onCancel={props.onCancel} />
      <Row className='mt-4'>
        <NameInput register={props.register} errors={props.errors} />
      </Row>
      <Row className='my-4 d-flex align-items-center'>
        <ReferredTemplateInput setEmailTemplate={setReferenceTemplate} />
      </Row>
      <HorizontalDivider />
      <CopyVariables />
      <Row className='my-4'>
        <SubjectInput
          control={props.control}
          subject={referenceTemplate?.subject}
          setSubjectText={props.setSubjectText}
        />
      </Row>
      <EmailTemplateInput
        control={props.control}
        template={referenceTemplate?.template}
        setHtml={props.setHtml}
      />
    </form>
  );
}
