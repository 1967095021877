import { Campaign, CampaignStatus } from '@vonq/hapi-elements-types';
import { CampaignRow } from '../../CampaignRow';
import React from 'react';
import { Chip, ChipColor } from '../../../../../../../components/Chip';
import { getPrice } from '../../../../utils/productDetails';
import { humanize } from '../../../../../../../utils/humanize';

interface PropTypes {
  campaign: Campaign;
}

function getStatusChipColor(status: CampaignStatus): ChipColor {
  switch (status) {
    case 'online':
      return ChipColor.Success;
    case 'offline':
      return ChipColor.Danger;
    case 'in progress':
      return ChipColor.Blue;
    case 'not processed':
      return ChipColor.Orange;
  }
}

export function CampaignHeader(props: PropTypes) {
  return (
    <CampaignRow
      highlightName={true}
      name={props.campaign.campaignName}
      updatedAt={props.campaign.createdOn}
      cost={
        <Chip color={ChipColor.Gray900}>
          {getPrice([props.campaign.totalPrice], props.campaign.currency, 100)}
        </Chip>
      }
      status={
        <Chip color={getStatusChipColor(props.campaign.status)}>
          {humanize(props.campaign.status)}
        </Chip>
      }
    />
  );
}
