import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Col, Row } from 'reactstrap';
import { Evaluation } from '../../../../../entities/training/Evaluation';
import { EvaluationScreenHeader } from './EvaluationScreenHeader';
import { EvaluationScreenBody } from './EvaluationScreenBody';
import { Survey } from '../../../../../entities/survey_engine/Survey';
import { SurveyQuestionAnswer } from '../../../../../entities/survey_engine/SurveyQuestionAnswer';
import { EvaluationScoreModal } from './EvaluationScoreModal';
import { FeedbackSessionModal } from '../../../FeedbackSessionModal';
import SessionService from '../../../../../services/training/SessionService';
import { ApiError } from '../../../../../services/ApiService/errors/ApiError';
import { SurveyService } from '../../../../../services/survey_engine/SurveyService';
import { EvaluationService } from '../../../../../services/training/EvaluationService';
import { isSuccessfulEvaluationAccuracy } from './EvaluationScoreModal/utils/isSuccessfulEvaluationAccuracy';

export type FinalScreenStatus =
  | 'NOT_READY'
  | 'FINISH_EVALUATION_MODAL'
  | 'COMPLETED';

interface PropTypes {
  evaluation: Evaluation;
}

export function EvaluationScreen(props: PropTypes) {
  const { step: stepParam } = useParams<'step'>();
  const navigate = useNavigate();
  const step = parseInt(stepParam, 10);

  const [finalScreenStatus, setFinalScreenStatus] =
    useState<FinalScreenStatus>('NOT_READY');
  const [feedbackModalVisible, setFeedbackModalVisible] =
    useState<boolean>(false);

  const [survey, setSurvey] = useState<Survey>(props.evaluation.survey);
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyQuestionAnswer[]>(
    props.evaluation.survey.answers,
  );

  const navigateToStep = (step: number) => {
    navigate(`../step/${step}`);
  };

  const onPreviousClick = () => {
    if (step - 1 > 0) {
      navigateToStep(step - 1);
    }
  };

  const onNextClick = async () => {
    if (step + 1 <= survey.sections.length) {
      navigateToStep(step + 1);
    } else if (step === survey.sections.length) {
      const newEvaluation = await EvaluationService.submit(
        props.evaluation.session.id,
        props.evaluation.id,
      );
      setSurvey(newEvaluation.survey);
      setSurveyAnswers(newEvaluation.survey.answers);
      setFinalScreenStatus('FINISH_EVALUATION_MODAL');
    }
  };

  async function handleFeedback(feedbackText?: string, rating?: number) {
    try {
      if (feedbackText || rating != null) {
        await SessionService.feedback(
          props.evaluation.session.id,
          feedbackText,
          rating,
        );
      }

      setFeedbackModalVisible(false);
      window.location.href = `/training/sessions/${props.evaluation.session.id}`;
    } catch (err: unknown) {
      if (err instanceof ApiError) {
        setFeedbackModalVisible(false);
      }

      throw err;
    }
  }

  async function handleSuccessfulEvaluation() {
    const [surveyResult, _] = await Promise.all([
      SurveyService.submit(props.evaluation.survey.secure_id),
      SessionService.complete(props.evaluation.session.id),
    ]);
    setSurvey(surveyResult);
    setFinalScreenStatus('COMPLETED');
    setFeedbackModalVisible(true);
  }

  return (
    <>
      <Row>
        <Col>
          <EvaluationScreenHeader
            session={props.evaluation.session}
            survey={survey}
            currentSectionIndex={step - 1}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EvaluationScreenBody
            surveySection={survey.sections[step - 1]}
            surveyAnswers={surveyAnswers}
            onUpdateSurvey={setSurvey}
            onUpdateSurveyAnswers={setSurveyAnswers}
          />
        </Col>
      </Row>
      <EvaluationScoreModal
        finalScreenStatus={finalScreenStatus}
        evaluationAccuracy={survey.accuracy}
        questionsCount={
          survey.sections
            .flatMap((section) => section.questions)
            .map((question) => question).length
        }
        correctlyAnsweredCount={
          surveyAnswers
            .flatMap((answer) => answer.fields)
            .filter((fieldAnswer) => fieldAnswer.correct === true).length
        }
        onClose={() => {
          if (isSuccessfulEvaluationAccuracy(survey.accuracy)) {
            handleSuccessfulEvaluation();
          } else {
            setFinalScreenStatus('NOT_READY');
          }
        }}
        onSkipEvaluation={() => {
          setFinalScreenStatus('NOT_READY');
          window.location.href = `/training/sessions/${props.evaluation.session.id}`;
        }}
        onRetryEvaluation={() => {
          setFinalScreenStatus('NOT_READY');
          navigateToStep(1);
        }}
        onSubmitFinishEvaluation={handleSuccessfulEvaluation}
      />
      <FeedbackSessionModal
        visible={feedbackModalVisible}
        onClose={() => handleFeedback(null, null)}
        onSkip={() => handleFeedback(null, null)}
        onSubmit={handleFeedback}
      />
    </>
  );
}
