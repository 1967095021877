import classNames from 'classnames';
import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Media } from '../../entities/Media';
import styles from './styles.module.scss';
import { ShortMediaPlayer } from '../MediaPlayer/ShortMediaPlayer.tsx';

interface PropTypes {
  interviewMarkerOffset: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  media: Media;
}

interface VideoModalPlayerPropTypes {
  interviewMarkerOffset: number;
  media: Media;
}

function VideoModalPlayer(props: VideoModalPlayerPropTypes) {
  return (
    <ModalBody className={classNames('bg-transparent', styles.modalBody)}>
      <ShortMediaPlayer
        isPlaying={true}
        volume={100}
        muted={false}
        playbackRate={1}
        startTime={props.interviewMarkerOffset}
        playerOptions={{
          bigPlayButton: false,
          autoplay: true,
          fluid: true,
          aspectRatio: '480:300',
          controls: true,
        }}
        media={props.media}
      />
    </ModalBody>
  );
}

export function VideoModal(props: PropTypes) {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      size='lg'
      contentClassName='bg-transparent border-0'
    >
      <Button
        close
        className={`btn-close-white py-0 ${styles.close}`}
        onClick={() => props.setIsOpen(false)}
      />
      <VideoModalPlayer
        media={props.media}
        interviewMarkerOffset={props.interviewMarkerOffset}
      />
    </Modal>
  );
}
