import { ApiService } from '../../../services/ApiService';
import { Survey } from '../../../entities/survey_engine/Survey';

export class SurveyService {
  public static get(surveyId: string): Promise<Survey> {
    return ApiService.get<Survey>(`/api/survey_engine/surveys/${surveyId}`);
  }

  public static submit(surveyId: string): Promise<Survey> {
    return ApiService.post<Survey>(
      `/api/survey_engine/surveys/${surveyId}/submit`,
      {},
    );
  }
}
