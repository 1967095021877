import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../ApiService';
import { JobList } from '../../../entities/v1/JobList';

export interface ListParams {
  name?: string;
  hasApplications?: boolean;
  page?: number;
  pageSize?: number;
  expand?: string[];
  sort_column?: string;
  sort_direction?: string;
}

export class JobService {
  public static list(params: ListParams): Promise<JobList> {
    return ApiService.get<JobList>('/api/v1/jobs', snakecaseKeys(params));
  }
}
