import React from 'react';
import { Control } from 'react-hook-form';
import { CustomizationStageToggle } from '../CustomizationStageToggle';

interface PropTypes {
  redactionEnabled: boolean;
  disabled: boolean;
  formControl: Control<any>;
  tooltip?: string;
}

export function RedactionCandidateToggle(props: PropTypes) {
  if (!props.redactionEnabled) {
    return null;
  }

  return (
    <CustomizationStageToggle
      controllerName='redactionEnabled'
      disabled={props.disabled}
      tooltip={props.tooltip}
      formControl={props.formControl}
      title='Does this stage include Anonymized Candidate Screening?'
      subTitle='Enables the redaction of candidate information for this stage. If the stage has an interview this will be disabled.'
    />
  );
}
