import React from 'react';
import { FilterState } from '../Filters';
import { ApplicationsCountHistoryWidget } from '../Widgets/ApplicationsCountHistoryWidget';
import { HiringProcessRatesWidget } from '../Widgets/HiringProcessRatesWidget';
import { OverviewWidget } from '../Widgets/OverviewWidget';
import { TopSourcesWidget } from '../Widgets/TopSourcesWidget';
import { TimeWidget } from '../Widgets/TimeWidget';
import { TopInterviewersWidget } from '../Widgets/TopInterviewersWidget';

export const WIDGET_HEIGHT = '306px';

interface PropTypes {
  filterState?: FilterState;
  exportToPDF?: boolean;
  setReportHasData?: (reportHasData: boolean) => void;
}

export function Body(props: PropTypes) {
  const exportToPDF = props.exportToPDF ?? false;

  return (
    <div className='d-flex flex-column w-100 gap-4'>
      <div className='row'>
        <div className='col-5'>
          <OverviewWidget
            filterState={props.filterState}
            height={WIDGET_HEIGHT}
            exportToPDF={exportToPDF}
          />
        </div>
        <div className='col-7'>
          <ApplicationsCountHistoryWidget
            filterState={props.filterState}
            exportToPDF={exportToPDF}
            height={WIDGET_HEIGHT}
            setReportHasData={props.setReportHasData}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-7'>
          <TopSourcesWidget
            filterState={props.filterState}
            exportToPDF={props.exportToPDF}
            height={WIDGET_HEIGHT}
          />
        </div>
        <div className='col-5'>
          <TopInterviewersWidget
            filterState={props.filterState}
            exportToPDF={exportToPDF}
            height={WIDGET_HEIGHT}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-7'>
          <TimeWidget
            filterState={props.filterState}
            exportToPDF={exportToPDF}
            height={WIDGET_HEIGHT}
          />
        </div>
        <div className='col-5'>
          <HiringProcessRatesWidget
            filterState={props.filterState}
            height={WIDGET_HEIGHT}
          />
        </div>
      </div>
    </div>
  );
}
