import React from 'react';
import classNames from 'classnames';
import { Chip, ChipColor } from '../../../../components/Chip';

export function JobHeaderBadge(props: {
  text: React.ReactNode;
  classNames: string;
  chipColor: ChipColor;
}) {
  return (
    <Chip
      className={classNames('fw-semibold', props.classNames)}
      color={props.chipColor}
    >
      {props.text}
    </Chip>
  );
}
