import { ApprovalFlowStep } from '../../entities/applicant_tracking/ApprovalFlow';
import { JobRequisitionApprover } from '../../entities/v1/applicant_tracking/JobRequisition';

export function buildSteps(
  approvers: JobRequisitionApprover[],
): ApprovalFlowStep[] {
  const stepOrders: Set<number> = new Set(approvers.map((a) => a.order).sort());

  const steps: ApprovalFlowStep[] = [];

  stepOrders.forEach((order) => {
    steps.push({
      order: order,
      approvers: approvers
        .filter((approver) => approver.order === order)
        .map((approver) => {
          return {
            user: approver.user,
            answer: approver.answer,
          };
        }),
    });
  });

  return steps;
}

export function getCurrentStepOrder(steps: ApprovalFlowStep[]): number {
  return steps.find((step) =>
    step.approvers.every((approver) => !approver.answer),
  )?.order;
}
