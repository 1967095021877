import React from 'react';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { IconSpan } from '../../../../../components/IconSpan';
import { shortFormatDate } from '../../../../../utils/timeFormat';
import { Scorecard } from '../../../../../entities/applicant_tracking/Scorecard';
import { Interview } from '../../../../../entities/Interview';

interface PropTypes {
  scorecard: Scorecard;
  interview?: Interview;
}

export function ScorecardHeader(props: PropTypes) {
  return (
    <div className='d-flex flex-nowrap align-items-center'>
      <div className='d-flex gap-2 me-auto'>
        <Chip className='fw-semibold' color={ChipColor.OrangeLight}>
          {props.interview?.stage || props.scorecard.jobStage.name}
        </Chip>
        <Chip className='fw-semibold' color={ChipColor.BlueLight}>
          <IconSpan
            spanText={shortFormatDate(
              props.interview?.scheduled_date || new Date(),
            )}
            icon={{ name: 'bi-calendar' }}
          />
        </Chip>
      </div>
      {props.interview?.status === 'completed' && (
        <div className='fs-6'>
          <a
            href={interviewUrl(props.interview)}
            target='_blank'
            rel='noreferrer'
            className='text-blue'
          >
            View Interview <i className='bi-box-arrow-up-right ms-2' />
          </a>
        </div>
      )}
    </div>
  );
}

function interviewUrl(interview: Interview) {
  return `${new URL(window.location.href).origin}/interviews/${interview.id}`;
}
