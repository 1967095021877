import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { Condition, SelectedConditions } from '..';
import { SelectOption } from '../../../../../components/Select';
import { SurveyTriggerService } from '../../../../../services/SurveyTriggerService';

interface PropTypes {
  setModalIsOpen: (modalIsOpen: boolean) => void;
  edit: boolean;
  surveyTriggerName: string;
  surveyTriggerId: number;
  surveyTemplate: SelectOption;
  conditions: Condition[];
  selectedConditions: SelectedConditions;
  setAlertMessage: (message: string) => void;
}

function checkEmptySelected(conditions: Condition[]) {
  const selectedConditions = conditions.filter(
    (condition) =>
      condition.multiSelected.length > 0 || condition.singleSelected,
  );
  return conditions.length === selectedConditions.length;
}

function buttonDisabled(
  surveyTriggerName: string,
  surveyTemplate: SelectOption,
  conditions: Condition[],
) {
  if (!surveyTriggerName) {
    return true;
  } else if (surveyTemplate === null) {
    return true;
  } else if (!checkEmptySelected(conditions)) {
    return true;
  } else {
    return false;
  }
}

function AddButton(props: PropTypes) {
  const handleOnSave = useCallback(async () => {
    try {
      await SurveyTriggerService.validate(props.surveyTriggerId, {
        active: props.selectedConditions.active,
        name: props.selectedConditions.name,
        maxReminders: props.selectedConditions.maxReminders,
        delayDays: props.selectedConditions.delayDays,
        processingStartsAt: props.selectedConditions.processingStartsAt,
        SurveyTemplateId: props.selectedConditions.surveyTemplateId,
        jobIds: props.selectedConditions.jobIds,
        invertJobCondition: props.selectedConditions.invertJobCondition,
        locationIds: props.selectedConditions.locationIds,
        invertLocationCondition:
          props.selectedConditions.invertLocationCondition,
        jobStageName: props.selectedConditions.jobStageName,
        invertJobStageCondition:
          props.selectedConditions.invertJobStageCondition,
        applicationRejectReason:
          props.selectedConditions.applicationRejectReason,
        applicationStatus: props.selectedConditions.applicationStatus,
        jobConfidential: props.selectedConditions.jobConfidential,
      });
      props.setModalIsOpen(true);
    } catch (e) {
      props.setAlertMessage(e.body['errors']);
    }
  }, [props.selectedConditions]);

  return (
    <Button
      color='primary'
      className='px-2h fw-normal py-2'
      onClick={handleOnSave}
      disabled={buttonDisabled(
        props.surveyTriggerName,
        props.surveyTemplate,
        props.conditions,
      )}
    >
      {props.edit ? 'Save' : 'Add'}
    </Button>
  );
}

export function PageHeaderRow(props: PropTypes) {
  return (
    <div className='app-card-header border-bottom-0 mb-3 w-100'>
      <div className='row align-items-center gx-3 w-100'>
        <div className='col-auto d-flex justify-content-between w-100'>
          <h2 className='app-card-title'>
            {props.edit ? 'Edit' : 'New'} Trigger
          </h2>
          <div className='d-flex align-items-center'>
            <Button
              color='danger'
              className='me-3'
              onClick={() =>
                (window.location.href =
                  '/organization_settings/survey_triggers')
              }
            >
              Cancel
            </Button>
            <AddButton {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}
