import React from 'react';
import { ScorecardListPanel } from './ScorecardListPanel';
import { User } from '../../../../entities/User';
import { LoadingState } from './LoadingState';
import { FeedbackFilters } from '../Filters';
import { SelectOption } from '../../../../components/Select';
import { CandidateRatingList } from './CandidateRatingList';
import { Application } from '../../../../entities/Application';

interface PropTypes {
  application: Application;
  currentUser: User;
  isLoading: boolean;
  filters: FeedbackFilters;
  viewAllScorecards: boolean;
  setIsLoading: (isLoading: boolean) => void;
  refreshCandidateRatingList: () => void;
}

export function mapSelectedIds(options: SelectOption[]) {
  return options.map((option) => parseInt(option.value));
}

export function FeedbackList(props: PropTypes) {
  return (
    <>
      {props.isLoading && <LoadingState />}
      {props.filters?.feedbackType === 'candidate_rating' ? (
        <CandidateRatingList {...props} />
      ) : (
        <ScorecardListPanel {...props} applicationId={props.application.id} />
      )}
    </>
  );
}
