import React from 'react';
import { Alert, AlertType } from '../../components/Alert';
import { ReferenceRequestStatus } from './ReferenceRequestStatus';

interface PropTypes {
  referenceRequestStatus: ReferenceRequestStatus;
  loadSurveyRequests: () => void;
}

export function ReferenceRequestConfirmationAlert(props: PropTypes) {
  return (
    <>
      {props.referenceRequestStatus === 'success' && (
        <Alert
          type={AlertType.Success}
          clearable={true}
          autoClearTimeout={4000}
          onClose={props.loadSurveyRequests}
        >
          Feedback has been successfully requested
        </Alert>
      )}
      {props.referenceRequestStatus === 'failed' && (
        <Alert
          type={AlertType.Danger}
          clearable={true}
          onClose={props.loadSurveyRequests}
        >
          Feedback request unsuccessful
        </Alert>
      )}
    </>
  );
}
