import React from 'react';
import { Application } from '../../../../../entities/Application';
import { User } from '../../../../../entities/User';
import classNames from 'classnames';
import styles from './../styles.module.scss';
import { FILTER_TYPE } from './../FilterByPills';
import { PendingActionsEmptyState } from './..';
import { FillScorecardItem } from './FillScorecardItem';
import { ScheduleInterviewItem } from './ScheduleInterviewItem';

export function ListActionItems(props: {
  application: Application;
  currentUser: User;
  showScheduleInterviewItem: boolean;
  filterBy: FILTER_TYPE;
}) {
  const userActionItems = props.application.actionItems.filter(
    (actionItem) => actionItem.hiringMember.userId === props.currentUser.id,
  );
  const pendingActionItems = userActionItems.filter(
    (actionItem) => actionItem.completed !== true,
  );
  const completedActionItems = userActionItems.filter(
    (actionItem) => actionItem.completed === true,
  );

  return (
    <>
      {userActionItems.length === 0 ? (
        <PendingActionsEmptyState />
      ) : (
        <div className={classNames(styles['pending-items-body'])}>
          <div className='mt-3 mb-2h d-flex justify-content-center align-items-center py-2 fs-6 bg-light-info rounded-1'>
            These are your pending actions.
          </div>
          <div className='d-flex flex-column gap-2 fs-5'>
            <FillScorecardItem
              pendingActionItems={pendingActionItems}
              completedActionItems={completedActionItems}
            />
            <ScheduleInterviewItem
              applicationId={props.application.id}
              interviewStageId={props.application.nextInterviewStageId}
              pendingActionItems={pendingActionItems}
              completedActionItems={completedActionItems}
              showScheduleInterviewItem={props.showScheduleInterviewItem}
            />
          </div>
        </div>
      )}
    </>
  );
}
