import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { IconButton } from '../../../../../../components/IconButton';

interface PropTypes {
  iconName: string;
  buttonText: string;
  onClick: () => void;
}

export function JobTemplateIconButton(props: PropTypes) {
  return (
    <div className='w-100'>
      <IconButton
        color='secondary'
        size='lg'
        iconName={props.iconName}
        iconClass={'fs-1'}
        buttonClass={classNames(
          'text-start',
          'mt-3',
          'w-24',
          'py-2',
          'border-gray-700',
          styles.button,
        )}
        iconTextClass={classNames('fs-4', 'fw-semibold', 'text-info')}
        buttonText={props.buttonText}
        onClick={props.onClick}
      />
    </div>
  );
}
