import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useParams, useNavigate } from 'react-router';
import { Col, Row, Button } from 'reactstrap';
import { ProgressBar } from '../../../../components/survey_engine/ProgressBar';
import { SurveyLayout } from '../../../../components/survey_engine/SurveyLayout';
import { SurveyLoadingStatus } from '../../../../types/survey_engine/SurveyLoadingStatus';
import { SurveyField } from './SurveyField';
import styles from '../styles.module.scss';
import { SubmitFeedbackButton } from './SubmitFeedbackButton';
import classNames from 'classnames';
import { Survey } from '../../../../entities/survey_engine/Survey';
import { SurveyQuestion } from '../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../../../entities/survey_engine/SurveyQuestionAnswer';
import { SurveySection } from '../../../../entities/survey_engine/SurveySection';
import { findLastUnansweredSectionIndex } from '../../../../utils/survey_engine/findLastUnansweredSectionIndex';
import { getQuestions } from '../../../../utils/survey_engine/getQuestions';
import { getQuestionsBasedOnConditions } from '../../../../utils/survey_engine/getQuestionsBasedOnConditions';
import { hasAnsweredAllQuestions } from '../../../../utils/survey_engine/hasAnsweredAllQuestions';
import { StyledSurveyQuestionTitle } from './StyledSurveyQuestionTitle';

const DEFAULT_PROGRESS_BAR_COLOR = '#19ceaf';

interface PropTypes {
  survey: Survey;
  surveySections: SurveySection[];
  defaultSurveySections: SurveySection[];
  totalSteps: number;
  onUpdateSurvey: (survey: Survey) => void;
  onChangeStatus: (status: SurveyLoadingStatus) => void;
  onFinishSurvey: () => void;
  submitAnswer: (
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ) => Promise<Survey>;
  onFollowUpSurvey?: () => void;
}

function getSectionQuestions(
  surveySection: SurveySection,
  allQuestions: SurveyQuestion[],
): SurveyQuestion[] {
  if (!surveySection) return [];
  if (allQuestions?.length === 0) return [];

  return allQuestions.filter(
    (question) =>
      question.section.id.toString() === surveySection.section_id.toString(),
  );
}

export function SurveyScreen(props: PropTypes) {
  const { step: stepParam } = useParams<'step'>();
  const step = parseInt(stepParam, 10);

  const [answers, setAnswers] = useState<SurveyQuestionAnswer[]>(
    props.survey.answers,
  );

  const extraSteps = props.totalSteps - props.defaultSurveySections.length;
  const surveySection = props.defaultSurveySections[step - 1 - extraSteps];

  const allQuestions = getQuestionsBasedOnConditions(
    getQuestions(props.survey),
    answers,
  );
  const sectionQuestions = getSectionQuestions(surveySection, allQuestions);
  const navigate = useNavigate();
  const navigateToStep = (step: number) => {
    navigate(`../step/${step}`);
  };

  const onSurveyEnd = () => {
    if (props.totalSteps === props.surveySections.length) {
      props.onFinishSurvey();
    } else {
      props.onFollowUpSurvey
        ? props.onFollowUpSurvey()
        : props.onFinishSurvey();
    }
  };

  const onPreviousClick = () => {
    if (step - 1 > 0) {
      navigateToStep(step - 1);
    }
  };

  const onNextClick = useCallback(() => {
    const nextSection = props.defaultSurveySections[step - extraSteps];
    const nextSectionQuestions = getSectionQuestions(nextSection, allQuestions);

    if (nextSectionQuestions.length > 0) {
      navigateToStep(step + 1);
    } else {
      onSurveyEnd();
    }
  }, [step, allQuestions, props.defaultSurveySections, props.surveySections]);

  useEffect(() => {
    if (isNaN(step) || step === 0 || step > props.totalSteps) {
      navigateToStep(findLastUnansweredSectionIndex(props.survey) + 1);
    }
  }, [step]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const progressBarColor =
    props.survey.company_info.primary_color || DEFAULT_PROGRESS_BAR_COLOR;

  if (surveySection === undefined) return null;

  return (
    <SurveyLayout
      companyInfo={props.survey.company_info}
      className={styles.surveyPage}
    >
      <Row className='mt-2 mb-5'>
        <Col>
          <ProgressBar
            progress={((step - 1) / props.totalSteps) * 100}
            color={progressBarColor}
            backgroundColor={'white'}
          />
        </Col>
      </Row>
      <div className={classNames(styles.surveySection)}>
        <div className={classNames('p-5')}>
          <Row>
            <Col>
              <h1>{surveySection.section_name}</h1>
              <small className='text-muted'>
                {surveySection.section_description}
              </small>
            </Col>
          </Row>
          <div className={classNames('px-4 pt-5')}>
            {sectionQuestions.map((question, index) => (
              <React.Fragment key={index}>
                {index > 0 && <hr className={'my-4'} />}
                <StyledSurveyQuestionTitle
                  key={question.id}
                  question={question}
                  companyInfo={props.survey.company_info}
                />
                {question.fields.map((f) => (
                  <SurveyField
                    key={f.id}
                    survey={props.survey}
                    question={question}
                    questionField={f}
                    surveyAnswer={answers.find(
                      (a) => question.id === a.question_id,
                    )}
                    onUpdateSurvey={props.onUpdateSurvey}
                    onUpdateSurveyAnswers={setAnswers}
                    submitAnswer={props.submitAnswer}
                  />
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <Row className={'mt-5'}>
        <Col>
          <Button
            onClick={onPreviousClick}
            size='lg'
            disabled={step === 1}
            color='secondary'
          >
            Previous
          </Button>
        </Col>
        <Col className='text-end mb-5'>
          {step !== props.totalSteps && (
            <Button
              size='lg'
              onClick={onNextClick}
              disabled={!hasAnsweredAllQuestions(answers, sectionQuestions)}
              style={{
                letterSpacing: `${props.survey.company_info.font_kerning}em`,
              }}
            >
              Next
            </Button>
          )}
          {step === props.totalSteps && (
            <SubmitFeedbackButton
              surveyAnswers={answers}
              allQuestions={allQuestions}
              onClick={onNextClick}
              fontKerning={props.survey.company_info.font_kerning}
            />
          )}
        </Col>
      </Row>
    </SurveyLayout>
  );
}
