import React, { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../../../components/Select';
import {
  JobPostTemplateInput,
  loadJobPostOptions,
} from './JobPostTemplateInput';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { Button, Col, Row } from 'reactstrap';
import { ShortDescriptionInput } from './ShortDescriptionInput';
import { IconButton } from '../../../../../../../components/IconButton';
import { IconText } from '../../../../../../survey_engine/SurveyPage/SurveyStartScreen/IconText';
import { Control, UseFormGetValues } from 'react-hook-form';
import { CreateJobDto } from '../../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { generateAiResponse } from './generateAiResponse';

interface PropTypes {
  setIsGeneratorOpened: (value) => void;
  formControl: Control<CreateJobDto>;
  formGetValues: UseFormGetValues<CreateJobDto>;
  setEditorState: (value: string) => void;
}

export function GenerateJobDescription(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [jobPostTemplateOptions, setJobPostTemplateOptions] = useState<
    SelectOption[]
  >([]);
  const [selectedjobPostTemplate, setSelectedjobPostTemplate] =
    useState<SelectOption>(null);

  const [description, setDescription] = useState<string>(null);

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  function handleOnCloseButtonClick() {
    props.setIsGeneratorOpened(false);
    setIsErrorMessage(false);
  }

  function handleOnGenerateButtonClick() {
    setIsErrorMessage(false);
    return generateDescription();
  }
  const generateDescription = async () =>
    await generateAiResponse(
      props.setEditorState,
      setIsErrorMessage,
      setIsGenerating,
      props.formGetValues('jobId'),
      props.formGetValues('name'),
      props.formGetValues('locationId'),
      props.formGetValues('locationType'),
      props.formGetValues('employmentType'),
      props.formGetValues('experienceLevel'),
      selectedjobPostTemplate ? selectedjobPostTemplate.value : null,
      description,
    );

  function handleOnCancelButtonClick() {
    setIsGenerating(false);
  }

  useEffect(() => {
    (async () => {
      setJobPostTemplateOptions(await loadJobPostOptions());

      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      <Row className='mt-4'>
        <FormFieldHeader
          fieldName={'Fill Description with AI'}
          isRequired={false}
          classNames={'fw-semibold'}
          fontSize={'fs-3'}
        />
      </Row>
      <Row className='mt-4 col-8'>
        <Col className='col-3'>
          <JobPostTemplateInput
            jobPostOptions={jobPostTemplateOptions}
            selectedJobPostOption={selectedjobPostTemplate}
            onSelectedJobPostUpdate={setSelectedjobPostTemplate}
            isLoading={isLoading}
          />
        </Col>
        <Col className='col-5'>
          <ShortDescriptionInput
            description={description}
            onChangeDescription={setDescription}
          />
        </Col>
        <Col className='d-flex align-items-end'>
          {!isGenerating ? (
            <>
              <Button
                size='md'
                color='borderless'
                onClick={handleOnCloseButtonClick}
                className='me-3'
              >
                Close
              </Button>
              <IconButton
                color='info'
                buttonText='Generate Description'
                iconName='bi-stars'
                size='md'
                onClick={handleOnGenerateButtonClick}
              />
            </>
          ) : (
            <>
              <Button
                size='md'
                color='danger'
                onClick={handleOnCancelButtonClick}
                className='me-3'
                disabled={isErrorMessage}
              >
                Cancel
              </Button>
              <IconText iconClassName='bi-stars' text='AI is writing' />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
