import * as React from 'react';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  INDENT_CONTENT_COMMAND,
  KEY_MODIFIER_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useCallback, useEffect, useState } from 'react';
import { UndoAction } from './UndoAction';
import { RedoAction } from './RedoAction';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { TextStyleDropDown, TextStyleType } from './TextStyleDropdown';
import { FormatTextButton } from './FormatTextButton';
import { TextAlignDropdown } from './TextAlignDropdown';
import { IndentationButton } from './IndentationButton';
import { VerticalDivider } from '../../../VerticalDivider';
import { HyperlinkButton } from './HyperlinkButton';
import { getSelectedNode } from '../../utils/getSelectedNode';
import { sanitizeUrl } from '../../utils/url';

export interface PropTypes {
  setIsLinkEditMode: React.Dispatch<boolean>;
  hideAlignLabel?: boolean;
  hideRedo?: boolean;
  hideUndo?: boolean;
  hideStyle?: boolean;
  hideAlignment?: boolean;
}

export function ToolbarPlugin(props: PropTypes): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState<TextStyleType>('paragraph');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
          );
          const type = (
            parentList ? parentList.getListType() : element.getListType()
          ) as TextStyleType;
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type as TextStyleType);
        }
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
    );
  }, [activeEditor, editor, updateToolbar]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          let url: string | null;
          if (!isLink) {
            props.setIsLinkEditMode(true);
            url = sanitizeUrl('https://');
          } else {
            props.setIsLinkEditMode(false);
            url = null;
          }
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [activeEditor, isLink, props.setIsLinkEditMode]);

  return (
    <div
      className={classNames(
        'border-start border-top border-end',
        'p-1',
        styles.toolbar,
      )}
    >
      {!props.hideUndo && (
        <UndoAction
          activeEditor={activeEditor}
          isEditable={isEditable}
          updateToolbar={updateToolbar}
          classNames={styles['toolbar-item']}
        />
      )}
      {!props.hideRedo && (
        <RedoAction
          activeEditor={activeEditor}
          isEditable={isEditable}
          updateToolbar={updateToolbar}
          classNames={styles['toolbar-item']}
        />
      )}
      {!props.hideRedo && !props.hideUndo && <VerticalDivider />}
      {!props.hideStyle && activeEditor === editor && (
        <>
          <TextStyleDropDown textStyle={blockType} />
          <VerticalDivider />
        </>
      )}
      <FormatTextButton
        isEditable={isEditable}
        isActive={isBold}
        commandName={'bold'}
        shortcutLetter={'B'}
      />
      <FormatTextButton
        isEditable={isEditable}
        isActive={isItalic}
        commandName={'italic'}
        shortcutLetter={'I'}
      />
      <FormatTextButton
        isEditable={isEditable}
        isActive={isUnderline}
        commandName={'underline'}
        shortcutLetter={'U'}
      />
      <HyperlinkButton
        isEditable={isEditable}
        isLink={isLink}
        setIsLinkEditMode={props.setIsLinkEditMode}
      />
      {!props.hideAlignment && (
        <>
          <VerticalDivider />
          <TextAlignDropdown hideButtonText={props.hideAlignLabel} />
          <VerticalDivider />
          <IndentationButton
            isEditable={isEditable}
            command={INDENT_CONTENT_COMMAND}
            direction={'left'}
            shortcut={'Tab'}
          />
          <IndentationButton
            isEditable={isEditable}
            command={OUTDENT_CONTENT_COMMAND}
            direction={'right'}
            shortcut={'Shift+Tab'}
          />
        </>
      )}
    </div>
  );
}
