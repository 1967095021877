import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';

interface PropTypes {
  text: string;
  maxWords?: number;
  maxChars?: number;
  maxLines?: number;
  ellipsize?: boolean;
  className?: string;
  tooltip?: boolean;
}

export function TruncateText(props: PropTypes) {
  const ref = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [allowTooltip, setAllowTooltip] = useState<boolean>(false);

  let text = props.text;
  if (props.maxChars != null) {
    text = text.slice(0, props.maxChars);
  }

  if (props.maxWords != null) {
    text = text.split(' ').slice(0, props.maxWords).join(' ');
  }

  useEffect(() => {
    if (
      props.tooltip &&
      (props.text != text ||
        ref.current.scrollHeight > ref.current.offsetHeight)
    ) {
      setAllowTooltip(true);
    }
  }, []);

  return (
    <>
      <div
        className={props.className}
        ref={ref}
        style={{
          display: '-webkit-box',
          lineClamp: props.maxLines || 1,
          WebkitLineClamp: props.maxLines || 1,
          boxOrient: 'vertical',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
      >
        {text}
        {props.ellipsize && props.text != text ? '...' : null}
      </div>
      {allowTooltip && (
        <Tooltip
          target={ref}
          isOpen={tooltipOpen}
          toggle={() => setTooltipOpen(!tooltipOpen)}
        >
          {props.text}
        </Tooltip>
      )}
    </>
  );
}
