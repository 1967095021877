import React from 'react';
import classNames from 'classnames';
import { Table } from 'reactstrap';
import { MetricCard } from '../../../components/MetricCard';
import tableStyles from '../styles.module.scss';
import { Chip, ChipColor } from '../../../../../components/Chip';
import { OverviewChip } from '../../../components/OverviewChip';

export interface TopicOverview {
  name: string;
  views: number;
  rating?: number;
  sessions: number;
}

interface PropTypes {
  bestTopics: TopicOverview[];
}

function BestTopicRow(props: { topic: TopicOverview }) {
  return (
    <tr>
      <td className='d-flex'>
        <Chip
          className='text-truncate mw-100 fw-semibold px-2'
          color={ChipColor.Gray}
        >
          {props.topic.name}
        </Chip>
      </td>
      <td className='text-center'>
        <OverviewChip iconClass='bi-eye' value={props.topic.views} />
      </td>
      <td className='text-center'>
        <OverviewChip
          iconClass='bi-journal-text'
          value={props.topic.sessions}
        />
      </td>
      <td className='text-center'>
        <OverviewChip
          classNames='bg-light-info'
          iconClass='bi-star'
          value={
            props.topic.rating != null ? props.topic.rating.toFixed(1) : 'N/A'
          }
        />
      </td>
    </tr>
  );
}

export function OverviewBestTopicsMetric(props: PropTypes) {
  return (
    <MetricCard
      title='Best Topics'
      tooltip="5 top rated session topics according to users' feedback. Additionally, displays the number of session views and the number of sessions that include that topic."
      className='mb-4'
    >
      <Table
        borderless
        className={classNames('table-fixed', tableStyles['overview-table'])}
      >
        <thead className='text-nowrap'>
          <tr className='fs-6 text-center'>
            <th className='fw-normal ps-0 py-0 col-7'></th>
            <th className='fw-normal py-0 col-auto'>Views</th>
            <th className='fw-normal py-0 col-auto'>Sessions</th>
            <th className='fw-normal py-0 col-auto'>Rating</th>
          </tr>
        </thead>
        <tbody>
          {props.bestTopics.map((topic, idx) => (
            <BestTopicRow key={idx} topic={topic} />
          ))}
        </tbody>
      </Table>
    </MetricCard>
  );
}
