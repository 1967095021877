import React from 'react';
import classNames from 'classnames';
import { IconChip } from '../../../../components/IconChip';
import { ChipColor } from '../../../../components/Chip';

interface PropTypes {
  classNames?: string;
  iconClass?: string;
  value: string | number;
}

export function OverviewChip(props: PropTypes) {
  return (
    <IconChip
      chipColor={ChipColor.Gray}
      className={classNames('fw-semibold', 'px-2', props.classNames)}
      iconPosition='left'
      iconClass={props.iconClass && `text-blue-gray-700 bi ${props.iconClass}`}
    >
      {props.value}
    </IconChip>
  );
}
