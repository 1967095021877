import { ApiService } from '../../ApiService';
import { ApplicationFormTemplate } from '../../../entities/applicant_tracking/ApplicationFormTemplate';

export class ApplicationFormTemplateService {
  public static list(): Promise<ApplicationFormTemplate[]> {
    return ApiService.get<ApplicationFormTemplate[]>(
      '/api/applicant_tracking/application_form_templates',
    );
  }

  public static get(id: number): Promise<ApplicationFormTemplate> {
    return ApiService.get<ApplicationFormTemplate>(
      `/api/applicant_tracking/application_form_templates/${id}`,
    );
  }
}
