export const SCHEDULE_FROM = {
  now: 'Now',
  one_hour: '1 Hour',
  two_hours: '2 Hours',
  three_hours: '3 Hours',
  tomorrow: 'Tomorrow',
  next_week: 'Next week',
};

export type ScheduleFromType = keyof typeof SCHEDULE_FROM;

export function mapScheduleFromToInt(scheduleFrom: ScheduleFromType) {
  switch (scheduleFrom) {
    case 'now':
      return 0;
    case 'one_hour':
      return 1;
    case 'two_hours':
      return 2;
    case 'three_hours':
      return 3;
    case 'tomorrow':
      return 24;
    case 'next_week':
      return 24 * 7;
  }
}

export function mapIntToScheduleFrom(value: number): ScheduleFromType {
  switch (value) {
    case 0:
      return 'now';
    case 1:
      return 'one_hour';
    case 2:
      return 'two_hours';
    case 3:
      return 'three_hours';
    case 24:
      return 'tomorrow';
    case 24 * 7:
      return 'next_week';
  }
}
