import React from 'react';
import { EmptyState } from '../../../components/EmptyState';
import { IconButton } from '../../../components/IconButton';
import { Size } from '../../../utils/types/Size';

interface PropTypes {
  title: string;
  text: string;
  addButtonText: string;
  onClickHref: string;
  disabled?: boolean;
  buttonSize?: Size;
  iconName?: string;
}

export default function ListPageEmptyState(props: PropTypes) {
  const disabled: boolean = props.disabled ?? false;

  return (
    <EmptyState
      title={props.title}
      text={props.text}
      cardless={true}
      actionButton={
        <IconButton
          color='primary'
          iconName={props.iconName ?? 'bi-plus-circle'}
          size={props.buttonSize ?? 'lg'}
          buttonClass='mt-4'
          buttonText={props.addButtonText}
          onClick={() => {
            window.location.href = props.onClickHref;
          }}
          disabled={disabled}
        />
      }
    />
  );
}
