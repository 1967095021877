import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { HiringMemberList } from '../../../../entities/v1/HiringMemberList';

export interface ListParams {
  name?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
}

export class HiringMemberService {
  public static list(params: ListParams): Promise<HiringMemberList> {
    return ApiService.get<HiringMemberList>(
      '/api/v1/applicant_tracking/hiring_members',
      snakecaseKeys(params),
    );
  }
}
