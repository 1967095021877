import React from 'react';
import { EmptyState } from '../../../../../components/EmptyState';

export function JobBoardsEmptyState() {
  return (
    <EmptyState
      title='We have encountered an error'
      text='Please try again. If the error persists contact our team at support@screenloop.com'
      cardless={true}
    />
  );
}
