import React from 'react';
import classNames from 'classnames';
import { TruncateText } from '../../../../../../../../components/TruncateText';

interface PropTypes {
  title: string;
  whitelabelEnabled: boolean;
}

const MAXCHARS = 48;

export function JobPostCardTitle(props: PropTypes) {
  const titleTextClass = props.whitelabelEnabled ? 'text-primary' : 'text-info';

  return (
    <h4 className={classNames('text-start', titleTextClass)}>
      <TruncateText
        maxChars={MAXCHARS}
        tooltip={true}
        text={props.title}
        ellipsize={true}
      />
    </h4>
  );
}
