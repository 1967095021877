import React, { useState } from 'react';
import { Session } from '../../../../entities/training/Session';
import { User } from '../../../../entities/User';
import { Col, Row } from 'reactstrap';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { SessionCardBody } from '../SessionCardBody';
import {} from '../SessionsEmptyState';
import { SessionCardHeader } from '../SessionCardHeader';
import { SessionsFilterHeader } from '../SessionsFilterHeader';
import { SessionsEmptyResultState } from '../SessionsEmptyResultState';
import { WelcomeMessage } from '../../components/WelcomeMessage';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface PropTypes {
  sessions: Session[];
  currentUser: User;
}

function getFilteredSessions(sessions: Session[], viewAssigned: boolean) {
  if (!viewAssigned) {
    return sessions;
  }

  return sessions.filter((session: Session) => session.assigned);
}

function getRefreshedSessions(
  sessions: Session[],
  newSession: Session,
): Session[] {
  return sessions.map((session) => {
    if (session.id === newSession.id) {
      return newSession;
    } else {
      return session;
    }
  });
}

function SessionCard(props: {
  session: Session;
  currentUser: User;
  updateSession: (session: Session) => void;
}) {
  return (
    <Col
      xs='12'
      md='6'
      lg='4'
      className={'mb-4'}
      data-testid={props.session.id}
      key={props.session.id}
    >
      <DeprecatedPanel
        additionalInnerContainerClassNames='p-3'
        hasHooverEffect={true}
      >
        <SessionCardHeader
          session={props.session}
          currentUser={props.currentUser}
        />
        <SessionCardBody
          currentUser={props.currentUser}
          session={props.session}
          onChangeSession={props.updateSession}
        />
      </DeprecatedPanel>
    </Col>
  );
}

function SessionListHeader(props: {
  currentUser: User;
  assignedEnabled: boolean;
  onChangeAssigned: (assignedEnabled: boolean) => void;
}) {
  return (
    <SessionsFilterHeader
      assignedEnabled={props.assignedEnabled}
      onChangeAssigned={() => props.onChangeAssigned(!props.assignedEnabled)}
      leftChildElement={
        props.currentUser.isAdmin ? (
          <h2 className='mb-4'>All sessions</h2>
        ) : (
          <WelcomeMessage
            userName={props.currentUser.name}
            text='Here’s a list of hand-picked sessions for you.'
          />
        )
      }
    />
  );
}

export function SessionsListTab(props: PropTypes) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const assignedToMeEnabled = searchParams.get('assignedToMe') === 'true';
  const [assignedToMe, setAssignedToMe] = useState(assignedToMeEnabled);
  const [sessions, setSessions] = useState(props.sessions);
  const filteredSessions = getFilteredSessions(sessions, assignedToMe);
  const handleTabClick = (value) => {
    setAssignedToMe(value);
    searchParams.delete('assignedToMe');
    navigate(`?${searchParams.toString()}&assignedToMe=${value}`);
  };

  return (
    <>
      <SessionListHeader
        currentUser={props.currentUser}
        assignedEnabled={assignedToMe}
        onChangeAssigned={() => handleTabClick(!assignedToMe)}
      />
      <Row data-testid='sessions-list'>
        {filteredSessions.length > 0 ? (
          filteredSessions.map((session) => (
            <SessionCard
              key={session.id}
              session={session}
              currentUser={props.currentUser}
              updateSession={(session) =>
                setSessions(getRefreshedSessions(props.sessions, session))
              }
            />
          ))
        ) : (
          <SessionsEmptyResultState />
        )}
      </Row>
    </>
  );
}
