import React from 'react';
import { ApplicationFeed } from '../../../../../../entities/ApplicationFeed';
import { ApplicationFeedComment } from '../../../../../../entities/ApplicationFeedComment';
import { User } from '../../../../../../entities/User';
import { Avatar } from '../../../../../../components/Avatar';
import { CommentBody } from '../CommentBody';
import classNames from 'classnames';

interface PropTypes {
  applicationFeed: ApplicationFeed;
  comment: ApplicationFeedComment;
  currentUser: User;
  onDelete: () => void;
  onEdit: () => void;
  reloadApplication: (state: boolean) => void;
}

export function CommentBlock(props: PropTypes) {
  return (
    <>
      <div className='d-flex mt-4'>
        <div className='me-2'>
          <Avatar name={props.comment.authorName} size='lg' />
        </div>
        <div
          className={classNames(
            'rounded-4 w-100',
            props.comment.userId == props.currentUser.id
              ? 'bg-green-light'
              : 'bg-gray',
          )}
        >
          <CommentBody
            entry={props.comment}
            currentUser={props.currentUser}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
          />
        </div>
      </div>
    </>
  );
}
