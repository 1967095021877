import React from 'react';
import { Nps } from '../../../entities/survey_engine/Nps';
import LogoOnly from '../../../images/logo-only.svg';

interface PropTypes {
  nps: Nps;
  url: string;
}

export default function JobAssistantPage(props: PropTypes) {
  return (
    <div className='text-center px-4'>
      <p className='my-4'>
        Hey 👋, this job scored{' '}
        <strong>{Number(props.nps.value).toFixed(1)}</strong> on CNPS in the
        last 30 days!
      </p>
      <a
        className='btn btn-info d-inline-flex gap-2'
        href={props.url}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={LogoOnly} />
        Check on Screenloop
      </a>
    </div>
  );
}
