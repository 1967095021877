import React from 'react';
import { AddSectionButton } from '../AddSectionButton';
import { Section, SectionData } from '../Section';
import { SectionListHeader } from '../SectionListHeader';
import { ColumnConfig, FieldConfig } from '../Field';
import { Action } from '../dispatcher';
import { FieldType } from '../../../../entities/v1/survey_engine/Question';

interface PropTypes {
  fieldConfigs: FieldConfig[];
  singleTypeSections: boolean;
  sections: SectionData[];
  title: string;
  subtitle: string;
  dispatch?: React.Dispatch<Action>;
  readOnly?: boolean;
  columns: ColumnConfig[];
  addFieldButtonText?: string;
  fieldDeleteTooltip?: string;
  defaultFieldType?: FieldType;
  /** True if the Section has an associated description. */
  enableDescription?: boolean;
}

export function SectionList(props: PropTypes) {
  function handleAddSection() {
    props.dispatch({
      type: 'section_add',
      defaultFieldType: props.defaultFieldType,
    });
  }

  return (
    <div className='py-4'>
      <SectionListHeader
        title={props.title}
        subtitle={props.subtitle}
        onAddSection={handleAddSection}
        readOnly={props.readOnly}
      />
      <div className='py-4 vstack gap-4'>
        {props.sections.map((section, i) => (
          <Section
            key={section.cId}
            fieldConfig={props.fieldConfigs}
            singleTypeSections={props.singleTypeSections}
            data={section}
            dispatch={props.dispatch}
            removeDisabled={
              props.sections.length === 1 || section.removable === false
            }
            upDisabled={i === 0}
            downDisabled={i === props.sections.length - 1}
            readOnly={props.readOnly}
            columns={props.columns}
            addFieldButtonText={props.addFieldButtonText}
            fieldDeleteTooltip={props.fieldDeleteTooltip}
            enableDescription={props.enableDescription}
          />
        ))}
      </div>
      {!props.readOnly && (
        <div className='d-flex justify-content-center'>
          <AddSectionButton onClick={handleAddSection} />
        </div>
      )}
    </div>
  );
}
