import React, { useCallback, useState } from 'react';
import { PublishJobConfirmationModal } from '../PublishJobConfirmationModal';
import { IconSpan } from '../../../../../components/IconSpan';
import { DropdownAction } from '../../../../../components/Dropdown/DropdownMenu/DropdownAction';

interface PropTypes {
  jobId: number;
  jobName: string;
  publishEnabled: boolean;
  unpublishEnabled: boolean;
}

export default function PublishJobButton(props: PropTypes) {
  const publishAction = props.unpublishEnabled ? 'Unpublish' : 'Publish';
  const actionIcon = props.unpublishEnabled
    ? 'bi-cloud-slash'
    : 'bi-cloud-arrow-up';

  const [isPublishJobModalOpen, setIsPublishJobModalOpen] = useState(false);

  const onPublishingJob = useCallback(async () => {
    setIsPublishJobModalOpen(true);
  }, []);

  if (!props.unpublishEnabled && !props.publishEnabled) {
    return null;
  }

  return (
    <>
      <PublishJobConfirmationModal
        jobId={props.jobId}
        jobName={props.jobName}
        publishAction={publishAction}
        isModalOpen={isPublishJobModalOpen}
        setIsModalOpen={setIsPublishJobModalOpen}
        redirectUri={window.location.href}
      />
      <DropdownAction
        action={onPublishingJob}
        buttonChild={
          <IconSpan
            className='ms-2'
            spanText={publishAction}
            icon={{ name: actionIcon }}
          />
        }
      />
    </>
  );
}
