import React from 'react';
import { Table } from 'reactstrap';
import classNames from 'classnames';
import { SurveyQuestionField } from '../../../../reference_check/entities/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../reference_check/entities/SurveyQuestionFieldAnswer';
import { SurveyQuestionFieldOption } from '../../../../reference_check/entities/SurveyQuestionFieldOption';
import styles from './styles.module.scss';
import { SurveyQuestion } from '../../../../reference_check/entities/SurveyQuestion';
import { PagePanel } from '../PagePanel';

const POSITIVE_COLORS = {
  colorClass: 'text-info',
  backgroundClass: 'bg-light-info',
};
const NEGATIVE_COLORS = {
  colorClass: 'text-danger',
  backgroundClass: 'bg-light-danger',
};

interface PropTypes {
  question: SurveyQuestion;
  questionField: SurveyQuestionField;
  referralAnswers: SurveyQuestionFieldAnswer[];
  candidateAnswers: SurveyQuestionFieldAnswer[];
  isPositive: boolean;
  exportToPDF?: boolean;
}

interface OptionCount {
  option: SurveyQuestionFieldOption;
  candidateChecked: boolean;
  referralCount: number;
}

const findOptionById = (
  questionField: SurveyQuestionField,
  id: number,
): SurveyQuestionFieldOption => {
  return questionField.options.find((opt) => opt.id === id);
};

const countAnswerOptions = (
  questionField: SurveyQuestionField,
  referralAnswers: SurveyQuestionFieldAnswer[],
  candidateAnswers: SurveyQuestionFieldAnswer[],
): OptionCount[] => {
  const count: { [key: number]: OptionCount } = {};
  referralAnswers.forEach((answer) => {
    answer.option_ids.forEach((optionId) => {
      count[optionId] ||= {
        option: findOptionById(questionField, optionId),
        referralCount: 0,
        candidateChecked: false,
      };
      count[optionId].referralCount += 1;
    });
  });
  candidateAnswers.forEach((answer) => {
    answer.option_ids.forEach((optionId) => {
      count[optionId] ||= {
        option: findOptionById(questionField, optionId),
        referralCount: 0,
        candidateChecked: false,
      };
      count[optionId].candidateChecked = true;
    });
  });
  return Object.values(count).sort((a, b) => b.referralCount - a.referralCount);
};

const renderMentionText = (count: number) => {
  if (count === 1) return 'Mention';
  return 'Mentions';
};

export const RankResultsTable = React.memo(function RankResultsTable(
  props: PropTypes,
) {
  const hasReferralData = props.referralAnswers.some(
    (answer) => answer.option_ids.length !== 0,
  );
  const hasCandidateData = props.candidateAnswers.some(
    (answer) => answer.option_ids.length !== 0,
  );

  if (!hasReferralData && !hasCandidateData) return null;

  const colors = props.isPositive ? POSITIVE_COLORS : NEGATIVE_COLORS;
  const optionCount: OptionCount[] = countAnswerOptions(
    props.questionField,
    props.referralAnswers,
    props.candidateAnswers,
  );

  return (
    <PagePanel question={props.question} exportToPDF={props.exportToPDF}>
      <div className={`col-12 ${getScrollingClasses(props.exportToPDF)}`}>
        <Table
          className={classNames(
            'table',
            'm-0',
            'fs-5',
            'text-primary',
            'align-middle',
            styles.table,
            styles.tableFixed,
          )}
          borderless
        >
          <thead className='fw-semibold'>
            <tr>
              <th scope='col'>Rank</th>
              <th scope='col'>Skill / Attribute</th>
              {hasCandidateData && (
                <th scope='col' className='text-center'>
                  Candidate
                </th>
              )}
              <th scope='col' className='text-center'>
                References
              </th>
            </tr>
          </thead>
          <tbody>
            {optionCount.map((count, index) => (
              <tr key={count.option.id} className={styles.tableRow}>
                <th scope='row' className={`${colors.colorClass}`}>
                  {index + 1}.
                </th>
                <td>
                  <span
                    className={classNames(
                      styles.badge,
                      colors.colorClass,
                      colors.backgroundClass,
                      'py-2',
                      'px-3',
                    )}
                  >
                    {count.option.name}
                  </span>
                </td>
                {hasCandidateData && (
                  <td className='text-center align-middle'>
                    {count.candidateChecked ? (
                      <h3 className='mb-0'>
                        <i
                          className='bi bi-check-circle'
                          style={{ width: '1em', height: '1em' }}
                        />
                      </h3>
                    ) : (
                      <h3 className='mb-0'>
                        <i
                          className='bi bi-check-circle text-muted'
                          style={{ width: '1em', height: '1em' }}
                        />
                      </h3>
                    )}
                  </td>
                )}
                <td className='text-center'>
                  <span
                    className={classNames(
                      styles.badge,
                      styles.outline,
                      colors.colorClass,
                      colors.backgroundClass,
                      'py-2',
                      'px-3',
                    )}
                  >
                    {count.referralCount}{' '}
                    {renderMentionText(count.referralCount)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </PagePanel>
  );
});

function getScrollingClasses(exportToPDF: boolean) {
  if (exportToPDF === true) {
    return styles.extended;
  } else {
    return styles.scrollable;
  }
}
