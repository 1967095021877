import classNames from 'classnames';
import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './styles.module.scss';

interface PropTypes {
  valueAsPercentage: number;
  text: string;
  color?: string;
  labelText?: string;
  labelClassName?: string;
  labelColor?: string;
  rating_legend: {
    minScore: number;
    label: string;
    color: string;
    bgColor: string;
  }[];
}

export function CircleChart(props: PropTypes) {
  const scoreAboveIndex = props.rating_legend.findIndex(
    (v) => v.minScore > props.valueAsPercentage,
  );
  const scoreIndex =
    scoreAboveIndex === -1
      ? props.rating_legend.length - 1
      : scoreAboveIndex - 1;
  const scoreLegend = props.rating_legend[scoreIndex];
  const labelText = props.labelText || scoreLegend.label;

  return (
    <div className='h-100 d-flex flex-column'>
      <CircularProgressbar
        value={props.valueAsPercentage}
        text={props.text}
        styles={buildStyles({
          pathColor: props.color || scoreLegend.color,
          textColor: props.color || scoreLegend.color,
          trailColor: '#FAFCFF',
        })}
        className='h2 px-4'
      />
      <div className='text-center mt-2'>
        <h3
          className={classNames('mb-0', props.labelClassName, styles.label)}
          style={{ color: props.labelColor || scoreLegend.color }}
          title={labelText}
        >
          {labelText}
        </h3>
      </div>
    </div>
  );
}
