import React from 'react';
import { SurveyRoute } from './SurveyRoute';
import defaultFinishScreenImage from '../../../images/woman-success.svg';
import { Survey } from '../../../entities/survey_engine/Survey';
import { SubmittedAnswersScreen } from './SubmittedAnswersScreen';
import { SurveyFinalScreen } from './SurveyFinalScreen';

interface PropTypes {
  survey: Survey;
  isDemographicsSubmitted?: boolean;
  isReferralsSubmitted?: boolean;
}

export function FinishRoute(props: PropTypes) {
  const finishScreenImage =
    props.survey.company_info.ce_survey_completed_image_url ??
    defaultFinishScreenImage;
  const headingStyle = {
    letterSpacing: props.survey.company_info.font_kerning * 2 + 'em',
  };

  return (
    <SurveyRoute survey={props.survey} status='completed' mismatchPath='..'>
      {props.isDemographicsSubmitted ? (
        <SubmittedAnswersScreen
          survey={props.survey}
          text={`We appreaciate you contributing to the creation of a better and fairer${' '} ${
            props.survey.organization_name
          }.`}
          finishScreenImage={finishScreenImage}
          headingStyle={headingStyle}
        />
      ) : props.isReferralsSubmitted ? (
        <SubmittedAnswersScreen
          survey={props.survey}
          text={
            'We will get in touch asap. We appreciate you contributing to the creation of a better Screenloop. See you soon!'
          }
          finishScreenImage={finishScreenImage}
          headingStyle={headingStyle}
        />
      ) : (
        <SurveyFinalScreen
          survey={props.survey}
          finishScreenImage={finishScreenImage}
          headingStyle={headingStyle}
        />
      )}
    </SurveyRoute>
  );
}
