import { Survey } from '../../entities/survey_engine/Survey';
import { filterDefaultFlowSections } from './filterDefaultFlowSections';
import { SurveyQuestionFieldAnswer } from '../../entities/survey_engine/SurveyQuestionFieldAnswer';

function isAnswerValueValid(value) {
  return value != null && value != '';
}

function areAnswerOptionsValid(optionIds) {
  return optionIds?.length > 0;
}

function isAnswerValid(fieldAnswer: SurveyQuestionFieldAnswer) {
  return (
    isAnswerValueValid(fieldAnswer.value) ||
    areAnswerOptionsValid(fieldAnswer.option_ids)
  );
}

export function findLastUnansweredSectionIndex(survey: Survey): number {
  const sections = filterDefaultFlowSections(survey.sections);

  const lastUnansweredQuestion = survey.answers.find((answer) =>
    answer.fields.some((fieldAnswer) => !isAnswerValid(fieldAnswer)),
  );

  if (lastUnansweredQuestion === undefined) return sections.length - 1;

  return sections.findIndex((section) => {
    const sectionQuestionIds = section.questions.map((question) => question.id);
    return sectionQuestionIds.includes(lastUnansweredQuestion.question_id);
  });
}
