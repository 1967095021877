import React from 'react';
import { Button } from 'reactstrap';
import { CandidateService } from '../../../../../../../services/applicant_tracking/CandidateService';
import { CandidateActionProps } from '..';
import { IconSpan } from '../../../../../../../components/IconSpan';

interface ModalProps {
  candidateId: number;
  candidateName: string;
  onClose: (action?: CandidateActionProps) => void;
}

export function DeleteModal(props: ModalProps) {
  const performAction = async () => {
    try {
      await CandidateService.delete(props.candidateId);

      props.onClose({
        candidateName: props.candidateName,
        action: 'Delete',
        state: 'Success',
      });
    } catch (error) {
      props.onClose({
        candidateName: props.candidateName,
        action: 'Delete',
        state: 'Error',
      });

      console.error(error);
    }
  };

  return (
    <>
      <div className='mb-3h'>
        Are you sure you want to delete the candidate{' '}
        <span className='fw-bold'>{props.candidateName}</span>?
      </div>
      <div className='mt-4 fs-5 bg-light-warning py-2 px-3 rounded align-self-start'>
        <IconSpan
          spanText='Deleting this candidate will permanently remove them from Screenloop. This action cannot be undone.'
          icon={{ name: 'bi-info-circle' }}
        />
      </div>
      <div className='d-flex mt-6h justify-content-end'>
        <Button className='me-2' color='danger' onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button color='primary' onClick={performAction}>
          Delete
        </Button>
      </div>
    </>
  );
}
