export function trimFileName(filename: string, maxLength: number) {
  if (filename.length <= maxLength) return filename;

  // if the filename is too long, the format should be abc...de.pdf
  const suffix = filename.substring(filename.lastIndexOf('.') - 2);
  return `${filename.substring(
    0,
    maxLength - (suffix.length + 3),
  )}...${suffix}`;
}
