import { ApiService } from '../../../services/ApiService';
import { SurveyRequestInfo } from '../../entities/SurveyRequestInfo';

export class CandidateSurveyRequestService {
  public static get(id: number): Promise<SurveyRequestInfo> {
    return ApiService.get<SurveyRequestInfo>(
      `/api/reference_check/candidate/survey_requests/${id}`,
    );
  }
}
