import React from 'react';
import { Button } from 'reactstrap';

interface PropTypes {
  currentSectionIndex: number;
  onPreviousClick: () => void;
}

function shouldDisable(currentSectionIndex: number): boolean {
  return currentSectionIndex == 0;
}

export function PreviousButton(props: PropTypes) {
  return (
    <Button
      className='me-2'
      color='secondary'
      disabled={shouldDisable(props.currentSectionIndex)}
      onClick={props.onPreviousClick}
    >
      Previous
    </Button>
  );
}
