import React from 'react';
import { Avatar } from '../../../Avatar';
import { Button } from 'reactstrap';
import { Note } from '../../../../entities/v1/interview_intelligence/Note';

export interface PropTypes {
  uniqueUserNames: string[];
  replies: Note[];
  setIsRepliesExpanded: (repliesExpanded: boolean) => void;
}

export function CommentsListRow(props: PropTypes) {
  return (
    <div className='mt-2 d-flex align-items-center'>
      <div className='d-flex'>
        {props.uniqueUserNames.slice(0, 6).map((name) => (
          <div key={name}>
            <Avatar name={name} size={'md'} type={'initials'} />
          </div>
        ))}
      </div>
      {props.replies.length > 0 && (
        <>
          <span className='text-blue-gray-900 me-2'>
            {props.replies.length} Comment
            {props.replies.length > 1 ? 's' : null}
          </span>
          <Button
            size='sm'
            color='borderless'
            onClick={() => props.setIsRepliesExpanded(true)}
          >
            View All
          </Button>
        </>
      )}
    </div>
  );
}
