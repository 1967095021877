import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'reactstrap';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  className?: string;
  tabs: { key: string; label: string }[];
  onTabChanged: (key: string) => void;
  activeTab: string;
}

export type SessionsTabType = 'overview' | 'sessions';

export function SessionsTabs(props: PropTypes) {
  return (
    <div className={props.className}>
      <Nav
        className={classNames('app-nav-tabs', 'bg-transparent', styles.navLink)}
      >
        {props.tabs.map(({ key, label }, idx) => {
          return (
            <Link
              key={idx}
              className={classNames(
                'nav-link',
                props.activeTab === key && 'active',
              )}
              to={`?tab=${key}`}
              onClick={() => props.activeTab !== key && props.onTabChanged(key)}
            >
              {label}
            </Link>
          );
        })}
      </Nav>
    </div>
  );
}
