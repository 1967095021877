import React from 'react';
import { BlankState } from './BlankState';

interface PropTypes {
  type: string;
}

export function EmptyState(props: PropTypes) {
  let name: string;
  switch (props.type) {
    case 'all':
      name = 'Highlights';
      break;
    case 'scorecard_attribute':
      name = 'Attributes';
      break;
    case 'note':
      name = 'Notes';
      break;
    case 'reaction':
      name = 'Reactions';
      break;
    case 'automatic_question':
      name = 'Automatic Questions';
      break;
  }

  return (
    <BlankState
      title={`No ${name} available`}
      subtitle={
        'Start using the Screenloop Sidekick feature to add notes, attributes and reactions.'
      }
    />
  );
}
