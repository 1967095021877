import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { Alert, AlertType } from '../../../../components/Alert';
import { SelectOption } from '../../../../components/Select';
import { SurveyTemplate } from '../../../../entities/candidate_experience/SurveyTemplate';
import { SurveyTrigger } from '../../../../entities/candidate_experience/SurveyTrigger';
import { Job } from '../../../../entities/Job';
import { JobStage } from '../../../../entities/JobStage';
import { Location } from '../../../../entities/Location';
import { RejectReason } from '../../../../entities/RejectReason';
import { buildLabelledOptions } from '../../../../utils/buildLabelledOptions';
import { filteredSelectedOptionsByLabel } from '../../../../utils/filteredSelectedOptionsByLabel';
import { filteredSelectedOptionsByValue } from '../../../../utils/filteredSelectedOptionsByValue';
import { SurveyTriggerConfirmationModal } from '../SurveyTriggerConfirmationModal';
import { SurveyTriggerPageTitleCol } from '../SurveyTriggerPageTitleCol';
import { PageCard } from './PageCard';
import { buildSelectedConditions } from './utils/buildSelectedConditions';
import { filterSurveyTemplate } from './utils/filterSurveyTemplate';
import { setApplicableConditions } from './utils/setApplicableConditions';
import { computeUpdateConditions } from './utils/computeUpdateConditions';
import { buildJobLabelledOptions } from '../../../../utils/buildJobLabelledOptions';

interface PropTypes {
  survey_trigger: SurveyTrigger;
  survey_templates?: SurveyTemplate[];
  survey_template?: SurveyTemplate;
  jobs: Job[];
  locations: Location[];
  reject_reasons: RejectReason[];
  job_stages: JobStage[];
  edit?: boolean;
}

export interface Condition {
  type?: SelectOption;
  mandatory: boolean;
  available: SelectOption[];
  singleSelected: SelectOption;
  multiSelected: SelectOption[];
  invert: SelectOption;
}

export interface ConditionsData {
  [key: string]: {
    value: string;
    label: string;
    options: any[];
    multiSelected: SelectOption[];
    singleSelected: any;
    hasInvertCondition: boolean;
    invertCondition: boolean;
    triggerCondition: number[] | string;
  };
}

export interface SelectedConditions {
  name: string;
  invertJobCondition: boolean;
  jobIds: number[];
  locationIds: number[];
  invertLocationCondition: boolean;
  jobStageName: string[];
  applicationRejectReason: string[];
  applicationStatus: string[];
  jobConfidential: string[];
  active: boolean;
  processingStartsAt: Moment;
  invertJobStageCondition: boolean;
  surveyTemplateId: number;
  maxReminders: number;
  delayDays: number;
}

const CONDITIONS = [
  { value: 'reject_reason', label: 'Application Reject Reasons' },
  { value: 'job', label: 'Job' },
  { value: 'location', label: 'Location' },
  { value: 'status', label: 'Status' },
  { value: 'stage', label: 'Stage' },
  { value: 'job_confidential', label: 'Job Confidentiality' },
];

export const SINGLE_SELECT_CONDITIONS = ['status', 'job_confidential'];

const STATUS = [
  { value: 'active', label: 'active' },
  { value: 'rejected', label: 'rejected' },
  { value: 'hired', label: 'hired' },
  { value: 'converted', label: 'converted' },
];

const JOB_CONFIDENTIAL = [
  { value: 'true', label: 'confidential jobs' },
  { value: 'false', label: 'non-confidential jobs' },
];

const defaultConditions = [
  {
    type: { value: null, label: 'Select Condition' },
    available: [],
    mandatory: false,
    singleSelected: null,
    multiSelected: [],
    invert: { value: 'false', label: 'Includes' },
  },
];

export default function SurveyTriggerAddPage(props: PropTypes) {
  const today = moment();
  const lastSevenDays = moment().subtract(7, 'days');
  const [startDate, setStartDate] = useState(
    props.edit
      ? moment(props.survey_trigger.processing_starts_at)
      : lastSevenDays,
  );
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>(null);

  const [surveyTriggerName, setsurveyTriggerName] = useState<string>(
    props.survey_trigger.name,
  );
  const [surveyTriggerStatus, setSurveyTriggerStatus] = useState<boolean>(
    props.survey_trigger.active,
  );
  const labelledSurveyTemplates = buildLabelledOptions(props.survey_templates);
  const jobOptions = buildJobLabelledOptions(props.jobs);
  const locationOptions = buildLabelledOptions(props.locations);
  const rejectReasonOptions = buildLabelledOptions(props.reject_reasons);
  const jobStageOptions = buildLabelledOptions(props.job_stages);

  const [selectedSurveyTemplate, setSelectedSurveyTemplate] =
    useState<SelectOption>(
      props.survey_template
        ? {
            value: props.survey_template.id.toString(),
            label: props.survey_template.name,
          }
        : null,
    );
  const selectedJobStageOptions = filteredSelectedOptionsByLabel(
    jobStageOptions,
    props.survey_trigger.job_stage_name,
  );
  const selectedRejectReasons = filteredSelectedOptionsByLabel(
    rejectReasonOptions,
    props.survey_trigger.application_reject_reason,
  );
  const selectedJobs = filteredSelectedOptionsByValue(
    jobOptions,
    props.survey_trigger.job_ids,
  );
  const selectedLocations = filteredSelectedOptionsByValue(
    locationOptions,
    props.survey_trigger.location_ids,
  );
  const selectedStatus = {
    value: props.survey_trigger.application_status,
    label: props.survey_trigger.application_status,
  };
  const selectedJobConfidential =
    props.survey_trigger.job_confidential === null
      ? null
      : JOB_CONFIDENTIAL.find(
          (i) => i.value === props.survey_trigger.job_confidential.toString(),
        );

  const [selectedMaxReminders, setSelectedMaxReminders] =
    useState<SelectOption>({
      value: props.survey_trigger.max_reminders.toString(),
      label: props.survey_trigger.max_reminders.toString(),
    });
  const [selectedDelayDays, setSelectedDelayDays] = useState<SelectOption>({
    value: props.survey_trigger.delay_days.toString(),
    label: props.survey_trigger.delay_days.toString(),
  });

  const conditionsData = {
    job: {
      value: 'job',
      label: 'Job',
      options: jobOptions,
      multiSelected: selectedJobs,
      singleSelected: null,
      hasInvertCondition: true,
      invertCondition: props.survey_trigger.invert_job_condition,
      triggerCondition: props.survey_trigger.job_ids,
    },
    status: {
      value: 'status',
      label: 'Status',
      options: STATUS,
      singleSelected: selectedStatus,
      multiSelected: [],
      hasInvertCondition: false,
      invertCondition: null,
      triggerCondition: props.survey_trigger.application_status,
    },
    job_confidential: {
      value: 'job_confidential',
      label: 'Job Confidential',
      options: JOB_CONFIDENTIAL,
      singleSelected: selectedJobConfidential,
      multiSelected: [],
      hasInvertCondition: false,
      invertCondition: null,
      triggerCondition: props.survey_trigger.job_confidential,
    },
    stage: {
      value: 'stage',
      label: 'Stage',
      options: jobStageOptions,
      multiSelected: selectedJobStageOptions,
      singleSelected: null,
      hasInvertCondition: true,
      invertCondition: props.survey_trigger.invert_job_stage_condition,
      triggerCondition: props.survey_trigger.job_stage_name,
    },
    location: {
      value: 'location',
      label: 'Location',
      options: locationOptions,
      multiSelected: selectedLocations,
      singleSelected: null,
      hasInvertCondition: true,
      invertCondition: props.survey_trigger.invert_location_condition,
      triggerCondition: props.survey_trigger.location_ids,
    },
    reject_reason: {
      value: 'reject_reason',
      label: 'Application Reject Reasons',
      options: rejectReasonOptions,
      multiSelected: selectedRejectReasons,
      singleSelected: null,
      hasInvertCondition: false,
      invertCondition: null,
      triggerCondition: props.survey_trigger.application_reject_reason,
    },
  };

  const [conditions, setConditions] = useState<Condition[]>(
    !props.edit
      ? defaultConditions
      : computeUpdateConditions(conditionsData, props.survey_template),
  );

  const applicableConditions = setApplicableConditions(
    selectedSurveyTemplate,
    filterSurveyTemplate,
    CONDITIONS,
    props.survey_templates,
  );

  const surveyTemplateId = selectedSurveyTemplate
    ? parseInt(selectedSurveyTemplate.value)
    : null;

  const selectedConditions = buildSelectedConditions(
    conditions,
    surveyTriggerStatus,
    surveyTemplateId,
    surveyTriggerName,
    startDate,
    selectedMaxReminders.label,
    selectedDelayDays.label,
  );
  return (
    <>
      <SurveyTriggerConfirmationModal
        isOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        surveyTriggerName={surveyTriggerName}
        surveyTemplate={
          selectedSurveyTemplate ? selectedSurveyTemplate.label : ''
        }
        conditions={conditions}
        maxReminders={selectedMaxReminders.label}
        delayDays={selectedDelayDays.label}
        startDate={startDate}
        surveyTriggerStatus={surveyTriggerStatus}
        edit={props.edit}
        surveyTemplateId={
          selectedSurveyTemplate ? parseInt(selectedSurveyTemplate.value) : null
        }
        surveyTriggerId={props.survey_trigger.id}
        selectedConditions={selectedConditions}
        setAlertMessage={setAlertMessage}
      />
      {alertMessage && (
        <Alert
          type={AlertType.Danger}
          autoClearTimeout={5000}
          onClose={() => setAlertMessage(null)}
        >
          {alertMessage}
        </Alert>
      )}
      <SurveyTriggerPageTitleCol buttonEnabled={false} />
      <PageCard
        setModalIsOpen={setModalIsOpen}
        surveyTriggerName={surveyTriggerName}
        selectedConditions={selectedConditions}
        setAlertMessage={setAlertMessage}
        surveyTriggerId={props.survey_trigger.id}
        onSurveyTriggerNameChange={setsurveyTriggerName}
        surveyTriggerStatus={surveyTriggerStatus}
        onSurveyTriggerStatusChange={setSurveyTriggerStatus}
        selectedMaxReminders={selectedMaxReminders}
        setSelectedMaxReminders={setSelectedMaxReminders}
        selectedDelayDays={selectedDelayDays}
        setSelectedDelayDays={setSelectedDelayDays}
        startDate={startDate}
        lastSevenDays={lastSevenDays}
        setStartDate={setStartDate}
        today={today}
        cardProps={{
          edit: props.edit,
          conditions,
          selectedSurveyTemplate,
          labelledSurveyTemplates,
          setSelectedSurveyTemplate,
          setConditions,
          surveyTemplates: props.survey_templates,
          conditionsData,
          applicableConditions,
        }}
        processingStartsAt={moment(props.survey_trigger.processing_starts_at)}
      />
    </>
  );
}
