import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { JobRequisition } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { User } from '../../../../../../entities/User';
import {
  buildSteps,
  getCurrentStepOrder,
} from '../../../../../../utils/applicant_tracking/jobRequisition';
import { AlertMessage } from '../../../../../../components/AlertMessage';
import { DeclineModal } from './DeclineModal';
import { AlertObject, AlertType } from '../../../../../../components/Alert';
import { JobRequisitionService } from '../../../../../../services/v1/applicant_tracking/JobRequisitionService';

interface PropTypes {
  currentUser: User;
  jobRequisition: JobRequisition;
  setLoadingState: (loading: boolean) => void;
  setAlertMessage: (message: AlertObject) => void;
  reloadJobRequisition: () => void;
}

export function buildAndSetAlertMessage(
  setAlertMessage: (message: AlertObject) => void,
  action: string,
  jobRequisition: JobRequisition,
  error: boolean,
) {
  const actionMessage = error
    ? `'could not be ${action}`
    : `was successfully ${action}`;

  const alertMessage = {
    type: error ? AlertType.Danger : AlertType.Success,
    message: (
      <>
        The job requisition{' '}
        <span className='fw-bold'>{jobRequisition.name}</span> {actionMessage}!
      </>
    ),
  };
  setAlertMessage(alertMessage);
}

function ApproverOrDeclineOptions(props: PropTypes) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const approveJobRequisition = () => {
    props.setLoadingState(true);
    JobRequisitionService.approve(props.jobRequisition.id)
      .then(() => {
        buildAndSetAlertMessage(
          props.setAlertMessage,
          'approved',
          props.jobRequisition,
          false,
        );
      })
      .catch(() => {
        buildAndSetAlertMessage(
          props.setAlertMessage,
          'approved',
          props.jobRequisition,
          true,
        );
      })
      .finally(props.reloadJobRequisition);
  };

  return (
    <div className='d-flex align-items-center'>
      <Button
        color='danger'
        className='me-3'
        onClick={() => setModalOpen(true)}
      >
        Decline
      </Button>
      <Button color='primary' className='me-3' onClick={approveJobRequisition}>
        Approve
      </Button>
      {modalOpen && (
        <DeclineModal
          currentUser={props.currentUser}
          jobRequisition={props.jobRequisition}
          setLoadingState={props.setLoadingState}
          setAlertMessage={props.setAlertMessage}
          reloadJobRequisition={props.reloadJobRequisition}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  );
}

function NoActionAlert(props: {
  approvableStepOrders: number[];
  currentStepOrder: number;
}) {
  const actionAtLaterStage = props.approvableStepOrders.some(
    (order) => order > props.currentStepOrder,
  );
  const message = actionAtLaterStage
    ? 'is still pending further approvals.'
    : 'has already been approved at this level.';

  return (
    <AlertMessage
      className='bg-light-warning fs-5'
      icon={{ name: 'bi-info-circle' }}
      text={`No action required${
        actionAtLaterStage ? ' yet' : ''
      }. This job requisition ${message}`}
    />
  );
}

export function getApprovableStepOrders(
  jobRequisition: JobRequisition,
  user: User,
): number[] {
  return jobRequisition.approvers
    .filter((approver) => approver.user.id === user.id)
    .map((approver) => approver.order);
}

export function ApproverActions(props: PropTypes) {
  const approvalSteps = buildSteps(props.jobRequisition.approvers);
  const currentStepOrder = getCurrentStepOrder(approvalSteps);
  const approvableStepOrders = getApprovableStepOrders(
    props.jobRequisition,
    props.currentUser,
  );

  if (approvableStepOrders.includes(currentStepOrder)) {
    return <ApproverOrDeclineOptions {...props} />;
  }

  return (
    <NoActionAlert
      approvableStepOrders={approvableStepOrders}
      currentStepOrder={currentStepOrder}
    />
  );
}
