import React from 'react';
import { SentimentTopicRow } from './SentimentTopicRow';
import { Table } from 'reactstrap';
import MetricCard, {
  Body,
  Head,
  Title,
} from '../../../../../components/MetricCard';
import { AutomaticAttribute } from '../../../../../entities/candidate_experience/AutomaticAttribute';
import { EmptyState } from '../../../../../components/EmptyState';
import classNames from 'classnames';
import styles from './styles.module.scss';
interface Proptypes {
  attributes: AutomaticAttribute[];
}

export function SentimentsByTopicPanel(props: Proptypes) {
  return (
    <MetricCard className='h-100'>
      <Head>
        <Title
          value='Sentiment Breakdown by Topic'
          tooltip={
            'Analysis of the free-text responses to what could be improved to get a higher CNPS score.'
          }
        />
      </Head>
      <Body>
        {props.attributes?.length > 0 ? (
          <>
            <Table className='fs-5 text-primary table-borderless'>
              <thead className='fw-bold'>
                <tr>
                  <td
                    scope='col'
                    className={classNames(
                      'col',
                      'col-3',
                      'text-right',
                      'fs-6',
                      'ps-4',
                      styles.topicsHeader,
                    )}
                  >
                    Topic
                  </td>
                  <td
                    scope='col'
                    className={classNames(
                      'col',
                      'col-1',
                      'text-center',
                      'fs-6',
                      styles.topicsHeader,
                    )}
                  >
                    Mentions
                  </td>
                  <td
                    scope='col'
                    className={classNames(
                      'col',
                      'col-1',
                      'text-center',
                      'fs-6',
                      styles.topicsHeader,
                    )}
                  >
                    % of Total
                  </td>
                  <td
                    scope='col'
                    className={classNames(
                      'col',
                      'col-auto',
                      'text-right',
                      'fs-6',
                      's-2',
                      styles.topicsHeader,
                    )}
                  >
                    Sentiment
                  </td>
                </tr>
              </thead>
              <tbody>
                {props.attributes.map((attribute) => (
                  <SentimentTopicRow
                    key={attribute.title}
                    attribute={attribute}
                  />
                ))}
              </tbody>
            </Table>
            <div className={classNames('fs-6', 'd-flex', 'mt-4')}>
              <div>
                <span
                  className={classNames(
                    'bi',
                    'bi-square-fill',
                    'mx-2',
                    'text-success-500',
                  )}
                ></span>
                Positive
              </div>
              <div className={'mx-3'}>
                <span
                  className={classNames(
                    'bi',
                    'bi-square-fill',
                    'mx-2',
                    styles.graySquare,
                  )}
                ></span>
                Neutral
              </div>
              <div>
                <span
                  className={classNames(
                    'bi',
                    'bi-square-fill',
                    'mx-2',
                    styles.redSquare,
                  )}
                ></span>
                Negative
              </div>
            </div>
          </>
        ) : (
          <EmptyState
            title='No data available yet'
            text={'There may be no data or we may still be processing.'}
          />
        )}
      </Body>
    </MetricCard>
  );
}
