import snakecaseKeys from 'snakecase-keys';
import { PlanTemplate } from '../../../entities/applicant_tracking/PlanTemplate';
import { StagePlanTemplate } from '../../../entities/applicant_tracking/StagePlanTemplate';
import { ApiService } from '../../ApiService';
import { StageMoveDTO } from '../../../containers/PlanTemplates/SetupPlanTemplatePage/DTOs/StageMoveDTO';
import {
  JobStageJob,
  JobStageJobList,
} from '../../../entities/applicant_tracking/JobStageJob';

interface UpsertPlanTemplateProps {
  id?: number;
  name: string;
  stages: StagePlanTemplate[];
  stageMoves?: StageMoveDTO[];
}

export class PlanTemplateService {
  public static list(): Promise<PlanTemplate[]> {
    return ApiService.get<PlanTemplate[]>(
      '/api/applicant_tracking/plan_templates',
    );
  }

  public static show(id: number | string): Promise<PlanTemplate> {
    return ApiService.get<PlanTemplate>(
      `/api/applicant_tracking/plan_templates/${id}`,
    );
  }

  public static archive(id: number): Promise<void> {
    return ApiService.put<void>(
      `/applicant_tracking/plan_templates/${id}/archive`,
    );
  }

  public static unArchive(id: number): Promise<void> {
    return ApiService.put<void>(
      `/applicant_tracking/plan_templates/${id}/unarchive`,
    );
  }

  public static upsert(props: UpsertPlanTemplateProps): Promise<PlanTemplate> {
    return props.id
      ? PlanTemplateService.update(props)
      : PlanTemplateService.create(props);
  }

  public static update(props: UpsertPlanTemplateProps): Promise<PlanTemplate> {
    return ApiService.put<PlanTemplate>(
      `/api/applicant_tracking/plan_templates/${props.id}`,
      snakecaseKeys(props),
    );
  }

  public static create(props: UpsertPlanTemplateProps): Promise<PlanTemplate> {
    return ApiService.post<PlanTemplate>(
      '/api/applicant_tracking/plan_templates',
      snakecaseKeys(props),
    );
  }

  public static listJobStageJobs(props: {
    stagePlanTemplateId: number;
  }): Promise<JobStageJob[]> {
    return ApiService.get<JobStageJobList>(
      `/api/v1/applicant_tracking/job_stage_jobs`,
      snakecaseKeys(props),
    ).then((response) => response.jobStageJobs);
  }
}
