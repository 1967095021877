import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { KeyMoment } from '../../../entities/KeyMoment';
import { formatTime } from '../../../utils/timeFormat';
import styles from './styles.module.scss';
import { TruncateText } from '../../TruncateText';

interface PropTypes {
  keyMoment: KeyMoment;
  className?: string;
  onSelectTimestamp: (timestamp: number) => void;
}

export function KeyMomentBox(props: PropTypes) {
  return (
    <Row
      className={classNames(
        'bg-gray',
        'fs-6',
        'gap-1',
        'gx-1',
        'p-2',
        'align-items-center',
        'flex-nowrap',
        'justify-content-between',
        styles['key-moment'],
        props.className,
      )}
      href={`#t${props.keyMoment.startOffsetMs}`}
      onClick={(e) => {
        e.preventDefault();
        props.onSelectTimestamp(props.keyMoment.startOffsetMs);
      }}
    >
      <Col className='fw-semibold' xs='9'>
        <TruncateText ellipsize text={props.keyMoment.title} maxLines={2} />
      </Col>
      <Col
        className={classNames('fw-normal', 'flex-nowrap', 'text-gray-900')}
        xs='auto'
      >
        {formatTime(props.keyMoment.startOffsetMs)}
      </Col>
      <Col xs='auto'>
        <a className={classNames('text-info-500', styles.play)}>
          <i className={classNames('bi', 'bi-play-circle-fill')}></i>
        </a>
      </Col>
    </Row>
  );
}
