import React from 'react';
import { CandidateHeaderBadge } from '../CandidateHeaderBadge';
import { capitalize } from '../../../../utils/capitalize';
import classNames from 'classnames';
import { applicationStatusChipColor } from '../../../../utils/applicationStatusChipColor';

interface PropTypes {
  classNames: string;
  status: string;
}

export function CandidatePageHeaderStatusInfo(props: PropTypes) {
  return (
    <CandidateHeaderBadge
      text={capitalize(props.status)}
      classNames={classNames(props.classNames)}
      chipColor={applicationStatusChipColor(props.status)}
    />
  );
}
