import classNames from 'classnames';
import React from 'react';
import { Survey } from '../../../../entities/survey_engine/Survey';
import styles from './styles.module.scss';

interface PropTypes {
  survey: Survey;
}

export function GlassdoorLinkScreen(props: PropTypes) {
  return (
    <>
      <p className={classNames('mt-1 mb-4 mx-auto', styles.text)}>
        Thank you for taking the time to complete this survey. Based on your
        responses, we would love you to leave us a positive Glassdoor review.
        You can do that right here.
      </p>
      <a
        href={props.survey.company_info.glassdoor_interview_review_url}
        className='btn btn-lg btn-primary py-2 px-5'
      >
        Review in Glassdoor
      </a>
    </>
  );
}
