import React from 'react';
import { Col, Row } from 'reactstrap';
import { ActionItems } from './ActionItems';
import { CNPSPanel } from './CNPSPanel';
import { HiringTeam } from './HiringTeam';
import { JobArtefacts } from './JobArtefacts';
import { JobInfo } from './JobInfo';
import { Overview } from './Overview';
import { humanize } from '../../../utils/humanize';
import { JobDetails } from '../../../entities/JobDetails';
import { User } from '../../../entities/User';
import { HeadingRow } from '../HeadingRow';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../../../config/queryClient';

interface PropTypes {
  currentUser: User;
  jobDetails: JobDetails;
  subDomain?: string;
  standardNpsCalculation: boolean;
}

export function OverviewTab(props: PropTypes) {
  return (
    <QueryClientProvider client={queryClient}>
      <HeadingRow date={props.jobDetails.updatedAt} tabName={'Overview'} />
      <div className='my-3 mx-3'>
        <Row className='gap-3'>
          <Col sm='12' md='3'>
            <Row>
              <JobInfo
                department={props.jobDetails.department}
                employmentType={humanize(props.jobDetails.employmentType)}
                jobOpenings={props.jobDetails.jobOpenings}
                location={props.jobDetails.location}
                locationType={humanize(props.jobDetails.locationType)}
              />
            </Row>
            <Row>
              <JobArtefacts
                applicationForms={props.jobDetails.applicationForms}
                jobPosts={props.jobDetails.jobPosts}
                jobStatus={props.jobDetails.status}
                interviewProcesses={props.jobDetails.interviewProcesses}
                subDomain={props.subDomain}
                triggers={props.jobDetails.triggers}
              />
            </Row>
          </Col>
          <Col>
            <Row>
              <Overview
                jobPostIds={props.jobDetails.jobPosts.map((v) => v.id)}
                overviewAnalytics={props.jobDetails.overviewAnalytics}
              />
            </Row>
            <Row>
              <ActionItems
                jobId={props.jobDetails.id}
                currentUser={props.currentUser}
                hiringMembers={props.jobDetails.hiringMembers}
              />
            </Row>
          </Col>
          <Col sm='12' md='3'>
            <Row>
              <HiringTeam hiringMembers={props.jobDetails.hiringMembers} />
            </Row>
            <Row>
              <CNPSPanel
                benchmarkValue={props.jobDetails.cnps?.benchmark}
                jobName={props.jobDetails.name}
                nps={props.jobDetails.cnps?.nps}
                npsTrend={props.jobDetails.cnps?.npsTrend}
                standardNpsCalculation={props.standardNpsCalculation}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </QueryClientProvider>
  );
}
