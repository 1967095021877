import React from 'react';
import { AlertMessage } from '../../../../../components/AlertMessage';
import { ApprovalFlow } from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { getRedirectUriQueryParam } from '../../../../../utils/url';
import { PageHeader } from '../../../../survey_engine/SurveyTemplates/PageHeader';

interface PropTypes {
  approvalFlow: ApprovalFlow;
}

export function ViewOrEditHeader(props: PropTypes) {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <PageHeader
        title={props.approvalFlow.name}
        backUrl={
          getRedirectUriQueryParam() || '/organization_settings/approval_flows'
        }
      />
      {props.approvalFlow.isActivelyUsed && (
        <AlertMessage
          hasOverlay={false}
          icon={{ name: 'bi-info-circle' }}
          className='bg-light-warning fs-5 mb-4 ms-4'
          text='This approval flow is being used by several jobs. Changes will be immediate, but only impact unapproved steps.'
        />
      )}
    </div>
  );
}
