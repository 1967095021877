import React from 'react';
import { IconEntry } from '../../IconEntry';

interface PropTypes {
  redacted: boolean;
  email?: string;
}

export function EmailDetails(props: PropTypes) {
  if (props.email == null) {
    return null;
  }

  return (
    <IconEntry label={props.email} redacted={props.redacted} icon='bi-envelope'>
      <a className='text-info' href={`mailto:${props.email}`}>
        {props.email}
      </a>
    </IconEntry>
  );
}
