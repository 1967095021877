import React from 'react';
import styles from './styles.module.scss';
import { formatTime } from '../../../utils/timeFormat';
import { truncateTimeToSeconds } from '../../../utils/truncateTimeToSeconds';
import { SeekbarMarkerKind } from '../../../entities/SeekbarMarker';
import { clamp } from '../../../utils/clamp';
import { MenuType } from '../MenuType';
import NoteTextEmbedded from '../../NoteTextEmbedded';
import { formattedDate } from '../../DateFormater';
import classNames from 'classnames';

export interface PropTypes {
  displayName?: string;
  createdDate?: Date;
  offsetMs?: number;
  kind?: SeekbarMarkerKind;
  totalTime: number;
  body?: string;
  scoreBody?: number;
  contextBody?: string;
  icon?: string;
  noteId?: number;
  menuType?: MenuType;
  deleteAccess?: boolean;
  onSelectTimestamp: (timestamp: number) => void;
  onClickReply?: () => void;
  onClickDelete?: (noteId: number) => void;
  showNameAndOffset: boolean;
  showContextQuestion: boolean;
  marginClass?: string;
  replyBox?: boolean;
}

export function NoteBlock(props: PropTypes) {
  const originalName = props.displayName || 'Unknown';

  const getNoteTimestamp = (offsetMs: number) => {
    if (offsetMs != null) {
      return formatTime(offsetMs);
    } else {
      return formatTime(props.totalTime);
    }
  };

  const getTimestamp = (offsetMs: number) => {
    if (offsetMs != null) {
      return clamp(offsetMs, 0, props.totalTime);
    } else {
      return truncateTimeToSeconds(props.totalTime);
    }
  };

  const getTimeLink = (offsetMs: number) => {
    if (offsetMs != null) {
      return offsetMs;
    } else {
      return props.totalTime;
    }
  };

  return (
    <div
      className={classNames(
        'row g-0 w-100 align-items-center',
        props.replyBox ? styles['reply-row'] : null,
      )}
    >
      <div className='col-auto me-auto d-flex align-items-center'>
        {props.showNameAndOffset && (
          <>
            {props.offsetMs !== undefined && (
              <div className='pe-2'>
                <a
                  href={`#t${getTimeLink(props.offsetMs)}`}
                  onClick={(e) => {
                    e.preventDefault();
                    props.onSelectTimestamp(getTimestamp(props.offsetMs));
                  }}
                >
                  <div
                    className={styles.noteTimestamp}
                    data-testid='NoteBlock-timestamp'
                  >
                    {getNoteTimestamp(props.offsetMs)}
                  </div>
                </a>
              </div>
            )}
            <a
              data-toggle='tooltip'
              data-bs-placement='top'
              title={originalName}
              href={`#t${props.offsetMs || '0'}`}
              className='fw-semibold text-nowrap overflow-hidden text-overflow-ellipsis'
              data-testid='NoteBlock-name'
              onClick={(e) => {
                e.preventDefault();
                props.onSelectTimestamp(getTimestamp(props.offsetMs));
              }}
            >
              {originalName}
            </a>
            <span className='ms-2 text-dark-200 fs-6'>
              {props.createdDate && formattedDate(props.createdDate)}
            </span>
          </>
        )}
        {props.kind === 'interview_secret' && (
          <i className={`bi bi-file-lock2 text-pin ms-2`} />
        )}
      </div>
      <div className='col-auto text-end'>
        {props.kind !== 'interview_secret' &&
          props.menuType === 'reply' &&
          (props.onClickReply || props.deleteAccess) && (
            <div className='dropdown'>
              <a
                href='#note-opts'
                type='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <i className='bi bi-three-dots text-blue' />
              </a>
              <ul className='dropdown-menu' style={{ fontSize: 'inherit' }}>
                {props.onClickReply && (
                  <li>
                    <a
                      className={`dropdown-item ${styles.replyBtn}`}
                      href='#reply'
                      onClick={() => props.onClickReply()}
                    >
                      <i className='bi bi-chat text-blue' />
                      <span className='ms-2 text-blue'>Comment</span>
                    </a>
                  </li>
                )}
                <li>
                  {props.deleteAccess && (
                    <a
                      className={`dropdown-item ${styles.deleteBtn}`}
                      href='#delete'
                      onClick={() => props.onClickDelete(props.noteId)}
                    >
                      <i className='bi bi-trash text-blue' />
                      <span className='ms-2 text-blue'>Delete</span>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          )}
      </div>
      <div
        className={classNames(
          'col-12',
          props.showContextQuestion ? 'mt-2' : null,
        )}
        data-testid='NoteBlock-text'
      >
        <a
          href={`#t${props.offsetMs || '0'}`}
          className='fs-5'
          onClick={(e) => {
            e.preventDefault();
            props.onSelectTimestamp(getTimestamp(props.offsetMs));
          }}
        >
          <i className={`${props.icon} ${props.marginClass} fs-4`} />
          {props.body != null && (
            <NoteTextEmbedded
              contextText={props.showContextQuestion ? props.contextBody : null}
              text={props.body}
              score={props.scoreBody}
              kind={props.kind}
            />
          )}
        </a>
      </div>
    </div>
  );
}
