import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiringManagerEmployeeEvalSurvey } from '../../../../entities/survey_engine/HiringManagerEmployeeEvalSurvey';
import { Survey } from '../../../../entities/survey_engine/Survey';
import { SurveyQuestionFieldType } from '../../../../entities/survey_engine/SurveyQuestionFieldType';
import { EmployeeService } from '../../../../services/quality_of_hire/EmployeeService';
import { buildQuestion } from '../../../../utils/survey_engine/buildQuestion';
import { CustomStepScaffold } from '../../../survey_engine/SurveyPage/CustomStepScaffold';
import { StyledSurveyQuestionTitle } from '../../../survey_engine/SurveyPage/SurveyScreen/StyledSurveyQuestionTitle';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';

interface PropTypes {
  survey: Survey;
  step: number;
  totalSteps: number;
  onUpdateSurvey: (survey: Survey) => void;
  onFinishSurvey: () => void;
}

export default function EmployeeDataStep2(props: PropTypes) {
  const surveyDetails = props.survey
    .surveyDetails as HiringManagerEmployeeEvalSurvey;
  const navigate = useNavigate();

  const [email, setEmail] = useState(surveyDetails.employee?.email);

  const question = buildQuestion(
    `Can you insert ${surveyDetails.employee.name}'s ${props.survey.company_info.name} email?`,
    SurveyQuestionFieldType.Text,
  );

  async function handleNextClick() {
    const employee = await EmployeeService.update(surveyDetails.id, email);
    props.onUpdateSurvey({
      ...props.survey,
      surveyDetails: {
        ...props.survey.surveyDetails,
        employee,
      },
    });
    navigate(`../step/3`);
  }

  useEffect(() => {
    const employee = surveyDetails.employee;
    if (Object.values(HrisProvider).includes(employee.provider)) {
      navigate(`../start`);
    }
  }, []);

  return (
    <CustomStepScaffold
      survey={props.survey}
      step={props.step}
      totalSteps={props.totalSteps}
      nextDisabled={email == null || email == ''}
      nextText='Next'
      title='Employee data'
      onNextClick={handleNextClick}
      showPrevious={true}
    >
      <StyledSurveyQuestionTitle
        question={question}
        companyInfo={props.survey.company_info}
      />
      <label className='form-label'>Email</label>
      <input
        type='email'
        className='form-control'
        style={{ maxWidth: '280px' }}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
    </CustomStepScaffold>
  );
}
