import React from 'react';

export interface PropTypes {
  selectedValue: string;
  children: React.ReactNode;
  childrenClassName?: string;
  disabled?: boolean;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  resetValue?: () => void;
}

export function CheckboxPillGroup(props: PropTypes) {
  return (
    <div>
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            onChange: props.onChange,
            resetValue: props.resetValue,
            selectedValue: props.selectedValue,
            className: props.childrenClassName,
            disabled: props.disabled,
          } as unknown);
        }
      })}
    </div>
  );
}
