import { useState } from 'react';
import { UseFormResetField } from 'react-hook-form';
import { FormValues } from './UserInvitationPage';

export function usePasswordErrorHandling(
  resetField: UseFormResetField<FormValues>,
) {
  const [alertActive, setAlertActive] = useState<boolean>(false);
  const [hasPasswordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const handlePasswordError = (
    message: string,
    currentPasswordInvalid?: boolean,
  ) => {
    setAlertActive(true);
    setErrorMessage(message);
    if (currentPasswordInvalid) {
      resetField('currentPassword');
      setIsPasswordInvalid(true);
    } else {
      resetField('password');
      resetField('passwordConfirmation');
      setPasswordValidationFailed(true);
    }
  };

  return {
    alertActive,
    setAlertActive,
    hasPasswordValidationFailed,
    setPasswordValidationFailed,
    errorMessage,
    handlePasswordError,
    isPasswordInvalid,
    setIsPasswordInvalid,
  };
}
