import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SetupJobPageRoutes } from '../SetupJobPageRoutes';
import { JobService } from '../../../../services/applicant_tracking/JobService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { EditJobDto } from '../../../../services/applicant_tracking/JobService/EditJobDto';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { HrisProvider } from '../../../../enums/IntegrationProvider/HrisProvider';

export interface PropTypes {
  hrisProvider?: HrisProvider;
  jobRequisitionMandatory: boolean;
  organicPostSkillsEnabled: boolean;
  selfSchedulingAutomationEnabled: boolean;
  generateJobDescriptionEnabled: boolean;
}

export function EditJobPage(props: PropTypes) {
  const jobId = useParams().id;
  const [jobDTO, setJobDTO] = useState<EditJobDto>(null);

  useEffect(() => {
    if (jobId) {
      JobService.get(jobId).then((dto: EditJobDto) => {
        if (dto.jobDetails.status === 'archived') {
          window.location.href = getRedirectUriQueryParam() ?? '/jobs';
        }

        setJobDTO(dto);
      });
    }
  }, [jobId]);

  return (
    <>
      {jobDTO != null ? (
        <SetupJobPageRoutes
          jobDetails={jobDTO.jobDetails}
          jobFormDTO={jobDTO.jobForm}
          hrisProvider={props.hrisProvider}
          jobRequisitionEnabled={jobDTO.jobForm.jobRequisitionId != null}
          updateJobRequisitionEnabled={false}
          jobRequisitionMandatory={props.jobRequisitionMandatory}
          organicPostSkillsEnabled={props.organicPostSkillsEnabled}
          selfSchedulingAutomationEnabled={
            props.selfSchedulingAutomationEnabled
          }
          generateJobDescriptionEnabled={props.generateJobDescriptionEnabled}
        />
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}
