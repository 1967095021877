import snakecaseKeys from 'snakecase-keys';
import { ApplicationList } from '../../../entities/v1/ApplicationList';
import { Application } from '../../../entities/v1/applicant_tracking/Application';
import { ApiService } from '../../ApiService';
import { ApplicationListWithoutCount } from '../../../entities/v1/ApplicationListWithoutCount';
import { ListCount } from '../../../entities/v1/ListCount';

interface ListParams {
  name?: string;
  jobId?: string;
  jobStageName?: string;
  status?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
  sort_column?: string;
  sort_direction?: string;
}

export class ApplicationService {
  public static show(
    id: number,
    expand: string[] = null,
  ): Promise<Application> {
    return ApiService.get<Application>(`/api/v1/applications/${id}`, {
      expand,
    });
  }

  public static list(params: ListParams): Promise<ApplicationList> {
    return ApiService.get<ApplicationList>(
      '/api/v1/applications',
      snakecaseKeys(params),
    );
  }

  public static listWithoutCount(
    params: ListParams,
  ): Promise<ApplicationListWithoutCount> {
    return ApiService.get<ApplicationList>(
      '/api/v1/applications',
      snakecaseKeys({ ...params, withoutCount: true }),
    );
  }

  public static count(params: ListParams): Promise<ListCount> {
    return ApiService.get<ApplicationList>(
      '/api/v1/applications',
      snakecaseKeys({ ...params, countOnly: true }),
    );
  }
}
