import React, { useState } from 'react';
import classNames from 'classnames';
import { IconButton } from '../../../../components/IconButton';
import { EvaluationService } from '../../../../services/training/EvaluationService';
import {
  Session,
  SessionEvaluationStatus,
} from '../../../../entities/training/Session';

interface PropTypes {
  className?: string;
  session: Session;
  hasBackground: boolean;
}

function validEvaluationStatus(
  sessionEvaluationStatus: SessionEvaluationStatus,
): boolean {
  return [
    SessionEvaluationStatus.NoEvaluation,
    SessionEvaluationStatus.Pending,
    SessionEvaluationStatus.Submitted,
  ].includes(sessionEvaluationStatus);
}

function shouldHide(session: Session): boolean {
  // It not visible if it is draft or there are no evaluations associated to the session.
  return (
    session.draft == true || !validEvaluationStatus(session.evaluation.status)
  );
}

function displayMessage(session: Session): string {
  switch (session.evaluation.status) {
    case SessionEvaluationStatus.NoEvaluation: {
      return 'Are you ready to test your knowledge? Start evaluation to complete this session.';
    }
    case SessionEvaluationStatus.Pending: {
      return 'Pick up where you left off. Resume evaluation to complete this session.';
    }
    case SessionEvaluationStatus.Submitted: {
      return 'Improve your test accuracy to complete this session.';
    }
    default: {
      return '';
    }
  }
}

function buttonText(session: Session): string {
  switch (session.evaluation.status) {
    case SessionEvaluationStatus.NoEvaluation: {
      return 'Start Evaluation';
    }
    case SessionEvaluationStatus.Pending: {
      return 'Resume Evaluation';
    }
    case SessionEvaluationStatus.Submitted: {
      return 'Retry Evaluation';
    }
    default: {
      return '';
    }
  }
}

export function StartEvaluationPanel(props: PropTypes) {
  const [session, setSession] = useState(props.session);

  async function handleOnCreateEvaluation() {
    const sessionResult = await EvaluationService.create(session.id);
    setSession(sessionResult);

    location.href = `/training/sessions/${sessionResult.id}/evaluations/${sessionResult.evaluation.id}`;
  }

  if (shouldHide(props.session)) {
    return null;
  }

  return (
    <div
      data-testid='training-session-start-evaluation-panel'
      className={classNames(
        props.className,
        { 'bg-gray': props.hasBackground },
        'rounded-4',
        'p-4',
        'my-4',
        'text-center',
      )}
    >
      <p className='fw-semibold fs-5'>{displayMessage(props.session)}</p>
      <IconButton
        iconName='bi-journal-text'
        color='primary'
        buttonText={buttonText(props.session)}
        onClick={handleOnCreateEvaluation}
      />
    </div>
  );
}
