import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import { VerticalDivider } from '../VerticalDivider';
import { SpanTooltip } from '../SpanTooltip';

interface PropTypes {
  title: ReactNode;
  firstItems: SetupHeaderButtonType[];
  intermediateItems: SetupHeaderButtonType[];
  lastItem?: SetupHeaderButtonType;
  value?: string;
  classNames?: string;
  titleClassNames?: string;
}

export interface SetupHeaderButtonType {
  title: React.ReactNode;
  action?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type: 'Cancel' | 'Save' | 'OpenConfirmationModal' | 'Navigate';
  value?:
    | 'previous'
    | 'next'
    | 'save'
    | 'save_as_draft'
    | 'save_and_continue'
    | 'save_and_submit';
  tooltip?: string;
}

function FirstItemHeader(props: { items?: SetupHeaderButtonType[] }) {
  return (
    <>
      {props.items?.map((item, index) => (
        <Button
          key={index}
          color='secondary'
          type={item.type === 'Save' ? 'submit' : 'button'}
          onClick={item.action || null}
          disabled={item.disabled}
          value={item.value}
        >
          {item.title}
        </Button>
      ))}
    </>
  );
}

function LastJobItemHeader(props: { item?: SetupHeaderButtonType }) {
  if (props.item == null) {
    return null;
  }

  return (
    <>
      <Button
        color='success'
        type='submit'
        onClick={props.item.action || null}
        disabled={props.item.disabled}
        value={props.item.value}
      >
        {props.item.title}
      </Button>
    </>
  );
}

export function SetupPageHeader(props: PropTypes) {
  return (
    <div className={classNames('d-flex', props.classNames)}>
      <h2 className={classNames('me-auto', props.titleClassNames)}>
        {props.title}
      </h2>
      <div className='d-flex gap-3'>
        <FirstItemHeader items={props.firstItems} />
        {props.firstItems?.length != 0 && <VerticalDivider />}
        {props.intermediateItems.map(
          (item: SetupHeaderButtonType, idx: number) => {
            return (
              <div key={idx}>
                {item.tooltip ? (
                  <SpanTooltip
                    tooltipText={item.tooltip || ''}
                    text={<NavButton item={item} />}
                  />
                ) : (
                  <NavButton item={item} />
                )}
              </div>
            );
          },
        )}
        <LastJobItemHeader item={props.lastItem} />
      </div>
    </div>
  );
}

function NavButton(props: { item: SetupHeaderButtonType }) {
  return (
    <Button
      color={props.item.type === 'Cancel' ? 'danger' : 'primary'}
      type={props.item.type === 'Save' ? 'submit' : 'button'}
      // TODO: SL-2891 if the action is cancel, it should show an cancel modal
      onClick={props.item.action || null}
      disabled={props.item.disabled}
      value={props.item.value}
    >
      {props.item.title}
    </Button>
  );
}
