import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  text: string;
  classNames?: string;
}

export function CandidatePageSubHeader(props: PropTypes) {
  return (
    <h2
      className={classNames(
        props.classNames,
        styles['sub-header'],
        'mb-0',
        'align-content-center',
      )}
    >
      {props.text}
    </h2>
  );
}
