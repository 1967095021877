import React from 'react';
import { CardTitle, Col, Row } from 'reactstrap';
import styles from './styles.module.scss';
import classNames from 'classnames';
import MetricCard from '../../../../../components/MetricCard';

interface PropTypes {
  sentimentQuantity: number;
  sentimentPercentage: string;
  sentimentTitle: string;
  sentimentColor: string;
  sentimentEmoji: string;
}

export function SentimentCounterPanel(props: PropTypes) {
  return (
    <MetricCard className='h-100'>
      <div className='d-flex align-items-center mb-2'>
        <CardTitle tag='h2' className='mb-0'>
          {props.sentimentTitle}
        </CardTitle>
      </div>
      <div>
        <Row className={classNames('align-items-center', 'gx-2')}>
          <Col lg={2} className={classNames('text-left')}>
            <span
              style={{ color: props.sentimentColor }}
              className={classNames(props.sentimentEmoji, 'fs-1')}
            ></span>
          </Col>
          <Col lg={10} className={classNames(styles['count-number'])}>
            {props.sentimentQuantity}
          </Col>
        </Row>
        <Row className={classNames('align-items-center', 'gx-2')}>
          <Col
            lg={2}
            style={{ color: props.sentimentColor }}
            className={classNames(
              'text-left',
              'fs-5',
              'ps-1',
              'fw-bold',
              styles.topicsHeader,
            )}
          >
            {`${props.sentimentPercentage}%`}
          </Col>
          <Col lg={10} className={classNames('align-items-left')}>
            <div className={classNames(styles['progress-bar-background'])}>
              <div
                className={classNames(
                  'h-100',
                  styles['progress-bar-background'],
                )}
                style={{
                  backgroundColor: props.sentimentColor,
                  width: `${props.sentimentPercentage}%`,
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </MetricCard>
  );
}
