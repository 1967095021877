import React from 'react';
import { FeedbackFilters } from '.';
import { MultiSelectAsyncFilter } from '../../../../components/MultiSelectAsyncFilter';
import { Application } from '../../../../entities/Application';
import { mapResultToSelectOption } from '../../../../utils/mapResultToSelectOption';
import { JobService } from '../../../../services/v1/applicant_tracking/JobService';

interface PropTypes {
  application: Application;
  isLoading: boolean;
  filters: FeedbackFilters;
  setFilters: (filters: FeedbackFilters) => void;
}

export function JobStagesFilter(props: PropTypes) {
  const loadJobStages = (name: string, page: number) =>
    mapResultToSelectOption(
      JobService.listJobStages({ id: props.application.job.id, name, page }),
      (filter) => filter.jobStages,
      false,
    );

  return (
    <MultiSelectAsyncFilter
      isMulti
      label='Stage'
      loadOptions={loadJobStages}
      onChange={(jobStages) =>
        props.setFilters({ ...props.filters, jobStages })
      }
      className='mt-4 ps-0 fs-5 fw-semibold'
      disabled={props.isLoading}
      selected={props.filters?.jobStages || []}
    />
  );
}
