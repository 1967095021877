import React from 'react';

interface PropTypes {
  classNames?: string;
  children: React.ReactNode;
}

export function TableCellLayout(props: PropTypes) {
  return <td className={props.classNames}>{props.children}</td>;
}
