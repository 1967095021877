import React from 'react';
import classNames from 'classnames';
import { Icon, PropTypes as IconPropTypes } from '../../Icon';
import styles from './styles.module.scss';

interface PropTypes {
  text?: string;
  icon?: IconPropTypes;
  className?: string;
  hasChevron?: boolean;
}

export const DropdownButton = (props: PropTypes) => {
  return (
    <a
      data-bs-toggle='dropdown'
      className={classNames(styles.link, props.className, 'rounded-1 p-2')}
      href={'#'}
      role={'button'}
      aria-expanded='false'
      aria-disabled={true}
    >
      {props.icon != null && (
        <Icon
          {...props.icon}
          className={classNames(
            props.icon.className,
            {
              'me-2': props.text != null,
            },
            'align-middle',
          )}
        />
      )}
      {props.text != null && <span className='fs-5'>{props.text}</span>}
      {props.hasChevron && <Icon name={'bi-chevron-down'} className={'ms-1'} />}
    </a>
  );
};
