import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TreeView } from '@lexical/react/LexicalTreeView';
import styles from './styles.module.scss';

export function TreeViewPlugin() {
  const [editor] = useLexicalComposerContext();

  return (
    <TreeView
      viewClassName={styles['tree-view-output']}
      treeTypeButtonClassName={'debug-treetype-button'}
      timeTravelPanelClassName={'debug-timetravel-panel'}
      timeTravelButtonClassName={'debug-timetravel-button'}
      timeTravelPanelSliderClassName={'debug-timetravel-panel-slider'}
      timeTravelPanelButtonClassName={'debug-timetravel-panel-button'}
      editor={editor}
    />
  );
}
