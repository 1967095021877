import React, { useEffect, useState } from 'react';
import { CloseableModal } from '../../../../../../components/CloseableModal';
import { JobRequisition } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import {
  buildSteps,
  getCurrentStepOrder,
} from '../../../../../../utils/applicant_tracking/jobRequisition';
import { getOrdinal } from '../../../../../../utils/number';
import { Button } from 'reactstrap';
import { JobRequisitionService } from '../../../../../../services/v1/applicant_tracking/JobRequisitionService';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';

interface PropTypes {
  jobRequisitionId: number;
  jobRequisition?: JobRequisition;
  onClose: (succeeded?: boolean) => void;
}

function ApproverNames(props: PropTypes & { currentStepOrder: number }) {
  const currentApproverNames = props.jobRequisition.approvers
    .filter((approver) => approver.order === props.currentStepOrder)
    .map((approver, index) => (
      <span key={index} className='fw-semibold'>
        {approver.user.name}
      </span>
    ));

  return (
    <>
      {currentApproverNames.reduce((prev, curr, index) => {
        return index === 0 ? [curr] : [...prev, ' or ', curr];
      }, [] as React.ReactNode[])}
    </>
  );
}

function ModalBody(props: PropTypes & { isLoading: boolean }) {
  if (props.isLoading) {
    return <LoadingSpinner />;
  }

  const currentStepOrder = getCurrentStepOrder(
    buildSteps(props.jobRequisition.approvers),
  );

  const sendReminder = () => {
    JobRequisitionService.sendReminder(props.jobRequisition.id)
      .then(() => props.onClose(true))
      .catch(() => props.onClose(false));
  };

  return (
    <div className='fs-5'>
      Your job requisition is awaiting {getOrdinal(currentStepOrder)} approval
      from either{' '}
      <ApproverNames {...props} currentStepOrder={currentStepOrder} />.{' '}
      <br></br>
      <br></br>Are you sure you want to send a reminder to them?
      <div className='d-flex justify-content-end mt-4'>
        <Button
          color='secondary'
          className='me-3'
          onClick={() => props.onClose()}
        >
          Cancel
        </Button>
        <Button color='primary' onClick={sendReminder}>
          Send
        </Button>
      </div>
    </div>
  );
}

export function SendReminderModal(props: PropTypes) {
  const [jobRequisition, setJobRequisition] = useState<JobRequisition | null>(
    props.jobRequisition,
  );
  const [isLoading, setIsLoading] = useState(!jobRequisition);

  useEffect(() => {
    if (jobRequisition) {
      return setIsLoading(false);
    }

    JobRequisitionService.show({
      id: props.jobRequisitionId.toString(),
      expand: ['approvers', 'approvers.user'],
    })
      .then(setJobRequisition)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={() => props.onClose()}
      isOpen={true}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle='Send Reminder'
      bodyChildren={
        <ModalBody
          {...props}
          jobRequisition={jobRequisition}
          isLoading={isLoading}
        />
      }
    />
  );
}
