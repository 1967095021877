import React from 'react';
import { ColumnConfig } from '../Field';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';
import { InfoTooltip } from '../../../../components/InfoTooltip';

interface PropTypes {
  singleTypeSections: boolean;
  readOnly?: boolean;
  column: ColumnConfig;
}

export function TypeHeader(props: PropTypes) {
  return (
    <div className={props.column.className}>
      <div className='d-flex gap-2'>
        <FormFieldHeader
          classNames='fw-semibold'
          fieldName='Fields'
          isRequired={!props.readOnly}
        />
        {!props.readOnly && props.singleTypeSections && (
          <InfoTooltip>
            Changing the field type of the first section will automatically
            update all fields.
          </InfoTooltip>
        )}
      </div>
    </div>
  );
}
