import { Role } from '../../entities/Role';
import { SurveyQuestionFieldOption } from '../../entities/survey_engine/SurveyQuestionFieldOption';

interface Optionable {
  id: number | Role;
  name: string;
}

export function buildSurveyQuestionFieldOption(
  optionable: Optionable,
): SurveyQuestionFieldOption {
  return {
    id: optionable.id,
    name: optionable.name,
  };
}
