import React from 'react';
import { SurveyQuestion } from '../../../../../../../../entities/survey_engine/SurveyQuestion';
import { placeholder } from '../../../utils/placeholder';
import { InputFormField } from '../../../../../../../../components/InputFormField';
import { FormState, UseFormRegister } from 'react-hook-form';
import { camelCase } from 'lodash';
import { SurveyQuestionFieldType } from '../../../../../../../../entities/survey_engine/SurveyQuestionFieldType';
import classNames from 'classnames';

interface PropTypes {
  question: SurveyQuestion;
  formState: FormState<any>;
  formRegister: UseFormRegister<any>;
  disabled?: boolean;
}

export function DefaultQuestionInput(props: PropTypes) {
  const type = getInputType(props.question.type);
  const error =
    props.formState.errors[camelCase(props.question.tag)]?.message?.toString();

  return (
    <InputFormField
      type={type}
      registerReturn={props.formRegister(camelCase(props.question.tag), {
        required: props.question.mandatory,
        pattern: getPattern(props.question.type),
      })}
      fieldName={props.question.title}
      placeholder={placeholder(props.question)}
      isRequired={props.question.mandatory}
      classNames={classNames('form-control fs-5', error && 'is-invalid')}
      hideHeader={true}
      disabled={props.disabled ?? false}
      error={error}
      errorClassNames='ps-0'
    />
  );
}

function getInputType(fieldType: SurveyQuestionFieldType) {
  switch (fieldType) {
    case SurveyQuestionFieldType.Url:
      return 'url';
    case SurveyQuestionFieldType.Email:
      return 'email';
    default:
      return 'text';
  }
}

function getPattern(fieldType: SurveyQuestionFieldType) {
  switch (fieldType) {
    case SurveyQuestionFieldType.Email:
      return {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Invalid email address',
      };
    default:
      return undefined;
  }
}
