import classNames from 'classnames';
import React from 'react';

export function RoundedIconAvatar(props: { icon: string }) {
  return (
    <span
      className={classNames('d-flex align-items-center me-2')}
      style={{
        width: '24px',
        minWidth: '24px',
        maxWidth: '24px',
      }}
    >
      <svg className='avatar' viewBox='0 0 100 100'>
        <rect x='0' y='0' width='100' height='100' fill='#344965' />
        <image href={props.icon} x='25%' y='25%' width='50' height='50' />
      </svg>
    </span>
  );
}
