import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  title: string;
  label: string;
  classNames?: string;
  showFieldLabels?: boolean;
}

export function ScorecardLabel(props: PropTypes) {
  return (
    <div
      className={classNames(props.classNames, 'd-flex justify-content-between')}
    >
      <div className='fw-semibold fs-3'>{props.title}</div>
      {props.showFieldLabels && (
        <span className='fs-5 text-dark-500 fw-normal'>{props.label}</span>
      )}
    </div>
  );
}
