import React from 'react';
import { CloseableModal } from '../../../../components/CloseableModal';
import { ModalBody } from './ModalBody';
import { Application } from '../../../../entities/Application';
import { SuccessfulModalBody } from './SuccessfulModalBody';

interface PropTypes {
  application: Application;
  isOpen: boolean;
  showConfirmationModal: boolean;
  filterRejectedBy?: string;
  preSelectedRejectReasonId?: number;
  onReject: (succeeded: boolean, rejectedReson?: string) => void;
  onClose: (sendEmail: boolean) => void;
  setPreSelectedRejectReasonId?: (preSelectedRejectReasonId: number) => void;
}

export function RejectCandidateStageModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={() => props.onClose(false)}
      isOpen={props.isOpen}
      size='md'
      keyboard
      backdrop
      headerTitle='Reject Candidate'
      bodyChildren={
        props.showConfirmationModal ? (
          <SuccessfulModalBody
            application={props.application}
            onClose={props.onClose}
          />
        ) : (
          <ModalBody
            applicationIds={[props.application.id]}
            description={
              <>
                Reject <b>{props.application.candidate.name}</b> for the{' '}
                <b>{props.application.job.name}</b> job?
              </>
            }
            filterRejectedBy={props.filterRejectedBy}
            preSelectedRejectReasonId={props.preSelectedRejectReasonId}
            setPreSelectedRejectReasonId={props.setPreSelectedRejectReasonId}
            onClose={props.onReject}
          />
        )
      }
    />
  );
}
