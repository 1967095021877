import React from 'react';
import { PageTitle } from '../../../../components/PageTitle';
import { IconButton } from '../../../../components/IconButton';

interface PropTypes {
  buttonEnabled: boolean;
}

export function SurveyTriggerPageTitleCol(props: PropTypes) {
  return (
    <div className='g-3 mb-4 align-items-center justify-content-between'>
      <div className='col-auto'>
        <div className='g-2 justify-content-start justify-content-md-end align-items-center'>
          <div className='col-auto d-flex justify-content-between align-items-start'>
            <PageTitle text={'Survey Triggers'} />
            {props.buttonEnabled && (
              <IconButton
                color='primary'
                buttonText='Add Trigger'
                iconName='bi-plus-circle'
                onClick={() =>
                  (window.location.href =
                    '/organization_settings/survey_triggers/new')
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
