import React from 'react';
import { Col, Row } from 'reactstrap';

interface PropTypes {
  descriptionText: React.ReactNode;
  saveButton?: React.ReactNode;
}

export function Header(props: PropTypes) {
  return (
    <div className='app-card-header border-bottom-0 mb-3'>
      <div className='row align-items-center gx-3'>
        <Row className={'w-100 ps-3'}>
          <Col lg={6}>
            <h2 className='card-title'>Your interview availability</h2>
          </Col>
          <Col lg={6} className='text-end pe-2'>
            {props.saveButton}
          </Col>
        </Row>
        <Row className={'ps-3 text-dark-200'}>{props.descriptionText}</Row>
      </div>
    </div>
  );
}
