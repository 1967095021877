import { ApiService } from '../../ApiService';
import { Session } from '../../../entities/training/Session';

export default class SessionService {
  public static complete(id: number): Promise<Session> {
    return ApiService.put<Session>(`/api/training/sessions/${id}/complete`);
  }

  public static assign(id: number, user_ids: number[]): Promise<Session> {
    return ApiService.put<Session>(`/api/training/sessions/${id}/assign`, {
      user_ids: user_ids,
    });
  }

  public static feedback(
    id: number,
    feedbackText: string,
    rating: number,
  ): Promise<Session> {
    return ApiService.put<Session>(`/api/training/sessions/${id}/feedback`, {
      comment: feedbackText,
      rating: rating,
    });
  }
}
