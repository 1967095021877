import React, { useEffect, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { NoteInput } from '../../components/NoteInput';
import { queryClient } from '../../config/queryClient';

interface PropTypes {
  name: string;
  autofocus?: boolean;
  placeholder?: string;
  singleLine?: boolean;
}

export default function NoteInputEmbedded(props: PropTypes) {
  const [value, setValue] = useState<string>('');
  const [inputRef, setInputRef] = useState<HTMLInputElement>();

  useEffect(() => {
    if (inputRef === undefined) return;
    inputRef.form.onreset = () => setValue('');
  }, [inputRef]);

  return (
    <QueryClientProvider client={queryClient}>
      <input
        type='hidden'
        value={value}
        name={props.name}
        ref={(ref) => setInputRef(ref)}
      />
      <NoteInput
        value={value}
        placeholder={props.placeholder}
        autofocus={props.autofocus}
        singleLine={props.singleLine}
        onChange={setValue}
      />
    </QueryClientProvider>
  );
}
