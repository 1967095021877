import React from 'react';
import { Control } from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';
import { CheckboxPillGroupFormHook } from '../../../../../../../components/CheckboxPillGroupFormHook';
import { AVAILABLE_EMPLOYMENT_TYPES } from '../../../../../../../utils/applicant_tracking/jobDetails';

interface PropTypes {
  formControl: Control<JobRequisitionDto>;
  formResetField: (controllerName: string) => void;
}

export function EmploymentType(props: PropTypes) {
  const required = false;

  return (
    <Col xs={3}>
      <FormFieldHeader
        fieldName='Employment type'
        isRequired={required}
        classNames='fw-semibold'
      />
      <CheckboxPillGroupFormHook<JobRequisitionDto>
        formHookContext={{
          controllerName: 'employmentType',
          formControl: props.formControl,
          required: required,
        }}
        resetField={props.formResetField}
        childrenProps={AVAILABLE_EMPLOYMENT_TYPES}
      />
    </Col>
  );
}
