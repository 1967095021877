import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export function BouncingDotsLoader() {
  return (
    <div className={classNames(styles['bouncing-loader'])}>
      <div className={classNames(styles['bouncing-loader-item'])} />
      <div
        className={classNames(
          styles['bouncing-loader-item'],
          styles['bouncing-loader-2'],
        )}
      />
      <div
        className={classNames(
          styles['bouncing-loader-item'],
          styles['bouncing-loader-3'],
        )}
      />
    </div>
  );
}
