import React from 'react';
import { StartPanelFields } from '../../../entities/survey_engine/StartPanelFields';
import { Survey } from '../../../entities/survey_engine/Survey';
import { SurveyRoute } from './SurveyRoute';
import { SurveyStartScreen } from './SurveyStartScreen';

interface PropTypes {
  survey: Survey;
  onStartSurvey: () => void;
  getStartScreenFields?: (SurveyTypes) => StartPanelFields;
}

export function StartRoute(props: PropTypes) {
  return (
    <SurveyRoute survey={props.survey} status='pending' mismatchPath='..'>
      <SurveyStartScreen
        companyInfo={props.survey.company_info}
        surveyStartBody={props.survey.survey_start_body}
        surveyDetails={props.survey.surveyDetails}
        template={props.survey.survey_template}
        onStartSurvey={props.onStartSurvey}
        getStartScreenFields={props.getStartScreenFields}
      />
    </SurveyRoute>
  );
}
