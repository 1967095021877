import React, { useCallback } from 'react';
import { Select, SelectOption } from '../../../../../../components/Select';
import classNames from 'classnames';
import { Size } from '../../../../../../utils/types/Size';

interface PropTypes {
  durationMinutes: number;
  candidateView?: boolean;
  setDurationMinutes: (duration: number) => void;
  className?: string;
  selectSize?: Size;
  readOnly?: boolean;
  titleClassName?: string;
}

const AVAILABLE_INTERVIEW_DURATION_IN_MINUTES = [15, 30, 45, 60, 90];
export const DEFAULT_DURATION_IN_MINUTES = 30;

export function InterviewDuration(props: PropTypes) {
  const availableDurationInMinutes =
    AVAILABLE_INTERVIEW_DURATION_IN_MINUTES.map((duration) => {
      return {
        value: duration.toString(),
        label: `${duration} mins`,
        selected: duration === props.durationMinutes,
      };
    });
  const selectedDurationInMinutes = availableDurationInMinutes.find(
    (option) => option.selected,
  );

  const onDurationSelection = useCallback(
    (option: SelectOption) => {
      props.setDurationMinutes(+option.value);
    },
    [props.durationMinutes],
  );

  return (
    <div
      data-testid='interview_duration'
      className={classNames(props.className)}
    >
      <p
        className={classNames(
          props.titleClassName || 'text-dark-500',
          'fs-5 mb-2',
        )}
      >
        Interview Duration
      </p>
      {props.candidateView ? (
        <p className={'text-dark-200 fs-6 mt-1'}>
          {props.durationMinutes} mins
        </p>
      ) : (
        <Select
          options={availableDurationInMinutes}
          placeholder='Select duration'
          onChange={onDurationSelection}
          selected={selectedDurationInMinutes}
          menuPlacement='top'
          size={props.selectSize || 'sm'}
          isClearable={false}
          disabled={!!props.readOnly}
        />
      )}
    </div>
  );
}
