import React, { useState } from 'react';
import { PageTitle } from '../../../../components/PageTitle';
import { BackArrow } from '../../../../components/BackArrow';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { HiringMemberSettingTablePanel } from './HiringMemberSettingTablePanel';
import { HiringMemberSettingsOrganizationPanel } from './HiringMemberSettingsOrganizationPanel';
import { HiringMemberSettingsType } from '../../../../entities/HiringMemberSettingsType';
import { UseMutationResult, useQuery } from 'react-query';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { PanelFooter } from '../../../../components/PanelFooter';
import { HiringMemberService } from '../../../../services/v1/HiringMemberService';
import { PanelHeader } from '../../../../components/PanelHeader';
import { OverallStatus } from '../../../../entities/v1/OverallStatus';

export interface PropTypes {
  recordingSettingPath: string;
  title: string;
  activateText: string;
  hiringMemberSettingsType: HiringMemberSettingsType;
  organizationAutoRecordingEnabled: boolean;
  organizationOptoutEnabled?: boolean;
  settingsQueryKey: string;
  queryFn: () => Promise<OverallStatus>;
  mutation: UseMutationResult;
  organizationSettingEnabled: boolean;
}

export function HiringMemberSettings(props: PropTypes) {
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');

  const { isLoading, data: hiringMembersData } = useQuery({
    queryKey: ['hiringMembers', page, name, props.mutation],
    queryFn: () => HiringMemberService.list({ page: page, name: name }),
    retry: false,
  });

  const { isLoading: settingStatusIsLoading, data: settingsStatusData } =
    useQuery<OverallStatus>({
      queryKey: [props.settingsQueryKey],
      queryFn: props.queryFn,
      retry: false,
    });

  const [settingEnabled, setSettingEnabled] = useState(
    props.organizationSettingEnabled,
  );

  return props.mutation.isLoading || settingStatusIsLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <div className='d-flex'>
        <BackArrow url={props.recordingSettingPath} />
        <PageTitle text={props.title} />
      </div>
      <HiringMemberSettingsOrganizationPanel
        title={props.title}
        activateText={props.activateText}
        hiringMemberSettingsType={props.hiringMemberSettingsType}
        organizationAutoRecordingEnabled={
          props.organizationAutoRecordingEnabled
        }
        organizationOptoutEnabled={props.organizationOptoutEnabled}
        setSettingEnabled={setSettingEnabled}
        mutation={props.mutation}
        settingsStatus={settingsStatusData.status}
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <DeprecatedPanel additionalInnerContainerClassNames='pt-4'>
          <div className='ps-4 pe-4'>
            <PanelHeader
              onSearchUpdated={(name) => setName(name)}
              title={'Hiring Members'}
              placeholder={'Search Users'}
              searchInputSize={''}
              searchValue={name}
            />
          </div>
          <HiringMemberSettingTablePanel
            hiringMemberSettingsType={props.hiringMemberSettingsType}
            organizationAutoRecordingEnabled={
              props.organizationAutoRecordingEnabled
            }
            organizationSettingEnabled={settingEnabled}
            hiringMembersList={hiringMembersData}
            settingsQueryKey={props.settingsQueryKey}
          />
          <PanelFooter
            page={hiringMembersData.page}
            pageSize={10}
            totalRows={hiringMembersData.entriesCount}
            disabled={false}
            onPageChanged={(page) => setPage(page)}
            className={'ps-4 pe-4 pb-4'}
          />
        </DeprecatedPanel>
      )}
    </>
  );
}
