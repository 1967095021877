import React, { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { JobRequisitionDto } from '../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { ApprovalFlowService } from '../../../../../services/v1/applicant_tracking/ApprovalFlowService';
import {
  ApprovalFlow,
  RequisitionType,
} from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { ApprovalFlowSummary } from '../../../ApprovalFlows/components/ApprovalFlowSummary';

interface PropTypes {
  requisitionType: RequisitionType;
  formControl: Control<JobRequisitionDto>;
}

export function ApprovalFlowContainer(props: PropTypes) {
  const [approvalFlow, setApprovalFlow] = useState<ApprovalFlow>(null);
  const locationId = useWatch({
    control: props.formControl,
    name: 'locationId',
  });
  const departmentId = useWatch({
    control: props.formControl,
    name: 'departmentId',
  });

  const getMatchingApprovalFlow = () => {
    if ((!locationId && departmentId) || (locationId && !departmentId)) {
      return;
    }

    ApprovalFlowService.getMatchingApprovalFlow(
      props.requisitionType,
      locationId,
      departmentId,
    ).then(setApprovalFlow);
  };

  useEffect(getMatchingApprovalFlow, [locationId, departmentId]);

  return (
    <>
      {approvalFlow && (
        <>
          <h3>Approval Flow</h3>
          <div className='fs-6 text-dark-200 mb-4'>
            Your requisition will be reviewed by the following approvers
          </div>
          <ApprovalFlowSummary steps={approvalFlow.steps} />
        </>
      )}
    </>
  );
}
