import React, { CSSProperties, ReactNode } from 'react';
import { SpanTooltip } from '../SpanTooltip';
import { Icon } from '../Icon';
import classNames from 'classnames';

interface PropTypes {
  children: ReactNode | string;
  className?: string;
  tooltipInnerClassName?: string;
  tooltipStyle?: CSSProperties;
}

export function InfoTooltip(props: PropTypes) {
  return (
    <SpanTooltip
      text={
        <Icon
          name={'bi-info-circle'}
          className={classNames(props.className, 'text-info')}
        />
      }
      tooltipInnerClassName={props.tooltipInnerClassName}
      tooltipStyle={props.tooltipStyle}
      tooltipText={props.children}
    />
  );
}
