import { Badge } from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';
import React from 'react';
import { Trend } from '../Trend';

interface PropTypes {
  metricName: string;
  metricValue: string;
  metricTrend?: number;
  badgeClassname: string;
}

export function SurveyMetricBadge(props: PropTypes) {
  return (
    <Badge
      className={classNames(
        'd-flex',
        'rounded-pill',
        'justify-content-center',
        'fs-6',
        props.badgeClassname,
      )}
    >
      <b>{props.metricValue}&nbsp;</b>
      <small>{props.metricName}</small>
      {props.metricTrend !== undefined && (
        <div className={classNames('ms-2', styles.trend)}>
          <Trend trend={props.metricTrend} />
        </div>
      )}
    </Badge>
  );
}
