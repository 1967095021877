import React from 'react';
import { Col, Row } from 'reactstrap';
import { EmptyState } from '../../../../components/EmptyState';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { JobOverviewAnalytics } from '../../../../entities/JobOverviewAnalytics';
import { Metric } from './Metric';
import { ActiveAndHiredBarChart } from '../../../../components/ActiveAndHiredBarChart';
import { shortFormatDate } from '../../../../utils/timeFormat';
import { IconButton } from '../../../../components/IconButton';

interface PropTypes {
  jobPostIds: number[];
  overviewAnalytics: JobOverviewAnalytics;
}

function calcPercentage(from, to) {
  if (to === 0) {
    return 100;
  }

  if (from === 0) {
    return 0;
  }

  return Math.round((from / to) * 100);
}

const Body = (props: PropTypes) => {
  const hiringRate = calcPercentage(
    props.overviewAnalytics.hires,
    props.overviewAnalytics.openings,
  );
  const activeCandidatesRate = calcPercentage(
    props.overviewAnalytics.activeCandidates,
    props.overviewAnalytics.applicants,
  );

  const activeCandidatesHistory =
    props.overviewAnalytics.activeCandidatesHistory.map((item) => item.value);
  const chartHeight = Math.max.apply(null, activeCandidatesHistory) * 1.5;
  const metricsColumnWidth = chartHeight > 0 ? 5 : 12;

  const labels = props.overviewAnalytics.hiredHistory.map(
    (history) =>
      `${shortFormatDate(history.from)} - ${shortFormatDate(history.to)}`,
  );
  const hiredData = props.overviewAnalytics.hiredHistory.map(
    (history) => history.value,
  );
  const activeCandidatesData =
    props.overviewAnalytics.activeCandidatesHistory.map(
      (history) => history.value,
    );

  return (
    <div className='text-dark-200 fs-5'>
      <Row className='d-flex align-items-center justify-content-between'>
        <Col sm='12' md={metricsColumnWidth}>
          <Row className='mb-2 mb-lg-4'>
            <Col sm='6'>
              <Metric
                value={props.overviewAnalytics.applicants}
                label='APPLICANTS'
              />
            </Col>
            <Col sm='6'>
              <Metric
                value={props.overviewAnalytics.hires}
                label='TOTAL HIRES'
                info={
                  props.overviewAnalytics.openings > 0 &&
                  `${hiringRate}% of openings`
                }
                color='info'
                infoColor='blue-500'
              />
            </Col>
          </Row>
          <Row>
            <Col sm='6'>
              <Metric
                value={props.overviewAnalytics.activeCandidates}
                label='ACTIVE CANDIDATES'
                info={`${activeCandidatesRate}% of total`}
                color='green-900'
                infoColor='green-900'
              />
            </Col>
            <Col sm='6'>
              <Metric
                value={
                  props.overviewAnalytics.daysToFill
                    ? `${props.overviewAnalytics.daysToFill} days`
                    : null
                }
                label='AVG TIME TO FILL'
              />
            </Col>
          </Row>
        </Col>
        {chartHeight > 0 && (
          <Col sm='12' md='7' className='py-2'>
            <ActiveAndHiredBarChart
              labels={labels}
              activeValues={activeCandidatesData}
              hiredValues={hiredData}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

function applicationsUrl(jobPostIds: number[]) {
  if (jobPostIds == null || jobPostIds.length === 0) {
    return '/candidates';
  }

  // FIXME: currently, only one job post is attached to a job. This may change in the future.
  return `/candidates?job=${jobPostIds?.[0]}`;
}

export function Overview(props: PropTypes) {
  const isEmpty = !props.overviewAnalytics;

  return (
    <DeprecatedPanel className='p-4 my-2'>
      <div className='mb-4 d-flex justify-content-between align-items-center'>
        <div className='fs-3 fw-semibold'>Overview</div>
        {!isEmpty && (
          <div className='d-flex align-items-center gap-2h'>
            <IconButton
              color='borderless'
              iconName='bi-people'
              buttonText='View All Applicants'
              size='sm'
              onClick={() =>
                (window.location.href = applicationsUrl(props.jobPostIds))
              }
            />
            <div className='fs-6 text-muted fw-semibold'>Last 90 days</div>
          </div>
        )}
      </div>
      {isEmpty ? (
        <EmptyState
          title='No job metrics available yet'
          text='Job related metrics will appear here once they are available.'
          excludeImage={true}
          titleClassName='fs-3'
          textFontSize='fs-5'
        />
      ) : (
        <Body {...props} />
      )}
    </DeprecatedPanel>
  );
}
