import React, { useEffect, useState } from 'react';
import { Card } from '../../../../components/Card';
import { OfferLetterTemplate } from '../../../../entities/v1/applicant_tracking/OfferLetterTemplate';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { IconButton } from '../../../../components/IconButton';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { OfferLetterTemplateService } from '../../../../services/v1/applicant_tracking/OfferLetterTemplateService';
import { NameInput } from '../components/NameInput';
import { OfferLetterRichTextComposer } from '../components/OfferLetterRichTextEditor/OfferLetterRichTextComposer';
import { BackArrow } from '../../../../components/BackArrow';
import { PageTitle } from '../../../../components/PageTitle';

interface PropTypes {
  id: number;
  editUrl?: string;
}

export const REDIRECT_URI =
  getRedirectUriQueryParam() ?? '/organization_settings/offer_letter_templates';

function Title(props: { name: string; editUrl?: string }) {
  return (
    <div className='d-flex justify-content-between mb-4'>
      <div className='d-flex me-auto align-items-center'>
        <BackArrow url={REDIRECT_URI} />
        <PageTitle text={props.name} />
      </div>
      {props.editUrl && (
        <IconButton
          color='primary'
          buttonText='Edit'
          iconName='bi-pencil'
          onClick={() => (window.location.href = props.editUrl)}
          disabled={props.editUrl == null}
        />
      )}
    </div>
  );
}

export default function ShowOfferLetterTemplatePage(props: PropTypes) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [offerLetterTemplate, setOfferLetterTemplate] =
    useState<OfferLetterTemplate>(null);

  useEffect(() => {
    (async () => {
      setOfferLetterTemplate(await OfferLetterTemplateService.show(props.id));

      setIsLoading(false);
    })();
  }, [props.id]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className='container'>
      <Title name={offerLetterTemplate.name} editUrl={props.editUrl} />
      <Card>
        <div className='d-flex flex-column w-50 fs-5 pt-2 pb-4 gap-4'>
          <NameInput errors={{}} disabled value={offerLetterTemplate.name} />
        </div>
        <OfferLetterRichTextComposer
          editor={offerLetterTemplate.template}
          disabled
        />
      </Card>
    </div>
  );
}
