import React from 'react';
import { StartingPoint } from '../../../survey_engine/SurveyTemplates/StartingPointSelector';
import { StartingPointSelector as BaseStartingPointSelector } from '../../../survey_engine/SurveyTemplates/StartingPointSelector';
import { ApplicationFormTemplateService } from '../../../../services/v1/applicant_tracking/ApplicationFormTemplateService';
import { ApplicationFormTemplateList } from '../../../../entities/v1/applicant_tracking/ApplicationFormTemplateList';

interface PropTypes {
  startingPoint: StartingPoint;
  onStartingPointChange: (value: StartingPoint) => void;
}

export function StartingPointSelector(props: PropTypes) {
  return (
    <BaseStartingPointSelector
      startingPoint={props.startingPoint}
      filterOptions={mapApplicationFormTemplatesToSelectOption}
      onStartingPointChange={props.onStartingPointChange}
      placeholder='Select application form'
    />
  );
}

async function mapApplicationFormTemplatesToSelectOption(
  _prevState: Promise<ApplicationFormTemplateList>,
  q: string,
) {
  return ApplicationFormTemplateService.list({
    name: q,
    expand: ['survey_template'],
  }).then((list) =>
    list.applicationFormTemplates.map((template) => ({
      value: template.id.toString(),
      label: template.surveyTemplate.name,
    })),
  );
}
