import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import { SurveyLayout } from '../../../components/SurveyLayout';
import { ProspectInfo } from '../../../entities/ProspectInfo';
import { Survey } from '../../../entities/Survey';
import { ProspectService } from '../../../services/ProspectService';
import { ProspectFormModal } from './ProspectFormModal';
import HelpYouPageImage from '../../../images/help_you_page.svg';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { CompanyInfo } from '../../../entities/CompanyInfo';
import SuccessPageImage from '../../../images/success_page.svg';
import { MoreInfoFooter } from '../MoreInfoFooter';

interface PropTypes {
  survey: Survey;
  onNotInterestedProspect: () => void;
}

export function AddProspectScreen(props: PropTypes) {
  const [isProspectFormOpen, setIsProspectFormOpen] = useState<boolean>(false);
  const [isProspectFormSubmitted, setIsProspectFormSubmitted] =
    useState<boolean>(false);

  const onSubmit = useCallback(async (prospectForm: Partial<ProspectInfo>) => {
    await ProspectService.create({
      survey_secure_id: props.survey.secure_id,
      ...prospectForm,
    });
    setIsProspectFormOpen(false);
    setIsProspectFormSubmitted(true);
  }, []);

  const formMethods = useForm<ProspectInfo>({ mode: 'onBlur' });

  return isProspectFormSubmitted ? (
    <SubmittedProspect companyInfo={props.survey.company_info} />
  ) : (
    <SurveyLayout
      companyInfo={props.survey.company_info}
      className={classNames(styles.bg)}
    >
      <div className='d-grid col-9 gap-4 mx-auto text-center'>
        <div className={classNames(styles['references-card'])}>
          <div
            className={classNames(
              'pt-5 pb-5',
              styles['help-card-content-max-width'],
            )}
          >
            {props.survey.company_info.careers_url && (
              <div>
                <Button
                  size='lg'
                  colour='secondary'
                  className='text-info border-info'
                  href={props.survey.company_info.careers_url}
                >
                  View Careers Page
                </Button>
              </div>
            )}
            <div className='d-flex justify-content-center'>
              <img className='d-block img-fluid' src={HelpYouPageImage} />
            </div>
            <h1 className='mb-0 mt-5 fw-semibold fs-display2'>
              Now Let Us Help You!
            </h1>
            <p className='mb-0 mt-3 fs-4'>
              Are you interested in hearing about job opportunities at{' '}
              {props.survey.company_info.name} or other awesome companies in our
              network?
            </p>
            <Row className='align-items-center justify-content-center mt-5'>
              <Col xs='4'>
                <Button
                  color='secondary'
                  size='lg'
                  className='px-4 w-100'
                  onClick={props.onNotInterestedProspect}
                >
                  No, I&apos;m not interested
                </Button>
              </Col>
              <Col xs='4'>
                <Button
                  color='primary'
                  size='lg'
                  className='px-4 w-100'
                  onClick={() => setIsProspectFormOpen(true)}
                >
                  Yes, I&apos;m interested
                </Button>
              </Col>
            </Row>
            <Row className='mt-4'>
              <MoreInfoFooter />
            </Row>
            <FormProvider {...formMethods}>
              <ProspectFormModal
                onSubmit={onSubmit}
                onClose={() => setIsProspectFormOpen(false)}
                isOpen={isProspectFormOpen}
                surveySecureId={props.survey.secure_id}
              />
            </FormProvider>
          </div>
        </div>
      </div>
    </SurveyLayout>
  );
}

function SubmittedProspect({ companyInfo }: { companyInfo: CompanyInfo }) {
  return (
    <SurveyLayout companyInfo={companyInfo}>
      <div className='col-9 mx-auto'>
        <Row className='text-center mt-4'>
          <div className={classNames(styles['references-card'])}>
            <div
              className={classNames(
                'pt-5 pb-5',
                styles['success-card-content-max-width'],
              )}
            >
              <Col>
                <Row className='mb-2 mt-5'>
                  <div className='d-flex justify-content-center'>
                    <img className='d-block img-fluid' src={SuccessPageImage} />
                  </div>
                  <h1 className='mb-0 mt-5 fw-semibold fs-display2'>
                    Success!
                  </h1>
                  <p className='mb-0 mt-3 fs-4'>
                    Thank you, now you&apos;re ready to be contacted by{' '}
                    {companyInfo.name} or other amazing companies.
                  </p>
                </Row>
                <Row className='mt-4'>
                  <MoreInfoFooter />
                </Row>
              </Col>
            </div>
          </div>
        </Row>
      </div>
    </SurveyLayout>
  );
}
