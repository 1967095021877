import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['name', 'counter'];

  countCharacters(_) {
    let characters = this.nameTarget.value.length;
    this.counterTarget.innerText = characters;
  }
}
