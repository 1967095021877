import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MultiOptionsField } from './fields/MultiOptionsField';
import { RatingField } from './fields/RatingField';
import { TextField } from './fields/TextField';
import { Survey } from '../../../../entities/survey_engine/Survey';
import { SurveyQuestion } from '../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../../../entities/survey_engine/SurveyQuestionAnswer';
import { SurveyQuestionField } from '../../../../entities/survey_engine/SurveyQuestionField';
import { hasAnsweredQuestion } from '../../../../utils/survey_engine/hasAnsweredQuestion';
import { updateSurveyWithAnswer } from '../../../../utils/survey_engine/updateSurveyWithAnswer';

interface PropTypes {
  survey: Survey;
  question: SurveyQuestion;
  questionField: SurveyQuestionField;
  surveyAnswer: SurveyQuestionAnswer;
  onUpdateSurvey: (survey: Survey) => void;
  onUpdateSurveyAnswers: (surveyAnswers: SurveyQuestionAnswer[]) => void;
  submitAnswer: (
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ) => Promise<Survey>;
}

function isQueryParamAnswerValid(
  question: SurveyQuestion,
  selectedFieldId: number,
) {
  return !(
    isNaN(selectedFieldId) ||
    !question.fields.map((field) => field.id).includes(selectedFieldId)
  );
}

function answerFromQueryParams(
  question: SurveyQuestion,
  surveyAnswer: SurveyQuestionAnswer,
  fieldId: number | typeof NaN,
  fieldValue: number | typeof NaN,
) {
  if (!isQueryParamAnswerValid(question, fieldId)) return;

  return {
    question_id: question.id,
    survey_id: surveyAnswer.survey_id,
    fields: [
      {
        field_id: fieldId,
        value: fieldValue.toString(),
        option_ids: [],
      },
    ],
  };
}

function updateSurveyAndSubmitAnswer(
  survey: Survey,
  answer: SurveyQuestionAnswer,
  onUpdateSurvey: (survey: Survey) => void,
  onUpdateSurveyAnswers: (surveyAnswers: SurveyQuestionAnswer[]) => void,
  submitAnswer: (
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ) => Promise<Survey>,
) {
  const updatedSurvey = updateSurveyWithAnswer(survey, answer);
  onUpdateSurvey(updatedSurvey);
  onUpdateSurveyAnswers(updatedSurvey.answers);
  submitAnswer(survey.secure_id, answer);
}

export function SurveyField(props: PropTypes) {
  const [searchParams] = useSearchParams();

  const fieldId = parseInt(searchParams.get('fieldId'), 10);
  const fieldValue = parseInt(searchParams.get('fieldValue'), 10);

  const fieldAnswer = props.surveyAnswer?.fields?.find(
    (f) => f.field_id === props.questionField.id,
  );

  const [answer, setAnswer] = useState<SurveyQuestionAnswer>(
    props.surveyAnswer,
  );

  // When answer comes from query params.
  useEffect(() => {
    const newAnswer = answerFromQueryParams(
      props.question,
      props.surveyAnswer,
      fieldId,
      fieldValue,
    );
    if (!newAnswer) return;
    if (!hasAnsweredQuestion(newAnswer, props.question, true)) return;

    updateSurveyAndSubmitAnswer(
      props.survey,
      newAnswer,
      props.onUpdateSurvey,
      props.onUpdateSurveyAnswers,
      props.submitAnswer,
    );
  }, [fieldId, fieldValue]);

  // // When answer comes from UI.
  function handleOnChangeAnswer(
    fieldId: number,
    value: string,
    optionIds?: number[],
  ) {
    const fields = answer?.fields.filter((f) => f.field_id !== fieldId);
    const newAnswer = {
      question_id: props.question.id,
      survey_id: props.survey.id,
      fields: fields.concat({
        field_id: fieldId,
        value,
        option_ids: optionIds,
      }),
    };
    if (!hasAnsweredQuestion(newAnswer, props.question, true)) return;

    setAnswer(newAnswer);
    updateSurveyAndSubmitAnswer(
      props.survey,
      newAnswer,
      props.onUpdateSurvey,
      props.onUpdateSurveyAnswers,
      props.submitAnswer,
    );
  }

  if (props.questionField.type === 'rating') {
    return (
      <RatingField
        field={props.questionField}
        fieldAnswer={fieldAnswer}
        onChangeAnswer={handleOnChangeAnswer}
      />
    );
  } else if (props.questionField.type === 'multi_options') {
    return (
      <MultiOptionsField
        field={props.questionField}
        fieldAnswer={fieldAnswer}
        onChangeAnswer={handleOnChangeAnswer}
      />
    );
  } else if (props.questionField.type === 'text') {
    return (
      <TextField
        field={props.questionField}
        fieldAnswer={fieldAnswer}
        onChangeAnswer={handleOnChangeAnswer}
      />
    );
  }

  return null;
}
