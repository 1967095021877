import { ReactNode } from 'react';
import React from 'react';
import { Card } from '../../../../../components/Card';

interface PropTypes {
  title: ReactNode;
  children: ReactNode;
  height: string; // Height of the card/panel in px.
}

export function TitledPanel(props: PropTypes) {
  return (
    <Card style={{ height: props.height }}>
      <div className='d-flex flex-column gap-2 h-100'>
        <div className='fs-3 fw-semibold'>{props.title}</div>
        {props.children}
      </div>
    </Card>
  );
}
