import React from 'react';
import { CloseableModal } from '../../CloseableModal';
import { ModalBody } from './ModalBody';

interface PropTypes {
  isOpen: boolean;
  applicationId: number;
  interviewStageId: number;
  onClose: () => void;
}

export function ScheduleInterviewModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={() => props.onClose()}
      isOpen={props.isOpen}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle='Schedule Interview'
      bodyChildren={<ModalBody {...props} />}
    />
  );
}
