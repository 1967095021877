import React from 'react';
import { CloseableModal } from '../../../../../components/CloseableModal';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { LoadingSpinnerIcon } from '../../../../../components/LoadingSpinnerIcon';
import { JobBoardAlertObject, JobBoardAlertType } from '../JobBoardAlert';
import { PaymentModalBody } from './PaymentModalBody';

export interface PaymentData {
  campaignId: string;
  campaignName: string;
  marketplaceUrl: string;
  partnerId: string;
  walletId: string;
}

interface PropTypes {
  isOpen: boolean;
  paymentData?: PaymentData;
  onComplete: (success: boolean) => void;
  setIsOpen: (open: boolean) => void;
  setAlert: (alert: JobBoardAlertObject) => void;
}

const PAYMENT_IFRAME_PATHNAME = 'wallet/topup.html';
const PAYMENT_METHOD_TYPE = 'card';

function buildUrl(
  campaignId: string,
  marketplaceUrl: string,
  partnerId: string,
  walletId: string,
) {
  const url = new URL(PAYMENT_IFRAME_PATHNAME, marketplaceUrl);
  url.searchParams.append('walletId', walletId);
  url.searchParams.append('partnerId', partnerId);
  url.searchParams.append('campaignId', campaignId);
  url.searchParams.append('paymentMethodTypes', PAYMENT_METHOD_TYPE);

  return url.toString();
}

function LoadingData() {
  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-center',
        styles['loading-payment'],
      )}
    >
      <div className='me-2'>
        <LoadingSpinnerIcon className='text-info' />
      </div>
      <span className='fs-5 fw-normal text-dark-200'>Creating campaign</span>
    </div>
  );
}

export function PaymentModal(props: PropTypes) {
  function handleCompletion(success: boolean) {
    setTimeout(() => {
      props.setIsOpen(false);
      props.onComplete(success);

      if (success) {
        props.setAlert({
          type: JobBoardAlertType.PaymentSuccess,
          message: (
            <span>
              <b>{props.paymentData.campaignName}</b> was successfully created!
            </span>
          ),
        });
      } else {
        props.setAlert({
          type: JobBoardAlertType.PaymentError,
          message: 'An error occurred during the payment',
        });
      }
    }, 2000); // Delay 2 seconds, so the modal is not immediately dismissed.
  }

  return (
    <CloseableModal
      bodyChildren={
        props.paymentData != null ? (
          <PaymentModalBody
            url={buildUrl(
              props.paymentData.campaignId,
              props.paymentData.marketplaceUrl,
              props.paymentData.partnerId,
              props.paymentData.walletId,
            )}
            marketplaceUrl={props.paymentData.marketplaceUrl}
            onComplete={handleCompletion}
            setAlert={props.setAlert}
          />
        ) : (
          <LoadingData />
        )
      }
      className='modal-dialog-centered'
      isOpen={props.isOpen}
      size='md'
      headerTitle='Review and Pay'
      onClose={() => props.setIsOpen(false)}
    />
  );
}
