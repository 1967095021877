import React from 'react';
import { Card } from '../../../../components/Card';
import { Row } from 'reactstrap';
import { TotpButtonsCol } from './TotpButtonsCol';
import { AuthenticatorTextCol } from './AuthenticatorTextCol';

interface PropTypes {
  totpEnabled: boolean;
}

export function TwoFactorAuthenticationCard(props: PropTypes) {
  return (
    <Card className='align-items-start mt-3 p-4'>
      <div className='mb-3 px-3 pt-3'>
        <h2>Two-Factor Authentication</h2>
      </div>
      <div>
        <div className='p-3'>
          <Row className='justify-content-between align-items-center'>
            <AuthenticatorTextCol totpEnabled={props.totpEnabled} />
            <TotpButtonsCol totpEnabled={props.totpEnabled} />
          </Row>
        </div>
      </div>
    </Card>
  );
}
