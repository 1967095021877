import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createTextNode, $getRoot } from 'lexical';
import { $createCodeNode } from '@lexical/code';
import { $convertFromMarkdownString } from '@lexical/markdown';
import { TRANSFORMERS } from '@lexical/markdown';
interface PropTypes {
  clearState?: boolean;
  text?: string;
}

export function MarkdownNodePlugin(props: PropTypes) {
  const [editor] = useLexicalComposerContext();

  function update(text, clearState) {
    editor.update(() => {
      // Get the RootNode from the EditorState
      const root = $getRoot();

      // Create a new TextNode
      const textNode = $createTextNode(text);

      // Add to root node as markdown node
      if (clearState) {
        root.clear();
      }
      root.append($createCodeNode('markdown').append(textNode));

      //Convert from markdown code to formatted string
      $convertFromMarkdownString(
        root.getFirstChild().getTextContent(),
        TRANSFORMERS,
      );
    });
  }

  useEffect(() => {
    // The setTimeout is needed to avoid the warning "Warning: flushSync was called from inside a
    // lifecycle method". Reference: https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1546629928
    setTimeout(() => {
      if (props.text != null) {
        update(props.text, props.clearState);
      }
    });
  }, [editor, props.text]);

  return null;
}
