import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';
import { getFirstOverflowIndex, isOverflowing } from '../../../utils/overflow';

const TOPIC_PIXELS_PER_CHARACTER = 8;

interface PropTypes {
  topics: string[];
  fixedHeight?: boolean;
}

function getClassNameByIndex(
  index: number,
  size: number,
  isOverflowing: boolean,
) {
  if (!isOverflowing) {
    return ''; // Ii will not overflow, so it should show all topics.
  } else if (size > 2 && index <= 1) {
    return styles.topicOverflow; // If there are three topics, the first two should overflow.
  }

  return 'w-100';
}

function Topic(props: {
  size: number;
  index: number;
  name: string;
  isOverflowing: boolean;
}) {
  return (
    <div
      key={props.index}
      className={classNames(
        getClassNameByIndex(props.index, props.size, props.isOverflowing),
        styles.topic,
      )}
    >
      <div
        className={classNames(
          'badge',
          'rounded-pill',
          'fw-normal',
          'text-primary',
          'mw-100',
          'bg-light-warning',
          'text-truncate',
        )}
      >
        {props.name}
      </div>
    </div>
  );
}

export function Topics(props: PropTypes) {
  const { width, ref } = useResizeDetector();

  const overflow = isOverflowing(
    getFirstOverflowIndex(width, props.topics, TOPIC_PIXELS_PER_CHARACTER),
  );

  return (
    <div
      ref={ref}
      className={classNames(
        'd-flex',
        overflow ? 'flex-wrap' : 'flex-nowrap',
        'gap-1',
        'w-100',
        props.fixedHeight && styles.topics,
      )}
    >
      {props.topics.map((topic, index) => {
        return (
          <Topic
            key={index}
            size={props.topics.length}
            index={index}
            name={topic}
            isOverflowing={overflow}
          />
        );
      })}
    </div>
  );
}
