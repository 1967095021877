import React from 'react';

import { Button, Col } from 'reactstrap';
import { AttachmentInput } from './AttachmentInput';
import { FileError } from 'use-file-picker';
import { MAX_FILES, MAX_FILE_SIZE_MB } from '../..';
import { EmailIntegrationFetchState } from './EmailIntegrationFetchState';

interface PropTypes {
  errors: FileError[];
  fileLength: number;
  isValid: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  openFileSelector: () => void;
}

function EmailButtons(props: {
  isValid: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) {
  return (
    <Col className='d-flex justify-content-end'>
      <Button
        type='button'
        className='me-3'
        color='danger'
        onClick={() => props.onClose()}
      >
        Cancel
      </Button>
      <Button
        type='button'
        onClick={props.onSubmit}
        color='primary'
        disabled={!props.isValid}
      >
        Send
      </Button>
    </Col>
  );
}

export function EmailFooter(props: PropTypes) {
  return (
    <>
      {props.isLoading ? (
        <EmailIntegrationFetchState />
      ) : (
        <AttachmentInput
          filesLength={props.fileLength}
          errors={props.errors}
          maxFiles={MAX_FILES}
          maxFileSizeMb={MAX_FILE_SIZE_MB}
          openFileSelector={props.openFileSelector}
        />
      )}
      <EmailButtons
        isValid={props.isValid && !props.isLoading}
        onClose={props.onClose}
        onSubmit={props.onSubmit}
      />
    </>
  );
}
