import React from 'react';
import { InputFormField } from '../../../../../components/InputFormField';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { UpsertEmailTemplateDto } from '../../UpsertEmailTemplateDto';
import { Col } from 'reactstrap';
import classNames from 'classnames';

interface PropTypes {
  errors: FieldErrors;
  register: UseFormRegister<UpsertEmailTemplateDto>;
  disabled?: boolean;
}

export function NameInput(props: PropTypes) {
  return (
    <Col xs={3}>
      <InputFormField
        placeholder='Insert email template name'
        isRequired={true}
        fieldName='Name'
        classNames={classNames(
          props.errors.name && 'is-invalid',
          'fs-5 fw-normal',
        )}
        registerReturn={props.register('name', { required: true })}
        type='text'
      />
    </Col>
  );
}
