import React from 'react';
import { Trigger } from '../../../../../entities/applicant_tracking/Trigger';
import { getLabelForCount } from '../../../../../utils/grammar';
import { Chip, ChipColor } from '../../../../../components/Chip';

interface PropTypes {
  trigger: Trigger;
  index: number;
}

function getTriggerDisplayText(trigger: Trigger) {
  switch (trigger.type) {
    case 'auto_reject':
      return 'Auto-Reject';
    case 'send_email':
      return 'Send Email';
    case 'send_self_scheduling_email':
      return 'Send Self-Scheduling Link';
  }
}

export function TriggerRow(props: PropTypes) {
  return (
    <div className='mb-2 d-flex justify-content-between'>
      <div>
        <span>A{props.index + 1} </span>
        {props.trigger.type == 'send_email' && (
          <span className='fs-6 text-dark-200'>
            ({getLabelForCount('Stage', props.trigger.stageIds.length)})
          </span>
        )}
      </div>
      <div>
        <Chip className='fw-semibold' color={ChipColor.Gray}>
          {getTriggerDisplayText(props.trigger)}
        </Chip>
      </div>
    </div>
  );
}
