import { FilterState, SelectedFilterState } from '../../utils/filterState';
import UndrawEmptyState from '../../../../../images/undraw_empty_state_cactus.svg';
import {
  EMPTY_PRODUCTS,
  ProductsWithPagination,
} from '../../utils/paginationState';
import { Card } from '../../../../../components/Card';
import React from 'react';
import { FiltersAndSorting } from './FiltersAndSorting';
import { Col, Row } from 'reactstrap';
import { Product, ProductPriceCurrency } from '@vonq/hapi-elements-types';
import { JobBoardCard } from './JobBoardCard';
import { LoadMoreButton } from '../../../../../components/LoadMoreButton';
import { EmptyState } from '../../../../../components/EmptyState';
import { JobBoardAlertObject, JobBoardAlertType } from '../JobBoardAlert';
import { WindowWithHapi } from '../..';
import { TargetGroupValues } from '../JobPostingDetails/TargetGroup/TargetGroupValues';

interface PropTypes {
  currency: ProductPriceCurrency;
  isLoadingProducts: boolean;
  filterState: FilterState;
  products: ProductsWithPagination;
  selectedProducts: Product[];
  selectedState: SelectedFilterState;
  targetGroupValues: TargetGroupValues;
  window: WindowWithHapi;
  onLoadElements: (...args: any[]) => Promise<void>;
  setAlert: (alert: JobBoardAlertObject) => void;
  setFilterState: (filterState: FilterState) => void;
  setProducts: (products: ProductsWithPagination) => void;
  setSelectedState: (selectedState: SelectedFilterState) => void;
}

export function Marketplace(props: PropTypes) {
  const hasMoreData =
    props.products.pagination.totalElements == null ||
    props.products.pagination.totalElements > props.products.pagination.offset;

  async function handleAddProduct(product: Product): Promise<void> {
    try {
      await props.window.hapi.basket.service.addProductOrContractById.run(
        product.product_id,
        true,
        false,
      );
      props.setAlert({
        type: JobBoardAlertType.ProductAdded,
        message: (
          <span>
            <b>{product.title}</b> was added to the cart
          </span>
        ),
      });
    } catch (e: any) {
      console.error(`Could not add product ${product.title} to the basket`, e);
      props.setAlert({
        type: JobBoardAlertType.ProductAddedError,
        message: (
          <span>
            Could not add the <b>{product.title}</b> to the cart
          </span>
        ),
      });
    }
  }

  return (
    <Card>
      <FiltersAndSorting
        filterState={props.filterState}
        window={props.window}
        selectedState={props.selectedState}
        targetGroupValues={props.targetGroupValues}
        setSelectedState={props.setSelectedState}
        onReset={() => props.setProducts(EMPTY_PRODUCTS)}
        setFilterState={props.setFilterState}
      />
      <Row className='g-3'>
        {!props.isLoadingProducts && props.products.products.length === 0 ? (
          <EmptyState
            title='No results found'
            text={'Please refine your search or filters and try again.'}
            imageSrc={UndrawEmptyState}
          />
        ) : (
          props.products.products.map((product: Product, index: number) => (
            <Col key={index} xs='4'>
              <JobBoardCard
                product={product}
                onAddProduct={handleAddProduct}
                currency={props.currency}
                selectedProducts={props.selectedProducts}
              />
            </Col>
          ))
        )}
        {hasMoreData && (
          <LoadMoreButton
            isLoading={props.isLoadingProducts}
            loadElements={props.onLoadElements}
          />
        )}
      </Row>
    </Card>
  );
}
