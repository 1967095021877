import React from 'react';
import { InterviewClip } from '../../../../entities/InterviewClip';
import { Monologue } from '../../../../entities/v1/interview_intelligence/Monologue';
import { InterviewClipItem } from '../InterviewClipListItem';

interface PropTypes {
  interviewId: string;
  interviewClips: InterviewClip[];
  loadingLinkClipId?: string;
  monologues: Monologue[];
  onSelectClip: (clip: InterviewClip) => void;
  onDelete: (clip: InterviewClip) => void;
  onEdit: (clip: InterviewClip) => void;
  onCopyLink: (clip: InterviewClip) => void;
}

export function InterviewClipList(props: PropTypes) {
  return (
    <>
      {props.interviewClips
        .sort((a, b) => a.startOffsetMs - b.startOffsetMs)
        .map((clip) => (
          <InterviewClipItem
            key={clip.id}
            clip={clip}
            monologues={props.monologues}
            loadingLink={clip.id == props.loadingLinkClipId}
            onClick={() => props.onSelectClip(clip)}
            onDelete={() => props.onDelete(clip)}
            onEdit={() => props.onEdit(clip)}
            onCopyLink={() => props.onCopyLink(clip)}
          />
        ))}
    </>
  );
}
