import React from 'react';
import { SalaryCurrency } from './SalaryCurrency';
import { SalaryRange } from './SalaryRange';
import { WeeklyRange } from './WeeklyRange';
import { SalaryPeriod } from './SalaryPeriod';
import { NumberField } from '../NumberField';
import { JobPostingDetailsForm } from '..';
import { FormGroup } from '../FormGroup';
import { isSalaryRangeFilled } from '../../../utils/formIsValid';

interface PropTypes {
  form: JobPostingDetailsForm;
  errors: Record<string, string>;
  onInputChange: (name: string, value: unknown) => void;
}

export function JobRequirementsFields(props: PropTypes) {
  return (
    <FormGroup>
      <h4>Job Requirements</h4>
      <NumberField
        label='Years of Experience'
        value={props.form.yearsOfExperience?.toString()}
        name='yearsOfExperience'
        onInputChange={props.onInputChange}
        mandatory={true}
        error={props.errors['yearsOfExperience']}
      />
      <SalaryRange
        form={props.form}
        errors={props.errors}
        onInputChange={props.onInputChange}
      />
      <SalaryPeriod
        form={props.form}
        mandatory={isSalaryRangeFilled(props.form)}
        disabled={!isSalaryRangeFilled(props.form)}
        onInputChange={props.onInputChange}
      />
      <SalaryCurrency
        form={props.form}
        mandatory={isSalaryRangeFilled(props.form)}
        disabled={!isSalaryRangeFilled(props.form)}
        onInputChange={props.onInputChange}
      />
      <WeeklyRange
        form={props.form}
        errors={props.errors}
        onInputChange={props.onInputChange}
      />
    </FormGroup>
  );
}
