import classNames from 'classnames';
import React from 'react';
import { Icon } from '../Icon';
import styles from './styles.module.scss';

interface PropTypes {
  value: number; // Provide in range [0-1]
  width?: number;
  fillColorClass?: string;
}

const colorMap = {
  'rating-color': '#ffd954',
};

function scale(value: number): number {
  const IN_MIN = 0;
  const IN_MAX = 1;
  const OUT_MIN = 0.2;
  const OUT_MAX = 0.8;

  return ((value - IN_MIN) * (OUT_MAX - OUT_MIN)) / (IN_MAX - IN_MIN) + OUT_MIN;
}

export function PartialStar(props: PropTypes) {
  const difference = props.value % 1;
  const scaledValue = scale(difference);
  const width = props.width || 16;
  const fillColor: string = props.fillColorClass || 'rating-color';

  return (
    <div className='ps-1'>
      <div className='d-flex'>
        <Icon
          name='bi-star-fill'
          className={classNames('p-0 fs-4 white', styles['partial-star'])}
          style={{
            background: `linear-gradient(to right, ${colorMap[fillColor]} ${
              width * scaledValue
            }px, white ${width * (scaledValue - 1)}px)`,
          }}
        />
        <Icon
          name='bi-star'
          className={classNames(
            'p-0 fs-4',
            `text-${fillColor} bg-${fillColor}`,
            styles['partial-star'],
          )}
          style={{
            marginLeft: `-${width}px`,
          }}
        />
      </div>
    </div>
  );
}
