import React, { useState } from 'react';
import { StyledModal } from '../../../components/StyledModal';
import { User } from '../../../entities/training/User';
import { AssignUserFooter } from './AssignUserFooter';
import { AssignedUserOption, AssignUserSelect } from './AssignUserSelect';

interface PropTypes {
  visible: boolean;
  sessionName: string;
  sessionId: number;
  trainingUsers: User[];
  onClose: () => void;
  onAssign: (sessionId: number, users: number[]) => void;
}

function mapUsersToUserOptions(users: User[]): AssignedUserOption[] {
  return users.map((user: User) => {
    return {
      label: user.name,
      subLabel: user.email,
      value: user.id.toString(),
      disabled: user.assigned && user.completed,
      selected: user.assigned,
    };
  });
}

function getUserOptionsIds(userOptions: AssignedUserOption[]): number[] {
  return userOptions.map((user) => {
    return new Number(user.value).valueOf();
  });
}

export function AssignUserModal(props: PropTypes) {
  if (props.trainingUsers == null || props.trainingUsers.length === 0) {
    return null;
  }

  const users = mapUsersToUserOptions(props.trainingUsers);

  const [selectedUsers, setSelectedUsers] = useState(
    users.filter((opt) => opt.selected),
  );

  function handleSessionUsers() {
    props.onAssign(props.sessionId, getUserOptionsIds(selectedUsers));
  }

  return (
    <StyledModal
      isOpen={props.visible}
      title='Edit Assigned Users'
      onCancel={props.onClose}
      size={'md'}
      overflowVisible
      bodyChildren={
        <AssignUserSelect
          sessionName={props.sessionName}
          options={users}
          selected={selectedUsers}
          onSelected={setSelectedUsers}
        />
      }
      footerChildren={
        <AssignUserFooter
          onCancel={props.onClose}
          onAssign={handleSessionUsers}
        />
      }
      footerClassName='pe-4'
    />
  );
}
