import React from 'react';
import { SingleScore } from '../../../../../../InterviewRatingResponse/SingleScore';
import { RateOption } from '../../../../../../../entities/RateOption';
import { AverageRating } from './AverageRating';
import { ScorecardBlockTitle } from './ScorecardBlockTitle';
import { Chip, ChipColor } from '../../../../../../../components/Chip';
import { IconSpan } from '../../../../../../../components/IconSpan';
import { User } from '../../../../../../../entities/User';
import { Scorecard } from '../../../../../../../entities/applicant_tracking/Scorecard';
import { Col } from 'reactstrap';
import { RATING_OPTIONS } from '../../../../../../applicant_tracking/Scorecards/ScorecardPage/ScorecardBody/ScorecardScore';
import { IconLink } from '../../../../../../../components/IconLink';
import { SpanTooltip } from '../../../../../../../components/SpanTooltip';
import { appendRedirectUri } from '../../../../../../../utils/url';

interface PropTypes {
  currentUser: User;
  scorecard: Scorecard;
  viewAllScorecards: boolean;
}

export function ScorecardHeader(props: PropTypes) {
  const ratedOption = RATING_OPTIONS.find(
    (option: RateOption) => option.value === props.scorecard.score,
  );

  return (
    <div className='d-flex justify-content-between w-100 align-items-center row flex-nowrap'>
      <Col>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div className='d-flex align-items-center'>
            <ScorecardBlockTitle
              currentUser={props.currentUser}
              scorecard={props.scorecard}
              viewAllScorecards={props.viewAllScorecards}
            />
            <Chip
              className='ms-0 text-truncate overflow-visible ms-1 px-3 me-3'
              color={ratedOption ? ratedOption.chipColor : ChipColor.Gray}
            >
              {ratedOption ? (
                <SingleScore score={ratedOption} logoAtStart={true} />
              ) : (
                <IconSpan
                  icon={{ name: 'bi-clipboard-x' }}
                  spanText='Not Rated'
                />
              )}
            </Chip>
          </div>
          <AverageRating scorecard={props.scorecard} />
        </div>
      </Col>
      <Col xl={2} xs={1} className='d-flex justify-content-end'>
        {props.scorecard.survey?.status === 'completed' &&
          props.scorecard.user.id === props.currentUser.id && (
            <SpanTooltip
              className='me-2'
              text={
                <IconLink
                  text=''
                  iconClassName='bi-pencil text-info'
                  className='my-1'
                  url={appendRedirectUri(
                    `/applicant_tracking/scorecards/${props.scorecard.id}`,
                  )}
                  onClick={(e) => e.stopPropagation()}
                />
              }
              tooltipText='Edit'
            />
          )}
      </Col>
    </div>
  );
}
