import React from 'react';
import { SelectOption } from '../../../components/Select';

interface BreakdownSelectedInterviewersValuesPropTypes {
  interviewers: SelectOption[];
  colors: { [index: string]: string };
  breakdownValues: { [key: number]: number };
}

function BreakdownSelectedInterviewersValues(
  props: BreakdownSelectedInterviewersValuesPropTypes,
) {
  return (
    <>
      {props.interviewers.map((opt) => (
        <li
          key={opt.value}
          className={'fs-6 fw-normal mt-1'}
          style={{ color: '#3B3E59' }}
        >
          <span
            {...(props.breakdownValues[opt.value]
              ? { style: { color: props.colors[opt.value] } }
              : { style: { color: '#DDE4EA' } })}
          >
            {props.breakdownValues[opt.value]
              ? `${Math.round(props.breakdownValues[opt.value])}% `
              : 'N/A '}
          </span>
          {opt.label}
        </li>
      ))}
    </>
  );
}

function BreakdownAllInterviewersValues(props: { score: number }) {
  return (
    <>
      <li
        key={'100'}
        className={'fs-6 fw-normal mt-1'}
        style={{ color: '#3B3E59' }}
      >
        <span
          {...(props.score
            ? { style: { color: '#7976FF' } }
            : { style: { color: '#DDE4EA' } })}
        >
          {props.score ? `${Math.round(props.score)}% ` : `N/A `}
        </span>
        All Interviewers
      </li>
    </>
  );
}

interface PropTypes {
  interviewers: SelectOption[];
  colors: { [index: string]: string };
  breakdownValues: { [key: number]: number };
  score: number;
}

export function BreakdownValues(props: PropTypes) {
  return (
    <>
      {props.interviewers.length > 0 ? (
        <BreakdownSelectedInterviewersValues
          interviewers={props.interviewers}
          colors={props.colors}
          breakdownValues={props.breakdownValues}
        />
      ) : (
        <BreakdownAllInterviewersValues score={props.score} />
      )}
    </>
  );
}
