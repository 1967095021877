import React from 'react';
import { LabelledSelect } from '../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../components/Select';

interface PropTypes {
  selectedMaxReminders: SelectOption;
  setSelectedMaxReminders: (selectedMaxReminder: SelectOption) => void;
  selectedDelayDays: SelectOption;
  setSelectedDelayDays: (selectedDelayDay: SelectOption) => void;
}

function buildLabelledNumberOptions(min: number, max: number) {
  const arr = [];
  let value = min;
  for (let i = 1; i <= max; i++) {
    arr.push({ value: value.toString(), label: value.toString() });
    value++;
  }
  return arr;
}

const MAXREMINDERS = buildLabelledNumberOptions(0, 7);
const DELAYDAYS = buildLabelledNumberOptions(0, 8);

export function MaxRemindersAndDelayDaysRow(props: PropTypes) {
  return (
    <div className='row align-items-center'>
      <div className='col-3'>
        <LabelledSelect
          options={MAXREMINDERS}
          selected={props.selectedMaxReminders}
          onChange={(e) => {
            props.setSelectedMaxReminders(e);
          }}
          label='Max Reminders'
          className={'w-100'}
        />
      </div>
      <div className='col-3'>
        <LabelledSelect
          options={DELAYDAYS}
          selected={props.selectedDelayDays}
          onChange={(e) => {
            props.setSelectedDelayDays(e);
          }}
          label='Delay Days'
          className={'w-100'}
        />
      </div>
    </div>
  );
}
