import React, { useEffect, useState } from 'react';
import { ToggleButton } from '../ToggleButton';

export interface PropTypes {
  enabled: boolean;
}

export function SidekickToggle(props: PropTypes) {
  const [enabled, setEnabled] = useState(props.enabled);

  function toggleEventHandler() {
    setEnabled(!enabled);
  }

  useEffect(() => {
    window.postMessage(
      {
        eventType: 'ToggleSidekickAlwaysOnTop',
        args: { enabled },
      },
      '*',
    );
  }, [enabled]);

  return (
    <>
      <span className='me-3'>Always on top</span>
      <ToggleButton
        name='SidekickToggle'
        checked={enabled}
        onChange={toggleEventHandler}
      />
    </>
  );
}
