import React from 'react';
import { Card, Col } from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';

export function InterviewKitCard(props: {
  title: string;
  children: JSX.Element;
}) {
  return (
    <Col xs='6'>
      <Card className={classNames('pt-4', 'pb-6h')}>
        <div className={classNames('px-4 fw-semibold fs-3')}>{props.title}</div>
        <div className={classNames('px-4', styles['interview-kit-card'])}>
          {props.children}
        </div>
      </Card>
    </Col>
  );
}
