import React from 'react';
import { Col, Row } from 'reactstrap';
import logo from '../../../images/logo.svg';

interface PropTypes {
  logoUrl?: string;
}

export function HeaderWithLogo({ logoUrl }: PropTypes) {
  return (
    <Row>
      <Col xs='12'>
        <img src={logoUrl || logo} style={{ width: '200px' }} />
      </Col>
    </Row>
  );
}
