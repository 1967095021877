import React from 'react';
import classNames from 'classnames';

interface PropTypes {
  text: React.ReactNode;
  classNames?: string;
  dataTestId?: string;
}

export function PageTitle(props: PropTypes) {
  return (
    <h1
      className={classNames('app-page-title', 'mb-0', props.classNames)}
      data-testid={props.dataTestId}
    >
      {props.text}
    </h1>
  );
}
