import React from 'react';
import { Avatar } from '../../../../../components/Avatar';
import { Application } from '../../../../../entities/Application';
import { AddCandidateRatingButton } from '../../../../../components/ApplicantTracking/AddCandidateRatingButton';
import { DeprecatedAverageRating } from './DeprecatedAverageRating';
import { AverageRating } from './AverageRating';

interface PropTypes {
  application: Application;
  hrisLogo: string;
  reloadApplication: () => void;
  feedbackFFEnabled: boolean;
}

export function CandidateDetails(props: PropTypes) {
  return (
    <div className='d-flex mb-4 justify-content-between'>
      <Avatar
        name={props.application.candidate.name}
        size='xl'
        sideAvatarImg={props.hrisLogo}
        sideAvatarUrl={props.application.hrisEmployee?.externalUrl}
      />
      {props.feedbackFFEnabled ? (
        <div>
          <AverageRating {...props} />
          <AddCandidateRatingButton
            {...props}
            className='mt-2'
            onClose={props.reloadApplication}
          />
        </div>
      ) : (
        <DeprecatedAverageRating value={props.application.attributeRating} />
      )}
    </div>
  );
}
