import React from 'react';
import { Row } from 'reactstrap';
import { QuestionTopChoices } from '../../../../entities/candidate_experience/QuestionTopChoices';
import { PageBreak } from '../PageBreak';
import { REPORTING_SECTION_CLASS } from '../../../../utils/pdfHandler';
import classNames from 'classnames';
import { SelectOption } from '../../../../components/Select';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import { isOdd } from '../../../../utils/isOdd';
import { ExtraMultiOptionsLastOdd } from './ExtraMultiOptionsLastOdd';
import { ExtraMultiOptionsLastEven } from './ExtraMultiOptionsLastEven';

export function ExtraMultiOptions(props: {
  extraMultiOptions: QuestionTopChoices[];
  benchmarkType: SelectOption;
}) {
  const extraMultiOptionsWithTopChoices = props.extraMultiOptions.filter(
    (option) => option.top_choices.length > 0,
  );

  if (extraMultiOptionsWithTopChoices.length === 0) return null;

  const colors = [
    stylesheetExports.pink,
    stylesheetExports.blue500,
    stylesheetExports.green700,
    stylesheetExports.chartOrange,
  ];

  return (
    <>
      <PageBreak />
      <Row className={classNames(REPORTING_SECTION_CLASS)}>
        {extraMultiOptionsWithTopChoices.map((option, idx) =>
          isOdd(idx + 1) &&
          extraMultiOptionsWithTopChoices.length === idx + 1 ? (
            <React.Fragment key={idx}>
              <ExtraMultiOptionsLastOdd option={option} colors={colors} />
            </React.Fragment>
          ) : (
            <React.Fragment key={idx}>
              <ExtraMultiOptionsLastEven
                option={option}
                benchmarkType={props.benchmarkType}
                colors={colors}
              />
            </React.Fragment>
          ),
        )}
      </Row>
    </>
  );
}
