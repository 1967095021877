import classNames from 'classnames';
import React from 'react';
import { ConditionsSelectOptions } from '../..';
import { LabelledMultiSelect } from '../../../../../../../../../../components/LabelledMultiSelect';
import { humanize } from '../../../../../../../../../../utils/humanize';
import { computeNewConditions } from '../../../../../../utils/computeNewConditions';

interface PropTypes {
  conditionsSelectOptions: ConditionsSelectOptions;
}

export function ConditionsLabelledMultiSelect(props: PropTypes) {
  const placeHolder = props.conditionsSelectOptions.type.value
    ? `Choose a ${props.conditionsSelectOptions.type.label}`
    : '';
  return (
    <LabelledMultiSelect
      options={props.conditionsSelectOptions.available}
      selected={props.conditionsSelectOptions.multiSelected}
      label={''}
      placeholder={placeHolder}
      singleSelectedText={`1 selected ${humanize(
        props.conditionsSelectOptions.type.value,
      )}`}
      manySelectedText={`%d selected ${humanize(
        props.conditionsSelectOptions.type.value,
      )}s`}
      disabled={!props.conditionsSelectOptions.selectedSurveyTemplate}
      onChange={(e) => {
        const newConditions = computeNewConditions(
          {
            index: props.conditionsSelectOptions.index,
            type: props.conditionsSelectOptions.type,
            invert: props.conditionsSelectOptions.invert,
            singleSelected: props.conditionsSelectOptions.singleSelected,
            multiSelected: e,
          },
          props.conditionsSelectOptions.conditions,
          props.conditionsSelectOptions.conditionsData,
        );
        props.conditionsSelectOptions.setConditions(newConditions);
      }}
      className={classNames('w-100', {
        'mt-4': props.conditionsSelectOptions.index <= 0,
      })}
    />
  );
}
