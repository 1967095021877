import { SelectOption } from '../../../../../components/Select';
import { QueryParam } from '../../../../../utils/url';
import {
  mapSingleValueSelectToQueryParam,
  parseSingleValueSelect,
} from './SingleValueSelectFilter';

export const JOB_STAGE_QUERY_PARAM = 'job_stage';

export function parseJobStage(options: SelectOption[]) {
  return parseSingleValueSelect(JOB_STAGE_QUERY_PARAM, options);
}

export function mapJobStageToQueryParam(value?: string): QueryParam | null {
  return mapSingleValueSelectToQueryParam(JOB_STAGE_QUERY_PARAM, value);
}
