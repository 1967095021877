import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CopyButton } from '../CopyButton';
import { Size } from '../../utils/types/Size';
import styles from './styles.module.scss';

interface PropTypes {
  text: string;
  collapseWords: number;
  expandWordsStep: number;
  maxWords: number;
  expandedByDefault?: boolean;
  buttonSize?: Size;
}

function CollapseButton(props: { collapsed: boolean; onCollapse: () => void }) {
  return (
    <Button color='borderless' onClick={() => props.onCollapse()}>
      {props.collapsed ? <span>View more</span> : <span>View less</span>}
    </Button>
  );
}

function textClamp(words: string[], maxWords: number) {
  if (words.length <= maxWords) {
    return words.join(' ') + ' ';
  }

  return words.slice(0, maxWords).join(' ') + ' ... ';
}

export function CollapsableText(props: PropTypes) {
  const words = props.text.split(' ');
  const expandable = words.length > props.collapseWords;
  const [expanded, setExpanded] = useState(props.expandedByDefault);
  const [wordLimit, setWordLimit] = useState(props.collapseWords);
  const expandedWordLimit = wordLimit + props.expandWordsStep;
  return (
    <div>
      <div className='d-flex align-items-start justify-content-between gap-2h'>
        <div>
          {expanded ? (
            <span className={styles['preserve-newlines']}>{props.text}</span>
          ) : (
            <span className={styles['preserve-newlines']}>
              {textClamp(words, wordLimit)}
            </span>
          )}
          {expandable && (
            <CollapseButton
              collapsed={!expanded}
              onCollapse={() => {
                if (expanded) {
                  setWordLimit(props.collapseWords);
                  setExpanded(false);
                } else {
                  setWordLimit(Math.min(expandedWordLimit, props.maxWords));
                  if (
                    expandedWordLimit >= Math.min(props.maxWords, words.length)
                  ) {
                    setExpanded(true);
                  }
                }
              }}
            />
          )}
        </div>
        <CopyButton
          textToBeCopied={props.text}
          size={props.buttonSize}
          text=''
        />
      </div>
    </div>
  );
}
