import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionField } from '../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestionFieldType } from '../../entities/survey_engine/SurveyQuestionFieldType';
import { SurveyTargetInfo } from '../../entities/survey_engine/SurveyTargetInfo';
import { buildSurveyQuestionFieldOption } from '../survey_engine/buildSurveyQuestionFieldOption';

function buildJobSurveyQuestionFields(
  targetInfo: SurveyTargetInfo,
): SurveyQuestionField[] {
  return [
    {
      id: -1,
      type: SurveyQuestionFieldType.MultiOptions,
      positive: true,
      options: targetInfo.available_jobs.map((job) =>
        buildSurveyQuestionFieldOption(job),
      ),
    },
  ];
}

export function buildInterviewerJobSurveyQuestion(
  targetInfo: SurveyTargetInfo,
): SurveyQuestion {
  return {
    id: -1,
    title:
      'Considering the jobs you were involved-in, which ones are you giving feedback for?\n' +
      '(select all that apply)',
    fields: buildJobSurveyQuestionFields(targetInfo),
    min_options: 1,
    order: 1,
    section: {
      id: 'recruitment_process_feedback',
      name: 'Recruitment process feedback',
    },
    conditions: [],
    default_flow: false,
    mandatory: true,
    type: SurveyQuestionFieldType.MultiOptions,
  };
}
