import React from 'react';
import { Alert, AlertType } from '../../components/Alert';
import { boldFormatter } from './Formatters/boldFormatter';

interface PropTypes {
  type: AlertType;
  message?: string;
}

// Usage:
// Wrap text in ** to make it bold, e.g. "This is a **bold** message."
function FormatAlertText(props: { text: string }) {
  const formattedText = boldFormatter(props.text);

  return <>{formattedText}</>;
}

export default function FlashAlert(props: PropTypes) {
  return (
    <Alert type={props.type} clearable={true} autoClearTimeout={3000}>
      <FormatAlertText text={props.message} />
    </Alert>
  );
}
