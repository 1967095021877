import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import {
  ChannelType,
  Source,
} from '../../../../entities/applicant_tracking/Source';
import { SourceData } from '../../../../containers/applicant_tracking/Sources/util';

export class SourceService {
  public static batchCreate(
    channelType: ChannelType,
    sources: SourceData[],
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/v1/applicant_tracking/sources/batch_create`,
      snakecaseKeys({ channelType, sources }),
    );
  }

  public static show(id: number): Promise<Source> {
    return ApiService.get<Source>(`/api/v1/applicant_tracking/sources/${id}`);
  }

  public static update(
    id: number,
    name: string,
    enabledOnCareers: boolean,
    channelType: ChannelType,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/sources/${id}`,
      snakecaseKeys({ name, enabledOnCareers, channelType }),
    );
  }

  public static destroy(id: number): Promise<void> {
    return ApiService.delete<void>(`/api/v1/applicant_tracking/sources/${id}`);
  }
}
