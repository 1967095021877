import { Validate, ValidationRule } from 'react-hook-form';

export const EMPTY_STATE_EDITOR_A =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';
const EMPTY_STATE_EDITOR_B =
  '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"   ","type":"text","version":1}],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

export function validationRule(
  required: boolean,
): Partial<{ [key: string]: ValidationRule<boolean> | Validate<string> }> {
  if (!required) {
    return { required: required };
  }

  return {
    required: required,
    validate: (value: string) => hasData(value),
  };
}

export function hasData(value: string): boolean {
  return (
    value != null &&
    value !== EMPTY_STATE_EDITOR_A &&
    value !== EMPTY_STATE_EDITOR_B
  );
}
