import {
  CampaignTargetGroupData,
  TaxonomyEducationAndSeniorityLevel,
} from '@vonq/hapi-elements-types';
import { SelectOption } from '../../../../../../components/Select';

export function targetGroupFromOption(option: SelectOption): {
  id: number | string;
  description: string;
} {
  return {
    description: option.label,
    id: option.value,
  };
}

export function targetGroupFromValue(input?: {
  id: number | string;
  name: string;
}): { id: number | string; description: string } {
  if (input == null) {
    return null;
  }

  return {
    description: input.name,
    id: input.id,
  };
}

export function multiLangTargetGroupFromOption(
  option: SelectOption,
): CampaignTargetGroupData {
  return {
    description: option.label,
    vonqId: option.value,
  };
}

export function multiLangTargetGroupFromValue(
  input?: TaxonomyEducationAndSeniorityLevel,
): CampaignTargetGroupData {
  if (input == null) {
    return null;
  }

  return {
    description: input.name.find((value) => value.languageCode === 'en_GB')
      .value,
    vonqId: input.id.toString(),
  };
}
