import React, { useCallback } from 'react';
import { Select, SelectOption } from '../Select';
import classNames from 'classnames';
import styles from './styles.module.scss';
import momentTimezone from 'moment-timezone';
import { Size } from '../../utils/types/Size';

interface PropTypes {
  timezone: string;
  setTimezone: (timezone: string) => void;
  className?: string;
  titleClassName?: string;
  selectClassName?: string;
  selectSize?: Size;
  readOnly?: boolean;
}

function humanizeTimezone(timezone: string): string {
  return timezone.replace(/_/g, ' ');
}

export function TimezoneSelect(props: PropTypes) {
  const availableTimezones = momentTimezone.tz.names().map((timezone) => {
    return {
      value: timezone,
      label: humanizeTimezone(timezone),
      selected: timezone === props.timezone,
    };
  });
  const selectedTimezone = availableTimezones.find((option) => option.selected);

  const onTimezoneSelection = useCallback(
    (option: SelectOption) => {
      props.setTimezone(option.value);
    },
    [props.timezone],
  );

  return (
    <div
      data-testid='timezone_select'
      className={classNames(props.className, 'align-items-center')}
    >
      <span className={classNames(props.titleClassName)}>Timezone</span>
      <Select
        options={availableTimezones}
        size={props.selectSize || 'sm'}
        placeholder='Select timezone'
        onChange={onTimezoneSelection}
        selected={selectedTimezone}
        className={classNames(styles['timezone-select'], props.selectClassName)}
        isClearable={false}
        disabled={!!props.readOnly}
      />
    </div>
  );
}
