import classNames from 'classnames';
import React from 'react';
import { HealthBar } from '../../../../../../../components/HealthBar';
import styles from '../../TableBody/styles.module.scss';
import stylesheetExports from '../../../../../../../stylesheets/export.module.scss';

const colors = {
  0: stylesheetExports.chartRed500,
  26: stylesheetExports.chartOrange500,
  51: stylesheetExports.greenSuccess500,
};

interface PropTypes {
  overallAverage: number;
}

export function OverallAverageHealthBar(props: PropTypes) {
  return (
    <td
      className={classNames(
        styles['table-data-cell-border-bottom'],
        'ps-3 pe-4',
      )}
      style={{ verticalAlign: 'middle' }}
    >
      <HealthBar
        value={props.overallAverage * 10}
        colors={colors}
        maxValue={100}
      />
    </td>
  );
}
