import React, { useEffect, useState } from 'react';
import { JobDetails } from '../../../entities/JobDetails';
import { initialize, windowHapi } from '../../../config/vonq';
import { TokenService } from '../../../services/applicant_tracking/analytics/jobboards/TokenService';
import {
  BasketProduct,
  Product,
  Wallet,
  WindowHapi,
  WindowHapiInjector,
} from '@vonq/hapi-elements-types';
import { Organization } from '../../../entities/Organization';
import { User } from '../../../entities/User';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { JobBoardsTabUI } from './JobBoardsTabUI';

interface PropTypes {
  jobDetails: JobDetails;
  organization: Organization;
  user: User;
}

export enum JobBoardView {
  Loading,
  ErrorState,
  Marketplace,
  AdsReporting,
}

export type WindowWithHapi = WindowHapi & Window & WindowHapiInjector;

function readDb(): { [jobId: number]: BasketProduct[] } {
  return JSON.parse(localStorage.getItem('BASKET') ?? '{}');
}

function writeDb(value: { [jobId: number]: BasketProduct[] }): void {
  localStorage.setItem('BASKET', JSON.stringify(value));
}

function storeBasket(jobId: number, basket: BasketProduct[]): void {
  const db = readDb();
  db[jobId] = basket;
  writeDb(db);
}

function readBasket(jobId: number): BasketProduct[] {
  const db = readDb();
  return db[jobId];
}

export function JobBoardsTab(props: PropTypes) {
  const window = windowHapi();

  const [view, setView] = useState(JobBoardView.Loading);
  const [wallet, setWallet] = useState<Wallet>(null);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [cartAmount, setCartAmount] = useState<number>(null);

  async function injectVonqElements() {
    try {
      const token = await TokenService.get();
      if (token) {
        await initialize(
          token.token,
          props.organization.organizationInfo?.currency,
        );

        window.hapi.basket.service.clear();

        const previousBasket = readBasket(props.jobDetails.id);
        if (previousBasket) {
          window.hapi.basket.state.productsMeta = previousBasket;
        }

        // @ts-expect-error the vonq documentation is not updated with the onChange method.
        await window.hapi.basket.state.productsMeta.onChange(
          (v: BasketProduct[]) => {
            storeBasket(props.jobDetails.id, v);
          },
        );

        // @ts-expect-error the vonq documentation is not updated with the onChange method.
        await window.hapi.basket.state.products.onChange((v: Product[]) => {
          setSelectedProducts(v);
        });

        // @ts-expect-error the vonq documentation is not updated with the onChange method.
        await window.hapi.basket.state.totalInWalletCurrency.onChange(
          (v: number) => setCartAmount(v),
        );

        // @ts-expect-error the vonq documentation is not updated with the onChange method.
        await window.hapi.wallet.state.wallet.onChange((w: Wallet) => {
          if (w && wallet == null) {
            setWallet(w);
            setView(JobBoardView.Marketplace);
          }
        });
      } else {
        setView(JobBoardView.ErrorState);
      }
    } catch (e: any) {
      setView(JobBoardView.ErrorState);
    }
  }

  useEffect(() => {
    (async () => {
      const callback = async () => {
        await injectVonqElements();
        removeEventListener('hapi:load:injector', callback);
      };

      if (window.hapiInjector == null) {
        window.addEventListener('hapi:load:injector', callback);
      } else {
        await injectVonqElements();
      }
    })();
  }, []);

  if (view === JobBoardView.Loading) {
    return <LoadingSpinner />;
  }

  return (
    <JobBoardsTabUI
      cartAmount={cartAmount}
      jobDetails={props.jobDetails}
      organization={props.organization}
      selectedProducts={selectedProducts}
      user={props.user}
      view={view}
      wallet={wallet}
      window={window}
      setView={setView}
      setWallet={setWallet}
    />
  );
}
