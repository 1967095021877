import { DateFilter } from '../../../components/DateRangePicker';
import { ApiService } from '../../ApiService';
import { HiringManagerAnalytics } from '../../../entities/candidate_experience/HiringManagerAnalytics';

export class HiringManagerAnalyticsService {
  public static get(
    filter_selector: string,
    job_names: string[],
    department_names: string[],
    benchmark_type: string,
    dateRange: DateFilter,
  ): Promise<HiringManagerAnalytics> {
    return ApiService.get<HiringManagerAnalytics>(
      '/api/candidate_experience/hiring_manager_analytics',
      {
        filter_selector,
        job_names,
        department_names,
        benchmark_type,
        start_date: dateRange.start.toJSON(),
        end_date: dateRange.end.toJSON(),
        date_range: dateRange.range,
      },
    );
  }
}
