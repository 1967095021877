import React from 'react';
import { IconButton } from '../../../../../../../../components/IconButton';

interface PropTypes {
  previewUrl: string;
  classNames?: string;
}

export function ApplicationFormPreviewButton(props: PropTypes) {
  return (
    <IconButton
      iconName={'bi-box-arrow-up-right'}
      color={'secondary'}
      buttonClass={props.classNames}
      buttonText={'Preview'}
      onClick={() =>
        window.open(props.previewUrl, '_blank', 'noopener,noreferrer')
      }
    />
  );
}
