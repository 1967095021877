import React from 'react';
import { EmptyState } from '../../../../components/EmptyState';
import { Button } from 'reactstrap';

interface PropTypes {
  setAvailabilityDefined: (b: boolean) => void;
  isCalendarConnected: boolean;
  cronofyUserAuthLink: string;
}

export function AvailabilityEmptyState(props: PropTypes) {
  return (
    <>
      {props.isCalendarConnected ? (
        <AvailabilityNotDefinedEmptyState
          setAvailabilityDefined={props.setAvailabilityDefined}
        />
      ) : (
        <CalendarDisconnectedEmptyState
          cronofyUserAuthLink={props.cronofyUserAuthLink}
        />
      )}
    </>
  );
}

function CalendarDisconnectedEmptyState(props: {
  cronofyUserAuthLink: string;
}) {
  return (
    <EmptyState
      title='No availability or calendar connected yet'
      text={
        'Define your availability for interview scheduling. ' +
        'To set the available slots you first need to connect your calendar.'
      }
      actionButton={
        <Button
          className='mt-4'
          size='lg'
          color='primary'
          type='submit'
          onClick={() => (window.location.href = props.cronofyUserAuthLink)}
        >
          Connect Calendar
        </Button>
      }
    />
  );
}

function AvailabilityNotDefinedEmptyState(props: {
  setAvailabilityDefined: (b: boolean) => void;
}) {
  return (
    <EmptyState
      title='No availability defined yet'
      text={
        'Define your availability for interview scheduling. ' +
        'If no availability is set, all open slots on your calendar will be considered.'
      }
      actionButton={
        <Button
          className={'mt-4'}
          color={'primary'}
          type={'submit'}
          onClick={() => props.setAvailabilityDefined(true)}
        >
          Define Availability
        </Button>
      }
    />
  );
}
