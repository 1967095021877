import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { DeprecatedPanel } from '../../../../../components/DeprecatedPanel';
import { SharedTablePanel } from '../../../../../components/survey_engine/SharedTablePanel';
import { QuestionAverage } from '../../../../../entities/quality_of_hire/QuestionAverage';
import { REPORTING_SECTION_CLASS } from '../../../../../utils/pdfHandler';
import { TableBody } from './TableBody';

interface PropTypes {
  questionAverages: QuestionAverage[];
}

const columns = [
  {
    key: 'category',
    label: 'Category',
    type: 'string',
    tooltipText:
      'Categories covered by the questions included in the Manager and Employee surveys.',
  },
  { key: 'hiring_manager_avg', label: 'Hiring Manager AVG', type: 'number' },
  { key: 'employee_avg', label: 'Employee AVG', type: 'number' },
  { key: 'overall_avg', label: 'Overall AVG', type: 'number' },
  { key: 'overall_avg_bar', label: '', type: 'string' },
];

export function SecondRow(props: PropTypes) {
  return (
    <Row className={classNames('gy-4 mb-3', REPORTING_SECTION_CLASS)}>
      <Col sm='12' lg='12'>
        <DeprecatedPanel additionalInnerContainerClassNames={'pb-4'}>
          <div className='mb-2 fs-2 fw-bold ps-4 pe-4 pt-4'>
            Onboarding Breakdown
          </div>
          <SharedTablePanel
            columns={columns}
            tableBody={<TableBody questionAverages={props.questionAverages} />}
          />
        </DeprecatedPanel>
      </Col>
    </Row>
  );
}
