import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { SurveyRequest } from '../../reference_check/entities/SurveyRequest';
import { ExportsService } from '../../services/ReferenceCheck/ExportsService';
import { Application } from '../../entities/Application';
import { triggerDownload } from '../../utils/triggerDownload';
import { LoadingSpinnerIcon } from '../../components/LoadingSpinnerIcon';

interface PropTypes {
  application: Application;
  surveyRequest: SurveyRequest;
}

export function ExportButton(props: PropTypes) {
  const hasFilledSurvey =
    props.surveyRequest != undefined &&
    props.surveyRequest.referrals.some((referral) =>
      referral.surveys.some((survey) => survey.status === 'completed'),
    );

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setLoading(true);

    const blob = await ExportsService.exportSuccessReport(props.application.id);

    triggerDownload(
      blob,
      `${props.application.candidate.name
        .toLowerCase()
        .replace(' ', '_')}_success_report.pdf`,
    );

    setLoading(false);
  }, []);

  return (
    <Button
      type='button'
      color='secondary'
      disabled={!hasFilledSurvey || loading}
      onClick={handleClick}
    >
      {loading ? (
        <LoadingSpinnerIcon className='me-2' />
      ) : (
        <i
          className='bi bi-download me-2'
          style={{ width: '1em', height: '1em', top: 0 }}
        />
      )}
      Export PDF
    </Button>
  );
}
