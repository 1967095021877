import React from 'react';
import { FormGroup } from 'reactstrap';
import { MultiSelect } from 'react-multi-select-component';
import multiSelectStyles from '../../../../../../components/LabelledMultiSelect/styles.module.scss';
import { Screening } from '../../../../../../entities/Screening';
import { Label } from '../Label';
import classNames from 'classnames';

export interface SelectOption {
  value: string;
  label: string;
  key?: string;
  disabled?: boolean;
}

function removeDuplicates(arr: string[]): string[] {
  return Array.from(new Set(arr));
}

function rootScreeningIds(
  screenings: Screening[],
  selectedOptions: SelectOption[],
): string[] {
  const selectedScreenings = screenings.filter((s) =>
    selectedOptions.find((o) => o.value == s.id),
  );

  const rootScreeningsHash = new Map<string, Screening>(
    selectedScreenings.map((s) => [s.id, s]),
  );

  selectedScreenings
    .flatMap((s) => s.includes || [])
    .forEach((id) => rootScreeningsHash.delete(id)); // remove child screenings

  return Array.from(rootScreeningsHash.values()).map((s) => s.id);
}

function childScreeningIds(
  screenings: Screening[],
  rootIds: string[],
): string[] {
  const rootScreenings = screenings.filter((s) => rootIds.includes(s.id));

  const childrenIds = rootScreenings.flatMap((s) => s.includes || []); // get all child screenings
  return removeDuplicates(childrenIds);
}

function translateSelectedOptions(
  screenings: Screening[],
  options: SelectOption[],
  selectedOptions: SelectOption[],
): { options: SelectOption[]; selected: SelectOption[] } {
  const rootIds = rootScreeningIds(screenings, selectedOptions);
  const childIds = childScreeningIds(screenings, rootIds);
  const selectedIds = [...rootIds, ...childIds];

  return {
    options: options.map((o) => ({
      label: o.label,
      value: o.value,
      disabled: childIds.includes(o.value),
    })),
    selected: options.filter((o) => selectedIds.includes(o.value)),
  };
}

export function ChecksFormGroup(props: {
  screenings: Screening[];
  options: SelectOption[];
  selectedOptions: SelectOption[];
  disabled: boolean;
  isLoading: boolean;
  validated: boolean;
  onSelectedScreeningsChanged: (checks: SelectOption[]) => void;
}) {
  const { options, selected } = translateSelectedOptions(
    props.screenings,
    props.options,
    props.selectedOptions,
  );

  return (
    <FormGroup check className='mt-3'>
      <Label for='bgc-checks' check size='sm' isRequired>
        Background Check
      </Label>
      <MultiSelect
        options={options}
        value={selected}
        onChange={(elems) => {
          const rootIds = rootScreeningIds(
            props.screenings,
            elems.filter((e) =>
              options.some((o) => !o.disabled && o.value == e.value),
            ),
          );

          props.onSelectedScreeningsChanged(
            elems.filter((e) => rootIds.includes(e.value)),
          );
        }}
        labelledBy='Select Background Checks'
        isLoading={props.isLoading}
        disabled={props.disabled}
        className={classNames('form-control p-0', multiSelectStyles.rmsc, {
          'is-invalid': props.validated && !props.selectedOptions?.length,
        })}
      />
    </FormGroup>
  );
}
