import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { InputFormField } from '../../../../../../../components/InputFormField';
import { FormFieldHeader } from '../../../../../../../components/FormFieldHeader';

interface PropTypes {
  formRegister: UseFormRegister<JobRequisitionDto>;
}

export function SalaryRange(props: PropTypes) {
  const required = true;

  return (
    <Col xs={3}>
      <FormFieldHeader
        fieldName='Annual Salary Range'
        isRequired={required}
        classNames='fw-semibold'
      />
      <Row className='gx-2h'>
        <Col xs={6}>
          <InputFormField
            hideHeader
            type='number'
            registerReturn={props.formRegister('salaryRangeFrom', {
              required: required,
            })}
            classNames='fs-5'
            placeholder='From'
            isRequired={required}
            fontSize='fs-5'
            headerClassNames='fw-semibold mb-0'
          />
        </Col>
        <Col xs={6}>
          <InputFormField
            hideHeader
            type='number'
            registerReturn={props.formRegister('salaryRangeTo', {
              required: required,
            })}
            classNames='fs-5'
            placeholder='To'
            isRequired={required}
            fontSize='fs-5'
            headerClassNames='fw-semibold mb-0'
          />
        </Col>
      </Row>
    </Col>
  );
}
