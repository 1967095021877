import classNames from 'classnames';
import React from 'react';
import stylesheetExports from '../../../../stylesheets/export.module.scss';
import { Table } from 'reactstrap';
import { HealthBar } from '../../../../components/HealthBar';
import { SelectOption } from '../../../../components/candidate_experience/Filters/filterState';
import { DEFAULT_RATING_LEGEND } from '../../../../components/survey_engine/RatingLegend';
import { Trend } from '../../../../components/survey_engine/Trend';
import { Chip, ChipColor } from '../../../../components/Chip';
import { TableHeader } from '../../../../components/TableHeader';
import { TableEntry } from '.';

export interface PropTypes {
  entries: TableEntry[];
  benchmarkType: SelectOption;
  textColumnHeader?: string;
  tableClassName?: string;
  questionTitleValueClassName?: string;
  questionAvgValueColor?: string;
  displayAverageTrend?: boolean;
  /** Value to multiply `TableEntry#value` by, before displaying. */
  multiplier?: number;
  /** True if the `TableEntry#value` should display as a percentage. */
  displayPercentage?: boolean;
  /**
   * Color to use. If not provided, it will be computed from the value.
   */
  fillColor?: string;
  /** True if benchmark column is displayed. */
  enableBenchmark?: boolean;
}

function mapColors(fillColor?: string) {
  const colors: { [k: number]: string } = {};

  DEFAULT_RATING_LEGEND.forEach((item) => {
    colors[item.minScore] = fillColor || item.color;
  });

  return colors;
}

export function TablePanelContent(props: PropTypes) {
  const hasBenchmark =
    props.enableBenchmark && props.benchmarkType.value !== 'best_practice';

  const multiplier = props.multiplier || 1.0;
  const colors = mapColors(props.fillColor);
  const tooltipText =
    'Average score for each question according to the applied filters';

  return (
    <Table
      className={classNames(
        'align-middle fs-5 text-primary',
        props.tableClassName,
      )}
    >
      <thead className='fw-semibold'>
        <tr>
          <th
            scope='col'
            className='fw-bold'
            style={{
              color: stylesheetExports.darkSideBar,
              width: '40%',
            }}
          >
            {props.textColumnHeader}
          </th>
          <th
            scope='col'
            className='text-center text-nowrap fw-bold'
            style={{
              color: stylesheetExports.darkPrimary,
            }}
          >
            {hasBenchmark && props.benchmarkType.label}
          </th>
          <th
            scope='col'
            className='text-center text-nowrap fw-bold'
            style={{
              color: stylesheetExports.darkPrimary,
            }}
          >
            <TableHeader label='AVG Score' tooltipText={tooltipText} />
          </th>
          <th scope='col' className='w-30 fw-bold' />
          {props.displayAverageTrend && (
            <th
              scope='col'
              className='text-center'
              style={{
                color: stylesheetExports.darkPrimary,
              }}
            >
              Trend
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {props.entries.map((question) => (
          <tr key={question.text} className='label'>
            <td
              className={classNames(props.questionTitleValueClassName)}
              style={{
                color: stylesheetExports.darkPrimary,
              }}
            >
              {question.text}
            </td>
            <td
              className='fw-semibold text-center'
              data-testid='benchmark-question-avg'
              style={{
                color:
                  props.questionAvgValueColor || stylesheetExports.darkPrimary,
              }}
            >
              {hasBenchmark &&
                (question.benchmarkAverage
                  ? props.displayPercentage
                    ? (question.benchmarkAverage * multiplier).toFixed(0) + '%'
                    : (question.benchmarkAverage * multiplier).toFixed(1)
                  : '—')}
            </td>
            <td className='fw-bold text-center'>
              <Chip
                className={classNames('me-2 px-3 py-2')}
                color={ChipColor.BlueLight}
              >
                {props.displayPercentage
                  ? (question.average * multiplier).toFixed(0) + '%'
                  : (question.average * multiplier).toFixed(1)}
              </Chip>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <HealthBar
                value={question.average * 100}
                colors={colors}
                maxValue={100}
              />
            </td>
            {props.displayAverageTrend && (
              <td className='fw-semibold text-center col-md-1 col-sm-2 '>
                <div>
                  <Trend trend={question.averageTrend} />
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
