import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import { IconButton } from '../IconButton';

interface PropTypes {
  moreFilters: boolean;
  appliedHiddenFilters?: number;
  onChange: (newExpanded: boolean) => void;
}

function MoreFiltersText(props: {
  moreFilters: boolean;
  appliedHiddenFilters?: number;
}) {
  if (props.moreFilters) {
    return <>Less Filters</>;
  }

  return (
    <>
      More Filters
      {props.appliedHiddenFilters != 0 && (
        <span className={classNames('ms-2', styles.numberCircle)}>
          {props.appliedHiddenFilters}
        </span>
      )}
    </>
  );
}

export function MoreFilters(props: PropTypes) {
  return (
    <IconButton
      iconName={'bi-filter'}
      color='borderless'
      buttonClass={styles.moreFiltersButton}
      buttonText={
        <MoreFiltersText
          moreFilters={props.moreFilters}
          appliedHiddenFilters={props.appliedHiddenFilters}
        />
      }
      onClick={() => props.onChange(!props.moreFilters)}
    />
  );
}
