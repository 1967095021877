import React, { useContext, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { SelectOption } from '../../../../../../components/Select';
import { DepartmentService } from '../../../../../../services/applicant_tracking/DepartmentService';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { SetupJobContext } from '../../index';

interface PropTypes {
  formControl: Control<CreateJobDto>;
}

async function loadDepartmentOptions(): Promise<SelectOption[]> {
  return (await DepartmentService.list()).map((dep) => ({
    value: dep.id.toString(),
    label: dep.name,
  }));
}

export function DepartmentInput(props: PropTypes) {
  const setupJobContext = useContext(SetupJobContext);
  const required = true;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [departmentOptions, setDepartmentOptions] = useState<SelectOption[]>(
    [],
  );

  useEffect(() => {
    loadDepartmentOptions()
      .then(setDepartmentOptions)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <LabelledSelectFormHook<CreateJobDto>
          label={'Department'}
          placeholder={'Select department'}
          formHookContext={{
            controllerName: 'departmentId',
            formControl: props.formControl,
            required: required,
          }}
          options={departmentOptions}
          disabled={setupJobContext.jobRequisition != null}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
}
