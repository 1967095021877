import React from 'react';
import { Control, UseFormRegisterReturn } from 'react-hook-form';
import { PhoneInput } from '../PhoneInput';
import { FormFieldHeader } from '../FormFieldHeader';
import { DefaultFormValues } from 'react-phone-number-input/react-hook-form';

interface PropTypes<E> {
  disabled: boolean;
  fieldName: string;
  required?: boolean;
  controllerName: string;
  placeholder?: string;
  hideHeader?: boolean;
  control: Control<E, any>;
  registerReturn: UseFormRegisterReturn;
  className?: string;
}

export function PhoneInputFormField<E>(props: PropTypes<E>) {
  const hideHeader = props.hideHeader ?? false;

  return (
    <>
      {!hideHeader && (
        <FormFieldHeader
          fieldName={props.fieldName}
          isRequired={props.required}
        />
      )}
      <PhoneInput
        name={props.controllerName}
        placeholder={props.placeholder}
        required={props.required}
        control={props.control as unknown as Control<DefaultFormValues, any>}
        disabled={props.disabled}
        formRegisterReturn={props.registerReturn}
        className={props.className}
      />
    </>
  );
}
