import {
  Campaign,
  EmploymentType,
  SalaryPeriod,
  Wallet,
} from '@vonq/hapi-elements-types';
import { WindowWithHapi } from '..';
import { JobDetails } from '../../../../entities/JobDetails';
import { Organization } from '../../../../entities/Organization';
import { User } from '../../../../entities/User';
import { isEmptyString } from '../../../../utils/isEmptyString';
import { JobPostingDetailsForm } from '../JobBoardsTabUI/JobPostingDetails';

function mapIndustryAndCategory(data: {
  id: number | string;
  description: string;
}) {
  return { description: data.description?.trim() };
}

function isSalaryRangeDefined(form: JobPostingDetailsForm) {
  return (
    !isEmptyString(form.salaryRangeFrom) || !isEmptyString(form.salaryRangeTo)
  );
}

function salaryIndication(form: JobPostingDetailsForm) {
  if (
    !isSalaryRangeDefined(form) ||
    !form.salaryCurrency ||
    !form.salaryPeriod
  ) {
    return {};
  }

  // It is possible to set on our form just SalaryFrom or SalaryTo
  // Vonq API requires the SalaryTo to be set
  // So, in case we just have one of them, we should "switch" them
  let salaryFrom: string = form.salaryRangeFrom?.trim();
  let salaryTo: string = form.salaryRangeTo?.trim();

  if (isEmptyString(salaryTo)) {
    salaryTo = salaryFrom;
    salaryFrom = null;
  }

  return {
    period: form.salaryPeriod as SalaryPeriod,
    range: {
      from: salaryFrom,
      to: salaryTo,
      currency: form.salaryCurrency,
    },
  };
}

export async function createCampaign(
  form: JobPostingDetailsForm,
  jobDetails: JobDetails,
  organization: Organization,
  user: User,
  wallet: Wallet,
  window: WindowWithHapi,
): Promise<Campaign> {
  const campaignForm = {
    companyId: organization.id.toString()?.trim(),
    currency: wallet.balance.currency,
    recruiterInfo: {
      id: user.id,
      name: user.name?.trim(),
      emailAddress: user.email?.trim(),
    },
    campaignName: form.campaignName?.trim(),
    postingDetails: {
      title: form.title?.trim(),
      description: form.editorStateHtml?.trim(),
      organization: {
        name: organization.name?.trim(),
        companyLogo: organization.organizationInfo?.logoUrl?.trim(),
      },
      workingLocation: {
        addressLine1: form.address1?.trim(),
        addressLine2: form.address2?.trim(),
        postcode: form.postcode?.trim(),
        city: form.city?.trim(),
        country: form.country?.trim(),
        allowsRemoteWork: jobDetails.locationType !== 'onsite',
      },
      contactInfo: {
        name: form.contactName?.trim(),
        phoneNumber: form.contactPhone?.trim(),
        emailAddress: form.contactEmail?.trim(),
      },
      yearsOfExperience: form.yearsOfExperience,
      employmentType: EmploymentType.permanent,
      weeklyWorkingHours: {
        from: form.weeklyHoursFrom?.trim(),
        to: form.weeklyHoursTo?.trim(),
      },
      salaryIndication: salaryIndication(form),
      jobPageUrl: form.jobPostUrl?.trim(),
      applicationUrl: form.applicationFormUrl?.trim(),
    },
    targetGroup: {
      educationLevel: [form.educationLevel],
      seniority: [form.seniority],
      industry: [mapIndustryAndCategory(form.industry)],
      jobCategory: [mapIndustryAndCategory(form.jobCategory)],
    },
    orderedProducts:
      // @ts-expect-error the vonq documentation is not updated with the value method.
      window.hapi.campaign.state.campaignForm.value.orderedProducts,
    labels: { jobId: jobDetails.id.toString()?.trim() },
    paymentMethod:
      window.hapi.orderJourney.utils.paymentMethodKeys.directCharge,
    walletId: wallet.id?.trim(),
  };

  // @ts-expect-error the vonq documentation is not updated with the value method.
  return await window.hapi.campaign.api.orderCampaign(campaignForm);
}
