import { HighlightType } from '../containers/InterviewPage/Entities/HighlightType';
import { InterviewSecret } from '../entities/InterviewSecret';
import { TimestampMarker } from '../entities/TimestampMarker';

function isScorecardAttribute(
  selectedHighlightType: string,
  threadData: TimestampMarker,
): boolean {
  if (selectedHighlightType !== 'scorecard_attribute') return false;

  return (
    threadData.kind === 'scorecard_attribute' ||
    threadData.kind === 'automatic_scorecard_attribute'
  );
}

function isReaction(
  selectedHighlightType: string,
  threadData: TimestampMarker,
): boolean {
  if (selectedHighlightType !== 'reaction') return false;

  return threadData.kind === 'flag' || threadData.kind === 'thumbs_up';
}

export function acceptTimestampMarker(
  selectedHighlightType: HighlightType,
  threadData: TimestampMarker,
): boolean {
  if (
    selectedHighlightType === 'all' ||
    selectedHighlightType === threadData.kind ||
    threadData.kind === 'interview_secret'
  ) {
    return true;
  }

  if (
    isReaction(selectedHighlightType, threadData) ||
    isScorecardAttribute(selectedHighlightType, threadData)
  ) {
    return true;
  }

  return false;
}

export function hideMarkersInRedactedRegion(
  secrets: InterviewSecret[],
  threadData: TimestampMarker,
): boolean {
  return (
    secrets.find(
      (secret) =>
        threadData.offset_ms >= secret.start_time &&
        threadData.offset_ms <= secret.end_time,
    ) === undefined
  );
}
