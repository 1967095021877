import React, { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { Col } from 'reactstrap';
import { JobRequisitionDto } from '../../../../../../../services/applicant_tracking/JobRequisitionService/CreateJobRequisitionDto';
import { LabelledSelectFormHook } from '../../../../../../../components/LabelledSelectFormHook';
import { Location } from '../../../../../../../entities/applicant_tracking/Location';
import { LocationService } from '../../../../../../../services/applicant_tracking/LocationService';

interface PropTypes {
  formControl: Control<JobRequisitionDto>;
}

export function Location(props: PropTypes) {
  const required = true;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    LocationService.list().then((locations: Location[]) => {
      setOptions(
        locations.map((location) => ({
          label: location.name,
          value: location.id.toString(),
        })),
      );
    });
  }, []);

  return (
    <Col xs={3}>
      <LabelledSelectFormHook<JobRequisitionDto>
        label='Location'
        placeholder='Select location'
        formHookContext={{
          controllerName: 'locationId',
          formControl: props.formControl,
          required: required,
        }}
        options={options}
      />
    </Col>
  );
}
