import classNames from 'classnames';
import React from 'react';
import SuccessEvaluationScoreImage from '../../../../../../../images/training-success-evaluation-score-modal.svg';
import styles from '../styles.module.scss';
import { displayEvaluationAccuracy } from '../utils/displayEvaluationAccuracy';

interface PropTypes {
  evaluationAccuracy?: number;
}

export function SuccessfulEvaluationScoreBody(props: PropTypes) {
  const accuracyMessage = displayEvaluationAccuracy(props.evaluationAccuracy);

  return (
    <>
      <div className='text-center'>
        <img
          className={classNames('text-center', styles.illustration)}
          src={SuccessEvaluationScoreImage}
        />
      </div>
      <div className='mt-4 text-center'>
        <span>Congrats! You have achieved</span>
        <br />
        <strong>{accuracyMessage} Accuracy</strong> on your answers.
      </div>
    </>
  );
}
