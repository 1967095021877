import React from 'react';
import { JobRequisition } from '../../../../../../entities/v1/applicant_tracking/JobRequisition';
import { User } from '../../../../../../entities/User';
import { OwnerOrAdminActions } from './OwnerOrAdminActions';
import { AlertMessage } from '../../../../../../components/AlertMessage';
import { AlertObject } from '../../../../../../components/Alert';

interface PropTypes {
  currentUser: User;
  jobRequisition: JobRequisition;
  jobRequisitionV2FfEnabled: boolean;
  setAlertMessage: (message: AlertObject) => void;
  reloadJobRequisition: () => void;
}

export function DeclinedAction(props: PropTypes) {
  const isOwnerOrAdmin =
    props.jobRequisition.ownerUserId === props.currentUser.id ||
    props.currentUser.isAdmin;

  return (
    <div className='d-flex align-items-center'>
      {isOwnerOrAdmin ? (
        <OwnerOrAdminActions
          currentUser={props.currentUser}
          jobRequisition={props.jobRequisition}
          setAlertMessage={props.setAlertMessage}
          jobRequisitionV2FfEnabled={props.jobRequisitionV2FfEnabled}
          reloadJobRequisition={props.reloadJobRequisition}
        />
      ) : (
        <AlertMessage
          className='bg-light-danger fs-5'
          icon={{ name: 'bi-info-circle' }}
          text='This job requisition has been declined.'
        />
      )}
    </div>
  );
}
