import React, { useState, useCallback, useEffect } from 'react';
import { SurveyQuestionAnswer } from '../../../../../../entities/survey_engine/SurveyQuestionAnswer';
import { SurveyQuestionField } from '../../../../../../entities/survey_engine/SurveyQuestionField';
import { SurveyQuestion } from '../../../../../../entities/survey_engine/SurveyQuestion';
import { Survey } from '../../../../../../entities/survey_engine/Survey';
import { hasAnsweredQuestion } from '../../../../../../utils/training/hasAnsweredQuestion';
import { SurveyQuestionAnswerService } from '../../../../../../services/survey_engine/SurveyQuestionAnswerService';
import { BooleanField } from './fields/BooleanField';
import { MultiOptionsField } from './fields/MultiOptionField';

interface PropTypes {
  chipClassName?: string;
  question: SurveyQuestion;
  questionField: SurveyQuestionField;
  surveyAnswer: SurveyQuestionAnswer;
  onUpdateSurvey: (survey: Survey) => void;
  onUpdateSurveyAnswers: (surveyAnswers: SurveyQuestionAnswer[]) => void;
}

export function SurveyField(props: PropTypes) {
  const [answer, setAnswer] = useState<SurveyQuestionAnswer>(
    props.surveyAnswer,
  );

  const fieldAnswer = props.surveyAnswer?.fields?.find(
    (f) => f.field_id === props.questionField.id,
  );

  useEffect(() => {
    if (!hasAnsweredQuestion(answer, props.question, true)) return;

    SurveyQuestionAnswerService.answer(
      props.surveyAnswer.survey_id,
      answer,
    ).then((updatedSurvey: Survey) => {
      props.onUpdateSurvey(updatedSurvey);
      props.onUpdateSurveyAnswers(updatedSurvey.answers);
    });
  }, [answer]);

  const handleOnChangeAnswer = useCallback(
    (fieldId: number, value: string, optionIds?: number[]) => {
      const fields = answer.fields.filter((f) => f.field_id !== fieldId);
      setAnswer({
        question_id: props.question.id,
        survey_id: answer.survey_id,
        fields: fields.concat({
          field_id: fieldId,
          value,
          option_ids: optionIds,
        }),
      });
    },
    [answer],
  );

  switch (props.questionField.type) {
    case 'boolean':
      return (
        <BooleanField
          field={props.questionField}
          fieldAnswer={fieldAnswer}
          chipClassName={props.chipClassName}
          onChangeAnswer={handleOnChangeAnswer}
        />
      );
    case 'multi_options':
      return (
        <MultiOptionsField
          field={props.questionField}
          fieldAnswer={fieldAnswer}
          onChangeAnswer={handleOnChangeAnswer}
        />
      );
    default:
      return null;
  }
}
