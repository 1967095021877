import React, { useState } from 'react';
import { Card } from '../../../../components/Card';
import { FormErrorMessage } from '../../../../components/FormErrorMessage';
import { useForm } from 'react-hook-form';
import { OfferLetterTemplateService } from '../../../../services/v1/applicant_tracking/OfferLetterTemplateService';
import { getRedirectUriQueryParam } from '../../../../utils/url';
import { PageHeader } from '../../../survey_engine/SurveyTemplates/PageHeader';
import { handleFormErrors } from '../../../../services/ApiService/handler';
import { UpsertOfferLetterTemplateDto } from '../components/UpsertEmailTemplateDto';
import { KnownKeys, set } from '../../../../utils/localArchive';
import { Body } from '../components/Body';

export const REDIRECT_URI =
  getRedirectUriQueryParam() ?? '/organization_settings/offer_letter_templates';

export default function NewOfferLetterTemplatePage() {
  const [html, setHtml] = useState<string>(null);

  const {
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
    handleSubmit,
    register,
    setError,
  } = useForm<UpsertOfferLetterTemplateDto>({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(
    async (
      data: UpsertOfferLetterTemplateDto,
      _event: React.SyntheticEvent<any, SubmitEvent>,
    ) => {
      try {
        await OfferLetterTemplateService.create({
          ...data,
          templateHtml: html,
        });

        window.location.href = REDIRECT_URI;
      } catch (e: unknown) {
        handleFormErrors(e, setError);
      }
    },
  );

  function handlePreview() {
    set(KnownKeys.OfferLetterTemplate, { ...getValues(), templateHtml: html });

    window.open(
      '/applicant_tracking/offer_letter_templates/preview',
      '_blank',
      'noopener,noreferrer',
    );
  }

  return (
    <div className='container'>
      {errors.name?.message && (
        <FormErrorMessage
          error={errors}
          errorMessage={{
            title: `${errors.name?.message}`,
            detail: 'Please change the offer letter template name',
          }}
        />
      )}
      <PageHeader title='Add Offer Letter' backUrl={REDIRECT_URI} />
      <Card>
        <Body
          control={control}
          errors={errors}
          isValid={isValid}
          showBasedOnTemplate={true}
          onCancel={() => (window.location.href = REDIRECT_URI)}
          onPreview={handlePreview}
          getValues={getValues}
          setValue={setValue}
          onSubmit={onSubmit}
          register={register}
          setHtml={setHtml}
        />
      </Card>
    </div>
  );
}
