import { ApiService } from '../../../services/ApiService';
import { SurveyRequest } from '../../entities/SurveyRequest';
import { SurveyRequestInfo } from '../../entities/SurveyRequestInfo';

export class SurveyRequestService {
  public static get(applicationId: number): Promise<SurveyRequest> {
    return ApiService.get<SurveyRequest>(
      `/api/reference_check/applications/${applicationId}/survey_requests`,
    );
  }

  public static create(applicationId: number): Promise<SurveyRequest> {
    return ApiService.post<SurveyRequest>(
      `/api/reference_check/applications/${applicationId}/survey_requests`,
      {},
    );
  }

  public static cancel(id: number): Promise<SurveyRequestInfo> {
    return ApiService.put<SurveyRequestInfo>(
      `/api/reference_check/survey_requests/${id}/cancel`,
    );
  }
}
