import React from 'react';
import { Control } from 'react-hook-form';
import { CustomizationStageToggle } from '../CustomizationStageToggle';

interface PropTypes {
  disabled: boolean;
  formControl: Control<any>;
}

export function OfferToggleInput(props: PropTypes) {
  return (
    <CustomizationStageToggle
      controllerName='offerEnabled'
      disabled={props.disabled}
      formControl={props.formControl}
      title='Do you want to send an Offer in this stage?'
      subTitle='Offer enablement will be available for only one stage per workflow.'
    />
  );
}
