import React from 'react';
import { components, ValueContainerProps } from 'react-select';

export function ValueContainer(props: ValueContainerProps) {
  return (
    <components.ValueContainer {...props}>
      {props.hasValue && props.isMulti
        ? `${props.getValue().length} selected`
        : props.children}
    </components.ValueContainer>
  );
}
