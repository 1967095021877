import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { NoteText } from '../../../../components/NoteText';
import { formatTime } from '../../../../utils/timeFormat';
import styles from './styles.module.scss';
import highlightThumbnailPlaceholder from '../../../../images/placeholders/highlight-thumbnail.svg';
import noVideoThumbnailPlaceholder from '../../../../images/placeholders/screenloop-video-placeholder-v2.svg';
import classNames from 'classnames';
import { Media } from '../../../../entities/Media';
import { MediaType } from '../../../../entities/MediaType';
import { VideoModal } from '../../../../components/VideoModal';

interface PropTypes {
  interviewMarkerOffset: number;
  interviewStartDate?: string;
  interviewerNames: string[];
  thumbnailUrl?: string;
  title: string;
  type: string;
  media: Media;
}

interface CardBodyPropTypes {
  media: Media;
  thumbnailUrl?: string;
  interviewMarkerOffset: number;
  interviewStartDate?: string;
  onClick: () => void;
}

interface CardLegendPropTypes {
  iconClass?: string;
  interviewerNames: string[];
  text: string;
  onClick: () => void;
}

function getIconClassByType(type: string) {
  switch (type) {
    case 'automatic_scorecard_attribute':
      return 'bi bi-record-circle text-info';
    case 'flag':
      return 'bi bi-flag-fill text-danger';
    case 'note':
      return 'bi bi-chat-right-text-fill text-secondary';
    case 'scorecard_attribute':
      return 'bi bi-pin-fill text-pin';
    case 'thumbs_up':
      return 'bi bi-hand-thumbs-up-fill text-success';
    default:
      return undefined;
  }
}

function getThumbnailImage(thumbnailUrl: string, media: Media) {
  if (media.type === MediaType.Audio) {
    return noVideoThumbnailPlaceholder;
  }

  return thumbnailUrl || highlightThumbnailPlaceholder;
}

function CardBody(props: CardBodyPropTypes) {
  const date = new Date(props.interviewStartDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <div
      className={`align-self-center ${styles.thumbnail}`}
      onClick={props.onClick}
    >
      <img
        src={getThumbnailImage(props.thumbnailUrl, props.media)}
        className={`img-fluid rounded-5`}
      />
      <i className={`bi bi-play-fill ${styles.play}`} />
      <div className={`text-white rounded-pill ${styles.date}`}>
        <span className='fs-6'>{date}</span>
      </div>
      <div className={`text-white rounded-pill ${styles.time}`}>
        <span className='d-flex align-items-center fs-6'>
          {formatTime(props.interviewMarkerOffset)}
        </span>
      </div>
    </div>
  );
}

function CardLegend(props: CardLegendPropTypes) {
  const ref = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (el === null) return;

    setIsOverflowing(el.offsetWidth < el.scrollWidth);
  }, [props.text]);

  return (
    <div className='px-3'>
      <div>
        <h4
          className={`mt-3 label fw-bold text-nowrap overflow-hidden ${styles.title}`}
          onClick={props.onClick}
          ref={ref}
        >
          {props.iconClass && (
            <i className={classNames(props.iconClass, 'pe-2')} />
          )}
          <NoteText text={props.text} />
        </h4>
        {isOverflowing && (
          <Tooltip
            target={ref.current}
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            placement='bottom'
          >
            <NoteText className='text-white' text={props.text} />
          </Tooltip>
        )}
      </div>
      <div>
        <span className='label'>Interviewed by</span>
        <span className='ms-1 label fw-semibold'>
          {props.interviewerNames.join(', ')}
        </span>
      </div>
    </div>
  );
}

export function HighlightCard(props: PropTypes) {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(true);
  }

  return (
    <div className={`d-flex flex-column`}>
      <CardBody {...props} onClick={handleClick} />
      <CardLegend
        iconClass={getIconClassByType(props.type)}
        interviewerNames={props.interviewerNames}
        text={props.title}
        onClick={handleClick}
      />
      <VideoModal
        interviewMarkerOffset={props.interviewMarkerOffset}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        media={props.media}
      />
    </div>
  );
}
