import React from 'react';
import { Control } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../../../components/FormFieldHeader';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { CheckboxPillGroupFormHook } from '../../../../../../components/CheckboxPillGroupFormHook';
import { AVAILABLE_EMPLOYMENT_TYPES } from '../../../../../../utils/applicant_tracking/jobDetails';

interface PropTypes {
  formControl: Control<CreateJobDto>;
  formResetField: (controllerName: string) => void;
}

export function EmploymentTypeInput(props: PropTypes) {
  const required = false;

  return (
    <Row className='mt-4'>
      <Col xs={6}>
        <FormFieldHeader
          fieldName={'Employment type'}
          isRequired={required}
          classNames={'fw-semibold mb-2'}
        />
        <CheckboxPillGroupFormHook<CreateJobDto>
          formHookContext={{
            controllerName: 'employmentType',
            formControl: props.formControl,
            required: required,
          }}
          resetField={props.formResetField}
          childrenProps={AVAILABLE_EMPLOYMENT_TYPES}
        />
      </Col>
    </Row>
  );
}
