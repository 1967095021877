import React from 'react';
import { AlertMessage } from '../../../../../components/AlertMessage';
import { ApprovalFlowStep } from '../../../../../entities/applicant_tracking/ApprovalFlow';
import { Button } from 'reactstrap';
import { ApprovalFlowSummary } from '../ApprovalFlowSummary';

interface PropTypes {
  steps: ApprovalFlowStep[];
  isActivelyUsed?: boolean;
  onCancel: () => void;
  onSave: () => void;
}

export function ModalBody(props: PropTypes) {
  return (
    <div>
      <div className='fs-5 mb-4'>
        Review your approval flow before submitting.
      </div>
      {props.isActivelyUsed && (
        <AlertMessage
          text='This approval flow is being used by several jobs. Changes will be immediate, but only impact unapproved steps.'
          icon={{ name: 'bi-info-circle' }}
          className='bg-light-warning fs-5 mb-4'
          hasOverlay={false}
        />
      )}
      <ApprovalFlowSummary steps={props.steps} />
      <div className='d-flex justify-content-end'>
        <Button color='danger' className='me-2' onClick={props.onCancel}>
          Cancel
        </Button>
        <Button color='primary' onClick={props.onSave}>
          Submit
        </Button>
      </div>
    </div>
  );
}
