import { Survey } from '../../entities/survey_engine/Survey';
import { SurveyQuestionAnswer } from '../../entities/survey_engine/SurveyQuestionAnswer';

export function updateSurveyWithAnswer(
  survey: Survey,
  answer: SurveyQuestionAnswer,
): Survey {
  const answerIndex = survey.answers.findIndex(
    (previousAnswer) => previousAnswer.question_id === answer.question_id,
  );

  const updatedSurvey = JSON.parse(JSON.stringify(survey));
  updatedSurvey.answers[answerIndex] = answer;

  return updatedSurvey;
}
