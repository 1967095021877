import classNames from 'classnames';
import React from 'react';
import { Session } from '../../../../../entities/training/Session';
import styles from './styles.module.scss';
import defaultSessionImage from '../../../../../images/default-training-image.png';

interface PropTypes {
  session: Session;
}

export function SessionCardImage(props: PropTypes) {
  return (
    <img
      className={classNames(
        'w-100',
        !props.session.draft && props.session.comingSoon && 'opacity-50',
        styles.img,
      )}
      src={props.session.thumbnail?.url || defaultSessionImage}
      alt={props.session.thumbnail?.description}
    />
  );
}
