import React from 'react';

interface PropTypes {
  children?: React.ReactNode;
}

export function PageBody(props: PropTypes) {
  return (
    <div className='tab-content pb-1' id='candidates-table-tab-content'>
      <div
        className='tab-pane fade show active'
        id='candidates-all'
        role='tabpanel'
        aria-labelledby='candidates-all-tab'
      >
        <div className='app-card app-card-table shadow-sm mb-4'>
          <div className='app-card-body'>{props.children}</div>
        </div>
      </div>
    </div>
  );
}
