import React, { useCallback, useEffect, useState } from 'react';
import { SchedulingUserIntegration } from '../../../entities/SchedulingUserIntegration';
import { PageTitle } from '../../../components/PageTitle';
import { Col, Row } from 'reactstrap';
import {
  AVAILABILITY_RULE_ID,
  AvailabilityEvent,
  AvailabilityRule,
  defaultWeeklyPeriods,
} from '../../../components/cronofy/CronofyAvailabilityRules';
import { CronofyOptions } from '../../../components/cronofy/CronofyOptions';
import { Alert, AlertType } from '../../../components/Alert';
import { SchedulingAvailabilityService } from '../../../services/applicant_tracking/SchedulingAvailabilityService';
import { AvailabilityEmptyState } from './AvailabilityEmptyState';
import { AvailabilityGrid } from './AvailabilityGrid';
import { Helper } from './Helper';
import { EditHeader } from './EditHeader';

interface PropTypes {
  cronofyUserAuthLink: string;
  cronofyOptions?: CronofyOptions;
  schedulingUserIntegration?: SchedulingUserIntegration;
  bypassEmptyState?: boolean;
}

function getAvailabilityTimezone(
  schedulingUserIntegration?: SchedulingUserIntegration,
) {
  if (schedulingUserIntegration?.availabilities?.length > 0) {
    return schedulingUserIntegration.availabilities[0].timezone;
  }

  return null;
}

function buildAvailabilityRule(
  timezone: string,
  schedulingUserIntegration?: SchedulingUserIntegration,
): AvailabilityRule {
  const weeklyPeriods = [];
  if (schedulingUserIntegration?.availabilities?.length > 0) {
    schedulingUserIntegration.availabilities.forEach((availability) =>
      weeklyPeriods.push({
        day: availability.day,
        start_time: availability.startTime,
        end_time: availability.endTime,
      }),
    );
  } else {
    weeklyPeriods.push(...defaultWeeklyPeriods());
  }

  return {
    availability_rule_id: AVAILABILITY_RULE_ID,
    tzid: timezone,
    weekly_periods: weeklyPeriods,
  };
}

export default function Availability(props: PropTypes) {
  const [alertMessage, setAlertMessage] = useState<string>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Success);

  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [withAvailabilityDefined, setAvailabilityDefined] = useState<boolean>(
    props.schedulingUserIntegration?.availabilities?.length > 0,
  );

  const [timezone, setTimezone] = useState<string>(
    getAvailabilityTimezone(props.schedulingUserIntegration) ||
      Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const [availabilityRule, setAvailabilityRule] = useState<AvailabilityRule>(
    buildAvailabilityRule(timezone, props.schedulingUserIntegration),
  );

  const alertSuccess = () => {
    setAlertMessage('Your interview availability was successfully defined.');
    setAlertType(AlertType.Success);
  };

  const alertError = () => {
    setAlertMessage('Your interview availability could not be saved!');
    setAlertType(AlertType.Danger);
    setSaveEnabled(true);
  };

  useEffect(() => {
    setSaveEnabled(true);
  }, [timezone]);

  useEffect(() => {
    if (availabilityRule.weekly_periods.length > 0) return;

    setSaveEnabled(false);
  }, [availabilityRule]);

  const onCallback = useCallback(async (callbackEvent: AvailabilityEvent) => {
    const notificationType = callbackEvent.notification.type;
    if (notificationType === 'availability_rule_not_found') {
      setSaveEnabled(true);
    } else if (notificationType === 'availability_rule_edited') {
      setSaveEnabled(true);
      setAvailabilityRule(callbackEvent.availability_rule);
    }
  }, []);

  const onSave = async () => {
    if (!availabilityRule) {
      alertError();
      return;
    }

    try {
      setSaveEnabled(false);

      await SchedulingAvailabilityService.create({
        slots: availabilityRule.weekly_periods.map((period) => {
          return {
            day: period.day,
            startTime: period.start_time,
            endTime: period.end_time,
          };
        }),
        timezone: timezone,
      });

      alertSuccess();
    } catch (e) {
      alertError();
    }
  };

  return (
    <>
      <PageTitle text={'Your Availability'} />
      {alertMessage && (
        <Alert
          type={alertType}
          clearable={true}
          autoClearTimeout={4000}
          onClose={() => {
            setAlertMessage(null);
            setAlertType(AlertType.Success);
          }}
        >
          {alertMessage}
        </Alert>
      )}
      <div className='app-card app-card-account shadow-sm align-items-start mt-3 p-3 p-xxl-4'>
        {withAvailabilityDefined || !!props.bypassEmptyState ? (
          <>
            <EditHeader
              isSaveEnabled={isSaveEnabled}
              emptyAvailabilities={availabilityRule.weekly_periods.length === 0}
              onSave={onSave}
            />
            <Row className={'w-100'}>
              <Col lg={7}>
                <AvailabilityGrid
                  cronofyOptions={props.cronofyOptions}
                  onCallback={onCallback}
                  timezone={timezone}
                  setTimezone={setTimezone}
                />
              </Col>
              <Col lg={5}>
                <Helper />
              </Col>
            </Row>
          </>
        ) : (
          <AvailabilityEmptyState
            setAvailabilityDefined={setAvailabilityDefined}
            isCalendarConnected={
              props.schedulingUserIntegration?.calendarId != null
            }
            cronofyUserAuthLink={props.cronofyUserAuthLink}
          />
        )}
      </div>
    </>
  );
}
