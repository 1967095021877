import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../components/PageTitle';
import { RecordingsSettingsPanel } from './RecordingsSettingsPanel';
import { RecordingsSettingsService } from '../../../services/v1/RecordingsSettingsService';

export interface PropTypes {
  recordingEnabledSettingsPath?: string;
  optOutSettingsPath?: string;
}

export default function RecordingsSettings(props: PropTypes) {
  const [autoRecordingStatus, setAutoRecordingStatus] = useState(null);
  const [optOutStatus, setOptOutStatus] = useState(null);

  useEffect(() => {
    const fetchRecordingStatus = async () => {
      const response = await RecordingsSettingsService.overallStatus({
        type: 'auto_recording',
      });
      setAutoRecordingStatus(response.status);
    };

    fetchRecordingStatus();
  }, []);

  useEffect(() => {
    const fetchRecordingStatus = async () => {
      const response = await RecordingsSettingsService.overallStatus({
        type: 'recording_consent_optout',
      });

      setOptOutStatus(response.status);
    };

    fetchRecordingStatus();
  }, []);

  return (
    <>
      <PageTitle text={'Recordings'} />
      <RecordingsSettingsPanel
        title={'Auto-recording'}
        status={autoRecordingStatus}
        subtitle={'Automatically record all interviews in your organisation.'}
        path={props.recordingEnabledSettingsPath}
      />
      <RecordingsSettingsPanel
        title={'Opt-out'}
        status={optOutStatus}
        subtitle={'Give candidates the option to decline being recorded.'}
        path={props.optOutSettingsPath}
      />
    </>
  );
}
