import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { StyledModal } from '../StyledModal';
import classNames from 'classnames';

type ChildElement = JSX.Element | string;

interface PropTypes {
  title: string;
  body: ChildElement;
  bodyClassName?: string;
  contentClassName?: string;
  isOpen: boolean;
  confirmText?: string;
  /**
   * Set to true if the 'confirm' button should be disabled right after the user
   * clicks it. Useful to avoid accidental form re-submissions.
   */
  disableAfterConfirm?: boolean;
  isConfirmButtonEnabled?: boolean;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  showCancelButton?: boolean;
  overflowVisible?: boolean;
  size: string;
  footerInfo?: ChildElement;
  external?: React.ReactNode;
}

export function ConfirmationModal(props: PropTypes) {
  const [confirmButtonEnabled, setConfirmButtonEnabled] = useState<boolean>(
    props.isConfirmButtonEnabled !== false,
  );

  function handleConfirmButtonClick() {
    if (props.disableAfterConfirm) setConfirmButtonEnabled(false);

    props.onConfirm();
  }

  useEffect(() => {
    setConfirmButtonEnabled(props.isConfirmButtonEnabled !== false);
  }, [props.isConfirmButtonEnabled]);

  return (
    <StyledModal
      onCancel={props.onCancel}
      isOpen={props.isOpen}
      title={props.title}
      overflowVisible={props.overflowVisible}
      size={props.size}
      bodyChildren={props.body}
      bodyClassName={props.bodyClassName}
      contentClassName={props.contentClassName}
      external={props.external}
      footerChildren={
        <Row
          className={classNames(
            'd-flex',
            'w-100',
            'align-items-center',
            'gx-0',
            'mx-0',
          )}
        >
          <Col>{props.footerInfo}</Col>
          <Col className={'text-end'}>
            {props.showCancelButton !== false && (
              <Button color='danger' onClick={props.onCancel}>
                {props.cancelText || 'Cancel'}
              </Button>
            )}
            <Button
              color={'primary'}
              className='ms-3'
              disabled={!confirmButtonEnabled}
              onClick={handleConfirmButtonClick}
            >
              {props.confirmText || 'Confirm'}
            </Button>
          </Col>
        </Row>
      }
      footerClassName='px-4'
    />
  );
}
