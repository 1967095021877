import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { InfoTooltip } from '../../../../../components/InfoTooltip';
import { selectTheme } from '../../../../../components/Select/selectTheme';
import { SelectOption } from '../../../../../components/Select';
import { Option } from './Option';
import { topicsSelectStyle } from './topicsSelectStyle';
import { Icon } from '../../../../../components/Icon';
import { components } from 'react-select';

interface PropTypes {
  topics: string[];
  onChange: (topics: string[]) => void;
  isAiGenerating: boolean;
}

export function SummaryTopics(props: PropTypes) {
  const CustomMultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <Icon name='bi-x' />
      </components.MultiValueRemove>
    );
  };

  const CustomMenuWithoutDropdown = () => {
    return null;
  };

  return (
    <>
      <div className='hstack gap-2'>
        <div className='fw-semibold fs-5 my-0'>Summary Topics</div>
        <InfoTooltip>
          Add new topics or remove existing ones to customize your summary.
        </InfoTooltip>
      </div>
      <div>
        <CreatableSelect
          value={props.topics.map((topic) => ({
            label: topic,
            value: topic,
          }))}
          isMulti
          tabSelectsValue
          components={{
            DropdownIndicator: null,
            Option,
            MultiValueRemove: CustomMultiValueRemove,
            Menu: CustomMenuWithoutDropdown,
          }}
          theme={selectTheme}
          formatCreateLabel={(inputValue) => inputValue}
          styles={topicsSelectStyle}
          isClearable={false}
          noOptionsMessage={(_) => null}
          isOptionSelected={(option: SelectOption) => !!option.selected}
          onChange={(newValue: SelectOption[]) => {
            const newTopics = newValue.map((option) => option.value);
            props.onChange(newTopics);
          }}
          placeholder='Add at least one topic to customise your summary'
          isDisabled={props.isAiGenerating}
        />
      </div>
    </>
  );
}
