import React from 'react';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { SurveyQuestion } from '../../../../../../../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionFieldOption } from '../../../../../../../../entities/survey_engine/SurveyQuestionFieldOption';
import { QuestionAnswer } from '../../../../../../../../services/applicant_tracking/CareersService/SubmitApplicationDto';

interface PropTypes {
  question: SurveyQuestion;
  control?: Control<any, any>;
  disabled?: boolean;
}

export function CustomMultiOptionsQuestionInput(props: PropTypes) {
  const maxOptions = props.question.maxOptions;

  if (maxOptions != 1) return <Input El={CheckboxInput} {...props} />;

  return <Input El={RadioInput} {...props} />;
}

function Input(
  props: PropTypes & { El: typeof CheckboxInput | typeof RadioInput },
) {
  const options = props.question.fields[0].options;

  function validate(value: QuestionAnswer) {
    if (!props.question.mandatory) return true;

    const optionIds = value?.fields[0]?.option_ids;
    if (optionIds == null) return false;
    if (optionIds.length === 0) return false;
    return true;
  }

  return (
    <Controller
      name={`customQuestionAnswers.${props.question.id}`}
      control={props.control}
      rules={{ validate }}
      render={({ field }) => (
        <>
          {options.map((option) => (
            <props.El
              key={option.id}
              question={props.question}
              option={option}
              field={field}
              disabled={props.disabled}
            />
          ))}
        </>
      )}
    />
  );
}

function CheckboxInput(props: {
  question: SurveyQuestion;
  option: SurveyQuestionFieldOption;
  field: ControllerRenderProps<any, `customQuestionAnswers.${number}`>;
  disabled?: boolean;
}) {
  function handleChange(event) {
    let answer: QuestionAnswer = {
      question_id: props.question.id,
      fields: [
        {
          field_id: props.question.fields[0].id,
          option_ids: [],
        },
      ],
    };
    if (props.field?.value != null) {
      answer = props.field.value as QuestionAnswer;
    }

    const optionIds = answer.fields[0].option_ids as number[];
    const value = props.option.id as number;
    const checked = event.target.checked;

    if (checked) {
      optionIds.push(value);
    } else {
      const idx = optionIds.indexOf(value);
      optionIds.splice(idx, 1);
    }

    props.field.onChange(answer);
  }

  return (
    <InputUi
      type={'checkbox'}
      onChange={handleChange}
      option={props.option}
      field={props.field}
      disabled={props.disabled}
    />
  );
}

function RadioInput(props: {
  question: SurveyQuestion;
  option: SurveyQuestionFieldOption;
  field: ControllerRenderProps<any, `customQuestionAnswers.${number}`>;
  disabled?: boolean;
}) {
  function handleChange() {
    const answer: QuestionAnswer = {
      question_id: props.question.id,
      fields: [
        {
          field_id: props.question.fields[0].id,
          option_ids: [props.option.id as number],
        },
      ],
    };
    props.field.onChange(answer);
  }

  return (
    <InputUi
      type={'radio'}
      onChange={handleChange}
      option={props.option}
      field={props.field}
      disabled={props.disabled}
    />
  );
}

function InputUi(props: {
  type: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  option: SurveyQuestionFieldOption;
  field: ControllerRenderProps<any, `customQuestionAnswers.${number}`>;
  disabled?: boolean;
}) {
  const id = `${props.field.name}-${props.option.id}`;

  return (
    <div className='form-check fw-normal'>
      <input
        className='form-check-input'
        type={props.type}
        onChange={props.onChange}
        name={props.field.name}
        id={id}
        ref={props.field.ref}
        value={props.option.id}
        disabled={props.disabled}
      />
      <label className='form-check-label' htmlFor={id}>
        {props.option.name}
      </label>
    </div>
  );
}
