import React from 'react';
import { Button, Col } from 'reactstrap';

interface PropTypes {
  totpEnabled: boolean;
}

export function TotpButtonsCol(props: PropTypes) {
  return (
    <Col xs={4} className='text-end'>
      {props.totpEnabled ? (
        <Button
          color='secondary'
          size={'md'}
          onClick={() =>
            (window.location.href = '/account_settings/security/mfa')
          }
        >
          View
        </Button>
      ) : (
        <Button
          color='secondary'
          size={'md'}
          onClick={() =>
            (window.location.href = '/account_settings/security/mfa/new')
          }
        >
          Setup
        </Button>
      )}
    </Col>
  );
}
