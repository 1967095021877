import React, { useState } from 'react';
import { Scorecard } from '../../../../../../../entities/applicant_tracking/Scorecard';
import { ScorecardService } from '../../../../../../../services/applicant_tracking/ScorecardService';
import { Alert, AlertType } from '../../../../../../../components/Alert';
import {
  MINUTES_IN_DAY,
  Timedelta,
} from '../../../../../../../utils/timedelta';
import { SpanTooltip } from '../../../../../../../components/SpanTooltip';
import { User } from '../../../../../../../entities/User';
import { Button } from 'reactstrap';
import styles from './styles.module.scss';

interface PropTypes {
  scorecard: Scorecard;
  currentUser: User;
}

function ActionButton(props: {
  text: string;
  disabled?: boolean;
  color?: string;
  action: () => void;
}) {
  return (
    <Button
      size='sm'
      color={props.color ?? 'primary'}
      className='text-nowrap'
      disabled={props.disabled}
      onClick={props.action}
      styles={styles['scorecard-button']}
    >
      {props.text}
    </Button>
  );
}

function SendReminderButton(props: {
  reminderEnabled: boolean;
  action: () => void;
}) {
  return props.reminderEnabled ? (
    <ActionButton
      action={props.action}
      text={'Send Reminder'}
      color='secondary'
    />
  ) : (
    <SpanTooltip
      text={
        <ActionButton
          disabled
          text={'Send Reminder'}
          color='secondary'
          action={props.action}
        />
      }
      tooltipText='Last reminder sent less than 24 hours ago'
    />
  );
}

function FillInScorecardButton(props: { scorecardId: number }) {
  return (
    <ActionButton
      action={() =>
        (window.location.href = `/applicant_tracking/scorecards/${props.scorecardId}`)
      }
      text={'Fill in Scorecard'}
    />
  );
}

export function ScorecardButton(props: PropTypes) {
  const [reminderEnabled, setReminderEnabled] = useState<boolean>(
    !props.scorecard.reminderSentAt ||
      Timedelta.timeTillDate(props.scorecard.reminderSentAt).toMins() <
        -MINUTES_IN_DAY,
  );

  const [alertActive, setAlertActive] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Info);
  const [alertMessage, setAlertMessage] = useState(null);

  const sendReminder = async () => {
    try {
      await ScorecardService.sendReminder(props.scorecard.id);
      setAlertType(AlertType.Success);
      setAlertMessage('Reminder sent successfully');
      setReminderEnabled(false);
    } catch (error) {
      setAlertType(AlertType.Danger);
      setAlertMessage('Could not send reminder');
      console.error(error);
    }
    setAlertActive(true);
  };

  return (
    <div className='d-flex flex-nowrap'>
      {props.scorecard?.user?.id !== props.currentUser.id ? (
        <>
          {alertActive && (
            <Alert
              type={alertType}
              autoClearTimeout={4000}
              clearable={true}
              onClose={() => setAlertActive(false)}
            >
              {alertMessage}
            </Alert>
          )}
          <SendReminderButton
            reminderEnabled={reminderEnabled}
            action={sendReminder}
          />
        </>
      ) : (
        <FillInScorecardButton scorecardId={props.scorecard.id} />
      )}
    </div>
  );
}
