import React from 'react';

interface PropTypes {
  npsDescription: string;
  npsTitle: string;
  npsPromotersText: string;
  npsDetractorsText: string;
}

export function OverallNpsTooltipText(props: PropTypes) {
  return (
    <>
      <p>{props.npsDescription}</p>
      <p className='mb-0'>
        ({props.npsTitle} = % {props.npsPromotersText.toLowerCase()} - %{' '}
        {props.npsDetractorsText.toLowerCase()})
      </p>
    </>
  );
}
