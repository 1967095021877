import React from 'react';
import { InterviewStage } from '../../../../../../entities/applicant_tracking/InterviewStage';
import { TimezoneSelect } from '../../../../../../components/TimezoneSelect';
import { getInterviewStageName } from '../../../../../../utils/applicant_tracking/interviewStage';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { DateRangeSelect } from '../../../../../../components/DateRangeSelect';
import { ScheduleFromSelect } from '../../ScheduleFromSelect';
import { ScheduleFromType } from '../../ScheduleFromSelect/ScheduleFromTypes';

interface PropTypes {
  interviewStage: InterviewStage;
  dateRange: number;
  scheduleFrom: ScheduleFromType;
  timezone: string;
  setDateRange: (dateRange: number) => void;
  setScheduleFrom: (scheduleFrom: ScheduleFromType) => void;
  setTimezone: (timezone: string) => void;
  candidateView: boolean;
  selfScheduleView: boolean;
  jobName?: string;
}

function Title(props: PropTypes) {
  let title: string;

  if (props.candidateView) {
    title = `Your interview for ${props.jobName}`;
  } else if (props.selfScheduleView) {
    title = 'Your team availability';
  } else {
    title = getInterviewStageName(props.interviewStage);
  }

  return <h3 className={'m-0'}>{title}</h3>;
}

function SubTitle(props: PropTypes) {
  if (props.candidateView) {
    return (
      <div className='align-items-center text-muted fs-5 mt-2'>
        Your {getInterviewStageName(props.interviewStage)} is ready to be
        scheduled
      </div>
    );
  } else if (props.selfScheduleView) {
    return (
      <div className='align-items-center text-muted fs-5'>
        {getInterviewStageName(props.interviewStage)} availability is ready to
        be shared
      </div>
    );
  }

  return null;
}

function DatePickerSelfScheduleCandidateView(props: PropTypes) {
  return (
    <>
      <div
        data-testid='interview-stage'
        className={'d-flex align-items-center justify-content-between'}
      >
        <Title {...props} />
        <SubTitle {...props} />
      </div>
      <div className='d-flex flex-nowrap gap-3 mt-3h'>
        <ScheduleFromSelect
          scheduleFrom={props.scheduleFrom}
          setScheduleFrom={props.setScheduleFrom}
          className={'d-flex'}
          titleClassName={classNames(styles['date-range-title'])}
          selectClassName={classNames('fs-6', styles['self-scheduling-select'])}
        />
        <DateRangeSelect
          dateRange={props.dateRange}
          setDateRange={props.setDateRange}
          className={'d-flex'}
          titleClassName={classNames(styles['date-range-title'])}
          selectClassName={classNames('fs-6', styles['self-scheduling-select'])}
        />
        <TimezoneSelect
          timezone={props.timezone}
          setTimezone={props.setTimezone}
          className={'d-flex'}
          titleClassName={classNames(styles['timezone-title'])}
          selectClassName={classNames('fs-6', styles['self-scheduling-select'])}
        />
      </div>
    </>
  );
}

export function InterviewDatePickerHeader(props: PropTypes) {
  if (props.selfScheduleView && !props.candidateView) {
    return <DatePickerSelfScheduleCandidateView {...props} />;
  }

  return (
    <>
      <div className={'d-flex align-items-center'}>
        <div
          data-testid='interview-stage'
          className={'m-0 w-50 fs-3 fw-semibold'}
        >
          <Title {...props} />
        </div>
        {!props.candidateView && (
          <DateRangeSelect
            dateRange={props.dateRange}
            setDateRange={props.setDateRange}
            className={'d-flex ms-auto me-3'}
            titleClassName={classNames(styles['date-range-title'])}
            selectClassName={classNames('fs-6', styles['date-range-select'])}
          />
        )}
        <TimezoneSelect
          timezone={props.timezone}
          setTimezone={props.setTimezone}
          className={classNames(props.candidateView && 'ms-auto', 'd-flex')}
          titleClassName={classNames(styles['timezone-title'])}
          selectClassName={classNames('fs-6', styles['timezone-select'])}
        />
      </div>
      <SubTitle {...props} />
    </>
  );
}
