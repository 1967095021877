import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { useStackOneHub } from '@stackone/react-hub';
import { IntegrationService } from '../../../services/IntegrationService';
import { Integration } from '../../../entities/Integration';

export interface PropTypes {
  integration: Integration;
  setIntegration?: (i: Integration) => void; //optional because we are also rendering this button from the backend
}

export const ConnectUnifiedApiButton = React.memo(
  function ConnectUnifiedApiButton(props: PropTypes) {
    const { startConnect } = useStackOneHub({
      connectUrl: props.integration.buttonOptions.unifiedCloudConnectFileUrl,
    });

    const handleOnSuccess = useCallback(async (account) => {
      const result = await IntegrationService.create({
        provider: props.integration.provider,
        accessToken: props.integration.buttonOptions.sessionToken,
        accountId: account.id,
      });

      if (props.setIntegration !== undefined)
        props.setIntegration({
          ...props.integration,
          id: result.id,
          connected: result.connected,
        });
    }, []);

    const startFlow = useCallback(async () => {
      startConnect({
        sessionToken: props.integration.buttonOptions.sessionToken,
        onSuccess: handleOnSuccess,
      });
    }, [startConnect]);

    return (
      <Button
        color='primary'
        onClick={startFlow}
        type='submit'
        className={props.integration.buttonOptions.classNames}
        disabled={
          props.integration.connected ||
          props.integration.buttonOptions.disabled
        }
      >
        {props.integration.buttonOptions.label ?? 'Connect'}
      </Button>
    );
  },
);
