import React from 'react';
import { GenericEntry } from '..';
import { Application } from '../../../../entities/Application';
import { Simple } from '../../../../entities/ApplicationFeed';
import { User } from '../../../../entities/User';
import { AuthorName } from '../AuthorName';
import { AlertObject } from '../../../../components/Alert';

interface PropTypes {
  application: Application;
  currentUser: User;
  entry: Simple;
  isLastItemInPage: boolean;
  setAlert: (data: AlertObject) => void;
  reloadApplication: () => void;
}

export function DetailsEntry(props: PropTypes) {
  return (
    <GenericEntry
      icon='bi-file-earmark-text'
      iconClassName='bg-blue-gray-light'
      title={
        <>
          {props.entry.authorName ? (
            <span>
              <AuthorName name={props.entry.authorName} /> added a note.
            </span>
          ) : (
            <span>A note has been added.</span>
          )}
        </>
      }
      date={props.entry.createdAt}
      {...props}
    >
      <div
        className='rounded rounded-3 bg-gray py-3 px-4'
        style={{ whiteSpace: 'pre-line' }}
      >
        {props.application.details}
      </div>
    </GenericEntry>
  );
}
