import { FilterState } from '../../../../containers/applicant_tracking/Analytics/Filters';
import { ApplicationsCountHistory } from '../../../../entities/applicant_tracking/analytics/ApplicationsCountHistory';
import { ApiService } from '../../../ApiService';
import { mapToParams } from '../mapToParams';

export class ApplicationsCountHistoryService {
  public static show(
    filterState?: FilterState,
  ): Promise<ApplicationsCountHistory> {
    return ApiService.get(
      '/api/v1/applicant_tracking/analytics/applications_count_history',
      mapToParams(filterState),
    );
  }
}
