import React, { useState } from 'react';
import { IntegrationService } from '../../../services/IntegrationService';
import { LabelledToggle } from '../../LabelledToggle';
import { StyledModal } from '../../StyledModal';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { Alert, AlertType } from '../../Alert';
import { Integration } from '../../../entities/Integration';

interface PropTypes {
  integration: Integration;
  setIntegration: (i: Integration) => void;
}

function Link(props: {
  href: string;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <a
      href={props.href}
      className={classNames('text-blue', props.className)}
      target='_blank'
      rel='noreferrer'
    >
      {props.children}
    </a>
  );
}

function ActionButtons(props: {
  onConnect: () => void;
  onCancel: () => void;
  connectDisabled: boolean;
}) {
  return (
    <div className='d-flex w-100 mt-3 justify-content-end'>
      <Button type='button' color='danger' onClick={props.onCancel}>
        Cancel
      </Button>
      <Button
        type='button'
        color='primary'
        className='ms-3'
        onClick={props.onConnect}
        disabled={!props.connectDisabled}
      >
        Connect
      </Button>
    </div>
  );
}

function Checkbox(props: { checked: boolean; onChange: () => void }) {
  return (
    <div className='d-inline-flex'>
      <input
        className='form-check-input'
        type='checkbox'
        name='consent'
        onChange={props.onChange}
        checked={props.checked}
      />
      <span className='ms-2 fs-6'>
        I agree with the{' '}
        <Link href='https://vault.pactsafe.io/s/5d800911-f26c-4e3a-884d-85117a81799b/legal.html?g=37964#contract-b1hg-isli'>
          Terms
        </Link>
        ,{' '}
        <Link href='https://vault.pactsafe.io/s/5d800911-f26c-4e3a-884d-85117a81799b/legal.html?g=37964#contract-byeg-ihlj'>
          Privacy Policy
        </Link>
        ,{' '}
        <Link href='https://vault.pactsafe.io/s/5d800911-f26c-4e3a-884d-85117a81799b/legal.html?g=37964#contract-bjee-urls'>
          Service agreement
        </Link>{' '}
        and{' '}
        <Link href='https://vault.pactsafe.io/s/5d800911-f26c-4e3a-884d-85117a81799b/legal.html?g=37964#contract-ryclhyapj'>
          Data processing addendum
        </Link>
        .
      </span>
    </div>
  );
}

function ModalBody(props: {
  checkbox: boolean;
  onCheckboxChanged: (value: boolean) => void;
  onConsent: () => void;
  onModalClose: () => void;
}) {
  return (
    <div>
      <h4 className='fw-bold'>
        We need to obtain your consent before we can connect with the requested
        background check services.
      </h4>
      <span className='fw-semibold fs-5'>
        Please read the{' '}
        <Link href='https://vault.pactsafe.io/s/5d800911-f26c-4e3a-884d-85117a81799b/legal.html?g=37964'>
          Certn terms and service agreement
        </Link>
        .
      </span>
      <hr />
      <Checkbox
        onChange={() => props.onCheckboxChanged(!props.checkbox)}
        checked={props.checkbox}
      />

      <ActionButtons
        onConnect={props.onConsent}
        onCancel={props.onModalClose}
        connectDisabled={props.checkbox}
      />
    </div>
  );
}

export function CertnIntegrationToggle(props: PropTypes) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [confirmationEnabled, setConfirmationEnabled] =
    useState<boolean>(false);
  async function onCheckboxChanged() {
    if (props.integration.connected) {
      await IntegrationService.disable({
        provider: props.integration.provider,
        id: props.integration.id,
      });
      setConfirmationEnabled(true);
      props.setIntegration({
        ...props.integration,
        id: null,
        connected: false,
      });
    } else {
      setModalOpen(true);
    }
  }

  async function onConsent() {
    const integration = await IntegrationService.enable({
      provider: props.integration.provider,
    });
    props.setIntegration({
      ...props.integration,
      id: integration.id,
      connected: true,
    });
    setModalOpen(false);
    setConfirmationEnabled(true);
  }

  function onModalClose() {
    setModalOpen(false);
    setCheckbox(false);
  }

  return (
    <>
      {confirmationEnabled && (
        <Alert
          type={AlertType.Success}
          clearable={true}
          autoClearTimeout={4000}
          onClose={() => {
            setConfirmationEnabled(false);
          }}
        >
          {props.integration.connected
            ? 'Certn is now connected to your account'
            : 'Certn is now disconnected to your account '}
        </Alert>
      )}
      <StyledModal
        isOpen={modalOpen}
        title='Connect Certn'
        onCancel={() => onModalClose()}
        size={'md'}
        overflowVisible={false}
        bodyChildren={
          <ModalBody
            checkbox={checkbox}
            onCheckboxChanged={setCheckbox}
            onConsent={onConsent}
            onModalClose={onModalClose}
          />
        }
      />
      <LabelledToggle
        inputName='CertIntegrationToggle'
        checked={props.integration.connected}
        onChange={onCheckboxChanged}
      />
    </>
  );
}
