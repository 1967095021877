import React from 'react';
import { Chip, ChipColor } from '../../../../../../../components/Chip';
import { Trend } from '../../../../../../../components/survey_engine/Trend';
import classNames from 'classnames';

interface PropTypes {
  total: number;
  value: string;
  trend: number;
  title: string;
  chipColor: ChipColor;
}

export function NpsTeamBreakdownChip(props: PropTypes) {
  return (
    <div className='d-inline-flex my-1'>
      <div>
        <Chip
          className={classNames(`fs-5 me-2 px-3 py-2`)}
          color={props.chipColor}
        >
          {' '}
          <strong>{Math.round(props.total)}</strong> {props.title}
          {Math.round(props.total) === 1 ? '' : 's'}
        </Chip>
      </div>
      <div>
        <Chip
          className={classNames(`fs-5 me-2 px-3 py-2`)}
          color={props.chipColor}
        >
          {' '}
          <strong>
            {props.value != null ? parseInt(props.value, 10).toFixed(1) : 'N/A'}
          </strong>
        </Chip>
      </div>
      <Trend trend={props.trend} className='py-2 fs-5 text-nowrap' />
    </div>
  );
}
