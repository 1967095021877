import React from 'react';
import { IconEntry } from '../../IconEntry';

interface PropTypes {
  redacted: boolean;
  linkedinUrl: string;
}

export function LinkedinDetails(props: PropTypes) {
  if (props.linkedinUrl == null) {
    return null;
  }

  const label = 'View Linkedin';

  return (
    <IconEntry label={label} redacted={props.redacted} icon='bi-linkedin'>
      <a
        className='text-info'
        href={props.linkedinUrl}
        target='_blank'
        rel='noreferrer'
      >
        {label}
      </a>
    </IconEntry>
  );
}
