import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { DeprecatedPanel } from '../../../components/DeprecatedPanel';
import { Interview } from '../../../entities/Interview';
import { InterviewService } from '../../../services/InterviewService';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { ActionPoint } from '../../../entities/ActionPoint';
import { shortFormatDate } from '../../../utils/timeFormat';
import { formatTime } from '../../../utils/timeFormat';
import { InterviewRatingResponse } from '../../InterviewRatingResponse';
import { Avatar } from '../../../components/Avatar';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { HighlightsSection } from './HighlightSection';
import { SummaryCard } from './SummaryCard';
import { KeyMomentsCard } from './KeyMomentsCard';
import { ActionPointsCard } from './ActionPointsCard';
import { InterviewListItem } from '../../../entities/InterviewListItem';
import { capitalize } from 'humanize-plus';
import { getDisplayName } from '../../../utils/getDisplayName';

interface PropTypes {
  interview: InterviewListItem;
  atsEnabled: boolean;
  onBack: () => void;
}

function InterviewSummaryHeader(props: {
  interview: Interview;
  onBack: () => void;
}) {
  return (
    <>
      <div className='d-inline-flex'>
        <Button
          color='light'
          className='bg-transparent text-dark fs-4 fw-normal p-0 me-1'
          onClick={props.onBack}
        >
          Interviews
        </Button>
        <p className='m-0'> / Summary page</p>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-inline-flex align-items-center'>
          <h3 className='mb-0'>{props.interview.stage}</h3>{' '}
          <a
            href='#'
            onClick={(ev) => {
              ev.preventDefault();
              props.onBack();
            }}
          >
            <div className='nav-link align-middle mx-2 fs-5 text-info'>
              <i className='bi bi-arrow-left-circle me-1'></i>
              Back to Interviews
            </div>
          </a>
        </div>

        <div className='d-flex'>
          <a href={`/interviews/${props.interview.id}`}>
            <div className='nav-link align-middle me-2 fs-5 text-info'>
              <i className='bi bi-play-btn me-1'></i>
              View Full Interview
            </div>
          </a>
          <InterviewRatingResponse
            interviewers={props.interview.interviewers}
            responses={props.interview.rating_responses}
            ratingOptions={props.interview.rating_options}
            classNames='bg-white mx-3 my-auto'
          />
        </div>
      </div>
    </>
  );
}

export function InterviewSummary(props: PropTypes) {
  const [loading, setLoading] = useState(true);
  const [interview, setInterview] = useState<Interview>();

  useEffect(() => {
    fetchInterview();
  }, []);

  const fetchInterview = async () => {
    const interview = await InterviewService.getByUrl(
      props.interview.interviewUrl,
    );
    setInterview(interview);
    setLoading(false);
  };

  const handleActionPoints = (actionPoints: ActionPoint[]) => {
    setInterview({
      ...interview,
      actionPoints: actionPoints,
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <InterviewSummaryHeader interview={interview} onBack={props.onBack} />
      <Row className={classNames('mt-3', styles['cardColumns'])}>
        <Col xs='12' md='4' className='h-100'>
          <SummaryCard interview={interview} />
        </Col>
        <Col xs='12' md='4' className='h-100 mt-4 mt-md-0'>
          <KeyMomentsCard interview={interview} />
        </Col>
        <Col xs='12' md='4' className='h-100 mt-4 mt-md-0'>
          <DeprecatedPanel
            additionalInnerContainerClassNames='p-4'
            style={{ height: '150px' }}
          >
            <p>{shortFormatDate(interview.start_date)}</p>
            <Row>
              <Col xs='6'>
                <p className='text-dark-200 fs-5'>Interview duration</p>
                <span>
                  {interview.media.duration != null &&
                    formatTime(interview.media.duration)}
                </span>
              </Col>
              <Col xs='6'>
                <p className='text-dark-200 fs-5'>Participants</p>
                <div className='d-inline-flex'>
                  {interview.interviewers.map((interviewer, idx) => (
                    <Avatar
                      key={idx}
                      name={getDisplayName(
                        interviewer.name,
                        capitalize(interviewer.email),
                      )}
                      size='lg'
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </DeprecatedPanel>
          <ActionPointsCard
            interview={interview}
            handleActionPoints={handleActionPoints}
            atsEnabled={props.atsEnabled}
          />
        </Col>
      </Row>
      <HighlightsSection interview={interview} />
    </>
  );
}
