import { SelectOption } from '../../../../components/Select';
import { ActionItem } from '../../../../entities/ActionItem';
import { HiringMember } from '../../../../entities/HiringMember';
import { User } from '../../../../entities/User';
import { Chip, ChipColor } from '../../../../components/Chip';
import React from 'react';
import { Col } from 'reactstrap';
import { GroupedOption } from '../../../../components/GroupedMultiSelect';

export const ROWS_PER_PAGE = 4;

export function filterActionItemsByStatus(actionItems: ActionItem[]) {
  const pendingItems = actionItems.filter(
    (actionItem) => !actionItem.completed,
  );
  const completedItems = actionItems.filter(
    (actionItem) => actionItem.completed,
  );

  return pendingItems.length > 0 ? pendingItems : completedItems;
}

export function filterActionItemsByHiringMember(
  actionItems: ActionItem[],
  hiringMemberFilter: SelectOption[],
) {
  if (hiringMemberFilter.length === 0) {
    return actionItems;
  }

  const hiringMemberIds = hiringMemberFilter.map((option) => option.value);

  return actionItems.filter((actionItem) =>
    hiringMemberIds.includes(actionItem.hiringMember.id.toString()),
  );
}

export function hiringMemberFilterOptions(
  hiringMembers: HiringMember[],
  currentUser: User,
): GroupedOption[] {
  const options = [];
  hiringMembers.forEach((hiringMember) => {
    const option = {
      value: hiringMember.id.toString(),
      label: hiringMember.name,
      category: mapRoleToOptionTitle(hiringMember.role),
    };

    if (currentUser.id === hiringMember.userId) {
      option['subLabel'] = (
        <Col>
          <Chip
            className='d-block mw-100 text-truncate fw-semibold'
            color={ChipColor.Gray}
          >
            My Own
          </Chip>
        </Col>
      );
    }

    options.push(option);
  });

  return options;
}

function mapRoleToOptionTitle(role: string) {
  switch (role) {
    case 'hiring_manager':
      return 'Hiring Managers';
    case 'recruiter':
      return 'Recruiters';
    case 'interviewer':
      return 'Interviewers';
  }
}

export function sliceActionsForPage(actionItems: ActionItem[], page: number) {
  return actionItems.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE);
}
