import {
  ApplicationRating,
  ApplicationRatingList,
} from '../../../../entities/v1/applicant_tracking/ApplicationRating';
import { ApiService } from '../../../ApiService';
import snakecaseKeys from 'snakecase-keys';

interface UpsertParams {
  id?: number;
  applicationId: number;
  rating: number;
  comment: string;
}

export class ApplicationRatingService {
  public static list(params: {
    applicationId: number;
    expand?: string[];
    hiringMemberIds?: number[];
    jobStageIds?: number[];
  }): Promise<ApplicationRatingList> {
    return ApiService.get<ApplicationRatingList>(
      `/api/v1/applicant_tracking/application_ratings`,
      snakecaseKeys({
        applicationId: params.applicationId,
        expand: params.expand,
        hiringMemberIds: params.hiringMemberIds,
        jobStageIds: params.jobStageIds,
      }),
    );
  }

  public static upsert(params: UpsertParams): Promise<void> {
    if (params.id) {
      return this.update(params);
    } else {
      return this.create(params);
    }
  }

  public static show(id: number): Promise<ApplicationRating> {
    return ApiService.get<ApplicationRating>(
      `/api/v1/applicant_tracking/application_ratings/${id}`,
    );
  }

  private static create(params: UpsertParams): Promise<void> {
    return ApiService.post<void>(
      '/api/v1/applicant_tracking/application_ratings',
      snakecaseKeys(params),
    );
  }

  private static update(params: UpsertParams): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/application_ratings/${params.id}`,
      snakecaseKeys(params),
    );
  }
}
