import classNames from 'classnames';
import React from 'react';
import FeedbackImage from '../../../../images/woman-success.svg';
import { FeedbackForm } from '../FeedbackForm';
import { FeedbackRating } from '../FeedbackRating';
import styles from './styles.module.scss';

interface PropTypes {
  onChangeFeedbackText: (value: string) => void;
  rating: number;
  onChangeRating: (value: number | null) => void;
}

export function FeebackSessionBody(props: PropTypes) {
  return (
    <>
      <div className='text-center'>
        <img
          className={classNames('text-center', styles.illustration)}
          src={FeedbackImage}
        />
      </div>
      <FeedbackRating
        activeRating={props.rating}
        onActiveRating={props.onChangeRating}
      />
      <FeedbackForm onChangeFeedbackText={props.onChangeFeedbackText} />
    </>
  );
}
