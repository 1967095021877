const SCROLL_OFFSET = 5;

function shouldAllowRightScroll(scrollContainer: HTMLElement) {
  const { scrollWidth, clientWidth, scrollLeft } = scrollContainer;

  const isContainerScrolledToRight =
    Math.abs(scrollWidth - clientWidth - scrollLeft) < SCROLL_OFFSET;

  const isContainerWidthGreaterThanScrollWidth = clientWidth >= scrollWidth;

  return !isContainerScrolledToRight && !isContainerWidthGreaterThanScrollWidth;
}

export function getScrollStates(scrollContainer: HTMLElement): {
  allowLeftScroll: boolean;
  allowRightScroll: boolean;
} {
  if (!scrollContainer)
    return { allowLeftScroll: false, allowRightScroll: false };

  const allowLeftScroll = scrollContainer.scrollLeft > 0;
  const allowRightScroll = shouldAllowRightScroll(scrollContainer);

  return { allowLeftScroll, allowRightScroll };
}
