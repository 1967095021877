import { ApiService } from '../ApiService';

export class UserInviteService {
  public static post(
    firstName: string,
    lastName: string,
    email: string,
    role: string,
  ): Promise<void> {
    return ApiService.post<void>(`/organization_settings/members/invites`, {
      user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        role: role,
      },
    });
  }
}
