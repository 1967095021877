import React from 'react';
import { HiringMember } from '../../../entities/HiringMember';
import { Avatar } from '../../../components/Avatar';
import { SpanTooltip } from '../../../components/SpanTooltip';
import styles from '../styles.module.scss';
import { capitalize } from 'humanize-plus';
import { getDisplayName } from '../../../utils/getDisplayName';

export function UnratedInterviewer(props: { user: HiringMember }) {
  return (
    <SpanTooltip
      text={
        <span className={styles.score}>
          <Avatar
            name={getDisplayName(props.user.name, capitalize(props.user.email))}
            size='sm'
          />
        </span>
      }
      tooltipText={
        <>
          <div className='fw-semibold fs-5'>
            {getDisplayName(props.user.name, props.user.email)}
          </div>
          Not Rated
        </>
      }
      tooltipFade={false}
    />
  );
}
