import snakecaseKeys from 'snakecase-keys';
import { FilterState } from '../../../containers/applicant_tracking/Analytics/Filters';

export function mapToParams(filterState?: FilterState) {
  if (filterState == null) return;

  return snakecaseKeys({
    startDate: filterState.dateRange.start.toISOString(),
    endDate: filterState.dateRange.end.toISOString(),
    filterBy: filterState.filterBy.value,
    filterByIds: filterState.entries.map((entry) => entry.value),
    locationIds: filterState.locations?.map((option) => option.value),
  });
}
