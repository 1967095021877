import React from 'react';
import { FormGroup } from 'reactstrap';
import { CountryDropdown } from '../../../../../../components/CountryDropdown';
import classNames from 'classnames';
import { Label } from '../Label';

export function CountryFormGroup(props: {
  countryCode: string;
  disabled: boolean;
  validated: boolean;
  onCountryCodeChanged: (countryCode: string) => void;
}) {
  const valid = props.validated && !props.countryCode?.length;
  return (
    <FormGroup check>
      <Label for='bgc-country' check size='sm' isRequired>
        Country
      </Label>
      <CountryDropdown
        className={classNames('form-control p-0', {
          'is-invalid': valid,
          'border-0': !valid,
        })}
        value={props.countryCode}
        onChange={props.onCountryCodeChanged}
        disabled={props.disabled}
      />
    </FormGroup>
  );
}
