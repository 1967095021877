import React from 'react';
import { BackArrow } from '../../../components/BackArrow';
import { PageTitle } from '../../../components/PageTitle';

interface PropTypes {
  title: string;
  backUrl: string;
  default?: boolean;
}

export function PageHeader(props: PropTypes) {
  return (
    <div className='d-flex align-items-center mb-4'>
      <BackArrow url={props.backUrl} />
      <PageTitle text={props.title} />
      {props.default && (
        <span className='ms-2 rounded-pill badge bg-light-danger text-primary fs-5'>
          Default
        </span>
      )}
    </div>
  );
}
