import { FilterState } from '../../../../containers/applicant_tracking/Analytics/Filters';
import { Overview } from '../../../../entities/applicant_tracking/analytics/Overview';
import { ApiService } from '../../../ApiService';
import { mapToParams } from '../mapToParams';

export class OverviewService {
  public static show(filterState?: FilterState): Promise<Overview> {
    return ApiService.get(
      '/api/v1/applicant_tracking/analytics/overview',
      mapToParams(filterState),
    );
  }
}
