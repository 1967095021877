import classNames from 'classnames';
import React from 'react';
import { Pagination } from '../../../../components/Pagination';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { HiringMember } from '../../../../entities/HiringMember';
import { HiringMembersTab } from './Tabs/HiringMembersTab';
import styles from './styles.module.scss';

const ROWS_PER_PAGE = 3;
interface PropTypes {
  hiringMembers: HiringMember[];
  className?: string;
}

function filterOnRole(
  hiringMembers: HiringMember[],
  role: string,
): HiringMember[] {
  return hiringMembers.filter((member) => member.role === role);
}

function getPageItems(
  sortedHiringMembers: HiringMember[],
  currentPage: number,
) {
  const prevIndex = (currentPage - 1) * ROWS_PER_PAGE;
  const nextIndex = currentPage * ROWS_PER_PAGE;

  const hiringMembers = sortedHiringMembers.slice(prevIndex, nextIndex);

  const recruiters = filterOnRole(hiringMembers, 'recruiter');
  const hiringManagers = filterOnRole(hiringMembers, 'hiring_manager');
  const interviewers = filterOnRole(hiringMembers, 'interviewer');

  return { recruiters, hiringManagers, interviewers };
}

export function HiringTeam(props: PropTypes) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const totalRows = props.hiringMembers.length;
  const pageCount = Math.ceil(totalRows / ROWS_PER_PAGE);

  const sortedHiringMembers = [
    ...filterOnRole(props.hiringMembers, 'recruiter'),
    ...filterOnRole(props.hiringMembers, 'hiring_manager'),
    ...filterOnRole(props.hiringMembers, 'interviewer'),
  ];

  const { recruiters, hiringManagers, interviewers } = getPageItems(
    sortedHiringMembers,
    currentPage,
  );
  const numberOfPanels = [recruiters, hiringManagers, interviewers].filter(
    (arr) => arr.length > 0,
  ).length;
  const paddingBottomValue = 4 - numberOfPanels;

  return (
    <DeprecatedPanel className={classNames(props.className || 'p-3 my-2')}>
      <div className='fs-3 fw-semibold mb-2 p-2'>Hiring Team</div>
      <div className={classNames('text-dark-200 fs-5')}>
        <div className={classNames(pageCount > 1 && styles['hiring-team-div'])}>
          <HiringMembersTab
            members={recruiters}
            title='Recruiter'
            bgColor='bg-gray'
            paddingBottomValue={paddingBottomValue}
          />
          <HiringMembersTab
            members={hiringManagers}
            title='Hiring Manager'
            paddingBottomValue={paddingBottomValue}
            classNames='pt-0 mb-0'
          />
          <HiringMembersTab
            members={interviewers}
            title='Interviewers'
            paddingBottomValue={paddingBottomValue}
            classNames='pt-0 mb-0'
          />
        </div>
        {pageCount > 1 && (
          <Pagination
            page={currentPage}
            pageCount={pageCount}
            visiblePageCount={3}
            disabled={false}
            onPageClicked={(page) => setCurrentPage(page)}
            backLabel='Prev'
            hideFirstAndLastPageLinks={true}
            align='center'
          />
        )}
      </div>
    </DeprecatedPanel>
  );
}
