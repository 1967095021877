import React from 'react';
import { ModalBody } from './ModalBody';
import { CloseableModal } from '../../../../../components/CloseableModal';

interface PropTypes {
  isOpen: boolean;
  noteId: number;
  onClose: (state?: boolean) => void;
}

export function DeleteNoteModal(props: PropTypes) {
  return (
    <CloseableModal
      className='modal-dialog-centered'
      onClose={() => props.onClose(null)}
      isOpen={props.isOpen}
      size='md'
      closeDisabled={false}
      keyboard={true}
      backdrop={true}
      headerTitle='Delete Note'
      bodyChildren={<ModalBody noteId={props.noteId} onClose={props.onClose} />}
    />
  );
}
