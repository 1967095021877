import React from 'react';

import { Col } from 'reactstrap';

import styles from './styles.module.scss';

export function HeaderRow(props: { label: string; children: JSX.Element }) {
  return (
    <div className='d-flex flex-nowrap align-items-center'>
      <Col className={styles['header-label']}>{props.label}</Col>
      <Col className={styles['header-value']}>{props.children}</Col>
    </div>
  );
}
