import React from 'react';
import { Table } from 'reactstrap';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { SurveyQuestionAnswer } from '../../../../reference_check/entities/SurveyQuestionAnswer';
import { SurveyQuestionFieldAnswer } from '../../../../reference_check/entities/SurveyQuestionFieldAnswer';
import { findSurveyQuestionField } from '../utils/findSurveyQuestionField';
import { groupAnswersByField } from '../utils/groupAnswersByField';
import { SurveyQuestion } from '../../../../reference_check/entities/SurveyQuestion';
import { SurveyQuestionField } from '../../../../reference_check/entities/SurveyQuestionField';
import styles from './styles.module.scss';
import { SurveyModuleVersion } from '../../../../reference_check/entities/SurveyModuleVersion';
import classNames from 'classnames';

interface PropTypes {
  surveyModuleVersions: SurveyModuleVersion[];
  answers: SurveyQuestionAnswer[];
  filter: (v: { fieldAnswers: SurveyQuestionFieldAnswer[] }) => boolean;
  sort: (
    a: { question: SurveyQuestion; field: SurveyQuestionField; rating: number },
    b: { question: SurveyQuestion; field: SurveyQuestionField; rating: number },
  ) => number;
  inputLimit?: number;
  descriptionWhenEmpty: string;
  exportToPDF?: boolean;
}

export const SummaryScoreTable = React.memo(function SummaryScoreTable(
  props: PropTypes,
) {
  const groupedAnswers = groupAnswersByField(props.answers);
  const limit =
    props.inputLimit > groupedAnswers.length
      ? groupedAnswers.length
      : props.inputLimit;
  const highlights = groupedAnswers
    .filter(props.filter)
    .map((v) => {
      const questionField = findSurveyQuestionField(
        props.surveyModuleVersions,
        v.fieldId,
      );
      return {
        question: questionField.question,
        field: questionField.field,
        rating:
          v.fieldAnswers.reduce(
            (prev, curr) => prev + parseFloat(`${curr.value}`),
            0.0,
          ) / v.fieldAnswers.length,
      };
    })
    .sort((a, b) => props.sort(a, b))
    .slice(0, limit);

  return (
    <>
      {highlights.length > 0 && (
        <div className={classNames(getScrollingClasses(props.exportToPDF))}>
          <Table
            className='fs-5 text-primary align-middle'
            style={{ tableLayout: 'fixed' }}
          >
            <thead className='fw-semibold'>
              <tr>
                <th colSpan={4} scope='col' style={{ minWidth: '190px' }}>
                  Skill / Question
                </th>
                <th scope='col' className='col-3'>
                  Rating
                </th>
              </tr>
            </thead>
            <tbody>
              {highlights.map((h) => (
                <tr key={h.field.id} className={styles.tableRow}>
                  <td
                    colSpan={4}
                    className={classNames(
                      getTextColumnClasses(props.exportToPDF),
                    )}
                  >
                    <SpanTooltip
                      text={
                        h.field.name ||
                        h.question.display_name ||
                        h.question.title ||
                        ''
                      }
                      placement='left'
                    />
                  </td>
                  <td>{h.rating.toFixed(1)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {highlights.length === 0 && (
        <div
          className='d-flex justify-content-center'
          style={{ minHeight: '230px', maxHeight: '14rem' }}
        >
          <div className='align-self-center text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='3rem'
              height='3rem'
              fill='currentColor'
              className='bi bi-chat-right-text mb-3'
              viewBox='0 0 16 16'
              style={{ color: '#B8CBE5' }}
            >
              <path d='M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z'></path>
              <path d='M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z'></path>
            </svg>
            <p className='m-0 fw-bold'>No items to highlight here.</p>
            <span className='fs-6'>{props.descriptionWhenEmpty}</span>
          </div>
        </div>
      )}
    </>
  );
});

function getScrollingClasses(exportToPDF: boolean) {
  if (exportToPDF === true) {
    return styles.extended;
  } else {
    return styles.scrollable;
  }
}

function getTextColumnClasses(exportToPDF: boolean) {
  if (exportToPDF === true) {
    return styles.textColumnPDF;
  } else {
    return styles.textColumn;
  }
}
