import React from 'react';
import { StyledModal } from '../../../../../../components/StyledModal';
import { CloseEvaluationBody } from './CloseEvaluationBody';
import { CloseEvaluationFooter } from './CloseEvaluationFooter';

interface PropTypes {
  visible: boolean;
  onClose: () => void;
  onLeaveEvaluation: () => void;
}

export function CloseEvaluationModal(props: PropTypes) {
  return (
    <StyledModal
      isOpen={props.visible}
      title='Close Evaluation'
      onCancel={props.onClose}
      size={'lg'}
      bodyChildren={<CloseEvaluationBody />}
      footerChildren={
        <CloseEvaluationFooter
          onStay={props.onClose}
          onSubmit={props.onLeaveEvaluation}
        />
      }
      footerClassName='justify-content-center'
    />
  );
}
