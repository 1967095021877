import cloneDeep from 'lodash.clonedeep';
import React, { useEffect, useState } from 'react';
import { Filters } from '../../../../components/candidate_experience/Filters';
import {
  FilterStates,
  OnChangeFilterStatesCallback,
} from '../../../../components/candidate_experience/Filters/filterState';
import { FilterSelector } from '../../../../entities/candidate_experience/FilterSelector';
import { getDefaultSelectedFilter } from '../../../../utils/getDefaultSelectedFilter';
import { HiringTeamAnalyticsPanel } from './HiringTeamAnalyticsPanel';
import { HiringTeamTab } from './HiringTeamAnalyticsPanel/HiringTeamPanel/HiringTeamTable/TableTopTabs';

interface PropTypes {
  filterStates: FilterStates;
  onApplyFilter: OnChangeFilterStatesCallback;
}

export interface PageFilters {
  name: string;
  page: number;
  sortColumn: string;
  sortDirection: number;
  activeTab: HiringTeamTab;
}

function removeUneededFilters(filterStates: FilterStates) {
  return {
    ...filterStates,
    filterSelector: {
      ...filterStates.filterSelector,
      all: filterStates.filterSelector.all.filter(
        (filter) =>
          filter.value !== FilterSelector.HiringManagers &&
          filter.value !== FilterSelector.Recruiters,
      ),
      selected:
        filterStates.filterSelector.selected.value ===
          FilterSelector.HiringManagers ||
        filterStates.filterSelector.selected.value === FilterSelector.Recruiters
          ? getDefaultSelectedFilter()
          : filterStates.filterSelector.selected,
    },
  };
}

export function HiringTeamAnalyticsTab(props: PropTypes) {
  const [origAll, _setOrigAll] = useState(
    cloneDeep(props.filterStates.filterSelector.all),
  );
  const [filterStates, setFilterStates] = useState(
    removeUneededFilters(props.filterStates),
  );

  const [pageFilters, setPageFilters] = useState<PageFilters>({
    name: '',
    page: 1,
    sortColumn: 'name',
    sortDirection: 1,
    activeTab: HiringTeamTab.All,
  });

  useEffect(() => {
    setFilterStates(removeUneededFilters(props.filterStates));
  }, [props.filterStates]);

  function handleApplyFilter(
    newFilterStates: FilterStates,
    newMoreFilters: boolean,
  ) {
    newFilterStates = {
      ...newFilterStates,
      filterSelector: {
        ...newFilterStates.filterSelector,
        all: origAll,
      },
      hiringManagers: {
        ...newFilterStates.hiringManagers,
        selected: [],
      },
      recruiters: {
        ...newFilterStates.recruiters,
        selected: [],
      },
    };
    props.onApplyFilter(newFilterStates, newMoreFilters);
    setPageFilters({ ...pageFilters, page: 1 });
  }

  return (
    <>
      <Filters
        filterStates={filterStates}
        onApplyFilter={handleApplyFilter}
        moreFiltersShown={false}
        hideJob={false}
        hideMoreFiltersButton={true}
        candidateDemographicQuestionsEnabled={false}
        hideBenchmark={true}
        className='mb-4'
        hideHireTeam={true}
      />
      <HiringTeamAnalyticsPanel
        filterStates={props.filterStates}
        pageFilters={pageFilters}
        setPageFilters={setPageFilters}
      />
    </>
  );
}
