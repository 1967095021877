import { ApiService } from '../ApiService';
import { BackgroundCheck } from '../../entities/BackgroundCheck';
import { Screening } from '../../entities/Screening';

export class BackgroundCheckService {
  public static list(applicationId: number): Promise<BackgroundCheck[]> {
    return ApiService.get<BackgroundCheck[]>(
      `/applications/${applicationId}/background_checks`,
    );
  }

  public static invite(data: {
    applicationId: number;
    country: string;
    checks: { id: string; provider: string }[];
    email: string;
    optionalParams?: { id: string; value: string }[];
  }): Promise<BackgroundCheck[]> {
    return ApiService.post<BackgroundCheck[]>(
      `/applications/${data.applicationId}/background_checks`,
      {
        country: data.country,
        checks: data.checks,
        email: data.email,
        optional_params: data.optionalParams,
      },
    );
  }

  public static screenings(countryCode: string): Promise<Screening[]> {
    return ApiService.get<Screening[]>(`/api/background_check/screenings`, {
      country: countryCode,
    });
  }

  public static listEmploymentSectors(): Promise<string[]> {
    return ApiService.get<string[]>(`/api/background_check/employment_sectors`);
  }
}
