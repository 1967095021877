import React from 'react';
import { SentimentStackedBar } from './SentimentStackedBar';
import { Badge } from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { AutomaticAttribute } from '../../../../../entities/candidate_experience/AutomaticAttribute';

interface Proptypes {
  attribute: AutomaticAttribute;
}

export function SentimentTopicRow(props: Proptypes) {
  const denominator =
    props.attribute.positive_sentiment_count +
    props.attribute.neutral_sentiment_count +
    props.attribute.negative_sentiment_count;
  return (
    <tr>
      <td>
        <Badge
          className={classNames(
            'd-flex rounded-pill me-0 fs-5 fw-semibold text-right',
            styles.grayBadge,
          )}
        >
          {props.attribute.title}
        </Badge>
      </td>
      <td>
        <Badge
          className={classNames(
            'd-flex rounded-pill me-0 fs-5 fw-semibold justify-content-center',
            styles.grayBadge,
          )}
        >
          {props.attribute.mentions}
        </Badge>
      </td>
      <td>
        <Badge
          className={classNames(
            'd-flex rounded-pill me-0 fs-5 fw-semibold justify-content-center',
            styles.grayBadge,
          )}
        >
          {props.attribute.percentage_of_mentions.toFixed(0)}%
        </Badge>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        {
          <SentimentStackedBar
            percentagePositive={
              100 * (props.attribute.positive_sentiment_count / denominator)
            }
            percentageNeutral={
              100 * (props.attribute.neutral_sentiment_count / denominator)
            }
            percentageNegative={
              100 * (props.attribute.negative_sentiment_count / denominator)
            }
            countNegative={props.attribute.negative_sentiment_count}
            countNeutral={props.attribute.neutral_sentiment_count}
            countPositive={props.attribute.positive_sentiment_count}
          />
        }
      </td>
    </tr>
  );
}
