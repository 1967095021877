import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useParams, useNavigate, Navigate } from 'react-router';
import { Survey } from '../../entities/Survey';
import { SurveyService } from '../../services/SurveyService';
import { SurveyStartScreen } from './SurveyStartScreen';
import { SurveyScreen } from './SurveyScreen';
import { SurveyQuestion } from '../../entities/SurveyQuestion';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { ApiError } from '../../../services/ApiService/errors/ApiError';
import { SurveyLoadingStatus } from './SurveyLoadingStatus';
import { SurveyFinalScreen } from './SurveyFinalScreen';
import { NotInterestedProspectScreen } from './NotInterestedProspectScreen';
import { SurveyRoute } from './SurveyRoute';
import { AddProspectScreen } from './AddProspectScreen';
import {
  historyConfirmationEnabled,
  HistoryConfirmationScreen,
} from './HistoryConfirmationScreen';

type Params = 'id';

const START_PATH = 'start';
const QUESTIONS_PATH = 'step';
const FINAL_PATH = 'thank-you';
const ADD_PROSPECT_PATH = 'add-prospect';
const PROSPECT_NOT_INTERESTED_PATH = 'not-interested';

const getQuestions = (survey: Survey): SurveyQuestion[] => {
  if (survey === undefined) return;

  return survey.modules
    .map((m) => m.questions)
    .reduce((prev, curr) => prev.concat(curr), [])
    .filter((q) => q.id != null);
};

export function SurveyPage() {
  const [survey, setSurvey] = useState<Survey>();
  const [status, setStatus] = useState<SurveyLoadingStatus>('LOADING');
  const { id } = useParams<Params>();
  const navigate = useNavigate();

  const outOfFlowSteps = historyConfirmationEnabled(survey) ? 1 : 0;

  useEffect(() => {
    (async () => {
      setStatus('LOADING');
      try {
        const survey = await SurveyService.get(id);
        setSurvey(survey);
        setStatus('OK');
      } catch (err) {
        if ((err as ApiError).status === 404) setStatus('SURVEY_NOT_FOUND');
        else setStatus('LOAD_ERROR');
      }
    })();
  }, [id]);

  useEffect(() => {
    if (survey === undefined) return;
  }, [survey]);

  const startSurvey = useCallback(() => navigate(`${QUESTIONS_PATH}/1`), []);
  const finishSurvey = useCallback(async () => {
    setStatus('LOADING');
    const updatedSurvey = await SurveyService.submit(survey.secure_id);
    setSurvey(updatedSurvey);
    navigate(FINAL_PATH);
    setStatus('OK');
  }, [survey]);
  const openNotInterestedProspect = useCallback(
    () => navigate(PROSPECT_NOT_INTERESTED_PATH),
    [],
  );

  function navigateToFirstStep() {
    if (historyConfirmationEnabled(survey)) {
      navigate(`${QUESTIONS_PATH}/history_confirmation`);
    } else {
      startSurvey();
    }
  }

  if (survey === undefined) return <LoadingSpinner showBackdrop />;

  return (
    <>
      {status !== 'OK' ? <LoadingSpinner showBackdrop /> : null}
      <Routes>
        <Route
          index
          element={
            survey?.status === 'pending' ? (
              <Navigate to={START_PATH} />
            ) : survey?.status === 'completed' ? (
              <Navigate to={FINAL_PATH} />
            ) : null
          }
        />
        <Route
          path={START_PATH}
          element={
            <SurveyRoute survey={survey} status='pending' mismatchPath='..'>
              <SurveyStartScreen
                survey={survey}
                onStartSurvey={() => navigateToFirstStep()}
              />
            </SurveyRoute>
          }
        />
        <Route
          path={`${QUESTIONS_PATH}/history_confirmation`}
          element={
            <SurveyRoute survey={survey} status='pending' mismatchPath='..'>
              <HistoryConfirmationScreen
                survey={survey}
                onNextClick={startSurvey}
                onSurveyUpdate={setSurvey}
              />
            </SurveyRoute>
          }
        />
        <Route
          path={`${QUESTIONS_PATH}/:step`}
          element={
            <SurveyRoute survey={survey} status='pending' mismatchPath='..'>
              <SurveyScreen
                survey={survey}
                questions={getQuestions(survey)}
                onChangeStatus={setStatus}
                onUpdateSurvey={setSurvey}
                onFinishSurvey={finishSurvey}
                outOfFlowSteps={outOfFlowSteps}
              />
            </SurveyRoute>
          }
        />
        <Route
          path={FINAL_PATH}
          element={
            <SurveyRoute survey={survey} status='completed' mismatchPath='..'>
              <SurveyFinalScreen survey={survey} />
            </SurveyRoute>
          }
        />
        <Route
          path={ADD_PROSPECT_PATH}
          element={
            <SurveyRoute survey={survey} status='completed' mismatchPath='..'>
              <AddProspectScreen
                survey={survey}
                onNotInterestedProspect={openNotInterestedProspect}
              />
            </SurveyRoute>
          }
        />
        <Route
          path={PROSPECT_NOT_INTERESTED_PATH}
          element={
            <SurveyRoute survey={survey} status='completed' mismatchPath='..'>
              <NotInterestedProspectScreen survey={survey} />
            </SurveyRoute>
          }
        />
      </Routes>
    </>
  );
}
