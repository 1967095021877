import React from 'react';
import { TimelineBar } from '../TimelineBar';
import { participantsToColor } from '../../../utils/participantsToColor';
import { Col, Row } from 'reactstrap';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { Monologue } from '../../../entities/v1/interview_intelligence/Monologue';
import { Participant } from '../../../entities/v1/interview_intelligence/Participant';
import { InterviewSecret } from '../../../entities/InterviewSecret';

interface PropTypes {
  interviewId: number;
  interviewSecrets: InterviewSecret[];
  monologues: Monologue[];
  totalTime: number;
  speaker: Participant;
  otherSpeakers: Participant[];
  onSelectTime: (time: number) => void;
  onSwapParticipants: (
    interviewId: number,
    participantAId: number,
    participantBId: number,
  ) => void;
}

export const SpeakerSegments = React.memo(function SpeakerSegments(
  props: PropTypes,
) {
  const participants = [...props.otherSpeakers, props.speaker];
  const color = participantsToColor(participants)[props.speaker.id];
  const segments = props.monologues.map((v) => ({
    start: v.startOffsetMs,
    end: v.endOffsetMs,
  }));

  function handleSwapClick(id: number) {
    props.onSwapParticipants(props.interviewId, props.speaker.id, id);
  }

  return (
    <Row className={classNames('fs-6', styles.row)}>
      <Col className={classNames('col-auto', styles.fontWeightHeavy)}>
        <a
          className='dropdown-toggle'
          id='speaker-swap-dropdown-toggle'
          data-toggle='dropdown'
          data-bs-toggle='dropdown'
          href='#'
          role='button'
          aria-expanded='false'
        >
          {props.speaker.displayName}
        </a>
        <ul
          className='dropdown-menu'
          role='menu'
          aria-labelledby='speaker-swap-dropdown-toggle'
          style={{ margin: '0px' }}
        >
          <li id='speaker-swap-dropdown-header'>
            <h4 className='dropdown-header'>Swap Speaker</h4>
          </li>

          {props.otherSpeakers.map((otherSpeaker) => (
            <li key={String(otherSpeaker.id)} className='dropdown-item'>
              <a href='#' onClick={() => handleSwapClick(otherSpeaker.id)}>
                <div className={classNames('fs-6')}>
                  {otherSpeaker.displayName}
                </div>
              </a>
            </li>
          ))}
        </ul>
      </Col>
      <Col className={classNames('col-auto')}>
        {props.speaker.isCandidate ? 'Candidate' : ''}
      </Col>
      <Col className={classNames('col text-end')} style={{ color }}>
        {props.speaker.insights?.talkRatio ?? 0}%
      </Col>
      <Col className={classNames('col-12')}>
        <TimelineBar
          segments={segments}
          secrets={props.interviewSecrets}
          totalTime={props.totalTime}
          color={color}
          onSelectTime={props.onSelectTime}
        />
      </Col>
    </Row>
  );
});
