import React from 'react';
import { Col, Row } from 'reactstrap';
import { Panel } from '../../../components/Panel';
import { Survey } from '../../../entities/Survey';

interface PropTypes {
  survey: Survey;
  onStartSurvey: () => void;
}

export function ReferenceMainPanel({ survey, onStartSurvey }: PropTypes) {
  return (
    <Panel>
      <h3>Thank you for providing feedback on {survey.candidate.name}.</h3>
      <p className='mb-4'>
        The feedback and insight you provide will help us understand the
        candidate&apos;s skills, experience, strengths, and weaknesses - and
        help shape their onboarding plan.
      </p>
      <Row className='align-items-center mb-3'>
        <Col xs='auto'>
          <i className='bi bi-clock' style={{ fontSize: '1.3em' }} />
        </Col>
        <Col>
          We estimate that completing this survey will take you less than 10
          minutes.
        </Col>
      </Row>
      <Row className='align-items-center mb-3'>
        <Col xs='auto'>
          <i className='bi bi-lock' style={{ fontSize: '1.3em' }} />
        </Col>
        <Col>
          Your reference is confidential and only shared with the new employer.
          Please be honest and thoughtful when answering these questions.
        </Col>
      </Row>
      <hr />
      <div className='text-center mb-4'>
        <a
          href='#start'
          onClick={onStartSurvey}
          className='btn btn-primary p-3 px-5'
        >
          Begin Survey
        </a>
      </div>
      <div className='text-center text-muted' style={{ fontSize: '0.9em' }}>
        We take security and privacy seriously. All of the data we collect is
        completely secure and confidential between you and your new employer.
        <br />
        If you have any questions, please contact us at{' '}
        <a href='mailto:support@screenloop.com'>support@screenloop.com</a>.
      </div>
    </Panel>
  );
}
