import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import {
  RejectedBy,
  RejectedReason,
} from '../../../../entities/v1/applicant_tracking/RejectedReason';

export class RejectedReasonService {
  public static show(id: number): Promise<RejectedReason> {
    return ApiService.get<RejectedReason>(
      `/api/v1/applicant_tracking/rejected_reasons/${id}`,
    );
  }

  public static batchCreate(
    rejectedBy: RejectedBy,
    reasons: string[],
  ): Promise<void> {
    return ApiService.post<void>(
      `/api/v1/applicant_tracking/rejected_reasons/batch_create`,
      snakecaseKeys({ rejectedBy, reasons }),
    );
  }

  public static update(
    id: number,
    name: string,
    rejectedBy: RejectedBy,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/rejected_reasons/${id}`,
      snakecaseKeys({ name, rejectedBy }),
    );
  }

  public static destroy(id: number): Promise<void> {
    return ApiService.delete<void>(
      `/api/v1/applicant_tracking/rejected_reasons/${id}`,
    );
  }
}
