import React, { useEffect, useState } from 'react';
import { WindowWithHapi } from '../..';
import { Campaign } from '@vonq/hapi-elements-types';
import { CampaignDetail } from './CampaignDetail';
import { CampaignReportingHeader } from './CampaignReportingHeader';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { EmptyState } from '../../../../../components/EmptyState';
import UndrawEmptyState from '../../../../../images/undraw_empty_state_cactus.svg';

interface PropTypes {
  jobId: string;
  window: WindowWithHapi;
}

function NoCampaignsEmptyState() {
  return (
    <EmptyState
      title='No Campaign data yet'
      text={'Create your campaign and monitor its performance here.'}
      imageSrc={UndrawEmptyState}
    />
  );
}

export function CampaignReportingView(props: PropTypes) {
  const [campaigns, setCampaigns] = useState<Campaign[]>(null);

  useEffect(() => {
    (async () => {
      const loadedCampaigns = await props.window.hapi.campaign.api.getCampaigns(
        0,
        100,
        { jobId: props.jobId },
      );
      setCampaigns(loadedCampaigns.data);
    })();
  }, []);

  if (campaigns == null) {
    return <LoadingSpinner />;
  }

  if (campaigns.length === 0) {
    return <NoCampaignsEmptyState />;
  } else {
    return (
      <>
        <CampaignReportingHeader />
        {campaigns.map((campaign: Campaign, index: number) => (
          <CampaignDetail
            key={index}
            campaign={campaign}
            opened={index === 0}
          />
        ))}
      </>
    );
  }
}
