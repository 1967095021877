import { TopSourcingChannels } from '../../../../entities/applicant_tracking/analytics/TopSourcingChannels';
import { ApiService } from '../../../ApiService';

export class TopSourcingChannelsService {
  public static show(): Promise<TopSourcingChannels> {
    return ApiService.get(
      '/api/applicant_tracking/analytics/top_sourcing_channels',
    );
  }
}
