import { Survey } from '../../../entities/survey_engine/Survey';
import { SurveyQuestionAnswer } from '../../../entities/survey_engine/SurveyQuestionAnswer';
import { ApiService } from '../../ApiService';

export class SurveyQuestionService {
  public static answer(
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ): Promise<Survey> {
    return ApiService.post<Survey>(
      `/api/quality_of_hire/surveys/${surveyId}/survey_questions/${answer.question_id}/answer`,
      { answer },
    );
  }
}
