import {
  HiringManagerEmployeeEvalSurvey,
  LeftReason,
} from '../../../entities/survey_engine/HiringManagerEmployeeEvalSurvey';
import { ApiService } from '../../ApiService';

export class HiringManagerEmployeeEvalSurveyService {
  public static update(
    id: number,
    partOfTeam: boolean,
    leftReason: LeftReason,
  ): Promise<HiringManagerEmployeeEvalSurvey> {
    return ApiService.patch<HiringManagerEmployeeEvalSurvey>(
      `/api/quality_of_hire/hiring_manager_employee_eval_surveys/${id}`,
      {
        part_of_team: partOfTeam,
        left_reason: leftReason,
      },
    );
  }
}
