import React from 'react';
import { RateOption } from '../../../entities/RateOption';
import { SpanTooltip } from '../../../components/SpanTooltip';
import styles from '../styles.module.scss';

export function SingleScore(props: {
  score: RateOption;
  username?: string;
  logoAtStart?: boolean;
}) {
  return (
    <>
      <div>
        {props.username ? (
          <SpanTooltip
            text={
              <div
                className={`d-flex align-items-center justify-content-center bg-${props.score.color} rounded-circle me-2 ${styles['rating-icon-pill']}`}
              >
                <i className={`bi ${props.score.icon} text-white`}></i>
              </div>
            }
            tooltipText={
              <>
                <div className='fw-semibold fs-5'>{props.username}</div>
                {props.score.title}
              </>
            }
            tooltipFade={false}
            className={styles.score}
          />
        ) : (
          <div className={`text-${props.score.color}`}>
            {props.logoAtStart ? (
              <>
                <i className={`bi ${props.score.icon} me-2`}></i>
                {props.score.title}
              </>
            ) : (
              <>
                {props.score.title}
                <i className={`bi ${props.score.icon} ms-2`}></i>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
