import { FilterStates } from './filterState';
import { updateFilterState } from './updateFilterState';
import { FilterState } from './update';
import * as queryString from 'query-string';

export function generateQueryString(filterStates: FilterStates) {
  return queryString.stringify(
    {
      filter_selector: filterStates.filterSelector.selected.value,
      jobs: filterStates.jobs.selected.map((option) => option.value),
      departments: filterStates.departments.selected.map(
        (option) => option.value,
      ),
      hiringManagers: filterStates.hiringManagers.selected.map(
        (option) => option.value,
      ),
      recruiters: filterStates.recruiters.selected.map(
        (option) => option.value,
      ),
      locations: filterStates.locations.selected.map((option) => option.value),
      surveyTemplates: filterStates.surveyTemplates.selected.map(
        (option) => option.value,
      ),
      benchmarkType: filterStates.benchmarkType.selected.value,
      startDate: filterStates.dateRange.start.toJSON(),
      endDate: filterStates.dateRange.end.toJSON(),
      dateRange: filterStates.dateRange.range,
      ethnicities: filterStates.ethnicities.selected.map(
        (option) => option.value,
      ),
      genders: filterStates.genders.selected.map((option) => option.value),
      ageRanges: filterStates.ageRanges.selected.map((option) => option.value),
      npsAttributes: filterStates.npsAttributes.selected.map(
        (option) => option.value,
      ),
    },
    {
      skipNull: true,
    },
  );
}

export function setFilters(
  initFilters: FilterState,
  searchParams: URLSearchParams,
) {
  const filterStateUpdates = {
    filterSelector: searchParams?.get('filter_selector'),
    jobs: searchParams?.getAll('jobs'),
    departments: searchParams?.getAll('departments'),
    hiringManagers: searchParams?.getAll('hiringManagers'),
    recruiters: searchParams?.getAll('recruiters'),
    surveyTemplates: searchParams?.getAll('surveyTemplates'),
    benchmarkType: searchParams?.get('benchmarkType'),
    startDate: searchParams?.get('startDate'),
    endDate: searchParams?.get('endDate'),
    dateRange: searchParams?.get('dateRange'),
    locations: searchParams?.getAll('locations'),
    ethnicities: searchParams?.getAll('ethnicities'),
    genders: searchParams?.getAll('genders'),
    ageRanges: searchParams?.getAll('ageRanges'),
    npsAttributes: searchParams?.getAll('npsAttributes'),
  };

  return updateFilterState(initFilters, filterStateUpdates);
}
