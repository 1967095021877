import { Controller } from 'stimulus';
import { ATSMember } from '../entities/ATSMember';
import { ApiService } from '../services/ApiService';

export default class extends Controller {
  static targets = ['accessToken', 'subdomain', 'members', 'refreshMembers'];

  connect() {
    this.refreshMembersButton.addEventListener('click', () =>
      this.refreshMembers(),
    );
    if (
      ['lever', 'teamtailor'].includes(
        this.membersSelect.dataset.integrationType,
      ) ||
      (this.hasAccessTokenInput && this.accessTokenInput?.value.length !== 0) ||
      this.subdomainInput.value.length !== 0
    )
      this.refreshMembers();
  }

  private async refreshMembers() {
    try {
      this.setRefreshMembersSelectLoadingState();
      await this.loadMemberOptions();
    } catch (err) {
      this.setFailedMembersLoadState();
    }
  }

  private setRefreshMembersSelectLoadingState() {
    this.membersSelect.innerHTML = '';
    const loadingOption = document.createElement('option');
    loadingOption.selected = true;
    loadingOption.textContent = 'Loading...';
    this.membersSelect.append(loadingOption);
  }

  private setFailedMembersLoadState() {
    this.membersSelect.innerHTML = '';
    const failedOption = document.createElement('option');
    failedOption.selected = true;
    failedOption.textContent =
      'Failed to load members. Please review the setup and try again.';
    this.membersSelect.append(failedOption);
  }

  private async loadMemberOptions() {
    let response = [];
    switch (this.membersSelect.dataset.integrationType) {
      case 'workable':
        response = await ApiService.get<ATSMember[]>(
          '/organization_settings/integrations/workable/members',
          this.build_workable_parameters(),
        );
        break;
      case 'lever':
        response = await ApiService.get<ATSMember[]>(
          '/organization_settings/integrations/lever/members',
          { id: this.membersSelect.dataset.integrationId },
        );
        break;
      case 'teamtailor':
        response = await ApiService.get<ATSMember[]>(
          `/organization_settings/integrations/teamtailor/${this.membersSelect.dataset.integrationId}/members`,
        );
        break;
    }

    this.membersSelect.innerHTML = '';
    response
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((v) => {
        const option = document.createElement('option');
        option.value = v.id;
        option.textContent = v.name;
        option.selected = v.id === this.membersSelect.dataset.value;
        return option;
      })
      .forEach((v) => this.membersSelect.append(v));
  }

  private build_workable_parameters(): object {
    if (this.hasAccessTokenInput) {
      return {
        access_token: this.accessTokenInput.value,
        subdomain: this.subdomainInput.value,
      };
    }

    return {
      id: this.membersSelect.dataset.integrationId,
      subdomain: this.subdomainInput.value,
    };
  }

  private get hasAccessTokenInput(): boolean {
    return (this as any).hasAccessTokenTarget;
  }

  private get accessTokenInput(): HTMLInputElement {
    if (this.hasAccessTokenInput) {
      return (this as any).accessTokenTarget;
    }

    return null;
  }

  private get subdomainInput(): HTMLInputElement {
    return (this as any).subdomainTarget;
  }

  private get membersSelect(): HTMLSelectElement {
    return (this as any).membersTarget;
  }

  private get refreshMembersButton(): HTMLAnchorElement {
    return (this as any).refreshMembersTarget;
  }
}
