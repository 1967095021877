import React from 'react';
import { EditButton } from '../editButton/index';
import { TextArea } from '../textArea';

interface PropTypes {
  jobStageName: string;
  candidateName: string;
  setDisableEditEmailBody: (disableEditEmailBody: boolean) => void;
  disableEditEmailBody: boolean;
  emailBody: string;
  setEmailBody: (email: string) => void;
  defaultEmailLength: number;
}

function bodyText(jobStageName: string, candidateName: string) {
  return (
    <>
      {jobStageName && (
        <p className='mb-0 fw-bold'>
          {candidateName} is currently in the {jobStageName} job stage
        </p>
      )}
      <p className='mt-3'>
        Use the default message or edit it before you send the survey email to
        the candidate.
      </p>
    </>
  );
}

export function Body(props: PropTypes) {
  const [count, setCount] = React.useState(props.defaultEmailLength);
  return (
    <>
      {bodyText(props.jobStageName, props.candidateName)}
      <div className='d-flex align-items-center justify-content-between mb-2'>
        <span className='fw-semibold fs-5'>Email Message</span>
        <EditButton
          setDisableEditEmailBody={props.setDisableEditEmailBody}
          disableEditEmailBody={props.disableEditEmailBody}
        />
      </div>
      <TextArea
        disableEditEmailBody={props.disableEditEmailBody}
        emailBody={props.emailBody}
        setEmailBody={props.setEmailBody}
        setCount={setCount}
        count={count}
      />
    </>
  );
}
