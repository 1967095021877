import React from 'react';
import { Routes, Route } from 'react-router';
import { EmptyState } from '../../../../components/EmptyState';
import { CreateJobStartPage } from './CreateJobStartPage';

export function CreateJobPageRoutes() {
  return (
    <Routes>
      <Route>
        <Route path={'start'} element={<CreateJobStartPage />} />
        <Route
          path={'*'}
          element={
            <EmptyState
              title={'Page not found!'}
              text={
                'For more information contact our support team at support@screenloop.com'
              }
              cardless={true}
            />
          }
        />
      </Route>
    </Routes>
  );
}
