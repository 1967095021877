import { GlobalState } from 'little-state-machine';
import { Control } from 'react-hook-form';
import { SelectOption } from '../components/Select';

interface ArrayTypeMetadata {
  name: string;
  index: number;
  attribute: string;
}

export interface ArrayContext {
  arrayData: any[];
  updateArrayData: (data: any[]) => void;
  getArrayMetadata: () => ArrayTypeMetadata;
}

export interface ControllerContext<ControlType> {
  controllerName: string;
  required?: boolean;
  validate?: (val) => boolean;
  formControl: Control<ControlType>;
}

/*
This will create a key in the local session store to store every input value.
Avoid input's keyname collisions under the same key.
*/
export function keyedUpdateAction(
  stateKey: string,
): (state: GlobalState, payload: any) => GlobalState {
  return function (state: GlobalState, payload: any) {
    return {
      ...state,
      [stateKey]: {
        ...(stateKey === 'setupJob' && state.setupJob),
        ...payload,
      },
    };
  };
}

/*
When the select is managed by the useFieldArray method, its state will be stored in the format: <array_name>.<index>.<attribute>

e.g.
Given the array:
 - employees = [{name: john, age: 13}, {name: Jane, age: 14}]

We will get the follwoing elements in the form state:
 - employees.0.name: john
 - employees.0.age: 13
 - employees.1.name: jane
 - employees.1.age: 14

Metadata: holds the metadata information of the clicked element: name, index, attribute.
e.g. employees, 0, name
ArrayData: holds the information on the values of the array.

Defaults option?.value to null as React Form Hooks misbehaves when it receives an undefined.

All the "..." and "slice" is just an immutable way of updating an array.
*/
export function updateArrayAttributeAt(
  arrayData: any[],
  metadata: ArrayTypeMetadata,
  option: SelectOption,
): any[] {
  return [
    ...arrayData.slice(0, metadata.index),
    {
      ...arrayData[metadata.index],
      ...{ [metadata.attribute]: option?.value ?? null },
    },
    ...arrayData.slice(metadata.index + 1),
  ];
}
