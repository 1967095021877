import React from 'react';
import { StarRating } from '../../../../../../../components/StarRating';
import { ApplicationRating } from '../../../../../../../entities/v1/applicant_tracking/ApplicationRating';

interface PropTypes {
  applicationRating: ApplicationRating;
}

export function CandidateStarRating(props: PropTypes) {
  return (
    <div className='d-flex fs-6 align-items-center'>
      <StarRating
        editable={false}
        rating={props.applicationRating.rating}
        fillColorClass='text-rating-color'
      />
      <span>
        <span className='fw-bold ms-2'>{props.applicationRating.rating}</span>{' '}
        <span className='text-dark-200'>Candidate Rating</span>
      </span>
    </div>
  );
}
