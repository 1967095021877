import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../ApiService';
import { JobStageList } from '../../../entities/v1/JobStageList';

export interface ListParams {
  name?: string;
  page?: number;
  pageSize?: number;
  expand?: string[];
  sort_column?: string;
  sort_direction?: string;
}

export class JobStageService {
  public static list(params: ListParams): Promise<JobStageList> {
    return ApiService.get<JobStageList>(
      '/api/v1/job_stages',
      snakecaseKeys(params),
    );
  }
}
