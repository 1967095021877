import { SurveyQuestion } from '../../entities/survey_engine/SurveyQuestion';
import { SurveyQuestionAnswer } from '../../entities/survey_engine/SurveyQuestionAnswer';
import { getSurveyQuestionsWithConditionsVisibility } from './getSurveyQuestionsWithConditionsVisibility';

function isQuestionDependent(
  question: SurveyQuestion,
  questionsWithConditions: {
    questionVisible: boolean;
    dependentQuestionId: number;
  }[],
) {
  return questionsWithConditions
    .map((q) => q.dependentQuestionId)
    .includes(question.id);
}

function isQuestionWithConditionVisible(
  question: SurveyQuestion,
  questionsWithConditions: {
    questionVisible: boolean;
    dependentQuestionId: number;
  }[],
) {
  return questionsWithConditions
    .filter((q) => q.questionVisible)
    .map((q) => q.dependentQuestionId)
    .includes(question.id);
}

export function getQuestionsBasedOnConditions(
  questions: SurveyQuestion[],
  answers: SurveyQuestionAnswer[],
): SurveyQuestion[] {
  if (questions === undefined) return;

  const questionsWithConditions = getSurveyQuestionsWithConditionsVisibility(
    questions,
    answers,
  );

  return questions.filter(
    (question) =>
      !isQuestionDependent(question, questionsWithConditions) ||
      isQuestionWithConditionVisible(question, questionsWithConditions),
  );
}
