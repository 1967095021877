import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { DeprecatedPanel } from '../../../../components/DeprecatedPanel';
import { SurveyQuestionAnswer } from '../../../../reference_check/entities/SurveyQuestionAnswer';
import { SurveyQuestionField } from '../../../../reference_check/entities/SurveyQuestionField';
import { SurveyQuestionFieldAnswer } from '../../../../reference_check/entities/SurveyQuestionFieldAnswer';
import { SurveyRequest } from '../../../../reference_check/entities/SurveyRequest';
import { SCORE_LEGEND } from '../ScoreLegend';
import 'react-circular-progressbar/dist/styles.css';
import { extractQuestionsFromModuleVersions } from '../../../../reference_check/utils/extractQuestionsFromModuleVersions';

interface PropTypes {
  surveyRequest: SurveyRequest;
  answers: SurveyQuestionAnswer[];
  className?: string;
}

export const OverallScoreCard = React.memo(function OverallScoreCard(
  props: PropTypes,
) {
  if (props.answers.length === 0) return null;

  const surveyModuleVersions =
    props.surveyRequest.survey_request_survey_module_versions.map(
      (srsmv) => srsmv.survey_module_version,
    );

  const fieldIds = extractQuestionsFromModuleVersions(surveyModuleVersions)
    .reduce<SurveyQuestionField[]>((prev, curr) => prev.concat(curr.fields), [])
    .filter((field) => field.type === 'rating')
    .map((f) => f.id);
  const fieldAnswers = props.answers
    .reduce<SurveyQuestionFieldAnswer[]>(
      (prev, curr) => prev.concat(curr.fields),
      [],
    )
    .filter((f) => f.value != null)
    .filter((f) => fieldIds.indexOf(f.field_id) !== -1);
  const rateSum = fieldAnswers.reduce(
    (prev, curr) => prev + parseInt(`${curr.value}`, 10),
    0,
  );
  const score = rateSum / fieldAnswers.length / 5.0;
  const scorePerc = (score * 100).toFixed(0);

  const scoreAboveIndex = SCORE_LEGEND.findIndex((v) => v.minScore > score);
  const scoreIndex =
    scoreAboveIndex === -1 ? SCORE_LEGEND.length - 1 : scoreAboveIndex - 1;
  const scoreLegend = SCORE_LEGEND[scoreIndex];

  return (
    <DeprecatedPanel
      style={{ minHeight: '19.25rem' }}
      className={props.className}
      additionalInnerContainerClassNames='p-4'
    >
      <div className='d-flex flex-column'>
        <h2>Overall Score</h2>
        <div className='mt-2'>
          <div className='mx-auto my-3 px-1' style={{ maxWidth: '150px' }}>
            <CircularProgressbar
              value={score}
              maxValue={1}
              text={`${scorePerc}%`}
              styles={buildStyles({
                pathColor: scoreLegend.color,
                textColor: '#787CA3',
              })}
              className={'h2'}
            />
          </div>
          <div className='text-center mt-2'>
            <h3 className='mb-0' style={{ color: scoreLegend.color }}>
              {scoreLegend.label}
            </h3>
          </div>
        </div>
      </div>
    </DeprecatedPanel>
  );
});
