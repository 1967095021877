import React, { FormEvent } from 'react';
import { Application } from '../../../../../entities/Application';
import { Button } from 'reactstrap';
import { ApplicationService } from '../../../../../services/applicant_tracking/ApplicationService';

interface PropTypes {
  application: Application;
  onClose: (succeeded?: boolean) => void;
}

export function ModalBody(props: PropTypes) {
  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      await ApplicationService.unreject(props.application.id);
      props.onClose(true);
    } catch (error) {
      props.onClose(false);
      console.error(error);
    }
  }

  return (
    <div>
      <div className='mb-4 fs-5'>
        Unreject <b>{props.application.candidate.name}</b> for the{' '}
        <b>{props.application.job.name}</b> job?
      </div>
      <form onSubmit={handleSubmit}>
        <div className='d-flex mt-5 justify-content-end'>
          <Button
            className='me-2'
            color='danger'
            onClick={() => props.onClose(null)}
          >
            Cancel
          </Button>
          <Button color='primary' type='submit'>
            Unreject
          </Button>
        </div>
      </form>
    </div>
  );
}
