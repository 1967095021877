import { ProductDuration } from '@vonq/hapi-elements-types';
import classNames from 'classnames';
import { Moment } from 'moment';
import React from 'react';
import { ReactNode } from 'react';
import { Col } from 'reactstrap';
import { momentToDateStr } from '../../../../../../utils/momentToDateStr';
import { TruncateText } from '../../../../../../components/TruncateText';
import { dateStrToMoment } from '../../../../../../utils/dateStrToMoment';

interface PropTypes {
  classNames?: string;
  highlightName: boolean;
  name: string;
  updatedAt?: string;
  datePosted?: string;
  duration?: ProductDuration;
  clicks?: number | string;
  cost?: ReactNode;
  status: ReactNode;
}

function addDurationToMoment(
  duration?: ProductDuration,
  moment?: Moment,
): Moment {
  if (moment == null || duration == null) {
    return null;
  }

  // There is an overlap between the add signature and the period: "hours" | "days" | "weeks" | "months"
  return moment.add(duration.period, duration.range);
}

function ColDetail(props: { classNames?: string; data?: ReactNode }) {
  return (
    <div
      className={classNames(
        props.classNames,
        'fs-5',
        'fw-normal',
        'text-truncate',
      )}
    >
      {props.data ?? '-'}
    </div>
  );
}

// CampaignReportingHeader should have the same Cols definition.
export function CampaignRow(props: PropTypes) {
  const datePosted = dateStrToMoment(props.datePosted);

  return (
    <div className={classNames(props.classNames, 'd-flex flex-nowrap w-100')}>
      <Col xs='3'>
        <TruncateText
          tooltip
          className={
            props.highlightName ? 'text-info fw-bold fs-5' : 'fs-5 fw-normal'
          }
          text={props.name}
        />
      </Col>
      <Col xs='2'>
        <ColDetail data={momentToDateStr(dateStrToMoment(props.updatedAt))} />
      </Col>
      <Col xs='2'>
        <ColDetail data={momentToDateStr(datePosted)} />
      </Col>
      <Col xs='1'>
        <ColDetail
          data={momentToDateStr(
            addDurationToMoment(props.duration, datePosted),
          )}
        />
      </Col>
      <Col xs='1'>
        <ColDetail data={props.clicks} />
      </Col>
      <Col xs='1'>
        <ColDetail data={props.cost} />
      </Col>
      <Col xs='2'>
        <ColDetail data={props.status} />
      </Col>
    </div>
  );
}
