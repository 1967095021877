import classNames from 'classnames';
import React from 'react';
import { Col } from 'reactstrap';
import { SpanTooltip } from '../../../../../../../components/SpanTooltip';
import { ScorecardAttribute } from '../../../../../../../entities/ScorecardAttribute';
import styles from './styles.module.scss';

interface PropTypes {
  activeAttribute: ScorecardAttribute;
  onScorecardAttributesUpdated: (score: number) => void;
}

export function ScorecardAttributeScore(props: PropTypes) {
  return (
    <>
      <SpanTooltip
        tooltipText={`Rate ${props.activeAttribute?.name}`}
        className='text-truncate me-2'
        text={
          <span>
            Rate <b>{props.activeAttribute?.name}</b>
          </span>
        }
      />
      <Col tag={'span'} xs='auto' className='flex-nowrap'>
        {[1, 2, 3, 4, 5].map((rate: number) => (
          <button
            key={rate}
            disabled={props.activeAttribute == null}
            onClick={() => props.onScorecardAttributesUpdated(rate)}
            className={classNames(
              styles.score,
              'btn',
              'btn-sm',
              'btn-secondary',
              'me-2',
            )}
          >
            {rate}
          </button>
        ))}
      </Col>
    </>
  );
}
