import React from 'react';
import { Control } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { LabelledSelectFormHook } from '../../../../../../components/LabelledSelectFormHook';
import { SelectOption } from '../../../../../../components/Select';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';

// LinkedIn Experience Level codes
// https://learn.microsoft.com/en-us/linkedin/shared/references/reference-tables/experience-level-codes
const EXPERIENCE_LEVEL_OPTIONS: SelectOption[] = [
  { label: 'Internship', value: 'internship' },
  { label: 'Entry Level', value: 'entry_level' },
  { label: 'Associate', value: 'associate' },
  { label: 'Middle to Senior Level', value: 'mid_senior_level' },
  { label: 'Director Level', value: 'director' },
  { label: 'Executive', value: 'executive' },
  { label: 'Not Applicable', value: 'not_applicable' },
];

interface PropTypes {
  formControl: Control<CreateJobDto>;
}

export function ExperienceLevelSelect(props: PropTypes) {
  const label = 'Experience level';

  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <LabelledSelectFormHook<CreateJobDto>
          label={label}
          placeholder={`Select ${label.toLowerCase()}`}
          formHookContext={{
            controllerName: 'experienceLevel',
            formControl: props.formControl,
            required: false,
          }}
          options={EXPERIENCE_LEVEL_OPTIONS}
        />
      </Col>
    </Row>
  );
}
