import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { OfferService } from '../../../../../services/applicant_tracking/OfferService';
import { Alert, AlertType } from '../../../../../components/Alert';
import { DeclineOfferModal } from './DeclineOfferModal';

interface PropTypes {
  id: string;
  disabled: boolean;
  rejectUrl: string;
  acceptUrl: string;
}

function DangerOfferAlert(props: { action: string }) {
  return (
    <span>
      An error occurred and the offer was not {props.action}. Please try again
      later or contact our{' '}
      <a className='text-info' href='mailto:support@screenloop.com'>
        support email
      </a>
      .
    </span>
  );
}

export function OfferActions(props: PropTypes) {
  if (!props.id) {
    return null;
  }

  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [alert, setAlert] = useState<React.ReactNode>(null);

  async function handleAccept() {
    try {
      await OfferService.accept(props.id);
      window.location.href = props.acceptUrl;
    } catch (e: any) {
      setAlert(<DangerOfferAlert action='accepted' />);
    }
  }

  async function handleReject(reasons: string[]) {
    try {
      await OfferService.reject(props.id, reasons);
      window.location.href = props.rejectUrl;
    } catch (e: any) {
      setAlert(<DangerOfferAlert action='rejected' />);
    }

    setDeclineModalOpen(false);
  }

  return (
    <>
      {alert && (
        <Alert
          type={AlertType.Danger}
          clearable={true}
          onClose={() => setAlert(null)}
        >
          {alert}
        </Alert>
      )}
      <DeclineOfferModal
        id={props.id}
        isOpen={declineModalOpen}
        onRejection={handleReject}
        setIsOpen={setDeclineModalOpen}
      />
      <div className='d-flex mt-auto justify-content-center gap-3'>
        <Button
          disabled={props.disabled}
          onClick={() => setDeclineModalOpen(true)}
          color='danger'
        >
          Decline Offer
        </Button>
        <Button
          disabled={props.disabled}
          color='primary'
          onClick={handleAccept}
        >
          Accept Offer
        </Button>
      </div>
    </>
  );
}
