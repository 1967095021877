import React from 'react';
import { IconButton } from '../../../../components/IconButton';

interface PropTypes {
  border?: boolean;
  onClick: () => void;
}

export function AddSectionButton(props: PropTypes) {
  return (
    <IconButton
      color={props.border ? 'secondary' : 'borderless'}
      iconName='bi-layers'
      buttonText='Add Section'
      onClick={props.onClick}
    />
  );
}
