import { InterviewerParticipant } from '../../../../entities/applicant_tracking/InterviewerParticipant';
import { CronofyOptions } from '../../CronofyOptions';

export function buildMembers(
  participants: InterviewerParticipant[],
  options: CronofyOptions,
) {
  const members = participants
    .map((participant) => {
      const integration = participant.user.schedulingUserIntegration;

      if (!integration) return;

      return {
        sub: integration.accountId,
        calendar_ids: [integration.calendarId],
        managed_availability: integration.availabilities.length > 0,
        only_managed_events: participant.ignoreCalendar, // Hack to ignore calendar events.
        // When true, we only consider the events created by us and that's ok because we don't want to schedule two interviews at the same time.
      };
    })
    .filter(Boolean);

  if (options.appCalendarAccountId !== null) {
    members.push({
      sub: options.appCalendarAccountId,
      calendar_ids: [],
      managed_availability: false,
      only_managed_events: false,
    });
  }

  return members;
}
