import { ProspectInfo } from '../../../reference_check/entities/ProspectInfo';
import { ApiService } from '../../../services/ApiService';

export class ProspectService {
  public static create(prospect): Promise<ProspectInfo> {
    return ApiService.post<ProspectInfo>(
      '/api/candidate_experience/prospects',
      { prospect },
    );
  }
}
