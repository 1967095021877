import classNames from 'classnames';
import React from 'react';
import { AnalyticsPanel } from '../../../../components/AnalyticsPanel';
import { SelectOption } from '../../../../components/candidate_experience/Filters/filterState';
import { TablePanelContent } from './TablePanelContent';
import { LazyContent } from '../../../../components/survey_engine/LazyContent';
import { CardTitle } from '../../../../components/Card';

export interface TableEntry {
  text: string;
  average: number;
  benchmarkAverage?: number;
  averageTrend?: number;
}

export interface PropTypes {
  title: string;
  entries: TableEntry[];
  benchmarkType: SelectOption;
  subtitle?: string;
  textColumnHeader?: string;
  tableClassName?: string;
  questionTitleValueClassName?: string;
  questionAvgValueColor?: string;
  displayAverageTrend?: boolean;
  multiplier?: number;
  displayPercentage?: boolean;
  fillColor?: string;
  enableBenchmark?: boolean;
}

export function TablePanel(props: PropTypes) {
  return (
    <AnalyticsPanel
      title={
        <div className={classNames('mb-0')}>
          <CardTitle>{props.title}</CardTitle>
        </div>
      }
      collapseClassName={'h-100 d-flex'}
      subtitle={<small className='text-muted'>{props.subtitle}</small>}
    >
      <LazyContent
        isLoading={!props.entries}
        isEmpty={props.entries?.length === 0}
      >
        <TablePanelContent
          entries={props.entries}
          benchmarkType={props.benchmarkType}
          textColumnHeader={props.textColumnHeader}
          tableClassName={props.tableClassName}
          questionTitleValueClassName={props.questionTitleValueClassName}
          questionAvgValueColor={props.questionAvgValueColor}
          displayAverageTrend={props.displayAverageTrend}
          multiplier={props.multiplier}
          displayPercentage={props.displayPercentage}
          fillColor={props.fillColor}
          enableBenchmark={props.enableBenchmark}
        />
      </LazyContent>
    </AnalyticsPanel>
  );
}
