import classNames from 'classnames';
import React from 'react';
import { ScorecardCategory } from '../../../../../../entities/ScorecardCategory';
import styles from './styles.module.scss';

interface PropTypes {
  scorecardCategories: {
    scorecardCategory: ScorecardCategory;
    index: number;
  }[];
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}

function Dropdown() {
  return (
    <a
      className={classNames('dropdown-toggle', styles.overflow)}
      data-bs-toggle='dropdown'
      href='#'
      role='button'
      aria-expanded='false'
    >
      <i
        className='bi bi-three-dots-vertical'
        title='More Categories'
        data-toggle='tooltip'
        data-bs-placement='left'
        data-bs-delay='1000'
      ></i>
    </a>
  );
}

function Category(props: {
  scorecardCategory: ScorecardCategory;
  index: number;
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}) {
  return (
    <a
      key={props.index}
      href='#'
      className={classNames(
        'nav-item',
        'nav-link',
        'text-truncate',
        'px-2',
        'fs-5',
        'fw-normal',
        styles.overflowitem,
        {
          'active bg-transparent': props.index == props.activeTabIndex,
        },
      )}
      onClick={() => props.setActiveTabIndex(props.index)}
    >
      {props.scorecardCategory.name}
    </a>
  );
}

export function ScorecardCategoriesOverflow(props: PropTypes) {
  return (
    <div className='align-self-center ms-auto'>
      <div className='dropdown'>
        <Dropdown />
        <ul className='dropdown-menu' aria-labelledby='user-dropdown-toggle'>
          {props.scorecardCategories.map((element) => (
            <Category
              key={element.index}
              scorecardCategory={element.scorecardCategory}
              index={element.index}
              activeTabIndex={props.activeTabIndex}
              setActiveTabIndex={props.setActiveTabIndex}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
