import classNames from 'classnames';
import React from 'react';
import { User } from '../../../../entities/User';
import { WelcomeMessage } from '../../components/WelcomeMessage';
import { SessionsTabs, SessionsTabType } from './SessionsTabs';

interface PropTypes {
  user: User;
  onTabChanged: (tab: SessionsTabType) => void;
  activeTab: SessionsTabType;
}

const tabs = [
  { key: 'overview', label: 'Overview' },
  { key: 'sessions', label: 'Sessions' },
];

function AdminLayout(props: PropTypes) {
  return (
    <>
      <div className='d-flex justify-content-between'>
        <h1>Training</h1>
        <WelcomeMessage
          userName={props.user.name}
          text='Here’s your team overview and training sessions.'
        />
      </div>
      <SessionsTabs
        tabs={tabs}
        activeTab={props.activeTab}
        onTabChanged={props.onTabChanged}
        className='mb-4'
      />
    </>
  );
}

function InterviewerLayout() {
  return (
    <div>
      <h1>Training</h1>
    </div>
  );
}

export function SessionsHeader(props: PropTypes) {
  return (
    <div className={classNames('ms-2', 'mb-3', 'mt-2')}>
      {props.user.isAdmin ? <AdminLayout {...props} /> : <InterviewerLayout />}
    </div>
  );
}
