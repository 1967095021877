import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppPlugin } from '..';
import { ApplicantTrackingJobRequisitionsApp } from '../../containers/applicant_tracking/JobRequisitions/ApplicantTrackingJobRequisitionsApp';

export class ApplicantTrackingJobRequisitionsAppPlugin implements AppPlugin {
  public load(): void {
    const el = document.getElementById('applicantTrackingJobRequisitionsApp');

    if (el) this.installContainer(el);
  }

  private installContainer(el: HTMLElement): void {
    const props = JSON.parse(el.dataset['props'] || '{}');

    createRoot(el).render(
      React.createElement(ApplicantTrackingJobRequisitionsApp, props),
    );
  }
}
