import React, { useCallback, useState } from 'react';
import { InterviewClip } from '../../entities/InterviewClip';
import logo from '../../images/logo.svg';
import { Chip, ChipColor } from '../../components/Chip';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { VideoPlayer } from '../../components/VideoPlayer';
import { MediaType } from '../../entities/MediaType';
import { PageTitle } from '../../components/PageTitle';
import { Timeline } from './Timeline';

interface PropTypes {
  interviewClip: InterviewClip;
  expiredInDays: number;
}

export default function InterviewClipPage(props: PropTypes) {
  const [startTime, setStartTime] = useState<number>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [playTimestamp, setPlayTimestamp] = useState<number>(0);

  const pickTime = useCallback((time: number) => {
    setStartTime(undefined);
    setImmediate(() => {
      setStartTime(time);
      setIsPlaying(true);
    });
  }, []);
  return (
    <div className='h-100 w-100 d-flex justify-content-center'>
      <div className={classNames('mt-2 mt-md-4', styles['container'])}>
        <img src={logo} className={styles['logo']} />
        <div className='mt-2 mt-md-5 mb-3 d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <PageTitle
              text={props.interviewClip.candidateName}
              classNames='fs-1'
            />
            <Chip className='ms-3' color={ChipColor.White}>
              {props.interviewClip.jobName}
            </Chip>
          </div>
          <span className='fs-5 text-dark-500'>
            Expires in {props.expiredInDays} days
          </span>
        </div>
        <VideoPlayer
          media={{
            type: MediaType.Video,
            url: props.interviewClip.videoUrl,
          }}
          startTime={startTime}
          isPlaying={isPlaying}
          playTimestamp={playTimestamp}
          onSelectTime={pickTime}
          onStartTimeChanged={setStartTime}
          onChangePlayTimestamp={setPlayTimestamp}
          onPlaying={setIsPlaying}
        />
        <Timeline
          time={playTimestamp}
          duration={
            props.interviewClip.endOffsetMs - props.interviewClip.startOffsetMs
          }
          onSelectTime={pickTime}
        />
      </div>
    </div>
  );
}
