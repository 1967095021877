import classNames from 'classnames';
import React from 'react';
import { Chip, ChipColor } from '../../../../../../../components/Chip';
import { Trend } from '../../../../../../../components/survey_engine/Trend';
import styles from '../../TableBody/styles.module.scss';

interface PropTypes {
  average: number;
  trend: number;
  chipColor: ChipColor;
}

export function SpecificAverage(props: PropTypes) {
  return (
    <td className={classNames(styles['table-data-cell-border-bottom'])}>
      <div className='d-flex justify-content-end'>
        <div
          className={classNames(
            styles['avg-trend-cell'],
            'd-flex justify-content-between align-items-center',
          )}
        >
          <div>
            {props.average !== null ? (
              <Chip
                className={classNames(`fw-semibold fs-6`)}
                color={props.chipColor}
              >
                {Math.round(props.average * 10) / 10}
              </Chip>
            ) : (
              <span className='text-muted'>N/A</span>
            )}
          </div>
          <Trend trend={props.trend} className='fs-6 text-nowrap ps-1' />
        </div>
      </div>
    </td>
  );
}
