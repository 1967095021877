import { SurveySection } from '../../entities/survey_engine/SurveySection';
import { SurveyTargetInfo } from '../../entities/survey_engine/SurveyTargetInfo';
import { buildInterviewerJobSurveyQuestion } from './buildInterviewerJobSurveyQuestion';
import { buildInterviewerRoleSurveyQuestion } from './buildInterviewerRoleSurveyQuestion';

export function buildInterviewerSurveySection(
  targetInfo: SurveyTargetInfo,
): SurveySection {
  return {
    id: -1,
    section_id: -1,
    section_name: 'Recruitment process feedback',
    sectionName: 'Recruitment process feedback',
    section_description: '',
    version_number: 1,
    order: 1,
    questions: [
      buildInterviewerJobSurveyQuestion(targetInfo),
      buildInterviewerRoleSurveyQuestion(targetInfo.available_roles),
    ],
  };
}
