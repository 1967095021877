import { EmailSender } from './EmailSender';

export enum ConnectionStatus {
  Connected = 'connected',
  NotVerified = 'not_verified',
  Disconnected = 'disconnected',
}

export interface EmailDomain {
  id: number;
  connection_status: ConnectionStatus;
  domain: string;
  dkim_host: string;
  dkim_value: string;
  dkim_verified_at: Date;
  return_path_host: string;
  return_path_value: string;
  return_path_verified_at: Date;
  email_sender: EmailSender;
}
