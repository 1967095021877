import React from 'react';
import { Session } from '../../../../entities/training/Session';
import { SessionStatusChip } from './SessionStatusChip';
import { SessionCardImage } from './SessionCardImage';
import { User } from '../../../../entities/User';
import classNames from 'classnames';

interface PropTypes {
  session: Session;
  currentUser: User;
}

function showPageRedirect(session: Session, currentUser: User) {
  if (session.comingSoon || (session.draft && !currentUser.isAdmin)) {
    return false;
  }

  return true;
}

function handleOnClick(session: Session, currentUser: User) {
  if (!showPageRedirect(session, currentUser)) {
    return null;
  }

  location.href = `/training/sessions/${session.id}`;
}

export function SessionCardHeader(props: PropTypes) {
  return (
    <a
      href={
        showPageRedirect(props.session, props.currentUser) ? '#' : undefined
      }
      onClick={() => handleOnClick(props.session, props.currentUser)}
    >
      <div>
        <SessionCardImage {...props} />
        <SessionStatusChip
          className={classNames('position-absolute', 'me-5h', 'mt-5h')}
          {...props}
        />
      </div>
      <h3 className={classNames('mb-0', 'mt-2', 'text-truncate')}>
        <span>{props.session.name}</span>
      </h3>
    </a>
  );
}
