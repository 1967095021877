import React from 'react';
import { User } from '../../../../../../../../entities/User';
import { Scorecard } from '../../../../../../../../entities/applicant_tracking/Scorecard';
import { Chip, ChipColor } from '../../../../../../../../components/Chip';

interface PropTypes {
  currentUser: User;
  scorecard: Scorecard;
  viewAllScorecards: boolean;
}

function memberName(currentUser: User, scorecardUser: User) {
  if (scorecardUser?.id === currentUser.id) {
    return 'My Own';
  }

  return scorecardUser?.name;
}

export function ScorecardBlockTitle(props: PropTypes) {
  const interviewScorecard = props.scorecard.interviewId ? true : false;

  return (
    <>
      {!props.viewAllScorecards ? (
        <div className='fw-semibold fs-3 me-3'>
          {props.scorecard.jobStage?.name}
        </div>
      ) : (
        <>
          <div className='fw-semibold fs-3 me-3'>
            {memberName(props.currentUser, props.scorecard.user)}
          </div>
          <Chip
            color={
              interviewScorecard ? ChipColor.OrangeLight : ChipColor.BlueLight
            }
          >
            {props.scorecard.jobStage?.name}
          </Chip>
        </>
      )}
    </>
  );
}
