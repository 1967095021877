import React from 'react';
import { PageTitle } from '../../../components/PageTitle';
import { longFormatDate } from '../../../utils/timeFormat';
import { JobPageTab } from '../JobPageTab';

interface PropTypes {
  children?: React.ReactNode;
  date?: Date;
  tabName: JobPageTab;
}

export function HeadingRow(props: PropTypes) {
  return (
    <div className='d-flex justify-content-between mx-2 mb-2'>
      <PageTitle text={props.tabName} />
      <div className='d-flex align-items-center gap-2h'>
        <div className='fs-5 text-dark-200'>
          {props.date && <>Last Updated at {longFormatDate(props.date)}</>}
        </div>
        {props.children}
      </div>
    </div>
  );
}
