import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';
import { UpsertStagePlanTemplateDto } from '../../DTOs/UpsertStagePlanTemplateDto';
import CreatableSelect from 'react-select/creatable';
import { SelectOption } from '../../../../../components/Select';
import { selectTheme } from '../../../../../components/Select/selectTheme';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { FormFieldHeader } from '../../../../../components/FormFieldHeader';
import { StagePlanTemplate } from '../../../../../entities/applicant_tracking/StagePlanTemplate';
import { JobStageTemplateInput } from '../..';

interface PropTypes {
  control: Control<UpsertStagePlanTemplateDto, void>;
  selectedStagePlanTemplates: StagePlanTemplate[];
  availableStagePlanTemplates: StagePlanTemplate[];
  errors: FieldErrors;
  register: UseFormRegister<UpsertStagePlanTemplateDto>;
  focusedStage: JobStageTemplateInput;
  disabled?: boolean;
}

function buildUserOptions(
  stagePlanTemplates: StagePlanTemplate[],
): SelectOption[] {
  return stagePlanTemplates.map((stagePlanTemplate) => ({
    value: stagePlanTemplate.name,
    label: stagePlanTemplate.name,
  }));
}

export function NameInput(props: PropTypes) {
  const validateStageName = (value: SelectOption) => {
    return !props.selectedStagePlanTemplates
      ?.filter((stage) => stage.uniqueKey !== props.focusedStage?.uniqueKey)
      ?.find((stagePlanTemplate) => stagePlanTemplate.name === value?.value);
  };

  return (
    <div className={classNames('w-100', styles['input-fields'])}>
      <FormFieldHeader
        fieldName='Stage Name'
        isRequired={true}
        classNames='fw-semibold'
      />
      <Controller
        render={({ field }) => (
          <CreatableSelect
            {...field}
            tabSelectsValue
            isClearable
            value={field.value}
            theme={selectTheme}
            placeholder='Insert stage name'
            options={buildUserOptions(props.availableStagePlanTemplates)}
            isOptionSelected={(option: SelectOption) => !!option.selected}
            components={{ DropdownIndicator: null }}
            className={classNames(
              props.errors.name && styles['creatable-select-error'],
            )}
            isDisabled={props.disabled}
            createOptionPosition='first'
            formatCreateLabel={(value) => (
              <div className='text-info fw-semibold'>
                Create &quot;{value}&quot;
              </div>
            )}
          />
        )}
        name='name'
        control={props.control}
        rules={{
          required: 'This field is required',
          validate: {
            checkDuplicates: (v) =>
              validateStageName(v) || 'This stage name already exists.',
          },
        }}
      />
      {props.errors.name && (
        <div className='text-danger-500 mt-2 fs-5'>
          {props.errors.name?.message}
        </div>
      )}
    </div>
  );
}
