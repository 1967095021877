import classNames from 'classnames';
import React from 'react';
import { Nav, NavLink } from 'reactstrap';
import styles from './styles.module.scss';

interface TabMenuPropTypes {
  children: React.ReactNode;
  className?: string;
}

export function TabMenu(props: TabMenuPropTypes) {
  return (
    <Nav
      className={classNames(
        'flex-wrap app-nav-tabs bg-transparent',
        props.className,
      )}
    >
      {props.children}
    </Nav>
  );
}

interface TabPropTypes {
  id: unknown;
  active: boolean;
  onClick: (tabId: unknown) => void;
  children: React.ReactNode;
}

export function Tab(props: TabPropTypes) {
  return (
    <NavLink
      active={props.active}
      className={classNames(
        'text-sm-center fw-bold',
        props.active
          ? styles.borderBottomDarkPrimary
          : styles.borderBottomTransparent,
      )}
      onClick={() => props.onClick(props.id)}
    >
      {props.children}
    </NavLink>
  );
}
