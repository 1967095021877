import React from 'react';
import styles from '../styles.module.scss';
import { NoteInput } from '../../../NoteInput';
import { Button } from 'reactstrap';
import { LoadingSpinnerIcon } from '../../../LoadingSpinnerIcon';

export interface PropTypes {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  noteText: string;
  setNoteText: (value: React.SetStateAction<string>) => void;
  setReplyActive: (value: React.SetStateAction<boolean>) => void;
  isSubmittingReply: boolean;
}

function ActionButtons(props: {
  setReplyActive: (value: React.SetStateAction<boolean>) => void;
  isSubmittingReply: boolean;
}) {
  return (
    <>
      <Button
        onClick={() => props.setReplyActive(false)}
        color='borderless'
        size='sm'
        disabled={props.isSubmittingReply}
      >
        Cancel
      </Button>
      <Button
        className='ms-3'
        color='primary'
        type='submit'
        size='sm'
        disabled={props.isSubmittingReply}
      >
        {props.isSubmittingReply && (
          <LoadingSpinnerIcon className='me-1' size='sm' />
        )}
        Save
      </Button>
    </>
  );
}

export function AddReplyBlock(props: PropTypes) {
  return (
    <form onSubmit={props.onSubmit}>
      <div className={styles.replyBoxWrapper}>
        <div className='row'>
          <div className='col-12'>
            <NoteInput
              className='fs-5'
              autofocus={true}
              placeholder='Type your reply...'
              value={props.noteText}
              onChange={(v) => props.setNoteText(v)}
            />
          </div>
        </div>
      </div>
      <div className='col-12 text-end mt-2'>
        <ActionButtons
          setReplyActive={props.setReplyActive}
          isSubmittingReply={props.isSubmittingReply}
        />
      </div>
    </form>
  );
}
