import React, { useState } from 'react';
import {
  InterviewMarker,
  InterviewMarkerKind,
} from '../../../../entities/InterviewMarker';
import { Button, Col, Row } from 'reactstrap';
import { NoteInput } from '../../../../components/NoteInput';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../../../../config/queryClient';
import { Timedelta } from '../../../../utils/timedelta';
import { ToastMessage } from '../../../../components/ToastMessage';

interface PropTypes {
  interviewId: number;
  userId: number;
  interviewMarkers: InterviewMarker[];
  showAcknowledgeChip: boolean;
  onInterviewMarkerUpdated: (kind: string, note: string) => void;
}

function InterviewMarkerButton(props: { kind: InterviewMarkerKind }) {
  switch (props.kind) {
    case 'flag':
      return (
        <i style={{ top: '0px' }} className='bi bi-flag-fill text-danger' />
      );
    case 'thumbs_up':
      return (
        <i
          style={{ top: '0px' }}
          className='bi bi-hand-thumbs-up-fill text-success'
        />
      );
    default:
      return <>Add Note</>;
  }
}

function getClassName(kind: InterviewMarkerKind) {
  switch (kind) {
    case 'flag':
      return 'ms-2 align-end';
    case 'thumbs_up':
      return 'align-end';
    default:
      return 'align-end ms-3';
  }
}

function NoteContext(props: { message: string }) {
  return (
    <>
      <span className='me-3' data-interview-marker-list-target='timestamp' />
      <ToastMessage className={'rounded-pill mx-2'} message={props.message} />
    </>
  );
}

function NoteButtons(props: {
  message: string;
  setKind: (kind: InterviewMarkerKind) => void;
}) {
  const markerKinds = ['note', 'thumbs_up', 'flag', 'note'];

  return (
    <Row className='mt-3 justify-content-between align-items-center'>
      <Col className='col-auto text-center'>
        <NoteContext message={props.message} />
      </Col>
      <Col className='col-auto'>
        {markerKinds.map((kind: InterviewMarkerKind, i: number) => (
          <Button
            hidden={i === 0}
            key={i === 0 ? `hidden_${kind}` : kind}
            type='submit'
            color={kind === 'note' ? 'primary' : 'borderless'}
            className={getClassName(kind)}
            onClick={() => props.setKind(kind)}
            value={kind}
          >
            <InterviewMarkerButton kind={kind} />
          </Button>
        ))}
      </Col>
    </Row>
  );
}

function Note(props: {
  noteText: string;
  setNoteText: (note: string) => void;
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <NoteInput
        autofocus={true}
        singleLine={true}
        placeholder='Type to add notes'
        value={props.noteText}
        onChange={(v) => {
          props.setNoteText(v);
        }}
      />
    </QueryClientProvider>
  );
}

function getMessage(kind: InterviewMarkerKind) {
  if (kind == 'note') {
    return 'Note added';
  } else if (kind == 'flag' || kind == 'thumbs_up') {
    return 'Reaction added';
  } else {
    return '';
  }
}

export function NotePanel(props: PropTypes) {
  const [kind, setKind] = useState<InterviewMarkerKind>(undefined);
  const [noteText, setNoteText] = useState<string>('');
  const [messageText, setMessageText] = useState<string>('');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onInterviewMarkerUpdated(kind, noteText);

    if (props.showAcknowledgeChip) {
      setTimeout(() => {
        setMessageText('');
      }, Timedelta.fromSecs(3).toMs());

      setMessageText(getMessage(kind));
    }

    setNoteText('');
  };

  return (
    <div
      data-controller='interview-marker-list'
      className='bg-white mx-3 mt-4 mb-2 p-3 rounded-4'
    >
      <form onSubmit={(e) => onSubmit(e)}>
        <Note noteText={noteText} setNoteText={setNoteText} />
        <NoteButtons message={messageText} setKind={setKind} />
      </form>
    </div>
  );
}
