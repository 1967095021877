import React from 'react';
import { DropdownIndicatorProps } from 'react-select';
import { DropdownIcon } from '../../DropdownIcon';
import classNames from 'classnames';
import styles from './styles.module.scss';

export function DropdownIndicator(props: DropdownIndicatorProps) {
  return (
    <DropdownIcon
      menuIsOpen={props.selectProps.menuIsOpen}
      classNames={classNames(
        props.isFocused ? styles['dropdown-focused'] : styles['dropdown'],
        'fs-6',
      )}
      isDisabled={props.isDisabled}
    />
  );
}
