import React from 'react';
import { SelectOption } from '../filterState';
import { LocationsLabelledMultiSelectCol } from './LocationsLabelledMultiSelectCol';
import { SurveyTracksLabelledMultiSelectCol } from './SurveyTracksLabelledMultiSelectCol';

interface PropTypes {
  selectedSurveyTemplates: SelectOption[];
  selectedLocations: SelectOption[];
  handleFilterUpdate: (
    filterName: string,
  ) => (newOptions: SelectOption[]) => void;
}

export interface AdditionalLabelledMultiSelect {
  selected: SelectOption[];
  handleFilterUpdate: (
    filterName: string,
  ) => (newOptions: SelectOption[]) => void;
}

export function AdditionalLabelledMultiSelectCols(props: PropTypes) {
  return (
    <>
      <SurveyTracksLabelledMultiSelectCol
        additionalLabelledMultiSelect={{
          selected: props.selectedSurveyTemplates,
          handleFilterUpdate: props.handleFilterUpdate,
        }}
      />
      <LocationsLabelledMultiSelectCol
        additionalLabelledMultiSelect={{
          selected: props.selectedLocations,
          handleFilterUpdate: props.handleFilterUpdate,
        }}
      />
    </>
  );
}
