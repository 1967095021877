import React from 'react';
import { Application } from '../../../../../entities/Application';
import { IconEntry } from '../IconEntry';
import { IconSpan } from '../../../../../components/IconSpan';
import { RedactedResumeService } from '../../../../../services/v1/applicant_tracking/RedactedResumeService';
import { RedactedEntry } from '../RedactedEntry';
import { isEmptyString } from '../../../../../utils/isEmptyString';

interface PropTypes {
  application: Application;
  hideCV?: boolean;
  setIsLoading: (v: boolean) => void;
}

function IconLink(props: {
  redacted: boolean;
  icon: string;
  spanText: string;
  link: string;
}) {
  if (isEmptyString(props.link)) {
    return null;
  }

  return (
    <IconEntry
      redacted={props.redacted}
      icon={props.icon}
      label={props.spanText}
    >
      <a
        href={props.link}
        className='text-info'
        target='_blank'
        rel='noreferrer'
      >
        <span>{props.spanText}</span>
      </a>
    </IconEntry>
  );
}

function CV(props: {
  hideCV: boolean;
  application: Application;
  setIsLoading: (v: boolean) => void;
}) {
  function handleClick() {
    props.setIsLoading(true);

    RedactedResumeService.show(props.application.applicationResume.id).then(
      (resume) => {
        window.open(resume.url, '_blank');
        props.setIsLoading(false);
      },
    );
  }

  if (props.hideCV) return null;
  if (!props.application.applicationResume) return null;

  if (!props.application.redacted) {
    return (
      <IconLink
        icon='bi-file-text'
        spanText='View CV'
        link={props.application.applicationResume.url}
        redacted={props.application.redacted}
      />
    );
  }

  return (
    <div className='d-flex text-info' role='button' onClick={handleClick}>
      <IconSpan
        icon={{ name: 'bi-file-lock2', className: 'text-blue-200 fs-4' }}
        spanText={'View CV'}
      />
    </div>
  );
}

function CoverLetter(props: { application: Application }) {
  if (!props.application.applicationCoverLetter) return null;

  if (!props.application.redacted) {
    return (
      <IconLink
        icon='bi-file-earmark-text'
        spanText='View Cover Letter'
        link={props.application.applicationCoverLetter?.url}
        redacted={props.application.redacted}
      />
    );
  }

  return <RedactedEntry label={'View Cover Letter'} />;
}

export function PortfolioAndCVAndCoverLetter(props: PropTypes) {
  return (
    <div className='d-flex flex-column gap-2h'>
      <CV
        hideCV={props.hideCV}
        application={props.application}
        setIsLoading={props.setIsLoading}
      />
      <IconLink
        icon='bi-laptop'
        link={props.application.portfolioUrl}
        redacted={props.application.redacted}
        spanText='View Portfolio'
      />
      <CoverLetter application={props.application} />
    </div>
  );
}
