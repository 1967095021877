import { useEffect } from 'react';

export function useQueryParam(
  name: string,
  callback: (value: string) => void,
): void {
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const value = query.get(name);

    callback(value);
  }, [location.search]);
}
