import {
  FilterState,
  FilterStateUpdates,
  updateFilterSelector,
  updateJobs,
  updateDepartments,
  updateHiringManagers,
  updateRecruiters,
  updateLocations,
  updateSurveyTemplates,
  updateBenchmarkTypes,
  updateDateRange,
  updateDemographics,
  updateNpsAttributes,
} from './update';

export function updateFilterState(
  filterState: FilterState,
  selected: FilterStateUpdates,
) {
  return {
    filterSelector: updateFilterSelector(selected.filterSelector),
    jobs: updateJobs(selected.jobs),
    departments: updateDepartments(selected.departments),
    hiringManagers: updateHiringManagers(selected.hiringManagers),
    recruiters: updateRecruiters(selected.recruiters),
    locations: updateLocations(selected.locations),
    surveyTemplates: updateSurveyTemplates(selected.surveyTemplates),
    benchmarkType: updateBenchmarkTypes(
      filterState.benchmarkTypes,
      selected.benchmarkType,
    ),
    dateRange: updateDateRange(
      selected.dateRange,
      selected.startDate,
      selected.endDate,
    ),
    ethnicities: updateDemographics(
      filterState.ethnicities,
      selected.ethnicities,
    ),
    genders: updateDemographics(filterState.genders, selected.genders),
    ageRanges: updateDemographics(filterState.age_ranges, selected.ageRanges),
    npsAttributes: updateNpsAttributes(
      filterState.npsAttributes,
      selected.npsAttributes,
    ),
  };
}
