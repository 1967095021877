import classNames from 'classnames';
import React from 'react';
import { SurveyChip } from '../../../../../components/survey_engine/SurveyChip';

interface PropTypes {
  questionOptions: Array<{ label: string; value: string }>;
  option: string;
  setOption: React.Dispatch<React.SetStateAction<string>>;
  question: string;
  subject: string;
}

export function DisplayDemographicOptions(props: PropTypes) {
  return (
    <>
      <p className='px-2 fs-3 fw-semibold'>{props.question}</p>
      <div className='mt-4' data-testid={props.subject}>
        <div className='d-flex align-content-end flex-wrap'>
          {props.questionOptions.map((opt, index) => (
            <div key={index} className='mb-2 px-2 pt-1'>
              <SurveyChip
                className={classNames('text-start fs-4 mb-4')}
                active={opt.value === props.option}
                onClick={() => props.setOption(opt.value)}
              >
                {opt.label}
              </SurveyChip>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
