import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { SessionOverviewPanel } from './SessionOverviewPanel';
import { StartEvaluationPanel } from './StartEvaluationPanel';
import { SectionPart } from './SectionPart';
import { Section } from '../../../entities/training/Section';
import { sortEntitiesByOrder } from '../../../utils/training/sortUtils';
import SessionService from '../../../services/training/SessionService';
import { ThematicBreak } from '../../../components/ThematicBreak';
import { ApiError } from '../../../services/ApiService/errors/ApiError';
import { AssignedUsersPanel } from './AssignedUsersPanel';
import { User } from '../../../entities/User';
import { Session } from '../../../entities/training/Session';
import { ActionSessionAlert } from '../ActionSessionAlert';
import { SessionSubinfo } from './SessionSubinfo';
import classNames from 'classnames';
import { AverageRatingPanel } from './AverageRatingPanel';
import { CommentsPanel } from './CommentsPanel';
import { SessionInfo } from './SessionInfo';

interface PropTypes {
  currentUser: User;
  session: Session;
}

interface RighPanelPropTypes {
  currentUser: User;
  assignModalVisible: boolean;
  session: Session;
  onAssign: (sessionId: number, users: number[]) => void;
  onAssignModalVisible: (modal: boolean) => void;
}

function SessionRightPanels(props: RighPanelPropTypes) {
  return (
    <>
      <SessionOverviewPanel goal={props.session.goal} />
      <StartEvaluationPanel session={props.session} hasBackground={true} />
      <AverageRatingPanel
        averageRating={props.session.averageRating}
        ratingHistory={props.session.ratingHistory}
      />
      <CommentsPanel comments={props.session.comments} />
      <AssignedUsersPanel
        session={props.session}
        currentUser={props.currentUser}
        assignModalVisible={props.assignModalVisible}
        onAssignModalVisible={props.onAssignModalVisible}
        onAssign={props.onAssign}
      />
    </>
  );
}

export default function SessionPage(props: PropTypes) {
  const [session, setSession] = useState(props.session);
  const [completedSuccessfully, setCompletedSuccessfully] = useState(
    props.session.evaluation?.alert,
  );
  const [assignedSuccessfully, setAssignedSuccessfully] = useState(null);
  const [assignModalVisible, setAssignModalVisible] = useState<boolean>(false);

  async function handleAssign(sessionId: number, users: number[]) {
    try {
      const sessionResult = await SessionService.assign(sessionId, users);
      setSession(sessionResult);
      setAssignedSuccessfully(true);
      setAssignModalVisible(false);
    } catch (err: unknown) {
      if (err instanceof ApiError) {
        setAssignedSuccessfully(false);
        setAssignModalVisible(false);
      }

      throw err;
    }
  }

  return (
    <div>
      <ActionSessionAlert
        successful={assignedSuccessfully}
        sessionName={props.session.name}
        onClose={() => setAssignedSuccessfully(null)}
        successfulMessage={'was successfully assigned'}
        errorMessage={'was not assigned successfully'}
      />
      <ActionSessionAlert
        successful={completedSuccessfully}
        sessionName={props.session.name}
        onClose={() => setCompletedSuccessfully(null)}
        successfulMessage={'evaluation was successfully submitted'}
        errorMessage={'evaluation was not successfully submitted'}
      />
      <SessionInfo session={session} />
      <Row>
        <Col
          className={classNames(
            'd-flex',
            'flex-column',
            'col-12',
            'order-md-2',
            'gap-3',
            'mb-3',
          )}
          md='3'
        >
          <SessionRightPanels
            assignModalVisible={assignModalVisible}
            session={session}
            onAssignModalVisible={setAssignModalVisible}
            onAssign={handleAssign}
            currentUser={props.currentUser}
          />
        </Col>
        <Col className='col-12 order-2 order-md-1' md='9'>
          <SessionSubinfo
            topics={props.session.topics}
            durationMillis={props.session.durationMillis}
            views={props.session.views}
          />
          {sortEntitiesByOrder<Section>(session.sections).map(
            (section: Section, i: number) => {
              return <SectionPart key={i} section={section} />;
            },
          )}
          <ThematicBreak className='mt-5' />
          <StartEvaluationPanel session={props.session} hasBackground={false} />
        </Col>
      </Row>
    </div>
  );
}
