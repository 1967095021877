import React from 'react';
import { FormState } from 'react-hook-form';
import { CreateJobDto } from '../../../../../../services/applicant_tracking/JobService/CreateJobDto';
import {
  SetupHeaderButtonType,
  SetupPageHeader,
} from '../../../../../../components/SetupPageHeader';
import { JobDetails } from '../../../../../../services/applicant_tracking/JobService/EditJobDto';

interface PropTypes {
  formState: FormState<CreateJobDto>;
  jobDetails: JobDetails;
  setupStep: number;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
  onCancel: () => void;
  onSave: () => void;
  onNext: () => void;
  onSubmit: () => void;
  isEditFlow: boolean;
}

export function JobDetailsHeader(props: PropTypes) {
  const saveAsDraft = [
    {
      title: 'Save as Draft',
      type: 'Save',
      action: props.onSave,
      disabled: !props.formState.isValid,
    },
  ] as SetupHeaderButtonType[];

  const NextButton = [
    {
      title: 'Next',
      type: 'Navigate',
      action: props.onNext,
      disabled: !props.formState.isValid,
    },
  ] as SetupHeaderButtonType[];
  const saveButtonText =
    props.isEditFlow && props.jobDetails?.status === 'published'
      ? 'Save and Publish'
      : 'Save and Continue';

  return (
    <SetupPageHeader
      title='Job Details'
      firstItems={props.isEditFlow ? NextButton : saveAsDraft}
      intermediateItems={[
        {
          title: 'Cancel',
          type: 'Cancel',
          action: props.onCancel,
        },
        {
          title: saveButtonText,
          type: 'Save',
          disabled: !props.formState.isValid,
          action: props.onSubmit,
        },
      ]}
    />
  );
}
