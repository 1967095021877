import React, { useEffect, useState } from 'react';
import { LabelledSelect } from '../../../../../../../../components/LabelledSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from '../Dropdown/TriggerRowDropdown';
import {
  AutoRejectTrigger,
  Trigger,
} from '../../../../../../../../entities/applicant_tracking/Trigger';
import { ApplicationFormTemplate } from '../../../../../../../../entities/applicant_tracking/ApplicationFormTemplate';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { GroupedSelect } from '../../../../../../../../components/GroupedReactSelect';
import {
  answerSelectOptions,
  getSelectedQuestions,
  mapGroupedSelectOptions,
  selectedAnswerFromOptions,
  selectedCustomQuestionId,
  selectedDefaultQuestionField,
} from './utils';

interface PropTypes {
  applicationFormTemplate: ApplicationFormTemplate;
  counter: number;
  trigger: Trigger;
  formData: Trigger[];
  jobLocation: string;
  onUpdateTrigger: (trigger: Trigger, index: number) => void;
}

export function AutoRejectTriggerRow(props: PropTypes) {
  const trigger = props.trigger as AutoRejectTrigger;
  const initApplicationFormQuestions = mapGroupedSelectOptions(
    props.applicationFormTemplate,
    props.jobLocation,
    trigger.applicationField || trigger.questionId?.toString(),
    getSelectedQuestions(props.formData as AutoRejectTrigger[], trigger),
  );

  const [questionOptions, setQuestionOptions] = useState(
    initApplicationFormQuestions,
  );
  const [answerOptions, setAnswerOptions] = useState(
    answerSelectOptions(trigger.rejectAnswer),
  );

  const updateTrigger = () => {
    props.onUpdateTrigger(
      {
        ...props.trigger,
        type: 'auto_reject',
        questionId: selectedCustomQuestionId(questionOptions),
        applicationField: selectedDefaultQuestionField(questionOptions),
        rejectAnswer: selectedAnswerFromOptions(answerOptions),
        delayDays: 0,
      },
      props.counter,
    );
  };

  useEffect(updateTrigger, [questionOptions, answerOptions]);

  return (
    <>
      <TriggerRowDropdown
        className={classNames(styles['double-width-dropdown'])}
      >
        <GroupedSelect
          mandatory
          isSearchable
          options={questionOptions}
          defaultValue={questionOptions
            .flatMap((v) => v.options)
            .find((v) => v.selected)}
          labelClassName='fs-5'
          label='Application form field'
          placeholder='Select question'
          onChange={(option) =>
            setQuestionOptions(
              mapGroupedSelectOptions(
                props.applicationFormTemplate,
                props.jobLocation,
                option?.value,
                getSelectedQuestions(
                  props.formData as AutoRejectTrigger[],
                  trigger,
                ),
              ),
            )
          }
        />
      </TriggerRowDropdown>
      <TriggerRowDropdown>
        <LabelledSelect
          mandatory
          disabled={
            !(
              selectedCustomQuestionId(questionOptions) ||
              selectedDefaultQuestionField(questionOptions)
            )
          }
          options={answerOptions}
          selected={answerOptions.find((v: SelectOption) => v.selected)}
          labelClassName='fs-5'
          label='Application form answer'
          placeholder='Select Answer'
          onChange={(option) =>
            setAnswerOptions(answerSelectOptions(option.value === 'yes'))
          }
        />
      </TriggerRowDropdown>
    </>
  );
}
