import React from 'react';
import { useParams } from 'react-router-dom';
import { SurveyLoadingStatus } from '../../../types/survey_engine/SurveyLoadingStatus';
import { Survey } from '../../../entities/survey_engine/Survey';
import { filterDefaultFlowSections } from '../../../utils/survey_engine/filterDefaultFlowSections';
import { getSurveySections } from '../../../utils/survey_engine/getSurveySections';
import { SurveyRoute } from './SurveyRoute';
import { SurveyScreen } from './SurveyScreen';
import { SurveyQuestionAnswer } from '../../../entities/survey_engine/SurveyQuestionAnswer';

interface PropTypes {
  survey: Survey;
  onUpdateSurvey: (survey: Survey) => void;
  onChangeStatus: (status: SurveyLoadingStatus) => void;
  onFinishSurvey: () => void;
  submitAnswer: (
    surveyId: string,
    answer: SurveyQuestionAnswer,
  ) => Promise<Survey>;
  onFollowUpSurvey?: () => void;
}

const HIRING_MANAGER_TRACK = 'Hiring Manager Employee Evaluation Track';

const CUSTOM_STEPS = {
  'Hiring Manager Employee Evaluation Track': {
    1: {
      module: 'quality_of_hire/SurveySteps/EmployeeDataStep1',
    },
    2: {
      module: 'quality_of_hire/SurveySteps/EmployeeDataStep2',
    },
  },
};

export function QuestionsRoute(props: PropTypes) {
  const surveySections = getSurveySections(props.survey);
  const defaultFlowSections = filterDefaultFlowSections(surveySections);

  const { step } = useParams<'step'>();
  const customSteps =
    props.survey.provider === 'quality_of_hire' &&
    props.survey.target === 'hiring_manager'
      ? CUSTOM_STEPS[HIRING_MANAGER_TRACK]
      : {};
  const customStep = customSteps[step];

  const totalSteps =
    defaultFlowSections.length + Object.keys(customSteps || {}).length;

  if (customStep != null) {
    const Component = require(`../../${customStep.module}`).default; // eslint-disable-line @typescript-eslint/no-var-requires
    return (
      <Component
        survey={props.survey}
        step={parseInt(step, 10)}
        totalSteps={totalSteps}
        onUpdateSurvey={props.onUpdateSurvey}
        onFinishSurvey={props.onFinishSurvey}
      />
    );
  }

  return (
    <SurveyRoute survey={props.survey} status='pending' mismatchPath='..'>
      <SurveyScreen
        survey={props.survey}
        surveySections={surveySections}
        defaultSurveySections={defaultFlowSections}
        totalSteps={totalSteps}
        onChangeStatus={props.onChangeStatus}
        onUpdateSurvey={props.onUpdateSurvey}
        onFinishSurvey={props.onFinishSurvey}
        onFollowUpSurvey={props.onFollowUpSurvey}
        submitAnswer={props.submitAnswer}
      />
    </SurveyRoute>
  );
}
