import { SummaryLengthOption } from '.';
import { InterviewService } from '../../../../services/InterviewService';

export function generateAiResponse(
  interviewdId: number,
  topics: string[],
  length: SummaryLengthOption,
  setValue: (value: string) => void,
  setIsAiGenerating: (state: boolean) => void,
  setIsErrorMessage: (isErrorMessage: boolean) => void,
) {
  let response = '';
  setIsAiGenerating(true);

  const onMessage = (message: MessageEvent) => {
    const data = JSON.parse(message.data);
    if (data.error) {
      setIsErrorMessage(true);
    }

    response += data.response;
    setValue(response);
  };

  InterviewService.aiGenerateCustomSummary({
    id: interviewdId,
    topics,
    length,
    onMessage,
    onEnd: () => {
      setIsAiGenerating(false);
    },
  });
}
