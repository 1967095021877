import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface PropTypes {
  className: string;
  message?: string | ReactNode;
}

function showToast(message?: string | ReactNode): boolean {
  if (!message) return false;

  return !(message instanceof String && message.trim() === '');
}

export function ToastMessage(props: PropTypes) {
  return (
    <label
      className={classNames(
        'fade',
        'px-2h',
        'bg-gray',
        'fs-6',
        showToast(props.message) && 'show',
        classNames(props.className),
      )}
    >
      {props.message}
    </label>
  );
}
