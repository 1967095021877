export function applyLargestRemainderMethod(
  arr: number[],
  expectedSum: number,
): number[] {
  const rounded: number[] = arr.map((x) => Math.floor(x));
  const afterRoundSum = rounded.reduce((pre, curr) => pre + curr, 0);
  const countMutableItems = rounded.filter((x) => x >= 1).length;
  const errorRate = expectedSum - afterRoundSum;

  const deductPortion = Math.ceil(errorRate / countMutableItems);

  const biggest = [...rounded]
    .sort((a, b) => b - a)
    .slice(0, Math.min(Math.abs(errorRate), countMutableItems));
  const result = rounded.map((x) => {
    const indexOfX = biggest.indexOf(x);
    if (indexOfX >= 0) {
      x += deductPortion;
      biggest.splice(indexOfX, 1);
      return x;
    }
    return x;
  });
  return result;
}
