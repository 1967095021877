import classNames from 'classnames';
import React from 'react';
import styles from '../styles.module.scss';
import { MetricValue } from './MetricValue';

interface PropTypes {
  value: string | number;
  label: string;
  color?: string;
  info?: string;
  infoColor?: string;
}

export function Metric(props: PropTypes) {
  return (
    <div>
      <MetricValue {...props} />
      <div
        className={classNames(
          'fw-semibold text-muted',
          styles['responsive-fs-6'],
        )}
      >
        {props.label.toUpperCase()}
      </div>
    </div>
  );
}
