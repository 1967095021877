import React from 'react';
import { SchedulingUserIntegration } from '../../../../entities/SchedulingUserIntegration';
import { humanize } from '../../../../utils/humanize';
import { SchedulingIntegrationsService } from '../../../../services/AccountSettingsService/SchedulingIntegrationsService';
import GoogleCalendarIcon from '../../../../images/logos/google_calendar.svg';
import AppleIcon from '../../../../images/logos/apple.svg';
import ExchangeIcon from '../../../../images/logos/exchange.svg';
import OutlookIcon from '../../../../images/logos/outlook.svg';
import { AlertType } from '../../../../components/Alert';
import { AccountIntegrationRow } from '.././AccountIntegrationRow';
import { IntegrationIcon } from '../../../../components/./IntegrationIcon';

interface PropTypes {
  integration?: SchedulingUserIntegration;
  authorizeUrl: string;
  onIntegration: (integration) => void;
  setAlertMessage: (message: string) => void;
  setAlertType: (type: AlertType) => void;
  reloadVcsProviders: () => void;
}

function getIconPath(provider?: string) {
  switch (provider) {
    case 'apple':
      return AppleIcon;
    case 'google':
      return GoogleCalendarIcon;
    case 'exchange':
      return ExchangeIcon;
    case 'live_connect':
      return OutlookIcon;
    default:
      return null;
  }
}

function Icon(props: { provider?: string }) {
  const logoPath = getIconPath(props.provider);
  const backgroundClassName = logoPath ? 'bg-gray' : 'bg-blue-500';

  return (
    <IntegrationIcon
      className={backgroundClassName}
      imgSrc={logoPath}
      iconClassName={'bi bi-calendar3 text-white'}
    />
  );
}

export function SchedulingIntegrationRow(props: PropTypes) {
  const calendarName =
    props.integration?.calendarProvider === 'live_connect'
      ? 'Outlook'
      : props.integration?.calendarProvider;

  async function handleDisconnectClick() {
    try {
      await SchedulingIntegrationsService.destroy();
      props.reloadVcsProviders();
      props.onIntegration(null);
      props.setAlertMessage('Your calendar was disconnected successfully!');
      props.setAlertType(AlertType.Success);
    } catch (e) {
      props.setAlertMessage('The calendar could not be disconnected!');
      props.setAlertType(AlertType.Danger);
    }
  }

  return (
    <AccountIntegrationRow
      integration={props.integration}
      integrationIcon={<Icon provider={props.integration?.calendarProvider} />}
      authorizeUrl={props.authorizeUrl}
      onDisconnect={handleDisconnectClick}
      connectedMessage={`Connected with ${humanize(calendarName)} calendar`}
      disconnectedMessage={'Connect your account with a calendar'}
      selectionMessage={'Select a calendar to integrate'}
      modalTitle={'Disconnect Calendar'}
      modalContent={'Are you sure you want to disconnect the calendar?'}
    />
  );
}
