import React from 'react';
import EmptyStateImage from '../../../images/empty-state-email.png';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PropTypes {
  title: string;
  subtitle: string;
}

export function BlankState(props: PropTypes) {
  return (
    <div className='h-100 d-flex flex-column align-items-center justify-content-center text-center'>
      <img
        src={EmptyStateImage}
        className={classNames(styles['empty-state-image-sizes'])}
      />
      <h4 className='mt-3'>{props.title}</h4>
      <p className='mt-2'>{props.subtitle}</p>
    </div>
  );
}
