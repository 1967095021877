import React from 'react';
import { Button } from 'reactstrap';
import { ApplicationFeedComment } from '../../../../../../../entities/ApplicationFeedComment';
import { ApplicationFeedCommentService } from '../../../../../../../services/applicant_tracking/ApplicationFeedCommentService';

interface ModalProps {
  comment: ApplicationFeedComment;
  onClose: (state?: boolean) => void;
}

export function ModalBody(props: ModalProps) {
  const deleteComment = async () => {
    try {
      await ApplicationFeedCommentService.delete(props.comment.id);
      props.onClose(true);
    } catch (error) {
      props.onClose(false);
    }
  };

  return (
    <>
      <div className='mb-2 fs-4'>
        Are you sure that you want to delete this following comment?
      </div>
      <div className='d-flex mt-5 justify-content-end'>
        <Button color='danger' className='me-3' onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button color='primary' onClick={deleteComment}>
          Delete
        </Button>
      </div>
    </>
  );
}
