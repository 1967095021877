import React, { useState } from 'react';
import { User } from '../../../../../../entities/User';
import { ApplicationFeedComment } from '../../../../../../entities/ApplicationFeedComment';
import { ApplicationFeed } from '../../../../../../entities/ApplicationFeed';
import { EditComment } from '../EditComment';
import { CommentBlock } from './CommentBlock';
import { DeleteCommentModal } from './DeleteCommentModal';
import { AlertObject } from '../../../../../../components/Alert';

interface PropTypes {
  applicationFeed: ApplicationFeed;
  comment: ApplicationFeedComment;
  currentUser: User;
  reloadApplication: (state: boolean) => void;
  setAlert: (data: AlertObject) => void;
}

export function SingleComment(props: PropTypes) {
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  return (
    <>
      {showDeleteModal && (
        <DeleteCommentModal
          comment={props.comment}
          showDeleteModal={showDeleteModal}
          reloadApplication={props.reloadApplication}
          setShowDeleteModal={setShowDeleteModal}
          setAlert={props.setAlert}
        />
      )}
      {isEditing ? (
        <EditComment
          {...props}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setAlert={props.setAlert}
        />
      ) : (
        <CommentBlock
          {...props}
          onEdit={() => setIsEditing(true)}
          onDelete={() => setShowDeleteModal(true)}
        />
      )}
    </>
  );
}
