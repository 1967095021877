import React from 'react';
import { Navigate } from 'react-router';
import { SurveyStatus } from '../../../entities/survey_engine/SurveyStatus';
import { Survey } from '../../../entities/survey_engine/Survey';

interface PropTypes {
  survey: Survey;
  status: SurveyStatus;
  mismatchPath: string;
  children: JSX.Element;
}

export function SurveyRoute(props: PropTypes) {
  if (props.status !== props.survey.status || props.survey.disabled) {
    return <Navigate to={props.mismatchPath} />;
  }

  return props.children;
}
