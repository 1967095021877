import { ApiService } from '../../../../ApiService';
import { FilterState } from '../../../../../containers/applicant_tracking/Analytics/Filters';
import { mapToParams } from '../../mapToParams';

export class ExportsService {
  public static pdf(filterState?: FilterState): Promise<Blob> {
    return ApiService.get(
      '/applicant_tracking/analytics/overview/exports/pdf',
      mapToParams(filterState),
    );
  }
}
