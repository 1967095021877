import React from 'react';
import classNames from 'classnames';
import { Select, SelectOption } from '../../../../../components/Select';
import { SCHEDULE_FROM, ScheduleFromType } from './ScheduleFromTypes';
import { Size } from '../../../../../utils/types/Size';
import { InfoTooltip } from '../../../../../components/InfoTooltip';

interface PropTypes {
  scheduleFrom: ScheduleFromType;
  setScheduleFrom: (scheduleFrom: ScheduleFromType) => void;
  className?: string;
  titleClassName?: string;
  selectClassName?: string;
  selectSize?: Size;
  toolTipText?: string;
  readOnly?: boolean;
}

export const DEFAULT_SCHEDULE_FROM_HOURS = 0;

export function ScheduleFromSelect(props: PropTypes) {
  const scheduleFromOptions = Object.keys(SCHEDULE_FROM).map(
    (option: string) => {
      return {
        value: option,
        label: SCHEDULE_FROM[option],
        selected: option === props.scheduleFrom,
      };
    },
  );
  const selectedOption = scheduleFromOptions.find((option) => option.selected);

  return (
    <div
      data-testid='daterange_select'
      className={classNames(props.className, 'align-items-center')}
    >
      <div className={'d-flex'}>
        <span className={classNames(props.titleClassName)}>Schedule from</span>
        {props.toolTipText && (
          <InfoTooltip className={'ms-1'}>
            This will allow scheduling from the moment the link is shared.
          </InfoTooltip>
        )}
      </div>
      <Select
        options={scheduleFromOptions}
        placeholder='Select schedule from'
        size={props.selectSize || 'sm'}
        onChange={(option: SelectOption) =>
          props.setScheduleFrom(option.value as ScheduleFromType)
        }
        selected={selectedOption}
        className={props.selectClassName}
        isClearable={false}
        disabled={!!props.readOnly}
      />
    </div>
  );
}
