import React from 'react';
import { Row } from 'reactstrap';
import { SurveyLayout } from '../../../components/SurveyLayout';
import { ReferenceMainPanel } from './ReferenceMainPanel';
import { Survey } from '../../../entities/Survey';
import { isSelfAssessmentSurvey } from '../../../utils/isSelfAssessmentSurvey';
import { SelfAssessmentMainPanel } from './SelfAssessmentMainPanel';

interface PropTypes {
  survey: Survey;
  onStartSurvey: () => void;
}

export function SurveyStartScreen({ survey, onStartSurvey }: PropTypes) {
  return (
    <SurveyLayout companyInfo={survey.company_info}>
      <Row>
        {isSelfAssessmentSurvey(survey) ? (
          <SelfAssessmentMainPanel onStartSurvey={onStartSurvey} />
        ) : (
          <ReferenceMainPanel survey={survey} onStartSurvey={onStartSurvey} />
        )}
      </Row>
    </SurveyLayout>
  );
}
