import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CronofyOptions } from '../../../../components/cronofy/CronofyOptions';
import { InterviewStage } from '../../../../entities/applicant_tracking/InterviewStage';
import { EventSlot } from '../../../../components/cronofy/CronofyDatePicker/EventSlot';
import { InterviewDetails } from './InterviewDetails';
import { InterviewDatePicker } from './InterviewDatePicker';
import { User } from '../../../../entities/User';
import { Candidate } from '../../../../entities/applicant_tracking/Candidate';
import { ApplicationService } from '../../../../services/ApplicationService';
import { Application } from '../../../../entities/Application';
import { InterviewListItem } from '../../../../entities/InterviewListItem';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { VcsProvider } from '../../../../enums/IntegrationProvider/VcsProvider';
import { Interview } from '../../../../entities/applicant_tracking/Interview';
import { InterviewerParticipant } from '../../../../entities/applicant_tracking/InterviewerParticipant';
import {
  mapIntToScheduleFrom,
  ScheduleFromType,
} from './ScheduleFromSelect/ScheduleFromTypes';
import { DEFAULT_DURATION_IN_MINUTES } from './InterviewDetails/InterviewDuration';
import { DEFAULT_DATE_RANGE } from '../../../../components/DateRangeSelect';
import { DEFAULT_SCHEDULE_FROM_HOURS } from './ScheduleFromSelect';
import {
  getAvailableMeetingProviders,
  getDefaultMeetingProvider,
} from '../../../../utils/applicant_tracking/interviews/interviews';

interface PropTypes {
  meetingOrganizer: User;
  applicationIds: number[];
  interview?: Interview;
  title: string;
  cronofyDatePickerOptions: CronofyOptions;
  candidate?: Candidate;
  participants: User[];
  availableParticipants: User[];
  interviewStage: InterviewStage;
  meetingProviders: VcsProvider[];
  meetingProvider: VcsProvider;
  durationMinutes?: number;
  customImage?: string;
  description?: string;
  candidateView: boolean;
  selfScheduleView: boolean;
  jobName?: string;
  startDate?: Date;
  scheduleFromHours?: number;
  dateRange?: number;
  timezone?: string;
  interviewDatePickerFooter?: React.ReactNode;
  onConfirm: (
    eventSlot: EventSlot,
    participants?: InterviewerParticipant[],
    meetingProvider?: string,
  ) => void;
  allowChangingMeetingOrganizer: boolean;
  allowIgnoreParticipantCalendar: boolean;
  setSchedulingSettings?: (schedulingSettings: SchedulingSettings) => void;
}

export interface SchedulingSettings {
  participants: InterviewerParticipant[];
  durationMinutes: number;
  meetingProvider: VcsProvider;
  dateRange: number;
  scheduleFrom: ScheduleFromType;
}

export default function ScheduleInterviewPage(props: PropTypes) {
  const [participants, setParticipants] = useState<InterviewerParticipant[]>(
    props.participants.map((user) => {
      return {
        user: user,
        isMeetingOrganizer: user.id === props.meetingOrganizer.id,
        ignoreCalendar: false,
      };
    }),
  );
  const [durationMinutes, setDurationMinutes] = useState<number>(
    props.durationMinutes || DEFAULT_DURATION_IN_MINUTES,
  );

  const [meetingProvider, setMeetingProvider] = useState<VcsProvider>(
    props.meetingProvider,
  );
  const [meetingProviders, setMeetingProviders] = useState<VcsProvider[]>(
    props.meetingProviders,
  );

  const [dateRange, setDateRange] = useState<number>(
    props.dateRange || DEFAULT_DATE_RANGE,
  );

  const [scheduleFrom, setScheduleFrom] = useState<ScheduleFromType>(
    mapIntToScheduleFrom(
      props.scheduleFromHours || DEFAULT_SCHEDULE_FROM_HOURS,
    ),
  );

  const onChangingMeetingOrganizer = (participant: InterviewerParticipant) => {
    setMeetingProvider(getDefaultMeetingProvider(participant.user));
    setMeetingProviders(
      getAvailableMeetingProviders(participant.user, meetingProviders),
    );
  };

  const [applicationInterviews, setApplicationInterviews] =
    useState<InterviewListItem[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!props.candidateView) {
    useEffect(() => {
      (async () => {
        if (props.applicationIds.length != 1) {
          return;
        }

        const applicationId = props.applicationIds[0];

        setIsLoading(true);

        if (applicationInterviews == null) {
          const application: Application = await ApplicationService.get(
            applicationId,
          );

          setApplicationInterviews(application.interviews);
        }

        setIsLoading(false);
      })();
    }, [applicationInterviews]);
  }

  useEffect(() => {
    if (props.setSchedulingSettings) {
      props.setSchedulingSettings({
        participants: participants,
        durationMinutes: durationMinutes,
        meetingProvider: meetingProvider,
        dateRange: dateRange,
        scheduleFrom: scheduleFrom,
      });
    }
  }, [participants, durationMinutes, meetingProvider, dateRange, scheduleFrom]);

  return (
    <Row className={'gx-0'}>
      <Col lg={4} className={classNames(styles['col-wrapper'], 'me-4')}>
        <InterviewDetails
          title={props.title}
          description={props.description}
          applicationIds={props.applicationIds}
          candidate={props.candidate}
          participants={participants}
          setParticipants={setParticipants}
          availableParticipants={props.availableParticipants}
          durationMinutes={durationMinutes}
          setDurationMinutes={setDurationMinutes}
          meetingProvider={meetingProvider}
          setMeetingProvider={setMeetingProvider}
          meetingProviders={meetingProviders}
          customImage={props.customImage}
          candidateView={props.candidateView}
          onChangingMeetingOrganizer={onChangingMeetingOrganizer}
          allowChangingMeetingOrganizer={props.allowChangingMeetingOrganizer}
          allowIgnoreParticipantCalendar={props.allowIgnoreParticipantCalendar}
        />
      </Col>
      <Col className={classNames(styles['col-wrapper'], 'p-4')}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <InterviewDatePicker
            cronofyDatePickerOptions={props.cronofyDatePickerOptions}
            applicationInterviews={applicationInterviews}
            interview={props.interview}
            participants={participants}
            meetingProvider={meetingProvider}
            durationMinutes={durationMinutes}
            interviewStage={props.interviewStage}
            onConfirm={props.onConfirm}
            candidateView={props.candidateView}
            selfScheduleView={props.selfScheduleView}
            jobName={props.jobName}
            startDate={props.startDate}
            scheduleFrom={scheduleFrom}
            setScheduleFrom={setScheduleFrom}
            dateRange={dateRange}
            setDateRange={setDateRange}
            timezone={props.timezone}
            interviewDatePickerFooter={props.interviewDatePickerFooter}
          />
        )}
      </Col>
    </Row>
  );
}
