import snakecaseKeys from 'snakecase-keys';
import { Settings } from '../../../../entities/v1/interview_intelligence/Settings';
import { ApiService } from '../../../ApiService';

export class SettingsService {
  public static update(params: Settings): Promise<void> {
    return ApiService.patch(
      '/api/v1/interview_intelligence/settings',
      snakecaseKeys(params),
    );
  }
}
