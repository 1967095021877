import React from 'react';
import { PropTypes as SelectPropTypes, SelectOption } from '.';
import classNames from 'classnames';
import { ClearIndicator } from './ClearIndicator';
import { DropdownIndicator } from './DropdownIndicator';
import { selectTheme } from './selectTheme';
import { selectStyle } from './selectStyle';
import { ActionMeta } from 'react-select';
import { Option } from './Option';
import ReactAsyncSelect from 'react-select/async';

interface PropTypes extends Omit<SelectPropTypes, 'options'> {
  loadOptions: (inputValue: string) => Promise<SelectOption[]>;
}

export function AsyncSelect(props: PropTypes) {
  return (
    <ReactAsyncSelect
      // Async
      cacheOptions
      defaultOptions
      loadOptions={props.loadOptions}
      // Rest
      name={props.name}
      className={classNames(
        props.className,
        props.size === 'sm' ? 'fs-6' : 'fs-5',
      )}
      classNamePrefix='tags'
      id={props.testId}
      value={props.selected ?? null}
      placeholder={
        <div className='text-truncate'>{props.placeholder || 'Select...'}</div>
      }
      isClearable={props.isClearable}
      isDisabled={props.disabled}
      onChange={(option: SelectOption, action: ActionMeta<string>) =>
        props.onChange?.(option, action)
      }
      menuPlacement={props.menuPlacement || 'auto'}
      components={{
        ClearIndicator,
        DropdownIndicator,
        Option,
      }}
      theme={selectTheme}
      styles={selectStyle(props.size || 'md')}
      maxMenuHeight={props.maxMenuHeight}
      isLoading={props.isLoading}
      isSearchable={props.isSearchable}
    />
  );
}
