const qualityOfHireFinalScreen = {
  title: 'Success!',
  subtitle: 'Thank you for filling the survey.',
  iconClass: 'bi-check-circle',
};

export const customFinalScreens = {
  'Hiring Manager Employee Evaluation Track': qualityOfHireFinalScreen,
  'Employee Evaluation Track': qualityOfHireFinalScreen,
};
