import React, { useEffect, useState } from 'react';
import { Select, SelectOption } from '../../../components/Select';
import { Application, BriefApplication } from '../../../entities/Application';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CandidateTab } from '../CandidateTab';

interface PropTypes {
  currentTab: CandidateTab;
  application: Application;
}

function mapToOption(
  chosenApplicationId: number,
  application: BriefApplication,
): SelectOption {
  return {
    value: application.id.toString(),
    label: application.job?.name,
    selected: chosenApplicationId === application.id,
  };
}

export function ApplicationSelection(props: PropTypes) {
  if (
    props.application.candidate.applications == null ||
    props.application.candidate.applications?.length === 0
  ) {
    return null;
  }

  const options: SelectOption[] = props.application.candidate.applications.map(
    (application) => mapToOption(props.application.id, application),
  );
  const [currentValue, setCurrentValue] = useState<SelectOption>(
    options.find((option) => option.selected),
  );
  const [previousValue, setPreviousValue] = useState<SelectOption>();

  useEffect(() => {
    const selectedOption = options.find(
      (option) => option.value === props.application.id.toString(),
    );

    setPreviousValue(selectedOption);
    setCurrentValue(selectedOption);
  }, []);

  useEffect(() => {
    if (previousValue != null && previousValue.value !== currentValue.value) {
      window.location.href = `${currentValue.value}?tab=${props.currentTab}`;
    }
  }, [currentValue]);

  return (
    <Select
      className={classNames(
        'text-start',
        'align-items-center',
        'me-2',
        styles['application-select'],
      )}
      options={options}
      selected={currentValue}
      onChange={(v) => setCurrentValue(v)}
    />
  );
}
