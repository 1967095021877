import classNames from 'classnames';
import React, { useState } from 'react';
import { InterviewRating } from '../../../../../entities/InterviewRating';
import { InterviewSidekick } from '../../../../../entities/InterviewSidekick';
import { Timedelta } from '../../../../../utils/timedelta';
import { RatingModal } from '../../../../InterviewPage/RatingModal';
import styles from './styles.module.scss';

interface PropTypes {
  interview: InterviewSidekick;
  isEnabled: boolean;
}

function RatingPanel(props: { onClick: () => void }) {
  return (
    <div className='bg-blue mx-3 mt-3 p-2 text-white rounded fs-5'>
      <i className='bi bi-star ms-3 me-2'></i>
      <span
        className={classNames('fw-bold ms-1', styles.pointer)}
        onClick={props.onClick}
      >
        Click here
      </span>{' '}
      to rate the interview
    </div>
  );
}

function AlertPanel(props: { text: string; onClose: () => void }) {
  return (
    <div className='bg-light-success mx-3 mt-3 p-3 rounded d-flex justify-content-between fs-5'>
      {props.text}
      <button
        type='button'
        className='btn-close pe-2'
        data-bs-dismiss='alert'
        data-dismiss='alert'
        aria-label='Close'
        onClick={props.onClose}
      />
    </div>
  );
}

function calcTimeToActivateRating(interview: InterviewSidekick) {
  const OFFSET_MINUTES = 10;

  if (interview.end_date) {
    const timeDifference =
      Timedelta.timeTillDate(interview.end_date).toMs() -
      Timedelta.fromMins(OFFSET_MINUTES).toMs();
    return timeDifference > 0 ? timeDifference : 0;
  } else {
    return 0;
  }
}

export function InterviewRatingPanel(props: PropTypes) {
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [ratingPanelEnabled, setRatingPanelEnabled] = useState(props.isEnabled);
  const [alertActive, setAlertActive] = useState(false);
  const [ratingPanelActive, setRatingPanelActive] = useState(false);
  const [alertText, setAlertText] = useState('');

  if (ratingPanelEnabled) {
    const timeToActivate = calcTimeToActivateRating(props.interview);
    setTimeout(() => setRatingPanelActive(true), timeToActivate);
  }

  const handleRatingClick = () => {
    setRatingModalOpen(true);
    setRatingPanelEnabled(false);
    setRatingPanelActive(false);
  };

  const handleRatingClose = (rating: InterviewRating) => {
    setRatingModalOpen(false);
    setAlertActive(true);

    if (rating) {
      setAlertText('Interview successfully rated.');
    } else {
      setAlertText(
        'You can always rate it later on the interview details page.',
      );
    }
  };

  return (
    <>
      {ratingPanelActive && <RatingPanel onClick={handleRatingClick} />}
      {alertActive && (
        <AlertPanel text={alertText} onClose={() => setAlertActive(false)} />
      )}
      {ratingModalOpen && (
        <RatingModal
          isOpen={ratingModalOpen}
          interviewId={props.interview.id}
          ratingOptions={props.interview.rating_options}
          onClose={handleRatingClose}
        />
      )}
    </>
  );
}
