import { Employee } from '../Employee';

export enum LeftReason {
  LeftOrganization = 'left_organization',
  CanNotAnswer = 'can_not_answer',
  PreferNotToAnswer = 'prefer_not_to_answer',
}

export interface HiringManagerEmployeeEvalSurvey {
  id: number;
  partOfTeam?: boolean;
  leftReason?: LeftReason;
  employee: Employee;
}
