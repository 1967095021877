import React from 'react';
import { ScorecardHeader } from './ScorecardHeader';
import { ScorecardBody } from './ScorecardBody';
import { AccordionPanel } from '../../../../../../components/AccordionPanel';
import { ScorecardButton } from './ScorecardButton';
import { User } from '../../../../../../entities/User';
import { Scorecard } from '../../../../../../entities/applicant_tracking/Scorecard';
import styles from './styles.module.scss';

interface PropTypes {
  scorecard: Scorecard;
  currentUser: User;
  opened?: boolean;
  viewAllScorecards: boolean;
}

export function ScorecardBlock(props: PropTypes) {
  return (
    <>
      {props.scorecard.survey?.status === 'completed' ? (
        <AccordionPanel
          title={
            <ScorecardHeader
              currentUser={props.currentUser}
              scorecard={props.scorecard}
              viewAllScorecards={props.viewAllScorecards}
            />
          }
          className={'mb-4'}
          id={`${props.scorecard.id}`}
          highlightOnHover={true}
          opened={props.opened}
          itemClassName={styles['box-shadow']}
        >
          <ScorecardBody
            currentUser={props.currentUser}
            scorecard={props.scorecard}
            viewAllScorecards={props.viewAllScorecards}
          />
        </AccordionPanel>
      ) : (
        <div className='d-flex bg-white mb-4 p-4 rounded-3'>
          <ScorecardHeader
            currentUser={props.currentUser}
            scorecard={props.scorecard}
            viewAllScorecards={props.viewAllScorecards}
          />
          <ScorecardButton
            scorecard={props.scorecard}
            currentUser={props.currentUser}
          />
        </div>
      )}
    </>
  );
}
