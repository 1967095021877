import { SelectOption } from '../../../../../components/Select';
import { Row } from 'reactstrap';
import stylesheetExports from '../../../../../stylesheets/export.module.scss';
import React from 'react';
import { QuestionTopChoices } from '../../../../../entities/candidate_experience/QuestionTopChoices';
import { InterviewerAnalytics } from '../../../../../entities/candidate_experience/InterviewerAnalytics';
import { TopChoiceTablePanel } from '../../../shared_panels/TablePanel/TopChoiceTablePanel';
import { PageBreak } from '../../../CandidatePulsePage/PageBreak';
import { TopChoicesOrEmptyState } from './TopChoicesOrEmptyState';

export function InterviewerTopChoicePanels(props: {
  analytics: InterviewerAnalytics;
  benchmarkType: SelectOption;
}) {
  return (
    <>
      <PageBreak />
      <FeedbackTopChoices
        recruitmentImprovementsNegative={
          props.analytics.recruitment_improvements_negative
        }
        recruitmentImprovementsNeutral={
          props.analytics.recruitment_improvements_neutral
        }
        benchmarkType={props.benchmarkType}
      />
    </>
  );
}

function FeedbackTopChoices(props: {
  recruitmentImprovementsNegative: QuestionTopChoices;
  recruitmentImprovementsNeutral: QuestionTopChoices;
  benchmarkType: SelectOption;
}) {
  return (
    <Row>
      <ImprovementNegativeFeedbackTopChoicesOrEmptyState
        recruitmentImprovementsNegative={props.recruitmentImprovementsNegative}
        benchmarkType={props.benchmarkType}
      />
      <ImprovementNeutralFeedbackTopChoicesorEmptyState
        recruitmentImprovementsNeutral={props.recruitmentImprovementsNeutral}
        benchmarkType={props.benchmarkType}
      />
    </Row>
  );
}

function ImprovementNegativeFeedbackTopChoices(props: {
  recruitmentImprovementsNegative: QuestionTopChoices;
  benchmarkType: SelectOption;
}) {
  if (
    props.recruitmentImprovementsNegative === undefined ||
    props.recruitmentImprovementsNegative.top_choices.length === 0
  )
    return null;

  return (
    <TopChoiceTablePanel
      title='Negative (0-6)'
      subtitle={props.recruitmentImprovementsNegative.question_text}
      tableClassName={'table-borderless'}
      entries={props.recruitmentImprovementsNegative.top_choices.map((tc) => ({
        text: tc.name,
        average: parseFloat(tc.weight),
        benchmarkAverage: parseFloat(tc.benchmark_weight),
      }))}
      enableBenchmark={true}
      benchmarkType={props.benchmarkType}
      multiplier={100}
      displayPercentage={true}
      fillColor={stylesheetExports.orange500}
    />
  );
}

function ImprovementNeutralFeedbackTopChoices(props: {
  recruitmentImprovementsNeutral: QuestionTopChoices;
  benchmarkType: SelectOption;
}) {
  if (
    props.recruitmentImprovementsNeutral === undefined ||
    props.recruitmentImprovementsNeutral.top_choices.length === 0
  )
    return null;

  return (
    <TopChoiceTablePanel
      title='Neutral (7-8)'
      subtitle={props.recruitmentImprovementsNeutral.question_text}
      tableClassName={'table-borderless'}
      entries={props.recruitmentImprovementsNeutral.top_choices.map((tc) => ({
        text: tc.name,
        average: parseFloat(tc.weight),
        benchmarkAverage: parseFloat(tc.benchmark_weight),
      }))}
      enableBenchmark={true}
      benchmarkType={props.benchmarkType}
      multiplier={100}
      displayPercentage={true}
      fillColor={stylesheetExports.blue500}
    />
  );
}

function ImprovementNegativeFeedbackTopChoicesOrEmptyState(props: {
  recruitmentImprovementsNegative: QuestionTopChoices;
  benchmarkType: SelectOption;
}) {
  return (
    <TopChoicesOrEmptyState
      recruitmentImprovements={props.recruitmentImprovementsNegative}
      title={'Negative (0-6)'}
    >
      <ImprovementNegativeFeedbackTopChoices
        recruitmentImprovementsNegative={props.recruitmentImprovementsNegative}
        benchmarkType={props.benchmarkType}
      />
    </TopChoicesOrEmptyState>
  );
}

function ImprovementNeutralFeedbackTopChoicesorEmptyState(props: {
  recruitmentImprovementsNeutral: QuestionTopChoices;
  benchmarkType: SelectOption;
}) {
  return (
    <TopChoicesOrEmptyState
      recruitmentImprovements={props.recruitmentImprovementsNeutral}
      title={'Neutral (7-8)'}
    >
      <ImprovementNeutralFeedbackTopChoices
        recruitmentImprovementsNeutral={props.recruitmentImprovementsNeutral}
        benchmarkType={props.benchmarkType}
      />
    </TopChoicesOrEmptyState>
  );
}
