import React from 'react';
import { Dropdown } from '../../../../Dropdown';
import { JobStageTemplateInput } from '../../../../../containers/PlanTemplates/SetupPlanTemplatePage';
import { IconSpan } from '../../../../IconSpan';
import { PropTypes as ActionPropTypes } from '../../../../Dropdown/DropdownMenu/DropdownAction';
import { StageMoveDTO } from '../../../../../containers/PlanTemplates/SetupPlanTemplatePage/DTOs/StageMoveDTO';
import styles from './styles.module.scss';

interface PropTypes {
  jobStageTemplate: JobStageTemplateInput;
  isFocused: boolean;
  stageMoves?: StageMoveDTO[];
  onEdit: () => void;
  onDelete: () => void;
}

function deleteStage(props: PropTypes): ActionPropTypes {
  return {
    action: props.onDelete,
    buttonChild: (
      <IconSpan
        spanText={'Delete'}
        icon={{ name: 'bi-trash', className: 'fs-4' }}
        className={'text-blue'}
      />
    ),
  };
}

function editStage(props: PropTypes): ActionPropTypes {
  return {
    action: props.onEdit,
    buttonChild: (
      <IconSpan
        spanText={'Edit'}
        icon={{ name: 'bi-pencil', className: 'fs-4' }}
        className='text-blue'
      />
    ),
  };
}

export default function RowActionsDropdown(props: PropTypes) {
  const color = props.isFocused ? 'text-white' : 'text-info';
  const menuActions = [editStage(props)];
  const receivingMoves =
    props.stageMoves.filter((move) => move.to === props.jobStageTemplate.name)
      .length > 0;

  if (!receivingMoves) {
    menuActions.push(deleteStage(props));
  }

  return (
    <Dropdown
      buttonIcon={{
        name: 'bi-three-dots-vertical',
        className: `fs-4 ${color}`,
      }}
      buttonClassName={styles['selected-dropdown']}
      menuPlacementClass={'dropdown-menu-end'}
      menuActions={menuActions}
    />
  );
}
