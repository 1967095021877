import React from 'react';

interface PropTypes {
  isFormActive: boolean;
  emailDomain: string;
  setEmailDomain: (emailAlias: string) => void;
}

export function EmailDomainInput(props: PropTypes) {
  return (
    <input
      type='url'
      className='form-control form-control-sm'
      placeholder='yourdomain.com'
      id='emailDomain'
      aria-label='emailDomain'
      disabled={!props.isFormActive}
      value={props.emailDomain}
      onChange={(e) => props.setEmailDomain(e.target.value)}
    />
  );
}
