import { InterviewKitTemplateService } from '../../../services/v1/applicant_tracking/InterviewKitTemplateService';
import { randomInt } from '../../../utils/number';
import {
  FieldConfig,
  FieldData,
} from '../../survey_engine/SurveyTemplates/Field';
import { SectionData } from '../../survey_engine/SurveyTemplates/Section';

export const FIELD_CONFIGS: FieldConfig[] = [
  {
    label: 'Open Text Response',
    fieldType: 'text',
  },
];

export async function fetchTemplate(id: string) {
  const template = await InterviewKitTemplateService.show(id, {
    expand: [
      'survey_template',
      'survey_template.sections',
      'survey_template.sections.questions',
    ],
  });

  const cSections: SectionData[] = [];
  for (const sSection of template.surveyTemplate.sections) {
    const cFields: FieldData[] = [];
    for (const sField of sSection.questions) {
      cFields.push({
        serverId: sField.id,
        clientId: randomInt(),
        title: sField.title,
        label: 'Open Text Response',
        fieldType: 'text',
        aiFillEnabled: sField.aiFillEnabled,
      });
    }

    cSections.push({
      sId: sSection.id,
      cId: randomInt(),
      name: sSection.name,
      fields: cFields,
    });
  }

  return {
    name: template.surveyTemplate.name,
    sections: cSections,
    default: template.default,
  };
}

export function areSectionsSavable(sections: SectionData[]) {
  for (const section of sections) {
    if (!section.name.trim()) return false;

    for (const field of section.fields) {
      if (!field.title.trim()) return false;
    }
  }

  return true;
}
