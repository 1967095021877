import React from 'react';
import { MonologueToken } from '../../../entities/v1/interview_intelligence/Monologue';

export interface TokenItemExtraProps {
  monologueActive: boolean;
  activeStyle: object;
  playTimestamp: number;
  selectionTriggerOffsetMs: number;
}

interface PropTypes extends TokenItemExtraProps {
  token: MonologueToken;
  active: boolean;
  suppressSpace?: boolean;
}

export function TokenItem(props: PropTypes) {
  if (props.token.text === '') return null;

  return (
    <span
      style={
        props.monologueActive &&
        props.playTimestamp >=
          props.token.startOffsetMs + props.selectionTriggerOffsetMs
          ? props.activeStyle
          : {}
      }
      data-active={props.active}
    >
      {props.token.tokenType === 'WORD' && !props.suppressSpace ? ' ' : ''}
      {props.token.text}
    </span>
  );
}
