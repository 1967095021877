import React from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { routes } from '../../config/routes';
import { SurveyPage } from '../SurveyPage';
import { CandidateSurveyRequestPage } from '../CandidateSurveyRequestPage';

export function ReferenceCheckApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.referenceCheck.path}>
          <Route path={routes.referenceCheck.surveys.path}>
            <Route
              path={routes.referenceCheck.surveys.survey.path}
              element={<SurveyPage />}
            />
          </Route>
          <Route path={routes.referenceCheck.candidate.path}>
            <Route path={routes.referenceCheck.candidate.surveyRequests.path}>
              <Route
                path={routes.referenceCheck.candidate.surveyRequests.show.path}
                element={<CandidateSurveyRequestPage />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
