import React, { useEffect, useState } from 'react';
import { ScorecardAttributeValue } from './ScorecardAttributeValue';
import { updateSurveyAnswers } from '../../../../../../../utils/applicant_tracking/scorecardSurvey';
import { Survey } from '../../../../../../../entities/v1/survey_engine/Survey';
import { Section } from '../../../../../../../entities/v1/survey_engine/Section';
import { Question } from '../../../../../../../entities/v1/survey_engine/Question';
import { Answer } from '../../../../../../../entities/v1/survey_engine/Answer';
import { ScorecardService } from '../../../../../../../services/v1/applicant_tracking/ScorecardService';

interface PropTypes {
  scorecardId: number;
  attribute: Question;
  section: Section;
  editable: boolean;
  survey: Survey;
  fillColorClass?: string;
  setSurvey: (survey: Survey) => void;
}

function getRatingValue(rating: number, fieldAnswer?: Answer): number | null {
  // This will allow deselect the current rating.
  if (fieldAnswer == null || fieldAnswer.value === rating?.toString()) {
    return null;
  }

  return rating;
}

async function applyRating(
  scorecardId: number,
  surveyId: number,
  attribute: Question,
  rating: number,
  fieldAnswer?: Answer,
): Promise<void> {
  if (fieldAnswer == null) {
    return;
  }

  return ScorecardService.answerAttribute(scorecardId, {
    surveyId: surveyId,
    questionId: attribute.id,
    value: rating?.toString(),
  });
}

function getFieldAnswer(survey: Survey, attributeFieldId: number) {
  const fieldAnswer = survey.answers?.find(
    (f) => f.questionId === attributeFieldId,
  );

  return (
    fieldAnswer ?? {
      questionId: attributeFieldId,
      surveyId: survey.id,
      value: null,
    }
  );
}

function getAnswerValue(answer: Answer) {
  return parseInt(answer?.value) || null;
}

export function ScorecardAttribute(props: PropTypes) {
  if (!props.survey) return null;

  const [fieldAnswer, setFieldAnswer] = useState(
    getFieldAnswer(props.survey, props.attribute.id),
  );

  useEffect(() => {
    if (fieldAnswer?.value && props.scorecardId != null) {
      applyRating(
        props.scorecardId,
        props.survey.id,
        props.attribute,
        getAnswerValue(fieldAnswer) ?? 0,
        fieldAnswer,
      );
    }
  }, []);

  const onChangeRating = async (rating: number) => {
    const actualRating = getRatingValue(rating, fieldAnswer);

    setFieldAnswer({
      ...fieldAnswer,
      value: actualRating?.toString(),
    });

    props.setSurvey(
      updateSurveyAnswers(
        props.survey,
        props.attribute,
        true,
        actualRating?.toString(),
      ),
    );

    await applyRating(
      props.scorecardId,
      props.survey.id,
      props.attribute,
      actualRating,
      fieldAnswer,
    );
  };

  return (
    <ScorecardAttributeValue
      id={`${props.section.id}_${props.attribute.id}`}
      editable={props.editable}
      fillColorClass={props.fillColorClass}
      name={props.attribute.title}
      rating={getAnswerValue(fieldAnswer)}
      onChangeRating={onChangeRating}
    />
  );
}
