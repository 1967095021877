import { Employee } from '../../../entities/Employee';
import { ApiService } from '../../ApiService';

export class EmployeeService {
  public static update(surveyId: number, email: string): Promise<Employee> {
    return ApiService.patch<Employee>(
      `/api/quality_of_hire/hiring_manager_employee_eval_surveys/${surveyId}/employee`,
      {
        email,
      },
    );
  }
}
