import React from 'react';

export function Benchmark(props: { label: string; value: number }) {
  return (
    <div className='d-flex align-items-center gap-2'>
      <small className='text-muted'>{props.label}</small>
      <span className={'me-1 text-green-500'}>
        <i className='bi bi-activity' />
      </span>
      <span className='fw-semibold d-inline-block mb-0'>
        {props.value ? `${Number(props.value).toFixed(1)}` : 'N/A'}
      </span>
    </div>
  );
}
