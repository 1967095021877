import React, { useRef, useState } from 'react';
import { Application } from '../../../../entities/Application';
import { Button, Tooltip } from 'reactstrap';

interface PropTypes {
  application: Application;
  setOpenSharePulseSurveyModal: (flag: boolean) => void;
}

export function PreviousCandidateActions(props: PropTypes) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const ref = useRef(null);

  return (
    <div className='d-flex gap-3 text-end'>
      {props.application.company_info.pulse_enabled && (
        <>
          <div ref={ref}>
            <Button
              color={'primary'}
              onClick={() => props.setOpenSharePulseSurveyModal(true)}
              disabled={!props.application.enabled_trigger}
            >
              <i
                className='bi bi-share me-2'
                style={{ width: '1em', height: '1em', top: 0 }}
              />
              Share CNPS Survey
            </Button>
          </div>
          <Tooltip
            target={ref}
            isOpen={!props.application.enabled_trigger && isTooltipOpen}
            toggle={() => setIsTooltipOpen(!isTooltipOpen)}
          >
            No triggers have yet been set up for the candidate&apos;s
            status/state.
          </Tooltip>
        </>
      )}
    </div>
  );
}
