import React, { useState } from 'react';
import { IconButton } from '../../../../../../../../components/IconButton';
import { Application } from '../../../../../../../../entities/Application';
import { RejectCandidateStageModal } from '../../../../../../../CandidatePage/CandidateActions/RejectCandidate';
import {
  AlertObject,
  AlertType,
} from '../../../../../../../../components/Alert';
import { BULK_REVIEW_NAVIGATION_TYPE } from '../../../../..';

interface PropTypes {
  application: Application;
  preSelectedRejectReasonId: number;
  setAlert: (alert: AlertObject) => void;
  onNext: (navType: BULK_REVIEW_NAVIGATION_TYPE) => void;
  setPreSelectedRejectReasonId: (id: number) => void;
}

export function RejectApplication(props: PropTypes) {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);

  const handleRejectModalClose = (
    succeeded: boolean,
    rejectedReason?: string,
  ) => {
    setIsRejectModalOpen(false);
    if (succeeded) {
      props.setAlert({
        type: AlertType.Success,
        message: (
          <span className='fw-semibold'>
            Candidate rejected with the reason &quot;{rejectedReason}&quot;.
          </span>
        ),
      });
      props.onNext('rejected');
    } else if (succeeded === false) {
      props.setAlert({
        type: AlertType.Danger,
        message: (
          <span className='fw-semibold'>
            Candidate could not be rejected with the reason &quot;
            {rejectedReason}
            &quot;.
          </span>
        ),
      });
    }
  };

  return (
    <>
      <RejectCandidateStageModal
        application={props.application}
        isOpen={isRejectModalOpen}
        showConfirmationModal={false}
        filterRejectedBy='organization'
        preSelectedRejectReasonId={props.preSelectedRejectReasonId}
        onClose={(_sendEmail: boolean) => setIsRejectModalOpen(false)}
        setPreSelectedRejectReasonId={props.setPreSelectedRejectReasonId}
        onReject={handleRejectModalClose}
      />
      <IconButton
        iconName='bi-x-circle'
        buttonText='Reject'
        buttonClass='me-2h'
        color='danger'
        onClick={() => setIsRejectModalOpen(true)}
      />
    </>
  );
}
