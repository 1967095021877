import { ApiService } from '../../ApiService';
import { SessionUserOverview } from '../../../entities/training/SessionUserOverview';

export class SessionUserService {
  public static overview(params: {
    page?: number;
    name?: string;
    sort?: string;
    direction?: string;
  }): Promise<SessionUserOverview> {
    return ApiService.get<SessionUserOverview>(
      `/api/training/session_users`,
      params,
    );
  }
}
