import { GlobalState, useStateMachine } from 'little-state-machine';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { CreateJobDto } from '../../../../../services/applicant_tracking/JobService/CreateJobDto';
import { buildUrl, replaceFinalSegment } from '../../../../../utils/url';
import { JobPostPageHeader } from './JobPostPageHeader';
import { JobAndJobPost, initCreateJobDto } from '..';
import { getReferrer } from '../referrers';
import { LocationInput, loadLocationOptions } from './LocationInput';
import { EmploymentTypeInput } from './EmploymentTypeInput';
import { SelectOption } from '../../../../../components/Select';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { FormErrorMessage } from '../../../../../components/FormErrorMessage';
import { handleFormErrors } from '../../../../../services/ApiService/handler';
import { JobDetails } from '../../../../../services/applicant_tracking/JobService/EditJobDto';
import { DeprecatedPanel } from '../../../../../components/DeprecatedPanel';
import { ExperienceLevelSelect } from './ExperienceLevelSelect';
import { JobDescriptionInput } from './JobDescriptionInput';

interface PropTypes {
  setupStep: number;
  setSetupStep: (newStep: number, jobDTO: CreateJobDto) => void;
  editorState?: string;
  updateLocalStorage: (state: GlobalState, payload: any) => GlobalState;
  initJobDto: CreateJobDto;
  isEditFlow: boolean;
  jobDetails: JobDetails;
  onSubmit: (state: CreateJobDto) => Promise<JobAndJobPost>;
  generateJobDescriptionEnabled: boolean;
}

export function SetupJobPostPage(props: PropTypes) {
  const navigate = useNavigate();
  const updateLocalStorage = props.updateLocalStorage;
  const { actions, state } = useStateMachine({ updateLocalStorage });
  const [createJobStore, setCreateJobStore] = useState<GlobalState>(state);

  const {
    handleSubmit,
    control,
    formState,
    reset,
    getValues,
    setValue,
    resetField,
    setError,
    watch,
  } = useForm<CreateJobDto>({
    mode: 'onChange',
    defaultValues: props.initJobDto,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    (async () => {
      setLocationOptions(await loadLocationOptions());

      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    reset(props.initJobDto);
  }, [props.initJobDto]);

  const onSubmit = handleSubmit(async () => {
    try {
      const newstate = { ...state.setupJob, ...getValues() };
      const { jobPost } = await props.onSubmit(newstate);
      const nextStepUrl = replaceFinalSegment('application_form');

      actions.updateLocalStorage({ ...newstate, jobPostId: jobPost.id });

      props.setSetupStep(props.setupStep + 1, state.setupJob);
      navigate(nextStepUrl);
    } catch (e: unknown) {
      handleFormErrors(e, setError);
    }
  });

  const updateGlobalStateCallback = useCallback(
    (data: any) => {
      const updatedState = {
        ...createJobStore,
        setupJob: { ...createJobStore.setupJob, ...getValues(), ...data },
      };
      setCreateJobStore(updatedState);
      actions.updateLocalStorage(updatedState.setupJob);
    },
    [createJobStore],
  );

  const previewUrl = buildUrl(
    `${window.location.origin}/applicant_tracking/job_posts/preview`,
    [
      { key: 'name', value: watch('name') },
      { key: 'department_id', value: watch('departmentId') },
      { key: 'location_id', value: watch('locationId') },
      { key: 'location_type', value: watch('locationType') },
    ],
  );
  const onNavigate = handleSubmit(async () => {
    actions.updateLocalStorage({ ...getValues() });
  });

  return (
    <DeprecatedPanel className={'px-4 pt-4 pb-5 mb-5'}>
      <form onSubmit={onSubmit}>
        <FormErrorMessage error={formState.errors} />
        <JobPostPageHeader
          formState={formState}
          jobDetails={props.jobDetails}
          setupStep={props.setupStep}
          setSetupStep={props.setSetupStep}
          onCancel={() => {
            actions.updateLocalStorage(initCreateJobDto);
            window.location.href = getReferrer();
          }}
          onNavigate={onNavigate}
          isEditFlow={props.isEditFlow}
        />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <LocationInput
              formControl={control}
              options={locationOptions}
              formResetField={resetField}
            />
            <EmploymentTypeInput
              formControl={control}
              formResetField={resetField}
            />
            <ExperienceLevelSelect formControl={control} />
            <JobDescriptionInput
              formControl={control}
              formState={formState}
              formResetField={resetField}
              formGetValues={getValues}
              formSetValue={setValue}
              previewUrl={previewUrl}
              updateGlobalStateCallback={updateGlobalStateCallback}
              generateJobDescriptionEnabled={
                props.generateJobDescriptionEnabled
              }
            />
          </>
        )}
      </form>
    </DeprecatedPanel>
  );
}
