import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../ApiService';

const APPLICATION_FEED_COMMENT_URL =
  '/api/applicant_tracking/application_feed_comments/';

interface CreateCommentProps {
  comment: string;
  applicationFeedId: number;
}

interface UpdateCommentProps {
  comment: string;
  applicationFeedCommentId: number;
}

export class ApplicationFeedCommentService {
  public static create(props: CreateCommentProps): Promise<void> {
    return ApiService.post(APPLICATION_FEED_COMMENT_URL, snakecaseKeys(props));
  }

  public static delete(id: number): Promise<void> {
    return ApiService.delete(`${APPLICATION_FEED_COMMENT_URL}${id}`);
  }

  public static update(props: UpdateCommentProps): Promise<void> {
    return ApiService.put(
      `${APPLICATION_FEED_COMMENT_URL}${props.applicationFeedCommentId}`,
      snakecaseKeys(props),
    );
  }
}
