import React from 'react';
import { Col, Row } from 'reactstrap';
import { Panel } from '../../../components/Panel';

interface PropTypes {
  onStartSurvey: () => void;
}

export function SelfAssessmentMainPanel({ onStartSurvey }: PropTypes) {
  return (
    <Panel>
      <h3>
        Thank you for taking the time to provide us with some information to
        help with your onboarding!
      </h3>
      <p className='mb-4'>
        We want to ensure you have an amazing onboarding experience and the
        information you provide will help your new employer understand your core
        strengths and opportunities to grow even better.
      </p>
      <Row className='align-items-center mb-3'>
        <Col xs='auto'>
          <i className='bi bi-clock' style={{ fontSize: '1.3em' }} />
        </Col>
        <Col>This should take you less than 10 minutes to complete.</Col>
      </Row>
      <Row className='align-items-center mb-3'>
        <Col xs='auto'>
          <i className='bi bi-lock' style={{ fontSize: '1.3em' }} />
        </Col>
        <Col>
          Please be honest and thoughtful when answering these questions.
        </Col>
      </Row>
      <hr />
      <div className='text-center mb-4'>
        <a
          href='#start'
          onClick={onStartSurvey}
          className='btn btn-primary p-3 px-5'
        >
          Start Survey
        </a>
      </div>
      <div className='text-center text-muted' style={{ fontSize: '0.9em' }}>
        We take security and privacy seriously. All of the data we collect is
        completely secure and confidential between you and your new employer.
        <br />
        If you have any questions, please contact us at{' '}
        <a href='mailto:support@screenloop.com'>support@screenloop.com</a>.
      </div>
    </Panel>
  );
}
