import React, { useEffect } from 'react';
import { SelectOption } from '../../../../../../../../components/Select';
import { TriggerRowDropdown } from './TriggerRowDropdown';
import { LabelledMultiSelect } from '../../../../../../../../components/LabelledMultiSelect';
import {
  SendEmailTrigger,
  Trigger,
} from '../../../../../../../../entities/applicant_tracking/Trigger';
import { mapStagesToSelectOption } from '../../triggerSelectOptions';
import { PlanTemplate } from '../../../../../../../../entities/applicant_tracking/PlanTemplate';

interface PropTypes {
  label: string;
  trigger: Trigger;
  planTemplate: PlanTemplate;
  stageOptions: SelectOption[];
  setStageOptions: (options: SelectOption[]) => void;
  onlyInterviewStages: boolean;
}

export function StagesDropdown(props: PropTypes) {
  const initStageOptions = mapStagesToSelectOption(
    props.planTemplate,
    props.trigger.stageIds?.map((v) => v.toString()),
    props.onlyInterviewStages,
  );

  useEffect(() => {
    props.setStageOptions(initStageOptions);
  }, []);

  useEffect(() => {
    props.planTemplate &&
      props.setStageOptions(
        mapStagesToSelectOption(
          props.planTemplate,
          (props.trigger as SendEmailTrigger).stageIds?.map((v) =>
            v.toString(),
          ),
          props.onlyInterviewStages,
        ),
      );
  }, [props.planTemplate]);

  return (
    <TriggerRowDropdown>
      <LabelledMultiSelect
        options={props.stageOptions}
        selected={props.stageOptions.filter((v: SelectOption) => v.selected)}
        labelClassName='fs-5'
        label={props.label}
        placeholder='Select stage'
        singleSelectedText='1 selected stage'
        manySelectedText={`${
          props.stageOptions.filter((v) => v.selected).length
        } selected stages`}
        min={1}
        hasSelectAll
        selectAllLabel='All Stages'
        onChange={(options) =>
          props.setStageOptions(
            mapStagesToSelectOption(
              props.planTemplate,
              options.map((o) => o.value),
              props.onlyInterviewStages,
            ),
          )
        }
      />
    </TriggerRowDropdown>
  );
}
