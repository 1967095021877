import React, { useState } from 'react';
import { CloseableModal } from '../../../components/CloseableModal';
import { ShareModalBody } from './ShareModalBody';
import { Button } from 'reactstrap';
import { InterviewAccess } from '../../../entities/InterviewAccess';

interface PropTypes {
  interviewId: number;
  interviewAccesses: InterviewAccess[];
  isOpen: boolean;
  onClose: () => void;
}

interface ModalProps {
  interviewId: number;
  interviewAccesses: InterviewAccess[];
  onChangeAccesses: (newAccesses: InterviewAccess[]) => void;
  onClose: () => void;
}

function ModalBody(props: ModalProps) {
  return (
    <div>
      <ShareModalBody {...props} />
      <div className='d-flex justify-content-end'>
        <Button type='button' color='secondary' onClick={props.onClose}>
          Close
        </Button>
      </div>
    </div>
  );
}

export function ShareModal(props: PropTypes) {
  const [interviewAccesses, setInterviewAccesses] = useState<InterviewAccess[]>(
    props.interviewAccesses,
  );

  return (
    <>
      {props.isOpen && (
        <CloseableModal
          className='modal-dialog-top'
          onClose={() => props.onClose()}
          isOpen={true}
          size='md'
          closeDisabled={false}
          keyboard={true}
          backdrop={true}
          headerTitle='Share Interview'
          bodyChildren={
            <ModalBody
              interviewId={props.interviewId}
              interviewAccesses={interviewAccesses}
              onChangeAccesses={setInterviewAccesses}
              onClose={props.onClose}
            />
          }
        />
      )}
    </>
  );
}
