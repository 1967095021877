import classNames from 'classnames';
import React from 'react';
import { TableHeader as TableHeaderComponent } from '../../../../../../components/TableHeader';
import styles from './styles.module.scss';
interface PropTypes {
  columns: { key: string; label: string; last: boolean }[];
}

function TableHeaderColumnName(props: {
  tag: string;
  label: string;
  idx: number;
  tooltipText: string;
  isSortable: boolean;
}) {
  return (
    <a className={classNames('d-flex justify-content-start')} href='#'>
      <TableHeaderComponent
        label={props.label}
        tooltipText={props.tooltipText}
      />
    </a>
  );
}

export function TableHeader(props: PropTypes) {
  return (
    <thead className={classNames('text-nowrap')}>
      <tr className={classNames('fs-5')}>
        {props.columns.map(({ key, label, last }, idx) => {
          return (
            <th
              className={classNames(
                styles['table-head-border-bottom'],
                'pb-3 fw-semibold',
                last == true && 'col-1',
                last == true && key == 'auto_recording' && 'ps-5',
                last == false && 'col-2',
                key == 'name' && 'ps-4',
              )}
              key={idx}
            >
              <TableHeaderColumnName
                tag={key}
                label={label}
                idx={idx}
                tooltipText={null}
                isSortable={key !== 'email'}
              />
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
