import React from 'react';
import { User } from '../../../entities/User';
import { ReconnectEmailIntegrationPopup } from './ReconnectEmailIntegrationPopup';

interface PropTypes {
  user: User;
  isFreshLogin: boolean;
}

export function InfoOverlay(props: PropTypes) {
  return (
    <>
      <ReconnectEmailIntegrationPopup
        user={props.user}
        isFreshLogin={props.isFreshLogin}
      />
    </>
  );
}
