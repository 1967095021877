import React from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { SurveyFinalScreen } from '../../../containers/quality_of_hire/SurveyPage/SurveyFinalScreen';
import { SurveyPage } from '../../../containers/survey_engine/SurveyPage';
import { SurveyQuestionService } from '../../../services/quality_of_hire/SurveyQuestionService';
import { SurveyService } from '../../../services/quality_of_hire/SurveyService';
import { startPanelFields } from '../../../utils/quality_of_hire/startPanelFields';
import { routes } from '../../config/routes';

export function QualityOfHireApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.qualityOfHire.path}>
          <Route path={routes.qualityOfHire.surveys.path}>
            <Route
              path={routes.qualityOfHire.surveys.survey.path}
              element={
                <SurveyPage
                  getSurvey={SurveyService.get}
                  submitSurvey={SurveyService.submit}
                  submitAnswer={SurveyQuestionService.answer}
                  getStartScreenFields={startPanelFields}
                  surveyFinalScreen={SurveyFinalScreen}
                />
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
