import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import {
  ApprovalFlow,
  RequisitionType,
  ListApprovalFlowsMetadata,
} from '../../../../entities/applicant_tracking/ApprovalFlow';
import { UpsertApprovalFlowRequest } from './UpsertApprovalFlowRequest';

export interface ListParams {
  id: number;
  expand?: string[];
}

export class ApprovalFlowService {
  public static show(params: ListParams): Promise<ApprovalFlow> {
    return ApiService.get<ApprovalFlow>(
      `/api/v1/applicant_tracking/approval_flows/${params.id}`,
      snakecaseKeys(params),
    );
  }

  public static getExistingFlowsMetadata(
    requisitionType: RequisitionType,
  ): Promise<ListApprovalFlowsMetadata> {
    return ApiService.get<ListApprovalFlowsMetadata>(
      '/api/v1/applicant_tracking/approval_flows/list_flows_metadata',
      snakecaseKeys({ requisitionType }),
    );
  }

  public static create(
    approvalFlowRequest: UpsertApprovalFlowRequest,
  ): Promise<void> {
    return ApiService.post<void>(
      '/api/v1/applicant_tracking/approval_flows',
      snakecaseKeys({ approvalFlowRequest }),
    );
  }

  public static update(
    approvalFlowRequest: UpsertApprovalFlowRequest,
  ): Promise<void> {
    return ApiService.put<void>(
      `/api/v1/applicant_tracking/approval_flows/${approvalFlowRequest.id}`,
      snakecaseKeys({ approvalFlowRequest }),
    );
  }

  public static getMatchingApprovalFlow(
    requisitionType: RequisitionType,
    locationId?: number,
    departmentId?: number,
  ): Promise<ApprovalFlow> {
    return ApiService.get<ApprovalFlow>(
      '/api/v1/applicant_tracking/approval_flows/show_matching_approval_flow',
      snakecaseKeys({
        locationId,
        departmentId,
        requisitionType,
        expand: ['steps', 'steps.approvers', 'steps.approvers.user'],
      }),
    );
  }
}
