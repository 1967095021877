import { Moment } from 'moment';
import React from 'react';
import { SingleDatePicker } from '../../../../../../../components/SingleDatePicker';
import { ApplicationUpdatesForm } from './ApplicationUpdatesForm';

interface PropTypes {
  startDate: Moment;
  lastSevenDays: Moment;
  today: Moment;
  setStartDate: (day: Moment) => void;
  setShowCalendar: (showCalendar: boolean) => void;
  showCalendar: boolean;
  edit: boolean;
  processingStartsAt: Moment;
}

export function ApplicationUpdatesFormRow(props: PropTypes) {
  return (
    <>
      <div className='row pt-4'>
        <div className='col-12 fs-5 fw-2 text-dark-200'>
          Consider application updates starting
        </div>
      </div>
      <div className='row pt-2'>
        <div className='row align-items-center'>
          <div className='col-5' style={{ width: 'auto' }}>
            {!props.edit ? (
              <ApplicationUpdatesForm {...props} />
            ) : (
              props.processingStartsAt.format('DD-MM-YYYY')
            )}
          </div>
          {props.showCalendar && !props.edit && (
            <div className='col-2'>
              <SingleDatePicker
                dateFilter={{ start: props.startDate }}
                onChange={(start) => {
                  props.setStartDate(start);
                }}
                minDate={props.lastSevenDays}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
