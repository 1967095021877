import classNames from 'classnames';
import React from 'react';
import { HiringMemberAnalytics } from '../../../../../../../../../entities/candidate_experience/HiringMemberAnalytics';
import { getPercentage } from '../../../../../../../../../utils/getPercentage';
import { SentimentStackedBar } from '../../../../../../FollowUpQuestionsSentimentTab/SentimentsByTopicPanel/SentimentStackedBar';
import styles from '../../TableBody/styles.module.scss';

interface PropTypes {
  hiring_member: HiringMemberAnalytics;
}

export function SentimentAnalysis(props: PropTypes) {
  return (
    <td
      style={{ verticalAlign: 'middle' }}
      className={classNames(
        styles['table-data-cell-border-bottom'],
        'text-center pb-4 pe-4 ps-5',
      )}
    >
      <SentimentStackedBar
        percentagePositive={getPercentage(
          props.hiring_member.sentiment_positives,
          props.hiring_member.sentiment_total,
        )}
        percentageNeutral={getPercentage(
          props.hiring_member.sentiment_neutrals,
          props.hiring_member.sentiment_total,
        )}
        percentageNegative={getPercentage(
          props.hiring_member.sentiment_negatives,
          props.hiring_member.sentiment_total,
        )}
        countNegative={props.hiring_member.sentiment_negatives}
        countNeutral={props.hiring_member.sentiment_neutrals}
        countPositive={props.hiring_member.sentiment_positives}
        topic={true}
      />
    </td>
  );
}
