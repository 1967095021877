import React from 'react';
import { User } from '../../entities/User';
import { ProductAccess } from '../../entities/ProductAccess';
import { HomePageLayout } from './HomePageLayout';
import {
  APPLICANT_TRACKING_WIDGETS,
  CANDIDATE_EXPERIENCE_WIDGETS,
  INTERVIEW_INTELLIGENCE_WIDGETS,
} from './Widgets';
import { InfoOverlay } from './InfoOverlay/indes';

interface Proptypes {
  user: User;
  products: ProductAccess;
  isFreshLogin: boolean;
}

function getWidgetsForUser(_user: User, products: ProductAccess) {
  const widgets = [];

  products.ApplicantTracking && widgets.push(...APPLICANT_TRACKING_WIDGETS);
  products.CandidateExperience && widgets.push(...CANDIDATE_EXPERIENCE_WIDGETS);
  products.InterviewIntelligence &&
    widgets.push(...INTERVIEW_INTELLIGENCE_WIDGETS);

  // widgets.filterByUserRole(user.role) - Yet to be implemented

  return widgets;
}

export default function Homepage(props: Proptypes) {
  const widgets = getWidgetsForUser(props.user, props.products);

  return (
    <>
      <InfoOverlay user={props.user} isFreshLogin={props.isFreshLogin} />
      <HomePageLayout user={props.user} widgets={widgets} />
    </>
  );
}
