import React from 'react';
import { Card } from '../../../../../components/Card';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { LoadingSpinnerIcon } from '../../../../../components/LoadingSpinnerIcon';

export function LoadingState() {
  return (
    <Card
      className={classNames(
        'd-flex justify-content-center align-items-center',
        styles['card'],
      )}
    >
      <div className='d-flex fs-5 align-items-center fw-semibold'>
        <LoadingSpinnerIcon className='text-blue me-3' size='xl' />
        Fetching Feedback...
      </div>
    </Card>
  );
}
