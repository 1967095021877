import classNames from 'classnames';
import { REPORTING_PAGE_BREAK_CLASS } from '../../../utils/pdfHandler';
import styles from './styles.module.scss';
import React from 'react';

export function PageBreak() {
  return (
    <div
      className={classNames(
        REPORTING_PAGE_BREAK_CLASS,
        styles.reportingPageBreak,
      )}
    />
  );
}
