import React from 'react';
import {
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  LexicalEditor,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { IconSpan } from '../../../../IconSpan';
import { Dropdown } from '../../../../Dropdown';

interface PropTypes {
  hideButtonText?: boolean;
}

function MenuAction(
  editor: LexicalEditor,
  command: ElementFormatType,
  iconName: string,
  text: string,
) {
  return {
    action: () => {
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, command);
    },
    buttonChild: <IconSpan spanText={text} icon={{ name: iconName }} />,
  };
}

export function TextAlignDropdown(props: PropTypes) {
  const [editor] = useLexicalComposerContext();

  return (
    <Dropdown
      containerClassName={'mx-1'}
      buttonClassName={'rounded-3 px-2 py-1 fs-5'}
      buttonText={!props.hideButtonText && 'Align'}
      buttonIcon={{
        name: 'bi-text-left',
        className: 'mx-1',
      }}
      menuPlacementClass={'dropdown-menu-start'}
      hasChevron={true}
      menuActions={[
        MenuAction(editor, 'left', 'bi-text-left', 'Left Align'),
        MenuAction(editor, 'center', 'bi-text-center', 'Center Align'),
        MenuAction(editor, 'right', 'bi-text-right', 'Right Align'),
        MenuAction(editor, 'justify', 'bi-justify', 'Justify'),
      ]}
    />
  );
}
