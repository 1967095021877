import React, { useState } from 'react';
import { formatTime } from '../../../utils/timeFormat';
import { markTokens } from './markTokens';
import { TokenList } from '../TokenList';
import { isVisible } from '../../../utils/isVisible';
import { participantsToColor } from '../../../utils/participantsToColor';
import {
  Monologue,
  MonologueToken,
} from '../../../entities/v1/interview_intelligence/Monologue';
import { Participant } from '../../../entities/v1/interview_intelligence/Participant';

// Allow selections to trigger slightly earlier than spoken to improve UX
const SELECTION_TRIGGER_OFFSET_MS = -300;

interface PropTypes {
  index: number;
  autoScroll: boolean;
  playTimestamp?: number;
  speakers: Participant[];
  monologue: Monologue;
  findString: string;
  activeMonologueOffset: number;
  selectedSearchOccurence: { monologue: number; mark: number; global: number };
  onSelectTimestamp: (timestamp: number) => void;
  onUpdateMonologueSearchOccurences?: (index: number, count: number) => void;
}

const extractFirstName = (name: string): string => {
  name = name || 'Unknown';
  return name.split(' ')[0];
};

const findMonologueSpeaker = (
  speakers: Participant[],
  monologue: Monologue,
): Participant => {
  return speakers.find((p) => p.id === monologue.participantId);
};

const getLastActiveWordIndex = (
  words: MonologueToken[],
  time: number,
): number => {
  return words.findIndex((word) => {
    return (
      time >= word.startOffsetMs + SELECTION_TRIGGER_OFFSET_MS &&
      time <= word.endOffsetMs + SELECTION_TRIGGER_OFFSET_MS
    );
  });
};

const RedactedMonologue = (props: { activeIndex: number }) => {
  return (
    <div className='d-flex justify-content-start align-items-center'>
      <i className='fs-2 bi bi-file-lock2 text-pin me-1 pe-2' />
      <div
        className='text-gray-900 fs-5'
        data-active={props.activeIndex === -1}
      >
        {`This content was redacted and it's not available for your user permissions.`}
      </div>
    </div>
  );
};

export const MonologueBlock = React.memo(function MonologueBlock(
  props: PropTypes,
) {
  const [ref, setRef] = useState<HTMLElement>();

  const speaker = findMonologueSpeaker(props.speakers, props.monologue);

  const color = participantsToColor(props.speakers)[
    props.monologue.participantId
  ];
  const activeStyle = { background: `${color}20` };

  const timestamp = props.monologue.startOffsetMs;
  const monologueActive = props.playTimestamp !== undefined;

  const textColor = monologueActive ? color : '#5d6778';
  const borderWidth = monologueActive ? '2px' : '1px';
  const activeIndex = monologueActive
    ? getLastActiveWordIndex(props.monologue.tokens, props.playTimestamp)
    : null;

  if (props.autoScroll && monologueActive) {
    const element = ref?.querySelector<HTMLElement>('[data-active=true]');
    if (
      element &&
      !isVisible(element, element.offsetParent as HTMLElement, {
        bottom: props.activeMonologueOffset,
      })
    ) {
      const { top } = element.getBoundingClientRect();
      const holder = ref.parentElement.parentElement;
      const holderRect = holder.getClientRects()[0];
      if (holderRect != null) {
        holder.scrollBy(0, top - holderRect.top - 50);
      }
    }
  }

  const marks = markTokens(props.monologue, props.findString);
  return (
    <div ref={setRef} className='row mb-4' data-testid='transcribed-monologue'>
      <div
        className='col-2 text-end fs-6'
        style={{
          borderRight: `${borderWidth} solid ${color}`,
        }}
      >
        <a
          href={`#t${timestamp}`}
          style={{
            color: textColor,
          }}
          onClick={(e) => {
            e.preventDefault();
            props.onSelectTimestamp(timestamp);
          }}
        >
          {extractFirstName(speaker?.displayName)}
          <div style={{ color: '#5d6778' }}>
            {formatTime(props.monologue.startOffsetMs)}
          </div>
        </a>
      </div>
      <div className='col fs-5'>
        {props.monologue.redacted ? (
          <RedactedMonologue activeIndex={activeIndex} />
        ) : (
          <TokenList
            tokens={props.monologue.tokens}
            marks={marks}
            monologueIndex={props.index}
            activeIndex={activeIndex}
            extraTokenProps={{
              monologueActive,
              activeStyle,
              playTimestamp: props.playTimestamp,
              selectionTriggerOffsetMs: SELECTION_TRIGGER_OFFSET_MS,
            }}
            selectedMonologueSearchOccurenceIndex={
              props.index === props.selectedSearchOccurence.monologue
                ? props.selectedSearchOccurence.mark
                : undefined
            }
            onUpdateMonologueSearchOccurences={
              props.onUpdateMonologueSearchOccurences
            }
          />
        )}
      </div>
    </div>
  );
});
