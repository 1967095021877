import React from 'react';
import { EmptyState } from '../../../../components/EmptyState';

export function SessionsEmptyState() {
  return (
    <EmptyState
      title='No training sessions yet!'
      text={
        <p>
          Currently there are no training sessions, be patient young apprentice.
        </p>
      }
    />
  );
}
