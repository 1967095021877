import React from 'react';
import classNames from 'classnames';

export interface PropTypes {
  selectedValue?: string;
  id: string;
  label: string;
  value: string;
  name?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export function RadioPill(props: PropTypes) {
  const checked = props.selectedValue === props.value;

  return (
    <>
      <input
        type='radio'
        className={'btn-check'}
        value={props.value}
        name={props.name || 'options'}
        id={props.id}
        autoComplete='off'
        defaultChecked={checked}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      <label
        className={classNames(
          'btn',
          'btn-sm',
          {
            'btn-primary': checked,
            'btn-secondary': !checked,
          },
          'rounded-pill',
          'mt-1',
          'me-1',
          'fs-6',
          props.className,
        )}
        htmlFor={props.id}
      >
        {props.label}
      </label>
    </>
  );
}
