import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { FormFieldHeader } from '../../../../components/FormFieldHeader';
import {
  LocationOption,
  LocationSelect,
} from '../../../../components/LocationSelect';
import { CandidateDto } from '../../../../services/applicant_tracking/CandidateService/CandidateDto';
import { LocationEntity } from '../../../../services/GeoLocationService';

interface PropTypes {
  control: Control<CandidateDto, any>;
}

export function LocationInput(props: PropTypes) {
  return (
    <Row className='mt-4'>
      <Col xs={3}>
        <FormFieldHeader fieldName='Location' classNames='fw-semibold' />
        <Controller
          control={props.control}
          name={'location'}
          render={({ field }) => (
            <LocationSelect
              selected={getSelectedOption(field.value)}
              onChange={(option: LocationOption) => {
                if (option == null) {
                  field.onChange(null);
                  return;
                }

                field.onChange({
                  name: option.label,
                  address: option.address,
                  city: option.city,
                  state: option.state,
                  country: option.country,
                });
              }}
              className='fs-5'
              isClearable={true}
            />
          )}
        />
      </Col>
    </Row>
  );
}

export function getSelectedOption(location?: LocationEntity): LocationOption {
  if (location == null || location.country == null) {
    return null;
  }

  return {
    value: location.name,
    label: location.name,
    selected: true,
    address: location.address,
    city: location.city,
    state: location.state,
    country: location.country,
  };
}
