import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface PropTypes {
  className?: string;
}

export function ThematicBreak(props: PropTypes) {
  return <hr className={classNames(props.className, styles.line, 'm-0')} />;
}
