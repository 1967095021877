import React from 'react';
import { Button } from 'reactstrap';
import { EmptyState } from '../../../../../components/EmptyState';

export function SurveyTriggerEmptyState() {
  return (
    <div className='card shadow-sm d-flex flex-column align-items-start mt-3 p-3 p-xxl-4'>
      <div className='w-100'>
        <div>
          <EmptyState
            title='No Survey Triggers created yet'
            text={
              <p>
                Create your first trigger to start sending candidate surveys.
              </p>
            }
            classNames={'mb-4'}
            innerMostClassName={'h-50'}
          />
        </div>
        <div className='d-flex justify-content-center mb-5'>
          <Button
            color={'primary'}
            onClick={() =>
              (window.location.href =
                '/organization_settings/survey_triggers/new')
            }
          >
            Add Trigger
          </Button>
        </div>
      </div>
    </div>
  );
}
