import React from 'react';
import { IconButton } from '../../../../components/IconButton';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface Proptypes {
  iconName: string;
  disabled?: boolean;
  iconTopStyle?: string;
  onClick: () => void;
}

export function ActionButton(props: Proptypes) {
  return (
    <IconButton
      disabled={props.disabled}
      color='secondary'
      iconClass='text-info'
      buttonClass={classNames(styles['action-button'])}
      iconName={props.iconName}
      onClick={props.onClick}
    />
  );
}
