import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Survey } from '../../../entities/Survey';
import { useNavigate } from 'react-router';
import DoneFeedbackRequestPageImage from '../../../images/done_feedback_request_page.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { MoreInfoFooter } from '../MoreInfoFooter';

interface PropTypes {
  survey: Survey;
}

export function ReferenceSurveyFinalScreen(props: PropTypes) {
  const navigate = useNavigate();

  return (
    <div className='col-9 mx-auto mb-3'>
      <Row className='text-center'>
        <div className={classNames(styles['references-card'])}>
          <div className={classNames('pt-5 pb-5')}>
            <Col>
              <div className='pt-5 pb-5'>
                <div className='d-flex justify-content-center'>
                  <img
                    className='d-block img-fluid'
                    src={DoneFeedbackRequestPageImage}
                  />
                </div>
                <Row className='mb-2 mt-5'>
                  <Col>
                    <h1 className='fw-semibold fs-display2'>
                      You&apos;re done with the feedback request
                    </h1>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md='8' className='offset-md-2'>
                    <p className='fs-4'>
                      Thanks for helping {props.survey.candidate.first_name}. A
                      hiring manager from {props.survey.company_info.name} may
                      contact you with additional questions.{' '}
                      {props.survey.company_info
                        .enable_reference_check_job_opportunities_question &&
                        'Please click next to complete the process.'}
                    </p>
                  </Col>
                </Row>
                {props.survey.company_info
                  .enable_reference_check_job_opportunities_question && (
                  <div className='d-grid col-2 mx-auto'>
                    <Button
                      color='primary'
                      size='lg'
                      onClick={() => {
                        navigate('../add-prospect');
                      }}
                    >
                      Next
                    </Button>
                  </div>
                )}
              </div>
              <Row>
                <MoreInfoFooter />
              </Row>
            </Col>
          </div>
        </div>
      </Row>
    </div>
  );
}
