import { ApiService } from '../../ApiService';

export class ExportsService {
  public static exportSuccessReport(applicationId: number): Promise<Blob> {
    return ApiService.get<Blob>(
      `/exports/success_reports/${applicationId}/pdf_export`,
      {},
    );
  }
}
